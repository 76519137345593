import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext } from "react";
import { BookingDetailContext } from "../../../context/booking";
import { IBooking } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";

const GENERAL_KEYS: IRowsKey<IBooking>[] = [
    {
        id: "bookingNo",
        label: Messages.bookingNo,
    },
    {
        id: "branch",
        label: Messages.branchWarehouse,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "schedule",
        label: Messages.estDateOfVisit,
        renderContent: ({ data }) =>
            `${fmtMSS(data?.start)} - ${fmtMSS(data?.end)}`,
    },
    {
        id: "scheduleDate",
        label: Messages.confirmedDateOfVisit,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
];

const BookingDetailGeneral = () => {
    const { booking } = useContext(BookingDetailContext);

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={booking}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default BookingDetailGeneral;
