import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find, omit } from "lodash";
import { useContext, useState } from "react";
import { TypeOfIdentity } from "../../../api/hooks";
import AgencyAPI from "../../../api/queries/agency";
import Drawer from "../../../common/Drawer";
import {
    AGENCY_TYPES,
    AGENCY_TYPE_OF_IDENTITIES,
} from "../../../constant/agency";
import { AgencyDetailContext } from "../../../context/agency";
import { AgencySchema } from "../../../formschema/agency";
import { IAgency, mapAgencyToServer } from "../../../interfaces/agency";
import Messages from "../../../languages/Messages";
import AgencyForm from "../share/AgencyForm";

interface IEditGeneralInfoDrawer {
    open: boolean;
    agency: any;
    onClose: () => void;
    onUpdated: any;
}

const EditGeneralInfoDrawer = ({
    agency,
    open,
    onClose,
    onUpdated,
}: IEditGeneralInfoDrawer) => {
    const agencyForm = useFormik<any>({
        initialValues: agency as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AgencySchema,
        onSubmit: (values: any) => {
            const input = mapAgencyToServer(
                omit(values, [
                    "id",
                    "updatedAt",
                    "createByAdmin",
                    "agencyNo",
                    "createdAt",
                ])
            );
            onUpdateAgency(input);
        },
    });

    const onUpdateAgency = (payload: any) => {
        Progress.show(
            {
                method: AgencyAPI.update,
                params: [{ id: agency?.id, payload }],
            },
            (res: any) => {
                const updated = res;
                onUpdated && onUpdated(updated);
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.generalInformation}
            onSave={() => agencyForm.handleSubmit()}
            size="large"
        >
            <AgencyForm agencyForm={agencyForm} variant="update" />
        </Drawer>
    );
};

const AgencyDetailGeneral = () => {
    const { agencyDetail, setAgencyDetail } = useContext(AgencyDetailContext);
    const [showEdit, setShowEdit] = useState(false);
    const { typeOfIdentity } = agencyDetail || {};

    const GENERAL_INFO_KEYS: IRowsKey<IAgency>[] = [
        {
            id: "agencyNo",
            label: Messages.id,
        },
        {
            id: "typeOfAgency",
            label: Messages.typeOfAgency,
            renderContent: ({ data }) => {
                return (Messages as any)[
                    find(AGENCY_TYPES, (item) => item?.id === data)?.label || ""
                ];
            },
        },
        {
            id: "typeOfIdentity",
            label: Messages.typeOfIdentity,
            renderContent: ({ data }) => {
                return (Messages as any)[
                    find(AGENCY_TYPE_OF_IDENTITIES, (item) => item?.id === data)
                        ?.label || ""
                ];
            },
        },

        {
            id: "name",
            label:
                typeOfIdentity === TypeOfIdentity.Business
                    ? Messages.companyName
                    : Messages.individualName,
            renderContent: ({ data }) => data,
        },
        {
            id: "countryOfOrigin",
            label: Messages.countryOfOrigin,
            renderContent: ({ data }) => {
                return data?.name;
            },
        },
        {
            id: "codeNumber",
            label:
                typeOfIdentity === TypeOfIdentity.Business
                    ? Messages.companyRegistrationNo
                    : Messages.citizenId,
            renderContent: ({ data }) => {
                return data;
            },
        },
        {
            id: "address",
            label:
                typeOfIdentity === TypeOfIdentity.Business
                    ? Messages.companyAddress
                    : Messages.residenceAddress,
            renderContent: ({ data }) => {
                return data;
            },
        },
        {
            id: "phone",
            label:
                typeOfIdentity === TypeOfIdentity.Business
                    ? Messages.companyPhoneNo
                    : Messages.phoneNo,
            renderContent: ({ data }) => {
                return data;
            },
        },
        {
            id: "representativesName",
            hidden: typeOfIdentity !== TypeOfIdentity.Business,
            label: Messages.representativesName,
            renderContent: ({ data }) => {
                return data;
            },
        },
        {
            id: "representativesPrimaryPhone",
            hidden: typeOfIdentity !== TypeOfIdentity.Business,
            label: Messages.representativesPrimaryPhone,
            renderContent: ({ data }) => {
                return data;
            },
        },
        {
            id: "remark",
            label: Messages.remark,
            renderContent: ({ data }) => {
                return <div dangerouslySetInnerHTML={{ __html: data }} />;
            },
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
        {
            id: "updatedAt",
            label: Messages.updatedAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
    ];

    const renderHeader = (title: any, onClickEdit: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                <Button
                    onClick={onClickEdit}
                    className=""
                    variant="trans"
                    size="small"
                    color="red"
                >
                    {Messages.edit}
                </Button>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation, () =>
                    setShowEdit(true)
                )}

                <ViewRowInterchange
                    keyList={GENERAL_INFO_KEYS}
                    dataSource={agencyDetail}
                    variant="background"
                />
            </div>

            {showEdit && (
                <EditGeneralInfoDrawer
                    agency={{
                        ...agencyDetail,
                    }}
                    open={showEdit}
                    onClose={() => setShowEdit(false)}
                    onUpdated={setAgencyDetail}
                />
            )}
        </div>
    );
};

export default AgencyDetailGeneral;
