import React, { Fragment, useState } from "react";
import { Button, Icon, Notifications, Select } from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";
import IUser from "../../../interfaces/user";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { IProduct, ISalesPerson } from "../../../interfaces/product";
import UserSelect from "../../user/share/UserSelect";
import UserSelectedView from "../../user/share/UserSelectedView";
import { SALES_PERSON_TYPE } from "../../../constant/order";
import { JobTypeAdmin, SalesPersonType } from "../../../api/hooks";

export const validateSalesPerson = (
    product: IProduct,
    values: ISalesPerson,
    primarySalesPerson: IUser
) => {
    let isValid = true;
    const isExist =
        product?.salesPerson?.find(
            (item) => item?.salesPerson?.id === values?.salesPerson?.id
        ) || values?.salesPerson?.id === primarySalesPerson?.id;
    if (isExist) {
        Notifications.showError(Messages.salesPersonExist);
        isValid = false;
    }
    //find person in product?.salesPerson if type of person is doctor and there is more than 1 doctor in product's sales persons then throw error
    const numOfDoctors =
        product?.salesPerson?.filter(
            (item) => item?.type === SalesPersonType.Doctor && !item.isPrimary
        )?.length ?? 0;
    if (numOfDoctors > 0 && values?.type === SalesPersonType.Doctor) {
        Notifications.showError(Messages.maximumNumberOfDoctorsIsOne);
        isValid = false;
    }
    //find person in product?.salesPerson if type of person is sale and there is more than 2 sales in product's sales persons then throw error
    const numOfSales =
        product?.salesPerson?.filter(
            (item) => item?.type === SalesPersonType.Sales && !item.isPrimary
        )?.length ?? 0;
    if (numOfSales > 1 && values?.type === SalesPersonType.Sales) {
        Notifications.showError(Messages.maximumNumberOfSalesIsTwo);
        isValid = false;
    }
    return isValid;
};

const ProductItemSalesperson = ({
    primarySalesPerson,
    product,
    onChange,
}: {
    primarySalesPerson: IUser;
    product: IProduct;
    onChange?: any;
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showAddSaleModal, setShowAddSaleModal] = useState(false);

    const onSaveSalePerson = (values: ISalesPerson) => {
        const isValid = validateSalesPerson(
            product,
            values,
            primarySalesPerson
        );
        if (!isValid) return;

        setShowAddSaleModal(false);
        const salesPersons = [...(product?.salesPerson ?? []), values];
        onChange && onChange(product, "salesPerson", salesPersons);
    };

    return (
        <div>
            <div
                className="bg-primary px-3 py-2 flex items-center cursor-pointer"
                onClick={() => setExpanded(!expanded)}
            >
                <span className="text-white text-sm flex-1">
                    {Messages.salesPersons}
                </span>
                <Icon
                    className="text-white cursor-pointer"
                    name={expanded ? "expand_less" : "expand_more"}
                />
            </div>
            <div hidden={!expanded} className="border border-primary p-3">
                {primarySalesPerson && (
                    <>
                        <UserAvatarName
                            user={primarySalesPerson}
                            classNameText="text-sm"
                        />
                        <div className="text-gray-500 bg-gray-100 mt-2 text-sm p-2">
                            {Messages.primarySalesPerson}
                        </div>
                    </>
                )}
                {product?.salesPerson?.map((item: any) => {
                    return (
                        <div
                            key={item?.salesPerson?.id}
                            className="border-t pt-2 mt-2"
                        >
                            <div className="flex items-center">
                                <UserAvatarName
                                    user={item?.salesPerson}
                                    classNameText="text-sm"
                                    className="flex-1"
                                />
                                <Icon
                                    className="cursor-pointer text-red-500"
                                    name="remove_circle"
                                    onClick={() => {
                                        //remove this item from list
                                        const salesPersons =
                                            product?.salesPerson?.filter(
                                                (salePerson) =>
                                                    salePerson?.salesPerson
                                                        ?.id !==
                                                    item?.salesPerson?.id
                                            );
                                        onChange &&
                                            onChange(
                                                product,
                                                "salesPerson",
                                                salesPersons
                                            );
                                    }}
                                />
                            </div>
                            <div className="text-gray-500 bg-gray-100 mt-2 text-sm p-2">
                                {item?.type === SalesPersonType.Doctor
                                    ? Messages.cosalePersonByDoctor
                                    : Messages.cosalePersonBySales}
                            </div>
                        </div>
                    );
                })}
                <div className="border-t border-gray-200 mt-2">
                    <Button
                        variant="outline"
                        color="primary"
                        className="w-100 mt-2 text-sm"
                        size="small"
                        onClick={() => setShowAddSaleModal(true)}
                    >
                        {Messages.addCoSales}
                    </Button>
                </div>
            </div>
            {showAddSaleModal && (
                <AddSalePersonModal
                    open={showAddSaleModal}
                    onClose={() => setShowAddSaleModal(false)}
                    onSave={(values: ISalesPerson) => {
                        onSaveSalePerson(values);
                    }}
                />
            )}
        </div>
    );
};

export const AddSalePersonModal = ({
    open,
    onClose,
    onSave,
}: {
    open: boolean;
    onClose: any;
    onSave: (values: ISalesPerson) => void;
}) => {
    const salesPersonForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: true,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            salesPerson: Yup.mixed().required(Messages.requiredField),
            type: Yup.string().required(Messages.requiredField),
        }),
        onSubmit: (values: any) => {
            onSave(values as ISalesPerson);
        },
    });
    const { values, errors, setFieldValue, handleSubmit } = salesPersonForm;

    return (
        <Drawer
            open={open}
            title={Messages.addCoSalePerson}
            onClose={() => onClose()}
            onSave={handleSubmit}
            destroyOnClose
            size="auto"
            width="500px"
        >
            <div className="p-3">
                <Select
                    className="mb-3"
                    dataSource={SALES_PERSON_TYPE}
                    label={Messages.typeOfSale}
                    getLabel={(item) => Messages[item.label]}
                    value={values?.type}
                    error={errors?.type}
                    onChange={(value) => {
                        setFieldValue("salesPerson", undefined);
                        setFieldValue("type", value);
                    }}
                />
                <UserSelect
                    onChange={(value) => {
                        setFieldValue("salesPerson", value);
                    }}
                    label={Messages.coSalePerson}
                    params={
                        values?.type === SalesPersonType.Doctor
                            ? { jobType: JobTypeAdmin.Doctor }
                            : {}
                    }
                    value={values?.salesPerson}
                    error={errors?.salesPerson}
                    disabled={!values.type}
                />
                {values?.salesPerson && (
                    <UserSelectedView user={values?.salesPerson} />
                )}
            </div>
        </Drawer>
    );
};

export default ProductItemSalesperson;
