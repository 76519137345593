import React, { useContext } from "react";
import { IRowsKey, TimeUtils, ViewLabelStatus, ViewRowInterchange } from "d-react-components";
import { MaterialRequestContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import { IMaterialPurchaseRequest } from "../../../interfaces/material";
import { MATERIAL_REQUEST_STATUSES } from "../../../constant/material";

const MaterialRequestDetailSummaryView = () => {
    const { request } = useContext(MaterialRequestContext);
    const DETAILS_KEY: IRowsKey<IMaterialPurchaseRequest>[] = [
        {
            id: "materialPurchaseRequestNo",
            label: Messages.id,
        },
        {
            id: "expectedDateOfArrival",
            label: Messages.expectedDateOfArrival,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDate(data);
            },
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];
    return (
        <div>
            {request && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            {request?.materialPurchaseRequestNo}
                        </div>
                        <ViewLabelStatus
                            status={request?.status}
                            listStatus={MATERIAL_REQUEST_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={request}
                            keyList={DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaterialRequestDetailSummaryView;
