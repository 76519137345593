import { Dropdown, Menu } from "antd";
import { Button } from "d-react-components";
import { map } from "lodash";
import React from "react";
import { TABLE_ACTIONS } from "../constant/app";
import Messages from "../languages/Messages";

const TableAction = ({ onClick, dataSource = TABLE_ACTIONS }: any) => {
    const menu = (
        <Menu>
            {map(dataSource, (action) => (
                <Menu.Item key={action.id} onClick={() => onClick(action.id)}>
                    {(Messages as any)[action.label]}
                </Menu.Item>
            ))}
        </Menu>
    );
    return (
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="flex-center w-100"
        >
            <Button size="small" iconName="more_vert" variant="trans" />
        </Dropdown>
    );
};

export default TableAction;
