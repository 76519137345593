import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useGetServiceCategoriesLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";

import TableAction from "../../../common/TableActions";
import { SERVICE_CATEGORY_STATUSES } from "../../../constant/service";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const ServiceCategoryTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const [getServiceCategories] = useGetServiceCategoriesLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.SERVICE_CATEGORY_DETAIL, {
                        categoryId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={SERVICE_CATEGORY_STATUSES}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },

        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        // {
        //     title: Messages.action,
        //     dataIndex: "",
        //     align: "center",
        //     render: (category: any) => {
        //         return (
        //             <TableAction
        //                 onClick={(actionId: string) =>
        //                     onClickTableAction(actionId, category)
        //                 }
        //             />
        //         );
        //     },
        // },
    ];

    const source = (pagingData: any) => {
        return getServiceCategories({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickTableAction = (actionId: string, category: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.SERVICE_CATEGORY_DETAIL, {
                        categoryId: category.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.serviceCategory}
                className="app-layout__page-header"
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onClickNew={() => navigate(Path.SERVICE_CATEGORY_CREATE)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default ServiceCategoryTable;
