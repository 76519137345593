import {
    AwesomeTableComponent,
    Button,
    ButtonProps,
    HeaderTable,
    IColumnsProps,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, filter, find, map } from "lodash";
import { useRef, useState } from "react";
import { useListEquipmentForAdminLazyQuery } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";
import EquipmentNameView from "./EquipmentNameView";
import { EQUIPMENT_CONDITIONS } from "../../../constant/equipment";

interface IModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (values: any) => void;
    defaultValue?: any[];
    multiple?: boolean;
    keepOrigin?: boolean;
    filterSource?: any;
    customSource?: any;
}

interface IProps extends Omit<IModalProps, "open" | "onClose"> {
    isInvalidateOpenSearch?: () => boolean;
    children?: any;
    buttonProps?: ButtonProps;
}

export const EquipmentSearchModal = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    multiple = true,
    keepOrigin = true,
    filterSource,
    customSource,
}: IModalProps) => {
    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",

            render: (equipment: IEquipment) => (
                <EquipmentNameView equipment={equipment} />
            ),
        },
        // {
        //     hidden: isHideAvaQtyCurrentWH,
        //     title: Messages.availableQtyCurrentStore,
        //     titleTooltip: Messages.availableQtyCurrentStoreTooltip,
        //     dataIndex: "allAvailableCW",
        // },

        // {
        //     hidden: isHideOnHoldQtyCurrentWH,
        //     title: Messages.onHoldQtyCurrentStore,
        //     titleTooltip: Messages.onHoldQtyCurrentStoreTooltip,
        //     dataIndex: "allOnHoldCW",
        // },
        // {
        //     hidden: isHideAvaQtyDelivery,
        //     title: Messages.availableQtyDelivery,
        //     titleTooltip: Messages.availableQtyDeliveryTooltip,
        //     dataIndex: "allAvailableDW",
        // },
        // {
        //     hidden: isHideOnHoldQtyDelivery,
        //     title: Messages.onHoldQtyDelivery,
        //     titleTooltip: Messages.onHoldQtyDeliveryTooltip,
        //     dataIndex: "allOnHoldDW",
        // },

        // {
        //     hidden: isHideRegularPrice,
        //     title: Messages.regularPrice,
        //     dataIndex: "regularPrice",
        //     render: StringUtils.moneyThaiFormat,
        //     width: 150,
        // },

        // {
        //     hidden: isHideSalePrice,
        //     title: Messages.salePrice,
        //     dataIndex: "salePrice",
        //     render: StringUtils.moneyThaiFormat,
        //     width: 150,
        // },
        {
            title: Messages.condition,
            dataIndex: "condition",
            render: (condition: any) => (
                <ViewLabelStatus
                    listStatus={EQUIPMENT_CONDITIONS}
                    status={condition}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
            width: 150,
        },
    ];

    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [equipmentSelect, setEquipmentSelect] =
        useState<IEquipment[]>(defaultValue);
    const [getListEquipment] = useListEquipmentForAdminLazyQuery();

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const source = (pagingData: any) => {
        if (customSource) {
            return customSource(searchRef?.current ?? "", pagingData);
        }
        return getListEquipment({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickSave = () => {
        onSave(equipmentSelect);
        onClose();
    };

    const getSelectProps = (equipment: any) => {
        const disabled = false;
        // if (
        //     product?.productType === PRODUCT_TYPE.SIMPLE_PRODUCT &&
        //     product.allAvailableCW === 0 &&
        //     !product.sellingOutOfStock
        // ) {
        //     disabled = true;
        // }
        return {
            disabled,
        };
    };

    const onChangeSelectEquipment = (
        selectedRowKeys: string[],
        selectRows: any[]
    ) => {
        const existedSelectRow = filter(selectRows, (item) => !!item);
        const equipments = map(selectedRowKeys, (equipmentId) => {
            const origin = find(defaultValue, (i) => i?.id === equipmentId);
            let selectRow = find(
                existedSelectRow,
                (item) => item?.id === equipmentId
            );
            if (!selectRow) {
                selectRow = find(
                    equipmentSelect,
                    (item) => item.id === equipmentId
                );
            } else if (keepOrigin && origin) {
                selectRow = origin;
            }
            return selectRow;
        });
        setEquipmentSelect(equipments);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.searchEquipment}
            onSave={onClickSave}
            size="auto"
            width="800px"
        >
            <div className="p-4">
                <HeaderTable
                    // onClickFilter={() => {}}
                    onChangeText={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                    className="mb-3"
                />
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    source={source}
                    columns={columns}
                    transformer={(res) => {
                        return res?.data?.data?.data ?? [];
                    }}
                    baseColumnProps={{ width: 600 }}
                    bordered={false}
                    rowSelection={{
                        type: multiple ? "checkbox" : "radio",
                        selectedRowKeys: map(
                            equipmentSelect,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectEquipment as any,
                        getCheckboxProps: getSelectProps,
                    }}
                />
            </div>
        </Drawer>
    );
};

const EquipmentSearchButton = ({
    isInvalidateOpenSearch = () => false,
    children = Messages.search,
    buttonProps = {},
    ...modalProps
}: IProps) => {
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const onClickSearch = () => {
        if (isInvalidateOpenSearch()) {
            return;
        }
        setOpenSearch(true);
    };

    return (
        <div>
            <Button iconName="search" onClick={onClickSearch} {...buttonProps}>
                {children}
            </Button>
            {openSearch && (
                <EquipmentSearchModal
                    open={openSearch}
                    onClose={() => setOpenSearch(false)}
                    {...modalProps}
                />
            )}
        </div>
    );
};

export default EquipmentSearchButton;
