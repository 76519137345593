import {
    DialogManager,
    Notifications,
    Progress,
    TabBar,
    ViewLabelStatus,
} from "d-react-components";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
    DoctorOrderStatus,
    TreatmentStatus,
    useCancelDoctorOrderForAdminMutation,
    useCompleteDoctorOrderForAdminMutation,
    useDetailDoctorOrderForAdminLazyQuery,
} from "../../../api/hooks";
import ActionMenu, { ActionButton } from "../../../common/ActionButton";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import { DoctorOrderDetailContext } from "../../../context/doctor-order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import DoctorOpinion from "./DoctorOpinion";
import DoctorOrderActivity from "./DoctorOrderActivity";
import DoctorOrderCompletedTreatment from "./DoctorOrderCompletedTreatment";
// import QuotationActivity from "./QuotationActivity";
// import QuotationContactInformation from "./QuotationContactInformation";
import DoctorOrderGeneralInformation from "./DoctorOrderGeneralInformation";
import DoctorOrderMedicine from "./DoctorOrderMedicine";
import DoctorOrderRecommendedTreatment from "./DoctorOrderRecommendedTreatment";
// import QuotationPrice from "./QuotationPrice";
import DoctorOrderSummaryView from "./DoctorOrderSummaryView";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <DoctorOrderGeneralInformation />,
    },
    {
        id: "doctorOpinion",
        label: Messages.doctorOpinion,
        component: <DoctorOpinion />,
    },
    {
        id: "recommendedTreatment",
        label: Messages.recommendedTreatment,
        component: <DoctorOrderRecommendedTreatment />,
    },
    {
        id: "completedTreatment",
        label: Messages.completedTreatment,
        component: <DoctorOrderCompletedTreatment />,
    },
    {
        id: "medicine",
        label: Messages.medicine,
        component: <DoctorOrderMedicine />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <DoctorOrderActivity />,
    },
];

const DoctorOrderDetail = () => {
    const { orderId } = useParams<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [getOrder, { data, loading, refetch }] =
        useDetailDoctorOrderForAdminLazyQuery();
    const [completeOrder] = useCompleteDoctorOrderForAdminMutation();
    const [cancelOrder] = useCancelDoctorOrderForAdminMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (orderId) {
            loadOrderDetail();
        }
    }, [orderId]);

    const loadOrderDetail = () => {
        Progress.show(
            {
                method: getOrder,
                params: [
                    {
                        variables: {
                            id: orderId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };

    const orderDetail = data?.data?.data;

    const isCancellable = useMemo(() => {
        const hasCompletedTreatment =
            (
                orderDetail?.treatments?.filter(
                    (treatment) =>
                        treatment.status === TreatmentStatus.Completed
                ) ?? []
            ).length > 0;
        const hasMedicine = (orderDetail?.products ?? []).length > 0;
        return !hasCompletedTreatment && !hasMedicine;
    }, [orderDetail]);

    const onClickAction = (item: any) => {
        if (item.id === "cancelDoctorOrder") {
            // setShowAddQuotation(true);
            if (!isCancellable) {
                Notifications.showError(Messages.cannotCancelThisDoctorOrder);
            } else {
                Progress.show(
                    {
                        method: cancelOrder,
                        params: [
                            {
                                variables: {
                                    id: orderId as string,
                                },
                            },
                        ],
                    },
                    () => {
                        Notifications.showSuccess(
                            Messages.cancelDoctorOrderSuccessfully
                        );
                        refetch();
                    },
                    (err: any) => {
                        Notifications.showError(err);
                    }
                );
            }
        }
    };

    const onCompleteDoctorOrder = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.confirmCompleteDoctorOrder,
            () => confirmCompleteDoctorOrder()
        );
    };

    const confirmCompleteDoctorOrder = () => {
        Progress.show(
            {
                method: completeOrder,
                params: [
                    {
                        variables: {
                            id: orderId as string,
                        },
                    },
                ],
            },
            () => {
                Notifications.showSuccess(
                    Messages.completeDoctorOrderSuccessfully
                );
                refetch();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    if (isEmpty(data?.data?.data)) {
        return <div />;
    }

    return (
        <DoctorOrderDetailContext.Provider
            value={{
                doctorOrder: data?.data?.data as any,
                loadDoctorOrderDetail: refetch,
            }}
        >
            <div className="px-4 py-3 bg-white flex border-b items-center">
                <div className="flex flex-1 align-items-center">
                    <h5 className="text-primary mr-3">
                        {Messages.doctorOrder} #
                        {`${orderDetail?.doctorOrderNo}`}
                    </h5>
                    <ViewLabelStatus
                        status={orderDetail?.status}
                        listStatus={DOCTOR_ORDER_STATUSES}
                        getLabel={(item) => (Messages as any)[item.label]}
                    />
                </div>
                <div className="flex items-center">
                    {orderDetail?.status !== DoctorOrderStatus.Completed && (
                        <ActionButton
                            className="mr-2"
                            onClick={() => onCompleteDoctorOrder()}
                        >
                            {Messages.completeDoctorOrder}
                        </ActionButton>
                    )}
                    {orderDetail?.status !== DoctorOrderStatus.Completed && (
                        <ActionButton
                            className="mr-2"
                            onClick={() => {
                                navigate(
                                    generatePath(Path.TREATMENT_CREATE, {
                                        orderId,
                                    })
                                );
                            }}
                        >
                            {Messages.createTreatment}
                        </ActionButton>
                    )}
                    <ActionMenu
                        dataSource={[
                            {
                                id: "cancelDoctorOrder",
                                label: Messages.cancelDoctorOrder,
                            },
                        ]}
                        onClick={onClickAction}
                    />
                </div>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <DoctorOrderSummaryView />
                    </div>
                    <div className="col-span-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
            </div>
        </DoctorOrderDetailContext.Provider>
    );
};

export default DoctorOrderDetail;
