import API from "../API";
import { F_JOB_COST } from "../fragments/job-cost";
import {
    F_SEARCH_SERVICE_CATEGORIES,
    F_SERVICE_CATEGORIES,
    F_SERVICE_CATEGORY,
    F_SERVICE_CONFIGURATION,
    F_SERVICE_CONFIGURATIONS,
} from "../fragments/service";

import { gql } from "@apollo/client";

const serviceConfigConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

export const getServiceCategories = gql`
    ${F_SERVICE_CATEGORIES}
    query getServiceCategories($paginate: ListAdminCategoryServiceRequest!) {
        data: listCategoryServiceForAdmin(paginate: $paginate) {
            ...F_SERVICE_CATEGORIES
        }
    }
`;

export const searchServiceCategories = gql`
    ${F_SEARCH_SERVICE_CATEGORIES}
    query searchCategoryService($paginate: SearchAdminCategoryServiceRequest!) {
        data: searchCategoryServiceForAdmin(paginate: $paginate) {
            ...F_SEARCH_SERVICE_CATEGORIES
        }
    }
`;

export const getServiceCategory = gql`
    ${F_SERVICE_CATEGORY}
    query getServiceCategory($id: String!) {
        data: detailCategoryServiceForAdmin(id: $id) {
            data {
                ...F_SERVICE_CATEGORY
            }
        }
    }
`;

export const createServiceCategory = gql`
    ${F_SERVICE_CATEGORY}
    mutation createServiceCategory(
        $payload: CreateAdminCategoryServiceRequest!
    ) {
        data: createCategoryServiceForAdmin(payload: $payload) {
            data {
                ...F_SERVICE_CATEGORY
            }
        }
    }
`;

export const updateServiceCategory = gql`
    ${F_SERVICE_CATEGORY}
    mutation updateServiceCategory(
        $id: String!
        $payload: UpdateAdminCategoryServiceRequest!
    ) {
        data: updateCategoryServiceForAdmin(id: $id, payload: $payload) {
            data {
                ...F_SERVICE_CATEGORY
            }
        }
    }
`;

export const getServiceConfigurations = gql`
    ${F_SERVICE_CONFIGURATIONS}
    query getServiceConfigurations(
        $paginate: ListAdminServiceConfigurationRequest!
    ) {
        data: listServiceConfigurationForAdmin(paginate: $paginate) {
            ...F_SERVICE_CONFIGURATIONS
        }
    }
`;
export const getServiceConfiguration = gql`
    ${F_SERVICE_CONFIGURATION}
    query getServiceConfiguration($id: String!) {
        data: detailServiceConfigurationForAdmin(id: $id) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const createServiceConfiguration = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation createServiceConfiguration(
        $payload: CreateAdminServiceConfigurationRequest!
    ) {
        data: createServiceConfigurationForAdmin(payload: $payload) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const updateServiceConfiguration = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation updateServiceConfiguration(
        $id: String!
        $payload: UpdateAdminServiceConfigurationRequest!
    ) {
        data: updateServiceConfigurationForAdmin(id: $id, payload: $payload) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const updateServiceJobCost = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation updateServiceJobCost(
        $serviceConfigId: String!
        $payload: AdminJobCostRequest!
    ) {
        data: updateServiceJobCostForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const addServiceMaterialPreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation addServiceMaterialPreset(
        $serviceConfigId: String!
        $payload: [AdminMaterialPresetRequest!]!
    ) {
        data: addServiceMaterialPresetForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;
export const updateServiceMaterialPreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation updateServiceMaterialPreset(
        $serviceConfigId: String!
        $payload: AdminMaterialPresetRequest!
    ) {
        data: updateServiceMaterialPresetForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const removeServiceMaterialPreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation removeServiceMaterialPreset(
        $serviceConfigId: String!
        $material: String!
    ) {
        data: removeServiceMaterialPresetForAdmin(
            serviceConfigId: $serviceConfigId
            material: $material
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const addServiceMedicinePreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation addServiceMedicinePreset(
        $serviceConfigId: String!
        $payload: [AdminMedicinePresetRequest!]!
    ) {
        data: addServiceMedicinePresetForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;
export const updateServiceMedicinePreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation updateServiceMedicinePreset(
        $serviceConfigId: String!
        $payload: AdminMedicinePresetRequest!
    ) {
        data: updateServiceMedicinePresetForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const removeServiceMedicinePreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation removeServiceMedicinePreset(
        $serviceConfigId: String!
        $product: String!
    ) {
        data: removeServiceMedicinePresetForAdmin(
            serviceConfigId: $serviceConfigId
            product: $product
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const addServiceServicePreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation addServiceServicePreset(
        $serviceConfigId: String!
        $payload: [AdminServicePresetRequest!]!
    ) {
        data: addServiceServicePresetForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;
export const updateServiceServicePreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation updateServiceServicePreset(
        $serviceConfigId: String!
        $payload: AdminServicePresetRequest!
    ) {
        data: updateServiceServicePresetForAdmin(
            serviceConfigId: $serviceConfigId
            payload: $payload
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const removeServiceServicePreset = gql`
    ${F_SERVICE_CONFIGURATION}
    mutation removeServiceServicePreset(
        $serviceConfigId: String!
        $product: String!
    ) {
        data: removeServiceServicePresetForAdmin(
            serviceConfigId: $serviceConfigId
            product: $product
        ) {
            data {
                ...F_SERVICE_CONFIGURATION
            }
        }
    }
`;

export const getAllJobCostTemplate = gql`
    ${F_JOB_COST}
    query getAllJobCostTemplateForAdmin {
        data: getAllJobCostTemplateForAdmin {
            data {
                id
                presets {
                    ...F_JOB_COST
                }
            }
        }
    }
`;

export const ServiceConfigAPI = {
    addMaterialPreset: async (serviceConfigId: string, payload: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: addServiceMaterialPreset,
            variables: { serviceConfigId, payload },
            fetchPolicy: "no-cache",
        }),
    updateMaterialPreset: async (serviceConfigId: string, payload: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: updateServiceMaterialPreset,
            variables: { serviceConfigId, payload },
            fetchPolicy: "no-cache",
        }),
    removeMaterialPreset: async (serviceConfigId: string, material: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: removeServiceMaterialPreset,
            variables: { serviceConfigId, material },
            fetchPolicy: "no-cache",
        }),

    addMedicinePreset: async (serviceConfigId: string, payload: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: addServiceMedicinePreset,
            variables: { serviceConfigId, payload },
            fetchPolicy: "no-cache",
        }),
    updateMedicinePreset: async (serviceConfigId: string, payload: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: updateServiceMedicinePreset,
            variables: { serviceConfigId, payload },
            fetchPolicy: "no-cache",
        }),
    removeMedicinePreset: async (serviceConfigId: string, product: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: removeServiceMedicinePreset,
            variables: { serviceConfigId, product },
            fetchPolicy: "no-cache",
        }),

    addServiceAddonPreset: async (serviceConfigId: string, payload: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: addServiceServicePreset,
            variables: { serviceConfigId, payload },
            fetchPolicy: "no-cache",
        }),

    updateServiceAddonPreset: async (serviceConfigId: string, payload: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: updateServiceServicePreset,
            variables: { serviceConfigId, payload },
            fetchPolicy: "no-cache",
        }),

    removeServiceAddonPreset: async (serviceConfigId: string, product: any) =>
        API.withConverter(serviceConfigConverter).mutate({
            mutation: removeServiceServicePreset,
            variables: { serviceConfigId, product },
            fetchPolicy: "no-cache",
        }),

    getAllJobCostTemplate: async () =>
        API.withConverter(serviceConfigConverter).query({
            query: getAllJobCostTemplate,
            fetchPolicy: "no-cache",
        }),
};
