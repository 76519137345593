import { Spin } from "antd";
import moment from "moment";
import { Notifications, ObjectUtils } from "d-react-components";
import {
    find,
    forEach,
    includes,
    isEmpty,
    isUndefined,
    map,
    omit,
    omitBy,
} from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useImportContext } from "./context";
import Messages from "../../languages/Messages";

export const getImportCustomer = (
    importData: any,
    importFields: any,
    parentName?: any
) => {
    const body: any = {};
    const normalFields = omitBy(importFields, (item) =>
        [
            "is_vip",
            "referrer_sale_person",
            "referrer_agency",
            "height",
            "weight",
            "blood_group",
            "blood_pressure",
            "allergy_history",
            "under_disease",
            "surgery_history",
        ].includes(item.name)
    );
    forEach(normalFields, (field) => {
        if (field?.fields?.length > 0) {
            body[field.key] = getImportCustomer(
                importData,
                field?.fields,
                field?.name
            );
        } else {
            const keyName = isEmpty(parentName)
                ? field.name
                : `${parentName}_${field.name}`;
            if (importData[keyName]) {
                if (keyName === "number_of_child") {
                    body[field.key] = parseInt(importData[keyName], 10);
                } else if (keyName === "family_information") {
                    body[field.key] = JSON.parse(importData[keyName])?.map(
                        (item: any) => ({
                            ...omit(item, ["date_of_birth"]),
                            dateOfBirth: moment(
                                item.date_of_birth,
                                "DD-MM-YYYY"
                            ).toDate(),
                        })
                    );
                } else if (keyName === "emergency_contact") {
                    body[field.key] = JSON.parse(importData[keyName]);
                } else if (
                    keyName === "birth_day" ||
                    keyName === "passport_expired"
                ) {
                    body[field.key] = moment(
                        importData[keyName],
                        "DD-MM-YYYY"
                    ).toDate();
                } else {
                    body[field.key] = importData[keyName];
                }
            }
        }
    });
    if (!isUndefined(importData.is_vip)) {
        body.setting = {
            isVip: importData.is_vip,
        };
    }
    if (
        !isUndefined(importData.referrer_sale_person) ||
        !isUndefined(importData.referrer_agency)
    ) {
        body.referrer = {
            salePerson: importData.referrer_sale_person,
            agency: importData.referrer_agency,
        };
    }
    if (
        !isUndefined(importData.height) ||
        !isUndefined(importData.weight) ||
        !isUndefined(importData.blood_group) ||
        !isUndefined(importData.blood_pressure) ||
        !isUndefined(importData.allergy_history) ||
        !isUndefined(importData.under_disease) ||
        !isUndefined(importData.surgery_history)
    ) {
        body.medicalProfile = {
            height: parseFloat(importData.height),
            weight: parseFloat(importData.weight),
            bloodGroup: importData.blood_group,
            bloodPressure: importData.blood_pressure,
            allergyHis: importData.allergy_history,
            underDisease: importData.under_disease,
            surgeryHis: JSON.parse(importData.surgery_history)?.map(
                (item: any) => ({
                    ...item,
                    date: moment(item.date, "DD-MM-YYYY").toDate(),
                })
            ),
        };
    }
    return body;
};

const FileValidationAndImport = () => {
    const { formImport, importData } = useImportContext();
    const [validateLoading, setValidateLoading] = useState(true);
    const { apiValidate } = importData;
    const { importType, importFields, fileImport } = formImport.values;

    useEffect(() => {
        try {
            onValidateImportDatabase(fileImport?.data);
        } catch (err) {
            setValidateLoading(false);
        }
    }, []);

    const onValidateImportDatabase = async (importData: any) => {
        const mulCodeList = ObjectUtils.sliceArrayToMui(importData, 300);
        const errors: any[] = [];
        const promisesList: any[] = [];

        const getValidateUserAPI = async (importData: any) => {
            return new Promise((resolve, reject) => {
                const body = {
                    customers: map(importData, (data) =>
                        getImportCustomer(data, importFields)
                    ),
                };
                apiValidate({
                    typeOfImport: importType,
                    input: body,
                }).then(
                    (res: any) => {
                        const validation = res?.data?.data?.errors ?? [];
                        errors.push(...validation);
                        resolve(validation);
                    },
                    (err: any) => {
                        Notifications.showError(err);
                        reject(err);
                    }
                );
            });
        };

        forEach(mulCodeList, (chunk) => {
            promisesList.push(() => getValidateUserAPI(chunk));
        });

        const runSerial = (promises: any) => {
            let result = Promise.resolve();
            promises.forEach((promise: any) => {
                result = result.then(
                    () => typeof promise === "function" && promise()
                );
            });
            return result;
        };
        await runSerial(promisesList);
        formImport.setFieldValue("fileImport", { ...fileImport, errors });
        setValidateLoading(false);
    };

    const errorData = useMemo(() => {
        const header = ["Branch", "Customer No", "Messages"];
        const dataResult = [header];
        forEach(fileImport?.errors, (validation) => {
            dataResult.push([
                validation.branchId,
                validation.customerNo,
                validation.messages.join(", "),
            ]);
        });
        return dataResult;
    }, [fileImport?.errors]);

    const getSuccessItemsData = () => {
        const fieldList = Object.keys(fileImport?.data[0]);
        const header = [...fieldList, "Message"];
        const dataResult = [header];
        forEach(fileImport.data, (user) => {
            const isErrorPro = includes(
                map(fileImport?.errors, (item) => item.code),
                user.username
            );
            if (!isErrorPro) {
                dataResult.push([
                    ...map(fieldList, (item) => user[item]),
                    "success",
                ]);
            }
        });
        return dataResult;
    };

    const renderRowInfo = (title: any, content: any) => (
        <div className="d-flex mt-3">
            <text className="font-weight-bold">{`${title}: `}</text>
            <text className="ml-1">{content}</text>
        </div>
    );

    const renderValidateError = () => {
        if (fileImport?.errors?.length > 0) {
            return (
                <div className="d-flex ">
                    <text className="text-error">
                        {fileImport?.errors?.length} {Messages.errors}
                    </text>
                    <CSVLink data={errorData} filename="invalid-import-sku.csv">
                        <div className="ml-1 text-primary">
                            {Messages.downloadLog}
                        </div>
                    </CSVLink>
                </div>
            );
        }
        return <text className="text-success">{Messages.readyToImport}</text>;
    };

    const renderValidateSuccess = () => {
        if (fileImport?.errors?.length) {
            return null;
        }
        return (
            <div className="d-flex ">
                <text className="text-success">{Messages.readyToImport}</text>
            </div>
        );
    };

    if (validateLoading) {
        return (
            <div className="flex-center p-5">
                <Spin />
            </div>
        );
    }
    return (
        <div className="p-4">
            <div
                className="d-flex flex-column p-4"
                style={{ backgroundColor: "rgba(33, 150, 243, 0.1)" }}
            >
                {renderRowInfo(Messages.fileName, fileImport?.name)}
                {fileImport?.errors?.length > 0 &&
                    renderRowInfo(
                        Messages.validateStatus,
                        renderValidateError()
                    )}
                {fileImport?.errors?.length === 0 &&
                    renderRowInfo(
                        Messages.validateStatus,
                        renderValidateSuccess()
                    )}
            </div>
            <div className="mt-5">
                {renderRowInfo(
                    `*${Messages.lastWarning}`,
                    Messages.pleaseCheckCarefully
                )}
            </div>
        </div>
    );
};

export default FileValidationAndImport;
