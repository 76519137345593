import React from "react";
import { EquipmentStockAdjustmentDto } from "../api/hooks";

interface IEquipmentStockAdjustmentDetailState {
    stockAdjustment: EquipmentStockAdjustmentDto;
    setStockAdjustment: (values: any) => any;
}

const equipmentStockAdjustmentDetailState: IEquipmentStockAdjustmentDetailState =
    {
        stockAdjustment: {} as any,
        setStockAdjustment: () => {},
    };

export const EquipmentStockAdjustmentDetailContext = React.createContext(
    equipmentStockAdjustmentDetailState
);
