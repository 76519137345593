import { PriceSetType } from "../api/hooks";

export const PRODUCT_CATEGORY_STATUSES = [
    { id: "DISABLED", label: "disabled", color: "#C4C4C4" },
    { id: "ENABLED", label: "enabled", color: "#33B950" },
];

export const PRODUCT_CATEGORY_COMMISSION_TYPES = [
    { id: "ANTI_AGING", label: "antiAging" },
    { id: "ALL_TREATMENT", label: "allTreatment" },
    { id: "PRODUCT", label: "product" },
    { id: "HAIR_VITAMINS", label: "hairVitamins" },
];

export const PRODUCT_VISIBILITIES = [
    { id: true, label: "yes" },
    { id: false, label: "no" },
];

export const PRODUCT_TYPE = {
    SIMPLE_PRODUCT: "SIMPLE_PRODUCT",
    GROUP_PRODUCT: "GROUP_PRODUCT",
    SERVICE_PRODUCT: "SERVICE_PRODUCT",
    PREMIUM_SERVICE: "PREMIUM_SERVICE",
    SPECIAL_GROUP_SERVICE: "SPECIAL_GROUP_SERVICE",
    BUNDLE_PRODUCT: "BUNDLE_PRODUCT",
};

export const PRODUCT_TYPES = [
    {
        id: PRODUCT_TYPE.SIMPLE_PRODUCT,
        label: "simpleProduct",
        title: "thisIsSimpleProduct",
    },
    {
        id: PRODUCT_TYPE.SERVICE_PRODUCT,
        label: "service",
        title: "thisIsService",
    },
    {
        id: PRODUCT_TYPE.PREMIUM_SERVICE,
        label: "premiumServiceProduct",
        title: "thisIsPremiumService",
        // tooltip: "tooltipPremiumService",
    },
    {
        id: PRODUCT_TYPE.GROUP_PRODUCT,
        label: "groupProduct",
        title: "thisIsGroupProduct",
        // tooltip: "tooltipGroupProduct",
    },
    {
        id: PRODUCT_TYPE.SPECIAL_GROUP_SERVICE,
        label: "specialGroupProduct",
        title: "thisIsSpecialGroupProduct",
    },
    {
        id: PRODUCT_TYPE.BUNDLE_PRODUCT,
        label: "bundleProduct",
        title: "thisIsBundleProduct",
    },
];

export const PREMIUM_SERVICE_TYPES = [
    { id: "SINGLE_USE", label: "singleUse" },
    { id: "MULTI_USE", label: "multipleUse" },
];

export const PriceSetTypeFe = {
    Thai: "THAI",
    Employee: PriceSetType.Employee,
    Foreigner: PriceSetType.Foreigner,
    Vip: PriceSetType.Vip,
};

export const SPECIAL_PRICE_SETS = [
    PriceSetTypeFe.Employee,
    PriceSetTypeFe.Foreigner,
    PriceSetTypeFe.Vip,
];

export const PRICE_SET_TYPES = [
    {
        id: PriceSetType.Default,
        label: "thaiNationality",
    },
    {
        id: PriceSetType.Employee,
        label: "employee",
    },
    {
        id: PriceSetType.Foreigner,
        label: "foreigner",
    },
    {
        id: PriceSetType.Vip,
        label: "vip",
    },
];
