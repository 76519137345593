import { gql } from "@apollo/client";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_MATERIAL, F_MATERIAL_BASIC } from "./material";
import { F_ENUM } from "./common";

export const F_MATERIAL_INVENTORY = gql`
    ${F_ENUM}
    fragment F_MATERIAL_INVENTORY on AdminMaterialWithStock {
        id
        name
        materialNo
        sku
        status
        purchaseUnit {
            ...F_ENUM
        }
        trackingUnit {
            ...F_ENUM
        }
        purchaseValue
        trackingValue
        gallery
        avgPurchasingUnitCost
        avgTrackingUnitCost
        lastPurchasingUnitCost
        lastTrackingUnitCost
        hold
        quantity
        currentQuantity
        currentHold
        totalQuantityPurchase
        totalQuantityOnPurchase
        description
    }
`;

export const F_MATERIAL_INVENTORY_HISTORY = gql`
    ${F_BRANCH_BASIC}
    ${F_ADMIN_USER_BASIC}

    fragment F_MATERIAL_INVENTORY_HISTORY on AdminMaterialStockHistory {
        id
        updatedAt
        createdAt
        code
        quantity
        hold
        refId
        refType
        branch {
            ...F_BRANCH_BASIC
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }

        adjustment {
            id
            adjustmentNo
        }
        treatment {
            treatmentNo
        }
        purchase {
            id
            updatedAt
            createdAt
            materialPurchaseNo
            status
            materialPurchaseOrderStatus
            subtotal
            total
        }
    }
`;

export const F_MATERIAL_INVENTORIES = gql`
    ${F_MATERIAL_INVENTORY}
    ${F_PAGINATE}
    fragment F_MATERIAL_INVENTORIES on ListAdminMaterialStockResponse {
        data {
            ...F_MATERIAL_INVENTORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_MATERIAL_INVENTORY_HISTORIES = gql`
    ${F_MATERIAL_INVENTORY_HISTORY}
    ${F_PAGINATE}
    fragment F_MATERIAL_INVENTORY_HISTORIES on ListAdminMaterialStockHistoryResponse {
        data {
            ...F_MATERIAL_INVENTORY_HISTORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_MATERIAL_ADJUST_REQUEST = gql`
    ${F_MATERIAL_BASIC}
    ${F_ADMIN_USER_BASIC}
    ${F_BRANCH_BASIC}
    fragment F_MATERIAL_ADJUST_REQUEST on AdminMaterialStockAdjustment {
        id
        updatedAt
        createdAt
        adjustmentNo
        status
        remark
        attachments
        branch {
            ...F_BRANCH_BASIC
        }
        materials {
            adjustment
            stock
            material {
                ...F_MATERIAL_BASIC
            }
        }
        confirmation {
            at
            remark
            createByAdmin {
                ...F_ADMIN_USER_BASIC
            }
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_MATERIAL_ADJUST_REQUESTS = gql`
    ${F_MATERIAL_ADJUST_REQUEST}
    ${F_PAGINATE}
    fragment F_MATERIAL_ADJUST_REQUESTS on ListAdminMaterialStockAdjustmentResponse {
        data {
            ...F_MATERIAL_ADJUST_REQUEST
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
