import React from "react";
import { useParams } from "react-router";
import ActivityView from "../../../common/activity/ActivityView";
import { RefActivity } from "../../../api/hooks";

const VendorDetailActivity = () => {
    const { vendorId } = useParams<any>();
    return (
        <div>
            {vendorId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.Vendor}
                    activityRefId={vendorId ?? ""}
                />
            )}
        </div>
    );
};

export default VendorDetailActivity;
