import { map, maxBy, minBy } from "lodash";
import { ITimeSlotValue } from "../common/TimeSlotSelect";
import { IBranch } from "./branch";
import { ICustomer, IGuest } from "./customer";
import { IProduct } from "./product";
import IUser from "./user";

export interface INote {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: IUser;
    noteNo: string;
    title: string;
    content: string;
    refId: string;
    ref: string;
}

export interface IBookingContact extends IGuest {}

export interface IBookingConfirmation {
    createByAdmin: IUser;
    createdAt: string;
    createByCustomer: IUser;
}

export interface IBooking {
    id: string;
    updatedAt: string;
    createdAt: string;

    bookingNo: string;
    status: string;
    contact: IBookingContact;
    sourceOfCustomer: ICustomer;
    doctor: IUser;
    remark: string;
    day: string;
    startDate: string;
    endDate: string;
    scheduleDate: string;
    schedule: {
        start: number;
        end: number;
    };
    branch: IBranch;
    services: IProduct[];
    customer: ICustomer;
    confirmation: IBookingConfirmation;
    cancellation: IBookingConfirmation;
    completion: IBookingConfirmation;
}

export const mapBookingFromServer = (booking: any) => {
    return {
        ...booking,
    };
};

export const mapBookingConfirmToServer = (input: any) => {
    return {
        schedule: {
            start: input.timeSlot?.start * 3600,
            end: input.timeSlot?.end * 3600,
        },
        doctor: input?.doctor?.id,
        scheduleDate: input?.date,
    };
};

export const mapBookingScheduleToServer = (timeSlots: ITimeSlotValue[]) => {
    const startMin = minBy(timeSlots, (item) => item.start);
    const endMax = maxBy(timeSlots, (item) => item.end);
    return {
        start: (startMin?.start ?? 0) * 3600,
        end: (endMax?.end ?? 0) * 3600,
    };
};

export const mapBookingToServer = (input: any) => {
    return {
        schedule: mapBookingScheduleToServer(
            input?.doctor ? input?.timeSlot ?? [] : input?.estTimeVisit ?? []
        ),
        scheduleDate: input?.date,
        branch: input?.branch?.id,
        customer: input?.isGuestVisit ? null : input?.customer?.id,
        contact: input?.isGuestVisit ? input?.guestInfo : null,
        sourceOfCustomer: input?.customerSource,
        services: map(input?.products, (item) => item.id),
        doctor: input?.doctor?.id,
        remark: input?.remark,
    };
};

export const mapBookingFilterToQueryParams = (input: any) => {
    return {
        branch: map(input?.branch, (item) => ({
            id: item.id,
            name: item.name,
        })),
        status: input?.status,
        createdBy: map(input?.createdBy, (item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        })),
        timeRange: map(input?.timeRange, (item) => item.valueOf()),
    };
};

export const mapBookingFilterToServer = (input: any) => {
    return {
        branch: map(input?.branch, (item) => item.id),
        status: input?.status,
        createdBy: map(input?.createdBy, (item) => item.id),
        estDateOfVisit: input?.timeRange,
    };
};
