/* eslint-disable prefer-destructuring */
import { InputText, ViewCollapse } from "d-react-components";
import React, { useState } from "react";
import { Switch } from "antd";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import LabelLanguage from "../../../common/label/LabelLanguage";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { PRODUCT_TYPE, PRODUCT_VISIBILITIES } from "../../../constant/product";
import Messages from "../../../languages/Messages";
import ProductCategorySelect from "../../product-category/share/ProductCategorySelect";

interface IProductGeneralInfoForm {
    productForm: any;
    variant?: "create" | "updateGeneral" | "updateDescription";
}

const ProductGeneralInfoForm = ({
    productForm,
    variant = "create",
}: IProductGeneralInfoForm) => {
    const [language, setLanguage] = useState("en");

    const formValues = productForm?.values ?? {};
    const formErrors = productForm?.errors ?? {};
    const classNameInput = "col-6 mt-3";
    const showDescription =
        variant === "create" || variant === "updateDescription";
    const showOthers = variant === "create" || variant === "updateGeneral";

    const { typeOfProduct, isOperationService, isNonVAT } = formValues || {};

    return (
        <div className="row w-fill-available">
            {showOthers && (
                <React.Fragment>
                    <div className="col-12">
                        {/* <LabelLanguage
                            label={Messages.name}
                            language={language}
                            onChange={setLanguage}
                        /> */}
                        <InputText
                            label={Messages.name}
                            value={formValues?.[language]?.name ?? ""}
                            onChange={(event) =>
                                productForm.setFieldValue(
                                    `${language}.name`,
                                    event?.target?.value
                                )
                            }
                            error={formErrors?.en?.name || formErrors?.th?.name}
                        />
                    </div>
                    <InputTextForm
                        className={classNameInput}
                        keyData="sku"
                        form={productForm}
                    />
                    <ProductCategorySelect
                        className={classNameInput}
                        value={formValues?.category}
                        onChange={(value) =>
                            productForm.setFieldValue("category", value)
                        }
                    />
                    <InputSelectForm
                        className={classNameInput}
                        keyData="brand"
                        form={productForm}
                        dataSource={[{ id: "dermaster", label: "Dermaster" }]}
                        getLabel={(item) => item?.label}
                    />
                    <InputSelectForm
                        className={classNameInput}
                        keyData="visibility"
                        form={productForm}
                        dataSource={PRODUCT_VISIBILITIES}
                    />
                    {variant === "create" && (
                        <React.Fragment>
                            <InputTextForm
                                disabled={
                                    typeOfProduct === PRODUCT_TYPE.GROUP_PRODUCT
                                }
                                className={classNameInput}
                                keyData="regularPrice"
                                form={productForm}
                                value={formValues?.regularPrice?.toLocaleString?.()}
                                onChange={(e) => {
                                    let value = e.target.value.replace(
                                        /\D+/g,
                                        ""
                                    );
                                    value = parseInt(value, 10);
                                    if (!value) {
                                        value = 0;
                                    }
                                    productForm.setFieldValue(
                                        "regularPrice",
                                        value
                                    );
                                }}
                            />
                            <InputTextForm
                                disabled={
                                    typeOfProduct === PRODUCT_TYPE.GROUP_PRODUCT
                                }
                                className={classNameInput}
                                keyData="salePrice"
                                form={productForm}
                                value={formValues?.salePrice?.toLocaleString?.()}
                                onChange={(e) => {
                                    let value = e.target.value.replace(
                                        /\D+/g,
                                        ""
                                    );
                                    value = parseInt(value, 10);
                                    if (!value) {
                                        value = 0;
                                    }
                                    productForm.setFieldValue(
                                        "salePrice",
                                        value
                                    );
                                }}
                            />
                        </React.Fragment>
                    )}

                    <InputTextForm
                        className={classNameInput}
                        keyData="weight"
                        form={productForm}
                    />
                    <InputSelectForm
                        className={classNameInput}
                        keyData="isSellOutOfStock"
                        form={productForm}
                        dataSource={PRODUCT_VISIBILITIES}
                    />
                    {variant === "updateGeneral" &&
                        typeOfProduct === PRODUCT_TYPE.SERVICE_PRODUCT && (
                            <div className="d-flex align-items-center mt-3 px-3">
                                <Switch
                                    checked={isOperationService}
                                    onChange={() =>
                                        productForm.setFieldValue(
                                            "isOperationService",
                                            !isOperationService
                                        )
                                    }
                                />
                                <text className="ml-3">
                                    {Messages.thisIsOperationProduct}
                                </text>
                            </div>
                        )}
                    <div className="d-flex align-items-center mt-3 px-3">
                        <Switch
                            checked={isNonVAT}
                            onChange={() =>
                                productForm.setFieldValue("isNonVAT", !isNonVAT)
                            }
                        />
                        <text className="ml-3">
                            {Messages.thisIsNonVatProduct}
                        </text>
                    </div>
                </React.Fragment>
            )}

            {showDescription && (
                <div className="px-3 w-full">
                    <ViewCollapse
                        label={Messages.description}
                        className="col-12 mt-3"
                    >
                        {/* <LabelLanguage
                            language={language}
                            onChange={setLanguage}
                        /> */}
                        <RichTextTiny
                            value={formValues?.[language]?.description ?? ""}
                            onChange={(value: any) =>
                                productForm.setFieldValue(
                                    `${language}.description`,
                                    value.toString("html")
                                )
                            }
                            placeholder={Messages.description}
                            size="large"
                            error={
                                formErrors?.en?.description ||
                                formErrors?.th?.description
                            }
                        />
                    </ViewCollapse>
                </div>
            )}
        </div>
    );
};

export default ProductGeneralInfoForm;
