import { useFormik } from "formik";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";
import { mapTreatmentFilterToQueryParams } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import TreatmentFilterForm from "../share/TreatmentFilterForm";

interface ITreatmentTableFilterDrawerProps {
    open: boolean;
    onClose: () => void;
}

const TreatmentTableFilterDrawer = ({
    open,
    onClose,
}: ITreatmentTableFilterDrawerProps) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const params = mapTreatmentFilterToQueryParams(values);
            setFilter(params);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            size="x-large"
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
            cancelText={Messages.clearAll}
            cancelButtonProps={{
                color: "error",
                onClick: () => {
                    filterForm.setValues({});
                },
            }}
        >
            <TreatmentFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default TreatmentTableFilterDrawer;
