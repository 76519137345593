import { gql } from "@apollo/client";
import { map } from "lodash";
import { mapScheduleWorkingTimeFromServer } from "../../interfaces/temporary-schedule";
import API from "../API";
import {
    F_TEMPORARY_SCHEDULE,
    F_TEMPORARY_SCHEDULES,
    F_USER_TEMPORARY_SCHEDULES,
} from "../fragments/temporary-schedule";

export const getUserTemporarySchedule = gql`
    ${F_USER_TEMPORARY_SCHEDULES}
    query listUserHasTemporaryScheduleForAdmin(
        $paginate: TemporarySchedulePaginateRequest!
    ) {
        data: listUserHasTemporaryScheduleForAdmin(paginate: $paginate) {
            ...F_USER_TEMPORARY_SCHEDULES
        }
    }
`;

export const createTemporarySchedule = gql`
    ${F_TEMPORARY_SCHEDULE}
    mutation createUserTemporaryScheduleForAdmin(
        $userId: String!
        $inputs: [TemporaryScheduleCreateRequest!]!
    ) {
        data: createUserTemporaryScheduleForAdmin(
            userId: $userId
            inputs: $inputs
        ) {
            data {
                ...F_TEMPORARY_SCHEDULE
            }
        }
    }
`;

export const updateTemporarySchedule = gql`
    ${F_TEMPORARY_SCHEDULE}
    mutation updateUserTemporaryScheduleForAdmin(
        $id: String!
        $payload: TemporaryScheduleUpdateRequest!
    ) {
        data: updateUserTemporaryScheduleForAdmin(id: $id, payload: $payload) {
            data {
                ...F_TEMPORARY_SCHEDULE
            }
        }
    }
`;

export const removeTemporarySchedule = gql`
    ${F_TEMPORARY_SCHEDULE}
    mutation removeUserTemporaryScheduleForAdmin($id: String!) {
        data: removeUserTemporaryScheduleForAdmin(id: $id) {
            data {
                ...F_TEMPORARY_SCHEDULE
            }
        }
    }
`;

export const getTemporarySchedules = gql`
    ${F_TEMPORARY_SCHEDULES}
    query listTemporaryScheduleForAdmin(
        $userId: String!
        $paginate: TemporarySchedulePaginateRequest!
    ) {
        data: listTemporaryScheduleForAdmin(
            userId: $userId
            paginate: $paginate
        ) {
            ...F_TEMPORARY_SCHEDULES
        }
    }
`;

const temporarySchedulesConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        const result = map(list, mapScheduleWorkingTimeFromServer);
        return { data: result, pagination: res?.data?.data?.pagination };
    },
};

const TemporaryScheduleAPI = {
    userTemporarySchedules: async (paginate: any) =>
        API.query({
            query: getUserTemporarySchedule,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    temporarySchedules: async (userId: string, paginate: any) =>
        API.withConverter(temporarySchedulesConverter).query({
            query: getTemporarySchedules,
            variables: { userId, paginate },
            fetchPolicy: "no-cache",
        }),

    createSchedule: async (input: any) =>
        API.mutate({
            mutation: createTemporarySchedule,
            variables: input,
        }),

    updateSchedule: async (id: string, payload: any) =>
        API.mutate({
            mutation: updateTemporarySchedule,
            variables: { id, payload },
        }),

    removeSchedule: async (id: string) =>
        API.mutate({
            mutation: removeTemporarySchedule,
            variables: { id },
        }),
};

export default TemporaryScheduleAPI;
