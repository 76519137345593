import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce, filter, includes, isEmpty, toLower } from "lodash";
import { useContext, useMemo, useState } from "react";
import TreatmentAPI from "../../../api/queries/treatment";
import Drawer from "../../../common/Drawer";
import { TreatmentDetailContext } from "../../../context/treatment";
import {
    ITreatmentEquipment,
    mapTreatmentEquipmentToServer,
} from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";
import EquipmentNameView from "../../equipment/share/EquipmentNameView";
import TreatmentEquipmentSelect from "../share/TreatmentEquipmentSelect";

interface ITreatmentEquipmentAddDrawer {
    open: boolean;
    onClose: () => void;
}

const TreatmentEquipmentAddDrawer = ({
    open,
    onClose,
}: ITreatmentEquipmentAddDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);

    const equipmentsForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapTreatmentEquipmentToServer(values);
            onAddEquipmentTreatment(input);
        },
    });

    const onAddEquipmentTreatment = (input: any) => {
        const body = {
            treatmentId: treatment?.id,
            ...input,
        };

        Progress.show(
            {
                method: TreatmentAPI.addEquipment,
                params: [body],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.equipment}
            onSave={() => equipmentsForm.handleSubmit()}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <TreatmentEquipmentSelect
                    className="mt-3"
                    branch={treatment?.branch}
                    date={treatment?.scheduleDate}
                    form={equipmentsForm}
                />
            </div>
        </Drawer>
    );
};

const TreatmentDetailEquipment = () => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [openAdd, setOpenAdd] = useState(false);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (equipment: ITreatmentEquipment) => (
                <EquipmentNameView equipment={equipment?.equipment} />
            ),
        },
        {
            title: Messages.bookedSchedule,
            dataIndex: "schedule",
            render: (schedule: ITreatmentEquipment["schedule"]) =>
                `${fmtMSS(schedule?.start)} - ${fmtMSS(schedule?.end)}`,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (equipment) => (
                <Icon
                    name="remove_circle"
                    className="cursor-pointer"
                    onClick={() => onRemove(equipment)}
                />
            ),
        },
    ];

    const onRemove = (equipment: ITreatmentEquipment) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: TreatmentAPI.removeEquipment,
                        params: [treatment?.id, equipment.equipment?.id],
                    },
                    (treatmentRes) => {
                        setTreatment(treatmentRes);
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const equipmentDataSource = useMemo(() => {
        if (isEmpty(textSearch)) {
            return treatment?.equipments ?? [];
        }

        return filter(treatment?.equipments ?? [], (equipment) =>
            includes(toLower(equipment?.equipment?.name), toLower(textSearch))
        );
    }, [textSearch, treatment?.equipments]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.equipment}</label>
                <Button onClick={() => setOpenAdd(true)} className="">
                    {Messages.add}
                </Button>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />

            <AwesomeTableComponent
                columns={columns}
                dataSource={equipmentDataSource}
                className="mt-3"
                pagination={false}
            />
            {openAdd && (
                <TreatmentEquipmentAddDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}
        </div>
    );
};

export default TreatmentDetailEquipment;
