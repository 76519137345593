import { gql } from "@apollo/client";
import {
    F_VENDOR,
    F_VENDORS,
    F_VENDOR_PURCHASES,
    F_VENDOR_SEARCH,
} from "../fragments/vendor";
import API from "../API";

const vendorDetailConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

export const searchVendor = gql`
    ${F_VENDOR_SEARCH}
    query searchVendor($paginate: SearchAdminVendorRequest!) {
        data: searchVendorForAdmin(paginate: $paginate) {
            data {
                ...F_VENDOR_SEARCH
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const listVendor = gql`
    ${F_VENDORS}
    query listVendor($paginate: ListAdminVendorRequest!) {
        data: listVendorForAdmin(paginate: $paginate) {
            ...F_VENDORS
        }
    }
`;

export const detailVendor = gql`
    ${F_VENDOR}
    query detailVendorForAdmin($id: String!) {
        data: detailVendorForAdmin(id: $id) {
            data {
                ...F_VENDOR
            }
        }
    }
`;

export const listPurchaseVendor = gql`
    ${F_VENDOR_PURCHASES}
    query listPurchaseVendor(
        $id: String!
        $paginate: SearchAdminMaterialPurchaseRequest!
    ) {
        data: listPurchaseOfVendorForAdmin(id: $id, paginate: $paginate) {
            ...F_VENDOR_PURCHASES
        }
    }
`;

export const createVendor = gql`
    mutation createVendorForAdmin($payload: CreateAdminVendorRequest!) {
        data: createVendorForAdmin(payload: $payload) {
            data {
                ...F_VENDOR
            }
        }
    }
    ${F_VENDOR}
`;

export const updateVendor = gql`
    mutation updateVendor($id: String!, $payload: UpdateAdminVendorRequest!) {
        data: updateVendorForAdmin(id: $id, payload: $payload) {
            data {
                ...F_VENDOR
            }
        }
    }
    ${F_VENDOR}
`;

const VendorAPI = {
    list: async (paginate: any) =>
        API.query({
            query: listVendor,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    listPurchase: async (id: string, paginate: any) =>
        API.query({
            query: listPurchaseVendor,
            variables: { id, paginate },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.mutate({
            mutation: createVendor,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    update: async (id: string, payload: any) =>
        API.mutate({
            mutation: updateVendor,
            variables: { id, payload },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: string) =>
        API.withConverter(vendorDetailConverter).mutate({
            mutation: detailVendor,
            variables: { id },
            fetchPolicy: "no-cache",
        }),
};

export default VendorAPI;
