import classNames from "classnames";
import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    InputTextSearch,
    Notifications,
    ObjectUtils,
    Progress,
    useFirstTime,
} from "d-react-components";
import {
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    map,
    toLower,
} from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ProductAPI from "../../../api/queries/product";
import TreatmentAPI from "../../../api/queries/treatment";
import Drawer from "../../../common/Drawer";
import { TREATMENT_STATUS } from "../../../constant/treatment";
import { TreatmentDetailContext } from "../../../context/treatment";
import { IProduct } from "../../../interfaces/product";
import { ITreatmentProduct } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/share/ProductNameView";
import QuantityInputField from "../../quotation/share/QuantityInputField";

interface IMedicineItem extends IProduct {
    quantity: number;
    provideAfterTreatment: boolean;
}

interface ITreatmentMedicineAddDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IMedicineItem[];
}

interface ITreatmentMedicineUpdateDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IMedicineItem;
}

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

const TreatmentMedicineAddDrawer = ({
    open,
    onClose,
    defaultValue = [],
}: ITreatmentMedicineAddDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [medicineSelected, setMedicineSelected] =
        useState<IMedicineItem[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onAddMedicineTreatment = () => {
        const medicineFiltered = filter(
            medicineSelected,
            (item) => !!item.quantity
        );
        const products = map(medicineFiltered, (item) => ({
            product: item.id,
            quantity: item.quantity,
            provideAfterTreatment: !!item.provideAfterTreatment,
        }));

        Progress.show(
            {
                method: TreatmentAPI.addMedicine,
                params: [treatment?.id, products],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.quantity,
            dataIndex: "",
            render: (product: any) => {
                const productSelected = find(
                    medicineSelected,
                    (item) => item.id === product.id
                );
                if (!productSelected) return <div />;
                return (
                    <QuantityInputField
                        quantity={productSelected?.quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity(quantity, product)
                        }
                        min={1}
                        disabled={productSelected?.provideAfterTreatment}
                    />
                );
            },
        },
    ];

    const onChangeQuantity = (quantity: number, product: IProduct) => {
        const result = ObjectUtils.updateArrayById(medicineSelected as any, {
            ...product,
            quantity,
        });
        setMedicineSelected(result);
    };

    const source = (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: medicineSelected });
        }
        return ProductAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            isMedicine: true,
            sort: {
                createdAt: -1,
            },
        });
    };

    const onChangeSelectProduct = (keys: string[], products: IProduct[]) => {
        const allProducts = filter(
            [...medicineSelected, ...products],
            (item) => !!item
        ).map((item: any) => ({ ...item, quantity: item?.quantity ?? 1 }));

        const result = map(keys, (id) =>
            find(allProducts, (item) => item.id === id)
        ).filter((item) => !!item);

        setMedicineSelected(result);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.medicine}
            onSave={onAddMedicineTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item) => (
                        <Button
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => {
                        return res?.data ?? [];
                    }}
                    rowSelection={{
                        selectedRowKeys: map(
                            medicineSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectProduct as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

const TreatmentMedicineUpdateDrawer = ({
    open,
    onClose,
    defaultValue,
}: ITreatmentMedicineUpdateDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [medicine, setMedicine] = useState<IMedicineItem>(defaultValue);

    const onUpdateMedicineTreatment = () => {
        const input = { product: medicine?.id, quantity: medicine?.quantity };
        Progress.show(
            {
                method: TreatmentAPI.updateMedicine,
                params: [treatment?.id, input],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity: any) => {
                return (
                    <QuantityInputField
                        quantity={quantity}
                        onChangeQuantity={(quantity: number) =>
                            setMedicine({ ...medicine, quantity })
                        }
                        min={1}
                    />
                );
            },
        },
    ];

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.medicine}
            onSave={onUpdateMedicineTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <AwesomeTableComponent
                    className="mt-3"
                    columns={columns}
                    dataSource={[medicine]}
                    pagination={false}
                />
            </div>
        </Drawer>
    );
};

const TreatmentDetailMedicine = () => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [openAdd, setOpenAdd] = useState(false);
    const [updatingMedicine, setUpdatingMedicine] =
        useState<IMedicineItem | null>(null);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
        {
            title: Messages.unit,
            dataIndex: "product",
            render: (product: IProduct) => product?.unit,
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, item) =>
                item.provideAfterTreatment
                    ? Messages.provideAfterTreatment
                    : quantity,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (medicine) => {
                const editable = getEditableMaterial(medicine);
                const deletable =
                    treatment?.doctorOrder?.status !== "COMPLETED" &&
                    medicine.hasPermissionDelete;

                return (
                    <div className="d-flex">
                        <Button
                            variant="trans"
                            iconName="edit"
                            className={classNames(
                                "cursor-pointer text-primary",
                                {
                                    "text-disabled": !editable,
                                }
                            )}
                            onClick={() =>
                                setUpdatingMedicine({
                                    ...medicine.product,
                                    quantity: medicine?.quantity,
                                })
                            }
                            disabled={!editable}
                        />
                        <Button
                            variant="trans"
                            iconName="remove_circle_outline"
                            className={classNames(
                                "cursor-pointer text-primary",
                                {
                                    "text-disabled": !deletable,
                                }
                            )}
                            onClick={() => onRemove(medicine)}
                            disabled={!deletable}
                        />
                    </div>
                );
            },
        },
    ];

    const getEditableMaterial = (medicine: any) => {
        if (treatment?.doctorOrder?.status === "COMPLETED") {
            return false;
        }

        if (medicine?.isPreset) {
            return true;
        }

        if (medicine?.hasPermissionAdjustment) {
            return treatment?.status === TREATMENT_STATUS.COMPLETED;
        }

        return medicine?.hasPermissionAdjustment;
    };

    const onRemove = (medicine: ITreatmentProduct) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: TreatmentAPI.removeMedicine,
                        params: [treatment?.id, medicine.product?.id],
                    },
                    (treatmentRes) => {
                        setTreatment(treatmentRes);
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const medicineDataSource = useMemo(() => {
        if (isEmpty(textSearch)) {
            return treatment?.products ?? [];
        }

        return filter(treatment?.products ?? [], (user) =>
            includes(
                toLower(user?.product?.name + user?.product?.sku),
                toLower(textSearch)
            )
        );
    }, [textSearch, treatment?.products]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.medicine}</label>
                <Button
                    onClick={() => setOpenAdd(true)}
                    className=""
                    disabled={treatment?.doctorOrder?.status === "COMPLETED"}
                >
                    {Messages.add}
                </Button>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={medicineDataSource}
                className="mt-3"
                pagination={false}
            />
            {openAdd && (
                <TreatmentMedicineAddDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    defaultValue={map(treatment?.products, (item) => ({
                        ...item.product,
                        quantity: item.quantity,
                        provideAfterTreatment: item.provideAfterTreatment,
                    }))}
                />
            )}
            {updatingMedicine && (
                <TreatmentMedicineUpdateDrawer
                    defaultValue={updatingMedicine}
                    open={!!updatingMedicine}
                    onClose={() => setUpdatingMedicine(null)}
                />
            )}
        </div>
    );
};

export default TreatmentDetailMedicine;
