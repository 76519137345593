import { DiscountType, SourceOfCustomer } from "../api/hooks";
import { IBillingAddress } from "./address";
import { IBranch } from "./branch";
import { ICredit } from "./credit";
import { ICustomer } from "./customer";
import { IDoctorOrder } from "./doctor-order";
import { IMaterial } from "./material";
import { IProduct, IProductOrder } from "./product";
import { IQuotation } from "./quotation";
import IUser from "./user";
import { IVoucher } from "./voucher";

export interface IOrderManualDiscount {
    discountType?: DiscountType;
    discountValue?: string;
    product?: IProductOrder;
    isWholeCart?: boolean;
    doctorOrder?: IDoctorOrder;
    total?: number;
    material?: IMaterial;
}

export interface IOrder {
    id: string;
    orderNo: string;
    status: string;
    typeOfTreatment: string;
    purchaseType: string;
    remark?: any;
    subtotal: number;
    total: number;
    totalVat: number;
    createdAt: Date;
    updatedAt: Date;
    expired: string;
    isSameShippingAddress: boolean;
    hasAutoGenerateVoucher: boolean;
    customer?: ICustomer;
    contact?: any;
    createByAdmin: IUser;
    salesPerson: IUser;
    store: IBranch;
    fee: any[];
    __typename: string;
    products: IProduct[];
    manualProduct: any[];
    billing?: IBillingAddress;
    doctorOrder?: IDoctorOrder[];
    quotation?: IQuotation[];
    voucher?: { voucher: IVoucher }[];
    manualDiscount?: IOrderManualDiscount[];
    sourceOfCustomer?: SourceOfCustomer;
    credits?: { credit: ICredit; amount: number }[];
}

export enum OrderType {
    QUOTATION = "QUOTATION",
    ORDER = "ORDER",
}

export interface CartValueState {
    subtotal: number;
    total: number;
    voucher?: {
        voucher: IVoucher;
        amount: number;
        doctorOrder: IDoctorOrder;
        product: IProduct;
        quantity: 1;
    }[];
    credits?: {
        credit: any;
        amount: number;
        doctorOrder: IDoctorOrder;
        product: IProduct;
        quantity: 1;
    }[];
    manualDiscount?: {
        discountType: DiscountType;
        discountValue: number;
        total: number;
        doctorOrder: IDoctorOrder;
        product: IProduct;
        material: IMaterial;
    }[];
}
