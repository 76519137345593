import { Button, Modal, Progress, ViewTextError } from "d-react-components";
import { useFormik } from "formik";
import React, { Fragment, useContext, useState } from "react";
import AuthCode from "react-auth-code-input";
import { CustomerDetailContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import { AuthOtpSchema } from "../../../formschema/auth";
import CustomerAPI from "../../../api/queries/customer";

export interface ICustomerDetailVerifyPhoneProps {
    [key: string]: any;
}

const CustomerDetailVerifyPhone: React.FC<ICustomerDetailVerifyPhoneProps> = ({
    id,
}) => {
    const { customer, loadCustomerDetail } = useContext(CustomerDetailContext);
    const {
        phone,
        email,
        status,
        phoneNumberStatus,
        id: customerId,
        phoneCodeCountry,
    } = customer;
    const [openVerifyModal, setOpenVerifyModal] = useState<{
        open: boolean;
        mode?: "sendOtp" | "inputOtp";
        otpToken?: string;
    }>({
        open: false,
    });
    const formik = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: AuthOtpSchema,
        onSubmit: (values) => {
            onSubmitHandler && onSubmitHandler(values?.code);
        },
    });

    const onRequestOtp = () => {
        return Progress.show(
            {
                method: CustomerAPI.requestOtp,
                params: [phone],
            },
            (res: any) => {
                const { otpToken } = res?.data?.data ?? {};
                setOpenVerifyModal({
                    ...openVerifyModal,
                    otpToken,
                    mode: "inputOtp",
                });
            }
        );
    };

    const renderSendOtp = () => {
        return (
            <div className="w-100 flex flex-col items-center justify-center">
                <div
                    dangerouslySetInnerHTML={{
                        __html: Messages.verifyPhoneNumberDescription?.replace?.(
                            "@phone",
                            `<span class="text-red-500">
                            (${phoneCodeCountry?.phoneCode ?? "N/A"}) ${phone}
                        </span>`
                        ),
                    }}
                />
                <Button
                    className="mt-3"
                    onClick={() => {
                        onRequestOtp();
                    }}
                >
                    {Messages.sendOtp}
                </Button>
            </div>
        );
    };

    const renderInputOtp = () => {
        return (
            <div className="w-100 flex flex-col items-center justify-center">
                <div
                    dangerouslySetInnerHTML={{
                        __html: Messages.inputOtpCodeDescription?.replace?.(
                            "@phone",
                            `<span class="text-red-500">
                        (${phoneCodeCountry?.phoneCode ?? "N/A"}) ${phone}
                        </span>`
                        ),
                    }}
                />
                <AuthCode
                    onChange={(code) => {
                        formik.setFieldValue("code", code);
                    }}
                    containerClassName="auth-code__container mt-3"
                    inputClassName="auth-code__input my-3"
                />
                {formik?.errors?.code && (
                    <ViewTextError error={formik?.errors?.code} />
                )}
            </div>
        );
    };

    const onSubmitHandler = (code: string) => {
        return Progress.show(
            {
                method: CustomerAPI.verifyOtp,
                params: [
                    {
                        otpToken: openVerifyModal?.otpToken,
                        otpCode: code,
                        customerId,
                    },
                ],
            },
            (res) => {
                loadCustomerDetail && loadCustomerDetail();
            }
        );
    };

    return (
        <Fragment>
            <Button
                className="ml-3"
                size="x-small"
                onClick={() =>
                    setOpenVerifyModal({ open: true, mode: "sendOtp" })
                }
            >
                {Messages.verify}
            </Button>
            {openVerifyModal.open && (
                <Modal
                    saveText={Messages.verify}
                    disabledSaveButton={
                        !openVerifyModal?.otpToken || !formik?.values?.code
                    }
                    title={Messages.phoneNumberVerification}
                    open={openVerifyModal?.open}
                    onClose={() => setOpenVerifyModal({ open: false })}
                    onSave={() => formik?.handleSubmit()}
                >
                    {openVerifyModal?.mode === "sendOtp" && renderSendOtp()}
                    {openVerifyModal?.mode === "inputOtp" && renderInputOtp()}
                </Modal>
            )}
        </Fragment>
    );
};

export default CustomerDetailVerifyPhone;
