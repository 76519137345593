import { Button, Notifications, Progress } from "d-react-components";
import { map } from "lodash";
import { useContext, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import ProductAPI from "../../../api/queries/product";
import Drawer from "../../../common/Drawer";
import Image from "../../../common/Image";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";
import ProductGalleryInput from "../share/ProductGalleryInput";

interface IProductGalleryModal {
    open: boolean;
    onClose: () => void;
}

const ProductGalleryModal = ({ open, onClose }: IProductGalleryModal) => {
    const { product, setProduct } = useContext(ProductDetailContext);
    const [galleryList, setGalleryList] = useState(
        product?.productGalleries ?? []
    );

    const onUpdateGallery = () => {
        const gallery = map(galleryList, (item) => item.fileUrl);
        Progress.show(
            {
                method: ProductAPI.update,
                params: [product?.id, { gallery }],
            },
            (product: any) => {
                setProduct(product);
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            onSave={() => onUpdateGallery()}
            title={Messages.gallery}
            width={1000}
        >
            <div className="p-3">
                <ProductGalleryInput
                    value={galleryList}
                    onChange={setGalleryList}
                />
            </div>
        </Drawer>
    );
};

const ProductDetailGallery = () => {
    const { product } = useContext(ProductDetailContext);
    const [openEdit, setOpenEdit] = useState(false);
    const [indexShowImage, setIndexShowImage] = useState<number | null>(null);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.gallery}</label>

                <Button variant="trans" onClick={() => setOpenEdit(true)}>
                    {Messages.edit}
                </Button>
            </div>
            <div className="d-flex flex-wrap border p-3">
                {map(product?.gallery, (url, index) => (
                    <Image
                        src={url}
                        className="mr-3 image-square-medium"
                        onClick={() => setIndexShowImage(index)}
                    />
                ))}
            </div>
            {openEdit && (
                <ProductGalleryModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
            <ModalGateway>
                {indexShowImage || indexShowImage === 0 ? (
                    <Modal onClose={() => setIndexShowImage(null)}>
                        <Carousel
                            views={map(product?.gallery, (file) => ({
                                source: file,
                                caption: "",
                            }))}
                            currentIndex={indexShowImage}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
};

export default ProductDetailGallery;
