import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import React, { useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useListQuotationForAdminLazyQuery } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { QUOTATION_STATUSES } from "../../../constant/quotation";
import Messages from "../../../languages/Messages";
import CustomerName from "../../../common/avatar/CustomerName";

const OrderQuotationRef = ({ open, onClose, onSave }: any) => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [getListQuotation, { data }] = useListQuotationForAdminLazyQuery();
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "quotationNo",
            width: 100,
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "store",
            render: (item) => item.name,
        },
        {
            title: Messages.amount,
            dataIndex: "total",
            render: (amount) => (
                <CurrencyFormat
                    value={amount}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            width: "100px",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={QUOTATION_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (user, item) => (
                <CustomerName user={item.contact || item.customer} />
            ),
            width: "150px",
        },
        {
            title: Messages.staff,
            dataIndex: "createByAdmin",
            render: (user, item) => <CustomerName user={user} />,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        return getListQuotation({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="1000px"
            title={Messages.addQuotationRef}
            onSave={() => onSave(selectedRows)}
        >
            <div className="p-3">
                <HeaderTable
                    label=""
                    onClickFilter={() => {}}
                    onChangeText={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                    onSubmitSearch={onSubmitSearch}
                    showFilter={false}
                    className="app-layout__page-header"
                />
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    source={source}
                    transformer={(res) => {
                        return res?.data?.data?.data ?? [];
                    }}
                    getTotalItems={(res) => {
                        return res?.data?.data?.pagination?.items ?? 0;
                    }}
                    columns={columns}
                    baseColumnProps={{ width: 100 }}
                    selectingRows={selectedRows}
                    rowSelection={{
                        onChange: (value) => setSelectedRows(value),
                        selectedRowKeys: selectedRows,
                        preserveSelectedRowKeys: true,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default OrderQuotationRef;
