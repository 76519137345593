import {
    CheckboxGroup,
    InputText,
    Notifications,
    ObjectUtils,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find, map } from "lodash";
import { useContext, useState } from "react";
import {
    useGetCustomerSurveyQuery,
    useUpdateCustomerSurveyMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { CustomerDetailContext } from "../../../context/customer";
import { SurveyQuestionType } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

interface ISurveyQuestion {
    survey: any;
    answer: string[];
    disabled?: boolean;
    onChange?: any;
}

export const SurveyQuestion = ({
    survey,
    answer,
    disabled = false,
    onChange,
}: ISurveyQuestion) => {
    const content = () => {
        switch (survey.type) {
            case SurveyQuestionType.CHECK_BOX:
                return (
                    <CheckboxGroup
                        numberOfColumns="3"
                        dataSource={survey?.answers ?? []}
                        label={
                            <div className="text-primary">{survey?.label}</div>
                        }
                        value={answer}
                        disabled={disabled}
                        onChange={onChange}
                    />
                );

            default:
                return (
                    <InputText
                        label={
                            <div className="text-primary mb-3">
                                {survey?.label}
                            </div>
                        }
                        value={answer}
                        disabled={disabled}
                        onChange={(e) => onChange && onChange(e?.target?.value)}
                        multiple
                    />
                );
        }
    };

    return <div className="mt-3 border p-3">{content()}</div>;
};

interface ICustomerDetailSurvey {
    open: boolean;
    surveyList: any[];
    onClose: () => void;
}

export const CustomerDetailSurveyDrawer = ({
    surveyList = [],
    open,
    onClose,
}: ICustomerDetailSurvey) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const [updateCustomerSurvey] = useUpdateCustomerSurveyMutation();

    const surveyForm = useFormik<any>({
        initialValues: {
            answers: customer?.survey?.surveyAnswers ?? [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onUpdateSurvey(values);
        },
    });
    const formValues = surveyForm?.values;

    const onUpdateValues = (survey: any, values: any[]) => {
        const result = map(surveyList, (item) => {
            if (item.id === survey.id) {
                return { questionId: survey.id, answers: values };
            }
            return item;
        });

        surveyForm.setFieldValue("answers", result);
    };

    const onUpdateSurvey = (input: any) => {
        Progress.show(
            {
                method: updateCustomerSurvey,
                params: [
                    {
                        variables: {
                            customerId: customer?.id,
                            surveyAnswers: input?.answers,
                        },
                    },
                ],
            },
            (res: any) => {
                setCustomer(res?.data?.data?.data);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.survey}
            width="800px"
            onSave={() => {
                surveyForm.handleSubmit();
            }}
        >
            <div className="p-3">
                {map(surveyList, (survey) => {
                    const answer = find(
                        formValues?.answers,
                        (item) => item.questionId === survey.id
                    );
                    return (
                        <SurveyQuestion
                            key={survey?.id}
                            survey={survey}
                            answer={answer?.answers}
                            onChange={(values: any) => {
                                onUpdateValues(survey, values);
                            }}
                        />
                    );
                })}
            </div>
        </Drawer>
    );
};

const CustomerDetailSurvey = () => {
    const { customer } = useContext(CustomerDetailContext);
    const [openSurvey, setOpenSurvey] = useState(false);
    const surveyListRes = useGetCustomerSurveyQuery();
    const surveyQuestionList = surveyListRes?.data?.data?.data ?? [];

    return (
        <div>
            <div className="bg-primary-trans p-3 flex-row-between-center">
                <div className="text-primary">
                    {`${Messages.surveyCompletedAt} ${TimeUtils.toDateTime(
                        customer?.survey?.updatedAt
                    )}`}
                </div>
                <div
                    className="text-primary text-bold cursor-pointer"
                    onClick={() => setOpenSurvey(true)}
                >
                    {Messages.update}
                </div>
            </div>
            {map(surveyQuestionList, (survey) => {
                const answer = find(
                    customer?.survey?.surveyAnswers,
                    (item) => item.questionId === survey.id
                );
                return (
                    <SurveyQuestion
                        survey={survey}
                        answer={answer?.answers ?? []}
                        disabled
                    />
                );
            })}

            {openSurvey && (
                <CustomerDetailSurveyDrawer
                    open={openSurvey}
                    onClose={() => setOpenSurvey(false)}
                    surveyList={surveyQuestionList}
                />
            )}
        </div>
    );
};

export default CustomerDetailSurvey;
