import { Notifications, Progress, RadioGroup } from "d-react-components";
import { find } from "lodash";
import React, { useContext, useState } from "react";
import { useUpdateStatusOrderDeliveryForAdminMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { ORDER_DELIVERY_STATUSES } from "../../../constant/order";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const OrderDeliveryStatusUpdate = ({
    delivery,
    open,
    onClose,
    onSave,
}: any) => {
    const [updateOrderDeliveryStatus] =
        useUpdateStatusOrderDeliveryForAdminMutation();
    const { order } = useContext(OrderDetailContext);
    const [deliveryStatus, setDeliveryStatus] = useState(
        delivery?.status
            ? find(ORDER_DELIVERY_STATUSES, { key: delivery.status })
            : null
    );
    const onSaveStatus = () => {
        updateOrderDeliveryStatus({
            variables: {
                order: order?.id as string,
                id: delivery?.id,
                status: deliveryStatus?.key as string,
            },
        });

        Progress.show(
            {
                method: updateOrderDeliveryStatus,
                params: [
                    {
                        variables: {
                            order: order?.id as string,
                            id: delivery?.id,
                            status: deliveryStatus?.key as string,
                        },
                    },
                ],
            },
            (resp) => {
                Notifications.showSuccess(
                    Messages.updateDeliveryStatusSuccessfully
                );
                onSave && onSave();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.deliveryStatus}
            onSave={onSaveStatus}
        >
            <div className="p-3">
                <RadioGroup
                    value={deliveryStatus}
                    onChange={(value) => setDeliveryStatus(value as any)}
                    dataSource={ORDER_DELIVERY_STATUSES}
                    getLabel={(status) => {
                        return Messages[status.label as string];
                    }}
                    getValue={(item) => item}
                    numberOfColumns="1"
                    label={Messages.deliveryStatus}
                    classNameItem="mt-0 mb-3"
                />
            </div>
        </Drawer>
    );
};

export default OrderDeliveryStatusUpdate;
