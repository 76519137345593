import * as Yup from "yup";
import Messages from "../languages/Messages";
import { ProDistrictSchema } from "./customer";

export const BranchSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    pickupCode: Yup.string().required("Required"),
    brandTaxId: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    status: Yup.mixed().required("Required"),
    manualStockAdjustment: Yup.mixed().required("Required"),
});

export const BranchAddressSchema = Yup.object().shape({
    province: ProDistrictSchema,
    district: ProDistrictSchema,
    subDistrict: ProDistrictSchema,
    postcode: Yup.string().required("Required field!"),
    address: Yup.string().required("Required field!"),
});