import {
    Button,
    DateInput,
    Header,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router";
import StockRequestAPI from "../../../../api/queries/stock-request";
import InputTextForm from "../../../../common/input/InputTextForm";
import PermissibleRender from "../../../../common/PermissibleComponent";
import UploadButton from "../../../../common/upload/UploadButton";
import { PERMISSION } from "../../../../constant/user";
import Messages from "../../../../languages/Messages";
import BranchSolidSelect from "../../../branch/share/BranchSolidSelect";
import Path from "../../../Path";
import StockRequestCreateItems from "./StockRequestCreateItems";
import InputSelectForm from "../../../../common/input/InputSelectForm";
import { STOCK_REQUEST_TYPES } from "../../../../constant/stock-request";

const mapStockRequestToServer = (values: any) => {
    const {
        branch,
        products,
        attachments,
        remark,
        type,
        expectedDateOfArrival,
    } = values;
    return {
        type,
        branch: branch?.id,
        products: map(products, (pro) => ({
            product: pro?.id,
            stock: pro?.quantity ?? 0,
        })),
        expectedDateOfArrival,
        attachments:
            attachments?.length > 0
                ? map(attachments, (att) => att?.fileUrl)
                : null,
        remark: remark || null,
    };
};

export const StockRequestSchema = Yup.object().shape({
    branch: Yup.object().required("Required field!"),
    type: Yup.string().required("Required field!"),
    products: Yup.array().min(1).required("Required field!"),
    expectedDateOfArrival: Yup.mixed().required("Required field!"),
});

const StockRequestCreate = () => {
    const navigate = useNavigate();

    const stockRequestForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: StockRequestSchema,
        onSubmit: (values: any) => {
            const input = mapStockRequestToServer(values);
            onCreateStockRequest(input);
        },
    });

    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = stockRequestForm;

    useEffect(() => {
        forEach(Object.keys(stockRequestForm.errors), (key) =>
            Notifications.showError(`${stockRequestForm.errors[key]}`)
        );
    }, [stockRequestForm.errors]);

    const onCreateStockRequest = (payload: any) => {
        Progress.show(
            {
                method: StockRequestAPI.create,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.INVENTORY_REQUEST_STOCK_DETAIL, {
                        stockRequestId: res?.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const classNameInput = "mt-3";
    return (
        <div className="flex-column-container bg-white w-100">
            <Header
                showCancel={false}
                className="sticky top-0"
                title={Messages.stockRequestCreate}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.PRODUCT_STOCK_REQUEST.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-4">
                <BranchSolidSelect
                    value={formValues?.branch}
                    onChange={(value) =>
                        setValues((prev: any) => ({
                            ...prev,
                            branch: value,
                            products: [],
                        }))
                    }
                    error={stockRequestForm?.errors?.branch}
                    className={classNameInput}
                />
                <InputSelectForm
                    dataSource={STOCK_REQUEST_TYPES}
                    keyData="type"
                    form={stockRequestForm}
                    className={classNameInput}
                />
                <StockRequestCreateItems stockRequestForm={stockRequestForm} />

                <DateInput
                    label={Messages.expectedDateOfArrival}
                    onChange={(value) => {
                        stockRequestForm.setFieldValue(
                            "expectedDateOfArrival",
                            value
                        );
                    }}
                    value={
                        formValues?.expectedDateOfArrival
                            ? moment(formValues?.expectedDateOfArrival)
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    error={formErrors?.expectedDateOfArrival as any}
                    className={classNameInput}
                />
                <InputTextForm
                    form={stockRequestForm}
                    keyData="remark"
                    multiple
                    className={classNameInput}
                />
                <div className={classNameInput}>
                    <label>{Messages.attachment}</label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) => {
                            stockRequestForm.setFieldValue("attachments", file);
                        }}
                        defaultFiles={formValues?.attachments ?? []}
                    />
                    <ViewTextError error={formErrors.attachments} />
                </div>
            </div>
        </div>
    );
};

export default StockRequestCreate;
