/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { Modal } from "d-react-components";
import { Progress } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { exportToCSVFile } from "../../utils/file";

export interface IExportProgressProps {
    source: (props: any) => Promise<any>;
    getData?: (res: any) => any;
    getPagination?: (res: any) => any;
    start?: boolean;
    exportName?: string;
    onStop: () => void;
    mapDataToExport?: (res: any) => any[];
}

const ExportProgress: React.FC<IExportProgressProps> = ({
    source,
    getData = (res) => res?.data?.data?.data ?? [],
    getPagination = (res) => res?.data?.data?.pagination ?? {},
    start,
    exportName,
    onStop,
    mapDataToExport = (res) => res,
}) => {
    const [openProgressModal, setOpenProgressModal] = useState<{
        open: boolean;
        progress?: number;
    }>({ open: false });

    useEffect(() => {
        if (start) {
            setOpenProgressModal({ open: true, progress: 0 });
            startExport();
        } else {
            console.log("stop");
        }
    }, [start]);

    const startExport = async () => {
        try {
            let page = 1;
            const exportData: any[] = [];
            const res = await source({ page, limit: 30 });
            const data = getData(res) || [];
            const pagination = getPagination(res);
            const { items, page: pageSer, total = 1 } = pagination || {};
            setOpenProgressModal({
                open: true,
                progress: Math.floor((page * 100) / total),
            });
            exportData.push(...data);
            while (page < total) {
                page++;
                const resPage = await source({ page, limit: 30 });
                setOpenProgressModal({
                    open: true,
                    progress: Math.floor((page * 100) / total),
                });
                const data = getData(resPage) || [];
                exportData.push(...data);
            }
            const mappedData = mapDataToExport(exportData);
            const fileName = exportName
                ? `${exportName}_${new Date().getTime()}`
                : `Der_Exported_Data_${new Date().getTime()}`;
            exportToCSVFile(mappedData, fileName);
        } catch (error) {
            setOpenProgressModal({ open: false });
        }
    };

    return (
        <Fragment>
            {openProgressModal?.open && (
                <Modal
                    wrapClassName="rounded-5"
                    classNameContent="rounded-5"
                    open={openProgressModal?.open}
                    showFooter={false}
                    showHeader={false}
                    onClose={() => {
                        setOpenProgressModal({ open: false });
                        onStop();
                    }}
                    closable
                    maskClosable={false}
                    hasCloseIcon={false}
                >
                    <div className="flex flex-col justify-center items-center py-5">
                        <Progress
                            size="small"
                            type="circle"
                            percent={openProgressModal?.progress}
                        />
                    </div>
                </Modal>
            )}
        </Fragment>
    );
};

export default ExportProgress;
