import styled from "@emotion/styled";
import { Avatar, Icon, Notifications } from "d-react-components";
import { useFormikContext } from "formik";
import { ElementRef, useRef, useState } from "react";
import { useGetCustomerListLazyQuery } from "../../../api/hooks";
import CustomerAPI from "../../../api/queries/customer";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import { PurchaseType } from "../../../constant/quotation";
import { CustomerStatus, ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import NewCustomerDrawer from "./NewCustomerDrawer";

const renderUserInfo = (user: ICustomer) => {
    const { customerNo, avatar, nickname, status } = user;
    const customerName = user?.fullNameTh;
    const firstNameCharacter = customerName ? customerName.charAt(0) : "";

    return (
        <UserInfoStyled className="flex items-center py-1">
            <div className="mx-2">
                {avatar ? (
                    <Avatar
                        className="w-10 h-10 object-cover"
                        src={avatar}
                        size="small"
                        variant="square"
                        text={firstNameCharacter}
                    />
                ) : (
                    <div className="w-10 h-10 rounded-full flex bg-gray-400 justify-content-center items-center">
                        <span
                            className="subTitle1"
                            style={{ fontSize: "1.5rem" }}
                        >
                            {firstNameCharacter}
                        </span>
                    </div>
                )}
            </div>
            <div className="userInfo w-100">
                <div className="flex-center-y w-100">
                    <span className="block text-sm font-semibold mr-1">
                        {`${customerName} ${nickname ? `(${nickname})` : ""}`}
                    </span>
                    <Icon
                        name="check_circle"
                        className={
                            status === CustomerStatus.VERIFIED
                                ? "text-green-500"
                                : "text-gray-400"
                        }
                    />
                </div>

                <span className="text-sm block text-gray-700 mt-1">
                    {Messages.customerId}: {customerNo}
                </span>
            </div>
        </UserInfoStyled>
    );
};

const CustomerSelect = () => {
    const selectRef = useRef<ElementRef<any>>(null);
    const { values, errors, handleSubmit, setFieldValue, setValues } =
        useFormikContext<any>();
    const { customer, purchaseTypes } = values;
    const [isCustomerFound, setIsCustomerFound] = useState(false);
    const [createNewModal, setCreateNewModal] = useState(false);
    const [getCustomerList] = useGetCustomerListLazyQuery({});
    const { store } = values;
    const onCreateNewCustomer = (customer: any) => {
        setFieldValue("customer", [customer]);
        setCreateNewModal(false);
    };

    const onSelectCustomer = async (values: ICustomer[]) => {
        const { id, setting } = values?.[0] || {};
        if (purchaseTypes === PurchaseType.STAFF) {
            const isStaffRes = await CustomerAPI.checkIsStaff(id);
            const isStaff = isStaffRes?.data?.data;
            if (isStaff) {
                const updatedValue = values?.[0] ?? {};
                if (!updatedValue?.setting) {
                    updatedValue.setting = {};
                }
                updatedValue.setting.isStaff = true;
                setFieldValue("customer", [updatedValue]);
            } else {
                selectRef.current && (selectRef.current as any)?.refresh?.();
                Notifications.showError(Messages.errorSelectCustomerStaff);
            }
        } else if (purchaseTypes === PurchaseType.VIP) {
            const isVip = setting?.isVip;
            if (isVip) {
                setFieldValue("customer", values);
            } else {
                selectRef.current && (selectRef.current as any)?.refresh?.();
                Notifications.showError(Messages.errorSelectCustomerVip);
            }
        } else {
            setFieldValue("customer", values);
        }
    };

    return (
        <>
            <SelectInfinityFilter
                ref={selectRef}
                placeholder={Messages.searchYourExistingCustomer}
                dataKey="data"
                fetchFn={(body, paging) => {
                    if (!body?.search) {
                        return Promise.resolve();
                    }
                    return getCustomerList({
                        variables: {
                            paginate: {
                                page: paging?.pageIndex as any,
                                limit: paging?.pageSize,
                                search: body?.search ?? "",
                                sort: {
                                    status: -1,
                                    createdAt: -1,
                                },
                            },
                        },
                    }).then((resp) => {
                        const resultLength = resp?.data?.data?.data?.length;
                        setIsCustomerFound(
                            resultLength ? resultLength > 0 : false
                        );
                        return resp;
                    });
                }}
                getLabel={(item) => (item ? renderUserInfo(item) : null)}
                onChange={(val) => {
                    onSelectCustomer(val);
                }}
                value={customer}
                allowCreateNew={!isCustomerFound}
                onCreateNew={() => setCreateNewModal(true)}
                disabled={!store || !purchaseTypes}
                onDropdownVisibleChange={(open) => {
                    if (open) {
                        setIsCustomerFound(false);
                    }
                }}
                error={errors?.customer}
            />
            {createNewModal && (
                <NewCustomerDrawer
                    open={createNewModal}
                    onClose={() => setCreateNewModal(false)}
                    onSave={onCreateNewCustomer}
                />
            )}
        </>
    );
};

export default CustomerSelect;

const UserInfoStyled = styled.div`
    .d-view-label-status {
        border-radius: 0 !important;
    }
`;
