import { gql } from "@apollo/client";
import { F_ADMIN_USER, F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";

export const F_TEMPORARY_SCHEDULE = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_ADMIN_USER}
    fragment F_TEMPORARY_SCHEDULE on AdminTempScheduleDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        day
        schedule {
            start
            end
        }
        user {
            ...F_ADMIN_USER
        }
        branch {
            id
            name
            code
        }
    }
`;

export const F_USER_TEMPORARY_SCHEDULE = gql`
    ${F_ADMIN_USER}
    fragment F_USER_TEMPORARY_SCHEDULE on AdminTempScheduleUserDto {
        user {
            ...F_ADMIN_USER
        }
        status
        fromDate
        toDate
        createdAt
        updatedAt
    }
`;

export const F_USER_TEMPORARY_SCHEDULES = gql`
    ${F_USER_TEMPORARY_SCHEDULE}
    ${F_PAGINATE}
    fragment F_USER_TEMPORARY_SCHEDULES on AdminTempScheduleUserPaginateResponse {
        data {
            ...F_USER_TEMPORARY_SCHEDULE
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_TEMPORARY_SCHEDULES = gql`
    ${F_TEMPORARY_SCHEDULE}
    ${F_PAGINATE}
    fragment F_TEMPORARY_SCHEDULES on AdminTempSchedulePaginateResponse {
        data {
            ...F_TEMPORARY_SCHEDULE
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
