import { CSSProperties } from "react";
import { generatePath } from "react-router-dom";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

interface IEquipmentNameView {
    equipment: IEquipment;
    isHideImage?: boolean;
    subContent?: any;
    subName?: any;
    className?: string;
    classNameLabel?: string;
    classNameContent?: string;
    classNameImage?: string;
    styleImage?: CSSProperties;
}

const EquipmentNameView = ({
    equipment,
    isHideImage = false,
    subContent = <div />,
    subName,
    className,
    classNameLabel,
    classNameContent,
    classNameImage,
    styleImage = {},
}: IEquipmentNameView) => {
    const { name, gallery } = equipment ?? {};

    const getNameEquipment = () => {
        return (
            <a
                className={`h5 ${classNameLabel}`}
                href={generatePath(Path.EQUIPMENT_DETAIL, {
                    equipmentId: equipment?.id ?? "id",
                })}
            >
                {name}
            </a>
        );
    };

    const renderImageEquipment = () => {
        const urlDefault = "/images/placeholder.png";
        if (isHideImage) {
            return <div />;
        }
        return (
            <img
                src={gallery?.[0] ?? urlDefault}
                className={`image-square-small ${classNameImage}`}
                style={styleImage}
            />
        );
    };

    return (
        <div className={`d-flex ${className}`}>
            {renderImageEquipment()}
            <div className={`flex-column ml-2 ${classNameContent}`}>
                <div className="d-flex">
                    <text className="d-flex flex-wrap align-items-center">
                        {getNameEquipment()}
                        <div
                            hidden={!subName}
                            className="width-fit-content ml-1"
                        >
                            {subName && subName}
                        </div>
                    </text>
                </div>
                <div className="small">{`${Messages.serialNo}: ${equipment?.serialNo}`}</div>

                {subContent}
            </div>
        </div>
    );
};

export default EquipmentNameView;
