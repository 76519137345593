import { ProductStockType } from "../api/hooks";

export enum ProductStockAction {
    ADD_STOCK = "ADD_STOCK",
    REMOVE_STOCK = "REMOVE_STOCK",
    ADD_HOLD = "ADD_HOLD",
    REMOVE_HOLD = "REMOVE_HOLD",
}

export const PRODUCT_STOCK_TYPES = [
    { id: ProductStockType.Order, label: "ordered" },
    { id: ProductStockType.ProductStockAdjustment, label: "adjusted" },
    { id: ProductStockType.ProductStockDelivery, label: "transferred" },
];

export const PRODUCT_STOCK_ACTION_TYPES = [
    { id: ProductStockAction.ADD_STOCK, label: "addStock" },
    { id: ProductStockAction.REMOVE_STOCK, label: "removeStock" },
    { id: ProductStockAction.ADD_HOLD, label: "addHold" },
    { id: ProductStockAction.REMOVE_HOLD, label: "removeHold" },
];
