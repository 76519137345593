import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    Notifications,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, filter, find, map, reduce } from "lodash";
import { useContext, useMemo, useRef, useState } from "react";
import { generatePath } from "react-router";
import {
    DeliveryStatus,
    useAllDeliveriesStockTransferLazyQuery,
    useAllDeliveriesStockTransferQuery,
} from "../../../../api/hooks";
import StockTransferAPI from "../../../../api/queries/stock-transfer";
import AppLink from "../../../../common/AppLink";
import UserAvatarName from "../../../../common/avatar/UserAvatarName";
import TableAction from "../../../../common/TableActions";
import { DELIVERY_DRIVER_STATUSES } from "../../../../constant/delivery-driver";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import { IDeliveryDriver } from "../../../../interfaces/delivery-driver";
import { IStockTransferProduct } from "../../../../interfaces/stock-transfer";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";
import StockTransferDeliveryDrawer from "./StockTransferDeliveryDrawer";

const computeStockLeftTransferItems = (
    products: IStockTransferProduct[],
    deliveries: IDeliveryDriver[]
): IStockTransferProduct[] => {
    return map(products, (pro) => {
        const { stock = 0, product } = pro;
        const delivered = reduce(
            deliveries,
            (res, item, index) => {
                const { stocks } = item;
                const foundPro = find(
                    stocks,
                    (iStock) => iStock?.product?.id === product?.id
                );
                return res + (foundPro?.quantity || 0);
            },
            0
        );
        return { ...pro, stock: stock - delivered };
    });
};

const StockTransferDelivery = () => {
    const tableRef = useRef<any>(null);
    const { stockTransfer, loadTransferDetail } = useContext(
        StockTransferDetailContext
    );
    const [textSearch, setTextSearch] = useState<string>("");
    const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState<{
        open: boolean;
        delivery?: any;
    }>({ open: false });
    const activeDeliveries = useMemo(() => {
        return filter(
            stockTransfer?.deliveries ?? [],
            (item) => item?.status !== DeliveryStatus.DeliveryCancelled
        );
    }, [stockTransfer?.deliveries]);
    const columns: IColumnsProps = [
        {
            title: "Ref. No",
            dataIndex: "deliveryNo",
            render: (deliveryNo, item) => (
                <AppLink
                    to={generatePath(Path.DELIVERY_DRIVER_DETAIL, {
                        deliveryId: item.id,
                    })}
                >
                    {deliveryNo}
                </AppLink>
            ),
        },
        {
            title: "DO No.",
            dataIndex: "deliveryNo",
        },
        {
            title: Messages.estDateOfArrival,
            dataIndex: "estDateOfArrival",
            render: (data) => (data ? TimeUtils.toDateTime(data) : "N/A"),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (data) => (
                <ViewLabelStatus
                    status={data}
                    listStatus={DELIVERY_DRIVER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.vehicleNo,
            dataIndex: "vehicle",
            render: (vehicle) => vehicle?.registrationNo ?? "N/A",
        },
        {
            title: Messages.driver,
            dataIndex: "driver",
            render: (user, item) => (
                <UserAvatarName size="x-small" user={user} />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: (data) => (data ? TimeUtils.toDateTime(data) : "N/A"),
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                        dataSource={[{ id: "update", label: "update" }]}
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, data: any) => {
        switch (actionId) {
            case "update":
                setOpenDeliveryDrawer({ open: true, delivery: data });
                break;

            default:
                break;
        }
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 0);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
            </div>
            <div className="flex-row-between-center mt-3">
                <InputTextSearch onChange={onChangeSearch} className="" />
                <Button
                    className="ml-3"
                    onClick={() => setOpenDeliveryDrawer({ open: true })}
                >
                    {Messages.create}
                </Button>
            </div>

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={stockTransfer?.deliveries ?? []}
                className="mt-3"
                pagination={false}
            />
            {openDeliveryDrawer.open && (
                <StockTransferDeliveryDrawer
                    open={openDeliveryDrawer.open}
                    onClose={() => setOpenDeliveryDrawer({ open: false })}
                    onSave={() => {
                        setOpenDeliveryDrawer({ open: false });
                        loadTransferDetail();
                        Notifications.showSuccess(Messages.updateSuccess);
                    }}
                    transferItems={computeStockLeftTransferItems(
                        stockTransfer?.products ?? [],
                        activeDeliveries as any
                    )}
                    delivery={openDeliveryDrawer?.delivery}
                />
            )}
        </div>
    );
};

export default StockTransferDelivery;
