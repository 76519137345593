import { useContext } from "react";
import { TypeOfProduct } from "../../../api/hooks";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";
import ProductSolidSelect from "../../product/share/ProductSolidSelect";

const BookingCreateService = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const formValues = bookingForm?.values;

    return (
        <div className="mt-3">
            <ProductSolidSelect
                label={Messages.treatment}
                value={formValues?.products}
                onChange={(value) => {
                    bookingForm.setFieldValue("products", value);
                }}
                error={bookingForm?.errors?.products}
                multiple
                customQuery={{ typeOfProduct: TypeOfProduct.ServiceProduct }}
            />
        </div>
    );
};

export default BookingCreateService;
