import { Button, Icon, Notifications } from "d-react-components";
import { useFormikContext } from "formik";
import { chain, filter, isEmpty, map, sumBy, uniqBy } from "lodash";
import React, { useContext, useState } from "react";
import { PurchaseType } from "../../../api/hooks";
import HoverableButton from "../../../common/HoverableButton";
import { QuotationCreateContext } from "../../../context/quotation";
import { OrderType } from "../../../interfaces/order";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import AddCustomItemDrawer from "../share/AddCustomItemDrawer";
import BillingDrawer from "../share/BillingDrawer";
import CreditVoucherDrawer from "../share/CreditVoucherDrawer";
import DiscountDrawer from "../share/DiscountDrawer";
import LoadDoctorOrderDrawer from "../share/LoadDoctorOrderDrawer";
import RemarkDrawer from "../share/RemarkDrawer";
import ShippingDrawer from "../share/ShippingDrawer";
import VoucherDrawer from "../share/VoucherDrawer";

export const getProductOrderId = (product: IProduct, doctorOrder?: any) => {
    return doctorOrder ? `${product?.id}_${doctorOrder?.id}` : `${product?.id}`;
};

const OrderProductActions = React.memo(
    ({
        applyCoupon,
        submitOrder,
        type = OrderType.QUOTATION,
        checkCartValue,
    }: any) => {
        const {
            productList,
            setProductList,
            productCustomList,
            setProductCustomList,
            shipping,
            billing,
            cartValue,
            manualDiscount,
            setManualDiscount,
        } = useContext(QuotationCreateContext);
        const { values, errors, handleSubmit, setFieldValue, setValues } =
            useFormikContext<any>();
        const [openVoucherModal, setOpenVoucherModal] = useState(false);
        const [openCreditVoucherModal, setOpenCreditVoucherModal] =
            useState(false);
        const [openDiscountModal, setOpenDiscountModal] = useState(false);
        const [openAddCustomItem, setOpenAddCustomItem] = useState(false);
        const [openShippingModal, setOpenShippingModal] = useState(false);
        const [openBillingModal, setOpenBillingModal] = useState(false);
        const [openOrderNote, setOpenOrderNote] = useState(false);
        const [openLoadDoctorOrder, setOpenLoadDoctorOrder] = useState(false);
        // const [openLoadCoSalePerson, setOpenLoadCoSalePerson] = useState(false);
        const { store, remark, customer, contact, purchaseTypes } = values;

        const handleApplyVoucher = (payload: any) => {
            applyCoupon(payload);
        };

        const handleOnClickAddVoucher = () => {
            if (!customer) {
                Notifications.showError(Messages.pleaseChooseCustomer);
                return;
            }
            if (isEmpty(store)) {
                Notifications.showError(
                    Messages.pleaseSelectProductChannelFirst
                );
                return;
            }
            if (productList?.length === 0 && productCustomList.length === 0) {
                Notifications.showError(Messages.pleaseAddProductToCartFirst);
                return;
            }
            setOpenVoucherModal(true);
        };

        const handleOnClickAddCreditVoucher = () => {
            if (!customer) {
                Notifications.showError(Messages.pleaseChooseCustomer);
                return;
            }

            if (productList?.length === 0 && productCustomList.length === 0) {
                Notifications.showError(Messages.pleaseAddProductToCartFirst);
                return;
            }
            setOpenCreditVoucherModal(true);
        };

        const handleOnClickAddDiscount = () => {
            if (!customer) {
                Notifications.showError(Messages.pleaseChooseCustomer);
                return;
            }
            if (isEmpty(store)) {
                Notifications.showError(
                    Messages.pleaseSelectProductChannelFirst
                );
                return;
            }
            if (productList?.length === 0 && productCustomList.length === 0) {
                Notifications.showError(Messages.pleaseAddProductToCartFirst);
                return;
            }
            setOpenDiscountModal(true);
        };

        const onSaveCustomItem = (customItem: any) => {
            setProductCustomList([...productCustomList, customItem]);
            setOpenAddCustomItem(false);
        };

        const onSaveRemark = (remark: string) => {
            setFieldValue("remark", remark);
            setOpenOrderNote(false);
        };

        const addProductsToOrder = (products: any) => {
            const productOrderIds = map(
                productList,
                (item) => item?.productOrderId
            );
            //filter product by purchase type
            const uniqueProducts = filter(products, (item) => {
                return !productOrderIds.includes(
                    getProductOrderId(item?.product, item?.doctorOrder)
                );
            });
            const listToAdd = filter(uniqueProducts, (product) => {
                if (purchaseTypes === PurchaseType.Complication) {
                    return product?.treatment?.isComplication;
                }
                return !product?.treatment?.isComplication;
            });
            const newProductList = [
                ...productList,
                ...map(listToAdd, (product: any) => ({
                    ...product.product,
                    quantity: product.quantity,
                    doctorOrder: product?.doctorOrder,
                    treatment: product?.treatment,
                    productOrderId: getProductOrderId(
                        product?.product,
                        product?.doctorOrder
                    ),
                })),
            ];

            //group product
            const groupedProductList = chain(newProductList)
                .groupBy("productOrderId")
                .map((items) => ({
                    ...items[0],
                    quantity: sumBy(items, "quantity"),
                }))
                .value();
            setProductList(groupedProductList);
        };

        // const onClickMoreActionItem = (item: any) => {
        //     switch (item?.id) {
        //         case "addCoSalePerson":
        //             setOpenLoadCoSalePerson(true);
        //             break;
        //         default:
        //             break;
        //     }
        // };

        return (
            <>
                <div className="">
                    <div className="flex items-center">
                        <HoverableButton
                            onClick={(item) => {
                                if (!customer) {
                                    Notifications.showError(
                                        Messages.pleaseChooseCustomer
                                    );
                                    return;
                                }
                                setOpenShippingModal(true);
                            }}
                            className="text-white inline-block px-2"
                            icon={<Icon name="local_shipping" />}
                            content={Messages.shippingAddress}
                            hasData={!isEmpty(shipping)}
                            dataNumber={shipping?.length}
                        />
                        {type === OrderType.ORDER && (
                            <HoverableButton
                                className="text-white inline-block px-2"
                                icon={<img alt="" src="/icons/bill.svg" />}
                                content={Messages.billingAddress}
                                onClick={(item) => {
                                    if (!customer) {
                                        Notifications.showError(
                                            Messages.pleaseChooseCustomer
                                        );
                                        return;
                                    }
                                    setOpenBillingModal(true);
                                }}
                                hasData={!isEmpty(billing)}
                            />
                        )}
                        {type === OrderType.ORDER && (
                            <HoverableButton
                                onClick={() => handleOnClickAddVoucher()}
                                className="text-white inline-block px-2"
                                icon={<img alt="" src="/icons/voucher.svg" />}
                                content={Messages.voucher}
                                hasData={!isEmpty(cartValue?.voucher)}
                            />
                        )}
                        <HoverableButton
                            onClick={() => handleOnClickAddDiscount()}
                            className="text-white inline-block px-2"
                            icon={<img alt="" src="/icons/discount.svg" />}
                            content={Messages.discount}
                            // hasData={!isEmpty(cartValue?.voucher)}
                        />

                        <HoverableButton
                            className="text-white inline-block px-2"
                            icon={<Icon name="settings" />}
                            content={Messages.customProduct}
                            onClick={(item) => {
                                if (!customer) {
                                    Notifications.showError(
                                        Messages.pleaseChooseCustomer
                                    );
                                    return;
                                }
                                setOpenAddCustomItem(true);
                            }}
                        />
                        {type === OrderType.ORDER && (
                            <HoverableButton
                                onClick={() => {
                                    if (!customer) {
                                        Notifications.showError(
                                            Messages.pleaseChooseCustomer
                                        );
                                        return;
                                    }
                                    setOpenLoadDoctorOrder(true);
                                }}
                                className="text-white inline-block px-2"
                                icon={<img alt="" src="/icons/change.svg" />}
                                content={Messages.doctorOrder}
                            />
                        )}
                        <HoverableButton
                            className="text-white inline-block px-2"
                            icon={<img alt="" src="/icons/notes.svg" />}
                            content={Messages.remark}
                            onClick={() => setOpenOrderNote(true)}
                            hasData={!isEmpty(remark)}
                        />
                        {type === OrderType.ORDER && (
                            <HoverableButton
                                onClick={() => handleOnClickAddCreditVoucher()}
                                className="text-white inline-block px-2"
                                icon={<img alt="" src="/icons/menu-add.svg" />}
                                content={Messages.credits}
                            />
                        )}
                        {/* <Dropdown
                            dataSource={[
                                {
                                    id: "addAdditionalFee",
                                    label: Messages.addAdditionalFee,
                                },
                                {
                                    id: "addCoSalePerson",
                                    label: Messages.addCoSalePerson,
                                },
                            ]}
                            onClick={onClickMoreActionItem}
                        >
                            <HoverableButton
                                className="text-white inline-block px-2"
                                icon={
                                    <img alt="" src="/icons/more-action.svg" />
                                }
                                content={Messages.moreActions}
                            />
                        </Dropdown> */}
                    </div>
                    <Button
                        color="primary"
                        className="w-full mt-2"
                        style={{ width: "100%" }}
                        onClick={() => submitOrder()}
                    >
                        {type === OrderType.QUOTATION &&
                            Messages.generateQuotation}
                        {type === OrderType.ORDER && Messages.placeOrder}
                    </Button>
                </div>
                {openVoucherModal && (
                    <VoucherDrawer
                        open={openVoucherModal}
                        onClose={() => setOpenVoucherModal(false)}
                        numberDiscount={0}
                        applyCoupon={handleApplyVoucher}
                    />
                )}
                {openCreditVoucherModal && (
                    <CreditVoucherDrawer
                        open={openCreditVoucherModal}
                        onClose={() => setOpenCreditVoucherModal(false)}
                    />
                )}
                {openDiscountModal && (
                    <DiscountDrawer
                        open={openDiscountModal}
                        onClose={() => setOpenDiscountModal(false)}
                        onSave={(value: any) =>
                            setManualDiscount &&
                            setManualDiscount(
                                uniqBy(
                                    [...value, ...(manualDiscount || [])],
                                    "product.id"
                                )
                            )
                        }
                    />
                )}
                {openAddCustomItem && (
                    <AddCustomItemDrawer
                        open={openAddCustomItem}
                        onClose={() => setOpenAddCustomItem(false)}
                        // onSave={(item: any) => onSaveCustomItem(item)}
                    />
                )}
                {openOrderNote && (
                    <RemarkDrawer
                        open={openOrderNote}
                        onClose={() => setOpenOrderNote(false)}
                        onSave={onSaveRemark}
                        remark={remark}
                    />
                )}
                {openShippingModal && (
                    <ShippingDrawer
                        open={openShippingModal}
                        onClose={() => setOpenShippingModal(false)}
                        onSave={() => setOpenShippingModal(false)}
                    />
                )}
                {openBillingModal && (
                    <BillingDrawer
                        open={openBillingModal}
                        onClose={() => setOpenBillingModal(false)}
                    />
                )}
                {openLoadDoctorOrder && (
                    <LoadDoctorOrderDrawer
                        open={openLoadDoctorOrder}
                        onClose={() => setOpenLoadDoctorOrder(false)}
                        onSave={(products: any) => {
                            addProductsToOrder(products);
                            setOpenLoadDoctorOrder(false);
                        }}
                    />
                )}
                {/* {openLoadCoSalePerson && (
                    <LoadCoSalePersonDrawer
                        open={openLoadCoSalePerson}
                        onClose={() => setOpenLoadCoSalePerson(false)}
                        onSave={(products: any) => {
                            addProductsToOrder(products);
                            setOpenLoadDoctorOrder(false);
                        }}
                    />
                )} */}
            </>
        );
    }
);

export default OrderProductActions;
