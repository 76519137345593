import {
    CreditCardType,
    Payment2C2POption,
    PaymentCardDevice,
} from "../api/hooks";

export const PAYMENT_CONFIRM_STATUS = {
    REJECTED: "rejected",
    APPROVE: "approved",
    PENDING: "pending",
};

export const REFUND_STATUS = {
    REJECTED: "rejected",
    APPROVE: "approved",
    PENDING: "pending",
    REFUNDED: "refunded",
    CANCELLED: "cancelled",
};

export const ORDER_PAYMENT_STATUS = {
    PENDING_PAYMENT: "pending",
    FULL_PAYMENT_CONFIRM: "full-payment-confirmed",
    FULL_PAYMENT_REJECT: "payment-fully-rejected",
    PARTIALLY_PAYMENT_REJECT: "payment-partially-rejected",
    PARTIALLY_PAYMENT_CONFIRM: "payment-partially-confirmed",
};

export const PAYMENTS_METHOD = {
    DEFAULT: "DEFAULT",
    PAYMENT_2C2P: "PAYMENT_2C2P",
    BANK_TRANSFER: "BANK_TRANSFER",
    CREDIT_CARD: "CREDIT_CARD",
    CREDIT_CARD_POS: "CREDIT_CARD_POS",
    CASH: "CASH",
    THAI_QR_PAYMENT: "THAI_QR_PAYMENT",
    PAID_BY_CHECK: "PAID_BY_CHECK",
    EMPLOYEE_SALARY_DEDUCTION: "EMPLOYEE_SALARY_DEDUCTION",
};

export const PAYMENTS_METHODS = [
    {
        value: PAYMENTS_METHOD.BANK_TRANSFER,
        label: "bankTransfer",
        type: "TRANSFER",
    },
    {
        value: PAYMENTS_METHOD.PAYMENT_2C2P,
        label: "c2pName",
        type: "CREDIT 2C2P",
    },
    {
        value: PAYMENTS_METHOD.DEFAULT,
        label: "default",
        type: "DEFAULT",
    },
    {
        value: PAYMENTS_METHOD.CREDIT_CARD,
        label: "creditCard",
        type: "CREDIT",
    },
    {
        value: PAYMENTS_METHOD.CREDIT_CARD_POS,
        label: "creditCardPos",
        type: "CREDIT",
    },
    {
        value: PAYMENTS_METHOD.CASH,
        label: "cash",
        type: "CASH",
    },
    {
        value: PAYMENTS_METHOD.THAI_QR_PAYMENT,
        label: "thaiQRPayment",
        type: "TRANSFER",
    },
];

export const PAYMENT_2C2P_OPTION = {
    ALL: "ALL",
};

export const PAYMENT_2C2P_OPTIONS = [
    {
        value: Payment2C2POption.All,
        label: "all",
    },
    {
        value: Payment2C2POption.Full,
        label: "full",
    },
    {
        value: Payment2C2POption.Bank,
        label: "bank",
    },
    {
        value: Payment2C2POption.Cc,
        label: "cc",
    },
    {
        value: Payment2C2POption.Ipp,
        label: "ipp",
    },
    {
        value: Payment2C2POption.Otc,
        label: "otc",
    },
    {
        value: Payment2C2POption.Q,
        label: "q",
    },
    {
        value: Payment2C2POption.Alipay,
        label: "alipay",
    },
    {
        value: Payment2C2POption.Line,
        label: "line",
    },
    {
        value: Payment2C2POption.Wallet,
        label: "wallet",
    },
];

export const PAYMENT_METHOD_STATUS = {
    ENABLE: "ENABLE",
    DISABLE: "DISABLE",
};

export const PAYMENT_METHOD_STATUSES = [
    { id: PAYMENT_METHOD_STATUS.ENABLE, label: "enabled", color: "#33B950" },
    { id: PAYMENT_METHOD_STATUS.DISABLE, label: "disabled", color: "#C4C4C4" },
];

export const PAYMENT_CARD_DEVICES = [
    // {
    //     id: PaymentCardDevice.Alipay,
    //     label: "ALIPAY",
    // },
    {
        id: PaymentCardDevice.AlipayKsher,
        label: "ALIPAY - KSHER",
    },
    {
        id: PaymentCardDevice.AlipayKtc,
        label: "ALIPAY - KTC",
    },
    {
        id: PaymentCardDevice.Bay,
        label: "BAY",
    },
    {
        id: PaymentCardDevice.Bbl1770,
        label: "BBL1770",
    },
    {
        id: PaymentCardDevice.Bbl8965,
        label: "BBL8965",
    },
    {
        id: PaymentCardDevice.Citibank,
        label: "CITIBANK",
    },
    {
        id: PaymentCardDevice.Kbank,
        label: "KBANK",
    },
    {
        id: PaymentCardDevice.Ktb,
        label: "KTB",
    },
    {
        id: PaymentCardDevice.Scb,
        label: "SCB",
    },
    {
        id: PaymentCardDevice.Uob,
        label: "UOB",
    },
    {
        id: PaymentCardDevice.Wechat,
        label: "WECHAT",
    },
];

export const CREDIT_CARD_TYPES = [
    {
        id: CreditCardType.Visa,
        label: "Visa",
    },
    {
        id: CreditCardType.Mastercard,
        label: "Mastercard",
    },
    {
        id: CreditCardType.Amex,
        label: "American Express",
    },
    {
        id: CreditCardType.Discover,
        label: "Discover Cards",
    },
    {
        id: CreditCardType.UnionPay,
        label: "Union Pay",
    },
    {
        id: CreditCardType.DinersClub,
        label: "Diners Club",
    },
    {
        id: CreditCardType.Jcb,
        label: "JCB",
    },
    {
        id: CreditCardType.Tba,
        label: "TBA",
    },
];
