import React from "react";
import { useParams } from "react-router";
import ActivityView from "../../../common/activity/ActivityView";
import { RefActivity } from "../../../api/hooks";

const MaterialAdjustRequestActivity = () => {
    const { requestId } = useParams<any>();
    return (
        <div>
            {requestId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.MaterialStockAdjustment}
                    activityRefId={requestId ?? ""}
                />
            )}
        </div>
    );
};

export default MaterialAdjustRequestActivity;
