import {
    Button,
    Header,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router";
import EquipmentStockAdjustmentAPI from "../../../../api/queries/equipment-stock-adjustment";
import InputTextForm from "../../../../common/input/InputTextForm";
import PermissibleRender from "../../../../common/PermissibleComponent";
import UploadButton from "../../../../common/upload/UploadButton";
import { PERMISSION } from "../../../../constant/user";
import Messages from "../../../../languages/Messages";
import BranchSolidSelect from "../../../branch/share/BranchSolidSelect";
import Path from "../../../Path";
import EquipmentStockAdjustmentCreateItems from "./EquipmentStockAdjustmentCreateItems";

const mapStockAdjustmentToServer = (values: any) => {
    const { branch, equipments, attachments, remark } = values;
    return {
        branch: branch?.id,
        equipments: map(equipments, (item) => ({
            equipment: item?.id,
            quantity: item?.quantity ?? 0,
        })),
        attachments:
            attachments?.length > 0
                ? map(attachments, (att) => att?.fileUrl)
                : null,
        remark: remark || null,
    };
};

export const EquipmentStockAdjustmentSchema = Yup.object().shape({
    branch: Yup.object().required("Required field!"),
    equipments: Yup.array().min(1).required("Required field!"),
});

const EquipmentStockAdjustmentCreate = () => {
    const navigate = useNavigate();

    const stockAdjustmentForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: EquipmentStockAdjustmentSchema,
        onSubmit: (values: any) => {
            const input = mapStockAdjustmentToServer(values);
            onCreateStockAdjustment(input);
        },
    });

    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = stockAdjustmentForm;

    useEffect(() => {
        forEach(Object.keys(stockAdjustmentForm.errors), (key) =>
            Notifications.showError(`${stockAdjustmentForm.errors[key]}`)
        );
    }, [stockAdjustmentForm.errors]);

    const onCreateStockAdjustment = (payload: any) => {
        Progress.show(
            {
                method: EquipmentStockAdjustmentAPI.create,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(
                        Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_DETAIL,
                        {
                            stockAdjustmentId: res?.id,
                        }
                    )
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const classNameInput = "mt-3";
    return (
        <div className="flex-column-container bg-white w-100">
            <Header
                showCancel={false}
                className="sticky top-0"
                title={Messages.newStockAdjustment}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={
                                PERMISSION.EQUIPMENT_STOCK_ADJUSTMENT.CREATE
                            }
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-4">
                <BranchSolidSelect
                    value={formValues?.branch}
                    onChange={(value) =>
                        setValues((prev: any) => ({
                            ...prev,
                            branch: value,
                            equipments: [],
                        }))
                    }
                    error={stockAdjustmentForm?.errors?.branch}
                    className={classNameInput}
                />
                <EquipmentStockAdjustmentCreateItems
                    stockAdjustmentForm={stockAdjustmentForm}
                />
                <div className={classNameInput}>
                    <label>{Messages.attachment}</label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) => {
                            stockAdjustmentForm.setFieldValue(
                                "attachments",
                                file
                            );
                        }}
                        defaultFiles={formValues?.attachments ?? []}
                    />
                    <ViewTextError error={formErrors.attachments} />
                </div>

                <InputTextForm
                    form={stockAdjustmentForm}
                    keyData="remark"
                    multiple
                    className={classNameInput}
                />
            </div>
        </div>
    );
};

export default EquipmentStockAdjustmentCreate;
