import { gql } from "@apollo/client";
import API from "../API";
import {
    F_EQUIPMENT_STOCK_ADJUSTMENT,
    F_EQUIPMENT_STOCK_ADJUSTMENTS,
} from "../fragments/equipment-stock-adjustment";

export const getEquipmentStockAdjustmentList = gql`
    ${F_EQUIPMENT_STOCK_ADJUSTMENTS}
    query getEquipmentStockAdjustmentList(
        $input: EquipmentStockAdjustmentPaginateRequest!
    ) {
        data: listEquipmentStockAdjustmentForAdmin(paginate: $input) {
            ...F_EQUIPMENT_STOCK_ADJUSTMENTS
        }
    }
`;

export const getEquipmentStockAdjustmentDetail = gql`
    ${F_EQUIPMENT_STOCK_ADJUSTMENT}
    query getEquipmentStockAdjustmentDetail($id: String!) {
        data: detailEquipmentStockAdjustmentForAdmin(id: $id) {
            data {
                ...F_EQUIPMENT_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const createEquipmentStockAdjustment = gql`
    ${F_EQUIPMENT_STOCK_ADJUSTMENT}
    mutation createEquipmentStockAdjustment(
        $payload: EquipmentStockAdjustmentCreateRequest!
    ) {
        data: createEquipmentStockAdjustmentForAdmin(payload: $payload) {
            data {
                ...F_EQUIPMENT_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const approveEquipmentStockAdjustment = gql`
    ${F_EQUIPMENT_STOCK_ADJUSTMENT}
    mutation approveEquipmentStockAdjustment(
        $id: String!
        $payload: EquipmentStockAdjustmentConfirmInput
    ) {
        data: confirmEquipmentStockAdjustmentForAdmin(
            id: $id
            payload: $payload
        ) {
            data {
                ...F_EQUIPMENT_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const cancelEquipmentStockAdjustment = gql`
    ${F_EQUIPMENT_STOCK_ADJUSTMENT}
    mutation cancelEquipmentStockAdjustment(
        $id: String!
        $payload: EquipmentStockAdjustmentConfirmInput
    ) {
        data: confirmEquipmentStockAdjustmentForAdmin(
            id: $id
            payload: $payload
        ) {
            data {
                ...F_EQUIPMENT_STOCK_ADJUSTMENT
            }
        }
    }
`;

const stockAdjustmentListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const stockAdjustmentConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

const EquipmentStockAdjustmentAPI = {
    list: async (input: any) =>
        API.withConverter(stockAdjustmentListConverter).query({
            query: getEquipmentStockAdjustmentList,
            variables: { input },
        }),

    detail: async (id: any) =>
        API.withConverter(stockAdjustmentConverter).query({
            query: getEquipmentStockAdjustmentDetail,
            variables: { id },
        }),

    create: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: createEquipmentStockAdjustment,
            variables: { payload },
        }),

    approve: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: approveEquipmentStockAdjustment,
            variables: { ...payload },
        }),

    cancel: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: cancelEquipmentStockAdjustment,
            variables: { ...payload },
        }),
};

export default EquipmentStockAdjustmentAPI;
