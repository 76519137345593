import * as Yup from "yup";

export const EquipmentCategorySchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    status: Yup.mixed().required("Required field!"),
});

export const EquipmentSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    model: Yup.string().required("Required field!"),
    serialNo: Yup.string().required("Required field!"),
    category: Yup.mixed().required("Required field!"),
    weight: Yup.number().required("Required field!"),
    visibility: Yup.mixed().required("Required field!"),
    condition: Yup.mixed().required("Required field!"),
});
