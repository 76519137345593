import React from "react";

interface IStockRequestDetailState {
    stockRequest: any;
    setStockRequest: any;
}

const stockRequestDetailState: IStockRequestDetailState = {
    stockRequest: {} as any,
    setStockRequest: () => {},
};

export const StockRequestDetailContext = React.createContext(
    stockRequestDetailState
);
