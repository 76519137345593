import { Progress, ViewLabelStatus } from "d-react-components";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { orderBy } from "lodash";
import {
    RefActivity,
    useCancelOrderMutation,
    useDetailMaterialPurchaseLazyQuery,
    MaterialPurchaseOrderStatus,
    useListAllMaterialPurchaseDeliveryLazyQuery,
} from "../../../api/hooks";
import ActionMenu from "../../../common/ActionButton";
import ActivityView from "../../../common/activity/ActivityView";
import Messages from "../../../languages/Messages";
import OrderHeader from "./OrderHeader";
import { IAddress } from "../../../interfaces/address";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import PriceTable from "./PriceTable";
import OrderVendor from "./OrderVendor";
import OrderPORequest from "./OrderPORequest";
import OrderShipping from "./OrderShipping";
import OrderBilling from "./OrderBilling";
import OrderNote from "./OrderNote";
import OrderApproval from "./OrderApproval";
import OrderPayment from "./OrderPayment";
import { MATERIAL_PURCHASE_STATUSES } from "../../../constant/material";
import { IDeliveryOrder } from "../../../interfaces/delivery-order";
import OrderDeliveryCreate from "./OrderDeliveryCreate";
import OrderDelivery from "./OrderDelivery";
import OrderVendorContact from "./OrderVendorContact";

const MaterialOrderDetail = () => {
    const { orderId } = useParams<any>();
    const [showCreateDelivery, setShowCreateDelivery] = useState(false);
    const [shippingList, setShippingList] = useState<IAddress[]>([]);
    // const [deliveryList, setDeliveryList] = useState<any[]>([]);

    const [cancelOrder] = useCancelOrderMutation();
    const [getOrder, { data, loading, refetch }] =
        useDetailMaterialPurchaseLazyQuery();
    const [getOrderDelivery, { data: deliveryList }] =
        useListAllMaterialPurchaseDeliveryLazyQuery({
            fetchPolicy: "no-cache",
        });

    useEffect(() => {
        loadOrderDetail();
        loadOrderDelivery();
    }, []);

    const loadOrderDelivery = () => {
        getOrderDelivery({
            variables: {
                materialPurchase: orderId as string,
            },
        });
    };

    const loadOrderDetail = () => {
        Progress.show(
            {
                method: getOrder,
                params: [
                    {
                        variables: {
                            id: orderId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };
    const orderDetail = data?.data?.data;

    const onClickAction = (item: any) => {
        if (item.id === "createDelivery") {
            setShowCreateDelivery(true);
        }
        // if (item.id === "cancelOrder") {
        //     DialogManager.showConfirm(
        //         Messages.confirm,
        //         Messages.areYouSureWantCancel,
        //         () =>
        //             Progress.show(
        //                 {
        //                     method: cancelOrder,
        //                     params: [
        //                         {
        //                             variables: {
        //                                 id: orderId as string,
        //                             },
        //                         },
        //                     ],
        //                 },
        //                 () => {
        //                     loadOrderDetail();
        //                 }
        //             )
        //     );
        // }
    };

    const onCloseDeliveryCreate = () => {
        setShowCreateDelivery(false);
        loadOrderDelivery();
    };

    const sortedDeliveryList = useMemo(() => {
        return orderBy(deliveryList?.data?.data, "createdAt", "desc");
    }, [deliveryList]);

    return (
        <MaterialPurchaseDetailContext.Provider
            value={{
                order: orderDetail as any,
                loadOrderDetail,
                shippingList,
                setShippingList,
                deliveryList: sortedDeliveryList as IDeliveryOrder[],
                loadDeliveryList: loadOrderDelivery,
            }}
        >
            <div className="px-4 py-3 bg-white flex border-b items-center">
                <div className="flex flex-1 align-items-center">
                    <h5 className="text-primary mr-3">
                        {Messages.order} #{`${orderDetail?.materialPurchaseNo}`}
                    </h5>
                    <ViewLabelStatus
                        status={orderDetail?.status}
                        listStatus={MATERIAL_PURCHASE_STATUSES}
                        getLabel={(item) => (Messages as any)[item.label]}
                    />
                </div>
                <div className="flex items-center">
                    <ActionMenu
                        dataSource={[
                            {
                                id: "createDelivery",
                                label: Messages.createDelivery,
                            },
                        ]}
                        onClick={onClickAction}
                    />
                </div>
            </div>
            <div className="px-4 py-3">
                <OrderHeader />
                <div className="grid grid-cols-4 gap-4 mt-3">
                    <div className="col-span-3">
                        <div className="border p-3 mb-3">
                            <PriceTable
                                order={orderDetail as any}
                                onUpdated={loadOrderDetail}
                            />
                        </div>
                        <OrderNote />
                        <OrderApproval />
                        {orderDetail?.materialPurchaseOrderStatus ===
                            MaterialPurchaseOrderStatus.Approved && (
                            <OrderPayment />
                        )}
                        <OrderDelivery />
                        <ActivityView
                            className="mt-3 border p-3"
                            activityRef={RefActivity?.MaterialPurchase}
                            activityRefId={orderId ?? ""}
                        />
                    </div>
                    <div className="col-span-1">
                        <OrderPORequest />
                        <OrderVendor />
                        <OrderVendorContact />
                        <OrderShipping />
                        <OrderBilling />
                    </div>
                </div>
            </div>
            {showCreateDelivery && (
                <OrderDeliveryCreate
                    open={showCreateDelivery}
                    onClose={() => setShowCreateDelivery(false)}
                    onSave={() => onCloseDeliveryCreate()}
                />
            )}
        </MaterialPurchaseDetailContext.Provider>
    );
};

export default MaterialOrderDetail;
