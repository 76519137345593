import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    InputText,
    Modal,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import * as Yup from "yup";
import { find } from "lodash";
import { useFormik } from "formik";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";

import {
    PaymentMethod,
    SourceOfPayment,
    useUpdateMaterialPaymentMethodMutation,
    useUpdatePaymentMethodForAdminMutation,
} from "../../../api/hooks";
import { PaymentContext } from "../../../context/payment";

const ChangePaymentMethodDrawer = ({ open, onClose, payment, onSave }: any) => {
    // const { paymentMethodsList, paymentOptions } = useContext(AppContext);
    // const [mtBankList, setMTBankList] = useState([]);
    const [updatePaymentMethod] = useUpdateMaterialPaymentMethodMutation();
    const {
        ref: order,
        paymentMethodsList,
        payment2C2POptionsList,
    } = useContext(PaymentContext);

    const ChangeMethodSchema = Yup.lazy((value) => {
        const schema = Yup.object().shape({
            // remark: Yup.string().required(Messages.fieldRequired),
        });
        // if (value.method === PAYMENT_MODERN_TRADE) {
        //     schema = schema.concat(
        //         Yup.object().shape({
        //             numberInstalment: Yup.number().required(t("fieldRequired")),
        //         })
        //     );
        // }
        return schema;
    });

    const methodForm = useFormik<any>({
        initialValues: {
            paymentMethod: payment.paymentMethod,
            paymentOption: payment.paymentOption,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: ChangeMethodSchema,
        onSubmit: (values: any) => {
            updatePaymentMethod({
                variables: {
                    refId: order?.id as string,
                    paymentId: payment?.id as string,
                    payload: {
                        paymentMethod: values?.paymentMethod,
                    },
                },
                fetchPolicy: "no-cache",
            })
                .then(() => {
                    onSave && onSave();
                })
                .catch((err: any) => {
                    Notifications.showError(err);
                });
        },
    });

    // useEffect(() => {
    //     modernTradeBankList().then((res) => {
    //         const bankList = res?.data?.data?.brand_name ?? [];
    //         setMTBankList(bankList);
    //     });
    // }, []);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.changePaymentMethod}
            onSave={() => methodForm.handleSubmit()}
        >
            <div className="p-3">
                <Select
                    dataSource={paymentMethodsList}
                    label={Messages.paymentMethod}
                    value={methodForm.values.paymentMethod}
                    error={methodForm.errors.paymentMethod}
                    getValue={(item: any) => item?.value}
                    getLabel={(item) => Messages[item?.label]}
                    onChange={(value) =>
                        methodForm.setFieldValue("paymentMethod", value)
                    }
                />
                {/* <Select
                className="mt-3"
                hidden={methodForm.values.method !== PAYMENT_MODERN_TRADE}
                dataSource={mtBankList}
                getLabel={(item) => (
                    <div className="flex items-center">
                        {item.logo && (
                            <img
                                src={item.logo}
                                className="w-4 h-4 mr-2"
                                alt=""
                            />
                        )}
                        {item.code}
                    </div>
                )}
                label={t("bankName")}
                value={methodForm.values.bankName}
                error={methodForm.errors.bankName}
                onChange={(value) =>
                    methodForm.setFieldValue("bankName", value)
                }
                placeholder={t("pleaseSelect")}
            /> */}
                {/* <Select
                className="mt-3"
                dataSource={NUMBER_INSTALMENT}
                label={t("numberOfInstalment")}
                value={methodForm.values.numberInstalment}
                error={methodForm.errors.numberInstalment}
                onChange={(value) =>
                    methodForm.setFieldValue("numberInstalment", value)
                }
                hidden={methodForm.values.method !== PAYMENT_MODERN_TRADE}
                placeholder={t("pleaseSelect")}
            /> */}
                <Select
                    className="mt-3"
                    hidden={
                        methodForm.values.paymentMethod !==
                        PaymentMethod.Payment_2C2P
                    }
                    label={Messages.paymentOption}
                    dataSource={payment2C2POptionsList}
                    value={methodForm.values.paymentOption}
                    getValue={(item: any) => item?.option}
                    getLabel={(item: any) => item?.title}
                    onChange={(value) => {
                        methodForm.setFieldValue("paymentOption", value);
                    }}
                />
                {/* <InputText
                    className="mt-3"
                    label={Messages.remark}
                    value={methodForm.values.remark}
                    error={methodForm.errors.remark as string}
                    name="remark"
                    onChange={methodForm.handleChange}
                    multiple
                /> */}
            </div>
        </Drawer>
    );
};

export default ChangePaymentMethodDrawer;
