import {
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { find, isString } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useUpdateQuotationForAdminMutation } from "../../../api/hooks";
import CustomerName from "../../../common/avatar/CustomerName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { QuotationDetailContext } from "../../../context/quotation";
import { IQuotation } from "../../../interfaces/quotation";
import Messages from "../../../languages/Messages";
import RemarkDrawer from "../share/RemarkDrawer";
import { CUSTOMER_SOURCES } from "../../../constant/customer";

const QuotationGeneralInformation = () => {
    const { quotation, loadQuotationDetail } = useContext(
        QuotationDetailContext
    );
    const [showEditModal, setShowEditModal] = useState(false);
    const [updateQuotation] = useUpdateQuotationForAdminMutation();

    const onUpdateRemark = (remark: string) => {
        if (!remark || !isString(remark)) {
            setShowEditModal(false);
            return;
        }
        Progress.show(
            {
                method: updateQuotation,
                params: [
                    {
                        variables: {
                            id: quotation?.id as string,
                            payload: {
                                remark,
                            },
                        },
                    },
                ],
            },
            (resp) => {
                Notifications.showSuccess(Messages.updateQuotationSuccessfull);
                loadQuotationDetail();
                setShowEditModal(false);
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    const GENERAL_KEYS: IRowsKey<IQuotation>[] = useMemo(
        () => [
            {
                id: "store",
                label: Messages.name,
                renderContent: ({ data }) => data?.name,
            },
            // {
            //     id: "typeOfTreatment",
            //     label: Messages.typeOfTreatment,
            //     renderContent: ({ data }) => data?.name,
            // },
            {
                id: "purchaseType",
                label: Messages.purchaseType,
                renderContent: ({ data }) => Messages[data],
            },
            {
                id: "sourceOfCustomer",
                label: Messages.customerSource,
                renderContent: ({ data, item }) => {
                    const source = find(
                        CUSTOMER_SOURCES,
                        (item) => item.id === data
                    );
                    return Messages[source?.label as any];
                },
            },
            {
                id: "customer",
                label: Messages.customer,
                renderContent: ({ data, item }) => (
                    <CustomerName
                        user={{ ...item.contact, ...item.customer }}
                    />
                ),
            },
            {
                id: "salesPerson",
                label: Messages.primarySalePerson,
                renderContent: ({ data }) => <UserAvatarName user={data} />,
            },
            {
                id: "createByAdmin",
                label: Messages.createdBy,
                renderContent: ({ data }) => <UserAvatarName user={data} />,
            },
            {
                id: "createdAt",
                label: Messages.createdBy,
                renderContent: ({ data }) => {
                    return !data
                        ? "N/A"
                        : TimeUtils.convertMiliToDateTime(data);
                },
            },
            {
                id: "expired",
                label: Messages.expiresAt,
                renderContent: ({ data }) => {
                    return !data
                        ? "N/A"
                        : TimeUtils.convertMiliToDateTime(data);
                },
            },
            {
                id: "remark",
                label: Messages.remark,
                renderContent: ({ data }) => {
                    return (
                        <>
                            {data || "N/A"}
                            {!quotation?.hasOrder && (
                                <div className="mt-2">
                                    <span
                                        className="cursor-pointer text-red-500"
                                        onClick={() => setShowEditModal(true)}
                                    >
                                        {Messages.edit}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                },
            },
        ],
        [quotation]
    );

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
            </div>
            <ViewRowInterchange
                keyList={GENERAL_KEYS}
                dataSource={quotation}
                variant="background"
            />
            {showEditModal && (
                <RemarkDrawer
                    open={showEditModal}
                    onSave={onUpdateRemark}
                    onClose={() => setShowEditModal(false)}
                    remark={quotation?.remark}
                />
            )}
        </div>
    );
};

export default QuotationGeneralInformation;
