import { useContext } from "react";
import { AppStateContext } from "../../../context/app";
import IUser from "../../../interfaces/user";
import Messages from "../../../languages/Messages";

const UserSelectedView = ({
    user,
    className,
}: {
    user: IUser | null;
    className?: string;
}) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    if (!user) return <div />;
    const { firstName, lastName, companyId, nickName, id } = user ?? {};
    return (
        <div
            className={`user-selected-view w-100 mt-3 bg-primary flex items-center ${className}`}
        >
            {user?.avatar ? (
                <img src={user?.avatar} className="image-square-medium" />
            ) : (
                <div className="image-square-medium bg-gray-200 flex items-center justify-center">
                    <span className="!text-white text-center font-weight-normal h1">
                        {firstName?.charAt(0)}
                    </span>
                </div>
            )}
            <div className="p-2 flex-column">
                <span
                    className="block mb-1 text-white font-weight-bold hover:cursor-pointer hover:text-blue-500"
                    onClick={() => {
                        setOpenAppDrawer({ open: true, userId: id });
                    }}
                >
                    {`${firstName} ${lastName} (${nickName})`}
                </span>
                <span className="block text-white text-sm opacity-75">{`${Messages.employeeId} : ${companyId}`}</span>
                {/* <span className="block text-white text-sm opacity-75">{`${Messages.lastOpd}: `}</span> */}
            </div>
        </div>
    );
};

export default UserSelectedView;
