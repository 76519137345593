import {
    Button,
    DialogManager,
    Drawer,
    IDrawerProps,
    IRowsKey,
    Notifications,
    Progress,
    ViewRowInterchange,
} from "d-react-components";
import { find } from "lodash";
import React, { useContext, useMemo } from "react";
import { useApproveUpdateInformationCustomerMutation } from "../../../api/hooks";
import { CustomerDetailContext } from "../../../context/customer";
import { IAddress } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import CustomerDetailPersonalInfo from "./CustomerDetailPersonalInfo";

export interface ICustomerUpdateInfoDrawerProps extends IDrawerProps {}

const CUSTOMER_RESIDENT_ADDRESS: IRowsKey<IAddress>[] = [
    {
        id: "country",
        label: Messages.country,
        renderContent: ({ data }) => data?.name ?? "N/A",
    },
    {
        id: "province",
        label: Messages.passportNo,
        renderContent: ({ data }) => data?.name ?? "N/A",
    },
    {
        id: "district",
        label: Messages.district,
        renderContent: ({ data }) => data?.name ?? "N/A",
    },
    {
        id: "subDistrict",
        label: Messages.subDistrict,
        renderContent: ({ data }) => data?.name ?? "N/A",
    },
    {
        id: "postcode",
        label: Messages.maritalStatus,
        renderContent: ({ data }) => data ?? "N/A",
    },
    {
        id: "address",
        label: Messages.address,
    },
    {
        id: "typeOfPlace",
        label: Messages.typeOfPlace,
        renderContent: ({ data }) => Messages[data],
    },
];

const CustomerUpdateInfoDrawer: React.FC<ICustomerUpdateInfoDrawerProps> = ({
    open,
    onClose,
}) => {
    const { customer, loadCustomerDetail } = useContext(CustomerDetailContext);
    const [approveUpdateInformationCustomer] =
        useApproveUpdateInformationCustomerMutation();

    const customerResidentAddress = useMemo(() => {
        return find(customer?.updateInfo?.address, (item) => item?.isDefault);
    }, [customer?.updateInfo?.address]);
    const handleSubmit = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantApproveThisCustomerInfo,
            () =>
                Progress.show(
                    {
                        method: approveUpdateInformationCustomer,
                        params: [
                            {
                                variables: {
                                    customerId: customer?.id,
                                },
                            },
                        ],
                    },
                    () => {
                        loadCustomerDetail();
                        Notifications.showSuccess(Messages.updateSuccess);
                    }
                )
        );
    };

    return (
        <Drawer
            width="1000px"
            size="auto"
            open={open}
            onClose={onClose}
            maskClosable={false}
            title={Messages.profileUpdateRequest}
            footer={
                <div className="w-100 p-3 d-flex">
                    <div className="flex-fill" />
                    <div>
                        <Button
                            content={Messages.approve}
                            className=""
                            onClick={(e) => handleSubmit()}
                        />
                    </div>
                </div>
            }
        >
            <CustomerDetailPersonalInfo
                editable={false}
                customerData={customer?.updateInfo}
            />
            {customer?.updateInfo?.address && (
                <div className="border p-3 mt-3">
                    <div className="flex-row-between-center mb-3">
                        <h5>{Messages.customerAddress}</h5>
                    </div>
                    <ViewRowInterchange
                        keyList={CUSTOMER_RESIDENT_ADDRESS}
                        dataSource={customerResidentAddress}
                        variant="background"
                    />
                </div>
            )}
        </Drawer>
    );
};

export default CustomerUpdateInfoDrawer;
