import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import StockAdjustmentAPI from "../../../../api/queries/stock-adjustment";
import { StockAdjustmentDetailContext } from "../../../../context/stock-adjustment";
import Messages from "../../../../languages/Messages";
import StockAdjustmentActivity from "./StockAdjustmentActivity";
import StockAdjustmentHeader from "./StockAdjustmentHeader";
import StockAdjustmentSummary from "./StockAdjustmentSummary";
import StockAdjustmentGeneral from "./StockAdjustmentGeneral";
import StockAdjustmentItems from "./StockAdjustmentItems";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <StockAdjustmentGeneral />,
    },
    {
        id: "service",
        label: Messages.items,
        component: <StockAdjustmentItems />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <StockAdjustmentActivity />,
    },
];

const StockAdjustmentDetail = () => {
    const { stockAdjustmentId } = useParams<any>();
    const [stockAdjustment, setStockAdjustment] = useState<any>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadBookingDetail();
    }, []);

    const loadBookingDetail = () => {
        Progress.show(
            {
                method: StockAdjustmentAPI.detail,
                params: [stockAdjustmentId],
            },
            setStockAdjustment as any
        );
    };

    if (!stockAdjustment) {
        return <div />;
    }

    return (
        <StockAdjustmentDetailContext.Provider
            value={{ stockAdjustment, setStockAdjustment }}
        >
            <div className="flex-column-container bg-white">
                <StockAdjustmentHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <StockAdjustmentSummary />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </StockAdjustmentDetailContext.Provider>
    );
};

export default StockAdjustmentDetail;
