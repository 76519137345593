import React from "react";
import IUser, { IUserRole } from "../interfaces/user";

////////// CRUD CONTEXT

interface IUserCrudState {
    userForm?: any;
    userUpdatePassForm?: any;
    roleList?: IUserRole[];
}

const userCrudState: IUserCrudState = {
    userForm: null,
    userUpdatePassForm: null,
    roleList: [],
};

export const UserCrudContext = React.createContext(userCrudState);

////////// ROLE CONTEXT

interface IUserRoleCrudState {
    roleForm: any;
}

const userRoleCrudState: IUserRoleCrudState = {
    roleForm: null,
};

export const UserRoleCrudContext = React.createContext(userRoleCrudState);

////////// DETAIL CONTEXT

interface IUserDetailState {
    user?: IUser;
    roleList?: IUserRole[];
    loadAdminDetail: () => void;
}

const userDetailState: IUserDetailState = {
    user: undefined,
    roleList: [],
    loadAdminDetail: () => {},
};

export const UserDetailContext = React.createContext(userDetailState);