import { StringUtils } from "d-react-components";
import { map } from "lodash";
import moment from "moment";
import {
    AdminUserBasic,
    PriceSetDto,
    PriceSetType,
    PromotionStatus,
} from "../api/hooks";
import { PromotionUpdateDrawerType } from "../context/promotion";
import { IBranch } from "./branch";
import { IProduct } from "./product";

export interface IPromotionProduct {
    product: IProduct;
    priceSet: Partial<PriceSetDto>[];
}

export interface IPromotion {
    id: string;
    updatedAt: any;
    createdAt: any;
    createByAdmin: AdminUserBasic;
    status: PromotionStatus;
    branch: IBranch[];
    documents?: string[];
    memo?: string;
    products?: IPromotionProduct[];
    promotionNo: string;
    start: Date;
    end: Date;
    title: string;
}

export const mapPromotionProductToServer = (products: any[]) => {
    return map(products, (i) => {
        const { children } = i || {};
        const priceSet = map(children, (j) => {
            return {
                priceSetType: j?.priceSetType,
                regularPrice: parseFloat(j?.regularPrice),
                salePrice: parseFloat(j?.newPrice),
            };
        });
        return {
            product: i?.id,
            priceSet: priceSet ?? [],
        };
    });
};

export const mapPromotionToServer = (input: any) => {
    const { status, start, end, branch, documents, title, memo, products } =
        input || {};

    return {
        status,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        branch: map(branch, (i) => i?.id),
        documents: map(documents, (i) => i?.fileUrl),
        title,
        memo,
        products: mapPromotionProductToServer(products),
    };
};

export const mapPromotionFilterToServer = (input: any, searchKey?: string) => {
    return {
        status: input?.status,
        branch: input?.branch?.id ? [input?.branch?.id] : undefined,
        searchKey,
    };
};

export const mapPromotionFilterToQueryParams = (input: any) => {
    return {
        status: input?.status,
        branch: input?.branch
            ? {
                  id: input?.branch?.id,
                  name: input?.branch?.name,
              }
            : undefined,
    };
};

export const mapPromotionProductToValuesProduct = (
    data: IPromotionProduct[]
) => {
    return data.map((item) => {
        const { priceSet: defaultPriceSet } = item?.product || {};
        return {
            ...item?.product,
            children: map(item?.priceSet ?? [], (i) => {
                const foundDefaultSet =
                    i?.priceSetType === PriceSetType.Default
                        ? { salePrice: item?.product?.salePrice }
                        : defaultPriceSet?.find(
                              (j) => j?.priceSetType === i?.priceSetType
                          );
                return {
                    priceSetType: i?.priceSetType,
                    regularPrice: i?.regularPrice,
                    salePrice: foundDefaultSet?.salePrice,
                    newPrice: i?.salePrice,
                    isChildren: true,
                    productId: item?.product?.id,
                    id: `${item?.product?.id}-${i?.priceSetType}`,
                };
            }),
        };
    });
};

export const mapPromotionDetailToValues = (data: IPromotion) => {
    const { start, end, branch, documents, title, memo, products } = data || {};

    const mappedProducts = products
        ? mapPromotionProductToValuesProduct(products)
        : [];
    const mappedDocuments = map(documents, (i) => {
        return {
            id: StringUtils.getUniqueID(),
            imageData: i,
            fileUrl: i,
        };
    });

    return {
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        branch,
        title,
        memo,
        documents: mappedDocuments,
        products: mappedProducts,
    };
};

export const PROMOTION_UPDATE_DRAWER_TYPES = [
    {
        id: PromotionUpdateDrawerType.MEMO,
        title: "memo",
        transformer: (input: any) => ({ memo: input?.memo }),
    },
    {
        id: PromotionUpdateDrawerType.BRANCH,
        title: "branch",
        transformer: (input: any) => ({
            branch: map(input?.branch, (j) => j?.id),
        }),
    },
    {
        id: PromotionUpdateDrawerType.PERIOD,
        title: "period",
        transformer: (input: any) => ({
            start: moment(input?.start).toDate(),
            end: moment(input?.end).toDate(),
        }),
    },
    {
        id: PromotionUpdateDrawerType.DOCUMENT,
        title: "documents",
        transformer: (input: any) => ({
            documents: map(input?.documents ?? [], (i) => i?.fileUrl),
        }),
    },
];
