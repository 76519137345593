import {
    AwesomeTableComponent,
    Button,
    DateInput,
    Icon,
} from "d-react-components";
import { filter, map } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { TransferVoucherCreateContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import VoucherFromOrderSelectDrawer from "../../voucher/share/VoucherFromOrderSelectDrawer";
import { getVoucherTableColumns } from "../../../common/vouchers/VoucherTableColumns";

const TransferVoucherFromOrderSelect = ({ className }: any) => {
    const { transferForm } = useContext(TransferVoucherCreateContext);
    const [openDrawer, setOpenDrawer] = useState(false);
    const formValues = transferForm?.values ?? {};
    const columns = [
        ...getVoucherTableColumns({
            showSaleDate: false,
            showFirstUsed: false,
            showLastUsed: false,
            showSalesPerson: false,
            showCoSalePerson: false,
            showChangeExpiryDate: true,
            renderChangeExpiryDate: (expiryDate: any, voucher: any) => {
                return (
                    <DateInput
                        className="min-w-[150px]"
                        value={expiryDate ? moment(expiryDate) : (null as any)}
                        onChange={(value) => onChangeExpireDate(voucher, value)}
                    />
                );
            },
        }),
        {
            title: Messages.action,
            dataIndex: "",
            render: (item: any) => (
                <Icon
                    name="delete"
                    className="cursor-default"
                    onClick={() => onRemoveOrderVoucher(item)}
                />
            ),
        },
    ];

    const onChangeExpireDate = (order: any, expiryDate: any) => {
        const result = map(formValues?.voucherOrders, (item) => {
            if (item.id === order.id) {
                return {
                    ...item,
                    expiryDate,
                };
            }
            return item;
        });
        transferForm.setFieldValue("voucherOrders", result);
    };

    const onRemoveOrderVoucher = (order: any) => {
        const result = filter(
            formValues?.voucherOrders,
            (item) => item.id !== order.id
        );
        transferForm.setFieldValue("voucherOrders", result);
    };

    return (
        <div className={className}>
            <div className="flex-row-between-center">
                <label>{Messages.voucher}</label>
                <Button onClick={() => setOpenDrawer(true)} className="">
                    {Messages.selectVoucher}
                </Button>
            </div>
            <AwesomeTableComponent
                classNameTable="mt-3"
                columns={columns}
                dataSource={formValues?.voucherOrders ?? []}
                className=""
            />
            {openDrawer && (
                <VoucherFromOrderSelectDrawer
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onSave={(value: any) => {
                        const orders = map(value, (item) => ({
                            ...item,
                            expiryDate: item?.voucher?.expiryDate,
                        }));
                        transferForm.setFieldValue("voucherOrders", orders);
                    }}
                    defaultValue={formValues?.voucherOrders ?? []}
                    customerId={formValues?.from?.id}
                />
            )}
        </div>
    );
};

export default TransferVoucherFromOrderSelect;
