import {
    AwesomeTableComponent,
    Button,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import React, { useRef } from "react";
import { generatePath, useNavigate } from "react-router";
import { useListEquipmentForAdminLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import PermissibleRender from "../../../common/PermissibleComponent";
import TableAction from "../../../common/TableActions";
import {
    EQUIPMENT_CONDITIONS,
    EQUIPMENT_VISIBILITIES,
} from "../../../constant/equipment";
import { PERMISSION } from "../../../constant/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const EquipmentTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [getListEquipment] = useListEquipmentForAdminLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            width: 200,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.EQUIPMENT_DETAIL, {
                        equipmentId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.serialNo,
            dataIndex: "serialNo",
        },
        {
            title: Messages.model,
            dataIndex: "model",
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (category) => category?.name,
        },
        {
            title: Messages.visibility,
            dataIndex: "visibility",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={EQUIPMENT_VISIBILITIES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.condition,
            dataIndex: "condition",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={EQUIPMENT_CONDITIONS}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.lastUpdated,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.EQUIPMENT_DETAIL, {
                        equipmentId: item.id,
                    })
                );
        }
    };

    const source = (pagingData: any) => {
        return getListEquipment({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickAddNew = () => {
        navigate(Path.EQUIPMENT_CREATE);
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    return (
        <div>
            <HeaderTable
                label={Messages.equipment}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onSubmitSearch={onSubmitSearch}
                showFilter={false}
                className="app-layout__page-header"
                customButtons={() => (
                    <PermissibleRender permission={PERMISSION.EQUIPMENT.CREATE}>
                        <Button size="small" onClick={onClickAddNew}>
                            {Messages.new}
                        </Button>
                    </PermissibleRender>
                )}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return (
                        res?.data?.listEquipmentForAdmin?.pagination?.items ?? 0
                    );
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default EquipmentTable;
