import classNames from "classnames";
import {
    Avatar,
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    Progress,
} from "d-react-components";
import { find, includes, isEmpty, lowerCase, map, reduce } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useUpdateEffect } from "react-use";
import {
    useListAdminRoleForAdminQuery,
    useListPicOfBranchForAdminLazyQuery,
    useListUserToAddBranchPicForAdminLazyQuery,
    useSummaryUserPerRoleBranchPicQuery,
    useUpdateBranchPicForAdminMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import Image from "../../../common/Image";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import {
    BranchDetailContext,
    BranchUserContext,
} from "../../../context/branch";
import { Action } from "../../../interfaces/common";
import IUser, { IUserRole } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";

const ROLE_ALL = {
    id: null,
    name: "All",
};

const UserRole = () => {
    const { branchId } = useParams<any>();
    const { roleList, selectedRole, setSelectedRole, reloadList } =
        useContext(BranchUserContext);
    const [textSearch, setTextSearch] = useState("");
    const { data: dataSummary, refetch: refetchSummary } =
        useSummaryUserPerRoleBranchPicQuery({
            variables: {
                branch: branchId as string,
            },
        });

    useUpdateEffect(() => {
        refetchSummary();
    }, [reloadList]);

    const rollAllTotal = useMemo(
        () =>
            reduce(
                dataSummary?.summaryUserPerRoleBranchPic?.data ?? [],
                (sum, item) => sum + (item?.number ?? 0),
                0
            ),
        [dataSummary]
    );

    const { data } = useListAdminRoleForAdminQuery({
        variables: {
            paginate: {
                page: 1,
                limit: 1000,
            },
        },
    });

    const renderRoleRow = (role: any, total: any) => {
        const isHide =
            !isEmpty(textSearch) &&
            !includes(lowerCase(role?.name), lowerCase(textSearch));
        const className = classNames(
            " p-3 justify-content-between align-items-center cursor-pointer",
            {
                "bg-primary-50": selectedRole?.id === role.id,
                hidden: isHide,
                "d-flex": !isHide,
            }
        );
        return (
            <div className={className} onClick={() => setSelectedRole(role)}>
                <span>{role?.name}</span>
                <div className="w-[24px] h-[24px] bg-red text-center text-white rounded-circle flex items-center justify-content-center">
                    {total ?? 0}
                </div>
            </div>
        );
    };

    return (
        <>
            <InputTextSearch
                className="my-3"
                onChange={(event: any) => setTextSearch(event.target.value)}
                value={textSearch}
            />
            {renderRoleRow(ROLE_ALL, rollAllTotal)}
            {map(data?.listAdminRoleForAdmin?.data ?? [], (role) => {
                const summary = find(
                    dataSummary?.summaryUserPerRoleBranchPic?.data ?? [],
                    (item) => item?.id === role?.id
                );
                return renderRoleRow(role, summary?.number ?? 0);
            })}
        </>
    );
};

const BranchUser = () => {
    const { selectedRole, reloadList, setReloadList } =
        useContext(BranchUserContext);
    const { branchId } = useParams<any>();
    const [textSearch, setTextSearch] = useState<string>("");
    const [getListPic] = useListPicOfBranchForAdminLazyQuery();
    const [removePic] = useUpdateBranchPicForAdminMutation();
    const tableRef = useRef<any>();

    const reloadTable = () => {
        tableRef && tableRef?.current?.refresh();
    };

    useEffect(() => {
        reloadTable();
    }, [selectedRole]);

    useUpdateEffect(() => {
        reloadTable();
    }, [reloadList]);

    const source = (paging: any) => {
        return getListPic({
            variables: {
                branchId: branchId as string,
                paginate: {
                    page: paging.pageIndex,
                    limit: paging.pageSize,
                    search: textSearch,
                },
                ...(selectedRole ? { role: selectedRole.id } : {}),
            },
            fetchPolicy: "no-cache",
        });
    };
    const transformer = (res: any) => {
        return res?.data?.listPicOfBranchForAdmin?.data ?? [];
    };

    const handleRemovePic = (id: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemoveBranch,
            () =>
                Progress.show(
                    {
                        method: removePic,
                        params: [
                            {
                                variables: {
                                    id: branchId as string,
                                    userIds: [id],
                                    action: Action.Remove,
                                },
                            },
                        ],
                    },
                    () => {
                        setReloadList(!reloadList);
                    }
                )
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.staff,
            dataIndex: "firstName",
            width: 200,
            render: (data, item, index) => (
                <div className="flex items-center">
                    <Avatar
                        src={item?.avatar}
                        text={data}
                        size="x-small"
                        variant="square"
                    />
                    <span className="ml-2">{`${item.firstName} ${item.lastName}`}</span>
                </div>
            ),
        },
        {
            title: Messages.role,
            dataIndex: "role",
            width: 100,
            render: (data, item, index) => data?.name,
        },
        {
            title: Messages.action,
            dataIndex: "id",
            width: 40,
            render: (data) => {
                return (
                    <Icon
                        className="text-primary cursor-pointer"
                        name="remove_circle_outline"
                        onClick={() => handleRemovePic(data)}
                    />
                );
            },
        },
    ];

    return (
        <>
            <InputTextSearch
                onChange={(event: any) => setTextSearch(event.target.value)}
                placeholder={Messages.search}
                value={textSearch}
                onSubmit={reloadTable}
            />
            <AwesomeTableComponent
                ref={tableRef}
                columns={columns}
                source={source}
                transformer={transformer}
                getTotalItems={(res) => {
                    return (
                        res?.data?.listPicOfBranchForAdmin?.pagination?.items ??
                        0
                    );
                }}
                className="mt-3"
            />
        </>
    );
};

const BranchPic = () => {
    const [showModal, setShowModal] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [reloadList, setReloadList] = useState();

    return (
        <BranchUserContext.Provider
            value={{
                roleList,
                selectedRole,
                setSelectedRole,
                reloadList,
                setReloadList,
            }}
        >
            <div className="grid grid-cols-4 gap-2 mt-3">
                <div className="col-span-1">
                    <div className="border p-3">
                        <h5 className="text-[14px] flex-1">
                            {Messages.allRoles}
                        </h5>
                        <UserRole />
                    </div>
                </div>
                <div className="col-span-3">
                    <div className="flex items-center mb-3">
                        <h5 className="text-[14px] flex-1">{Messages.pic}</h5>
                        <Button
                            size="small"
                            iconName="add"
                            classNameIcon="mx-0 mr-1"
                            onClick={() => setShowModal(true)}
                        >
                            {Messages.add}
                        </Button>
                    </div>
                    <BranchUser />
                </div>
            </div>
            {showModal && (
                <ModalAddPic
                    open={showModal}
                    onClose={() => setShowModal(false)}
                />
            )}
        </BranchUserContext.Provider>
    );
};

const ModalAddPic = ({ open, onClose }: any) => {
    const { branch, loadBranchDetail } = useContext(BranchDetailContext);
    const { reloadList, setReloadList } = useContext(BranchUserContext);
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    const [getListUser] = useListUserToAddBranchPicForAdminLazyQuery();
    const [addPic] = useUpdateBranchPicForAdminMutation();

    const handleSubmit = () => {
        if (selectedUsers.length) {
            Progress.show(
                {
                    method: addPic,
                    params: [
                        {
                            variables: {
                                id: branch?.id as string,
                                userIds: selectedUsers.map((u) => u.id),
                                action: Action.Add,
                            },
                        },
                    ],
                },
                (resp: any) => {
                    setReloadList(!reloadList);
                    Notifications.showSuccess(
                        Messages.updateBranchSuccessfully
                    );
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        } else {
            onClose();
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.addPIC}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <SelectInfinityFilter
                    value={selectedUsers}
                    multiple
                    onChange={(value) => setSelectedUsers(value as IUser[])}
                    fetchFn={(params: any, paging: any) =>
                        getListUser({
                            variables: {
                                branchId: branch?.id as string,
                                paginate: {
                                    search: params?.search ?? "",
                                    limit: paging.pageSize,
                                    page: paging.pageIndex,
                                },
                            },
                        })
                    }
                    dataKey="listUserToAddBranchPicForAdmin"
                    placeholder={Messages.pleaseSearchAndSelect}
                    getLabel={(item) => `${item?.firstName} ${item?.lastName}`}
                />
            </div>
        </Drawer>
    );
};

export default BranchPic;
