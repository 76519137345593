import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";
import { F_EQUIPMENT_BASIC } from "./equipment";
import { F_PRODUCT_BASIC } from "./product";
import { F_VEHICLE_BASIC } from "./vehicle";

export const F_DELIVERY_DRIVER_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_DELIVERY_DRIVER_CONFIRMATION on DeliveryConfirmationDto {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        status
        driver {
            createByAdmin {
                ...F_ADMIN_USER_BASIC
            }
            createdAt
            updatedAt
            remark
            fullName
            proofs
            signature
        }
        staff {
            createByAdmin {
                ...F_ADMIN_USER_BASIC
            }
            createdAt
            updatedAt
            remark
            fullName
            proofs
            signature
        }
    }
`;

export const F_DELIVERY_DRIVER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_VEHICLE_BASIC}
    ${F_BRANCH_BASIC}
    ${F_PRODUCT_BASIC}
    ${F_EQUIPMENT_BASIC}
    ${F_DELIVERY_DRIVER_CONFIRMATION}
    fragment F_DELIVERY_DRIVER on DeliveryDto {
        id
        updatedAt
        createdAt
        deliveryId
        status
        type
        transferFor
        refId
        estDateOfArrival
        deliveryNo
        origin {
            ...F_BRANCH_BASIC
        }
        destination {
            ...F_BRANCH_BASIC
        }
        driver {
            ...F_ADMIN_USER_BASIC
        }
        vehicle {
            ...F_VEHICLE_BASIC
        }
        stocks {
            product {
                ...F_PRODUCT_BASIC
            }
            quantity
            staffReceived
            driverReceived
        }
        stockOffList {
            product {
                ...F_PRODUCT_BASIC
            }
            quantity
            staffReceived
            driverReceived
        }
        equipments {
            equipment {
                ...F_EQUIPMENT_BASIC
            }
            quantity
            staffReceived
            driverReceived
        }
        equipmentOffList {
            equipment {
                ...F_EQUIPMENT_BASIC
            }
            quantity
            staffReceived
            driverReceived
        }
        originConfirmation {
            ...F_DELIVERY_DRIVER_CONFIRMATION
        }
        destinationConfirmation {
            ...F_DELIVERY_DRIVER_CONFIRMATION
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_DELIVERY_DRIVERS = gql`
    ${F_DELIVERY_DRIVER}
    ${F_PAGINATE}
    fragment F_DELIVERY_DRIVERS on DeliveryPaginateResponse {
        data {
            ...F_DELIVERY_DRIVER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
