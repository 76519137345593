import { gql } from "@apollo/client";
import { F_PRODUCT_SALESPERSON } from "./order";
import { F_PRODUCT_BASIC } from "./product";
import { F_ADMIN_BANK, F_ADMIN_PAYMENT_PROOF } from "./payment";
import { F_TREATMENT_USER } from "./treatment";
import { F_BRANCH_BASIC } from "./branch";

export const F_REPORT_CASHIER = gql`
    ${F_ADMIN_PAYMENT_PROOF}
    fragment F_REPORT_CASHIER on ReportCashierOrder {
        id
        createdAt
        orderNo
        subtotal
        total
        settleAmount
        unsettleAmount
        remainingAmount
        isFullyPaid
        store {
            id
            code
            name
        }
        customer {
            customerNo
        }
        payments {
            id
            paymentNo
            total
            status
            paymentMethod
            sourceOfPayment
            paymentOption
            paymentCardDevice
            hasOrder
            refId
            createdAt
            creditCard {
                type
                number
                expiryDate
            }
            proof {
                ...F_ADMIN_PAYMENT_PROOF
            }
            confirmation {
                at
                dateOfReceiving
                remark
                # bank {
                #     ...F_ADMIN_BANK
                # }
            }
        }
    }
`;

export const F_REPORT_SALESPERSON_PRODUCT = gql`
    ${F_PRODUCT_BASIC}
    ${F_PRODUCT_SALESPERSON}
    fragment F_REPORT_SALESPERSON_PRODUCT on AdminOrderProduct {
        quantity
        regularPrice
        salePrice

        product {
            ...F_PRODUCT_BASIC
            category {
                id
                name
                categoryNo
                status
                commissionType
            }
        }
        salesPerson {
            ...F_PRODUCT_SALESPERSON
        }
    }
`;

export const F_REPORT_SALESPERSON_USER = gql`
    ${F_REPORT_SALESPERSON_PRODUCT}
    fragment F_REPORT_SALESPERSON_USER on ReportSalespersonUser {
        id
        createdAt
        orderNo
        total
        status
        customer {
            id
        }
        store {
            id
            code
            name
        }
        salesPerson {
            id
            firstName
            lastName
            nickName
        }
        products {
            ...F_REPORT_SALESPERSON_PRODUCT
        }
        manualDiscount {
            discountType
            discountValue
            total
            product {
                id
                name
                sku
            }
        }
    }
`;

export const F_REPORT_SALESPERSON_MONTHLY = gql`
    fragment F_REPORT_SALESPERSON_MONTHLY on ReportSalespersonMonthly {
        user {
            id
            firstName
            lastName
            nickName
        }
        totalCustomerServed
        todaySaleAmount
        firstToYesterdayAmount
        monthToDateAmount
    }
`;

export const F_CUSTOMER_SOURCE = gql`
    fragment F_CUSTOMER_SOURCE on ReportCustomerSource {
        source
        customerQty
        totalSaleAmount
    }
`;
export const F_DOCTOR_SUMMARY = gql`
    fragment F_DOCTOR_SUMMARY on ReportDoctorSummary {
        totalTreatmentJoined
        totalHourTreatmentJoined
        totalDoctorFee
        doctor {
            id
            firstName
            lastName
            companyId
        }
    }
`;

export const F_TREATMENT_PIC_SUMMARY = gql`
    ${F_BRANCH_BASIC}
    ${F_TREATMENT_USER}
    fragment F_TREATMENT_PIC_SUMMARY on ReportTreatmentPicSummary {
        treatments {
            id
            treatmentNo
            updatedAt
            createdAt
            status
            day
            startDate
            endDate
            scheduleDate
            schedule {
                start
                end
            }

            doctor {
                ...F_TREATMENT_USER
            }
            branch {
                ...F_BRANCH_BASIC
            }

            jobCostAssessment
            manualJobCostAssessment

            PIC {
                ...F_TREATMENT_USER
            }
        }
        pic {
            id
            firstName
            lastName
            companyId
        }
    }
`;
