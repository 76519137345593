import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router";
import { useGetPaymentMethodSettingListLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import {
    PAYMENT_METHOD_STATUS,
    PAYMENT_METHOD_STATUSES,
} from "../../../constant/payment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const PaymentMethodTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [getListPaymentMethodSetting] = useGetPaymentMethodSettingListLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            width: 200,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.PAYMENT_METHOD_DETAIL, {
                        paymentId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={PAYMENT_METHOD_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.priority,
            dataIndex: "priority",
        },
        {
            title: Messages.title,
            dataIndex: "title",
        },
        {
            title: Messages.description,
            dataIndex: "description",
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.PAYMENT_METHOD_DETAIL, {
                        paymentId: item.id,
                    })
                );
        }
    };

    const source = (pagingData: any) => {
        return getListPaymentMethodSetting({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    return (
        <div>
            <HeaderTable
                label={Messages.paymentMethod}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onSubmitSearch={onSubmitSearch}
                showFilter={false}
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default PaymentMethodTable;
