import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useParams } from "react-router";
import { useGetCustomerDoctorOrderHistoryLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CustomerDetailDoctorOrder = () => {
    const { customerId } = useParams<any>();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [getCustomerDoctorOrderHistory] =
        useGetCustomerDoctorOrderHistoryLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.doctorOrderNo,
            dataIndex: "doctorOrderNo",
            width: 100,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.subject,
            dataIndex: "doctorSubject",
            render: (item) => item || "N/A",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            align: "center",
            width: 150,
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={DOCTOR_ORDER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        if (!customerId) {
            return Promise.resolve();
        }
        return getCustomerDoctorOrderHistory({
            variables: {
                customerId,
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const refreshTable = () => {
        return tableRef.current && tableRef.current.refresh();
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        refreshTable();
    }, 500);

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.recentDoctorOrders}</h5>
                {/* <Button iconName="add" onClick={() => {}} className="">
                    {Messages.new}
                </Button> */}
            </div>
            <InputTextSearch
                className="mt-3"
                onChange={(event: any) => onChangeSearch(event.target.value)}
            />
            <AwesomeTableComponent
                className="mt-3"
                ref={tableRef}
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
        </div>
    );
};

export default CustomerDetailDoctorOrder;
