import { Notifications, Progress } from "d-react-components";
import { pick } from "lodash";
import React, { useContext, useState } from "react";
import { useUpdateOrderForAdminMutation } from "../../../api/hooks";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import NewCustomerDrawer from "../../quotation/share/NewCustomerDrawer";

const OrderContact = ({ onUpdated }: any) => {
    const { order } = useContext(OrderDetailContext);
    const [showEditModal, setShowEditModal] = useState(false);
    const [updateOrder] = useUpdateOrderForAdminMutation();
    const { contact = {} } = order ?? {};

    const onUpdateContact = (contact: any) => {
        Progress.show(
            {
                method: updateOrder,
                params: [
                    {
                        variables: {
                            id: order?.id as string,
                            payload: {
                                contact: {
                                    ...pick(contact, [
                                        "firstName",
                                        "lastName",
                                        "phone",
                                        "email",
                                    ]),
                                },
                            },
                        },
                    },
                ],
            },
            (resp) => {
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
                setShowEditModal(false);
                onUpdated();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <>
            <div className="border p-3 mb-3 mb-3">
                <div className="flex mb-2 items-center">
                    <h5 className="font-semibold flex-1">
                        {Messages.orderContact}
                    </h5>
                    <a onClick={() => setShowEditModal(true)}>
                        {contact ? Messages.edit : Messages.add}
                    </a>
                </div>
                {contact && (
                    <>
                        <span className="block mb-1">
                            {contact.firstName} {contact.lastName}
                        </span>
                        <span className="block mb-1">{contact.email}</span>
                        <span className="block mb-1">{contact.phone}</span>
                    </>
                )}
            </div>
            {showEditModal && (
                <NewCustomerDrawer
                    open={showEditModal}
                    onSave={onUpdateContact}
                    onClose={() => setShowEditModal(false)}
                    contact={contact ?? {}}
                />
            )}
        </>
    );
};

export default OrderContact;
