import {
    Button,
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
    useCreateAdminRoleForAdminMutation,
    useDetailAdminRoleForAdminLazyQuery,
    useUpdateAdminRoleForAdminMutation,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import VerticalTabView from "../../../common/VerticalTabView";
import {
    PERMISSION,
    PERMISSION_TYPE,
    PERMISSION_TYPES,
} from "../../../constant/user";
import { UserRoleCrudContext } from "../../../context/user";
import { UserRoleSchema } from "../../../formschema/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserRoleCrudSetting from "./UserRoleCrudSetting";

const RoleGeneral = () => {
    const { roleForm } = useContext(UserRoleCrudContext);
    const { values, errors } = roleForm;
    return (
        <>
            <h5 className="text-[12px] flex-1 mb-3">
                {Messages.generalInformation}
            </h5>
            <InputText
                placeholder={Messages.name}
                onChange={(event) =>
                    roleForm.setFieldValue("name", event?.target?.value)
                }
                value={values?.name}
                error={errors?.name as any}
                label={Messages.name}
            />
        </>
    );
};

const RoleAccess = () => {
    const { roleForm } = useContext(UserRoleCrudContext);
    const { values, errors } = roleForm;
    const onChangePermissionType = (value: any) => {
        roleForm.setFieldValue("permissionType", value);
    };
    return (
        <>
            <h5 className="text-[12px] flex-1 mb-3">
                {Messages.accessConfiguration}
            </h5>
            <Select
                className="mt-3"
                value={values?.permissionType}
                dataSource={PERMISSION_TYPES}
                onChange={onChangePermissionType}
                label={Messages.preset}
            />
            {values.permissionType === PERMISSION_TYPE.CUSTOM && (
                <UserRoleCrudSetting />
            )}
        </>
    );
};

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <RoleGeneral />,
    },
    {
        id: "access",
        label: Messages.accessConfiguration,
        component: <RoleAccess />,
    },
];

const UserRoleCrud = () => {
    const navigate = useNavigate();
    const { roleId } = useParams<any>();
    const isEdit = useMemo(() => !!roleId, [roleId]);
    const [selectedTab, setSelectedTab] = useState<any>(TABS[0]);
    const [getRoleDetail] = useDetailAdminRoleForAdminLazyQuery();
    const [createRole] = useCreateAdminRoleForAdminMutation();
    const [updateRole] = useUpdateAdminRoleForAdminMutation();

    const roleForm = useFormik<any>({
        initialValues: {
            permissionType: PERMISSION_TYPE.ALL,
            permissions: [],
            // permissions: getDefaultRolePermissions(),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UserRoleSchema,
        onSubmit: (values: any) => {
            // const input = mapUserRoleToSever(values);
            if (isEdit) {
                onUpdate(values);
            } else {
                onCreate(values);
            }
        },
    });

    const { values, setValues } = roleForm;

    useEffect(() => {
        isEdit && loadRoleDetail();
    }, []);

    const setRoleValue = (res: any) => {
        const roleDetail = res?.data?.detailAdminRoleForAdmin?.data;
        setValues({
            name: roleDetail.name,
            permissionType: roleDetail.isAdmin
                ? PERMISSION_TYPE.ALL
                : PERMISSION_TYPE.CUSTOM,
            permissions: roleDetail.permissions,
        });
    };

    const loadRoleDetail = () => {
        Progress.show(
            {
                method: getRoleDetail,
                params: [
                    {
                        variables: {
                            id: roleId as string,
                        },
                    },
                ],
            },
            (res) => setRoleValue(res)
        );
    };

    const onCreate = (input: any) => {
        Progress.show(
            {
                method: createRole,
                params: [
                    {
                        variables: {
                            payload: {
                                isAdmin:
                                    values?.permissionType ===
                                    PERMISSION_TYPE.ALL,
                                name: values?.name,
                                permissions:
                                    values?.permissionType ===
                                    PERMISSION_TYPE.ALL
                                        ? []
                                        : values?.permissions,
                            },
                        },
                    },
                ],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.createRoleSuccess);
                navigate(
                    generatePath(Path.USER_ROLE_DETAIL, {
                        roleId: res?.data?.createAdminRoleForAdmin?.data?.id,
                    })
                );
            }
        );
    };

    const onUpdate = (input: any) => {
        Progress.show(
            {
                method: updateRole,
                params: [
                    {
                        variables: {
                            id: roleId,
                            payload: {
                                isAdmin:
                                    values?.permissionType ===
                                    PERMISSION_TYPE.ALL,
                                name: values?.name,
                                permissions:
                                    values?.permissionType ===
                                    PERMISSION_TYPE.ALL
                                        ? []
                                        : values?.permissions,
                            },
                        },
                    },
                ],
            },
            (roleDetail: any) => {
                Notifications.showSuccess(Messages.updateRoleSuccess);
            }
        );
    };

    return (
        <UserRoleCrudContext.Provider
            value={{
                roleForm,
            }}
        >
            <Header
                className="sticky top-0"
                title={Messages.role}
                showCancel={false}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={
                                isEdit
                                    ? PERMISSION.ADMIN.ROLE.UPDATE
                                    : PERMISSION.ADMIN.ROLE.CREATE
                            }
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => roleForm.handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="h-100 overflow-auto p-3 bg-white">
                <div className="row">
                    <div className="col-sm-3">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-9">
                        <div className="border p-4">
                            {
                                find(TABS, (item) => selectedTab.id === item.id)
                                    ?.component
                            }
                        </div>
                    </form>
                </div>
            </div>
        </UserRoleCrudContext.Provider>
    );
};

export default UserRoleCrud;
