import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_MATERIAL_BASIC } from "./material";
import { F_PRODUCT_BASIC } from "./product";
import { F_JOB_COST } from "./job-cost";

export const F_SERVICE_CATEGORY = gql`
    fragment F_SERVICE_CATEGORY on AdminCategoryService {
        id
        updatedAt
        createdAt
        name
        categoryNo
        status
        description
    }
`;

export const F_SERVICE_CATEGORIES = gql`
    ${F_SERVICE_CATEGORY}
    ${F_PAGINATE}
    fragment F_SERVICE_CATEGORIES on ListAdminCategoryServiceResponse {
        data {
            ...F_SERVICE_CATEGORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_SEARCH_SERVICE_CATEGORY = gql`
    fragment F_SEARCH_SERVICE_CATEGORY on AdminSearchCategoryService {
        id
        name
        categoryNo
        status
    }
`;

export const F_SEARCH_SERVICE_CATEGORIES = gql`
    ${F_SEARCH_SERVICE_CATEGORY}
    ${F_PAGINATE}
    fragment F_SEARCH_SERVICE_CATEGORIES on SearchAdminCategoryServiceResponse {
        data {
            ...F_SEARCH_SERVICE_CATEGORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;


export const F_SERVICE_CONFIGURATION = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_SERVICE_CATEGORY}
    ${F_MATERIAL_BASIC}
    ${F_JOB_COST}
    fragment F_SERVICE_CONFIGURATION on AdminServiceConfiguration {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        name
        serviceNo
        status
        procedureTime
        description
        jobCostTemplate
        surgeryType
        defaultSaleCommission
        isModifiedJobCost
        jobCost {
            ...F_JOB_COST
        }
        category {
            ...F_SERVICE_CATEGORY
        }
        materialPreset {
            quantity
            hasPermissionAdjustment
            material {
                ...F_MATERIAL_BASIC
            }
        }
        medicinePreset {
            quantity
            hasPermissionAdjustment
            product {
                id
                name
                productNo
                status
                sku
                unit
                description
                gallery
                thumbnail
            }
        }
        servicePreset {
            quantity
            hasPermissionAdjustment
            product {
                id
                name
                productNo
                status
                sku
                unit
                description
                gallery
                thumbnail
            }
        }
        servicePreset {
            quantity
            hasPermissionAdjustment
            product {
                id
                name
                productNo
                status
                sku
                unit
                description
                gallery
                thumbnail
            }
        }
    }
`;

export const F_SERVICE_CONFIGURATIONS = gql`
    ${F_SERVICE_CONFIGURATION}
    ${F_PAGINATE}
    fragment F_SERVICE_CONFIGURATIONS on ListAdminServiceConfigurationResponse {
        data {
            ...F_SERVICE_CONFIGURATION
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
