import classNames from "classnames";
import { TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const OrderDOReferences = () => {
    const { order } = useContext(OrderDetailContext);
    const { doctorOrder } = (order as IOrder) ?? {};
    if (!doctorOrder) {
        return <></>;
    }
    return (
        <div className="border p-3 mb-3">
            <div className="flex">
                <h5 className="font-semibold mb-2">
                    {Messages.doctorOrderReferences}
                </h5>
            </div>
            {doctorOrder.map((order, index) => (
                <div
                    key={index}
                    className={classNames("pt-2 flex-column", {
                        "pb-2 border-b": index + 1 !== doctorOrder.length,
                    })}
                >
                    <span>
                        <AppLink
                            to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                                orderId: order.id,
                            })}
                        >
                            #{order.doctorOrderNo}
                        </AppLink>
                    </span>
                    <span>
                        {Messages.createdOn}{" "}
                        {TimeUtils.convertMiliToDateTime(order.createdAt)}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default OrderDOReferences;
