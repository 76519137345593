import styled from "@emotion/styled";
import ClassNames from "classnames";
import { Button, Icon } from "d-react-components";
import { useFormikContext } from "formik";
import { filter, isEmpty, map, reduce } from "lodash";
import { Fragment, useContext, useMemo } from "react";
import CurrencyFormat from "react-currency-format";
import { QuotationCreateContext } from "../../../context/quotation";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import { groupCredits, groupVouchers } from "../../../utils/Utils";
import { getProductOrderId } from "../crud/QuotationProductActions";
import { DiscountType } from "../../../api/hooks";
import Tooltip from "../../../common/views/Tooltip";
// import { CART_RULE_ACTION_KEY, FREE_GIFT_ACTION_KEY } from "@constants/order";
// import {
//     ADDITIONAL_DISCOUNT,
//     GP_DISCOUNT,
//     INTERNAL_DISCOUNT,
//     MEMBER_DISCOUNT,
//     MT_CREDIT_DISCOUNT,
// } from "@constants/discount";
// import { getPaidFromPayments } from "@helpers/payment";

// const convertMoney = StringUtils.moneyThaiFormat;
// const fixed = CART_RULE_ACTION_KEY.discountFix.id;
// const percentage = CART_RULE_ACTION_KEY.discountPercentage.id;

// const getDisplayCode = (item: any) => {
//   if (item?.code?.code) {
//     return `(${item.code.code.toUpperCase()})`;
//   }
//   if (item?.selfDiscount) {
//     switch (item?.action?.specific) {
//       case fixed:
//         return `(${StringUtils.moneyThaiFormat(item?.action?.amount)})`;
//       case percentage:
//         return `(${item?.action?.percentage}%)`;
//       default:
//         return "";
//     }
//   }
//   return "";
// };

// const showRemove = (item: any) => {
//   if (item?.action.level === GP_DISCOUNT) return false;
//   return (
//     item?.selfDiscount ||
//     !!item?.code?.code ||
//     item?.isEmployeeDiscount ||
//     item.isChainDiscount
//   );
// };

// const CouponRow = ({ item, removeCoupon, discountMoney }: any) => {
//   const {
//     ruleid,
//     selfDiscount,
//     approvedBy,
//     isEmployeeDiscount,
//     employeeDiscountCode,
//   } = item;
//   const { t } = useTranslation();

//   const displayCode = getDisplayCode(item);
//   const ruleIdText = ruleid ? `(${t("ruleid")}: ${ruleid})` : "";
//   const selfRequestText = selfDiscount ? `(${t("manualDiscount")})` : "";
//   const employeeDiscountText = isEmployeeDiscount
//     ? `(${t("employeeDiscount")}: ${employeeDiscountCode})`
//     : "";

//   const getDiscountTitle = (coupon: any) => {
//     switch (coupon?.action?.level) {
//       case GP_DISCOUNT:
//         return t("gpDiscount");
//       case INTERNAL_DISCOUNT:
//         return `${t("internalDiscount")} ${coupon.internalDiscountIndex + 1}`;
//       case MEMBER_DISCOUNT:
//         return t("mtMemberDiscount");
//       case ADDITIONAL_DISCOUNT:
//         return `${t("mtAdditionalDiscount")} ${
//           coupon.additionalDiscountIndex + 1
//         }`;
//       case MT_CREDIT_DISCOUNT:
//         return t("mtCreditCardDiscount");
//       default:
//         return t("discount");
//     }
//   };

//   const getDiscountAmount = (coupon: any, amount: any) => {
//     let discountAmount = `-${StringUtils.moneyThaiFormatFixed2(amount)}`;
//     const specific = coupon?.action?.specific;
//     if (
//       specific === FREE_GIFT_ACTION_KEY.discountPercentage.id ||
//       specific === FREE_GIFT_ACTION_KEY.discountPercentageCart.id
//     ) {
//       discountAmount += `(${coupon?.action?.percentage}%)`;
//     } else if (
//       specific === FREE_GIFT_ACTION_KEY.freegift.id ||
//       specific === FREE_GIFT_ACTION_KEY.buyXGetX.id ||
//       specific === FREE_GIFT_ACTION_KEY.buyXGetBundle.id
//     ) {
//       discountAmount = t("freeGift");
//     }
//     return discountAmount;
//   };

//   return (
//     <tr>
//       <td colSpan={2} align="left" className="border-b py-2 text-sm">
//         <div className="flex flex-column align-items-start">
//           <div className="flex items-center">
//             <div>
//               <span className="text-sm">
//                 {`${getDiscountTitle(item)}  `}
//                 {`${displayCode} `}
//                 {`${ruleIdText} `}
//                 {`${selfRequestText} `}
//                 {employeeDiscountText}
//               </span>
//               {approvedBy && (
//                 <div className="text-success text-sm flex items-center">
//                   <span>{`${t("approvedBy")}  `}</span>
//                   <img
//                     src={approvedBy?.avatar}
//                     className="w-6 h-6 object-cover rounded-full mx-1"
//                     alt=""
//                   />
//                   <span>{`${approvedBy?.fullname}  `}</span>
//                 </div>
//               )}
//             </div>
//             {showRemove(item) && (
//               <span
//                 onClick={() => removeCoupon(item)}
//                 className="text-error text-sm cursor-pointer ml-1"
//               >
//                 <Icon name="delete" />
//               </span>
//             )}
//           </div>
//         </div>
//       </td>
//       <td colSpan={2} align="right" className="border-b py-2 text-sm">
//         <span className="text-success text-sm">
//           {getDiscountAmount(item, discountMoney)}
//         </span>
//       </td>
//     </tr>
//   );
// };

const CouponRow = ({ removeCoupon, item }: any) => {
    return (
        <tr>
            <td colSpan={2} align="left" className="border-b py-2 text-sm">
                <div className="flex flex-column align-items-start">
                    <div className="flex items-center">
                        <div>
                            <span className="text-sm">
                                {Messages.discount}({item?.voucher?.code})
                            </span>
                        </div>
                        <span
                            onClick={() => removeCoupon(item)}
                            className="text-error text-sm cursor-pointer ml-1"
                        >
                            <Icon name="delete" />
                        </span>
                    </div>
                </div>
            </td>
            <td colSpan={2} align="right" className="border-b py-2 text-sm">
                <span className="text-success text-sm">
                    -
                    <CurrencyFormat
                        displayType="text"
                        thousandSeparator
                        prefix="฿"
                        value={item?.amount}
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </span>
            </td>
        </tr>
    );
};

const CreditRow = ({ onRemove, item }: any) => {
    return (
        <tr>
            <td colSpan={2} align="left" className="border-b py-2 text-sm">
                <div className="flex flex-column align-items-start">
                    <div className="flex items-center">
                        <div>
                            <span className="text-sm">
                                {Messages.creditVoucher}(
                                {item?.credit?.creditNo})
                            </span>
                        </div>
                        <span
                            onClick={() => onRemove(item)}
                            className="text-error text-sm cursor-pointer ml-1"
                        >
                            <Icon name="delete" />
                        </span>
                    </div>
                </div>
            </td>
            <td colSpan={2} align="right" className="border-b py-2 text-sm">
                <span className="text-success text-sm">
                    -
                    <CurrencyFormat
                        displayType="text"
                        thousandSeparator
                        prefix="฿"
                        value={item?.amount}
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </span>
            </td>
        </tr>
    );
};

const PriceTable = (props: any) => {
    const {
        cartValue,
        setCartValue,
        checkCartValue,
        manualDiscount,
        setManualDiscount,
        productList,
        productCustomList,
    } = useContext(QuotationCreateContext);
    const { values } = useFormikContext<any>();
    const {
        manualDiscount: manualDiscountCart,
        subtotal,
        total,
    } = cartValue || {};

    console.log("productList", productList);
    console.log("productCustomList", productCustomList);

    const orderTotalVAT = useMemo(() => {
        const nonVatProducts = filter(
            productList,
            (item: any) => item?.isNonVAT
        );
        return (
            (total ?? 0) -
            reduce(
                nonVatProducts,
                (sum, item: any) =>
                    sum + (item?.salePrice ?? 0) * (item.quantity ?? 0),
                0
            )
        );
    }, [JSON.stringify(productList), total]);

    // const [paidOrderAmount, setPaidOrderAmount] = useState(
    //     getPaidFromPayments(originOrder?.splitPayment ?? [])
    // );

    // useEffect(() => {
    //     const paidFromPayment = getPaidFromPayments(
    //         originOrder?.splitPayment ?? []
    //     );
    //     const paidFromOrder = originOrder?.order?.paid;
    //     setPaidOrderAmount(paidFromPayment + paidFromOrder);
    // }, [originOrder]);

    // const onRemoveFreeGiftHandle = useCallback(
    //     (item: any) => {
    //         const newFreeGiftList =
    //             freeGiftList &&
    //             freeGiftList.filter((freeGift: any) => freeGift.id !== item.id);
    //         setFreeGiftList([...newFreeGiftList]);
    //     },
    //     [freeGiftList, setFreeGiftList]
    // );

    // const onRemoveChainDiscount = useCallback(
    //     (item: any) => {
    //         let values = [];
    //         if (item.action.level === INTERNAL_DISCOUNT) {
    //             values = filter(
    //                 discountChainList,
    //                 (item) => item.action.level !== INTERNAL_DISCOUNT
    //             );
    //         } else if (item.action.level === ADDITIONAL_DISCOUNT) {
    //             values = filter(
    //                 discountChainList,
    //                 (item) => item.action.level !== ADDITIONAL_DISCOUNT
    //             );
    //         } else {
    //             values = ObjectUtils.removeArrayById(
    //                 discountChainList,
    //                 item?.id
    //             );
    //         }
    //         setDiscountChainList(values);
    //     },
    //     [discountChainList, setDiscountChainList]
    // );

    // const onRemoveCoupon = useCallback(
    //     (item: any) => {
    //         if (isChainExternal) {
    //             onRemoveChainDiscount(item);
    //         } else {
    //             onRemoveFreeGiftHandle(item);
    //         }
    //     },
    //     [isChainExternal, onRemoveChainDiscount, onRemoveFreeGiftHandle]
    // );

    const onRemoveCoupon = (voucherId: any) => {
        checkCartValue(
            values,
            cartValue?.voucher
                ?.filter((v) => v?.voucher?.id !== voucherId)
                ?.map((v) => v?.voucher?.code)
        );
    };
    const onRemoveCredit = (creditId: any) => {
        checkCartValue(
            values,
            null,
            true,
            cartValue?.credits
                ?.filter((v) => v?.credit?.id !== creditId)
                ?.map((v) => v?.credit?.id)
        );
    };

    const onRemoveDiscount = (product?: IProduct, doctorOrder?: any) => {
        if (!product) {
            const newDiscounts = filter(
                manualDiscount,
                (item) => !item.isWholeCart
            );
            setManualDiscount &&
                setManualDiscount(
                    filter(manualDiscount, (item) => !item.isWholeCart)
                );

            if (isEmpty(newDiscounts)) {
                setCartValue({ ...cartValue, manualDiscount: undefined });
            }
        } else {
            const removeId = getProductOrderId(product, doctorOrder);
            const newDiscounts = filter(
                manualDiscount,
                (item) => item?.product?.productOrderId !== removeId
            );
            setManualDiscount && setManualDiscount(newDiscounts);
            if (isEmpty(newDiscounts)) {
                setCartValue({ ...cartValue, manualDiscount: undefined });
            }
        }
    };

    const rowMoney = (
        title: any,
        money: any,
        secondTitleStyle = {},
        isTotal = false,
        isVAT = false
    ) => {
        return (
            <tr>
                <td colSpan={2} align="left" className="border-b py-2 text-sm">
                    <div className="flex items-center">
                        <span className="font-weight-bold text-sm">
                            {title}
                        </span>
                        {isVAT && (
                            <Tooltip title={Messages.vatNotIncludedInCredit} />
                        )}
                    </div>
                </td>
                <td colSpan={2} align="right" className="border-b py-2 text-sm">
                    <span
                        className={`text-sm ${
                            isTotal && "bg-primary px-2 py-1 text-white"
                        }`}
                        style={{ ...secondTitleStyle }}
                    >
                        <CurrencyFormat
                            displayType="text"
                            thousandSeparator
                            prefix="฿"
                            value={money}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </span>
                </td>
            </tr>
        );
    };

    // const renderCouponList = useCallback(() => {
    //     let afterDiscount = subTotalState;

    //     return map([...discountChainList, ...freeGiftList], (coupon) => {
    //         const { specific, percentage, amount, level } =
    //             coupon?.action ?? {};
    //         const isPercentage =
    //             specific === CART_RULE_ACTION_KEY.discountPercentage.id;
    //         let discountMoney = 0;

    //         if (isPercentage && level === INTERNAL_DISCOUNT) {
    //             discountMoney = (afterDiscount * percentage) / 100;
    //         } else if (
    //             isPercentage &&
    //             (coupon?.selfDiscount ||
    //                 coupon?.isEmployeeDiscount ||
    //                 coupon?.isChainDiscount)
    //         ) {
    //             discountMoney = (afterDiscount * percentage) / 100;
    //         } else {
    //             discountMoney = amount;
    //         }

    //         afterDiscount -= discountMoney;

    //         return (
    //             <CouponRow
    //                 item={coupon}
    //                 removeCoupon={onRemoveCoupon}
    //                 discountMoney={discountMoney}
    //             />
    //         );
    //     });
    // }, [discountChainList, freeGiftList, onRemoveCoupon, subTotalState]);

    const renderCouponList = () => {
        const vouchersList = groupVouchers(cartValue?.voucher ?? ([] as any[]));
        return vouchersList?.map((voucher, index) => {
            return (
                <CouponRow
                    key={index}
                    item={voucher}
                    removeCoupon={() => onRemoveCoupon(voucher?.voucher?.id)}
                    // discountMoney={discountMoney}
                />
            );
        });
    };

    const renderCreditList = () => {
        const creditList = groupCredits(cartValue?.credits ?? ([] as any[]));
        return map(creditList, (credit, index) => {
            return (
                <CreditRow
                    key={index}
                    item={credit}
                    onRemove={() => onRemoveCredit(credit?.credit?.id)}
                    // discountMoney={discountMoney}
                />
            );
        });
    };

    const renderManualDiscount = () => {
        if (isEmpty(manualDiscountCart)) {
            return null;
        }
        return (
            <Fragment>
                {map(manualDiscountCart, (item, index) => {
                    const {
                        discountType,
                        discountValue,
                        product,
                        doctorOrder,
                        total,
                    } = item || {};
                    const percentageManualDiscount = discountValue;
                    return (
                        <tr
                            className={ClassNames("w-full", {
                                "border-b":
                                    manualDiscountCart?.length &&
                                    index === manualDiscountCart?.length - 1,
                            })}
                        >
                            <td colSpan={2} align="left" className="text-sm">
                                <div className="flex items-center">
                                    <div className="font-weight-bold text-sm">
                                        {Messages.manualDiscount}
                                    </div>
                                    <Button
                                        className="inline"
                                        variant="trans"
                                        iconName="delete"
                                        onClick={() => {
                                            onRemoveDiscount(
                                                product,
                                                doctorOrder
                                            );
                                        }}
                                    />
                                </div>
                                <div className="text-primary">
                                    {`( ${
                                        product
                                            ? `SKU:${product?.sku}`
                                            : Messages.wholeCart
                                    } )`}
                                </div>
                            </td>
                            <td
                                colSpan={2}
                                align="right"
                                className="py-2 text-sm"
                            >
                                <CurrencyFormat
                                    className="text-success"
                                    displayType="text"
                                    thousandSeparator
                                    prefix="- ฿"
                                    value={total}
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                                {discountType === DiscountType.Percentage && (
                                    <span className="text-success">
                                        (
                                        <CurrencyFormat
                                            displayType="text"
                                            thousandSeparator
                                            value={percentageManualDiscount}
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                        %)
                                    </span>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </Fragment>
        );
    };
    return (
        <PriceTableStyled className="price-table__container p-3">
            <table id="priceTable" className="w-full">
                <tr>
                    <td
                        colSpan={2}
                        align="left"
                        className="border-b py-2 text-sm"
                    >
                        <span className="font-weight-bold text-sm">
                            {Messages.subTotal}
                        </span>
                    </td>
                    <td
                        colSpan={2}
                        align="right"
                        className="border-b py-2 text-sm"
                    >
                        <span className="text-sm bg-primary px-2 py-1 text-white">
                            <CurrencyFormat
                                displayType="text"
                                thousandSeparator
                                prefix="฿"
                                value={cartValue?.subtotal}
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </span>
                    </td>
                </tr>
                {renderCouponList()}
                {renderCreditList()}
                {renderManualDiscount()}
                {rowMoney(
                    Messages.taxIncluded,
                    (orderTotalVAT * 7) / 100,
                    {},
                    false,
                    true
                )}
                {rowMoney(Messages.total, cartValue?.total || 0, {}, true)}

                {/* {originOrder &&
                    rowMoney(t("paid"), paidOrderAmount, {
                        color: "green",
                    })}
                {originOrder &&
                    rowMoney(
                        t("remainingAmount"),
                        totalState - paidOrderAmount
                    )} */}
            </table>
        </PriceTableStyled>
    );
};

const PriceTableStyled = styled.div`
    max-height: 256px;
    overflow-y: auto;
`;

export default PriceTable;
