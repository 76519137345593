/* eslint-disable jsx-a11y/no-autofocus */
import { Tree } from "antd";
import { Button, Loading } from "d-react-components";
import { includes, map } from "lodash";
import { useContext, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import MediaAPI from "../../api/queries/media";
import { MediaContext } from "../../context/media";
import Messages from "../../languages/Messages";
import Path from "../Path";

const NodeTitleRender = ({ node }: any) => {
    const MODE = {
        VIEW: "view",
        EDIT: "edit",
        LOADING: "loading",
    };

    const { folderId, onChangeState } = useContext(MediaContext);
    const isShowEditButton = node.id === folderId;

    const [modeView, setModeView] = useState<string>();
    const nodeNameText = useRef(node.name);

    const onClickEdit = () => {
        setModeView(MODE.EDIT);
    };

    const onUpdateTitleNode = async () => {
        setModeView(MODE.LOADING);

        const body = {
            name: nodeNameText.current,
        };
        const folders = await MediaAPI.updateFolder(node.id, body);
        onChangeState({ folders });
        setModeView(MODE.VIEW);
    };

    if (modeView === MODE.EDIT) {
        const handleKeyDown = (e: any) => {
            if (e.key === "Enter") {
                onUpdateTitleNode();
            }
        };
        return (
            <input
                type="text"
                className="form-control form-control-sm"
                defaultValue={node.name}
                onChange={(event) => {
                    nodeNameText.current = event.target.value;
                }}
                onKeyDown={handleKeyDown}
                onBlur={() => setModeView(MODE.VIEW)}
                autoFocus
            />
        );
    }

    if (modeView === MODE.LOADING) {
        return (
            <div className="d-flex align-items-center">
                <text>{nodeNameText.current}</text>
                <Loading className="ml-2" size="small" />
            </div>
        );
    }

    return (
        <div className="d-flex align-items-center my-1">
            <text>{`${node.name} (${node.size})`}</text>
            {isShowEditButton && (
                <Button
                    variant="trans"
                    onClick={onClickEdit}
                    iconName="edit"
                    size="x-small"
                    className="ml-1"
                />
            )}
        </div>
    );
};

const MediaManagerTreeFolder = () => {
    const { folders, folderId, isSelectMode, onChangeState } =
        useContext(MediaContext);
    const navigate = useNavigate();

    const [expandedKeys, setExpandedKeys] = useState<any[]>(
        // MediaKeysManager.getExpandedKeys()
        []
    );

    // useEffect(() => {
    //     MediaKeysManager.saveExpandedKeys(expandedKeys);
    // }, [expandedKeys]);

    const foldersTreeData = useMemo(() => folders, [folders]);

    const onSelect = (selectedKeys: any, { selectedNodes }: any) => {
        const folderId = selectedNodes?.[0]?.id;
        if (!folderId) return;
        !isSelectMode &&
            navigate(generatePath(Path.MEDIA_FOLDER, { folderId }));
        onChangeState({ folderId });
        // MediaKeysManager.saveSelectedFolder(folderId);
    };

    const onClickExpandAll = () => {
        setExpandedKeys(map(folders, (item: any) => item.id));
    };

    const onClickCollapseAll = () => {
        setExpandedKeys([]);
    };

    const onDropFolder = (dropInfo: any) => {
        const { dragNode, node, dropToGap } = dropInfo;

        if (dropToGap) {
            const isMoveToRoot = includes(
                map(folders, (item) => item.id),
                node.id
            );
            if (isMoveToRoot) {
                moveToAnotherFolder(dragNode, { id: null });
                return;
            }

            if (dragNode?.parentId !== node?.parentId) {
                moveToAnotherFolder(dragNode, { id: node?.parentId });
            }
        } else {
            moveToAnotherFolder(dragNode, node);
        }
    };

    const moveToAnotherFolder = async (dragNode: any, parentNode: any) => {
        const folders = await MediaAPI.moveFolder(dragNode.id, parentNode?.id);
        onChangeState({ folders });
    };

    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center">
                <Button variant="trans" onClick={onClickExpandAll}>
                    {Messages.expandAll}
                </Button>
                <h5>|</h5>
                <Button variant="trans" onClick={onClickCollapseAll}>
                    {Messages.collapseAll}
                </Button>
            </div>
            <Tree
                showLine
                showIcon
                selectedKeys={[folderId]}
                onSelect={onSelect}
                treeData={foldersTreeData}
                expandedKeys={expandedKeys}
                onExpand={(keys: any) => {
                    setExpandedKeys(keys);
                }}
                defaultExpandedKeys={expandedKeys}
                titleRender={(node: any) => <NodeTitleRender node={node} />}
                draggable
                onDrop={onDropFolder}
            />
        </div>
    );
};

export default MediaManagerTreeFolder;
