import { TabBar, ViewLabelStatus } from "d-react-components";
import { filter, find, map, some } from "lodash";
import { useContext, useMemo, useState, Fragment } from "react";
import { useParams } from "react-router";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import DeliveryConfirmationView from "../../../delivery-driver/shared/DeliveryConfirmationView";
import Messages from "../../../../languages/Messages";
import { DeliveryConfirmationStatus } from "../../../../api/hooks";
import { DELIVERY_DRIVER_CONFIRMATION_STATUSES } from "../../../../constant/delivery-driver";
import StockTransferReceiveButton from "./StockTransferReceiveButton";
import StockTransferCompleteDeliveryButton from "./StockTransferCompleteDeliveryButton";

const StockTransferDestination = () => {
    const { transferId } = useParams<any>();
    const { stockTransfer, loadTransferDetail } = useContext(
        StockTransferDetailContext
    );
    const { deliveries } = stockTransfer;

    const TABS = map(
        filter(deliveries, (iDel) => {
            return [
                DeliveryConfirmationStatus.Pending,
                DeliveryConfirmationStatus.Completed,
            ].includes(iDel?.destinationConfirmation?.status as any);
        }),
        (deliveryItem, index) => {
            return {
                id: deliveryItem?.id ?? index,
                label: deliveryItem.deliveryNo,
            };
        }
    );
    const [tab, setTab] = useState(TABS[0]);

    const renderContent = () => {
        const renderTabContent = () => {
            const activeDelivery = find(deliveries, (i) => i?.id === tab?.id);
            const { destinationConfirmation, stocks } = activeDelivery ?? {};
            const showCompleteButton = some(
                activeDelivery?.stocks ?? [],
                (item) => (item?.staffReceived || 0) > 0
            );

            if (!activeDelivery) {
                return <div />;
            }

            if (
                destinationConfirmation?.status ===
                DeliveryConfirmationStatus.Pending
            ) {
                return (
                    <Fragment>
                        <ViewLabelStatus
                            className="mt-3"
                            status={destinationConfirmation?.status}
                            listStatus={DELIVERY_DRIVER_CONFIRMATION_STATUSES}
                        />
                        <div className="flex-center-y mt-3">
                            <StockTransferReceiveButton
                                delivery={activeDelivery}
                            />
                            {showCompleteButton && (
                                <StockTransferCompleteDeliveryButton
                                    delivery={activeDelivery}
                                />
                            )}
                        </div>
                    </Fragment>
                );
            }

            return (
                <DeliveryConfirmationView
                    status={destinationConfirmation?.status}
                    giver={destinationConfirmation?.driver}
                    receiver={destinationConfirmation?.staff}
                    giverLabel={Messages.driver}
                    receiverLabel={Messages.pickupStaff}
                    delivery={activeDelivery}
                    confirmationTime={destinationConfirmation?.driver?.createdAt}
                />
            );
        };

        return (
            <Fragment>
                <TabBar
                    dataSource={TABS}
                    onChange={(v) => {
                        setTab(v as any);
                    }}
                    value={tab}
                />
                {renderTabContent()}
            </Fragment>
        );
    };

    return (
        <div className="py-3 px-4 bg-white border mt-3">
            <h5 className="mb-3">{Messages.destinationPickupConfirmation}</h5>
            {TABS?.length > 0 && renderContent()}
        </div>
    );
};

export default StockTransferDestination;
