import classNames from "classnames";
import {
    AwesomeTableComponent,
    Badge,
    Button,
    HeaderTable,
    IColumnsProps,
    TabBar,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { filter, find, forEach } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath, useNavigate } from "react-router-dom";
import {
    OrderStatus,
    useListOrderForAdminLazyQuery,
    useSummaryOrderForAdminLazyQuery,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import PermissibleRender from "../../../common/PermissibleComponent";
import TableAction from "../../../common/TableActions";
import { ORDER_STATUSES } from "../../../constant/order";
import { PERMISSION } from "../../../constant/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerName from "../../../common/avatar/CustomerName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";

const FILTER_ORDER_STATUS = [
    OrderStatus.PendingPayment,
    OrderStatus.PendingConfirmation,
    OrderStatus.PartiallyPaid,
    OrderStatus.OrderProcessing,
    OrderStatus.DeliveryProcessing,
    OrderStatus.Cancelled,
];

const STATUS_TABS = [
    {
        id: "all",
        label: "all",
    },
    ...filter(ORDER_STATUSES, (item) => FILTER_ORDER_STATUS.includes(item.id)),
];

const OrderTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [summary, setSummary] = useState<any>();
    const [getListOrder, { data }] = useListOrderForAdminLazyQuery();
    const [getListSummary] = useSummaryOrderForAdminLazyQuery();
    const [tabSelected, setTabSelected] = useState<any>(STATUS_TABS[0]);

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.ORDER_DETAIL, {
                        orderId: item.id,
                    })
                );
        }
    };

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "orderNo",
            width: 100,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        // {
        //     title: Messages.branchWarehouse,
        //     dataIndex: "store",
        //     render: (item) => item.name,
        // },
        // {
        //     title: Messages.typeOfTreatment,
        //     dataIndex: "typeOfTreatment",
        //     render: (item) => Messages[item],
        // },
        {
            title: Messages.branchWarehouse,
            dataIndex: "store",
            render: (store) => store?.name,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={ORDER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (user, item) => (
                <CustomerName user={item.customer || item.contact} />
            ),
            width: "150px",
        },
        {
            title: Messages.amount,
            dataIndex: "total",
            render: (amount) => (
                <CurrencyFormat
                    value={amount}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            width: "100px",
        },
        {
            title: Messages.purchaseType,
            dataIndex: "purchaseType",
            width: "100px",
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user, item) => (
                <UserAvatarName size="x-small" user={user} />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return getListOrder({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: { createdAt: -1 },
                    ...(tabSelected?.id !== "all"
                        ? { status: [tabSelected?.id] }
                        : {}),
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const getQuantityOrder = () => {
        getListSummary().then((response) => {
            const resp: any = response?.data?.data?.data ?? {};
            const summary: any = {};
            forEach(FILTER_ORDER_STATUS, (status) => {
                summary[status] = find(resp, { status })?.count ?? 0;
            });
            setSummary(summary);
        });
    };

    const onClickAddNew = () => {
        navigate(Path.ORDER_CREATE);
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
        tableRef.current.refresh();
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    useEffect(() => {
        tableRef.current.refresh();
    }, [tabSelected]);

    useEffect(() => {
        getQuantityOrder();
    }, []);

    return (
        <div>
            <HeaderTable
                label={Messages.order}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                // onSubmitSearch={onSubmitSearch}
                showFilter={false}
                className="app-layout__page-header"
                customButtons={() => (
                    <PermissibleRender permission={PERMISSION.ORDER.CREATE}>
                        <Button size="small" onClick={onClickAddNew}>
                            {Messages.new}
                        </Button>
                    </PermissibleRender>
                )}
            />
            <div className="flex">
                <TabBar
                    dataSource={STATUS_TABS}
                    onChange={(tab) => setTabSelected(tab)}
                    value={tabSelected}
                    // className="overflow-x-scroll my-4 pb-1 flex-column form-tabs"
                    getLabel={(item: any) => (
                        <>
                            <span>{Messages[item.label]}</span>
                            {item.id !== "all" &&
                                item.id !== OrderStatus.Cancelled && (
                                    <Badge
                                        index={
                                            summary?.[item.id] > 100
                                                ? "100+"
                                                : summary?.[item.id]
                                        }
                                        variant="index"
                                        size="x-large"
                                        color={
                                            summary?.[item.id] === 0
                                                ? "green"
                                                : "red"
                                        }
                                    />
                                )}
                        </>
                    )}
                    isScroll
                    hideScrollBar={false}
                    minWidthItem="100%"
                    classNameItem="justify-content-start text-gray-500 bg-white"
                    variant="horizontal"
                />
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default OrderTable;
