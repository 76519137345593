////////// DETAIL CONTEXT

import React from "react";
import { IEquipment } from "../interfaces/equipment";

export interface IEquipmentDetailState {
    equipment?: IEquipment;
    loadEquipmentDetail: () => void;
}

const equipmentDetailState: IEquipmentDetailState = {
    equipment: undefined,
    loadEquipmentDetail: () => {},
};

export const EquipmentDetailContext = React.createContext(equipmentDetailState);
