import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";

export const F_ADMIN_BANK = gql`
    fragment F_ADMIN_BANK on AdminBankBasic {
        id
        title
        bankName
        bankLogo
        accountName
        accountNumber
        qrPayment
    }
`;

export const F_ADMIN_PAYMENT_PROOF = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_ADMIN_BANK}
    fragment F_ADMIN_PAYMENT_PROOF on AdminPaymentProof {
        nameOfPayer
        transactionId
        dateOfPayment
        remark
        attachments
        at
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        bank {
            ...F_ADMIN_BANK
        }
    }
`;

export const F_ADMIN_PAYMENT_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_ADMIN_BANK}
    fragment F_ADMIN_PAYMENT_CONFIRMATION on AdminPaymentConfirmation {
        at
        attachments
        dateOfReceiving
        remark
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        bank {
            ...F_ADMIN_BANK
        }
    }
`;

export const F_ADMIN_PAYMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_ADMIN_PAYMENT_PROOF}
    ${F_ADMIN_PAYMENT_CONFIRMATION}
    fragment F_ADMIN_PAYMENT on AdminPayment {
        id
        paymentNo
        total
        status
        paymentMethod
        sourceOfPayment
        paymentOption
        paymentCardDevice
        hasOrder
        refId
        hasPermissionUploadProofForCustomer
        createdAt
        updatedAt
        creditCard {
            type
            number
            expiryDate
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        proof {
            ...F_ADMIN_PAYMENT_PROOF
        }
        confirmation {
            ...F_ADMIN_PAYMENT_CONFIRMATION
        }
    }
`;

export const F_ADMIN_PAYMENT_RESPONSE = gql`
    ${F_ADMIN_PAYMENT}
    fragment F_ADMIN_PAYMENT_RESPONSE on AllAdminPaymentResponse {
        data {
            ...F_ADMIN_PAYMENT
        }
    }
`;
