import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { ListAdminUserRequest, useUserListLazyQuery } from "../../../api/hooks";
import { IFieldSelect } from "../../../interfaces/select";
// import UserAPI from "../../../api/user/UserAPI";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Image from "../../../common/Image";

export interface IUserSearchSelect<T> extends IFieldSelect<T> {
    params?: ListAdminUserRequest;
    showAvatar?: boolean;
}

const UserSelect = ({
    value = [],
    error,
    className,
    multiple,
    label = Messages.user,
    onChange,
    params = {},
    disabled = false,
    showAvatar = false,
    ...props
}: IUserSearchSelect<IUser>) => {
    const [userList, setUserList] = useState<IUser[]>(
        isArray(value) ? value : [value]
    );
    const [getListUser] = useUserListLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setUserList([]);
        } else {
            getListUser({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 50,
                        search: textSearch,
                        ...params,
                    },
                },
                fetchPolicy: "no-cache",
            }).then((res) => {
                const userList = res?.data?.listAdminUserForAdmin?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const uniqUser = uniqBy(
                    [...selectedValue, ...userList],
                    (item: any) => item.id
                );

                setUserList(uniqUser as any);
            });
        }
    }, 500);

    const onSelectUser = (ids: any) => {
        if (multiple) {
            onChange(
                map(ids, (id) =>
                    ObjectUtils.findItemFromId(userList, id)
                ) as any
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(userList, ids));
    };

    const userValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    const getLabel = (item: any) => {
        return `${item.firstName} ${item.lastName} (ID: ${
            item.companyId || "N/A"
        })`;
    };

    return (
        <Select
            {...props}
            label={label}
            onSearch={onChangeTextSearch}
            dataSource={userList}
            getLabel={(item) =>
                showAvatar ? (
                    <div className="flex items-center">
                        <Image
                            src={item?.avatar}
                            className="user-info-avatar w-[24px] h-[24px] mr-2"
                        />
                        {getLabel(item)}
                    </div>
                ) : (
                    getLabel(item)
                )
            }
            onChange={onSelectUser}
            showSearch
            error={error}
            filterOption={false}
            className={className}
            multiple={multiple}
            value={userValue}
            disabled={disabled}
            // tagRender={(props) => (
            //     <div className="text-primary">{props.label}</div>
            // )}
        />
    );
};

export default UserSelect;
