import { Pagination, Spin } from "antd";
import classNames from "classnames";
import { Notifications } from "d-react-components";
import React, { useState, useEffect, useContext } from "react";
import { useMedia } from "react-use";
import { useListMaterialLazyQuery } from "../../../api/hooks";
import { MaterialPurchaseContext } from "../../../context/material";
import { SIZE_OPTIONS } from "../crud/MaterialPurchaseMaterialList";
import ProductCard from "./ProductCard";

const ProductGrid = ({ pagination, setPagination, addNewProduct }: any) => {
    const { productFilters, materialRequest } = useContext(MaterialPurchaseContext);
    const [productList, setProductList] = useState<any>([]);
    const [total, setTotal] = useState<number>();
    const [loadingList, setLoadingList] = useState(false);
    const isWide = useMedia("(min-width: 1280px)");
    const [getProductList] = useListMaterialLazyQuery();

    const getProduct = async () => {
        try {
            setLoadingList(true);
            const productResp = await getProductList({
                variables: {
                    paginate: {
                        page: pagination?.pageIndex as any,
                        limit: pagination?.pageSize,
                        search: productFilters?.textSearch ?? "",
                        sort: {
                            createdAt: -1,
                        },
                        // ...(productFilters?.categories?.length
                        //     ? {
                        //           categories: productFilters?.categories?.map(
                        //               (item: any) => item.id
                        //           ),
                        //       }
                        //     : {}),
                    },
                },
            });
            setProductList(productResp?.data?.data?.data);
            setTotal(productResp?.data?.data?.pagination?.items);
            setLoadingList(false);
        } catch (err) {
            Notifications.showError(err);
            setLoadingList(false);
        }
    };

    useEffect(() => {
        getProduct();
    }, [pagination, productFilters?.textSearch, productFilters?.categories]);

    return (
        <div>
            <div
                className={classNames(
                    { "grid-cols-6": isWide, "grid-cols-4": !isWide },
                    "grid gap-3 mt-3 overflow-auto relative"
                )}
            >
                {materialRequest?.materials?.map((item: any, index: number) => (
                    <ProductCard
                        product={item.material}
                        key={index}
                        addNewProduct={addNewProduct}
                        quantity={item.quantity}
                    />
                ))}
                {loadingList && (
                    <div className="absolute w-full pt-10 flex justify-content-center">
                        <Spin />
                    </div>
                )}
            </div>

            {/* <div className="d-table-awesome-component bg-white flex align-items-end">
                <Pagination
                    showTotal={(total: any) => (
                        <div className="captionText">{`Total ${total} items`}</div>
                    )}
                    current={pagination.pageIndex}
                    total={total}
                    className="ant-table-pagination ant-table-pagination-right"
                    pageSizeOptions={SIZE_OPTIONS}
                    onChange={(pageIndex, pageSize) =>
                        setPagination({ pageIndex, pageSize })
                    }
                    pageSize={pagination.pageSize}
                />
            </div> */}
        </div>
    );
};

export default ProductGrid;
