/* eslint-disable no-nested-ternary */
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetAllCountryLazyQuery } from "../../../api/hooks";
import { ICountry } from "../../../interfaces/address";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";

interface ICountrySelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const CountrySelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: ICountrySelect<ICountry>) => {
    const [countryList, setCountryList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getCountryList] = useGetAllCountryLazyQuery();

    useEffect(() => {
        getCountryList({
            variables: {
                paginate: {
                    page: 1,
                    limit: 10000,
                },
            },
        }).then((res: any) => {
            const countryRes = res?.data?.data?.data ?? [];
            const selectedValue = isArray(value) ? value : value ? [value] : [];
            const filterCountry = map(
                [...selectedValue, ...countryRes],
                (item) => ({
                    ...item,
                    id: item?.id,
                    name: item?.name,
                })
            );
            const uniqCountry = uniqBy(filterCountry, (item) => item?.id);
            setCountryList(uniqCountry);
        });
    }, []);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(countryList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(countryList, id));
    };

    const countryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            className={className}
            label={Messages.country}
            dataSource={countryList}
            getLabel={(item) => item?.name}
            getKey={(item) => item?.id}
            value={countryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default CountrySelect;
