/* eslint-disable consistent-return */
import {
    Avatar,
    AwesomeTableComponent,
    IColumnsProps,
    IDrawerProps,
} from "d-react-components";
import { find } from "lodash";
import React, { useRef } from "react";
import { generatePath } from "react-router";
import { ProductStockType } from "../../../api/hooks";
import InventoryAPI from "../../../api/queries/inventory";
import AppLink from "../../../common/AppLink";
import Drawer from "../../../common/Drawer";
import { PRODUCT_STOCK_TYPES } from "../../../constant/inventory";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductNameView from "../../product/share/ProductNameView";

export interface IInventoryHistoryDrawerProps extends IDrawerProps {
    product: IProduct;
}

const InventoryHistoryDrawer: React.FC<IInventoryHistoryDrawerProps> = ({
    open,
    onClose,
    product,
}) => {
    const tableRef = useRef<any>(null);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            width: 500,
            render: (item) => {
                return <ProductNameView product={product} />;
            },
        },
        {
            title: Messages.action,
            dataIndex: "refType",
            render: (refType) => {
                return (Messages as any)?.[
                    find(PRODUCT_STOCK_TYPES, (i) => i?.id === refType)
                        ?.label ?? ""
                ];
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
        },
        {
            title: Messages.holdQTy,
            dataIndex: "hold",
        },
        {
            title: Messages.ref,
            dataIndex: "refType",
            render: (refType, item) => {
                switch (refType) {
                    case ProductStockType.Order:
                        break;
                    case ProductStockType.ProductStockAdjustment:
                        return (
                            <AppLink
                                to={generatePath(
                                    Path.INVENTORY_STOCK_ADJUSTMENT_DETAIL,
                                    {
                                        stockAdjustmentId:
                                            item?.adjustmentRequest?.id ?? "",
                                    }
                                )}
                            >
                                {item?.adjustmentRequest?.adjustmentNo}
                            </AppLink>
                        );
                    case ProductStockType.ProductStockDelivery:
                        return (
                            <AppLink
                                to={generatePath(Path.DELIVERY_DRIVER_DETAIL, {
                                    deliveryId: item?.delivery?.id ?? "",
                                })}
                            >
                                {item?.delivery?.deliveryNo}
                            </AppLink>
                        );

                    default:
                        return "N/A";
                }
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = {};
        const paginate = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return InventoryAPI.history({ paginate, product: product?.id });
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.history}
            size="auto"
            width="50%"
            hideFooter
        >
            <div className="p-4">
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    source={source}
                    columns={columns}
                    baseColumnProps={{ width: 150 }}
                    getTotalItems={(res) => {
                        return res?.data?.data?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => res?.data?.data?.data ?? []}
                />
            </div>
        </Drawer>
    );
};

export default InventoryHistoryDrawer;
