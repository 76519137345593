/* eslint-disable consistent-return */
import React, { Fragment, useContext, useRef, useState } from "react";
import {
    Button,
    DateInput,
    Drawer,
    InputText,
    Notifications,
    Select,
    StringUtils,
} from "d-react-components";
import { isEmpty } from "lodash";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import moment, { Moment } from "moment";
import Messages from "../../../languages/Messages";
import CountrySelect from "../../address/share/CountrySelect";
import { AppStateContext } from "../../../context/app";
import CustomerAPI from "../../../api/queries/customer";
import { ICustomer } from "../../../interfaces/customer";
import { CustomerSolidItem } from "../../customer/share/CustomerSolidSelect";

interface CustomerForm {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    nationality?: any;
}

const NewCustomerDrawer = ({ open, onClose, onSave, contact }: any) => {
    const { primaryCountry } = useContext(AppStateContext);
    const isEdit = !isEmpty(contact);
    const CustomerSchema = Yup.object().shape({
        firstName: Yup.string().required(Messages.fieldRequired),
        lastName: Yup.string().required(Messages.fieldRequired),
        phone: Yup.string()
            .matches(StringUtils.phoneRegExp, {
                message: "Invalid phone number!",
            })
            .required(Messages.fieldRequired),
        email: Yup.string().email("Invalid email!").nullable(),
    });

    const [checkingCustomer, setCheckingCustomer] = useState(false);
    const [existedCustomer, setExistedCustomer] = useState<ICustomer | null>(
        null
    );

    const prevChecked = useRef<any>();

    const customerForm = useFormik<CustomerForm>({
        initialValues: !isEdit
            ? { nationality: primaryCountry }
            : { ...contact },
        onSubmit: () => {
            submitForm();
        },
        validationSchema: CustomerSchema,
        validateOnChange: false,
    });
    const { values, setFieldValue, errors, handleSubmit } = customerForm;

    const submitForm = async () => {
        const { firstName, lastName, phone, email, nationality } = values;
        const existed = await validateCustomerByPhone();
        if (existed) {
            return;
        }
        const body = {
            firstName,
            lastName,
            phone,
            email,
            nationality,
        };
        try {
            onSave && onSave(body);
        } catch (err) {
            Notifications.showError(err);
        }
    };

    const validateCustomerByPhone = async () => {
        if (!values?.phone) return;
        if (!StringUtils.phoneRegExp.test(values.phone)) return;
        setCheckingCustomer(true);
        const checkRes = await CustomerAPI.list({
            phone: values?.phone,
            phoneCode: "+66",
        });
        const thisCus = checkRes?.data?.[0];
        if (thisCus) {
            setExistedCustomer(thisCus);
            setCheckingCustomer(false);
            return true;
        }
        setCheckingCustomer(false);
        return false;
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width="400px"
            size="auto"
            title={isEdit ? Messages.editContact : Messages.createGuest}
            maskClosable={false}
            destroyOnClose
        >
            <div className="grid grid-cols-1 gap-4 p-4">
                <div className="col-span-1">
                    <InputText
                        label={Messages.firstName}
                        value={values.firstName}
                        onChange={(e) =>
                            setFieldValue("firstName", e?.currentTarget?.value)
                        }
                        error={errors?.firstName}
                    />
                </div>
                <div className="col-span-1">
                    <InputText
                        label={Messages.lastName}
                        value={values.lastName}
                        onChange={(e) =>
                            setFieldValue("lastName", e?.currentTarget?.value)
                        }
                        error={errors?.lastName}
                    />
                </div>
                <div className="col-span-1">
                    <InputText
                        label={Messages.phone}
                        value={values.phone}
                        onChange={(e) => {
                            setExistedCustomer(null);
                            setFieldValue("phone", e?.currentTarget?.value);
                        }}
                        error={errors?.phone}
                        onBlur={() => validateCustomerByPhone()}
                    />
                    {existedCustomer && (
                        <Fragment>
                            <div className="small text-error mt-2">
                                {Messages.phoneAlreadyExistError}
                            </div>
                            <CustomerSolidItem
                                className="p-3 items-center"
                                customer={existedCustomer}
                                customRight={() => {
                                    return (
                                        <div>
                                            <Button
                                                color="light"
                                                onClick={() => {
                                                    onSave &&
                                                        onSave(existedCustomer);
                                                }}
                                            >
                                                {Messages.select}
                                            </Button>
                                        </div>
                                    );
                                }}
                            />
                        </Fragment>
                    )}
                </div>
                <div className="col-span-1">
                    <InputText
                        label={Messages.email}
                        value={values.email}
                        onChange={(e) =>
                            setFieldValue("email", e?.currentTarget?.value)
                        }
                        error={errors?.email}
                    />
                </div>
                <div className="col-span-1">
                    <CountrySelect
                        allowClear={false}
                        multiple={false}
                        label={Messages.nationality}
                        value={values.nationality}
                        onChange={(e) => setFieldValue("nationality", e)}
                        error={errors?.nationality}
                    />
                </div>
            </div>
            <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
                <Button
                    onClick={() => {
                        onClose && onClose({});
                    }}
                    variant="outline"
                >
                    {Messages.cancel}
                </Button>
                <Button
                    onClick={() => {
                        if (existedCustomer) {
                            return;
                        }
                        handleSubmit();
                    }}
                >
                    {Messages.save}
                </Button>
            </div>
        </Drawer>
    );
};

export default NewCustomerDrawer;
