import {
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import {
    useRoleListQuery,
    useUpdateAdminUserSpecialAccessMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { SPECIAL_ACCESS_TYPES, USER_STATUSES } from "../../../constant/user";

import { UserCrudContext, UserDetailContext } from "../../../context/user";
import IUser, { mapUserGeneralInfoToSer } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import UserCrudSpecialAccess from "../crud/UserCrudSpecialAccess";

const UserDetailSpecialAccess = () => {
    const { user } = useContext(UserDetailContext);
    const { specialAccess } = user as IUser;
    const [showEditModal, setShowEditModal] = useState(false);

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-3">
                <h5 className="text-[16px] flex-1">{Messages.specialAccess}</h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <p className="text-base">{Messages.specialAccessDescription}</p>
            {specialAccess && (
                <>
                    <div className="mb-3">
                        <label className="text-label">{Messages.status}</label>
                        <div>
                            {specialAccess?.status
                                ? Messages.enabled
                                : Messages.disabled}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-label">
                            {Messages.usageType}
                        </label>
                        <div>
                            {
                                Messages[
                                    find(SPECIAL_ACCESS_TYPES, {
                                        key: specialAccess?.specialAccessType,
                                    })?.label ?? "N/A"
                                ]
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="text-label">{Messages.code}</label>
                        <div>{specialAccess?.code}</div>
                    </div>
                    <div className="mb-3">
                        <label className="text-label">
                            {Messages.expiredAt}
                        </label>
                        <div>
                            {!specialAccess?.expired
                                ? "N/A"
                                : TimeUtils.convertMiliToDateTime(
                                      specialAccess.expired
                                  )}
                        </div>
                    </div>
                </>
            )}
            {showEditModal && (
                <ModalEditInfo
                    open={showEditModal}
                    onClose={() => {
                        setShowEditModal(false);
                    }}
                />
            )}
        </div>
    );
};

const ModalEditInfo = ({ open, onClose }: any) => {
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const [updateAdminUser] = useUpdateAdminUserSpecialAccessMutation();

    const { data: dataRole } = useRoleListQuery({
        variables: {
            paginate: {
                limit: 1000,
                page: 1,
            },
        },
    });
    const roleList: any[] = useMemo(() => {
        return dataRole?.listAdminRoleForAdmin?.data ?? [];
    }, [dataRole]);

    const userForm = useFormik({
        initialValues: {
            specialAccess: {
                status: user?.specialAccess?.status
                    ? USER_STATUSES[0].id
                    : USER_STATUSES[1].id,
                specialAccessType:
                    find(SPECIAL_ACCESS_TYPES, {
                        key: user?.specialAccess?.specialAccessType,
                    })?.key ?? undefined,
                code: user?.specialAccess?.code,
                expired: moment(user?.specialAccess?.expired),
            },
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        onSubmit: (values) => {
            const bodyInfo = mapUserGeneralInfoToSer(values);

            const body = {
                variables: {
                    id: user?.id as string,
                    payload: bodyInfo.specialAccess,
                },
            };

            Progress.show(
                {
                    method: updateAdminUser,
                    params: [body],
                },
                (resp: any) => {
                    loadAdminDetail();
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    return (
        <UserCrudContext.Provider value={{ userForm, roleList }}>
            <ModalEditInfoContent open={open} onClose={onClose} />
        </UserCrudContext.Provider>
    );
};

const ModalEditInfoContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;
    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="medium"
            title={Messages.specialAccess}
            onSave={() => {
                handleSubmit();
            }}
        >
            <UserCrudSpecialAccess />
        </Drawer>
    );
};

export default UserDetailSpecialAccess;
