import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, join, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import AppointmentAPI from "../../../api/queries/appointment";

import AppLink from "../../../common/AppLink";

import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import { APPOINTMENT_STATUSES } from "../../../constant/appointment";
import { mapAppointmentFilterToServer } from "../../../interfaces/appointment";

import Messages from "../../../languages/Messages";
import Path from "../../Path";
import AppointmentTableFilterDrawer from "./AppointmentTableFilterDrawer";

const AppointmentTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "appointmentNo",
            render: (appointmentNo, item) => (
                <AppLink
                    to={generatePath(Path.APPOINTMENT_DETAIL, {
                        appointmentId: item.id,
                    })}
                >
                    {appointmentNo}
                </AppLink>
            ),
        },

        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.estDateOfVisit,
            dataIndex: "scheduleDate",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.service,
            dataIndex: "services",
            render: (services) =>
                join(
                    map(services, (item) => item?.name),
                    ", "
                ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer) => (
                <CustomerAvatarName customer={customer} size="xx-small" />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={APPOINTMENT_STATUSES}
                />
            ),
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = mapAppointmentFilterToServer(filter);
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return AppointmentAPI.list(input);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.allAppointment}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <AppointmentTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default AppointmentTable;
