import classNames from "classnames";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { VOUCHER_STATUSES, VOUCHER_TYPES } from "../../../constant/voucher";
import Messages from "../../../languages/Messages";
import CustomerSolidSelect from "../../customer/share/CustomerSolidSelect";
import ProductSolidSelect from "../../product/share/ProductSolidSelect";

interface IVoucherFilterForm {
    filterForm: any;
    hideCustomer?: boolean;
}

export const CreditFilterForm = ({
    filterForm,
    hideCustomer,
}: IVoucherFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <InputSelectForm
                keyData="status"
                dataSource={VOUCHER_STATUSES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <InputSelectForm
                keyData="type"
                dataSource={VOUCHER_TYPES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            {!hideCustomer && (
                <CustomerSolidSelect
                    onChange={(value) =>
                        filterForm.setFieldValue("customer", value)
                    }
                    value={formValues?.customer}
                    className={classNameInput}
                    multiple
                />
            )}
            <ProductSolidSelect
                label={Messages.services}
                onChange={(value) =>
                    filterForm.setFieldValue("services", value)
                }
                value={formValues?.services}
                className={classNameInput}
                multiple
            />
            <ProductSolidSelect
                label={Messages.orderProduct}
                onChange={(value) =>
                    filterForm.setFieldValue("orderProducts", value)
                }
                value={formValues?.orderProducts}
                className={classNameInput}
                multiple
            />
        </div>
    );
};

export default CreditFilterForm;
