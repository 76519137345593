import {
    Button,
    ButtonProps,
    Dropdown,
    DropdownProps,
} from "d-react-components";
import React from "react";
import Messages from "../languages/Messages";

export const ActionMenu = (props: DropdownProps) => {
    return (
        <Dropdown
            {...props}
            buttonProps={{
                content: Messages.action,
                size: "small",
                color: "gray",
                variant: "standard",
                suffixIcon: "expand_more",
                iconName: undefined,
                className: "text-primary button-action",
                ...(props?.buttonProps ?? {}),
            }}
        />
    );
};

export const ActionButton = (props: ButtonProps) => {
    return (
        <Button
            {...props}
            className={`${props.className} text-primary button-action h-[32px]`}
            size="small"
        >
            {props.children}
        </Button>
    );
};

export default ActionMenu;
