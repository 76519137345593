import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { map } from "lodash";
import { Fragment, useContext, useMemo, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import {
    useAddServiceDoctorOrderForAdminMutation,
    useRemoveServiceDoctorOrderForAdminMutation,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { useMe } from "../../../context/app";
import { DoctorOrderDetailContext } from "../../../context/doctor-order";
import { JobType } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductName from "../../quotation/share/ProductName";
import ProductSelectDrawer from "../crud/ProductSelectDrawer";

const DoctorOrderRecommendedTreatment = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const me = useMe();
    const [textSearch, setTextSearch] = useState("");
    const { doctorOrder, loadDoctorOrderDetail } = useContext(
        DoctorOrderDetailContext
    );
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [addService] = useAddServiceDoctorOrderForAdminMutation();
    const [removeService] = useRemoveServiceDoctorOrderForAdminMutation();
    const { services, complicationTreatments, customer } = doctorOrder ?? {};
    const isAllowedRemove = [
        JobType.DOCTOR,
        JobType.NURSE,
        JobType.NORMAL_EMPLOYEE,
    ].includes(me?.jobType as any);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "id",
            ellipsis: true,
            render: (id: string, item: any) => {
                const { product, complicationTreatment } = item || {};
                return (
                    <Fragment>
                        <ProductName item={product} />
                        {complicationTreatment && (
                            <div className="flex-center-y mt-2">
                                <div>{Messages.complicationTreatment}:</div>
                                <AppLink
                                    className="ml-2"
                                    to={generatePath(Path.TREATMENT_DETAIL, {
                                        treatmentId:
                                            complicationTreatment?.id ?? "",
                                    })}
                                >
                                    {complicationTreatment?.treatmentNo}
                                </AppLink>
                            </div>
                        )}
                    </Fragment>
                );
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            width: 100,
        },
        {
            title: Messages.unit,
            dataIndex: "product",
            render: (product: any) => product?.unit,
        },
        {
            title: Messages.salePrice,
            dataIndex: "product",
            render: (product: any, item) => {
                return StringUtils.moneyThaiFormat(product?.salePrice);
            },
        },
        {
            title: Messages.action,
            dataIndex: "id",
            width: 100,
            render: (product: any, item: any) => {
                if (!isAllowedRemove) {
                    return null;
                }
                return (
                    <Icon
                        onClick={() => removeProduct(item?.product?.id)}
                        name="remove_circle_outline"
                        className="text-primary cursor-pointer"
                    />
                );
            },
        },
    ];

    const removeProduct = (serviceId: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () => {
                Progress.show(
                    {
                        method: removeService,
                        params: [
                            {
                                variables: {
                                    id: doctorOrder?.id as string,
                                    serviceId,
                                },
                            },
                        ],
                    },
                    () => {
                        Notifications.showSuccess(
                            Messages.removeTreatmentSuccessfully
                        );
                        loadDoctorOrderDetail();
                    },
                    (err: any) => {
                        Notifications.showError(err);
                    }
                );
            }
        );
    };

    const handleAddProduct = (newProducts: any) => {
        Progress.show(
            {
                method: addService,
                params: [
                    {
                        variables: {
                            id: doctorOrder?.id as string,
                            payload: newProducts,
                        },
                    },
                ],
            },
            () => {
                Notifications.showSuccess(Messages.addTreatmentSuccessfully);
                setShowAddProduct(false);
                loadDoctorOrderDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
    };

    const dataSource = useMemo(() => {
        return services?.filter((service: any) => {
            const { product } = service;
            return (
                product?.name
                    ?.toLowerCase()
                    ?.includes(textSearch?.toLowerCase()) ||
                product?.sku?.toLowerCase()?.includes(textSearch?.toLowerCase())
            );
        });
    }, [textSearch, services]);

    return (
        <div className="">
            <div className="flex items-center p-2">
                <div className="flex-1">
                    <h5>{Messages.recommendedTreatment}</h5>
                </div>
                <div>
                    <Button
                        size="small"
                        className="ml-2"
                        onClick={() => setShowAddProduct(true)}
                    >
                        {Messages.add}
                    </Button>
                </div>
            </div>
            <HeaderTable
                className="mb-3"
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onSubmitSearch={() => {
                    setTextSearch(searchRef?.current);
                }}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                dataSource={dataSource}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                showHeader
            />
            {showAddProduct && (
                <ProductSelectDrawer
                    open={showAddProduct}
                    onClose={() => setShowAddProduct(false)}
                    onSave={handleAddProduct}
                    defaultValue={map(services, (item) => ({
                        ...item.product,
                        quantity: item.quantity,
                    }))}
                    customerId={customer?.id}
                />
            )}
        </div>
    );
};

export default DoctorOrderRecommendedTreatment;
