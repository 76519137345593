/* eslint-disable no-case-declarations */
import {
    Avatar,
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    IDrawerProps,
    InputText,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useRef, useState } from "react";
import {
    useGetBankAccountSettingListLazyQuery,
    useUpdateBankAccountSettingMutation,
    useCreateBankAccountSettingMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import LabelLanguage from "../../../common/label/LabelLanguage";
import TableAction from "../../../common/TableActions";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { PAYMENT_METHOD_STATUSES } from "../../../constant/payment";
import { PaymentMethodDetailContext } from "../../../context/payment-method";
import {
    mapBankAccountFromServer,
    mapBankAccountToServer,
} from "../../../interfaces/payment-method";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";

interface IDrawerBankAccountCrud {
    method?: any;
    open: boolean;
    onClose: () => void;
    onSave: (values: any) => void;
    inputType?: "normal" | "richText";
    drawerProps?: Partial<IDrawerProps>;
}

const PaymentMethodBankAccount = () => {
    const { paymentMethod, loadPaymentMethodDetail } = useContext(
        PaymentMethodDetailContext
    );
    const tableRef = useRef<any>(null);
    const [showModal, setShowModal] = useState<{ open: boolean; data?: any }>({
        open: false,
    });
    const [getBankAccountSettingList] = useGetBankAccountSettingListLazyQuery();
    const [updateBankAccountSetting] = useUpdateBankAccountSettingMutation();
    const [createBankAccountSetting] = useCreateBankAccountSettingMutation();

    const onUpdateHandler = (id: string, payload: any) => {
        return Progress.show(
            {
                method: updateBankAccountSetting,
                params: {
                    variables: {
                        id,
                        payload,
                    },
                },
            },
            (res) => {
                refreshTable();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };
    const onCreateHandler = (payload: any) => {
        return Progress.show(
            {
                method: createBankAccountSetting,
                params: {
                    variables: {
                        payload,
                    },
                },
            },
            (res) => {
                refreshTable();
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.priority,
            dataIndex: "priority",
            render: (data) => data ?? "N/A",
        },
        {
            title: Messages.item,
            dataIndex: "",
            render: (data, item) => {
                const { bankName, accountNumber, bankLogo, accountName } = item;
                return (
                    <div className="flex-center-y">
                        <Avatar
                            src={bankLogo}
                            size="x-small"
                            text={accountName}
                            variant="square"
                        />
                        <div className="ml-2">
                            <div>{bankName}</div>
                            <div className="text-gray-400">{`${
                                Messages.accountNo
                            }:${accountNumber || "N/A"}`}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: Messages.accountName,
            dataIndex: "accountName",
        },
        {
            title: Messages.accountNo,
            dataIndex: "accountNumber",
        },
        {
            title: Messages.branch,
            dataIndex: "branch",
            render: (data) => data?.name ?? "N/A",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={PAYMENT_METHOD_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "id",
            align: "center",
            width: 30,
            render: (data: any, item) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, item)
                        }
                        dataSource={[{ id: "update", label: "update" }]}
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                const mappedData = mapBankAccountFromServer(item);
                setShowModal({ open: true, data: mappedData });
        }
    };

    const source = (paging: any) => {
        return getBankAccountSettingList({
            variables: {
                paginate: {
                    page: paging.pageIndex,
                    limit: paging.pageSize,
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current?.refresh?.();
    };

    return (
        <div className="my-3">
            <div className="flex items-center mb-3">
                <h5 className="text-[16px] flex-1">{Messages.bankAccounts}</h5>
                <Button
                    size="small"
                    iconName="add"
                    classNameIcon="mx- 0 mr-1"
                    onClick={() => setShowModal({ open: true })}
                >
                    {Messages.add}
                </Button>
            </div>

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
            {showModal?.open && (
                <DrawerBankAccountCrud
                    open={showModal?.open}
                    onClose={() => setShowModal({ open: false })}
                    onSave={(input) => {
                        if (showModal?.data?.id) {
                            onUpdateHandler(showModal?.data?.id, input);
                        } else {
                            onCreateHandler(input);
                        }
                        setShowModal({ open: false });
                    }}
                    method={showModal?.data}
                />
            )}
        </div>
    );
};

const DrawerBankAccountCrud = ({
    method,
    open,
    onClose,
    onSave,
    inputType = "normal",
    drawerProps = {},
}: IDrawerBankAccountCrud) => {
    const bankAccountForm = useFormik<any>({
        initialValues: method ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: NoteSchema,
        onSubmit: (values: any) => {
            const input = mapBankAccountToServer(values);
            onSave && onSave(input);
        },
    });
    const classNameInput = "col-12 mt-3";

    const {
        values: formValues,
        errors: formErrors,
        handleSubmit,
        setFieldValue,
        handleChange,
    } = bankAccountForm;
    const [language, setLanguage] = useState("en");

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.bankAccounts}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="px-4">
                <div className={classNameInput}>
                    <LabelLanguage
                        label={Messages.bankName}
                        language={language}
                        onChange={setLanguage}
                    />
                    <InputText
                        value={
                            formValues?.[language as keyof typeof formValues]
                                ?.bankName ?? ""
                        }
                        onChange={(event) =>
                            setFieldValue(
                                `${language}.bankName`,
                                event?.target?.value
                            )
                        }
                        error={
                            //@ts-ignore
                            formErrors?.en?.bankName || formErrors?.th?.bankName
                        }
                    />
                </div>

                <div className={classNameInput}>
                    <label>{Messages.bankLogo}</label>
                    <UploadAvatar
                        value={formValues?.bankLogo}
                        onChange={(file: any) => {
                            setFieldValue("bankLogo", file?.fileUrl);
                        }}
                        variant="square"
                        className="rounded-sm mt-1"
                        classNameImg="rounded-sm mt-1"
                    />
                </div>

                <div className={classNameInput}>
                    <LabelLanguage
                        label={Messages.accountName}
                        language={language}
                        onChange={setLanguage}
                    />
                    <InputText
                        value={
                            formValues?.[language as keyof typeof formValues]
                                ?.accountName ?? ""
                        }
                        onChange={(event) =>
                            setFieldValue(
                                `${language}.accountName`,
                                event?.target?.value
                            )
                        }
                        error={
                            //@ts-ignore
                            formErrors?.en?.accountName ||
                            //@ts-ignore
                            formErrors?.th?.accountName
                        }
                    />
                </div>
                <InputTextForm
                    form={bankAccountForm}
                    keyData="accountNumber"
                    className={classNameInput}
                />
                <InputSelectForm
                    dataSource={PAYMENT_METHOD_STATUSES}
                    form={bankAccountForm}
                    keyData="status"
                    className={classNameInput}
                />
                <InputTextForm
                    form={bankAccountForm}
                    keyData="priority"
                    className={classNameInput}
                />
                <BranchSelect
                    className={classNameInput}
                    value={formValues?.branch}
                    onChange={(value) => setFieldValue("branch", value)}
                />
            </div>
        </Drawer>
    );
};

export default PaymentMethodBankAccount;
