import { StringUtils } from "d-react-components";
import { isEmpty, map } from "lodash";
import { Event } from "react-big-calendar";
import { CalendarRef, CalendarStatus } from "../api/hooks";
import Messages from "../languages/Messages";
import { IBooking } from "./booking";
import { IBranch } from "./branch";
import { ICustomer } from "./customer";
import { IEquipment } from "./equipment";
import { ITreatment } from "./treatment";
import IUser, { JobType, WorkingSchedule } from "./user";

export enum CalendarAdditionalSubjectType {
    EQUIPMENT = "EQUIPMENT",
    ROOM = "ROOM",
}

export type CalendarSubjectType = CalendarAdditionalSubjectType | JobType;

export interface ICalendarUser {
    adminUser?: IUser;
    schedule?: WorkingSchedule["schedule"];
    isHost?: boolean;
}

export interface ICalendarEquipment {
    equipment?: IEquipment;
    schedule?: WorkingSchedule["schedule"];
}

export interface ICalendar {
    id?: string;
    ref: CalendarRef;
    refId: string;
    status: CalendarStatus;
    branch: IBranch;
    title: string;
    description?: string;
    calendarNo: string;
    adminUsers?: ICalendarUser[];
    equipments?: ICalendarEquipment[];
    customers?: ICustomer[];
    booking?: IBooking;
    treatment?: ITreatment;
    day: string;
    startDate: Date;
    endDate: Date;
    scheduleDate: Date;
    schedule: {
        start: number;
        end: number;
    };
    createByAdmin?: IUser;
    createdAt?: Date;
    updatedAt?: Date;
    __typename?: string;
}

export interface ICalendarEvent extends Event {
    id?: string;
    resource?: ICalendar;
}

const getCalendarTitleByRef = (ref: CalendarRef) => {
    switch (ref) {
        case CalendarRef.Booking:
            return Messages.booking;
        case CalendarRef.Treatment:
            return Messages.treatment;
        default:
            return "N/A";
    }
};

export const mapCalendarDataToEvent = (
    calendarData: ICalendar
): ICalendarEvent => {
    const { startDate, endDate, title = "N/A", ref } = calendarData;
    return {
        id: StringUtils.getUniqueID(),
        title: getCalendarTitleByRef(ref),
        allDay: false,
        end: new Date(endDate),
        start: new Date(startDate),
        resource: calendarData,
    };
};

export const mapListCalendarDataToEvents = (
    list: ICalendar[]
): ICalendarEvent[] => {
    if (isEmpty(list)) {
        return [];
    }
    return map(list, mapCalendarDataToEvent);
};
