import { Select as SelectAntd } from "antd";
import ClassNames from "classnames";
import { Icon, ObjectUtils, Select, ViewLabelStatus } from "d-react-components";
import { debounce, find, isArray, isEmpty, map, uniqBy } from "lodash";
import {
    forwardRef,
    ForwardRefRenderFunction,
    Fragment,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useGetCustomerListLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import { CUSTOMER_STATUS } from "../../../constant/customer";
import { CustomerStatus, ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const { Option } = SelectAntd;

export interface ICustomerSelect<T> {
    className?: string;
    value?: T | T[];
    multiple?: boolean;
    onChange: (value: T | T[]) => void;
    variant?: "select" | "search";
    showLabel?: boolean;
    [key: string]: any;
}

export interface ICustomerSearchOption {
    showEmptyNote?: boolean;
    filter?: any;
    onFoundCallback?: (props: any) => void;
}

export interface ICustomerMethod {
    onSearch: (text: string, options?: ICustomerSearchOption) => void;
}

const CustomerSelect: ForwardRefRenderFunction<
    ICustomerMethod,
    ICustomerSelect<ICustomer>
> = (
    {
        value = [],
        multiple,
        className,
        onChange,
        showLabel = true,
        variant = "select",
        placeholder = Messages.pleaseSearchAndSelect,
        ...selectProps
    },
    ref
) => {
    const navigate = useNavigate();
    const selectClass = ClassNames(`customer-select--${variant}`, className);
    const [customerList, setCustomerList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getCustomerList] = useGetCustomerListLazyQuery();
    const [searchValue, setSearchValue] = useState<any>();
    const [displayEmptyNote, setDisplayEmptyNote] = useState(false);

    useImperativeHandle(ref, () => ({
        onSearch: (text, option) => {
            setSearchValue(text);
            onChangeTextSearch(text, option);
        },
    }));

    const onChangeTextSearch = debounce(
        (textSearch, option?: ICustomerSearchOption) => {
            setDisplayEmptyNote(false);
            if (isEmpty(textSearch)) {
                setCustomerList([]);
            } else {
                const { filter, showEmptyNote, onFoundCallback } = option || {};
                getCustomerList({
                    variables: {
                        paginate: {
                            page: 1,
                            limit: 50,
                            search: textSearch,
                            sort: {
                                status: -1,
                            },
                            statuses: [
                                CustomerStatus.VERIFIED,
                                CustomerStatus.UN_VERIFIED,
                            ],
                            ...(filter || {}),
                        },
                    },
                }).then((res: any) => {
                    const customerRes = res?.data?.data?.data ?? [];
                    const selectedValue = isArray(value) ? value : [value];
                    const filterCustomer = map(
                        [...selectedValue, ...customerRes],
                        (item) => ({
                            ...item,
                            id: item.id,
                            name: item.name,
                        })
                    );
                    const uniqCustomer = uniqBy(
                        filterCustomer,
                        (item) => item.id
                    );
                    if (showEmptyNote && isEmpty(uniqCustomer)) {
                        setDisplayEmptyNote(true);
                    }
                    if (
                        onFoundCallback &&
                        typeof onFoundCallback === "function"
                    ) {
                        onFoundCallback(uniqCustomer);
                    }
                    setCustomerList(uniqCustomer);
                });
            }
        },
        500
    );

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(customerList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(customerList, id));
    };

    const customerValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item?.id);
        }
        return (value as any)?.id;
    }, [value]);

    const labelContent = (item: ICustomer) => {
        if (!item) {
            return <div />;
        }
        return (
            <div
                className={`p-2 flex items-center ${ClassNames({
                    "pointer-events-none relative": variant === "search",
                })}`}
            >
                <Image
                    src={item?.avatar ?? "/images/placeholder.png"}
                    className="image-square-small"
                />
                <div className="ml-2 flex-column">
                    <div className="flex-center-y">
                        <h5 className="mr-1">{`${item?.fullNameTh} (${
                            item?.nickname ?? "N/A"
                        })`}</h5>
                        <Icon
                            name="check_circle"
                            className={
                                item.status === CustomerStatus.VERIFIED
                                    ? "text-green-500"
                                    : "text-yellow-300"
                            }
                        />
                    </div>
                    <span className="text-sm block text-gray-700 mt-1">
                        {Messages.customerId}: {item?.customerNo}
                    </span>
                </div>
                {variant === "search" && (
                    <div
                        style={{ pointerEvents: "all" }}
                        className="p-0 absolute right-0"
                        onClick={(e) => {
                            navigate(
                                generatePath(Path.CUSTOMER_DETAIL, {
                                    customerId: item?.id,
                                })
                            );
                        }}
                    >
                        <Icon name="visibility" />
                    </div>
                )}
            </div>
        );
    };

    const renderSelectLabel = (item: ICustomer) => {
        if (variant === "search") {
            return (
                <AppLink
                    to={generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: item?.id,
                    })}
                >
                    <div className="cursor-pointer">{labelContent(item)}</div>
                </AppLink>
            );
        }
        return labelContent(item);
    };

    const renderSelectTag = (tagProps: any) => {
        let customer: any = value;
        if (multiple) {
            customer = find(value, (item: any) => item?.id === tagProps.value);
        }

        return (
            <div className="text-white bg-primary px-3 py-1 mr-2 small d-flex">
                {customer?.nickname}
                <Icon
                    name="clear"
                    onClick={tagProps.onClose}
                    className="text-white small ml-2 cursor-pointer"
                />
            </div>
        );
    };

    const content = (
        <Select
            onSearch={(text) => {
                setSearchValue(text);
                onChangeTextSearch(text);
            }}
            className={selectClass}
            label={showLabel ? Messages.customer : ""}
            dataSource={customerList}
            value={customerValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={placeholder}
            showSearch
            searchValue={searchValue}
            filterOption={false}
            getLabel={renderSelectLabel}
            // for multiple select
            tagRender={renderSelectTag}
            //for single select
            optionLabelProp="label"
            getOptionProps={(item) =>
                ({
                    label: item?.nickname,
                } as any)
            }
            {...selectProps}
        />
    );
    if (variant === "search" && displayEmptyNote) {
        return (
            <Fragment>
                {content}
                <div className="small text-secondary mt-3">
                    {Messages.emptySearchCustomerNote}
                </div>
            </Fragment>
        );
    }
    return content;
};

export default forwardRef(CustomerSelect);
