import { Button, Icon, ObjectUtils, StringUtils } from "d-react-components";
import { map } from "lodash";
import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import EmergencyContactForm from "../share/EmergencyContactForm";

const CustomerCreateEmergency = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    const emergencyContactList = customerForm?.values?.emergencyContact ?? [];
    const onUpdateEmergencyContactList = (contact: any) => {
        const result = ObjectUtils.updateArrayById(
            emergencyContactList,
            contact
        );

        customerForm.setFieldValue("emergencyContact", result);
    };

    const onAddContact = () => {
        customerForm.setFieldValue("emergencyContact", [
            ...emergencyContactList,
            { id: StringUtils.getUniqueID() },
        ]);
    };

    const onRemoveContact = (index: string) => {
        customerForm.setFieldValue(
            "emergencyContact",
            emergencyContactList.filter((_: any, i: string) => i !== index)
        );
    };

    return (
        <div className="card-container p-3">
            {map(emergencyContactList, (contact, index) => (
                <div className="mt-3 mb-5 px-3">
                    <div className="flex">
                        <label className="flex-1">{`${Messages.contactInfo} ${
                            index + 1
                        }`}</label>
                        <Icon
                            className="cursor-pointer"
                            name="remove_circle_outline"
                            onClick={() => onRemoveContact(index)}
                        />
                    </div>
                    <EmergencyContactForm
                        contact={contact}
                        onChange={(value: any) =>
                            onUpdateEmergencyContactList(value)
                        }
                    />
                </div>
            ))}
            <Button
                className="mt-4 mx-3"
                variant="trans"
                iconName="add"
                onClick={onAddContact}
            >
                {Messages.addNew}
            </Button>
        </div>
    );
};

export default CustomerCreateEmergency;
