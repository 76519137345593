import { Switch } from "antd";
import React from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import Messages from "../../../languages/Messages";

interface IProductMedicineForm {
    configForm: any;
    isEdit?: boolean;
}

const ProductConfigMedicineForm = ({
    configForm,
    isEdit = false,
}: IProductMedicineForm) => {
    const productFormValue = configForm.values;

    return (
        <div className="mt-3">
            <label>{Messages.additionalConfiguration}</label>
            <div className="border p-3">
                {productFormValue?.typeOfProduct ===
                    PRODUCT_TYPE.SIMPLE_PRODUCT && (
                    <div className="d-flex align-items-center mb-3">
                        <Switch
                            checked={productFormValue?.isMedicine}
                            onChange={() =>
                                configForm.setFieldValue(
                                    "isMedicine",
                                    !productFormValue?.isMedicine
                                )
                            }
                            disabled={isEdit}
                        />
                        <text className="ml-3">{Messages.thisIsMedicine}</text>
                    </div>
                )}
                {productFormValue?.typeOfProduct ===
                    PRODUCT_TYPE.SERVICE_PRODUCT && (
                    <div className="d-flex align-items-center">
                        <Switch
                            checked={productFormValue?.isOperationService}
                            onChange={() =>
                                configForm.setFieldValue(
                                    "isOperationService",
                                    !productFormValue?.isOperationService
                                )
                            }
                            disabled={isEdit}
                        />
                        <text className="ml-3">
                            {Messages.thisIsOperationProduct}
                        </text>
                    </div>
                )}
                <div className="d-flex align-items-center">
                    <Switch
                        checked={productFormValue?.isNonVAT}
                        onChange={() =>
                            configForm.setFieldValue(
                                "isNonVAT",
                                !productFormValue?.isNonVAT
                            )
                        }
                        disabled={isEdit}
                    />
                    <text className="ml-3">{Messages.thisIsNonVatProduct}</text>
                </div>
            </div>
        </div>
    );
};

export default ProductConfigMedicineForm;
