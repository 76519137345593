import {
    Button,
    DateInput,
    Notifications,
    Progress,
    TimeUtils,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import TreatmentAPI from "../../../api/queries/treatment";
import Drawer from "../../../common/Drawer";
import Image from "../../../common/Image";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import UploadButton from "../../../common/upload/UploadButton";
import { TreatmentDetailContext } from "../../../context/treatment";
import { TreatmentBeforeAfterSchema } from "../../../formschema/treatment";
import {
    ITreatmentBeforeAfter,
    mapTreatmentBeforeAfterToServer,
} from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";

interface ITreatmentBeforeAfterItem {
    beforeAfter: ITreatmentBeforeAfter;
    onEdit: () => void;
}

const TreatmentBeforeAfterItem = ({
    beforeAfter,
    onEdit,
}: ITreatmentBeforeAfterItem) => {
    return (
        <div className="p-3 border mt-3">
            <div className="flex-row-between-center">
                <label>{beforeAfter?.refNo}</label>
                <Button onClick={onEdit} className="" variant="trans">
                    {Messages.edit}
                </Button>
            </div>
            <div>
                {`${Messages.recordedAt} ${TimeUtils.toDateTime(
                    beforeAfter?.recordedAt
                )}`}
            </div>
            <div
                className="text mt-3"
                dangerouslySetInnerHTML={{
                    __html: beforeAfter?.description,
                }}
            />
            <div
                className="d-flex mt-3 position-relative"
                style={{ width: "600px", height: "600px" }}
            >
                <ReactBeforeSliderComponent
                    className=""
                    firstImage={{ imageUrl: beforeAfter?.before }}
                    secondImage={{ imageUrl: beforeAfter?.after }}
                />

                <div className="absolute b-0 l-0 bg-primary text-white px-2 py-1">
                    {Messages.before}
                </div>
                <div className="absolute t-0 r-0 bg-primary text-white px-2 py-1">
                    {Messages.after}
                </div>
            </div>
        </div>
    );
};

interface ITreatmentBeforeAfterCrudDrawer {
    beforeAfter?: ITreatmentBeforeAfter;
    open: boolean;
    onClose: () => void;
}

const TreatmentBeforeAfterCrudDrawer = ({
    open,
    beforeAfter,
    onClose,
}: ITreatmentBeforeAfterCrudDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);

    const treatmentForm = useFormik<any>({
        initialValues: beforeAfter
            ? {
                  date: moment(beforeAfter?.recordedAt),
                  description: beforeAfter?.description,
                  before: { imageData: beforeAfter?.before },
                  after: { imageData: beforeAfter?.after },
              }
            : {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: TreatmentBeforeAfterSchema,
        onSubmit: (values: any) => {
            const input = mapTreatmentBeforeAfterToServer(values);
            beforeAfter ? onUpdateBeforeAfter(input) : onAddBeforeAfter(input);
        },
    });

    const formValues = treatmentForm?.values;
    const formErrors = treatmentForm?.errors;

    const classNameInput = "col-12 mt-3";

    const onUpdateBeforeAfter = (input: any) => {
        Progress.show(
            {
                method: TreatmentAPI.updateBeforeAfter,
                params: [treatment?.id, beforeAfter?.id, input],
            },
            (treatment) => {
                setTreatment(treatment);
                Notifications.showSuccess(Messages.updateSuccess);
                onClose();
            }
        );
    };

    const onAddBeforeAfter = (input: any) => {
        Progress.show(
            {
                method: TreatmentAPI.addBeforeAfter,
                params: [treatment?.id, input],
            },
            (treatment) => {
                setTreatment(treatment);
                Notifications.showSuccess(Messages.addSuccess);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.beforeAfter}
            onSave={() => treatmentForm.handleSubmit()}
            size="auto"
            width="80vw"
        >
            <div className="rows">
                <DateInput
                    label={Messages.recordedAt}
                    onChange={(value) => {
                        treatmentForm.setFieldValue("date", value);
                    }}
                    value={formValues?.date}
                    format="DD/MM/YYYY"
                    error={formErrors?.date as any}
                    className={classNameInput}
                />
                <div className={classNameInput}>
                    <RichTextTiny
                        label={Messages.description}
                        value={formValues?.description ?? ""}
                        onChange={(value: any) =>
                            treatmentForm.setFieldValue(
                                `description`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.description}
                        error={formErrors?.description}
                    />
                </div>
                <div className={classNameInput}>
                    <label>{Messages.before}</label>

                    <UploadButton
                        onChange={([file]: any) => {
                            treatmentForm.setFieldValue("before", file);
                        }}
                        defaultFiles={
                            formValues?.before ? [formValues?.before] : []
                        }
                    />
                    <ViewTextError error={formErrors.before} />
                </div>
                <div className={classNameInput}>
                    <label>{Messages.after}</label>
                    <UploadButton
                        onChange={([file]: any) =>
                            treatmentForm.setFieldValue("after", file)
                        }
                        defaultFiles={
                            formValues?.after ? [formValues?.after] : []
                        }
                    />
                    <ViewTextError error={formErrors.after} />
                </div>
            </div>
        </Drawer>
    );
};

const TreatmentDetailBeforeAfter = () => {
    const { treatment } = useContext(TreatmentDetailContext);
    const [openAdd, setOpenAdd] = useState(false);
    const [beforeAfterEditing, setBeforeAfterEditing] =
        useState<ITreatmentBeforeAfter | null>(null);
    return (
        <div className="flex-column">
            <div className="flex-row-between-center mb-3">
                <h5>{Messages.beforeAfter}</h5>
                <Button onClick={() => setOpenAdd(true)} className="">
                    {Messages.add}
                </Button>
            </div>

            <div className="flex-column">
                {map(treatment.beforeAfter, (beforeAfterItem) => (
                    <TreatmentBeforeAfterItem
                        beforeAfter={beforeAfterItem}
                        key={beforeAfterItem?.id}
                        onEdit={() => setBeforeAfterEditing(beforeAfterItem)}
                    />
                ))}
            </div>
            {openAdd && (
                <TreatmentBeforeAfterCrudDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}

            {beforeAfterEditing && (
                <TreatmentBeforeAfterCrudDrawer
                    open={!!beforeAfterEditing}
                    onClose={() => setBeforeAfterEditing(null)}
                    beforeAfter={beforeAfterEditing}
                />
            )}
        </div>
    );
};

export default TreatmentDetailBeforeAfter;
