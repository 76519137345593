import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import React, { useContext } from "react";
import { Link, generatePath } from "react-router-dom";
import { EQUIPMENT_VISIBILITIES } from "../../../constant/equipment";
import { EquipmentDetailContext } from "../../../context/equipment";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const EquipmentSummaryView = () => {
    const { equipment } = useContext(EquipmentDetailContext);
    const DETAILS_KEY: IRowsKey<IEquipment>[] = [
        {
            id: "equipmentNo",
            label: Messages.id,
        },
        {
            id: "category",
            label: Messages.category,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "model",
            label: Messages.model,
        },
        {
            id: "serialNo",
            label: Messages.serialNo,
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.updatedAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {equipment && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            <Link
                                to={generatePath(Path.EQUIPMENT_DETAIL, {
                                    equipmentId: equipment?.id,
                                })}
                            >
                                {equipment?.name}
                            </Link>
                        </div>
                        <ViewLabelStatus
                            status={equipment?.visibility}
                            listStatus={EQUIPMENT_VISIBILITIES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={equipment}
                            keyList={DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EquipmentSummaryView;
