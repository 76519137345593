import { ViewLabelStatus } from "d-react-components";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { ActionButton } from "../../../common/ActionButton";
import { APPOINTMENT_STATUSES } from "../../../constant/appointment";
import { AppointmentDetailContext } from "../../../context/appointment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const AppointmentDetailHeader = () => {
    const { appointment } = useContext(AppointmentDetailContext);
    const navigate = useNavigate();

    return (
        <div className="bg-white px-4 py-3 flex-row-between-center border-bottom">
            <div className="flex flex-1 align-items-center">
                <h5 className="text-primary">{`${appointment?.appointmentNo}`}</h5>
                <ViewLabelStatus
                    status={appointment?.status}
                    listStatus={APPOINTMENT_STATUSES}
                    className="ml-3"
                />
            </div>
            <div className="d-flex">
                <ActionButton
                    onClick={() => {
                        navigate(
                            Path.BOOKING_CREATE.replace(
                                ":appointmentId?",
                                appointment?.id
                            )
                        );
                    }}
                    className="ml-2"
                >
                    {Messages.createBooking}
                </ActionButton>

                <ActionButton onClick={() => {}} className="ml-2">
                    {Messages.print}
                </ActionButton>
            </div>
        </div>
    );
};

export default AppointmentDetailHeader;
