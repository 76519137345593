import { gql } from "@apollo/client";

import API from "../API";
import {
    F_TRANSFER_VOUCHER,
    F_TRANSFER_VOUCHERS,
} from "../fragments/transfer-voucher";

export const getTransferVoucherDetail = gql`
    ${F_TRANSFER_VOUCHER}
    query detailTransferVoucherForAdmin($id: String!) {
        data: detailTransferVoucherForAdmin(id: $id) {
            data {
                ...F_TRANSFER_VOUCHER
            }
        }
    }
`;

export const getTransferVoucherList = gql`
    ${F_TRANSFER_VOUCHERS}
    query listTransferVoucherForAdmin(
        $input: ListAdminTransferVoucherRequest!
    ) {
        data: listTransferVoucherForAdmin(paginate: $input) {
            ...F_TRANSFER_VOUCHERS
        }
    }
`;

export const createTransferVoucher = gql`
    ${F_TRANSFER_VOUCHER}
    mutation createTransferVoucher(
        $payload: CreateAdminTransferVoucherRequest!
    ) {
        data: createTransferVoucherForAdmin(payload: $payload) {
            data {
                ...F_TRANSFER_VOUCHER
            }
        }
    }
`;
export const approveTransferVoucher = gql`
    ${F_TRANSFER_VOUCHER}
    mutation approveTransferVoucher($id: String!, $remark: String!) {
        data: approveTransferVoucherForAdmin(id: $id, remark: $remark) {
            data {
                ...F_TRANSFER_VOUCHER
            }
        }
    }
`;

export const cancelTransferVoucher = gql`
    ${F_TRANSFER_VOUCHER}
    mutation cancelTransferVoucher($id: String!, $remark: String!) {
        data: cancelTransferVoucherForAdmin(id: $id, remark: $remark) {
            data {
                ...F_TRANSFER_VOUCHER
            }
        }
    }
`;

const transferVoucherConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? {};
        return detail;
    },
};

const transferVouchersConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const TransferVoucherAPI = {
    list: async (input: any) =>
        API.withConverter(transferVouchersConverter).query({
            query: getTransferVoucherList,
            variables: { input },
        }),

    detail: async (id: any) =>
        API.withConverter(transferVoucherConverter).query({
            query: getTransferVoucherDetail,
            variables: { id },
        }),

    create: async (payload: any) =>
        API.withConverter(transferVoucherConverter).mutate({
            mutation: createTransferVoucher,
            variables: { payload },
        }),

    approve: async (id: string, remark: string) =>
        API.withConverter(transferVoucherConverter).mutate({
            mutation: approveTransferVoucher,
            variables: { id, remark },
        }),

    cancel: async (id: string, remark: string) =>
        API.withConverter(transferVoucherConverter).mutate({
            mutation: cancelTransferVoucher,
            variables: { id, remark },
        }),
};

export default TransferVoucherAPI;
