import React from "react";
import { IAddress, IBillingAddress } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";

const AddressItem = ({
    address,
    displayType = "col",
}: {
    address: IAddress | IBillingAddress;
    displayType?: "row" | "col";
}) => {
    const {
        id,
        phone,
        isDefault,
        typeOfPlace,
        firstName = "",
        lastName = "",
        nickName = "",
        district,
        subDistrict,
        country,
        postcode,
        province,
        address: addressInfo,
        remark,
    } = address;
    return displayType === "col" ? (
        <div className="flex-column">
            <span className="mb-1 text-bold">
                {firstName} {lastName} ({nickName})
            </span>
            <span className="mb-1">{phone}</span>
            {(address as IBillingAddress).citizenId && (
                <span className="mb-1">
                    {Messages.citizendId}{" "}
                    {(address as IBillingAddress).citizenId}
                </span>
            )}
            <span className="mb-1">{addressInfo}</span>
            <span className="mb-1">
                {subDistrict?.name}, {district?.name}
            </span>
            <span className="mb-1">
                {province?.name}, {postcode}
            </span>
            <span>{Messages[typeOfPlace]}</span>
            {remark && (
                <div className="my-2 p-3 bg-[#DCE5E5]">
                    <span className="font-semibold">{Messages.remark}</span>:{" "}
                    {remark}
                </div>
            )}
        </div>
    ) : (
        <div className="flex">
            <span className="mb-1">
                {firstName} {lastName}
            </span>
            {` - `}
            <span className="mb-1">{phone}</span>
            {` - `}
            <span className="mb-1">
                {addressInfo}, {subDistrict?.name}, {district?.name},{" "}
                {province?.name}, {postcode}
            </span>
        </div>
    );
};

export default AddressItem;
