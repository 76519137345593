import {
    Button,
    ButtonProps,
    Drawer as DDrawer,
    IDrawerProps,
} from "d-react-components";
import Messages from "../languages/Messages";

interface DrawerProps extends IDrawerProps {
    saveText?: string;
    cancelText?: string;
    onSave?: () => void;
    disableSave?: boolean;
    hideFooter?: boolean;
    cancelButtonProps?: ButtonProps;
}

export interface CustomDrawerProps {
    open: boolean;
    onClose: () => void;
    item?: any;
    [key: string]: any;
}

export const Drawer = (props: DrawerProps) => {
    const {
        saveText,
        cancelText,
        onSave,
        onClose,
        disableSave = false,
        hideFooter = false,
        cancelButtonProps = {},
    } = props;
    return (
        <DDrawer {...props} onClose={onClose} destroyOnClose>
            <div className="pb-16">{props.children}</div>
            {!hideFooter && (
                <div className="position-absolute z-50 bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
                    {onClose && (
                        <Button
                            onClick={(event) => {
                                onClose && onClose(event);
                            }}
                            variant="outline"
                            {...cancelButtonProps}
                        >
                            {cancelText || Messages.cancel}
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            onSave && onSave();
                        }}
                        disabled={disableSave}
                    >
                        {saveText || Messages.save}
                    </Button>
                </div>
            )}
        </DDrawer>
    );
};

export default Drawer;
