import React from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";
import PhoneCodeInput from "../../address/share/PhoneCodeInput";

interface IContactForm {
    form: any;
}

const ContactForm = ({ form: customerForm }: IContactForm) => {
    const inputClassName = "col-12 mt-3";

    return (
        <div className="row p-3">
            <PhoneCodeInput className={inputClassName} form={customerForm} />
            <InputTextForm
                label={Messages.alternativePhoneNo}
                keyData="alternativePhone"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="email"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="lineId"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="instagram"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="facebook"
                form={customerForm}
                className={inputClassName}
            />
        </div>
    );
};

export default ContactForm;
