/* eslint-disable import/prefer-default-export */

import Messages from "../languages/Messages";
import Path from "../screen/Path";
import { PERMISSION } from "./user";

export const API_GOOGLE_KEY = "AIzaSyCo-pN_alJHEpOXRIerbH9SZ88urh9Ag-w";
export const FIREBASE_MESSAGING_KEY =
    "BIp4RcTf2S77-YcnPjs7H0FbSdKmmREJyYrUa8ALdNiCxShtQfog8vtF67DHJKsHDcDxOi3mm4kW8lL0VMVp2-I";

export const MAIN_MENU = [
    {
        id: "dashboard",
        label: "dashboard",
        iconName: "dashboard",
        path: Path.ROOT,
    },
    {
        id: "report",
        label: "report",
        iconName: "report",
        subMenu: [
            {
                id: "cashier",
                label: "cashierReportByOrder",
                path: Path.REPORT_CASHIER,
                accessType: PERMISSION.REPORT.CASHIER,
            },
            {
                id: "cashierPayment",
                label: "cashierReportByPayment",
                path: Path.REPORT_CASHIER_PAYMENT,
                accessType: PERMISSION.REPORT.CASHIER_PAYMENT,
            },
            {
                id: "salespersonUser",
                label: "salespersonUserReport",
                path: Path.REPORT_SALESPERSON_USER,
                accessType: PERMISSION.REPORT.SALESPERSON_USER,
            },
            {
                id: "salespersonMonthlyReport",
                label: "salespersonMonthlyReport",
                path: Path.REPORT_SALESPERSON_MONTHLY,
                accessType: PERMISSION.REPORT.SALESPERSON_MONTHLY,
            },
            {
                id: "customerSourceReport",
                label: "customerSourceReport",
                path: Path.REPORT_CUSTOMER_SOURCE,
                accessType: PERMISSION.REPORT.CUSTOMER_SOURCE,
            },
            {
                id: "doctorDetailReport",
                label: "doctorDetailReport",
                path: Path.REPORT_DOCTOR_DETAIL,
                accessType: PERMISSION.REPORT.DOCTOR_DETAIL,
            },
            {
                id: "doctorSummaryReport",
                label: "doctorSummaryReport",
                path: Path.REPORT_DOCTOR_SUMMARY,
                accessType: PERMISSION.REPORT.DOCTOR_SUMMARY,
            },
            {
                id: "nurseDetailReport",
                label: "nurseDetailReport",
                path: Path.REPORT_NURSE_DETAIL,
                accessType: PERMISSION.REPORT.NURSE_DETAIL,
            },
            {
                id: "nurseSummaryReport",
                label: "nurseSummaryReport",
                path: Path.REPORT_NURSE_SUMMARY,
                accessType: PERMISSION.REPORT.NURSE_SUMMARY,
            },
            {
                id: "therapistDetailReport",
                label: "therapistDetailReport",
                path: Path.REPORT_THERAPIST_DETAIL,
                accessType: PERMISSION.REPORT.THERAPIST_DETAIL,
            },
            {
                id: "therapistSummaryReport",
                label: "therapistSummaryReport",
                path: Path.REPORT_THERAPIST_SUMMARY,
                accessType: PERMISSION.REPORT.THERAPIST_SUMMARY,
            },
            {
                id: "customerVoucherReport",
                label: "customerVoucherReport",
                path: Path.REPORT_CUSTOMER_VOUCHER,
                accessType: PERMISSION.REPORT.CUSTOMER_VOUCHER,
            },
            {
                id: "treatmentDailyUsageReport",
                label: "treatmentDailyUsageReport",
                path: Path.REPORT_TREATMENT_DAILY_USAGE,
                accessType: PERMISSION.REPORT.TREATMENT_DAILY_USAGE,
            },
        ],
    },
    {
        id: "customer",
        label: "customers",
        iconName: "wheelchair_pickup",
        subMenu: [
            {
                id: "customers",
                label: "customerTable",
                path: Path.CUSTOMER_TABLE,
                accessType: PERMISSION.CUSTOMER.LIST,
            },
            {
                id: "customerCreate",
                label: "customerCreate",
                path: Path.CUSTOMER_CREATE,
                accessType: PERMISSION.CUSTOMER.CREATE,
                breadcrumb: [
                    {
                        title: Messages.customerTable,
                        url: Path.CUSTOMER_TABLE,
                    },
                    {
                        title: Messages.customerCreate,
                    },
                ],
            },
            {
                id: "customerDetail",
                show: false,
                path: Path.CUSTOMER_DETAIL,
                breadcrumb: [
                    {
                        title: Messages.customerTable,
                        url: Path.CUSTOMER_TABLE,
                    },
                    {
                        title: Messages.customerDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "quotation",
        label: "quotation",
        iconName: "request_quote",
        subMenu: [
            {
                id: "quotationList",
                label: "quotationTable",
                accessType: PERMISSION.QUOTATION.LIST,
                path: Path.QUOTATION_TABLE,
            },
            {
                id: "quotationCreate",
                label: "newQuotation",
                path: Path.QUOTATION_CREATE,
                accessType: PERMISSION.QUOTATION.CREATE,
                breadcrumb: [
                    {
                        title: Messages.quotationTable,
                        url: Path.QUOTATION_TABLE,
                    },
                    {
                        title: Messages.newQuotation,
                    },
                ],
            },
            {
                id: "quotationDetail",
                label: "quotationDetail",
                path: Path.QUOTATION_DETAIL,
                accessType: PERMISSION.QUOTATION.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.quotationTable,
                        url: Path.QUOTATION_TABLE,
                    },
                    {
                        title: Messages.quotationDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "order",
        label: "order",
        iconName: "shopping_cart",
        subMenu: [
            {
                id: "orderList",
                label: "orderTable",
                accessType: PERMISSION.ORDER.LIST,
                path: Path.ORDER_TABLE,
            },
            {
                id: "orderCreate",
                label: "newOrder",
                path: Path.ORDER_CREATE,
                accessType: PERMISSION.ORDER.CREATE,
                breadcrumb: [
                    {
                        title: Messages.orderTable,
                        url: Path.ORDER_TABLE,
                    },
                    {
                        title: Messages.newOrder,
                    },
                ],
            },
            {
                id: "orderDetail",
                label: "orderDetail",
                path: Path.ORDER_DETAIL,
                accessType: PERMISSION.ORDER.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.orderTable,
                        url: Path.ORDER_TABLE,
                    },
                    {
                        title: Messages.orderDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "appointment",
        label: "appointment",
        iconName: "book_online",
        subMenu: [
            {
                id: "appointments",
                label: "appointmentTable",
                accessType: PERMISSION.APPOINTMENT.LIST,
                path: Path.APPOINTMENT_TABLE,
            },
            // {
            //     id: "appointmentCreate",
            //     label: "appointmentCreate",
            //     path: Path.APPOINTMENT_CREATE,
            //     accessType: PERMISSION.APPOINTMENT.CREATE,
            //     breadcrumb: [
            //         {
            //             title: Messages.appointmentTable,
            //             url: Path.APPOINTMENT_TABLE,
            //         },
            //         {
            //             title: Messages.appointmentCreate,
            //         },
            //     ],
            // },
            {
                id: "appointmentDetail",
                label: "appointmentDetail",
                path: Path.APPOINTMENT_DETAIL,
                accessType: PERMISSION.APPOINTMENT.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.appointmentTable,
                        url: Path.APPOINTMENT_TABLE,
                    },
                    {
                        title: Messages.appointmentDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "booking",
        label: "bookings",
        iconName: "event_available",
        subMenu: [
            {
                id: "bookings",
                label: "bookingTable",
                path: Path.BOOKING_TABLE,
                selected: true,
                accessType: PERMISSION.BOOKING.LIST,
            },
            {
                id: "bookingCreate",
                label: "bookingCreate",
                path: Path.BOOKING_CREATE.replace("/:appointmentId?", ""),
                accessType: PERMISSION.BOOKING.CREATE,
                breadcrumb: [
                    {
                        title: Messages.bookingTable,
                        url: Path.BOOKING_TABLE,
                    },
                    {
                        title: Messages.bookingCreate,
                    },
                ],
            },
            {
                id: "bookingDetail",
                show: false,
                path: Path.BOOKING_DETAIL,
                breadcrumb: [
                    {
                        title: Messages.bookingTable,
                        url: Path.BOOKING_TABLE,
                    },
                    {
                        title: Messages.bookingDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "doctorOrder",
        label: "doctorOrder",
        iconName: "medication_liquid",
        subMenu: [
            {
                id: "doctorOrderList",
                label: "doctorOrderTable",
                accessType: PERMISSION.DOCTOR_ORDER.LIST,
                path: Path.DOCTOR_ORDER_TABLE,
            },
            {
                id: "doctorOrderCreate",
                label: "newDoctorOrder",
                path: Path.DOCTOR_ORDER_CREATE,
                accessType: PERMISSION.DOCTOR_ORDER.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.doctorOrderTable,
                        url: Path.DOCTOR_ORDER_TABLE,
                    },
                    {
                        title: Messages.newDoctorOrder,
                    },
                ],
            },
            {
                id: "doctorOrderDetail",
                label: "doctorOrderDetail",
                path: Path.DOCTOR_ORDER_DETAIL,
                accessType: PERMISSION.DOCTOR_ORDER.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.doctorOrderTable,
                        url: Path.DOCTOR_ORDER_TABLE,
                    },
                    {
                        title: Messages.doctorOrderDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "treatment",
        label: "treatments",
        iconName: "vaccines",
        subMenu: [
            {
                id: "treatments",
                label: "treatmentTable",
                path: Path.TREATMENT_TABLE,
                selected: true,
                accessType: PERMISSION.TREATMENT.LIST,
            },
            {
                id: "treatmentCreate",
                label: "treatmentCreate",
                show: false,
                path: Path.TREATMENT_CREATE,
                accessType: PERMISSION.TREATMENT.CREATE,
                breadcrumb: [
                    {
                        title: Messages.treatmentTable,
                        url: Path.TREATMENT_TABLE,
                    },
                    {
                        title: Messages.treatmentCreate,
                    },
                ],
            },
            {
                id: "treatmentDetail",
                show: false,
                path: Path.TREATMENT_DETAIL,
                breadcrumb: [
                    {
                        title: Messages.treatmentTable,
                        url: Path.TREATMENT_TABLE,
                    },
                    {
                        title: Messages.treatmentDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "product",
        label: "products",
        iconName: "medical_information",
        subMenu: [
            {
                id: "products",
                label: "productTable",
                path: Path.PRODUCT_TABLE,
                selected: true,
                accessType: PERMISSION.PRODUCT.LIST,
            },
            {
                id: "productCreate",
                label: "productCreate",
                path: Path.PRODUCT_CREATE,
                accessType: PERMISSION.PRODUCT.CREATE,
                breadcrumb: [
                    {
                        title: Messages.productTable,
                        url: Path.PRODUCT_TABLE,
                    },
                    {
                        title: Messages.productCreate,
                    },
                ],
            },
            {
                id: "productDetail",
                path: Path.PRODUCT_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.productTable,
                        url: Path.PRODUCT_TABLE,
                    },
                    {
                        title: Messages.productDetail,
                    },
                ],
            },
            {
                id: "productCategory",
                label: "productCategory",
                path: Path.PRODUCT_CATEGORY,
                accessType: PERMISSION.CATEGORY_PRODUCT.LIST,
            },
            {
                id: "productCategoryDetail",
                path: Path.PRODUCT_CATEGORY_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.productCategory,
                        url: Path.PRODUCT_CATEGORY,
                    },
                    {
                        title: Messages.productCategoryDetail,
                    },
                ],
            },
            {
                id: "productCategoryCreate",
                path: Path.PRODUCT_CATEGORY_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.productCategory,
                        url: Path.PRODUCT_CATEGORY,
                    },
                    {
                        title: Messages.productCategoryCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "promotion",
        label: "promotion",
        iconName: "group_add",
        subMenu: [
            {
                id: "promotions",
                label: "promotionTable",
                accessType: PERMISSION.PROMOTION.LIST,
                path: Path.PROMOTION_TABLE,
            },
            {
                id: "promotionCreate",
                label: "promotionCreate",
                path: Path.PROMOTION_CREATE,
                accessType: PERMISSION.PROMOTION.CREATE,
                breadcrumb: [
                    {
                        title: Messages.promotionTable,
                        url: Path.PROMOTION_TABLE,
                    },
                    {
                        title: Messages.promotionCreate,
                    },
                ],
            },
            {
                id: "promotionDetail",
                label: "promotionDetail",
                path: Path.PROMOTION_DETAIL,
                accessType: PERMISSION.PROMOTION.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.promotionTable,
                        url: Path.PROMOTION_TABLE,
                    },
                    {
                        title: Messages.promotionDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "inventory",
        label: "inventory",
        iconName: "warehouse",
        subMenu: [
            {
                id: "inventoryList",
                label: "inventoryList",
                path: Path.INVENTORY_TABLE,
                selected: true,
                accessType: PERMISSION.PRODUCT_STOCK_HISTORY.LIST,
            },
            // stock-adjustment

            {
                id: "stockAdjustmentRequest",
                label: "stockAdjustmentRequest",
                path: Path.INVENTORY_STOCK_ADJUSTMENT_TABLE,
                accessType: PERMISSION.PRODUCT_STOCK_ADJUSTMENT.LIST,
            },
            {
                id: "stockAdjustmentCreate",
                path: Path.INVENTORY_STOCK_ADJUSTMENT_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.stockAdjustmentRequest,
                        url: Path.INVENTORY_STOCK_ADJUSTMENT_TABLE,
                    },
                    {
                        title: Messages.newStockAdjustment,
                    },
                ],
            },
            {
                id: "stockAdjustmentDetail",
                path: Path.INVENTORY_STOCK_ADJUSTMENT_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.stockAdjustmentRequest,
                        url: Path.INVENTORY_STOCK_ADJUSTMENT_TABLE,
                    },
                    {
                        title: Messages.stockAdjustmentDetail,
                    },
                ],
            },

            // stock-request
            {
                id: "stockRequestTable",
                label: "stockRequestTable",
                path: Path.INVENTORY_REQUEST_STOCK_TABLE,
                accessType: PERMISSION.PRODUCT_STOCK_REQUEST.LIST,
            },
            {
                id: "stockRequestCreate",
                path: Path.INVENTORY_REQUEST_STOCK_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.stockRequestTable,
                        url: Path.INVENTORY_REQUEST_STOCK_TABLE,
                    },
                    {
                        title: Messages.stockRequestCreate,
                    },
                ],
            },
            {
                id: "stockRequestDetail",
                path: Path.INVENTORY_REQUEST_STOCK_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.stockRequestTable,
                        url: Path.INVENTORY_REQUEST_STOCK_TABLE,
                    },
                    {
                        title: Messages.stockRequestDetail,
                    },
                ],
            },

            // stock-transfer
            {
                id: "stockTransferTable",
                label: "stockTransferTable",
                path: Path.INVENTORY_TRANSFER_STOCK_TABLE,
                accessType: PERMISSION.PRODUCT_STOCK_TRANSFER.LIST,
            },
            {
                id: "stockTransferCreate",
                path: Path.INVENTORY_TRANSFER_STOCK_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.stockTransferTable,
                        url: Path.INVENTORY_TRANSFER_STOCK_TABLE,
                    },
                    {
                        title: Messages.stockTransferCreate,
                    },
                ],
            },
            {
                id: "stockTransferDetail",
                path: Path.INVENTORY_TRANSFER_STOCK_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.stockTransferTable,
                        url: Path.INVENTORY_TRANSFER_STOCK_TABLE,
                    },
                    {
                        title: Messages.stockTransferDetail,
                    },
                ],
            },
        ],
    },

    {
        id: "service",
        label: "services",
        iconName: "medical_services",
        subMenu: [
            {
                id: "serviceCategory",
                label: "serviceCategoryTable",
                path: Path.SERVICE_CATEGORY,
                accessType: PERMISSION.CATEGORY_SERVICE.LIST,
            },
            {
                id: "serviceCategoryDetail",
                label: "serviceCategoryDetail",
                path: Path.SERVICE_CATEGORY_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.serviceCategoryTable,
                        url: Path.SERVICE_CATEGORY,
                    },
                    {
                        title: Messages.serviceCategoryDetail,
                    },
                ],
            },
            {
                id: "serviceCategoryCreate",
                label: "serviceCategoryCreate",
                path: Path.SERVICE_CATEGORY_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.serviceCategoryTable,
                        url: Path.SERVICE_CATEGORY,
                    },
                    {
                        title: Messages.serviceCategoryCreate,
                    },
                ],
            },
            {
                id: "serviceConfiguration",
                label: "serviceConfiguration",
                path: Path.SERVICE_CONFIGURATION,
                accessType: PERMISSION.COMMON,
            },
            {
                id: "serviceConfigurationDetail",
                label: "serviceConfigurationDetail",
                path: Path.SERVICE_CONFIGURATION_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.serviceConfiguration,
                        url: Path.SERVICE_CONFIGURATION,
                    },
                    {
                        title: Messages.serviceConfigurationDetail,
                    },
                ],
            },
        ],
    },

    {
        id: "voucher",
        label: "voucher",
        iconName: "discount",
        subMenu: [
            {
                id: "vouchers",
                label: "voucherTable",
                accessType: PERMISSION.VOUCHER.LIST,
                path: Path.VOUCHER_TABLE,
            },
            {
                id: "voucherCreate",
                label: "voucherCreate",
                path: Path.VOUCHER_CREATE,
                accessType: PERMISSION.VOUCHER.CREATE,
                breadcrumb: [
                    {
                        title: Messages.voucherTable,
                        url: Path.VOUCHER_TABLE,
                    },
                    {
                        title: Messages.voucherCreate,
                    },
                ],
            },
            {
                id: "voucherDetail",
                label: "voucherDetail",
                path: Path.VOUCHER_DETAIL,
                accessType: PERMISSION.VOUCHER.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.voucherTable,
                        url: Path.VOUCHER_TABLE,
                    },
                    {
                        title: Messages.voucherDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "credit",
        label: "credit",
        iconName: "discount",
        path: Path.CREDIT_TABLE,
        // accessType: PERMISSION.VOUCHER.LIST,
    },
    {
        id: "transfer-voucher",
        label: "transferVoucher",
        iconName: "move_up",
        subMenu: [
            {
                id: "transferVouchers",
                label: "transferVoucherTable",
                accessType: PERMISSION.TRANSFER_VOUCHER.LIST,
                path: Path.TRANSFER_VOUCHER_TABLE,
            },
            {
                id: "transferVoucherCreate",
                label: "transferVoucherCreate",
                path: Path.TRANSFER_VOUCHER_CREATE,
                accessType: PERMISSION.TRANSFER_VOUCHER.CREATE,
                breadcrumb: [
                    {
                        title: Messages.transferVoucherTable,
                        url: Path.TRANSFER_VOUCHER_TABLE,
                    },
                    {
                        title: Messages.transferVoucherCreate,
                    },
                ],
            },
            {
                id: "transferVoucherDetail",
                label: "transferVoucherDetail",
                path: Path.TRANSFER_VOUCHER_DETAIL,
                accessType: PERMISSION.TRANSFER_VOUCHER.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.transferVoucherTable,
                        url: Path.TRANSFER_VOUCHER_TABLE,
                    },
                    {
                        title: Messages.transferVoucherDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "equipment",
        label: "equipment",
        iconName: "medication_liquid",
        subMenu: [
            {
                id: "equipmentCategory",
                label: "equipmentCategory",
                accessType: PERMISSION.CATEGORY_EQUIPMENT.LIST,
                path: Path.EQUIPMENT_CATEGORY_TABLE,
            },
            {
                id: "equipmentCategoryCreate",
                label: "equipmentCategoryCreate",
                path: Path.EQUIPMENT_CATEGORY_CREATE,
                accessType: PERMISSION.CATEGORY_EQUIPMENT.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentCategory,
                        url: Path.EQUIPMENT_CATEGORY_TABLE,
                    },
                    {
                        title: Messages.newEquipmentCategory,
                    },
                ],
            },
            {
                id: "equipmentCategoryDetail",
                label: "equipmentCategoryDetail",
                path: Path.EQUIPMENT_CATEGORY_DETAIL,
                accessType: PERMISSION.CATEGORY_EQUIPMENT.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentCategory,
                        url: Path.EQUIPMENT_CATEGORY_TABLE,
                    },
                    {
                        title: Messages.equipmentCategoryDetail,
                    },
                ],
            },
            {
                id: "equipmentList",
                label: "equipmentTable",
                accessType: PERMISSION.EQUIPMENT.LIST,
                path: Path.EQUIPMENT_TABLE,
            },
            {
                id: "equipmentCreate",
                label: "equipmentCreate",
                path: Path.EQUIPMENT_CREATE,
                accessType: PERMISSION.EQUIPMENT.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentTable,
                        url: Path.EQUIPMENT_TABLE,
                    },
                    {
                        title: Messages.newEquipment,
                    },
                ],
            },
            {
                id: "equipmentDetail",
                label: "equipmentDetail",
                path: Path.EQUIPMENT_DETAIL,
                accessType: PERMISSION.EQUIPMENT.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentTable,
                        url: Path.EQUIPMENT_TABLE,
                    },
                    {
                        title: Messages.equipmentDetail,
                    },
                ],
            },
        ],
    },

    {
        id: "equipmentInventory",
        label: "equipmentInventory",
        iconName: "warehouse",
        subMenu: [
            {
                id: "equipmentInventoryList",
                label: "equipmentInventoryList",
                path: Path.EQUIPMENT_INVENTORY_TABLE,
                selected: true,
                accessType: PERMISSION.EQUIPMENT_INVENTORY.LIST,
            },
            // stock-adjustment

            {
                id: "equipmentStockAdjustmentRequest",
                label: "equipmentStockAdjustmentRequest",
                path: Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_TABLE,
                accessType: PERMISSION.EQUIPMENT_STOCK_ADJUSTMENT.LIST,
            },
            {
                id: "equipmentStockAdjustmentCreate",
                path: Path.INVENTORY_STOCK_ADJUSTMENT_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentStockAdjustmentRequest,
                        url: Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_TABLE,
                    },
                    {
                        title: Messages.newEquipmentStockAdjustment,
                    },
                ],
            },
            {
                id: "equipmentStockAdjustmentDetail",
                path: Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentStockAdjustmentRequest,
                        url: Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_TABLE,
                    },
                    {
                        title: Messages.equipmentStockAdjustmentDetail,
                    },
                ],
            },

            // stock-request
            {
                id: "equipmentStockRequestTable",
                label: "equipmentStockRequestTable",
                path: Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_TABLE,
                accessType: PERMISSION.EQUIPMENT_REQUEST.LIST,
            },
            {
                id: "equipmentStockRequestCreate",
                path: Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentStockRequestTable,
                        url: Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_TABLE,
                    },
                    {
                        title: Messages.equipmentStockRequestCreate,
                    },
                ],
            },
            {
                id: "equipmentStockRequestDetail",
                path: Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentStockRequestTable,
                        url: Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_TABLE,
                    },
                    {
                        title: Messages.equipmentStockRequestDetail,
                    },
                ],
            },

            // stock-transfer
            {
                id: "equipmentStockTransferTable",
                label: "equipmentStockTransferTable",
                path: Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_TABLE,
                accessType: PERMISSION.EQUIPMENT_TRANSFER.LIST,
            },
            {
                id: "equipmentStockTransferCreate",
                path: Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentStockTransferTable,
                        url: Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_TABLE,
                    },
                    {
                        title: Messages.equipmentStockTransferCreate,
                    },
                ],
            },
            {
                id: "equipmentStockTransferDetail",
                path: Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.equipmentStockTransferDetail,
                        url: Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_TABLE,
                    },
                    {
                        title: Messages.equipmentStockTransferDetail,
                    },
                ],
            },
        ],
    },

    {
        id: "deliveryDriver",
        label: "deliveryDriver",
        iconName: "local_shipping",
        path: Path.DELIVERY_DRIVER_TABLE,
        subMenu: [
            {
                id: "deliveryDriverDetail",
                label: "deliveryDriverDetail",
                path: Path.DELIVERY_DRIVER_DETAIL,
                accessType: PERMISSION.DRIVER_DELIVERY.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.deliveryDriver,
                        url: Path.DELIVERY_DRIVER_TABLE,
                    },
                    {
                        title: Messages.deliveryDriverDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "agency",
        label: "agency",
        iconName: "real_estate_agent",
        subMenu: [
            {
                id: "agencies",
                label: "agencyManagement",
                accessType: PERMISSION.AGENCY.LIST,
                path: Path.AGENCY_TABLE,
            },
            {
                id: "agencyCreate",
                label: "newAgency",
                path: Path.AGENCY_CREATE,
                accessType: PERMISSION.AGENCY.CREATE,
                breadcrumb: [
                    {
                        title: Messages.agencyManagement,
                        url: Path.AGENCY_TABLE,
                    },
                    {
                        title: Messages.newAgency,
                    },
                ],
            },
            {
                id: "agencyDetail",
                label: "agencyDetail",
                path: Path.AGENCY_DETAIL,
                accessType: PERMISSION.AGENCY.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.agencyManagement,
                        url: Path.AGENCY_TABLE,
                    },
                    {
                        title: Messages.agencyDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "user",
        label: "users",
        iconName: "person",
        subMenu: [
            {
                id: "users",
                label: "userTable",
                accessType: PERMISSION.ADMIN.USER.LIST,
                path: Path.USER_TABLE,
            },
            {
                id: "userCreate",
                label: "userCreate",
                path: Path.USER_CREATE,
                accessType: PERMISSION.ADMIN.USER.CREATE,
                breadcrumb: [
                    {
                        title: Messages.allUsers,
                        url: Path.USER_TABLE,
                    },
                    {
                        title: Messages.userCreate,
                    },
                ],
            },
            {
                id: "userDetail",
                label: "userDetail",
                path: Path.USER_DETAIL,
                accessType: PERMISSION.ADMIN.USER.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.allUsers,
                        url: Path.USER_TABLE,
                    },
                    {
                        title: Messages.userDetail,
                    },
                ],
            },
            {
                id: "roles",
                label: "roles",
                path: Path.USER_ROLES,
                accessType: PERMISSION.ADMIN.ROLE.LIST,
                breadcrumb: [
                    {
                        title: Messages.allRoles,
                        url: Path.USER_ROLES,
                    },
                    {
                        title: Messages.userRoles,
                    },
                ],
            },
        ],
    },
    {
        id: "branch",
        label: "branch",
        iconName: "store",
        subMenu: [
            {
                id: "branches",
                label: "branchTable",
                accessType: PERMISSION.BRANCH.LIST,
                path: Path.BRANCH_TABLE,
            },
            {
                id: "branchCreate",
                label: "branchCreate",
                path: Path.BRANCH_CREATE,
                accessType: PERMISSION.BRANCH.CREATE,
                breadcrumb: [
                    {
                        title: Messages.branchTable,
                        url: Path.BRANCH_TABLE,
                    },
                    {
                        title: Messages.branchCreate,
                    },
                ],
            },
            {
                id: "branchDetail",
                label: "branchDetail",
                path: Path.BRANCH_DETAIL,
                accessType: PERMISSION.BRANCH.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.branchTable,
                        url: Path.BRANCH_TABLE,
                    },
                    {
                        title: Messages.branchDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "temporarySchedule",
        label: "temporarySchedule",
        iconName: "schedule",
        subMenu: [
            {
                id: "temporarySchedules",
                label: "temporaryScheduleTable",
                accessType: PERMISSION.TEMPORARY_SCHEDULE.LIST,
                path: Path.TEMPORARY_SCHEDULE,
            },
            {
                id: "temporaryScheduleCreate",
                label: "temporaryScheduleCreate",
                path: Path.TEMPORARY_SCHEDULE_CREATE,
                accessType: PERMISSION.TEMPORARY_SCHEDULE.CREATE,
                breadcrumb: [
                    {
                        title: Messages.temporaryScheduleTable,
                        url: Path.TEMPORARY_SCHEDULE,
                    },
                    {
                        title: Messages.temporaryScheduleCreate,
                    },
                ],
            },
            {
                id: "temporaryScheduleDetail",
                label: "temporaryScheduleDetail",
                path: Path.TEMPORARY_SCHEDULE_DETAIL,
                accessType: PERMISSION.TEMPORARY_SCHEDULE.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.temporaryScheduleTable,
                        url: Path.TEMPORARY_SCHEDULE,
                    },
                    {
                        title: Messages.temporaryScheduleDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "material",
        label: "material",
        iconName: "cabin",
        subMenu: [
            {
                id: "materialCategory",
                label: "materialCategory",
                accessType: PERMISSION.CATEGORY_MATERIAL.LIST,
                path: Path.MATERIAL_CATEGORY,
            },
            {
                id: "materialCategoryCreate",
                label: "materialCategoryCreate",
                path: Path.MATERIAL_CATEGORY_CREATE,
                accessType: PERMISSION.CATEGORY_MATERIAL.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialCategory,
                        url: Path.MATERIAL_CATEGORY,
                    },
                    {
                        title: Messages.materialCategoryCreate,
                    },
                ],
            },
            {
                id: "materialCategoryDetail",
                label: "materialCategoryDetail",
                path: Path.MATERIAL_CATEGORY_DETAIL,
                accessType: PERMISSION.CATEGORY_MATERIAL.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialCategory,
                        url: Path.MATERIAL_CATEGORY,
                    },
                    {
                        title: Messages.materialCategoryDetail,
                    },
                ],
            },
            {
                id: "material",
                label: "material",
                accessType: PERMISSION.MATERIAL.LIST,
                path: Path.MATERIAL,
            },
            {
                id: "materialCreate",
                label: "materialCreate",
                path: Path.MATERIAL_CREATE,
                accessType: PERMISSION.MATERIAL.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.material,
                        url: Path.MATERIAL,
                    },
                    {
                        title: Messages.materialCreate,
                    },
                ],
            },
            {
                id: "materialDetail",
                label: "materialDetail",
                path: Path.MATERIAL_DETAIL,
                accessType: PERMISSION.MATERIAL.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.material,
                        url: Path.MATERIAL,
                    },
                    {
                        title: Messages.materialDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "material-inventory",
        label: "materialInventory",
        iconName: "foundation",
        subMenu: [
            {
                id: "materialInventoryTable",
                label: "materialInventoryTable",
                path: Path.MATERIAL_INVENTORY_TABLE,
            },

            {
                id: "materialAdjustmentRequest",
                label: "materialAdjustmentRequest",
                path: Path.MATERIAL_ADJUST_REQUEST_TABLE,
            },
            {
                id: "materialAdjustmentRequestDetail",
                label: "materialAdjustmentRequestDetail",
                path: Path.MATERIAL_ADJUST_REQUEST_DETAIL,
                accessType: PERMISSION.MATERIAL_STOCK_ADJUSTMENT.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialAdjustmentRequest,
                        url: Path.MATERIAL_ADJUST_REQUEST_TABLE,
                    },
                    {
                        title: Messages.materialAdjustmentRequestDetail,
                    },
                ],
            },
            {
                id: "materialAdjustmentRequestCreate",
                label: "materialAdjustmentRequestCreate",
                path: Path.MATERIAL_ADJUST_REQUEST_CREATE,
                accessType: PERMISSION.MATERIAL_STOCK_ADJUSTMENT.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialAdjustmentRequest,
                        url: Path.MATERIAL_ADJUST_REQUEST_TABLE,
                    },
                    {
                        title: Messages.materialAdjustmentRequestCreate,
                    },
                ],
            },
            {
                id: "materialRequest",
                label: "materialRequest",
                accessType: PERMISSION.MATERIAL_PURCHASE_REQUEST.LIST,
                path: Path.MATERIAL_REQUEST,
            },
            {
                id: "materialRequestCreate",
                label: "materialRequestCreate",
                path: Path.MATERIAL_REQUEST_CREATE,
                accessType: PERMISSION.MATERIAL_PURCHASE_REQUEST.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialRequest,
                        url: Path.MATERIAL_REQUEST,
                    },
                    {
                        title: Messages.newMaterialRequest,
                    },
                ],
            },
            {
                id: "materialRequestDetail",
                label: "materialRequestDetail",
                path: Path.MATERIAL_REQUEST_DETAIL,
                accessType: PERMISSION.MATERIAL_PURCHASE_REQUEST.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialRequest,
                        url: Path.MATERIAL_REQUEST,
                    },
                    {
                        title: Messages.materialRequestDetail,
                    },
                ],
            },
            {
                id: "materialOrder",
                label: "materialOrder",
                path: Path.MATERIAL_ORDER,
                accessType: PERMISSION.MATERIAL_PURCHASE.LIST,
            },
            {
                id: "materialOrderCreate",
                label: "newMaterialOrder",
                path: Path.MATERIAL_ORDER_CREATE,
                accessType: PERMISSION.MATERIAL_PURCHASE.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialOrder,
                        url: Path.MATERIAL_ORDER,
                    },
                    {
                        title: Messages.newMaterialOrder,
                    },
                ],
            },
            {
                id: "materialOrderCreate",
                label: "newMaterialOrder",
                path: Path.MATERIAL_ORDER_CREATE,
                accessType: PERMISSION.MATERIAL_PURCHASE.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialOrder,
                        url: Path.MATERIAL_ORDER,
                    },
                    {
                        title: Messages.newMaterialOrder,
                    },
                ],
            },
            {
                id: "materialOrderDetail",
                label: "materialOrderDetail",
                path: Path.MATERIAL_ORDER_DETAIL,
                accessType: PERMISSION.MATERIAL_PURCHASE.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.materialOrder,
                        url: Path.MATERIAL_ORDER,
                    },
                    {
                        title: Messages.materialOrderDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "vendor",
        label: "vendorManagement",
        iconName: "real_estate_agent",
        subMenu: [
            {
                id: "vendorTable",
                label: "vendorTable",
                path: Path.VENDOR,
            },
            {
                id: "vendorDetail",
                label: "vendorDetail",
                path: Path.VENDOR_DETAIL,
                accessType: PERMISSION.VENDOR.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.vendor,
                        url: Path.VENDOR,
                    },
                    {
                        title: Messages.vendorDetail,
                    },
                ],
            },
            {
                id: "vendorCreate",
                label: "vendorCreate",
                path: Path.VENDOR_CREATE,
                accessType: PERMISSION.VENDOR.CREATE,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.vendor,
                        url: Path.VENDOR,
                    },
                    {
                        title: Messages.vendorCreate,
                    },
                ],
            },
        ],
    },
    {
        id: "calendar",
        public: true,
        label: "calendar",
        iconName: "calendar_month",
        path: Path.CALENDAR,
        title: "calendar",
    },
    {
        id: "paymentMethod",
        label: "paymentMethod",
        iconName: "credit_card",
        path: Path.PAYMENT_METHOD_TABLE,
        subMenu: [
            {
                id: "paymentMethodDetail",
                label: "paymentMethodDetail",
                path: Path.PAYMENT_METHOD_DETAIL,
                accessType: PERMISSION.PAYMENT_CONFIGURATION.DETAIL,
                show: false,
                breadcrumb: [
                    {
                        title: Messages.paymentMethod,
                        url: Path.PAYMENT_METHOD_TABLE,
                    },
                    {
                        title: Messages.paymentMethodDetail,
                    },
                ],
            },
        ],
    },
    {
        id: "media",
        public: true,
        label: "mediaManager",
        iconName: "perm_media",
        path: Path.MEDIA,
        title: "mediaManager",
    },
    {
        id: "importTools",
        label: "importTools",
        iconName: "download",
        path: Path.IMPORT_CUSTOMER,
        // subMenu: [
        //     {
        //         id: "importCustomer",
        //         label: "customers",
        //         path: Path.IMPORT_CUSTOMER,
        //         show: true,
        //         breadcrumb: [
        //             {
        //                 title: Messages.importCustomer,
        //             },
        //         ],
        //     },
        // ],
    },
];

export const LANGUAGE_DEFAULT = "en";

export const LANGUAGES = [
    {
        label: "Thai",
        id: "th",
        locale: "th",
        code: "TH",
        icon: "/images/languages/language-3.png",
        default: true,
    },
    {
        label: "English",
        id: "en",
        locale: "en",
        code: "EN",
        icon: "/images/languages/language-1.png",
    },
];

export const SITE_SETTINGS = [{ id: "logout", label: "Logout" }];
export const TABLE_ACTIONS = [{ id: "detail", label: "detail" }];
export const YES_NO_OPTIONS = [
    { id: true, label: "yes" },
    { id: false, label: "no" },
];

export const APP_WIDE_SCREEN_WIDTH = 576;
