import React from "react";

interface IStockAdjustmentDetailState {
    stockAdjustment: any;
    setStockAdjustment: any;
}

const stockAdjustmentDetailState: IStockAdjustmentDetailState = {
    stockAdjustment: {} as any,
    setStockAdjustment: () => {},
};

export const StockAdjustmentDetailContext = React.createContext(
    stockAdjustmentDetailState
);
