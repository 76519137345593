import classNames from "classnames";
import { Button, Notifications } from "d-react-components";
import { Formik } from "formik";
import { filter, find, forEach, isUndefined, omit } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
    ShippingType,
    useCreateShippingForAdminMutation,
    useListAllShippingForAdminLazyQuery,
    useUpdateShippingForAdminMutation,
} from "../../../api/hooks";
import {
    QuotationCreateContext,
    QuotationDetailContext,
} from "../../../context/quotation";
import { IAddress, mapAddressToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import { CreateEditAddressDrawer } from "../../customer/detail/CustomerDetailAddress";
import AddressItem from "../../order/share/AddressItem";
import ShippingDrawer from "../share/ShippingDrawer";

const QuotationAddress = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditShipping, setShowEditShipping] = useState<any>(undefined);
    const { quotation } = useContext(QuotationDetailContext);
    const [shippingList, setShippingList] = useState<IAddress[]>([]);
    const [newShippingList, setNewShippingList] = useState<IAddress[]>([]);
    const [getShippingList] = useListAllShippingForAdminLazyQuery();
    const [createShipping] = useCreateShippingForAdminMutation();
    const [updateShipping] = useUpdateShippingForAdminMutation();

    const loadShippingList = () => {
        getShippingList({
            variables: {
                refId: quotation?.id as string,
            },
            fetchPolicy: "no-cache",
        }).then((resp) => {
            const shippingAddList = resp?.data?.data?.data ?? ([] as any);
            setShippingList(shippingAddList);
            setNewShippingList(shippingAddList);
        });
    };

    const onChangeOrderShipping = () => {
        setShowAddModal(false);
        const newShipping = filter(
            newShippingList,
            (newShipping) =>
                !find(
                    shippingList,
                    (shipping) => newShipping.id === shipping.id
                )
        );
        if (newShipping.length) {
            let successfulCount = 0;
            forEach(newShipping, async (shippingItem, index) => {
                await createShipping({
                    variables: {
                        refId: quotation?.id as string,
                        shippingType: ShippingType.Quotation as any,
                        payload: omit(
                            mapAddressToSer(shippingItem),
                            "isDefault"
                        ),
                    },
                })
                    .then(() => {
                        successfulCount += 1;
                        if (successfulCount === newShipping.length) {
                            loadShippingList();
                        }
                    })
                    .catch((err) => {
                        Notifications.showError(err);
                    });
            });
        }
    };

    const onUpdatedShipping = (shippingItem: any) => {
        updateShipping({
            variables: {
                refId: quotation?.id as string,
                id: shippingItem?.id,
                shippingType: ShippingType.Quotation as any,
                payload: omit(mapAddressToSer(shippingItem), "isDefault"),
            },
        })
            .then(() => {
                setShowEditShipping(undefined);
                loadShippingList();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    useEffect(() => {
        if (quotation && !shippingList.length) {
            loadShippingList();
        }
    }, []);

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2 items-center border-b pb-3">
                <h5 className="text-[16px] flex-1">
                    {Messages.addressInformation}
                </h5>
                {!quotation?.hasOrder && (
                    <Button size="medium" onClick={() => setShowAddModal(true)}>
                        {Messages.new}
                    </Button>
                )}
            </div>
            <div>
                {shippingList &&
                    shippingList.map((shipping, index) => (
                        <div
                            className={classNames("pt-2", {
                                "pb-2 border-b":
                                    index + 1 !== shippingList.length,
                            })}
                            key={index}
                        >
                            <div className="flex py-2">
                                <div className="flex-1">
                                    <AddressItem address={shipping} />
                                </div>
                                <div className="mt-2">
                                    <span
                                        onClick={() =>
                                            setShowEditShipping(shipping)
                                        }
                                        className="text-primary cursor-pointer mr-3"
                                    >
                                        {Messages.edit}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <QuotationCreateContext.Provider
                value={
                    {
                        shipping: newShippingList,
                        setShipping: setNewShippingList,
                    } as any
                }
            >
                <Formik
                    initialValues={{
                        customer: quotation?.customer
                            ? [quotation?.customer]
                            : [],
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values) => {}}
                >
                    {({ handleSubmit }) => (
                        <>
                            {showAddModal && (
                                <ShippingDrawer
                                    open={showAddModal}
                                    onClose={() => setShowAddModal(false)}
                                    onSave={onChangeOrderShipping}
                                />
                            )}
                            {!isUndefined(showEditShipping) && (
                                <CreateEditAddressDrawer
                                    open={!isUndefined(showEditShipping)}
                                    onSaveAddress={onUpdatedShipping}
                                    onClose={() =>
                                        setShowEditShipping(undefined)
                                    }
                                    addressDefault={showEditShipping}
                                />
                            )}
                        </>
                    )}
                </Formik>
            </QuotationCreateContext.Provider>
        </div>
    );
};

export default QuotationAddress;
