import {
    Button,
    DateInput,
    Header,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, isEmpty } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import AppointmentAPI from "../../../api/queries/appointment";
import BookingAPI from "../../../api/queries/booking";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import PermissibleRender from "../../../common/PermissibleComponent";
import { CUSTOMER_SOURCES } from "../../../constant/customer";
import { PERMISSION } from "../../../constant/user";
import { BookingCreateContext } from "../../../context/booking";
import { BookingSchema } from "../../../formschema/booking";
import { IAppointment } from "../../../interfaces/appointment";
import { mapBookingToServer } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import BranchSolidSelect from "../../branch/share/BranchSolidSelect";
import Path from "../../Path";
import BookingDoctorSelect from "../share/BookingDoctorSelect";
import BookingCreateCustomer from "./BookingCreateCustomer";
import BookingCreateEstTimeVisit from "./BookingCreateEstTimeVisit";
import BookingCreateService from "./BookingCreateService";

const BookingCreate = () => {
    const navigate = useNavigate();
    const { appointmentId } = useParams();

    const bookingForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BookingSchema,
        onSubmit: (values: any) => {
            const input = mapBookingToServer(values);
            onCreateBooking(input);
        },
    });

    const { values: formValues, errors: formErrors, setValues } = bookingForm;

    useEffect(() => {
        forEach(Object.keys(bookingForm.errors), (key) =>
            Notifications.showError(`${bookingForm.errors[key]}`)
        );
    }, [bookingForm.errors]);

    const onCreateBooking = (payload: any) => {
        Progress.show(
            {
                method: BookingAPI.create,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.BOOKING_DETAIL, {
                        bookingId: res?.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    useEffect(() => {
        if (appointmentId) {
            Progress.show(
                {
                    method: AppointmentAPI.detail,
                    params: [appointmentId],
                },
                (resp) => {
                    const appointment: IAppointment = resp as IAppointment;
                    if (!isEmpty(appointment)) {
                        setValues({
                            branch: appointment.branch,
                            date: moment(appointment.scheduleDate),
                            products: appointment.services,
                            ...(appointment.customer
                                ? { customer: appointment.customer }
                                : {}),
                            ...(!appointment.customer && appointment.contact
                                ? {
                                      isGuestVisit: true,
                                      guestInfo: appointment.contact,
                                  }
                                : {}),
                        });
                    }
                }
            );
        }
    }, []);

    const classNameInput = "mt-3";
    return (
        <BookingCreateContext.Provider value={{ bookingForm }}>
            <div className="flex-column-container bg-white w-100">
                <Header
                    showCancel={false}
                    className="sticky top-0 index-100"
                    title={Messages.newBooking}
                    customRight={() => (
                        <>
                            <Button
                                size="small"
                                className="ml-2"
                                variant="outline"
                                onClick={() => navigate(-1)}
                            >
                                {Messages.back}
                            </Button>
                            <PermissibleRender
                                permission={PERMISSION.BOOKING.CREATE}
                            >
                                <Button
                                    size="small"
                                    className="ml-2"
                                    onClick={() => bookingForm.handleSubmit()}
                                >
                                    {Messages.save}
                                </Button>
                            </PermissibleRender>
                        </>
                    )}
                />
                <div className="p-4">
                    <BranchSolidSelect
                        value={formValues?.branch}
                        onChange={(value) =>
                            bookingForm?.setFieldValue("branch", value)
                        }
                        error={bookingForm?.errors?.branch}
                        className={classNameInput}
                    />
                    <InputSelectForm
                        dataSource={CUSTOMER_SOURCES}
                        keyData="customerSource"
                        form={bookingForm}
                        className={classNameInput}
                    />
                    <DateInput
                        label={Messages.confirmDateOfVisit}
                        onChange={(value) => {
                            bookingForm.setFieldValue("date", value);
                        }}
                        value={
                            formValues?.date
                                ? moment(formValues?.date)
                                : (null as any)
                        }
                        format="DD/MM/YYYY"
                        error={formErrors?.date as any}
                        className={classNameInput}
                    />
                    <BookingCreateService />
                    <BookingDoctorSelect
                        className="mt-3"
                        branch={formValues?.branch}
                        date={formValues?.date}
                        form={bookingForm}
                        userSelectProps={{
                            disabled: !formValues?.date || !formValues?.branch,
                        }}
                    />

                    <BookingCreateEstTimeVisit />

                    <ViewTextError error={bookingForm?.errors?.doctor} />
                    <BookingCreateCustomer />
                    <InputTextForm
                        form={bookingForm}
                        keyData="remark"
                        multiple
                        className={classNameInput}
                    />
                </div>
            </div>
        </BookingCreateContext.Provider>
    );
};

export default BookingCreate;
