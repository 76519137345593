import ClassNames from "classnames";
import moment from "moment";
import React, { forwardRef } from "react";
import {
    Calendar,
    CalendarProps,
    DateHeaderProps,
    momentLocalizer,
} from "react-big-calendar";
import BigCalendarToolbar from "./content/BigCalendarToolbar";

export interface IBigCalendarProps extends Partial<CalendarProps> {
    dateRange?: any;
    customDateHeader?: React.ComponentType<DateHeaderProps>;
    classNameDate?: string;
    dateCellProps?: React.HTMLAttributes<HTMLDivElement>;
    getDateProps?: CalendarProps["dayPropGetter"];
    overlayDateCell?: boolean;
    classNameContainer?: string;
}

export interface IBigCalendarMethods {}

const localizer = momentLocalizer(moment);

const BigCalendar: React.ForwardRefRenderFunction<
    IBigCalendarMethods,
    IBigCalendarProps
> = (
    {
        dateRange,
        components = {},
        customDateHeader,
        classNameDate,
        getDateProps,
        overlayDateCell,
        classNameContainer,
        ...rest
    },
    ref
) => {
    const myHeader = (props: any, event: any) => {
        return (
            <div className="text-small w-100 text-start px-3 py-2">
                <div>{props?.label}</div>
            </div>
        );
    };
    const dayClass = ClassNames(
        `my-big-calendar__date-cell border-left border-gray w-100 ${classNameDate}`,
        { "my-big-calendar__date-cell-overlay": overlayDateCell }
    );

    return (
        <div className={`my-big-calendar__container ${classNameContainer}`}>
            <Calendar
                localizer={localizer}
                style={{ height: 950 }}
                views={{
                    month: true,
                }}
                components={{
                    toolbar: (props) => (
                        <BigCalendarToolbar {...props} dateRange={dateRange} />
                    ),
                    month: {
                        header: myHeader,
                        dateHeader: customDateHeader,
                    },
                    ...components,
                }}
                dayPropGetter={(props) => {
                    let dayProps: any = {};
                    if (getDateProps) {
                        dayProps = getDateProps(props);
                    }
                    return {
                        className: dayClass,
                        ...dayProps,
                    };
                }}
                showAllEvents={false}
                doShowMoreDrillDown
                {...rest}
            />
        </div>
    );
};

export default forwardRef(BigCalendar);
