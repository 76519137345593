import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_PRODUCT_BASIC } from "./product";

export const F_STOCK_ADJUSTMENT_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_STOCK_ADJUSTMENT_CONFIRMATION on AdminProductStockAdjustmentConfirmation {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        at
        remark
    }
`;

export const F_STOCK_ADJUSTMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_PRODUCT_BASIC}
    ${F_STOCK_ADJUSTMENT_CONFIRMATION}
    fragment F_STOCK_ADJUSTMENT on AdminProductStockAdjustment {
        id
        adjustmentNo
        status
        remark
        attachments
        createdAt
        updatedAt
        branch {
            id
            name
            code
        }
        products {
            product {
                ...F_PRODUCT_BASIC
            }
            adjustment
        }
        confirmation {
            ...F_STOCK_ADJUSTMENT_CONFIRMATION
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_STOCK_ADJUSTMENTS = gql`
    ${F_STOCK_ADJUSTMENT}
    ${F_PAGINATE}
    fragment F_STOCK_ADJUSTMENTS on ListAdminProductStockAdjustmentResponse {
        data {
            ...F_STOCK_ADJUSTMENT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
