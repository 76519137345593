import { useContext, useEffect } from "react";
import {
    useListAllMaterialPurchasePaymentLazyQuery,
    useUpdatePaymentForAdminMutation,
} from "../../../api/hooks";
import { MATERIAL_PAYMENTS_METHODS } from "../../../constant/material";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import { OrderRefType, PaymentContext } from "../../../context/payment";
import { IPayment } from "../../../interfaces/payment";
import Messages from "../../../languages/Messages";
import PaymentInfoItem from "../payment/PaymentInfoItem";

const OrderPayment = () => {
    const { order, loadOrderDetail } = useContext(
        MaterialPurchaseDetailContext
    );
    const [getPaymentList, { data, refetch }] =
        useListAllMaterialPurchasePaymentLazyQuery();
    const [splitPayment] = useUpdatePaymentForAdminMutation();
    const paymentMethodsList = MATERIAL_PAYMENTS_METHODS;

    const onChangePaymentInfo = () => {
        refetch();
        loadOrderDetail();
    };

    useEffect(() => {
        if (order?.id) {
            getPaymentList({
                variables: {
                    refId: order?.id,
                },
                fetchPolicy: "no-cache",
            });
        }
    }, [order]);

    const paymentList = data?.data?.data ?? [];

    return (
        <PaymentContext.Provider
            value={
                {
                    paymentMethodsList: paymentMethodsList ?? [],
                    payment2C2POptionsList: [],
                    ref: order,
                    reloadRef: loadOrderDetail,
                    refType: OrderRefType.ORDER,
                } as any
            }
        >
            <div className="border p-3 mb-3 mt-3">
                <div className="flex items-center">
                    <h5 className="font-semibold mb-2 flex-1">
                        {Messages.payment}
                    </h5>
                </div>
                <div>
                    <div className="mt-2 w-full flex flex-col whitespace-nowrap overflow-x-auto py-2">
                        {paymentMethodsList.length &&
                            paymentList?.map((payment: any, index: number) => (
                                <PaymentInfoItem
                                    payment={payment as IPayment}
                                    key={index}
                                    index={index}
                                    onChangePaymentInfo={onChangePaymentInfo}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </PaymentContext.Provider>
    );
};

export default OrderPayment;
