import React, { Dispatch } from "react";
import { IJobCostPreset, IServiceConfiguration } from "../interfaces/service";
import { JobCostTemplate } from "../api/hooks";

interface IServiceConfigState {
    configuration: IServiceConfiguration;
    setConfiguration: any;
    jobCostPresets?: IJobCostPreset[];
    openJobCostPresetDrawer?: {
        open: boolean;
        template?: JobCostTemplate;
    };
    setOpenJobCostPresetDrawer: Dispatch<{
        open: boolean;
        template?: JobCostTemplate;
    }>;
}

const serviceConfigState: IServiceConfigState = {
    configuration: {} as any,
    setConfiguration: () => {},
    setOpenJobCostPresetDrawer: () => {},
};

export const ServiceConfigContext = React.createContext(serviceConfigState);
