import { Progress } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import DeliveryDriverAPI from "../../../api/queries/delivery-driver";
import ActivityView from "../../../common/activity/ActivityView";
import { DeliveryDriverDetailContext } from "../../../context/delivery-driver";
import DeliveryDriverConfirmation from "./DeliveryDriverConfirmation";
import DeliveryDriverHeader from "./DeliveryDriverHeader";
import DeliveryDriverItems from "./DeliveryDriverItems";
import DeliveryDriverSideView from "./DeliveryDriverSideView";

export interface IDeliveryDriverDetailProps {
    [key: string]: any;
}

const DeliveryDriverDetail: React.FC<IDeliveryDriverDetailProps> = () => {
    const { deliveryId } = useParams<any>();
    const [deliveryDetail, setDeliveryDetail] = useState<any>();

    useEffect(() => {
        loadDeliveryDetail();
    }, [deliveryId]);

    const loadDeliveryDetail = () => {
        return Progress.show(
            {
                method: DeliveryDriverAPI.detail,
                params: [deliveryId],
            },
            (res) => {
                setDeliveryDetail(res);
            }
        );
    };

    return (
        <DeliveryDriverDetailContext.Provider
            value={{ delivery: deliveryDetail, loadDeliveryDetail }}
        >
            <DeliveryDriverHeader />
            <div className="px-4">
                <div className="grid grid-cols-4 gap-4 mt-3">
                    <div className="col-span-3">
                        <DeliveryDriverItems />
                        <DeliveryDriverConfirmation />
                        <ActivityView
                            className="mt-3 border p-3"
                            activityRef={RefActivity?.Delivery}
                            activityRefId={deliveryId ?? ""}
                        />
                    </div>
                    <DeliveryDriverSideView />
                </div>
            </div>
        </DeliveryDriverDetailContext.Provider>
    );
};

export default DeliveryDriverDetail;
