import {
    InputText,
    Notifications,
    Select,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, isNil, pickBy } from "lodash";
import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import * as Yup from "yup";
import {
    PaymentMethod,
    useListAllBankForAdminLazyQuery,
    useUploadMaterialProofMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import UploadButton from "../../../common/upload/UploadButton";
import { PaymentContext } from "../../../context/payment";
import Messages from "../../../languages/Messages";

const UploadPaymentInfoRef = (
    {
        banks = [],
        paymentId,
        onSuccessUploadInfo,
        isView = false,
        defaultInfo = {},
        paymentMethod,
        merchantList,
    }: any,
    ref: any
) => {
    const { payment2C2POptionsList, ref: order } = useContext(PaymentContext);
    const [uploadProof] = useUploadMaterialProofMutation();

    const UploadPaymentSchema: any = Yup.object().shape({
        attachments: Yup.array()
            .required(Messages.fieldRequired)
            .min(1, Messages.fieldRequired),
    });

    const UploadBankSchema = Yup.lazy(() => {
        return UploadPaymentSchema.concat(
            Yup.object().shape({
                // bankTransfer: Yup.string().required("Require bank transfer"),
            })
        );
    });

    const Upload2C2PSchema = Yup.lazy(() => {
        return UploadPaymentSchema.concat(
            Yup.object().shape({
                // merchant: Yup.string().required(Messages.fieldRequired),
                transactionId: Yup.string().required(Messages.fieldRequired),
                paymentOption: Yup.mixed().required(Messages.fieldRequired),
            })
        );
    });

    const [getListBank] = useListAllBankForAdminLazyQuery();
    const [bankOptions, setBankOptions] = useState<any[]>([]);
    const [mtBankList, setMTBankList] = useState([]);

    useEffect(() => {
        // if (paymentMethod !== PAYMENT_MODERN_TRADE) {
        //     return;
        // }
        // modernTradeBankList().then((res) => {
        //     const bankList = res?.data?.data?.brand_name ?? [];
        //     setMTBankList(bankList);
        // });
        getListBank({
            variables: {
                paymentMethod,
                branch: order?.store?.id as string,
            },
        }).then((resp) => {
            setBankOptions(resp?.data?.data?.data ?? []);
        });
    }, [paymentMethod]);

    const isRequireBankField = useMemo(
        () => bankOptions.length > 0,
        [bankOptions]
    );

    const validate = (values: any) => {
        const errors: any = {};

        if (
            paymentMethod === PaymentMethod.BankTransfer &&
            isRequireBankField &&
            !values.bankTransfer
        ) {
            Notifications.showError(Messages.requireBankTransfer);
            errors.bankTransfer = Messages.requireBankTransfer;
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            fullNameTransfer: "",
            bankTransfer: undefined,
            dateTransfer: undefined,
            comment: "",
            ...defaultInfo,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema:
            paymentMethod === PaymentMethod.Payment_2C2P
                ? Upload2C2PSchema
                : UploadBankSchema,
        validate,

        onSubmit: async (values) => {
            return onUploadBankInfo();
        },
    });

    useImperativeHandle(ref, () => ({
        formik,
    }));

    const onUploadBankInfo = async () => {
        const { comment, attachments } = formik.values;

        uploadProof({
            variables: {
                refId: order?.id as string,
                id: paymentId,
                paymentMethod,
                payload: pickBy(
                    {
                        remark: comment,
                        attachments: attachments?.map(
                            (item: any) => item.fileUrl
                        ),
                    },
                    (item) => !isNil(item)
                ) as any,
            },
        })
            .then(() => {
                onSuccessUploadInfo && onSuccessUploadInfo();
            })
            .catch((err: any) => {
                Notifications.showError(err);
            });

        // attachments.forEach((fileItem: any) => {
        //     const data = new FormData();
        //     data.append("payment_method", paymentMethod);
        //     data.append("splitid", paymentId);
        //     data.append("file", fileItem.fileData);
        //     data.append("transfer_date", dateTransfer);
        //     data.append("transfer_name", fullNameTransfer);
        //     bankName && data.append("bank_name_id", bankName);
        //     numberInstalment &&
        //         data.append("installment_loan_tenure", numberInstalment);
        //     merchant && data.append("bank_2c2p_id", merchant);
        //     transactionId &&
        //         data.append("payment_transaction_id", transactionId);
        //     isRequireBankField && data.append("transfer_bank", bankTransfer);
        //     paymentMethod === "thai-qr-payment" &&
        //         data.append("transfer_bank", thaiQrPayment?.id);
        //     data.append("comment", comment);
        //     data.append("payment_option", paymentOption?.id);
        //     APIList.push({ method: uploadOrderSplitPayment, params: data });
        // });
        // Progress.show(APIList, (res) => {
        //     Notifications.showSuccess(t("notification:uploadFileSuccess"));
        //     onSuccessUploadInfo();
        // });
    };

    return (
        <div>
            <Select
                disabled={isView || paymentMethod !== PaymentMethod.Default}
                hidden={paymentMethod !== PaymentMethod.Payment_2C2P}
                className="mt-3"
                label={Messages.paymentOption}
                dataSource={payment2C2POptionsList}
                value={formik.values.paymentOption}
                error={formik.errors.paymentOption}
                getLabel={(item) => item.title}
                getValue={(item: any) => item?.option}
                onChange={(value) =>
                    formik.setFieldValue("paymentOption", value)
                }
            />
            {/* <Select
                disabled={!!defaultInfo.numberInstalment || isView}
                hidden={paymentMethod !== PAYMENT_MODERN_TRADE}
                className="mt-3"
                label={t("numberOfInstalment")}
                name="numberInstalment"
                dataSource={NUMBER_INSTALMENT}
                value={formik.values.numberInstalment}
                error={formik.errors.numberInstalment}
                onChange={(value) =>
                    formik.setFieldValue("numberInstalment", value)
                }
                placeholder={t("pleaseSelect")}
            /> */}
            {/* <Select
                disabled={!!defaultInfo.bankName || isView}
                hidden={paymentMethod !== PAYMENT_MODERN_TRADE}
                className="mt-3"
                label={t("bankName")}
                name="bankName"
                dataSource={mtBankList}
                value={formik.values.bankName}
                error={formik.errors.bankName}
                onChange={(value) => formik.setFieldValue("bankName", value)}
                getLabel={(item) => (
                    <div className="flex items-center">
                        {item.logo && (
                            <img
                                src={item.logo}
                                className="w-4 h-4 mr-2"
                                alt=""
                            />
                        )}
                        {item.code}
                    </div>
                )}
                placeholder={t("pleaseSelect")}
            /> */}
            {!isView && (
                <div className="mt-2">
                    <UploadButton
                        onChange={(file: any) =>
                            formik.setFieldValue("attachments", file)
                        }
                        defaultFiles={formik?.values?.attachments}
                        // uploadImagesOnly
                    />
                    <ViewTextError error={formik.errors.attachments} />
                </div>
            )}

            <InputText
                className="mt-3"
                label={Messages.remark}
                multiple
                placeholder={Messages.pleaseInputTheNote}
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={formik.errors.comment as string}
                hidden={isView && !formik.values.comment}
            />
        </div>
    );
};
export const UploadPaymentInfo = forwardRef(UploadPaymentInfoRef);

export default function UploadPaymentInfoDrawer({
    open,
    onClose,
    payment,
    onChangePaymentInfo,
}: any) {
    const {
        paymentMethod: defaultMethod,
        installment_loan_tenure,
        id,
        bank_name,
        paymentOption,
    } = payment;
    const { paymentMethodsList } = useContext(PaymentContext);
    const uploadBankInfoRef = useRef<any>();
    const [paymentMethod, setPaymentMethod] = useState(
        defaultMethod === PaymentMethod.Default ? null : defaultMethod
    );

    const renderInputContent = () => {
        if (isEmpty(paymentMethod)) {
            return <div />;
        }
        return (
            <UploadPaymentInfo
                ref={uploadBankInfoRef}
                onSuccessUploadInfo={onSuccessUploadInfo}
                paymentId={id}
                paymentMethod={paymentMethod}
                defaultInfo={{
                    numberInstalment: installment_loan_tenure,
                    bankName: bank_name?.id,
                    paymentOption,
                }}
            />
        );
    };

    const onClickSave = async () => {
        if (!paymentMethod) {
            return Promise.reject();
        }
        return uploadBankInfoRef.current.formik.handleSubmit();
    };

    const onSuccessUploadInfo = () => {
        onClose();
        onChangePaymentInfo && onChangePaymentInfo();
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            onSave={onClickSave}
            saveText={Messages.submit}
            title={Messages.paymentInformation}
        >
            <div className="p-3">
                <Select
                    disabled={!isEmpty(defaultMethod)}
                    label={`${Messages.paymentMethod} *`}
                    value={paymentMethod}
                    onChange={(value) => {
                        setPaymentMethod(value);
                    }}
                    dataSource={paymentMethodsList}
                    getValue={(item: any) => item?.value}
                    getLabel={(item) => Messages[item?.label]}
                />
                {renderInputContent()}
            </div>
        </Drawer>
    );
}
