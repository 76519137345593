import React from "react";
import { IProduct } from "../interfaces/product";

interface IProductCreateState {
    productForm: any;
}

const productCreateState: IProductCreateState = {
    productForm: null,
};

export const ProductCreateContext = React.createContext(productCreateState);

interface IProductDetailState {
    product: IProduct | null;
    setProduct: any;
}

const productDetailState: IProductDetailState = {
    product: null,
    setProduct: () => {},
};

export const ProductDetailContext = React.createContext(productDetailState);
