import {
    Button,
    Header,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find, forEach } from "lodash";
import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useCreateCustomerMutation } from "../../../api/hooks";
import VerticalTabView from "../../../common/VerticalTabView";
import { CustomerCreateContext } from "../../../context/customer";
import { CustomerSchema } from "../../../formschema/customer";
import { mapCustomerToServer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import { isBase64String } from "../../../utils/Utils";
import Path from "../../Path";
import MediaAPI from "../../../api/queries/media";
import { CustomerCreateBranch } from "./CustomerCreateBranch";
import CustomerCreateCardReader from "./CustomerCreateCardReader";
import CustomerCreateContact from "./CustomerCreateContact";
import CustomerCreateEmergency from "./CustomerCreateEmergency";
import CustomerCreateFamilyInfo from "./CustomerCreateFamilyInfo";
import CustomerCreatePersonalInfo from "./CustomerCreatePersonalInfo";
import CustomerCreatePrivacyInfo from "./CustomerCreatePrivacyInfo";
import { AppStateContext } from "../../../context/app";
import CustomerCreateReferrer from "./CustomerCreateReferrer";
import CustomerCreateMedicalProfile from "./CustomerCreateMedicalProfile";

const TABS = [
    {
        id: "branchWarehouse",
        label: Messages.branchWarehouse,
        component: <CustomerCreateBranch />,
    },

    {
        id: "personalInfo",
        label: Messages.personalInfo,
        component: <CustomerCreatePersonalInfo />,
    },
    {
        id: "privacy",
        label: Messages.privacyInfo,
        component: <CustomerCreatePrivacyInfo />,
    },
    {
        id: "medicalProfile",
        label: Messages.medicalProfile,
        component: <CustomerCreateMedicalProfile />,
    },
    {
        id: "contact",
        label: Messages.contactInfo,
        component: <CustomerCreateContact />,
    },
    {
        id: "family",
        label: Messages.familyInfo,
        component: <CustomerCreateFamilyInfo />,
    },
    {
        id: "emergency",
        label: Messages.emergencyContact,
        component: <CustomerCreateEmergency />,
    },
    {
        id: "referrer",
        label: Messages.referrer,
        component: <CustomerCreateReferrer />,
    },
];

const CustomerCreate = () => {
    const navigate = useNavigate();
    const { primaryCountry } = useContext(AppStateContext);
    const [selectedTab, setSelectedTab] = useState(TABS[0]);
    const [createCustomer] = useCreateCustomerMutation();

    const customerForm = useFormik<any>({
        initialValues: {
            phoneCodeCountry: primaryCountry,
            nationality: primaryCountry,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerToServer(values);
            onProcessCustomer(input);
        },
    });

    useEffect(() => {
        forEach(Object.keys(customerForm.errors), (key) =>
            Notifications.showError(`${customerForm.errors[key]}`)
        );
    }, [customerForm.errors]);

    const onProcessCustomer = async (data: any) => {
        const input = { ...data };
        if (input?.avatar && isBase64String(input?.avatar)) {
            const res = await fetch(`data:image/png;base64, ${input?.avatar}`);
            const blob = await res?.blob();
            const file = new File([blob], StringUtils.getUniqueID(), {
                type: "image/png",
            });
            const reader: any = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                const uploadRes = await MediaAPI.uploadFile(file);
                const { fileUrl } = uploadRes || {};
                Object.assign(input, { avatar: fileUrl });
                onCreateCustomer({
                    variables: {
                        input,
                    },
                });
            };
        } else {
            onCreateCustomer({
                variables: {
                    input,
                },
            });
        }
    };

    const onCreateCustomer = (body: any) => {
        return Progress.show(
            { method: createCustomer, params: [body] },
            (res: any) => {
                const customerId = res?.data?.data?.data?.id;
                Notifications.showSuccess(Messages.createCustomerSuccess);
                navigate(generatePath(Path.CUSTOMER_DETAIL, { customerId }));
            }
        );
    };

    return (
        <CustomerCreateContext.Provider
            value={{
                customerForm,
            }}
        >
            <Header
                showCancel={false}
                className="sticky top-0"
                title={Messages.customerCreate}
                // onSave={() => customerForm.handleSubmit()}
                customRight={() => {
                    return (
                        <div className="flex-center-y">
                            <CustomerCreateCardReader />
                            <Button onClick={() => customerForm.handleSubmit()}>
                                {Messages.save}
                            </Button>
                        </div>
                    );
                }}
            />
            <div className="h-100 overflow-auto p-3 bg-white">
                <div className="row">
                    <div className="col-sm-3">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-9">
                        <div className="border">
                            {
                                find(TABS, (item) => selectedTab.id === item.id)
                                    ?.component
                            }
                        </div>
                    </form>
                </div>
            </div>
        </CustomerCreateContext.Provider>
    );
};

export default CustomerCreate;
