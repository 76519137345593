import { gql } from "@apollo/client";
import { mapProductFromServer } from "../../interfaces/product";
import API from "../API";
import {
    F_PRODUCT,
    F_PRODUCTS,
    F_PRODUCTS_FULL_DETAILS,
    F_PRODUCT_CATEGORIES,
    F_PRODUCT_CATEGORY,
} from "../fragments/product";

export const getProductCategories = gql`
    ${F_PRODUCT_CATEGORIES}
    query getProductCategories($paginate: ListAdminCategoryProductRequest!) {
        data: listCategoryProductForAdmin(paginate: $paginate) {
            ...F_PRODUCT_CATEGORIES
        }
    }
`;

export const getProductCategory = gql`
    ${F_PRODUCT_CATEGORY}
    query getProductCategory($id: String!) {
        data: detailCategoryProductForAdmin(id: $id) {
            data {
                ...F_PRODUCT_CATEGORY
            }
        }
    }
`;

export const getProducts = gql`
    ${F_PRODUCTS}
    query getProducts($input: ListAdminProductRequest!) {
        data: listProductForAdmin(paginate: $input) {
            ...F_PRODUCTS
        }
    }
`;

export const getProduct = gql`
    ${F_PRODUCT}
    query getProduct($id: String!) {
        data: detailProductForAdmin(id: $id) {
            data {
                ...F_PRODUCT
            }
        }
    }
`;

export const createProduct = gql`
    mutation createProduct($payload: CreateAdminProductRequest!) {
        data: createProductForAdmin(payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const updateProduct = gql`
    ${F_PRODUCT}
    mutation updateProduct($id: String!, $payload: UpdateAdminProductRequest!) {
        data: updateProductForAdmin(id: $id, payload: $payload) {
            data {
                ...F_PRODUCT
            }
        }
    }
`;

export const createProductCategory = gql`
    ${F_PRODUCT_CATEGORY}
    mutation createProductCategory(
        $payload: CreateAdminCategoryProductRequest!
    ) {
        data: createCategoryProductForAdmin(payload: $payload) {
            data {
                ...F_PRODUCT_CATEGORY
            }
        }
    }
`;

export const updateProductCategory = gql`
    ${F_PRODUCT_CATEGORY}
    mutation updateProductCategory(
        $id: String!
        $payload: UpdateAdminCategoryProductRequest!
    ) {
        data: updateCategoryProductForAdmin(id: $id, payload: $payload) {
            data {
                ...F_PRODUCT_CATEGORY
            }
        }
    }
`;

export const getProductsFullDetails = gql`
    ${F_PRODUCTS_FULL_DETAILS}
    query getProductsFullDetails($paginate: SearchAdminProductRequest!) {
        data: searchProductForAdmin(paginate: $paginate) {
            ...F_PRODUCTS_FULL_DETAILS
        }
    }
`;

const productDetailConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? [];
        const productDetail = mapProductFromServer(detail);
        return productDetail;
    },
};

const productsConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const ProductAPI = {
    list: async (input: any) =>
        API.withConverter(productsConverter).query({
            query: getProducts,
            variables: { input },
            fetchPolicy: "no-cache",
        }),
    detail: async (id: string) =>
        API.withConverter(productDetailConverter).mutate({
            mutation: getProduct,
            variables: { id },
        }),

    update: async (id: string, payload: any) =>
        API.withConverter(productDetailConverter).mutate({
            mutation: updateProduct,
            variables: { id, payload },
        }),
};

export default ProductAPI;
