import * as Yup from "yup";
import Messages from "../languages/Messages";

export const TreatmentBeforeAfterSchema = Yup.object().shape({
    date: Yup.string().required(Messages.fieldRequired),
    description: Yup.string().required(Messages.fieldRequired),
    before: Yup.object().required(Messages.fieldRequired),
    after: Yup.object().required(Messages.fieldRequired),
});

export const TreatmentSchema = Yup.object().shape({
    timeSlot: Yup.array().required(Messages.fieldRequired),
    products: Yup.array().required(Messages.fieldRequired),
    date: Yup.object().required(Messages.fieldRequired),
    branch: Yup.object().required(Messages.fieldRequired),
    doctorOrder: Yup.object().required(Messages.fieldRequired),
    doctor: Yup.object().when("noDoctor", (noDoctor: boolean, yup: any) => {
        if (!noDoctor) {
            return Yup.object().required(Messages.fieldRequired);
        }
        return Yup.mixed().nullable();
    }),
    remark: Yup.string().required(Messages.fieldRequired),
});
