import styled from "@emotion/styled";
import { Pagination } from "antd";
import {
    Button,
    DialogManager,
    Progress,
    Notifications,
    TimeUtils,
} from "d-react-components";
import parse from "html-react-parser";
import { map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
    useCreateDoctorOrderNoteForAdminMutation,
    useListDoctorOrderNotesForAdminLazyQuery,
    useUpdateDoctorOrderNoteForAdminMutation,
} from "../../../api/hooks";
import { DoctorOrderDetailContext } from "../../../context/doctor-order";
import { INote } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import {
    BookingNoteCrudDrawer,
    INoteItem,
} from "../../booking/detail/BookingDetailNote";

export const SIZE_OPTIONS = [20, 40, 60];

const NoteItem = ({ note, onUpdated }: INoteItem) => {
    const [openUpdate, setOpenUpdate] = useState(false);
    const [updateNote] = useUpdateDoctorOrderNoteForAdminMutation();

    const onUpdateNote = (input: any) => {
        Progress.show(
            {
                method: updateNote,
                params: [
                    {
                        variables: {
                            noteId: note?.id,
                            payload: {
                                ...input,
                            },
                        },
                    },
                ],
            },
            () => {
                onUpdated();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <div className="border p-4 relative note-item__container mb-3">
            <div className=" w-100 flex-row-between-center">
                <label>{note?.title}</label>
            </div>
            <ContentWrapper className="text mt-2 whitespace-pre">
                {parse(note?.content ?? "")}
            </ContentWrapper>
            <div className="border-t mt-2 py-2 text-gray">
                <small>
                    {Messages.lastUpdated}:{" "}
                    {TimeUtils.toDateTime(note?.createdAt)}
                </small>
            </div>
            <div className="absolute top-[12px] right-[10px]">
                <div className="note-item__edit-delete">
                    <Button
                        onClick={() => setOpenUpdate(true)}
                        className="mr-1"
                        iconName="edit"
                        size="x-small"
                    />
                    {/* <Button
                      onClick={onRemoveNote}
                      iconName="delete"
                      size="x-small"
                  /> */}
                </div>
            </div>
            {openUpdate && (
                <BookingNoteCrudDrawer
                    note={note}
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                    onSave={onUpdateNote}
                    inputType="richText"
                    drawerProps={{
                        width: "600px",
                        size: "auto",
                    }}
                />
            )}
        </div>
    );
};

const DoctorOpinion = () => {
    const [showAddOpinion, setShowAddOpinion] = useState(false);
    const [getNoteList] = useListDoctorOrderNotesForAdminLazyQuery();
    const [createNote] = useCreateDoctorOrderNoteForAdminMutation();
    const { doctorOrder } = useContext(DoctorOrderDetailContext);
    const [total, setTotal] = useState(0);
    const [noteList, setNoteList] = useState<INote[]>([]);
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 10,
    });

    useEffect(() => {
        loadNoteList();
    }, [pagination]);

    const loadNoteList = () => {
        getNoteList({
            variables: {
                doctorOrderId: doctorOrder?.id as string,
                paginate: {
                    limit: pagination?.pageSize,
                    page: pagination?.pageIndex,
                },
            },
            fetchPolicy: "no-cache",
        }).then((res) => {
            const noteListRes = res?.data?.data?.data ?? [];
            const total = res?.data?.data?.pagination?.items ?? 0;
            setNoteList(noteListRes as INote[]);
            setTotal(total);
        });
    };

    const onAddNote = (input: any) => {
        Progress.show(
            {
                method: createNote,
                params: [
                    {
                        variables: {
                            doctorOrderId: doctorOrder?.id,
                            payload: {
                                ...input,
                            },
                        },
                    },
                ],
            },
            () => {
                loadNoteList();
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    return (
        <div>
            <div className="flex items-center p-2">
                <div className="flex-1">
                    <h5>{Messages.doctorOpinion}</h5>
                </div>
                <div>
                    <Button
                        size="small"
                        className="ml-2"
                        onClick={() => setShowAddOpinion(true)}
                    >
                        {Messages.add}
                    </Button>
                </div>
            </div>
            <div className="flex-column p-2">
                {map(noteList, (node) => (
                    <NoteItem note={node} onUpdated={loadNoteList} />
                ))}
            </div>
            <div className="d-flex justify-end p-2">
                <Pagination
                    showTotal={(total: any) => (
                        <div className="captionText">{`Total ${total} items`}</div>
                    )}
                    current={pagination.pageIndex}
                    total={total}
                    pageSizeOptions={SIZE_OPTIONS}
                    onChange={(pageIndex, pageSize) =>
                        setPagination({ pageIndex, pageSize })
                    }
                    pageSize={pagination.pageSize}
                />
            </div>
            {showAddOpinion && (
                <BookingNoteCrudDrawer
                    open={showAddOpinion}
                    onClose={() => setShowAddOpinion(false)}
                    onSave={onAddNote}
                    inputType="richText"
                    drawerProps={{
                        width: "600px",
                        size: "auto",
                    }}
                />
            )}
        </div>
    );
};

const ContentWrapper = styled.div`
    * {
        white-space: pre-line;
    }
`;

export default DoctorOpinion;
