import styled from "@emotion/styled";
import {
    Button,
    InputText,
    InputTextProps,
    Select,
    SelectProps,
} from "d-react-components";
import React, { CSSProperties } from "react";
import Messages from "../../languages/Messages";

export interface IInputSelectKeyProps {
    className?: string;
    dataSource?: SelectProps["dataSource"];
    selectProps?: Partial<SelectProps>;
    inputProps?: Partial<InputTextProps>;
    selectKey?: any;
    textSearch?: string;
    onChangeText?: InputTextProps["onChange"];
    onChangeSelectKey?: SelectProps["onChange"];
    getLabel?: SelectProps["getLabel"];
    getKey?: SelectProps["getKey"];
    placeholder?: InputTextProps["placeholder"];
    style?: CSSProperties;
    styleSelect?: CSSProperties;
    styleInput?: CSSProperties;
    [key: string]: any;
}

const InputSelectKey: React.FC<IInputSelectKeyProps> = ({
    className,
    dataSource,
    inputProps,
    selectProps,
    selectKey,
    textSearch,
    placeholder,
    onChangeText,
    onChangeSelectKey,
    getKey,
    getLabel = (i) => Messages[i?.label],
    style,
    styleInput,
    styleSelect,
}) => {
    return (
        <InputSelectKeyStyled
            className={`flex-center-y gap-3 ${className}`}
            style={style}
        >
            <div className="flex-center-y w-full">
                <Select
                    className="input-select-key__select"
                    style={{ minWidth: "150px", ...(styleSelect || {}) }}
                    dataSource={dataSource}
                    value={selectKey}
                    onChange={onChangeSelectKey}
                    getKey={getKey}
                    getLabel={getLabel}
                    {...(selectProps || {})}
                />
                <div
                    className=""
                    style={{
                        width: "1px",
                        backgroundColor: "#ccc",
                        height: "30px",
                    }}
                />
                <InputText
                    className="w-full input-select-key__input"
                    onChange={onChangeText}
                    value={textSearch}
                    placeholder={placeholder}
                    style={styleInput}
                    {...(inputProps || {})}
                />
            </div>

            <Button>{Messages.search}</Button>
        </InputSelectKeyStyled>
    );
};

export default InputSelectKey;

const InputSelectKeyStyled = styled.div`
    .input-select-key__select {
        .d-select__select {
            border-right-width: 0px !important;
        }
        .ant-select-focused {
            border-color: #ccc !important;
        }
    }
    .input-select-key__input {
        .d-input-text__input-container {
            border-left-width: 0px !important;
        }
        .d-input-text__input-container-outline:focus-within {
            border-color: #ccc !important;
        }
    }
`;
