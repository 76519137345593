import classNames from "classnames";
import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
} from "d-react-components";
import { map, union } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import {
    TypeOfProduct,
    useAddProductDoctorOrderForAdminMutation,
    useRemoveProductDoctorOrderForAdminMutation,
} from "../../../api/hooks";
import { DoctorOrderDetailContext } from "../../../context/doctor-order";
import Messages from "../../../languages/Messages";
import ProductName from "../../quotation/share/ProductName";
import ProductSelectDrawer from "../crud/ProductSelectDrawer";

const DoctorOrderMedicine = () => {
    const tableRef = useRef<any>(null);
    const { doctorOrder, loadDoctorOrderDetail } = useContext(
        DoctorOrderDetailContext
    );
    const [addProduct] = useAddProductDoctorOrderForAdminMutation();
    const [removeProduct] = useRemoveProductDoctorOrderForAdminMutation();
    const { products, treatments } = doctorOrder ?? {};
    const [showAddProduct, setShowAddProduct] = useState(false);

    const dataSource = useMemo(() => {
        return [
            ...(products || []),
            ...(treatments
                ? union(
                      ...treatments.map((treatment) => [
                          ...treatment?.products?.map((product) => ({
                              ...product,
                              treatmentNo: treatment.treatmentNo,
                          })),
                      ])
                  )
                : []),
        ];
    }, [products, treatments]);

    const handleAddProduct = (newProducts: any) => {
        Progress.show(
            {
                method: addProduct,
                params: [
                    {
                        variables: {
                            id: doctorOrder?.id as string,
                            payload: newProducts,
                        },
                    },
                ],
            },
            () => {
                Notifications.showSuccess(Messages.addProductSuccessfully);
                setShowAddProduct(false);
                loadDoctorOrderDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    const handleRemoveProduct = (item: any) => {
        if (item.treatmentNo) {
            return;
        }
        const productId = item?.product?.id;
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () => {
                Progress.show(
                    {
                        method: removeProduct,
                        params: [
                            {
                                variables: {
                                    id: doctorOrder?.id as string,
                                    productId,
                                },
                            },
                        ],
                    },
                    () => {
                        Notifications.showSuccess(
                            Messages.removeProductSuccessfully
                        );
                        loadDoctorOrderDetail();
                    },
                    (err: any) => {
                        Notifications.showError(err);
                    }
                );
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "id",
            // width: 300,
            ellipsis: true,
            render: (product: any, item: any) => {
                return (
                    <ProductName
                        item={item?.product}
                        subBody={
                            <>
                                {item?.treatmentNo && (
                                    <span className="text-primary mt-1">
                                        {Messages.ref}: #{item?.treatmentNo}
                                    </span>
                                )}
                            </>
                        }
                    />
                );
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            width: 100,
        },
        {
            title: Messages.unit,
            dataIndex: "product",
            render: (product: any) => product?.unit,
        },
        {
            title: Messages.action,
            dataIndex: "id",
            width: 100,
            render: (product: any, item: any) => {
                return (
                    <Icon
                        onClick={() => handleRemoveProduct(item)}
                        name="remove_circle_outline"
                        className={classNames({
                            "text-primary cursor-pointer": !item.treatmentNo,
                            "text-gray-300": !!item.treatmentNo,
                        })}
                    />
                );
            },
        },
    ];

    return (
        <div>
            <div className="flex items-center p-2">
                <div className="flex-1">
                    <h5>{Messages.medicine}</h5>
                </div>
                <div>
                    <Button
                        size="small"
                        className="ml-2"
                        onClick={() => setShowAddProduct(true)}
                    >
                        {Messages.add}
                    </Button>
                </div>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                dataSource={dataSource}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                showHeader
            />
            {showAddProduct && (
                <ProductSelectDrawer
                    open={showAddProduct}
                    onClose={() => setShowAddProduct(false)}
                    onSave={handleAddProduct}
                    productType={TypeOfProduct.SimpleProduct}
                    isMedicine
                    defaultValue={map(products as any, (item) => ({
                        ...item.product,
                        quantity: item.quantity,
                    }))}
                />
            )}
        </div>
    );
};

export default DoctorOrderMedicine;
