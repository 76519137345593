import {
    DialogManager,
    Icon,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import PrintProvider, { NoPrint, Print } from "react-easy-print";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
    RefActivity,
    useDetailOrderForAdminLazyQuery,
    useListAllOrderDeliveryForAdminLazyQuery,
    useUpdateOrderExpiredForAdminMutation,
    useUpdateOrderQuotationForAdminMutation,
    useCancelOrderMutation,
    useGetPaymentMethodSettingListQuery,
    PaymentMethod,
    useListAllPaymentForAdminQuery,
} from "../../../api/hooks";
import ActionMenu, { ActionButton } from "../../../common/ActionButton";
import ActivityView from "../../../common/activity/ActivityView";
import { ORDER_STATUSES } from "../../../constant/order";
import { QuotationAction } from "../../../constant/quotation";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import ChangeExpiryDateDrawer from "./OrderChangeExpiryDateDrawer";
import QuotationPriceTable from "../../quotation/share/QuotationPriceTable";
import OrderBilling from "./OrderBilling";
import OrderBranch from "./OrderBranch";
import OrderContact from "./OrderContact";
import OrderCustomer from "./OrderCustomer";
import OrderDOReferences from "./OrderDOReferences";
import OrderHeader from "./OrderHeader";
import OrderNote from "./OrderNote";
import OrderQuotationRef from "./OrderQuotationRef";
import OrderQuotationReferences from "./OrderQuotationReferences";
import OrderShipping from "./OrderShipping";
import OrderPayment from "./OrderPayment";
import OrderDeliveryCreate from "./OrderDeliveryCreate";
import { IAddress } from "../../../interfaces/address";
import { IDeliveryOrder } from "../../../interfaces/delivery-order";
import OrderDelivery from "./OrderDelivery";
import { CONFIG } from "../../../configuration/AppConfig";
import OrderVoucherReferences from "./OrderVoucherReferences";
import OrderGeneratedVoucher from "./OrderGeneratedVoucher";
import OrderSalePerson from "./OrderSalePerson";
import OrderPurchaseType from "./OrderPurchaseType";
import OrderTreatmentReferences from "./OrderTreatmentReferences";
import { OrderType } from "../../../interfaces/order";
import OrderPrintLayout from "./OrderPrintLayout";
import OrderSourceCustomer from "./OrderSourceCustomer";

const OrderDetail = () => {
    const { orderId } = useParams<any>();
    const printRef = useRef<any>(null);
    const [showAddQuotation, setShowAddQuotation] = useState(false);
    const [showChangeExpiry, setShowChangeExpiry] = useState(false);
    const [showCreateDelivery, setShowCreateDelivery] = useState(false);
    const [shippingList, setShippingList] = useState<IAddress[]>([]);
    const { data: dataPaymentList, refetch: reloadPaymentList } =
        useListAllPaymentForAdminQuery({
            variables: {
                refId: orderId as string,
            },
            skip: !orderId,
        });
    const { data: dataPaymentMethodsList } =
        useGetPaymentMethodSettingListQuery({
            variables: {
                paginate: {
                    page: 1,
                    limit: 100,
                },
            },
        });
    // const [deliveryList, setDeliveryList] = useState<any[]>([]);

    const [cancelOrder] = useCancelOrderMutation();
    const [updateOrderQuotation] = useUpdateOrderQuotationForAdminMutation();
    const [updateOrderExpiry] = useUpdateOrderExpiredForAdminMutation();
    const [getOrder, { data, loading, refetch }] =
        useDetailOrderForAdminLazyQuery();
    const [getOrderDelivery, { data: deliveryList }] =
        useListAllOrderDeliveryForAdminLazyQuery({
            fetchPolicy: "no-cache",
        });

    useEffect(() => {
        loadOrderDetail();
        loadOrderDelivery();
    }, []);

    const loadOrderDelivery = () => {
        getOrderDelivery({
            variables: {
                order: orderId as string,
            },
        });
    };

    const loadOrderDetail = () => {
        Progress.show(
            {
                method: getOrder,
                params: [
                    {
                        variables: {
                            id: orderId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };
    const orderDetail = data?.data?.data;

    const onClickAction = (item: any) => {
        if (item.id === "addQuotationRef") {
            setShowAddQuotation(true);
        }
        if (item.id === "changeExpiryDate") {
            setShowChangeExpiry(true);
        }
        if (item.id === "createDelivery") {
            setShowCreateDelivery(true);
        }
        if (item.id === "cancelOrder") {
            DialogManager.showConfirm(
                Messages.confirm,
                Messages.areYouSureWantCancel,
                () =>
                    Progress.show(
                        {
                            method: cancelOrder,
                            params: [
                                {
                                    variables: {
                                        id: orderId as string,
                                    },
                                },
                            ],
                        },
                        () => {
                            loadOrderDetail();
                        }
                    )
            );
        }
    };

    const onCloseAddQuotation = (selectedQuotation: string[]) => {
        updateOrderQuotation({
            variables: {
                id: orderDetail?.id as string,
                payload: {
                    quotation: selectedQuotation,
                    action: QuotationAction.ADD as any,
                },
            },
        })
            .then(() => {
                setShowAddQuotation(false);
                loadOrderDetail();
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    const onCloseChangeExpiry = (date: any) => {
        updateOrderExpiry({
            variables: {
                id: orderDetail?.id as string,
                payload: {
                    expired: moment(date).toDate(),
                },
            },
        })
            .then(() => {
                setShowChangeExpiry(false);
                loadOrderDetail();
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
            })
            .catch((err: any) => {
                Notifications.showError(err);
            });
    };

    const onCloseDeliveryCreate = () => {
        setShowCreateDelivery(false);
        loadOrderDelivery();
    };

    const paymentList = useMemo(
        () => dataPaymentList?.data?.data ?? [],
        [dataPaymentList]
    );
    const paymentMethodsList = useMemo(
        () => [
            ...(dataPaymentMethodsList?.data?.data ?? []),
            {
                paymentConfigurationType: PaymentMethod.Default,
                title: "Default",
                name: "Default",
            },
        ],
        [dataPaymentMethodsList]
    );

    return (
        <PrintProvider>
            <OrderDetailContext.Provider
                value={{
                    order: orderDetail as any,
                    loadOrderDetail,
                    shippingList,
                    setShippingList,
                    deliveryList: deliveryList?.data?.data as IDeliveryOrder[],
                    loadDeliveryList: loadOrderDelivery,
                    paymentList,
                    paymentMethodsList,
                    reloadPaymentList,
                }}
            >
                {/* <Print name="printOrder" printOnly> */}
                <OrderPrintLayout ref={printRef} />
                {/* </Print> */}
                <NoPrint>
                    <div className="px-4 py-3 bg-white flex border-b items-center">
                        <div className="flex flex-1 align-items-center">
                            <h5 className="text-primary mr-3">
                                {Messages.order} #{`${orderDetail?.orderNo}`}
                            </h5>
                            <ViewLabelStatus
                                status={orderDetail?.status}
                                listStatus={ORDER_STATUSES}
                                getLabel={(item) =>
                                    (Messages as any)[item.label]
                                }
                            />
                        </div>
                        <div className="flex items-center">
                            <ActionButton
                                className="mr-2"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${CONFIG.CUSTOMER_PAGE_URL}/o/${orderDetail?.id}`
                                    );
                                    Notifications.showInfo(
                                        Messages.copySuccess
                                    );
                                }}
                            >
                                {Messages.orderUrl}
                            </ActionButton>
                            <ActionButton
                                className="mr-2"
                                onClick={() => {
                                    window.print();
                                }}
                            >
                                <Icon name="print" />
                            </ActionButton>
                            <ActionMenu
                                dataSource={[
                                    {
                                        id: "addQuotationRef",
                                        label: Messages.addQuotationRef,
                                    },
                                    {
                                        id: "createDelivery",
                                        label: Messages.createDelivery,
                                    },
                                    {
                                        id: "changeExpiryDate",
                                        label: Messages.changeExpiryDate,
                                    },
                                    {
                                        id: "cancelOrder",
                                        label: Messages.cancelOrder,
                                    },
                                ]}
                                onClick={onClickAction}
                            />
                        </div>
                    </div>
                    <div className="px-4 py-3">
                        <OrderHeader />
                        <div className="grid grid-cols-4 gap-4 mt-3">
                            <div className="col-span-3">
                                <div className="border p-3 mb-3">
                                    <QuotationPriceTable
                                        order={orderDetail as any}
                                        onUpdated={loadOrderDetail}
                                        type={OrderType.ORDER}
                                    />
                                </div>
                                <OrderNote />
                                <OrderGeneratedVoucher />
                                <OrderPayment />
                                <ActivityView
                                    className="mt-3 border p-3"
                                    activityRef={RefActivity?.DoctorOrder}
                                    activityRefId={orderId ?? ""}
                                />
                            </div>
                            <div className="col-span-1">
                                <OrderDelivery />
                                <OrderBranch />
                                <OrderSalePerson
                                    onUpdated={() => loadOrderDetail()}
                                />
                                <OrderPurchaseType />
                                <OrderSourceCustomer />
                                <OrderCustomer />
                                <OrderContact
                                    onUpdated={() => loadOrderDetail()}
                                />
                                <OrderShipping />
                                <OrderBilling />
                                <OrderDOReferences />
                                <OrderTreatmentReferences />
                                <OrderQuotationReferences />
                                <OrderVoucherReferences />
                            </div>
                        </div>
                    </div>
                    {showAddQuotation && (
                        <OrderQuotationRef
                            open={showAddQuotation}
                            onClose={() => setShowAddQuotation(false)}
                            onSave={(selectedQuotation: string[]) =>
                                onCloseAddQuotation(selectedQuotation)
                            }
                        />
                    )}
                    {showChangeExpiry && (
                        <ChangeExpiryDateDrawer
                            open={showChangeExpiry}
                            onClose={() => setShowChangeExpiry(false)}
                            defaultDate={orderDetail?.expired}
                            onSave={(date: any) => onCloseChangeExpiry(date)}
                        />
                    )}
                    {showCreateDelivery && (
                        <OrderDeliveryCreate
                            open={showCreateDelivery}
                            onClose={() => setShowCreateDelivery(false)}
                            onSave={() => onCloseDeliveryCreate()}
                        />
                    )}
                </NoPrint>
            </OrderDetailContext.Provider>
        </PrintProvider>
    );
};

export default OrderDetail;
