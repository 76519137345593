import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, isNaN, join, map, reduce } from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import { ITreatment } from "../../interfaces/treatment";
import { JobType } from "../../interfaces/user";
import { IVoucher } from "../../interfaces/voucher";
import Messages from "../../languages/Messages";
import { getFullName } from "../../utils/Utils";
import { exportToCSV } from "../../utils/file";
import BranchSelect from "../branch/share/BranchSelect";
import ReportFooter from "./ReportFooter";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    user: [],
    timeRange: null,
};

const ReportTreatmentDailyUsage = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = (values: any) => {
        const payload = {
            branch: map(values.branch, (item) => item.id),
            from: values?.timeRange?.[0]?.valueOf(),
            to: values?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.treatmentDetailUsage, params: [payload] },
            loadData as any
        );
    };

    const loadData = (dataList: any[]) => {
        const exportData: any = [];
        const firstDayMonth = moment(formValues?.timeRange?.[0]);
        const lastDayMonth = moment(formValues?.timeRange?.[1]);
        const dateRangeText = join(
            [
                firstDayMonth.format("DD/MM/YYYY"),
                lastDayMonth.format("DD/MM/YYYY"),
            ],
            " - "
        );

        try {
            for (let index = 0; index < dataList.length; index += 1) {
                const treatment: ITreatment = dataList[index];
                const {
                    customer,
                    doctorOrder,
                    day,
                    treatmentNo,
                    services,
                    branch,
                    createdAt,
                    doctor,
                    PIC,
                } = treatment;

                const voucherList: { voucher: IVoucher }[] =
                    doctorOrder?.order?.voucher ?? [];

                const nurseList = filter(
                    PIC,
                    (item) => item?.adminUser?.jobType === JobType.NURSE
                );
                const nurseNames = join(
                    map(nurseList, (item) => getFullName(item.adminUser)),
                    ", "
                );
                const therapistList = filter(
                    PIC,
                    (item) => item?.adminUser?.jobType === JobType.THERAPIST
                );
                const therapistNames = join(
                    map(therapistList, (item) => getFullName(item.adminUser)),
                    ", "
                );

                for (let index = 0; index < services.length; index += 1) {
                    const treatmentService = services[index];

                    const voucherTreatment: any = find(
                        voucherList,
                        (item: any) => {
                            return (
                                item?.product?.id ===
                                treatmentService?.productFull?.id
                            );
                        }
                    );

                    const voucherUsageList = filter(
                        voucherTreatment?.voucherFull?.usage,
                        (item: any) =>
                            moment(item?.usedAt).isBefore(
                                moment(day, "DD/MM/YYYY")
                            )
                    );

                    const accummulateUsed = reduce(
                        voucherUsageList,
                        (sum, item) => sum + item.usedValue,
                        0
                    );

                    const usedValueToday =
                        treatmentService?.quantity *
                        (treatmentService?.productFull?.salePrice ?? 0);

                    const balance =
                        voucherTreatment?.amount -
                        accummulateUsed -
                        usedValueToday;

                    // const voucherOrder = voucherTreatment?.;

                    exportData.push({
                        "Date of treatment": day,
                        CN_USED: customer?.customerNo,
                        "Customer Name": customer?.fullNameTh,
                        "Doctor Order ID": doctorOrder?.doctorOrderNo,
                        "Treatment ID": treatmentNo,
                        "Apply Voucher ID":
                            voucherTreatment?.voucherFull?.voucherNo,
                        Service: treatmentService?.productFull?.name,
                        "Total Amount": voucherTreatment?.quantity,
                        "Price After Discount": voucherTreatment?.amount,
                        "Accummulate used(THB)": accummulateUsed,
                        "Used Amount (Today)": treatmentService?.quantity,
                        "Used Values Today(THB)": usedValueToday,
                        "Balance(Bath)": isNaN(balance) ? "" : balance,
                        "Treatment Branch Used": branch?.name,
                        SaleDate: TimeUtils.toDate(createdAt),
                        Doctor: getFullName(doctor?.adminUser),
                        Nurse: nurseNames,
                        Therapist: therapistNames,
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Treatment daily usage report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <BranchSelect
                    className={classNameInput}
                    value={formValues?.branch}
                    onChange={(value) =>
                        exportForm.setFieldValue("branch", value)
                    }
                    multiple
                />
            </div>
        );
    };

    //                 Date: "",
    //                 Branch: "",
    //                 "Treatment no.": "",
    //                 Type: "",
    //                 CN: "",
    //                 "Customer name": "",
    //                 Treatment: "",
    //                 "Minutes of treatment": "",
    //                 "Quantity of treatment": "",
    //                 "Doctor fee": "",

    const columns: IColumnsProps = [
        {
            title: "Date of treatment",
            dataIndex: "Date of treatment",
        },
        {
            title: "CN_USED",
            dataIndex: "CN_USED",
        },
        {
            title: "Customer Name",
            dataIndex: "Customer Name",
        },
        {
            title: "Doctor Order ID",
            dataIndex: "Doctor Order ID",
        },
        {
            title: "Treatment ID",
            dataIndex: "Treatment ID",
        },
        {
            title: "Apply Voucher ID",
            dataIndex: "Apply Voucher ID",
        },
        {
            title: "Service",
            dataIndex: "Service",
        },
        {
            title: "Total Amount",
            dataIndex: "Total Amount",
        },
        {
            title: "Price After Discount",
            dataIndex: "Price After Discount",
        },
        {
            title: "Accummulate used(THB)",
            dataIndex: "Accummulate used(THB)",
        },
        {
            title: "Used Amount (Today)",
            dataIndex: "Used Amount (Today)",
        },
        {
            title: "Used Values Today(THB)",
            dataIndex: "Used Values Today(THB)",
        },
        {
            title: "Balance(Bath)",
            dataIndex: "Balance(Bath)",
        },
        {
            title: "Treatment Branch Used",
            dataIndex: "Treatment Branch Used",
        },
        {
            title: "SaleDate",
            dataIndex: "SaleDate",
        },
        {
            title: "Doctor",
            dataIndex: "Doctor",
        },
        {
            title: "Nurse",
            dataIndex: "Nurse",
        },
        {
            title: "Therapist",
            dataIndex: "Therapist",
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                baseColumnProps={{ width: 100 }}
                columns={columns}
                dataSource={dataTable}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.treatmentDailyUsageReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={!formValues?.timeRange?.length}
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportTreatmentDailyUsage;
