import { Button, Checkbox, CheckboxGroup, Icon } from "d-react-components";
import { useFormikContext } from "formik";
import { isEmpty, isUndefined, map, uniqBy } from "lodash";
import React, { memo, useContext, useEffect, useState } from "react";
import { useAllCustomerAddressForAdminLazyQuery } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { MaterialPurchaseContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import {
    AddressItem,
    CreateEditAddressDrawer,
} from "../../customer/detail/CustomerDetailAddress";

const ShippingDrawer = memo(({ open, onClose, onSave }: any) => {
    const [showAddShippingDrawer, setShowAddShippingDrawer] = useState(false);
    const [showEditShipping, setShowEditShipping] = useState<any>(undefined);
    const { shipping, setShipping } = useContext(MaterialPurchaseContext);
    const { values } = useFormikContext<any>();
    const { customer } = values ?? {};
    const isGuest = isUndefined(customer?.[0]?.id);

    const onCloseAddShipping = (newShipping: any[]) => {
        setShowAddShippingDrawer(false);
        setShipping(
            uniqBy([...(shipping ?? []), ...(newShipping ?? [])], "id")
        );
    };

    const onCloseEditShipping = (address: any) => {
        setShowEditShipping(undefined);
        if (address.id) {
            setShipping(
                shipping?.map((shippingAddress) =>
                    shippingAddress.id === address.id
                        ? address
                        : shippingAddress
                )
            );
        } else {
            setShipping([
                ...(shipping ?? []),
                {
                    ...address,
                    id: Math.random(),
                },
            ]);
        }
    };

    const onClickRemoveShipping = (address: any) => {
        setShipping(
            shipping?.filter(
                (shippingAddress) => address.id !== shippingAddress.id
            )
        );
    };

    return (
        <Drawer
            title={Messages.shippingInformation}
            open={open}
            onClose={onClose}
            destroyOnClose
            onSave={onSave}
        >
            <div className="p-4">
                {map(shipping ?? [], (address, index) => (
                    <div key={index} className="border-b pt-1 pb-3">
                        <AddressItem
                            address={address}
                            index={index + 1}
                            showAction={false}
                        />
                        <div className="mt-2">
                            <div
                                className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none mr-1"
                                onClick={() => {
                                    setShowEditShipping(address);
                                }}
                            >
                                <Icon name="edit" size="x-small" />
                            </div>
                            <div
                                className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none"
                                onClick={() => {
                                    onClickRemoveShipping(address);
                                }}
                            >
                                <Icon name="delete" size="x-small" />
                            </div>
                        </div>
                    </div>
                ))}
                {showAddShippingDrawer && (
                    <AddShippingDrawer
                        open={showAddShippingDrawer}
                        onSave={onCloseAddShipping}
                        onClose={
                            () => setShowAddShippingDrawer(false) //show shipping selection for customer
                        }
                    />
                )}
                {!isUndefined(showEditShipping) && (
                    <CreateEditAddressDrawer
                        open={!isUndefined(showEditShipping)}
                        onSaveAddress={onCloseEditShipping}
                        onClose={() => setShowEditShipping(undefined)}
                        addressDefault={showEditShipping}
                    />
                )}
                <Button
                    iconName="add"
                    onClick={() =>
                        isGuest
                            ? setShowEditShipping({})
                            : setShowAddShippingDrawer(true)
                    }
                    className="mt-4"
                >
                    {Messages.add}
                </Button>
            </div>
        </Drawer>
    );
});

const AddShippingDrawer = ({ open, onClose, onSave }: any) => {
    const { values } = useFormikContext<any>();
    const { customer } = values ?? {};
    const [selected, setSelected] = useState<string[]>([]);
    const [addresses, setAddresses] = useState<any[]>([]);
    const [showAddNewShipping, setShowAddNewShipping] = useState(false);
    const [getShippingAddress] = useAllCustomerAddressForAdminLazyQuery();

    const onChangeCheckbox = (address: any) => {
        if (selected.includes(address.id)) {
            setSelected(selected.filter((item) => item !== address.id));
        } else {
            setSelected([...selected, address.id]);
        }
    };

    const onSaveShipping = () => {
        onSave(addresses?.filter((address) => selected.includes(address.id)));
    };

    const onSaveNewShipping = () => {
        setShowAddNewShipping(false);
        loadShippingAddress();
    };

    const loadShippingAddress = () => {
        const customerId = customer?.[0]?.id;
        if (customer?.[0]?.id) {
            getShippingAddress({
                variables: {
                    customerId,
                },
                fetchPolicy: "no-cache",
            }).then((resp) => {
                setAddresses(resp?.data?.data?.data ?? []);
            });
        }
    };

    useEffect(() => {
        loadShippingAddress();
    }, [customer]);

    return (
        <Drawer
            title={
                <div className="flex items-center">
                    <span className="flex-1">
                        {Messages.addShippingInformation}
                    </span>
                    <Button
                        size="medium"
                        onClick={() => {
                            setShowAddNewShipping(true);
                        }}
                    >
                        <Icon name="add" /> {Messages.add}
                    </Button>
                </div>
            }
            open={open}
            onClose={onClose}
            destroyOnClose
            onSave={onSaveShipping}
        >
            <div className="p-4">
                {map(addresses ?? [], (address, index) => (
                    <div key={index} className="flex border-b pt-1 pb-3">
                        <Checkbox
                            className="mr-3"
                            checked={selected.includes(address.id)}
                            onChange={() => onChangeCheckbox(address)}
                        />
                        <AddressItem
                            address={address}
                            index={index + 1}
                            showAction={false}
                        />
                    </div>
                ))}
            </div>
            {showAddNewShipping && (
                <CreateEditAddressDrawer
                    open={showAddNewShipping}
                    onClose={() => onSaveNewShipping()}
                    isEdit={false}
                />
            )}
        </Drawer>
    );
};

export default ShippingDrawer;
