import { Button } from "d-react-components";
import { filter, isEmpty, map } from "lodash";
import Messages from "../../../../languages/Messages";
import StockRequestSelect, { IBranchSelect } from "./StockRequestSelect";

interface IStockRequestSolidItem {
    request: any;
    onRemove?: any;
}

export const StockRequestSolidItem = ({
    request,
    onRemove,
}: IStockRequestSolidItem) => {
    const { requestNo, branch } = request;
    return (
        <div className="w-100 mt-3 bg-primary d-flex items-center justify-between">
            <div className="p-3 flex-column">
                <span className="block text-white font-weight-bold">
                    #{requestNo}
                </span>
                <span className="block text-white opacity-75">{`${
                    branch?.name ?? "N/A"
                }`}</span>
            </div>
            {onRemove && <Button iconName="delete" onClick={onRemove} />}
        </div>
    );
};

const StockRequestSolidSelect = ({
    className,
    onChange,
    ...selectProps
}: IBranchSelect<any>) => {
    const renderSelected = () => {
        if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
        if (selectProps?.multiple) {
            return map(selectProps?.value, (data) => (
                <StockRequestSolidItem
                    request={data}
                    onRemove={() =>
                        onChange(
                            filter(
                                selectProps?.value ?? [],
                                (i) => i?.id !== data?.id
                            )
                        )
                    }
                />
            ));
        }
        return <StockRequestSolidItem request={selectProps?.value as any} />;
    };

    return (
        <div className={className}>
            <StockRequestSelect {...selectProps} onChange={onChange} />
            {renderSelected()}
        </div>
    );
};

export default StockRequestSolidSelect;
