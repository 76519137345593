import React from "react";
import {
    Button,
    DateInput,
    Select,
    TabBar,
    TimeUtils,
} from "d-react-components";
import ClassNames from "classnames";
import moment from "moment";
import Messages from "../../../languages/Messages";
import CalendarFilterButton from "./CalendarFilterButton";
import ActionMenu from "../../../common/ActionButton";
import { CALENDAR_SUBJECT_TYPE } from "../../../constant/calendar";
import BranchSelectByTab from "../../branch/share/BranchSelectByTab";
import { CalendarContext } from "../../../context/calendar";

export interface ICalendarHeaderProps {
    [key: string]: any;
}

const CalendarHeader: React.FC<ICalendarHeaderProps> = () => {
    const { branchList, calendarFilterState, updateFilterState } =
        React.useContext(CalendarContext);
    const { dateRange } = calendarFilterState || {};

    const renderTopHeader = (
        <div className="px-3 py-3 bg-white flex border-b items-center">
            <div className="flex flex-1 align-items-center">
                <h5 className="text-primary mr-3">{Messages.calendar}</h5>
            </div>
            <div className="flex items-center">
                <ActionMenu
                    dataSource={[
                        {
                            id: "addQuotationRef",
                            label: Messages.addQuotationRef,
                        },
                    ]}
                    // onClick={onClickAction}
                />
            </div>
        </div>
    );

    const rightSide = (
        <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-end">
                <label className="d-block">{Messages.dateRange}</label>
                <div className="text-x-small">
                    {TimeUtils.convertMiliToDate(
                        moment(dateRange).startOf("M")
                    )}
                    -{TimeUtils.convertMiliToDate(moment(dateRange).endOf("M"))}
                </div>
            </div>
            <DateInput
                useButton
                className="mx-3"
                // isRangePicker
                type="month"
                onChange={(value) => {
                    if (value) {
                        updateFilterState({ dateRange: value as any });
                    }
                }}
                value={dateRange as any}
                format="DD/MM/YYYY"
            />
            <CalendarFilterButton />
        </div>
    );

    return (
        <React.Fragment>
            {renderTopHeader}
            <CalendarMiddleHeader />
            <div className="d-flex justify-between align-items-center bg-gray-100 py-3 px-3">
                <BranchSelectByTab
                    key={branchList?.length}
                    autoLoadData={false}
                    branchListProps={branchList}
                    value={calendarFilterState?.branch as any}
                    onChange={(v) => {
                        updateFilterState({ branch: v });
                    }}
                />
                {rightSide}
            </div>
        </React.Fragment>
    );
};

export default CalendarHeader;

const CalendarMiddleHeader: React.FC<any> = () => {
    const { branchList, calendarFilterState, updateFilterState } =
        React.useContext(CalendarContext);
    return (
        <div className="d-flex justify-center align-items-center py-3">
            <TabBar
                activeIndicator="none"
                dataSource={CALENDAR_SUBJECT_TYPE}
                getLabel={(i) => Messages[i?.label as keyof typeof Messages]}
                getItemProps={({ isActive }) => {
                    return {
                        className: `border-none ${ClassNames({
                            "text-primary": isActive,
                        })}`,
                        color: "gray",
                    };
                }}
                value={calendarFilterState?.calendarSubject}
                onChange={(v) => updateFilterState({ calendarSubject: v as any })}
            />
        </div>
    );
};
