import { Pagination, Spin } from "antd";
import classNames from "classnames";
import { Notifications } from "d-react-components";
import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { useMedia } from "react-use";
import { useGetProductsFullDetailsLazyQuery } from "../../../api/hooks";
import { QuotationCreateContext } from "../../../context/quotation";
import { SIZE_OPTIONS } from "../crud/QuotationCreateProductList";
import ProductCard from "./ProductCard";

const ProductGrid = ({ pagination, setPagination, addNewProduct }: any) => {
    const { productFilters, orderMetrics } = useContext(QuotationCreateContext);

    const { values } = useFormikContext<any>();
    const { store } = values;

    const [productList, setProductList] = useState<any>([]);
    const [total, setTotal] = useState<number>();
    const [loadingList, setLoadingList] = useState(false);
    const isWide = useMedia("(min-width: 1280px)");
    const [getProductList] = useGetProductsFullDetailsLazyQuery();

    const getProduct = async () => {
        try {
            setLoadingList(true);
            const productResp = await getProductList({
                variables: {
                    paginate: {
                        page: pagination?.pageIndex as any,
                        limit: pagination?.pageSize,
                        search: productFilters?.textSearch ?? "",
                        sort: {
                            createdAt: -1,
                        },
                        ...(productFilters?.branches?.length
                            ? {
                                  branches: productFilters.branches,
                              }
                            : {}),
                        ...(productFilters?.categories?.length
                            ? {
                                  categories: productFilters?.categories?.map(
                                      (item: any) => item.id
                                  ),
                                  isVisibility: true,
                              }
                            : {
                                  isVisibility: true,
                              }),
                    },
                },
                fetchPolicy: "no-cache",
            });
            setProductList(productResp?.data?.data?.data);
            setTotal(productResp?.data?.data?.pagination?.items);
            setLoadingList(false);
        } catch (err) {
            Notifications.showError(err);
            setLoadingList(false);
        }
    };

    useEffect(() => {
        getProduct();
    }, [
        pagination,
        productFilters?.textSearch,
        productFilters?.categories,
        productFilters?.branches?.[0],
    ]);

    return (
        <div>
            <div
                className={classNames(
                    { "grid-cols-6": isWide, "grid-cols-4": !isWide },
                    "grid gap-3 mt-3 overflow-auto relative"
                )}
            >
                {productList?.map((product: any, index: number) => (
                    <ProductCard
                        product={product}
                        key={index}
                        addNewProduct={addNewProduct}
                        applyPriceSet={orderMetrics?.applyPriceSet}
                    />
                ))}
                {loadingList && (
                    <div className="absolute w-full pt-10 flex justify-content-center">
                        <Spin />
                    </div>
                )}
            </div>

            <div className="d-table-awesome-component bg-white flex align-items-end">
                <Pagination
                    showTotal={(total: any) => (
                        <div className="captionText">{`Total ${total} items`}</div>
                    )}
                    current={pagination.pageIndex}
                    total={total}
                    className="ant-table-pagination ant-table-pagination-right"
                    pageSizeOptions={SIZE_OPTIONS}
                    onChange={(pageIndex, pageSize) =>
                        setPagination({ pageIndex, pageSize })
                    }
                    pageSize={pagination.pageSize}
                />
            </div>
        </div>
    );
};

export default ProductGrid;
