import { DateInput, InputTextSearch, Button } from "d-react-components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { useParams } from "react-router";

import moment from "moment";
import {
    MaterialPurchaseRequestStatus,
    useDetailMaterialPurchaseRequestLazyQuery,
    useSearchCategoryMaterialLazyQuery,
    useSearchMaterialPurchaseRequestLazyQuery,
} from "../../../api/hooks";
import Messages from "../../../languages/Messages";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import { MaterialPurchaseContext } from "../../../context/material";

const MaterialCreateHeader = () => {
    const { requestId } = useParams();
    const [textSearch, setTextSearch] = useState("");
    const { productFilters, setProductFilters, setMaterialRequest } =
        useContext(MaterialPurchaseContext);
    const [getMaterialPurchaseList] =
        useSearchMaterialPurchaseRequestLazyQuery();
    const [getPurchaseRequest] = useDetailMaterialPurchaseRequestLazyQuery();

    const { values, errors, setFieldValue, setValues } =
        useFormikContext<any>();

    const updateFilters = (key: string, value: any) => {
        setProductFilters({
            ...productFilters,
            [key]: value,
        });
    };

    useEffect(() => {
        if (values?.poRequest?.[0]) {
            getPurchaseRequest({
                variables: {
                    id: values?.poRequest?.[0]?.id,
                },
            }).then((resp) => {
                setMaterialRequest &&
                    setMaterialRequest(resp?.data?.data?.data as any);
            });
        }
    }, [values?.poRequest]);

    useEffect(() => {
        if (requestId) {
            getPurchaseRequest({
                variables: {
                    id: requestId,
                },
            }).then((resp) => {
                setFieldValue("poRequest", [resp?.data?.data?.data]);
                setMaterialRequest &&
                    setMaterialRequest(resp?.data?.data?.data as any);
            });
        }
    }, []);

    return (
        <div>
            <div className="grid grid-cols-6 gap-4 mb-3">
                <div className="col-span-3">
                    <SelectInfinityFilter
                        label={Messages.poRequest}
                        value={values?.poRequest}
                        error={errors?.poRequest}
                        onChange={(value) => setFieldValue("poRequest", value)}
                        fetchFn={(params: any, paging: any) =>
                            getMaterialPurchaseList({
                                variables: {
                                    paginate: {
                                        search: params?.search ?? "",
                                        limit: paging.pageSize,
                                        page: paging.pageIndex,
                                        sort: "materialPurchaseRequestNo",
                                        status: [
                                            MaterialPurchaseRequestStatus.Approved,
                                        ],
                                    },
                                },
                            })
                        }
                        dataKey="data"
                        placeholder={Messages.poRequest}
                        getLabel={(item) => item?.materialPurchaseRequestNo}
                    />
                </div>

                <div className="col-span-3">
                    <DateInput
                        label={Messages.purchaseDate}
                        onChange={(value) => {
                            setFieldValue("purchaseDate", value);
                        }}
                        value={
                            values?.purchaseDate
                                ? moment(values?.purchaseDate)
                                : (null as any)
                        }
                        format="DD/MM/YYYY"
                        error={errors?.date as any}
                        className="mb-3"
                    />
                </div>
            </div>
            <div className="pt-3 border-t">
                <div className="mb-2">
                    <InputTextSearch
                        className="bg-white mb-3"
                        placeholder={Messages.search}
                        value={textSearch}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            setTextSearch(e?.currentTarget?.value)
                        }
                        onSubmit={() => updateFilters("textSearch", textSearch)}
                    />
                </div>
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2">
                        {/* <SelectInfinityFilter
                            value={productFilters?.categories}
                            multiple
                            onChange={(value) =>
                                updateFilters("categories", value)
                            }
                            fetchFn={(params: any, paging: any) =>
                                getCategoryList({
                                    variables: {
                                        paginate: {
                                            search: params?.search ?? "",
                                            limit: paging.pageSize,
                                            page: paging.pageIndex,
                                        },
                                    },
                                })
                            }
                            dataKey="data"
                            placeholder={Messages.categories}
                            getLabel={(item) => `${item?.name}`}
                            showCount
                            tagTitle={Messages.categories}
                            className="w-[250px]"
                        /> */}
                    </div>
                    <div className="col-span-1 flex justify-content-end">
                        <Button
                            onClick={() => updateFilters("viewMode", "grid")}
                            iconName="apps"
                            color={
                                productFilters?.viewMode === "grid"
                                    ? "primary"
                                    : "light"
                            }
                            className="mr-2"
                        />
                        <Button
                            onClick={() => updateFilters("viewMode", "list")}
                            iconName="table_rows"
                            color={
                                productFilters?.viewMode === "list"
                                    ? "primary"
                                    : "light"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialCreateHeader;
