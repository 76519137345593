import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import VendorAPI from "../../../api/queries/vendor";
import { VendorDetailContext } from "../../../context/vendor";
import { IVendor } from "../../../interfaces/vendor";
import Messages from "../../../languages/Messages";
import VendorDetailGeneral from "./VendorDetailGeneral";
import VendorDetailHeader from "./VendorDetailHeader";
import VendorDetailSummaryView from "./VendorDetailSummaryView";
import VendorDetailActivity from "./VendorDetailActivity";
import VendorDetailPurchase from "./VendorDetailPurchase";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <VendorDetailGeneral />,
    },
    {
        id: "purchase",
        label: Messages.purchase,
        component: <VendorDetailPurchase />,
    },

    {
        id: "activity",
        label: Messages.activity,
        component: <VendorDetailActivity />,
    },
];

const VendorDetail = () => {
    const { vendorId } = useParams<any>();
    const [vendor, setVendor] = useState<IVendor>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadVendorDetail();
    }, []);

    const loadVendorDetail = () => {
        Progress.show(
            {
                method: VendorAPI.detail,
                params: [vendorId],
            },
            setVendor as any
        );
    };

    if (!vendor) {
        return <div />;
    }

    const renderInfoRow = (title: any, content: any) => {
        return (
            <div className="d-flex align-items-start w-100 justify-content-between py-3">
                <div className="text-small w-100 font-weight-bold">{title}</div>
                <div className="w-100 text text-end">{content}</div>
            </div>
        );
    };

    return (
        <VendorDetailContext.Provider value={{ vendor, setVendor }}>
            <div className="flex-column-container bg-white">
                <VendorDetailHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <VendorDetailSummaryView />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </VendorDetailContext.Provider>
    );
};

export default VendorDetail;
