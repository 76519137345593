import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
    useGetServiceCategoriesLazyQuery,
    useGetServiceCategoriesQuery,
} from "../../../api/hooks";
import { IServiceCategory } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";

interface IServiceCategorySelect {
    className?: string;
    value: IServiceCategory | IServiceCategory[];
    multiple?: boolean;
    onChange: (value: IServiceCategory | IServiceCategory[]) => void;
    [key: string]: any;
}

const ServiceCategorySelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IServiceCategorySelect) => {
    const [categoryList, setCategoryList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    // const [getCategoryList] = useGetServiceCategoriesLazyQuery();
    const { data } = useGetServiceCategoriesQuery({
        variables: {
            paginate: {
                page: 1,
                limit: 500,
                search: "",
            },
        },
    });

    useEffect(() => {
        if (!isEmpty(data)) {
            setCategoryList(data?.data?.data ?? []);
        }
    }, [data]);

    // const onChangeTextSearch = debounce((textSearch) => {
    //     if (isEmpty(textSearch)) {
    //         setCategoryList([]);
    //     } else {
    //         getCategoryList({
    //             variables: {
    //                 paginate: {
    //                     page: 1,
    //                     limit: 15,
    //                     search: textSearch,
    //                 },
    //             },
    //         }).then((res: any) => {
    //             const categoryRes = res?.data?.data?.data ?? [];
    //             const selectedValue = isArray(value) ? value : [value];
    //             const filterCategory = map(
    //                 [...selectedValue, ...categoryRes],
    //                 (item) => ({
    //                     id: item.id,
    //                     name: item.name,
    //                 })
    //             );
    //             const uniqCat = uniqBy(filterCategory, (item) => item.id);
    //             setCategoryList(uniqCat);
    //         });
    //     }
    // }, 500);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(categoryList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(categoryList, id));
    };

    const categoryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            className={className}
            label={Messages.category}
            dataSource={categoryList}
            getLabel={(item) => item?.name}
            getKey={(item) => item?.id}
            value={categoryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default ServiceCategorySelect;
