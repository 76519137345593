import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    ObjectUtils,
    Progress,
    Select,
    useFirstTime,
} from "d-react-components";
import {
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    map,
    toLower,
} from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import Drawer from "../../../common/Drawer";
import { ServiceConfigContext } from "../../../context/service";

import Messages from "../../../languages/Messages";

import QuantityInputField from "../../quotation/share/QuantityInputField";
import { ServiceConfigAPI } from "../../../api/queries/service";
import { YES_NO_OPTIONS } from "../../../constant/app";
import { IProduct } from "../../../interfaces/product";
import ProductNameView from "../../product/share/ProductNameView";
import ProductAPI from "../../../api/queries/product";
import { TypeOfProduct } from "../../../api/hooks";
import { SERVICE_ADJUSTMENT_CONFIGS } from "../../../constant/service";
import { IMaterial } from "../../../interfaces/material";

interface IMedicineItem extends IProduct {
    hasPermissionAdjustment: string;
    quantity: number;
}

interface IMedicineAddDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IMedicineItem[];
}

interface ITreatmentMedicineUpdateDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IMedicineItem;
}

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

const MedicineAddDrawer = ({
    open,
    onClose,
    defaultValue = [],
}: IMedicineAddDrawer) => {
    const { setConfiguration, configuration } =
        useContext(ServiceConfigContext);
    const [medicineSelected, setMedicineSelected] =
        useState<IMedicineItem[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onAddMedicine = () => {
        const medicineFiltered = filter(
            medicineSelected,
            (item) => !!item.quantity
        );
        const medicines = map(medicineFiltered, (item) => ({
            product: item.id,
            quantity: item.quantity,
            hasPermissionAdjustment: item.hasPermissionAdjustment,
        }));

        Progress.show(
            {
                method: ServiceConfigAPI.addMedicinePreset,
                params: [configuration?.id, medicines],
            },
            (configRes: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                setConfiguration(configRes);
                onClose();
            }
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.adjustAfterTreatment,
            dataIndex: "",
            render: (material: any) => {
                const itemSelected = find(
                    medicineSelected,
                    (item) => item.id === material.id
                );
                if (!itemSelected) return <div />;
                return (
                    <Select
                        className="width-[75px]"
                        getLabel={(item) => Messages[item.label]}
                        value={itemSelected?.hasPermissionAdjustment}
                        dataSource={SERVICE_ADJUSTMENT_CONFIGS}
                        onChange={(value) =>
                            onChangeIsAdjust(value, itemSelected)
                        }
                    />
                );
            },
            width: 200,
        },

        {
            title: Messages.quantity,
            dataIndex: "",
            render: (material: any) => {
                const itemSelected = find(
                    medicineSelected,
                    (item) => item.id === material.id
                );
                if (!itemSelected) return <div />;
                if (itemSelected?.hasPermissionAdjustment === "YES") {
                    return Messages.provideAfterTreatment;
                }
                return (
                    <QuantityInputField
                        quantity={itemSelected?.quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity(quantity, itemSelected)
                        }
                        min={1}
                    />
                );
            },
            width: 200,
        },
    ];

    const onChangeIsAdjust = (
        hasPermissionAdjustment: string,
        medicine: IProduct & { quantity: number }
    ) => {
        const result = ObjectUtils.updateArrayById(medicineSelected as any, {
            ...medicine,
            hasPermissionAdjustment,
            quantity:
                hasPermissionAdjustment === "YES" ? 0 : medicine?.quantity,
        });

        setMedicineSelected(result);
    };

    const onChangeQuantity = (quantity: number, medicine: IProduct) => {
        const result = ObjectUtils.updateArrayById(medicineSelected as any, {
            ...medicine,
            quantity,
        });
        setMedicineSelected(result);
    };

    const source = (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: medicineSelected });
        }
        return ProductAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            typeOfProduct: TypeOfProduct.SimpleProduct,
            isMedicine: true,
            sort: {
                createdAt: -1,
            },
        });
    };

    const onChangeSelectMedicine = (keys: string[], medicines: IProduct[]) => {
        const result = map(medicines, (medicine) => {
            const itemSelected = find(
                medicineSelected,
                (item) => item.id === medicine.id
            );

            if (itemSelected) return itemSelected;
            return { ...medicine, quantity: 1, hasPermissionAdjustment: "YES" };
        });
        setMedicineSelected(result);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.medicine}
            onSave={onAddMedicine}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item) => (
                        <Button
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => res?.data ?? []}
                    rowSelection={{
                        selectedRowKeys: map(
                            medicineSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectMedicine as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

const TreatmentMedicineUpdateDrawer = ({
    open,
    onClose,
    defaultValue,
}: ITreatmentMedicineUpdateDrawer) => {
    const { setConfiguration, configuration } =
        useContext(ServiceConfigContext);
    const [medicine, setMedicine] = useState<IMedicineItem>(defaultValue);

    const onUpdateMedicineTreatment = () => {
        const input = {
            product: medicine?.id,
            quantity: medicine?.quantity,
            hasPermissionAdjustment: medicine?.hasPermissionAdjustment,
        };
        Progress.show(
            {
                method: ServiceConfigAPI.updateMedicinePreset,
                params: [configuration?.id, input],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setConfiguration(res);
                onClose();
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity: any, item) => {
                if (!item) return <div />;
                if (item?.hasPermissionAdjustment === "YES") {
                    return Messages.provideAfterTreatment;
                }
                return (
                    <QuantityInputField
                        quantity={quantity}
                        onChangeQuantity={(quantity: number) =>
                            setMedicine({ ...medicine, quantity })
                        }
                        min={1}
                    />
                );
            },
        },
    ];

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.medicine}
            onSave={onUpdateMedicineTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <AwesomeTableComponent
                    className="mt-3"
                    columns={columns}
                    dataSource={[medicine]}
                    pagination={false}
                />

                <Select
                    className="mt-3"
                    label={Messages.adjustAfterTreatment}
                    getLabel={(item) => Messages[item.label]}
                    value={medicine?.hasPermissionAdjustment}
                    dataSource={SERVICE_ADJUSTMENT_CONFIGS}
                    onChange={(hasPermissionAdjustment) =>
                        setMedicine({
                            ...medicine,
                            hasPermissionAdjustment,
                            quantity:
                                hasPermissionAdjustment === "YES"
                                    ? 0
                                    : medicine?.quantity,
                        })
                    }
                />
            </div>
        </Drawer>
    );
};

const ServiceConfigMedicine = () => {
    const { setConfiguration, configuration } =
        useContext(ServiceConfigContext);

    const [openAdd, setOpenAdd] = useState(false);
    const [updatingMedicine, setUpdatingMedicine] =
        useState<IMedicineItem | null>(null);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
        {
            title: Messages.unit,
            dataIndex: "product",
            render: (product: IProduct) => product?.unit,
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            width: 200,
            render: (quantity: any, item: any) => {
                if (item?.hasPermissionAdjustment === "YES") {
                    return Messages.provideAfterTreatment;
                }
                return quantity;
            },
        },
        {
            title: Messages.adjustAfterTreatment,
            dataIndex: "hasPermissionAdjustment",
            render: (isAdjust: string) => {
                const adjustConfig = find(
                    SERVICE_ADJUSTMENT_CONFIGS,
                    (item) => item.id === isAdjust
                );
                return Messages[adjustConfig?.label as any];
            },
            width: 200,
        },

        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <div className="d-flex">
                    <Icon
                        name="edit"
                        className="cursor-pointer mr-3 text-primary"
                        onClick={() =>
                            setUpdatingMedicine({
                                ...product.product,
                                quantity: product?.quantity,
                                hasPermissionAdjustment:
                                    product?.hasPermissionAdjustment,
                            })
                        }
                    />
                    <Icon
                        name="remove_circle_outline"
                        className="cursor-pointer text-primary"
                        onClick={() => onRemove(product)}
                    />
                </div>
            ),
        },
    ];

    const onRemove = (product: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: ServiceConfigAPI.removeMedicinePreset,
                        params: [configuration?.id, product.product?.id],
                    },
                    (treatmentRes) => {
                        setConfiguration(treatmentRes);
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const medicineDataSource = useMemo(() => {
        if (isEmpty(textSearch)) {
            return configuration?.medicinePreset ?? [];
        }

        return filter(configuration?.medicinePreset ?? [], (item) =>
            includes(
                toLower(item?.product?.name + item?.product?.sku),
                toLower(textSearch)
            )
        );
    }, [textSearch, configuration?.medicinePreset]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <h5>{Messages.medicine}</h5>
                <Button onClick={() => setOpenAdd(true)} className="">
                    {Messages.add}
                </Button>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={medicineDataSource}
                className="mt-3"
                pagination={false}
            />
            {openAdd && (
                <MedicineAddDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    defaultValue={map(
                        configuration?.medicinePreset,
                        (item) => ({
                            ...item.product,
                            hasPermissionAdjustment:
                                item.hasPermissionAdjustment,
                            quantity: item.quantity,
                        })
                    )}
                />
            )}
            {updatingMedicine && (
                <TreatmentMedicineUpdateDrawer
                    defaultValue={updatingMedicine}
                    open={!!updatingMedicine}
                    onClose={() => setUpdatingMedicine(null)}
                />
            )}
        </div>
    );
};

export default ServiceConfigMedicine;
