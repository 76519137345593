import {
    Button,
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { isUndefined, map, pick } from "lodash";
import React, { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import {
    useCreateEquipmentForAdminMutation,
    useDetailEquipmentForAdminLazyQuery,
    useGetEquipmentCategoryListLazyQuery,
    useUpdateEquipmentForAdminMutation,
} from "../../../api/hooks";
import GalleryUpload from "../../../common/gallery/GalleryUpload";
import PermissibleRender from "../../../common/PermissibleComponent";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import {
    EQUIPMENT_CONDITIONS,
    EQUIPMENT_CONFIGURATION,
    EQUIPMENT_VISIBILITIES,
} from "../../../constant/equipment";
import { PERMISSION } from "../../../constant/user";
import { EquipmentSchema } from "../../../formschema/equipment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import EquipmentConfiguration from "../share/EquipmentConfiguration";

const EquipmentCreate = () => {
    const params = useParams();
    const isEdit = !isUndefined(params.equipmentId);
    const [createEquipment] = useCreateEquipmentForAdminMutation();
    const [updateEquipment] = useUpdateEquipmentForAdminMutation();
    const [getListEquipmentCategory] = useGetEquipmentCategoryListLazyQuery();
    const [getEquipmentDetail, { data, loading, refetch }] =
        useDetailEquipmentForAdminLazyQuery();

    const navigate = useNavigate();
    const eForm = useFormik({
        initialValues: {
            configuration: EQUIPMENT_CONFIGURATION.SIMPLE,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: EquipmentSchema,
        onSubmit: (values) => {
            isEdit ? onUpdateEquipment(values) : onCreateEquipment(values);
        },
    });
    const { values, errors, handleSubmit, setFieldValue, setValues } = eForm;

    const onCreateEquipment = (input: any) => {
        const body: any = {
            variables: {
                payload: {
                    name: input?.name,
                    model: input?.model,
                    serialNo: input?.serialNo,
                    visibility: input?.visibility,
                    condition: input?.condition,
                    category: input?.category?.[0]?.id,
                    weight: input?.weight,
                    description: input?.description,
                    configuration: input?.configuration,

                    children: map(input.equipmentsChildNew ?? [], (item) => ({
                        name: item.name,
                        serialNo: item.serialNo,
                        configuration: item.configuration,
                        weight: item.weight,
                        gallery: map(item.gallery, (item) => item.fileUrl),
                    })),
                    childrenId: map(
                        input.equipmentsChild ?? [],
                        (item) => item.id
                    ),
                    gallery: map(input?.gallery ?? [], (item) => item.fileUrl),
                    ...(input?.weight
                        ? { weight: parseFloat(input?.weight) }
                        : {}),
                },
            },
        };

        Progress.show(
            {
                method: createEquipment,
                params: [body],
            },
            (resp: any) => {
                const newEq = resp?.data?.createEquipmentForAdmin?.data;
                navigate(
                    generatePath(Path.EQUIPMENT_DETAIL, {
                        equipmentId: newEq.id,
                    })
                );
                Notifications.showSuccess(Messages.createEquipmentSuccessfully);
            }
        );
    };

    const onUpdateEquipment = (input: any) => {
        const body = {
            variables: {
                id: params.equipmentId,
                payload: {
                    name: input?.name,
                    model: input?.model,
                    serialNo: input?.serialNo,
                    visibility: input?.visibility,
                    condition: input?.condition,
                    category: input?.category?.[0]?.id,
                    weight: input?.weight,
                    description: input?.description,
                    configuration: input?.configuration,
                    children: map(input.equipmentsChildNew ?? [], (item) => ({
                        name: item.name,
                        serialNo: item.serialNo,
                        configuration: item.configuration,
                        weight: item.weight,
                        gallery: map(item.gallery, (item) => item.fileUrl),
                    })),
                    childrenId: map(
                        input.equipmentsChild ?? [],
                        (item) => item.id
                    ),
                    gallery: map(input?.gallery ?? [], (item) => item.fileUrl),
                    // ...(input?.weight
                    //     ? { weight: parseFloat(input?.weight) }
                    //     : {}),
                },
            },
        };

        Progress.show(
            {
                method: updateEquipment,
                params: [body],
            },
            (resp: any) => {
                const newEq = resp?.data?.updateEquipmentForAdmin?.data;
                Notifications.showSuccess(Messages.updateEquipmentSuccessfully);
            }
        );
    };

    useEffect(() => {
        if (params.equipmentId) {
            loadEquipmentDetail();
        }
    }, [params]);

    const loadEquipmentDetail = () => {
        Progress.show(
            {
                method: getEquipmentDetail,
                params: [
                    {
                        variables: {
                            id: params.equipmentId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {
                const detail = res?.data?.detailEquipmentForAdmin?.data;
                setValues({
                    category: detail.category ? [detail.category] : [],
                    gallery: detail.gallery
                        ? map(detail.gallery, (item) => ({ fileUrl: item }))
                        : [],
                    ...pick(detail, [
                        "name",
                        "model",
                        "serialNo",
                        "weight",
                        "visibility",
                        "condition",
                        "description",
                        "configuration",
                    ]),
                });
            }
        );
    };

    return (
        <div>
            <Header
                showCancel={false}
                className="sticky top-0"
                title={
                    isEdit
                        ? data?.detailEquipmentForAdmin?.data?.name ?? ""
                        : Messages.newEquipment
                }
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.CATEGORY_EQUIPMENT.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-3 bg-white">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <InputText
                            label={Messages.name}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                setFieldValue("name", event.target.value)
                            }
                            value={values?.name}
                            error={errors?.name as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.model}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                setFieldValue("model", event.target.value)
                            }
                            value={values?.model}
                            error={errors?.model as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.serialNo}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                setFieldValue("serialNo", event.target.value)
                            }
                            value={values?.serialNo}
                            error={errors?.serialNo as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <SelectInfinityFilter
                            label={Messages.category}
                            value={values?.category}
                            multiple={false}
                            onChange={(value) =>
                                setFieldValue("category", value)
                            }
                            fetchFn={(params: any, paging: any) =>
                                getListEquipmentCategory({
                                    variables: {
                                        paginate: {
                                            search: params?.search ?? "",
                                            limit: paging.pageSize,
                                            page: paging.pageIndex,
                                        },
                                    },
                                })
                            }
                            dataKey="listCategoryEquipmentForAdmin"
                            placeholder={Messages.pleaseSearchAndSelect}
                            getLabel={(item) => item?.name}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.weight}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                setFieldValue("weight", event.target.value)
                            }
                            value={values?.weight}
                            error={errors?.weight as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.visibility}
                            value={values?.visibility}
                            error={errors?.visibility}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={EQUIPMENT_VISIBILITIES}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                setFieldValue("visibility", value)
                            }
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.condition}
                            value={values?.condition}
                            error={errors?.condition}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={EQUIPMENT_CONDITIONS}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                setFieldValue("condition", value)
                            }
                        />
                    </div>
                    <div className="col-span-2">
                        <RichTextTiny
                            label={Messages.description}
                            value={values?.description ?? ""}
                            onChange={(value: any) =>
                                setFieldValue(
                                    `description`,
                                    value.toString("html")
                                )
                            }
                            placeholder={Messages.description}
                            error={errors?.description}
                        />
                    </div>
                    <div className="col-span-2">
                        <GalleryUpload form={eForm} />
                    </div>
                    <div className="col-span-2 ">
                        <div className="label">
                            {Messages.equipmentConfiguration}
                        </div>
                        <div className="p-3 border mt-3">
                            <EquipmentConfiguration form={eForm} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EquipmentCreate;
