import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find, groupBy, join, map, reduce, uniq } from "lodash";
import moment from "moment";
import { useState } from "react";
import { JobCostType, JobTypeAdmin } from "../../api/hooks";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import { ServiceCommissionType } from "../../constant/user";
import { ITreatment } from "../../interfaces/treatment";
import Messages from "../../languages/Messages";
import { exportToCSV } from "../../utils/file";
import { getFullName } from "../../utils/Utils";
import UserSelect from "../user/share/UserSelect";
import ReportFooter from "./ReportFooter";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    user: [],
    timeRange: null,
};

const ReportDoctorDetail = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = (values: any) => {
        const payload = {
            user: map(values.user, (item) => item.id),
            from: values?.timeRange?.[0]?.valueOf(),
            to: values?.timeRange?.[1]?.valueOf(),
            userJobTypes: [JobTypeAdmin.Doctor],
        };
        Progress.show(
            { method: ReportAPI.treatmentUserDetail, params: [payload] },
            loadData as any
        );
    };

    const getMinutesOfTreatment = (treatment: ITreatment) => {
        const jobCostList = treatment?.doctor?.jobCost ?? [];
        const totalTime = reduce(
            jobCostList,
            (sum, jobCost) => sum + (jobCost?.end ?? 0) - (jobCost?.start ?? 0),
            0
        );
        return totalTime;
    };
    const getDoctorFeeOfTreatment = (treatment: ITreatment) => {
        const doctorId = treatment?.doctor?.adminUser?.id;
        const manualDoctorFee =
            treatment?.manualJobCostAssessment?.[
                `${doctorId}&${JobCostType.DoctorFee}`
            ];
        if (manualDoctorFee) {
            return manualDoctorFee?.amount ?? 0;
        }
        const totalAutoAssign = reduce(
            treatment.jobCostAssessment,
            (sum, jobCostAssessment) =>
                sum +
                (jobCostAssessment?.[`${doctorId}&${JobCostType.DoctorFee}`]
                    ?.amount ?? 0),
            0
        );

        return totalAutoAssign;
    };

    const loadData = (dataList: any[]) => {
        const exportData: any = [];
        const firstDayMonth = moment(formValues?.timeRange?.[0]);
        const lastDayMonth = moment(formValues?.timeRange?.[1]);
        const dateRangeText = join(
            [
                firstDayMonth.format("DD/MM/YYYY"),
                lastDayMonth.format("DD/MM/YYYY"),
            ],
            " - "
        );

        const dataGroupByDoctor = groupBy(
            dataList,
            (item) => item?.doctor?.adminUser?.id
        );
        const doctorIds = Object.keys(dataGroupByDoctor);

        console.log("doctorIds", doctorIds);
        try {
            for (let index = 0; index < doctorIds.length; index += 1) {
                const doctorId = doctorIds[index];
                const doctorTreatments = dataGroupByDoctor[doctorId];
                const doctor = doctorTreatments?.[0]?.doctor?.adminUser;
                const totalMinutes = reduce(
                    doctorTreatments,
                    (sum, item) => sum + getMinutesOfTreatment(item),
                    0
                );
                const totalServices = reduce(
                    doctorTreatments,
                    (sum, item) => sum + item?.services?.length,
                    0
                );
                const totalDoctorFee = reduce(
                    doctorTreatments,
                    (sum, item) => sum + getDoctorFeeOfTreatment(item),
                    0
                );
                exportData.push({
                    "Report Date": dateRangeText,
                    "Name of doctor": getFullName(doctor),
                    "EmployeeID of doctor": doctor?.companyId,
                    "Type of money": "",
                    Date: "",
                    Branch: "",
                    "Treatment no.": "",
                    Type: "",
                    CN: "",
                    "Customer name": "",
                    Treatment: "",
                    "Minutes of treatment": totalMinutes,
                    "Quantity of treatment": totalServices,
                    "Sale price": "",
                    "Quantity For Fee Calc.": "",
                    "Calculated from the total": "",
                    "Doctor fee": totalDoctorFee,
                });

                for (
                    let treatmentIndex = 0;
                    treatmentIndex < doctorTreatments.length;
                    treatmentIndex += 1
                ) {
                    const treatment: ITreatment =
                        doctorTreatments[treatmentIndex];
                    const {
                        day,
                        branch,
                        treatmentNo,
                        services,
                        customer,
                        PIC,
                        doctorOrder,
                    } = treatment;

                    const serviceTypes = uniq(
                        map(
                            services,
                            (item) => item?.productFull?.category?.name
                        )
                    );

                    const serviceTypeText = join(serviceTypes, ", ");
                    const serviceNameText = join(
                        map(services, (item) => item.productFull?.name),
                        ", "
                    );
                    const serviceSalePriceText = join(
                        map(services, (service) => {
                            const orderProduct = find(
                                doctorOrder?.order?.products,
                                (product: any) =>
                                    product?.product?.id ===
                                    service?.productFull?.id
                            );
                            return StringUtils.moneyThaiFormat(
                                orderProduct?.salePrice ?? 0
                            );
                        }),
                        ", "
                    );
                    const serviceQuantityText = join(
                        map(services, (service) => {
                            const orderProduct = find(
                                doctorOrder?.order?.products,
                                (product: any) =>
                                    product?.product?.id ===
                                    service?.productFull?.id
                            );
                            return `${orderProduct?.quantity ?? 0}`;
                        }),
                        ", "
                    );

                    const serviceCommissionText = join(
                        map(services, (service) => {
                            const orderProduct = find(
                                doctorOrder?.order?.products,
                                (product: any) =>
                                    product?.product?.id ===
                                    service?.productFull?.id
                            );
                            const productPrice =
                                (orderProduct?.quantity ?? 0) *
                                (orderProduct?.salePrice ?? 0);

                            const doctorCommissionServices =
                                doctor?.adminUser?.commissionService ?? [];

                            const doctorCommissionService = find(
                                doctorCommissionServices,
                                (item) =>
                                    item?.service?.id ===
                                    service?.productFull?.service?.id
                            );
                            if (
                                doctor?.adminUser?.commissionServiceType ===
                                    ServiceCommissionType.DEFAULT ||
                                !doctorCommissionService
                            ) {
                                return (
                                    (productPrice *
                                        (service?.productFull?.service
                                            ?.defaultSaleCommission ?? 0)) /
                                    100
                                );
                            }
                            return `${
                                (productPrice *
                                    (doctorCommissionService?.value ?? 0)) /
                                100
                            }`;
                        }),
                        ", "
                    );

                    console.log("serviceSalePriceText", serviceSalePriceText);

                    const isDoctorInPic = !!find(
                        PIC,
                        (pic) => pic.adminUser?.id === doctor?.id
                    );
                    const typeOfMoney = isDoctorInPic ? "Fee" : "Commission";

                    exportData.push({
                        "Report Date": "",
                        "Name of doctor": "",
                        "EmployeeID of doctor": "",
                        "Type of money": typeOfMoney,
                        Date: day,
                        Branch: branch?.name,
                        "Treatment no.": treatmentNo,
                        Type: serviceTypeText,
                        CN: customer?.customerNo,
                        "Customer name": customer?.fullNameEn,
                        Treatment: serviceNameText,
                        "Minutes of treatment":
                            getMinutesOfTreatment(treatment),
                        "Quantity of treatment": services?.length,
                        "Sale price": serviceSalePriceText,
                        "Quantity For Fee Calc.": serviceQuantityText,
                        "Calculated from the total": serviceCommissionText,
                        "Doctor fee": getDoctorFeeOfTreatment(treatment),
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
        // for (let index = 0; index < dataList.length; index += 1) {
        //     const dataItem = dataList[index];

        //     exportData.push({
        //         "Report Date": dateRangeText,
        //         Doctor: getFullName(dataItem?.doctor),
        //         "Employee ID": dataItem?.doctor?.companyId,
        //         "Total treatment joined (qty.)": dataItem?.totalTreatmentJoined,
        //         "Total treatment joined (hours)":
        //             dataItem?.totalHourTreatmentJoined,
        //         "Total Doctor Fee (THB)": dataItem?.totalDoctorFee,
        //     });
        // }

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Summary Doctor Report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <UserSelect
                    params={{ jobType: JobTypeAdmin.Doctor }}
                    className={classNameInput}
                    value={formValues?.user}
                    onChange={(value) =>
                        exportForm.setFieldValue("user", value)
                    }
                    multiple
                    label={Messages.doctor}
                />
            </div>
        );
    };

    //                 Date: "",
    //                 Branch: "",
    //                 "Treatment no.": "",
    //                 Type: "",
    //                 CN: "",
    //                 "Customer name": "",
    //                 Treatment: "",
    //                 "Minutes of treatment": "",
    //                 "Quantity of treatment": "",
    //                 "Doctor fee": "",
    const columns: IColumnsProps = [
        {
            title: "Report Date",
            dataIndex: "Report Date",
        },
        {
            title: "Name of doctor",
            dataIndex: "Name of doctor",
        },
        {
            title: "EmployeeID of doctor",
            dataIndex: "EmployeeID of doctor",
        },
        {
            title: "Type of money",
            dataIndex: "Type of money",
        },
        {
            title: "Date",
            dataIndex: "Date",
        },
        {
            title: "Branch",
            dataIndex: "Branch",
        },
        {
            title: "Treatment no.",
            dataIndex: "Treatment no.",
        },
        {
            title: "Type",
            dataIndex: "Type",
            width: 200,
        },
        {
            title: "CN",
            dataIndex: "CN",
        },
        {
            title: "Customer name",
            dataIndex: "Customer name",
        },
        {
            title: "Treatment",
            dataIndex: "Treatment",
            width: 300,
        },
        {
            title: "Minutes of treatment",
            dataIndex: "Minutes of treatment",
            width: 200,
        },
        {
            title: "Quantity of treatment",
            dataIndex: "Quantity of treatment",
            width: 200,
        },
        {
            title: "Sale price",
            dataIndex: "Sale price",
            width: 200,
        },
        {
            title: "Quantity For Fee Calc.",
            dataIndex: "Quantity For Fee Calc.",
            width: 200,
        },
        {
            title: "Calculated from the total",
            dataIndex: "Calculated from the total",
            width: 200,
        },
        {
            title: "Doctor fee",
            dataIndex: "Doctor fee",
            width: 200,
            render: StringUtils.moneyThaiFormat,
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                baseColumnProps={{ width: 100 }}
                columns={columns}
                dataSource={dataTable}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.doctorDetailReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={!formValues?.timeRange?.length}
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportDoctorDetail;
