import { Icon, Progress, TimeUtils } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import VoucherAPI from "../../../api/queries/voucher";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { VoucherDetailContext } from "../../../context/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import VoucherDetailActivity from "./VoucherDetailActivity";
import VoucherDetailGeneralInfo from "./VoucherDetailGeneralInfo";
import VoucherDetailHeader from "./VoucherDetailHeader";
import VoucherDetailOwnerStatus from "./VoucherDetailOwnerStatus";
import VoucherDetailRemark from "./VoucherDetailRemark";
import VoucherDetailServices from "./VoucherDetailServices";
import VoucherUsageStatus from "./VoucherUsageStatus";

const VoucherDetail = () => {
    const [voucher, setVoucher] = useState<IVoucher>();
    const { voucherId } = useParams();

    useEffect(() => {
        loadOrderDetail();
    }, []);

    const loadOrderDetail = () => {
        Progress.show(
            { method: VoucherAPI.detail, params: [voucherId] },
            setVoucher as any
        );
    };

    if (!voucher) return <div />;
    return (
        <VoucherDetailContext.Provider value={{ voucher, setVoucher } as any}>
            <VoucherDetailHeader />
            <div className="p-3">
                <div className="border p-3 flex-row-between-center">
                    <div className="d-flex">
                        <div className="d-flex">
                            <Icon name="event" />
                            <small className="ml-1">{`${
                                Messages.createdOn
                            }: ${TimeUtils.toDateTime(
                                voucher?.createdAt
                            )}`}</small>
                        </div>
                        <div className="d-flex ml-2">
                            <Icon name="alarm" />
                            <small className="ml-1">{`${Messages.expiryOn}: ${
                                voucher?.expiryDate
                                    ? TimeUtils.toDateTime(voucher?.expiryDate)
                                    : Messages.never
                            }`}</small>
                        </div>
                    </div>
                    <UserAvatarName user={voucher.createByAdmin} />
                </div>
                <VoucherDetailGeneralInfo />
                <VoucherDetailServices />
                <VoucherDetailRemark />
                <VoucherDetailOwnerStatus />
                <VoucherUsageStatus />
                <VoucherDetailActivity />
            </div>
        </VoucherDetailContext.Provider>
    );
};

export default VoucherDetail;
