import CustomerAPI from "../../api/queries/customer";
import FileValidationAndImport from "./FileValidationAndImport";
import ImportResult from "./ImportResult";
import SelectCSVFile from "./SelectCSVFile";
import SelectImportMethod from "./SelectImportMethod";

export enum ImportMethod {
    UPDATE = "UPDATE",
    CREATE = "CREATE",
}

export const IMPORT_CUSTOMER_STEPS = [
    {
        id: "selectImportMethod",
        title: "selectImportMethod",
        desc: "selectImportMethodDesc",
        component: <SelectImportMethod />,
    },
    {
        id: "selectCSVFile",
        title: "selectCSVFile",
        component: <SelectCSVFile />,
    },
    {
        id: "validation",
        title: "fileValidationAndImport",
        component: <FileValidationAndImport />,
    },
    { id: "import", title: "importResult", component: <ImportResult /> },
];

export const IMPORT_CUSTOMER_FIELDS = [
    {
        label: "branchId",
        key: "branchId",
        required: false,
        type: "text",
        name: "branch_id",
    },
    {
        label: "customerNo",
        key: "customerNo",
        required: true,
        type: "text",
        name: "customer_no",
    },
    {
        label: "title",
        key: "title",
        required: false,
        type: "text",
        name: "title",
    },
    {
        label: "firstNameTh",
        key: "firstNameTh",
        required: false,
        type: "text",
        name: "first_name_th",
    },
    {
        label: "lastNameTh",
        key: "lastNameTh",
        required: false,
        type: "text",
        name: "last_name_th",
    },
    {
        label: "firstNameEn",
        key: "firstNameEn",
        required: false,
        type: "text",
        name: "first_name_en",
    },
    {
        label: "lastNameEn",
        key: "lastNameEn",
        required: false,
        type: "text",
        name: "last_name_en",
    },
    {
        label: "nickname",
        key: "nickname",
        required: false,
        type: "text",
        name: "nickname",
    },
    {
        label: "gender",
        key: "gender",
        required: false,
        type: "text",
        name: "gender",
    },
    {
        label: "birthDay",
        key: "birthDay",
        required: false,
        type: "text",
        name: "birth_day",
    },
    {
        label: "citizenId",
        key: "citizenId",
        required: false,
        type: "text",
        name: "citizen_id",
    },
    {
        label: "nationality",
        key: "nationality",
        required: false,
        type: "text",
        name: "nationality",
    },
    {
        label: "passportNo",
        key: "passportNo",
        required: false,
        type: "text",
        name: "passport_no",
    },
    {
        label: "passportExp",
        key: "passportExp",
        required: false,
        type: "text",
        name: "passport_expired",
    },
    {
        label: "religion",
        key: "religion",
        required: false,
        type: "text",
        name: "religion",
    },
    {
        label: "numberOfChild",
        key: "numberOfChild",
        required: false,
        type: "text",
        name: "number_of_child",
    },
    {
        label: "phoneCodeCountry",
        key: "phoneCodeCountry",
        required: false,
        type: "text",
        name: "phone_code_country",
    },
    {
        label: "phone",
        key: "phone",
        required: false,
        type: "text",
        name: "phone",
    },
    {
        label: "alternativePhone",
        key: "alternativePhone",
        required: false,
        type: "text",
        name: "alternative_phone",
    },
    {
        label: "email",
        key: "email",
        required: false,
        type: "text",
        name: "email",
    },
    {
        label: "lineId",
        key: "lineId",
        required: false,
        type: "text",
        name: "lineId",
    },
    {
        label: "instagram",
        key: "instagram",
        required: false,
        type: "text",
        name: "instagram",
    },
    {
        label: "facebook",
        key: "facebook",
        required: false,
        type: "text",
        name: "facebook",
    },
    {
        label: "familyInfo",
        key: "familyInformation",
        required: false,
        type: "text",
        name: "family_information",
    },
    {
        label: "emergencyContact",
        key: "emergencyContact",
        required: false,
        type: "text",
        name: "emergency_contact",
    },
    {
        label: "isVip",
        key: "isVip",
        required: false,
        type: "text",
        name: "is_vip",
    },
    {
        label: "referrerSalePerson",
        key: "salePerson",
        required: false,
        type: "text",
        name: "referrer_sale_person",
    },
    {
        label: "referrerAgency",
        key: "agency",
        required: false,
        type: "text",
        name: "referrer_agency",
    },
    {
        label: "height",
        key: "height",
        required: false,
        type: "text",
        name: "height",
    },
    {
        label: "weight",
        key: "weight",
        required: false,
        type: "text",
        name: "weight",
    },
    {
        label: "bloodGroup",
        key: "bloodGroup",
        required: false,
        type: "text",
        name: "blood_group",
    },
    {
        label: "bloodPressure",
        key: "bloodPressure",
        required: false,
        type: "text",
        name: "blood_pressure",
    },
    {
        label: "allergyHistory",
        key: "allergyHis",
        required: false,
        type: "text",
        name: "allergy_history",
    },
    {
        label: "underlyingDisease",
        key: "underDisease",
        required: false,
        type: "text",
        name: "under_disease",
    },
    {
        label: "surgeryHistory",
        key: "surgeryHis",
        required: false,
        type: "text",
        name: "surgery_history",
    },
];

export const IMPORT_CUSTOMER_METHODS = [
    { id: ImportMethod.UPDATE, label: "updateCustomer" },
    { id: ImportMethod.CREATE, label: "addNewCustomer" },
];

export enum ImportType {
    // PRODUCT = "product",
    CUSTOMER = "customer",
}

export const IMPORT_DATA = {
    [ImportType.CUSTOMER]: {
        steps: IMPORT_CUSTOMER_STEPS,
        methods: IMPORT_CUSTOMER_METHODS,
        title: "importCustomer",
        fields: IMPORT_CUSTOMER_FIELDS,
        apiValidate: CustomerAPI.validateImportCustomer,
        apiImport: CustomerAPI.importCustomer,
    },
};

export const IMPORT_SAMPLE_DATA = {
    [ImportType.CUSTOMER]: [
        {
            branch_id: "1",
            customer_no: "CL2403120099",
            title: "Mr",
            first_name_th: "Test First Name TH",
            last_name_th: "Test Last Name TH",
            first_name_en: "Test First Name EN",
            last_name_en: "Test Last Name EN",
            nickname: "nickname",
            gender: "MALE",
            birth_day: "01-01-1990",
            citizen_id: "1234567890123",
            nationality: "TH",
            passport_no: "A12345",
            passport_expired: "01-01-2030",
            religion: "Buddists",
            number_of_child: "1",
            phone_code_country: "+66",
            phone: "0111111111",
            alternative_phone: "0222222222",
            email: "test@dermaster.com",
            lineId: "dermaster",
            instagram: "dermaster",
            facebook: "dermaster",
            family_information:
                '[{"relationship": "Father", "phone": "093432535", "name": "MrD", "date_of_birth": "23-05-1970"}]',
            emergency_contact:
                '[{"relationship": "Father", "phone": "093432535", "name": "MrD", "residence": "th"}, {"relationship": "Mother", "phone": "093432535", "name": "MsD", "residence": "th"}]',
            is_vip: "TRUE",
            referrer_sale_person: "trung",
            referrer_agency: "AGC23053134555",
            height: "170",
            weight: "50",
            blood_group: "A+",
            blood_pressure: "50",
            allergy_history: "Allergy History",
            under_disease: "Underlying Disease",
            surgery_history:
                '[{"surgery": "surgery", "hospital": "H", "date": "23-05-1980"}]',
        },
        {
            branch_id: "2",
            customer_no: "CL2403120100",
            title: "Mr",
            first_name_th: "Test First Name TH",
            last_name_th: "Test Last Name TH",
            first_name_en: "Test First Name EN",
            last_name_en: "Test Last Name EN",
            nickname: "nickname",
            gender: "MALE",
            birth_day: "01-01-1990",
            citizen_id: "1234567890123",
            nationality: "TH",
            passport_no: "A12345",
            passport_expired: "01-01-2030",
            religion: "Buddists",
            number_of_child: "1",
            phone_code_country: "+66",
            phone: "0111111111",
            alternative_phone: "0222222222",
            email: "test@dermaster.com",
            lineId: "dermaster",
            instagram: "dermaster",
            facebook: "dermaster",
            family_information:
                '[{"relationship": "Father", "phone": "093432535", "name": "MrD", "date_of_birth": "23-05-1970"}]',
            emergency_contact:
                '[{"relationship": "Father", "phone": "093432535", "name": "MrD", "residence": "th"}, {"relationship": "Mother", "phone": "093432535", "name": "MsD", "residence": "th"}]',
            is_vip: "TRUE",
            referrer_sale_person: "trung",
            referrer_agency: "AGC23053134555",
            height: "170",
            weight: "50",
            blood_group: "A+",
            blood_pressure: "50",
            allergy_history: "Allergy History",
            under_disease: "Underlying Disease",
            surgery_history:
                '[{"surgery": "surgery", "hospital": "H", "date": "23-05-1980"}]',
        },
    ],
};
