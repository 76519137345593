import {
    AvatarName,
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import AgencyAPI from "../../../api/queries/agency";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { AGENCY_STATUSES } from "../../../constant/agency";

import Messages from "../../../languages/Messages";
import Path from "../../Path";

const AgencyTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const columns: IColumnsProps = [
        {
            title: Messages.agencyId,
            dataIndex: "agencyNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.AGENCY_DETAIL, {
                        agencyId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.agency,
            dataIndex: "",
            render: (item) => (
                <AvatarName
                    variant="square"
                    size="x-small"
                    user={{ name: item?.name, avatar: item?.picture }}
                />
            ),
        },
        {
            title: Messages.type,
            dataIndex: "typeOfAgency",
        },
        {
            title: Messages.countryOfOrigin,
            dataIndex: "countryOfOrigin",
            render: (countryOfOrigin, item) => countryOfOrigin?.name ?? "N/A",
        },
        {
            title: Messages.phoneNo,
            dataIndex: "phone",
            render: (phone, item) => phone ?? "N/A",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={AGENCY_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },

        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user, item) => (
                <UserAvatarName size="x-small" user={user} />
            ),
        },

        // {
        //     title: Messages.action,
        //     dataIndex: "",
        //     align: "center",
        //     render: (config: any) => {
        //         return (
        //             <TableAction
        //                 onClick={(actionId: string) =>
        //                     onClickTableAction(actionId, config)
        //                 }
        //             />
        //         );
        //     },
        // },
    ];

    const source = (pagingData: any) => {
        return AgencyAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            sort: {
                createdAt: -1,
            },
        });
    };

    const onClickTableAction = (actionId: string, config: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.SERVICE_CONFIGURATION_DETAIL, {
                        configId: config.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.agencyManagement}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                onClickNew={() => navigate(Path.AGENCY_CREATE)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default AgencyTable;
