import { TimeUtils, ViewLabelStatus } from "d-react-components";
import ClassNames from "classnames";
import { useContext } from "react";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { SERVICE_CONFIG_STATUSES } from "../../../constant/service";
import { ServiceConfigContext } from "../../../context/service";
import Messages from "../../../languages/Messages";

const ServiceConfigurationDetailInfo = () => {
    const { configuration } = useContext(ServiceConfigContext);

    const {
        name,
        serviceNo,
        procedureTime,
        createByAdmin,
        createdAt,
        updatedAt,
        status,
    } = configuration ?? {};

    const renderInfoRow = (
        title: any,
        content: any,
        options?: { showBorder?: boolean }
    ) => {
        const { showBorder = true } = options || {};

        return (
            <div
                className={ClassNames("flex-row-between-center  py-3 w-100", {
                    "border-bottom": showBorder,
                })}
            >
                <div className="text-bold">{title}</div>
                <div className="text">{content}</div>
            </div>
        );
    };

    return (
        <div className="w-100 bg-white">
            <div className="flex-column flex-center border py-5 px-4">
                <img src="/images/contract.png" className="image-reg-large" />
                <h4 className="text-primary mt-2">{name}</h4>
                <ViewLabelStatus
                    status={status}
                    listStatus={SERVICE_CONFIG_STATUSES}
                    className="mt-3"
                    getLabel={(item) => Messages[item.label]}
                />
            </div>
            <div className="flex-column flex-center border py-3 px-4">
                {renderInfoRow(Messages.id, serviceNo)}
                {renderInfoRow(Messages.noUpTask, "")}
                {renderInfoRow(Messages.procedureTime, procedureTime)}
                {renderInfoRow(
                    Messages.createdBy,
                    <UserAvatarName user={createByAdmin} size="x-small" />
                )}
                {renderInfoRow(Messages.createdAt, TimeUtils.toDate(createdAt))}
                {renderInfoRow(
                    Messages.lastUpdated,
                    TimeUtils.toDate(updatedAt),
                    { showBorder: false }
                )}
            </div>
        </div>
    );
};

export default ServiceConfigurationDetailInfo;
