import { gql } from "@apollo/client";
import API from "../API";
import {
    F_STOCK_ADJUSTMENT,
    F_STOCK_ADJUSTMENTS,
} from "../fragments/stock-adjustment";

export const getStockAdjustmentList = gql`
    ${F_STOCK_ADJUSTMENTS}
    query getStockAdjustmentList(
        $input: ListAdminProductStockAdjustmentRequest!
    ) {
        data: listProductStockAdjustmentForAdmin(paginate: $input) {
            ...F_STOCK_ADJUSTMENTS
        }
    }
`;

export const getStockAdjustmentDetail = gql`
    ${F_STOCK_ADJUSTMENT}
    query getStockAdjustmentDetail($id: String!) {
        data: detailProductStockAdjustmentForAdmin(id: $id) {
            data {
                ...F_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const createStockAdjustment = gql`
    ${F_STOCK_ADJUSTMENT}
    mutation createStockAdjustment(
        $payload: CreateAdminProductStockAdjustmentRequest!
    ) {
        data: createProductStockAdjustmentForAdmin(payload: $payload) {
            data {
                ...F_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const updateStockAdjustment = gql`
    ${F_STOCK_ADJUSTMENT}
    mutation updateStockAdjustment(
        $id: String!
        $payload: UpdateAdminProductStockAdjustmentRequest!
    ) {
        data: updateProductStockAdjustmentForAdmin(id: $id, payload: $payload) {
            data {
                ...F_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const approveStockAdjustment = gql`
    ${F_STOCK_ADJUSTMENT}
    mutation approveStockAdjustment($id: String!, $remark: String!) {
        data: approveProductStockAdjustmentForAdmin(id: $id, remark: $remark) {
            data {
                ...F_STOCK_ADJUSTMENT
            }
        }
    }
`;

export const cancelStockAdjustment = gql`
    ${F_STOCK_ADJUSTMENT}
    mutation cancelStockAdjustment($id: String!, $remark: String!) {
        data: cancelProductStockAdjustmentForAdmin(id: $id, remark: $remark) {
            data {
                ...F_STOCK_ADJUSTMENT
            }
        }
    }
`;

const stockAdjustmentListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const stockAdjustmentConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

const StockAdjustmentAPI = {
    list: async (input: any) =>
        API.withConverter(stockAdjustmentListConverter).query({
            query: getStockAdjustmentList,
            variables: { input },
        }),

    detail: async (id: any) =>
        API.withConverter(stockAdjustmentConverter).query({
            query: getStockAdjustmentDetail,
            variables: { id },
        }),

    create: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: createStockAdjustment,
            variables: { payload },
        }),

    update: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: updateStockAdjustment,
            variables: { payload },
        }),

    approve: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: approveStockAdjustment,
            variables: { ...payload },
        }),

    cancel: async (payload: any) =>
        API.withConverter(stockAdjustmentConverter).mutate({
            mutation: cancelStockAdjustment,
            variables: { ...payload },
        }),
};

export default StockAdjustmentAPI;
