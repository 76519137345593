import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import Messages from "../../../../languages/Messages";
import StockTransferActivity from "./StockTransferActivity";
import StockTransferHeader from "./StockTransferHeader";
import StockTransferSummary from "./StockTransferSummary";
import StockTransferGeneral from "./StockTransferGeneral";
import StockTransferItems from "./StockTransferItems";
import StockTransferAPI from "../../../../api/queries/stock-transfer";
import StockTransferRequest from "./StockTransferRequest";
import StockTransferOrigin from "./StockTransferOrigin";
import StockTransferDestination from "./StockTransferDestination";
import StockTransferDelivery from "./StockTransferDelivery";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <StockTransferGeneral />,
    },
    {
        id: "items",
        label: Messages.items,
        component: <StockTransferItems />,
    },
    {
        id: "deliveries",
        label: Messages.deliveries,
        component: <StockTransferDelivery />,
    },
    {
        id: "origin",
        label: Messages.origin,
        component: <StockTransferOrigin />,
    },
    {
        id: "destination",
        label: Messages.destination,
        component: <StockTransferDestination />,
    },
    {
        id: "stockRequest",
        label: Messages.stockRequest,
        component: <StockTransferRequest />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <StockTransferActivity />,
    },
];

const StockTransferDetail = () => {
    const { transferId } = useParams<any>();
    const [stockTransfer, setStockTransfer] = useState<any>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadTransferDetail();
    }, []);

    const loadTransferDetail = () => {
        Progress.show(
            {
                method: StockTransferAPI.detail,
                params: [transferId],
            },
            setStockTransfer as any
        );
    };

    if (!stockTransfer) {
        return <div />;
    }

    return (
        <StockTransferDetailContext.Provider
            value={{
                stockTransfer,
                setStockTransfer,
                loadTransferDetail,
            }}
        >
            <div className="flex-column-container bg-white">
                <StockTransferHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <StockTransferSummary />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </StockTransferDetailContext.Provider>
    );
};

export default StockTransferDetail;
