import { Button, Notifications, Progress } from "d-react-components";
import { map, pick } from "lodash";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import {
    DeliveryConfirmationStatus,
    DeliveryTransferPurpose,
} from "../../../api/hooks";
import DeliveryDriverAPI from "../../../api/queries/delivery-driver";
import { DeliveryDriverDetailContext } from "../../../context/delivery-driver";
import Messages from "../../../languages/Messages";
import DeliveryConfirmationView from "../shared/DeliveryConfirmationView";
import DeliveryConfirmDrawer, {
    IDeliveryConfirmationInput,
} from "../shared/DeliveryConfirmDrawer";

export interface IDeliveryDriverConfirmationProps {
    [key: string]: any;
}

const DeliveryDriverConfirmation: React.FC<
    IDeliveryDriverConfirmationProps
> = ({ id }) => {
    const { deliveryId } = useParams<any>();
    const { delivery, loadDeliveryDetail } = useContext(
        DeliveryDriverDetailContext
    );
    const { originConfirmation } = delivery || {};
    const [openConfirmDrawer, setOpenConfirmDrawer] = useState(false);

    const onConfirmOrigin = (values: IDeliveryConfirmationInput) => {
        const { receiver, giver } = values;
        const input = {
            ...(delivery?.transferFor ===
            DeliveryTransferPurpose.EquipmentTransfer
                ? { deliveryId }
                : {}),
            ...(delivery?.transferFor === DeliveryTransferPurpose.StockTransfer
                ? { id: deliveryId }
                : {}),
            payload: {
                staff: { ...pick(giver, ["signature", "fullName"]) },
                driver: {
                    ...pick(receiver, ["signature", "remark"]),
                    proofs:
                        receiver?.proofs && receiver?.proofs?.length > 0
                            ? map(receiver?.proofs, (i) => i?.fileUrl)
                            : null,
                },
            },
        };
        const confirmAPI =
            delivery?.transferFor === DeliveryTransferPurpose.EquipmentTransfer
                ? DeliveryDriverAPI.originConfirmEquipment
                : DeliveryDriverAPI.originConfirmStock;
        return Progress.show({ method: confirmAPI, params: [input] }, (res) => {
            Notifications.showSuccess(Messages.confirmSuccess);
            loadDeliveryDetail();
        });
    };

    const renderReceiveView = () => {
        return (
            <div>
                <Button onClick={() => setOpenConfirmDrawer(true)}>
                    {Messages.confirmReceiveAll}
                </Button>
            </div>
        );
    };

    const renderConfirmView = () => {
        const { driver, staff, status } = originConfirmation || {};

        return (
            <DeliveryConfirmationView
                status={status}
                giver={staff}
                receiver={driver}
                delivery={delivery}
            />
        );
    };

    return (
        <div className="py-3 px-4 bg-white border mt-3">
            <h5 className="mb-3">{Messages.originPickupConfirmation}</h5>
            {![DeliveryConfirmationStatus.Completed].includes(
                originConfirmation?.status ?? ("" as any)
            ) && renderReceiveView()}
            {[DeliveryConfirmationStatus.Completed].includes(
                originConfirmation?.status ?? ("" as any)
            ) && renderConfirmView()}
            {openConfirmDrawer && (
                <DeliveryConfirmDrawer
                    open={openConfirmDrawer}
                    onClose={() => setOpenConfirmDrawer(false)}
                    onSave={(values) => {
                        onConfirmOrigin(values);
                    }}
                />
            )}
        </div>
    );
};

export default DeliveryDriverConfirmation;
