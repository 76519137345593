import * as Yup from "yup";
import Messages from "../languages/Messages";

export const ServiceCategorySchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    description: Yup.string().required("Required field!"),
});

export const ServiceConfigSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    // description: Yup.string().required("Required field!"),
    // procedureTime: Yup.string().required("Required field!"), //  not required as request
    jobCostTemplate: Yup.string().required("Required field!"),
});

export const ServiceJobCostSchema = Yup.object().shape({
    // name: Yup.string().required("Required field!"),
    // unit: Yup.string().required("Required field!"),
    // quantity: Yup.number().required("Required field!"),
});
