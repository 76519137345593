import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import {
    filter,
    find,
    forEach,
    groupBy,
    includes,
    join,
    map,
    reduce,
    uniq,
    uniqBy,
} from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import Messages from "../../languages/Messages";
import { exportToCSV } from "../../utils/file";
import UserSelect from "../user/share/UserSelect";
import ReportFooter from "./ReportFooter";
import {
    ORDER_CONFIRMED_STATUSES,
    ORDER_PENDING_CONFIRM_STATUSES,
} from "../../constant/order";
import { getFullName } from "../../utils/Utils";
import { PRODUCT_CATEGORY_COMMISSION_TYPES } from "../../constant/product";

export const EXPORT_STEPS = [
    { id: "input", title: "input" },
    { id: "export", title: "export" },
];

export const INIT_EXPORT_FORM = {
    branch: [],
    timeRange: [],
};

const ReportSalespersonUser = () => {
    const [mode, setMode] = useState(EXPORT_MODE.INPUT);
    const [dataTable, setDataTable] = useState([]);

    const COLUMNS: IColumnsProps = [
        {
            title: Messages.salesperson,
            dataIndex: "salesPerson",
        },
        {
            title: Messages.reportDate,
            dataIndex: "date",
        },
        {
            title: Messages.branch,
            dataIndex: "branch",
        },
        {
            title: Messages.totalSaleAmountConfirmed,
            dataIndex: "totalConfirmed",
            render: StringUtils.moneyFormat,
            width: 200,
        },
        {
            title: Messages.totalPendingAmountConfirm,
            dataIndex: "totalPendingConfirm",
            render: StringUtils.moneyFormat,
            width: 200,
        },
        {
            title: Messages.totalCustomerServed,
            dataIndex: "totalCustomer",
            width: 150,
        },
        ...map(PRODUCT_CATEGORY_COMMISSION_TYPES, (type) => ({
            title: Messages[type.label],
            dataIndex: type.id,
            width: 150,
        })),
    ];

    const [dataColumn, setDataColumn] = useState(COLUMNS);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            const payload = {
                user: map(values.user, (item) => item.id),
                from: values?.timeRange?.[0]?.valueOf(),
                to: values?.timeRange?.[1]?.valueOf(),
            };
            onExportReport(payload);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onExportReport = (payload: any) => {
        Progress.show(
            { method: ReportAPI.salespersonByUser, params: [payload] },
            exportCSV as any
        );
    };

    const getAllCategoryByOrders = (orders: any[]) => {
        const allCat = [];
        for (let index = 0; index < orders.length; index += 1) {
            const order = orders[index];
            for (
                let indexProducts = 0;
                indexProducts < order?.products.length;
                indexProducts += 1
            ) {
                const product = order?.products[indexProducts];
                if (product?.product?.category) {
                    allCat.push(product?.product?.category);
                }
            }
        }
        const allUniqCategory = uniqBy(allCat, (item) => item.id);
        return allUniqCategory;
    };

    const getCategoryReportByOrders = (commissionType: any, orders: any) => {
        let categoryTotal = 0;
        for (let index = 0; index < orders.length; index += 1) {
            const order = orders[index];
            for (
                let indexProducts = 0;
                indexProducts < order?.products.length;
                indexProducts += 1
            ) {
                const product = order?.products[indexProducts];
                const numberOfSalesPerson = uniqBy(
                    product?.salesPerson,
                    (item: any) => item.salePerson?.id
                )?.length;

                if (
                    product?.product?.category?.commissionType ===
                    commissionType?.id
                ) {
                    categoryTotal += product?.salePrice * product?.quantity;
                }

                const manualDiscount = find(
                    order?.manualDiscount,
                    (discount) => discount.product?.id === product?.id
                );
                if (manualDiscount) {
                    categoryTotal -= manualDiscount?.discountValue;
                }
                categoryTotal /=
                    numberOfSalesPerson === 0 ? 1 : numberOfSalesPerson;
            }
        }

        return categoryTotal;
    };

    const getReportDataFromOrders = (orders: any[]) => {
        const confirmedOrders = filter(orders, (order) =>
            includes(ORDER_CONFIRMED_STATUSES, order.status)
        );

        const allCatConfirmed =
            getProductCategoryReportFromOrder(confirmedOrders);
        const totalConfirmed = reduce(
            Object.keys(allCatConfirmed),
            (sum, key) => sum + allCatConfirmed[key] ?? 0,
            0
        );

        const pendingConfirmOrders = filter(orders, (order) =>
            includes(ORDER_PENDING_CONFIRM_STATUSES, order.status)
        );
        const allCatPendingConfirm =
            getProductCategoryReportFromOrder(pendingConfirmOrders);
        const totalPendingConfirm = reduce(
            Object.keys(allCatPendingConfirm),
            (sum, key) => sum + allCatPendingConfirm[key] ?? 0,
            0
        );

        const totalCustomers = uniqBy(orders, (order) => order.customer?.id);

        const branchNameList = uniq(map(orders, (order) => order?.store?.name));
        const branchName = join(branchNameList, ", ");

        return {
            totalConfirmed,
            totalPendingConfirm,
            totalCustomer: totalCustomers?.length ?? 0,
            branchName,
        };
    };

    const getProductCategoryReportFromOrder = (orders: any) => {
        const categoryReport: any = {};
        // const allCategory = getAllCategoryByOrders(orders);
        forEach(PRODUCT_CATEGORY_COMMISSION_TYPES, (type) => {
            const total = getCategoryReportByOrders(type, orders);
            categoryReport[type?.id] = total;
        });
        return categoryReport;
    };

    const exportCSV = (dataList: any[]) => {
        const exportData: any = [];

        const dataGroupByUser = onGroupOrderBySalesperson(dataList);

        const userIds = Object.keys(dataGroupByUser);

        for (let index = 0; index < userIds.length; index += 1) {
            const userId = userIds[index];
            const userOrders = dataGroupByUser[userId];
            const dateRangeText = join(
                map(formValues.timeRange, (item) => item.format("DD/MM/YYYY")),
                " - "
            );

            const userReportData = getReportDataFromOrders(userOrders);
            const productCategoryReport =
                getProductCategoryReportFromOrder(userOrders);

            exportData.push({
                Salesperson: getFullName(userOrders[0]?.salesPerson),
                "Report Date": dateRangeText,
                Branch: "All",
                "Total Sales Amount (Only Confirmed SO)":
                    userReportData.totalConfirmed,
                "Total Sales Amount (Incl. Pending Confirmation SO)":
                    userReportData.totalPendingConfirm,
                "Total Customer Served": userReportData.totalCustomer,
                ...productCategoryReport,
            });

            const orderGroupByDate = groupBy(userOrders, (item) =>
                moment(item.createdAt).format("DD/MM/YYYY")
            );

            const dateList = Object.keys(orderGroupByDate);
            for (
                let dateIndex = 0;
                dateIndex < dateList.length;
                dateIndex += 1
            ) {
                const date = dateList[dateIndex];
                const dateOrders = orderGroupByDate[date];
                const dateData = getReportDataFromOrders(dateOrders);
                const productCategoryReport =
                    getProductCategoryReportFromOrder(dateOrders);

                exportData.push({
                    Salesperson: "",
                    "Report Date": date,
                    Branch: dateData.branchName,
                    "Total Sales Amount (Only Confirmed SO)":
                        dateData.totalConfirmed,
                    "Total Sales Amount (Incl. Pending Confirmation SO)":
                        dateData.totalPendingConfirm,
                    "Total Customer Served": dateData.totalCustomer,
                    ...productCategoryReport,
                });
            }
        }

        exportToCSV(exportData, `Salesperson Report by User`);
    };

    const onPreview = () => {
        const payload = {
            user: map(formValues.user, (item) => item.id),
            from: formValues?.timeRange?.[0]?.valueOf(),
            to: formValues?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.salespersonByUser, params: [payload] },
            onLoadDataToView
        );
    };

    const onGroupOrderBySalesperson = (orderList: any) => {
        const orderGroupBy: any = {};
        for (let index = 0; index < orderList.length; index += 1) {
            const order = orderList[index];
            orderGroupBy[order?.salesPerson?.id] = [
                ...(orderGroupBy?.[order?.salesPerson?.id] ?? []),
                order,
            ];
            for (
                let indexProducts = 0;
                indexProducts < order?.products.length;
                indexProducts += 1
            ) {
                const product = order?.products[indexProducts];
                for (
                    let indexProSalesperson = 0;
                    indexProSalesperson < product?.salesPerson.length;
                    indexProSalesperson += 1
                ) {
                    const productSalesperson =
                        product?.salesPerson[indexProSalesperson];
                    orderGroupBy[productSalesperson?.salesPerson?.id] = [
                        ...(orderGroupBy?.[
                            productSalesperson?.salesPerson?.id
                        ] ?? []),
                        {
                            ...order,
                            salesPerson: productSalesperson?.salesPerson,
                        },
                    ];
                }
            }
        }

        const allSalespersonIds = Object.keys(orderGroupBy);
        for (let index = 0; index < allSalespersonIds.length; index += 1) {
            const salespersonId = allSalespersonIds[index];
            const orderList = uniqBy(
                orderGroupBy[salespersonId],
                (item: any) => item.id
            );
            orderGroupBy[salespersonId] = orderList;
        }

        return orderGroupBy;
    };

    const onLoadDataToView = (dataList: any) => {
        const tableData: any = [];

        const dataGroupByUser = onGroupOrderBySalesperson(dataList);

        const userIds = Object.keys(dataGroupByUser);
        console.log("productCategoryReport userIds", userIds);

        for (let index = 0; index < userIds.length; index += 1) {
            const userId = userIds[index];
            const userOrders = dataGroupByUser[userId];
            const dateRangeText = join(
                map(formValues.timeRange, (item) => item.format("DD/MM/YYYY")),
                " - "
            );

            const userReportData = getReportDataFromOrders(userOrders);
            const productCategoryReport =
                getProductCategoryReportFromOrder(userOrders);
            console.log("productCategoryReport", productCategoryReport);

            const tableDataItem: any = {
                id: getFullName(userOrders[0]?.salesPerson),
                salesPerson: getFullName(userOrders[0]?.salesPerson),
                date: dateRangeText,
                branch: "All",
                totalConfirmed: userReportData.totalConfirmed,
                totalPendingConfirm: userReportData.totalPendingConfirm,
                totalCustomer: userReportData.totalCustomer,
                ...productCategoryReport,
                children: [],
            };

            const orderGroupByDate = groupBy(userOrders, (item) =>
                moment(item.createdAt).format("DD/MM/YYYY")
            );

            const dateList = Object.keys(orderGroupByDate);
            for (
                let dateIndex = 0;
                dateIndex < dateList.length;
                dateIndex += 1
            ) {
                const date = dateList[dateIndex];
                const dateOrders = orderGroupByDate[date];
                const dateData = getReportDataFromOrders(dateOrders);
                const dateProCatReport =
                    getProductCategoryReportFromOrder(dateOrders);

                tableDataItem.children.push({
                    id: date,
                    salesperson: "",
                    date,
                    branch: dateData.branchName,
                    totalConfirmed: dateData.totalConfirmed,
                    totalPendingConfirm: dateData.totalPendingConfirm,
                    totalCustomer: dateData.totalCustomer,
                    ...dateProCatReport,
                });
            }

            tableData.push(tableDataItem);
        }

        // const allCat = getAllCategoryByOrders(dataList);

        // const allCatCol = map(allCat, (cate) => ({
        //     title: cate?.name,
        //     dataIndex: cate?.name,
        //     width: 150,
        // }));

        // setDataColumn([...COLUMNS, ...allCatCol]);

        setDataTable(tableData);
        setMode(EXPORT_MODE.VIEW);
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <UserSelect
                    className={classNameInput}
                    value={formValues?.user}
                    onChange={(value) =>
                        exportForm.setFieldValue("user", value)
                    }
                    multiple
                />
            </div>
        );
    };

    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                columns={dataColumn}
                dataSource={dataTable}
                className=""
                expandable={{
                    defaultExpandAllRows: true,
                }}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (mode) {
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.salespersonUserReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={mode === EXPORT_MODE.INPUT}
                onClickPrevious={() => setMode(EXPORT_MODE.INPUT)}
                onClickExport={() => exportForm.handleSubmit()}
                onClickView={onPreview}
                exportDisabled={!formValues?.timeRange?.length}
            />
            {mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportSalespersonUser;
