import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetUserScheduleByDateLazyQuery } from "../../../api/hooks";
import { IBranch } from "../../../interfaces/branch";
import IUser, { UserWorkingScheduleDate } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import UserSelect from "../../user/share/UserSelect";
import UserSelectedView from "../../user/share/UserSelectedView";
import TimeSlotSelect, {
    ITimeSlotValue,
} from "../../../common/TimeSlotSelect";

interface IUserSelectValue {
    user: IUser | null;
    timeSlots: ITimeSlotValue[];
}

interface ITreatmentUserSelect<T> {
    date: string;
    branch: IBranch | null;
    className?: any;
    form: any;
    userJobTypes?: string[];
}

const TreatmentUserSelect = ({
    date,
    form,
    branch,
    className,
    userJobTypes = [],
}: ITreatmentUserSelect<IUserSelectValue>) => {
    const [getUserSchedule] = useGetUserScheduleByDateLazyQuery();
    const [userSchedule, setUserSchedule] = useState<UserWorkingScheduleDate>();

    const formValues = form?.values;

    useEffect(() => {
        if (!formValues?.user || !branch || !date) {
            return;
        }
        getUserSchedule({
            variables: {
                userId: formValues?.user?.id,
                branchId: branch?.id,
                date,
            },
            fetchPolicy: "no-cache",
        }).then((res) => {
            const userSchedule = res?.data?.data?.data;
            setUserSchedule(userSchedule as any);
        });
    }, [formValues?.user, branch, date]);

    const userWorkingSchedule = useMemo(() => {
        return map(userSchedule?.workingSchedule ?? [], (item) => ({
            start: item?.schedule?.start / 3600,
            end: item?.schedule?.end / 3600,
        }));
    }, [formValues?.user, date, userSchedule, branch]);

    const userOccupiedSchedule = useMemo(() => {
        return map(userSchedule?.occupiedSlots ?? [], (item) => ({
            start: item?.schedule?.start / 3600,
            end: item?.schedule?.end / 3600,
        }));
    }, [formValues?.user, date, userSchedule, branch]);

    const renderUserSchedule = () => {
        if (!userSchedule) return <div />;
        if (!userSchedule?.isWorking) {
            return (
                <div className="mt-3 text-gray italic">
                    {Messages.userNotWorking}
                </div>
            );
        }
        return (
            <TimeSlotSelect
                workingSchedule={userWorkingSchedule}
                occupiedSchedule={userOccupiedSchedule}
                className="mt-3"
                value={formValues?.timeSlot}
                onChange={(value) => form.setFieldValue("timeSlot", value)}
                multiple
            />
        );
    };

    return (
        <div className={className}>
            <UserSelect
                onChange={(value) => form.setFieldValue("user", value)}
                params={{ jobTypes: userJobTypes as any }}
            />
            <UserSelectedView user={formValues.user} />
            {renderUserSchedule()}
        </div>
    );
};

export default TreatmentUserSelect;
