/* eslint-disable no-plusplus */
import { Notifications, Progress } from "d-react-components";
import React, { Fragment, useContext, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import TreatmentAPI from "../../../../api/queries/treatment";
import Drawer from "../../../../common/Drawer";
import { JOB_COST_TYPES } from "../../../../constant/service";
import { TreatmentDetailContext } from "../../../../context/treatment";
import { mapTreatmentJobAssessmentToServer } from "../../../../interfaces/treatment";
import Messages from "../../../../languages/Messages";
import { convertCsvJSON } from "../../../../utils/file";
import { useUserJobCost } from "./TreatmentJobAssessmentDrawer";

export interface ITreatmentJobAssessmentImportDrawerProps {
    open: boolean;
    onClose: () => void;
}

const TreatmentJobAssessmentImportDrawer: React.FC<
    ITreatmentJobAssessmentImportDrawerProps
> = ({ open, onClose }) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const {
        PIC,
        doctor,
        treatmentNo,
    } = treatment || {};
    const picDataSource = useUserJobCost(PIC, doctor);
    const [activeView, setActiveView] = useState<
        "import" | "validation" | "finish"
    >("import");
    const [validationData, setValidationData] = useState<any>();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });
    const getDisabledSave = useMemo(() => {
        if (activeView !== "validation") return true;
        return validationData?.error;
    }, [activeView, validationData]);

    const header = [
        "companyId",
        "name",
        "jobType",
        "jobCostRoleCode",
        "jobCostRole",
        "unit",
        "quantity",
        "amount",
    ];

    const getTemplateData = useMemo(() => {
        const dataResult = [header];
        picDataSource.forEach((user) => {
            const { adminUser, type, unit } = user || {};
            const { firstName, lastName, nickName, companyId, jobType } =
                adminUser || {};
            const foundJobCost = JOB_COST_TYPES.find(
                (item) => item?.id === type
            );
            dataResult.push([
                companyId,
                `${firstName} ${lastName} (${nickName})`,
                jobType,
                type,
                Messages?.[foundJobCost?.label ?? ""] ?? "N/A",
                unit,
                "",
                "",
            ]);
        });
        return dataResult;
    }, [picDataSource]);

    function onDrop([file]: any[]) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            const data = convertCsvJSON(reader.result);
            if (!data?.length) return;
            const importRes: any = {};
            let error = false;
            let errorCount = 0;
            const dataValidation = data.map((item: any, index: number) => {
                const {
                    companyId,
                    name,
                    jobType,
                    jobCostRoleCode,
                    jobCostRole,
                    unit,
                    quantity,
                    amount,
                } = item;
                const rowValidation = [
                    companyId,
                    name,
                    jobType,
                    jobCostRoleCode,
                    jobCostRole,
                    unit,
                    quantity,
                    amount,
                ];
                const foundUser = picDataSource.find(
                    (user) =>
                        user?.adminUser?.companyId ===
                            companyId?.toString?.() &&
                        jobCostRoleCode === user?.type
                );
                if (!foundUser) {
                    error = true;
                    errorCount++;
                    return [...rowValidation, "User not found"];
                }
                importRes[`${foundUser?.id}&${foundUser?.type}`] = {
                    manualAmount: amount || 0,
                    quantity: quantity || 0,
                };
                return [...rowValidation, ""];
            });

            dataValidation.unshift([...header, "error"]);

            setValidationData({
                error,
                errorCount,
                errorData: dataValidation,
                importRes,
                fileName: file?.name,
            });
            setActiveView("validation");
        };
    }

    const onSaveHandler = () => {
        const payload = mapTreatmentJobAssessmentToServer(
            validationData?.importRes
        );
        const body = {
            treatmentId: treatment?.id,
            payload,
        };
        return Progress.show(
            {
                method: TreatmentAPI.updateManualJobCostAssessment,
                params: [body],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    const renderImportView = useMemo(() => {
        return (
            <Fragment>
                <div
                    className="flex-center border-dashed p-5 flex-column cursor-pointer"
                    {...getRootProps()}
                >
                    <img
                        src="/icons/csv.svg"
                        id="csvIconImage"
                        className="mt-5"
                    />
                    <input {...getInputProps()} />
                    <h5 className="font-weight-bold mt-3">
                        {Messages.dragAndDropYourCSVHere}
                    </h5>
                    <small className="block my-3">
                        {Messages.maximumFileSizeIs5MB}
                    </small>
                    <small className="mb-5 text-primary text-underline">
                        {Messages.orSelectFromYourComputer}
                    </small>
                </div>
                <div className="mt-3 h5">
                    <span className="text ">{Messages.notSureHowToStart}</span>
                    <CSVLink
                        filename={`JobCostTemplate_${new Date().valueOf()}_${treatmentNo}`}
                        data={getTemplateData}
                    >
                        <span className="inline-block mx-1 text-primary hover:cursor-pointer">
                            {`${Messages.downloadYourCurrentJobCostTemplate}`}
                        </span>
                    </CSVLink>

                    <span className="text ">
                        {Messages.thenEditAndReuploadTheFile}
                    </span>
                </div>
            </Fragment>
        );
    }, []);

    const renderValidationView = useMemo(() => {
        return (
            <Fragment>
                <h5 className="mb-3">{Messages.fileValidation}</h5>
                {validationData?.error && (
                    <div className="bg-primary-light p-4 text">
                        <div className="flex mb-3">
                            <h5 className="mb-0">{Messages.fileName}:</h5>
                            <div className="ml-2">
                                {validationData?.fileName}
                            </div>
                        </div>
                        <div className="flex">
                            <h5 className="mb-0">
                                {Messages.validationStatus}:
                            </h5>
                            <div className="flex gap-2">
                                <span className="text-red-400 inline-block ml-2">
                                    {validationData?.errorCount}{" "}
                                    {Messages.errors}
                                </span>
                                <CSVLink
                                    filename={`JobCostTemplate_Error_Report_${new Date().valueOf()}_${treatmentNo}`}
                                    data={validationData?.errorData}
                                >
                                    <span className="text-blue-500 inline-block">
                                        {Messages.downloadReport}
                                    </span>
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                )}
                {!validationData?.error && (
                    <div className="bg-primary-light p-4 text">
                        <div className="flex mb-3">
                            <h5 className="mb-0">{Messages.fileName}:</h5>
                            <div className="ml-2">
                                {validationData?.fileName}
                            </div>
                        </div>
                        <div className="flex">
                            <h5 className="mb-0">
                                {Messages.validationStatus}:
                            </h5>
                            <div className="flex ml-2 text-green-700">
                                {Messages.readyToImport}
                            </div>
                        </div>
                    </div>
                )}
                <p className="mt-3 text">
                    <span className="font-bold inline-block mr-2">
                        * {Messages.lastWarning}
                    </span>
                    <span>{Messages.pleaseCheckCarefully}</span>
                </p>
            </Fragment>
        );
    }, [validationData]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.manualJobCostAssessmentImport}
            onSave={onSaveHandler}
            disableSave={getDisabledSave}
            size="auto"
            width="50%"
        >
            <div className="p-4">
                {activeView === "import" && renderImportView}
                {activeView === "validation" && renderValidationView}
                {activeView === "finish" && renderImportView}
            </div>
        </Drawer>
    );
};

export default TreatmentJobAssessmentImportDrawer;
