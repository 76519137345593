export const BOOKING_STATUS = {
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
};

export const BOOKING_STATUSES = [
    { id: "PENDING", label: "pending", color: "#FAC256" },
    { id: "CONFIRMED", label: "confirmed", color: "#29B3BC" },
    { id: "COMPLETED", label: "completed", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
];
