import classNames from "classnames";
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useUpdateCustomerMedicalProfileMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { CustomerDetailContext } from "../../../context/customer";
import {
    ICustomer,
    mapCustomerMedicalProfileToServer,
} from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import MedicalProfileForm from "../share/MedicalProfileForm";
import SurgeryHisForm from "../share/SurgeryHisForm";

const UpdateMedicalProfileDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const [updateMedicalProfile] = useUpdateCustomerMedicalProfileMutation();
    const customerForm = useFormik<any>({
        initialValues: customer?.medicalProfile ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const input = mapCustomerMedicalProfileToServer(values);
            onUpdatePersonalInfo(input);
        },
    });

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateMedicalProfile,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.medicalProfile}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <MedicalProfileForm form={customerForm} />
        </Drawer>
    );
};

export const AllergyHistoryForm = ({ form }: any) => {
    const inputClassName = "col-12 mt-3";
    return (
        <>
            <InputTextForm
                label={Messages.allergyHistory}
                keyData="allergyHis"
                form={form}
                className={inputClassName}
                multiple
            />
            <InputTextForm
                label={Messages.underlyingDisease}
                keyData="underDisease"
                form={form}
                className={inputClassName}
                multiple
            />
        </>
    );
};

const UpdateAllergyHistoryDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const [updateMedicalProfile] = useUpdateCustomerMedicalProfileMutation();
    const customerForm = useFormik<any>({
        initialValues: {
            allergyHis: customer?.medicalProfile?.allergyHis,
            underDisease: customer?.medicalProfile?.underDisease,
        },
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onUpdatePersonalInfo(values);
        },
    });

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateMedicalProfile,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.allergyHistoryAnd}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <div className="row p-3">
                <AllergyHistoryForm form={customerForm} />
            </div>
        </Drawer>
    );
};

export const SurgeryHistoryForm = ({ form }: any) => {
    const formValues = form?.values;
    const formErrors = form?.errors;

    const onUpdateSurgeryHisList = (history: any, index: string) => {
        const list = map(formValues?.surgeryHis, (item, itemIndex) => {
            if (index === itemIndex) {
                return history;
            }
            return item;
        });
        form.setFieldValue("surgeryHis", list);
    };

    const onAddHistoryList = () => {
        const list = [...(formValues?.surgeryHis ?? []), {}];
        form.setFieldValue("surgeryHis", list);
    };

    const onRemoveHistory = (indexItem: number) => {
        const list = formValues?.surgeryHis;
        list.splice(indexItem, 1);
        form.setFieldValue("surgeryHis", list);
    };

    return (
        <>
            {map(formValues?.surgeryHis, (surgeryHis, index) => (
                <div className="border-bottom py-3">
                    <SurgeryHisForm
                        surgeryHis={surgeryHis}
                        formError={formErrors?.[index]}
                        onChange={(value: any) =>
                            onUpdateSurgeryHisList(value, index)
                        }
                    />
                    <div
                        className="text-error mt-3 cursor-pointer"
                        // eslint-disable-next-line radix
                        onClick={() => onRemoveHistory(parseInt(index))}
                    >
                        {Messages.remove}
                    </div>
                </div>
            ))}
            <Button onClick={onAddHistoryList} className="mt-3">
                {Messages.add}
            </Button>
        </>
    );
};

const UpdateSurgeryHisDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);

    const [updateMedicalProfile] = useUpdateCustomerMedicalProfileMutation();

    const customerForm = useFormik<any>({
        initialValues: {
            surgeryHis: customer?.medicalProfile?.surgeryHis ?? [],
        },
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const input = {
                surgeryHis: map(values?.surgeryHis, (item) => ({
                    date: item.date,
                    hospital: item.hospital,
                    surgery: item.surgery,
                })),
            };
            onUpdateSurgeryHis(input);
        },
    });

    const formErrors = customerForm?.errors;

    useEffect(() => {
        forEach(Object.keys(formErrors), (key) =>
            Notifications.showError(JSON.stringify(formErrors[key]))
        );
    }, [formErrors]);

    const onUpdateSurgeryHis = (input: any) => {
        Progress.show(
            {
                method: updateMedicalProfile,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.surgeryHistory}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <div className="p-3">
                <SurgeryHistoryForm form={customerForm} />
            </div>
        </Drawer>
    );
};

const MEDICAL_PROFILE_KEYS: IRowsKey<ICustomer["medicalProfile"]>[] = [
    {
        id: "height",
        label: Messages.heightCm,
    },
    {
        id: "weight",
        label: Messages.weightKg,
    },
    {
        id: "bloodGroup",
        label: Messages.bloodGroup,
    },
    {
        id: "bloodPressure",
        label: Messages.bloodPressure,
    },
];

const MEDICAL_ALLERGIC_KEYS: IRowsKey<ICustomer["medicalProfile"]>[] = [
    {
        id: "allergyHis",
        label: Messages.allergyHistory,
    },
    {
        id: "underDisease",
        label: Messages.underlyingDisease,
    },
];

const CustomerDetailMedicalProfile = () => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const { medicalProfile } = customer ?? {};
    const [openEditMedical, setOpenEditMedical] = useState(false);
    const [openEditAllergy, setOpenEditAllergy] = useState(false);
    const [openEditSurgeryHis, setOpenEditSurgeryHis] = useState(false);

    const surgeryColumns: IColumnsProps = [
        {
            title: Messages.date,
            dataIndex: "date",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.surgery,
            dataIndex: "surgery",
        },
        {
            title: Messages.admissionHospital,
            dataIndex: "hospital",
        },
    ];

    const renderHeader = (title: any, onClickEdit: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                <Button
                    onClick={onClickEdit}
                    className=""
                    variant="trans"
                    size="small"
                    color="red"
                >
                    {Messages.edit}
                </Button>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3">
                {renderHeader(Messages.basicHealthInformation, () =>
                    setOpenEditMedical(true)
                )}

                <ViewRowInterchange
                    keyList={MEDICAL_PROFILE_KEYS}
                    dataSource={customer?.medicalProfile}
                    variant="background"
                />
            </div>
            <div className="border p-3 mt-3">
                {renderHeader(Messages.allergyHistoryAnd, () =>
                    setOpenEditAllergy(true)
                )}
                <ViewRowInterchange
                    keyList={MEDICAL_ALLERGIC_KEYS}
                    dataSource={customer?.medicalProfile}
                    variant="background"
                />
            </div>
            <div className="border p-3 mt-3">
                {renderHeader(Messages.surgeryHistory, () =>
                    setOpenEditSurgeryHis(true)
                )}
                <AwesomeTableComponent
                    columns={surgeryColumns}
                    dataSource={medicalProfile?.surgeryHis ?? []}
                    className="mt-3"
                />
            </div>
            {openEditMedical && (
                <UpdateMedicalProfileDrawer
                    open={openEditMedical}
                    onClose={() => {
                        setOpenEditMedical(false);
                    }}
                />
            )}
            {openEditAllergy && (
                <UpdateAllergyHistoryDrawer
                    open={openEditAllergy}
                    onClose={() => {
                        setOpenEditAllergy(false);
                    }}
                />
            )}
            {openEditSurgeryHis && (
                <UpdateSurgeryHisDrawer
                    open={openEditSurgeryHis}
                    onClose={() => {
                        setOpenEditSurgeryHis(false);
                    }}
                />
            )}
        </div>
    );
};

export default CustomerDetailMedicalProfile;
