import styled from "@emotion/styled";
import React, { Fragment, useContext, useState } from "react";
import { isEmpty } from "lodash";
import { Progress, Notifications } from "d-react-components";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import UserSelectedView from "../../user/share/UserSelectedView";
import EditSalespersonDrawer from "../../quotation/share/EditSalespersonDrawer";
import { useUpdateOrderPrimaySalesPersonForAdminMutation } from "../../../api/hooks";
import IUser from "../../../interfaces/user";

const OrderSalePerson = ({ onUpdated }: any) => {
    const { order } = useContext(OrderDetailContext);
    const [updateOrder] = useUpdateOrderPrimaySalesPersonForAdminMutation();
    const [showEditModal, setShowEditModal] = useState(false);
    const { createByAdmin, salesPerson } = order || {};

    const onUpdateSalesPerson = (sp: IUser) => {
        Progress.show(
            {
                method: updateOrder,
                params: [
                    {
                        variables: {
                            id: order?.id as string,
                            payload: {
                                salesPerson: sp.id,
                            },
                        },
                    },
                ],
            },
            (resp) => {
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
                setShowEditModal(false);
                onUpdated();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    if (isEmpty(salesPerson)) {
        return null;
    }
    return (
        <OrderSalePersonStyled className="border p-3 mb-3">
            {/* {createByAdmin && (
                <Fragment>
                    <h5 className="font-semibold mb-1">{Messages.salePerson}</h5>
                    <UserSelectedView
                        user={createByAdmin}
                        className="flex-center-y"
                    />
                </Fragment>
            )} */}
            {/* {salesPerson && <div className="divider mt-3 w-100" />} */}
            {salesPerson && (
                <div className="flex mb-2 items-start">
                    <div className="flex-1">
                        <h5 className="font-semibold mb-1">
                            {Messages.primarySalesPerson}
                        </h5>
                        <UserSelectedView
                            user={salesPerson}
                            className="flex-center-y"
                        />
                    </div>
                    <a onClick={() => setShowEditModal(true)}>
                        {Messages.edit}
                    </a>
                </div>
            )}
            {showEditModal && (
                <EditSalespersonDrawer
                    open={showEditModal}
                    salesPerson={salesPerson}
                    products={order?.products}
                    onClose={() => setShowEditModal(false)}
                    onSave={(value: IUser) => {
                        onUpdateSalesPerson(value);
                    }}
                />
            )}
        </OrderSalePersonStyled>
    );
};

export default OrderSalePerson;

const OrderSalePersonStyled = styled.div`
    .user-selected-view {
        background-color: white !important;
        margin-top: 0 !important;
        .image-square-medium {
            width: 50px;
            height: 50px;
        }
        .text-white {
            color: black !important;
        }
    }
`;
