import { Radio, Switch } from "antd";
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputText,
    ObjectUtils,
    Select,
} from "d-react-components";
import { find, isNaN, map } from "lodash";
import CurrencyFormat from "react-currency-format";
import { useMedia } from "react-use";
import { UsageType } from "../../../api/hooks";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import {
    PREMIUM_SERVICE_TYPES,
    PRODUCT_TYPE,
    PRODUCT_TYPES,
} from "../../../constant/product";
import {
    VOUCHER_TYPE,
    VOUCHER_TYPES,
    VOUCHER_USAGE_TYPES,
} from "../../../constant/voucher";
import { getPriceDynamicItemInSpecialGroupProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ServiceConfigurationSelect from "../../service-config/share/ServiceConfigurationSelect";
import ProductNameView from "./ProductNameView";
import ProductSearchButton from "./ProductSearchButton";

export const ProductTypePremiumService = ({
    configForm,
    disableOption,
}: any) => {
    const productFormValue = configForm?.values;
    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product: any) => <ProductNameView product={product} />,
            width: 1000,
        },

        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            width: 300,
            render: (regularPrice) => (
                <CurrencyFormat
                    value={regularPrice}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            width: 300,
            render: (salePrice) => (
                <CurrencyFormat
                    value={salePrice}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <Button
                    disabled={disableOption?.remove}
                    iconName="remove_circle_outline"
                    variant="trans"
                    onClick={() => onRemove(product?.id)}
                />
            ),
        },
    ];

    const onRemove = (id: string) => {
        const services = ObjectUtils.removeArrayById(
            productFormValue?.premiumService?.product ?? [],
            id
        );
        configForm.setFieldValue("premiumService.product", services);
    };

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.specialGroupService}</label>
                {!disableOption?.addProduct && (
                    <ProductSearchButton
                        isShowSalePrice
                        isShowRegularPrice
                        onSave={(values) => {
                            // const spendingCap = reduce(
                            //     values,
                            //     (res, item, index) => {
                            //         // return res + item?.regularPrice;
                            //         return res + item?.salePrice;
                            //     },
                            //     0
                            // );
                            configForm.setValues((v: any) => ({
                                ...v,
                                premiumService: {
                                    ...(v?.premiumService ?? {}),
                                    product: values,
                                    // spending: spendingCap,
                                },
                            }));
                        }}
                        defaultValue={
                            productFormValue?.premiumService?.product ?? []
                        }
                        isHideAvaQtyCurrentWH
                        isHideOnHoldQtyCurrentWH
                        filterSource={{
                            productType: [PRODUCT_TYPE.SERVICE_PRODUCT],
                        }}
                    >
                        {Messages.add}
                    </ProductSearchButton>
                )}
            </div>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={productFormValue?.premiumService?.product ?? []}
                pagination={false}
            />

            <label className="mt-3">{Messages.configuration}</label>
            <div className="bg-primary p-3 row">
                <InputText
                    disabled
                    classNameLabel="text-white"
                    label={Messages.spendingCap}
                    // value={productFormValue?.premiumService?.spending}
                    value={productFormValue?.salePrice}
                    onChange={(event) => {
                        const value = parseFloat(event?.target?.value);
                        const spending = isNaN(value) ? 0 : value;
                        configForm.setFieldValue(
                            "premiumService.spending",
                            spending
                        );
                    }}
                    className="col-6"
                />
                <Select
                    classNameLabel="text-white"
                    label={Messages.type}
                    value={
                        productFormValue?.premiumService?.premiumServiceType ??
                        []
                    }
                    onChange={(value) =>
                        configForm.setFieldValue(
                            "premiumService.premiumServiceType",
                            value
                        )
                    }
                    getLabel={(item) => Messages[item.label]}
                    dataSource={PREMIUM_SERVICE_TYPES}
                    className="col-6"
                    disabled={disableOption?.premiumServiceType}
                />
            </div>
        </div>
    );
};
/**
 * Product group include the simple products only, not include service product
 */
export const ProductTypeGroup = ({ configForm, disableOption }: any) => {
    const productFormValue = configForm?.values;
    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product: any) => <ProductNameView product={product} />,
            width: 400,
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            width: 100,
            render: (quantity, product) => {
                if (disableOption?.quantity) {
                    return quantity;
                }
                return (
                    <InputTextQuantity
                        value={quantity}
                        onChange={(quantityValue) =>
                            onUpdateGroups({
                                ...product,
                                quantity: quantityValue,
                            })
                        }
                        min={1}
                        disabled={disableOption?.quantity}
                    />
                );
            },
        },
        {
            title: <div className="text-nowrap">{Messages.regularPrice}</div>,
            dataIndex: "regularPrice",
            width: 140,
            align: "center",
            render: (regularPrice, product) => {
                return (
                    <CurrencyFormat
                        value={regularPrice}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },

        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            width: 250,
            render: (salePrice, product) => (
                <InputText
                    value={salePrice}
                    onChange={(event) => {
                        let salePrice = parseFloat(event?.target?.value);
                        salePrice = isNaN(salePrice) ? 0 : salePrice;
                        onUpdateGroups({
                            ...product,
                            salePrice,
                        });
                    }}
                    disabled={disableOption?.salePrice}
                />
            ),
        },
        {
            title: Messages.unitPrice,
            dataIndex: "salePrice",
            width: 100,
            align: "center",
            render: (salePrice, product) => (
                <CurrencyFormat
                    value={Math.floor(salePrice / (product?.quantity || 1))}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.voucherType,
            dataIndex: "voucherType",
            render: (voucherType, product) => (
                <Select
                    dataSource={VOUCHER_TYPES}
                    value={voucherType}
                    getLabel={(item) => Messages[item.label]}
                    onChange={(value) => {
                        onUpdateGroups({
                            ...product,
                            voucherType: value,
                            ...(value === VOUCHER_TYPE.GIFT_VOUCHER
                                ? { usageType: UsageType.SingleUse }
                                : {}),
                        });
                    }}
                    disabled={disableOption?.voucherType}
                />
            ),
        },
        {
            title: Messages.usage,
            dataIndex: "usageType",
            render: (usageType, product) => (
                <Select
                    dataSource={VOUCHER_USAGE_TYPES}
                    value={usageType}
                    disabled={
                        product.voucherType === VOUCHER_TYPE.GIFT_VOUCHER ||
                        disableOption?.usageType
                    }
                    getLabel={(item) => Messages[item.label]}
                    onChange={(value) => {
                        onUpdateGroups({
                            ...product,
                            usageType: value,
                        });
                    }}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <Button
                    disabled={disableOption?.remove}
                    iconName="remove_circle_outline"
                    variant="trans"
                    onClick={() => onRemove(product?.id)}
                />
            ),
            width: 100,
        },
    ];

    const onUpdateGroups = (product: any) => {
        const groups = ObjectUtils.updateArrayById(
            productFormValue?.groups ?? [],
            product
        );
        configForm.setFieldValue("groups", groups);
    };

    const onRemove = (id: string) => {
        const services = ObjectUtils.removeArrayById(
            productFormValue?.groups ?? [],
            id
        );
        configForm.setFieldValue("groups", services);
    };

    const onAddProduct = (products: any[]) => {
        const result = map(products, (product) => {
            const productSelected = find(
                productFormValue?.groups,
                (item) => item?.id === product?.id
            );
            if (productSelected) {
                return productSelected;
            }

            return { ...product, quantity: 1 };
        });
        configForm.setFieldValue("groups", result);
    };

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.groupProduct}</label>
                <ProductSearchButton
                    onSave={onAddProduct}
                    defaultValue={productFormValue?.groups ?? []}
                    isHideAvaQtyCurrentWH
                    isHideOnHoldQtyCurrentWH
                    isShowRegularPrice
                    isShowSalePrice
                    filterSource={{
                        productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                    }}
                >
                    {Messages.addItem}
                </ProductSearchButton>
            </div>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={productFormValue?.groups ?? []}
                isPagination={false}
            />
        </div>
    );
};

export const SpecialGroupProduct = ({ configForm, disableOption }: any) => {
    const isSmallScreen = useMedia(`(max-width: 1440px)`);
    const productFormValue = configForm?.values;

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product: any) => <ProductNameView product={product} />,
            width: 300,
        },
        {
            title: Messages.dynamicPrice,
            dataIndex: "isDynamicPrice",
            render: (isDynamicPrice, product) => (
                <Radio
                    onChange={() => onUpdateIsDynamic(product)}
                    checked={isDynamicPrice}
                    disabled={disableOption?.isDynamicPrice}
                />
            ),
        },
        {
            title: Messages.defaultSalePrice,
            dataIndex: "salePrice",
            render: (salePrice, item) => {
                return (
                    <CurrencyFormat
                        value={salePrice}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                    />
                );
            },
        },

        {
            title: Messages.salePrice,
            dataIndex: "newSalePrice",
            width: 300,
            render: (newSalePrice, product) => {
                if (product.isDynamicPrice || disableOption?.newSalePrice) {
                    const amount = getPriceDynamicItemInSpecialGroupProduct(
                        product.id,
                        productFormValue
                    );

                    return (
                        <CurrencyFormat
                            value={amount / product.quantity}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    );
                }
                return (
                    <InputText
                        disabled={product.isDynamicPrice}
                        value={newSalePrice}
                        onChange={(event) => {
                            let newSalePrice = parseFloat(event?.target?.value);
                            newSalePrice = isNaN(newSalePrice)
                                ? 0
                                : newSalePrice;
                            onUpdateGroups({
                                ...product,
                                newSalePrice,
                            });
                        }}
                    />
                );
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => {
                if (disableOption?.quantity) {
                    return quantity;
                }
                return (
                    <InputTextQuantity
                        value={quantity}
                        onChange={(quantityValue) =>
                            onUpdateGroups({
                                ...product,
                                quantity: quantityValue,
                            })
                        }
                        min={1}
                    />
                );
            },
        },
        {
            title: Messages.amount,
            dataIndex: "quantity",
            render: (quantity, item) => {
                if (item.isDynamicPrice) {
                    const amount = getPriceDynamicItemInSpecialGroupProduct(
                        item.id,
                        productFormValue
                    );
                    return (
                        <CurrencyFormat
                            value={amount}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    );
                }
                return (
                    <CurrencyFormat
                        value={quantity * item.newSalePrice}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },

        {
            title: Messages.voucherType,
            dataIndex: "voucherType",
            render: (voucherType, product) => (
                <Select
                    dataSource={VOUCHER_TYPES}
                    value={voucherType}
                    getLabel={(item) => Messages[item.label]}
                    onChange={(value) => {
                        onUpdateGroups({
                            ...product,
                            voucherType: value,
                            ...(value === VOUCHER_TYPE.GIFT_VOUCHER
                                ? { usageType: UsageType.SingleUse }
                                : {}),
                        });
                    }}
                    disabled={disableOption?.voucherType}
                />
            ),
        },
        {
            title: Messages.usage,
            dataIndex: "usageType",
            render: (usageType, product) => (
                <Select
                    dataSource={VOUCHER_USAGE_TYPES}
                    value={usageType}
                    disabled={
                        product.voucherType === VOUCHER_TYPE.GIFT_VOUCHER ||
                        disableOption?.usageType
                    }
                    getLabel={(item) => Messages[item.label]}
                    onChange={(value) => {
                        onUpdateGroups({
                            ...product,
                            usageType: value,
                        });
                    }}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <Button
                    iconName="remove_circle_outline"
                    variant="trans"
                    onClick={() => onRemove(product?.id)}
                    disabled={disableOption?.remove}
                />
            ),
            width: 100,
        },
    ];

    const onUpdateIsDynamic = (product: any) => {
        const result = map(productFormValue?.specialGroups, (item) => {
            if (product?.id === item.id) {
                return {
                    ...item,
                    newSalePrice: product?.salePrice,
                    isDynamicPrice: true,
                };
            }
            return { ...item, isDynamicPrice: false };
        });
        configForm.setFieldValue("specialGroups", result);
    };

    const onAddProduct = (products: any[]) => {
        const result = map(products, (product) => {
            const productSelected = find(
                productFormValue?.specialGroups,
                (item) => item?.id === product?.id
            );
            if (productSelected) {
                return productSelected;
            }

            return {
                ...product,
                newSalePrice: product?.salePrice,
                quantity: 1,
                isDynamicPrice: false,
            };
        });
        configForm.setFieldValue("specialGroups", result);
    };

    const onUpdateGroups = (product: any) => {
        const groups = ObjectUtils.updateArrayById(
            productFormValue?.specialGroups ?? [],
            product
        );
        configForm.setFieldValue("specialGroups", groups);
    };

    const onRemove = (id: string) => {
        const services = ObjectUtils.removeArrayById(
            productFormValue?.specialGroups ?? [],
            id
        );
        configForm.setFieldValue("specialGroups", services);
    };

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.specialGroupProductService}</label>
                {!disableOption?.addProduct && (
                    <ProductSearchButton
                        isShowSalePrice
                        isShowRegularPrice
                        onSave={onAddProduct}
                        defaultValue={productFormValue?.specialGroups ?? []}
                        isHideAvaQtyCurrentWH
                        isHideOnHoldQtyCurrentWH
                        filterSource={{
                            productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                        }}
                    >
                        {Messages.addItem}
                    </ProductSearchButton>
                )}
            </div>

            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={productFormValue?.specialGroups ?? []}
                isPagination={false}
            />
        </div>
    );
};
export const BundleProduct = ({ configForm, disableOption }: any) => {
    const productFormValue = configForm?.values;

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product: any) => <ProductNameView product={product} />,
            width: 400,
        },
        {
            title: <div className="text-nowrap">{Messages.regularPrice}</div>,
            dataIndex: "regularPrice",
            render: (regularPrice) => (
                <CurrencyFormat
                    value={regularPrice}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: (salePrice) => {
                return (
                    <CurrencyFormat
                        value={salePrice}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },
        {
            title: Messages.unitPrice,
            dataIndex: "salePrice",
            width: 200,
            render: (salePrice, product) => (
                <CurrencyFormat
                    value={Math.floor(salePrice / (product?.quantity || 1))}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => {
                if (disableOption?.quantity) {
                    return quantity;
                }
                return (
                    <InputTextQuantity
                        value={quantity}
                        onChange={(quantityValue) =>
                            onUpdateGroups({
                                ...product,
                                quantity: quantityValue,
                            })
                        }
                        min={1}
                    />
                );
            },
        },

        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <Button
                    iconName="remove_circle_outline"
                    variant="trans"
                    onClick={() => onRemove(product?.id)}
                />
            ),
            width: 100,
        },
    ];

    const onAddProduct = (products: any[]) => {
        const result = map(products, (product) => {
            const productSelected = find(
                productFormValue?.bundleProducts,
                (item) => item?.id === product?.id
            );
            if (productSelected) {
                return productSelected;
            }

            return {
                ...product,
                quantity: 1,
            };
        });
        configForm.setFieldValue("bundleProducts", result);
    };

    const onUpdateGroups = (product: any) => {
        const groups = ObjectUtils.updateArrayById(
            productFormValue?.bundleProducts ?? [],
            product
        );
        configForm.setFieldValue("bundleProducts", groups);
    };

    const onRemove = (id: string) => {
        const services = ObjectUtils.removeArrayById(
            productFormValue?.bundleProducts ?? [],
            id
        );
        configForm.setFieldValue("bundleProducts", services);
    };

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.bundle}</label>
                <ProductSearchButton
                    isShowSalePrice
                    isShowRegularPrice
                    onSave={onAddProduct}
                    defaultValue={productFormValue?.bundleProducts ?? []}
                    isHideAvaQtyCurrentWH
                    isHideOnHoldQtyCurrentWH
                    // filterSource={{
                    //     productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                    // }}
                >
                    {Messages.addItem}
                </ProductSearchButton>
            </div>

            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={productFormValue?.bundleProducts ?? []}
                isPagination={false}
            />
        </div>
    );
};

interface IProductConfigurationForm {
    configForm: any;
    disableSelectType?: boolean;
}

const ProductConfigurationForm = ({
    configForm,
    disableSelectType,
}: IProductConfigurationForm) => {
    const productFormValue = configForm?.values;

    const renderProductTypeConfig = () => {
        switch (productFormValue?.typeOfProduct) {
            case PRODUCT_TYPE.SERVICE_PRODUCT:
                return renderProductServiceConfig();
            case PRODUCT_TYPE.PREMIUM_SERVICE:
                return <ProductTypePremiumService configForm={configForm} />;
            case PRODUCT_TYPE.GROUP_PRODUCT:
                return <ProductTypeGroup configForm={configForm} />;
            case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
                return <SpecialGroupProduct configForm={configForm} />;
            case PRODUCT_TYPE.BUNDLE_PRODUCT:
                return <BundleProduct configForm={configForm} />;
            default:
                return <div />;
        }
    };

    const renderProductServiceConfig = () => (
        <ServiceConfigurationSelect
            value={productFormValue?.service ?? []}
            onChange={(value) => configForm.setFieldValue("service", value)}
            className="mt-3"
        />
    );

    return (
        <div className="mt-3">
            <label>{Messages.productType}</label>
            <div className="border p-3">
                {map(PRODUCT_TYPES, (type) => (
                    <div className="d-flex align-items-center mt-3">
                        <Switch
                            disabled={disableSelectType}
                            checked={
                                productFormValue?.typeOfProduct === type?.id
                            }
                            onChange={() =>
                                configForm.setFieldValue(
                                    "typeOfProduct",
                                    type?.id
                                )
                            }
                        />
                        <text className="ml-3">
                            {(Messages as any)[type.title]}
                        </text>
                        {/* {type.tooltip && (
                            <Tooltip
                                title={(Messages as any)[type.tooltip]}
                                className="ml-1"
                            >
                                <Icon name="info" />
                            </Tooltip>
                        )} */}
                    </div>
                ))}
                <div className="mt-3">{renderProductTypeConfig()}</div>
            </div>
        </div>
    );
};

export default ProductConfigurationForm;
