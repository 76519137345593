/* eslint-disable react/jsx-no-constructed-context-values */
import {
    AwesomeTableComponent,
    IColumnsProps,
    Progress,
    TabBar,
    TimeUtils,
} from "d-react-components";
import { join, map, reduce } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Table } from "antd";
import TreatmentAPI from "../../../api/queries/treatment";
import { isGranted } from "../../../common/PermissibleComponent";
import { PERMISSION } from "../../../constant/user";
import { TreatmentDetailContext } from "../../../context/treatment";
import { ITreatment, ITreatmentUser } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import { getFullName } from "../../../utils/Utils";
import CustomerInfoBar from "../../customer/share/CustomerInfoBar";
import TreatmentDetailActivity from "./TreatmentDetailActivity";
import TreatmentDetailAddonServices from "./TreatmentDetailAddonServices";
import TreatmentDetailBeforeAfter from "./TreatmentDetailBeforeAfter";
import TreatmentDetailDoctor from "./TreatmentDetailDoctor";
import TreatmentDetailEquipment from "./TreatmentDetailEquipment";
import TreatmentDetailGeneral from "./TreatmentDetailGeneral";
import TreatmentDetailHeader from "./TreatmentDetailHeader";
import TreatmentDetailJobCost from "./TreatmentDetailJobCost";
import TreatmentDetailMaterial from "./TreatmentDetailMaterial";
import TreatmentDetailMedicine from "./TreatmentDetailMedicine";
import TreatmentDetailNote from "./TreatmentDetailNote";
import TreatmentDetailPic from "./TreatmentDetailPic";
import { IMaterial } from "../../../interfaces/material";
import { UserJobCostRoleView } from "../../user/share/UserSubView";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <TreatmentDetailGeneral />,
    },
    {
        id: "doctor",
        label: Messages.doctor,
        component: <TreatmentDetailDoctor />,
    },
    {
        id: "pic",
        label: Messages.pic,
        component: <TreatmentDetailPic />,
    },
    {
        id: "equipment",
        label: Messages.equipment,
        component: <TreatmentDetailEquipment />,
    },
    {
        id: "material",
        label: Messages.material,
        component: <TreatmentDetailMaterial />,
    },
    {
        id: "medicine",
        label: Messages.medicine,
        component: <TreatmentDetailMedicine />,
    },
    {
        id: "addonServices",
        label: Messages.addonServices,
        component: <TreatmentDetailAddonServices />,
    },
    {
        id: "beforeAfter",
        label: Messages.beforeAfter,
        component: <TreatmentDetailBeforeAfter />,
    },
    {
        id: "jobCost",
        label: Messages.jobCost,
        component: <TreatmentDetailJobCost />,
    },
    {
        id: "note",
        label: Messages.note,
        component: <TreatmentDetailNote />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <TreatmentDetailActivity />,
    },
];

enum TreatmentPrintMode {
    PRINT_TREATMENT = "PRINT_TREATMENT",
    PRINT_MATERIAL = "PRINT_MATERIAL",
}

const TreatmentDetail = () => {
    const { treatmentId } = useParams<any>();
    const [treatment, setTreatment] = useState<ITreatment>();
    const [printMode, setPrintMode] = useState<any>(null);
    const renderTabs = () => {
        let res = [...TABS];
        const hasJobCostPermission = isGranted([
            PERMISSION.TREATMENT.UPDATE_JOB_ASSESSMENT,
            PERMISSION.TREATMENT.UPDATE_MANUAL_JOB_ASSESSMENT,
        ]);
        if (!hasJobCostPermission) {
            res = res.filter((item) => item.id !== "jobCost");
        }
        return res;
    };
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadTreatmentDetail();
    }, []);

    const loadTreatmentDetail = () => {
        Progress.show(
            {
                method: TreatmentAPI.detail,
                params: [treatmentId],
            },
            setTreatment as any
        );
    };

    const onPrint = () => {
        setPrintMode(TreatmentPrintMode.PRINT_TREATMENT);
        setTimeout(() => window.print(), 500);
    };

    const onPrintMaterial = () => {
        setPrintMode(TreatmentPrintMode.PRINT_MATERIAL);
        setTimeout(() => window.print(), 500);
    };

    if (!treatment) {
        return <div />;
    }

    const renderPrintInfo = (title: any, content: any) => {
        return (
            <div className="d-flex flex-row py-2 border-bottom">
                <div className="font-weight-bold">{`${title}:`}</div>
                <div className="ml-1">{content}</div>
            </div>
        );
    };

    const renderPrintSignature = (title: string) => {
        return (
            <div className="w-[200px] d-flex flex-column flex-center">
                <div className="divider" />
                <div className="mt-3">{title}</div>
            </div>
        );
    };

    const renderTreatmentPrinting = () => {
        const {
            branch,
            customer,
            services,
            completion,
            starting,
            treatmentNo,
            doctorOrder,
        } = treatment;
        const serviceNames = join(
            map(services, (item) => item?.product?.name),
            ", "
        );

        const serviceQty = reduce(
            services,
            (sum, item) => sum + (item?.quantity ?? 0),
            0
        );
        return (
            <div className="treatment-print__container p-5">
                <div className="d-flex flex-column justify-content-between h-100 w-100">
                    <div className="d-flex align-items-start align-items-center ">
                        <img
                            src="/images/logo.png"
                            alt=""
                            className="mb-5 h-[100px]"
                            style={{ width: "auto", objectFit: "contain" }}
                        />
                    </div>

                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column flex-grow-1 mr-5">
                            {renderPrintInfo(Messages.branch, branch?.name)}
                            {renderPrintInfo(
                                Messages.customer,
                                `${customer?.fullNameTh}(CN:${customer?.customerNo})`
                            )}
                            {renderPrintInfo(Messages.treatment, serviceNames)}
                            {renderPrintInfo(Messages.used, serviceQty)}
                            {renderPrintInfo(
                                Messages.usedDate,
                                `${TimeUtils.toDateTime(
                                    starting?.actualTime
                                )} - ${TimeUtils.toDateTime(
                                    completion?.actualTime
                                )}`
                            )}
                        </div>
                        <div className="flex-column flex-grow-1 p-3 bg-muted">
                            {renderPrintInfo(Messages.reference, branch?.name)}
                            {renderPrintInfo(Messages.treatmentNo, treatmentNo)}
                            {renderPrintInfo(
                                Messages.salePerson,
                                getFullName(doctorOrder?.order?.salesPerson)
                            )}
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between w-100">
                        {renderPrintSignature(Messages.customerSignature)}
                        {renderPrintSignature(Messages.cashier)}
                    </div>
                </div>
            </div>
        );
    };

    const renderMaterialPrinting = () => {
        const {
            customer,
            services,

            treatmentNo,
            doctorOrder,
            day,
            materials,
            branch,
            doctor,
            PIC,
        } = treatment;

        const treatmentName = join(
            map(services, (item) => item.product?.name),
            ", "
        );

        const picDataSource = () => {
            const allPics = PIC ? [...PIC] : [];
            if (doctor?.adminUser) {
                allPics.unshift({ ...doctor, isDoctor: true } as any);
            }
            return allPics ?? [];
        };

        const columns: IColumnsProps = [
            {
                title: Messages.item,
                dataIndex: "material",
                render: (material: IMaterial) => (
                    <div>
                        <div>{material?.name}</div>
                        <div className="small">{`${Messages.sku}: ${material?.sku}`}</div>
                    </div>
                ),
            },
            {
                title: Messages.unit,
                dataIndex: "material",
                render: (material: IMaterial) => material?.trackingUnit?.name,
            },
            {
                title: Messages.actualUsed,
                dataIndex: "quantity",
                render: (quantity, item) =>
                    item.provideAfterTreatment
                        ? Messages.provideAfterTreatment
                        : quantity,
            },
        ];

        const picColumns: IColumnsProps = [
            {
                title: Messages.staffName,
                dataIndex: "adminUser",
                render: (adminUser: any) =>
                    `${adminUser?.firstName} ${adminUser?.lastName}`,
            },
            {
                title: Messages.employeeId,
                dataIndex: "adminUser",
                render: (adminUser) => adminUser?.companyId,
            },
            {
                title: Messages.jobCostRole,
                dataIndex: "",
                width: 300,
                render: (user: ITreatmentUser) => {
                    const { jobCost } = user || {};
                    if (!jobCost || !jobCost.length) {
                        return "N/A";
                    }
                    return (
                        <div className="text-nowrap max-w-xl">
                            {map(jobCost, (i) => {
                                return (
                                    <UserJobCostRoleView
                                        jobCostRole={i?.type}
                                        className="mt-2"
                                    />
                                );
                            })}
                        </div>
                    );
                },
            },
        ];
        return (
            <div className="p-5">
                <div className="d-flex flex-column justify-content-between h-100 w-100">
                    <div className="d-flex flex-row justify-content-between">
                        <img
                            src="/images/logo.png"
                            alt=""
                            className="mb-5 h-[100px]"
                            style={{ width: "auto", objectFit: "contain" }}
                        />
                        <h4>Material Actual Used</h4>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column flex-grow-1 w-50 mr-3">
                            {renderPrintInfo(
                                Messages.customer,
                                `${customer?.fullNameTh}(CN:${customer?.customerNo})`
                            )}
                            {renderPrintInfo(
                                Messages.SO,
                                doctorOrder?.order?.orderNo ?? ""
                            )}
                            {renderPrintInfo(Messages.treatmentNo, treatmentNo)}
                        </div>
                        <div className="d-flex flex-column flex-grow-1 w-50">
                            {renderPrintInfo(Messages.service, treatmentName)}
                            {renderPrintInfo(
                                Messages.dateOfTreatment,
                                TimeUtils.toDateTime(day)
                            )}
                            {renderPrintInfo(Messages.branch, branch?.name)}
                        </div>
                    </div>
                    <Table
                        rowClassName="treatment-material__table-row"
                        columns={picColumns}
                        dataSource={picDataSource()}
                        className="mt-3 w-auto"
                        pagination={false}
                    />

                    <Table
                        rowClassName="treatment-material__table-row"
                        columns={columns}
                        dataSource={materials}
                        className="mt-3 w-auto"
                        pagination={false}
                    />
                </div>
            </div>
        );
    };

    const renderLayoutPrint = () => {
        switch (printMode) {
            case TreatmentPrintMode.PRINT_MATERIAL:
                return renderMaterialPrinting();

            case TreatmentPrintMode.PRINT_TREATMENT:
                return renderTreatmentPrinting();

            default:
                return <div />;
        }
    };

    return (
        <TreatmentDetailContext.Provider
            value={{
                treatment,
                setTreatment,
                onPrint,
                onPrintMaterial,
            }}
        >
            <div className="printClass  flex-col">{renderLayoutPrint()}</div>
            <div className="noPrintClass flex-column-container bg-white">
                <TreatmentDetailHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <CustomerInfoBar
                                customer={treatment?.customer}
                                onUpdate={() => loadTreatmentDetail()}
                            />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={renderTabs()}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </TreatmentDetailContext.Provider>
    );
};

export default TreatmentDetail;
