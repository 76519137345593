import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import { VENDOR_STATUSES } from "../../../constant/vendor";
import { VendorDetailContext } from "../../../context/vendor";
import { IVendor } from "../../../interfaces/vendor";
import Messages from "../../../languages/Messages";
import Image from "../../../common/Image";

const VendorDetailSummaryView = () => {
    const { vendor } = useContext(VendorDetailContext);
    const DETAILS_KEY: IRowsKey<IVendor>[] = [
        {
            id: "vendorNo",
            label: Messages.id,
        },
        {
            id: "countryOfOrigin",
            label: Messages.countryOfOrigin,
            renderContent: ({ data }) => {
                return data?.name;
            },
        },
        {
            id: "name",
            label: Messages.name,
        },
        {
            id: "codeNumber",
            label: Messages.companyRegNo,
        },
        {
            id: "phone",
            label: Messages.phone,
        },

        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
    ];

    return (
        <div>
            {vendor && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <Image
                            src={vendor?.picture}
                            className="image-square-medium"
                        />
                        <div className="text-large-bold my-3">
                            {vendor?.name}
                        </div>
                        <ViewLabelStatus
                            status={vendor?.status}
                            listStatus={VENDOR_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={vendor}
                            keyList={DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VendorDetailSummaryView;
