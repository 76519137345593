import React from "react";
import { IBooking } from "../interfaces/booking";

interface IBookingCreateState {
    bookingForm: any;
}

const bookingCreateState: IBookingCreateState = {
    bookingForm: null,
};

export const BookingCreateContext = React.createContext(bookingCreateState);

interface IBookingDetailState {
    booking: IBooking;
    setBooking: any;
    loadBookingDetail: any;
}

const bookingDetailState: IBookingDetailState = {
    booking: {} as any,
    setBooking: () => {},
    loadBookingDetail: () => {},
};

export const BookingDetailContext = React.createContext(bookingDetailState);
