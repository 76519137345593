import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
    StringUtils,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, forEach } from "lodash";
import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import TemporaryScheduleAPI from "../../../api/queries/temporary-schedule";
import { TemporaryScheduleSchema } from "../../../formschema/temporary-schedule";
import { mapTemporaryScheduleToServer } from "../../../interfaces/temporary-schedule";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserSelect from "../../user/share/UserSelect";
import AddScheduleWorkingTimeDrawer from "../share/AddScheduleWorkingTimeDrawer";

const TemporaryScheduleCreate = () => {
    const navigate = useNavigate();
    const [openAddSchedule, setOpenAddSchedule] = useState(false);

    const scheduleForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: TemporaryScheduleSchema,
        onSubmit: (values: any) => {
            const input = mapTemporaryScheduleToServer(values);
            onCreateSchedule(input);
        },
    });

    const scheduleValues = scheduleForm?.values;

    useEffect(() => {
        forEach(Object.keys(scheduleForm.errors), (key) =>
            Notifications.showError(`${scheduleForm.errors[key]}`)
        );
    }, [scheduleForm.errors]);

    const onCreateSchedule = (payload: any) => {
        Progress.show(
            {
                method: TemporaryScheduleAPI.createSchedule,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.TEMPORARY_SCHEDULE_DETAIL, {
                        userId: payload?.userId,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.date,
            dataIndex: "date",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (item) => item?.name,
        },
        {
            title: Messages.from,
            dataIndex: "from",
            render: TimeUtils.toTime,
        },
        {
            title: Messages.to,
            dataIndex: "to",
            render: TimeUtils.toTime,
        },
        {
            title: Messages.action,
            dataIndex: "id",
            render: (id) => (
                <Icon
                    name="remove_circle_outline"
                    className="cursor-control"
                    onClick={() => onRemoveSchedule(id)}
                />
            ),
        },
    ];

    const onAddSchedule = (schedule: any) => {
        const { schedules = [] } = scheduleValues;
        scheduleForm.setFieldValue("schedules", [
            ...schedules,
            { ...schedule, id: StringUtils.getUniqueID() },
        ]);
    };

    const onRemoveSchedule = (scheduleId: any) => {
        const { schedules = [] } = scheduleValues;
        scheduleForm.setFieldValue(
            "schedules",
            filter(schedules, (item) => item?.id !== scheduleId)
        );
    };

    const renderUserSelected = () => {
        if (!scheduleValues.user) return <div />;
        const { firstName, lastName, companyId } = scheduleValues.user;
        return (
            <div className="w-100 mt-3 bg-primary d-flex">
                <img
                    src={scheduleValues.user?.avatar}
                    className="image-square-large"
                />
                <div className="p-2 flex-column">
                    <span className="block text-white font-weight-bold">
                        {`${firstName} ${lastName}`}
                    </span>
                    <span className="block text-white text-sm opacity-75">{`${Messages.doctorNo}: ${companyId}`}</span>
                    <span className="block text-white text-sm opacity-75">{`${Messages.lastOpd}: `}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="flex-column-container bg-white w-100">
            <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                <h5 className="text-primary">{Messages.newSchedule}</h5>
                <Button onClick={() => scheduleForm.handleSubmit()}>
                    {Messages.save}
                </Button>
            </div>
            <div className="p-4">
                <UserSelect
                    onChange={(user) =>
                        scheduleForm.setFieldValue("user", user)
                    }
                />
                {renderUserSelected()}

                <div className="flex-row-between-center mt-3">
                    <label>{Messages.temporaryScheduleDetail}</label>
                    <Button
                        onClick={() => setOpenAddSchedule(true)}
                        iconName="add"
                    >
                        {Messages.add}
                    </Button>
                </div>
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={scheduleValues?.schedules ?? []}
                    className="mt-3"
                    pagination={false}
                />
            </div>
            {openAddSchedule && (
                <AddScheduleWorkingTimeDrawer
                    open={openAddSchedule}
                    onClose={() => setOpenAddSchedule(false)}
                    onSave={onAddSchedule}
                />
            )}
        </div>
    );
};

export default TemporaryScheduleCreate;
