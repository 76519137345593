import React from "react";
import { IDeliveryDriver } from "../interfaces/delivery-driver";

export interface IDeliveryDriverState {
    delivery?: IDeliveryDriver;
    loadDeliveryDetail: () => void;
}

const deliveryDriverState: IDeliveryDriverState = {
    delivery: undefined,
    loadDeliveryDetail: () => {},
};

export const DeliveryDriverDetailContext =
    React.createContext(deliveryDriverState);
