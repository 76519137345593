import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useListCategoryMaterialLazyQuery, useListMaterialPurchaseLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";

import TableAction from "../../../common/TableActions";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { MATERIAL_CATEGORY_STATUSES, MATERIAL_PURCHASE_STATUSES } from "../../../constant/material";
import UserAvatarName from "../../../common/avatar/UserAvatarName";

const MaterialPurchaseTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const [getMaterialPurchase] = useListMaterialPurchaseLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.poNo,
            dataIndex: "materialPurchaseNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            width: 100,
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_PURCHASE_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.vendor,
            dataIndex: "vendor",
            render: (vendor, item) => (
                <>{vendor.name}</>
            ),
        },
        {
            title: Messages.amount,
            dataIndex: "total",
            render: (total, item) => (
                <>THB {total}</>
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (category: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, category)
                        }
                        dataSource={[{ id: "detail", label: "detail" }]}
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return getMaterialPurchase({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.MATERIAL_ORDER_DETAIL, {
                        orderId: item.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.materialOrder}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                onClickNew={() => navigate(Path.MATERIAL_ORDER_CREATE)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default MaterialPurchaseTable;
