/* eslint-disable no-continue */
import { find, forEach, split, replace, map, includes, isEmpty } from "lodash";
import React, { useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useImportContext } from "./context";
import Messages from "../../languages/Messages";
import { exportToCSV, exportToCSVFile } from "../../utils/file";
import { IMPORT_DATA, IMPORT_SAMPLE_DATA, ImportType } from "./constant";

const splitRemoveSpaceString = (stringData = "") => {
    const breakArray = includes(stringData, ";")
        ? split(stringData, ";")
        : split(stringData, ",");
    return map(breakArray, (item) => replace(item, "\r", ""));
};

export function csvJSON(csv: any) {
    const lines = split(csv, "\n");
    const result = [];
    const headers = splitRemoveSpaceString(lines[0]);

    for (let i = 1; i < lines.length; i += 1) {
        if (!lines[i]) continue;
        const obj: any = {};
        const currentLine = splitRemoveSpaceString(lines[i]);

        for (let j = 0; j < headers.length; j += 1) {
            const currentHeader = headers[j];
            let value: any = currentLine[j];
            if (value === "TRUE") {
                value = true;
            }
            if (value === "FALSE") {
                value = false;
            }
            if (!isEmpty(currentHeader)) {
                obj[currentHeader] = value;
            }
        }
        result.push(obj);
    }
    return result;
}

const SelectCSVFile = () => {
    const { formImport, type } = useImportContext();

    const { importFields, fileImport } = formImport.values;

    const onDrop = ([file]: any) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            const data = csvJSON(reader.result);
            formImport.setFieldValue("fileImport", {
                ...fileImport,
                name: file?.name,
                data,
            });
        };
    };

    const onClickDownloadTemplate = () => {
        const importColumnHeader: any[] = [];
        forEach(importFields, (fieldItem) => {
            const { fields = [], name } = fieldItem;
            if (fields?.length > 0) {
                forEach(fields, (fieldChild) => {
                    importColumnHeader.push(`${name}_${fieldChild.name}`);
                });
            } else {
                importColumnHeader.push(fieldItem.name);
            }
        });
        const importColumnRows = IMPORT_SAMPLE_DATA[type as ImportType]?.map(
            (item: any) => {
                const row: any = [];
                forEach(importFields, (fieldItem) => {
                    row.push(item[fieldItem.name]);
                });
                return row;
            }
        );
        exportToCSVFile(
            [importColumnHeader, ...importColumnRows],
            `Import_${type}_file`
        );
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <div className="p-4">
            <div>
                <div
                    className="flex-center border-dashed p-5 flex-column"
                    {...getRootProps()}
                >
                    <img
                        src="/images/CSVIcon.png"
                        id="csvIconImage"
                        className="mt-5"
                    />
                    <input {...getInputProps()} />
                    <text className="font-weight-bold my-3">
                        {Messages.dragAndDropYourCSVHere}
                    </text>
                    <small>{Messages.maximumFileSizeIs5MB}</small>
                    <small className="mb-5 text-primary text-underline">
                        {Messages.orSelectFromYourComputer}
                    </small>
                    <text className="mb-5 text-primary">
                        {fileImport?.name}
                    </text>
                </div>
                <div className="flex-column d-flex">
                    <text className="mt-3">{Messages.notSureHowToStart}</text>
                    <text>- {Messages.downloadACSVFile}</text>
                    <div className="d-flex text">
                        {`- ${Messages.download}`}
                        <div
                            onClick={onClickDownloadTemplate}
                            className="mx-3 text-primary cursor-pointer"
                        >
                            {Messages.blankCSVTemplate}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectCSVFile;
