import {
    Button,
    DialogManager,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import { useContext, useEffect, useState } from "react";
import {
    useCreateCustomerNoteMutation,
    useGetCustomerNotesLazyQuery,
    useRemoveCustomerNoteMutation,
    useUpdateCustomerNoteMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { CustomerDetailContext } from "../../../context/customer";
import { CustomerNoteSchema } from "../../../formschema/customer";
import { ICustomerNote } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

interface ICustomerNoteView {
    note: ICustomerNote;
    onClickEdit: any;
    onReloadNotes: any;
}

const CustomerNoteView = ({
    note,
    onClickEdit,
    onReloadNotes,
}: ICustomerNoteView) => {
    const [removeNote] = useRemoveCustomerNoteMutation();

    const onClickRemoveNote = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            onRemoveNote
        );
    };

    const onRemoveNote = () => {
        Progress.show(
            {
                method: removeNote,
                params: [{ variables: { noteId: note?.id } }],
            },
            () => {
                Notifications.showSuccess(Messages.removeSuccess);
                onReloadNotes();
            }
        );
    };

    return (
        <div className="w-100 border-top py-3">
            <div className="flex-row-between-center">
                <div className="text-bold">{note?.title}</div>
                <div className="d-flex align-items-center">
                    <small>{TimeUtils.toDateTime(note?.createdAt)}</small>
                    <Button
                        iconName="edit"
                        size="x-small"
                        className="ml-2"
                        onClick={onClickEdit}
                    />
                    <Button
                        iconName="delete"
                        size="x-small"
                        className="ml-2"
                        onClick={onClickRemoveNote}
                    />
                </div>
            </div>
            <div className="mt-3">{note?.content}</div>
        </div>
    );
};

interface ICustomerCrudNoteDrawer {
    open: boolean;
    note?: ICustomerNote | null;
    onClose: () => void;
    onReloadNotes: any;
}

const CustomerCrudNoteDrawer = ({
    note,
    open,
    onClose,
    onReloadNotes,
}: ICustomerCrudNoteDrawer) => {
    const { customer } = useContext(CustomerDetailContext);

    const [createCustomerNote] = useCreateCustomerNoteMutation();
    const [updateCustomerNote] = useUpdateCustomerNoteMutation();

    const noteForm = useFormik<any>({
        initialValues: note ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerNoteSchema,
        onSubmit: (values: any) => {
            const input = { title: values?.title, content: values?.content };
            if (note) {
                onUpdateNote(input);
            } else {
                onCreateNote(input);
            }
        },
    });
    const onCreateNote = (input: any) => {
        Progress.show(
            {
                method: createCustomerNote,
                params: [
                    {
                        variables: {
                            customerId: customer?.id as string,
                            payload: input,
                        },
                    },
                ],
            },
            () => {
                onReloadNotes();
                Notifications.showSuccess(Messages.createCustomerNoteSuccess);
                onClose();
            }
        );
    };

    const onUpdateNote = (input: any) => {
        Progress.show(
            {
                method: updateCustomerNote,
                params: [
                    {
                        variables: {
                            noteId: note?.id as string,
                            payload: input,
                        },
                    },
                ],
            },
            () => {
                onReloadNotes();
                Notifications.showSuccess(Messages.updateCustomerNoteSuccess);
                onClose();
            }
        );
    };

    const classNameInput = "col-12 mt-3";
    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.note}
            width="800px"
            onSave={() => {
                noteForm.handleSubmit();
            }}
        >
            <div className="row p-3">
                <InputTextForm
                    form={noteForm}
                    keyData="title"
                    className={classNameInput}
                />
                <InputTextForm
                    form={noteForm}
                    keyData="content"
                    className={classNameInput}
                    multiple
                />
            </div>
        </Drawer>
    );
};

export const CustomerDetailNotes = () => {
    const { customer } = useContext(CustomerDetailContext);
    const [getCustomerNotes] = useGetCustomerNotesLazyQuery();
    const [noteList, setNoteList] = useState<ICustomerNote[]>([]);
    const [openCrudNote, setOpenCrudNote] = useState<{
        open: boolean;
        note: ICustomerNote | null;
    }>({
        open: false,
        note: null,
    });

    useEffect(() => {
        loadCustomerNotes();
    }, []);

    const loadCustomerNotes = () => {
        getCustomerNotes({
            variables: {
                paginate: { page: 1, limit: 1000 },
                customerId: customer?.id as string,
            },
            fetchPolicy: "no-cache",
        }).then((res) => {
            const noteList = res?.data?.data?.data ?? [];
            setNoteList(noteList as any);
        });
    };

    return (
        <div className="p-4 border mt-3">
            <div className="flex-row-between-center">
                <h5>{Messages.note}</h5>
                <Button
                    iconName="add"
                    onClick={() => setOpenCrudNote({ open: true, note: null })}
                    className=""
                >
                    {Messages.new}
                </Button>
            </div>
            <div className="mt-3">
                {map(noteList, (note) => (
                    <CustomerNoteView
                        note={note}
                        onClickEdit={() =>
                            setOpenCrudNote({ open: true, note })
                        }
                        onReloadNotes={loadCustomerNotes}
                    />
                ))}
            </div>

            {openCrudNote.open && (
                <CustomerCrudNoteDrawer
                    open={openCrudNote.open}
                    onClose={() => setOpenCrudNote({ open: false, note: null })}
                    note={openCrudNote.note}
                    onReloadNotes={loadCustomerNotes}
                />
            )}
        </div>
    );
};
