import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_SERVICE_CONFIGURATION } from "./service";

export const F_PRODUCT_CATEGORY = gql`
    fragment F_PRODUCT_CATEGORY on AdminCategoryProduct {
        id
        updatedAt
        createdAt
        name
        categoryNo
        status
        description
        commissionType
    }
`;

export const F_PRODUCT_PRICE_SET = gql`
    fragment F_PRODUCT_PRICE_SET on PriceSetDto {
        regularPrice
        salePrice
        priceSetType
    }
`;

export const F_PRODUCT_BASIC = gql`
    fragment F_PRODUCT_BASIC on AdminProductBasic {
        id
        name
        productNo
        status
        sku
        unit
        description
        gallery
        thumbnail
        salePrice
        isNonVAT
    }
`;

export const F_PRODUCT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_SERVICE_CONFIGURATION}
    ${F_PRODUCT_CATEGORY}
    ${F_PRODUCT_BASIC}
    ${F_PRODUCT_PRICE_SET}
    fragment F_PRODUCT on AdminProduct {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        name
        productNo
        status
        sku
        category {
            ...F_PRODUCT_CATEGORY
        }
        isVisibility
        weight
        regularPrice
        salePrice
        isSellOutOfStock
        isGenerateGiftVoucher
        isOperationService
        isNonVAT
        description
        thumbnail
        gallery
        typeOfProduct
        isMedicine
        service {
            ...F_SERVICE_CONFIGURATION
        }
        unit
        premiumService {
            spending
            premiumServiceType
            product {
                ...F_PRODUCT_BASIC
                regularPrice
                salePrice
            }
        }
        translations {
            language
            name
            description
        }
        groups {
            quantity
            salePrice
            regularPrice
            isDynamicPrice
            voucherType
            usageType
            product {
                ...F_PRODUCT_BASIC
            }
            priceSet {
                ...F_PRODUCT_PRICE_SET
            }
        }
        priceSet {
            ...F_PRODUCT_PRICE_SET
        }
        isGenerateGiftVoucher
        expiredAfterDay
        expiredAfterMonth
        expiredAfterYear
    }
`;

export const F_PRODUCT_ITEM = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_PRODUCT_CATEGORY}
    ${F_PRODUCT_PRICE_SET}
    fragment F_PRODUCT_ITEM on AdminProduct {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        name
        productNo
        status
        sku
        category {
            ...F_PRODUCT_CATEGORY
        }
        isVisibility
        weight
        regularPrice
        salePrice
        isSellOutOfStock
        description
        thumbnail
        gallery
        typeOfProduct
        unit
        priceSet {
            ...F_PRODUCT_PRICE_SET
        }
    }
`;

export const F_PRODUCTS = gql`
    ${F_PRODUCT_ITEM}
    ${F_PAGINATE}
    fragment F_PRODUCTS on ListAdminProductResponse {
        data {
            ...F_PRODUCT_ITEM
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_PRODUCT_CATEGORIES = gql`
    ${F_PRODUCT_CATEGORY}
    ${F_PAGINATE}
    fragment F_PRODUCT_CATEGORIES on ListAdminCategoryProductResponse {
        data {
            ...F_PRODUCT_CATEGORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_PRODUCT_ITEM_FULL_DETAILS = gql`
    ${F_PRODUCT_BASIC}
    ${F_PRODUCT_PRICE_SET}
    fragment F_PRODUCT_ITEM_FULL_DETAILS on AdminSearchProduct {
        id
        updatedAt
        createdAt
        name
        productNo
        status
        sku
        category {
            ...F_PRODUCT_CATEGORY
        }
        isVisibility
        weight
        regularPrice
        salePrice
        isSellOutOfStock
        isNonVAT
        isGenerateGiftVoucher
        description
        thumbnail
        gallery
        typeOfProduct
        unit
        groups {
            product {
                ...F_PRODUCT_BASIC
            }
            quantity
            salePrice
            isDynamicPrice
            priceSet {
                ...F_PRODUCT_PRICE_SET
            }
        }
        premiumService {
            product {
                ...F_PRODUCT_BASIC
            }
            spending
            premiumServiceType
        }
        priceSet {
            ...F_PRODUCT_PRICE_SET
        }
        promotion
    }
`;

export const F_PRODUCTS_FULL_DETAILS = gql`
    ${F_PRODUCT_ITEM_FULL_DETAILS}
    ${F_PAGINATE}
    fragment F_PRODUCTS_FULL_DETAILS on SearchAdminProductResponse {
        data {
            ...F_PRODUCT_ITEM_FULL_DETAILS
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
