import { InputText } from "d-react-components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";

const RemarkDrawer = ({
    open,
    onClose,
    onSave,
    remark,
}: {
    open: boolean;
    onClose: any;
    onSave: any;
    remark?: string;
}) => {
    const [noteContent, setNoteContent] = useState(remark || "");

    return (
        <Drawer
            title={Messages.remark}
            open={open}
            onClose={onClose}
            destroyOnClose
            onSave={() => {
                onSave(noteContent);
            }}
        >
            <div className="p-4">
                <InputText
                    label={Messages.remark}
                    value={noteContent}
                    onChange={(e) => setNoteContent(e.target.value)}
                    multiple
                    rows={5}
                />
            </div>
        </Drawer>
    );
};

export default RemarkDrawer;
