/* eslint-disable no-nested-ternary */
import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import StockRequestAPI from "../../../../api/queries/stock-request";
import { IFieldSelect } from "../../../../interfaces/select";
import Messages from "../../../../languages/Messages";

export interface IBranchSelect<T> extends IFieldSelect<T> {
    params?: any;
}

const StockRequestSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    params = {},
    ...selectProps
}: IBranchSelect<any>) => {
    const [stockRequestList, setStockRequestList] = useState<any[]>(
        isArray(value) ? value : [value]
    );

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setStockRequestList([]);
        } else {
            StockRequestAPI.list({
                page: 1,
                limit: 15,
                // search: textSearch,
                // ...params,
            }).then((res: any) => {
                const requestRes = res?.data || [];
                const selectedValue = isArray(value)
                    ? value
                    : value
                    ? [value]
                    : [];
                const filterBranch = map(
                    [...selectedValue, ...requestRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        requestNo: item.requestNo,
                    })
                );
                const uniqData = uniqBy(filterBranch, (item) => item.id);
                setStockRequestList(uniqData);
            });
        }
    }, 0);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(stockRequestList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(stockRequestList, id));
    };

    const branchValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.stockRequest}
            dataSource={stockRequestList}
            getLabel={(item) => item?.requestNo}
            value={branchValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSelect}
            showSearch
            filterOption={false}
            {...selectProps}
        />
    );
};

export default StockRequestSelect;
