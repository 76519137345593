import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import VoucherAPI from "../../../api/queries/voucher";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { VoucherDetailContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";

interface IRemarkUpdateDrawer {
    open: boolean;
    onClose: () => void;
}

const RemarkUpdateDrawer = ({ open, onClose }: IRemarkUpdateDrawer) => {
    const { setVoucher, voucher } = useContext(VoucherDetailContext);
    const remarkForm = useFormik<any>({
        initialValues: { remark: voucher?.remark } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            onUpdateRemark(values);
        },
    });

    const onUpdateRemark = (input: any) => {
        if (!input?.remark) return;
        Progress.show(
            {
                method: VoucherAPI.updateRemark,
                params: [voucher?.id, input?.remark],
            },
            (newVoucher: any) => {
                setVoucher(newVoucher);
                Notifications.showSuccess(Messages.updateSuccess);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.remark}
            onSave={() => remarkForm.handleSubmit()}
            size="medium"
        >
            <div className="p-3">
                <InputTextForm form={remarkForm} keyData="remark" multiple />
            </div>
        </Drawer>
    );
};

const VoucherDetailRemark = () => {
    const { voucher } = useContext(VoucherDetailContext);
    const [openEdit, setOpenEdit] = useState(false);
    return (
        <div className="mt-3 p-3 border flex-column">
            <div className="flex-row-between-center">
                <label>{Messages.remark}</label>
                <Button
                    onClick={() => setOpenEdit(true)}
                    className=""
                    variant="trans"
                >
                    {Messages.edit}
                </Button>
            </div>
            <text>{voucher?.remark}</text>
            {openEdit && (
                <RemarkUpdateDrawer
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

export default VoucherDetailRemark;
