import { Button, Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find, forEach, isEmpty, omit } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
    JobCostTemplate,
    useCreateAdminUserMutation,
    useGetAdminDetailLazyQuery,
    useRoleListQuery,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
// import UserAPI from "../../../api/user/UserAPI";
import VerticalTabView from "../../../common/VerticalTabView";
import { PERMISSION, USER_STATUS } from "../../../constant/user";
import { UserCrudContext } from "../../../context/user";
import {
    UserCreateSchema,
    UserPasswordSchema,
    UserUpdateInfoSchema,
} from "../../../formschema/user";
import {
    JobType,
    mapUserGeneralInfoToSer,
    mapUserPasswordToSer,
    mapUserResetPasswordToSer,
} from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserCrudInfo from "./UserCrudInfo";
import UserCrudOtpAuth from "./UserCrudOtpAuth";
import UserCrudPassword from "./UserCrudPassword";
import UserCrudSetting from "./UserCrudSetting";

const TABS = [
    {
        id: "general",
        label: Messages.personalInfo,
        component: <UserCrudInfo />,
    },

    {
        id: "password",
        label: Messages.password,
        component: <UserCrudPassword />,
    },
    // {
    //     id: "role",
    //     label: Messages.role,
    //     component: <UserCrudRoles />,
    // },
    {
        id: "setting",
        label: Messages.settings,
        component: <UserCrudSetting isCreate />,
    },
    {
        id: "otpAuth",
        label: Messages.authentication,
        component: <UserCrudOtpAuth />,
    },
];

const UserCrud = () => {
    const { userId, duplicateId } = useParams<any>();
    const isEdit = useMemo(() => !!userId, [userId]);
    const navigate = useNavigate();
    const [createAdminUser] = useCreateAdminUserMutation();
    const [getAdminDetail, { data, loading, refetch }] =
        useGetAdminDetailLazyQuery();
    const { data: dataRole } = useRoleListQuery({
        variables: {
            paginate: {
                limit: 1000,
                page: 1,
            },
        },
    });
    const roleList = useMemo(() => {
        return dataRole?.listAdminRoleForAdmin?.data ?? [];
    }, [dataRole]);

    const userForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        validate: (values) => {
            const error: any = {};
            if (!isEdit && values.password !== values.confirmPassword) {
                error.confirmPassword = "Confirm password not match!";
            }

            if (values.isOtpAuth && isEmpty(values.otpAuthSecretCode)) {
                error.otpAuthSecretCode = "OTP Authenticator Code require!";
            }
            return error;
        },
        onSubmit: (values) => {
            const bodyInfo = mapUserGeneralInfoToSer(values);
            const bodyPass = mapUserPasswordToSer(values);
            if (isEdit) {
                onUpdateUser(bodyInfo);
            } else {
                onCreateUser(bodyInfo);
            }
        },
    });

    const { values } = userForm;

    const userUpdatePassForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UserPasswordSchema,
        validate: (values) => {
            const error: any = {};
            if (values.password !== values.confirmPassword) {
                error.confirmPassword = "Confirm password not match!";
            }
            return error;
        },
        onSubmit: (values) => {
            const bodyPass = mapUserResetPasswordToSer(values);
            onResetPassUser(bodyPass);
        },
    });

    const [selectedTab, setSelectedTab] = useState<any>(TABS[0]);

    useEffect(() => {
        if (duplicateId && duplicateId?.length === 24) {
            loadUserDetail();
        }
    }, [duplicateId]);

    useEffect(() => {
        forEach(Object.keys(userForm.errors), (key) =>
            Notifications.showError(JSON.stringify(userForm.errors[key]))
        );
    }, [userForm.errors]);

    const loadUserDetail = () => {
        Progress.show(
            {
                method: getAdminDetail,
                params: [
                    {
                        variables: {
                            id: duplicateId as string,
                        },
                    },
                ],
            },
            (res: any) => {
                const data = res?.data?.detailAdminUserForAdmin?.data ?? {};
                if (!isEmpty(data)) {
                    const dupData = omit(data, [
                        "adminNo",
                        "createdAt",
                        "updatedAt",
                        "authenticationCode",
                        "authenticationStatus",
                        "branch",
                        "role",
                        "id",
                        "specialAccess",
                        "defaultWorkingSchedule",
                        "__typename",
                    ]);
                    const modifiedData: any = {};

                    Object.keys(dupData).forEach((key) => {
                        const value = dupData?.[key];
                        modifiedData[key] = `${value}_duplicated`;
                    });
                    userForm.setValues({
                        ...modifiedData,
                        avatar: data?.avatar,
                        jobType: data?.jobType,
                        roleId: data?.role?.id,
                        orderStatusManagement: data?.orderStatusManagement,
                        deliveryStatusManagement:
                            data?.deliveryStatusManagement,
                        status: data?.status
                            ? USER_STATUS.ACTIVE
                            : USER_STATUS.INACTIVE,
                    });
                }
            }
        );
    };

    const onUpdateUser = (input: any) => {
        Progress.show(
            {
                // method: UserAPI.update,
                method: () => Promise.resolve([]),
                params: [userId, input],
            },
            (userDetail: any) => {
                userForm.setValues(userDetail);
                Notifications.showSuccess(Messages.updateCustomerSuccessfully);
            }
        );
    };

    const onResetPassUser = (input: any) => {
        Progress.show(
            {
                method: () => Promise.resolve([]),
                // method: UserAPI.resetPassword,
                params: [userId, input],
            },
            (userDetail: any) => {
                userForm.setValues(userDetail);
                Notifications.showSuccess(
                    Messages.resetUserPasswordSuccessfully
                );
            }
        );
    };

    const onCreateUser = (input: any) => {
        if (input?.jobType === JobType.THERAPIST) {
            Object.assign(input, {
                therapistFee: [
                    {
                        template: JobCostTemplate.Jc1Template,
                        amount: parseFloat(values?.feeTemplate1),
                    },
                    {
                        template: JobCostTemplate.Jc3Template,
                        amount: parseFloat(values?.feeTemplate3),
                    },
                ],
            });
        }

        const body = {
            variables: {
                payload: { ...input },
            },
        };

        Progress.show(
            {
                method: createAdminUser,
                params: [body],
            },
            (resp: any) => {
                const newUser = resp?.data?.createAdminUserForAdmin?.data;
                navigate(
                    generatePath(Path.USER_DETAIL, { userId: newUser.id })
                );
                Notifications.showSuccess(Messages.createUserSuccessfully);
            }
        );
    };

    const renderHeaderButtons = () => (
        <div className="flex-center" hidden={!isEdit}>
            <Button variant="outline" className="mr-3">
                {Messages.back}
            </Button>
            <Button
                className="mr-3"
                onClick={() => userUpdatePassForm.handleSubmit()}
                hidden={isEmpty(userUpdatePassForm?.values?.password)}
            >
                {Messages.sendResetPassword}
            </Button>
        </div>
    );

    return (
        <UserCrudContext.Provider
            value={
                {
                    userForm,
                    userUpdatePassForm,
                    roleList,
                } as any
            }
        >
            <Header
                className="sticky top-0"
                showCancel={false}
                title={Messages.userDetail}
                customRight={() => (
                    <>
                        <PermissibleRender
                            permission={PERMISSION.ADMIN.USER.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => userForm.handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="h-100 overflow-auto p-3 bg-white">
                <div className="row">
                    <div className="col-sm-3">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={setSelectedTab}
                        />
                    </div>
                    <form className="col-sm-9">
                        <div className="border p-4">
                            {
                                find(TABS, (item) => selectedTab.id === item.id)
                                    ?.component
                            }
                        </div>
                    </form>
                </div>
            </div>
        </UserCrudContext.Provider>
    );
};

export default UserCrud;
