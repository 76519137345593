import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import { TRANSFER_VOUCHER_STATUSES } from "../../../constant/transfer-voucher";
import { TransferVoucherContext } from "../../../context/voucher";

import Messages from "../../../languages/Messages";
import { ITransferVoucher } from "../../../interfaces/transfer-voucher";

const TransferVoucherSummaryView = () => {
    const { transferDetail } = useContext(TransferVoucherContext);

    const DETAILS_KEY: IRowsKey<ITransferVoucher>[] = [
        {
            id: "transferVoucherNo",
            label: Messages.id,
        },
        {
            id: "from",
            label: Messages.transferFromCustomer,
            renderContent: ({ data }) => {
                return (
                    <div className="d-flex justify-content-end">
                        <CustomerAvatarName size="xx-small" customer={data} />
                    </div>
                );
            },
        },
        {
            id: "to",
            label: Messages.transferToCustomer,
            renderContent: ({ data }) => {
                return (
                    <div className="d-flex justify-content-end">
                        <CustomerAvatarName size="xx-small" customer={data} />
                    </div>
                );
            },
        },

        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
    ];

    return (
        <div>
            {transferDetail && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            {transferDetail?.transferVoucherNo}
                        </div>
                        <ViewLabelStatus
                            status={transferDetail?.status}
                            listStatus={TRANSFER_VOUCHER_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={transferDetail}
                            keyList={DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransferVoucherSummaryView;
