import classNames from "classnames";
import {
    IRowsKey,
    Notifications,
    Progress,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import { Fragment, useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import {
    JobCostTemplate,
    JobCostType,
    useRoleListQuery,
    useUpdateAdminUserMutation,
    useUpdateAdminUserTherapistFeeMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import Tooltip from "../../../common/views/Tooltip";
import {
    DELIVERY_STATUS_MANAGEMENTS,
    JOB_TYPES,
    ORDER_STATUS_MANAGEMENTS,
    USER_STATUSES,
} from "../../../constant/user";
import { UserCrudContext, UserDetailContext } from "../../../context/user";
import IUser, {
    JobType,
    mapUserGeneralInfoToSer,
} from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import UserCrudSetting, {
    UserCrudAnesthesiologistFee,
    UserCrudDoctorFee,
    UserCrudTherapistFee,
} from "../crud/UserCrudSetting";

const SETTINGS_KEY: IRowsKey<IUser>[] = [
    {
        id: "role",
        label: Messages.role,
        renderContent: ({ data }) => {
            return data?.name;
        },
    },
    {
        id: "jobType",
        label: Messages.jobType,
        renderContent: ({ data }) => {
            const jobType = find(JOB_TYPES, { key: data })?.label;
            return jobType ? Messages[jobType] : "N/A";
        },
    },
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => {
            const status = data
                ? USER_STATUSES[0].label
                : USER_STATUSES[1].label;
            return status ? Messages[status] : "N/A";
        },
    },
    {
        id: "jobCostFeature",
        label: Messages.jobCostFeature,
        renderContent: ({ data }) => {
            return (
                <div className="relative !max-w-fit">
                    <Tooltip
                        title={
                            <div className="p-2">
                                Allow users to view the job cost assignment in
                                treatment or not - Default is Disabled
                            </div>
                        }
                        className="flex-center-y gap-3"
                        iconProps={{ color: "red" }}
                    >
                        <div>{data ? "Enabled" : "Disabled"}</div>
                    </Tooltip>
                </div>
            );
        },
    },
    {
        id: "orderStatusManagement",
        label: Messages.orderStatusManagement,
        renderContent: ({ data }) => {
            return data?.map((item: any, index: number) => {
                const statusItem = find(ORDER_STATUS_MANAGEMENTS, {
                    key: item,
                })?.label;
                return (
                    <div
                        className={classNames({
                            "mb-2": index !== data.length - 1,
                        })}
                    >
                        {statusItem ? Messages[statusItem] : "N/A"}
                    </div>
                );
            });
        },
    },
    {
        id: "deliveryStatusManagement",
        label: Messages.deliveryStatusManagement,
        renderContent: ({ data }) => {
            return data?.map((item: any, index: number) => {
                const statusItem = find(DELIVERY_STATUS_MANAGEMENTS, {
                    key: item,
                })?.label;
                return (
                    <div
                        className={classNames({
                            "mb-2": index !== data.length - 1,
                        })}
                    >
                        {statusItem ? Messages[statusItem] : "N/A"}
                    </div>
                );
            });
        },
    },
];

export const RegNumber = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");

const mapUserJobCostFeeFromServer = (
    jobType: JobType,
    jobCostFee: IUser["jobCostFee"]
) => {
    const template1 = find(
        jobCostFee,
        (item) => item?.template === JobCostTemplate.Jc1Template
    );
    const template2 = find(
        jobCostFee,
        (item) => item?.template === JobCostTemplate.Jc2Template
    );
    const template3 = find(
        jobCostFee,
        (item) => item?.template === JobCostTemplate.Jc3Template
    );
    if (jobType === JobType.DOCTOR) {
        const foundDoctorFee = find(
            template2?.presets ?? [],
            (item) => item?.type === JobCostType?.DoctorFee
        );
        const foundAnesthesiologistFee = find(
            template2?.presets ?? [],
            (item) => item?.type === JobCostType?.AnesthesiologistFee
        );
        return {
            amountGA: foundDoctorFee?.primaryFee?.amount,
            amountLA: foundDoctorFee?.primaryFee?.amountSecondary,
            primaryFee: foundAnesthesiologistFee?.primaryFee?.amount,
            additionalFee: foundAnesthesiologistFee?.secondaryFee?.amount,
        };
    }
    if (jobType === JobType.NURSE) {
        const foundAnesthesiologistFee = find(
            template2?.presets ?? [],
            (item) => item?.type === JobCostType?.AnesthesiologistFee
        );
        return {
            primaryFee: foundAnesthesiologistFee?.primaryFee?.amount,
            additionalFee: foundAnesthesiologistFee?.secondaryFee?.amount,
        };
    }
    if (jobType === JobType.THERAPIST) {
        const foundTherapistFee1 = find(
            template1?.presets ?? [],
            (item) => item?.type === JobCostType?.TherapistFee
        );
        const foundTherapistFee3 = find(
            template3?.presets ?? [],
            (item) => item?.type === JobCostType?.TherapistFee
        );
        return {
            feeTemplate1: foundTherapistFee1?.primaryFee?.amount,
            feeTemplate3: foundTherapistFee3?.primaryFee?.amount,
        };
    }
    return {};
};

const mapUserJobCostFeeToServer = (jobType: JobType, values: any) => {
    const {
        feeTemplate1,
        feeTemplate3,
        amountGA,
        amountLA,
        primaryFee,
        additionalFee,
    } = values || {};

    if (jobType === JobType.DOCTOR) {
        return [
            {
                template: JobCostTemplate.Jc2Template,
                presets: [
                    {
                        type: JobCostType.DoctorFee,
                        role: JobType.DOCTOR,
                        primaryFee: {
                            amount: parseFloat(amountGA),
                            amountSecondary: parseFloat(amountLA),
                        },
                    },
                    {
                        type: JobCostType.AnesthesiologistFee,
                        role: JobType.DOCTOR,
                        primaryFee: {
                            amount: parseFloat(primaryFee),
                        },
                        secondaryFee: {
                            amount: parseFloat(additionalFee),
                        },
                    },
                ],
            },
        ];
    }
    if (jobType === JobType.NURSE) {
        return [
            {
                template: JobCostTemplate.Jc2Template,
                presets: [
                    {
                        type: JobCostType.AnesthesiologistFee,
                        role: JobType.NURSE,
                        primaryFee: {
                            amount: parseFloat(primaryFee),
                        },
                        secondaryFee: {
                            amount: parseFloat(additionalFee),
                        },
                    },
                ],
            },
        ];
    }
    if (jobType === JobType.THERAPIST) {
        return [
            {
                template: JobCostTemplate.Jc1Template,
                presets: [
                    {
                        type: JobCostType.TherapistFee,
                        role: JobType.THERAPIST,
                        primaryFee: {
                            amount: parseFloat(feeTemplate1),
                        },
                    },
                ],
            },
            {
                template: JobCostTemplate.Jc3Template,
                presets: [
                    {
                        type: JobCostType.TherapistFee,
                        role: JobType.THERAPIST,
                        primaryFee: {
                            amount: parseFloat(feeTemplate3),
                        },
                    },
                ],
            },
        ];
    }
    return null;
};

const UserTherapistFeeSchema = Yup.object().shape({
    feeTemplate1: Yup.string()
        .required(Messages.isRequired)
        .matches(RegNumber, {
            message: "Not a number",
        }),
    feeTemplate3: Yup.string()
        .required(Messages.isRequired)
        .matches(RegNumber, {
            message: "Not a number",
        }),
});

const UserDetailSetting = () => {
    const { user } = useContext(UserDetailContext);
    const { jobType, jobCostFee } = user || {};
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEditFeeModal, setShowEditFeeModal] = useState<{
        open: boolean;
        type?: any;
    }>({ open: false });

    const renderTherapistFee = () => {
        const values = mapUserJobCostFeeFromServer(
            JobType.THERAPIST,
            jobCostFee
        );
        const { feeTemplate1, feeTemplate3 } = values || {};
        return (
            <div className="px-4 py-3 border my-3">
                <div className="flex mb-2">
                    <h5 className="text-[16px] flex-1">
                        {Messages.therapistFee}
                    </h5>
                    <span
                        className="cursor-pointer text-red-500"
                        onClick={() =>
                            setShowEditFeeModal({
                                open: true,
                                type: JobType.THERAPIST,
                            })
                        }
                    >
                        {Messages.edit}
                    </span>
                </div>
                <div className="flex flex-row">
                    <div className="w-100">{Messages.defaultTherapistRate}</div>
                    <div className="w-100">
                        <div className="">
                            <div>{Messages.JcTemplate_1}</div>
                            <div className="text-green-700 mt-3">
                                {feeTemplate1 ?? "N/A"} THB
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>{Messages.JcTemplate_3}</div>
                            <div className="text-green-700 mt-3">
                                {feeTemplate3 ?? "N/A"} THB/graft
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDoctorFee = () => {
        const values = mapUserJobCostFeeFromServer(JobType.DOCTOR, jobCostFee);
        const { amountGA, amountLA, primaryFee, additionalFee } = values || {};
        return (
            <div className="px-4 py-3 border my-3">
                <div className="flex mb-2">
                    <h5 className="text-[16px] flex-1">{Messages.fees}</h5>
                    <span
                        className="cursor-pointer text-red-500"
                        onClick={() =>
                            setShowEditFeeModal({
                                open: true,
                                type: JobType.DOCTOR,
                            })
                        }
                    >
                        {Messages.edit}
                    </span>
                </div>
                <div className="flex flex-row">
                    <div className="w-100">{Messages.defaultDoctorRate}</div>
                    <div className="w-100">
                        <div className="">
                            <div>{Messages.gaSurgery}</div>
                            <div className="text-green-700 mt-3">
                                {amountGA || "N/A"} %
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>{Messages.laSurgery}</div>
                            <div className="text-green-700 mt-3">
                                {amountLA || "N/A"} %
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row mt-3 border-top pt-3">
                    <div className="w-100">
                        {Messages.defaultAnesthesiologistRate}
                    </div>
                    <div className="w-100">
                        <div className="">
                            <div>{Messages.primaryFee}</div>
                            <div className="text-green-700 mt-3">
                                {primaryFee || "N/A"} THB
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>{Messages.additionalFeePer}</div>
                            <div className="text-green-700 mt-3">
                                {additionalFee || "N/A"} THB
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNurseFee = () => {
        const values = mapUserJobCostFeeFromServer(JobType.DOCTOR, jobCostFee);
        const { primaryFee, additionalFee } = values || {};
        return (
            <div className="px-4 py-3 border my-3">
                <div className="flex mb-2">
                    <h5 className="text-[16px] flex-1">{Messages.fees}</h5>
                    <span
                        className="cursor-pointer text-red-500"
                        onClick={() =>
                            setShowEditFeeModal({
                                open: true,
                                type: JobType.NURSE,
                            })
                        }
                    >
                        {Messages.edit}
                    </span>
                </div>
                <div className="flex flex-row mt-3 border-top pt-3">
                    <div className="w-100">
                        {Messages.defaultAnesthesiologistRate}
                    </div>
                    <div className="w-100">
                        <div className="">
                            <div>{Messages.primaryFee}</div>
                            <div className="text-green-700 mt-3">
                                {primaryFee || "N/A"} THB
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>{Messages.additionalFeePer}</div>
                            <div className="text-green-700 mt-3">
                                {additionalFee || "N/A"} THB
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="px-4 py-3 border my-3">
                <div className="flex mb-2">
                    <h5 className="text-[16px] flex-1">{Messages.settings}</h5>
                    <span
                        className="cursor-pointer text-red-500"
                        onClick={() => setShowEditModal(true)}
                    >
                        {Messages.edit}
                    </span>
                </div>
                <ViewRowInterchange
                    keyList={SETTINGS_KEY}
                    dataSource={user}
                    variant="border"
                />
                {showEditModal && (
                    <ModalEditSetting
                        open={showEditModal}
                        onClose={() => {
                            setShowEditModal(false);
                        }}
                    />
                )}
            </div>
            {/* {jobType === JobType.THERAPIST && renderTherapistFee()}
            {jobType === JobType.DOCTOR && renderDoctorFee()}
            {jobType === JobType.NURSE && renderNurseFee()}
            {showEditFeeModal?.open && showEditFeeModal?.type && (
                <ModalEditTherapist
                    type={showEditFeeModal?.type}
                    open={showEditFeeModal}
                    onClose={() => {
                        setShowEditFeeModal({ open: false });
                    }}
                />
            )} */}
        </Fragment>
    );
};

const ModalEditSetting = ({ open, onClose }: any) => {
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const { data: dataRole } = useRoleListQuery({
        variables: {
            paginate: {
                limit: 1000,
                page: 1,
            },
        },
    });
    const roleList: any[] = useMemo(() => {
        return dataRole?.listAdminRoleForAdmin?.data ?? [];
    }, [dataRole]);

    const userForm = useFormik({
        initialValues: {
            status: user?.status ? USER_STATUSES[0].id : USER_STATUSES[1].id,
            roleId: user?.role?.id,
            jobType: user?.jobType,
            jobCostFeature: user?.jobCostFeature,
            orderStatusManagement: user?.orderStatusManagement,
            deliveryStatusManagement: user?.deliveryStatusManagement,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        onSubmit: (values) => {
            const bodyInfo = mapUserGeneralInfoToSer(values);

            const body = {
                variables: {
                    id: user?.id as string,
                    payload: bodyInfo,
                },
            };

            Progress.show(
                {
                    method: updateAdminUser,
                    params: [body],
                },
                (resp: any) => {
                    loadAdminDetail();
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    return (
        <UserCrudContext.Provider value={{ userForm, roleList }}>
            <ModalEditSettingContent open={open} onClose={onClose} />
        </UserCrudContext.Provider>
    );
};

const ModalEditTherapist = ({ open, onClose, type }: any) => {
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const { jobCostFee } = user || {};
    const [useUpdateAdminUserTherapistFee] =
        useUpdateAdminUserTherapistFeeMutation();
    const userForm = useFormik({
        initialValues: mapUserJobCostFeeFromServer(type, jobCostFee),
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: UserTherapistFeeSchema,
        onSubmit: (values: any) => {
            const bodyInfo = mapUserJobCostFeeToServer(type, values);
            if (!bodyInfo) {
                return;
            }

            const body = {
                variables: {
                    id: user?.id as string,
                    payload: bodyInfo,
                },
            };

            Progress.show(
                {
                    method: useUpdateAdminUserTherapistFee,
                    params: [body],
                },
                (resp: any) => {
                    loadAdminDetail();
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    return (
        <UserCrudContext.Provider value={{ userForm }}>
            {type === JobType.THERAPIST && (
                <ModalEditTherapistContent open={open} onClose={onClose} />
            )}
            {type === JobType.DOCTOR && (
                <ModalEditDoctorContent open={open} onClose={onClose} />
            )}
            {type === JobType.NURSE && (
                <ModalEditNurseContent open={open} onClose={onClose} />
            )}
        </UserCrudContext.Provider>
    );
};

const ModalEditSettingContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;
    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.settings}
            width="800px"
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <UserCrudSetting />
            </div>
        </Drawer>
    );
};

const ModalEditTherapistContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.defaultTherapistRate}
            width="800px"
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <UserCrudTherapistFee />
            </div>
        </Drawer>
    );
};

const ModalEditDoctorContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.defaultDoctorRate}
            width="800px"
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <UserCrudDoctorFee />
                <UserCrudAnesthesiologistFee />
            </div>
        </Drawer>
    );
};

const ModalEditNurseContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.defaultNurseRate}
            width="800px"
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <UserCrudAnesthesiologistFee />
            </div>
        </Drawer>
    );
};

export default UserDetailSetting;
