import { Badge, Button, ButtonProps } from "d-react-components";
import React, { Fragment, useState } from "react";
import Messages from "../../languages/Messages";
import Drawer from "../Drawer";

export interface IListDataButtonProps<T> {
    data: Array<T>;
    label?: string;
    className?: string;
    buttonProps?: ButtonProps;
    renderItem: (item: T) => React.ReactNode;
}

const ListDataButton: React.FC<IListDataButtonProps<any>> = ({
    data,
    label = Messages.list,
    buttonProps,
    className,
    renderItem,
}) => {
    const [showDrawer, setShowDrawer] = useState(false);
    return (
        <Fragment>
            <Button
                className={`w-100 ${className}`}
                variant="outline"
                {...(buttonProps || {})}
                onClick={() => {
                    setShowDrawer(true);
                }}
            >
                {label}
                <Badge
                    variant="index"
                    size="large"
                    className="ml-1"
                    index={data?.length}
                    classNameBadge="!border-gray-300"
                >
                    {null}
                </Badge>
            </Button>
            {showDrawer && (
                <Drawer
                    open={showDrawer}
                    onClose={() => setShowDrawer(false)}
                    title={label}
                    hideFooter
                >
                    <div className="p-3 gap-3">
                        {data?.map((item, index) => (
                            <div key={index + item?.id} className="mb-2">
                                {renderItem(item)}
                            </div>
                        ))}
                    </div>
                </Drawer>
            )}
        </Fragment>
    );
};

export default ListDataButton;
