import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import React, { useContext } from "react";
import { isEmpty } from "lodash";
import { generatePath } from "react-router";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import { DoctorOrderDetailContext } from "../../../context/doctor-order";
import { IDoctorOrder } from "../../../interfaces/doctor-order";
import Messages from "../../../languages/Messages";
import CustomerName from "../../../common/avatar/CustomerName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import AppLink from "../../../common/AppLink";
import Path from "../../Path";

const DoctorOrderGeneralInformation = () => {
    const { doctorOrder } = useContext(DoctorOrderDetailContext);
    const GENERAL_KEYS: IRowsKey<IDoctorOrder>[] = [
        {
            id: "doctorOrderNo",
            label: Messages.doNo,
        },
        //@ts-ignore
        ...(!isEmpty(doctorOrder.order)
            ? [
                  {
                      id: "order",
                      label: Messages.soNo,
                      renderContent: ({ data }: any) => {
                          return (
                              <AppLink
                                  to={generatePath(Path.ORDER_DETAIL, {
                                      orderId: data.id,
                                  })}
                              >
                                  {data.orderNo}
                              </AppLink>
                          );
                      },
                  },
              ]
            : []),

        {
            //@ts-ignore
            id: "booking",
            label: Messages.bookingNo,
            renderContent: ({ data }: any) => {
                return (
                    <AppLink
                        to={generatePath(Path.BOOKING_DETAIL, {
                            bookingId: data.id,
                        })}
                    >
                        {data.bookingNo}
                    </AppLink>
                );
            },
        },
        {
            //@ts-ignore
            id: "booking",
            label: Messages.branch,
            renderContent: ({ data }: any) => {
                return (
                    <div>{`${data?.branch?.name ?? "N/A"} (${
                        data?.branch?.code
                    })`}</div>
                );
            },
        },

        {
            //@ts-ignore
            id: "doctorSubject",
            label: Messages.doctorSubject,
        },
        {
            //@ts-ignore
            id: "status",
            label: Messages.status,
            renderContent: ({ data }) => {
                const status = DOCTOR_ORDER_STATUSES.find(
                    (status) => status.id === data
                );
                return status ? Messages[status.label] : "N/A";
            },
        },
        {
            //@ts-ignore
            id: "doctor",
            label: Messages.doctor,
            renderContent: ({ data }) => {
                return (
                    <UserAvatarName
                        user={data}
                        showInfo={{ showOPD: false, showEmployeeId: false }}
                        size="xx-small"
                    />
                );
            },
        },
        {
            //@ts-ignore
            id: "customer",
            label: Messages.customer,
            renderContent: ({ data }) => {
                return <CustomerName user={data} />;
            },
        },
        {
            //@ts-ignore
            id: "complicationTreatments",
            label: Messages.complicationCase,
            renderContent: ({ data }) => {
                return data?.length ? Messages.yes : Messages.no;
            },
        },
        {
            //@ts-ignore
            id: "createdAt",
            label: Messages.date,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            //@ts-ignore
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];
    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
            </div>
            <ViewRowInterchange
                keyList={GENERAL_KEYS}
                dataSource={doctorOrder}
                variant="background"
            />
        </div>
    );
};

export default DoctorOrderGeneralInformation;
