import {
    AwesomeTableComponent,
    Button,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import React, { useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath, useNavigate } from "react-router-dom";
import { useListQuotationForAdminLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import PermissibleRender from "../../../common/PermissibleComponent";
import TableAction from "../../../common/TableActions";
import { QUOTATION_STATUSES } from "../../../constant/quotation";
import { PERMISSION } from "../../../constant/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerName from "../../../common/avatar/CustomerName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";

const QuotationTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [getListQuotation, { data }] = useListQuotationForAdminLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "quotationNo",
            width: 100,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.QUOTATION_DETAIL, {
                        quotationId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "store",
            render: (item) => item.name,
        },
        // {
        //     title: Messages.typeOfTreatment,
        //     dataIndex: "typeOfTreatment",
        //     render: (item) => item?.name,
        // },
        {
            title: Messages.amount,
            dataIndex: "total",
            render: (amount) => (
                <CurrencyFormat
                    value={amount}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            width: "100px",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={QUOTATION_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (user, item) => (
                <CustomerName user={item.customer || item.contact} />
            ),
            width: "150px",
        },
        {
            title: Messages.staff,
            dataIndex: "createByAdmin",
            render: (user, item) => (
                <UserAvatarName size="x-small" user={user} />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.QUOTATION_DETAIL, {
                        quotationId: item.id,
                    })
                );
        }
    };

    const source = (pagingData: any) => {
        return getListQuotation({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: { createdAt: -1 },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickAddNew = () => {
        navigate(Path.QUOTATION_CREATE);
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
        tableRef.current.refresh();
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    return (
        <div>
            <HeaderTable
                label={Messages.quotation}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                // onSubmitSearch={onSubmitSearch}
                showFilter={false}
                className="app-layout__page-header"
                customButtons={() => (
                    <PermissibleRender permission={PERMISSION.QUOTATION.CREATE}>
                        <Button size="small" onClick={onClickAddNew}>
                            {Messages.new}
                        </Button>
                    </PermissibleRender>
                )}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default QuotationTable;
