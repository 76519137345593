import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";

const DoctorOrderActivity = () => {
    const { orderId } = useParams<any>();
    return (
        <div>
            {orderId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.DoctorOrder}
                    activityRefId={orderId ?? ""}
                />
            )}
        </div>
    );
};

export default DoctorOrderActivity;
