import { ObjectUtils, Select, TimeUtils } from "d-react-components";
import { filter, includes, map } from "lodash";
import { Fragment, useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { TreatmentCreateContext } from "../../../context/treatment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { ProductSolidItem } from "../../product/share/ProductSolidSelect";

const TreatmentCreateProduct = () => {
    const { treatmentForm } = useContext(TreatmentCreateContext);
    const { values: formValues, setFieldValue } = treatmentForm || {};
    const treatmentProducts = map(
        formValues?.doctorOrder?.services ?? [],
        (item) => ({
            ...item.product,
            quantity: item?.quantity ?? 1,
            complicationTreatment: item?.complicationTreatment,
        })
    );

    const onRemove = (productId: string) => {
        const result = ObjectUtils.removeArrayById(
            formValues?.products,
            productId
        );
        treatmentForm.setFieldValue("products", result);
    };

    return (
        <div className="mt-3">
            <Select
                label={Messages.treatment}
                onChange={(value) => {
                    const products = filter(treatmentProducts, (item: any) =>
                        includes(value, item.id)
                    );
                    treatmentForm.setFieldValue("products", products);
                }}
                getLabel={(item) => item?.name}
                getKey={(item) => item?.id + item?.name}
                error={treatmentForm?.errors?.products}
                multiple
                dataSource={treatmentProducts}
            />

            {map(formValues?.products, (product) => {
                const { complicationTreatment } = product || {};
                return (
                    <Fragment>
                        <ProductSolidItem
                            product={product}
                            onClickRemove={() => onRemove(product?.id)}
                            renderContent={() => {
                                return (
                                    <InputTextQuantity
                                        className="mt-2"
                                        value={product?.quantity ?? 1}
                                        onChange={(v) => {
                                            const res = map(
                                                formValues?.products ?? [],
                                                (i) =>
                                                    i?.id === product?.id
                                                        ? { ...i, quantity: v }
                                                        : i
                                            );
                                            setFieldValue("products", res);
                                        }}
                                    />
                                );
                            }}
                        />
                        {complicationTreatment && (
                            <div className="mt-3">
                                <label>{Messages.complicationCaseOf}</label>
                                <div className="bg-primary p-3">
                                    <div className="flex-center-y text-white">
                                        <span className="font-semibold">
                                            ID:
                                        </span>
                                        <AppLink
                                            to={generatePath(
                                                Path.TREATMENT_DETAIL,
                                                {
                                                    treatmentId:
                                                        complicationTreatment?.id ??
                                                        "",
                                                }
                                            )}
                                            target="_blank"
                                        >
                                            <span className="text-white ml-1 font-semibold">
                                                {
                                                    complicationTreatment?.treatmentNo
                                                }
                                            </span>
                                        </AppLink>
                                    </div>
                                    <span className="block text-white text-sm opacity-75">{`${
                                        Messages.createdAt
                                    }: ${TimeUtils.toDateTime(
                                        complicationTreatment?.createdAt
                                    )}`}</span>
                                </div>
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default TreatmentCreateProduct;
