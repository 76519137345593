import {
    AwesomeTableComponent,
    IColumnsProps,
    InputTextSearch,
} from "d-react-components";
import { debounce, filter, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { StockRequestDetailContext } from "../../../../context/stock-request";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/share/ProductNameView";

const StockRequestItems = () => {
    const { stockRequest } = useContext(StockRequestDetailContext);
    const [textSearch, setTextSearch] = useState<string>("");
    const proSource = useMemo(() => {
        const mappedPros = map(stockRequest?.products ?? [], (item) => ({
            ...(item?.product ?? {}),
            stock: item?.stock ?? 0,
        }));
        if (textSearch) {
            return filter(mappedPros, (item) => {
                const { name, sku } = item;
                const text = textSearch?.toLocaleLowerCase?.();
                return (
                    name?.toLocaleLowerCase?.()?.indexOf(text) !== -1 ||
                    sku?.toLocaleLowerCase?.()?.indexOf(text) !== -1
                );
            });
        }
        return mappedPros;
    }, [textSearch, stockRequest?.products]);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (service) => <ProductNameView product={service} />,
        },
        {
            title: Messages.quantity,
            dataIndex: "stock",
        },
    ];

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 0);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={proSource}
                className="mt-3"
                pagination={false}
            />
        </div>
    );
};

export default StockRequestItems;
