import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import parse from "html-react-parser";
import { useContext, useState } from "react";
import ProductAPI from "../../../api/queries/product";
import Drawer from "../../../common/Drawer";
import { ProductDetailContext } from "../../../context/product";
import { ProductSchema } from "../../../formschema/product";
import {
    IProduct,
    mapProductGeneralInfoToServer,
} from "../../../interfaces/product";

import Messages from "../../../languages/Messages";
import ProductGeneralInfoForm from "../share/ProductGeneralInfoForm";

const UpdateDescriptionDrawer = ({ open, onClose }: any) => {
    const { product, setProduct } = useContext(ProductDetailContext);

    const productForm = useFormik<any>({
        initialValues: product ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductSchema,
        onSubmit: (values: any) => {
            const input = mapProductGeneralInfoToServer(values);
            onUpdateProduct(input);
        },
    });

    const onUpdateProduct = (input: any) => {
        Progress.show(
            {
                method: ProductAPI.update,
                params: [product?.id, input],
            },
            (product: any) => {
                setProduct(product);
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.personalInfo}
            width="800px"
            onSave={() => {
                productForm.handleSubmit();
            }}
        >
            <div className="p-3">
                <ProductGeneralInfoForm
                    productForm={productForm}
                    variant="updateDescription"
                />
            </div>
        </Drawer>
    );
};

const ProductDetailDescription = () => {
    const { product } = useContext(ProductDetailContext);
    const [openEditInfo, setOpenEditGeneral] = useState(false);

    return (
        <div className="border p-4 relative note-item__container mb-3">
            <div className=" w-100 flex-row-between-center">
                <label>{Messages.description}</label>
            </div>
            <div className="text mt-2">
                {parse(product?.description ?? "")}
            </div>
            <div className="border-t mt-2 py-2 text-gray">
                <small>
                    {Messages.lastUpdated}:{" "}
                    {TimeUtils.toDateTime(product?.updatedAt)}
                </small>
            </div>
            <div className="absolute top-[12px] right-[10px]">
                <div className="note-item__edit-delete">
                    <Button
                        onClick={() => setOpenEditGeneral(true)}
                        className="mr-1"
                        iconName="edit"
                        size="x-small"
                    />
                    {/* <Button
                  onClick={onRemoveNote}
                  iconName="delete"
                  size="x-small"
              /> */}
                </div>
            </div>
            {openEditInfo && (
                <UpdateDescriptionDrawer
                    open={openEditInfo}
                    onClose={() => setOpenEditGeneral(false)}
                />
            )}
        </div>
    );
};

export default ProductDetailDescription;
