import { Progress, TabBar } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGetPaymentMethodSettingDetailLazyQuery } from "../../../api/hooks";
import { PaymentMethodDetailContext } from "../../../context/payment-method";
import Messages from "../../../languages/Messages";
import PaymentMethodActivity from "./PaymentMethodActivity";
import PaymentMethodGeneralInformation from "./PaymentMethodGeneralInformation";
import PaymentMethodSummaryView from "./PaymentMethodSummaryView";
import {
    mapPaymentMethodFromServer,
    PaymentMethodType,
} from "../../../interfaces/payment-method";
import PaymentMethod2C2PMethod from "./PaymentMethod2C2PMethod";
import PaymentMethodBankAccount from "./PaymentMethodBankAccount";
import PaymentMethodPOSCardDevice from "./PaymentMethodPOSCardDevice";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <PaymentMethodGeneralInformation />,
    },
    // {
    //     id: "warehouseDelivery",
    //     label: Messages.warehouseDelivery,
    //     component: <BranchWarehouseDelivery />,
    // },
    {
        id: "activity",
        label: Messages.activities,
        component: <PaymentMethodActivity />,
    },
];

const PaymentMethodDetail = () => {
    const { paymentId } = useParams<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [getPaymentMethodSettingDetail, { data, loading, refetch }] =
        useGetPaymentMethodSettingDetailLazyQuery();

    useEffect(() => {
        loadPaymentMethodDetail();
    }, []);

    const loadPaymentMethodDetail = () => {
        Progress.show(
            {
                method: getPaymentMethodSettingDetail,
                params: [
                    {
                        variables: {
                            id: paymentId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };

    const paymentMethodDetail = mapPaymentMethodFromServer(data?.data?.data);

    const displayTabs = [...TABS];

    if (
        paymentMethodDetail?.paymentConfigurationType ===
        PaymentMethodType.PAYMENT_2C2P
    ) {
        displayTabs.splice(1, 0, {
            id: "2C2PMethod",
            label: Messages.method2C2P,
            component: <PaymentMethod2C2PMethod />,
        });
    }

    if (
        paymentMethodDetail?.paymentConfigurationType &&
        [
            PaymentMethodType.THAI_QR_PAYMENT,
            PaymentMethodType.BANK_TRANSFER,
        ].includes(paymentMethodDetail?.paymentConfigurationType)
    ) {
        displayTabs.splice(1, 0, {
            id: "backAccount",
            label: Messages.bankAccounts,
            component: <PaymentMethodBankAccount />,
        });
    }

    if (
        paymentMethodDetail?.paymentConfigurationType &&
        [PaymentMethodType.CREDIT_CARD_POS].includes(
            paymentMethodDetail?.paymentConfigurationType
        )
    ) {
        displayTabs.splice(1, 0, {
            id: "posCardDevice",
            label: Messages.posCardDevice,
            component: <PaymentMethodPOSCardDevice />,
        });
    }

    return (
        <PaymentMethodDetailContext.Provider
            value={{
                paymentMethod: paymentMethodDetail,
                loadPaymentMethodDetail: refetch,
            }}
        >
            <div className="bg-white flex-column-container">
                <div className="border-b p-3 d-flex justify-content-between align-items-center">
                    <h5 className="text-primary">{`${paymentMethodDetail?.title}`}</h5>
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <PaymentMethodSummaryView loading={loading} />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={displayTabs}
                                onChange={setTab as any}
                                value={tab}
                            />

                            {tab.component}
                        </div>
                    </div>
                </div>
            </div>
        </PaymentMethodDetailContext.Provider>
    );
};

export default PaymentMethodDetail;
