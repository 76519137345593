import { useParams } from "react-router";
import ActivityView from "../../../common/activity/ActivityView";

const AppointmentDetailActivity = () => {
    const { appointmentId } = useParams<any>();
    return (
        <div>
            {appointmentId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef="APPOINTMENT"
                    activityRefId={appointmentId ?? ""}
                />
            )}
        </div>
    );
};

export default AppointmentDetailActivity;
