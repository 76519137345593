import React, { Dispatch } from "react";
import { IAddress } from "../interfaces/address";
import { IDeliveryOrder } from "../interfaces/delivery-order";
import {
    IMaterial,
    IMaterialOrderDelivery,
    IMaterialPurchase,
    IMaterialPurchaseRequest,
    IMaterialRequestAdjustment,
} from "../interfaces/material";
import { CartValueState, IOrderManualDiscount } from "../interfaces/order";
import { IVendor } from "../interfaces/vendor";

interface IMaterialCreateState {
    materialForm: any;
}

const MaterialCreateState: IMaterialCreateState = {
    materialForm: null,
};

export const MaterialCreateContext = React.createContext(MaterialCreateState);

interface IMaterialDetailState {
    material: IMaterial | null;
    setMaterial: any;
}

const materialDetailState: IMaterialDetailState = {
    material: null,
    setMaterial: () => {},
};

export const MaterialDetailContext = React.createContext(materialDetailState);

interface IMaterialStockRequestDetailState {
    requestDetail: IMaterialRequestAdjustment;
    setRequestDetail: any;
}

const materialStockRequestDetailState: IMaterialStockRequestDetailState = {
    requestDetail: {} as any,
    setRequestDetail: () => {},
};

export const MaterialStockRequestDetailContext = React.createContext(
    materialStockRequestDetailState
);

interface IMaterialRequestDetailState {
    request: IMaterialPurchaseRequest | null;
    reloadRequest: any;
}

const materialRequestState: IMaterialRequestDetailState = {
    request: null,
    reloadRequest: () => {},
};

export const MaterialRequestContext = React.createContext(materialRequestState);

interface MaterialPurchaseCreateState {
    productFilters: any;
    setProductFilters: (productFilters: any) => void;
    setProductList: (values: any) => void;
    productList: IMaterial[];
    productCustomList?: any;
    setProductCustomList?: any;
    discountList?: any;
    vendor: IVendor[] | undefined;
    setVendor: any;
    shipping?: any[];
    setShipping?: any;
    billing?: any[];
    setBilling?: any;
    cartValue?: CartValueState;
    setCartValue?: any;
    checkCartValue?: any;
    setCoSalePerson?: (values: any) => void;
    manualDiscount?: IOrderManualDiscount[];
    setManualDiscount?: Dispatch<IOrderManualDiscount[]>;
    materialRequest?: IMaterialPurchaseRequest;
    setMaterialRequest?: Dispatch<IMaterialPurchaseRequest>;
}

const materialPurchaseCreateState: MaterialPurchaseCreateState = {
    productFilters: null,
    setProductFilters: () => {},
    productList: [],
    setProductList: () => {},
    vendor: undefined,
    setVendor: () => {},
    manualDiscount: undefined,
    setManualDiscount: () => {},
};

export const MaterialPurchaseContext = React.createContext(
    materialPurchaseCreateState
);

export interface IMaterialPurchaseDetailState {
    order?: IMaterialPurchase | null;
    loadOrderDetail: () => void;
    shippingList?: IAddress[];
    setShippingList: any;
    deliveryList?: IMaterialOrderDelivery[];
    loadDeliveryList: any;
}

const materialPurchaseDetailState: IMaterialPurchaseDetailState = {
    order: undefined,
    loadOrderDetail: () => {},
    shippingList: [],
    setShippingList: () => {},
    deliveryList: [],
    loadDeliveryList: () => {},
};

export const MaterialPurchaseDetailContext = React.createContext(
    materialPurchaseDetailState
);
