import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputText,
    InputTextSearch,
    ObjectUtils,
    StringUtils,
    useFirstTime,
} from "d-react-components";
import { useContext, useRef, useState, useEffect, useMemo } from "react";
import {
    compact,
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    map,
    reduce,
    toLower,
    uniqBy,
} from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";
import ProductSelect from "../../product/share/ProductSelect";
import {
    TypeOfProduct,
    VoucherStatus,
    useGetCustomerVouchersLazyQuery,
} from "../../../api/hooks";
import { IProduct, IProductGroupItem } from "../../../interfaces/product";
import ProductNameView from "../../product/share/ProductNameView";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import ProductAPI from "../../../api/queries/product";
import CustomerAPI from "../../../api/queries/customer";

const ProductSchema = Yup.object().shape({
    product: Yup.mixed().required(Messages.fieldRequired),
    quantity: Yup.number().required(Messages.fieldRequired),
    remark: Yup.string(),
});

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
    {
        id: "serviceCustomer",
        label: "serviceOfCustomer",
    },
];

const ProductSelectDrawerSingle = ({
    open,
    onClose,
    onSave,
    productType,
}: any) => {
    const productForm = useFormik<any>({
        initialValues: {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductSchema,
        onSubmit: (values: any) => {
            onSave(values);
        },
    });

    const { values, setFieldValue, errors, handleSubmit } = productForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="500px"
            title={Messages.addProduct}
            onSave={handleSubmit}
        >
            <div className="p-3">
                <div className="mb-3">
                    <ProductSelect
                        value={values?.product}
                        onChange={(values: any) =>
                            setFieldValue("product", values)
                        }
                        title={Messages.product}
                        error={errors?.products}
                        customQuery={{
                            typeOfProduct:
                                productType || TypeOfProduct.ServiceProduct,
                        }}
                    />
                </div>
                <div className="mb-3">
                    <InputText
                        label={Messages.quantity}
                        value={values?.quantity}
                        onChange={(e: any) =>
                            setFieldValue("quantity", e?.target?.value)
                        }
                        error={errors?.quantity as string}
                    />
                </div>
                <div className="mb-3">
                    <InputText
                        label={Messages.remark}
                        value={values?.remark}
                        onChange={(e: any) =>
                            setFieldValue("remark", e?.target?.value)
                        }
                        error={errors?.remark as string}
                        multiple
                    />
                </div>
            </div>
        </Drawer>
    );
};

const ProductSelectDrawer = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    productType,
    isMedicine = false,
    isEdit = true,
    customerId,
}: any) => {
    const [productSelected, setProductSelected] =
        useState<IProductGroupItem[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();
    const [getCustomerVouchers] = useGetCustomerVouchersLazyQuery();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onAddProduct = () => {
        // const filtered = filter(productSelected, (item) => !!item.quantity);
        const products = map(productSelected, (item) => ({
            product: isEdit ? item.id : item,
            quantity: item.quantity,
        }));
        onSave(products);
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.quantity,
            dataIndex: "",
            render: (product: any) => {
                const selected = find(
                    productSelected,
                    (item) => item.id === product.id
                );
                if (!productSelected) return <div />;
                return (
                    <QuantityInputField
                        quantity={selected?.quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity(quantity, product)
                        }
                        min={1}
                    />
                );
            },
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
        },
    ];

    const onChangeQuantity = (quantity: number, product: IProduct) => {
        const result = ObjectUtils.updateArrayById(productSelected as any, {
            ...product,
            quantity,
        });
        setProductSelected(result);
    };

    const source = async (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: productSelected });
        }
        if (filterTab === "serviceCustomer") {
            const customerVoucherRes = await getCustomerVouchers({
                variables: {
                    customerId,
                    paginate: {
                        page: pagingData?.pageIndex,
                        limit: pagingData?.pageSize,
                        status: [VoucherStatus.Available],
                    },
                },
                fetchPolicy: "no-cache",
            });
            const vouchers = customerVoucherRes?.data?.data?.data ?? [];
            let serviceList: any[] = reduce(
                vouchers,
                (list, voucher) =>
                    list.concat((voucher?.services ?? []) as any),
                []
            );

            if (!isEmpty(searchRef.current)) {
                serviceList = filter(serviceList, (item: any) =>
                    includes(
                        toLower(item.name + item.sku),
                        toLower(searchRef.current)
                    )
                );
            }
            const serviceUniqList = uniqBy(
                serviceList,
                (service: any) => service.id
            );

            return Promise.resolve({ data: serviceUniqList });
        }
        return ProductAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            typeOfProduct: productType || TypeOfProduct.ServiceProduct,
            isMedicine,
            sort: {
                createdAt: -1,
            },
        });
    };

    const onChangeSelectProduct = (keys: string[], products: IProduct[]) => {
        const result = map(compact(products), (product) => {
            const itemSelected = find(
                productSelected,
                (item) => item.id === product.id
            );

            if (itemSelected) return itemSelected;
            return { ...product, quantity: 1 };
        });
        setProductSelected(result as any);
    };

    const filterTabs = useMemo(() => {
        if (isEmpty(customerId)) {
            return filter(FILTER_TABS, (item) => item.id !== "serviceCustomer");
        }
        return FILTER_TABS;
    }, [customerId]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.addProduct}
            onSave={onAddProduct}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(filterTabs, (item, index) => (
                        <Button
                            key={`${item?.id}_${index}`}
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => res?.data ?? []}
                    rowSelection={{
                        selectedRowKeys: map(
                            productSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectProduct as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default ProductSelectDrawer;
