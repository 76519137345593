import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";

export const F_PAGINATE = gql`
    fragment F_PAGINATE on PaginateResponse {
        page
        total
        items
    }
`;


export const F_NOTE = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_NOTE on NoteDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        noteNo
        title
        content
        refId
        ref
    }
`;

export const F_NOTES = gql`
    ${F_NOTE}
    ${F_PAGINATE}
    fragment F_NOTES on NotePaginateResponse {
        data {
            ...F_NOTE
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
