import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";
import { usePromotionDetailContext } from "../../../context/promotion";

const PromotionDetailActivity = () => {
    const { promotionId } = useParams<any>();
    const { promotion } = usePromotionDetailContext();
    return (
        <div>
            {promotionId && (
                <ActivityView
                    key={`${promotionId}_${promotion?.updatedAt}`}
                    className="border"
                    activityRef={RefActivity?.Promotion}
                    activityRefId={promotionId ?? ""}
                />
            )}
        </div>
    );
};

export default PromotionDetailActivity;
