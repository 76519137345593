import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath, useParams } from "react-router";
import {
    useGetCustomerOrderHistoryLazyQuery,
    useGetCustomerTreatmentHistoryLazyQuery,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { ORDER_STATUSES } from "../../../constant/order";
import { TREATMENT_STATUSES } from "../../../constant/treatment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CustomerDetailTreatment = () => {
    const { customerId } = useParams<any>();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [getCustomerTreatmentHistory] =
        useGetCustomerTreatmentHistoryLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "treatmentNo",
            render: (treatmentNo, item) => (
                <AppLink
                    to={generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: item.id,
                    })}
                >
                    {treatmentNo}
                </AppLink>
            ),
        },
        {
            title: Messages.doctorOrder,
            dataIndex: "doctorOrder",
            render: (doctorOrder, item) => (
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: doctorOrder.id,
                    })}
                >
                    {doctorOrder?.doctorOrderNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={TREATMENT_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },

        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor) => (
                <UserAvatarName
                    user={doctor?.adminUser}
                    size="xx-small"
                    variant="square"
                    showInfo={{ showOPD: false }}
                />
            ),
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        if (!customerId) {
            return Promise.resolve();
        }
        return getCustomerTreatmentHistory({
            variables: {
                customerId,
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const refreshTable = () => {
        return tableRef.current && tableRef.current.refresh();
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        refreshTable();
    }, 500);

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.recentTreatments}</h5>
                {/* <Button iconName="add" onClick={() => {}} className="">
                    {Messages.new}
                </Button> */}
            </div>
            <InputTextSearch
                className="mt-3"
                onChange={(event: any) => onChangeSearch(event.target.value)}
            />
            <AwesomeTableComponent
                className="mt-3"
                ref={tableRef}
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
        </div>
    );
};

export default CustomerDetailTreatment;
