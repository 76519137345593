import * as Yup from "yup";

export const MaterialCategorySchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    description: Yup.string().required("Required field!"),
});

export const MaterialInfoSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    description: Yup.string().required("Required field!"),
});

export const MaterialSchema = Yup.object().shape({
    sku: Yup.string().required("Required field!"),
    name: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    category: Yup.mixed().required("Required field!"),
    description: Yup.string().required("Required field!"),
    purchaseUnit: Yup.object().required("Required field!"),
    trackingUnit: Yup.object().required("Required field!"),
    trackingValue: Yup.number(),
    lowStockQuantity: Yup.number(),
    vendor: Yup.mixed().required("Required field!"),
});

export const MaterialUpdateSchema = Yup.object().shape({
    sku: Yup.string().required("Required field!"),
    name: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    category: Yup.mixed().required("Required field!"),
    description: Yup.string().required("Required field!"),
    lowStockQuantity: Yup.number(),
    vendor: Yup.mixed().required("Required field!"),
});