import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import { MATERIAL_ADJUST_REQUEST_STATUSES } from "../../../constant/material";
import { MaterialStockRequestDetailContext } from "../../../context/material";
import { IMaterialRequestAdjustment } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";

const MaterialAdjustRequestSummary = ({ loading }: { loading?: boolean }) => {
    const { requestDetail } = useContext(MaterialStockRequestDetailContext);
    const STOCK_REQUEST_KEY: IRowsKey<IMaterialRequestAdjustment>[] = [
        {
            id: "adjustmentNo",
            label: "ID",
        },
        {
            id: "branch",
            label: Messages.branchWarehouse,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "branch",
            label: Messages.branchWarehouseCode,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "differentRate",
            label: Messages["differentRate%"],
        },

        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
    ];

    return (
        <div>
            {requestDetail && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            {requestDetail?.adjustmentNo}
                        </div>
                        <ViewLabelStatus
                            status={requestDetail?.status}
                            listStatus={MATERIAL_ADJUST_REQUEST_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={requestDetail}
                            keyList={STOCK_REQUEST_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
            {/* {loading && <Skeleton count={5} />} */}
        </div>
    );
};

export default MaterialAdjustRequestSummary;
