import ClassNames from "classnames";
import { Button, TimeUtils } from "d-react-components";
import { includes, map } from "lodash";
import { ElementRef, useContext, useEffect, useRef, useState } from "react";
import { createSearchParams, generatePath } from "react-router-dom";
import AppLink from "../../common/AppLink";
import { AppStateContext } from "../../context/app";
import { useCardReader } from "../../hooks/customer";
import Messages from "../../languages/Messages";
import CustomerSelect from "../customer/share/CustomerSelect";
import Path from "../Path";

const Dashboard = () => {
    const { me } = useContext(AppStateContext);
    return (
        <div className="text-center py-10 h-screen flex flex-col justify-center items-center overflow-hidden">
            {/* <img
                className="m-auto max-w-[500px]"
                src="/images/welcome.png"
                alt="Dashboard"
            />
            <h1 className="text-3xl mb-3">
                {Messages.haveAGoodDay}{" "}
                <span className="text-blue-500">
                    {`${me?.firstName} ${me?.lastName}`}
                </span>
            </h1>
            <p className="m-auto max-w-screen-md">
                {Messages.dashboardWelcome}
            </p> */}
            <SearchCustomerView />
        </div>
    );
};

export default Dashboard;

const SearchCustomerView = () => {
    const selectRef = useRef<ElementRef<typeof CustomerSelect>>(null);
    const [foundCustomers, setFoundCustomers] = useState<any>();
    const { sendMessage, mappedData, onReadCard, selectedReader } =
        useCardReader();

    useEffect(() => {
        setFoundCustomers([]);
        if (mappedData?.citizenId && mappedData?.citizenId?.length === 13) {
            selectRef.current &&
                selectRef.current?.onSearch(mappedData?.citizenId, {
                    filter: [{ citizenId: [mappedData?.citizenId] }],
                    showEmptyNote: true,
                    onFoundCallback: (list) => {
                        setFoundCustomers(list);
                    },
                });
        }
    }, [mappedData?.citizenId]);

    const getCreateCustomerLink = () => {
        if (
            includes(
                map(foundCustomers, (i: any) => i?.citizenId),
                mappedData?.citizenId
            )
        ) {
            return generatePath(Path.CUSTOMER_CREATE);
        }
        return generatePath(Path.CUSTOMER_CREATE, {
            search: createSearchParams(mappedData),
        });
    };

    return (
        <div className="shadow flex flex-col justify-center items-center w-1/2 py-5 px-4 mb-60">
            <h4 className="text-primary">{Messages.searchCustomer}</h4>
            <CustomerSelect
                ref={selectRef}
                className="w-full mt-3"
                onChange={() => {}}
                variant="search"
                showLabel={false}
                placeholder={Messages.searchForCustomer}
            />
            <div className="flex-center-y mt-3 w-full">
                <div className="divider" />
                <small className="d-block min-w-fit text-nowrap mx-2">
                    {Messages.firstTimeAtDermaster}
                </small>
                <div className="divider" />
            </div>
            <AppLink
                className="mt-3 w-full d-block"
                to={getCreateCustomerLink()}
            >
                <Button
                    size="auto"
                    iconName="add"
                    variant="outline"
                    className="w-100 py-3"
                >
                    {Messages.newCustomerProfile}
                </Button>
            </AppLink>
            <div className="flex-center-y mt-3 w-full">
                <div className="divider" />
                <small className="d-block min-w-fit text-nowrap mx-2">
                    {Messages.orScanCustomerIdCard}
                </small>
                <div className="divider" />
            </div>
            <div
                onClick={() => onReadCard()}
                className={ClassNames(
                    " hover:cursor-pointer w-36 h-36 flex flex-col justify-center items-center mt-3 p-2",
                    {
                        "bg-primary": selectedReader,
                        "bg-gray-400": !selectedReader,
                    }
                )}
            >
                <img src="/icons/scan.svg" />
                <div className="small text-white font-weight-bold mt-3">
                    {selectedReader
                        ? Messages.scanIdCard
                        : Messages.noCardReader}
                </div>
            </div>
        </div>
    );
};
