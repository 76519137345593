import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath, useParams } from "react-router";
import { useGetCustomerOrderHistoryLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { ORDER_STATUSES } from "../../../constant/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const CustomerDetailOrder = () => {
    const { customerId } = useParams<any>();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [getCustomerOrderHistory] = useGetCustomerOrderHistoryLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.orderId,
            dataIndex: "orderNo",
            width: 100,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={ORDER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.amount,
            dataIndex: "total",
            render: (amount) => (
                <CurrencyFormat
                    value={amount}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            width: "100px",
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        if (!customerId) {
            return Promise.resolve();
        }
        return getCustomerOrderHistory({
            variables: {
                customerId,
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const refreshTable = () => {
        return tableRef.current && tableRef.current.refresh();
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        refreshTable();
    }, 500);

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.recentOrders}</h5>
                <Button iconName="add" onClick={() => {}} className="">
                    {Messages.new}
                </Button>
            </div>
            <InputTextSearch
                className="mt-3"
                onChange={(event: any) => onChangeSearch(event.target.value)}
            />
            <AwesomeTableComponent
                className="mt-3"
                ref={tableRef}
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
        </div>
    );
};

export default CustomerDetailOrder;
