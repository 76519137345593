import {
    Avatar,
    Button,
    Icon,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { map } from "lodash";
import { useState } from "react";
import { useUpdateCustomerTagMutation } from "../../../api/hooks";
import Image from "../../../common/Image";
import { CUSTOMER_STATUS } from "../../../constant/customer";
import { CustomerStatus, ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import TagSelectModal from "../../tags/share/TagSelectModal";
import PriceView from "../../../common/views/PriceView";

interface ICustomerInfoBarConfig {
    showMedicalInfo?: boolean;
    showGeneralInfo?: boolean;
    showContactInfo?: boolean;
    showHeaderView?: boolean;
    showStatus?: boolean;
    showAddTags?: boolean;
    showCredit?: boolean;
    classNameMedical?: string;
    classNameGeneral?: string;
    classNameContact?: string;
    classNameHeader?: string;
}

const DEFAULT_INFO_SHOW: ICustomerInfoBarConfig = {
    showMedicalInfo: true,
    showGeneralInfo: true,
    showContactInfo: true,
    showHeaderView: true,
    showAddTags: true,
    showCredit: false,
};

interface ICustomerInfoBar {
    customer: ICustomer;
    onUpdate?: (customer: ICustomer) => void;
    showInfo?: ICustomerInfoBarConfig;
    className?: string;
}
const CustomerInfoBar = ({
    customer,
    onUpdate,
    showInfo = {},
    className,
}: ICustomerInfoBar) => {
    const [updateCustomerTag] = useUpdateCustomerTagMutation();
    const [openAddTag, setOpenAddTag] = useState(false);
    const {
        avatar,
        fullNameTh,
        lineId,
        email,
        phone,
        tags,
        customerNo,
        firstNameEn,
        lastNameEn,
        nickname,
        title,
        gender,
        birthDay,
        createdAt,
        updatedAt,
        medicalProfile,
        facebook,
        instagram,
        alternativePhone,
        totalCredit,
    } = customer ?? {};

    const {
        showContactInfo,
        showGeneralInfo,
        showHeaderView,
        showMedicalInfo,
        showAddTags,
        showStatus,
        showCredit,
        classNameContact,
        classNameGeneral,
        classNameMedical,
        classNameHeader = "border",
    } = { ...DEFAULT_INFO_SHOW, ...showInfo };

    const onClickPhone = () => {};
    const onClickEmail = () => {};
    const onClickLine = () => {};

    const onUpdateCustomerTags = (newTags: any[]) => {
        Progress.show(
            {
                method: updateCustomerTag,
                params: [
                    {
                        variables: {
                            customerId: customer?.id,
                            tagIds: map(newTags, (item) => item.id),
                        },
                    },
                ],
            },
            (res: any) => {
                setOpenAddTag(false);
                Notifications.showSuccess(Messages.updateCustomerTagSuccess);
                const newCustomer = res?.data?.data?.data;
                onUpdate && onUpdate(newCustomer);
            }
        );
    };

    const renderContactIcon = (iconName: string, onClick: any) => (
        <Image
            onClick={onClick}
            src={`/images/contact/${iconName}.png`}
            className="mr-2"
            style={{ width: "20px", height: "20px" }}
        />
    );

    const renderInfoRow = (title: any, content: any) => {
        return (
            <div className="d-flex align-items-start w-100 justify-content-between py-3">
                <div className="text-small w-100 font-weight-bold">{title}</div>
                <div className="w-100 text text-end">{content}</div>
            </div>
        );
    };

    const renderStatus = () => {
        return (
            <ViewLabelStatus
                className="absolute right-0 top-0"
                status={customer?.status ?? CustomerStatus.UN_VERIFIED}
                listStatus={CUSTOMER_STATUS}
                getLabel={(item) => Messages[item.label]}
            />
        );
    };

    return (
        <div className={`"w-100 bg-white ${className}`}>
            <div
                className={`flex-column flex-center py-5 ${classNameHeader}`}
                hidden={!showHeaderView}
            >
                <Avatar src={avatar} size="large" text={fullNameTh} />
                <h4 className="text-primary mt-2">{fullNameTh}</h4>
                <div className="d-flex mt-2">
                    {phone && renderContactIcon("phone", onClickPhone)}
                    {email && renderContactIcon("email", onClickEmail)}
                    {lineId && renderContactIcon("line", onClickLine)}
                </div>
                <div className="d-flex flex-center w-100 flex-wrap px-2">
                    <Icon name="local_offer" className="text-primary" />
                    {map(tags, (tag) => (
                        <div className="px-2 py-1 ml-2 bg-primary text-white small text-no-wrap mt-2">
                            {tag.name}
                        </div>
                    ))}
                    {showAddTags && (
                        <Button
                            iconName="add"
                            size="x-small"
                            variant="outline"
                            className="ml-2 mt-2"
                            onClick={() => setOpenAddTag(true)}
                        >
                            {Messages.add}
                        </Button>
                    )}
                </div>
                {showStatus && renderStatus()}
            </div>
            <div
                className={`flex-column flex-center border p-3 ${classNameGeneral}`}
                hidden={!showGeneralInfo}
            >
                {renderInfoRow(Messages.customerNo, customerNo)}
                {showCredit
                    ? renderInfoRow(
                          Messages.totalCredit,
                          (<PriceView value={totalCredit} />) as any
                      )
                    : null}
                {renderInfoRow(Messages.firstName, firstNameEn)}
                {renderInfoRow(Messages.lastName, lastNameEn)}
                {renderInfoRow(Messages.nickname, nickname)}
                {renderInfoRow(Messages.title, title)}
                {renderInfoRow(Messages.gender, gender)}
                {renderInfoRow(
                    Messages.dateOfBirth,
                    birthDay ? TimeUtils.toDate(birthDay) : "N/A"
                )}
                {renderInfoRow(Messages.joinSince, TimeUtils.toDate(createdAt))}
                {renderInfoRow(
                    Messages.lastUpdated,
                    TimeUtils.toDate(updatedAt)
                )}
            </div>

            <div
                className={`flex-column flex-center border p-3 ${classNameMedical}`}
                hidden={!medicalProfile || !showMedicalInfo}
            >
                {renderInfoRow(Messages.heightCm, medicalProfile?.height)}
                {renderInfoRow(Messages.weightKg, medicalProfile?.weight)}
                {renderInfoRow(Messages.bloodGroup, medicalProfile?.bloodGroup)}
                {renderInfoRow(
                    Messages.bloodPressure,
                    medicalProfile?.bloodPressure
                )}
            </div>

            <div
                className={`flex-column flex-center border p-3 ${classNameContact}`}
                hidden={!showContactInfo}
            >
                {renderInfoRow(Messages.email, email)}
                {renderInfoRow(Messages.phoneNo, phone)}
                {renderInfoRow(
                    Messages.otherPhoneNo,
                    alternativePhone || "N/A"
                )}
                {renderInfoRow(Messages.lineId, lineId || "N/A")}
                {renderInfoRow(
                    Messages.facebook,
                    facebook ? (
                        <a
                            className=""
                            href={facebook}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {Messages.view}
                        </a>
                    ) : (
                        "N/A"
                    )
                )}
                {renderInfoRow(
                    Messages.instagram,
                    instagram ? (
                        <a
                            className=""
                            href={instagram}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {Messages.view}
                        </a>
                    ) : (
                        "N/A"
                    )
                )}
            </div>
            {openAddTag && (
                <TagSelectModal
                    open={openAddTag}
                    onClose={() => setOpenAddTag(false)}
                    value={tags ?? []}
                    onSave={onUpdateCustomerTags}
                />
            )}
        </div>
    );
};

export default CustomerInfoBar;
