import {
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, map } from "lodash";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import {
    useCreateMaterialMutation,
    useDetailMaterialQuery,
    useSearchCategoryMaterialLazyQuery,
    useSearchVendorLazyQuery,
    useUpdateMaterialMutation,
} from "../../../api/hooks";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import GalleryUpload from "../../../common/gallery/GalleryUpload";
import { MATERIAL_STATUSES } from "../../../constant/material";
import { MaterialCreateContext } from "../../../context/material";
import { MaterialSchema } from "../../../formschema/material";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import MaterialUnitSelect from "../share/MaterialUnitSelect";

const MaterialCreate = () => {
    const { materialId } = useParams();
    const navigate = useNavigate();
    const [searchVendor] = useSearchVendorLazyQuery();
    const [searchCategoryMaterial] = useSearchCategoryMaterialLazyQuery();
    const [createMaterial] = useCreateMaterialMutation();
    const [updateMaterial] = useUpdateMaterialMutation();

    const { data, refetch } = useDetailMaterialQuery({
        variables: {
            id: materialId as string,
        },
        skip: !materialId,
    });

    const materialForm = useFormik<any>({
        initialValues: {
            gallery: [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values: any) => {
            const errors: any = {};
            return errors;
        },
        validationSchema: MaterialSchema,
        onSubmit: (values: any) => {
            const {
                name,
                sku,
                status,
                category,
                description,
                purchaseUnit,
                trackingUnit,
                purchaseValue,
                trackingValue,
                lowStockQuantity,
                vendor,
                gallery,
            } = values;

            const payload = {
                name,
                sku,
                status,
                ...(category ? { category: category?.[0]?.value } : {}),
                description,
                purchaseUnit: purchaseUnit?.id,
                trackingUnit: trackingUnit?.id,
                purchaseValue: 1,
                trackingValue: Number(trackingValue),
                lowStockQuantity: Number(lowStockQuantity),
                ...(vendor ? { vendor: vendor?.[0]?.value } : {}),
                gallery: map(gallery, (item) => item.fileUrl),
            };

            Progress.show(
                {
                    method: materialId ? updateMaterial : createMaterial,
                    params: {
                        variables: {
                            payload,
                            ...(materialId ? { id: materialId } : {}),
                        },
                    },
                },
                (res: any) => {
                    navigate(
                        generatePath(Path.MATERIAL_DETAIL, {
                            materialId: res?.data?.data?.data?.id,
                        })
                    );
                    Notifications.showSuccess(
                        materialId
                            ? Messages.updateSuccess
                            : Messages.createSuccess
                    );
                }
            );
            // createMaterial({
            //     variables: {
            //         payload
            //     }
            // })
        },
    });
    const { values: formValues, errors: formErrors, setValues } = materialForm;

    useEffect(() => {
        if (!isEmpty(data)) {
            const material = data?.data?.data ?? {};
            const {
                name,
                sku,
                status,
                category,
                description,
                purchaseUnit,
                trackingUnit,
                purchaseValue,
                trackingValue,
                lowStockQuantity,
                vendor,
                gallery,
            } = material;
            setValues({
                name,
                sku,
                status,
                category: [category],
                description,
                purchaseUnit,
                trackingUnit,
                purchaseValue,
                trackingValue,
                lowStockQuantity,
                vendor: [vendor],
                gallery: map(gallery, (item) => ({ fileUrl: item })),
            });
        }
    }, [data]);

    const materialDetail = data?.data?.data ?? {};

    return (
        <MaterialCreateContext.Provider
            value={{
                materialForm,
            }}
        >
            <Header
                title={
                    materialId
                        ? materialDetail?.name ?? ""
                        : Messages.materialCreate
                }
                className="sticky top-0"
                onSave={() => materialForm.handleSubmit()}
                onCancel={() => navigate(-1)}
                cancelText={Messages.back}
            />
            <div className="h-100 overflow-auto p-4 bg-white mb-5">
                <div className="row w-fill-available">
                    <div className="col-md-6 mb-3">
                        <InputText
                            value={formValues?.name ?? ""}
                            label={Messages.name}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                materialForm.setFieldValue(
                                    `name`,
                                    event?.target?.value
                                )
                            }
                            error={
                                (formErrors?.name || formErrors?.name) as string
                            }
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <InputText
                            value={formValues?.sku ?? ""}
                            label={Messages.sku}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                materialForm.setFieldValue(
                                    `sku`,
                                    event?.target?.value
                                )
                            }
                            error={
                                (formErrors?.sku || formErrors?.sku) as string
                            }
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <Select
                            dataSource={MATERIAL_STATUSES}
                            value={formValues?.status}
                            label={Messages.status}
                            placeholder={Messages.pleaseSelect}
                            getLabel={(item) => Messages[item.label]}
                            onChange={(value) =>
                                materialForm.setFieldValue(`status`, value)
                            }
                            error={
                                (formErrors?.status ||
                                    formErrors?.status) as string
                            }
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <SelectInfinityFilter
                            fetchFn={(params: any, paging: any) =>
                                searchCategoryMaterial({
                                    variables: {
                                        paginate: {
                                            search: params?.search ?? "",
                                            limit: paging.pageSize,
                                            page: paging.pageIndex,
                                        },
                                    },
                                })
                            }
                            dataKey="data"
                            getLabel={(item) => (item ? item.name : null)}
                            value={formValues?.category}
                            label={Messages.category}
                            placeholder={Messages.pleaseSelect}
                            onChange={(value) =>
                                materialForm.setFieldValue(`category`, value)
                            }
                            error={
                                (formErrors?.category ||
                                    formErrors?.category) as string
                            }
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <InputText
                            multiple
                            value={formValues?.description ?? ""}
                            label={Messages.description}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                materialForm.setFieldValue(
                                    `description`,
                                    event?.target?.value
                                )
                            }
                            error={
                                (formErrors?.description ||
                                    formErrors?.description) as string
                            }
                        />
                    </div>
                </div>
                <div className="border-t border-solid border-gray-200 mt-2 pt-4">
                    <div className="row w-fill-available">
                        <div className="col-md-6 mb-3">
                            <MaterialUnitSelect
                                value={formValues?.purchaseUnit}
                                label={Messages.purchaseUnit}
                                onChange={(value) =>
                                    materialForm.setFieldValue(
                                        `purchaseUnit`,
                                        value
                                    )
                                }
                                error={
                                    (formErrors?.purchaseUnit ||
                                        formErrors?.purchaseUnit) as string
                                }
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <MaterialUnitSelect
                                value={formValues?.trackingUnit}
                                label={Messages.trackingUnit}
                                onChange={(value) =>
                                    materialForm.setFieldValue(
                                        `trackingUnit`,
                                        value
                                    )
                                }
                                error={
                                    (formErrors?.trackingUnit ||
                                        formErrors?.trackingUnit) as string
                                }
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="text-label">
                                {Messages.purchaseToTrackingConversion}
                            </label>
                            <div className="flex items-center">
                                <span className="mr-2">
                                    1 {formValues?.purchaseUnit?.name} ={" "}
                                </span>
                                <InputText
                                    value={formValues?.trackingValue}
                                    onChange={(e) =>
                                        materialForm.setFieldValue(
                                            `trackingValue`,
                                            e?.target?.value
                                        )
                                    }
                                    error={
                                        (formErrors?.trackingValue ||
                                            formErrors?.trackingValue) as string
                                    }
                                />
                                <span className="ml-2">
                                    {formValues?.trackingUnit?.name}
                                </span>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <label className="text-label">
                                {Messages.trackingValue}
                            </label>
                            <div className="flex items-center">
                                <span className="mr-2">
                                    {Messages.notifyWhenTrackingUnitEqual}
                                </span>
                                <InputText
                                    value={formValues?.lowStockQuantity}
                                    onChange={(e) =>
                                        materialForm.setFieldValue(
                                            `lowStockQuantity`,
                                            e?.target?.value
                                        )
                                    }
                                    error={
                                        (formErrors?.lowStockQuantity ||
                                            formErrors?.lowStockQuantity) as string
                                    }
                                />
                                <span className="ml-2">
                                    {formValues?.trackingUnit?.name}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-solid border-gray-200 mt-4 pt-4">
                    <div className="row w-fill-available">
                        <div className="col-md-6 mb-3">
                            <SelectInfinityFilter
                                value={formValues?.vendor}
                                label={Messages.preferredVendor}
                                placeholder={Messages.pleaseSelect}
                                onChange={(value) =>
                                    materialForm.setFieldValue(`vendor`, value)
                                }
                                error={
                                    (formErrors?.vendor ||
                                        formErrors?.vendor) as string
                                }
                                fetchFn={(params: any, paging: any) =>
                                    searchVendor({
                                        variables: {
                                            paginate: {
                                                search: params?.search ?? "",
                                                limit: paging.pageSize,
                                                page: paging.pageIndex,
                                            },
                                        },
                                    })
                                }
                                dataKey="data"
                                getLabel={(item) => (item ? item.name : null)}
                            />
                        </div>
                    </div>
                </div>
                <div className="border-t border-solid border-gray-200 mt-4 pt-4">
                    <GalleryUpload form={materialForm} />
                </div>
            </div>
        </MaterialCreateContext.Provider>
    );
};

export default MaterialCreate;
