export const EQUIPMENT_STOCK_ADJUSTMENT_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
};

export const EQUIPMENT_STOCK_ADJUSTMENT_STATUSES = [
    {
        id: EQUIPMENT_STOCK_ADJUSTMENT_STATUS.PENDING,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: EQUIPMENT_STOCK_ADJUSTMENT_STATUS.APPROVED,
        label: "approved",
        color: "#33B950",
    },
    {
        id: EQUIPMENT_STOCK_ADJUSTMENT_STATUS.CANCELLED,
        label: "cancelled",
        color: "#C4C4C4",
    },
];
