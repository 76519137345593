import React from "react";
import { JobCostType } from "../../../api/hooks";
import { JOB_COST_TYPES } from "../../../constant/service";
import { JOB_TYPES } from "../../../constant/user";
import { JobType } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";

export interface IUserSubViewProps {
    className?: string;
}

export interface IUserJobTypeViewProps extends IUserSubViewProps {
    jobType?: JobType;
}

export interface IUserCostRoleViewProps extends IUserSubViewProps {
    jobCostRole?: JobCostType | null;
}

export const UserJobTypeView: React.FC<IUserJobTypeViewProps> = ({
    className,
    jobType,
}) => {
    const foundJobType = JOB_TYPES.find((item) => item?.key === jobType);
    return (
        <div className={`${className}`}>
            {Messages?.[foundJobType?.label ?? ""] ?? "N/A"}
        </div>
    );
};

export const UserJobCostRoleView: React.FC<IUserCostRoleViewProps> = ({
    className,
    jobCostRole,
}) => {
    const foundJobCost = JOB_COST_TYPES.find(
        (item) => item?.id === jobCostRole
    );
    return (
        <div className={`${className}`}>
            {Messages?.[foundJobCost?.label ?? ""] ?? "N/A"}
        </div>
    );
};
