export const STOCK_ADJUSTMENT_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
};

export const STOCK_ADJUSTMENT_STATUSES = [
    { id: "PENDING", label: "pending", color: "#FAC256" },
    { id: "APPROVED", label: "approved", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
];
