import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { RefActivity, useGetAdminDetailLazyQuery } from "../../../api/hooks";
import TemporaryScheduleAPI from "../../../api/queries/temporary-schedule";
import ActivityView from "../../../common/activity/ActivityView";
import Messages from "../../../languages/Messages";
import UserSummaryView from "../../user/share/UserSummaryView";
import { ScheduleDetailActivity } from "./ScheduleDetailActivity";
import ScheduleDetailWorkingTime from "./ScheduleDetailWorkingTime";

const TABS = [
    {
        id: "scheduleDetailTimes",
        label: Messages.temporaryScheduleDetail,
        component: <ScheduleDetailWorkingTime />,
    },

    {
        id: "activity",
        label: Messages.activity,
        component: <ScheduleDetailActivity />,
    },
];

const TemporaryScheduleDetail = () => {
    const { userId } = useParams<any>();

    const [tab, setTab] = useState(TABS[0]);
    const [getAdminDetail, { data, loading, refetch }] =
        useGetAdminDetailLazyQuery();

    useEffect(() => {
        loadAdminDetail();
    }, []);

    const loadAdminDetail = () => {
        Progress.show(
            [
                {
                    method: getAdminDetail,
                    params: [
                        {
                            variables: {
                                id: userId as string,
                            },
                        },
                    ],
                },
            ],
            (res: any) => {}
        );
    };

    const adminDetail = data?.detailAdminUserForAdmin?.data;

    return (
        <div className="bg-white flex-column-container bg-trans">
            <div className="border-b p-3 d-flex justify-content-between align-items-center">
                <h5 className="text-primary">{`${adminDetail?.firstName} ${adminDetail?.lastName}`}</h5>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <UserSummaryView
                            user={data?.detailAdminUserForAdmin?.data as any}
                        />
                    </div>
                    <div className="col-span-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemporaryScheduleDetail;
