import { UsageType, VoucherStatus } from "../api/hooks";

export const VOUCHER_TYPE = {
    CASH_VOUCHER: "CASH_VOUCHER",
    GIFT_VOUCHER: "GIFT_VOUCHER",
};

export const VOUCHER_TYPES = [
    {
        id: VOUCHER_TYPE.CASH_VOUCHER,
        label: "cashVoucher",
    },
    {
        id: VOUCHER_TYPE.GIFT_VOUCHER,
        label: "giftVoucher",
    },
];

export const VOUCHER_USAGE_TYPES = [
    { id: UsageType.SingleUse, label: "singleUse" },
    { id: UsageType.MultiUser, label: "multipleUse" },
];

export const VOUCHER_ISSUANCE_TYPE = {
    MANUAL: "MANUAL",
    AUTO_GENERATED: "AUTO_GENERATED",
};

export const VOUCHER_ISSUANCE_TYPES = [
    {
        id: VOUCHER_ISSUANCE_TYPE.MANUAL,
        label: "manual",
    },
    {
        id: VOUCHER_ISSUANCE_TYPE.AUTO_GENERATED,
        label: "autoGenerate",
    },
];

export const VOUCHER_STATUS = {
    USED: "USED",
    AVAILABLE: "AVAILABLE",
    CANCELLED: "CANCELLED",
    EXPIRING: "EXPIRING",
    EXPIRED: "EXPIRED",
};

export const VOUCHER_STATUSES = [
    { id: VoucherStatus.Available, label: "available", color: "#33B950" },
    { id: VoucherStatus.Used, label: "used", color: "#29B3BC" },
    { id: VoucherStatus.Cancelled, label: "cancelled", color: "#C4C4C4" },
    { id: VoucherStatus.Expiring, label: "expiring", color: "#FAC256" },
    { id: VoucherStatus.Expired, label: "expired", color: "#ED6969" },
    {
        id: VoucherStatus.Converted,
        label: "convertedToCredit",
        color: "#e08953",
    },
];

export const VOUCHER_OWNER_STATUS = {
    OWNED: "OWNED",
    AVAILABLE: "AVAILABLE",
    CANCELLED: "CANCELLED",
};

export const VOUCHER_OWNER_STATUSES = [
    { id: "AVAILABLE", label: "available", color: "#FAC256" },
    { id: "OWNED", label: "owned", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#ED6969" },
];
