import { useFormikContext } from "formik";
import { filter, find, forEach, isEmpty, isUndefined } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useMeasure } from "react-use";
import {
    PriceSetType,
    useGetCustomerDetailLazyQuery,
} from "../../../api/hooks";
import { CustomerDetailContext } from "../../../context/customer";
import { QuotationCreateContext } from "../../../context/quotation";
import { ICustomer } from "../../../interfaces/customer";
import { OrderType } from "../../../interfaces/order";
import { getProductPrice } from "../../../interfaces/product";
import CustomerInfo from "../share/CustomerInfo";
import CustomerSelect from "../share/CustomerSelect";
import PriceTable from "../share/PriceTable";
import ProductCartItem from "../share/ProductCartItem";
import OrderProductActions from "./QuotationProductActions";

const getSubTotal = (productList: any, applyPriceSet?: PriceSetType) => {
    let result = 0;
    forEach(productList, (product) => {
        result +=
            getProductPrice("salePrice", product, applyPriceSet) *
            product.quantity;
    });

    return result;
};

const QuotationProductsCart = ({ type }: { type?: OrderType }) => {
    const {
        productList,
        setProductList,
        productCustomList,
        setProductCustomList,
        selectedCustomer,
        setSelectedCustomer,
        shipping,
        setShipping,
        billing,
        setBilling,
        cartValue,
        setCartValue,
        checkCartValue,
        manualDiscount,
        setManualDiscount,
        orderMetrics,
    } = useContext(QuotationCreateContext);
    const [actionBlockRef, { height: actionBlockHeight }] = useMeasure<any>();
    const [customerBlockRef, { height: customerBlockHeight }] =
        useMeasure<any>();
    const { values, errors, handleSubmit, setFieldValue, setValues } =
        useFormikContext<any>();
    const [getCustomerDetail, { data }] = useGetCustomerDetailLazyQuery();
    const { customer, primarySalesPerson } = values;

    const onChangeQuantity = (product: any, quantity: number) => {
        if (quantity < 1) {
            return;
        }
        if (product.isCustomProduct) {
            const newProductList = productCustomList.map((item: any) => {
                if (item.id === product?.id) {
                    return {
                        ...item,
                        quantity,
                    };
                }
                return item;
            });
            setProductCustomList([...newProductList]);
        } else {
            const newProductList = productList.map((item: any) => {
                if (item.productOrderId === product?.productOrderId) {
                    return {
                        ...item,
                        quantity,
                    };
                }
                return item;
            });
            setProductList([...newProductList]);
        }
    };

    const onDeleteProductHandle = (productInfo: any) => {
        const { id, productOrderId } = productInfo;

        if (productInfo.isCustomProduct) {
            const newProCustomList = productCustomList.filter(
                (item: any) => item.id !== id
            );
            setProductCustomList(newProCustomList);
        } else {
            const newProductList = productList.filter(
                (item: any) => item.productOrderId !== productOrderId
            );

            if (manualDiscount && manualDiscount?.length > 0) {
                const newDiscountList = filter(
                    manualDiscount ?? [],
                    (i) => i?.product?.productOrderId !== productOrderId
                );
                setManualDiscount && setManualDiscount(newDiscountList);
                if (isEmpty(newDiscountList)) {
                    setCartValue({ ...cartValue, manualDiscount: undefined });
                }
            }
            setProductList([...newProductList]);
        }
    };

    const onChangeProductDetail = (
        productInfo: any,
        key: string,
        newValue: any
    ) => {
        const { id } = productInfo;
        if (productInfo.isCustomProduct) {
            const newProCustomList = productCustomList.map((item: any) =>
                item.id !== id
                    ? item
                    : {
                          ...item,
                          [key]: newValue,
                      }
            );
            setProductCustomList(newProCustomList);
        } else {
            const newProductList = productList.map((item: any) =>
                item.id !== id
                    ? item
                    : {
                          ...item,
                          [key]: newValue,
                      }
            );
            setProductList([...newProductList]);
        }
    };

    const productSource = useMemo(
        () => [...productList, ...productCustomList],
        [productList, productCustomList]
    );

    const submitOrder = () => {
        handleSubmit();
    };

    useEffect(() => {
        if (cartValue?.voucher?.length || manualDiscount?.length) {
            checkCartValue && checkCartValue(values);
        } else {
            const subTotalValue = getSubTotal(
                [...productList, ...productCustomList],
                orderMetrics?.applyPriceSet
            );
            setCartValue({
                ...cartValue,
                subtotal: subTotalValue,
                total: subTotalValue,
            });
        }
    }, [productList, productCustomList, manualDiscount]);

    ///load customer detail after selected
    useEffect(() => {
        if (customer?.[0]?.id) {
            getCustomerDetail({
                variables: {
                    id: customer?.[0]?.id,
                },
            }).then((resp) => {
                updateCustomerContextData(resp);
                setProductList([]);
            });
        } else if (!isUndefined(setSelectedCustomer)) {
            setSelectedCustomer(undefined);
            setShipping([]);
            setBilling(undefined);
            if (isEmpty(customer)) {
                setProductList([]);
            }
        }
    }, [customer]);

    const updateCustomerContextData = (resp: any) => {
        if (!isUndefined(setSelectedCustomer)) {
            let customerDetail = resp?.data?.data?.data;
            if (customer?.[0]?.setting?.isStaff) {
                customerDetail = {
                    ...customerDetail,
                    setting: {
                        ...(customerDetail?.setting || {}),
                        isStaff: true,
                    },
                };
            }

            setSelectedCustomer(customerDetail);
            if (
                customerDetail?.address &&
                customerDetail?.address.length &&
                !isUndefined(setShipping)
            ) {
                const defaultAddress = find(
                    customerDetail?.address,
                    (add) => add.isDefault
                );
                setShipping(
                    defaultAddress
                        ? [defaultAddress]
                        : [customerDetail?.address?.[0]]
                );
            }
        }
    };

    return (
        <div className="border relative ">
            <div
                className="bg-white p-3"
                style={{ height: `calc(100vh - 142px)` }}
            >
                <div ref={customerBlockRef}>
                    <CustomerSelect key={values?.purchaseTypes} />
                    {!isEmpty(customer) && (
                        <CustomerInfo customer={customer?.[0]} />
                    )}
                </div>
                <div
                    className="overflow-auto"
                    style={{
                        height: `calc(100% - ${
                            customerBlockHeight + actionBlockHeight
                        }px)`,
                    }}
                >
                    <div className="my-3">
                        {productSource.map((product, index) => (
                            <ProductCartItem
                                product={product}
                                isSubItem={false}
                                key={index}
                                onChangeQuantity={onChangeQuantity}
                                onDeleteProduct={onDeleteProductHandle}
                                onChangeProductDetail={onChangeProductDetail}
                                type={type}
                                applyPriceSet={orderMetrics?.applyPriceSet}
                                primarySalesPerson={primarySalesPerson}
                                manualDiscount={manualDiscount}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div
                ref={actionBlockRef}
                className="bg-white absolute bottom-0 left-0 w-100 z-30"
            >
                <div className="bg-white">
                    <PriceTable />
                </div>
                <CustomerDetailContext.Provider
                    value={
                        {
                            customer: selectedCustomer as ICustomer,
                            setCustomer: setSelectedCustomer,
                        } as any
                    }
                >
                    <OrderProductActions
                        // applyCoupon={(coupon: any) => onLoadFreeGift(coupon)}
                        submitOrder={submitOrder}
                        type={type}
                    />
                </CustomerDetailContext.Provider>
            </div>
        </div>
    );
};

export default QuotationProductsCart;
