import { gql } from "@apollo/client";
import { TreeDataUtils } from "d-react-components";
import { map } from "lodash";
import API from "../API";
import { F_DIRECTORY, F_MEDIA } from "../fragments/media";
import axios from "axios";

const allDirConverter = {
    fromServer: (res: any) => {
        const dirAll = res?.data?.data?.data ?? [];
        const dirAllMap = map(dirAll, (item) => ({
            ...item,
            parentId: item?.parent?.id,
            key: item.id,
            children: [],
        }));
        return TreeDataUtils.mapListDataToTree(dirAllMap, "parentId");
    },
};

export const getUrlUpload = gql`
    query getUrlUpload($name: String!) {
        getUrlUploadForAdmin(name: $name) {
            data {
                uploadUrl
                fileName
                fileUrl
                status
            }
        }
    }
`;

export const getAllMediaDir = gql`
    ${F_DIRECTORY}
    query getAllMediaDir($paginate: MediaFolderPaginateRequest!) {
        data: allMediaFolderForAdmin(paginate: $paginate) {
            data {
                ...F_DIRECTORY
            }
        }
    }
`;

export const getMedias = gql`
    ${F_MEDIA}
    query getMedias($folderId: String!, $paginate: MediaFilePaginateRequest!) {
        data: listFilesInFolderForAdmin(
            folderId: $folderId
            paginate: $paginate
        ) {
            data {
                ...F_MEDIA
            }
        }
    }
`;

export const getMediaDetail = gql`
    ${F_MEDIA}
    query getMediaDetail($id: String!) {
        data: detailMediaFileForAdmin(id: $id) {
            data {
                ...F_MEDIA
            }
        }
    }
`;

export const createFolder = gql`
    ${F_DIRECTORY}
    mutation createFolder($input: MediaFolderCreateRequest!) {
        data: createMediaFolderForAdmin(input: $input) {
            data {
                ...F_DIRECTORY
            }
        }
    }
`;

export const updateFolder = gql`
    ${F_DIRECTORY}
    mutation updateFolder(
        $folderId: String!
        $input: MediaFolderUpdateRequest!
    ) {
        data: updateMediaFolderForAdmin(folderId: $folderId, input: $input) {
            data {
                ...F_DIRECTORY
            }
        }
    }
`;

export const moveFolder = gql`
    ${F_DIRECTORY}
    mutation moveFolder($folderId: String!, $parentId: String) {
        data: moveMediaFolderForAdmin(
            folderId: $folderId
            parentId: $parentId
        ) {
            data {
                ...F_DIRECTORY
            }
        }
    }
`;

export const removeFolder = gql`
    ${F_DIRECTORY}
    mutation removeFolder($fileId: String!) {
        data: deleteMediaFolderForAdmin(fileId: $fileId) {
            data {
                ...F_DIRECTORY
            }
        }
    }
`;

export const moveMedia = gql`
    mutation moveMedia($fileIds: [String!]!, $destinationId: String!) {
        data: moveMediaFileForAdmin(
            fileIds: $fileIds
            destinationId: $destinationId
        )
    }
`;

export const removeMedia = gql`
    mutation removeMedia($fileIds: [String!]!) {
        data: deleteMediaFileForAdmin(fileIds: $fileIds)
    }
`;

export const addMedia = gql`
    ${F_MEDIA}
    mutation addMedia($input: MediaFileCreateRequest!) {
        data: createMediaFileForAdmin(input: $input) {
            data {
                ...F_MEDIA
            }
        }
    }
`;

const MediaAPI = {
    uploadFile: async (fileData: any) => {
        try {
            const dataUrlUpload = await API.query({
                query: getUrlUpload,
                fetchPolicy: "no-cache",
                variables: {
                    name: fileData?.name,
                },
                // fetchPolicy: "network-only",
            });
            const { uploadUrl, fileUrl, fileName } =
                dataUrlUpload?.data?.getUrlUploadForAdmin?.data ?? {};

            const uploaded = await axios.put(uploadUrl as any, fileData);
            return { fileUrl, fileName };
        } catch (error) {
            return Promise.reject(error);
        }
    },

    uploadMediaToDir: async (folderId: string, file: any) => {
        try {
            const { fileUrl, fileName } = await MediaAPI.uploadFile(file);
            const input = {
                title: file?.title,
                folderId,
                fileUrl,
                fileName,
                type: file?.type,
                size: file?.size,
                dimension: file?.dimension,
            };
            // console.log("fileName", fileName);

            await API.mutate({
                mutation: addMedia,
                variables: { input },
                fetchPolicy: "no-cache",
            });

            return fileUrl;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    getAllMediaDir: async (paginate: any) =>
        API.withConverter(allDirConverter).query({
            query: getAllMediaDir,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    getMedias: async (folderId: string, paginate: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: getMedias,
            variables: { folderId, paginate },
            fetchPolicy: "no-cache",
        }),

    getMediaDetail: async (id: string) =>
        API.query({
            query: getMediaDetail,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    createFolder: async (input: any) =>
        API.withConverter(allDirConverter).mutate({
            mutation: createFolder,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    moveFolder: async (folderId: string, parentId: string) =>
        API.withConverter(allDirConverter).mutate({
            mutation: moveFolder,
            variables: { folderId, parentId },
            fetchPolicy: "no-cache",
        }),

    updateFolder: async (folderId: string, input: any) =>
        API.withConverter(allDirConverter).mutate({
            mutation: updateFolder,
            variables: { folderId, input },
            fetchPolicy: "no-cache",
        }),

    removeFolder: async (fileId: string) =>
        API.withConverter(allDirConverter).mutate({
            mutation: removeFolder,
            variables: { fileId },
            fetchPolicy: "no-cache",
        }),

    moveMedia: async (fileIds: string[], destinationId: string) =>
        API.mutate({
            mutation: moveMedia,
            variables: { fileIds, destinationId },
            fetchPolicy: "no-cache",
        }),

    removeMedia: async (fileIds: string[]) =>
        API.mutate({
            mutation: removeMedia,
            variables: { fileIds },
            fetchPolicy: "no-cache",
        }),
};
export default MediaAPI;
