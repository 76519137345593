import { map } from "lodash";

export const mapServiceConfigurationFilterToQueryParams = (input: any) => {
    return {
        status: input?.status,
        categories: map(input?.categories, (item) => ({
            id: item.id,
            name: item.name,
        })),
    };
};

export const mapServiceConfigurationFilterToServer = (input: any) => {
    return {
        status: input?.status ? [input?.status] : undefined,
        categories: map(input?.categories, (item) => item.id),
    };
};
