import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import { MATERIAL_STATUSES } from "../../../constant/material";
import { MaterialDetailContext } from "../../../context/material";
import { IMaterial } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";

const MaterialDetailSummaryView = () => {
    const { material } = useContext(MaterialDetailContext);
    const DETAILS_KEY: IRowsKey<IMaterial>[] = [
        {
            id: "materialNo",
            label: Messages.id,
        },
        {
            id: "category",
            label: Messages.category,
            renderContent: ({ data }) => {
                return data?.name;
            },
        },
        {
            id: "sku",
            label: Messages.sku,
        },
        {
            id: "purchaseUnit",
            label: Messages.purchasingUnit,
            renderContent: ({ data, item }) => data?.name,
        },
        {
            id: "trackingUnit",
            label: Messages.trackingUnit,
            renderContent: ({ data, item }) => data?.name,
        },
        {
            id: "trackingValue",
            label: Messages.unitConversionRate,
            renderContent: ({ data, item }) => {
                return `${item.purchaseValue} ${item.purchaseUnit?.name} = ${data} ${item.trackingUnit?.name}`;
            },
        },
        {
            id: "lastPurchasingUnitCost",
            label: Messages.lastPurchasingUnitCost,
            renderContent: ({ data, item }) => {
                return `${data} THB/ ${item.purchaseUnit?.name}`;
            },
        },
        {
            id: "lastTrackingUnitCost",
            label: Messages.lastTrackingUnitCost,
            renderContent: ({ data, item }) => {
                return `${data} THB/ ${item.trackingUnit?.name}`;
            },
        },
        {
            id: "totalQuantityOnPurchase",
            label: Messages.onOrderQty,
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
    ];

    return (
        <div>
            {material && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 border-b py-5 px-3">
                        {material?.gallery?.[0] ? (
                            <img
                                src={material?.gallery?.[0]}
                                className="w-32 h-32"
                            />
                        ) : (
                            <img src="/icons/contract.svg" />
                        )}
                        <div className="text-large-bold my-3">
                            {material?.name}
                        </div>
                        <ViewLabelStatus
                            status={material?.status}
                            listStatus={MATERIAL_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={material}
                            keyList={DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaterialDetailSummaryView;
