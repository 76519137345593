import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { map } from "lodash";
import { useContext } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import {
    TREATMENT_STATUS,
    TREATMENT_STATUSES,
} from "../../../constant/treatment";
import { TreatmentDetailContext } from "../../../context/treatment";
import { ITreatment } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";
import Path from "../../Path";

const TreatmentDetailGeneral = () => {
    const { treatment } = useContext(TreatmentDetailContext);
    const GENERAL_KEYS: IRowsKey<ITreatment>[] = [
        {
            id: "doctorOrder",
            label: Messages.soNo,
            renderContent: ({ data }) => (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: data?.order?.id ?? "",
                    })}
                >
                    {data?.order?.orderNo}
                </AppLink>
            ),
        },
        {
            id: "doctorOrder",
            label: Messages.doctorOrderNo,
            renderContent: ({ data }) => (
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: data.id,
                    })}
                >
                    {data?.doctorOrderNo}
                </AppLink>
            ),
        },
        {
            id: "treatmentNo",
            label: Messages.treatmentNo,
        },
        {
            id: "branch",
            label: Messages.branchWarehouse,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "services",
            label: Messages.service,
            renderContent: ({ data }) => {
                return (
                    <div className="flex">
                        {map(data, (item) => {
                            return (
                                <AppLink
                                    to={generatePath(Path.PRODUCT_DETAIL, {
                                        productId: item?.product?.id ?? "",
                                    })}
                                    target="_blank"
                                >
                                    {item.product?.name}
                                </AppLink>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            id: "services",
            label: Messages.quantity,
            renderContent: ({ data }) => {
                const serviceQty = data?.[0]?.quantity ?? 1;
                return <div className="flex">{serviceQty}</div>;
            },
        },
        {
            id: "day",
            label: Messages.dateOfTreatment,
        },
        {
            id: "doctor",
            label: Messages.doctorSlot,
            renderContent: ({ data }) =>
                `${fmtMSS(data?.schedule?.start)} - ${fmtMSS(
                    data?.schedule?.end
                )}`,
        },
        {
            id: "schedule",
            label: Messages.scheduledTreatmentTime,
            renderContent: ({ data }) =>
                `${fmtMSS(data?.start)} - ${fmtMSS(data.end)}`,
        },
        {
            id: "completion",
            label: Messages.actualTreatmentTime,
            renderContent: ({ data, item }) => {
                if (
                    item?.status === TREATMENT_STATUS.CANCELLED ||
                    item?.status === TREATMENT_STATUS.SCHEDULED
                ) {
                    return <div />;
                }

                if (item?.status === TREATMENT_STATUS.IN_PROGRESS) {
                    return TimeUtils.toDateTime(item?.starting?.actualTime);
                }

                return `${TimeUtils.toDateTime(
                    item?.starting?.actualTime
                )} - ${TimeUtils.toDateTime(data?.actualTime)}`;
            },
        },
        {
            id: "complicationTreatments",
            label: Messages.complicationCase,
            renderContent: ({ data }) => {
                return data?.length ? Messages.yes : Messages.no;
            },
        },
        {
            id: "status",
            label: Messages.status,
            renderContent: ({ data }) => (
                <ViewLabelStatus
                    status={data}
                    listStatus={TREATMENT_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
    ];

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={treatment}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default TreatmentDetailGeneral;
