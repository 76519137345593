import { reduce } from "lodash";
import { useContext, useEffect } from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import { ProductCreateContext } from "../../../context/product";
import ProductGeneralInfoForm from "../share/ProductGeneralInfoForm";

const ProductCreateInfo = () => {
    const { productForm } = useContext(ProductCreateContext);

    const { values, setFieldValue, setValues } = productForm || {};
    const { typeOfProduct, groups } = values || {};

    useEffect(() => {
        if (typeOfProduct === PRODUCT_TYPE.GROUP_PRODUCT) {
            if (groups?.length) {
                const proPrice = reduce(
                    groups,
                    (res, item) => {
                        return {
                            salePrice:
                                (res?.salePrice || 0) + (item?.salePrice || 0),
                            regularPrice:
                                (res?.regularPrice || 0) +
                                (item?.regularPrice || 0),
                        };
                    },
                    {
                        salePrice: 0,
                        regularPrice: 0,
                    }
                );
                setValues((v: any) => ({
                    ...v,
                    ...(proPrice || {}),
                }));
            } else {
                setValues((v: any) => ({
                    ...v,
                    salePrice: 0,
                    regularPrice: 0,
                }));
            }
        }
    }, [typeOfProduct, groups]);

    return <ProductGeneralInfoForm productForm={productForm} />;
};

export default ProductCreateInfo;
