import {
    AwesomeTableComponent,
    DateInput,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { find, isUndefined } from "lodash";
import moment from "moment";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
    JobTypeAdmin,
    useCreateOrderDeliveryForAdminMutation,
    useListProductOrderDeliveryForAdminQuery,
    useUpdateOrderDeliveryForAdminMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { ORDER_DELIVERY_SERVICES, ORDER_DELIVERY_TYPES } from "../../../constant/order";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";
import ProductName from "../../quotation/share/ProductName";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import UserSelect from "../../user/share/UserSelect";
import VehicleSelect from "../../vehicle/share/VehicleSelect";
import AddressItem from "../share/AddressItem";

export const DeliveryFormSchema = Yup.object().shape({
    deliveryType: Yup.mixed().required(Messages.fieldRequired),
    branch: Yup.mixed().required(Messages.fieldRequired),
    deliveryService: Yup.string().required(Messages.fieldRequired),
    vehicle: Yup.mixed().required(Messages.fieldRequired),
    driver: Yup.mixed().required(Messages.fieldRequired),
    deliveryNo: Yup.string().required(Messages.fieldRequired),
    estDateOfArrival: Yup.mixed().required(Messages.fieldRequired),
    shipping: Yup.mixed().required(Messages.fieldRequired),
    remark: Yup.string().required(Messages.fieldRequired),
});

const OrderDeliveryCreate = ({
    open,
    onClose,
    onSave,
    isView,
    delivery,
}: any) => {
    const isEdit = !isUndefined(delivery);
    const { orderId } = useParams<any>();
    const { shippingList, order } = useContext(OrderDetailContext);
    const [deliveryProducts, setDeliveryProducts] = useState<any[]>(
        delivery ? delivery.products : []
    );
    const [selectedAddress, setSelectedAddress] = useState<any>(undefined);
    const [createOrderDelivery] = useCreateOrderDeliveryForAdminMutation();
    const [updateOrderDelivery] = useUpdateOrderDeliveryForAdminMutation();
    const { data } = useListProductOrderDeliveryForAdminQuery({
        variables: {
            order: orderId as string,
        },
        fetchPolicy: "no-cache",
    });

    const deliveryForm = useFormik<any>({
        initialValues: delivery
            ? {
                  deliveryType: delivery.deliveryType,
                  branch: delivery.branch,
                  deliveryService: delivery.deliveryService,
                  vehicle: delivery.vehicle,
                  driver: delivery.driver,
                  deliveryNo: delivery.deliveryNo,
                  estDateOfArrival: moment(delivery.estDateOfArrival),
                  shipping: delivery?.shipping?.id,
                  remark: delivery.remark,
              }
            : ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DeliveryFormSchema,
        onSubmit: (values: any) => {
            const {
                deliveryType,
                branch,
                deliveryService,
                vehicle,
                driver,
                deliveryNo,
                estDateOfArrival,
                shipping,
                remark,
            } = values;
            Progress.show(
                {
                    method: isEdit ? updateOrderDelivery : createOrderDelivery,
                    params: [
                        {
                            variables: {
                                order: order?.id as string,
                                payload: {
                                    ...(isEdit ? {} : { deliveryType }),
                                    branch: branch?.id,
                                    deliveryService,
                                    vehicle: vehicle?.id,
                                    driver: driver?.id,
                                    deliveryNo,
                                    estDateOfArrival:
                                        moment(estDateOfArrival).toDate(),
                                    shipping,
                                    remark,
                                    products: deliveryProducts
                                        .map((item) => ({
                                            product: item?.product?.id,
                                            quantity: item.stock,
                                        }))
                                        .filter((item) => item.quantity > 0),
                                },
                                ...(isEdit ? { id: delivery.id } : {}),
                            },
                        },
                    ],
                },
                (resp) => {
                    Notifications.showSuccess(
                        Messages.createDeliverySuccessfully
                    );
                    onSave && onSave();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    const { values, errors, setFieldValue, handleSubmit } = deliveryForm;

    useEffect(() => {
        if (!isUndefined(delivery) && delivery.shipping) {
            setSelectedAddress(delivery.shipping);
        }
    }, []);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="800px"
            title={isEdit ? Messages.updateDelivery : Messages.createDelivery}
            onSave={handleSubmit}
            disableSave={isView}
        >
            <div className="p-3">
                <div className="mb-3">
                    <InputSelectForm
                        form={deliveryForm}
                        keyData="deliveryType"
                        dataSource={ORDER_DELIVERY_TYPES}
                        label={Messages.deliveryType}
                        disabled={isEdit}
                    />
                </div>
                <div className="mb-3">
                    <BranchSelect
                        value={values?.branch}
                        onChange={(value) => setFieldValue("branch", value)}
                        error={errors?.branch}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <InputSelectForm
                        form={deliveryForm}
                        keyData="deliveryService"
                        dataSource={ORDER_DELIVERY_SERVICES}
                        label={Messages.shippingService}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <VehicleSelect
                        value={values?.vehicle}
                        onChange={(value) => setFieldValue("vehicle", value)}
                        error={errors?.vehicle}
                        label={Messages.vehicleNo}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <UserSelect
                        onChange={(value) => setFieldValue("driver", value)}
                        label={Messages.driver}
                        params={{ jobType: JobTypeAdmin.Driver }}
                        value={values?.driver}
                        error={errors?.driver as string}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <InputTextForm
                        form={deliveryForm}
                        keyData="deliveryNo"
                        label={Messages.deliveryNo}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <DateInput
                        label={Messages.estDateOfArrival}
                        onChange={(value) => {
                            setFieldValue("estDateOfArrival", value);
                        }}
                        value={
                            values?.estDateOfArrival
                                ? moment(values?.estDateOfArrival)
                                : (null as any)
                        }
                        format="DD/MM/YYYY"
                        error={errors?.estDateOfArrival as any}
                        disabled={isView}
                    />
                </div>
                {data?.data?.data && (
                    <div className="mb-3">
                        <TableItemDelivery
                            products={data?.data?.data ?? []}
                            onChange={(values: any) =>
                                setDeliveryProducts(values)
                            }
                            disabled={isView}
                            isEdit={isEdit}
                            inDeliveryProducts={delivery?.products}
                        />
                    </div>
                )}
                <div className="mb-3">
                    <Select
                        dataSource={shippingList}
                        label={Messages.address}
                        value={values?.shipping}
                        onChange={(value, option) => {
                            setFieldValue("shipping", value);
                            setSelectedAddress(
                                value
                                    ? find(shippingList, { id: value })
                                    : undefined
                            );
                        }}
                        getLabel={(address) => (
                            <AddressItem address={address} displayType="row" />
                        )}
                        error={errors?.shipping}
                        disabled={isView}
                    />
                </div>
                {selectedAddress && (
                    <div className="p-3 my-2 bg-[#DCE5E5]">
                        <AddressItem address={selectedAddress} />
                    </div>
                )}
                <div className="mb-3">
                    <InputTextForm
                        form={deliveryForm}
                        keyData="remark"
                        label={Messages.remark}
                        multiple
                        disabled={isView}
                    />
                </div>
            </div>
        </Drawer>
    );
};

//fake stock
const addStockToProducts = (products: any[], inDeliveryProducts: any[]) => {
    return products.map((product) => {
        const inDeliveryProduct = find(inDeliveryProducts, (p) => p.id === product.id);
        const currentStock = inDeliveryProduct ? product.quantity - inDeliveryProduct.quantity : 0;
        return {
            ...product,
            stock: currentStock,
            leftItemCount: product.quantity - currentStock,
            id: product.id,
        };
    });
};

const TableItemDelivery = ({
    onChange,
    products = [],
    inDeliveryProducts = [],
    disabled = false,
    isEdit = false,
    transferDelivery = false,
    selectedWarehouse,
}: any) => {
    const [productDelivery, setProductDelivery] = useState(
        addStockToProducts(products, inDeliveryProducts)
    );
    const deliveryItemList = useRef<any>(null);

    useEffect(() => {
        deliveryItemList && deliveryItemList.current.refreshData();
        onChange && onChange(productDelivery);
    }, [productDelivery]);

    // useEffect(() => {
    //     if (!selectedWarehouse || !selectedWarehouse?.id) return;
    //     onLoadStockInformation();
    // }, [selectedWarehouse]);

    // const onLoadStockInformation = () => {
    //     const body = {
    //         product_id: products.map((item: any) => item?.product?.id),
    //         warehouse_id: selectedWarehouse?.id,
    //     };
    //     getStockProductWarehouse(body).then((res) => {
    //         const stockList = res?.data?.data?.stock ?? [];

    //         const productDeliveryResult = productDelivery.map(
    //             (productItem: any) => {
    //                 const stockItem = find(
    //                     stockList,
    //                     (item) => item?.product?.id === productItem?.product?.id
    //                 );
    //                 if (stockItem?.product) {
    //                     return {
    //                         ...productItem,
    //                         stockHold: stockItem?.hold,
    //                         stockWarehouse: stockItem?.stock,
    //                     };
    //                 }
    //                 return productItem;
    //             }
    //         );
    //         setProductDelivery(productDeliveryResult);
    //     });
    // };

    const onChangeQuantitySubPro = useCallback(
        (parentPro, childPro, quantity) => {
            if (disabled) {
                return;
            }
            if (quantity > childPro.quantity || quantity < 0) {
                return;
            }
            if (quantity > childPro.stock && childPro.leftItemCount === 0) {
                Notifications.showError(Messages.noLeftItem);
                return;
            }
            const productResult = productDelivery.map((itemParam: any) => {
                if (parentPro.id === itemParam.id) {
                    const { groups } = itemParam;
                    const groupResult = setChangeQuantityInProducts(
                        groups,
                        childPro,
                        quantity
                    );

                    return {
                        ...itemParam,
                        groups: groupResult,
                    };
                }
                return itemParam;
            });
            setProductDelivery(productResult);
        },
        [disabled, productDelivery]
    );

    const onChangeQuantityPickup = useCallback(
        (quantity, item) => {
            if (disabled) {
                return;
            }
            if (quantity > item.quantity || quantity < 0) {
                return;
            }
            if (quantity > item.stock && item.leftItemCount === 0) {
                Notifications.showError(Messages.noLeftItem);
                return;
            }
            const productResult = setChangeQuantityInProducts(
                productDelivery,
                item,
                quantity
            );
            setProductDelivery(productResult);
        },
        [disabled, productDelivery]
    );

    const setChangeQuantityInProducts = (
        products: any,
        productItem: any,
        quantity: any
    ) => {
        const list = products.map((itemParam: any) => {
            if (productItem?.product?.id === itemParam?.product?.id) {
                return {
                    ...itemParam,
                    stock: quantity,
                    leftItemCount:
                        itemParam.leftItemCount + itemParam.stock - quantity,
                };
            }
            return itemParam;
        });
        return list;
    };

    const columns = useMemo(
        () => [
            {
                title: Messages.item,
                dataIndex: "product",
                render: (product: any, item: any) => {
                    const isNoneItem = item.leftItemCount === 0;
                    let subBody = <div />;
                    if (!item.groups || item.groups.length === 0) {
                        subBody = (
                            <div
                                className={
                                    isNoneItem
                                        ? "text-xs text-green-500"
                                        : "text-xs text-orange-500"
                                }
                            >
                                {item.leftItemCount + Messages.willBeLeft}
                            </div>
                        );
                    }
                    return <ProductName item={product} subBody={subBody} />;
                },
                // ellipsis: true,
                width: 500,
            },
            // {
            //     title: Messages.orderQty,
            //     dataIndex: "quantity",
            //     render: (quantity: any) => quantity,
            // },
            // ...(transferDelivery
            //     ? []
            //     : [
            //           {
            //               title: Messages.availableQtyCurrentStore,
            //               dataIndex: "stockWarehouse",
            //               titleTooltip:
            //                   Messages.availableQtyCurrentStoreTooltip,
            //               render: (stockWarehouse: any) => stockWarehouse,
            //           },
            //       ]),
            // ...(transferDelivery
            //     ? []
            //     : [
            //           {
            //               title: Messages.onHoldQtyCurrentStore,
            //               dataIndex: "stockHold",
            //               titleTooltip: Messages.onHoldQtyCurrentStoreTooltip,
            //               render: (stockHold: any) => stockHold,
            //           },
            //       ]),
            {
                title: Messages.quantity,
                dataIndex: "stock",
                width: disabled ? 70 : 200,
                render: (stock, item) => {
                    const isGroupProduct = item?.groups?.length > 0;
                    const parentPro = item?.parentPro;
                    const isSubProductTable = !!parentPro;
                    let quantityView = (
                        <QuantityInputField
                            quantity={stock}
                            onChangeQuantity={(quantity: any) =>
                                isSubProductTable
                                    ? onChangeQuantitySubPro(
                                          parentPro,
                                          item,
                                          quantity
                                      )
                                    : onChangeQuantityPickup(quantity, item)
                            }
                        />
                    );
                    if (disabled) {
                        quantityView = stock;
                    }
                    return isGroupProduct ? <div /> : quantityView;
                },
            },
        ],
        [
            disabled,
            onChangeQuantityPickup,
            onChangeQuantitySubPro,
            transferDelivery,
        ]
    );

    const source = () => {
        return Promise.resolve(productDelivery);
    };

    const transformer = (products: any) => {
        const transformedProduct = products.map((product: any = {}) => {
            let { groups = [] } = product;
            if (groups?.length > 0) {
                groups = groups.map((item: any) => ({
                    ...item,
                    parentPro: product,
                }));
            }
            if (groups?.length > 0) {
                return {
                    ...product,
                    children: groups,
                };
            }
            return product;
        });
        return transformedProduct;
    };

    return (
        <AwesomeTableComponent
            className="height-auto"
            baseColumnProps={{ width: 150, align: "center" }}
            ref={(ref) => {
                deliveryItemList.current = ref;
            }}
            source={() => source()}
            transformer={transformer}
            columns={columns}
            isScroll={false}
            isPagination={false}
            classNameTable="tableItemDelivery"
            // expandable={{
            //     rowExpandable: (product) => product?.groups?.length > 0,
            //     expandedRowKeys: calculateDefaultExpandedRowKeys(
            //         productDelivery,
            //         {}
            //     ),
            //     // defaultExpandedRowKeys: true,
            // }}
            rowClassName={(product) => {
                let rowClassName = "rowAwesomeTable ";
                if (product.isGift) {
                    rowClassName += "productGiftTableItemRow";
                }
                return rowClassName;
            }}
        />
    );
};

export default OrderDeliveryCreate;
