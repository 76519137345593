import { Button, Modal, ViewTextError } from "d-react-components";
import React, { ElementRef, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useMedia } from "react-use";
import { APP_WIDE_SCREEN_WIDTH } from "../../constant/app";
import Messages from "../../languages/Messages";
import "./SignatureField.scss";

export const SignatureModal: React.FC<any> = ({
    open,
    onClose,
    canvasProps = {},
    onSave,
    description,
}) => {
    const isWide = useMedia(`(min-width: ${APP_WIDE_SCREEN_WIDTH}px)`);
    const signaturePadRef = useRef<ElementRef<typeof SignatureCanvas>>(null);
    const onClear = () => {
        signaturePadRef.current && signaturePadRef.current.clear();
    };

    const onSaveSignature = async () => {
        onSave &&
            onSave(
                signaturePadRef.current && signaturePadRef.current.toDataURL()
            );
        onClose && onClose();
        return Promise.resolve();
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            onSave={onSaveSignature}
            title={Messages.signature}
            size="medium"
            className="signature-field-modal"
        >
            <div className="flex-center">
                <div className="signaturePadViewContainer ">
                    <Button
                        className="buttonClearSignature"
                        variant="trans"
                        color="primary"
                        onClick={onClear}
                    >
                        {Messages.clear}
                    </Button>
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                            className: "signaturePadView ",
                            ...canvasProps,
                        }}
                        ref={signaturePadRef}
                    />
                </div>
                {description && (
                    <div className="subTitle2 mt-3">{description}</div>
                )}
            </div>
        </Modal>
    );
};

const SignatureField = ({
    content = Messages.signNow,
    onChange,
    error,
    value,
    className = "",
}: any) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className={className}>
            {value && (
                <img
                    src={value}
                    className="image-reg-x-large border mb-3"
                />
            )}
            <Button onClick={() => setOpenModal(true)}>{content}</Button>
            {error && <ViewTextError error={error} />}
            <SignatureModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                onSave={onChange}
            />
        </div>
    );
};

export default SignatureField;
