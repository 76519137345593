import React from "react";
import { ICustomer } from "../interfaces/customer";

interface ICustomerCreateState {
    customerForm: any;
}

const customerCreateState: ICustomerCreateState = {
    customerForm: null,
};

export const CustomerCreateContext = React.createContext(customerCreateState);

interface ICustomerDetailState {
    customer: ICustomer;
    setCustomer: any;
    tab: any;
    setTab: any;
    loadCustomerDetail: any;
}

const customerDetailState: ICustomerDetailState = {
    customer: {} as any,
    tab: {} as any,
    setCustomer: () => {},
    setTab: () => {},
    loadCustomerDetail: () => {},
};

export const CustomerDetailContext = React.createContext(customerDetailState);
