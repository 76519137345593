import React, { Dispatch } from "react";
import { IStockTransfer } from "../interfaces/stock-transfer";

interface IStockTransferDetailState {
    stockTransfer: IStockTransfer;
    setStockTransfer: Dispatch<IStockTransfer>;
    loadTransferDetail: Function;
}

const stockTransferDetailState: IStockTransferDetailState = {
    stockTransfer: {} as any,
    setStockTransfer: () => {},
    loadTransferDetail: () => {},
};

export const StockTransferDetailContext = React.createContext(
    stockTransferDetailState
);
