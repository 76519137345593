import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import React, { useRef, useContext } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import Messages from "../../../languages/Messages";
import { IMaterialAdjustment } from "../../../interfaces/material";
import { useListMaterialStockAdjustLazyQuery } from "../../../api/hooks";
import { MaterialDetailContext } from "../../../context/material";
import AppLink from "../../../common/AppLink";
import { MATERIAL_ADJUST_REQUEST_STATUSES } from "../../../constant/material";
import Path from "../../Path";

const MaterialAdjustments = () => {
    const tableRef = useRef<any>(null);
    const { material } = useContext(MaterialDetailContext);
    const { orderId } = useParams<any>();
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [getAdjustmentList] = useListMaterialStockAdjustLazyQuery();

    const source = (pagingData: any) => {
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            material: [material?.id || ""],
        };
        return getAdjustmentList({
            variables: {
                paginate: input,
            },
        });
    };

    const columns: IColumnsProps = [
        {
            title: Messages.adjustmentDate,
            dataIndex: "createdAt",
            width: 100,
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.ref,
            dataIndex: "adjustmentNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_ADJUST_REQUEST_DETAIL, {
                        requestId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },

        // {
        //     title: Messages.branchWarehouse,
        //     dataIndex: "branch",
        //     render: (branch) => branch?.name,
        // },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_ADJUST_REQUEST_STATUSES}
                    getLabel={(item) => {
                        return Messages[item.label];
                    }}
                />
            ),
        },
        {
            title: Messages.adjustment,
            dataIndex: "materials",
            render: (materials) => <>{materials?.[0]?.adjustment}</>,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    return (
        <div className="p-4">
            <div className="flex mb-3">
                <InputTextSearch className="flex-1" />
                <Button
                    className="ml-2"
                    onClick={() =>
                        navigate(Path.MATERIAL_ADJUST_REQUEST_CREATE)
                    }
                >
                    {Messages.create}
                </Button>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default MaterialAdjustments;
