import classNames from "classnames";
import { Notifications, TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router";
import { useUpdateOrderQuotationForAdminMutation } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { QuotationAction } from "../../../constant/quotation";
import { OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const OrderQuotationReferences = () => {
    const { order, loadOrderDetail } = useContext(OrderDetailContext);
    const [updateOrderQuotation] = useUpdateOrderQuotationForAdminMutation();
    const { quotation } = (order as IOrder) ?? {};

    const onRemoveQuotation = (quotation: any) => {
        updateOrderQuotation({
            variables: {
                id: order?.id as string,
                payload: {
                    quotation: [quotation?.id],
                    action: QuotationAction.REMOVE as any,
                },
            },
        })
            .then(() => {
                loadOrderDetail();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    if (!quotation) {
        return <></>;
    }
    return (
        <div className="border p-3 mb-3">
            <div className="flex">
                <h5 className="font-semibold mb-2">
                    {Messages.quotationReferences}
                </h5>
            </div>
            {quotation.map((order, index) => (
                <div
                    key={index}
                    className={classNames("pt-2 flex-column", {
                        "pb-2 border-b": index + 1 !== quotation.length,
                    })}
                >
                    <span>
                        <AppLink
                            to={generatePath(Path.QUOTATION_DETAIL, {
                                quotationId: order.id,
                            })}
                        >
                            #{order.quotationNo}
                        </AppLink>
                    </span>
                    <span>
                        {Messages.createdOn}{" "}
                        {TimeUtils.convertMiliToDateTime(order.createdAt)}
                    </span>
                    <span
                        className="text-red-500 cursor-pointer"
                        onClick={() => onRemoveQuotation(order)}
                    >
                        {Messages.remove}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default OrderQuotationReferences;
