import {
    AwesomeTableComponent,
    IColumnsProps,
    Icon,
    useDeepEffect,
    ViewTextError,
} from "d-react-components";
import { filter, find, map } from "lodash";
import React, { useEffect, useMemo } from "react";
import InventoryAPI from "../../../../api/queries/inventory";
import InputTextQuantity from "../../../../common/input/InputTextQuantity";
import { PRODUCT_TYPE } from "../../../../constant/product";
import { IProduct } from "../../../../interfaces/product";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/share/ProductNameView";
import ProductSearchButton from "../../../product/share/ProductSearchButton";

const StockAdjustmentCreateItems = ({ stockAdjustmentForm }: any) => {
    const { values, errors, setFieldValue } = stockAdjustmentForm || {};
    const { products, branch } = values || {};
    const productIds = useMemo(() => {
        return map(products || [], (i) => i?.id);
    }, [products]);

    useDeepEffect(() => {
        if (productIds && productIds?.length > 0 && branch?.id) {
            mapStockToProduct();
        }
    }, [productIds]);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (service) => <ProductNameView product={service} />,
        },
        {
            title: Messages.currentQty,
            dataIndex: "currentQty",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => (
                <InputTextQuantity
                    useParseInt={false}
                    value={quantity}
                    onChange={(quantityValue) =>
                        onUpdateQty({
                            ...product,
                            quantity: quantityValue,
                        })
                    }
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (service) => (
                <Icon
                    className="cursor-pointer"
                    name="remove_circle_outline"
                    onClick={() => onRemoveProduct([service?.id])}
                />
            ),
        },
    ];

    const mapStockToProduct = async () => {
        try {
            const res = await InventoryAPI.checkProductStock({
                branch: [branch?.id],
                product: productIds,
            });
            const proStocks = res?.data?.data?.data ?? [];
            const mappedStocks = map(products, (item) => {
                const found = find(proStocks, (pro) => pro?.id === item?.id);
                return {
                    ...item,
                    currentQty: found?.quantity ?? 0,
                    quantity: item?.quantity ?? found?.quantity ?? 0,
                };
            });
            setFieldValue("products", mappedStocks);
        } catch (error) {
            console.error({ error });
        }
    };

    const onAddProduct = (addPros: IProduct[]) => {
        setFieldValue("products", addPros);
    };

    const onRemoveProduct = (removeIds: string[]) => {
        setFieldValue(
            "products",
            filter(products, (pro) => !removeIds?.includes(pro?.id))
        );
    };

    const onUpdateQty = (updatePro: any) => {
        setFieldValue(
            "products",
            map(products, (pro) =>
                updatePro?.id === pro?.id ? updatePro : pro
            )
        );
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
                {values?.branch && (
                    <ProductSearchButton
                        onSave={onAddProduct}
                        defaultValue={products ?? []}
                        filterSource={{
                            productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                        }}
                    >
                        {Messages.add}
                    </ProductSearchButton>
                )}
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={products ?? []}
                className="mt-3"
                pagination={false}
            />
            {errors?.products && <ViewTextError error={errors.products} />}
        </div>
    );
};

export default StockAdjustmentCreateItems;
