import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
    useGetAgencyListLazyQuery,
    useGetAgencyListQuery,
} from "../../../api/hooks";
import { IServiceCategory } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";

interface IAgencySelectProps {
    className?: string;
    value: IServiceCategory | IServiceCategory[];
    multiple?: boolean;
    onChange: (value: IServiceCategory | IServiceCategory[]) => void;
    [key: string]: any;
}

const AgencySelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IAgencySelectProps) => {
    const [agencyList, setAgencyList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getAgencyList] = useGetAgencyListLazyQuery();
    const { data } = useGetAgencyListQuery({
        variables: {
            paginate: {
                page: 1,
                limit: 100,
                search: "",
            },
        },
    });

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setAgencyList([]);
        } else {
            getAgencyList({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 50,
                        search: textSearch,
                    },
                },
            }).then((res: any) => {
                const categoryRes = res?.data?.data?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterCategory = map(
                    [...selectedValue, ...categoryRes],
                    (item) => ({
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqCat = uniqBy(filterCategory, (item) => item.id);
                setAgencyList(uniqCat);
            });
        }
    }, 500);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(agencyList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(agencyList, id));
    };

    const categoryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    useEffect(() => {
        if (!isEmpty(data)) {
            setAgencyList(data?.data?.data ?? []);
        }
    }, [data]);

    return (
        <Select
            // onSearch={onChangeTextSearch}
            className={className}
            label={Messages.agency}
            dataSource={agencyList}
            getLabel={(item) => item?.name}
            value={categoryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default AgencySelect;
