import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RefActivity } from "../../../api/hooks";
import AgencyAPI from "../../../api/queries/agency";
import ActivityView from "../../../common/activity/ActivityView";
import { AgencyDetailContext } from "../../../context/agency";
import { IAgency } from "../../../interfaces/agency";
import Messages from "../../../languages/Messages";
import AgencyDetailGeneral from "./AgencyDetailGeneral";
import AgencyDetailInfo from "./AgencyDetailInfo";

const TABS = [
    {
        id: "dashboard",
        label: Messages.generalInformation,
        component: <AgencyDetailGeneral />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <div />,
    },
];

const AgencyDetail = () => {
    const { agencyId } = useParams<any>();

    console.log(
        "🚀 >>>>>> file: AgencyDetail.tsx:45 >>>>>> AgencyDetail >>>>>> agencyId:",
        agencyId
    );
    const [agencyDetail, setAgencyDetail] = useState<IAgency>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadConfigDetail();
    }, []);

    const loadConfigDetail = () => {
        Progress.show(
            {
                method: AgencyAPI.detail,
                params: [agencyId],
            },
            (res: any) => {
                setAgencyDetail(res);
            }
        );
    };

    if (!agencyDetail) {
        return <div />;
    }

    return (
        <AgencyDetailContext.Provider
            value={{
                agencyDetail,
                setAgencyDetail,
            }}
        >
            <div className="flex-column-container bg-white">
                <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                    <h5 className="text-primary">{agencyDetail?.name}</h5>
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <AgencyDetailInfo />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                            <div
                                className="bg-white mt-3"
                                hidden={tab.id !== "activity"}
                            >
                                <ActivityView
                                    className="mt-3 border"
                                    activityRef={RefActivity?.Customer}
                                    activityRefId={agencyId ?? ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AgencyDetailContext.Provider>
    );
};

export default AgencyDetail;
