import { gql } from "@apollo/client";
import API from "../API";
import { F_STOCK_REQUEST, F_STOCK_REQUESTS } from "../fragments/stock-request";

export const getStockRequestList = gql`
    ${F_STOCK_REQUESTS}
    query getStockRequestList($input: ListAdminProductStockRequestRequest!) {
        data: listProductStockRequestForAdmin(paginate: $input) {
            ...F_STOCK_REQUESTS
        }
    }
`;

export const getStockRequestDetail = gql`
    ${F_STOCK_REQUEST}
    query getStockRequestDetail($id: String!) {
        data: detailProductStockRequestForAdmin(id: $id) {
            data {
                ...F_STOCK_REQUEST
            }
        }
    }
`;

export const createStockRequest = gql`
    ${F_STOCK_REQUEST}
    mutation createStockRequest(
        $payload: CreateAdminProductStockRequestRequest!
    ) {
        data: createProductStockRequestForAdmin(payload: $payload) {
            data {
                ...F_STOCK_REQUEST
            }
        }
    }
`;

export const updateStockRequest = gql`
    ${F_STOCK_REQUEST}
    mutation updateStockRequest(
        $id: String!
        $payload: UpdateAdminProductStockRequestRequest!
    ) {
        data: updateProductStockRequestForAdmin(id: $id, payload: $payload) {
            data {
                ...F_STOCK_REQUEST
            }
        }
    }
`;

export const approveStockRequest = gql`
    ${F_STOCK_REQUEST}
    mutation approveStockRequest($id: String!, $remark: String!) {
        data: approveProductStockRequestForAdmin(id: $id, remark: $remark) {
            data {
                ...F_STOCK_REQUEST
            }
        }
    }
`;

export const cancelStockRequest = gql`
    ${F_STOCK_REQUEST}
    mutation cancelStockRequest($id: String!, $remark: String!) {
        data: cancelProductStockRequestForAdmin(id: $id, remark: $remark) {
            data {
                ...F_STOCK_REQUEST
            }
        }
    }
`;

const stockRequestListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const stockRequestConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

const StockRequestAPI = {
    list: async (input: any) =>
        API.withConverter(stockRequestListConverter).query({
            query: getStockRequestList,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(stockRequestConverter).query({
            query: getStockRequestDetail,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.withConverter(stockRequestConverter).mutate({
            mutation: createStockRequest,
            variables: { payload },
        }),

    update: async (payload: any) =>
        API.withConverter(stockRequestConverter).mutate({
            mutation: updateStockRequest,
            variables: { payload },
        }),

    approve: async (payload: any) =>
        API.withConverter(stockRequestConverter).mutate({
            mutation: approveStockRequest,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),

    cancel: async (payload: any) =>
        API.withConverter(stockRequestConverter).mutate({
            mutation: cancelStockRequest,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
};

export default StockRequestAPI;
