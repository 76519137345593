import {
    AwesomeTableComponent,
    Button,
    DateInput,
    Header,
    IColumnsProps,
    Icon,
    InputText,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, map, reduce } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import TransferVoucherAPI from "../../../api/queries/transfer-voucher";
import PermissibleRender from "../../../common/PermissibleComponent";
import InputSelectForm from "../../../common/input/InputSelectForm";
import PriceView from "../../../common/views/PriceView";
import {
    TRANSFER_VOUCHER_REASONS,
    TRANSFER_VOUCHER_TYPE,
    TRANSFER_VOUCHER_TYPES,
} from "../../../constant/transfer-voucher";
import { PERMISSION } from "../../../constant/user";
import { VOUCHER_STATUSES, VOUCHER_TYPES } from "../../../constant/voucher";
import { TransferVoucherCreateContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerSolidSelect from "../../customer/share/CustomerSolidSelect";
import VoucherSelectDrawer from "../../voucher/share/VoucherSelectDrawer";
import TransferVoucherFromOrderSelect from "./TransferVoucherFromOrderSelect";

const TransferVoucherSelect = ({ className }: any) => {
    const { transferForm } = useContext(TransferVoucherCreateContext);
    const [openDrawer, setOpenDrawer] = useState(false);
    const formValues = transferForm?.values ?? {};
    const columns: IColumnsProps = [
        {
            title: Messages.code,
            dataIndex: "code",
        },
        {
            title: Messages.type,
            dataIndex: "type",
            render: (data) => {
                const voucherType = find(VOUCHER_TYPES, { id: data });
                return Messages[voucherType?.label as string];
            },
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    listStatus={VOUCHER_STATUSES}
                    status={status}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.balance,
            dataIndex: "balance",
            render: (balance) => <PriceView value={balance} />,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (item) => (
                <Icon
                    name="delete"
                    className="cursor-default"
                    onClick={() => onRemoveVoucher(item)}
                />
            ),
        },
    ];

    if (
        formValues?.transferVoucherType ===
        TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER
    ) {
        columns.splice(2, 0, {
            title: Messages.expiryDate,
            dataIndex: "expiryDate",
            render: (expiryDate, voucher) => {
                return (
                    <DateInput
                        value={moment(expiryDate) as any}
                        onChange={(value) => onChangeExpireDate(voucher, value)}
                    />
                );
            },
        });
    }

    const onChangeExpireDate = (voucher: any, expiryDate: any) => {
        const result = map(formValues?.voucher, (item) => {
            if (item.id === voucher.id) {
                return {
                    ...item,
                    expiryDate,
                };
            }
            return item;
        });
        transferForm.setFieldValue("voucher", result);
    };

    const onRemoveVoucher = (voucher: any) => {
        const result = filter(
            formValues?.voucher,
            (item) => item.id !== voucher.id
        );
        transferForm.setFieldValue("voucher", result);
    };

    return (
        <div className={className}>
            <div className="flex-row-between-center">
                <label>{Messages.voucher}</label>
                <Button onClick={() => setOpenDrawer(true)} className="">
                    {Messages.selectVoucher}
                </Button>
            </div>
            <AwesomeTableComponent
                classNameTable="mt-3"
                columns={columns}
                dataSource={formValues?.voucher ?? []}
                className=""
            />
            {openDrawer && (
                <VoucherSelectDrawer
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onSave={(value: any) => {
                        const vouchers = map(value, (item) => ({
                            ...item,
                            expiryDate: item?.expiryDate ?? moment(),
                        }));
                        transferForm.setFieldValue("voucher", vouchers);
                    }}
                    defaultValue={formValues?.voucher}
                    params={{
                        customer:
                            formValues?.transferVoucherType ===
                                TRANSFER_VOUCHER_TYPE.CUSTOMER_TO_CUSTOMER ||
                            formValues?.transferVoucherType ===
                                TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER
                                ? [formValues?.from?.id]
                                : null,
                        isValidToConvertCredit:
                            formValues?.transferVoucherType ===
                            TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER
                                ? true
                                : undefined,
                    }}
                />
            )}
        </div>
    );
};

const TransferVoucherCreate = () => {
    const navigate = useNavigate();

    const transferForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: TransferVoucherSchema,
        onSubmit: (values) => {
            const isCreditTransfer =
                values?.transferVoucherType ===
                TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER;
            const input = {
                transferVoucherType: values?.transferVoucherType,
                voucher: map(values?.voucher, (item) => item.id),
                voucherExpiryDate: map(values?.voucher, (item) => ({
                    expiryDate: new Date(item.expiryDate),
                    id: item.id,
                })),
                reason: values?.reason,
                to: isCreditTransfer ? values?.from?.id : values?.to?.id,
                from: values?.from?.id,
            };

            if (isCreditTransfer) {
                const allVouchers = reduce(
                    values?.voucherOrders,
                    (allVoucher, order) =>
                        allVoucher.concat(
                            map(order?.vouchers, (item) => ({
                                ...item,
                                expiryDate: order.expiryDate,
                            })) as any
                        ),
                    []
                );
                input.voucher = map(allVouchers, (item: any) => item.id);
                input.voucherExpiryDate = map(allVouchers, (item: any) => ({
                    expiryDate: item.expiryDate,
                    id: item.id,
                }));
            }
            onCreateTransfer(input);
        },
    });

    const formValues = transferForm?.values;
    const formErrors = transferForm?.errors;

    const onCreateTransfer = (input: any) => {
        Progress.show(
            {
                method: TransferVoucherAPI.create,
                params: [input],
            },
            (transfer: any) => {
                navigate(
                    generatePath(Path.TRANSFER_VOUCHER_DETAIL, {
                        transferVoucherId: transfer.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccessfully);
            }
        );
    };
    const classNameInput = "col-span-2 mt-3";

    return (
        <div>
            <TransferVoucherCreateContext.Provider value={{ transferForm }}>
                <Header
                    className="sticky top-0"
                    showCancel={false}
                    title={Messages.newTransfer}
                    customRight={() => (
                        <>
                            <Button
                                size="small"
                                className="ml-2"
                                variant="outline"
                                onClick={() => navigate(-1)}
                            >
                                {Messages.back}
                            </Button>
                            <PermissibleRender
                                permission={PERMISSION.TRANSFER_VOUCHER.CREATE}
                            >
                                <Button
                                    size="small"
                                    className="ml-2"
                                    onClick={() => transferForm.handleSubmit()}
                                >
                                    {Messages.save}
                                </Button>
                            </PermissibleRender>
                        </>
                    )}
                />
                <div className="p-3 bg-white">
                    <div className="grid grid-cols-2 gap-4">
                        <InputSelectForm
                            keyData="transferVoucherType"
                            className={classNameInput}
                            form={transferForm}
                            dataSource={TRANSFER_VOUCHER_TYPES}
                        />
                        {formValues?.transferVoucherType ===
                            TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER && (
                            <InputSelectForm
                                keyData="reason"
                                className={classNameInput}
                                form={transferForm}
                                dataSource={TRANSFER_VOUCHER_REASONS}
                            />
                        )}
                        {(formValues?.transferVoucherType ===
                            TRANSFER_VOUCHER_TYPE.CUSTOMER_TO_CUSTOMER ||
                            formValues?.transferVoucherType ===
                                TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER) && (
                            // eslint-disable-next-line react/jsx-indent
                            <CustomerSolidSelect
                                value={formValues?.from}
                                onChange={(value) =>
                                    transferForm?.setFieldValue("from", value)
                                }
                                error={transferForm?.errors?.from}
                                className={classNameInput}
                                label={Messages.transferFromCustomer}
                            />
                        )}

                        {formValues?.transferVoucherType !==
                            TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER && (
                            <TransferVoucherSelect
                                className={classNameInput}
                                value={formValues?.voucher}
                                onChange={(values: any) =>
                                    transferForm.setFieldValue(
                                        "voucher",
                                        values
                                    )
                                }
                            />
                        )}
                        {formValues?.transferVoucherType ===
                            TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER && (
                            <TransferVoucherFromOrderSelect
                                className={classNameInput}
                                value={formValues?.voucher}
                                onChange={(values: any) =>
                                    transferForm.setFieldValue(
                                        "voucher",
                                        values
                                    )
                                }
                            />
                        )}
                        {/* <VoucherSolidSelect
                        value={formValues?.voucher}
                        onChange={(values) =>
                            transferForm.setFieldValue("voucher", values)
                        }
                        multiple
                        className={classNameInput}
                        params={{
                            customer:
                                formValues?.transferVoucherType ===
                                "CUSTOMER_TO_CUSTOMER"
                                    ? [formValues?.from?.id]
                                    : null,
                        }}
                    /> */}
                        {formValues?.transferVoucherType !==
                            TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER && (
                            <CustomerSolidSelect
                                value={formValues?.to}
                                onChange={(value) =>
                                    transferForm?.setFieldValue("to", value)
                                }
                                error={transferForm?.errors?.to}
                                className={classNameInput}
                                label={Messages.transferToCustomer}
                            />
                        )}
                        <InputText
                            label={Messages.remark}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                transferForm.setFieldValue(
                                    "remark",
                                    event.target.value
                                )
                            }
                            value={transferForm?.values?.remark}
                            error={transferForm?.errors?.remark as any}
                            className="col-span-2 mt-3"
                            multiple
                        />
                    </div>
                </div>
            </TransferVoucherCreateContext.Provider>
        </div>
    );
};

export default TransferVoucherCreate;
