import { isEmpty, map } from "lodash";
import { generatePath } from "react-router-dom";
import { getFullAddress } from "../../../interfaces/address";
import { IBranch } from "../../../interfaces/branch";
import AppLink from "../../../common/AppLink";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import BranchSelect, { IBranchSelect } from "./BranchSelect";

interface IBranchSolidItem {
    branch: IBranch;
    hideAddress?: boolean;
}

export const BranchSolidItem = ({ branch, hideAddress }: IBranchSolidItem) => {
    const { name, code, address } = branch;
    return (
        <div className="w-100 mt-3 bg-primary p-2">
            <div className="p-2 flex-column gap-1">
                <AppLink
                    to={generatePath(Path.BRANCH_DETAIL, {
                        branchId: branch.id,
                    })}
                    target="_blank"
                >
                    <span className="block text-white font-weight-bold">
                        {name}
                    </span>
                </AppLink>
                <span className="block text-white opacity-75">{`${Messages.code}: ${code}`}</span>
                {hideAddress ? null : (
                    <span className="block text-white opacity-75">{`${
                        Messages.address
                    }: ${getFullAddress(address)}`}</span>
                )}
            </div>
        </div>
    );
};

const BranchSolidSelect = ({
    className,
    ...selectProps
}: IBranchSelect<IBranch>) => {
    const renderBranchSelected = () => {
        if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
        if (selectProps?.multiple) {
            return map(selectProps?.value, (branch) => (
                <BranchSolidItem branch={branch} />
            ));
        }
        return <BranchSolidItem branch={selectProps?.value as IBranch} />;
    };

    return (
        <div className={className}>
            <BranchSelect {...selectProps} />
            {renderBranchSelected()}
        </div>
    );
};

export default BranchSolidSelect;
