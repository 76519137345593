import { gql } from "@apollo/client";
import { F_PRODUCT_ITEM, F_PRODUCT_PRICE_SET } from "./product";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";

export const F_PROMOTION = gql`
    ${F_PRODUCT_ITEM}
    ${F_PRODUCT_PRICE_SET}
    ${F_ADMIN_USER_BASIC}
    fragment F_PROMOTION on AdminPromotionDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        promotionNo
        status
        start
        end
        title
        memo
        documents
        branch {
            id
            name
            code
        }
        products {
            product {
                ...F_PRODUCT_ITEM
            }
            priceSet {
                ...F_PRODUCT_PRICE_SET
            }
        }
    }
`;

export const F_PROMOTION_ITEM = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_PROMOTION_ITEM on AdminPromotionDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        promotionNo
        status
        start
        end
        title
        branch {
            id
            name
            code
        }
    }
`;

export const F_PROMOTIONS = gql`
    ${F_PROMOTION_ITEM}
    ${F_PAGINATE}
    fragment F_PROMOTIONS on AdminPromotionPaginateResponse {
        data {
            ...F_PROMOTION_ITEM
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_VALIDATE_PROMOTION_PRODUCT = gql`
    ${F_PRODUCT_ITEM}
    fragment F_VALIDATE_PROMOTION_PRODUCT on ValidatePromotionProductResult {
        product {
            ...F_PRODUCT_ITEM
        }
        promotion {
            id
            promotionNo
            title
            start
            end
        }
        sku
        error
    }
`;
