import React, { useContext, useMemo } from "react";
import { QuotationDetailContext } from "../../../context/quotation";
import { IQuotation } from "../../../interfaces/quotation";
import QuotationPriceTable from "../share/QuotationPriceTable";

const QuotationPrice = () => {
    const { quotation } = useContext(QuotationDetailContext);
    return (
        <div className="px-4 py-3 border my-3">
            <QuotationPriceTable order={quotation as IQuotation} />
        </div>
    );
};

export default QuotationPrice;
