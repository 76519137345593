import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { generatePath, useNavigate } from "react-router-dom";
import { useCreateServiceConfigurationMutation } from "../../../api/hooks";
import { ServiceConfigSchema } from "../../../formschema/service";
import { mapServiceConfigToServer } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ServiceConfigInfoForm from "../share/ServiceConfigInfoForm";

const ServiceConfigurationCreate = () => {
    const navigate = useNavigate();
    const [createServiceConfiguration] =
        useCreateServiceConfigurationMutation();
    const configForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ServiceConfigSchema,
        onSubmit: (values: any) => {
            const input = mapServiceConfigToServer(values);
            onCreateConfig(input);
        },
    });

    const onCreateConfig = (payload: any) => {
        Progress.show(
            {
                method: createServiceConfiguration,
                params: [{ variables: { payload } }],
            },
            (res: any) => {
                const configId = res?.data?.data?.data?.id;
                navigate(
                    generatePath(Path.SERVICE_CONFIGURATION_DETAIL, {
                        configId,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    return (
        <div className="flex-column-container bg-white w-100">
            <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                <h5 className="text-primary">{Messages.newConfig}</h5>
                <Button onClick={() => configForm.handleSubmit()}>
                    {Messages.save}
                </Button>
            </div>

            <ServiceConfigInfoForm configForm={configForm} />
        </div>
    );
};

export default ServiceConfigurationCreate;
