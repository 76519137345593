import {
    AwesomeTableComponent,
    DateInput,
    InputText,
    Notifications,
    Progress,
    Select,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { find, findIndex, forEach, isUndefined, map } from "lodash";
import moment from "moment";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
    useCreateMaterialPurchaseDeliveryMutation,
    useListMaterialMaterialPurchaseDeliveryQuery,
    useUpdateMaterialPurchaseDeliveryMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import UserSelect from "../../user/share/UserSelect";
import UploadButton from "../../../common/upload/UploadButton";
import MaterialName from "../share/ProductName";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import Gallery from "../../../common/gallery/Gallery";

export const DeliveryFormSchema = Yup.object().shape({
    destination: Yup.mixed().required(Messages.fieldRequired),
    received: Yup.mixed().required(Messages.fieldRequired),
    deliveryTime: Yup.string().required(Messages.fieldRequired),
    receiptNo: Yup.string().required(Messages.fieldRequired),
    // attachment: Yup.mixed().required(Messages.fieldRequired),
    remark: Yup.string().required(Messages.fieldRequired),
});

const OrderDeliveryCreate = ({
    open,
    onClose,
    onSave,
    isView,
    delivery, //items in current delivery
}: any) => {
    const isEdit = !isUndefined(delivery);
    const { orderId } = useParams<any>();
    const { order, deliveryList } = useContext(MaterialPurchaseDetailContext);
    const [deliveryProducts, setDeliveryProducts] = useState<any[]>(
        delivery ? delivery.materials : []
    );
    const [createOrderDelivery] = useCreateMaterialPurchaseDeliveryMutation();
    const [updateOrderDelivery] = useUpdateMaterialPurchaseDeliveryMutation();
    const allMaterials = order?.materials;

    const deliveryForm = useFormik<any>({
        initialValues: delivery
            ? {
                  destination: delivery.destination,
                  received: delivery.received,
                  deliveryTime: moment(delivery.deliveryTime),
                  remark: delivery.remark,
                  attachment: delivery?.attachment?.map((item: any) => ({
                      fileUrl: item,
                  })),
                  receiptNo: delivery.receiptNo,
              }
            : ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DeliveryFormSchema,
        onSubmit: (values: any) => {
            const {
                destination,
                received,
                deliveryTime,
                receiptNo,
                attachment,
                remark,
            } = values;
            Progress.show(
                {
                    method: isEdit ? updateOrderDelivery : createOrderDelivery,
                    params: [
                        {
                            variables: {
                                materialPurchase: order?.id as string,
                                payload: {
                                    // ...(isEdit ? {} : { deliveryType }),
                                    destination: destination?.id,
                                    received: received?.id,
                                    receiptNo,
                                    deliveryTime: moment(deliveryTime).toDate(),
                                    remark,
                                    attachment: map(
                                        attachment,
                                        (item) => item.fileUrl
                                    ),
                                    materials: deliveryProducts
                                        .map((item) => ({
                                            material: item?.material?.id,
                                            quantity: item.stock,
                                        }))
                                        .filter((item) => item.quantity > 0),
                                },
                                ...(isEdit ? { id: delivery.id } : {}),
                            },
                        },
                    ],
                },
                (resp) => {
                    Notifications.showSuccess(
                        Messages.createDeliverySuccessfully
                    );
                    onSave && onSave();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    const { values, errors, setFieldValue, handleSubmit } = deliveryForm;

    const inDeliveryItems = useMemo(() => {
        const list: any[] = [];
        forEach(allMaterials, (material, index) => {
            list.push({
                ...material,
                allDeliveryQuantity: 0,
                curDeliveryQuantity: 0,
            });
            if (deliveryList?.length) {
                //find the quantity of the material in all delivery
                forEach(deliveryList, (d) => {
                    if (d.materials?.length) {
                        const materialIndex = findIndex(d.materials, (item) => {
                            return (
                                item?.material?.id === material?.material?.id
                            );
                        });
                        if (materialIndex >= 0) {
                            list[index].allDeliveryQuantity +=
                                d.materials[materialIndex].quantity;
                        }
                    }
                });
            }
            if (delivery?.materials?.length) {
                //find the quantity of the material in all delivery
                const materialIndex = findIndex(delivery.materials, (item: any) => {
                    return item?.material?.id === material?.material?.id;
                });
                if (materialIndex >= 0) {
                    list[index].curDeliveryQuantity +=
                        delivery.materials[materialIndex].quantity;
                }
            }
        });
        return list;
    }, [deliveryList, delivery]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="800px"
            title={isEdit ? Messages.updateDelivery : Messages.createDelivery}
            onSave={handleSubmit}
            disableSave={isView}
        >
            <div className="p-3">
                <div className="mb-3">
                    <BranchSelect
                        value={values?.destination}
                        onChange={(value) =>
                            setFieldValue("destination", value)
                        }
                        error={errors?.destination}
                        disabled={isView}
                        label={Messages.warehouse}
                    />
                </div>
                <div className="mb-3">
                    <InputText
                        value={values?.receiptNo}
                        onChange={(e) =>
                            setFieldValue("receiptNo", e?.target?.value)
                        }
                        label={Messages.receiptNo}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <DateInput
                        label={Messages.deliveryTime}
                        onChange={(value) => {
                            setFieldValue("deliveryTime", value);
                        }}
                        format="DD/MM/YYYY HH:mm"
                        error={errors?.deliveryTime as any}
                        disabled={isView}
                        value={values.deliveryTime}
                    />
                </div>
                <div className="mb-3">
                    <UserSelect
                        onChange={(value) => setFieldValue("received", value)}
                        label={Messages.receivedBy}
                        value={values?.received}
                        error={errors?.received as string}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <TableItemDelivery
                        products={allMaterials ?? []}
                        onChange={(values: any) => setDeliveryProducts(values)}
                        disabled={isView}
                        isEdit={isEdit}
                        inDeliveryProducts={inDeliveryItems}
                    />
                </div>
                {isView ? (
                    <>
                        <Gallery
                            gallery={delivery.attachment}
                            itemClassName="w-24 h-24 mr-2 mb-3"
                        />
                    </>
                ) : (
                    <div className="mb-3">
                        <label className="text-label">
                            {Messages.attachments}
                        </label>
                        <UploadButton
                            variant="square"
                            onChange={(file: any) =>
                                setFieldValue("attachment", file)
                            }
                            defaultFiles={values?.attachment ?? []}
                        />
                        <ViewTextError error={errors.attachment} />
                    </div>
                )}
                <div className="mb-3">
                    <InputTextForm
                        form={deliveryForm}
                        keyData="remark"
                        label={Messages.remark}
                        multiple
                        disabled={isView}
                    />
                </div>
            </div>
        </Drawer>
    );
};

const addStockForDelivery = (
    products: any[],
    isEdit: boolean
) => {
    return products.map((product: any) => {
        let currentStock = 0;
        currentStock = isEdit ? product.curDeliveryQuantity : 0;
        return {
            ...product,
            stock: currentStock,
            leftItemCount: product.quantity - product.allDeliveryQuantity,
            id: product?.material?.id,
        };
    });
};

const TableItemDelivery = ({
    onChange,
    products = [],
    inDeliveryProducts = [],
    disabled = false,
    isEdit = false,
    transferDelivery = false,
}: any) => {
    const [productDelivery, setProductDelivery] = useState(
        addStockForDelivery(inDeliveryProducts, isEdit)
    );
    const deliveryItemList = useRef<any>(null);

    useEffect(() => {
        deliveryItemList && deliveryItemList.current.refreshData();
        onChange && onChange(productDelivery);
    }, [productDelivery]);

    const onChangeQuantityPickup = useCallback(
        (quantity, item) => {
            if (disabled) {
                return;
            }
            if (quantity > item.quantity || quantity < 0) {
                return;
            }
            if (quantity > item.stock && item.leftItemCount === 0) {
                Notifications.showError(Messages.noLeftItem);
                return;
            }
            const productResult = setChangeQuantityInProducts(
                productDelivery,
                item,
                quantity
            );
            setProductDelivery(productResult);
        },
        [disabled, productDelivery]
    );

    const setChangeQuantityInProducts = (
        products: any,
        productItem: any,
        quantity: any
    ) => {
        const list = products.map((itemParam: any) => {
            if (productItem?.material?.id === itemParam?.material?.id) {
                return {
                    ...itemParam,
                    stock: quantity,
                    leftItemCount:
                        itemParam.leftItemCount + itemParam.stock - quantity,
                };
            }
            return itemParam;
        });
        return list;
    };

    const columns = useMemo(
        () => [
            {
                title: Messages.item,
                dataIndex: "material",
                render: (product: any, item: any) => {
                    const isNoneItem = item.leftItemCount === 0;
                    let subBody = <div />;
                    if (!item.groups || item.groups.length === 0) {
                        subBody = (
                            <div
                                className={
                                    isNoneItem
                                        ? "text-xs text-green-500"
                                        : "text-xs text-orange-500"
                                }
                            >
                                {item.leftItemCount + Messages.willBeLeft}
                            </div>
                        );
                    }
                    return <MaterialName item={product} subBody={subBody} />;
                },
                // ellipsis: true,
                width: 500,
            },
            // {
            //     title: Messages.orderQty,
            //     dataIndex: "quantity",
            //     render: (quantity: any) => quantity,
            // },
            // ...(transferDelivery
            //     ? []
            //     : [
            //           {
            //               title: Messages.availableQtyCurrentStore,
            //               dataIndex: "stockWarehouse",
            //               titleTooltip:
            //                   Messages.availableQtyCurrentStoreTooltip,
            //               render: (stockWarehouse: any) => stockWarehouse,
            //           },
            //       ]),
            // ...(transferDelivery
            //     ? []
            //     : [
            //           {
            //               title: Messages.onHoldQtyCurrentStore,
            //               dataIndex: "stockHold",
            //               titleTooltip: Messages.onHoldQtyCurrentStoreTooltip,
            //               render: (stockHold: any) => stockHold,
            //           },
            //       ]),
            {
                title: Messages.quantity,
                dataIndex: "stock",
                width: disabled ? 70 : 200,
                render: (stock, item) => {
                    let quantityView = (
                        <QuantityInputField
                            quantity={stock}
                            onChangeQuantity={(quantity: any) =>
                                onChangeQuantityPickup(quantity, item)
                            }
                        />
                    );
                    if (disabled) {
                        quantityView = stock;
                    }
                    return quantityView;
                },
            },
        ],
        [disabled, onChangeQuantityPickup, transferDelivery]
    );

    const source = () => {
        return Promise.resolve(productDelivery);
    };

    const transformer = (products: any) => {
        const transformedProduct = products.map((product: any = {}) => {
            return {
                id: product?.material?.id,
                ...product,
            };
        });
        return transformedProduct;
    };

    return (
        <AwesomeTableComponent
            className="height-auto"
            baseColumnProps={{ width: 150, align: "center" }}
            ref={(ref) => {
                deliveryItemList.current = ref;
            }}
            source={() => source()}
            transformer={transformer}
            columns={columns}
            isScroll={false}
            isPagination={false}
            classNameTable="tableItemDelivery"
            // expandable={{
            //     rowExpandable: (product) => product?.groups?.length > 0,
            //     expandedRowKeys: calculateDefaultExpandedRowKeys(
            //         productDelivery,
            //         {}
            //     ),
            //     // defaultExpandedRowKeys: true,
            // }}
            rowClassName={(product) => {
                let rowClassName = "rowAwesomeTable ";
                if (product.isGift) {
                    rowClassName += "productGiftTableItemRow";
                }
                return rowClassName;
            }}
        />
    );
};

export default OrderDeliveryCreate;
