import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";

const VoucherDetailActivity = () => {
    const { voucherId } = useParams<any>();
    return (
        <div>
            {voucherId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.Voucher}
                    activityRefId={voucherId ?? ""}
                />
            )}
        </div>
    );
};

export default VoucherDetailActivity;
