export default {
    ROOT: "/",
    /* ----------------------------------------
    // REPORT
    // ---------------------------------------- */
    REPORT_CASHIER: "/report-cashier",
    REPORT_CASHIER_PAYMENT: "/report-cashier-payment",
    REPORT_SALESPERSON_USER: "/report-salesperson-user",
    REPORT_SALESPERSON_MONTHLY: "/report-salesperson-monthly",
    REPORT_CUSTOMER_SOURCE: "/report-customer-source",
    REPORT_DOCTOR_SUMMARY: "/report-doctor-summary",
    REPORT_DOCTOR_DETAIL: "/report-doctor-detail",
    REPORT_NURSE_DETAIL: "/report-nurse-detail",
    REPORT_NURSE_SUMMARY: "/report-nurse-summary",
    REPORT_THERAPIST_DETAIL: "/report-therapist-detail",
    REPORT_THERAPIST_SUMMARY: "/report-therapist-summary",
    REPORT_CUSTOMER_VOUCHER: "/customer-voucher-report",
    REPORT_TREATMENT_DAILY_USAGE: "/treatment-daily-usage-report",

    /* ----------------------------------------
    // AUTH
    // ---------------------------------------- */
    AUTH: "/auth",
    AUTH_LOGIN: "/auth/login",
    AUTH_FORGET_PASS: "/auth/forgetpassword",
    AUTH_OTP_AUTH: "/auth/otp-auth",

    /* ----------------------------------------
    // OAUTH
    // ---------------------------------------- */
    OAUTH: "/oauth",

    /* ----------------------------------------
    // BOOKING
    // ---------------------------------------- */
    BOOKING_TABLE: "/bookings",
    BOOKING_CREATE: "/bookings/create/:appointmentId?",
    BOOKING_DETAIL: "/bookings/detail/:bookingId",

    /* ----------------------------------------
    // TREATMENT
    // ---------------------------------------- */
    TREATMENT_TABLE: "/treatments",
    TREATMENT_CREATE: "/treatments/create/:orderId",
    TREATMENT_DETAIL: "/treatments/detail/:treatmentId",

    /* ----------------------------------------
    // CUSTOMER
    // ---------------------------------------- */
    CUSTOMER_TABLE: "/customers",
    CUSTOMER_CREATE: "/customers/create",
    CUSTOMER_DETAIL: "/customers/detail/:customerId",

    /* ----------------------------------------
    // USERS
    // ---------------------------------------- */
    USER_TABLE: "/users",
    USER_DETAIL: "/users/detail/:userId",
    USER_CREATE: "/users/create/:duplicateId",
    USER_ROLES: "/users/roles",
    USER_ROLE_DETAIL: "/users/roles/detail/:roleId",
    USER_ROLE_CREATE: "/users/roles/create",

    /* ----------------------------------------
    // WORKSHEET
    // ---------------------------------------- */
    WORKSHEET_TABLE: "/worksheet",
    WORKSHEET_CREATE: "/worksheet/create",
    WORKSHEET_DETAIL: "/worksheet/detail/:worksheetId",

    /* ----------------------------------------
    // ORDER
    // ---------------------------------------- */
    ORDER_TABLE: "/order",
    ORDER_CREATE: "/order/create",
    ORDER_DETAIL: "/order/detail/:orderId",

    /* ----------------------------------------
    // ORDER
    // ---------------------------------------- */
    DOCTOR_ORDER_TABLE: "/doctor-order",
    DOCTOR_ORDER_CREATE: "/doctor-order/create/:bookingId",
    DOCTOR_ORDER_DETAIL: "/doctor-order/detail/:orderId",

    /* ----------------------------------------
    // SERVICE
    // ---------------------------------------- */
    SERVICE: "/service",
    SERVICE_CATEGORY: "/service-category",
    SERVICE_CATEGORY_CREATE: "/service-category/create",
    SERVICE_CATEGORY_DETAIL: "/service-category/detail/:categoryId",

    SERVICE_CONFIGURATION: "/service-configuration",
    SERVICE_CONFIGURATION_CREATE: "/service-configuration/create",
    SERVICE_CONFIGURATION_DETAIL: "/service-configuration/detail/:configId",

    // BRANCH
    // ---------------------------------------- */
    BRANCH_TABLE: "/branch",
    BRANCH_CREATE: "/branch/create",
    BRANCH_DETAIL: "/branch/detail/:branchId",

    // PAYMENT METHOD
    // ---------------------------------------- */
    PAYMENT_METHOD_TABLE: "/payment",
    PAYMENT_METHOD_DETAIL: "/payment/detail/:paymentId",

    /* ----------------------------------------
    // PRODUCT
    // ---------------------------------------- */
    PRODUCT_TABLE: "/product",
    PRODUCT_DETAIL: "/product/detail/:productId",
    PRODUCT_CREATE: "/product/create/:duplicateId",

    PRODUCT_CATEGORY: "/product-category",
    PRODUCT_CATEGORY_CREATE: "/product-category/create",
    PRODUCT_CATEGORY_DETAIL: "/product-category/detail/:categoryId",

    /* ----------------------------------------
    // INVENTORY
    // ---------------------------------------- */
    INVENTORY_TABLE: "/inventory",

    INVENTORY_STOCK_ADJUSTMENT_TABLE: "/inventory/stock-adjustment",
    INVENTORY_STOCK_ADJUSTMENT_CREATE: "/inventory/stock-adjustment/create",
    INVENTORY_STOCK_ADJUSTMENT_DETAIL:
        "/inventory/stock-adjustment/detail/:stockAdjustmentId",

    INVENTORY_REQUEST_STOCK_TABLE: "/inventory/request-stock",
    INVENTORY_REQUEST_STOCK_CREATE: "/inventory/request-stock/create",
    INVENTORY_REQUEST_STOCK_DETAIL:
        "/inventory/request-stock/detail/:stockRequestId",

    INVENTORY_TRANSFER_STOCK_TABLE: "/inventory/transfer-stock",
    INVENTORY_TRANSFER_STOCK_CREATE: "/inventory/transfer-stock/create",
    INVENTORY_TRANSFER_STOCK_DETAIL:
        "/inventory/transfer-stock/detail/:transferId",

    /* ----------------------------------------
    // DELIVERY DRIVER
    // ---------------------------------------- */

    DELIVERY_DRIVER_TABLE: "/delivery-driver",
    DELIVERY_DRIVER_DETAIL: "/delivery-driver/detail/:deliveryId",

    /* ----------------------------------------
    // MEDIA
    // ---------------------------------------- */
    MEDIA: "/medias/manager",
    MEDIA_FOLDER: "/medias/manager/:folderId",
    MEDIA_DETAIL: "/medias/manager/:folderId/:mediaId",

    /* ----------------------------------------
    // CALENDAR
    // ---------------------------------------- */
    CALENDAR: "/calendar",

    // EQUIPMENT
    // ---------------------------------------- */
    EQUIPMENT_TABLE: "/equipment",
    EQUIPMENT_CATEGORY_TABLE: "/equipment/category",
    EQUIPMENT_DETAIL: "/equipment/detail/:equipmentId",
    EQUIPMENT_EDIT: "/equipment/update/:equipmentId",
    EQUIPMENT_CATEGORY_DETAIL:
        "/equipment/category/detail/:equipmentCategoryId",
    EQUIPMENT_CREATE: "/equipment/create",
    EQUIPMENT_CATEGORY_CREATE: "/equipment/category/create",

    /* ----------------------------------------
    // EQUIPMENT-INVENTORY
    // ---------------------------------------- */
    EQUIPMENT_INVENTORY_TABLE: "/equipment-inventory",

    EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_TABLE:
        "/equipment-inventory/stock-adjustment",
    EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_CREATE:
        "/equipment-inventory/stock-adjustment/create",
    EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_DETAIL:
        "/equipment-inventory/stock-adjustment/detail/:stockAdjustmentId",

    EQUIPMENT_INVENTORY_REQUEST_STOCK_TABLE:
        "/equipment-inventory/request-stock",
    EQUIPMENT_INVENTORY_REQUEST_STOCK_CREATE:
        "/equipment-inventory/request-stock/create",
    EQUIPMENT_INVENTORY_REQUEST_STOCK_DETAIL:
        "/equipment-inventory/request-stock/detail/:stockRequestId",

    EQUIPMENT_INVENTORY_TRANSFER_STOCK_TABLE:
        "/equipment-inventory/transfer-stock",
    EQUIPMENT_INVENTORY_TRANSFER_STOCK_CREATE:
        "/equipment-inventory/transfer-stock/create",
    EQUIPMENT_INVENTORY_TRANSFER_STOCK_DETAIL:
        "/equipment-inventory/transfer-stock/detail/:transferId",

    /* ----------------------------------------
    // TEMPORARY SCHEDULE
    // ---------------------------------------- */
    TEMPORARY_SCHEDULE: "/temporary-schedule",
    TEMPORARY_SCHEDULE_DETAIL: "/temporary-schedule/detail/:userId",
    TEMPORARY_SCHEDULE_CREATE: "/temporary-schedule/create",

    /* ----------------------------------------
    // QUOTATION
    // ---------------------------------------- */
    QUOTATION_TABLE: "/quotation",
    QUOTATION_DETAIL: "/quotation/detail/:quotationId",
    QUOTATION_EDIT: "/quotation/update/:quotationId",
    QUOTATION_CREATE: "/quotation/create",

    /* ----------------------------------------
    // SO
    // ---------------------------------------- */
    SO_TABLE: "/order",
    SO_DETAIL: "/order/detail/:orderId",
    SO_EDIT: "/order/update/:orderId",
    SO_CREATE: "/order/create",

    /* ----------------------------------------
    // VOUCHER
    // ---------------------------------------- */
    VOUCHER_TABLE: "/voucher",
    VOUCHER_DETAIL: "/voucher/detail/:voucherId",
    VOUCHER_CREATE: "/voucher/create",

    /* ----------------------------------------
    // CREDIT
    // ---------------------------------------- */
    CREDIT_TABLE: "/credit",

    /* ----------------------------------------
    // PROMOTION
    // ---------------------------------------- */
    PROMOTION_TABLE: "/promotion",
    PROMOTION_DETAIL: "/promotion/detail/:promotionId",
    PROMOTION_CREATE: "/promotion/create/:duplicateId",

    /* ----------------------------------------
    // APPOINTMENT
    // ---------------------------------------- */
    APPOINTMENT_TABLE: "/appointment",
    APPOINTMENT_DETAIL: "/appointment/detail/:appointmentId",
    APPOINTMENT_CREATE: "/appointment/create",

    /* ----------------------------------------
    // TRANSFER VOUCHER
    // ---------------------------------------- */
    TRANSFER_VOUCHER_TABLE: "/transfer-voucher",
    TRANSFER_VOUCHER_DETAIL: "/transfer-voucher/detail/:transferVoucherId",
    TRANSFER_VOUCHER_CREATE: "/transfer-voucher/create",

    /* ----------------------------------------
    // AGENCY
    // ---------------------------------------- */

    AGENCY_TABLE: "/agency",
    AGENCY_CREATE: "/agency/create",
    AGENCY_DETAIL: "/agency/detail/:agencyId",

    /* ----------------------------------------
    // MATERIAL
    // ---------------------------------------- */
    MATERIAL: "/material",
    MATERIAL_CREATE: "/material/create",
    MATERIAL_DETAIL: "/material/detail/:materialId",
    MATERIAL_UPDATE: "/material/update/:materialId",
    MATERIAL_CATEGORY: "/material-category",
    MATERIAL_CATEGORY_CREATE: "/material-category/create",
    MATERIAL_CATEGORY_DETAIL: "/material-category/detail/:categoryId",
    MATERIAL_ORDER_CREATE: "/material-order/create/:requestId?",
    MATERIAL_ORDER_DETAIL: "/material-order/detail/:orderId",
    MATERIAL_ORDER: "/material-order",
    MATERIAL_REQUEST: "/material-request",
    MATERIAL_REQUEST_CREATE: "/material-request/create",
    MATERIAL_REQUEST_DETAIL: "/material-request/detail/:requestId",

    MATERIAL_INVENTORY_TABLE: "/material-inventory",
    MATERIAL_ADJUST_REQUEST_TABLE: "/material-adjust-request",
    MATERIAL_ADJUST_REQUEST_CREATE: "/material-adjust-request/create",
    MATERIAL_ADJUST_REQUEST_DETAIL:
        "/material-adjust-request/detail/:requestId",

    /* ----------------------------------------
    // VENDOR
    // ---------------------------------------- */
    VENDOR: "/vendor",
    VENDOR_CREATE: "/vendor/create",
    VENDOR_DETAIL: "/vendor/detail/:vendorId",

    /* ----------------------------------------
    // IMPORT
    // ---------------------------------------- */
    IMPORT: "/import",
    IMPORT_CUSTOMER: "/import/customer",
    IMPORT_PRODUCT: "/import/product",
    IMPORT_MATERIAL: "/import/material",
    IMPORT_USER: "/import/user",
};
