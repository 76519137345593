import { Progress, TabBar } from "d-react-components";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
    JobCostTemplate,
    RefActivity,
    useGetServiceConfigurationLazyQuery,
} from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";
import { ServiceConfigContext } from "../../../context/service";
import {
    IJobCostPreset,
    IServiceConfiguration,
} from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import ServiceConfigDescription from "./ServiceConfigDescription";
import ServiceConfigGeneral from "./ServiceConfigGeneral";
import ServiceConfigJobCost from "./ServiceConfigJobCost";
import ServiceConfigurationDetailInfo from "./ServiceConfigurationDetailInfo";
import ServiceConfigMaterial from "./ServiceConfigMaterial";
import ServiceConfigMedicine from "./ServiceConfigMedicine";
import ServiceConfigAddon from "./ServiceConfigAddon";
import { ServiceConfigAPI } from "../../../api/queries/service";
import JobCostPresetDrawer from "./JobCostPresetDrawer";

const TABS = [
    {
        id: "dashboard",
        label: Messages.generalInformation,
        component: <ServiceConfigGeneral />,
    },
    {
        id: "serviceDescription",
        label: Messages.serviceDescription,
        component: <ServiceConfigDescription />,
    },
    {
        id: "material",
        label: Messages.materialPreset,
        component: <ServiceConfigMaterial />,
    },
    {
        id: "medicine",
        label: Messages.medicinePreset,
        component: <ServiceConfigMedicine />,
    },
    {
        id: "addon",
        label: Messages.addonPreset,
        component: <ServiceConfigAddon />,
    },
    {
        id: "jobCost",
        label: Messages.jobCostPreset,
        component: <ServiceConfigJobCost />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <div />,
    },
];

const ServiceConfigurationDetail = () => {
    const { configId } = useParams<any>();
    const [configuration, setConfiguration] = useState<IServiceConfiguration>();
    const [jobCostPresets, setJobCostPresets] = useState<IJobCostPreset[]>();
    const [openJobCostPresetDrawer, setOpenJobCostPresetDrawer] = useState<{
        open: boolean;
        template?: JobCostTemplate;
    }>({ open: false });
    const [tab, setTab] = useState(TABS[0]);
    const [getConfigDetail] = useGetServiceConfigurationLazyQuery();

    const contextValues = useMemo(
        () => ({
            configuration: configuration!,
            jobCostPresets,
            openJobCostPresetDrawer,
            setConfiguration,
            setOpenJobCostPresetDrawer,
        }),
        [
            configuration,
            jobCostPresets,
            openJobCostPresetDrawer,
            setConfiguration,
            setOpenJobCostPresetDrawer,
            setOpenJobCostPresetDrawer,
        ]
    );

    useEffect(() => {
        loadConfigDetail();
        loadJobCostTemplate();
    }, []);

    const loadConfigDetail = () => {
        Progress.show(
            {
                method: getConfigDetail,
                params: [{ variables: { id: configId } }],
            },
            (res: any) => {
                const configDetail = res?.data?.data?.data ?? {};
                setConfiguration(configDetail);
            }
        );
    };

    const loadJobCostTemplate = () => {
        ServiceConfigAPI.getAllJobCostTemplate().then(setJobCostPresets);
    };

    if (!configuration) {
        return <div />;
    }

    return (
        <ServiceConfigContext.Provider value={contextValues}>
            <div className="flex-column-container bg-white">
                <div className="px-4 py-3 bg-white flex border-b items-center">
                    <div className="flex flex-1 align-items-center">
                        <h5 className="text-primary mr-3">{`${configuration?.name}`}</h5>
                    </div>
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-5 gap-4">
                        <div className="col-span-1">
                            <ServiceConfigurationDetailInfo />
                        </div>
                        <div className="col-span-4">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                            <div
                                className="bg-white mt-3"
                                hidden={tab.id !== "activity"}
                            >
                                <ActivityView
                                    className="mt-3 border"
                                    activityRef={RefActivity?.Customer}
                                    activityRefId={configId ?? ""}
                                />
                            </div>
                            <div className="h-[200px]" />
                        </div>
                    </div>
                </div>
            </div>
            {openJobCostPresetDrawer?.open &&
                openJobCostPresetDrawer?.template &&
                jobCostPresets && (
                    <JobCostPresetDrawer
                        open={openJobCostPresetDrawer.open}
                        onClose={() => {
                            setOpenJobCostPresetDrawer({ open: false });
                        }}
                        jobCost={
                            configuration?.isModifiedJobCost
                                ? configuration?.jobCost
                                : jobCostPresets?.find(
                                      (i) =>
                                          i?.id ===
                                          openJobCostPresetDrawer?.template
                                  )?.presets ?? []
                        }
                        jobCostTemplate={openJobCostPresetDrawer.template}
                        hideAction
                    />
                )}
        </ServiceConfigContext.Provider>
    );
};

export default ServiceConfigurationDetail;
