import { IAgency } from "../../../interfaces/agency";
import Messages from "../../../languages/Messages";

const AgencySelectedView = ({
    agency,
    className,
}: {
    agency: IAgency | null;
    className?: string;
}) => {
    if (!agency) return <div />;
    const { id, codeNumber, picture, name } = agency ?? {};
    return (
        <div
            className={`user-selected-view w-100 mt-3 bg-primary flex items-center ${className}`}
        >
            <img src={picture} className="image-square-medium" />
            <div className="p-2 flex-column">
                <span className="block mb-1 text-white font-weight-bold hover:cursor-pointer hover:text-blue-500">
                    {`${name}`}
                </span>
                <span className="block text-white text-sm opacity-75">{`${Messages.agencyCode} : ${codeNumber}`}</span>
                {/* <span className="block text-white text-sm opacity-75">{`${Messages.lastOpd}: `}</span> */}
            </div>
        </div>
    );
};

export default AgencySelectedView;
