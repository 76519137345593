import { gql } from "@apollo/client";
import API from "../API";
import { F_AGENCIES, F_AGENCY } from "../fragments/agency";

export const getAgencyList = gql`
    ${F_AGENCIES}
    query getAgencyList($paginate: ListAdminAgencyRequest!) {
        data: listAgencyForAdmin(paginate: $paginate) {
            ...F_AGENCIES
        }
    }
`;

export const getAgency = gql`
    ${F_AGENCY}
    query getAgency($id: String!) {
        data: detailAgencyForAdmin(id: $id) {
            data {
                ...F_AGENCY
            }
        }
    }
`;

export const createAgency = gql`
    ${F_AGENCY}
    mutation createAgency($payload: CreateAdminAgencyRequest!) {
        data: createAgencyForAdmin(payload: $payload) {
            data {
                ...F_AGENCY
            }
        }
    }
`;

export const updateAgency = gql`
    ${F_AGENCY}
    mutation updateAgency($id: String!, $payload: UpdateAdminAgencyRequest!) {
        data: updateAgencyForAdmin(id: $id, payload: $payload) {
            data {
                ...F_AGENCY
            }
        }
    }
`;

const agencyListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const agencyConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

const AgencyAPI = {
    list: async (paginate: any) =>
        API.withConverter(agencyListConverter).query({
            query: getAgencyList,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(agencyConverter).query({
            query: getAgency,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.withConverter(agencyConverter).mutate({
            mutation: createAgency,
            variables: { payload },
        }),

    update: async (payload: any) =>
        API.withConverter(agencyConverter).mutate({
            mutation: updateAgency,
            variables: { ...payload },
        }),
};

export default AgencyAPI;
