import classNames from "classnames";
import { InputText, Icon, Notifications } from "d-react-components";
import { isEmpty } from "lodash";
import React from "react";

export interface IInputTextQuantity {
    value: number;
    min?: number;
    max?: number;
    className?: string;
    onChange: (value: number) => void;
    useParseInt?: boolean;
    disabled?: boolean;
}

const InputTextQuantity = ({
    value = 0,
    className,
    min,
    max,
    onChange,
    useParseInt = true,
    disabled,
}: IInputTextQuantity) => {
    const valueParseNumber = useParseInt ? parseInt(`0${value}`, 10) : value;
    const classNameInput = classNames(
        "input-text-quantity__container",
        className
    );

    const onChangeValue = (newValue: number) => {
        if ((min || min === 0) && newValue < min) {
            Notifications.showError(`Can't less than ${min}`);
            return;
        }

        if ((max || max === 0) && newValue > max) {
            Notifications.showError(`Can't greater than ${max}`);
            return;
        }
        onChange(newValue);
    };

    return (
        <InputText
            disabled={disabled}
            className={classNameInput}
            prefix={
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        onChangeValue(valueParseNumber - 1);
                    }}
                >
                    <Icon name="remove" size="small" />
                </div>
            }
            suffix={
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        onChangeValue(valueParseNumber + 1);
                    }}
                >
                    <Icon name="add" size="small" />
                </div>
            }
            value={`${value ?? "0"}`}
            onChange={(event) => {
                let { value } = event.target;
                if (isEmpty(value)) {
                    onChange(0);
                    return;
                }
                if (Number.isNaN(value)) {
                    value = min || 0;
                }
                onChangeValue(useParseInt ? parseInt(`0${value}`, 10) : value);
            }}
        />
    );
};

export default InputTextQuantity;
