import { InputText } from "d-react-components";
import React, { useContext } from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import { VOUCHER_ISSUANCE_TYPE } from "../../../constant/voucher";
import { VoucherCreateContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";

export const InputTextNumberForm = ({ form, className, keyData }: any) => {
    const formValues = form?.values;
    return (
        <InputText
            label={Messages[keyData]}
            value={formValues?.[keyData]?.toLocaleString() ?? 0}
            className={className}
            onChange={(event) => {
                const result = event.target.value.replace(/\D/g, "");
                let value = parseInt(result, 10);
                if (!value) {
                    value = 0;
                }
                form.setFieldValue(keyData, value);
            }}
            error={form?.errors?.[keyData]}
        />
    );
};

const VoucherCreateAutoGenFields = () => {
    const { voucherForm } = useContext(VoucherCreateContext);

    const classNameInput = "mt-3 col-sm-12";
    if (
        voucherForm?.values?.typeOfIssuance !==
        VOUCHER_ISSUANCE_TYPE.AUTO_GENERATED
    ) {
        return <div />;
    }

    return (
        <>
            <InputTextForm
                form={voucherForm}
                keyData="prefix"
                className="mt-3 col-6"
            />
            <InputTextForm
                form={voucherForm}
                keyData="suffix"
                className="mt-3 col-6"
            />
            <InputTextNumberForm
                form={voucherForm}
                keyData="quantity"
                className={classNameInput}
            />
        </>
    );
};

export default VoucherCreateAutoGenFields;
