import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_VOUCHER_BASIC, F_VOUCHER_ITEM, F_VOUCHERS } from "./voucher";
import { F_CUSTOMER_BASIC } from "./customer";

export const F_CREDIT_ITEM = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_VOUCHER_ITEM}
    ${F_CUSTOMER_BASIC}
    fragment F_CREDIT_ITEM on CreditDto {
        creditNo
        status
        voucher {
            ...F_VOUCHER_ITEM
        }
        initialValue
        value
        totalUsedTimes
        totalUsedValues
        totalUsedValues
        expiryDate
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        updatedAt
        createdAt
        owner {
            ...F_CUSTOMER_BASIC
        }
        voucher {
            id
            voucherNo
            status
            type
            code
            value
            limit
        }
        vouchers {
            id
            voucherNo
            status
            type
            code
            value
            limit
            totalUsedTimes
            totalUsedValues
        }
    }
`;

export const F_CREDITS = gql`
    ${F_CREDIT_ITEM}
    ${F_PAGINATE}
    fragment F_CREDITS on CreditPaginateResponse {
        data {
            ...F_CREDIT_ITEM
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
