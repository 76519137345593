import {
    Button,
    Checkbox,
    InputText as Input,
    ObjectUtils,
    Select,
} from "d-react-components";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { CUSTOMER_TYPES, GENDERS } from "../../../constant/customer";
import { IAddressFormShow } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressFormInput from "../../address/share/AddressFormInput";

interface ICustomerAddressFormShow extends IAddressFormShow {
    citizenId?: boolean;
    company?: boolean;
    email?: boolean;
    nickname?: boolean;
    gender?: boolean;
    typeOfCustomer?: boolean;
}

interface AddressFormProps {
    formData: any;
    prefix?: string;
    title?: string;
    show?: ICustomerAddressFormShow;
    onToggleDefault?: () => any;
    onClickDelete?: () => any;
}

const DEFAULT_SHOW = {
    citizenId: false,
    company: false,
    email: false,
    nickname: true,
    gender: true,
    typeOfCustomer: false,
};

const AddressForm = ({
    formData,
    prefix = "",
    title,
    show = {},
    onToggleDefault,
    onClickDelete,
}: AddressFormProps) => {
    const showField = { ...DEFAULT_SHOW, ...show };
    const isPrefix = useMemo(() => !isEmpty(prefix), []);

    const formDataValue = isPrefix
        ? ObjectUtils.getValueFromStringKey(formData.values, prefix)
        : formData.values;

    const formDataError = isPrefix
        ? ObjectUtils.getValueFromStringKey(formData.errors, prefix)
        : formData.errors;

    const getName = (key: string) => {
        return isPrefix ? `${prefix}.${key}` : key;
    };

    const onChangeAddress = (newValue: any) => {
        if (isPrefix) {
            return formData.setFieldValue(prefix, newValue);
        }
        return formData.setValues(newValue);
    };

    const classNameInput = "col-6 mt-3";

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <h5 hidden={!title}>{title}</h5>
                    {onClickDelete && (
                        <Button
                            iconName="delete"
                            variant="trans"
                            onClick={onClickDelete}
                        />
                    )}
                </div>
                {onToggleDefault && (
                    <Checkbox
                        variant="radio"
                        label={Messages.defaultAddress}
                        classNameLabel="label"
                        checked={formDataValue?.isDefault}
                        onChange={() => {
                            formData.setFieldValue(
                                getName("isDefault"),
                                !formDataValue?.isDefault
                            );
                            onToggleDefault();
                        }}
                    />
                )}
            </div>

            <div className="row customer-address__container">
                {showField.typeOfCustomer && (
                    <div className={classNameInput}>
                        <Select
                            label={Messages.typeCustomer}
                            placeholder={Messages.typeCustomer}
                            dataSource={CUSTOMER_TYPES}
                            onChange={(value: any) =>
                                formData.setFieldValue(
                                    getName("typeOfCustomer"),
                                    value
                                )
                            }
                            value={formDataValue?.typeOfCustomer}
                            error={formDataError?.typeOfCustomer}
                            getLabel={(item: any) =>
                                (Messages as any)[item.label]
                            }
                            required
                        />
                    </div>
                )}
                <div className={classNameInput}>
                    <Input
                        label={Messages.firstName}
                        placeholder={Messages.firstName}
                        name={getName("firstName")}
                        onChange={formData.handleChange}
                        value={formDataValue?.firstName}
                        error={formDataError?.firstName}
                        required
                    />
                </div>
                <div className={classNameInput}>
                    <Input
                        label={Messages.lastName}
                        placeholder={Messages.lastName}
                        name={getName("lastName")}
                        onChange={formData.handleChange}
                        value={formDataValue?.lastName}
                        error={formDataError?.lastName}
                        required
                    />
                </div>
                {showField.citizenId && (
                    <div className={classNameInput}>
                        <Input
                            label={Messages.citizenId}
                            placeholder={Messages.citizenId}
                            name={getName("citizenId")}
                            onChange={formData.handleChange}
                            value={formDataValue?.citizenId}
                            error={formDataError?.citizenId}
                            required
                        />
                    </div>
                )}
                {showField.company && (
                    <div className={classNameInput}>
                        <Input
                            label={Messages.companyName}
                            placeholder={Messages.companyName}
                            name={getName("companyName")}
                            onChange={formData.handleChange}
                            value={formDataValue?.companyName}
                            error={formDataError?.companyName}
                            required
                        />
                    </div>
                )}
                {showField.company && (
                    <div className={classNameInput}>
                        <Input
                            label={Messages.companyTaxId}
                            placeholder={Messages.companyTaxId}
                            name={getName("taxId")}
                            onChange={formData.handleChange}
                            value={formDataValue?.taxId}
                            error={formDataError?.taxId}
                            required
                        />
                    </div>
                )}
                {showField.company && (
                    <div className={classNameInput}>
                        <Input
                            label={Messages.companyPhoneNo}
                            placeholder={Messages.companyPhoneNo}
                            name={getName("companyPhone")}
                            onChange={formData.handleChange}
                            value={formDataValue?.companyPhone}
                            error={formDataError?.companyPhone}
                            required
                        />
                    </div>
                )}

                {showField.nickname && (
                    <div className={classNameInput}>
                        <Input
                            label={Messages.nickName}
                            placeholder={Messages.nickName}
                            name={getName("nickName")}
                            onChange={formData.handleChange}
                            value={formDataValue?.nickName}
                            error={formDataError?.nickName}
                        />
                    </div>
                )}
                {showField.gender && (
                    <div className={classNameInput}>
                        <Select
                            label={Messages.gender}
                            name={getName("gender")}
                            onChange={(value) =>
                                formData.setFieldValue(getName("gender"), value)
                            }
                            value={formDataValue?.gender}
                            error={formDataError?.gender}
                            dataSource={GENDERS}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                )}
                {showField.email && (
                    <div className={classNameInput}>
                        <Input
                            label={Messages.email}
                            placeholder={Messages.email}
                            name={getName("email")}
                            onChange={formData.handleChange}
                            value={formDataValue?.email}
                            error={formDataError?.email}
                        />
                    </div>
                )}
                <div className={classNameInput}>
                    <Input
                        label={Messages.phoneNumber}
                        placeholder={Messages.phoneNumber}
                        name={getName("phone")}
                        onChange={formData.handleChange}
                        value={formDataValue?.phone}
                        error={formDataError?.phone}
                        required
                    />
                </div>
                <AddressFormInput
                    show={showField}
                    onChange={onChangeAddress}
                    formDataValue={formDataValue}
                    formDataError={formDataError}
                />
            </div>
        </div>
    );
};

export default AddressForm;
