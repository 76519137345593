/* eslint-disable react/jsx-indent */
import {
    Select,
    SelectInfinity,
    SelectInfinityProps,
    SelectProps,
} from "d-react-components";
import React, {
    ElementRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";

export interface SelectFilterProps {
    tagTitle?: string;
    fetchFn?: (params: any, paging: any) => Promise<any>;
    dataKey?: string;
    customQuery?: (search?: string) => any;
    customTagRender?: (props: any, dropdownOpen: boolean) => any;
}

export const SelectFilter: React.FC<
    Partial<SelectProps> & SelectFilterProps
> = (props) => {
    const { value, tagTitle, onDropdownVisibleChange, customTagRender } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    return (
        <Select
            // transformer={props.transformer as any}
            selectAll
            tagRender={
                customTagRender
                    ? (props) => customTagRender(props, dropdownOpen)
                    : (props) => {
                          return props?.value === value?.[0] &&
                              !dropdownOpen ? (
                              <div className="flex items-center">
                                  {tagTitle}{" "}
                                  <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
                                      {value.length}
                                  </span>
                              </div>
                          ) : (
                              <></>
                          );
                      }
            }
            onDropdownVisibleChange={(open) => {
                setDropdownOpen(open);
                onDropdownVisibleChange && onDropdownVisibleChange(open);
            }}
            {...props}
        />
    );
};

export const SelectInfinityFilterRef: React.ForwardRefRenderFunction<
    any,
    Partial<SelectInfinityProps> & SelectFilterProps & { showCount?: boolean }
> = (props, ref) => {
    const {
        value,
        tagTitle,
        onDropdownVisibleChange,
        fetchFn,
        dataKey,
        customQuery,
        customTagRender,
        multiple,
        showCount = false,
    } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const selectRef = useRef<ElementRef<typeof SelectInfinity>>(null);

    useImperativeHandle(ref, () => ({
        refresh: () => selectRef.current && selectRef.current?.onRefresh(),
    }));

    return (
        <SelectInfinity
            mode={multiple ? "multiple" : undefined}
            ref={selectRef}
            getLabel={(item) => item?.name ?? "N/A"}
            {...(showCount
                ? {
                      tagRender: customTagRender
                          ? (props) => customTagRender(props, dropdownOpen)
                          : (props) => {
                                return props?.value === value?.[0]?.id &&
                                    !dropdownOpen ? (
                                    <div className="flex items-center">
                                        {tagTitle}{" "}
                                        <span className="text-white bg-primary ml-1 w-4 h-4 rounded-full inline-block text-center text-xs">
                                            {value.length}
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                );
                            },
                  }
                : {})}
            onDropdownVisibleChange={(open) => {
                setDropdownOpen(open);
                onDropdownVisibleChange && onDropdownVisibleChange(open);
            }}
            source={(params, paging) => {
                return fetchFn ? fetchFn(params, paging) : Promise.resolve([]);
            }}
            transformer={(res) => {
                const data = dataKey ? res?.data?.[dataKey]?.data : [];
                let transform = [];
                if (data?.length > 0) {
                    transform = data.map((item: any) => ({
                        ...item,
                        value: item?.id,
                    }));
                }
                return transform;
            }}
            {...props}
        />
    );
};

export const SelectInfinityFilter = React.forwardRef(SelectInfinityFilterRef);
export default SelectFilter;
