import { Button, Drawer, IDrawerProps, Progress } from "d-react-components";
import React, { useContext, useEffect } from "react";
import { generatePath } from "react-router";
import { useGetAdminDetailLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { AppStateContext } from "../../../context/app";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { UserSummaryView } from "./UserSummaryView";

export interface IUserInfoDrawerProps {
    userId: string;
    drawerProps?: IDrawerProps;
    open: any;
    onClose: any;
}

const UserInfoDrawer: React.FC<IUserInfoDrawerProps> = ({
    userId,
    open,
    onClose,
    drawerProps,
}) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    const [getAdminDetail, { data, loading, refetch }] =
        useGetAdminDetailLazyQuery();

    useEffect(() => {
        loadAdminDetail();
    }, []);

    const loadAdminDetail = () => {
        Progress.show(
            {
                method: getAdminDetail,
                params: [
                    {
                        variables: {
                            id: userId as string,
                        },
                    },
                ],
            },
            (res: any) => {}
        );
    };

    const adminDetail = data?.detailAdminUserForAdmin?.data;

    if (!adminDetail) {
        return <div />;
    }

    return (
        <Drawer open={open} onClose={onClose} size="x-large" {...drawerProps}>
            <div className="p-4">
                <UserSummaryView user={adminDetail as any} />
            </div>
            <AppLink
                className="position-absolute bottom-0 w-100"
                to={generatePath(Path.USER_DETAIL, {
                    userId: adminDetail.id,
                })}
                target="_blank"
                onClick={() => {
                    setOpenAppDrawer({ open: false });
                }}
            >
                <Button className="w-100" style={{ height: "60px" }}>
                    {Messages.visitProfile}
                </Button>
            </AppLink>
        </Drawer>
    );
};

export default UserInfoDrawer;
