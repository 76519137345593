import { Tooltip } from "antd";
import { Button, Icon, TimeUtils } from "d-react-components";
import { forEach, isEmpty } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { OrderStatus, PaymentStatus } from "../../../../api/hooks";
import PermissibleRender from "../../../../common/PermissibleComponent";
import { PERMISSION } from "../../../../constant/user";
import { PaymentContext } from "../../../../context/payment";
import Messages from "../../../../languages/Messages";
import UploadPaymentInfoDrawer, {
    UploadPaymentInfo,
} from "./UploadPaymentInfoDrawer";

const UploadPaymentInfoView = ({
    payment,
    // renderTooltip,
    onChangePaymentInfo,
}: any) => {
    const { status, paymentMethod } = payment;
    const { ref: order } = useContext(PaymentContext);
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const [modalImageView, setModalImageView] = useState({
        isVisible: false,
        index: 0,
    });

    const paymentInfoList = useMemo(() => {
        const list: any[] = [];
        forEach(payment?.proof, (item) => {
            forEach(item?.attachments, (att: any) => {
                list.push({
                    ...item,
                    fileUrl: att,
                });
            });

            if (!item?.attachments?.length) {
                list.push({ ...item, fileUrl: "/images/placeholder.png" });
            }
        });
        if (
            payment.status === PaymentStatus.Confirmed ||
            payment.status === PaymentStatus.Rejected
        ) {
            forEach(payment?.confirmation?.attachments, (att: any) => {
                list.push({
                    ...payment?.confirmation,
                    fileUrl: att,
                });
            });
            // if (!payment?.confirmation?.attachments?.length) {
            //     list.push({
            //         ...payment?.confirmation,
            //         fileUrl: "/images/placeholder.png",
            //     });
            // }
        }

        return list;
    }, [payment]);

    const renderTooltip = (paymentInfo: any) => {
        if (!paymentInfo || isEmpty(paymentInfo?.nameOfPayer)) {
            return false;
        }
        const bankTransfer = paymentInfo?.bank?.id;
        const dateTransfer = paymentInfo?.dateOfPayment;
        const comment = paymentInfo?.remark;
        return (
            <UploadPaymentInfo
                isView
                banks={[]}
                paymentMethod={paymentMethod}
                defaultInfo={{
                    fullNameTransfer: paymentInfo?.nameOfPayer,
                    bankTransfer,
                    dateTransfer,
                    comment,
                    // numberInstalment: payload?.installment_loan_tenure,
                }}
            />
        );
    };

    const isShowUploadButton = () => {
        return (
            status !== PaymentStatus.Confirmed &&
            order?.status !== OrderStatus.Cancelled
        );
    };

    const onClickUploadButton = () => {
        setOpenModalUpload(true);
    };

    return (
        <div>
            <div className="banktranferImageContainer mt-2 w-full flex whitespace-nowrap overflow-x-auto py-2">
                {paymentInfoList.map((file: any, index: number) => {
                    const tooltip = renderTooltip && renderTooltip(file);
                    return (
                        <div
                            className="wrapImagePayment mr-1 relative cursor-pointer"
                            onClick={() => {
                                setModalImageView({ isVisible: true, index });
                            }}
                        >
                            <img
                                className="fileUpload rounded w-32 h-32 object-cover m-1 ml-0"
                                src={file.fileUrl ?? "/images/placeholder.png"}
                                alt=""
                            />

                            {tooltip && (
                                <Tooltip
                                    title={
                                        <div className="containerTooltip bg-white p-2">
                                            {tooltip}
                                        </div>
                                    }
                                    overlayClassName="containerTooltip bg-white p-2"
                                >
                                    <Icon
                                        name="info"
                                        className="absolute -top-1 -right-1"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );
                })}
            </div>

            {isShowUploadButton() && (
                <PermissibleRender permission={PERMISSION.MEDIA.UPLOAD.GET_URL}>
                    <Button
                        iconName="cloud_upload"
                        onClick={onClickUploadButton}
                        color="dark"
                        variant="outline"
                    >
                        {Messages.upload}
                    </Button>
                </PermissibleRender>
            )}

            {/* @ts-ignore */}
            <ModalGateway>
                {modalImageView.isVisible ? (
                    //  @ts-ignore
                    <Modal
                        onClose={() =>
                            setModalImageView({ index: 0, isVisible: false })
                        }
                    >
                        {/* @ts-ignore  */}
                        <Carousel
                            currentIndex={modalImageView.index}
                            views={paymentInfoList?.map((file: any) => ({
                                caption: `${Messages.by} ${
                                    file?.createByAdmin?.nickName ?? " "
                                }${
                                    Messages.at
                                } ${TimeUtils.convertMiliToDateTime(file.at)}`,
                                source: file.fileUrl,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>

            {openModalUpload && (
                <UploadPaymentInfoDrawer
                    open={openModalUpload}
                    onClose={() => setOpenModalUpload(false)}
                    payment={payment}
                    onChangePaymentInfo={onChangePaymentInfo}
                />
            )}
        </div>
    );
};

export default UploadPaymentInfoView;
