import {
    AwesomeTableComponent,
    Button,
    ButtonProps,
    HeaderTable,
    IColumnsProps,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce, filter, find, map } from "lodash";
import { Fragment, useRef, useState } from "react";
import { useGetProductsLazyQuery } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { PRODUCT_TYPE } from "../../../constant/product";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductCategorySelect from "../../product-category/share/ProductCategorySelect";
import ProductNameView from "./ProductNameView";

interface IModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (productSelect: any) => void;
    defaultValue?: any[];
    multiple?: boolean;
    keepOrigin?: boolean;
    filterSource?: any;
    isHideAvaQtyCurrentWH?: boolean;
    isHideOnHoldQtyCurrentWH?: boolean;

    isHideAvaQtyDelivery?: boolean;
    isHideOnHoldQtyDelivery?: boolean;

    isShowRegularPrice?: boolean;
    isShowSalePrice?: boolean;
}

interface IProps extends Omit<IModalProps, "open" | "onClose"> {
    isInvalidateOpenSearch?: () => boolean;
    children?: any;
    buttonProps?: ButtonProps;
}

export const ProductSearchModal = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    multiple = true,
    keepOrigin = true,
    filterSource,
    isHideAvaQtyCurrentWH,
    isHideOnHoldQtyCurrentWH,
    isHideAvaQtyDelivery,
    isHideOnHoldQtyDelivery,
    isShowRegularPrice,
    isShowSalePrice,
}: IModalProps) => {
    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",

            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
        // {
        //     hidden: isHideAvaQtyCurrentWH,
        //     title: Messages.availableQtyCurrentStore,
        //     titleTooltip: Messages.availableQtyCurrentStoreTooltip,
        //     dataIndex: "allAvailableCW",
        // },

        // {
        //     hidden: isHideOnHoldQtyCurrentWH,
        //     title: Messages.onHoldQtyCurrentStore,
        //     titleTooltip: Messages.onHoldQtyCurrentStoreTooltip,
        //     dataIndex: "allOnHoldCW",
        // },
        // {
        //     hidden: isHideAvaQtyDelivery,
        //     title: Messages.availableQtyDelivery,
        //     titleTooltip: Messages.availableQtyDeliveryTooltip,
        //     dataIndex: "allAvailableDW",
        // },
        // {
        //     hidden: isHideOnHoldQtyDelivery,
        //     title: Messages.onHoldQtyDelivery,
        //     titleTooltip: Messages.onHoldQtyDeliveryTooltip,
        //     dataIndex: "allOnHoldDW",
        // },

        {
            hidden: !isShowRegularPrice,
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: StringUtils.moneyThaiFormat,
            width: 150,
        },

        {
            hidden: !isShowSalePrice,
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: StringUtils.moneyThaiFormat,
            width: 150,
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (category: any) => category?.name,
            width: 150,
        },
    ];

    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [productSelect, setProductSelect] =
        useState<IProduct[]>(defaultValue);
    const [getProductList] = useGetProductsLazyQuery();
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            // const params = mapProductFilterToQueryParams(values);
            // setFilter(params);
            // onClose();
        },
    });
    const { values, setFieldValue } = filterForm || {};
    const { category } = values || {};

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const source = (pagingData: any) => {
        return getProductList({
            variables: {
                input: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                    categories: map(category, (item) => item.id),
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickSave = () => {
        onSave(productSelect);
        onClose();
    };

    const getSelectProps = (product: any) => {
        let disabled = false;
        if (
            product?.productType === PRODUCT_TYPE.SIMPLE_PRODUCT &&
            product.allAvailableCW === 0 &&
            !product.sellingOutOfStock
        ) {
            disabled = true;
        }
        return {
            disabled,
        };
    };

    const onChangeSelectProduct = (
        selectedRowKeys: string[],
        selectRows: any[]
    ) => {
        const existedSelectRow = filter(selectRows, (item) => !!item);
        const products = map(selectedRowKeys, (productId) => {
            const origin = find(defaultValue, (i) => i?.id === productId);
            let selectRow = find(
                existedSelectRow,
                (item) => item?.id === productId
            );
            if (!selectRow) {
                selectRow = find(
                    productSelect,
                    (item) => item.id === productId
                );
            } else if (keepOrigin && origin) {
                selectRow = origin;
            }
            return selectRow;
        });
        setProductSelect(products);
    };

    return (
        <Fragment>
            <Drawer
                open={open}
                onClose={onClose}
                title={Messages.searchProductTable}
                onSave={onClickSave}
                size="auto"
                width="800px"
            >
                <div className="p-4">
                    <HeaderTable
                        onClickFilter={() => {
                            setOpenFilterDrawer(true);
                        }}
                        onChangeText={(event: any) =>
                            onChangeSearch(event.target.value)
                        }
                        className="mb-3"
                    />
                    <AwesomeTableComponent
                        ref={(ref) => {
                            tableRef.current = ref;
                        }}
                        source={source}
                        columns={columns}
                        transformer={(res) => {
                            return res?.data?.data?.data ?? [];
                        }}
                        baseColumnProps={{ width: 600 }}
                        bordered={false}
                        rowSelection={{
                            type: multiple ? "checkbox" : "radio",
                            selectedRowKeys: map(
                                productSelect,
                                (item) => item.id
                            ),
                            preserveSelectedRowKeys: true,
                            onChange: onChangeSelectProduct as any,
                            getCheckboxProps: getSelectProps,
                        }}
                    />
                </div>
            </Drawer>
            {openFilterDrawer && (
                <Drawer
                    open={openFilterDrawer}
                    onClose={() => {
                        setOpenFilterDrawer(false);
                    }}
                    title={Messages.productFilter}
                    onSave={() => {
                        setOpenFilterDrawer(false);
                        tableRef.current.refresh();
                    }}
                >
                    <ProductCategorySelect
                        className="mt-3 col-12"
                        value={values?.category}
                        onChange={(value) =>
                            filterForm.setFieldValue("category", value)
                        }
                        multiple
                    />
                </Drawer>
            )}
        </Fragment>
    );
};

const ProductSearchButton = ({
    isInvalidateOpenSearch = () => false,
    children = Messages.search,
    buttonProps = {},
    ...modalProps
}: IProps) => {
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const onClickSearch = () => {
        if (isInvalidateOpenSearch()) {
            return;
        }
        setOpenSearch(true);
    };

    return (
        <div>
            <Button iconName="search" onClick={onClickSearch} {...buttonProps}>
                {children}
            </Button>
            {openSearch && (
                <ProductSearchModal
                    open={openSearch}
                    onClose={() => setOpenSearch(false)}
                    {...modalProps}
                />
            )}
        </div>
    );
};

export default ProductSearchButton;
