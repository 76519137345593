import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useParams } from "react-router";
import { useGetCustomerBookingHistoryLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { BOOKING_STATUSES } from "../../../constant/booking";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";
import Path from "../../Path";

const CustomerDetailBooking = () => {
    const { customerId } = useParams<any>();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [getCustomerBookingHistory] = useGetCustomerBookingHistoryLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.bookingNo,
            dataIndex: "bookingNo",
            render: (bookingNo, item) => (
                <AppLink
                    to={generatePath(Path.BOOKING_DETAIL, {
                        bookingId: item.id,
                    })}
                >
                    {bookingNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.estDateOfVisit,
            dataIndex: "scheduleDate",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.estTimeOfVisit,
            dataIndex: "schedule",
            render: (schedule) =>
                `${fmtMSS(schedule?.start)} - ${fmtMSS(schedule?.end)}`,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={BOOKING_STATUSES}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        if (!customerId) {
            return Promise.resolve();
        }
        return getCustomerBookingHistory({
            variables: {
                customerId,
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const refreshTable = () => {
        return tableRef.current && tableRef.current.refresh();
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        refreshTable();
    }, 500);

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.recentBookings}</h5>
                <Button iconName="add" onClick={() => {}} className="">
                    {Messages.new}
                </Button>
            </div>
            <InputTextSearch
                className="mt-3"
                onChange={(event: any) => onChangeSearch(event.target.value)}
            />
            <AwesomeTableComponent
                className="mt-3"
                ref={tableRef}
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
        </div>
    );
};

export default CustomerDetailBooking;
