import { DateInput, InputText } from "d-react-components";
import moment from "moment";
import { ISurgeryHistory } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

interface ISurgeryHisForm {
    surgeryHis: ISurgeryHistory;
    onChange: any;
    formError?: any;
}

const SurgeryHisForm = ({
    surgeryHis,
    onChange,
    formError,
}: ISurgeryHisForm) => {
    const inputClassName = "col-6 mt-3";
    const onChangeValue = (key: string, value: any) => {
        onChange({ ...surgeryHis, [key]: value });
    };

    return (
        <div className="row">
            <DateInput
                label={Messages.date}
                onChange={(value) => onChangeValue("date", value)}
                value={
                    surgeryHis?.date ? moment(surgeryHis?.date) : (null as any)
                }
                format="DD/MM/YYYY"
                className={inputClassName}
                error={formError?.date}
            />
            <InputText
                label={Messages.surgery}
                onChange={(event) =>
                    onChangeValue("surgery", event?.target?.value)
                }
                className={inputClassName}
                value={surgeryHis?.surgery}
                error={formError?.surgery}
            />
            <InputText
                label={Messages.admissionHospital}
                onChange={(event) =>
                    onChangeValue("hospital", event?.target?.value)
                }
                className={inputClassName}
                value={surgeryHis?.hospital}
                error={formError?.hospital}
            />
        </div>
    );
};

export default SurgeryHisForm;
