import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";
import { F_CUSTOMER } from "./customer";
import { F_PRODUCT_BASIC } from "./product";

export const F_APPOINTMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_BRANCH_BASIC}
    ${F_PRODUCT_BASIC}
    ${F_CUSTOMER}
    fragment F_APPOINTMENT on AdminAppointmentDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        appointmentNo
        status
        remark
        branch {
            ...F_BRANCH_BASIC
        }
        services {
            ...F_PRODUCT_BASIC
        }
        customer {
            ...F_CUSTOMER
        }
        contact {
            firstName
            lastName
            email
            phone
        }
        order {
            id
            orderNo
            status
            subtotal
            total
        }
        quotation {
            id
            createdAt
            quotationNo
            status
            subtotal
            total
        }
    }
`;

export const F_APPOINTMENTS = gql`
    ${F_APPOINTMENT}
    ${F_PAGINATE}
    fragment F_APPOINTMENTS on AdminAppointmentPaginateResponse {
        data {
            ...F_APPOINTMENT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
