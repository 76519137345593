import React, { CSSProperties } from "react";
import { generatePath } from "react-router-dom";
import { StringUtils } from "d-react-components";
import { IProduct } from "../../../interfaces/product";

import Messages from "../../../languages/Messages";
import Path from "../../Path";

interface IProductNameView {
    product: IProduct;
    isHideSku?: boolean;
    isHideImage?: boolean;
    subContent?: any;
    subName?: any;
    className?: string;
    classNameLabel?: string;
    classNameContent?: string;
    classNameImage?: string;
    styleImage?: CSSProperties;
}

const ProductNameView = ({
    product,
    isHideSku,
    isHideImage = false,
    subContent = <div />,
    subName,
    className,
    classNameLabel,
    classNameContent,
    classNameImage,
    styleImage = {},
}: IProductNameView) => {
    const { thumbnail, sku, gallery, salePrice } = product;

    const getNameProduct = () => {
        // if (product?.isCustomProduct) {
        //     return <h5>{(product as any)?.name}</h5>;
        // }
        return (
            <a
                className={`h5 ${classNameLabel}`}
                href={generatePath(Path.PRODUCT_DETAIL, {
                    productId: product?.id ?? "productId",
                })}
            >
                {/* {(product as any)?.[Messages.getLanguage()]?.name ?? ""} */}
                {product?.name}
            </a>
        );
    };

    const renderImageProduct = () => {
        const urlDefault = "/images/placeholder.png";
        if (isHideImage) {
            return <div />;
        }
        return (
            <img
                src={gallery?.[0] ?? urlDefault}
                className={`image-square-small ${classNameImage}`}
                style={styleImage}
            />
        );
    };

    return (
        <div className={`d-flex ${className}`}>
            {renderImageProduct()}
            <div className={`flex-column ml-2 ${classNameContent}`}>
                <div className="d-flex">
                    <text className="d-flex flex-wrap align-items-center">
                        {getNameProduct()}
                        <div
                            hidden={!subName}
                            className="width-fit-content ml-1"
                        >
                            {subName && subName}
                        </div>
                    </text>
                </div>
                {!isHideSku && (
                    <small>
                        {Messages.sku}: {sku}
                    </small>
                )}
                {!isHideSku && (
                    <small>
                        {`${Messages.salePrice}: ${StringUtils.moneyThaiFormat(
                            salePrice
                        )}`}
                    </small>
                )}
                {subContent}
            </div>
        </div>
    );
};

export default ProductNameView;
