import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useContext, useState } from "react";
import VendorAPI from "../../../api/queries/vendor";
import Drawer from "../../../common/Drawer";
import { VendorDetailContext } from "../../../context/vendor";
import Messages from "../../../languages/Messages";

interface IVendorPurchaseAddDrawer {
    open: boolean;
    onClose: () => void;
}

const VendorPurchaseAddDrawer = ({
    open,
    onClose,
}: IVendorPurchaseAddDrawer) => {
    const { vendor, setVendor } = useContext(VendorDetailContext);

    const purchaseForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {},
    });

    const onAddPurchase = (input: any) => {
        const body = {
            ...input,
        };

        // Progress.show(
        //     {
        //         method: VendorAPI.addPurchase,
        //         params: [body],
        //     },
        //     () => {
        //         Notifications.showSuccess(Messages.addSuccess);

        //         onClose();
        //     }
        // );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.equipment}
            onSave={() => purchaseForm.handleSubmit()}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <div />
            </div>
        </Drawer>
    );
};

const VendorDetailPurchase = () => {
    const { vendor, setVendor } = useContext(VendorDetailContext);
    const [openAdd, setOpenAdd] = useState(false);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.dateOfPurchase,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.poNo,
            dataIndex: "materialPurchaseNo",
        },
        {
            title: Messages.totalPurchasingCost,
            dataIndex: "total",
        },
        // {
        //     title: Messages.lotNo,
        //     dataIndex: "lotNo",
        // },
        // {
        //     title: Messages.action,
        //     dataIndex: "",
        //     render: (purchase) => (
        //         <Icon
        //             name="remove_circle"
        //             className="cursor-pointer"
        //             onClick={() => onRemove(purchase?.id)}
        //         />
        //     ),
        // },
    ];

    const onRemove = (purchaseId: string) => {
        // DialogManager.showConfirm(
        //     Messages.confirm,
        //     Messages.areYouSureWantRemove,
        //     () =>
        //         Progress.show(
        //             {
        //                 method: VendorAPI.removePurchase,
        //                 params: [vendor?.id, purchaseId],
        //             },
        //             () => {
        //                 Notifications.showSuccess(Messages.removeSuccess);
        //             }
        //         )
        // );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const source = (paging: any) => {
        const body = {
            page: paging?.pageIndex,
            limit: paging?.pageSize,
        };
        return VendorAPI.listPurchase(vendor?.id, body);
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.purchase}</label>
                <Button onClick={() => setOpenAdd(true)} className="">
                    {Messages.add}
                </Button>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />

            <AwesomeTableComponent
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
                className="mt-3"
                pagination={false}
            />
            {openAdd && (
                <VendorPurchaseAddDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}
        </div>
    );
};

export default VendorDetailPurchase;
