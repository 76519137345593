import React from "react";
import { Button, ButtonProps, Icon } from "d-react-components";
import classNames from "classnames";
import styled from "@emotion/styled";

interface HoverableButtonProps extends ButtonProps {
    icon: string | React.ReactNode;
    hasData?: boolean;
    dataNumber?: any;
}

const HoverableButton = ({
    className,
    content,
    icon,
    hasData,
    dataNumber,
    ...props
}: HoverableButtonProps) => {
    return (
        <StyledButton className="group inline-block mr-1 relative">
            {hasData && (
                <span
                    className={`${
                        dataNumber
                            ? "w-4 h-4 -top-2 -right-2"
                            : "w-2 h-2 -top-1 -right-1"
                    } text-xs rounded-full z-10 bg-red-500 inline-block text-white absolute text-center`}
                >
                    {dataNumber}
                </span>
            )}
            <Button
                {...props}
                color="gray"
                className={classNames(
                    className,
                    "transition min-w-[40px] group-hover:bg-primary group-hover:w-auto"
                )}
                style={{ minWidth: "40px" }}
            >
                {icon}
                <span className="group-hover:ml-1 transition w-0 opacity-0 group-hover:visible group-hover:opacity-100 group-hover:w-auto">
                    {content}
                </span>
            </Button>
        </StyledButton>
    );
};

const StyledButton = styled.div`
    button {
        background-color: #000 !important;
    }
    &:hover {
        button {
            background-color: var(--primary-color) !important;
        }
    }
`;

export default HoverableButton;
