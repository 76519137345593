import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { join, map, maxBy, minBy, reduce } from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import { IVoucher } from "../../interfaces/voucher";
import Messages from "../../languages/Messages";
import { getFullName } from "../../utils/Utils";
import { exportToCSV } from "../../utils/file";
import CustomerSelect from "../customer/share/CustomerSelect";
import ReportFooter from "./ReportFooter";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    customer: [],
};

const ReportCustomerVoucher = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = (values: any) => {
        const customer = map([values.customer], (item) => item.id);
        const input = {
            customer,
            from: values?.timeRange?.[0]?.valueOf(),
            to: values?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.customerVouchers, params: [input] },
            loadData as any
        );
    };

    const loadData = (dataList: any[]) => {
        const exportData: any = [];
        try {
            for (let index = 0; index < dataList.length; index += 1) {
                const voucher: IVoucher = dataList[index];
                const {
                    customer,
                    createdAt,
                    order,
                    code,
                    services,
                    usage,
                    expiryDate,
                    value,
                    totalUsedValues,
                    balance,
                } = voucher;

                const firstUsed = minBy(usage, (item: any) => item.usedAt);
                const lastUsed = maxBy(usage, (item: any) => item.usedAt);

                const coSaleOrders = reduce(
                    order?.products,
                    (listCoSale: any[], product) =>
                        listCoSale.concat(product.salesPerson),
                    []
                );
                const coSaleOrdersName = join(
                    map(coSaleOrders, (item) => getFullName(item.salesPerson)),
                    ", "
                );

                exportData.push({
                    CN: customer?.customerNo,
                    "Customer name": customer?.fullNameTh,
                    SaleDate: TimeUtils.toDate(createdAt),
                    "SO No": order?.orderNo,
                    Voucher: code,
                    SKU: join(
                        map(services, (item) => item.sku),
                        ", "
                    ),
                    Product: join(
                        map(services, (item) => item.name),
                        ", "
                    ),
                    "First Used": firstUsed?.usedAt
                        ? TimeUtils.toDate(firstUsed?.usedAt)
                        : "N/A",
                    "Last Used": lastUsed?.usedAt
                        ? TimeUtils.toDate(lastUsed?.usedAt)
                        : "N/A",
                    "Expire Date": expiryDate
                        ? TimeUtils.toDate(expiryDate)
                        : "N/A",
                    Total: value,
                    "Amount Of Use (THB)": totalUsedValues,
                    "Amount Balance (THB)": balance,
                    "Primary Sale": getFullName(order?.salesPerson),
                    "CO-Sale": coSaleOrdersName,
                    "Branch Name": order?.store?.name,
                });
            }
        } catch (error) {
            console.log("error", error);
        }

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Customer Voucher Report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <CustomerSelect
                    className={classNameInput}
                    value={formValues?.customer}
                    onChange={(value: any) =>
                        exportForm.setFieldValue("customer", value)
                    }
                    // multiple
                    label={Messages.customer}
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: "CN",
            dataIndex: "CN",
        },
        {
            title: "Customer name",
            dataIndex: "Customer name",
        },
        {
            title: "SaleDate",
            dataIndex: "SaleDate",
        },
        {
            title: "SO No",
            dataIndex: "SO No",
        },
        {
            title: "Voucher",
            dataIndex: "Voucher",
        },
        {
            title: "SKU",
            dataIndex: "SKU",
        },
        {
            title: "Product",
            dataIndex: "Product",
        },
        {
            title: "First Used",
            dataIndex: "First Used",
        },
        {
            title: "Last Used",
            dataIndex: "Last Used",
        },
        {
            title: "Expire Date",
            dataIndex: "Expire Date",
        },
        {
            title: "Total",
            dataIndex: "Total",
        },
        {
            title: "Amount Of Use (THB)",
            dataIndex: "Amount Of Use (THB)",
        },
        {
            title: "Amount Balance (THB)",
            dataIndex: "Amount Balance (THB)",
        },
        {
            title: "Primary Sale",
            dataIndex: "Primary Sale",
        },
        {
            title: "CO-Sale",
            dataIndex: "CO-Sale",
        },
        {
            title: "Branch Name",
            dataIndex: "Branch Name",
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                baseColumnProps={{ width: 100 }}
                columns={columns}
                dataSource={dataTable}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.customerVoucherReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={
                    !formValues?.customer || !formValues?.timeRange?.length
                }
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportCustomerVoucher;
