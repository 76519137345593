import {
    AwesomeTableComponent,
    IColumnsProps,
    Icon,
    useDeepEffect,
    ViewTextError,
} from "d-react-components";
import { filter, find, forEach, map, reduce } from "lodash";
import { useMemo } from "react";
import InventoryAPI from "../../../../api/queries/inventory";
import InputTextQuantity from "../../../../common/input/InputTextQuantity";
import { PRODUCT_TYPE } from "../../../../constant/product";
import { IProduct } from "../../../../interfaces/product";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/share/ProductNameView";
import ProductSearchButton from "../../../product/share/ProductSearchButton";

const StockTransferCreateItems = ({ stockTransferForm }: any) => {
    const { values, errors, setFieldValue } = stockTransferForm || {};
    const { products, origin, requests } = values || {};
    const requestIds = useMemo(() => {
        return map(requests || [], (i) => i?.id);
    }, [requests]);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (service) => <ProductNameView product={service} />,
        },
        {
            title: Messages.currentQty,
            dataIndex: "currentQty",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => (
                <InputTextQuantity
                    value={quantity}
                    onChange={(quantityValue) =>
                        onUpdateQty({
                            ...product,
                            quantity: quantityValue,
                        })
                    }
                    min={1}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (service) => (
                <Icon
                    className="cursor-pointer"
                    name="remove_circle_outline"
                    onClick={() => onRemoveProduct([service?.id])}
                />
            ),
        },
    ];

    useDeepEffect(() => {
        const productIds = map(products || [], (item) => item?.id);
        if (productIds && productIds?.length > 0 && origin?.id) {
            mapStockToProduct(productIds);
        }
    }, [map(products, (item) => item?.id)]);

    useDeepEffect(() => {
        loadProductRequest();
    }, [requestIds]);

    const loadProductRequest = () => {
        const prevProds = values?.products ?? [];
        const allProReq: Array<any> = [];

        forEach(requests, (request) => {
            forEach(request?.products ?? [], (iPro) => {
                allProReq.push(iPro);
            });
        });
        if (allProReq?.length > 0) {
            const mappedAllProReq: Array<any> = reduce(
                allProReq,
                (res, item, index) => {
                    if (
                        find(res, (i) => i?.product?.id === item?.product?.id)
                    ) {
                        return map(res, (i) =>
                            i?.product?.id === item?.product?.id
                                ? {
                                      ...i,
                                      quantity:
                                          (i?.quantity ?? 0) + item?.quantity,
                                  }
                                : i
                        );
                    }
                    res.push(item);
                    return res;
                },
                [] as any[]
            );

            forEach(mappedAllProReq, (proItem: any) => {
                const { stock, product } = proItem || {};
                const isIn = find(
                    prevProds,
                    (item) => item?.id === product?.id
                );
                if (!isIn) {
                    prevProds.push({ ...product, quantity: stock });
                }
                // if (isIn) {
                //     prevProds = map(prevProds, (i) =>
                //         i?.id === product?.id
                //             ? { ...i, quantity: (i?.quantity ?? 0) + stock }
                //             : i
                //     );
                // } else {
                //     prevProds.push({ ...product, quantity: stock });
                // }
            });
        }
        setFieldValue("products", prevProds);
    };

    const mapStockToProduct = async (productIds: string[]) => {
        try {
            const res = await InventoryAPI.checkProductStock({
                branch: [origin?.id],
                product: productIds,
            });
            const proStocks = res?.data?.data?.data ?? [];
            const mappedStocks = map(products, (item) => {
                const found = find(proStocks, (pro) => pro?.id === item?.id);
                return {
                    ...item,
                    currentQty: found?.quantity ?? 0,
                    quantity: item?.quantity ?? 0,
                };
            });
            setFieldValue("products", mappedStocks);
        } catch (error) {
            console.error({ error });
        }
    };

    const onAddProduct = (addPros: IProduct[]) => {
        setFieldValue("products", addPros);
    };

    const onRemoveProduct = (removeIds: string[]) => {
        setFieldValue(
            "products",
            filter(products, (pro) => !removeIds?.includes(pro?.id))
        );
    };

    const onUpdateQty = (updatePro: any) => {
        setFieldValue(
            "products",
            map(products, (pro) =>
                updatePro?.id === pro?.id ? updatePro : pro
            )
        );
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
                {values?.destination && values?.origin && values?.type && (
                    <ProductSearchButton
                        onSave={onAddProduct}
                        defaultValue={products ?? []}
                        filterSource={{
                            productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                        }}
                    >
                        {Messages.add}
                    </ProductSearchButton>
                )}
            </div>
            <AwesomeTableComponent
                key={products?.length}
                columns={columns}
                dataSource={products ?? []}
                className="mt-3"
                pagination={false}
            />
            {errors?.products && <ViewTextError error={errors.products} />}
        </div>
    );
};

export default StockTransferCreateItems;
