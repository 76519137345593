import { Button, InputText, Notifications, Progress } from "d-react-components";
import React, { useContext, useState } from "react";
import { useUpdateOrderForAdminMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const OrderNote = () => {
    const { order, loadOrderDetail } = useContext(OrderDetailContext);
    const [openEditNote, setOpenEditNote] = useState<{
        open: boolean;
        value?: string;
    }>({ open: false, value: order?.remark ?? "" });
    const [updateOrder] = useUpdateOrderForAdminMutation();

    const onUpdateRemark = () => {
        Progress.show(
            {
                method: updateOrder,
                params: [
                    {
                        variables: {
                            id: order?.id as string,
                            payload: {
                                remark: openEditNote?.value,
                            },
                        },
                    },
                ],
            },
            (resp) => {
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
                setOpenEditNote({ open: false });
                loadOrderDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <div className="border p-3 mb-3">
            <div className="flex flex-row justify-between items-center ">
                <h5 className="font-semibold mb-2">{Messages.orderNotes}</h5>
                <Button
                    variant="trans"
                    size="x-small"
                    onClick={() =>
                        setOpenEditNote({
                            open: true,
                            value: order?.remark ?? "",
                        })
                    }
                >
                    {Messages.edit}
                </Button>
            </div>
            <p className="text-base mb-0">{order?.remark}</p>
            {openEditNote.open && (
                <Drawer
                    open={openEditNote.open}
                    onClose={() => setOpenEditNote({ open: false })}
                    title={Messages.orderNotes}
                    onSave={() => onUpdateRemark()}
                >
                    <InputText
                        multiple
                        value={openEditNote.value}
                        onChange={(e) =>
                            setOpenEditNote((v) => ({
                                ...v,
                                value: e?.target?.value,
                            }))
                        }
                        className="mt-3 col-sm-12"
                    />
                </Drawer>
            )}
        </div>
    );
};

export default OrderNote;
