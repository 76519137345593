import React, { useContext, useMemo, useState } from "react";
import { Spin } from "antd";
import {
    Notifications,
    Button,
    Icon,
    TimeUtils,
    ObjectUtils,
    DialogManager,
    Badge,
} from "d-react-components";
import { find } from "lodash";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import {
    DeliveryStatus,
    OrderDeliveryStatus,
    OrderDeliveryType,
    OrderStatus,
} from "../../../api/hooks";
import OrderDeliveryCreate from "./OrderDeliveryCreate";
import {
    ORDER_DELIVERY_TYPES,
    ORDER_DELIVERY_STATUSES,
} from "../../../constant/order";
import OrderDeliveryStatusUpdate from "./OrderDeliveryStatusUpdate";
import AddressItem from "../share/AddressItem";

const SHIPPOP_TYPE_ARRAY = ObjectUtils.mapObjectToArray(ORDER_DELIVERY_TYPES);

const DeliveryTrackingItem = ({
    deliveryTracking,
    onReloadOrderData,
    order,
    ...props
}: any) => {
    const { loadDeliveryList } = useContext(OrderDetailContext);
    const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
    const [progressPrint, setProgressPrint] = useState(false);
    const [openEditDeliveryModal, setOpenEditDeliveryModal] = useState(false);
    const [isViewDelivery, setIsViewDelivery] = useState(false);

    const isDislayEdit =
        deliveryTracking?.status !== OrderDeliveryStatus.DeliveryCompleted;

    const onClickCopy = () => {
        navigator.clipboard.writeText(deliveryTracking.code);
        Notifications.showInfo(Messages.copySuccess);
    };

    const isDisplayTrackIcon = () => {
        return (
            deliveryTracking?.delivery?.courier === OrderDeliveryType.Shippop
        );
    };

    const isDisplayUpdateStatus = () => {
        return (
            !isDisplayTrackIcon() && deliveryTracking.status !== DeliveryStatus
        );
    };

    const onClickTrackPackage = () => {
        const trackingUrl = deliveryTracking?.delivery?.tracking;
        if (!trackingUrl || !deliveryTracking.code) {
            Notifications.showError(Messages.hasNoTrackingInfo);
            return;
        }
        const urlReplacedCode = trackingUrl.replace(
            "#code",
            deliveryTracking.code
        );

        window.open(urlReplacedCode, "_blank");
    };

    const onClickPrintShippopHandle = () => {
        // setProgressPrint(true);
        // printDelivery(order?.id).then((respone) => {
        //     const listDelivery = respone?.data?.data?.print ?? [];
        //     const printResult = listDelivery.find(
        //         (item: any) => item.code === deliveryTracking.code
        //     );
        //     if (isEmpty(printResult) || isEmpty(printResult?.download)) {
        //         setProgressPrint(false);
        //         Notifications.showError(
        //             t("notification:unableToPrintThisShippop")
        //         );
        //     } else {
        //         setProgressPrint(false);
        //         window.open(printResult?.download);
        //     }
        // });
        // logPrint(order?.id, "delivery");
    };

    const codeDisplay = () => {
        if (isDisplayTrackIcon()) {
            return deliveryTracking?.deliveryNo ?? null;
        }
        return deliveryTracking?.deliveryNo ?? null;
    };

    const getNumberOfItemDelivery = () => {
        let total = 0;
        const listItems = deliveryTracking?.products ?? [];
        listItems.forEach((item: any) => {
            total += item.quantity;
        });
        return total;
    };

    const getDisplayCourier = (code: any) => {
        let label = "N/A";
        const labelCourier =
            SHIPPOP_TYPE_ARRAY.find((item) => item.id === code)?.label ?? null;
        if (labelCourier) {
            label = Messages[labelCourier];
        }
        return label;
    };

    const deliveryStatus = useMemo(() => {
        return find(ORDER_DELIVERY_STATUSES, {
            key: deliveryTracking.status,
        });
    }, [deliveryTracking.status]);

    const deliveryType = useMemo(() => {
        return find(ORDER_DELIVERY_TYPES, {
            id: deliveryTracking.deliveryType,
        });
    }, [deliveryTracking.deliveryType]);

    return (
        <div
            className="deliveryTracking border-b border-gray-200"
            key={props.key}
        >
            {/* <div className="subTitle1">
                {deliveryTracking?.delivery?.name}
                {deliveryTracking?.courier_code && (
                    <span>{`(${getDisplayCourier(
                        deliveryTracking?.courier_code
                    )})`}</span>
                )}
            </div> */}

            {deliveryType && (
                <div className="my-2 text-sm">
                    {Messages[deliveryType?.label]}
                </div>
            )}

            {deliveryTracking.deliveryNo && (
                <div className="flex items-center my-2 text-sm">
                    <div className="subTitle1">{codeDisplay()}</div>
                    {codeDisplay() && (
                        <Icon
                            className="cursor-pointer"
                            onClick={() => onClickCopy()}
                            name="file_copy_outlined"
                        />
                    )}
                </div>
            )}

            {deliveryTracking.ref && (
                <div className="flex items-center my-2 text-sm">
                    <div className="subTitle1">{`${Messages.ref}: ${deliveryTracking.ref}`}</div>
                </div>
            )}

            {deliveryTracking.status && (
                <div
                    className="itemContainer subTitle1 statusText mb-2 text-sm"
                    style={{
                        color:
                            deliveryTracking.status ===
                            DeliveryStatus.DeliveryCompleted
                                ? "#27AE60"
                                : "#E29A46",
                    }}
                >
                    {Messages[deliveryStatus?.label ?? ""]}
                </div>
            )}

            {deliveryTracking.branch && (
                <div className="text-gray-400 mb-1 text-sm">
                    {`${Messages.branch} ${deliveryTracking?.branch?.name} (${deliveryTracking?.branch?.code}) `}
                </div>
            )}
            {deliveryTracking.estDateOfArrival && (
                <div className="text-gray-400 mb-1 text-sm">
                    {Messages.estimatedOn +
                        TimeUtils.convertMiliToDateTime(
                            deliveryTracking.estDateOfArrival
                        )}
                </div>
            )}

            {deliveryTracking.dateOfArrival && (
                <div className="text-gray-400 mb-2 text-sm">
                    {`${Messages.arrivedOn} ${TimeUtils.convertMiliToDateTime(
                        deliveryTracking.dateOfArrival
                    )}`}
                </div>
            )}

            {deliveryTracking?.shipping && (
                <div className="p-3 my-2 bg-[#DCE5E5] text-sm mb-2">
                    <AddressItem address={deliveryTracking?.shipping} />
                </div>
            )}

            {deliveryTracking.remark && (
                <div className="p-3 my-2 text-sm bg-[#DCE5E5] mb-2">
                    <span className="font-semibold text-sm">{`${Messages.remark}:`}</span>{" "}
                    {`"${deliveryTracking.remark}"`}
                </div>
            )}

            {deliveryTracking?.by && (
                <div className="mb-3 text-gray-500 text-sm">
                    {`${Messages.createdBy} ${deliveryTracking.by.fullname} ${
                        Messages.at
                    } ${TimeUtils.convertMiliToDateTime(
                        deliveryTracking.created
                    )}`}
                </div>
            )}

            <div className="flex my-3">
                {isDisplayTrackIcon() && (
                    <Button
                        onClick={() => onClickTrackPackage()}
                        id="buttonTrack"
                        variant="outline"
                        color="primary"
                        className="mr-1"
                    >
                        <span className="subTitle2 text-primary whitespace-nowrap text-ellipsis text-sm overflow-hidden inline-block">
                            {Messages.trackPackage.toLocaleUpperCase()}
                        </span>
                    </Button>
                )}
                {isDisplayTrackIcon() &&
                    deliveryTracking.status !== "completed" &&
                    order.status !== OrderStatus.Completed && (
                        <Button
                            onClick={() => onClickPrintShippopHandle()}
                            id="buttonTrack"
                            variant="outline"
                            color="primary"
                            className="mr-1"
                        >
                            {progressPrint ? (
                                <Spin size="default" />
                            ) : (
                                <span className="text-primary whitespace-nowrap text-ellipsis text-sm overflow-hidden inline-block">
                                    {Messages.printShippopLabel.toLocaleUpperCase()}
                                </span>
                            )}
                        </Button>
                    )}
                <Button
                    onClick={() => {
                        setOpenEditDeliveryModal(true);
                        setIsViewDelivery(true);
                    }}
                    id="buttonTrack"
                    variant="outline"
                    color="primary"
                    className="inline-block mr-2"
                >
                    <span className="text-primary whitespace-nowrap text-ellipsis text-sm overflow-hidden inline-block">
                        {`${Messages.listItem.toUpperCase()} (${getNumberOfItemDelivery()})`}
                    </span>
                </Button>

                {isDisplayUpdateStatus() && (
                    <Button
                        onClick={() => setOpenUpdateStatusModal(true)}
                        id="buttonTrack"
                        color="primary"
                        className="inline-block"
                    >
                        <span className="subTitle2 text-white text-sm">
                            {Messages.updateStatus.toUpperCase()}
                        </span>
                    </Button>
                )}

                {/* <NavLogButton deliveryTracking={deliveryTracking} /> */}
            </div>

            <div className="flex my-3">
                {isDislayEdit && (
                    <div
                        className="subTitle2 buttonEdit text-primary cursor-pointer"
                        onClick={() => {
                            setOpenEditDeliveryModal(true);
                            setIsViewDelivery(false);
                        }}
                    >
                        {Messages.edit}
                    </div>
                )}
            </div>

            {openUpdateStatusModal && (
                <OrderDeliveryStatusUpdate
                    open={openUpdateStatusModal}
                    onClose={() => setOpenUpdateStatusModal(false)}
                    delivery={deliveryTracking}
                    onSave={() => {
                        setOpenUpdateStatusModal(false);
                        loadDeliveryList();
                    }}
                />
            )}
            {openEditDeliveryModal && (
                <OrderDeliveryCreate
                    delivery={deliveryTracking}
                    open={openEditDeliveryModal}
                    isView={isViewDelivery}
                    onClose={() => setOpenEditDeliveryModal(false)}
                    onSave={() => {
                        setOpenEditDeliveryModal(false);
                        loadDeliveryList();
                    }}
                />
            )}
        </div>
    );
};

const OrderDelivery = () => {
    const [showAllDeliveries, setShowAllDeliveries] = useState(false);
    const {
        order,
        deliveryList: delivery,
        loadDeliveryList,
    } = useContext(OrderDetailContext);

    const deliveryItemToDisplay = useMemo(() => {
        if (showAllDeliveries) {
            return delivery;
        }
        return [delivery?.[0] ?? {}];
    }, [showAllDeliveries, delivery]);

    if (!delivery || delivery.length === 0) {
        return <div />;
    }

    // const getRemainingItems = () => {
    //     const totalQuantity = reduce(
    //         products,
    //         (sum, product) => {
    //             const { groups } = product;
    //             if (!groups || groups.length === 0) {
    //                 return (sum += product?.quantity);
    //             }
    //             const sumQuantity = reduce(
    //                 groups,
    //                 (sumGroup, proGroup) => (sumGroup += proGroup?.quantity),
    //                 0
    //             );
    //             return (sum += sumQuantity);
    //         },
    //         0
    //     );

    //     let totalDelivered = 0;
    //     delivery.forEach((deliveryItem: any) => {
    //         deliveryItem.items.forEach((skuItem: any) => {
    //             totalDelivered += skuItem.stock;
    //         });
    //     });
    //     let pickedUp = 0;
    //     pickup.forEach((pickupItem: any) => {
    //         pickupItem.pickup.forEach((skuItem: any) => {
    //             pickedUp += skuItem.stock;
    //         });
    //     });

    //     return totalQuantity - totalDelivered - pickedUp;
    // };
    return (
        <div className="bg-white deliveryContainer mb-3 p-3 border">
            <h5 className="font-semibold mb-2">
                {Messages.deliveryAssignment}
            </h5>
            {deliveryItemToDisplay?.map((deliveryItem: any, index: number) => (
                <DeliveryTrackingItem
                    deliveryTracking={deliveryItem}
                    onReloadOrderData={loadDeliveryList}
                    order={order}
                    key={index}
                />
            ))}
            {delivery.length > 1 && (
                <div className="flex justify-end">
                    <Button
                        onClick={() => setShowAllDeliveries(!showAllDeliveries)}
                        id="buttonTrack"
                        color="primary"
                        className="mt-3"
                    >
                        <span className="text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
                            {showAllDeliveries
                                ? Messages.hide
                                : Messages.allDeliveries.toLocaleUpperCase()}
                            <Badge
                                index={delivery.length}
                                variant="index"
                                size="x-large"
                                color="red"
                                className="ml-2"
                            />
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default OrderDelivery;
