import { gql } from "@apollo/client";
import API from "../API";
import {
    F_EQUIPMENT_STOCK_REQUEST,
    F_EQUIPMENT_STOCK_REQUESTS,
} from "../fragments/equipment-stock-request";

export const getEquipmentStockRequestList = gql`
    ${F_EQUIPMENT_STOCK_REQUESTS}
    query getEquipmentStockRequestList(
        $input: EquipmentRequestPaginateRequest!
    ) {
        data: listEquipmentRequestForAdmin(paginate: $input) {
            ...F_EQUIPMENT_STOCK_REQUESTS
        }
    }
`;

export const getEquipmentStockRequestDetail = gql`
    ${F_EQUIPMENT_STOCK_REQUEST}
    query getEquipmentStockRequestDetail($id: String!) {
        data: detailEquipmentRequestForAdmin(id: $id) {
            data {
                ...F_EQUIPMENT_STOCK_REQUEST
            }
        }
    }
`;

export const createEquipmentStockRequest = gql`
    ${F_EQUIPMENT_STOCK_REQUEST}
    mutation createEquipmentStockRequest(
        $payload: EquipmentRequestCreateRequest!
    ) {
        data: createEquipmentRequestForAdmin(payload: $payload) {
            data {
                ...F_EQUIPMENT_STOCK_REQUEST
            }
        }
    }
`;

export const updateEquipmentStockRequest = gql`
    ${F_EQUIPMENT_STOCK_REQUEST}
    mutation updateEquipmentStockRequest(
        $id: String!
        $payload: EquipmentRequestUpdateRequest!
    ) {
        data: updateEquipmentRequestForAdmin(id: $id, payload: $payload) {
            data {
                ...F_EQUIPMENT_STOCK_REQUEST
            }
        }
    }
`;

export const approveEquipmentStockRequest = gql`
    ${F_EQUIPMENT_STOCK_REQUEST}
    mutation approveEquipmentStockRequest(
        $requestId: String!
        $payload: EquipmentRequestConfirmInput
    ) {
        data: confirmEquipmentRequestForAdmin(
            requestId: $requestId
            payload: $payload
        ) {
            data {
                ...F_EQUIPMENT_STOCK_REQUEST
            }
        }
    }
`;

export const rejectEquipmentStockRequest = gql`
    ${F_EQUIPMENT_STOCK_REQUEST}
    mutation rejectEquipmentStockRequest(
        $requestId: String!
        $payload: EquipmentRequestConfirmInput
    ) {
        data: rejectEquipmentRequestForAdmin(
            requestId: $requestId
            payload: $payload
        ) {
            data {
                ...F_EQUIPMENT_STOCK_REQUEST
            }
        }
    }
`;

const equipmentStockRequestListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const equipmentStockRequestConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

const EquipmentStockRequestAPI = {
    list: async (input: any) =>
        API.withConverter(equipmentStockRequestListConverter).query({
            query: getEquipmentStockRequestList,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(equipmentStockRequestConverter).query({
            query: getEquipmentStockRequestDetail,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.withConverter(equipmentStockRequestConverter).mutate({
            mutation: createEquipmentStockRequest,
            variables: { payload },
        }),

    update: async (payload: any) =>
        API.withConverter(equipmentStockRequestConverter).mutate({
            mutation: updateEquipmentStockRequest,
            variables: { payload },
        }),

    approve: async (payload: any) =>
        API.withConverter(equipmentStockRequestConverter).mutate({
            mutation: approveEquipmentStockRequest,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),

    reject: async (payload: any) =>
        API.withConverter(equipmentStockRequestConverter).mutate({
            mutation: rejectEquipmentStockRequest,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
};

export default EquipmentStockRequestAPI;
