import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import React, { useContext } from "react";
import { VoucherDetailContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/share/ProductNameView";

const VoucherDetailServices = () => {
    const { voucher } = useContext(VoucherDetailContext);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
    ];

    return (
        <div className="mt-3">
            <label>{Messages.service}</label>
            <AwesomeTableComponent
                columns={columns}
                className=""
                dataSource={voucher?.services ?? []}
                pagination={false}
            />
        </div>
    );
};

export default VoucherDetailServices;
