import {
    IRowsKey,
    Modal,
    Notifications,
    Progress,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useMemo, useState } from "react";
import {
    useRoleListQuery,
    useUpdateAdminUserMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { UserCrudContext, UserDetailContext } from "../../../context/user";
import IUser, { mapUserGeneralInfoToSer } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import UserCrudInfo from "../crud/UserCrudInfo";

const SETTINGS_KEY: IRowsKey<IUser>[] = [
    {
        id: "companyId",
        label: Messages.employeedId,
    },
    {
        id: "username",
        label: Messages.username,
    },
    {
        id: "firstName",
        label: Messages.firstName,
    },
    {
        id: "lastName",
        label: Messages.lastName,
    },
    {
        id: "nickName",
        label: Messages.nickName,
    },
    {
        id: "email",
        label: Messages.email,
    },
];

const UserDetailInformation = () => {
    const { user } = useContext(UserDetailContext);
    const [showEditModal, setShowEditModal] = useState(false);
    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">{Messages.personalInfo}</h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <ViewRowInterchange
                keyList={SETTINGS_KEY}
                dataSource={user}
                variant="border"
            />
            {showEditModal && (
                <ModalEditInfo
                    open={showEditModal}
                    onClose={() => {
                        setShowEditModal(false);
                    }}
                />
            )}
        </div>
    );
};

const ModalEditInfo = ({ open, onClose }: any) => {
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const { data: dataRole } = useRoleListQuery({
        variables: {
            paginate: {
                limit: 1000,
                page: 1,
            },
        },
    });
    const roleList: any[] = useMemo(() => {
        return dataRole?.listAdminRoleForAdmin?.data ?? [];
    }, [dataRole]);

    const userForm = useFormik({
        initialValues: {
            avatar: user?.avatar,
            username: user?.username,
            firstName: user?.firstName,
            lastName: user?.lastName,
            nickName: user?.nickName,
            companyId: user?.companyId,
            email: user?.email,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        onSubmit: (values) => {
            const bodyInfo = mapUserGeneralInfoToSer(values);

            const body = {
                variables: {
                    id: user?.id as string,
                    payload: bodyInfo,
                },
            };

            Progress.show(
                {
                    method: updateAdminUser,
                    params: [body],
                },
                (resp: any) => {
                    loadAdminDetail();
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    return (
        <UserCrudContext.Provider value={{ userForm, roleList }}>
            <ModalEditInfoContent open={open} onClose={onClose} />
        </UserCrudContext.Provider>
    );
};

const ModalEditInfoContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;
    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="600px"
            title={Messages.personalInfo}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <UserCrudInfo />
            </div>
        </Drawer>
    );
};

export default UserDetailInformation;
