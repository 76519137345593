import React from "react";
import { Button } from "d-react-components";

export interface ICalendarFilterButtonProps {
    [key: string]: any;
}

const CalendarFilterButton: React.FC<ICalendarFilterButtonProps> = ({ id }) => {
    return <Button iconName="tune" />;
};

export default CalendarFilterButton;
