import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import TreatmentAPI from "../../../api/queries/treatment";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import { TREATMENT_STATUS } from "../../../constant/treatment";
import { TreatmentDetailContext } from "../../../context/treatment";
import { mapTreatmentDoctorToServer } from "../../../interfaces/treatment";
import IUser, { JobType } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import { UserJobTypeView } from "../../user/share/UserSubView";
import TreatmentUserSelect from "../share/TreatmentUserSelect";

interface ITreatmentDoctorUpdateDrawer {
    open: boolean;
    onClose: () => void;
}

const TreatmentDoctorUpdateDrawer = ({
    open,
    onClose,
}: ITreatmentDoctorUpdateDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);

    const doctorForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapTreatmentDoctorToServer(values);
            onUpdateDoctorTreatment(input);
        },
    });

    const onUpdateDoctorTreatment = (input: any) => {
        const body = {
            treatmentId: treatment?.id,
            ...input,
        };
        Progress.show(
            {
                method: TreatmentAPI.updateDoctor,
                params: [body],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.doctor}
            onSave={() => doctorForm.handleSubmit()}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <TreatmentUserSelect
                    className="mt-3"
                    branch={treatment?.branch}
                    date={treatment?.scheduleDate}
                    form={doctorForm}
                    userJobTypes={[JobType.DOCTOR]}
                />
            </div>
        </Drawer>
    );
};

const TreatmentDetailDoctor = () => {
    const { treatment } = useContext(TreatmentDetailContext);
    const [openUpdate, setOpenUpdate] = useState(false);

    const columns: IColumnsProps = [
        {
            title: Messages.doctor,
            dataIndex: "",
            render: (doctor) => <UserAvatarName user={doctor} />,
        },
        {
            title: Messages.jobType,
            dataIndex: "",
            render: (doctor: IUser) => (
                <UserJobTypeView jobType={doctor?.jobType} />
            ),
        },
    ];

    const showUpdateDoctor = useMemo(
        () => treatment?.status === TREATMENT_STATUS.SCHEDULED,
        [treatment?.status]
    );

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.doctor}</label>
                <Button
                    onClick={() => setOpenUpdate(true)}
                    className=""
                    hidden={!showUpdateDoctor}
                >
                    {Messages.update}
                </Button>
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={
                    treatment?.doctor?.adminUser
                        ? [treatment?.doctor?.adminUser]
                        : []
                }
                className="mt-3"
                pagination={false}
            />
            {openUpdate && (
                <TreatmentDoctorUpdateDrawer
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                />
            )}
        </div>
    );
};

export default TreatmentDetailDoctor;
