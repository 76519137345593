import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import React, { useContext } from "react";
import { Link, generatePath } from "react-router-dom";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import { DoctorOrderDetailContext } from "../../../context/doctor-order";
import { IDoctorOrder } from "../../../interfaces/doctor-order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerName from "../../../common/avatar/CustomerName";
import AppLink from "../../../common/AppLink";

const DoctorOrderSummaryView = () => {
    const { doctorOrder } = useContext(DoctorOrderDetailContext);
    const DETAILS_KEY: IRowsKey<IDoctorOrder>[] = [
        {
            id: "doctorOrderNo",
            label: Messages.doNo,
        },
        {
            id: "doctor",
            label: Messages.doctor,
            renderContent: ({ data }) => {
                return (
                    <div className="flex justify-content-end">
                        <UserAvatarName
                            user={data}
                            size="xx-small"
                            showInfo={{ showEmployeeId: false, showOPD: false }}
                        />
                    </div>
                );
            },
        },
        {
            id: "customer",
            label: Messages.customer,
            renderContent: ({ data }) => {
                return (
                    <div className="flex justify-content-end">
                        <CustomerName user={data} />
                    </div>
                );
            },
        },
        {
            id: "createdAt",
            label: Messages.date,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {doctorOrder && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            {doctorOrder?.doctorOrderNo}
                        </div>
                        <ViewLabelStatus
                            status={doctorOrder?.status}
                            listStatus={DOCTOR_ORDER_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={doctorOrder}
                            keyList={DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DoctorOrderSummaryView;
