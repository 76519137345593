import { filter, isEmpty, map } from "lodash";
import { IOrderManualDiscount } from "../interfaces/order";

export const getManualDiscountPayload = (
    discounts: IOrderManualDiscount[],
    products: Array<any>
) => {
    if (isEmpty(discounts)) {
        return {};
    }
    const manualDiscount = map(
        filter(discounts, (item) => {
            const parseValue = parseFloat(item?.discountValue ?? "");
            return !Number.isNaN(parseValue) && parseValue > 0;
        }),
        (discountItem) => {
            const { discountValue, discountType, product } = discountItem || {};

            return {
                discountType,
                discountValue: parseFloat(discountValue || ""),
                product: product?.id ?? undefined,
                doctorOrder: product?.doctorOrder?.id ?? undefined,
            };
        }
    );
    return { manualDiscount };
};
