import { Dropdown, Progress, TabBar } from "d-react-components";
import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import { useDetailEquipmentForAdminLazyQuery } from "../../../api/hooks";
import ActionMenu from "../../../common/ActionButton";
import { EquipmentDetailContext } from "../../../context/equipment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import EquipmentActivity from "./EquipmentActivity";
import EquipmentGeneralInformation from "./EquipmentGeneralInformation";
import EquipmentSummaryView from "./EquipmentSummaryView";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <EquipmentGeneralInformation />,
    },
    {
        id: "activity",
        label: Messages.activities,
        component: <EquipmentActivity />,
    },
];

const EquipmentDetail = () => {
    const { equipmentId } = useParams<any>();
    const [tab, setTab] = useState(TABS[0]);
    const navigate = useNavigate();
    const [getEquipmentDetail, { data, loading, refetch }] =
        useDetailEquipmentForAdminLazyQuery();

    useEffect(() => {
        loadEquipmentDetail();
    }, []);

    const loadEquipmentDetail = () => {
        Progress.show(
            {
                method: getEquipmentDetail,
                params: [
                    {
                        variables: {
                            id: equipmentId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };
    const equipmentDetail = data?.detailEquipmentForAdmin?.data;

    const onClickAction = (item: any) => {
        if (item.id === "update") {
            navigate(
                generatePath(Path.EQUIPMENT_EDIT, { equipmentId })
            );
        }
    };

    return (
        <EquipmentDetailContext.Provider
            value={{
                equipment: data?.detailEquipmentForAdmin?.data as any,
                loadEquipmentDetail: refetch,
            }}
        >
            <div className="bg-white flex-column-container">
                <div className="border-b p-3 d-flex justify-content-between align-items-center">
                    <h5 className="text-primary">{`${equipmentDetail?.name}`}</h5>
                    <div>
                        <ActionMenu
                            dataSource={[
                                {
                                    label: Messages.update,
                                    id: "update",
                                },
                            ]}
                            onClick={onClickAction}
                        />
                    </div>
                </div>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <EquipmentSummaryView />
                    </div>
                    <div className="col-span-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
            </div>
        </EquipmentDetailContext.Provider>
    );
};

export default EquipmentDetail;
