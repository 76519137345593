/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template */
import { chain, forEach, map, split, sumBy } from "lodash";
import moment, { Moment } from "moment";
import { ICustomer } from "../interfaces/customer";
import Messages from "../languages/Messages";
import { IVoucher } from "../interfaces/voucher";
import { ICredit } from "../interfaces/credit";

export function getAge(dateString: string) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
    }
    return age;
}

export function getSecondFromTime(time: Moment) {
    const miliSec = (moment(time) as any) - (moment(time).startOf("d") as any);
    const sec = Math.floor(miliSec / 1000);
    return sec;
}

export function fmtMSS(e: number) {
    const h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0");
    const m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0");

    return `${h}:${m}`;
}

export const getFullName = (data: any) => {
    if (!data) {
        return "";
    }
    return `${data?.firstName ?? "N/A"} ${data?.lastName ?? "N/A"}`;
};

export const getCustomerNameByLanguage = (customer: ICustomer) => {
    const language = Messages.getLanguage();
    const { fullNameEn, fullNameTh } = customer;
    const customerName = language === "en" ? fullNameEn : fullNameTh;
    return customerName;
};

export const getCustomerFullNameByLanguage = (customer: ICustomer) => {
    // const language = Messages.getLanguage();
    const { firstNameEn, firstNameTh, lastNameEn, lastNameTh, nickname } =
        customer;
    return `${firstNameTh} ${lastNameTh} ${nickname ? `(${nickname})` : ""}`;
    // const customerName =
    //     language === "en"
    //         ? `${firstNameEn} ${lastNameEn} ${nickname ? `(${nickname})` : ""}`
    //         : `${firstNameTh} ${lastNameTh} ${nickname ? `(${nickname})` : ""}`;
    // return customerName;
};

export const getMinutesFromHhMm = (time: string): number => {
    const arr = split(time, ":");
    const minutes = +arr[0] * 60 + +arr[1];
    return minutes;
};

export const getHhMmFromMinutes = (minutes: number): string => {
    if (!minutes || isNaN(minutes)) {
        return "N/A";
    }
    const m = minutes % 60;
    const h = (minutes - m) / 60;
    const HhMm =
        (h < 10 ? "0" : "") +
        h.toString() +
        ":" +
        (m < 10 ? "0" : "") +
        m.toString();
    return HhMm;
};

export const getArrayIntegerFromLength = (length: number) => {
    const array = [];
    for (let index = 0; index < length + 1; index += 1) {
        array.push(index);
    }
    return array;
};

export const getSourceSelectIntegerFromLength = (length: number) => {
    const array = getArrayIntegerFromLength(length);
    return map(array, (item) => ({ id: item, label: item }));
};

export const groupVouchers = (vouchers: IVoucher[]) => {
    //@ts-ignore
    const groupedVoucher = chain(vouchers)?.groupBy("voucher.id").value();
    const vouchersList: any[] = [];
    forEach(groupedVoucher, (vouchers) => {
        vouchersList.push({
            ...vouchers[0],
            amount: sumBy(vouchers, "amount"),
            quantity: sumBy(vouchers, "quantity"),
        });
    });
    return vouchersList;
};

export const groupCredits = (credits: ICredit[]) => {
    //@ts-ignore
    const groupedCredit = chain(credits)?.groupBy("credit.id").value();
    const creditList: any[] = [];
    forEach(groupedCredit, (credit) => {
        creditList.push({
            ...credit[0],
            amount: sumBy(credit, "amount"),
            quantity: sumBy(credit, "quantity"),
        });
    });
    return creditList;
};

export const base64ImgSource = (base64: any) => {
    return `data:image/png;base64, ${base64}`;
};

export const isBase64String = (string: string) => {
    const base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(string);
};

export const convertThaiDateToWesternDate = (thaiDate: any) => {
    return thaiDate ? moment(thaiDate).subtract("years", 543).toDate() : "";
};
