import {
    Button,
    DialogManager,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { isUndefined, map } from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
    useCreateCustomerAddressMutation,
    useRemoveCustomerAddressMutation,
    useUpdateCustomerAddressMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { CustomerDetailContext } from "../../../context/customer";
import { CustomerAddressSchema } from "../../../formschema/customer";

import {
    getFullAddress,
    IServiceAddress,
    mapAddressToForm,
    mapAddressToSer,
} from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressForm from "../share/AddressForm";
import AddressItemHeader from "../share/AddressItemHeader";

interface IAddressItem {
    address: IServiceAddress;
    index: number;
    showAction?: boolean;
}

export const CreateEditAddressDrawer = ({
    open,
    onClose,
    isEdit,
    addressDefault = {},
    onSaveAddress, //save address without calling BE
}: any) => {
    const { customerId: customerIdFromUrl } = useParams<any>();
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const customerId = customerIdFromUrl || customer?.id;
    const [createAddress] = useCreateCustomerAddressMutation();
    const [updateAddress] = useUpdateCustomerAddressMutation();

    const addressForm = useFormik<any>({
        initialValues: mapAddressToForm(addressDefault),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerAddressSchema,
        onSubmit: (values) => {
            const input = mapAddressToSer(values);
            if (!isUndefined(onSaveAddress)) {
                onSaveAddress({
                    ...values,
                    id: addressDefault?.id
                });
            }
            else if (isEdit) {
                onEditAddress(input);
            } else {
                onCreateAddress(input);
            }
        },
    });

    const onEditAddress = (input: any) => {
        Progress.show(
            {
                method: updateAddress,
                params: [
                    {
                        variables: {
                            customerId,
                            addressId: addressDefault?.id,
                            input,
                        },
                    },
                ],
            },
            (res: any) => {
                setCustomer(res?.data?.data?.data);
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                onClose();
            }
        );
    };

    const onCreateAddress = (input: any) => {
        Progress.show(
            {
                method: createAddress,
                params: [{ variables: { customerId, input } }],
            },
            (res: any) => {
                Notifications.showSuccess(
                    Messages.createCustomerAddressSuccess
                );
                setCustomer(res?.data?.data?.data);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.address}
            width="800px"
            onSave={() => {
                addressForm.handleSubmit();
            }}
        >
            <div className="p-3">
                <AddressForm
                    formData={addressForm}
                    title={Messages.serviceAddress}
                />
            </div>
        </Drawer>
    );
};

export const AddressItem = ({ address, index, showAction }: IAddressItem) => {
    const { setCustomer, customer } = useContext(CustomerDetailContext);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const { id, phone, nameOfPlace, isDefault } = address;
    const [updateAddress] = useUpdateCustomerAddressMutation();
    const [removeAddress] = useRemoveCustomerAddressMutation();

    const onSetDefaultAddress = () => {
        const input = { isDefault: true };
        Progress.show(
            {
                method: updateAddress,
                params: [
                    {
                        variables: {
                            customerId: customer?.id,
                            addressId: id,
                            input,
                        },
                    },
                ],
            },
            (res: any) => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                setCustomer(res?.data?.data?.data);
            }
        );
    };

    const onRemoveAddress = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: removeAddress,
                        params: [
                            {
                                variables: {
                                    customerId: customer?.id,
                                    addressId: id,
                                },
                            },
                        ],
                    },
                    (res: any) => {
                        Notifications.showSuccess(Messages.removeSuccess);
                        setCustomer(res?.data?.data?.data);
                    }
                )
        );
    };

    return (
        <div className="mt-3">
            <AddressItemHeader
                isDefault={isDefault}
                label={`${Messages.address} ${index}`}
                onClickEdit={() => setOpenEdit(true)}
                onClickDelete={onRemoveAddress}
                onClickSetDefault={onSetDefaultAddress}
                showAction={showAction}
            />
            <div className="flex-column">
                <text>{`${address?.firstName} ${address?.lastName}`}</text>
                <text>{phone}</text>
                <text>{getFullAddress(address)}</text>
                <text>{nameOfPlace}</text>
            </div>
            {openEdit && (
                <CreateEditAddressDrawer
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    isEdit
                    addressDefault={address}
                />
            )}
        </div>
    );
};

const CustomerDetailAddress = () => {
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const { customer } = useContext(CustomerDetailContext);
    return (
        <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between">
                <h5>{Messages.addressInfo}</h5>
                <Button iconName="add" onClick={() => setOpenAdd(true)}>
                    {Messages.new}
                </Button>
            </div>
            <div>
                {map(customer?.address ?? [], (address, index) => (
                    <AddressItem address={address} index={index + 1} />
                ))}
            </div>
            {openAdd && (
                <CreateEditAddressDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}
        </div>
    );
};

export default CustomerDetailAddress;
