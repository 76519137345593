import {
    IRowsKey,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import { VOUCHER_OWNER_STATUSES } from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const GENERAL_KEYS: IRowsKey<IVoucher["ownership"]>[] = [
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => (
            <ViewLabelStatus
                status={data}
                listStatus={VOUCHER_OWNER_STATUSES}
                getLabel={(item) => (Messages as any)[item.label]}
            />
        ),
    },
    {
        id: "ownedBy",
        label: Messages.customer,
        renderContent: ({ data }) =>
            data ? (
                <AppLink
                    to={generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: data?.id ?? "",
                    })}
                    target="_blank"
                >
                    <CustomerAvatarName customer={data} />
                </AppLink>
            ) : (
                "N/A"
            ),
    },
    {
        id: "remark",
        label: Messages.remark,
    },
];

const VoucherDetailOwnerStatus = () => {
    const { voucher } = useContext(VoucherDetailContext);
    return (
        <div className="border p-3 mt-3">
            <label>{Messages.ownershipStatus}</label>
            <ViewRowInterchange
                keyList={GENERAL_KEYS}
                dataSource={voucher?.ownership}
                variant="background"
            />
        </div>
    );
};

export default VoucherDetailOwnerStatus;
