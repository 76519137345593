import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_EQUIPMENT_BASIC } from "./equipment";

export const F_EQUIPMENT_STOCK_ADJUSTMENT_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_EQUIPMENT_STOCK_ADJUSTMENT_CONFIRMATION on EquipmentAdjustmentConfirmationDto {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        createdAt
        remark
    }
`;

export const F_EQUIPMENT_STOCK_ADJUSTMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_EQUIPMENT_BASIC}
    ${F_EQUIPMENT_STOCK_ADJUSTMENT_CONFIRMATION}
    fragment F_EQUIPMENT_STOCK_ADJUSTMENT on EquipmentStockAdjustmentDto {
        id
        stockAdjustmentNo
        status
        remark
        attachments
        createdAt
        updatedAt
        branch {
            id
            name
            code
        }
        equipments {
            equipment {
                ...F_EQUIPMENT_BASIC
            }
            quantity
        }
        confirmation {
            ...F_EQUIPMENT_STOCK_ADJUSTMENT_CONFIRMATION
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_EQUIPMENT_STOCK_ADJUSTMENTS = gql`
    ${F_EQUIPMENT_STOCK_ADJUSTMENT}
    ${F_PAGINATE}
    fragment F_EQUIPMENT_STOCK_ADJUSTMENTS on EquipmentStockAdjustmentPaginateResponse {
        data {
            ...F_EQUIPMENT_STOCK_ADJUSTMENT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
