import { AgencyStatus, TypeOfAgency, TypeOfIdentity } from "../api/hooks";
import { ICountry } from "./address";
import IUser from "./user";

export interface IAgency {
    id?: string;
    updatedAt?: JSON;
    createdAt?: JSON;
    agencyNo?: string;
    typeOfAgency?: TypeOfAgency;
    typeOfIdentity?: TypeOfIdentity;
    countryOfOrigin?: ICountry;
    name?: string;
    address?: string;
    codeNumber?: string;
    phone?: string;
    picture?: string;
    remark?: string;
    representativesName?: string;
    representativesPrimaryPhone?: string;
    representativesAlternatePhone?: string;
    status?: AgencyStatus;
    createByAdmin?: IUser;
}

export const mapAgencyToServer = (input: any) => {
    const { countryOfOrigin, ...rest } = input || {};
    return { countryOfOrigin: countryOfOrigin?.id, ...rest };
};
