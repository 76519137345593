/* eslint-disable no-restricted-syntax */
import {
    AwesomeTableComponent,
    IColumnsProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, sortBy } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import { FeeUnit, JobCostType } from "../../../../api/hooks";
import TreatmentAPI from "../../../../api/queries/treatment";
import UserAvatarName from "../../../../common/avatar/UserAvatarName";
import Drawer from "../../../../common/Drawer";
import InputTextForm from "../../../../common/input/InputTextForm";
import { TreatmentJobCostAssessmentOptionType } from "../../../../constant/treatment";
import { TreatmentDetailContext } from "../../../../context/treatment";
import {
    ITreatmentUser,
    mapTreatmentJobAssessmentToServer,
} from "../../../../interfaces/treatment";
import Messages from "../../../../languages/Messages";
import {
    getHhMmFromMinutes,
    getMinutesFromHhMm,
} from "../../../../utils/Utils";
import QuantityInputField from "../../../quotation/share/QuantityInputField";
import {
    UserJobCostRoleView,
    UserJobTypeView,
} from "../../../user/share/UserSubView";

export interface ITreatmentJobAssessmentDrawerProps {
    open: boolean;
    onClose: () => void;
    assessmentType: TreatmentJobCostAssessmentOptionType;
}

export const useUserJobCost = (PIC: Array<any>, doctor: any) => {
    const picDataSource = useMemo(() => {
        if (isEmpty(PIC) && isEmpty(doctor)) {
            return [];
        }
        let res: Array<any> = [];
        if (PIC.length) {
            for (const user of PIC) {
                const { adminUser, jobCost, schedule } = user || {};
                if (jobCost && jobCost.length) {
                    for (const jobCostItem of jobCost) {
                        if (jobCostItem?.type) {
                            const {
                                type,
                                quantity,
                                unit,
                                amount,
                                manualAmount,
                                start,
                                end,
                            } = jobCostItem || {};
                            const resId = `${adminUser?.id}&${jobCostItem?.type}`;
                            res.push({
                                id: resId,
                                adminUser,
                                jobCost,
                                schedule,
                                type,
                                quantity,
                                unit,
                                amount,
                                manualAmount,
                                start,
                                end,
                            });
                        }
                    }
                }
            }
        }
        if (res?.length) {
            res = sortBy(res, (item) => item?.type);
        }
        if (doctor) {
            res.unshift({
                id: `${doctor?.adminUser?.id}&${JobCostType?.DoctorFee}`,
                adminUser: doctor?.adminUser,
                type: JobCostType.DoctorFee,
                quantity: 1,
                unit: FeeUnit.Time,
                amount: doctor?.jobCost?.[0]?.amount,
                manualAmount: doctor?.jobCost?.[0]?.manualAmount,
                isDoctor: true,
                jobCost: doctor?.jobCost,
                start: doctor?.jobCost?.[0]?.start,
                end: doctor?.jobCost?.[0]?.end,
            });
        }

        return res;
    }, [PIC, doctor]);
    return picDataSource;
};

const TreatmentJobAssessmentDrawer: React.FC<
    ITreatmentJobAssessmentDrawerProps
> = ({ open, onClose, assessmentType }) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const { PIC, doctor, jobCostMetric } = treatment || {};
    const picDataSource = useUserJobCost(PIC, doctor);
    const assessmentForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const mapped = mapTreatmentJobAssessmentToServer(values);
            if (
                assessmentType ===
                TreatmentJobCostAssessmentOptionType.AUTO_CALCULATE
            ) {
                onAutoUpdateTreatmentAssessment(mapped);
            } else {
                onManualUpdateTreatmentAssessment(mapped);
            }
        },
    });
    const { values, setFieldValue } = assessmentForm || {};
    const columns: IColumnsProps = [
        {
            title: Messages.staff,
            dataIndex: "",
            render: (user: ITreatmentUser) => (
                <UserAvatarName user={user.adminUser} />
            ),
        },
        {
            title: Messages.jobType,
            dataIndex: "",
            render: (user: ITreatmentUser) => (
                <UserJobTypeView jobType={user.adminUser.jobType} />
            ),
        },
        {
            title: Messages.jobCostRole,
            dataIndex: "type",
            render: (data, item, index) => {
                return <UserJobCostRoleView jobCostRole={data} />;
            },
        },
        {
            title: Messages.timeStart,
            dataIndex: "start",
            align: "center",
            width: 100,
            render: (start, item: any) => {
                const endValue = values?.[item?.id]?.end;
                let endParse: any = null;
                if (endValue) {
                    endParse = parseFloat(`${getMinutesFromHhMm(endValue)}`);
                }

                return (
                    <InputTextForm
                        showLabel={false}
                        form={assessmentForm}
                        keyData="start"
                        type="time"
                        value={
                            values?.[item?.id]?.start ||
                            (start ? getHhMmFromMinutes(start) : "")
                        }
                        onChange={(e) => {
                            const startValue = e?.target?.value;
                            const startParse = parseFloat(
                                `${getMinutesFromHhMm(startValue)}`
                            );
                            if (endParse) {
                                if (startParse >= endParse) {
                                    Notifications.showError(
                                        Messages.invalidTime
                                    );
                                    return;
                                }
                            }
                            onChangeItemValue(item?.id, {
                                start: startValue,
                            });
                        }}
                    />
                );
            },
        },
        {
            title: Messages.timeEnd,
            dataIndex: "end",
            align: "center",
            width: 100,
            render: (end, item: any) => {
                const startValue = values?.[item?.id]?.start;
                let startParse: any = null;
                if (startValue) {
                    startParse = parseFloat(
                        `${getMinutesFromHhMm(startValue)}`
                    );
                }
                return (
                    <InputTextForm
                        showLabel={false}
                        form={assessmentForm}
                        keyData="end"
                        type="time"
                        value={
                            values?.[item?.id]?.end ||
                            (end ? getHhMmFromMinutes(end) : "")
                        }
                        onChange={(e) => {
                            const endValue = e?.target?.value;
                            const endParse = parseFloat(
                                `${getMinutesFromHhMm(endValue)}`
                            );
                            if (startParse) {
                                if (endParse <= startParse) {
                                    Notifications.showError(
                                        Messages.invalidTime
                                    );
                                    return;
                                }
                            }
                            onChangeItemValue(item?.id, {
                                end: endValue,
                            });
                        }}
                    />
                );
            },
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
            width: 100,
            render: (unit, item: any) => {
                if (item?.isDoctor) {
                    return FeeUnit.Time;
                }
                const jobCostPresetMap = new Map(
                    Object.entries(jobCostMetric?.jobCostPreset ?? {})
                );
                const thisPreset: any = jobCostPresetMap.get(
                    `${item?.adminUser?.id}&${item?.type}`
                );
                return thisPreset?.primaryFee?.unit ?? "N/A";
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
            render: (quantity, item) => {
                if (item?.isDoctor) {
                    return quantity;
                }
                return (
                    <QuantityInputField
                        quantity={values?.[item?.id]?.quantity || quantity}
                        onChangeQuantity={(v: number) => {
                            onChangeItemValue(item?.id, {
                                quantity: v,
                            });
                        }}
                        // disabled={isSubItem || product.doctorOrder}
                    />
                );
            },
        },
    ];

    if (assessmentType === TreatmentJobCostAssessmentOptionType.MANUAL_INPUT) {
        columns.push({
            title: Messages.fee,
            dataIndex: "manualAmount",
            align: "center",
            render: (manualAmount, item: any) => {
                return (
                    <InputTextForm
                        showLabel={false}
                        form={assessmentForm}
                        keyData="manualAmount"
                        type="number"
                        value={values?.[item?.id]?.manualAmount || manualAmount}
                        onChange={(e) => {
                            e.preventDefault();
                            onChangeItemValue(item?.id, {
                                manualAmount: e?.target?.value,
                            });
                        }}
                    />
                );
            },
        });
    }

    const onChangeItemValue = (itemId: string, newValues: any) => {
        const itemValue = values?.[itemId] ?? {};
        setFieldValue(itemId, { ...itemValue, ...newValues });
    };

    const onAutoUpdateTreatmentAssessment = (payload: any) => {
        const body = {
            treatmentId: treatment?.id,
            payload,
        };

        return Progress.show(
            {
                method: TreatmentAPI.updateAutoJobCostAssessment,
                params: [body],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    const onManualUpdateTreatmentAssessment = (payload: any) => {
        const body = {
            treatmentId: treatment?.id,
            payload,
        };

        return Progress.show(
            {
                method: TreatmentAPI.updateManualJobCostAssessment,
                params: [body],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    useEffect(() => {
        // if (picDataSource?.length) {
        //     picDataSource.forEach((item) => {
        //         setFieldValue(item?.id, item?.quantity);
        //     });
        // }
    }, [picDataSource]);

    useEffect(() => {
        document.addEventListener("wheel", (event) => {
            if (
                document?.activeElement &&
                (document?.activeElement as any)?.type === "number"
            ) {
                (document.activeElement as any)?.blur?.();
            }
        });
        return () => {
            document.removeEventListener("wheel", () => {});
        };
    }, []);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={
                assessmentType ===
                TreatmentJobCostAssessmentOptionType.AUTO_CALCULATE
                    ? Messages.jobCostAssessment
                    : Messages.manualJobCostAssessment
            }
            onSave={() => assessmentForm.handleSubmit()}
            disableSave={isEmpty(values)}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={picDataSource}
                    className="mt-3"
                    pagination={false}
                />
            </div>
        </Drawer>
    );
};

export default TreatmentJobAssessmentDrawer;
