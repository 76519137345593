import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import PrivacyInfoForm from "../share/PrivacyInfoForm";

const CustomerCreatePrivacyInfo = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    return (
        <div className="card-container px-3">
            <PrivacyInfoForm form={customerForm} />
        </div>
    );
};

export default CustomerCreatePrivacyInfo;
