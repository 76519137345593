import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
    useFirstTime,
} from "d-react-components";
import { debounce, find } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import VoucherAPI from "../../../api/queries/voucher";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { VOUCHER_STATUSES, VOUCHER_TYPES } from "../../../constant/voucher";
import { mapVoucherFilterToServer } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import VoucherTableFilterDrawer from "./VoucherTableFilterDrawer";

const VoucherTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);
    const navigate = useNavigate();

    const columns: IColumnsProps = [
        {
            title: Messages.code,
            dataIndex: "code",
            render: (code, voucher) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })}
                >
                    {code}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "type",
            render: (data) => {
                const voucherType = find(VOUCHER_TYPES, { id: data });
                return Messages[voucherType?.label as string];
            },
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    listStatus={VOUCHER_STATUSES}
                    status={status}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer) => (
                <CustomerAvatarName customer={customer} size="xx-small" />
            ),
        },
        {
            title: Messages.effectiveDate,
            dataIndex: "effectiveDate",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user) => (
                <UserAvatarName
                    user={user}
                    size="xx-small"
                    variant="square"
                    showInfo={{ showOPD: false }}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (voucher: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, voucher)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterInput = mapVoucherFilterToServer(filter);

        const body = {
            page: pagingData?.pageIndex,
            limit: pagingData?.pageSize,
            search: searchRef?.current,
            ...filterInput,
        };
        return VoucherAPI.list(body);
    };

    const onClickTableAction = (actionId: string, voucher: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.VOUCHER_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allVoucher}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />

            {openFilter && (
                <VoucherTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default VoucherTable;
