/* eslint-disable no-nested-ternary */
import { Icon, ObjectUtils, Select, StringUtils } from "d-react-components";
import { debounce, filter, find, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import ProductAPI from "../../../api/queries/product";
import Image from "../../../common/Image";
import { IProduct } from "../../../interfaces/product";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";

export interface IProductSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const findItemFromId = (
    list: any[] = [],
    id: string,
    getId: (item: any) => any = (item) => item?.id
) => {
    if (!list || list.length === 0) {
        return {};
    }

    return filter(list, (pro) => getId(pro) === id)?.[0];
};

const ProductSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    customQuery = {},
    getValue = (item) => item?.id,
    ...selectProps
}: IProductSelect<IProduct>) => {
    const [productList, setProductList] = useState<IProduct[]>(
        selectProps?.dataSource
            ? selectProps.dataSource
            : isArray(value)
            ? value
            : [value]
    );

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setProductList([]);
        } else {
            ProductAPI.list({
                page: 1,
                limit: 50,
                search: textSearch,
                sort: {
                    createdAt: -1,
                },
                ...customQuery,
            }).then((res: any) => {
                const productRes = res?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterProduct = map(
                    [...selectedValue, ...productRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqProduct = uniqBy(filterProduct, (item) => item.id);
                setProductList(uniqProduct);
            });
        }
    }, 500);

    const onChangeValue = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    findItemFromId(productList, item, getValue)
                )
            );
            return;
        }
        onChange(findItemFromId(productList, values, getValue));
    };

    const productValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return getValue(value) ?? null;
    }, [value]);

    const renderSelectLabel = (item: IProduct) => (
        <div className="p-2 d-flex">
            <Image
                src={item?.gallery?.[0] ?? "/images/placeholder.png"}
                className="image-square-small"
            />
            <div className="ml-2 flex-column">
                <h5>{item.name}</h5>
                <small>
                    {Messages.sku}: {item?.sku}
                </small>
                <small>
                    {Messages.salePrice}:{" "}
                    {StringUtils.moneyThaiFormat(item?.salePrice)}
                </small>
            </div>
        </div>
    );

    const renderSelectTag = (tagProps: any) => {
        let product: any = value;
        if (multiple) {
            product = find(
                value,
                (item: any) => getValue(item) === tagProps.value
            );
        }

        return (
            <div className="text-white bg-primary px-3 py-1 mr-2 small d-flex mt-1">
                {product?.name}
                <Icon
                    name="clear"
                    onClick={tagProps.onClose}
                    className="text-white small ml-2 cursor-pointer"
                />
            </div>
        );
    };

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.product}
            dataSource={productList}
            getKey={(item) => getValue(item) + item?.name}
            value={productValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            filterOption={false}
            getLabel={renderSelectLabel}
            tagRender={renderSelectTag}
            optionLabelProp="label"
            getOptionProps={(item) =>
                ({
                    label: item?.name,
                } as any)
            }
            getValue={getValue}
            {...selectProps}
        />
    );
};

export default ProductSelect;
