import { Button, Notifications, Progress, TabBar } from "d-react-components";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";
import TransferVoucherAPI from "../../../api/queries/transfer-voucher";
import ActionMenu from "../../../common/ActionButton";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { TRANSFER_VOUCHER_STATUS } from "../../../constant/transfer-voucher";
import { TransferVoucherContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import TransferVoucherDetailActivity from "./TransferVoucherDetailActivity";
import TransferVoucherDetailGeneral from "./TransferVoucherDetailGeneral";
import TransferVoucherDetailVouchers from "./TransferVoucherDetailVouchers";
import TransferVoucherSummaryView from "./TransferVoucherSummaryView";
import { ITransferVoucher } from "../../../interfaces/transfer-voucher";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <TransferVoucherDetailGeneral />,
    },
    {
        id: "voucher",
        label: Messages.voucher,
        component: <TransferVoucherDetailVouchers />,
    },
    {
        id: "activity",
        label: Messages.activities,
        component: <TransferVoucherDetailActivity />,
    },
];

interface IBookingConfirmDrawer {
    open: boolean;
    title?: string;
    description?: string;
    onClose: () => void;
    onConfirm: (props: any) => void;
}

const TransferVoucherConfirmDrawer = ({
    open,
    onClose,
    onConfirm,
    title,
    description,
}: IBookingConfirmDrawer) => {
    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            remark: Yup.string().required("Is Required!"),
        }),
        onSubmit: (values: any) => {
            onClose && onClose();
            onConfirm(values);
        },
    });

    const formValues = confirmForm?.values;
    const formErrors = confirmForm?.errors;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={title}
            onSave={() => confirmForm.handleSubmit()}
            size="auto"
            width="40%"
        >
            {description && <p className="text px-4 mt-4">{description}</p>}
            <div className="px-4">
                <InputTextForm form={confirmForm} keyData="remark" multiple />
            </div>
        </Drawer>
    );
};

const TransferVoucherDetail = () => {
    const { transferVoucherId } = useParams<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [transferDetail, setTransferDetail] =
        useState<ITransferVoucher | null>(null);
    const navigate = useNavigate();
    const [openConfirm, setOpenConfirm] = useState<{
        open: boolean;
        type?: "approve" | "cancel";
    }>({ open: false });

    const isShowConfirm = useMemo(() => {
        return transferDetail?.status === TRANSFER_VOUCHER_STATUS.PENDING;
    }, [transferDetail?.status]);

    useEffect(() => {
        loadTransferDetail();
    }, []);

    const onClickAction = (item: any) => {
        if (item.id === "cancelRequest") {
            setOpenConfirm({ open: true, type: "cancel" });
        }
    };

    const loadTransferDetail = () => {
        Progress.show(
            {
                method: TransferVoucherAPI.detail,
                params: [transferVoucherId],
            },
            setTransferDetail as any
        );
    };

    const onApproveRequest = (input: any) => {
        Progress.show(
            {
                method: TransferVoucherAPI.approve,
                params: [transferVoucherId, input?.remark],
            },
            (bookingRes: any) => {
                if (bookingRes) {
                    Notifications.showSuccess(Messages.cancelSuccess);
                    setTransferDetail(bookingRes);
                }
            }
        );
    };

    const onCancelRequest = (input: any) => {
        Progress.show(
            {
                method: TransferVoucherAPI.cancel,
                params: [transferVoucherId, input?.remark],
            },
            (bookingRes: any) => {
                if (bookingRes) {
                    Notifications.showSuccess(Messages.cancelSuccess);
                    setTransferDetail(bookingRes);
                }
            }
        );
    };

    return (
        <TransferVoucherContext.Provider
            value={{
                transferDetail,
                loadTransferDetail,
            }}
        >
            <div className="bg-white flex-column-container">
                <div className="border-b p-3 d-flex justify-content-between align-items-center">
                    <h5 className="text-primary">{`${transferDetail?.transferVoucherNo}`}</h5>
                    <div className="d-flex">
                        <Button
                            variant="outline"
                            size="small"
                            onClick={() =>
                                setOpenConfirm({ open: true, type: "approve" })
                            }
                            className="ml-2"
                            hidden={!isShowConfirm}
                        >
                            {Messages.approve}
                        </Button>
                        <ActionMenu
                            className="ml-2"
                            dataSource={[
                                {
                                    id: "cancelRequest",
                                    label: Messages.cancelRequest,
                                },
                            ]}
                            onClick={onClickAction}
                        />
                    </div>
                </div>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <TransferVoucherSummaryView />
                    </div>
                    <div className="col-span-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
                {openConfirm?.open && (
                    <TransferVoucherConfirmDrawer
                        title={
                            openConfirm?.type === "approve"
                                ? Messages.approvalConfirmation
                                : Messages.cancelConfirmation
                        }
                        description={
                            openConfirm?.type === "approve"
                                ? Messages.approvalRequestedConfirmationDes
                                : Messages.cancelRequestedConfirmationDes
                        }
                        open={openConfirm?.open}
                        onClose={() => setOpenConfirm({ open: false })}
                        onConfirm={(input) =>
                            openConfirm?.type === "approve"
                                ? onApproveRequest(input)
                                : onCancelRequest(input)
                        }
                    />
                )}
            </div>
        </TransferVoucherContext.Provider>
    );
};

export default TransferVoucherDetail;
