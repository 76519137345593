import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_PRODUCT_BASIC } from "./product";

export const F_STOCK_REQUEST_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_STOCK_REQUEST_CONFIRMATION on AdminProductStockRequestConfirmation {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        at
        remark
    }
`;

export const F_STOCK_REQUEST = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_PRODUCT_BASIC}
    ${F_STOCK_REQUEST_CONFIRMATION}
    fragment F_STOCK_REQUEST on AdminProductStockRequest {
        id
        requestNo
        status
        type
        expectedDateOfArrival
        remark
        attachments
        createdAt
        updatedAt
        branch {
            id
            name
            code
        }
        products {
            product {
                ...F_PRODUCT_BASIC
            }
            stock
        }
        confirmation {
            ...F_STOCK_REQUEST_CONFIRMATION
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_STOCK_REQUESTS = gql`
    ${F_STOCK_REQUEST}
    ${F_PAGINATE}
    fragment F_STOCK_REQUESTS on ListAdminProductStockRequestResponse {
        data {
            ...F_STOCK_REQUEST
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
