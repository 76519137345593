import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { join, map } from "lodash";
import { useContext } from "react";
import { AppointmentDetailContext } from "../../../context/appointment";
import { IAppointment } from "../../../interfaces/appointment";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";

const GENERAL_KEYS: IRowsKey<IAppointment>[] = [
    {
        id: "appointmentNo",
        label: Messages.appointmentNo,
    },
    {
        id: "branch",
        label: Messages.branchWarehouse,
        renderContent: ({ data }) => data?.name,
    },

    {
        id: "schedule",
        label: Messages.scheduledTreatmentTime,
        renderContent: ({ data, item }) =>
            `${item?.day} ${fmtMSS(data?.start)} - ${fmtMSS(data.end)}`,
    },
    {
        id: "remark",
        label: Messages.remark,
    },
];

const AppointmentDetailGeneral = () => {
    const { appointment } = useContext(AppointmentDetailContext);

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={appointment}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default AppointmentDetailGeneral;
