import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
} from "d-react-components";
import React, { useRef } from "react";
import { generatePath, useParams } from "react-router";
import {
    TreatmentStatus,
    // useListTreatmentForAdminLazyQuery,
} from "../../../api/hooks";
import Messages from "../../../languages/Messages";
import ProductName from "../../quotation/share/ProductName";
import CustomerName from "../../../common/avatar/CustomerName";
import AppLink from "../../../common/AppLink";
import Path from "../../Path";
import TreatmentAPI from "../../../api/queries/treatment";

const DoctorOrderCompletedTreatment = () => {
    const tableRef = useRef<any>(null);
    const { orderId } = useParams<any>();
    const searchRef = useRef<string>("");
    // const [getTreatmentList] = useListTreatmentForAdminLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.ref,
            dataIndex: "treatmentNo",
            width: 100,
            render: (treatmentNo, item) => (
                <AppLink
                    to={generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: item.id,
                    })}
                >
                    {treatmentNo}
                </AppLink>
            ),
        },
        {
            title: Messages.item,
            dataIndex: "id",
            // width: 300,
            ellipsis: true,
            render: (product: any, item: any) => {
                return <ProductName item={item?.services?.[0]?.product} />;
            },
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch: any, item: any) => {
                return branch?.name;
            },
        },
        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor: any, item: any) => {
                return <CustomerName user={doctor?.adminUser} />;
            },
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        return TreatmentAPI.list({
            page: pagingData?.pageIndex,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            doctorOrder: [orderId] as string[],
            status: [TreatmentStatus.Completed],
        });
        // return getTreatmentList({
        //     variables: {
        //         input: {
        //             page: pagingData?.pageIndex,
        //             limit: pagingData?.pageSize,
        //             search: searchRef?.current ?? "",
        //             doctorOrder: [orderId] as string[],
        //             status: [TreatmentStatus.Completed],
        //         },
        //     },
        //     fetchPolicy: "no-cache",
        // });
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    return (
        <div>
            <div className="flex items-center p-2">
                <div className="flex-1">
                    <h5>{Messages.completedTreatment}</h5>
                </div>
            </div>
            <HeaderTable
                className="mb-3"
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onSubmitSearch={onSubmitSearch}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default DoctorOrderCompletedTreatment;
