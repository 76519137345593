/* eslint-disable no-await-in-loop */
import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import {
    filter,
    find,
    groupBy,
    isEmpty,
    join,
    map,
    reduce,
    uniqBy,
} from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { PAYMENTS_METHOD, PAYMENTS_METHODS } from "../../constant/payment";
import { EXPORT_MODE } from "../../constant/report";
import { IPayment } from "../../interfaces/payment";
import Messages from "../../languages/Messages";
import { exportToCSV } from "../../utils/file";
import BranchSelect from "../branch/share/BranchSelect";
import ReportFooter from "./ReportFooter";

export const EXPORT_STEPS = [
    { id: "input", title: "input" },
    { id: "export", title: "export" },
];

export const INIT_EXPORT_FORM = {
    branch: [],
    timeRange: [],
};

const ReportCashierByPayment = () => {
    const [mode, setMode] = useState(EXPORT_MODE.INPUT);
    const [cashierDataTable, setCashierDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            const payload = {
                branch: map(values.branch, (item) => item.id),
                from: values?.timeRange?.[0]?.valueOf(),
                to: values?.timeRange?.[1]?.valueOf(),
            };
            onExportReport(payload);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onExportReport = (payload: any) => {
        Progress.show(
            { method: ReportAPI.cashierByPayment, params: [payload] },
            async (res) => {
                const paymentList = await onProcessData(res);
                exportCSV(paymentList);
            }
        );
    };

    const getCashierDataFromOrders = (orders: any[]) => {
        const totalInvoice = reduce(orders, (sum, item) => sum + item.total, 0);
        const totalSettleAmount = reduce(
            orders,
            (sum, item) => sum + item.settleAmount,
            0
        );
        const totalUnSettleAmount = reduce(
            orders,
            (sum, item) => sum + (item.unsettleAmount ?? 0),
            0
        );
        const totalRemainingAmount = reduce(
            orders,
            (sum, item) => sum + (item.remainingAmount ?? 0),
            0
        );

        return {
            totalInvoice,
            totalSettleAmount,
            totalUnSettleAmount,
            totalRemainingAmount,
        };
    };

    const exportCSV = (dataList: any[]) => {
        const exportData: any = [];

        const dataGroupByBranch = groupBy(
            dataList,
            (item) => item?.order?.store?.id
        );
        const branchIds = Object.keys(dataGroupByBranch);

        for (let index = 0; index < branchIds.length; index += 1) {
            const branchId = branchIds[index];

            const branchPayments = filter(
                dataGroupByBranch[branchId],
                (item) => !isEmpty(item?.confirmation?.dateOfReceiving)
            );
            const branchOrderList = uniqBy(
                map(branchPayments, (item) => item.order),
                (item) => item.id
            );
            const dateRangeText = join(
                map(formValues.timeRange, (item) => item.format("DD/MM/YYYY")),
                " - "
            );

            const branchCashierData = getCashierDataFromOrders(branchOrderList);

            const totalAmount = reduce(
                branchPayments,
                (sum, payment) => sum + payment.total,
                0
            );

            exportData.push({
                "Created Date": dateRangeText,
                SO: "",
                CN: "",
                RecieptNo: "",
                "Payment Method": "",
                CardNumber: "",
                CardType: "",
                "Income Type": "",
                "Bank Of Transfer ": "",
                BankNumber: "",
                Amount: totalAmount,
                "Paid by": "",
                Branch: branchOrderList[0]?.store?.name,
                "Total invoice": branchCashierData.totalInvoice,
                "Settle amount": branchCashierData.totalSettleAmount,
                "Unsettle amount (Deposit)":
                    branchCashierData.totalUnSettleAmount,
                "Balance of invoice": branchCashierData.totalRemainingAmount,
            });

            const dateOfPaymentGroupBy = groupBy(branchPayments, (item) =>
                moment(item?.confirmation?.dateOfReceiving).format("DD/MM/YYYY")
            );

            const dateList = Object.keys(dateOfPaymentGroupBy);
            for (
                let dateIndex = 0;
                dateIndex < dateList.length;
                dateIndex += 1
            ) {
                const date = dateList[dateIndex];
                const datePayments = dateOfPaymentGroupBy[date];
                const dateOrderList = uniqBy(
                    map(datePayments, (item) => item.order),
                    (item) => item.id
                );
                const dateCashierData = getCashierDataFromOrders(dateOrderList);

                const totalAmount = reduce(
                    datePayments,
                    (sum, payment) => sum + payment.total,
                    0
                );

                exportData.push({
                    "Created Date": date,
                    SO: "All payment",
                    CN: "",
                    RecieptNo: "",
                    "Payment Method": "",
                    CardNumber: "",
                    CardType: "",
                    "Income Type": "",
                    "Bank Of Transfer ": "",
                    BankNumber: "",
                    Amount: totalAmount,
                    "Paid by": "",
                    Branch: dateOrderList[0]?.store?.name,
                    "Total invoice": dateCashierData.totalInvoice,
                    "Settle amount": dateCashierData.totalSettleAmount,
                    "Unsettle amount (Deposit)":
                        dateCashierData.totalUnSettleAmount,
                    "Balance of invoice": dateCashierData.totalRemainingAmount,
                });

                for (
                    let paymentIndex = 0;
                    paymentIndex < datePayments.length;
                    paymentIndex += 1
                ) {
                    const payment = datePayments[paymentIndex];
                    const order = payment?.order ?? {};
                    const {
                        paymentNo,
                        paymentMethod,
                        incomeType,
                        bankTransfer,
                        bankTransferNo,
                        total,
                        paidByText,
                        creditCard,
                    } = payment;
                    exportData.push({
                        "Created Date": "",
                        SO: order?.orderNo,
                        CN: order?.customer?.customerNo,
                        RecieptNo: paymentNo,
                        "Payment Method": paymentMethod,
                        CardNumber: creditCard?.number ?? "",
                        CardType: creditCard?.type ?? "",
                        "Income Type": incomeType,
                        "Bank Of Transfer ": bankTransfer,
                        BankNumber: bankTransferNo,
                        Amount: total,
                        "Paid by": paidByText,
                        Branch: order?.store?.name,
                        "Total invoice": order.total,
                        "Settle amount": order.settleAmount,
                        "Unsettle amount (Deposit)": order.unsettleAmount ?? 0,
                        "Balance of invoice": order.remainingAmount,
                    });
                }
            }
        }

        exportToCSV(exportData, `Report_Cashier_BY_Payment`);
    };

    const onPreview = () => {
        const payload = {
            branch: map(formValues.branch, (item) => item.id),
            from: formValues?.timeRange?.[0]?.valueOf(),
            to: formValues?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.cashierByPayment, params: [payload] },
            async (res) => {
                const paymentList = onProcessData(res);
                onLoadDataToView(paymentList);
            }
        );
    };

    const getPaymentMethod = (payment: IPayment) => {
        if (payment.paymentMethod === PAYMENTS_METHOD.CREDIT_CARD_POS) {
            return "POS Card Device";
        }
        const method = find(
            PAYMENTS_METHODS,
            (item) => item.value === payment.paymentMethod
        );
        return Messages[method?.label as any];
    };
    const getIncomeType = (payment: IPayment) => {
        const method = find(
            PAYMENTS_METHODS,
            (item) => item.value === payment.paymentMethod
        );
        return method?.type;
    };

    const onProcessData = (orderList: any) => {
        let paymentListResult: any[] = [];
        for (let index = 0; index < orderList.length; index += 1) {
            const order = orderList[index];
            const paymentList = map(order?.payments, (payment) => {
                const paymentMethod = getPaymentMethod(payment);
                const bankTransfer =
                    payment.paymentMethod === PAYMENTS_METHOD.BANK_TRANSFER
                        ? payment?.proof?.[0]?.bank?.bankName
                        : "";
                const bankTransferNo =
                    payment.paymentMethod === PAYMENTS_METHOD.BANK_TRANSFER
                        ? payment?.proof?.[0]?.bank?.accountNumber
                        : "";
                const paidByText = `${paymentMethod} ${bankTransfer} ${payment.total}`;
                return {
                    ...payment,
                    incomeType: getIncomeType(payment),
                    paymentMethod,
                    bankTransfer,
                    bankTransferNo,
                    paidByText,
                    order,
                };
            });

            paymentListResult = paymentListResult.concat(paymentList);
        }

        return paymentListResult;
    };

    const onLoadDataToView = (dataList: any) => {
        const tableData: any = [];

        const dataGroupByBranch = groupBy(
            dataList,
            (item) => item?.order?.store?.id
        );
        const branchIds = Object.keys(dataGroupByBranch);

        for (let index = 0; index < branchIds.length; index += 1) {
            const branchId = branchIds[index];
            const branchPayments = filter(
                dataGroupByBranch[branchId],
                (item) => !isEmpty(item?.confirmation?.dateOfReceiving)
            );
            const branchOrderList = uniqBy(
                map(branchPayments, (item) => item.order),
                (item) => item.id
            );
            const dateRangeText = join(
                map(formValues.timeRange, (item) => item.format("DD/MM/YYYY")),
                " - "
            );
            const totalAmount = reduce(
                branchPayments,
                (sum, payment) => sum + payment.total,
                0
            );
            const branchCashierData = getCashierDataFromOrders(branchOrderList);
            const tableDataItem: any = {
                id: branchOrderList[0]?.store?.name,
                createdDate: dateRangeText,
                orderNo: "",
                branchName: branchOrderList[0]?.store?.name,
                total: branchCashierData.totalInvoice,
                settleAmount: branchCashierData.totalSettleAmount,
                unsettleAmount: branchCashierData.totalUnSettleAmount,
                remainingAmount: branchCashierData.totalRemainingAmount,
                paymentTotal: totalAmount,
                children: [],
            };
            const dateOfPaymentGroupBy = groupBy(branchPayments, (item) =>
                moment(item?.confirmation?.dateOfReceiving).format("DD/MM/YYYY")
            );

            const dateList = Object.keys(dateOfPaymentGroupBy);
            for (
                let dateIndex = 0;
                dateIndex < dateList.length;
                dateIndex += 1
            ) {
                const date = dateList[dateIndex];
                const datePayments = dateOfPaymentGroupBy[date];

                const dateOrderList = uniqBy(
                    map(datePayments, (item) => item.order),
                    (item) => item.id
                );
                const dateCashierData = getCashierDataFromOrders(dateOrderList);

                const totalAmount = reduce(
                    datePayments,
                    (sum, payment) => sum + payment.total,
                    0
                );
                const dateDataItem: any = {
                    id: date,
                    createdDate: date,
                    orderNo: "All SO",
                    branchName: dateOrderList[0]?.store?.name,
                    total: dateCashierData.totalInvoice,
                    settleAmount: dateCashierData.totalSettleAmount,
                    unsettleAmount: dateCashierData.totalUnSettleAmount,
                    remainingAmount: dateCashierData.totalRemainingAmount,
                    paymentTotal: totalAmount,
                    children: [],
                };

                for (
                    let paymentIndex = 0;
                    paymentIndex < datePayments.length;
                    paymentIndex += 1
                ) {
                    const payment = datePayments[paymentIndex];

                    const order = payment?.order;
                    const {
                        paymentMethod,
                        incomeType,
                        bankTransfer,
                        bankTransferNo,
                        total,
                        paidByText,
                        paymentNo,
                        creditCard,
                    } = payment;
                    dateDataItem.children.push({
                        id: order?.orderNo,
                        createdDate: "",
                        orderNo: order?.orderNo,
                        branchName: order?.store?.name,
                        total: order?.total,
                        settleAmount: order?.settleAmount,
                        unsettleAmount: order?.unsettleAmount ?? 0,
                        remainingAmount: order?.remainingAmount,
                        customerNo: order?.customer?.customerNo,
                        receiptNo: paymentNo,
                        paymentMethod,
                        cardNumber: creditCard?.number ?? "",
                        cardType: creditCard?.type ?? "",
                        incomeType,
                        bankTransfer,
                        bankTransferNo,
                        paymentTotal: total,
                        paidByText,
                    });
                }

                tableDataItem.children.push(dateDataItem as any);
            }

            tableData.push(tableDataItem);
        }

        setCashierDataTable(tableData);
        setMode(EXPORT_MODE.VIEW);
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <BranchSelect
                    className={classNameInput}
                    value={formValues?.branch}
                    onChange={(value) =>
                        exportForm.setFieldValue("branch", value)
                    }
                    multiple
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.createdDate,
            dataIndex: "createdDate",
            width: 400,
        },
        {
            title: "SO",
            dataIndex: "orderNo",
        },
        {
            title: "CN",
            dataIndex: "customerNo",
        },
        {
            title: Messages.receiptNo,
            dataIndex: "receiptNo",
        },
        {
            title: Messages.paymentMethod,
            dataIndex: "paymentMethod",
        },
        {
            title: Messages.cardNumber,
            dataIndex: "cardNumber",
            width: 150,
        },
        {
            title: Messages.cardType,
            dataIndex: "cardType",
        },
        {
            title: Messages.incomeType,
            dataIndex: "incomeType",
        },
        {
            title: Messages.bankTransfer,
            dataIndex: "bankTransfer",
        },
        {
            title: Messages.bankNumber,
            dataIndex: "bankTransferNo",
        },
        {
            title: Messages.amount,
            dataIndex: "paymentTotal",
        },
        {
            title: Messages.paidBy,
            dataIndex: "paidByText",
            width: 300,
        },
        {
            title: Messages.branch,
            dataIndex: "branchName",
        },
        {
            title: Messages.totalInvoice,
            dataIndex: "total",
            render: StringUtils.moneyFormat,
        },
        {
            title: Messages.settleAmount,
            dataIndex: "settleAmount",
            render: StringUtils.moneyFormat,
            titleTooltip: "Order with fully paid",
        },
        {
            title: Messages.unsettleAmount,
            dataIndex: "unsettleAmount",
            render: StringUtils.moneyFormat,
            width: 200,
            titleTooltip: "Order with partially paid",
        },
        {
            title: Messages.balanceOfInvoice,
            dataIndex: "remainingAmount",
            render: StringUtils.moneyFormat,
            width: 200,
            titleTooltip: "Remaining amount",
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                baseColumnProps={{ width: 100 }}
                columns={columns}
                dataSource={cashierDataTable}
                // source={() => Promise.resolve(cashierDataTable)}
                className=""
                expandable={{
                    defaultExpandAllRows: true,
                }}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (mode) {
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.cashierReportByPayment}
                    </div>

                    {/* <div className="bg-dark px-3 py-1">
                        <small className="text-white">{`Step ${mode + 1} of ${
                            EXPORT_STEPS.length
                        }`}</small>
                    </div> */}
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={mode === EXPORT_MODE.INPUT}
                onClickPrevious={() => setMode(EXPORT_MODE.INPUT)}
                onClickExport={() => exportForm.handleSubmit()}
                onClickView={onPreview}
                exportDisabled={
                    !formValues?.branch?.length ||
                    !formValues?.timeRange?.length
                }
            />
            {mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportCashierByPayment;
