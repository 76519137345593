import { gql } from "@apollo/client";
import API from "../API";
import { F_CREDITS } from "../fragments/credit";

export const getCreditList = gql`
    ${F_CREDITS}
    query getCreditList($paginate: CreditPaginateInput!) {
        data: listCreditForAdmin(paginate: $paginate) {
            ...F_CREDITS
        }
    }
`;

const creditsConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const voucherConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? [];
        return detail;
    },
};

const CreditAPI = {
    list: async (paginate: any) =>
        API.withConverter(creditsConverter).query({
            query: getCreditList,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),
};

export default CreditAPI;
