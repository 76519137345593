import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
    TimeUtils,
} from "d-react-components";
import { find } from "lodash";
import { useContext } from "react";
import { generatePath } from "react-router-dom";
import AppLink from "../../../common/AppLink";
import { VOUCHER_TYPES } from "../../../constant/voucher";
import { TransferVoucherContext } from "../../../context/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { TRANSFER_VOUCHER_TYPE } from "../../../constant/transfer-voucher";

const TransferVoucherDetailVouchers = () => {
    const { transferDetail } = useContext(TransferVoucherContext);
    const columns: IColumnsProps = [
        {
            title: Messages.voucher,
            dataIndex: "code",
            render: (code, voucher) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher?.id,
                    })}
                >
                    {code}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "type",
            render: (data) => {
                const voucherType = find(VOUCHER_TYPES, { id: data });
                return Messages[voucherType?.label as string];
            },
        },
        {
            title: Messages.amount,
            dataIndex: "value",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.effectiveDate,
            dataIndex: "effectiveDate",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.expiryDate,
            dataIndex: "expiryDate",
            render: (expiryDate, voucher) => {
                if (
                    transferDetail?.transferVoucherType ===
                    TRANSFER_VOUCHER_TYPE.CONVERT_TO_CREDIT_VOUCHER
                ) {
                    const expiryDate = find(
                        transferDetail.voucherExpiryDate,
                        (item) => item.id === voucher?.id
                    );
                    return TimeUtils.toDateTime(expiryDate?.expiryDate);
                }
                return expiryDate
                    ? TimeUtils.toDateTime(expiryDate)
                    : Messages.neverExpires;
            },
        },
    ];
    return (
        <AwesomeTableComponent
            columns={columns}
            dataSource={transferDetail?.voucher ?? []}
        />
    );
};

export default TransferVoucherDetailVouchers;
