import { Spin } from "antd";
import classNames from "classnames";
import { Button } from "d-react-components";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import MediaAPI from "../../api/queries/media";
import Messages from "../../languages/Messages";

interface IUploadField {
    onChange: (value: string) => void;
    disabled?: boolean;
    className?: string;
    children?: any;
}

const UploadField = ({
    onChange,
    disabled = false,
    children = <Button>{Messages.upload}</Button>,
    className,
}: IUploadField) => {
    const [loading, setLoading] = useState(false);

    const onDrop = ([fileUpload]: any) => {
        setLoading(true);
        const reader: any = new FileReader();
        reader.readAsDataURL(fileUpload);
        reader.onloadend = async () => {
            MediaAPI.uploadFile(fileUpload).then(({ fileUrl }) => {
                onChange({
                    ...fileUpload,
                    fileUrl,
                });
                setLoading(false);
            });
        };
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const containerClass = classNames(className);

    return (
        <div {...getRootProps()} className={containerClass}>
            <input {...getInputProps()} multiple={false} disabled={disabled} />
            {children}
            {loading && (
                <div className="position-absolute">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default UploadField;
