import {
    Button,
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import {
    useCreateCategoryEquipmentForAdminMutation,
    useDetailCategoryEquipmentForAdminLazyQuery,
    useUpdateCategoryEquipmentForAdminMutation,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { EQUIPMENT_CATEGORY_STATUSES } from "../../../constant/equipment";
import { PERMISSION } from "../../../constant/user";
import { EquipmentCategorySchema } from "../../../formschema/equipment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const EquipmentCategoryCreate = () => {
    const [createCat] = useCreateCategoryEquipmentForAdminMutation();
    const [getCatDetail, { data, loading, refetch }] =
        useDetailCategoryEquipmentForAdminLazyQuery();
    const [updateCat] = useUpdateCategoryEquipmentForAdminMutation();

    const navigate = useNavigate();
    const params = useParams();
    const isEdit = !isEmpty(params?.equipmentCategoryId);

    const categoryForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: EquipmentCategorySchema,
        onSubmit: (values) => {
            onCreateCat(values);
        },
    });

    const onCreateCat = (input: any) => {
        const body = {
            variables: {
                payload: { ...input },
                ...(isEdit ? { id: params?.equipmentCategoryId } : {}),
            },
        };
        if (!isEdit) {
            Progress.show(
                {
                    method: createCat,
                    params: [body],
                },
                (resp: any) => {
                    const newCat =
                        resp?.data?.createCategoryEquipmentForAdmin?.data;
                    navigate(
                        generatePath(Path.EQUIPMENT_CATEGORY_DETAIL, {
                            equipmentCategoryId: newCat.id,
                        })
                    );
                    Notifications.showSuccess(
                        Messages.createEquipmentCategorySuccessfully
                    );
                }
            );
        } else {
            Progress.show(
                {
                    method: updateCat,
                    params: [body],
                },
                (resp: any) => {
                    const newCat =
                        resp?.data?.updateCategoryEquipmentForAdmin?.data;
                    Notifications.showSuccess(
                        Messages.updateEquipmentCategorySuccessfully
                    );
                }
            );
        }
    };

    useEffect(() => {
        if (isEdit) {
            getCatDetail({
                variables: {
                    id: params?.equipmentCategoryId as string,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(data)) {
            const catDetail = data?.detailCategoryEquipmentForAdmin?.data;
            categoryForm.setValues({
                name: catDetail?.name,
                status: catDetail?.status,
                description: catDetail?.description,
            });
        }
    }, [data]);

    return (
        <div>
            <Header
                showCancel={false}
                className="sticky top-0"
                title={
                    isEdit
                        ? data?.detailCategoryEquipmentForAdmin?.data?.name ??
                          ""
                        : Messages.newEquipmentCategory
                }
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.CATEGORY_EQUIPMENT.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => categoryForm.handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-3 bg-white">
                <div className="grid grid-cols-1 gap-4">
                    <div className="col-span-1">
                        <InputText
                            label={Messages.name}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                categoryForm.setFieldValue(
                                    "name",
                                    event.target.value
                                )
                            }
                            value={categoryForm?.values?.name}
                            error={categoryForm?.errors?.name as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.status}
                            value={categoryForm.values?.status}
                            error={categoryForm.errors?.status}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={EQUIPMENT_CATEGORY_STATUSES}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                categoryForm.setFieldValue("status", value)
                            }
                        />
                    </div>
                    <div className="col-span-1">
                        <RichTextTiny
                            label={Messages.description}
                            value={categoryForm?.values?.description ?? ""}
                            onChange={(value: any) =>
                                categoryForm.setFieldValue(
                                    `description`,
                                    value.toString("html")
                                )
                            }
                            placeholder={Messages.description}
                            error={categoryForm?.errors?.description}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EquipmentCategoryCreate;
