import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
    useFirstTime,
} from "d-react-components";
import CurrencyFormat from "react-currency-format";
import { debounce, find } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import CreditAPI from "../../../api/queries/credit";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CreditTableFilterDrawer from "./CreditTableFilterDrawer";
import { IVoucher } from "../../../interfaces/voucher";
import { VoucherCodeView } from "../../customer/detail/CustomerDetailServiceHistory";
import CustomerName from "../../../common/avatar/CustomerName";

const CreditTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);
    const navigate = useNavigate();

    const columns: IColumnsProps = [
        {
            title: Messages.creditNo,
            dataIndex: "creditNo",
            render: (code) => code,
        },
        {
            title: Messages.ref,
            dataIndex: "vouchers",
            render: (voucherList: IVoucher[], item) => {
                return (
                    <VoucherCodeView
                        voucherList={
                            item.voucher ? [item.voucher] : voucherList
                        }
                    />
                );
            },
        },
        {
            title: Messages.customer,
            dataIndex: "owner",
            render: (customer) => <CustomerName user={customer} />,
            width: 150,
        },
        {
            title: Messages.initialValue,
            dataIndex: "initialValue",
            width: 120,
            render: (value) => (
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.creditAmount,
            dataIndex: "value",
            width: 120,
            render: (value) => (
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
        },
        {
            title: Messages.usedTimes,
            dataIndex: "totalUsedTimes",
            width: 120,
        },
        {
            title: Messages.usedValues,
            dataIndex: "totalUsedValues",
            render: (value) => (
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user) => (
                <UserAvatarName
                    user={user}
                    size="xx-small"
                    variant="square"
                    showInfo={{ showOPD: false }}
                />
            ),
        },
    ];

    const source = (pagingData: any) => {
        const filterInput = {};

        const body = {
            page: pagingData?.pageIndex,
            limit: pagingData?.pageSize,
            search: searchRef?.current,
            sort: {
                createdAt: -1,
            },
            ...filterInput,
        };
        return CreditAPI.list(body);
    };

    const onClickTableAction = (actionId: string, voucher: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                onClickNew={null}
                label={Messages.allCredit}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />

            {openFilter && (
                <CreditTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default CreditTable;
