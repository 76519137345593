import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { Checkbox } from "d-react-components";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { QuotationCreateContext } from "../../../context/quotation";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";

interface CustomerForm {
    [key: string]: any;
}
const ProductSchema = Yup.object().shape({
    name: Yup.string().required(Messages.fieldRequired),
    sku: Yup.string().required(Messages.fieldRequired),
    salePrice: Yup.number().required(Messages.fieldRequired),
    remark: Yup.string(),
});

const AddCustomItemDrawer = ({
    open,
    onClose,
    product,
}: {
    open: boolean;
    onClose: any;
    product?: IProduct;
}) => {
    const { productCustomList, setProductCustomList } = useContext(
        QuotationCreateContext
    );
    const productForm = useFormik<CustomerForm>({
        initialValues: isEmpty(product) ? { remark: "" } : product,
        onSubmit: () => {
            if (values.id) {
                setProductCustomList(
                    productCustomList.map((product: any) =>
                        product.id === values.id
                            ? {
                                  ...product,
                                  ...values,
                                  salePrice: parseFloat(values?.salePrice),
                              }
                            : product
                    )
                );
            } else {
                setProductCustomList([
                    ...productCustomList,
                    {
                        ...values,
                        id: Math.random(),
                        quantity: 1,
                        isCustomProduct: true,
                        salePrice: parseFloat(values?.salePrice),
                    },
                ]);
            }
            onClose();
        },
        validationSchema: ProductSchema,
        validateOnChange: false,
    });
    const { values, setFieldValue, errors, handleSubmit } = productForm;
    const classNameInput = "mb-3";

    return (
        <Drawer
            title={Messages.customProduct}
            open={open}
            onClose={onClose}
            destroyOnClose
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                {/* <LabelLanguage
                    label={Messages.name}
                    language={language}
                    onChange={setLanguage}
                />
                <InputText
                    value={values?.[language]?.name ?? ""}
                    onChange={(event) =>
                        setFieldValue(`${language}.name`, event?.target?.value)
                    }
                    error={errors?.en?.name || errors?.th?.name}
                    className={classNameInput}
                /> */}
                <InputTextForm
                    keyData="name"
                    form={productForm}
                    className={classNameInput}
                />
                <InputTextForm
                    keyData="sku"
                    form={productForm}
                    className={classNameInput}
                />
                <InputTextForm
                    className={classNameInput}
                    keyData="salePrice"
                    form={productForm}
                />
                <InputTextForm
                    className={classNameInput}
                    keyData="remark"
                    form={productForm}
                    multiple
                />
                <Checkbox
                    label={Messages.vatIncluded}
                    onChange={() => setFieldValue("isNonVAT", !values.isNonVAT)}
                    className="mt-3"
                    checked={values.isNonVAT}
                />
            </div>
        </Drawer>
    );
};

export default AddCustomItemDrawer;
