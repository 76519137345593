import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import PersonalInfoForm from "../share/PersonalInfoForm";

const CustomerCreatePersonalInfo = () => {
    const { customerForm } = useContext(CustomerCreateContext);

    return (
        <div className="card-container p-3">
            <PersonalInfoForm form={customerForm} />
        </div>
    );
};

export default CustomerCreatePersonalInfo;
