import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find, reduce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import StockRequestAPI from "../../../../api/queries/stock-request";
import AppLink from "../../../../common/AppLink";
import UserAvatarName from "../../../../common/avatar/UserAvatarName";
import {
    STOCK_REQUEST_STATUSES,
    STOCK_REQUEST_TYPES,
} from "../../../../constant/stock-request";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";
import StockRequestFilterDrawer from "./StockRequestFilterDrawer";

const StockRequestTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: "ID",
            dataIndex: "requestNo",
            render: (requestNo, item) => (
                <AppLink
                    to={generatePath(Path.INVENTORY_REQUEST_STOCK_DETAIL, {
                        stockRequestId: item.id,
                    })}
                >
                    {requestNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.type,
            dataIndex: "type",
            render: (type) =>
                (Messages as any)[
                    find(STOCK_REQUEST_TYPES, (i) => i?.id === type)?.label ??
                        ""
                ],
        },
        {
            title: Messages.requestedQty,
            dataIndex: "products",
            render: (products) => {
                return reduce(
                    products,
                    (res, item) => res + (item?.stock ?? 0),
                    0
                );
            },
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (data) => (
                <ViewLabelStatus
                    status={data}
                    listStatus={STOCK_REQUEST_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.staff,
            dataIndex: "createByAdmin",
            render: (user) => (
                <UserAvatarName
                    user={user}
                    size="xx-small"
                    variant="square"
                    showInfo={{ showOPD: false }}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        // const filterParam = mapBookingFilterToServer(filter);
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: 1 },
            // ...filterParam,
        };
        return StockRequestAPI.list(input);
    };

    const onClickTableAction = (actionId: string, booking: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.BOOKING_DETAIL, {
                        bookingId: booking.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.INVENTORY_REQUEST_STOCK_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allStockRequest}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <StockRequestFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default StockRequestTable;
