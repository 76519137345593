//@ts-ignore
import QRCode from "react-qr-code";
import { Switch } from "antd";
import { Button, InputText, Progress } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useMemo } from "react";
// import UserAPI from "../../../api/user/UserAPI";
import { UserCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";
import { useGenerateAuthenticatorSecretMutation } from "../../../api/hooks";
import { getAuthString } from "../../../utils/qrcode";

const UserCrudOtpAuth = () => {
    const { userForm } = useContext(UserCrudContext);
    const { isOtpAuth, otpAuthSecretCode, nickName } = userForm?.values;
    const [getAuthenticatorSecret] = useGenerateAuthenticatorSecretMutation();

    const onClickGenerateCode = () =>
        Progress.show(
            {
                method: getAuthenticatorSecret,
                // params: [`DeMasterCRM - ${companyId}_${nickName}`],
                params: [],
            },
            (res: any) => {
                userForm.setValues({
                    ...userForm?.values,
                    ...(res?.data?.generateAuthenticatorSecret?.secret
                        ? {
                              otpAuthSecretCode:
                                  res?.data?.generateAuthenticatorSecret
                                      ?.secret,
                          }
                        : {}),
                });
            }
        );

    const otpAuthUrl = useMemo(() => {
        return getAuthString(nickName, otpAuthSecretCode);
    }, [otpAuthSecretCode]);

    return (
        <div className="bg-white">
            <h5 className="text-primary">{Messages.authentication}</h5>
            <Switch
                className="mt-3"
                checked={isOtpAuth}
                onChange={() => userForm.setFieldValue("isOtpAuth", !isOtpAuth)}
            />
            <div hidden={!isOtpAuth} className="mt-3">
                <text>{Messages.userGoogleToScanThisCode}</text>
                <Button
                    hidden={!isEmpty(otpAuthSecretCode)}
                    onClick={onClickGenerateCode}
                    className="mt-3"
                >
                    {Messages.generate}
                </Button>
                <div className="mt-3" hidden={isEmpty(otpAuthSecretCode)}>
                    <div className="d-flex justify-content-between">
                        <label>{Messages.secretCode}</label>
                        <div
                            className="text-primary cursor-pointer"
                            onClick={onClickGenerateCode}
                        >
                            {Messages.regenerate}
                        </div>
                    </div>
                    <InputText disabled value={otpAuthSecretCode} />
                    <label className="mt-3">{Messages.qrCode}</label>
                    {!isEmpty(otpAuthUrl) && (
                        <QRCode
                            value={otpAuthUrl}
                            size={150}
                            className="mt-3"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserCrudOtpAuth;
