/* eslint-disable no-nested-ternary */
import { ObjectUtils, Select } from "d-react-components";
import { isArray, map, sortBy, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import MaterialAPI from "../../../api/queries/material";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";

interface IUnitSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

interface IUnit {
    id: string;
    name: string;
}

const MaterialUnitSelect = ({
    label = Messages.unit,
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IUnitSelect<IUnit>) => {
    const [unitList, setUnitList] = useState<any[]>(
        isArray(value) ? value : [value]
    );

    useEffect(() => {
        MaterialAPI.loadConfig().then((res: any) => {
            const unitRes = sortBy(
                res?.data?.data?.data ?? [],
                (item) => item.name
            );
            const selectedValue = isArray(value) ? value : value ? [value] : [];
            const filterUnit = map([...selectedValue, ...unitRes], (item) => ({
                id: item?.id,
                name: item?.name,
            }));
            const uniqUnits = uniqBy(filterUnit, (item) => item?.id);
            setUnitList(uniqUnits);
        });
    }, []);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(unitList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(unitList, id));
    };

    const unitValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            className={className}
            label={label}
            dataSource={unitList}
            getLabel={(item) => item?.name}
            getKey={(item) => item?.id}
            value={unitValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default MaterialUnitSelect;
