import { Button, Drawer, IDrawerProps, Progress } from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { useGetCustomerQuickViewLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { AppStateContext } from "../../../context/app";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerInfoBar from "./CustomerInfoBar";

export interface ICustomerInfoDrawerProps {
    customerId: string;
    drawerProps?: IDrawerProps;
    open: any;
    onClose: any;
}

const CustomerInfoDrawer: React.FC<ICustomerInfoDrawerProps> = ({
    customerId,
    open,
    onClose,
    drawerProps,
}) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    const [customer, setCustomer] = useState<ICustomer>();
    const [quickViewCustomerForAdmin] = useGetCustomerQuickViewLazyQuery();

    useEffect(() => {
        if (customerId) {
            loadCustomerDetail();
        }
    }, [customerId]);

    const loadCustomerDetail = () => {
        Progress.show(
            {
                method: quickViewCustomerForAdmin,
                params: [{ variables: { id: customerId } }],
            },
            (res: any) => {
                const customerDetail = res?.data?.data?.data ?? {};
                setCustomer(customerDetail);
            }
        );
    };
    if (!customer) {
        return <div />;
    }

    return (
        <Drawer open={open} onClose={onClose} size="x-large" {...drawerProps}>
            <div className="p-4">
                <CustomerInfoBar
                    customer={customer}
                    showInfo={{
                        classNameHeader: "",
                        classNameContact: "mt-3",
                        classNameGeneral: "mt-3",
                        classNameMedical: "mt-3",
                        showAddTags: false,
                        showStatus: true,
                        showCredit: true,
                    }}
                    className="pb-24 relative"
                />
            </div>
            <AppLink
                className="position-absolute bottom-0 w-100"
                to={generatePath(Path.CUSTOMER_DETAIL, {
                    customerId: customer.id,
                })}
                target="_blank"
                onClick={() => {
                    setOpenAppDrawer({ open: false });
                }}
            >
                <Button className="w-100" style={{ height: "60px" }}>
                    {Messages.visitProfile}
                </Button>
            </AppLink>
        </Drawer>
    );
};

export default CustomerInfoDrawer;
