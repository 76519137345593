import { Icon } from "d-react-components";
import _ from "lodash";
import React from "react";
import CurrencyFormat from "react-currency-format";
import LinesEllipsis from "react-lines-ellipsis";
import { getProductPrice } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";

const ProductCard = React.memo(
    ({
        product,
        isHideSku = false,
        isHideImage = false,
        addNewProduct,
        applyPriceSet,
    }: any) => {
        const { imageurl, name, sku, gallery, thumbnail } = product;

        const getNameProduct = () => {
            if (_.isEmpty(product?.detailurl) && _.isEmpty(product?.editurl)) {
                return name;
            }
            return <a href={product?.editurl}>{name}</a>;
        };
        const renderImageProduct = () => {
            let url = "/images/img-default.png";
            if (isHideImage) {
                return <div />;
            }
            if (thumbnail) {
                url = thumbnail;
            } else if (gallery && gallery?.[0]) {
                url = gallery?.[0];
            }
            return (
                <div className="aspect-w-4 aspect-h-3">
                    <img
                        className="w-full h-full object-center object-cover"
                        alt={name}
                        src={url}
                    />
                </div>
            );
        };

        return (
            <div className="group mb-2 bg-white flex flex-col cursor-pointer relative max-h-[280px] 3xl:max-h-[320px] 4xl:max-h-[360px]">
                {/* {product?.total_stock > 0 && ( */}
                <div
                    onClick={() => addNewProduct(product)}
                    className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 hidden group-hover:flex items-center justify-content-center z-50"
                >
                    <Icon name="add" size="xxx-large" color="white" />
                </div>
                {/* )} */}
                {product.total_stock > 0 && (
                    <div className="absolute bg-green-500 text-white px-2 py-1 text-xs top-0 left-0 z-40">
                        {product.total_stock} {Messages.left}
                    </div>
                )}
                {product.total_stock <= 0 && (
                    <div className="absolute bg-gray-400 text-white px-2 py-1 text-xs top-0 left-0 z-40">
                        {Messages.outOfStock}
                    </div>
                )}
                {renderImageProduct()}

                <div className="p-2 flex-1 overflow-hidden">
                    {!isHideSku && (
                        <span className="text-xs inline-block text-gray-400 w-100">
                            <LinesEllipsis
                                text={sku || ""}
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                            />
                        </span>
                    )}
                    <span className="text-sm inline-block text-gray-500 overflow-hidden">
                        <LinesEllipsis
                            text={getNameProduct() || ""}
                            maxLine="3"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                        {/* {renderSubName && renderSubName()} */}
                    </span>
                </div>
                <div className="flex">
                    <span className="flex-1 text-xs text-center bg-primary text-white py-2">
                        <CurrencyFormat
                            value={getProductPrice(
                                "salePrice",
                                product,
                                applyPriceSet
                            )}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </span>
                    <span className="flex-1 text-xs text-center bg-gray-400 text-white py-2 line-through">
                        <CurrencyFormat
                            value={getProductPrice(
                                "regularPrice",
                                product,
                                applyPriceSet
                            )}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </span>
                </div>
            </div>
        );
    }
);

export default ProductCard;
