import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
    password: Yup.string()
        .min(4, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    username: Yup.string().required("Required"),
});
export const AuthOtpSchema = Yup.object().shape({
    code: Yup.string().length(6, "6 number required!").required("Required"),
});
