import { IColumnsProps, StringUtils, TimeUtils } from "d-react-components";
import { find, join, map, max, min, reduce, some } from "lodash";
import moment from "moment";
import { generatePath } from "react-router";
import { VoucherStatus, VoucherType } from "../../api/hooks";
import AppLink from "../AppLink";
import UserAvatarName from "../avatar/UserAvatarName";
import { VOUCHER_TYPE, VOUCHER_TYPES } from "../../constant/voucher";
import { IOrder } from "../../interfaces/order";
import { IVoucher } from "../../interfaces/voucher";
import Messages from "../../languages/Messages";
import ProductNameView from "../../screen/product/share/ProductNameView";
import Path from "../../screen/Path";
import { VoucherCodeView } from "../../screen/customer/detail/CustomerDetailServiceHistory";

export const getVoucherTableColumns = ({
    showSaleDate = true,
    showFirstUsed = true,
    showLastUsed = true,
    showSalesPerson = true,
    showCoSalePerson = true,
    showChangeExpiryDate = false,
    renderChangeExpiryDate,
}: Record<string, any>) => {
    const voucherTableColumns: IColumnsProps = [
        ...(showSaleDate
            ? [
                  {
                      title: Messages.saleDate,
                      dataIndex: "order",
                      render: (order: IOrder) =>
                          TimeUtils.toDateTime(order?.createdAt),
                  },
              ]
            : []),
        {
            title: Messages.orderNo,
            dataIndex: "order",
            render: (order: IOrder) => (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, { orderId: order?.id })}
                >
                    {order?.orderNo}
                </AppLink>
            ),
        },
        {
            title: Messages.product,
            dataIndex: "product",
            render: ({ product }: any) => (
                <ProductNameView product={product} isHideImage />
            ),
        },
        ...(showChangeExpiryDate
            ? [
                  {
                      title: Messages.expiryDate,
                      dataIndex: "expiryDate",
                      render: (expiryDate: any, voucher: any) =>
                          renderChangeExpiryDate(expiryDate, voucher),
                  },
              ]
            : []),
        {
            title: Messages.voucher,
            dataIndex: "vouchers",
            render: (voucherList: IVoucher[]) => (
                <VoucherCodeView voucherList={voucherList} />
            ),
        },
        ...(showFirstUsed
            ? [
                  {
                      title: Messages.firstUsed,
                      dataIndex: "vouchers",
                      render: (voucherList: IVoucher[]) => {
                          const allUsageVouchers = reduce(
                              voucherList,
                              (allUsages: any, voucher) =>
                                  allUsages.concat(voucher?.usage ?? []),
                              []
                          );

                          const allUsageUsedAt = map(allUsageVouchers, (item) =>
                              moment(item.usedAt).valueOf()
                          );

                          const minUsedAt = min(allUsageUsedAt);

                          if (!minUsedAt || !allUsageUsedAt?.length) {
                              return "N/A";
                          }
                          return TimeUtils.toDateTime(minUsedAt);
                      },
                  },
              ]
            : []),
        ...(showLastUsed
            ? [
                  {
                      title: Messages.lastUsed,
                      dataIndex: "vouchers",
                      render: (voucherList: IVoucher[]) => {
                          const allUsageVouchers = reduce(
                              voucherList,
                              (allUsages: any, voucher) =>
                                  allUsages.concat(voucher?.usage ?? []),
                              []
                          );

                          const allUsageUsedAt = map(allUsageVouchers, (item) =>
                              moment(item.usedAt).valueOf()
                          );

                          const maxUsedAt = max(allUsageUsedAt);

                          if (!maxUsedAt || !allUsageUsedAt?.length) {
                              return "N/A";
                          }
                          return TimeUtils.toDateTime(maxUsedAt);
                      },
                  },
              ]
            : []),
        {
            title: Messages.expiryDate,
            dataIndex: "vouchers",
            render: (voucherList: IVoucher[]) => {
                const allExpiryVouchers = map(voucherList, (voucher) =>
                    moment(voucher.expiryDate).valueOf()
                );

                const minExpiryDate = min(allExpiryVouchers);

                if (!minExpiryDate || !allExpiryVouchers?.length) {
                    return "N/A";
                }
                return TimeUtils.toDateTime(minExpiryDate);
            },
        },
        {
            title: Messages.purchasingPriceSO,
            dataIndex: "product",
            render: (product) => {
                return (
                    <div className="min-w-[150px]">
                        {StringUtils.moneyFormat(
                            product?.salePrice * product?.quantity
                        )}
                    </div>
                );
            },
        },
        {
            title: Messages.qtyTotal,
            dataIndex: "vouchers",
            render: (vouchers) => (
                <div className="min-w-[100px]">{vouchers?.length}</div>
            ),
        },
        {
            title: Messages.qtyOfUse,
            dataIndex: "vouchers",
            render: (vouchers) => {
                let qtyOfUse = reduce(
                    vouchers,
                    (sum, voucher) => sum + voucher.totalUsedTimes,
                    0
                );
                const isCashVoucher = some(
                    vouchers,
                    (item) => item.type === VOUCHER_TYPE.CASH_VOUCHER
                );
                if (isCashVoucher) {
                    qtyOfUse = reduce(
                        vouchers,
                        (sum, voucher) => sum + voucher.totalUsedTimes,
                        0
                    );
                }
                return <div className="min-w-[100px]">{qtyOfUse}</div>;
            },
        },
        {
            title: Messages.qtyBalance,
            dataIndex: "vouchers",
            width: 150,
            render: (vouchers) => {
                const qtyBalance: any =
                    (vouchers?.length ?? 0) -
                    reduce(
                        vouchers,
                        (sum, voucher) => sum + voucher.totalUsedTimes,
                        0
                    );
                const isCashVoucher = some(
                    vouchers,
                    (item) => item.type === VOUCHER_TYPE.CASH_VOUCHER
                );
                // if (isCashVoucher) {
                //     qtyBalance = StringUtils.moneyFormat(
                //         reduce(
                //             vouchers,
                //             (sum, voucher) =>
                //                 sum +
                //                 (voucher?.value - voucher.totalUsedValues),
                //             0
                //         )
                //     );
                // }
                return (
                    <div className="min-w-[100px]">
                        {isCashVoucher
                            ? Messages.notApplicableForCV
                            : qtyBalance}
                    </div>
                );
            },
        },
        {
            title: Messages.balanceThb,
            dataIndex: "vouchers",
            width: 150,
            render: (vouchers: IVoucher[]) => {
                const isGiftVoucher = some(
                    vouchers,
                    (item) => item.type === VoucherType.GiftVoucher
                );
                if (isGiftVoucher) {
                    return Messages.notApplicableForGV;
                }
                const listVouchersExcludingCredit = vouchers.filter(
                    (v) => v.status !== VoucherStatus.Converted
                );
                return StringUtils.moneyFormat(
                    reduce(
                        listVouchersExcludingCredit,
                        (sum, voucher) =>
                            sum +
                            parseFloat(voucher.value) -
                            voucher.totalUsedValues,
                        0
                    )
                );
            },
        },
        ...(showSalesPerson
            ? [
                  {
                      title: Messages.salesperson,
                      dataIndex: "order",
                      render: (order: IOrder) => (
                          <div className="min-w-[150px]">
                              <UserAvatarName user={order?.salesPerson} />
                          </div>
                      ),
                  },
              ]
            : []),
        ...(showCoSalePerson
            ? [
                  {
                      title: Messages.coSalePerson,
                      dataIndex: "product",
                      render: (product: any) => {
                          const salesPerson = product?.salesPerson ?? [];

                          const allSalesPersonName = map(
                              salesPerson,
                              (item) =>
                                  `${item?.salesPerson?.firstName} ${item?.salesPerson?.lastName}`
                          );
                          let salePersonTest = join(allSalesPersonName, ", ");
                          if (!allSalesPersonName?.length) {
                              salePersonTest = "N/A";
                          }
                          return (
                              <div className="min-w-[150px]">
                                  {salePersonTest}
                              </div>
                          );
                      },
                  },
              ]
            : []),
        {
            title: Messages.branch,
            dataIndex: "order",
            render: (order) => (
                <div className="min-w-[100px]">{order?.branch?.name}</div>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "vouchers",
            render: ([voucher]) => {
                const voucherType = find(
                    VOUCHER_TYPES,
                    (item) => item.id === voucher?.type
                );
                return Messages[voucherType?.label as any];
            },
        },
    ];

    return voucherTableColumns;
};
