export const BRANCH_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};
export const BRANCH_STATUSES = [
    { id: BRANCH_STATUS.ACTIVE, label: "enabled", color: "#33B950" },
    { id: BRANCH_STATUS.INACTIVE, label: "disabled", color: "#C4C4C4" },
];
export const STOCK_ADJUSTMENT_STATUS = {
    ALLOW: "ALLOW",
    NOT_ALLOW: "NOT_ALLOW",
};
export const STOCK_ADJUSTMENT_STATUSES = [
    { id: STOCK_ADJUSTMENT_STATUS.ALLOW, label: "allowed", color: "#33B950" },
    {
        id: STOCK_ADJUSTMENT_STATUS.NOT_ALLOW,
        label: "notAllowed",
        color: "#C4C4C4",
    },
];