import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_CUSTOMER_BASIC } from "./customer";
import { F_PRODUCT_BASIC } from "./product";
import { F_TREATMENT_PRODUCT } from "./treatment";

export const F_DOCTOR_ORDER_PRODUCT = gql`
    ${F_PRODUCT_BASIC}
    fragment F_DOCTOR_ORDER_PRODUCT on AdminDoctorOrderProductDto {
        quantity
        remark
        product {
            ...F_PRODUCT_BASIC
        }
        complicationTreatment {
            id
            treatmentNo
            createdAt
        }
    }
`;

export const F_DOCTOR_ORDER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_DOCTOR_ORDER_PRODUCT}
    ${F_CUSTOMER_BASIC}
    ${F_ADMIN_USER_BASIC}
    ${F_TREATMENT_PRODUCT}
    fragment F_DOCTOR_ORDER on AdminDoctorOrderDto {
        id
        doctorOrderNo
        updatedAt
        createdAt
        status
        doctorSubject
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        products {
            ...F_DOCTOR_ORDER_PRODUCT
        }
        services {
            ...F_DOCTOR_ORDER_PRODUCT
        }
        customer {
            ...F_CUSTOMER_BASIC
        }
        doctor {
            ...F_ADMIN_USER_BASIC
        }
        treatments {
            id
            treatmentNo
            status
            products {
                ...F_TREATMENT_PRODUCT
            }
        }
        complicationTreatments {
            id
            treatmentNo
        }
        order {
            id
            orderNo
        }
        booking {
            id
            bookingNo
            branch {
                id
                name
                code
            }
        }
    }
`;

export const F_DOCTOR_ORDER_FOR_CREATE_TREATMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_DOCTOR_ORDER_PRODUCT}
    ${F_CUSTOMER_BASIC}
    ${F_ADMIN_USER_BASIC}
    fragment F_DOCTOR_ORDER_FOR_CREATE_TREATMENT on AdminDoctorOrderDto {
        id
        doctorOrderNo
        status
        products {
            ...F_DOCTOR_ORDER_PRODUCT
        }
        services {
            ...F_DOCTOR_ORDER_PRODUCT
        }
        customer {
            ...F_CUSTOMER_BASIC
        }
        doctor {
            ...F_ADMIN_USER_BASIC
        }
        order {
            id
            orderNo
        }
        booking {
            id
            bookingNo
            startDate
            endDate
            branch {
                id
                name
                code
                address {
                    address
                    country {
                        id
                        name
                        primary
                    }
                    province {
                        id
                        name
                    }
                    district {
                        id
                        name
                    }
                    subDistrict {
                        id
                        name
                    }
                    postcode
                }
            }
        }
    }
`;

export const F_DOCTOR_ORDERS = gql`
    ${F_DOCTOR_ORDER}
    ${F_PAGINATE}
    fragment F_DOCTOR_ORDERS on AdminDoctorOrderPaginateResponse {
        data {
            ...F_DOCTOR_ORDER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_SEARCH_DOCTOR_ORDERS = gql`
    ${F_DOCTOR_ORDER}
    ${F_PAGINATE}
    fragment F_SEARCH_DOCTOR_ORDERS on SearchAdminDoctorOrderResponse {
        data {
            ...F_DOCTOR_ORDER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
