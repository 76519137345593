import { Notifications } from "d-react-components";
import { Formik } from "formik";
import { isEmpty, isUndefined, omit } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useUpdateMaterialPurchaseBillingMutation } from "../../../api/hooks";
import { QuotationCreateContext } from "../../../context/quotation";
import { mapBillingToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import BillingDrawer from "../../quotation/share/BillingDrawer";
import AddressItem from "../../order/share/AddressItem";
import { MaterialPurchaseDetailContext } from "../../../context/material";

const OrderBilling = () => {
    const { order, loadOrderDetail } = useContext(
        MaterialPurchaseDetailContext
    );
    const [showAddModal, setShowAddModal] = useState(false);
    const [updateOrderBilling] = useUpdateMaterialPurchaseBillingMutation();
    const { billing } = order ?? {};
    const [newBilling, setNewBilling] = useState<any>(order?.billing);

    const onChangeOrderBilling = (formValues: any) => {
        updateOrderBilling({
            variables: {
                id: order?.id as string,
                isSameShippingAddress: formValues.isSameShippingAddress,
                payload: omit(mapBillingToSer(newBilling), "isDefault") as any,
            },
        })
            .then(() => {
                setShowAddModal(false);
                loadOrderDetail();
            })
            .catch((err: any) => {
                Notifications.showError(err);
            });
    };

    useEffect(() => {
        setNewBilling(order?.billing);
    }, [order]);

    if (!order) {
        return <></>;
    }

    return (
        <QuotationCreateContext.Provider
            value={
                {
                    billing: newBilling,
                    setBilling: setNewBilling,
                } as any
            }
        >
            {/* <CustomerDetailContext.Provider
                value={
                    { customer: order?.customer, setCustomer: () => {} } as any
                }
            > */}
            <Formik
                initialValues={{
                    isSameShippingAddress: isUndefined(
                        order?.isSameShippingAddress
                    )
                        ? false
                        : order?.isSameShippingAddress,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={() => {}}
            >
                {({ values, errors }) => {
                    return (
                        <>
                            <div className="border p-3 mb-3">
                                <div className="flex items-center mb-2">
                                    <h5 className="font-semibold flex-1">
                                        {Messages.billingInformation}
                                    </h5>
                                    <a onClick={() => setShowAddModal(true)}>
                                        {Messages.edit}
                                    </a>
                                </div>
                                {billing && !order?.isSameShippingAddress && (
                                    <div>
                                        <AddressItem address={billing} />
                                    </div>
                                )}
                                {order?.isSameShippingAddress &&
                                    Messages.sameAsShipping}
                            </div>

                            {showAddModal && (
                                <BillingDrawer
                                    open={showAddModal}
                                    onClose={() => setShowAddModal(false)}
                                    onSave={() => onChangeOrderBilling(values)}
                                />
                            )}
                        </>
                    );
                }}
            </Formik>
            {/* </CustomerDetailContext.Provider> */}
        </QuotationCreateContext.Provider>
    );
};

export default OrderBilling;
