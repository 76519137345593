import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import TemporaryScheduleAPI from "../../../api/queries/temporary-schedule";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import TableAction from "../../../common/TableActions";
import { SCHEDULE_STATUSES } from "../../../constant/temporary-schedule";

import Messages from "../../../languages/Messages";
import Path from "../../Path";

const TemporaryScheduleTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "user",
            render: (user) => (
                <UserAvatarName
                    user={user}
                    size="x-small"
                    variant="square"
                    showInfo={{ showOPD: false }}
                />
            ),
        },
        {
            title: Messages.companyId,
            dataIndex: "user",
            render: (user) => user?.companyId,
        },
        {
            title: Messages.role,
            dataIndex: "user",
            render: (user) => user?.role?.name,
        },

        {
            title: Messages.from,
            dataIndex: "fromDate",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.to,
            dataIndex: "toDate",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    listStatus={SCHEDULE_STATUSES}
                    status={status}
                />
            ),
        },
        {
            title: Messages.updatedAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (config: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, config)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const paginate = {
            search: searchRef.current,
            page: pagingData?.pageIndex,
            limit: pagingData?.pageSize,
        };
        return TemporaryScheduleAPI.userTemporarySchedules(paginate);
    };

    const onClickTableAction = (actionId: string, config: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.TEMPORARY_SCHEDULE_DETAIL, {
                        userId: config?.user?.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.temporarySchedule}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                onClickNew={() => navigate(Path.TEMPORARY_SCHEDULE_CREATE)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default TemporaryScheduleTable;
