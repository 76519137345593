import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { getProductPrice } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const ProductName = ({
    item,
    isHideSku = false,
    subBody,
    isHideImage = false,
    isOldPro = false,
    renderSubName,
    imgClass = "w-10 h-10",
    showPrice = false,
    addOneAfterName = null,
    generateVoucher = false,
    isCreate = false,
    linkToProduct = true,
    applyPriceSet,
}: any) => {
    if (!item) {
        return <></>;
    }
    const { name, sku, gallery, thumbnail, doctorOrder, isGift } = item || {};

    const renderProName = () => {
        if (linkToProduct) {
            const productName = () => {
                return (
                    <span
                        className={classNames(
                            "text-sm whitespace-normal mb-1",
                            {
                                "line-through": isOldPro,
                            }
                        )}
                    >
                        {name}
                        {renderSubName && renderSubName()}
                    </span>
                );
            };
            return (
                <div className="flex items-center">
                    {item.id ? (
                        <AppLink
                            to={generatePath(Path.PRODUCT_DETAIL, {
                                productId: item.id,
                            })}
                            target="_blank"
                        >
                            {productName()}
                        </AppLink>
                    ) : (
                        productName()
                    )}
                    {addOneAfterName && addOneAfterName()}
                </div>
            );
        }
        return (
            <div className="flex items-center">
                <span
                    className={classNames("text-sm whitespace-normal mb-1", {
                        "line-through": isOldPro,
                    })}
                >
                    {name}
                    {renderSubName && renderSubName()}
                </span>
                {addOneAfterName && addOneAfterName()}
            </div>
        );
    };

    return (
        <div className="flex items-start z-50 relative">
            <div className="relative shrink-0">
                <ProductImage
                    {...{
                        gallery,
                        imgClass,
                        isHideImage,
                        thumbnail,
                    }}
                />
                {item.quantity && (
                    <span className="rounded-full bg-primary leading-none text-[8px] px-0 text-white p-1 text-center absolute -top-1 -right-1 block w-[16px] h-[16px]">
                        {item.quantity}
                    </span>
                )}
            </div>
            <div className="ml-2 flex flex-col w-full">
                {renderProName()}
                {generateVoucher && generateVoucher()}
                {!isHideSku && (
                    <span
                        className={classNames("text-sm text-gray-500", {
                            "line-through": isOldPro,
                        })}
                        style={{ textAlign: "start" }}
                    >
                        SKU: {sku}
                    </span>
                )}
                {showPrice && (
                    <div>
                        <span className="text-sm line-through text-gray-500">
                            <CurrencyFormat
                                value={getProductPrice(
                                    "regularPrice",
                                    item,
                                    applyPriceSet
                                )}
                                displayType="text"
                                prefix="฿"
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </span>
                        <span className="text-sm ml-2">
                            <CurrencyFormat
                                value={getProductPrice(
                                    "salePrice",
                                    item,
                                    applyPriceSet
                                )}
                                displayType="text"
                                prefix="฿"
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </span>
                    </div>
                )}
                {isGift && !isCreate && (
                    <div className="my-2 px-2 py-1 text-white bg-amber-500 text-sm w-fit-content flex">
                        <img
                            alt=""
                            src="/icons/voucher.svg"
                            className="mr-1 w-[16px]"
                        />
                        {Messages.generatedGiftVoucher}
                    </div>
                )}
                {doctorOrder && (
                    <AppLink
                        to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                            orderId: doctorOrder.id,
                        })}
                        target="_blank"
                    >
                        <div className="my-2 px-2 py-1 text-white bg-theme text-sm w-fit-content hover:bg-primary-light">
                            {Messages.doctorOrderNo}: #
                            {doctorOrder?.doctorOrderNo}
                        </div>
                    </AppLink>
                )}
                {subBody}
            </div>
        </div>
    );
};
const ProductImage = React.memo(
    ({ isHideImage, gallery, thumbnail, imgClass }: any) => {
        const imgUrl = useMemo<string>(() => {
            let url = "/images/img-default.png";
            if (thumbnail) {
                url = thumbnail;
            } else if (gallery && gallery?.[0]) {
                url = gallery?.[0];
            }
            return url;
        }, [gallery]);

        return isHideImage ? null : (
            <img
                src={imgUrl}
                className={`${imgClass} object-cover mt-1 flex-1`}
                alt=""
            />
        );
    }
);

export default ProductName;
