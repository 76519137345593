import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";

const EquipmentActivity = () => {
    const { equipmentId } = useParams<any>();
    return (
        <div>
            {equipmentId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.Equipment}
                    activityRefId={equipmentId ?? ""}
                />
            )}
        </div>
    );
};

export default EquipmentActivity;
