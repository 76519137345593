import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_EQUIPMENT_BASIC } from "./equipment";

export const F_EQUIPMENT_STOCK_REQUEST_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_EQUIPMENT_STOCK_REQUEST_CONFIRMATION on EquipmentRequestConfirmationDto {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        createdAt
        remark
    }
`;

export const F_EQUIPMENT_STOCK_REQUEST = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_EQUIPMENT_BASIC}
    ${F_EQUIPMENT_STOCK_REQUEST_CONFIRMATION}
    fragment F_EQUIPMENT_STOCK_REQUEST on EquipmentRequestDto {
        id
        equipmentRequestNo
        status
        dateOfArrival
        remark
        attachments
        createdAt
        updatedAt
        branch {
            id
            name
            code
        }
        items {
            ...F_EQUIPMENT_BASIC
        }
        confirmation {
            ...F_EQUIPMENT_STOCK_REQUEST_CONFIRMATION
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_EQUIPMENT_STOCK_REQUESTS = gql`
    ${F_EQUIPMENT_STOCK_REQUEST}
    ${F_PAGINATE}
    fragment F_EQUIPMENT_STOCK_REQUESTS on EquipmentRequestPaginateResponse {
        data {
            ...F_EQUIPMENT_STOCK_REQUEST
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
