import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_AGENCY_BASIC } from "./agency";
import { F_PAGINATE } from "./app";
import { F_PRODUCT_BASIC, F_PRODUCT_ITEM_FULL_DETAILS } from "./product";

export const F_ORDER_BILLING = gql`
    fragment F_ORDER_BILLING on AdminOrderBilling {
        country {
            ...F_COUNTRY
        }
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        typeOfCustomer
        firstName
        lastName
        citizenId
        taxId
        companyName
        companyPhone
        email
        phone
        address
    }
`;

export const F_ORDER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_ORDER_BILLING}
    ${F_AGENCY_BASIC}
    fragment F_ORDER on AdminOrder {
        id
        orderNo
        status
        purchaseType
        remark
        subtotal
        total
        totalVat
        createdAt
        updatedAt
        isSameShippingAddress
        hasAutoGenerateVoucher
        priceSetType
        expired
        sourceOfCustomer
        billing {
            ...F_ORDER_BILLING
        }
        doctorOrder {
            id
            doctorOrderNo
            createdAt
        }
        quotation {
            id
            quotationNo
            createdAt
        }
        customer {
            avatar
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            id
            nickname
            phone
            email
            gender
            customerNo
            birthDay
        }
        contact {
            firstName
            lastName
            email
            phone
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        salesPerson {
            ...F_ADMIN_USER_BASIC
        }
        store {
            id
            name
        }
        fee {
            name
            total
        }
        voucher {
            quantity
            amount
            voucher {
                id
                voucherNo
                createdAt
                code
            }
        }
        agency {
            ...F_AGENCY_BASIC
        }
        manualDiscount {
            discountType
            discountValue
            total
            product {
                id
                name
                sku
            }
        }
        purchaseType

        credits {
            credit {
                id
                creditNo
                initialValue
                value
                expiryDate
            }
            amount
        }
    }
`;

export const F_ORDER_BASIC = gql`
    fragment F_ORDER_BASIC on AdminOrderBasic {
        id
        status
        orderNo
        subtotal
        total
    }
`;

export const F_ORDERS = gql`
    ${F_ORDER}
    ${F_PAGINATE}
    fragment F_ORDERS on ListAdminOrderResponse {
        data {
            ...F_ORDER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_PRODUCT_SALESPERSON = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_PRODUCT_SALESPERSON on AdminSalesPerson {
        isPrimary
        type
        salesPerson {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_ORDER_PRODUCT = gql`
    ${F_PRODUCT_BASIC}
    ${F_PRODUCT_SALESPERSON}
    fragment F_ORDER_PRODUCT on AdminOrderProduct {
        quantity
        regularPrice
        hasOpeningPrice
        salePrice
        isGift
        typeOfProduct
        product {
            ...F_PRODUCT_BASIC
        }
        salesPerson {
            ...F_PRODUCT_SALESPERSON
        }
        service {
            id
            name
        }
        premiumService {
            spending
            premiumServiceType
            product {
                ...F_PRODUCT_BASIC
            }
        }
        groups {
            quantity
            salePrice
            isDynamicPrice
            product {
                ...F_PRODUCT_BASIC
            }
        }
        doctorOrder {
            id
            doctorOrderNo
        }
        manualProduct
    }
`;

export const F_ORDER_MANUAL_PRODUCT = gql`
    fragment F_ORDER_MANUAL_PRODUCT on AdminOrderManualProduct {
        quantity
        salePrice
        name
        remark
        sku
        id
    }
`;

export const F_ORDER_DOCTOR_ORDER_PRODUCT = gql`
    ${F_PRODUCT_ITEM_FULL_DETAILS}
    fragment F_ORDER_DOCTOR_ORDER_PRODUCT on AdminOrderDoctorOrderProduct {
        quantity
        product {
            ...F_PRODUCT_ITEM_FULL_DETAILS
        }
        doctorOrder {
            id
            doctorOrderNo
        }
        treatment {
            id
            treatmentNo
            isComplication
        }
    }
`;
