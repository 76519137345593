import {
    AwesomeTableComponent,
    Badge,
    Button,
    HeaderTable,
    IColumnsProps,
    TabBar,
    ViewLabelStatus,
    useFirstTime,
} from "d-react-components";
import { debounce, find, forEach, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { JsonParam, useQueryParam } from "use-query-params";
import MaterialInventoryAPI from "../../../api/queries/material-inventory";
import AppLink from "../../../common/AppLink";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { MATERIAL_ADJUST_REQUEST_STATUSES } from "../../../constant/material";

export interface IInventoryTableProps {
    [key: string]: any;
}

const STATUS_TABS = [
    {
        id: "all",
        label: "all",
    },
    ...MATERIAL_ADJUST_REQUEST_STATUSES,
];

const MaterialAdjustmentRequestTable: React.FC<IInventoryTableProps> = ({
    id,
}) => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");

    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const [tabSelected, setTabSelected] = useState<any>(STATUS_TABS[0]);
    const [summary, setSummary] = useState<any>();
    const navigate = useNavigate();

    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    useEffect(() => {
        getSummary();
    }, []);

    const getSummary = () => {
        const listStatus = map(
            MATERIAL_ADJUST_REQUEST_STATUSES,
            (item) => item.id
        );
        MaterialInventoryAPI.listAdjustmentRequestSummary().then((response) => {
            const resp: any = response?.data?.data?.data ?? {};
            const summary: any = {};
            forEach(listStatus, (status) => {
                summary[status] = find(resp, { status })?.count ?? 0;
            });
            setSummary(summary);
        });
    };

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "adjustmentNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_ADJUST_REQUEST_DETAIL, {
                        requestId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },

        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_ADJUST_REQUEST_STATUSES}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = {};
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...(tabSelected?.id !== "all" ? { status: [tabSelected?.id] } : {}),
            ...filterParam,
        };
        return MaterialInventoryAPI.listAdjustmentRequest(input);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.inventory}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onClickNew={() => navigate(Path.MATERIAL_ADJUST_REQUEST_CREATE)}
                className="app-layout__page-header"
            />
            <div className="flex">
                <TabBar
                    dataSource={STATUS_TABS}
                    onChange={(tab) => setTabSelected(tab)}
                    value={tabSelected}
                    className="overflow-x-scroll my-4 pb-1 flex-row form-tabs"
                    getLabel={(item: any) => (
                        <>
                            <span>{Messages[item.label]}</span>
                            {item.id !== "all" && (
                                <Badge
                                    index={
                                        summary?.[item.id] > 100
                                            ? "100+"
                                            : summary?.[item.id]
                                    }
                                    variant="index"
                                    size="x-large"
                                    color={
                                        summary?.[item.id] === 0
                                            ? "green"
                                            : "red"
                                    }
                                />
                            )}
                        </>
                    )}
                    isScroll
                    hideScrollBar={false}
                    minWidthItem="100%"
                    classNameItem="justify-content-start text-gray-500 bg-white"
                    variant="horizontal"
                />
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
            {/* {openFilter && (
                <StockAdjustmentFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )} */}
        </div>
    );
};

export default MaterialAdjustmentRequestTable;
