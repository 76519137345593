import {
    AwesomeTableComponent,
    Dropdown,
    IColumnsProps,
    Icon,
} from "d-react-components";
import { filter, find, includes, isEmpty, map, some } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Drawer from "../../../common/Drawer";
import { PURCHASE_TYPES } from "../../../constant/quotation";
import {
    FEE_TYPES,
    FEE_UNITS,
    JOB_COST_TYPES,
} from "../../../constant/service";
import { JOB_TYPES } from "../../../constant/user";
import { IJobCost, IJobCostFee } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import { JobCostTemplate, PurchaseType } from "../../../api/hooks";

export interface IJobCostPresetDrawerProps {
    open: boolean;
    onClose: () => void;
    jobCost: IJobCost[];
    jobCostTemplate: JobCostTemplate;
    hideAction?: boolean;
}

const JobCostPresetDrawer: React.FC<IJobCostPresetDrawerProps> = ({
    open,
    onClose,
    jobCost,
    jobCostTemplate,
    hideAction,
}) => {
    const [jobCostList, setJobCostList] = useState<IJobCost[]>(jobCost);
    const [filterData, setFilterData] = useState<any>();
    const addSecondaryColumns = useMemo(() => {
        return some(jobCostList, (item) => !!item?.secondaryFee);
    }, [jobCostList]);

    const columns: IColumnsProps = [
        {
            title: (
                <div className="flex flex-row items-center">
                    <div>{Messages.type}</div>
                    <Dropdown
                        buttonProps={{ iconName: "expand_more" }}
                        dataSource={JOB_COST_TYPES}
                        position="left-edge"
                        multiple
                        value={filterData?.type ?? []}
                        showSelectIndicator
                        onChange={(v) => {
                            setFilterData({
                                ...(filterData || {}),
                                type: v,
                            });
                        }}
                        Messages={Messages}
                    />
                </div>
            ),
            dataIndex: "type",
            render: (data, item, index) => {
                return (
                    Messages[find(JOB_COST_TYPES, { id: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.role,
            dataIndex: "role",
            render: (data, item, index) => {
                return (
                    Messages[find(JOB_TYPES, { key: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: (
                <div className="flex flex-row items-center">
                    <div>{Messages.purchaseType}</div>
                    <Dropdown
                        buttonProps={{ iconName: "expand_more" }}
                        dataSource={PURCHASE_TYPES}
                        multiple
                        value={filterData?.purchaseType ?? []}
                        showSelectIndicator
                        onChange={(v) => {
                            setFilterData({
                                ...(filterData || {}),
                                purchaseType: v,
                            });
                        }}
                        Messages={Messages}
                    />
                </div>
            ),
            dataIndex: "purchaseType",
            render: (data, item, index) => {
                if (data === null) {
                    return Messages.noAgency;
                }
                return (
                    Messages[find(PURCHASE_TYPES, { id: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.primaryFee,
            dataIndex: "primaryFee",
            render: (data: IJobCostFee, item, index) => {
                const { feeType } = data || ({} as IJobCostFee);
                return find(FEE_TYPES, { id: feeType })?.label ?? ("" || "N/A");
            },
        },
        {
            title: Messages.unit,
            dataIndex: "primaryFee",
            render: (data: IJobCostFee, item, index) => {
                const { unit } = data || {};
                return (
                    Messages[find(FEE_UNITS, { id: unit })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.unitQty,
            dataIndex: "primaryFee",
            render: (data: IJobCostFee, item, index) => {
                const { unitQty } = data || {};
                return unitQty;
            },
        },
        {
            title: Messages.amount,
            dataIndex: "primaryFee",
            align: "center",
            render: (data: IJobCostFee, item, index) => {
                const { amount, isFlexible } = data || {};
                if (isFlexible) {
                    return Messages.flexible;
                }
                return amount;
            },
        },
        {
            title: Messages.action,
            dataIndex: "",
            width: 100,
            align: "center",
            render: (jobCost, item, index) => (
                <Icon
                    name="edit"
                    onClick={() => {
                        // setOpenEditModal({ open: true, data: item });
                    }}
                    className="cursor-pointer"
                />
            ),
        },
    ];

    if (addSecondaryColumns) {
        const secondaryFees: IColumnsProps = [
            {
                title: Messages.additionalFee,
                dataIndex: "secondaryFee",
                render: (data: IJobCostFee, item, index) => {
                    const { feeType } = data || ({} as IJobCostFee);
                    return (
                        find(FEE_TYPES, { id: feeType })?.label ?? ("" || "N/A")
                    );
                },
            },
            {
                title: Messages.unitOfAdditionalFee,
                dataIndex: "secondaryFee",
                render: (data: IJobCostFee, item, index) => {
                    const { unit } = data || {};
                    return (
                        Messages[find(FEE_UNITS, { id: unit })?.label ?? ""] ||
                        "N/A"
                    );
                },
            },
            {
                title: Messages.unitQtyOfAdditionalFee,
                dataIndex: "secondaryFee",
                render: (data: IJobCostFee, item, index) => {
                    const { unitQty } = data || {};
                    return unitQty || "N/A";
                },
            },
            {
                title: Messages.amountOfAdditionalFee,
                dataIndex: "secondaryFee",
                align: "center",
                render: (data: IJobCostFee, item, index) => {
                    const { amount } = data || {};
                    return amount || "N/A";
                },
            },
            {
                title: Messages.requiredUnitQtyTriggeringFee,
                dataIndex: "secondaryFee",
                align: "center",
                render: (data: IJobCostFee, item, index) => {
                    const { triggerThresholdQty } = data || {};
                    return triggerThresholdQty || "N/A";
                },
            },
        ];
        columns.splice(columns.length - 1, 0, ...secondaryFees);
    }

    if (hideAction) {
        columns.pop();
    }

    useEffect(() => {
        if (
            some(
                Object.keys(filterData || {}),
                (key) => !isEmpty(filterData?.[key])
            )
        ) {
            let res = [...(jobCost ?? [])];
            Object.keys(filterData).forEach((key) => {
                if (!isEmpty(filterData?.[key])) {
                    const ids = map(filterData?.[key], (item) => item?.id);
                    res = filter(res, (item: any) => {
                        if (
                            [
                                JobCostTemplate.Jc2Template,
                                JobCostTemplate.Jc3Template,
                            ].includes(jobCostTemplate)
                        ) {
                            if (
                                key === "purchaseType" &&
                                item?.[key] === null
                            ) {
                                return some(ids, (id) =>
                                    [
                                        PurchaseType.Complication,
                                        PurchaseType.Marketing,
                                        PurchaseType.Normal,
                                        PurchaseType.Staff,
                                        PurchaseType.Vip,
                                    ].includes(id)
                                );
                            }
                        }
                        return includes(ids, item?.[key]);
                    });
                }
            });
            setJobCostList(res || []);
        } else {
            setJobCostList(jobCost);
        }
    }, [jobCost, filterData]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="90%"
            hideFooter
        >
            <AwesomeTableComponent
                className="job-cost-preset-table"
                tableLayout="auto"
                columns={columns}
                dataSource={jobCostList}
                components={
                    {
                        // body: {
                        //     wrapper: DraggableContainer,
                        //     row: DraggableBodyRow,
                        // },
                    }
                }
                pagination={false}
                rowKey={(item) => `${item.name}_${item.quantity}`}
                baseColumnProps={{ width: 150 }}
            />
        </Drawer>
    );
};

export default JobCostPresetDrawer;
