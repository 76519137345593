import {
    Button,
    DialogManager,
    Icon,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { compact } from "lodash";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { PromotionStatus } from "../../../api/hooks";
import PromotionAPI from "../../../api/queries/promotion";
import ActionMenu from "../../../common/ActionButton";
import { PROMOTIONS_STATUSES } from "../../../constant/promotion";
import { usePromotionDetailContext } from "../../../context/promotion";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

export interface IPromotionDetailHeaderProps {
    [key: string]: any;
}

const PROMOTION_ACTIONS = [
    {
        id: "stop",
        label: Messages.stopThisPromotion,
    },
    {
        id: "duplicate",
        label: Messages.duplicate,
    },
];

const PromotionDetailHeader: React.FC<IPromotionDetailHeaderProps> = () => {
    const navigate = useNavigate();
    const { promotion, setPromotion } = usePromotionDetailContext();
    const { title, status, createdAt, promotionNo, start, end } =
        promotion || {};
    const actionMenus = compact(
        PROMOTION_ACTIONS.map((i) => {
            if (
                promotion.status === PromotionStatus.Ended &&
                i?.id === "stop"
            ) {
                return null;
            }
            return i;
        })
    );

    const onStopPromotion = () => {
        return Progress.show(
            { method: PromotionAPI.stop, params: [promotion?.id] },
            (res: any) => {
                if (res?.data?.data?.error) {
                    return;
                }
                setPromotion(res?.data?.data?.data);
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <div className="border-b">
            <div className="flex justify-between items-center px-4 py-2 border-b">
                <div className="flex-center-y gap-4">
                    <h5 className="text-primary">{title}</h5>
                    <ViewLabelStatus
                        status={status}
                        listStatus={PROMOTIONS_STATUSES}
                        getLabel={(i) => Messages[i?.label]}
                    />
                </div>
                <div className="flex-center-y gap-4">
                    <Button iconName="print" />
                    {actionMenus?.length ? (
                        <ActionMenu
                            dataSource={actionMenus}
                            buttonProps={{ size: "large" }}
                            onClick={(item) => {
                                if (item?.id === "stop") {
                                    DialogManager.showConfirm(
                                        Messages.stopThisPromotion,
                                        Messages.stopThisPromotionDes,
                                        () => {
                                            onStopPromotion();
                                        }
                                    );
                                } else if (item?.id === "duplicate") {
                                    navigate(
                                        generatePath(Path.PROMOTION_CREATE, {
                                            duplicateId: promotion?.id,
                                        })
                                    );
                                }
                            }}
                        />
                    ) : null}
                </div>
            </div>
            <div className="flex items-center px-4 py-3 gap-4 text-gray-400">
                <div className="flex-center-y">
                    <Icon name="description" />
                    <div className="ml-2">{promotionNo}</div>
                </div>
                <div className="flex-center-y">
                    <Icon name="calendar_today" />
                    <div className="ml-2">
                        {`${Messages.createdOn} `}
                        {TimeUtils.toDateTime(createdAt)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionDetailHeader;
