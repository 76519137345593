// react
import {
    Button,
    InputText,
    InputTextPassword,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useMedia } from "react-use";
import { StringParam, useQueryParam } from "use-query-params";
import AuthAPI from "../../api/auth/AuthAPI";
import { useLoginAsAdminLazyQuery } from "../../api/hooks";
import { CONFIG } from "../../configuration/AppConfig";
import { APP_WIDE_SCREEN_WIDTH } from "../../constant/app";
import { LoginSchema } from "../../formschema/auth";
import Messages from "../../languages/Messages";
import { useSignIn } from "../../store/auth/authHook";
import Path from "../Path";
import OtpFormView from "./shared/OtpFormView";

enum LoginViewType {
    LOGIN_PASSWORD = "LOGIN_PASSWORD",
    LOGIN_OTP = "LOGIN_OTP",
    LOGIN_WORKINFINITY = "LOGIN_WORKINFINITY",
    LOGIN_DEFAULT = "LOGIN_DEFAULT",
}
const workInfinityXUrl = CONFIG.LOGIN_WX_URL;
const workInfinityXMobileUrl = CONFIG.LOGIN_WX_MOBILE_URL;

export default function AuthLogin() {
    const signIn = useSignIn();
    const isMobile = useMedia(`(max-width: ${APP_WIDE_SCREEN_WIDTH}px)`);
    const [activeView, setActiveView] = useState(LoginViewType.LOGIN_DEFAULT);
    const [thirdPartyAccessToken] = useQueryParam("accessToken", StringParam);
    const [loginAdmin] = useLoginAsAdminLazyQuery();

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
            accessToken: null as any,
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            Progress.show(
                {
                    method: loginAdmin,
                    params: [
                        {
                            variables: {
                                request: {
                                    username: values?.username,
                                    password: values?.password,
                                },
                                device: "WEBSITE",
                            },
                        },
                    ],
                },
                onProcessLogin,
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });

    useEffect(() => {
        if (!isEmpty(thirdPartyAccessToken)) {
            signIn(thirdPartyAccessToken as string);
            window.open(Path.ROOT, "_self");
        }
    }, [thirdPartyAccessToken]);

    const onProcessLogin = (res: any) => {
        if (res?.error) {
            Notifications.showError(res?.error);
            return;
        }
        const { accessToken, verified } = res?.data?.loginAdmin;
        if (!verified) {
            setActiveView(LoginViewType.LOGIN_OTP);
        } else {
            onProcessAuthenticated(null, accessToken);
        }
    };

    const onSubmitOtp = (code: string) => {
        Progress.show(
            {
                method: AuthAPI.otpAuthenticate,
                params: [{ code, accessToken: formik?.values?.accessToken }],
            },
            onProcessAuthenticated
        );
    };

    const onProcessAuthenticated = (res?: any, token?: string) => {
        const accessToken = res?.data?.authenticate?.accessToken ?? token;
        signIn(accessToken);
        window.open(Path.ROOT, "_self");
    };

    const renderBackButton = () => {
        return (
            <Button
                size="x-small"
                className="align-self-start mb-5 px-0"
                variant="trans"
                iconName="chevron_left"
                content={Messages.back}
                onClick={() => {
                    if (activeView === LoginViewType.LOGIN_OTP) {
                        formik.setFieldValue("accessToken", null);
                        return setActiveView(LoginViewType.LOGIN_PASSWORD);
                    }
                    if (activeView === LoginViewType.LOGIN_PASSWORD) {
                        return setActiveView(LoginViewType.LOGIN_DEFAULT);
                    }
                    return setActiveView(LoginViewType.LOGIN_PASSWORD);
                }}
            />
        );
    };

    const renderAuthorizationView = () => {
        return (
            <div className=" w-100 ">
                <form onSubmit={formik.handleSubmit}>
                    <InputText
                        id="login-username"
                        name="username"
                        placeholder="Enter username"
                        label="User name"
                        onChange={formik.handleChange}
                        error={formik.errors.username}
                        className="mt-3"
                    />
                    <InputTextPassword
                        id="login-password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        label="Password"
                        onChange={formik.handleChange}
                        error={formik.errors.password}
                        className="mt-3"
                    />

                    <Button
                        type="submit"
                        className="mt-5 w-100"
                        // onClick={() => formik.handleSubmit()}
                    >
                        Login
                    </Button>
                </form>
            </div>
        );
    };

    const renderOtpView = () => {
        return <OtpFormView onSubmitHandler={onSubmitOtp} />;
    };

    const renderDefaultView = () => {
        return (
            <div>
                <a
                    href={isMobile ? workInfinityXMobileUrl : workInfinityXUrl}
                    rel="noreferrer"
                    className="text text-nowrap d-button d-button__large w-100 inline-block text-white"
                    style={{ backgroundColor: "#041B47" }}
                >
                    <div className="bg-white w-6 h-6 p-1 rounded-1 mr-3">
                        <img
                            src="/images/workInfinityLogo.png"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <span className="text-white">
                        {Messages.loginWithWorkInfinityX}
                    </span>
                </a>
                <div className="relative my-3 text-center">
                    <div className="border-t-[1px] absolute w-full top-[18px] z-10">
                        {" "}
                    </div>
                    <span className="py-2 relative z-20 px-2 bg-white inline-block">
                        {Messages.orLoginWith}
                    </span>
                </div>
                <Button
                    onClick={() => setActiveView(LoginViewType.LOGIN_PASSWORD)}
                    variant="outline"
                    className="w-100"
                >
                    {Messages.loginWithUsernamePassword}
                </Button>
            </div>
        );
    };

    return (
        <div
            className="container-fluid d-flex justify-content-center align-items-center bg-muted"
            style={{ height: "100vh" }}
        >
            <div className="d-flex bg-white shadow">
                <img
                    alt=""
                    className="d-none d-md-block"
                    src="/images/login/login-welcome.jpg"
                    style={{ width: "500px", height: "500px" }}
                />
                <div
                    className="d-flex flex-column align-items-center justify-content-center bg-white px-4 py-4 w-sm-auto position-relative"
                    style={{ height: "500px", width: "350px" }}
                >
                    {activeView !== LoginViewType.LOGIN_DEFAULT &&
                        renderBackButton()}
                    <img
                        src="/images/logo.png"
                        alt=""
                        className="mb-5 d-sm-block h-[80px]"
                        style={{ width: "auto" }}
                    />
                    {activeView === LoginViewType.LOGIN_DEFAULT &&
                        renderDefaultView()}
                    {activeView === LoginViewType.LOGIN_PASSWORD &&
                        renderAuthorizationView()}
                    {activeView === LoginViewType.LOGIN_OTP && renderOtpView()}
                </div>
            </div>
        </div>
    );
}
