import { InputText } from "d-react-components";
import { isNaN } from "lodash";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import {
    JOB_COST_TEMPLATES,
    SERVICE_CONFIG_STATUSES,
    SERVICE_SURGERY_TYPES,
} from "../../../constant/service";
import Messages from "../../../languages/Messages";
import ServiceCategorySelect from "../../service-category/share/ServiceCategorySelect";

interface IServiceConfigInfoForm {
    configForm: any;
    showDescription?: boolean;
}

const ServiceConfigInfoForm = ({
    configForm,
    showDescription = true,
}: IServiceConfigInfoForm) => {
    const classNameInput = "col-12 mt-3";
    const formValues = configForm?.values;
    const formErrors = configForm?.errors;

    return (
        <div className="p-4 row">
            <InputTextForm
                form={configForm}
                keyData="name"
                className={classNameInput}
            />
            <ServiceCategorySelect
                value={formValues?.category}
                onChange={(value) => {
                    configForm.setFieldValue("category", value);
                }}
                className={classNameInput}
            />
            <InputSelectForm
                dataSource={JOB_COST_TEMPLATES}
                form={configForm}
                keyData="jobCostTemplate"
                className={classNameInput}
            />
            <InputSelectForm
                dataSource={SERVICE_SURGERY_TYPES}
                form={configForm}
                keyData="surgeryType"
                className={classNameInput}
            />
            <InputSelectForm
                dataSource={SERVICE_CONFIG_STATUSES}
                form={configForm}
                keyData="status"
                className={classNameInput}
            />
            <InputTextForm
                form={configForm}
                keyData="procedureTime"
                className={classNameInput}
                type="time"
            />
            <InputText
                label={Messages.defaultSaleCommission}
                className={classNameInput}
                value={`${formValues?.defaultSaleCommission}`}
                onChange={(event) => {
                    const value = parseFloat(event.target.value);
                    const commission = isNaN(value) ? 0 : value;
                    configForm.setFieldValue(
                        "defaultSaleCommission",
                        commission
                    );
                }}
            />
            {showDescription && (
                <div className={classNameInput}>
                    <label>{Messages.description}</label>
                    <RichTextTiny
                        value={formValues?.description ?? ""}
                        onChange={(value: any) =>
                            configForm.setFieldValue(
                                `description`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.description}
                        size="large"
                        error={formErrors.description || formErrors.description}
                    />
                </div>
            )}
        </div>
    );
};

export default ServiceConfigInfoForm;
