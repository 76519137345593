import styled from "@emotion/styled";
import classNames from "classnames";
import { AwesomeTableComponent } from "d-react-components";
import { isEmpty, map } from "lodash";
import { Fragment, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { DiscountType } from "../../../api/hooks";
import { IMaterialPurchase } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";
import MaterialName from "../share/ProductName";
import MaterialNameView from "../../material/share/MaterialNameView";
import ProductNameView from "../../product/share/ProductNameView";

const isExpandableProductRow = (row: any) => {
    return row?.children?.length > 0;
};

const RowMoney = ({
    title,
    value,
    className = "",
    isDiscount = false,
}: {
    title: string;
    value: number | string;
    className?: string;
    isDiscount?: boolean;
}) => {
    return (
        <div className={`${className} text-sm grid grid-cols-2 py-3 border-b`}>
            <div className="col-span-1">
                {isDiscount ? `${Messages.voucher} (${title})` : title}
            </div>
            <div
                className={classNames(
                    { "text-green-500": isDiscount },
                    `col-span-1 text-right`
                )}
            >
                {isDiscount && ` - `}
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    suffix=" THB"
                    decimalScale={2}
                    fixedDecimalScale
                />
            </div>
        </div>
    );
};

const PriceTable = ({ order }: { order: IMaterialPurchase }) => {
    // const vouchers = useMemo(() => {
    //     return groupVouchers(order?.voucher ?? []);
    // }, [order]);
    const { discount: manualDiscount, subtotal: orderTotal } = order;

    const renderManualDiscount = () => {
        if (isEmpty(manualDiscount)) {
            return null;
        }
        return (
            <Fragment>
                {map(manualDiscount, (item, index) => {
                    const {
                        discountType,
                        discountValue,
                        material,
                        doctorOrder,
                        total,
                    } = item || {};
                    const percentageManualDiscount = discountValue;
                    return (
                        <div
                            key={index}
                            className="text-sm grid grid-cols-2 py-3 border-b"
                        >
                            <div className="col-span-1">
                                <div className="flex items-center">
                                    <div className="font-weight-bold text-sm">
                                        {Messages.manualDiscount}
                                    </div>
                                </div>
                                <div className="text-primary">
                                    {`( ${
                                        material
                                            ? `SKU:${material?.sku}`
                                            : Messages.wholeCart
                                    } )`}
                                </div>
                            </div>
                            <div
                                className={classNames(
                                    `col-span-1 text-right text-green-500`
                                )}
                            >
                                <>
                                    <CurrencyFormat
                                        className="text-success"
                                        displayType="text"
                                        thousandSeparator
                                        prefix="- ฿"
                                        value={total}
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                    {discountType ===
                                        DiscountType.Percentage && (
                                        <span className="text-success">
                                            (
                                            <CurrencyFormat
                                                displayType="text"
                                                thousandSeparator
                                                value={percentageManualDiscount}
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                            %)
                                        </span>
                                    )}
                                </>
                            </div>
                        </div>
                    );
                })}
            </Fragment>
        );
    };

    return (
        <div className="flex justify-content-end text-sm">
            <div className="price-table min-w-[400px]">
                <RowMoney
                    title={Messages.subTotal}
                    value={order.subtotal ?? 0}
                />
                {order?.fee &&
                    order.fee.map((fee: any, index: string) => (
                        <RowMoney title={fee.name} value={fee.total} />
                    ))}
                {/* {vouchers &&
                    vouchers?.length > 0 &&
                    vouchers.map((voucher: any, index: number) => (
                        <RowMoney
                            key={index}
                            title={voucher?.voucher?.code}
                            value={voucher.amount}
                            isDiscount
                        />
                    ))} */}
                {renderManualDiscount()}
                <RowMoney
                    title={Messages.taxIncluded}
                    value={(Number(order.total) * 7) / 100}
                />
                <RowMoney
                    title={Messages.total}
                    value={order.total ?? 0}
                    className="font-semibold"
                />
            </div>
        </div>
    );
};

const QuotationPriceTable = ({
    order,
    onUpdated,
}: {
    order: IMaterialPurchase;
    onUpdated?: () => void;
}) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

    const renderExpandableProductTable = (product: any) => {
        return <></>;
    };

    const expandableTable = {
        expandedRowRender: renderExpandableProductTable,
        rowExpandable: (record: any) => isExpandableProductRow(record),
        defaultExpandAllRows: false,
        expandedRowKeys,
        onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
    };

    const productSource = useMemo(() => {
        return (
            order?.materials?.map((item) => ({
                ...item,
                ...item.material,
            })) ?? []
        );
    }, [order]);

    const columns = useMemo(() => {
        return [
            {
                title: Messages.no,
                dataIndex: "no",
                render: (data: any, item: any, index: any) => index + 1,
            },
            {
                title: Messages.name,
                dataIndex: "material",
                render: (data: any, item: any) => {
                    return (
                        <>
                            <MaterialName item={item} />
                            {item.remark && (
                                <div className="mt-2 bg-[#82A4A4] py-2 px-3 text-white">
                                    <h5 className="text-sm text-white mb-2">
                                        {Messages.remark}
                                    </h5>
                                    <div className="text-xs whitespace-pre-line">
                                        {item.remark}
                                    </div>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                title: Messages.avgPurchasePrice,
                dataIndex: "avgPurchasingUnitCost",
                align: "right" as const,
                width: 150,
                render: (data: any) => (
                    <CurrencyFormat
                        value={data ?? 0}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                ),
            },
            {
                title: Messages.unit,
                dataIndex: "material",
                align: "right" as const,
                width: 150,
                render: (material: any) => material?.purchaseUnit?.name,
            },
            {
                title: Messages.unitPrice,
                dataIndex: "price",
                align: "right" as const,
                width: 150,
                render: (data: any) => (
                    <CurrencyFormat
                        value={data}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                ),
            },
            {
                title: Messages.quantity,
                dataIndex: "quantity",
                align: "right" as const,
                width: 150,
            },
            {
                title: Messages.price,
                dataIndex: "price",
                align: "right" as const,
                width: 150,
                render: (data: any, item: any) => (
                    <CurrencyFormat
                        value={item.quantity ? data * item.quantity : ""}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                ),
            },
        ];
    }, [productSource]);

    return (
        <StyledTable>
            <AwesomeTableComponent
                tableLayout="auto"
                dataSource={productSource}
                columns={columns}
                expandable={expandableTable}
                pagination={false}
                rowKey={(item) => item.id}
                bordered={false}
            />
            {order && <PriceTable order={order} />}
        </StyledTable>
    );
};

const StyledTable = styled.div`
    .ant-table-expanded-row-level-1 {
        td {
            padding: 0 !important;
        }
    }
`;

export default QuotationPriceTable;
