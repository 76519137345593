import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find, join, map, reduce, round } from "lodash";
import moment from "moment";
import { useState } from "react";
import { JobCostType, JobTypeAdmin } from "../../api/hooks";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import Messages from "../../languages/Messages";
import { exportToCSV } from "../../utils/file";
import { getFullName } from "../../utils/Utils";
import UserSelect from "../user/share/UserSelect";
import ReportFooter from "./ReportFooter";
import UserAPI from "../../api/queries/user";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    user: [],
    timeRange: null,
};

const ReportNurseSummary = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = async (values: any) => {
        let userList = values.user;

        if (!userList?.length) {
            const userListRes = await UserAPI.list({
                jobTypes: [JobTypeAdmin.Nurse],
                page: 1,
                limit: 100000,
            });
            userList = userListRes?.data?.listAdminUserForAdmin?.data ?? [];
        }

        const payload = {
            user: map(userList, (item) => item.id),
            from: values?.timeRange?.[0]?.valueOf(),
            to: values?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.treatmentPicSummary, params: [payload] },
            loadData as any
        );
    };

    const loadData = (dataList: any[]) => {
        const exportData: any = [];
        const firstDayMonth = moment(formValues?.timeRange?.[0]);
        const lastDayMonth = moment(formValues?.timeRange?.[1]);
        const dateRangeText = join(
            [
                firstDayMonth.format("DD/MM/YYYY"),
                lastDayMonth.format("DD/MM/YYYY"),
            ],
            " - "
        );
        for (let index = 0; index < dataList.length; index += 1) {
            const dataItem = dataList[index];
            const picId = dataItem?.pic?.id;
            const totalHourTreatmentJoined = reduce(
                dataItem?.treatments,
                (sum, treatment) => {
                    const treatmentPic = find(
                        treatment.PIC,
                        (pic) => pic?.adminUser?.id === picId
                    );

                    // const jobCostList = treatmentPic?.jobCost ?? [];
                    // const totalTime = reduce(
                    //     jobCostList,
                    //     (sum, jobCost) =>
                    //         sum + (jobCost?.end ?? 0) - (jobCost?.start ?? 0),
                    //     0
                    // );
                    const totalTime =
                        (treatmentPic?.schedule?.end ?? 0) -
                        (treatmentPic?.schedule?.start ?? 0);
                    return round((sum + totalTime / 12) * 100) / 100;
                },
                0
            );

            const totalFee = reduce(
                dataItem?.treatments,
                (sum, treatment) => {
                    const manualFee =
                        treatment?.manualJobCostAssessment?.[
                            `${picId}&${JobCostType.NurseFee}`
                        ];
                    if (manualFee) {
                        return sum + manualFee?.amount ?? 0;
                    }
                    const totalAutoAssign = reduce(
                        treatment.jobCostAssessment,
                        (sum, jobCostAssessment) =>
                            sum +
                            (jobCostAssessment?.[
                                `${picId}&${JobCostType.NurseFee}`
                            ]?.amount ?? 0),
                        0
                    );

                    return sum + totalAutoAssign;
                },
                0
            );

            exportData.push({
                "Report Date": dateRangeText,
                Nurse: getFullName(dataItem?.pic),
                "Employee ID": dataItem?.pic?.companyId,
                "Total treatment joined (qty.)": dataItem?.treatments?.length,
                "Total treatment joined (hours)": totalHourTreatmentJoined,
                "Total Nurse Fee (THB)": StringUtils.moneyFormat(totalFee),
            });
        }

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Summary Nurse Report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <UserSelect
                    params={{ jobType: JobTypeAdmin.Nurse }}
                    className={classNameInput}
                    value={formValues?.user}
                    onChange={(value) =>
                        exportForm.setFieldValue("user", value)
                    }
                    multiple
                    label={Messages.nurse}
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: "Report Date",
            dataIndex: "Report Date",
        },
        {
            title: "Nurse",
            dataIndex: "Nurse",
        },
        {
            title: "Employee ID",
            dataIndex: "Employee ID",
        },
        {
            title: "Total treatment joined (qty.)",
            dataIndex: "Total treatment joined (qty.)",
        },
        {
            title: "Total treatment joined (hours)",
            dataIndex: "Total treatment joined (hours)",
        },
        {
            title: "Total Nurse Fee (THB)",
            dataIndex: "Total Nurse Fee (THB)",
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                columns={columns}
                dataSource={dataTable}
                className=""
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.nurseSummaryReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={!formValues?.timeRange?.length}
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportNurseSummary;
