import { Button, TimeUtils } from "d-react-components";
import React, { useMemo, useState } from "react";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import {
    PromotionUpdateDrawerType,
    usePromotionDetailContext,
} from "../../../context/promotion";
import Messages from "../../../languages/Messages";
import BranchListDataButton from "../../branch/share/BranchListDataButton";
import { PromotionStatus } from "../../../api/hooks";

export interface IPromotionDetailSideViewProps {
    [key: string]: any;
}

const PromotionDetailSideView: React.FC<IPromotionDetailSideViewProps> = ({
    id,
}) => {
    const { promotion, setOpenUpdateDrawer } = usePromotionDetailContext();
    const { start, end, branch, createByAdmin, status } = promotion || {};
    const isShowEdit = useMemo(() => {
        return status === PromotionStatus.Draft;
    }, [status]);

    return (
        <div className="flex flex-col gap-4">
            <div className="p-4 border flex flex-col gap-3">
                <div className="flex-center-y justify-between">
                    <h5 className="">{Messages.period}</h5>
                    {isShowEdit && (
                        <Button
                            iconName="edit"
                            variant="trans"
                            className="rounded-2"
                            size="x-small"
                            onClick={() => {
                                setOpenUpdateDrawer({
                                    open: true,
                                    type: PromotionUpdateDrawerType.PERIOD,
                                });
                            }}
                        />
                    )}
                </div>
                <div className="flex-center-y justify-between">
                    <div className="">{Messages.startAt}</div>
                    <div className="">{TimeUtils.toDateTime(start)}</div>
                </div>
                <div className="flex-center-y justify-between">
                    <div className="">{Messages.endAt}</div>
                    <div className="">{TimeUtils.toDateTime(end)}</div>
                </div>
            </div>
            <div className="p-4 border flex flex-col gap-3">
                <h5 className="">{Messages.createdBy}</h5>
                <UserAvatarName user={createByAdmin as any} />
            </div>
            <div className="p-4 border flex flex-col gap-3">
                <div className="flex-center-y justify-between">
                    <h5 className="">{Messages.branchWarehouse}</h5>
                    {isShowEdit && (
                        <Button
                            iconName="edit"
                            variant="trans"
                            className="rounded-2"
                            size="x-small"
                            onClick={() => {
                                setOpenUpdateDrawer({
                                    open: true,
                                    type: PromotionUpdateDrawerType.BRANCH,
                                });
                            }}
                        />
                    )}
                </div>
                <BranchListDataButton data={branch} />
            </div>
        </div>
    );
};

export default PromotionDetailSideView;
