// react
import React from "react";

// third-party
import { Route, Routes } from "react-router-dom";
import AuthLogin from "./auth/AuthLogin";
// import AuthLogin from "./auth/AuthLogin";
// import AuthOTP from "./auth/AuthOTP";
import Path from "./Path";

// application

// pages

export default function AuthLayout() {
    return (
        <React.Fragment>
            <Routes>
                <Route path={Path.AUTH} element={<AuthLogin />} />
                <Route path={Path.AUTH_LOGIN} element={<AuthLogin />} />
                {/* <Route exact path={Path.AUTH_OTP_AUTH} component={AuthOTP} /> */}
                <Route path="/" element={<AuthLogin />} />
            </Routes>
        </React.Fragment>
    );
}
