import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingAPI from "../../../api/queries/booking";
import { BookingDetailContext } from "../../../context/booking";
import { IBooking } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import CustomerInfoBar from "../../customer/share/CustomerInfoBar";
import GuestInfoBar from "../../customer/share/GuestInfoBar";
import BookingDetailActivity from "./BookingDetailActivity";
import BookingDetailDoctor from "./BookingDetailDoctor";
import BookingDetailGeneral from "./BookingDetailGeneral";
import BookingDetailHeader from "./BookingDetailHeader";
import BookingDetailNote from "./BookingDetailNote";
import BookingDetailService from "./BookingDetailService";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <BookingDetailGeneral />,
    },
    {
        id: "doctor",
        label: Messages.doctor,
        component: <BookingDetailDoctor />,
    },
    {
        id: "service",
        label: Messages.service,
        component: <BookingDetailService />,
    },
    {
        id: "note",
        label: Messages.note,
        component: <BookingDetailNote />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <BookingDetailActivity />,
    },
];

const BookingDetail = () => {
    const { bookingId } = useParams<any>();
    const [booking, setBooking] = useState<IBooking>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadBookingDetail();
    }, []);

    const loadBookingDetail = () => {
        Progress.show(
            {
                method: BookingAPI.detail,
                params: [bookingId],
            },
            setBooking as any
        );
    };

    if (!booking) {
        return <div />;
    }

    return (
        <BookingDetailContext.Provider
            value={{ booking, setBooking, loadBookingDetail }}
        >
            <div className="flex-column-container bg-white">
                <BookingDetailHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            {booking?.customer && (
                                <CustomerInfoBar
                                    customer={booking?.customer}
                                    onUpdate={() => loadBookingDetail()}
                                />
                            )}
                            {!booking?.customer && (
                                <GuestInfoBar guest={booking?.contact} />
                            )}
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </BookingDetailContext.Provider>
    );
};

export default BookingDetail;
