import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    Select,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { find, join, map, reduce } from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import Messages from "../../languages/Messages";
import { getFullName } from "../../utils/Utils";
import { exportToCSV } from "../../utils/file";
import UserSelect from "../user/share/UserSelect";
import ReportFooter from "./ReportFooter";
import { CUSTOMER_SOURCES } from "../../constant/customer";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    source: [],
    timeRange: null,
};

const ReportCustomerSource = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = (values: any) => {
        const payload = {
            source: values.source,
            from: values?.timeRange?.[0]?.valueOf(),
            to: values?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.customerSource, params: [payload] },
            loadData as any
        );
    };

    const loadData = (dataList: any[]) => {
        const exportData: any = [];
        const firstDayMonth = moment(formValues.timestamp);
        const lastDayMonth = moment(formValues.timestamp);
        const dateRangeText = join(
            [
                firstDayMonth.format("DD/MM/YYYY"),
                lastDayMonth.format("DD/MM/YYYY"),
            ],
            " - "
        );
        for (let index = 0; index < dataList.length; index += 1) {
            const dataItem = dataList[index];
            const customerSource = find(
                CUSTOMER_SOURCES,
                (item) => item.id === dataItem.source
            );

            exportData.push({
                "Report Date": dateRangeText,
                "Source of customer": Messages[customerSource?.label as any],
                "Qty. of customer": dataItem.customerQty,
                "Total Sales Amount": dataItem.totalSaleAmount,
            });
        }

        const totalCustomerServed = reduce(
            dataList,
            (sum, item) => sum + item.customerQty,
            0
        );
        const totalSaleAmount = reduce(
            dataList,
            (sum, item) => sum + item.totalSaleAmount,
            0
        );

        exportData.push({
            "Report Date": "",
            "Source of customer": "",
            "Qty. of customer": totalCustomerServed,
            "Total Sales Amount": totalSaleAmount,
        });

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Customer Source Report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <Select
                    className={classNameInput}
                    value={formValues?.source}
                    onChange={(value) =>
                        exportForm.setFieldValue("source", value)
                    }
                    multiple
                    dataSource={CUSTOMER_SOURCES}
                    getLabel={(item) => Messages[item.label]}
                    label={Messages.customerSource}
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: "Report Date",
            dataIndex: "Report Date",
        },
        {
            title: "Source of customer",
            dataIndex: "Source of customer",
        },
        {
            title: "Qty. of customer",
            dataIndex: "Qty. of customer",
        },
        {
            title: "Total Sales Amount",
            dataIndex: "Total Sales Amount",
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                columns={columns}
                dataSource={dataTable}
                className=""
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.customerSourceReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={
                    !formValues?.source?.length ||
                    !formValues?.timeRange?.length
                }
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportCustomerSource;
