import classNames from "classnames";
import {
    Button,
    Checkbox,
    DateInput,
    Header,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import { useDetailDoctorOrderInCreateTreatmentForAdminLazyQuery } from "../../../api/hooks";
import TreatmentAPI from "../../../api/queries/treatment";
import AppLink from "../../../common/AppLink";
import PermissibleRender from "../../../common/PermissibleComponent";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import TimeSlotSelect from "../../../common/TimeSlotSelect";
import { PERMISSION } from "../../../constant/user";
import { TreatmentCreateContext } from "../../../context/treatment";
import { TreatmentSchema } from "../../../formschema/treatment";
import { mapTreatmentToServer } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import BranchSolidSelect from "../../branch/share/BranchSolidSelect";
import { CustomerSolidItem } from "../../customer/share/CustomerSolidSelect";
import Path from "../../Path";
import TreatmentDoctorSelect from "./TreatmentCreateDoctor";
import TreatmentCreateProduct from "./TreatmentCreateProduct";

const TreatmentCreate = () => {
    const { orderId } = useParams<any>();
    const [getDoctorOrder] =
        useDetailDoctorOrderInCreateTreatmentForAdminLazyQuery();

    const navigate = useNavigate();

    const defaultWorkingSchedule = useMemo(() => {
        return map([{ start: 28800, end: 79200 }], (item) => ({
            start: item?.start / 3600,
            end: item?.end / 3600,
        }));
    }, []);

    useEffect(() => {
        loadDoctorOrderData();
    }, []);

    const loadDoctorOrderData = () => {
        Progress.show(
            {
                method: getDoctorOrder,
                params: [
                    {
                        variables: {
                            id: orderId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {
                const doctorOrder = res?.data?.data?.data;
                const { booking, doctor, services } = doctorOrder || {};
                treatmentForm.setFieldValue("doctorOrder", doctorOrder);
                treatmentForm.setFieldValue("doctor", doctor);
                if (booking?.branch) {
                    treatmentForm.setFieldValue("branch", booking?.branch);
                }
                if (booking?.startDate) {
                    const date = moment(booking?.startDate);
                    treatmentForm.setFieldValue("date", date);
                }
                if (services?.length && services?.length === 1) {
                    treatmentForm.setFieldValue("products", [
                        {
                            ...services?.[0]?.product,
                            quantity: 1,
                        },
                    ]);
                }
            }
        );
    };
    const treatmentForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: TreatmentSchema,
        onSubmit: (values: any) => {
            const input = mapTreatmentToServer(values);
            onCreateTreatment(input);
        },
    });
    const {
        values: formValues,
        errors: formErrors,
        setFieldValue,
    } = treatmentForm || {};
    const { noDoctor } = formValues || {};

    useEffect(() => {
        forEach(Object.keys(treatmentForm.errors), (key) =>
            Notifications.showError(`${treatmentForm.errors[key]}`)
        );
    }, [treatmentForm.errors]);

    const onCreateTreatment = (payload: any) => {
        Progress.show(
            {
                method: TreatmentAPI.create,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: res?.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const classNameInput = "mt-4";

    const renderDoctorOrder = () => {
        const {
            doctorOrderNo,
            createdAt,
            id: doctorOrderId,
        } = formValues?.doctorOrder ?? {};
        return (
            <div>
                <label>{Messages.doctorOrder}</label>
                <div className="w-100 bg-primary d-flex">
                    <div className="p-3 flex-column">
                        <div className="flex-center-y text-white">
                            <span className="font-semibold">ID:</span>
                            <AppLink
                                to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                                    orderId: doctorOrderId || "",
                                })}
                                target="_blank"
                            >
                                <span className="block text-white font-weight-bold ml-2">
                                    {doctorOrderNo}
                                </span>
                            </AppLink>
                        </div>

                        <span className="block text-white text-sm opacity-75">{`${
                            Messages.createdAt
                        }: ${TimeUtils.toDateTime(createdAt)}`}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <TreatmentCreateContext.Provider value={{ treatmentForm }}>
            <div className="flex-column-container bg-white w-100">
                <Header
                    showCancel={false}
                    title={Messages.newTreatment}
                    className="sticky top-0 index-100"
                    customRight={() => (
                        <>
                            <Button
                                size="small"
                                className="ml-2"
                                variant="outline"
                                onClick={() => navigate(-1)}
                            >
                                {Messages.back}
                            </Button>
                            <PermissibleRender
                                permission={PERMISSION.TREATMENT.CREATE}
                            >
                                <Button
                                    size="small"
                                    className="ml-2"
                                    onClick={() => treatmentForm.handleSubmit()}
                                >
                                    {Messages.save}
                                </Button>
                            </PermissibleRender>
                        </>
                    )}
                />
                <div className="p-4">
                    {renderDoctorOrder()}
                    <BranchSolidSelect
                        value={formValues?.branch}
                        onChange={(value) =>
                            treatmentForm?.setFieldValue("branch", value)
                        }
                        error={treatmentForm?.errors?.branch}
                        className={classNameInput}
                    />
                    <TreatmentCreateProduct />
                    <DateInput
                        label={Messages.dateOfTreatment}
                        onChange={(value) => {
                            treatmentForm.setFieldValue("date", value);
                        }}
                        value={
                            formValues?.date
                                ? moment(formValues?.date)
                                : (null as any)
                        }
                        format="DD/MM/YYYY"
                        error={formErrors?.date as any}
                        className={classNameInput}
                    />

                    {formValues?.doctorOrder?.customer && (
                        <div className={classNameInput}>
                            <label className="text-label">
                                <span>{Messages.customer}</span>
                            </label>
                            <CustomerSolidItem
                                customer={
                                    formValues?.doctorOrder?.customer as any
                                }
                            />
                        </div>
                    )}
                    <TreatmentDoctorSelect
                        className={`mt-3 ${classNames({
                            "d-none": noDoctor,
                        })}`}
                        branch={formValues?.branch}
                        date={formValues?.date}
                        form={treatmentForm}
                    />
                    <div className="mt-3">
                        <Checkbox
                            label={Messages.thisTreatmentDoesNotRequireDoctor}
                            checked={formValues?.noDoctor}
                            onChange={() =>
                                setFieldValue("noDoctor", !noDoctor)
                            }
                        />
                        {noDoctor && (
                            <TimeSlotSelect
                                label={Messages.estTimeOfTreatment}
                                workingSchedule={defaultWorkingSchedule}
                                occupiedSchedule={[]}
                                className="mt-3"
                                value={formValues?.timeSlot}
                                onChange={(value) =>
                                    setFieldValue("timeSlot", value)
                                }
                                multiple
                            />
                        )}
                    </div>

                    <div className={classNameInput}>
                        <RichTextTiny
                            label={Messages.remark}
                            value={treatmentForm?.values?.remark ?? ""}
                            onChange={(value: any) =>
                                treatmentForm.setFieldValue(
                                    `remark`,
                                    value.toString("html")
                                )
                            }
                            placeholder={Messages.remark}
                            error={treatmentForm?.errors?.remark}
                        />
                    </div>
                </div>
            </div>
        </TreatmentCreateContext.Provider>
    );
};

export default TreatmentCreate;
