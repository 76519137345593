import { gql } from "@apollo/client";
import {
    F_MATERIAL_ADJUST_REQUEST,
    F_MATERIAL_ADJUST_REQUESTS,
    F_MATERIAL_INVENTORIES,
    F_MATERIAL_INVENTORY_HISTORIES,
} from "../fragments/material-inventory";
import API from "../API";
import { reduce } from "lodash";

const requestAdjustConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? {};
        const totalStock = reduce(
            detail?.materials,
            (sum, item) => sum + item.stock,
            0
        );
        const totalAdjustment = reduce(
            detail?.materials,
            (sum, item) => sum + item.adjustment,
            0
        );
        const differentRate =
            ((totalAdjustment - totalStock) * 100) / totalStock;
        return { ...detail, totalStock, totalAdjustment, differentRate };
    },
};

export const listMaterialInventory = gql`
    ${F_MATERIAL_INVENTORIES}
    query listMaterialInventory($payload: SearchMaterialWithStockRequest!) {
        data: listMaterialWithStockForAdmin(payload: $payload) {
            ...F_MATERIAL_INVENTORIES
        }
    }
`;

export const listMaterialInventoryHistory = gql`
    ${F_MATERIAL_INVENTORY_HISTORIES}
    query listMaterialInventoryHistory(
        $material: String!
        $paginate: ListAdminMaterialStockHistoryRequest!
    ) {
        data: listMaterialStockHistoryForAdmin(
            material: $material
            paginate: $paginate
        ) {
            ...F_MATERIAL_INVENTORY_HISTORIES
        }
    }
`;

export const listMaterialStockAdjust = gql`
    ${F_MATERIAL_ADJUST_REQUESTS}
    query listMaterialStockAdjust(
        $paginate: ListAdminMaterialStockAdjustmentRequest!
    ) {
        data: listMaterialStockAdjustmentForAdmin(paginate: $paginate) {
            ...F_MATERIAL_ADJUST_REQUESTS
        }
    }
`;

export const listMaterialStockAdjustSummary = gql`
    query summaryMaterialStockAdjustmentForAdmin {
        data: summaryMaterialStockAdjustmentForAdmin {
            data {
                status
                count
            }
        }
    }
`;

export const detailMaterialStockAdjust = gql`
    ${F_MATERIAL_ADJUST_REQUEST}
    query detailMaterialStockAdjustmentForAdmin($id: String!) {
        data: detailMaterialStockAdjustmentForAdmin(id: $id) {
            data {
                ...F_MATERIAL_ADJUST_REQUEST
            }
        }
    }
`;

export const createMaterialStockAdjustment = gql`
    mutation createMaterialStockAdjustment(
        $payload: CreateAdminMaterialStockAdjustmentRequest!
    ) {
        data: createMaterialStockAdjustmentForAdmin(payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const approveMaterialStockAdjustment = gql`
    ${F_MATERIAL_ADJUST_REQUEST}
    mutation approveMaterialStockAdjustment($id: String!, $remark: String!) {
        data: approveMaterialStockAdjustmentForAdmin(id: $id, remark: $remark) {
            data {
                ...F_MATERIAL_ADJUST_REQUEST
            }
        }
    }
`;

export const cancelMaterialStockAdjustment = gql`
    ${F_MATERIAL_ADJUST_REQUEST}
    mutation cancelMaterialStockAdjustmentForAdmin(
        $id: String!
        $remark: String!
    ) {
        data: cancelMaterialStockAdjustmentForAdmin(id: $id, remark: $remark) {
            data {
                ...F_MATERIAL_ADJUST_REQUEST
            }
        }
    }
`;

export const checkMaterialStock = gql`
    query checkMaterialStock($payload: SearchAdminMaterialStockRequest!) {
        data: searchMaterialStockForAdmin(payload: $payload) {
            data {
                id
                hold
                quantity
            }
        }
    }
`;


const MaterialInventoryAPI = {
    checkMaterialStock: async (payload: {
        branch?: string[];
        material?: string[];
    }) =>
        API.query({
            query: checkMaterialStock,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    list: async (payload: any) =>
        API.query({
            query: listMaterialInventory,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    history: async (payload: any) =>
        API.query({
            query: listMaterialInventoryHistory,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),

    listAdjustmentRequest: async (paginate: any) =>
        API.query({
            query: listMaterialStockAdjust,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    listAdjustmentRequestSummary: async () =>
        API.query({
            query: listMaterialStockAdjustSummary,

            fetchPolicy: "no-cache",
        }),

    createAdjustmentRequest: async (payload: any) =>
        API.mutate({
            mutation: createMaterialStockAdjustment,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    approveAdjustmentRequest: async (input: any) =>
        API.withConverter(requestAdjustConverter).mutate({
            mutation: approveMaterialStockAdjustment,
            variables: input,
            fetchPolicy: "no-cache",
        }),

    cancelAdjustmentRequest: async (input: any) =>
        API.withConverter(requestAdjustConverter).mutate({
            mutation: cancelMaterialStockAdjustment,
            variables: input,
            fetchPolicy: "no-cache",
        }),

    detailAdjustmentRequest: async (id: any) =>
        API.withConverter(requestAdjustConverter).query({
            query: detailMaterialStockAdjust,
            variables: { id },
            fetchPolicy: "no-cache",
        }),
};

export default MaterialInventoryAPI;
