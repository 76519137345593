import styled from "@emotion/styled";
import classNames from "classnames";
import {
    AwesomeTableComponent,
    Badge,
    Button,
    Icon,
    Notifications,
    Progress,
} from "d-react-components";
import {
    filter,
    find,
    isEmpty,
    isString,
    map,
    reduce,
    sumBy,
    uniqBy,
} from "lodash";
import {
    Fragment,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import CurrencyFormat from "react-currency-format";
import { IOrder, OrderType } from "../../../interfaces/order";
import { IQuotation } from "../../../interfaces/quotation";
import Messages from "../../../languages/Messages";
import { groupCredits, groupVouchers } from "../../../utils/Utils";
import MapCustomProductDrawer from "./MapCustomProductDrawer";
import ProductName from "./ProductName";
import {
    DiscountType,
    SalesPersonType,
    TypeOfProduct,
    useUpdateSalesPersonOrderForAdminMutation,
} from "../../../api/hooks";
import { IProduct, ISalesPerson } from "../../../interfaces/product";
import {
    AddSalePersonModal,
    validateSalesPerson,
} from "./ProductItemSalesperson";
import Drawer from "../../../common/Drawer";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import IUser from "../../../interfaces/user";
import AllVouchersDrawer from "./AllVouchersDrawer";
import AllCreditsDrawer from "./AllCreditsDrawer";
import Tooltip from "../../../common/views/Tooltip";

export enum LayoutTable {
    NORMAL = "normal",
    PRINT = "print",
}

const isExpandableProductRow = (row: any) => {
    return row?.children?.length > 0;
};

const getTableTitle = (
    title: string,
    layout: LayoutTable,
    newLine: boolean = false
) => {
    return layout === LayoutTable.NORMAL
        ? Messages[title]
        : Messages.getBothLangsText(title, true, newLine);
};

export const RowMoney = ({
    title,
    value,
    className = "",
    isDiscount = false,
    isVAT = false,
}: {
    title: any;
    value: number | string;
    className?: string;
    isDiscount?: boolean;
    isVAT?: boolean;
}) => {
    return (
        <div className={`${className} text-sm grid grid-cols-2 py-3 border-b`}>
            <div className="col-span-1">
                <div className="flex items-center">
                    {isDiscount && isString(title)
                        ? `${Messages.voucher} (${title})`
                        : title}
                    {isVAT && (
                        <Tooltip title={Messages.vatNotIncludedInCredit} />
                    )}
                </div>
            </div>
            <div
                className={classNames(
                    { "text-green-500": isDiscount },
                    `col-span-1 text-right`
                )}
            >
                {isDiscount && ` - `}
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    suffix=" THB"
                    decimalScale={2}
                    fixedDecimalScale
                />
            </div>
        </div>
    );
};

const PriceTable = ({
    order,
    layout = LayoutTable.NORMAL,
}: {
    order: IQuotation | IOrder;
    layout?: LayoutTable;
}) => {
    const [showVoucherDrawer, setShowVoucherDrawer] = useState(false);
    const [showCreditDrawer, setShowCreditDrawer] = useState(false);
    const vouchers = useMemo(() => {
        return groupVouchers(order?.voucher ?? []);
    }, [order]);

    const credits = useMemo(() => {
        return groupCredits((order as any)?.credits ?? []);
    }, [order]);

    const { manualDiscount, subtotal: orderTotal } = order;

    const vouchersValue = useMemo(() => {
        return sumBy(vouchers, "amount");
    }, [vouchers]);

    const creditsValue = useMemo(() => {
        return sumBy(credits, "amount");
    }, [credits]);

    const orderTotalVAT = useMemo(() => {
        const nonVatProducts = filter(
            order?.products,
            (item: any) => item?.product?.isNonVAT
        );

        const orderTotal = parseFloat(order?.total as string);
        return (
            orderTotal -
            reduce(
                nonVatProducts,
                (sum, item: any) =>
                    sum +
                    (item?.product?.salePrice ?? 0) * (item.quantity ?? 0),
                0
            )
        );
    }, [JSON.stringify(order?.products), orderTotal]);

    const renderManualDiscount = () => {
        if (isEmpty(manualDiscount)) {
            return null;
        }
        return (
            <Fragment>
                {map(manualDiscount, (item, index) => {
                    const {
                        discountType,
                        discountValue,
                        product,
                        doctorOrder,
                        total,
                    } = item || {};
                    const percentageManualDiscount = discountValue;
                    return (
                        <div
                            key={index}
                            className="text-sm grid grid-cols-2 py-3 border-b"
                        >
                            <div className="col-span-1">
                                <div className="flex items-center">
                                    <div className="font-weight-bold text-sm">
                                        {Messages.manualDiscount}
                                    </div>
                                </div>
                                <div className="text-primary">
                                    {`( ${
                                        product
                                            ? `SKU:${product?.sku}`
                                            : Messages.wholeCart
                                    } )`}
                                </div>
                            </div>
                            <div
                                className={classNames(
                                    `col-span-1 text-right text-green-500`
                                )}
                            >
                                <>
                                    <CurrencyFormat
                                        className="text-success"
                                        displayType="text"
                                        thousandSeparator
                                        prefix="- ฿"
                                        value={total}
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                    {discountType ===
                                        DiscountType.Percentage && (
                                        <span className="text-success">
                                            (
                                            <CurrencyFormat
                                                displayType="text"
                                                thousandSeparator
                                                value={percentageManualDiscount}
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                            %)
                                        </span>
                                    )}
                                </>
                            </div>
                        </div>
                    );
                })}
            </Fragment>
        );
    };

    return (
        <div className="flex justify-content-end text-sm">
            <div
                className={classNames("price-table price-table-footer", {
                    "min-w-[400px]": layout === LayoutTable.NORMAL,
                    "min-w-full": layout === LayoutTable.PRINT,
                })}
            >
                <RowMoney
                    title={getTableTitle("subTotal", layout)}
                    value={order.subtotal}
                />
                {order?.fee &&
                    order.fee.map((fee, index) => (
                        <RowMoney title={fee.name} value={fee.total} />
                    ))}
                {vouchers && vouchers?.length > 0 && (
                    <RowMoney
                        title={
                            <div className="flex items-center">
                                <span
                                    className="cursor-pointer"
                                    onClick={() => setShowVoucherDrawer(true)}
                                >
                                    {Messages.voucher}{" "}
                                </span>
                                <Badge
                                    index={vouchers?.length}
                                    variant="index"
                                    size="large"
                                    color="primary"
                                    className="cursor-pointer"
                                    onClick={() => setShowVoucherDrawer(true)}
                                />
                            </div>
                        }
                        value={vouchersValue}
                        isDiscount
                    />
                )}
                {credits && credits?.length > 0 && (
                    <RowMoney
                        title={
                            <div className="flex items-center">
                                <span
                                    className="cursor-pointer"
                                    onClick={() => setShowCreditDrawer(true)}
                                >
                                    {Messages.creditVoucher}{" "}
                                </span>
                                <Badge
                                    index={credits?.length}
                                    variant="index"
                                    size="large"
                                    color="primary"
                                    className="cursor-pointer"
                                    onClick={() => setShowCreditDrawer(true)}
                                />
                            </div>
                        }
                        value={creditsValue}
                        isDiscount
                    />
                )}
                {renderManualDiscount()}
                <RowMoney
                    title={getTableTitle("taxIncluded", layout)}
                    value={Number(order.totalVat)}
                    isVAT
                />
                <RowMoney
                    title={getTableTitle("total", layout)}
                    value={Number(order?.total || 0)}
                    className="font-semibold"
                />
            </div>
            {showVoucherDrawer && (
                <AllVouchersDrawer
                    open={showVoucherDrawer}
                    onClose={() => setShowVoucherDrawer(false)}
                    vouchers={vouchers}
                />
            )}
            {showCreditDrawer && (
                <AllCreditsDrawer
                    open={showCreditDrawer}
                    onClose={() => setShowCreditDrawer(false)}
                    credits={credits}
                />
            )}
        </div>
    );
};

const addProductChildren = (row: any, layout: LayoutTable) => {
    if (row?.groups?.length > 0 || row?.premiumService?.product?.length > 0) {
        return {
            children: [
                ...(row.typeOfProduct === TypeOfProduct.GroupProduct ||
                row.typeOfProduct === TypeOfProduct.SpecialGroupService ||
                row.typeOfProduct === TypeOfProduct.BundleProduct
                    ? row?.groups ?? []
                    : []),
                ...(row.typeOfProduct === TypeOfProduct.PremiumService &&
                layout !== LayoutTable.PRINT
                    ? row?.premiumService?.product?.map((row: any) => ({
                          ...row,
                          product: row,
                      })) ?? []
                    : []),
            ].map((item) => ({
                ...item,
                isChildren: true,
            })),
        };
    }
    return {};
};

const QuotationPriceTable = ({
    order,
    onUpdated,
    type = OrderType.QUOTATION,
    layout = LayoutTable.NORMAL,
}: {
    order: IQuotation | IOrder;
    onUpdated?: () => void;
    type?: OrderType;
    layout?: LayoutTable;
}) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();

    const [updateSalesPerson] = useUpdateSalesPersonOrderForAdminMutation();

    const renderExpandableProductTable = (product: any) => {
        return <></>;
    };

    const expandableTable = {
        expandedRowRender: renderExpandableProductTable,
        rowExpandable: (record: any) => isExpandableProductRow(record),
        defaultExpandAllRows: false,
        expandedRowKeys,
        onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
    };

    const columns = useMemo(() => {
        return [
            ...(layout === LayoutTable.NORMAL
                ? [
                      {
                          title: getTableTitle("no", layout, true),
                          dataIndex: "no",
                          render: (data: any, item: any, index: any) => data,
                      },
                  ]
                : []),
            {
                title: getTableTitle("name", layout, true),
                dataIndex: "product",
                render: (data: any, item: any) => (
                    <div
                        className={classNames({
                            "ml-4":
                                item.isChildren && layout === LayoutTable.PRINT,
                        })}
                    >
                        <ProductName
                            item={{
                                ...data,
                                doctorOrder: item?.doctorOrder,
                                isGift: item?.isGift,
                            }}
                            addOneAfterName={() => renderSubName(item, false)}
                        />
                    </div>
                ),
            },
            {
                title: getTableTitle("unitPrice", layout, true),
                dataIndex: "salePrice",
                align: "right" as const,
                width: layout === LayoutTable.PRINT ? 200 : 150,
                render: (data: any, item: any) => {
                    return (
                        <CurrencyFormat
                            value={
                                !item.isChildren
                                    ? data
                                    : item.quantity
                                    ? data / item.quantity
                                    : ""
                            }
                            thousandSeparator
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    );
                },
            },
            {
                title: getTableTitle("quantity", layout, true),
                dataIndex: "quantity",
                align: "right" as const,
                width: 150,
            },
            {
                title: getTableTitle("price", layout, true),
                dataIndex: "salePrice",
                align: "right" as const,
                width: 150,
                render: (data: any, item: any) => (
                    <CurrencyFormat
                        value={
                            item.isChildren
                                ? data
                                : item.quantity
                                ? data * item.quantity
                                : ""
                        }
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                ),
            },
            ...(type === OrderType.ORDER
                ? [
                      {
                          title: Messages.salesPersons,
                          dataIndex: "salesPerson",
                          align: "right" as const,
                          width: 100,
                          render: (data: any, item: any) =>
                              item.isChildren ? (
                                  ""
                              ) : (
                                  <span
                                      className="cursor-pointer flex items-center justify-end"
                                      onClick={() => setSelectedProduct(item)}
                                  >
                                      {Messages.view}{" "}
                                      <Badge
                                          index={data?.length}
                                          variant="index"
                                          size="large"
                                          color="primary"
                                      />
                                  </span>
                              ),
                      },
                  ]
                : []),
        ];
    }, []);

    const productSource = useMemo(() => {
        const products =
            order?.products?.map((product: any) => ({
                ...product,
                ...addProductChildren(product, layout),
                id: product?.product?.id,
                isGift: product?.isGift,
            })) ?? [];
        const manualProducts =
            order?.manualProduct
                ?.map((product: any) => ({
                    ...product,
                    id: product.id,
                    isCustomProduct: true,
                    product: {
                        name: product.name,
                        sku: product.sku,
                    },
                    ...addProductChildren(product, layout),
                }))
                .filter((item) => {
                    if (item.id) {
                        return !find(
                            products,
                            (product) => item.id === product.manualProduct
                        );
                    }
                    return true;
                }) ?? [];
        const alls = [...products, ...manualProducts];
        const allWithNos = map(alls, (item, index) => {
            if (item?.children?.length > 0) {
                const mappedChild = map(item.children || [], (i, subIndex) => ({
                    ...i,
                    no: `${index + 1}.${subIndex + 1}`,
                }));
                return { ...item, children: mappedChild, no: `${index + 1}` };
            }
            return { ...item, no: `${index + 1}` };
        });
        return allWithNos;
    }, [order]);

    const isCustomProduct = (product: any) => {
        return product.isCustomProduct || !!product.manualProduct;
    };

    const renderSubName = useCallback(
        (product: any, hideCustomProInfo: boolean) => {
            const subView: any[] = [];
            if (hideCustomProInfo) {
                return subView;
            }
            if (isCustomProduct(product)) {
                subView.push(
                    <MapCustomProductDrawer
                        product={product}
                        isRemap={!!product.manualProduct}
                        onUpdated={onUpdated}
                    />
                );
            }

            // if (!isEmpty(manual_product_id) && !isEmpty(manualProducts)) {
            //     const dummyPro = manualProducts.find(
            //         (manualItem: any) => manualItem.id === manual_product_id
            //     );

            //     subView.push(
            //         <Tooltip
            //             title={renderMappedProductInfo(dummyPro)}
            //             overlayClassName="containerTooltip"
            //         >
            //             <span className="cursor-pointer px-1 text-tiny ml-1 relative top-[1px] bg-gray-600 text-white">
            //                 {t("mapped")}
            //             </span>
            //         </Tooltip>
            //     );

            //     if (now() < expired_manual) {
            //         subView.push(
            //             <MapDummyProduct dummyProduct={dummyPro} isRemap />
            //         );
            //     }
            // }

            // const isHoldProduct = hold.find(
            //     (item: any) => item.product_id === product?.product?.id
            // );
            // if (isHoldProduct) {
            //     subView.push(
            //         <HoldStockProduct product={product} orderId={order?.id} />
            //     );
            // }

            return subView;
        },
        [productSource]
    );

    const updateSalesPersonForProduct = (salesPerson: any) => {
        const payload = {
            id: order?.id,
            salesPerson: [
                {
                    id: selectedProduct?.id,
                    salesPerson: salesPerson?.map((item: any) => ({
                        salesPerson: item?.salesPerson?.id,
                        type: item?.type,
                        isPrimary: item?.isPrimary,
                    })),
                },
            ],
        };
        const body = {
            variables: {
                payload,
            },
        };
        Progress.show(
            {
                method: updateSalesPerson,
                params: [body],
            },
            (resp: any) => {
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
                setSelectedProduct(undefined);
                onUpdated && onUpdated();
            }
        );
    };

    useEffect(() => {
        if (layout === LayoutTable.PRINT) {
            setExpandedRowKeys(productSource?.map((item) => item?.id) ?? []);
        }
    }, [productSource]);

    return (
        <StyledTable layout={layout}>
            <AwesomeTableComponent
                tableLayout="auto"
                dataSource={productSource}
                columns={columns}
                expandable={expandableTable}
                pagination={false}
                rowKey={(item) => item.id}
                bordered={false}
            />
            {order && <PriceTable order={order} layout={layout} />}
            {!isEmpty(selectedProduct) && (
                <SalesPersonDrawer
                    product={selectedProduct}
                    open={!isEmpty(selectedProduct)}
                    onClose={() => setSelectedProduct(undefined)}
                    primarySalesPerson={order.salesPerson as IUser}
                    onSave={(salesPerson) => {
                        updateSalesPersonForProduct(salesPerson);
                    }}
                />
            )}
        </StyledTable>
    );
};

const SalesPersonDrawer = ({
    open,
    onClose,
    onSave,
    product,
    primarySalesPerson,
}: {
    open: boolean;
    onClose: any;
    onSave: (values: any) => void;
    product: IProduct;
    primarySalesPerson: IUser;
}) => {
    const [selectedSalespersons, setSelectedSalespersons] = useState(
        product?.salesPerson ?? []
    );
    const [showAddSaleModal, setShowAddSaleModal] = useState(false);
    const handleSubmit = () => {
        onSave(selectedSalespersons);
    };
    const onSaveSalePerson = (values: ISalesPerson) => {
        const isValid = validateSalesPerson(
            { ...product, salesPerson: selectedSalespersons },
            values,
            primarySalesPerson
        );
        if (!isValid) return;

        setShowAddSaleModal(false);
        setSelectedSalespersons([...selectedSalespersons, values]);
    };
    return (
        <Drawer
            open={open}
            title={Messages.salesPersons}
            onClose={() => onClose()}
            onSave={handleSubmit}
            destroyOnClose
            size="auto"
            width="500px"
        >
            <div className="p-4">
                {selectedSalespersons?.map((item) => {
                    return (
                        <div
                            key={item?.salesPerson?.id}
                            className="border-b pb-2 mt-2"
                        >
                            <div className="flex items-center">
                                <UserAvatarName
                                    user={item?.salesPerson}
                                    classNameText="text-sm"
                                    className="flex-1"
                                />
                                {!item.isPrimary && (
                                    <Icon
                                        className="cursor-pointer text-red-500"
                                        name="remove_circle"
                                        onClick={() => {
                                            //remove this item from list
                                            const salesPersons =
                                                selectedSalespersons?.filter(
                                                    (salePerson) =>
                                                        salePerson?.salesPerson
                                                            ?.id !==
                                                        item?.salesPerson?.id
                                                );
                                            setSelectedSalespersons(
                                                salesPersons
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <div className="text-gray-500 bg-gray-100 mt-2 text-sm p-2">
                                {item.isPrimary && Messages.primarySalesPerson}
                                {!item.isPrimary &&
                                    (item?.type === SalesPersonType.Doctor
                                        ? Messages.cosalePersonByDoctor
                                        : Messages.cosalePersonBySales)}
                            </div>
                        </div>
                    );
                })}
                <div className="mt-3">
                    <Button
                        variant="outline"
                        color="primary"
                        className="w-100 mt-2 text-sm"
                        size="small"
                        onClick={() => setShowAddSaleModal(true)}
                    >
                        {Messages.addCoSales}
                    </Button>
                </div>
            </div>
            {showAddSaleModal && (
                <AddSalePersonModal
                    open={showAddSaleModal}
                    onClose={() => setShowAddSaleModal(false)}
                    onSave={(values: ISalesPerson) => {
                        onSaveSalePerson(values);
                    }}
                />
            )}
        </Drawer>
    );
};

const StyledTable: any = styled.div`
    overflow: hidden;
    .ant-table-expanded-row-level-1 {
        td {
            padding: 0 !important;
        }
    }
    ${(props: any) =>
        props.layout === LayoutTable.PRINT &&
        `
        width: 1000px !important;
        .ant-table {
            margin-left: -40px;
            * {
                font-size: 1rem !important;
            }
        }
        .price-table-footer {
            * {
                font-size: 1rem !important;
            }
        }
        .ant-table-row-expand-icon-cell {
            width: 0 !important;
            overflow: hidden;
            padding: 0 !important;
            .ant-table-row-expand-icon {
                display: none !important;
            }
    }`}
`;

export default QuotationPriceTable;
