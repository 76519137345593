import { DateInput } from "d-react-components";
import moment from "moment";
import React, { useState } from "react";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";

const ChangeExpiryDateDrawer = ({
    open,
    onClose,
    onSave,
    defaultDate,
}: any) => {
    const [expireDate, setExpireDate] = useState<any>(moment(defaultDate));

    return (
        <Drawer
            open={open}
            onClose={onClose}
            onSave={() => onSave(expireDate)}
            title={Messages.changeExpiryDate}
        >
            <div className="p-3">
                <DateInput
                    showTime
                    value={expireDate}
                    onChange={(date) => setExpireDate(date)}
                    format="DD/MM/YYYY HH:mm"
                />
            </div>
        </Drawer>
    );
};

export default ChangeExpiryDateDrawer;
