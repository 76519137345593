import { every } from "lodash";
import React, { useContext, useMemo } from "react";
import { AppStateContext } from "../context/app";
import { PERMISSION } from "../constant/user";

interface PermissibleRenderProps {
    permission?: string;
    permissions?: string[];
    children?: React.ReactNode;
}

export const isGranted = (permissions: string[]) => {
    const { me } = useContext(AppStateContext);
    const userPermissions = me?.role?.permissions ?? [];
    if (
        !me?.jobCostFeature &&
        [
            PERMISSION.REPORT.DOCTOR_DETAIL,
            PERMISSION.REPORT.DOCTOR_SUMMARY,
            PERMISSION.REPORT.NURSE_DETAIL,
            PERMISSION.REPORT.NURSE_SUMMARY,
            PERMISSION.REPORT.THERAPIST_DETAIL,
            PERMISSION.REPORT.THERAPIST_SUMMARY,
        ].includes(permissions[0])
    ) {
        return null;
    }
    return (
        me?.role?.isAdmin ||
        (permissions?.length &&
            every(permissions, (item) => userPermissions.includes(item)))
    );
};

export const PermissibleRender: React.FC<PermissibleRenderProps> = ({
    children,
    permission,
    permissions,
}) => {
    const { me } = useContext(AppStateContext);
    const { role = {} } = me || {};
    const userPermissions = role?.permissions ?? [];
    const isGranted = useMemo(() => {
        return (
            role?.isAdmin ||
            (permission && userPermissions.includes(permission)) ||
            (permissions?.length &&
                every(permissions, (item) => userPermissions.includes(item)))
        );
    }, [permission, permissions, userPermissions, role]);

    return isGranted ? <>{children}</> : null;
};

export default PermissibleRender;
