import { TimeUtils } from "d-react-components";
import { find } from "lodash";
import React, { Fragment, useContext, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { PaymentMethod, PaymentStatus } from "../../../../api/hooks";
import UserAvatarName from "../../../../common/avatar/UserAvatarName";
import PermissibleRender from "../../../../common/PermissibleComponent";
import { PERMISSION } from "../../../../constant/user";
import { PaymentContext } from "../../../../context/payment";
import { IPayment } from "../../../../interfaces/payment";
import { IPaymentMethod } from "../../../../interfaces/payment-method";
import Messages from "../../../../languages/Messages";
import ChangePaymentMethodDrawer from "./ChangePaymentMethodDrawer";
import PaymentConfirmButton from "./PaymentConfirmButton";
import UploadPaymentInfoView from "./UploadPaymentInfo";

const PaymentInfoItem = ({
    payment,
    index,
    onChangePaymentInfo,
}: {
    payment: IPayment;
    index: number;
    onChangePaymentInfo: any;
}) => {
    const {
        total,
        paymentNo,
        paymentMethod,
        status,
        confirmation,
        paymentCardDevice,
        creditCard,
    } = payment;

    const { paymentMethodsList, ref: order } = useContext(PaymentContext);
    const [showChangeMethod, setShowChangeMethod] = useState(false);
    const isReject = status === PaymentStatus.Rejected;
    const isApprove = status === PaymentStatus.Confirmed;

    const isShowChangePaymentMethod = useMemo(() => {
        if (isApprove || isReject) {
            return false;
        }
        // if (
        //     order?.status &&
        //     order?.status !== ORDER_STATUS.PENDING_PAYMENT &&
        //     order?.status !== ORDER_STATUS.PENDING_CONFIRMATION &&
        //     order?.status !== ORDER_STATUS.PARTIALLY_PAID
        // ) {
        //     return false;
        // }
        return true;
    }, [order]);

    const onClickChangeMethod = () => {
        setShowChangeMethod(true);
    };

    const onMethodChanged = () => {
        onChangePaymentInfo();
        setShowChangeMethod(false);
    };

    const paymentMethodLabel = useMemo(() => {
        const selected = find(paymentMethodsList, {
            paymentConfigurationType: paymentMethod,
        });
        return selected ? (selected as IPaymentMethod)?.title : "N/A";
    }, [paymentMethod, paymentMethodsList]);

    return (
        <div className="border-dashed p-3 my-3 w-100">
            <div className="flex flex-col md:flex-row justify-content-between">
                <div>
                    <div className="text-primary font-weight-bold mb-3">
                        {Messages.payment} {index + 1}
                    </div>
                    {total && (
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.total}
                            </span>
                            <CurrencyFormat
                                displayType="text"
                                value={total}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </div>
                    )}
                    {paymentNo && (
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.paymentRef}
                            </span>
                            {paymentNo}
                        </div>
                    )}
                    {paymentMethod && (
                        <PermissibleRender
                            permission={PERMISSION.MATERIAL_PURCHASE.UPDATE}
                        >
                            <div className="my-1">
                                <span className="font-weight-bold mr-2">
                                    {Messages.method}
                                </span>
                                {paymentMethodLabel}
                                {isShowChangePaymentMethod && (
                                    <span
                                        className="text-primary cursor-pointer ml-3"
                                        onClick={onClickChangeMethod}
                                    >
                                        {Messages.change}
                                    </span>
                                )}
                            </div>
                        </PermissibleRender>
                    )}
                    {paymentCardDevice && (
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.posCardDevice}
                            </span>
                            {paymentCardDevice}
                        </div>
                    )}
                    {paymentMethod === PaymentMethod.CreditCardPos &&
                        creditCard && (
                            <Fragment>
                                <div className="my-1">
                                    <span className="font-weight-bold mr-2">
                                        {Messages.creditCard}
                                    </span>
                                    {creditCard?.type}
                                </div>
                                <div className="my-1">
                                    <span className="font-weight-bold mr-2">
                                        {Messages.creditCardNumber}
                                    </span>
                                    {creditCard?.number}
                                </div>
                                <div className="my-1">
                                    <span className="font-weight-bold mr-2">
                                        {Messages.expiryDate}
                                    </span>
                                    {creditCard?.expiryDate
                                        ? TimeUtils.convertMiliToDateWithFormat(
                                              creditCard?.expiryDate,
                                              "MM/YY"
                                          )
                                        : "N/A"}
                                </div>
                            </Fragment>
                        )}
                </div>
                <PaymentConfirmButton
                    payment={payment}
                    onChangePaymentInfo={onChangePaymentInfo}
                />
                {/* {paymentMethod !== PaymentMethod.Payment_2C2P && (
                    <PaymentConfirmButton
                        payment={payment}
                        onChangePaymentInfo={onChangePaymentInfo}
                    />
                )} */}
            </div>
            {showChangeMethod && (
                <ChangePaymentMethodDrawer
                    open={showChangeMethod}
                    payment={payment}
                    onClose={() => setShowChangeMethod(false)}
                    onSave={() => onMethodChanged()}
                />
            )}
            <UploadPaymentInfoView
                payment={payment}
                onChangePaymentInfo={onChangePaymentInfo}
            />
            {confirmation?.dateOfReceiving && (
                <div className=" mt-1 text-success">
                    {`${
                        Messages.actualDatePayment
                    }: ${TimeUtils.convertMiliToDateTime(
                        confirmation?.dateOfReceiving
                    )}`}
                </div>
            )}
            {isApprove && (
                <div className="text-success flex items-center mt-2">
                    <span className="mr-2">{Messages.confirmBy}</span>{" "}
                    <UserAvatarName
                        user={confirmation.createByAdmin}
                        size="xx-small"
                        placeholder={Messages.system}
                        showInfo={{ showEmployeeId: false, showOPD: false }}
                    />
                    {confirmation.at && (
                        <span className="ml-2">
                            {Messages.at}{" "}
                            {TimeUtils.toDateTime(confirmation.at)}{" "}
                            {`"${confirmation.remark}"`}
                        </span>
                    )}
                </div>
            )}
            {isReject && (
                <div className="text-success flex items-center mt-2">
                    <span className="mr-2">{Messages.rejectBy}</span>{" "}
                    <UserAvatarName
                        user={confirmation.createByAdmin}
                        size="xx-small"
                        showInfo={{ showEmployeeId: false, showOPD: false }}
                    />
                    <span className="ml-2">
                        {Messages.at} {TimeUtils.toDateTime(confirmation.at)}{" "}
                        {`"${confirmation.remark}"`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default PaymentInfoItem;
