import React, { Dispatch, useContext } from "react";
import { ICountry, IProvince } from "../interfaces/address";
import IUser from "../interfaces/user";

export interface ISiteConfig {
    breadcrumb?: any[];
    label?: string;
}

export interface IAppDrawerConfig {
    open: boolean;
    customerId?: string;
    userId?: string;
}

export interface IAppState {
    me: any;
    provinceList?: IProvince[];
    countryList?: ICountry[];
    primaryCountry?: ICountry;
    siteConfig: ISiteConfig;
    openAppDrawer?: IAppDrawerConfig;
    setOpenAppDrawer: Dispatch<IAppDrawerConfig>;
    setSiteConfig: (values: any) => any;
}

export const appStateDefault: IAppState = {
    me: {},
    provinceList: [],
    countryList: [],
    siteConfig: {} as any,
    setSiteConfig: () => {},
    setOpenAppDrawer: () => {},
};

export const AppStateContext = React.createContext(appStateDefault);

export const useMe = (): IUser => {
    return useContext(AppStateContext)?.me;
};
