import {
    IRowsKey,
    StringUtils,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useContext, useState } from "react";

import { find, reduce } from "lodash";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import {
    TRANSFER_VOUCHER_REASONS,
    TRANSFER_VOUCHER_TYPES,
} from "../../../constant/transfer-voucher";
import { TransferVoucherContext } from "../../../context/voucher";
import { ITransferVoucher } from "../../../interfaces/transfer-voucher";
import Messages from "../../../languages/Messages";

const GENERAL_KEYS: IRowsKey<ITransferVoucher>[] = [
    {
        id: "transferVoucherNo",
        label: Messages.id,
    },
    {
        id: "transferVoucherType",
        label: Messages.transferVoucherType,
        renderContent: ({ data }) => {
            const reason = find(
                TRANSFER_VOUCHER_TYPES,
                (item) => item.id === data
            );
            return Messages[reason?.label as any];
        },
    },
    {
        id: "from",
        label: Messages.transferFromCustomer,
        renderContent: ({ data }) => (
            <CustomerAvatarName size="xx-small" customer={data} />
        ),
    },
    {
        id: "to",
        label: Messages.transferToCustomer,
        renderContent: ({ data }) => (
            <CustomerAvatarName size="xx-small" customer={data} />
        ),
    },
    {
        id: "reason",
        label: Messages.reason,
        renderContent: ({ data }) => {
            const reason = find(
                TRANSFER_VOUCHER_REASONS,
                (item) => item.id === data
            );
            return Messages[reason?.label as any];
        },
    },
    {
        id: "voucher",
        label: Messages.totalCredit,
        renderContent: ({ data }) => {
            const sum = reduce(data, (sum, item) => sum + item.value, 0);
            return StringUtils.moneyThaiFormat(sum);
        },
    },
    {
        id: "remark",
        label: Messages.remark,
    },
    {
        id: "status",
        label: Messages.status,
    },

    {
        id: "createByAdmin",
        label: Messages.createdBy,
        renderContent: ({ data }) => {
            return <UserAvatarName user={data} />;
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.toDateTime(data);
        },
    },
];

const TransferVoucherDetailGeneral = () => {
    const { transferDetail } = useContext(TransferVoucherContext);
    const [openEdit, setOpenEdit] = useState(false);

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                {/* <Button
                    variant="trans"
                    onClick={() => setOpenEdit(true)}
                    className=""
                >
                    {Messages.edit}
                </Button> */}
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={transferDetail}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default TransferVoucherDetailGeneral;
