import { Avatar } from "d-react-components";
import Image from "../../../common/Image";
import { IGuest } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

interface IGuestInfoBar {
    guest: IGuest;
    className?: string;
}
const GuestInfoBar = ({ guest, className }: IGuestInfoBar) => {
    const { firstName, lastName, email, phone } = guest ?? {};

    const onClickPhone = () => {};
    const onClickEmail = () => {};

    const renderContactIcon = (iconName: string, onClick: any) => (
        <Image
            onClick={onClick}
            src={`/images/contact/${iconName}.png`}
            className="mr-2"
            style={{ width: "20px", height: "20px" }}
        />
    );

    const renderInfoRow = (title: any, content: any) => {
        return (
            <div className="d-flex align-items-start w-100 justify-content-between py-3">
                <div className="text-small w-100 font-weight-bold">{title}</div>
                <div className="w-100 text text-end">{content}</div>
            </div>
        );
    };

    return (
        <div className={`"w-100 bg-white ${className}`}>
            <div className={`flex-column flex-center py-5 border `}>
                <Avatar text={firstName} size="large" />
                <h4 className="text-primary mt-2">{`${firstName} ${lastName}`}</h4>
                <div className="d-flex mt-2">
                    {phone && renderContactIcon("phone", onClickPhone)}
                    {email && renderContactIcon("email", onClickEmail)}
                </div>
            </div>
            <div className={`flex-column flex-center border p-3 `}>
                {renderInfoRow(Messages.firstName, firstName)}
                {renderInfoRow(Messages.lastName, lastName)}
                {renderInfoRow(Messages.phone, phone)}
                {renderInfoRow(Messages.email, email)}
            </div>
        </div>
    );
};

export default GuestInfoBar;
