import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_CUSTOMER_BASIC } from "./customer";
import { F_VOUCHER_BASIC } from "./voucher";

export const F_TRANSFER_VOUCHER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_CUSTOMER_BASIC}
    ${F_VOUCHER_BASIC}
    fragment F_TRANSFER_VOUCHER on AdminTransferVoucher {
        id
        transferVoucherNo
        transferVoucherType
        status
        remark
        reason
        from {
            ...F_CUSTOMER_BASIC
        }
        voucher {
            ...F_VOUCHER_BASIC
        }
        to {
            ...F_CUSTOMER_BASIC
        }
        confirmation {
            at
            remark
            createByAdmin {
                ...F_ADMIN_USER_BASIC
            }
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        createdAt
        voucherExpiryDate {
            id
            expiryDate
        }
    }
`;

export const F_TRANSFER_VOUCHERS = gql`
    ${F_TRANSFER_VOUCHER}
    ${F_PAGINATE}
    fragment F_TRANSFER_VOUCHERS on ListAdminTransferVoucherResponse {
        data {
            ...F_TRANSFER_VOUCHER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
