import classNames from "classnames";
import { DateInput } from "d-react-components";
import { map } from "lodash";
import moment from "moment";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { BOOKING_STATUSES } from "../../../constant/booking";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";
import UserSelect from "../../user/share/UserSelect";

interface IBookingFilterForm {
    filterForm: any;
}

const BookingFilterForm = ({ filterForm }: IBookingFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <BranchSelect
                className={classNameInput}
                value={formValues?.branch}
                onChange={(value) => filterForm.setFieldValue("branch", value)}
                multiple
            />
            <InputSelectForm
                keyData="status"
                dataSource={BOOKING_STATUSES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <UserSelect
                className={classNameInput}
                value={formValues?.createdBy}
                onChange={(value) =>
                    filterForm.setFieldValue("createdBy", value)
                }
                label={Messages.createdBy}
                multiple
            />
            <DateInput
                className={classNameInput}
                isRangePicker
                label={Messages.estDateOfVisit}
                value={
                    map(formValues?.timeRange, (time) => moment(time)) as any
                }
                onChange={(value) =>
                    filterForm.setFieldValue("timeRange", value)
                }
            />
        </div>
    );
};

export default BookingFilterForm;
