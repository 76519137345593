import {
    AwesomeTableComponent,
    DialogManager,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
} from "d-react-components";
import { map } from "lodash";
import { useContext } from "react";
import BookingAPI from "../../../api/queries/booking";
import { PRODUCT_TYPE } from "../../../constant/product";
import { BookingDetailContext } from "../../../context/booking";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/share/ProductNameView";
import ProductSearchButton from "../../product/share/ProductSearchButton";

const BookingDetailService = () => {
    const { booking, setBooking } = useContext(BookingDetailContext);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (service) => <ProductNameView product={service} />,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (service) => (
                <Icon
                    className="cursor-pointer"
                    name="remove_circle_outline"
                    onClick={() => onRemoveProduct([service?.id])}
                />
            ),
        },
    ];

    const onAddProduct = (products: IProduct[]) => {
        const productIds = map(products, (item) => item.id);
        Progress.show(
            {
                method: BookingAPI.addService,
                params: [booking?.id, productIds],
            },
            (booking) => {
                Notifications.showSuccess(Messages.createSuccess);
                setBooking(booking);
            }
        );
    };

    const onRemoveProduct = (productIds: string[]) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: BookingAPI.removeService,
                        params: [booking?.id, productIds],
                    },
                    (booking) => {
                        Notifications.showSuccess(Messages.removeSuccess);
                        setBooking(booking);
                    }
                )
        );
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.service}</label>
                <ProductSearchButton
                    onSave={onAddProduct}
                    defaultValue={booking?.services ?? []}
                    filterSource={{
                        productType: [PRODUCT_TYPE.SERVICE_PRODUCT],
                    }}
                >
                    {Messages.add}
                </ProductSearchButton>
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={booking?.services ?? []}
                className="mt-3"
                pagination={false}
            />
        </div>
    );
};

export default BookingDetailService;
