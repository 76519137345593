import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find, join, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import * as Yup from "yup";
import TreatmentAPI from "../../../api/queries/treatment";
import AppLink from "../../../common/AppLink";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import { TREATMENT_STATUSES } from "../../../constant/treatment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const ProductSchema = Yup.object().shape({
    product: Yup.mixed().required(Messages.fieldRequired),
    quantity: Yup.number().required(Messages.fieldRequired),
    remark: Yup.string(),
});

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

const TreatmentSelectDrawer = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    isEdit = true,
    params = {},
}: any) => {
    const [treatmentSelected, setTreatmentSelected] =
        useState<any[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "treatmentNo",
            render: (treatmentNo, item) => (
                <AppLink
                    to={generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: item.id,
                    })}
                >
                    {treatmentNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.service,
            dataIndex: "services",
            render: (services) =>
                join(
                    map(services, (item) => item?.product?.name),
                    ", "
                ),
        },
        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor) => (
                <UserAvatarName
                    showInfo={{ showOPD: false }}
                    user={doctor?.adminUser}
                    size="xx-small"
                    variant="square"
                />
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer) => (
                <CustomerAvatarName size="xx-small" customer={customer} />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={TREATMENT_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: treatmentSelected });
        }
        return TreatmentAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            ...params,
            sort: {
                createdAt: -1,
            },
        });
    };

    const onAddTreatment = () => {
        onSave && onSave(treatmentSelected);
        onClose && onClose();
    };

    const onChangeSelectTreatment = (keys: string[], treatments: any[]) => {
        const result = map(treatments, (treatment) => {
            const { services } = treatment || {};
            const itemSelected = find(
                treatmentSelected,
                (item) => item.id === treatment.id
            );

            if (itemSelected) return itemSelected;
            return {
                ...treatment,
                quantity: services?.[0]?.quantity,
                completedQty: services?.[0]?.quantity,
            };
        });
        setTreatmentSelected(result as any);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.loadPreviousTreatment}
            onSave={onAddTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item, index) => (
                        <Button
                            key={`${item?.id}_${index}`}
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => {
                        return res?.data || [];
                    }}
                    rowSelection={{
                        selectedRowKeys: map(
                            treatmentSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectTreatment as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default TreatmentSelectDrawer;
