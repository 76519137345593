import ClassNames from "classnames";
import { Button, InputTextSearch, Select } from "d-react-components";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    PriceSetType,
    useGetProductCategoriesLazyQuery,
    useSearchCategoryServiceLazyQuery,
} from "../../../api/hooks";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import { CUSTOMER_SOURCES } from "../../../constant/customer";
import { PURCHASE_TYPES, PurchaseType } from "../../../constant/quotation";
import { AppStateContext } from "../../../context/app";
import { QuotationCreateContext } from "../../../context/quotation";
import { ICustomer } from "../../../interfaces/customer";
import { OrderType } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import AgencySelect from "../../agency/share/AgencySelect";
import BranchSelect from "../../branch/share/BranchSelect";
import UserSelect from "../../user/share/UserSelect";

const getOrderMetricPriceSet = (
    customer: ICustomer,
    primaryCountry?: any
): PriceSetType | undefined => {
    const { setting } = customer || {};
    if (setting?.isVip) {
        return PriceSetType.Vip;
    }
    if (setting?.isStaff) {
        return PriceSetType.Employee;
    }
    if (
        customer?.nationality?.id &&
        customer?.nationality?.id !== primaryCountry?.id
    ) {
        return PriceSetType.Foreigner;
    }
    return PriceSetType.Default;
};

const QuotationCreateHeader = ({ type }: { type?: OrderType }) => {
    const { primaryCountry } = useContext(AppStateContext);
    const {
        productFilters,
        setProductFilters,
        selectedCustomer,
        orderMetrics,
        setOrderMetrics,
        setProductList,
    } = useContext(QuotationCreateContext);

    const { me } = useContext(AppStateContext);
    const [textSearch, setTextSearch] = useState("");
    const [getCategoryList] = useGetProductCategoriesLazyQuery();
    const [getServiceCategoryList] = useSearchCategoryServiceLazyQuery();
    const { values, errors, setFieldValue, setValues } =
        useFormikContext<any>();
    const { customer } = values;

    const updateFilters = (key: string, value: any) => {
        setProductFilters({
            ...productFilters,
            [key]: value,
        });
    };
    const showSelectAgency = useMemo(() => {
        return values?.purchaseTypes === PurchaseType.AGENCY;
    }, [values]);
    const secondRowClass = ClassNames({
        "col-span-3": !showSelectAgency,
        "col-span-2": showSelectAgency,
    });

    useEffect(() => {
        const thisCustomer = selectedCustomer || values?.customer?.[0];
        if (thisCustomer) {
            setOrderMetrics &&
                setOrderMetrics({
                    ...orderMetrics,
                    applyPriceSet: getOrderMetricPriceSet(
                        thisCustomer,
                        primaryCountry
                    ),
                });
        }
    }, [selectedCustomer, values?.customer]);

    return (
        <div>
            <div className="grid grid-cols-6 gap-4 mb-3">
                <div className="col-span-3">
                    <BranchSelect
                        allowClear={false}
                        value={values?.store}
                        error={errors?.store}
                        onChange={(values: any) => {
                            setFieldValue("store", values);
                            setProductList([]);
                            updateFilters("branches", [values?.id]);
                        }}
                    />
                </div>
                <div className="col-span-3">
                    <Select
                        label={Messages.customerSource}
                        dataSource={CUSTOMER_SOURCES}
                        value={values?.sourceOfCustomer}
                        error={errors?.sourceOfCustomer}
                        onChange={(values) =>
                            setFieldValue("sourceOfCustomer", values)
                        }
                        getLabel={(item) => Messages[item.label]}
                    />
                </div>
                {/* <div className="col-span-3">
                    <SelectInfinityFilter
                        label={Messages.typeOfTreatment}
                        value={values?.typeOfTreatment}
                        error={errors?.typeOfTreatment}
                        onChange={(value) =>
                            setFieldValue("typeOfTreatment", value)
                        }
                        fetchFn={(params: any, paging: any) =>
                            getServiceCategoryList({
                                variables: {
                                    paginate: {
                                        search: params?.search ?? "",
                                        limit: paging.pageSize,
                                        page: paging.pageIndex,
                                        sort: "name",
                                    },
                                },
                            })
                        }
                        dataKey="data"
                        placeholder={Messages.typeOfTreatment}
                        getLabel={(item) => item?.name}
                        tagTitle={Messages.typeOfTreatment}
                    />
                </div> */}
                <div className="col-span-3">
                    <UserSelect
                        showAvatar
                        label={Messages.primarySalesPerson}
                        value={values?.primarySalesPerson}
                        error={errors?.primarySalesPerson}
                        onChange={(values) =>
                            setFieldValue("primarySalesPerson", values)
                        }
                        params={{ status: true }}
                    />
                </div>
                {type === OrderType.ORDER && (
                    <div className={secondRowClass}>
                        <UserSelect
                            showAvatar
                            label={Messages.defaultCoSalePerson}
                            value={values?.defaultCoSalePerson}
                            error={errors?.defaultCoSalePerson}
                            onChange={(values) =>
                                setFieldValue("defaultCoSalePerson", values)
                            }
                            params={{ status: true }}
                        />
                    </div>
                )}
                <div className={secondRowClass}>
                    <Select
                        allowClear={false}
                        label={Messages.purchaseTypes}
                        dataSource={PURCHASE_TYPES}
                        value={values?.purchaseTypes}
                        getLabel={(item) => Messages[item.label]}
                        error={errors?.purchaseTypes}
                        onChange={(updatePurchaseTypes) => {
                            setValues((v: any) => ({
                                ...(v || {}),
                                purchaseTypes: updatePurchaseTypes,
                                customer: null,
                            }));
                        }}
                    />
                </div>
                {showSelectAgency && (
                    <div className={secondRowClass}>
                        <AgencySelect
                            value={values?.agency}
                            error={errors?.agency}
                            onChange={(values) =>
                                setFieldValue("agency", values)
                            }
                        />
                    </div>
                )}
            </div>
            {(selectedCustomer || customer) && (
                <div className="pt-3 border-t">
                    <div className="mb-2">
                        <InputTextSearch
                            className="bg-white mb-3"
                            placeholder={Messages.search}
                            value={textSearch}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                setTextSearch(e?.currentTarget?.value)
                            }
                            onSubmit={() =>
                                updateFilters("textSearch", textSearch)
                            }
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <SelectInfinityFilter
                                value={productFilters?.categories}
                                multiple
                                onChange={(value) =>
                                    updateFilters("categories", value)
                                }
                                fetchFn={(params: any, paging: any) =>
                                    getCategoryList({
                                        variables: {
                                            paginate: {
                                                search: params?.search ?? "",
                                                limit: paging.pageSize,
                                                page: paging.pageIndex,
                                            },
                                        },
                                    })
                                }
                                dataKey="data"
                                placeholder={Messages.categories}
                                getLabel={(item) => `${item?.name}`}
                                showCount
                                tagTitle={Messages.categories}
                                className="w-[250px]"
                            />
                        </div>
                        <div className="col-span-1 flex justify-content-end">
                            <Button
                                onClick={() =>
                                    updateFilters("viewMode", "grid")
                                }
                                iconName="apps"
                                color={
                                    productFilters?.viewMode === "grid"
                                        ? "primary"
                                        : "light"
                                }
                                className="mr-2"
                            />
                            <Button
                                onClick={() =>
                                    updateFilters("viewMode", "list")
                                }
                                iconName="table_rows"
                                color={
                                    productFilters?.viewMode === "list"
                                        ? "primary"
                                        : "light"
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuotationCreateHeader;
