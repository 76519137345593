import { Switch } from "antd";
import {
    AwesomeTableComponent,
    Button,
    Header,
    IColumnsProps,
    Icon,
    InputText,
    Notifications,
    Progress,
    StringUtils,
    ViewLabelStatus,
    ViewTextError,
} from "d-react-components";
import { Formik, FormikProps, useFormikContext } from "formik";
import { filter, join, map, uniqBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import {
    TreatmentStatus,
    useCreateDoctorOrderForAdminMutation,
    useGetBookingDetailLazyQuery,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import PermissibleRender from "../../../common/PermissibleComponent";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { TREATMENT_STATUSES } from "../../../constant/treatment";
import { PERMISSION } from "../../../constant/user";
import Messages from "../../../languages/Messages";
import { CustomerSolidItem } from "../../customer/share/CustomerSolidSelect";
import Path from "../../Path";
import ProductName from "../../quotation/share/ProductName";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import UserSelectedView from "../../user/share/UserSelectedView";
import ProductSelectDrawer from "./ProductSelectDrawer";
import TreatmentSelectDrawer from "./TreatmentSelectDrawer";

export const DoctorOrderSchema = Yup.object().shape({
    doctorSubject: Yup.string().required(Messages.fieldRequired),
    doctorOpinion: Yup.string().required(Messages.fieldRequired),
    complicationTreatments: Yup.mixed().when(
        "isComplication",
        (isComplication: boolean, yup: any) => {
            if (isComplication) {
                return Yup.array().min(1).required(Messages.fieldRequired);
            }
            return Yup.object().nullable();
        }
    ),
});

const mapDoctorOrderToServer = (values: any) => {
    const {
        bookingId,
        doctorSubject,
        doctorOpinion,
        services,
        isComplication,
        complicationTreatments,
    } = values || {};

    const servicesPayload = isComplication
        ? map(complicationTreatments, (treatment) => ({
              product: treatment?.services?.[0]?.product?.id,
              quantity: treatment.quantity,
              complicationTreatment: treatment.id,
          }))
        : map(services, (service) => ({
              product: service?.product?.id,
              quantity: service.quantity,
          }));

    const complicationTreatmentPayload = map(
        complicationTreatments,
        (i) => i?.id
    );

    return {
        bookingId: bookingId as string,
        doctorSubject: doctorSubject || "",
        doctorOpinion: doctorOpinion || "",
        services: servicesPayload,
        complicationTreatments: complicationTreatmentPayload,
    };
};

interface DoctorOrderFormProps {
    doctorSubject?: string;
    doctorOpinion?: string;
    isComplication?: string;
}

const DoctorOrderCreate = () => {
    const navigate = useNavigate();
    const { bookingId } = useParams();
    const tableRef = useRef<any>(null);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [createDoctorOrder] = useCreateDoctorOrderForAdminMutation();
    const [getBookingDetail, { called, loading, data }] =
        useGetBookingDetailLazyQuery();
    const [services, setServices] = useState<any[]>([]);

    const handleAddProduct = (newServices: any) => {
        setServices(
            newServices.map((newService: any) => ({
                ...newService,
                quantity: newService.quantity,
                id: newService?.product?.id,
            }))
        );
        setShowAddProduct(false);
    };

    const removeProduct = (productId: string) => {
        setServices(
            services.filter((service) => service?.product?.id !== productId)
        );
    };

    const onChangeQuantity = (quantity: number, productId: string) => {
        setServices(
            services.map((service) =>
                service?.product?.id === productId
                    ? { ...service, quantity }
                    : service
            )
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "id",
            // width: 300,
            ellipsis: true,
            render: (product: any, item: any) => {
                return <ProductName item={item?.product} />;
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            width: 200,
            render: (quantity: any, item: any) => {
                return (
                    <QuantityInputField
                        quantity={quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity(quantity, item?.product?.id)
                        }
                    />
                );
            },
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
            render: (quantity: any, item: any) => {
                return item?.product?.unit;
            },
        },
        {
            title: Messages.salePrice,
            dataIndex: "unit",
            render: (quantity: any, item: any) => {
                return StringUtils.moneyThaiFormat(item?.product?.salePrice);
            },
        },
        {
            title: Messages.action,
            dataIndex: "id",
            width: 100,
            render: (product: any, item: any) => {
                return (
                    <Icon
                        onClick={() => removeProduct(item?.product?.id)}
                        name="remove_circle_outline"
                        className="text-primary cursor-pointer"
                    />
                );
            },
        },
    ];

    const onCreateDoctorOrder = (payload: any) => {
        Progress.show(
            {
                method: createDoctorOrder,
                params: [
                    {
                        variables: {
                            payload,
                        },
                    },
                ],
            },
            (resp: any) => {
                const doctorOrderId = resp?.data?.data?.data?.id;
                navigate(
                    generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: doctorOrderId,
                    })
                );
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    useEffect(() => {
        getBookingDetail({
            variables: {
                id: bookingId as string,
            },
        });
    }, []);

    const booking = data?.data?.data;

    if (called && !loading && !booking) {
        return <div className="p-4">{Messages.bookingNotFound}</div>;
    }

    return (
        <div>
            <Formik
                initialValues={{}}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={DoctorOrderSchema}
                onSubmit={(values) => {
                    const payload = mapDoctorOrderToServer({
                        ...values,
                        bookingId: booking?.id,
                        services,
                    });
                    onCreateDoctorOrder(payload);
                }}
            >
                {({
                    handleSubmit,
                    values,
                    setFieldValue,
                    errors,
                }: FormikProps<DoctorOrderFormProps>) => (
                    <>
                        <Header
                            showCancel={false}
                            className="sticky top-0"
                            title={Messages.newDoctorOrder}
                            customRight={() => (
                                <>
                                    <Button
                                        size="small"
                                        className="ml-2"
                                        variant="outline"
                                        onClick={() => navigate(-1)}
                                    >
                                        {Messages.back}
                                    </Button>
                                    <PermissibleRender
                                        permission={
                                            PERMISSION.DOCTOR_ORDER.CREATE
                                        }
                                    >
                                        <Button
                                            size="small"
                                            className="ml-2"
                                            onClick={() => handleSubmit()}
                                        >
                                            {Messages.save}
                                        </Button>
                                    </PermissibleRender>
                                </>
                            )}
                        />
                        <div className="p-4">
                            {booking?.customer && (
                                <div className="mb-3">
                                    <label className="text-label">
                                        <span>{Messages.customer}</span>
                                    </label>
                                    <CustomerSolidItem
                                        customer={booking?.customer as any}
                                    />
                                </div>
                            )}
                            {booking?.doctor && (
                                <div className="mb-3">
                                    <label className="text-label">
                                        <span>{Messages.doctor}</span>
                                    </label>
                                    <UserSelectedView
                                        user={booking?.doctor as any}
                                    />
                                </div>
                            )}
                            <div className="flex-center-y mb-3">
                                <Switch
                                    checked={!!values?.isComplication}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "isComplication",
                                            !values?.isComplication
                                        )
                                    }
                                />
                                <label className="block ml-2 mb-0 text">
                                    {Messages.thisIsComplicationCase}
                                </label>
                            </div>
                            <DoctorOrderLoadTreatments booking={booking} />
                            <div className="mb-3">
                                <InputText
                                    label={Messages.doctorSubject}
                                    value={values.doctorSubject}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "doctorSubject",
                                            e?.target?.value
                                        )
                                    }
                                    error={errors?.doctorSubject}
                                />
                            </div>
                            <div className="mb-3">
                                <RichTextTiny
                                    label={Messages.doctorOpinion}
                                    value={values?.doctorOpinion ?? ""}
                                    onChange={(value: any) =>
                                        setFieldValue(
                                            `doctorOpinion`,
                                            value.toString("html")
                                        )
                                    }
                                    placeholder={Messages.content}
                                    error={errors?.doctorOpinion}
                                />
                            </div>
                            {!values?.isComplication && (
                                <div>
                                    <div className="flex my-3 items-center">
                                        <div className="flex-1 text-bold">
                                            {Messages.recommendedTreatments}
                                        </div>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                setShowAddProduct(true)
                                            }
                                        >
                                            {Messages.add}
                                        </Button>
                                    </div>
                                    <AwesomeTableComponent
                                        ref={(ref) => {
                                            tableRef.current = ref;
                                        }}
                                        dataSource={services}
                                        columns={columns}
                                        baseColumnProps={{ width: 100 }}
                                    />
                                </div>
                            )}
                        </div>
                        {showAddProduct && (
                            <ProductSelectDrawer
                                open={showAddProduct}
                                onClose={() => setShowAddProduct(false)}
                                onSave={handleAddProduct}
                                isEdit={false}
                                defaultValue={services.map((service) => ({
                                    ...service.product,
                                    id: service.id,
                                    quantity: service.quantity,
                                    product: undefined,
                                }))}
                                customerId={booking?.customer?.id}
                            />
                        )}
                    </>
                )}
            </Formik>
        </div>
    );
};

export default DoctorOrderCreate;

const DoctorOrderLoadTreatments = ({ booking }: any) => {
    const { values, errors, setFieldValue, setValues } =
        useFormikContext<any>();
    const tableRef = useRef<any>(null);
    const [showLoadPreviousTreatment, setShowLoadPreviousTreatment] =
        useState(false);
    const { complicationTreatments, isComplication } = values || {};

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "treatmentNo",
            render: (treatmentNo, item) => (
                <AppLink
                    to={generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: item.id,
                    })}
                >
                    {treatmentNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.service,
            dataIndex: "services",
            render: (services) =>
                join(
                    map(services, (item) => item?.product?.name),
                    ", "
                ),
        },
        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor) => (
                <UserAvatarName
                    showInfo={{ showOPD: false }}
                    user={doctor?.adminUser}
                    size="xx-small"
                    variant="square"
                />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={TREATMENT_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },

        {
            title: Messages.completedQty,
            dataIndex: "completedQty",
            align: "center",
            render: (completedQty: any, item: any) => {
                return completedQty;
            },
        },
        {
            title: Messages.newComplicationQty,
            dataIndex: "quantity",
            width: 100,
            render: (quantity: any, item: any) => {
                return (
                    <QuantityInputField
                        quantity={quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity({ ...item, quantity })
                        }
                    />
                );
            },
        },
        {
            title: Messages.action,
            dataIndex: "id",
            align: "center",
            render: (product: any, item: any) => {
                return (
                    <Icon
                        onClick={() =>
                            setFieldValue(
                                "complicationTreatments",
                                filter(
                                    complicationTreatments,
                                    (i) => i?.id !== item?.id
                                )
                            )
                        }
                        name="remove_circle_outline"
                        className="text-primary cursor-pointer"
                    />
                );
            },
        },
    ];

    const onChangeQuantity = (item: any) => {
        return setFieldValue(
            "complicationTreatments",
            map(complicationTreatments, (i) => (i?.id === item?.id ? item : i))
        );
    };

    const handleAddTreatments = (treatments: any[]) => {
        setFieldValue("complicationTreatments", treatments);
    };

    if (!isComplication) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="flex items-center my-3">
                <div className="flex-1">
                    <h5>{Messages.treatment}</h5>
                </div>
                <div>
                    <Button
                        size="small"
                        className="ml-2"
                        onClick={() => setShowLoadPreviousTreatment(true)}
                    >
                        {Messages.loadPreviousTreatment}
                    </Button>
                </div>
            </div>
            <div className="mb-3">
                <AwesomeTableComponent
                    className=""
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    dataSource={complicationTreatments || []}
                    columns={columns}
                    baseColumnProps={{ width: 100 }}
                    showHeader
                />
                {errors?.complicationTreatments && (
                    <ViewTextError error={errors?.complicationTreatments} />
                )}
            </div>

            {showLoadPreviousTreatment && (
                <TreatmentSelectDrawer
                    params={{
                        status: [TreatmentStatus.Completed],
                        customer: [booking?.customer?.id],
                    }}
                    open={showLoadPreviousTreatment}
                    onClose={() => setShowLoadPreviousTreatment(false)}
                    onSave={handleAddTreatments}
                    isEdit={false}
                    defaultValue={complicationTreatments}
                />
            )}
        </React.Fragment>
    );
};
