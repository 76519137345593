import React from "react";
import { IDoctorOrder } from "../interfaces/doctor-order";

export interface IDoctorOrderDetailState {
    doctorOrder?: IDoctorOrder;
    loadDoctorOrderDetail: () => void;
}

const DoctorOrderDetailState: IDoctorOrderDetailState = {
    doctorOrder: undefined,
    loadDoctorOrderDetail: () => {},
};

export const DoctorOrderDetailContext = React.createContext(DoctorOrderDetailState);
