import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useGetServiceConfigurationsLazyQuery } from "../../../api/hooks";
import { IServiceCategory } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";

interface IServiceCategorySelect {
    className?: string;
    value: IServiceCategory | IServiceCategory[];
    multiple?: boolean;
    onChange: (value: IServiceCategory | IServiceCategory[]) => void;
    [key: string]: any;
}

const ServiceConfigurationSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IServiceCategorySelect) => {
    const [configList, setConfigList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getServiceConfigurations] = useGetServiceConfigurationsLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setConfigList([]);
        } else {
            getServiceConfigurations({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 15,
                        search: textSearch,
                    },
                },
            }).then((res: any) => {
                const categoryRes = res?.data?.data?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterCategory = map(
                    [...selectedValue, ...categoryRes],
                    (item) => ({
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqCat = uniqBy(filterCategory, (item) => item.id);
                setConfigList(uniqCat);
            });
        }
    }, 500);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(configList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(configList, id));
    };

    const categoryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.service}
            dataSource={configList}
            getLabel={(item) => {
                return item?.name;
            }}
            value={categoryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default ServiceConfigurationSelect;
