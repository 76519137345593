import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
    JobTypeAdmin,
    useGetUserScheduleByDateLazyQuery,
} from "../../../api/hooks";
import { IBranch } from "../../../interfaces/branch";
import IUser, { UserWorkingScheduleDate } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import UserSelect, { IUserSearchSelect } from "../../user/share/UserSelect";
import UserSelectedView from "../../user/share/UserSelectedView";
import TimeSlotSelect, { ITimeSlotValue } from "../../../common/TimeSlotSelect";

interface IDoctorSelectValue {
    doctor: IUser | null;
    timeSlots: ITimeSlotValue[];
}

interface IBookingDoctorSelect<T> {
    date: string;
    branch: IBranch | null;
    className?: any;
    form: any;
    userSelectProps?: Partial<IUserSearchSelect<any>>;
}

const BookingDoctorSelect = ({
    date,
    form,
    branch,
    className,
    userSelectProps,
}: IBookingDoctorSelect<IDoctorSelectValue>) => {
    const [getUserSchedule] = useGetUserScheduleByDateLazyQuery();
    const [doctorSchedule, setDoctorSchedule] =
        useState<UserWorkingScheduleDate>();

    const formValues = form?.values;

    useEffect(() => {
        if (!formValues?.doctor || !branch || !date) {
            return;
        }
        getUserSchedule({
            variables: {
                userId: formValues?.doctor?.id,
                branchId: branch?.id,
                date,
            },
            fetchPolicy: "no-cache",
        }).then((res) => {
            const userSchedule = res?.data?.data?.data;
            setDoctorSchedule(userSchedule as any);
        });
    }, [formValues?.doctor, branch, date]);

    // const doctorDefaultSchedule = useMemo(() => {
    //     const defaultWorkingTime = find(
    //         formValues?.doctor?.defaultWorkingSchedule,
    //         (item) => {
    //             const weekDays = moment.weekdays();
    //             const weekDay = weekDays[moment(date).weekday()];
    //             return (
    //                 item?.branch?.id === branch?.id &&
    //                 toUpper(weekDay) === item.date
    //             );
    //         }
    //     );
    //     return {
    //         startHour: (defaultWorkingTime?.schedule?.start ?? 0) / 3600,
    //         endHour: (defaultWorkingTime?.schedule?.end ?? 0) / 3600,
    //     };
    // }, [formValues?.doctor, date, branch]);

    const doctorWorkingSchedule = useMemo(() => {
        return map(doctorSchedule?.workingSchedule ?? [], (item) => ({
            start: item?.schedule?.start / 3600,
            end: item?.schedule?.end / 3600,
        }));
    }, [formValues?.doctor, date, doctorSchedule, branch]);

    const doctorOccupiedSchedule = useMemo(() => {
        return map(doctorSchedule?.occupiedSlots ?? [], (item) => ({
            start: item?.schedule?.start / 3600,
            end: item?.schedule?.end / 3600,
        }));
    }, [formValues?.doctor, date, doctorSchedule, branch]);

    const renderDoctorSchedule = () => {
        if (!doctorSchedule) return <div />;
        if (!doctorSchedule?.isWorking) {
            return (
                <div className="mt-3 text-gray italic">
                    {Messages.doctorNotWorking}
                </div>
            );
        }
        return (
            <TimeSlotSelect
                workingSchedule={doctorWorkingSchedule}
                occupiedSchedule={doctorOccupiedSchedule}
                occupiedSelectable
                className="mt-3"
                value={formValues?.timeSlot}
                onChange={(value) => {
                    form.setFieldValue("timeSlot", value);
                }}
                multiple
            />
        );
    };

    return (
        <div className={className}>
            <UserSelect
                onChange={(value) => form.setFieldValue("doctor", value)}
                label={Messages.doctor}
                params={{ jobType: JobTypeAdmin.Doctor }}
                {...userSelectProps}
            />
            <UserSelectedView user={formValues.doctor} />
            {renderDoctorSchedule()}
        </div>
    );
};

export default BookingDoctorSelect;
