import { Button, Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { generatePath, useNavigate } from "react-router-dom";
import { TypeOfIdentity } from "../../../api/hooks";
import AgencyAPI from "../../../api/queries/agency";
import PermissibleRender from "../../../common/PermissibleComponent";
import { PERMISSION } from "../../../constant/user";
import { AgencySchema } from "../../../formschema/agency";
import { mapAgencyToServer } from "../../../interfaces/agency";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import AgencyForm from "../share/AgencyForm";

const AgencyCreate = () => {
    const navigate = useNavigate();
    const configForm = useFormik<any>({
        initialValues: { typeOfIdentity: TypeOfIdentity.Individual } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AgencySchema,
        onSubmit: (values: any) => {
            const input = mapAgencyToServer(values);
            onCreateAgency(input);
        },
    });

    const onCreateAgency = (payload: any) => {
        Progress.show(
            {
                method: AgencyAPI.create,
                params: [payload],
            },
            (res: any) => {
                const agencyId = res?.id;
                navigate(
                    generatePath(Path.AGENCY_DETAIL, {
                        agencyId,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    return (
        <div className="flex-column-container bg-white w-100">
            <Header
                showCancel={false}
                title={Messages.newAgency}
                className="sticky top-0"
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.AGENCY.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => configForm.handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="px-1 sm:px-4 pb-5">
                <AgencyForm agencyForm={configForm} variant="create" />
            </div>
        </div>
    );
};

export default AgencyCreate;
