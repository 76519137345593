import { gql } from "@apollo/client";
import { F_COUNTRY } from "./address";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";

export const F_BRANCH = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_COUNTRY}
    fragment F_BRANCH on BranchDto {
        id
        # updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        branchId
        name
        code
        pickupCode
        brandTaxId
        phone
        status
        manualStockAdjustment
        address {
            address
            country {
                ...F_COUNTRY
            }
            province {
                id
                name
                translations {
                    language
                    name
                }
            }
            district {
                id
                name
                translations {
                    language
                    name
                }
            }
            subDistrict {
                id
                name
                translations {
                    language
                    name
                }
            }
            postcode
        }
    }
`;
export const F_BRANCH_BASIC = gql`
    fragment F_BRANCH_BASIC on AdminBranchBasic {
        id
        name
        code
    }
`;

export const F_BRANCHES = gql`
    ${F_BRANCH}
    ${F_PAGINATE}
    fragment F_BRANCHES on BranchPaginateResponse {
        data {
            ...F_BRANCH
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_BRANCH_DETAIL = gql`
    ${F_BRANCH}
    fragment F_BRANCH_DETAIL on BranchDto {
        ...F_BRANCH
        warehouseDelivery {
            id
            name
            code
        }
        address {
            country {
                id
                name
            }
            province {
                id
                name
            }
            district {
                id
                name
            }
            subDistrict {
                id
                name
            }
            postcode
            address
        }
    }
`;

export const F_BRANCH_ROLE_SUMMARY = gql`
    fragment F_BRANCH_ROLE_SUMMARY on AdminRoleWithNumberPic {
        number
        id
    }
`;
