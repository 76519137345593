import {
    Avatar,
    AwesomeTableComponent,
    Button,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { JsonParam, useQueryParam } from "use-query-params";
import InventoryAPI from "../../../api/queries/inventory";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import InventoryHistoryDrawer from "./InventoryHistoryDrawer";

export interface IInventoryTableProps {
    [key: string]: any;
}

const InventoryHistoryTable: React.FC<IInventoryTableProps> = ({ id }) => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const [openHistoryModal, setOpenHistoryModal] = useState<{
        open: boolean;
        product?: any;
    }>({ open: false });
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.PRODUCT_DETAIL, {
                        productId: item.id,
                    })}
                >
                    <div className="flex-center-y">
                        <Avatar
                            src={item?.gallery?.[0]}
                            size="x-small"
                            variant="rounded"
                            className="mr-2"
                            text={name}
                        />
                        {name}
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.sku,
            dataIndex: "sku",
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.currentQty,
            dataIndex: "currentQuantity",
        },
        {
            title: Messages.totalQty,
            dataIndex: "quantity",
        },
        {
            title: Messages.onHoldQty,
            dataIndex: "currentHold",
        },
        {
            title: Messages.totalOnHoldQty,
            dataIndex: "hold",
        },

        {
            title: Messages.history,
            dataIndex: "",
            width: 50,
            render: (product: any) => {
                return (
                    <Button
                        className="w-auto"
                        iconName="history"
                        variant="trans"
                        onClick={() =>
                            setOpenHistoryModal({ open: true, product })
                        }
                    />
                );
            },
        },

        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (product: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) => {
                            // onClickTableAction(actionId, product)
                        }}
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = {};
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...filterParam,
        };
        if (searchRef.current) {
            Object.assign(input, { search: searchRef.current });
        }
        return InventoryAPI.list(input);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.inventory}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
            {/* {openFilter && (
                <StockAdjustmentFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )} */}
            {openHistoryModal?.open && openHistoryModal?.product && (
                <InventoryHistoryDrawer
                    product={openHistoryModal.product}
                    open={openHistoryModal.open}
                    onClose={() => setOpenHistoryModal({ open: false })}
                />
            )}
        </div>
    );
};

export default InventoryHistoryTable;
