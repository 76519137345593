/* eslint-disable prefer-arrow-callback */
import * as FileSaver from "file-saver";
import { isEmpty, split } from "lodash";
import * as XLSX from "xlsx";

export const exportToCSV = (csvData: any, fileName: string) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export function convertCsvJSON(csv: any) {
    const workbook = XLSX.read(csv, {
        type: "binary",
    });
    const data: Array<any> = [];
    workbook.SheetNames.forEach(function (sheetName, index) {
        // Here is your object
        const XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
        );
        if (XL_row_object.length > 0) {
            data.push(...XL_row_object);
        }
    });
    return data;
}

export const exportToCSVFile = (csvData: any, fileName: string) => {
    const fileType = "data:text/csv;charset=utf-8";
    const fileExtension = ".csv";
    const convertedData = csvData?.map((i: any) => i.join(";")).join("\n");
    const data = new Blob([convertedData], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
