import {
    Button,
    DateInput,
    Header,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router";
import InputTextForm from "../../../../common/input/InputTextForm";
import PermissibleRender from "../../../../common/PermissibleComponent";
import UploadButton from "../../../../common/upload/UploadButton";
import { PERMISSION } from "../../../../constant/user";
import Messages from "../../../../languages/Messages";
import BranchSolidSelect from "../../../branch/share/BranchSolidSelect";
import Path from "../../../Path";
import StockTransferCreateItems from "./StockTransferCreateItems";
import InputSelectForm from "../../../../common/input/InputSelectForm";
import { STOCK_TRANSFER_TYPES } from "../../../../constant/stock-transfer";
import StockRequestSolidSelect from "../../stock-request/share/StockRequestSolidSelect";
import StockTransferAPI from "../../../../api/queries/stock-transfer";

const mapStockTransferToServer = (values: any) => {
    const {
        origin,
        destination,
        products,
        attachments,
        remark,
        type,
        expectedDateOfArrival,
        expectedDateOfTransfer,
        requests,
    } = values;
    return {
        type,
        origin: origin?.id,
        destination: destination?.id,
        products: map(products, (pro) => ({
            product: pro?.id,
            stock: pro?.quantity ?? 0,
        })),
        expectedDateOfArrival,
        expectedDateOfTransfer,
        requests: requests?.length > 0 ? map(requests, (req) => req?.id) : null,
        attachments:
            attachments?.length > 0
                ? map(attachments, (att) => att?.fileUrl)
                : null,
        remark: remark || null,
    };
};

export const StockTransferSchema = Yup.object().shape({
    origin: Yup.object().required("Required field!"),
    destination: Yup.object().required("Required field!"),
    type: Yup.string().required("Required field!"),
    products: Yup.array().min(1).required("Required field!"),
    expectedDateOfArrival: Yup.mixed().required("Required field!"),
});

const StockTransferCreate = () => {
    const navigate = useNavigate();

    const stockTransferForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: StockTransferSchema,
        onSubmit: (values: any) => {
            const input = mapStockTransferToServer(values);
            onCreateStockRequest(input);
        },
    });

    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = stockTransferForm;

    useEffect(() => {
        forEach(Object.keys(stockTransferForm.errors), (key) =>
            Notifications.showError(`${stockTransferForm.errors[key]}`)
        );
    }, [stockTransferForm.errors]);

    const onCreateStockRequest = (payload: any) => {
        Progress.show(
            {
                method: StockTransferAPI.create,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.INVENTORY_TRANSFER_STOCK_DETAIL, {
                        transferId: res?.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const classNameInput = "mt-3";
    return (
        <div className="flex-column-container bg-white w-100">
            <Header
                showCancel={false}
                className="sticky top-0"
                title={Messages.stockRequestCreate}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.PRODUCT_STOCK_REQUEST.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-4">
                <BranchSolidSelect
                    label={Messages.origin}
                    value={formValues?.origin}
                    onChange={(value) =>
                        setValues((prev: any) => ({
                            ...prev,
                            origin: value,
                            products: [],
                            requests: [],
                        }))
                    }
                    error={stockTransferForm?.errors?.origin}
                    className={classNameInput}
                />
                <BranchSolidSelect
                    label={Messages.destination}
                    value={formValues?.destination}
                    onChange={(value) =>
                        setValues((prev: any) => ({
                            ...prev,
                            destination: value,
                            products: [],
                            requests: [],
                        }))
                    }
                    error={stockTransferForm?.errors?.origin}
                    className={classNameInput}
                />
                <InputSelectForm
                    dataSource={STOCK_TRANSFER_TYPES}
                    keyData="type"
                    form={stockTransferForm}
                    className={classNameInput}
                    onChange={(v) => {
                        setValues((prev: any) => ({
                            ...prev,
                            type: v,
                            requests: [],
                            products: [],
                        }));
                    }}
                />
                <DateInput
                    label={Messages.expectedDateOfTransfer}
                    onChange={(value) => {
                        stockTransferForm.setFieldValue(
                            "expectedDateOfTransfer",
                            value
                        );
                    }}
                    value={
                        formValues?.expectedDateOfTransfer
                            ? moment(formValues?.expectedDateOfTransfer)
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    error={formErrors?.expectedDateOfTransfer as any}
                    className={classNameInput}
                />
                <DateInput
                    label={Messages.expectedDateOfArrival}
                    onChange={(value) => {
                        stockTransferForm.setFieldValue(
                            "expectedDateOfArrival",
                            value
                        );
                    }}
                    value={
                        formValues?.expectedDateOfArrival
                            ? moment(formValues?.expectedDateOfArrival)
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    error={formErrors?.expectedDateOfArrival as any}
                    className={classNameInput}
                />
                <StockRequestSolidSelect
                    multiple
                    disabled={!formValues?.type}
                    className={classNameInput}
                    value={formValues?.requests ?? []}
                    onChange={(v) => {
                        setValues((prev: any) => ({
                            ...prev,
                            requests: v,
                        }));
                    }}
                />
                <StockTransferCreateItems
                    stockTransferForm={stockTransferForm}
                />
                <div className={classNameInput}>
                    <label>{Messages.attachment}</label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) => {
                            stockTransferForm.setFieldValue(
                                "attachments",
                                file
                            );
                        }}
                        defaultFiles={formValues?.attachments ?? []}
                    />
                    <ViewTextError error={formErrors.attachments} />
                </div>
                <InputTextForm
                    form={stockTransferForm}
                    keyData="remark"
                    multiple
                    className={classNameInput}
                />
            </div>
        </div>
    );
};

export default StockTransferCreate;
