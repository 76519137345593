/* eslint-disable consistent-return */
import {
    AwesomeTableComponent,
    IColumnsProps,
    IDrawerProps,
    TimeUtils,
} from "d-react-components";
import React, { useRef } from "react";
import { generatePath } from "react-router";
import MaterialInventoryAPI from "../../../api/queries/material-inventory";
import Drawer from "../../../common/Drawer";
import { IMaterial } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";
import { MATERIAL_INVENTORY_HISTORY_TYPE } from "../../../constant/material";
import AppLink from "../../../common/AppLink";
import Path from "../../Path";

export interface IInventoryHistoryDrawerProps extends IDrawerProps {
    material: IMaterial;
}

const InventoryMaterialHistoryDrawer: React.FC<
    IInventoryHistoryDrawerProps
> = ({ open, onClose, material }) => {
    const tableRef = useRef<any>(null);

    const renderRef = (history: any) => {
        switch (history?.refType) {
            case MATERIAL_INVENTORY_HISTORY_TYPE.TREATMENT:
                return (
                    <AppLink
                        to={generatePath(Path.TREATMENT_DETAIL, {
                            treatmentId: history.refId,
                        })}
                    >
                        {history?.treatment?.treatmentNo}
                    </AppLink>
                );
            case MATERIAL_INVENTORY_HISTORY_TYPE.MATERIAL_STOCK_ADJUSTMENT:
                return (
                    <AppLink
                        to={generatePath(Path.MATERIAL_ADJUST_REQUEST_DETAIL, {
                            requestId: history.refId,
                        })}
                    >
                        {history?.adjustment?.adjustmentNo}
                    </AppLink>
                );
            case MATERIAL_INVENTORY_HISTORY_TYPE.MATERIAL_PURCHASE_ORDER:
                return (
                    <AppLink
                        to={generatePath(Path.MATERIAL_ORDER_DETAIL, {
                            orderId: history.refId,
                        })}
                    >
                        {history?.purchase?.materialPurchaseNo}
                    </AppLink>
                );
            default:
                return <div />;
        }
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "code",
        },
        {
            title: Messages.time,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (data) => `+${data}`,
        },
        {
            title: Messages.reason,
            dataIndex: "refType",
        },
        {
            title: Messages.ref,
            dataIndex: "",
            render: renderRef,
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = {};
        const paginate = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return MaterialInventoryAPI.history({
            paginate,
            material: material?.id,
        });
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.history}
            size="auto"
            width="50%"
            hideFooter
        >
            <div className="p-4">
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    source={source}
                    columns={columns}
                    baseColumnProps={{ width: 150 }}
                    getTotalItems={(res) => {
                        return res?.data?.data?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => res?.data?.data?.data ?? []}
                />
            </div>
        </Drawer>
    );
};

export default InventoryMaterialHistoryDrawer;
