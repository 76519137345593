import { DateInput } from "d-react-components";
import moment from "moment";
import InputTextForm from "../../../common/input/InputTextForm";
import { usePromotionCreateContext } from "../../../context/promotion";
import BranchSelect from "../../branch/share/BranchSelect";
import Messages from "../../../languages/Messages";

const PromotionCreateInformationForm = () => {
    const { promotionForm } = usePromotionCreateContext();
    const { values, errors, setFieldValue } = promotionForm;

    return (
        <div className="p-4 border">
            <BranchSelect
                className=""
                multiple
                value={values?.branch}
                error={errors?.branch}
                onChange={(v) => {
                    setFieldValue("branch", v);
                }}
            />
            <div className="flex items-start gap-4 mt-3">
                <DateInput
                    className="w-100"
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    allowClear={false}
                    label={Messages.startAt}
                    disabledDate={(date) =>
                        moment()
                            .startOf("day")
                            .isAfter(moment(date).startOf("D"))
                    }
                    onChange={(value) => {
                        setFieldValue("start", value);
                        if (values?.end && value?.isAfter(values?.end)) {
                            setFieldValue("end", undefined);
                        }
                    }}
                    value={values?.start ? moment(values.start) : (null as any)}
                    error={errors.start as any}
                />
                <DateInput
                    disabled={!values?.start}
                    className="w-100"
                    showTime
                    format="DD/MM/YYYY HH:mm"
                    allowClear={false}
                    label={Messages.endAt}
                    onChange={(value) => setFieldValue("end", value)}
                    value={values?.end ? moment(values.end) : (null as any)}
                    error={errors.end as any}
                    disabledDate={(date) => {
                        return date.isBefore(values?.start);
                    }}
                />
            </div>
            <InputTextForm
                form={promotionForm}
                keyData="title"
                className="mt-3"
            />
            <InputTextForm
                form={promotionForm}
                multiple
                keyData="memo"
                className="mt-3"
            />
        </div>
    );
};

export default PromotionCreateInformationForm;
