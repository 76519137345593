import { InputText } from "d-react-components";
import { FormikProps } from "formik";
import React, { useState } from "react";
import InputSelectForm from "../../../../common/input/InputSelectForm";
import InputTextForm from "../../../../common/input/InputTextForm";
import LabelLanguage from "../../../../common/label/LabelLanguage";
import { PAYMENT_METHOD_STATUSES } from "../../../../constant/payment";
import { IPaymentMethod } from "../../../../interfaces/payment-method";
import Messages from "../../../../languages/Messages";

export interface IPaymentMethodGeneralFormProps {
    generalForm: FormikProps<IPaymentMethod>;
}

const PaymentMethodGeneralForm: React.FC<IPaymentMethodGeneralFormProps> = ({
    generalForm,
}) => {
    const [language, setLanguage] = useState("en");

    const classNameInput = "col-12 mt-3";
    const {
        errors: formErrors,
        values: formValues,
        setFieldValue,
    } = generalForm;

    return (
        <div className="p-4 row">
            <InputTextForm
                form={generalForm}
                keyData="name"
                className={classNameInput}
            />
            <div className={classNameInput}>
                <LabelLanguage
                    label={Messages.title}
                    language={language}
                    onChange={setLanguage}
                />
                <InputText
                    value={
                        formValues?.[language as keyof typeof formValues]
                            ?.title ?? ""
                    }
                    onChange={(event) =>
                        setFieldValue(`${language}.title`, event?.target?.value)
                    }
                    //@ts-ignore
                    error={formErrors?.en?.title || formErrors?.th?.title}
                />
            </div>
            <InputSelectForm
                dataSource={PAYMENT_METHOD_STATUSES}
                form={generalForm}
                keyData="status"
                className={classNameInput}
            />
            <div className={classNameInput}>
                <LabelLanguage
                    label={Messages.description}
                    language={language}
                    onChange={setLanguage}
                />
                <InputText
                    multiple
                    value={
                        formValues?.[language as keyof typeof formValues]
                            ?.description ?? ""
                    }
                    onChange={(event) =>
                        setFieldValue(
                            `${language}.description`,
                            event?.target?.value
                        )
                    }
                    error={
                        //@ts-ignore
                        formErrors?.en?.description ||
                        //@ts-ignore
                        formErrors?.th?.description
                    }
                />
            </div>
        </div>
    );
};

export default PaymentMethodGeneralForm;
