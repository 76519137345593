import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    InputTextSearch,
    Notifications,
    ObjectUtils,
    Progress,
    useFirstTime,
} from "d-react-components";
import classNames from "classnames";

import {
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    map,
    toLower,
} from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import MaterialAPI from "../../../api/queries/material";
import TreatmentAPI from "../../../api/queries/treatment";
import Drawer from "../../../common/Drawer";
import { TreatmentDetailContext } from "../../../context/treatment";
import { IMaterial } from "../../../interfaces/material";
import { ITreatmentMaterial } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import MaterialNameView from "../../material/share/MaterialNameView";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import { TREATMENT_STATUS } from "../../../constant/treatment";

interface IMaterialItem extends IMaterial {
    quantity: number;
    provideAfterTreatment: boolean;
}

interface ITreatmentMaterialAddDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IMaterialItem[];
}

interface ITreatmentMaterialUpdateDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IMaterialItem;
}

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

const TreatmentMaterialAddDrawer = ({
    open,
    onClose,
    defaultValue = [],
}: ITreatmentMaterialAddDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [materialSelected, setMaterialSelected] =
        useState<IMaterialItem[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onAddMaterialTreatment = () => {
        // const materialFiltered = filter(
        //     materialSelected,
        //     (item) => !!item.quantity
        // );
        const materials = map(materialSelected, (item) => ({
            material: item.id,
            quantity: item.quantity,
            provideAfterTreatment: !!item.provideAfterTreatment,
        }));

        Progress.show(
            {
                method: TreatmentAPI.addMaterial,
                params: [treatment?.id, materials],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (material) => <MaterialNameView material={material} />,
        },
        {
            title: Messages.unit,
            dataIndex: "trackingUnit",
            render: (unit) => unit.name,
        },
        {
            title: Messages.quantityActual,
            dataIndex: "",
            render: (material: any) => {
                const selectedItem = find(
                    materialSelected,
                    (item) => item.id === material.id
                );
                if (!selectedItem) return <div />;
                return (
                    <QuantityInputField
                        quantity={selectedItem?.quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity(quantity, material)
                        }
                        min={1}
                        disabled={selectedItem.provideAfterTreatment}
                    />
                );
            },
        },
    ];

    const onChangeQuantity = (quantity: number, material: IMaterial) => {
        const result = ObjectUtils.updateArrayById(materialSelected as any, {
            ...material,
            quantity,
        });
        setMaterialSelected(result);
    };

    const source = (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: materialSelected });
        }

        return MaterialAPI.search({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            sort: {
                createdAt: -1,
            },
        });
    };

    const onChangeSelectMaterial = (keys: string[], materials: IMaterial[]) => {
        const allMaterials = filter(
            [...materialSelected, ...materials],
            (item) => !!item
        ).map((item: any) => ({ ...item, quantity: item?.quantity ?? 1 }));

        const result = map(keys, (id) =>
            find(allMaterials, (item) => item.id === id)
        ).filter((item) => !!item);

        setMaterialSelected(result);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.material}
            onSave={onAddMaterialTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item) => (
                        <Button
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => {
                        return res?.data?.data?.data ?? [];
                    }}
                    rowSelection={{
                        selectedRowKeys: map(
                            materialSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectMaterial as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

const TreatmentMaterialUpdateDrawer = ({
    open,
    onClose,
    defaultValue,
}: ITreatmentMaterialUpdateDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [material, setMaterial] = useState<IMaterialItem>(defaultValue);

    const onUpdateMaterialTreatment = () => {
        const input = {
            material: material?.id,
            quantity: material?.quantity,
        };
        Progress.show(
            {
                method: TreatmentAPI.updateMaterial,
                params: [treatment?.id, input],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(treatmentRes);
                onClose();
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (material) => <MaterialNameView material={material} />,
        },
        {
            title: Messages.unit,
            dataIndex: "trackingUnit",
            render: (unit) => unit?.name,
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity: any) => {
                return (
                    <QuantityInputField
                        quantity={quantity}
                        onChangeQuantity={(quantity: number) =>
                            setMaterial({ ...material, quantity })
                        }
                        min={1}
                    />
                );
            },
        },
    ];

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.material}
            onSave={onUpdateMaterialTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <AwesomeTableComponent
                    className="mt-3"
                    columns={columns}
                    dataSource={[material]}
                    pagination={false}
                />
            </div>
        </Drawer>
    );
};

const TreatmentDetailMaterial = () => {
    const { treatment, setTreatment, onPrintMaterial } = useContext(
        TreatmentDetailContext
    );
    const materialPreset =
        treatment?.services?.[0]?.serviceConfiguration?.materialPreset ?? [];
    const [openAdd, setOpenAdd] = useState(false);
    const [updatingMaterial, setUpdatingMaterial] =
        useState<IMaterialItem | null>(null);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "material",
            render: (material: IMaterial) => (
                <MaterialNameView material={material} />
            ),
        },
        {
            title: Messages.unit,
            dataIndex: "material",
            render: (material: IMaterial) => material?.trackingUnit?.name,
        },
        {
            title: `${Messages.quantity} (Default)`,
            dataIndex: "quantity",
            width: 150,
            align: "center",
            render: (quantity, item) => {
                const preset = find(
                    materialPreset,
                    (i) => i?.material?.id === item?.material?.id
                );
                return preset?.quantity ?? "N/A";
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
            width: 250,
            render: (quantity, item) =>
                item.provideAfterTreatment
                    ? Messages.provideAfterTreatment
                    : quantity,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            width: 200,
            render: (material) => {
                const editable = getEditableMaterial(material);
                const deletable = getDeletableMaterial(material);
                return (
                    <div className="d-flex">
                        <Button
                            variant="trans"
                            iconName="edit"
                            className={classNames(
                                "cursor-pointer mr-3 text-primary",
                                {
                                    "text-disabled": !editable,
                                }
                            )}
                            onClick={() =>
                                setUpdatingMaterial({
                                    ...material.material,
                                    quantity: material?.quantity,
                                })
                            }
                            disabled={!editable}
                        />
                        <Button
                            variant="trans"
                            iconName="remove_circle_outline"
                            className={classNames(
                                "cursor-pointer mr-3 text-primary",
                                {
                                    "text-disabled": !deletable,
                                }
                            )}
                            onClick={() => onRemove(material)}
                            disabled={!deletable}
                        />
                    </div>
                );
            },
        },
    ];

    const getEditableMaterial = (material: any) => {
        if (treatment?.doctorOrder?.status === "COMPLETED") {
            return false;
        }

        // if (material?.isPreset) {
        //     return true;
        // }

        // if (material?.hasPermissionAdjustment) {
        //     return treatment?.status === TREATMENT_STATUS.COMPLETED;
        // }

        return material?.hasPermissionAdjustment;
    };

    const getDeletableMaterial = (material: any) => {
        return material?.hasPermissionDelete;
    };

    const onRemove = (material: ITreatmentMaterial) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: TreatmentAPI.removeMaterial,
                        params: [treatment?.id, material.material?.id],
                    },
                    (treatmentRes) => {
                        setTreatment(treatmentRes);
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const materialDataSource = useMemo(() => {
        if (isEmpty(textSearch)) {
            return treatment?.materials ?? [];
        }

        return filter(treatment?.materials ?? [], (item) =>
            includes(
                toLower(item?.material?.name + item?.material?.sku),
                toLower(textSearch)
            )
        );
    }, [textSearch, treatment?.materials]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.material}</label>
                <div className="d-flex flex-row">
                    <Button onClick={() => onPrintMaterial()}>
                        {Messages.print}
                    </Button>
                    <Button
                        onClick={() => setOpenAdd(true)}
                        className="ml-3"
                        disabled={
                            treatment?.doctorOrder?.status === "COMPLETED"
                        }
                    >
                        {Messages.add}
                    </Button>
                </div>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={materialDataSource}
                className="mt-3"
                pagination={false}
            />
            {openAdd && (
                <TreatmentMaterialAddDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    defaultValue={map(treatment?.materials, (item) => ({
                        ...item.material,
                        quantity: item.quantity,
                        provideAfterTreatment: item.provideAfterTreatment,
                    }))}
                />
            )}
            {!!updatingMaterial && (
                <TreatmentMaterialUpdateDrawer
                    defaultValue={updatingMaterial}
                    open={!!updatingMaterial}
                    onClose={() => setUpdatingMaterial(null)}
                />
            )}
        </div>
    );
};

export default TreatmentDetailMaterial;
