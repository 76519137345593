/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { map } from "lodash";
import moment from "moment";
import { CustomerReferrerDto, CustomerSettingDto } from "../api/hooks";
import { IBillingAddress, ICountry, IServiceAddress } from "./address";
import { ITag } from "./tag";
import { IUser } from "./user";

export enum SurveyQuestionType {
    INPUT = "INPUT",
    CHECK_BOX = "CHECK_BOX",
}

export enum CustomerStatus {
    VERIFIED = "VERIFIED",
    UN_VERIFIED = "UN_VERIFIED",
    CANCELLED = 'CANCELLED',
}

export enum PhoneNumberStatus {
    VERIFIED = "VERIFIED",
    UN_VERIFIED = "UN_VERIFIED",
}

export enum SecretCodeStatus {
    ACTIVE = "ACTIVE",
    IN_ACTIVE = "IN_ACTIVE",
}

export enum SecretCodeRef {
    SELF_REGISTRATION = "SELF_REGISTRATION",
    UPDATE_INFO = "UPDATE_INFO",
    HEALTH_ASSESSMENT = "HEALTH_ASSESSMENT",
}

export enum CustomerUpdateInfoStatus {
    REQUESTED = "REQUESTED",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
}

export interface ICustomerEmergencyContact {
    residence: string;
    name: string;
    phone: string;
    relationship: string;
}

export interface ICustomerFamilyInfo {
    dateOfBirth: string;
    name: string;
    phone: string;
    relationship: string;
}

export interface ISurgeryHistory {
    date: string;
    hospital: string;
    surgery: number;
}

export interface IMedicalProfile {
    allergyHis: string;
    bloodGroup: string;
    bloodPressure: string;
    height: number;
    surgeryHis: ISurgeryHistory[];
    underDisease: string;
    weight: string;
}

export interface ISurvey {
    id: string;
    label: string;
    type?: SurveyQuestionType;
    translate?: any[];
    answers?: ISurvey[];
}

export interface ICustomerSurvey {
    updatedAt: string;
    surveyAnswers: {
        questionId: string;
        answers: string[];
    }[];
    confirmation?: {
        other?: {
            updatedAt?: Date;
            signature?: string;
            remark?: string;
        };
        customer?: {
            updatedAt?: Date;
            signature?: string;
            remark?: string;
        };
    };
}

export interface ICustomerNote {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: IUser;
    noteNo: string;
    title: string;
    content: string;
    refId: string;
    ref: string;
}

export interface ICustomer {
    id?: string;
    status?: CustomerStatus;
    customerNo?: string;
    title?: string;
    avatar?: string;
    billing?: IBillingAddress[];
    birthDay?: string;
    citizenId?: string;
    createBy?: string;
    createdAt?: string;
    createByAdmin?: IUser;
    email?: string;
    emergencyContact: ICustomerEmergencyContact[];
    familyInformation: ICustomerFamilyInfo[];
    firstNameEn: string;
    firstNameTh: string;
    fullNameTh: string;
    fullNameEn: string;
    gender?: "MALE" | "FEMALE" | "NOT_SAY";
    lastNameEn: string;
    lastNameTh: string;
    lineId: string;
    facebook?: string;
    instagram?: string;
    maritalStatus: string;
    medicalProfile: IMedicalProfile;
    nationality: ICountry;
    phoneCodeCountry?: ICountry;
    nickname?: string;
    numberOfChild: number;
    passportExp: string;
    passportNo: string;
    phone: string;
    phoneNumberStatus?: PhoneNumberStatus;
    phoneCode: string;
    alternativePhone: string;
    religion: string;
    updatedAt: string;
    address?: IServiceAddress[];
    tags: ITag[];
    survey: ICustomerSurvey;
    healthAssessment: ICustomerSurvey;
    patientType?: string;
    updateInfo?: ICustomer;
    updateInfoCode?: ISecretCode;
    healthAssessmentCode?: ISecretCode;
    updateInfoStatus?: CustomerUpdateInfoStatus;
    setting?: CustomerSettingDto & { isStaff?: boolean };
    referrer?: CustomerReferrerDto;
    totalCredit?: number;
}

export interface IGuest {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface ISecretCode {
    id?: string;
    secretCodeNo?: string;
    ref?: SecretCodeRef;
    refId?: string;
    code?: string;
    expiryTime?: string;
    status?: SecretCodeStatus;
}

export const mapCustomerPersonalInfoToServer = (customer: ICustomer) => {
    const {
        avatar,
        title,
        firstNameTh,
        firstNameEn,
        lastNameEn,
        lastNameTh,
        nickname,
        gender,
        birthDay,
    } = customer as any;
    return {
        avatar,
        title,
        firstNameTh,
        firstNameEn,
        lastNameEn,
        lastNameTh,
        nickname,
        gender,
        birthDay,
    };
};

export const mapCustomerPrivacyInfoToServer = (customer: ICustomer) => {
    const {
        citizenId,
        nationality,
        passportNo,
        passportExp,
        religion,
        maritalStatus,
        numberOfChild,
    } = customer as any;
    return {
        citizenId,
        nationality: nationality?.id,
        passportNo,
        passportExp,
        religion,
        maritalStatus,
        numberOfChild: parseInt(numberOfChild, 10),
    };
};

export const mapCustomerContactToServer = (customer: ICustomer) => {
    const {
        phone,
        email,
        lineId,
        instagram,
        facebook,
        alternativePhone,
        phoneCodeCountry,
    } = customer as any;
    return {
        phone,
        email,
        lineId,
        instagram,
        facebook,
        alternativePhone,
        phoneCodeCountry: phoneCodeCountry?.id,
    };
};

export const mapCustomerFamilyInfoToServer = (customer: ICustomer) => {
    const { familyInformation } = customer as any;
    return {
        familyInformation: map(familyInformation, (item) => ({
            name: item.name,
            relationship: item.relationship,
            dateOfBirth: item.dateOfBirth,
            phone: item.phone,
        })),
    };
};

export const mapCustomerEmergencyToServer = (customer: ICustomer) => {
    const { emergencyContact } = customer as any;
    return {
        emergencyContact: map(emergencyContact, (item) => ({
            name: item.name,
            relationship: item.relationship,
            residence: item.residence,
            phone: item.phone,
        })),
    };
};

export const mapCustomerReferrerToServer = (customer: ICustomer) => {
    const { salePerson, agency } = customer as any;
    if (!salePerson && !agency) return {};
    return {
        referrer: {
            salePerson: salePerson?.id,
            agency: agency?.id,
        },
    };
};

export const mapCustomerToServer = (customer: ICustomer) => {
    const personalInfo = mapCustomerPersonalInfoToServer(customer);
    const privacyInfo = mapCustomerPrivacyInfoToServer(customer);
    const contact = mapCustomerContactToServer(customer);
    const familyInfo = mapCustomerFamilyInfoToServer(customer);
    const emergencyContact = mapCustomerEmergencyToServer(customer);
    const referrer = mapCustomerReferrerToServer(customer);
    const medicalProfile = mapCustomerFullMedicalProfileToServer(customer);
    const { branch } = customer as any;
    return {
        ...personalInfo,
        ...privacyInfo,
        ...contact,
        ...familyInfo,
        ...emergencyContact,
        ...referrer,
        medicalProfile,
        branchId: branch?.id,
    };
};

export const mapCustomerMedicalProfileToServer = (profile: any) => {
    const { height, weight, bloodGroup, bloodPressure } = profile;
    return {
        height: parseFloat(height),
        weight: parseFloat(weight),
        bloodGroup,
        bloodPressure,
    };
};

export const mapCustomerFullMedicalProfileToServer = (profile: any) => {
    const { allergyHis, underDisease, surgeryHis } = profile;
    return {
        ...mapCustomerMedicalProfileToServer(profile),
        allergyHis,
        underDisease,
        surgeryHis:
            surgeryHis?.map((item: any) => ({
                ...item,
                date: moment(item.date).toDate(),
            })) ?? [],
    };
};

export const mapCustomerFilterBodyToServer = (filterBody: any) => {
    if (!filterBody) return {};
    const { gender, province, status, typeOfCustomer } = filterBody;
    return {
        gender,
        province,
        status,
        typeOfCustomer,
    };
};

export const mapCustomerFilterToQueryParams = (filter: any) => {
    const { nationality } = filter || {};
    return {
        nationality: nationality?.length
            ? map(nationality, (n) => ({ id: n?.id, name: n?.name }))
            : undefined,
    };
};

export const mapCustomerFilterParamsToServer = (filter: any) => {
    const { nationality } = filter || {};
    return {
        nationality: nationality?.length
            ? map(nationality, (n) => n?.id)
            : undefined,
    };
};
