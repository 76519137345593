import React from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { BLOOD_GROUPS } from "../../../constant/customer";
import Messages from "../../../languages/Messages";

interface IMedicalProfileForm {
    form: any;
    isModal?: boolean;
}

const MedicalProfileForm = ({
    form: customerForm,
    isModal = true,
}: IMedicalProfileForm) => {
    const inputClassName = isModal ? "col-12 mt-3" : "col-6 mt-3";

    return (
        <div className="row p-3">
            <InputTextForm
                label={Messages.heightCm}
                keyData="height"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                label={Messages.weightKg}
                keyData="weight"
                form={customerForm}
                className={inputClassName}
            />
            <InputSelectForm
                dataSource={BLOOD_GROUPS}
                keyData="bloodGroup"
                getLabel={(item: any) => item.label}
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="bloodPressure"
                form={customerForm}
                className={inputClassName}
            />
        </div>
    );
};

export default MedicalProfileForm;
