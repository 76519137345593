/* eslint-disable no-case-declarations */
import { Popover } from "antd";
import ClassNames from "classnames";
import { Avatar, Icon, StringUtils, TimeUtils } from "d-react-components";
import { filter, find, some } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { EventWrapperProps } from "react-big-calendar";
import { generatePath, Link } from "react-router-dom";
import { CalendarRef } from "../../../api/hooks";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import BigCalendar from "../../../common/calendar/BigCalendar";
import { CalendarContext } from "../../../context/calendar";
import { IBooking } from "../../../interfaces/booking";
import { ICalendarEvent } from "../../../interfaces/calendar";
import { ITreatment } from "../../../interfaces/treatment";
import { JobType } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

export interface ICalendarBodyProps {
    [key: string]: any;
}

const checkOverlapEvent = (
    eventToCheck: ICalendarEvent,
    other: ICalendarEvent
) => {
    const { schedule: scheduleToCheck, day: dayToCheck } =
        eventToCheck?.resource || {};
    const { schedule, day } = other?.resource || {};
    const checked =
        day === dayToCheck &&
        scheduleToCheck?.start &&
        scheduleToCheck?.end &&
        schedule?.start &&
        schedule?.end &&
        scheduleToCheck?.start < schedule?.start &&
        scheduleToCheck.end > schedule?.end;
    return checked;
};

const getDayEvents = (calendarEvents: ICalendarEvent[]) => {
    const backgroundEvents: ICalendarEvent[] = [];
    const normalEvents: ICalendarEvent[] = [];
    if (calendarEvents?.length > 0) {
        calendarEvents.forEach((eventItem) => {
            if (
                some(
                    filter(calendarEvents, (i) => i?.id !== eventItem?.id),
                    (obj) => checkOverlapEvent(eventItem, obj)
                )
            ) {
                backgroundEvents.push(eventItem);
            } else {
                normalEvents.push(eventItem);
            }
        });
    }
    return [backgroundEvents, normalEvents];
};

const CalendarBody: React.FC<ICalendarBodyProps> = ({ id }) => {
    const {
        updateFilterState,
        setCalendarDayDrawer,
        calendarFilterState,
        calendarEvents = [],
    } = React.useContext(CalendarContext);
    const { dateRange } = calendarFilterState || {};

    return (
        <div className="p-3">
            <BigCalendar
                overlayDateCell
                classNameContainer="calendar-body_container"
                date={moment(dateRange).toDate()}
                dateRange={dateRange}
                onNavigate={(value) => {
                    updateFilterState({ dateRange: moment(value) });
                }}
                events={calendarEvents}
                components={{
                    event: (props) => (
                        <CalendarEventItem
                            {...props}
                            classNameContainer="calendar-body__event-container"
                        />
                    ),
                    eventWrapper: (props) => (
                        <CalendarEventWrapper
                            {...props}
                            classNamePopOver="calendar-event-wrapper_popover"
                        />
                    ),
                }}
                onShowMore={(events, day) => {
                    const [backgroundEvents, normalEvents] =
                        getDayEvents(calendarEvents);
                    setCalendarDayDrawer({
                        open: true,
                        day,
                        backgroundEvents,
                        normalEvents,
                    });
                }}
                eventPropGetter={() => ({ className: "bg-primary" })}
                // customDateHeader={myDateHeader}
                // eventPropGetter={(event: any, start, end, isSelect) => {
                //     return {
                //         className:
                //             "my-big-calendar__event-container text-x-small py-2 px-3 ml-3 mr-3",
                //         style: {
                //             // backgroundColor:
                //             //     event?.color ?? "rgba(0, 0, 0, 0.67)",
                //             width: "100%",
                //         },
                //     };
                // }}
                // getDateProps={(props) => {
                //     const checkTimeStatus = getCheckTimeStatus(
                //         props,
                //         checkTimeData
                //     );
                //     return {
                //         style: {
                //             backgroundColor:
                //                 checkTimeStatus?.backgroundColor ?? "",
                //         },
                //     };
                // }}
                // onSelectEvent={(props: IMyTimelineEvent) => {
                //     if (!props?.timelineId) {
                //         return;
                //     }
                //     onChangeData("openCheckTimeModal", {
                //         open: true,
                //         checktimeId: props?.timelineId,
                //     });
                // }}
                // onSelectSlot={(slot) => {
                //     const { start, end, slots, action } = slot;
                //     let thisDayEvent: IMyTimelineEvent[] = [];
                //     if (myEvents?.length > 0) {
                //         thisDayEvent = myEvents.filter((i) => {
                //             const { start: eventStart, end: eventEnd } = i;
                //             return (
                //                 moment(start).isBetween(eventStart, eventEnd) ||
                //                 moment(start).isSame(eventStart, "d") ||
                //                 moment(start).isSame(eventEnd, "D")
                //             );
                //         });
                //     }
                //     if (thisDayEvent?.length > 0) {
                //         const thisDayChecktime = thisDayEvent?.[0];
                //         onChangeData("openCheckTimeModal", {
                //             open: true,
                //             checktimeId: thisDayChecktime?.timelineId,
                //         });
                //     }
                // }}
            />
        </div>
    );
};

export default CalendarBody;

export interface ICalendarEventItemProps
    extends Partial<Omit<EventWrapperProps, "event">> {
    event: ICalendarEvent;
    classNameContainer?: string;
}

export interface ICalendarEventWrapperProps {
    classNameContainer?: string;
    event?: ICalendarEvent;
    selected?: boolean;
    [key: string]: any;
}

export const classNameEventShare =
    "rounded-sm px-2 text-x-small text-white flex items-center justify-between";

export const CalendarEventItem: React.FC<ICalendarEventItemProps> = ({
    event,
    classNameContainer,
    ...rest
}) => {
    const { calendarFilterState } = React.useContext(CalendarContext);
    const { calendarSubject } = calendarFilterState || {};
    const { title, resource, start } = event || {};

    const leftView = () => {
        const doctor: any = find(resource?.adminUsers, (item) => item?.isHost);
        let avatarView = <div />;
        if (doctor) {
            avatarView = (
                <Avatar
                    src={doctor?.adminUser?.avatar}
                    text={doctor?.adminUser.firstName}
                    variant="rounded"
                    size="xx-small"
                    className="mr-1"
                />
            );
        }
        return (
            <div className="flex-center-y">
                {calendarSubject?.id === JobType.DOCTOR && avatarView}
                <div className="text-nowrap" style={{ maxWidth: "80px" }}>
                    {title || "N/A"}
                </div>
            </div>
        );
    };

    return (
        <div
            className={`${classNameEventShare} ${classNameContainer}`}
            {...rest}
        >
            {leftView()}
            <div className="text-nowrap" style={{ maxWidth: "50px" }}>
                {TimeUtils.convertMiliToTime(start)}
            </div>
        </div>
    );
};

export const CalendarEventWrapper: React.FC<ICalendarEventWrapperProps> = ({
    children,
    classNamePopOver,
    ...rest
}) => {
    const idContainer = useRef<string>(StringUtils.getUniqueID()).current;
    const [containerStyle, setContainerStyle] = useState<React.CSSProperties>();
    const { event, selected, label } = rest || {};
    const [showEventDetail, setShowEventDetail] = useState(false);
    const content = <CalendarEventPopoverDetail event={event} />;

    useEffect(() => {
        const popoverContainer = document.getElementById(idContainer);
        if (!popoverContainer) {
            return;
        }
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const marginLeft = popoverContainer?.getBoundingClientRect()?.left ?? 0;
        const marginBottom =
            popoverContainer?.getBoundingClientRect()?.top ?? 0;
        const isLeftSide = marginLeft / windowWidth < 0.5;
        const isBottomSie = marginBottom / windowHeight > 0.65;
    }, [showEventDetail, idContainer]);

    return (
        <Popover
            content={content}
            open={showEventDetail}
            trigger="hover"
            onOpenChange={(open) => setShowEventDetail(open)}
            placement="left"
            overlayClassName={`${classNamePopOver} ${ClassNames({
                "calendar-event-wrapper_popover-selected": showEventDetail,
            })}`}
            overlayStyle={{ ...containerStyle }}
            {...rest}
            showArrow
            align={{ offset: ["100%", 0] as any }}
            id={idContainer}
        >
            {children}
        </Popover>
    );
};

export const CalendarEventPopoverDetail: React.FC<{
    event?: ICalendarEventWrapperProps["event"];
}> = ({ event }) => {
    const { resource } = event || {};
    const {
        ref,
        treatment,
        booking,
    } = resource || {};

    const renderContent = () => {
        switch (ref) {
            case CalendarRef.Treatment:
                return (
                    <CalendarEventPopoverTreatment
                        treatment={treatment as any}
                    />
                );
            case CalendarRef.Booking:
                return <CalendarEventPopoverBooking booking={booking as any} />;
            default:
                return <div />;
        }
    };

    return (
        <div className="w-full max-w-3xl" style={{ minWidth: "13rem" }}>
            {renderContent()}
        </div>
    );
};

function renderRow(
    left: any,
    right?: any,
    option?: {
        showBorder?: boolean;
        style?: React.CSSProperties;
        className?: string;
        classNameLeft?: string;
        classNameRight?: string;
    }
) {
    return (
        <div
            className={`flex-center-y justify-between py-2 text-white ${
                option?.className
            } ${ClassNames({
                "border-gray-50 border-b": option?.showBorder,
            })}`}
        >
            {left && <div className={option?.classNameLeft}>{left}</div>}
            {right && <div>{right}</div>}
        </div>
    );
}

const CalendarEventPopoverBooking: React.FC<{ booking: IBooking }> = ({
    booking,
}) => {
    const { bookingNo, doctor, services, endDate, remark, startDate } =
        booking || {};

    return (
        <React.Fragment>
            {renderRow(
                Messages.booking,
                TimeUtils.convertMiliToTime(startDate),
                {
                    showBorder: true,
                }
            )}
            {renderRow(
                <UserAvatarName
                    classNameText="text-white"
                    size="xx-small"
                    user={doctor as any}
                    showInfo={{ showEmployeeId: false, showOPD: false }}
                />,
                null,
                { showBorder: true }
            )}

            {renderRow(
                <React.Fragment>
                    <Icon
                        name="calendar_month"
                        color="white"
                        className="mr-2"
                        size="x-large"
                    />
                    <div>
                        {`${TimeUtils.convertMiliToTime(
                            startDate
                        )} - ${TimeUtils.convertMiliToTime(endDate)}`}
                    </div>
                </React.Fragment>,
                null,
                { classNameLeft: "flex-center-y", showBorder: true }
            )}
            {renderRow(
                <React.Fragment>
                    <Link
                        to={generatePath(Path.BOOKING_DETAIL, {
                            bookingId: booking?.id,
                        })}
                        className="flex-center-y text-warning"
                        target="_blank"
                    >
                        <Icon
                            name="description"
                            color="white"
                            className="mr-2"
                            size="x-large"
                        />
                        {bookingNo}
                    </Link>
                </React.Fragment>,
                null,
                {
                    classNameLeft: "flex-center-y",
                    showBorder: !!remark || !!services?.length,
                }
            )}
            {remark &&
                renderRow(`${Messages.remark}: "${remark}"`, null, {
                    classNameLeft: "flex-center-y",
                    showBorder: !!services.length,
                })}
            {services?.length > 0 &&
                services.map((service) => {
                    return renderRow(
                        <span className="">
                            {Messages.serviceName}:{` ${service?.name}`}
                        </span>,
                        null,
                        {
                            classNameLeft: "flex-center-y",
                            showBorder: true,
                        }
                    );
                })}
        </React.Fragment>
    );
};

const CalendarEventPopoverTreatment: React.FC<{ treatment: ITreatment }> = ({
    treatment,
}) => {
    const { treatmentNo, doctor, services, endDate, startDate } =
        treatment || {};

    return (
        <React.Fragment>
            {renderRow(
                Messages.treatment,
                TimeUtils.convertMiliToTime(startDate),
                {
                    showBorder: true,
                }
            )}
            {renderRow(
                <div className="flex-center-y">
                    <UserAvatarName
                        classNameText="text-white"
                        size="xx-small"
                        user={doctor?.adminUser as any}
                        showInfo={{ showEmployeeId: false, showOPD: false }}
                    />
                </div>,
                null,
                { showBorder: true }
            )}
            {renderRow(
                <React.Fragment>
                    <Icon
                        name="calendar_month"
                        color="white"
                        className="mr-2"
                        size="x-large"
                    />
                    <div>
                        {`${TimeUtils.convertMiliToTime(
                            startDate
                        )} - ${TimeUtils.convertMiliToTime(endDate)}`}
                    </div>
                </React.Fragment>,
                null,
                { classNameLeft: "flex-center-y", showBorder: true }
            )}
            {renderRow(
                <React.Fragment>
                    <Link
                        to={generatePath(Path.TREATMENT_DETAIL, {
                            treatmentId: treatment?.id,
                        })}
                        className="flex-center-y text-warning"
                        target="_blank"
                    >
                        <Icon
                            name="description"
                            color="white"
                            className="mr-2"
                            size="x-large"
                        />
                        {treatmentNo}
                    </Link>
                </React.Fragment>,
                null,
                {
                    classNameLeft: "flex-center-y",
                    showBorder: !!services.length,
                }
            )}
            {services?.length > 0 &&
                services.map((service, index) => {
                    return renderRow(
                        <span className="">
                            {Messages.serviceName}:
                            {` ${service?.product?.name}`}
                        </span>,
                        null,
                        {
                            classNameLeft: "flex-center-y",
                            showBorder: index !== services.length - 1,
                        }
                    );
                })}
        </React.Fragment>
    );
};
