import {
    AwesomeTableComponent,
    DialogManager,
    Dropdown,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, findIndex, includes, isEmpty, map, some } from "lodash";
import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import {
    JobCostTemplate,
    PurchaseType,
    useUpdateServiceConfigurationMutation,
    useUpdateServiceJobCostMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { PURCHASE_TYPES } from "../../../constant/quotation";
import {
    FEE_TYPES,
    FEE_UNITS,
    JOB_COST_TYPES,
} from "../../../constant/service";
import { JOB_TYPES } from "../../../constant/user";
import { ServiceConfigContext } from "../../../context/service";
import { ServiceJobCostSchema } from "../../../formschema/service";
import {
    IJobCost,
    IJobCostFee,
    getJobCostTemplateLabelById,
    mapServiceJobCostToServer,
} from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import Tooltip from "../../../common/views/Tooltip";

const DragHandle = SortableHandle(() => (
    <Icon name="menu" className="cursor-pointer" />
));

interface IServiceConfigJobCostCrudDrawer {
    open: boolean;
    onClose: () => void;
    value?: IJobCost;
}

const ServiceConfigJobCostCrudDrawer = ({
    open,
    onClose,
    value,
}: IServiceConfigJobCostCrudDrawer) => {
    const { configuration, setConfiguration } =
        useContext(ServiceConfigContext);

    const [updateServiceJobCost] = useUpdateServiceJobCostMutation();

    const configForm = useFormik<IJobCost>({
        initialValues: { ...(value || {}) } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ServiceJobCostSchema,
        onSubmit: (values: any) => {
            const input = mapServiceJobCostToServer(values);
            onUpdateJobCost(input);
        },
    });

    const {
        values: formValues,
        errors: formErrors,
        setFieldValue,
    } = configForm;

    const classNameInput = "col-12 mt-3";

    const onUpdateJobCost = (newJobCost: any) => {
        Progress.show(
            {
                method: updateServiceJobCost,
                params: [
                    {
                        variables: {
                            serviceConfigId: configuration?.id,
                            payload: newJobCost,
                        },
                    },
                ],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                const newConfig = res?.data?.data?.data;
                setConfiguration(newConfig);
                onClose();
            }
        );
    };

    const onChangeFeeValue = (feeKey: string, value: any) => {
        setFieldValue(feeKey, value);
    };

    const renderInputFee = (feeKey: string) => {
        const feeValues: any = formValues?.[feeKey as keyof IJobCost] ?? {};
        const isAdditional = feeKey === "secondaryFee";

        return (
            <Fragment>
                <InputSelectForm
                    form={configForm}
                    keyData="feeType"
                    label={
                        isAdditional
                            ? Messages.additionalFee
                            : Messages.primaryFee
                    }
                    dataSource={FEE_TYPES}
                    className={classNameInput}
                    value={feeValues?.feeType}
                    onChange={(v) =>
                        onChangeFeeValue(feeKey, { ...feeValues, feeType: v })
                    }
                />
                <InputSelectForm
                    form={configForm}
                    keyData="unit"
                    label={
                        isAdditional
                            ? Messages.unitOfAdditionalFee
                            : Messages.unit
                    }
                    dataSource={FEE_UNITS}
                    className={classNameInput}
                    value={feeValues?.unit}
                    onChange={(v) =>
                        onChangeFeeValue(feeKey, { ...feeValues, unit: v })
                    }
                />
                <InputTextForm
                    keyData="unitQty"
                    form={configForm}
                    className={classNameInput}
                    label={
                        isAdditional
                            ? Messages.unitQtyOfAdditionalFee
                            : Messages.unitQty
                    }
                    value={feeValues?.unitQty}
                    onChange={(e) =>
                        onChangeFeeValue(feeKey, {
                            ...feeValues,
                            unitQty: e?.target?.value,
                        })
                    }
                />
                {feeValues?.isFlexible ? (
                    <InputTextForm
                        keyData="amount"
                        form={configForm}
                        value={Messages.flexible}
                        className={classNameInput}
                        disabled
                    />
                ) : (
                    <InputTextForm
                        keyData="amount"
                        form={configForm}
                        className={classNameInput}
                        label={
                            isAdditional
                                ? Messages.amountOfAdditionalFee
                                : Messages.amount
                        }
                        value={feeValues?.amount}
                        onChange={(e) =>
                            onChangeFeeValue(feeKey, {
                                ...feeValues,
                                amount: e?.target?.value,
                            })
                        }
                    />
                )}
                {isAdditional && (
                    <InputTextForm
                        keyData="triggerThresholdQty"
                        form={configForm}
                        className={classNameInput}
                        label={Messages.requiredUnitQtyTriggeringFee}
                        value={feeValues?.triggerThresholdQty}
                        onChange={(e) =>
                            onChangeFeeValue(feeKey, {
                                ...feeValues,
                                triggerThresholdQty: e?.target?.value,
                            })
                        }
                    />
                )}
            </Fragment>
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={
                (Messages as any)[
                    find(JOB_COST_TYPES, { id: formValues?.type })?.label ?? ""
                ] ?? Messages.jobCost
            }
            onSave={() => {
                configForm.handleSubmit();
            }}
        >
            <div className="p-3">
                <InputSelectForm
                    disabled
                    label={Messages.type}
                    form={configForm}
                    keyData="type"
                    dataSource={JOB_COST_TYPES}
                    className={classNameInput}
                />
                <InputSelectForm
                    disabled
                    label={Messages.role}
                    form={configForm}
                    keyData="role"
                    dataSource={JOB_TYPES}
                    className={classNameInput}
                />
                <InputSelectForm
                    disabled
                    label={Messages.purchaseType}
                    form={configForm}
                    keyData="purchaseType"
                    dataSource={PURCHASE_TYPES}
                    className={classNameInput}
                />
                {renderInputFee("primaryFee")}
                {formValues?.secondaryFee && renderInputFee("secondaryFee")}
            </div>
        </Drawer>
    );
};

function arrayMove(array: any[], from: number, to: number) {
    const result = [...array];
    if (to === from) return result;

    const target = result[from];
    const increment = to < from ? -1 : 1;

    for (let k = from; k !== to; k += increment) {
        result[k] = result[k + increment];
    }
    result[to] = target;

    return result;
}

const ServiceConfigJobCost = () => {
    const { configuration, setConfiguration } =
        useContext(ServiceConfigContext);
    const { jobCostTemplate, jobCost, isModifiedJobCost } = configuration || {};
    const [activeView, setActiveView] = useState();

    const [openEditModal, setOpenEditModal] = useState<{
        open: boolean;
        data?: any;
    }>({ open: false });
    const [jobCostList, setJobCostList] = useState<IJobCost[]>(jobCost);
    const [updateServiceConfig] = useUpdateServiceConfigurationMutation();
    const addSecondaryColumns = useMemo(() => {
        return some(jobCostList, (item) => !!item?.secondaryFee);
    }, [jobCostList]);
    const [filterData, setFilterData] = useState<any>({
        purchaseType: [PURCHASE_TYPES[0]],
    });

    useEffect(() => {
        if (
            some(
                Object.keys(filterData || {}),
                (key) => !isEmpty(filterData?.[key])
            )
        ) {
            let res = [...(jobCost ?? [])];
            Object.keys(filterData).forEach((key) => {
                if (!isEmpty(filterData?.[key])) {
                    const ids = map(filterData?.[key], (item) => item?.id);
                    res = filter(res, (item: any) => {
                        if (
                            [
                                JobCostTemplate.Jc2Template,
                                JobCostTemplate.Jc3Template,
                            ].includes(jobCostTemplate)
                        ) {
                            if (
                                key === "purchaseType" &&
                                item?.[key] === null
                            ) {
                                return some(ids, (id) =>
                                    [
                                        PurchaseType.Complication,
                                        PurchaseType.Marketing,
                                        PurchaseType.Normal,
                                        PurchaseType.Staff,
                                        PurchaseType.Vip,
                                    ].includes(id)
                                );
                            }
                        }
                        return includes(ids, item?.[key]);
                    });
                }
            });
            setJobCostList(res || []);
        } else {
            setJobCostList(jobCost);
        }
    }, [jobCost, filterData]);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            const newJobCost = arrayMove(
                jobCostList as any,
                oldIndex,
                newIndex
            );

            setJobCostList(newJobCost);

            const jobCostListUpdated = map(
                newJobCost,
                mapServiceJobCostToServer
            );
            updateServiceConfig({
                variables: {
                    id: configuration?.id,
                    payload: {
                        jobCost: jobCostListUpdated,
                    } as any,
                },
            })
                .then((res) => {
                    Notifications.showSuccess(Messages.updateSuccess);
                    const newConfig = res?.data?.data?.data;
                    setConfiguration(newConfig);
                })
                .catch(() => {
                    setJobCostList(jobCost);
                });
        }
    };

    const DraggableContainer = (props: any) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({
        className,
        style,
        ...restProps
    }) => {
        const index = findIndex(
            jobCostList,
            (item) => `${item.id}` === restProps["data-row-key"]
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const columns: IColumnsProps = [
        {
            title: (
                <Dropdown
                    buttonProps={{ iconName: "expand_more" }}
                    dataSource={JOB_COST_TYPES}
                    position="left-edge"
                    multiple
                    value={filterData?.type ?? []}
                    showSelectIndicator
                    onChange={(v) => {
                        setFilterData({
                            ...(filterData || {}),
                            type: v,
                        });
                    }}
                    Messages={Messages}
                >
                    <div className="hover:cursor-pointer flex flex-row">
                        <Icon name="expand_more" />
                        {Messages.type}
                    </div>
                </Dropdown>
            ),
            dataIndex: "type",
            render: (data, item, index) => {
                return (
                    Messages[find(JOB_COST_TYPES, { id: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.role,
            dataIndex: "role",
            render: (data, item, index) => {
                return (
                    Messages[find(JOB_TYPES, { key: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: (
                <Dropdown
                    dataSource={PURCHASE_TYPES}
                    multiple
                    value={filterData?.purchaseType ?? []}
                    showSelectIndicator
                    onChange={(v) => {
                        setFilterData({
                            ...(filterData || {}),
                            purchaseType: v,
                        });
                    }}
                    Messages={Messages}
                >
                    <div className="hover:cursor-pointer flex flex-row">
                        <Icon name="expand_more" />
                        {Messages.purchaseType}
                    </div>
                </Dropdown>
            ),
            dataIndex: "purchaseType",
            render: (data, item, index) => {
                if (data === null) {
                    return Messages.noAgency;
                }
                return (
                    Messages[find(PURCHASE_TYPES, { id: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.primaryFee,
            dataIndex: "primaryFee",
            render: (data: IJobCostFee, item, index) => {
                const { feeType } = data || ({} as IJobCostFee);
                return find(FEE_TYPES, { id: feeType })?.label ?? ("" || "N/A");
            },
        },
        {
            title: Messages.unit,
            dataIndex: "primaryFee",
            render: (data: IJobCostFee, item, index) => {
                const { unit } = data || {};
                return (
                    Messages[find(FEE_UNITS, { id: unit })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.unitQty,
            dataIndex: "primaryFee",
            render: (data: IJobCostFee, item, index) => {
                const { unitQty } = data || {};
                return unitQty;
            },
        },
        {
            title: Messages.amount,
            dataIndex: "primaryFee",
            align: "center",
            render: (data: IJobCostFee, item, index) => {
                const { amount, isFlexible } = data || {};
                if (isFlexible) {
                    return Messages.flexible;
                }
                return amount;
            },
        },
        {
            title: Messages.action,
            dataIndex: "",
            width: 100,
            align: "center",
            render: (jobCost, item, index) => (
                <Icon
                    name="edit"
                    onClick={() => {
                        setOpenEditModal({ open: true, data: item });
                    }}
                    className="cursor-pointer"
                />
            ),
        },
    ];

    if (addSecondaryColumns) {
        const secondaryFees: IColumnsProps = [
            {
                title: Messages.additionalFee,
                titleTooltip: "Secondary Fee Type - ",
                dataIndex: "secondaryFee",
                render: (data: IJobCostFee, item, index) => {
                    const { feeType } = data || ({} as IJobCostFee);
                    return (
                        find(FEE_TYPES, { id: feeType })?.label ?? ("" || "N/A")
                    );
                },
            },
            {
                title: Messages.unitOfAdditionalFee,
                dataIndex: "secondaryFee",
                render: (data: IJobCostFee, item, index) => {
                    const { unit } = data || {};
                    return (
                        Messages[find(FEE_UNITS, { id: unit })?.label ?? ""] ||
                        "N/A"
                    );
                },
            },
            {
                title: Messages.unitQtyOfAdditionalFee,
                dataIndex: "secondaryFee",
                render: (data: IJobCostFee, item, index) => {
                    const { unitQty } = data || {};
                    return unitQty || "N/A";
                },
            },
            {
                title: Messages.amountOfAdditionalFee,
                dataIndex: "secondaryFee",
                align: "center",
                render: (data: IJobCostFee, item, index) => {
                    const { amount } = data || {};
                    return amount || "N/A";
                },
            },
            {
                title: Messages.requiredUnitQtyTriggeringFee,
                dataIndex: "secondaryFee",
                align: "center",
                render: (data: IJobCostFee, item, index) => {
                    const { triggerThresholdQty } = data || {};
                    return triggerThresholdQty || "N/A";
                },
            },
        ];
        columns.splice(columns.length - 1, 0, ...secondaryFees);
    }

    const onRemoveJobCost = (jobCostIndex: number) => {
        const newJobCost = filter(
            jobCostList,
            (item, index) => index !== jobCostIndex
        );
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: updateServiceConfig,
                        params: [
                            {
                                variables: {
                                    id: configuration?.id,
                                    payload: {
                                        jobCost: map(
                                            newJobCost,
                                            mapServiceJobCostToServer
                                        ),
                                    } as any,
                                },
                            },
                        ],
                    },
                    (res: any) => {
                        Notifications.showSuccess(Messages.removeSuccess);
                        setConfiguration(res?.data?.data?.data);
                    }
                )
        );
    };

    const SortableItem = SortableElement(
        (props: React.HTMLAttributes<HTMLTableRowElement>) => {
            return <tr {...props} />;
        }
    );

    const SortableBody = SortableContainer(
        (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
            <tbody {...props} />
        )
    );

    return (
        <Fragment>
            <div className="flex-row-between-center mb-3">
                <h5>
                    {Messages.jobCostSetting}
                    {` - ${getJobCostTemplateLabelById(jobCostTemplate)}`}
                    {isModifiedJobCost ? <span> - Modified</span> : null}
                </h5>
            </div>
            <AwesomeTableComponent
                className="job-cost-preset-table"
                columns={columns}
                dataSource={jobCostList}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                pagination={false}
                rowKey={(item) => `${item.name}_${item.quantity}`}
            />
            {openEditModal?.open && (
                <ServiceConfigJobCostCrudDrawer
                    value={openEditModal?.data}
                    open={openEditModal.open}
                    onClose={() => setOpenEditModal({ open: false })}
                />
            )}
        </Fragment>
    );
};

export default ServiceConfigJobCost;
