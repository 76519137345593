import {
    DeliveryConfirmationStatus,
    DeliveryStatus,
    DeliveryTransferPurpose,
    DeliveryType,
} from "../api/hooks";

export const DELIVERY_DRIVER_STATUSES = [
    {
        id: DeliveryStatus.DeliveryPending,
        label: "deliveryPending",
        color: "#FAC256",
    },

    {
        id: DeliveryStatus.DeliveryBooked,
        label: "deliveryBooked",
        color: "#FAC256",
    },
    {
        id: DeliveryStatus.InDelivery,
        label: "inDelivery",
        color: "#84C4FF",
    },
    {
        id: DeliveryStatus.DeliveryCompleted,
        label: "deliveryCompleted",
        color: "#33B950",
    },
    {
        id: DeliveryStatus.DeliveryCancelled,
        label: "deliveryCancelled",
        color: "#C4C4C4",
    },
];

export const DELIVERY_DRIVER_PURPOSES = [
    {
        id: DeliveryTransferPurpose.EquipmentTransfer,
        label: "equipmentTransfer",
    },
    {
        id: DeliveryTransferPurpose.StockTransfer,
        label: "stockTransfer",
    },
];

export const DELIVERY_DRIVER_TYPES = [
    {
        id: DeliveryType.InHouse,
        label: "inHouse",
    },
    {
        id: DeliveryType.OutSource,
        label: "outSource",
    },
];

export const DELIVERY_DRIVER_CONFIRMATION_STATUSES = [
    {
        id: DeliveryConfirmationStatus.Pending,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: DeliveryConfirmationStatus.Completed,
        label: "completed",
        color: "#33B950",
    },
];
