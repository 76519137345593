import React from "react";
import ClassNames from "classnames";
import Messages from "../../../languages/Messages";
import { usePromotionCreateForm } from "../../../context/promotion";

export interface IPromotionCreateSideViewProps {
    [key: string]: any;
}

const PromotionCreateSideView: React.FC<IPromotionCreateSideViewProps> = ({
    id,
}) => {
    const { values, errors } = usePromotionCreateForm();

    const STEPS = [
        {
            id: "generalInformation",
            label: "generalInformation",
            isActive: () => {
                return (
                    !!values?.branch?.length &&
                    !!values?.title &&
                    !!values?.start &&
                    !!values?.end
                );
            },
        },
        {
            id: "listOfItems",
            label: "listOfItems",
            isActive: () => {
                return !!values?.products?.length;
            },
        },
        {
            id: "documents",
            label: "documents",
            isActive: () => {
                return !!values?.documents?.length;
            },
        },
    ];

    const renderStep = (step: any, idx: number, isActive: boolean) => {
        const { id, label } = step;
        return (
            <div
                className={`py-4 flex-center-y gap-4 ${ClassNames({
                    "border-t": idx !== 0,
                })}`}
            >
                <div
                    className={`bg-white !border !border-gray-300 text-center px-2 py-2 rounded-full w-[30px] h-[30px] flex flex-col justify-center items-center ${ClassNames(
                        { "bg-primary": isActive }
                    )}`}
                >
                    <div
                        className={`${ClassNames({ "text-white": isActive })}`}
                    >
                        {idx + 1}
                    </div>
                </div>
                <div
                    className={`${ClassNames({
                        "text-primary font-bold": isActive,
                    })}`}
                >
                    {Messages[label]}
                </div>
            </div>
        );
    };

    return (
        <div className="promotion-create-side-view p-4">
            {STEPS.map((step, idx) => {
                const active = !!step?.isActive?.();
                return <div className="">{renderStep(step, idx, active)}</div>;
            })}
        </div>
    );
};

export default PromotionCreateSideView;
