import classNames from "classnames";
import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find, split } from "lodash";
import { useContext, useState } from "react";
import { useUpdateServiceConfigurationMutation } from "../../../api/hooks";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import {
    JOB_COST_TEMPLATES,
    SERVICE_SURGERY_TYPES,
} from "../../../constant/service";
import { ServiceConfigContext } from "../../../context/service";
import { ServiceConfigSchema } from "../../../formschema/service";
import {
    IServiceConfiguration,
    mapServiceConfigToServer,
} from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import { getHhMmFromMinutes } from "../../../utils/Utils";
import ServiceConfigInfoForm from "../share/ServiceConfigInfoForm";

interface IEditGeneralInfoDrawer {
    open: boolean;
    config: any;
    onClose: () => void;
    onUpdated: any;
}

const EditGeneralInfoDrawer = ({
    config,
    open,
    onClose,
    onUpdated,
}: IEditGeneralInfoDrawer) => {
    const [updateServiceConfiguration] =
        useUpdateServiceConfigurationMutation();

    const configForm = useFormik<any>({
        initialValues: config as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ServiceConfigSchema,
        onSubmit: (values: any) => {
            const input = mapServiceConfigToServer(values);
            onUpdateConfig(input);
        },
    });

    const onUpdateConfig = (payload: any) => {
        Progress.show(
            {
                method: updateServiceConfiguration,
                params: [{ variables: { id: config?.id, payload } }],
            },
            (res: any) => {
                const config = res?.data?.data?.data;
                onUpdated && onUpdated(config);
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.generalInformation}
            onSave={() => configForm.handleSubmit()}
            size="large"
        >
            <ServiceConfigInfoForm
                configForm={configForm}
                showDescription={false}
            />
        </Drawer>
    );
};

const GENERAL_INFO_KEYS: IRowsKey<IServiceConfiguration>[] = [
    {
        id: "serviceNo",
        label: Messages.id,
    },
    {
        id: "name",
        label: Messages.name,
    },
    {
        id: "category",
        label: Messages.category,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "procedureTime",
        label: Messages.procedureTime,
        renderContent: ({ data }) => {
            const HhMm = getHhMmFromMinutes(data);
            const arr = split(HhMm, ":");
            return `${arr?.[0]} ${Messages.hours} ${arr?.[1]} ${Messages.minutes}`;
        },
    },
    {
        id: "jobCostTemplate",
        label: Messages.jobCostTemplate,
        renderContent: ({ data }) => {
            return (
                Messages?.[
                    find(JOB_COST_TEMPLATES, (item) => item?.id === data)
                        ?.label ?? ""
                ] ?? "N/A"
            );
        },
    },
    {
        id: "defaultSaleCommission",
        label: Messages.defaultSaleCommission,
        renderContent: ({ data }) => `${data}%`,
    },
    {
        id: "surgeryType",
        label: Messages.surgeryType,
        renderContent: ({ data }) => {
            return (
                Messages?.[
                    find(SERVICE_SURGERY_TYPES, (item) => item?.id === data)
                        ?.label ?? ""
                ] ?? "N/A"
            );
        },
    },
    {
        id: "createByAdmin",
        label: Messages.createdBy,
        renderContent: ({ data }) => (
            <UserAvatarName user={data} size="x-small" />
        ),
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
    {
        id: "updatedAt",
        label: Messages.updatedAt,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
];

const ServiceConfigGeneral = () => {
    const { configuration, setConfiguration, setOpenJobCostPresetDrawer } =
        useContext(ServiceConfigContext);
    const [showEdit, setShowEdit] = useState(false);

    const GENERAL_INFO_KEYS: IRowsKey<IServiceConfiguration>[] = [
        {
            id: "serviceNo",
            label: Messages.id,
        },
        {
            id: "name",
            label: Messages.name,
        },
        {
            id: "category",
            label: Messages.category,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "procedureTime",
            label: Messages.procedureTime,
            renderContent: ({ data }) => {
                const HhMm = getHhMmFromMinutes(data);
                const arr = split(HhMm, ":");
                return `${arr?.[0]} ${Messages.hours} ${arr?.[1]} ${Messages.minutes}`;
            },
        },
        {
            id: "jobCostTemplate",
            label: Messages.jobCostTemplate,
            renderContent: ({ data }) => {
                return (
                    <div
                        className="hover:cursor-pointer hover:!text-blue-500 text-primary h5"
                        onClick={() => {
                            if (!data) return;
                            setOpenJobCostPresetDrawer({
                                open: true,
                                template: data,
                            });
                        }}
                    >
                        {Messages?.[
                            find(
                                JOB_COST_TEMPLATES,
                                (item) => item?.id === data
                            )?.label ?? ""
                        ] ?? "N/A"}{" "}
                        {configuration?.isModifiedJobCost ? (
                            <span> - Modified</span>
                        ) : null}
                    </div>
                );
            },
        },
        {
            id: "defaultSaleCommission",
            label: Messages.defaultSaleCommission,
            renderContent: ({ data }) => `${data}%`,
        },
        {
            id: "surgeryType",
            label: Messages.surgeryType,
            renderContent: ({ data }) => {
                return (
                    Messages?.[
                        find(SERVICE_SURGERY_TYPES, (item) => item?.id === data)
                            ?.label ?? ""
                    ] ?? "N/A"
                );
            },
        },
        {
            id: "createByAdmin",
            label: Messages.createdBy,
            renderContent: ({ data }) => (
                <UserAvatarName user={data} size="x-small" />
            ),
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
        {
            id: "updatedAt",
            label: Messages.updatedAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
    ];

    const renderHeader = (title: any, onClickEdit: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                <Button
                    onClick={onClickEdit}
                    className=""
                    variant="trans"
                    size="small"
                    color="red"
                >
                    {Messages.edit}
                </Button>
            </div>
        );
    };

    return (
        <div className="flex-column">
            {renderHeader(Messages.generalInformation, () => setShowEdit(true))}
            <div className="border p-3">
                <ViewRowInterchange
                    keyList={GENERAL_INFO_KEYS}
                    dataSource={configuration}
                    variant="background"
                />
            </div>
            {showEdit && (
                <EditGeneralInfoDrawer
                    config={{
                        ...configuration,
                        procedureTime: getHhMmFromMinutes(
                            configuration?.procedureTime as any
                        ),
                    }}
                    open={showEdit}
                    onClose={() => setShowEdit(false)}
                    onUpdated={setConfiguration}
                />
            )}
        </div>
    );
};

export default ServiceConfigGeneral;
