import {
    AwesomeTableComponent,
    IColumnsProps,
    InputTextSearch,
    StringUtils,
    TimeUtils,
} from "d-react-components";
import { debounce } from "lodash";
import { useContext, useRef } from "react";
import { generatePath } from "react-router";
import CustomerAPI from "../../../api/queries/customer";
import AppLink from "../../../common/AppLink";
import { CustomerDetailContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { IVoucher } from "../../../interfaces/voucher";
import { VoucherCodeView } from "./CustomerDetailServiceHistory";

const CustomerDetailCredits = () => {
    const tableRef = useRef<any>();
    const searchRef = useRef<string>();
    const { customer } = useContext(CustomerDetailContext);

    const { totalCredit } = customer || {};

    const columns: IColumnsProps = [
        {
            title: Messages.creditNo,
            dataIndex: "creditNo",
            render: (creditNo) => creditNo,
        },
        {
            title: Messages.ref,
            dataIndex: "vouchers",
            render: (voucherList: IVoucher[], item) => {
                return (
                    <VoucherCodeView
                        voucherList={
                            item.voucher ? [item.voucher] : voucherList
                        }
                    />
                );
            },
        },
        {
            title: Messages.creditAmount,
            dataIndex: "value",
        },
        {
            title: Messages.status,
            dataIndex: "status",
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.expiresAt,
            dataIndex: "expiryDate",
            render: (expiryDate) =>
                expiryDate ? TimeUtils.toDateTime(expiryDate) : "N/A",
        },
    ];

    const source = (paging: any) => {
        return CustomerAPI.creditList(customer?.id ?? "", paging, {
            search: searchRef.current,
            sort: {
                createdAt: -1,
            },
        });
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    return (
        <div className="border mt-3 p-4">
            <div className="d-flex flex-row align-items-center">
                <h5>{Messages.credits}</h5>
                <div className="ml-2 px-2 py-1 rounded border-primary border text-primary">
                    {`${Messages.totalAvailability}: ${StringUtils.moneyFormat(
                        totalCredit
                    )}`}
                </div>
            </div>
            <InputTextSearch className="mt-3" onChange={onChangeSearch} />
            <AwesomeTableComponent
                className="mt-3"
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
                ref={(ref) => {
                    tableRef.current = ref;
                }}
            />
        </div>
    );
};

export default CustomerDetailCredits;
