export const STOCK_REQUEST_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
};

export const STOCK_REQUEST_STATUSES = [
    { id: "PENDING", label: "pending", color: "#FAC256" },
    { id: "APPROVED", label: "approved", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
];

export const STOCK_REQUEST_TYPE = {
    REQUEST_STOCK_TO: "REQUEST_STOCK_TO",
    REQUEST_STOCK_FROM: "REQUEST_STOCK_FROM",
};

export const STOCK_REQUEST_TYPES = [
    { id: STOCK_REQUEST_TYPE.REQUEST_STOCK_FROM, label: "requestStockFrom" },
    { id: STOCK_REQUEST_TYPE.REQUEST_STOCK_TO, label: "requestStockTo" },
];
