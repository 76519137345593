import { SourceOfCustomer } from "../api/hooks";
import { CustomerStatus, PhoneNumberStatus } from "../interfaces/customer";

export const CUSTOMER_TITLES = [
    { id: "Mr", label: "Mr" },
    { id: "Mrs", label: "Mrs" },
    { id: "Miss", label: "Miss" },
    { id: "Ms", label: "Ms" },
];

export const GENDERS = [
    { id: "MALE", label: "male" },
    { id: "FEMALE", label: "female" },
    { id: "NOT_SAY", label: "preferNotToSay" },
];

export const CUSTOMER_TYPES = [
    { id: "INDIVIDUAL", label: "individual" },
    { id: "BUSINESS", label: "business" },
];

export const RELIGIONS = [
    { id: "No religion", label: "noReligion" },
    { id: "Buddists", label: "duddists" },
    { id: "Christians", label: "christians" },
    { id: "Hindus", label: "hindus" },
    { id: "Muslims", label: "muslims" },
];

export const MARITAL_STATUS = [
    { id: "Single", label: "single" },
    { id: "Married", label: "married" },
    { id: "Divorced", label: "divorced" },
];

export const TYPE_OF_PLACES = [
    { id: "private-house", label: "privateHouse" },
    { id: "condo-apartment", label: "condominium" },
    { id: "company-office", label: "companyOffice" },
];

export const BLOOD_GROUPS = [
    { id: "A+", label: "A+" },
    { id: "A-", label: "A-" },
    { id: "B+", label: "B+" },
    { id: "B-", label: "B-" },
    { id: "O+", label: "O+" },
    { id: "O-", label: "O-" },
    { id: "AB+", label: "AB+" },
    { id: "AB-", label: "AB-" },
];

export const CUSTOMER_SOURCES = [
    { id: SourceOfCustomer.Agency, label: "agency" },
    { id: SourceOfCustomer.CallIn, label: "callIn" },
    { id: SourceOfCustomer.Facebook, label: "facebook" },
    { id: SourceOfCustomer.Friend, label: "friendGetFriend" },
    { id: SourceOfCustomer.Instagram, label: "instagram" },
    { id: SourceOfCustomer.Internet, label: "internet" },
    { id: SourceOfCustomer.Line, label: "line" },
    { id: SourceOfCustomer.Magazine, label: "magazine" },
    { id: SourceOfCustomer.OldCustomer, label: "oldCustomer" },
    { id: SourceOfCustomer.Partner, label: "partner" },
    { id: SourceOfCustomer.Sms, label: "sms" },
    { id: SourceOfCustomer.Taxi, label: "taxi" },
    { id: SourceOfCustomer.Tiktok, label: "tiktok" },
    { id: SourceOfCustomer.TravelThrough, label: "travelThrough" },
    { id: SourceOfCustomer.Tv, label: "tv" },
    { id: SourceOfCustomer.WalkIn, label: "walkIn" },
    { id: SourceOfCustomer.Website, label: "website" },
];

export const CUSTOMER_STATUS = [
    { id: CustomerStatus.VERIFIED, label: "verified", color: "#33B950" },
    { id: CustomerStatus.UN_VERIFIED, label: "unVerified", color: "#FAC256" },
    { id: CustomerStatus.CANCELLED, label: "cancelled", color: "#C4C4C4" },
];

export const CUSTOMER_PHONE_NUMBER_STATUS = [
    { id: PhoneNumberStatus.VERIFIED, label: "verified", color: "#33B950" },
    {
        id: PhoneNumberStatus.UN_VERIFIED,
        label: "unVerified",
        color: "#FAC256",
    },
];

export const SIMPLE_CHOICES = [
    { id: true, label: "yes" },
    { id: false, label: "no" },
];
