import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
    useFirstTime,
} from "d-react-components";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
//@ts-ignore
import { JsonParam, useQueryParam } from "use-query-params";
import PromotionAPI from "../../../api/queries/promotion";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import InputSelectKey from "../../../common/input/InputSelectKey";
import {
    PROMOTIONS_FILTER_STATUSES,
    PROMOTIONS_SEARCH_KEYS,
    PROMOTIONS_STATUSES,
} from "../../../constant/promotion";
import {
    mapPromotionFilterToQueryParams,
    mapPromotionFilterToServer,
} from "../../../interfaces/promotion";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import BranchSelect from "../../branch/share/BranchSelect";
import BranchListDataButton from "../../branch/share/BranchListDataButton";

const PromotionTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [searchKey, setSearchKey] = useState<any>();
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);
    const navigate = useNavigate();

    const columns: IColumnsProps = [
        {
            title: Messages.title,
            dataIndex: "title",
            render: (title, item) => (
                <div>
                    <AppLink
                        to={generatePath(Path.PROMOTION_DETAIL, {
                            promotionId: item.id,
                        })}
                    >
                        {title}
                    </AppLink>
                    <div className="text-gray-500 text-xs">
                        {item?.promotionNo}
                    </div>
                </div>
            ),
        },
        {
            title: Messages.branches,
            dataIndex: "branch",
            width: "100px",
            render: (branch, item) => {
                return (
                    <BranchListDataButton
                        data={branch}
                        label={Messages.branches}
                    />
                );
            },
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    listStatus={PROMOTIONS_STATUSES}
                    status={status}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.period,
            dataIndex: "start",
            render: (start, data) => {
                return (
                    <div className="">
                        {TimeUtils.toDate(start)} -{TimeUtils.toDate(data?.end)}
                        <div className="!text-gray-500 text-xs">
                            {Math.abs(
                                moment(start).diff(moment(data?.end), "days")
                            )}
                            {Messages.days}
                        </div>
                    </div>
                );
            },
        },
        {
            title: Messages.createdAt,
            dataIndex: "createByAdmin",
            render: (user, item) => (
                <div className="">
                    {item?.createdAt ? (
                        <div className="">
                            {TimeUtils.toDateTime(item?.createdAt)}
                        </div>
                    ) : null}
                    <UserAvatarName
                        user={user}
                        size="xx-small"
                        variant="square"
                        showInfo={{ showOPD: false }}
                    />
                </div>
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, item)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterInput = mapPromotionFilterToServer(filter, searchKey);

        const body = {
            page: pagingData?.pageIndex,
            limit: pagingData?.pageSize,
            search: searchRef?.current,
            ...filterInput,
        };
        return PromotionAPI.list(body);
    };

    const onClickTableAction = (actionId: string, promotion: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.PROMOTION_DETAIL, {
                        promotionId: promotion.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.PROMOTION_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const renderHeader = useMemo(() => {
        return (
            <div className="w-full border px-3">
                <div className="flex-center-y p-3 border-b justify-between">
                    <div className="flex-center-y gap-3">
                        <h5 className="m-0">{Messages.status}</h5>
                        {PROMOTIONS_FILTER_STATUSES.map((status) => {
                            const isSelect =
                                filter?.status === status?.id ||
                                (status?.id === "all" && !filter?.status);
                            return (
                                <Button
                                    variant={isSelect ? "standard" : "outline"}
                                    onClick={() => {
                                        setFilter((prev: any) =>
                                            mapPromotionFilterToQueryParams({
                                                ...(prev || {}),
                                                status:
                                                    status?.id === "all"
                                                        ? undefined
                                                        : status?.id,
                                            })
                                        );
                                    }}
                                >
                                    {Messages[status?.label]}
                                </Button>
                            );
                        })}
                    </div>
                    <div className="flex-center-y">
                        <Button iconName="add" onClick={onClickAddNew}>
                            {Messages.new}
                        </Button>
                    </div>
                </div>
                <div className="d-flex flex-center-y gap-3 w-100 p-3">
                    <BranchSelect
                        hideLabel
                        onChange={(v) => {
                            setFilter((prev: any) =>
                                mapPromotionFilterToQueryParams({
                                    ...(prev || {}),
                                    branch: v,
                                })
                            );
                        }}
                        value={filter?.branch}
                        style={{ width: "300px" }}
                    />
                    <InputSelectKey
                        className="w-100"
                        placeholder="Search..."
                        selectKey={searchKey}
                        dataSource={PROMOTIONS_SEARCH_KEYS}
                        getLabel={(i) => Messages[i?.label]}
                        onChangeSelectKey={(v) => {
                            setSearchKey(v);
                            tableRef.current.refresh();
                        }}
                        onChangeText={(e) => {
                            onChangeSearch(e.target.value);
                        }}
                    />
                    {/* <InputText
                        className="w-100"
                        placeholder="Search..."
                        onChange={(e) => {
                            onChangeSearch(e.target.value);
                        }}
                    /> */}
                </div>
            </div>
        );
    }, [filter]);

    return (
        <div className="p-3">
            {renderHeader}
            <AwesomeTableComponent
                className="mt-4"
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
            />
        </div>
    );
};

export default PromotionTable;
