import classNames from "classnames";
import { InputText as Input, ObjectUtils, Select } from "d-react-components";
import { debounce, join, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import {
    useGetDistrictsLazyQuery,
    useGetProvincesLazyQuery,
    useGetSubDistrictsLazyQuery,
} from "../../../api/hooks";
import { TYPE_OF_PLACES } from "../../../constant/customer";
import {
    IAddressFormShow,
    IDistrict,
    IProvince,
    ISubdistrict,
} from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressMapSelect from "./AddressMapSelect";
import CountrySelect from "./CountrySelect";
// import AddressMapSelect from "./AddressMapSelect";

interface IAddressFormInput {
    formDataValue: any;
    formDataError: any;
    show?: IAddressFormShow;
    onChange: any;
}

const DEFAULT_SHOW = {
    typeOfPlace: true,
    remark: false,
    map: true,
    country: true,
    address: true,
};

const AddressFormInput = ({
    formDataValue,
    formDataError,
    show = {},
    onChange,
}: IAddressFormInput) => {
    const showField = { ...DEFAULT_SHOW, ...show };
    const [listCity, setListCity] = useState([]);
    const [listSubdistrict, setListSubdistrict] = useState<ISubdistrict[]>([]);
    const [listProvince, setListProvince] = useState<IProvince[]>([]);
    const [listPostcode, setListPostcode] = useState<string[]>([]);
    const [getProvinceList] = useGetProvincesLazyQuery();
    const [getDistrictList] = useGetDistrictsLazyQuery();
    const [getSubDistrictList] = useGetSubDistrictsLazyQuery();

    const [location, setLocation] = useState<any>({
        latitude: formDataValue.latitude,
        longitude: formDataValue.longitude,
    });

    useEffect(() => {
        if (showField.map && location) {
            onChangeValue({ ...location });
        }
    }, [location]);

    useEffect(() => {
        if (!formDataValue?.country) {
            return;
        }
        getProvinceList({
            variables: {
                countryId: formDataValue?.country?.id,
                paginate: { page: 1, limit: 10000 },
            },
        }).then((res) => {
            setListProvince(res?.data?.data?.data ?? ([] as any));
        });
    }, [formDataValue?.country]);

    useEffect(() => {
        if (!formDataValue?.province) {
            return;
        }
        getDistrictList({
            variables: {
                provinceId: formDataValue?.province?.id,
                paginate: { page: 1, limit: 10000 },
            },
        }).then((res) => {
            setListCity(res?.data?.data?.data ?? ([] as any));
        });
    }, [formDataValue?.province]);

    useEffect(() => {
        if (!formDataValue?.district) {
            return;
        }
        getSubDistrictList({
            variables: {
                districtId: formDataValue?.district?.id,
                paginate: { page: 1, limit: 10000 },
            },
        }).then((res) => {
            setListSubdistrict(res?.data?.data?.data ?? ([] as any));
        });
    }, [formDataValue?.district]);

    useEffect(() => {
        if (!formDataValue?.subDistrict || listSubdistrict.length === 0) {
            return;
        }
        const subDistrict: any = listSubdistrict.find(
            (item: ISubdistrict) => item.id === formDataValue?.subDistrict?.id
        );
        setListPostcode(subDistrict?.postcode ?? []);
    }, [formDataValue?.subDistrict, listSubdistrict]);

    const isFirstTime = useRef(true);
    useEffect(() => {
        if (
            (isFirstTime.current &&
                formDataValue?.latitude &&
                formDataValue?.longitude) ||
            !showField.map
        ) {
            isFirstTime.current = false;
            return;
        }
        loadLocation();
    }, [
        formDataValue?.province?.id,
        formDataValue?.district?.id,
        formDataValue?.subDistrict?.id,
    ]);

    const loadLocation = debounce(() => {
        const addressList = [
            formDataValue?.subDistrict?.name,
            formDataValue?.district?.name,
            formDataValue?.province?.name,
        ].filter((item) => !!item);
        const fulAddress = join(addressList, ",");
        // if (isEmpty(fulAddress)) {
        //     return;
        // }
        // API.getLocationFromAddress(fulAddress).then((res) => {
        //     const locationResult = res?.data?.results?.[0]?.geometry?.location;
        //     if (locationResult) {
        //         setLocation({
        //             latitude: locationResult.lat,
        //             longitude: locationResult.lng,
        //         });
        //     }
        // });
    }, 1000);

    const onChangeValue = (values = {}) => {
        onChange({ ...formDataValue, ...values });
    };

    const onChangeProvince = (id: string) => {
        const provinceValue = ObjectUtils.findItemFromId(listProvince, id);
        onChangeValue({
            province: provinceValue,
            district: null,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeDistrict = (id: string) => {
        const districtValue = ObjectUtils.findItemFromId(listCity, id);
        onChangeValue({
            ...formDataValue,
            district: districtValue,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeSubDistrict = (id: string) => {
        const subDistrictValue = ObjectUtils.findItemFromId(
            listSubdistrict,
            id
        );
        onChangeValue({
            ...formDataValue,
            subDistrict: subDistrictValue,
            postcode: null,
        });
    };

    const renderMapSelect = () => {
        if (!location?.latitude || !location?.longitude) {
            return <div />;
        }
        return (
            <div className="col-12 mt-3">
                <AddressMapSelect
                    location={location}
                    onChange={(location) => {
                        setLocation(location);
                    }}
                />
            </div>
        );
    };

    const classNameInputField = classNames("col-6 mt-3");

    return (
        <>
            <div className={classNameInputField} hidden={!showField.country}>
                <CountrySelect
                    value={formDataValue?.country}
                    onChange={(value) => onChangeValue({ country: value })}
                />
            </div>
            <div className={classNameInputField}>
                <Select
                    showSearch
                    label={Messages.province}
                    placeholder={Messages.province}
                    dataSource={listProvince}
                    onChange={onChangeProvince}
                    value={formDataValue?.province?.id}
                    error={formDataError?.province?.id}
                    getLabel={(item: IProvince) => item.name}
                    required
                />
            </div>

            <div className={classNameInputField}>
                <Select
                    showSearch
                    label={Messages.district}
                    placeholder={Messages.district}
                    dataSource={listCity}
                    onChange={onChangeDistrict}
                    value={formDataValue?.district?.id}
                    error={formDataError?.district?.id}
                    disabled={listCity.length === 0}
                    getLabel={(item: IDistrict) => item.name}
                    required
                />
            </div>
            <div className={classNameInputField}>
                <Select
                    showSearch
                    label={Messages.subDistrict}
                    placeholder={Messages.subDistrict}
                    dataSource={listSubdistrict}
                    onChange={onChangeSubDistrict}
                    value={formDataValue?.subDistrict?.id}
                    error={formDataError?.subDistrict?.id}
                    disabled={listSubdistrict.length === 0}
                    getLabel={(item: ISubdistrict) => item.name}
                    required
                />
            </div>
            <div className={classNameInputField}>
                <Select
                    label={Messages.postcode}
                    placeholder={Messages.postcode}
                    dataSource={listPostcode}
                    onChange={(value: any) =>
                        onChangeValue({ postcode: value })
                    }
                    value={formDataValue?.postcode}
                    error={formDataError?.postcode}
                    disabled={listPostcode.length === 0}
                    getLabel={(item: string) => item}
                    getValue={(item: string) => item}
                    required
                />
            </div>

            <div className="col-12 mt-3" hidden={!showField.address}>
                <Input
                    label={Messages.address}
                    placeholder={Messages.address}
                    onChange={(event: any) =>
                        onChangeValue({ address: event?.target.value })
                    }
                    value={formDataValue?.address}
                    error={formDataError?.address}
                    required
                />
            </div>
            <div
                className={classNameInputField}
                hidden={!showField.typeOfPlace}
            >
                <Select
                    label={Messages.typeOfPlace}
                    placeholder={Messages.typeOfPlace}
                    dataSource={map(TYPE_OF_PLACES, (item) => ({
                        ...item,
                        label: (Messages as any)[item.label],
                    }))}
                    onChange={(value: any) =>
                        onChangeValue({ typeOfPlace: value })
                    }
                    value={formDataValue?.typeOfPlace}
                    error={formDataError?.typeOfPlace}
                    required
                />
            </div>
            <div
                className={classNameInputField}
                hidden={!showField.typeOfPlace}
            >
                <Input
                    label={Messages.nameOfPlace}
                    placeholder={Messages.nameOfPlace}
                    onChange={(event: any) =>
                        onChangeValue({ nameOfPlace: event?.target.value })
                    }
                    value={formDataValue?.nameOfPlace}
                    error={formDataError?.nameOfPlace}
                />
            </div>
            {/* {showField.map && renderMapSelect()} */}
            <div className="col-12" hidden={!showField.remark}>
                <Input
                    label={Messages.remark}
                    onChange={(event: any) =>
                        onChangeValue({ remark: event?.target.value })
                    }
                    value={formDataValue?.remark}
                    error={formDataError?.remark}
                    multiple
                />
            </div>
        </>
    );
};

export default AddressFormInput;
