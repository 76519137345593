import { useFormik } from "formik";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";
import { mapDoctorOrderFilterToQueryParams } from "../../../interfaces/doctor-order";
import Messages from "../../../languages/Messages";
import DoctorOrderFilterForm from "../share/DoctorOrderFilterForm";

interface IDoctorOrderTableFilterDrawerProps {
    open: boolean;
    onClose: () => void;
}

const DoctorOrderTableFilterDrawer = ({
    open,
    onClose,
}: IDoctorOrderTableFilterDrawerProps) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const params = mapDoctorOrderFilterToQueryParams(values);
            setFilter(params);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            size="x-large"
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
            cancelText={Messages.clearAll}
            cancelButtonProps={{
                color: "error",
                onClick: () => {
                    filterForm.setValues({});
                },
            }}
        >
            <DoctorOrderFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default DoctorOrderTableFilterDrawer;
