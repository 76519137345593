import { gql } from "@apollo/client";
import { F_PRODUCT } from "../fragments/product";
import {
    F_QUOTATIONS,
    F_QUOTATION,
    F_QUOTATION_PRODUCT,
    F_QUOTATION_MANUAL_PRODUCT,
} from "../fragments/quotation";
import API from "../API";

export const getQuotationList = gql`
    ${F_QUOTATIONS}
    query listQuotationForAdmin($paginate: ListAdminQuotationRequest!) {
        data: listQuotationForAdmin(paginate: $paginate) {
            ...F_QUOTATIONS
        }
    }
`;

export const createQuotation = gql`
    mutation createQuotationForAdmin($payload: CreateAdminQuotationRequest!) {
        data: createQuotationForAdmin(payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const editQuotation = gql`
    mutation updateQuotationForAdmin(
        $id: String!
        $payload: UpdateAdminQuotationRequest!
    ) {
        data: updateQuotationForAdmin(id: $id, payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const cancelQuotation = gql`
    mutation cancelQuotationForAdmin($id: String!) {
        data: cancelQuotationForAdmin(id: $id) {
            data {
                id
            }
        }
    }
`;

export const getQuotationDetail = gql`
    ${F_QUOTATION}
    ${F_QUOTATION_PRODUCT}
    ${F_QUOTATION_MANUAL_PRODUCT}
    query detailQuotationForAdmin($id: String!) {
        data: detailQuotationForAdmin(id: $id) {
            data {
                ...F_QUOTATION
                products {
                    ...F_QUOTATION_PRODUCT
                }
                manualProduct {
                    ...F_QUOTATION_MANUAL_PRODUCT
                }
            }
        }
    }
`;

export const updateQuotationExpiredDate = gql`
    mutation updateQuotationExpiredForAdmin(
        $id: String!
        $payload: UpdateAdminQuotationExpiredRequest!
    ) {
        data: updateQuotationExpiredForAdmin(id: $id, payload: $payload) {
            data {
                id
            }
        }
    }
`;

const QuotationAPI = {
    cancel: async (id: string) =>
        API.mutate({
            mutation: cancelQuotation,
            variables: { id },
        }),
};

export default QuotationAPI;
