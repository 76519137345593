import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";

const ProductDetailActivity = () => {
    const { productId } = useParams<any>();

    return (
        <div className="">
            <ActivityView
                className="mt-3 border"
                activityRef={RefActivity?.Product}
                activityRefId={productId ?? ""}
            />
        </div>
    );
};

export default ProductDetailActivity;
