import {
    AwesomeTableComponent,
    Button,
    Header,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    Progress,
    ViewTextError,
    useDeepEffect,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, forEach, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import * as Yup from "yup";
import MaterialInventoryAPI from "../../../api/queries/material-inventory";
import PermissibleRender from "../../../common/PermissibleComponent";
import InputTextForm from "../../../common/input/InputTextForm";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import UploadButton from "../../../common/upload/UploadButton";
import { PERMISSION } from "../../../constant/user";
import { IMaterial } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import BranchSolidSelect from "../../branch/share/BranchSolidSelect";
import MaterialSearchButton from "../../material/share/MaterialSearchButton";
import MaterialNameView from "../../material/share/MaterialNameView";

interface MaterialAdjustmentProps {
    form: any;
    requireBranch?: boolean;
    forForm?: "adjustment" | "request";
}

const mapStockRequestToServer = (values: any) => {
    const { branch, materials, attachments, remark } = values;
    return {
        remark: remark || null,
        branch: branch?.id,
        attachments:
            attachments?.length > 0
                ? map(attachments, (att) => att?.fileUrl)
                : null,
        materials: map(materials, (pro) => ({
            material: pro?.id,
            adjustment: pro?.quantity,
        })),
    };
};

export const MaterialStockRequestSchema = Yup.object().shape({
    branch: Yup.object().required("Required field!"),
    remark: Yup.string().required("Required field!"),
    materials: Yup.array().min(1).required("Required field!"),
    attachments: Yup.array().min(1).required("Required field!"),
});

export const MaterialAdjustmentItems = ({
    form,
    requireBranch = true,
    forForm = "adjustment",
}: MaterialAdjustmentProps) => {
    const [searchValue, setSearchValue] = useState("");
    const { values, errors, setFieldValue } = form || {};
    const { materials, branch } = values || {};

    const materialIds = map(materials, (i) => i?.id);

    useDeepEffect(() => {
        if (
            materialIds &&
            materialIds?.length > 0 &&
            (requireBranch ? branch?.id : true)
        ) {
            mapStockToProduct(materialIds);
        }
    }, [materialIds]);

    const mapStockToProduct = async (materialIds: string[]) => {
        try {
            const res = await MaterialInventoryAPI.checkMaterialStock({
                branch: requireBranch ? [branch?.id] : [],
                material: materialIds,
            });
            const materialStocks = res?.data?.data?.data ?? [];
            const mappedStocks = map(materials, (item) => {
                const found = find(
                    materialStocks,
                    (pro) => pro?.id === item?.id
                );
                return {
                    ...item,
                    currentQty: found?.quantity ?? 0,
                    quantity: item?.quantity ?? 0,
                };
            });
            setFieldValue("materials", mappedStocks);
        } catch (error) {
            console.error({ error });
        }
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (material) => <MaterialNameView material={material} />,
            width: 200,
        },
        {
            title: Messages.unit,
            dataIndex: "purchaseUnit",
            width: 200,
            render: (unit) => unit?.name,
        },
        {
            title: Messages.currentQty,
            dataIndex: "currentQty",
            width: 200,
        },
        {
            title:
                forForm === "adjustment"
                    ? Messages.adjustment
                    : Messages.requestQtt,
            dataIndex: "quantity",
            render: (quantity, product) => (
                <InputTextQuantity
                    value={quantity}
                    onChange={(quantityValue) =>
                        onUpdateQty({
                            ...product,
                            quantity: quantityValue,
                        })
                    }
                    min={1}
                />
            ),
            width: 200,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (service) => (
                <Icon
                    className="cursor-pointer"
                    name="remove_circle_outline"
                    onClick={() => onRemove([service?.id])}
                />
            ),
            width: 150,
        },
    ];

    const onAddMaterial = (addPros: IMaterial[]) => {
        setFieldValue("materials", addPros);
    };

    const onRemove = (removeIds: string[]) => {
        setFieldValue(
            "materials",
            filter(materials, (pro) => !removeIds?.includes(pro?.id))
        );
    };

    const onUpdateQty = (updatePro: any) => {
        setFieldValue(
            "materials",
            map(materials, (pro) =>
                updatePro?.id === pro?.id ? updatePro : pro
            )
        );
    };

    const materialList = useMemo(() => {
        return filter(
            materials,
            (material) =>
                material?.sku
                    ?.toLowerCase()
                    ?.includes(searchValue?.toLowerCase()) ||
                material?.name
                    ?.toLowerCase()
                    ?.includes(searchValue?.toLowerCase())
        );
    }, [searchValue, materials]);

    useEffect(() => {
        setFieldValue("materials", []);
    }, [values.branch]);

    return (
        <div className="mt-3">
            <label>{Messages.items}</label>
            <div className="flex-row-between-center">
                <InputTextSearch
                    className="mr-2"
                    placeholder={Messages.search}
                    value={searchValue}
                    onChange={(e: any) => setSearchValue(e?.target?.value)}
                />
                <MaterialSearchButton
                    buttonProps={{
                        disabled: requireBranch ? !branch?.id : false,
                    }}
                    onSave={onAddMaterial}
                    defaultValue={materials ?? []}
                    branch={form?.values?.branch}
                    forForm={forForm}
                >
                    {Messages.add}
                </MaterialSearchButton>
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={materialList ?? []}
                className="mt-3"
                pagination={false}
            />
            {errors?.materials && <ViewTextError error={errors.materials} />}
        </div>
    );
};

const MaterialAdjustmentRequestCreate = () => {
    const navigate = useNavigate();

    const stockRequestForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MaterialStockRequestSchema,
        onSubmit: (values: any) => {
            const input = mapStockRequestToServer(values);
            onCreateStockRequest(input);
        },
    });

    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = stockRequestForm;

    useEffect(() => {
        forEach(Object.keys(stockRequestForm.errors), (key) =>
            Notifications.showError(`${stockRequestForm.errors[key]}`)
        );
    }, [stockRequestForm.errors]);

    const onCreateStockRequest = (payload: any) => {
        Progress.show(
            {
                method: MaterialInventoryAPI.createAdjustmentRequest,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.MATERIAL_ADJUST_REQUEST_DETAIL, {
                        requestId: res?.data?.data?.data?.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const classNameInput = "mt-3";
    return (
        <div className="flex-column-container bg-white w-100">
            <Header
                className="sticky top-0"
                showCancel={false}
                title={Messages.materialAdjustmentRequestCreate}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={
                                PERMISSION.MATERIAL_STOCK_ADJUSTMENT.CREATE
                            }
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-4">
                <BranchSolidSelect
                    value={formValues?.branch}
                    onChange={(value) =>
                        setValues((prev: any) => ({
                            ...prev,
                            branch: value,
                            materials: [],
                        }))
                    }
                    error={stockRequestForm?.errors?.branch}
                    className={classNameInput}
                />

                <MaterialAdjustmentItems form={stockRequestForm} />

                <div className={classNameInput}>
                    <label>{Messages.attachment}</label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) => {
                            stockRequestForm.setFieldValue("attachments", file);
                        }}
                        defaultFiles={formValues?.attachments ?? []}
                    />
                    <ViewTextError error={formErrors.attachments} />
                </div>

                <InputTextForm
                    form={stockRequestForm}
                    keyData="remark"
                    multiple
                    className={classNameInput}
                />
            </div>
        </div>
    );
};

export default MaterialAdjustmentRequestCreate;
