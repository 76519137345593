/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-syntax */
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    IRowsKey,
    Modal,
    ViewRowInterchange,
} from "d-react-components";
import { filter, find, forEach, map, replace } from "lodash";
import { Fragment, useContext, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath } from "react-router-dom";
import { FeeUnit } from "../../../api/hooks";
import TreatmentAPI from "../../../api/queries/treatment";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import { JOB_COST_TYPES } from "../../../constant/service";
import { TreatmentDetailContext } from "../../../context/treatment";
import { ITreatment, ITreatmentUser } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import { exportToCSV } from "../../../utils/file";
import { getHhMmFromMinutes } from "../../../utils/Utils";
import MaterialNameView from "../../material/share/MaterialNameView";
import Path from "../../Path";
import { useUserJobCost } from "./treatment-jobcost/TreatmentJobAssessmentDrawer";
import { useMe } from "../../../context/app";

interface IBomDetailDrawer {
    open: boolean;
    onClose: () => void;
}

const BomDetailDrawer = ({ open, onClose }: IBomDetailDrawer) => {
    const { treatment } = useContext(TreatmentDetailContext);
    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "material",
            render: (material) => <MaterialNameView material={material} />,
        },
        {
            title: Messages.trackingUnitCost,
            dataIndex: "price",
            render: (price) => Math.round(price * 100) / 100,
        },
        {
            title: Messages.usedQty,
            dataIndex: "quantity",
        },
        {
            title: Messages.subtotal,
            dataIndex: "price",
            render: (price, item) =>
                Math.round(price * item.quantity * 100) / 100,
        },
        {
            title: Messages.lotNo,
            dataIndex: "lotNo",
            render: (lotNo, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_ORDER_DETAIL, {
                        orderId:
                            item?.materialPurchaseDelivery?.materialPurchase
                                ?.id,
                    })}
                >
                    {lotNo}
                </AppLink>
            ),
        },
    ];

    const source = () => {
        return TreatmentAPI.getListMaterialDetail(treatment?.id);
    };
    const transformer = (res: any) => res?.data?.data?.data ?? [];

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.BOM}
            onSave={() => {}}
            size="auto"
            width="50%"
        >
            <div className="p-3">
                <AwesomeTableComponent
                    columns={columns}
                    source={source as any}
                    transformer={transformer}
                    pagination={false}
                />
            </div>
        </Drawer>
    );
};

const TreatmentDetailJobCost = () => {
    const { jobCostFeature } = useMe();
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const {
        PIC,
        doctor,
        jobCostMetric,
        jobCostAssessment,
        manualJobCostAssessment,
    } = treatment || {};
    const picDataSource = useUserJobCost(PIC, doctor);
    const [openBomDetail, setOpenBomDetail] = useState(false);
    const [openFormulaModal, setOpenFormulaModal] = useState<{
        open: boolean;
        data?: any;
    }>({ open: false });
    const SUMMARY_KEYS: IRowsKey<ITreatment>[] = [
        {
            id: "bom",
            label: Messages.BOM,
            renderContent: ({ data, item }) => (
                <div className="d-flex">
                    <div>{Math.round(data * 100) / 100}</div>
                    {item.hasActionCalculatorBom && (
                        <img
                            src="/icons/history.svg"
                            className="ml-1"
                            onClick={() => setOpenBomDetail(true)}
                        />
                    )}
                </div>
            ),
        },
    ];

    const columns: IColumnsProps = [
        {
            title: Messages.jobCostRole,
            dataIndex: "type",
            render: (data, item, index) => {
                return (
                    Messages[find(JOB_COST_TYPES, { id: data })?.label ?? ""] ||
                    "N/A"
                );
            },
        },
        {
            title: Messages.payee,
            dataIndex: "",
            render: (user: ITreatmentUser) => (
                <UserAvatarName user={user.adminUser} />
            ),
        },
        {
            title: Messages.timeStart,
            dataIndex: "start",
            align: "center",
            width: 100,
            render: (start, item: any) => {
                const value = start ? getHhMmFromMinutes(start) : "";
                return value;
            },
        },
        {
            title: Messages.timeEnd,
            dataIndex: "end",
            align: "center",
            width: 100,
            render: (end, item: any) => {
                const value = end ? getHhMmFromMinutes(end) : "";
                return value;
            },
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
            width: 100,
            render: (unit, item: any) => {
                if (!jobCostAssessment?.length) {
                    return (
                        <div className="text-[#FAC256]">{Messages.pending}</div>
                    );
                }
                if (item?.isDoctor) {
                    return FeeUnit.Time;
                }
                const jobCostPresetMap = new Map(
                    Object.entries(jobCostMetric?.jobCostPreset ?? {})
                );
                const thisPreset: any = jobCostPresetMap.get(
                    `${item?.adminUser?.id}&${item?.type}`
                );
                return thisPreset?.primaryFee?.unit ?? "N/A";
            },
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
            width: 75,
            render: (quantity, item) => {
                if (!jobCostAssessment?.length) {
                    return (
                        <div className="text-[#FAC256]">{Messages.pending}</div>
                    );
                }
                return quantity;
            },
        },
        {
            title: Messages.autoCalculatedAmount,
            dataIndex: "amount",
            align: "center",
            width: 150,
            render: (amount, item) => {
                if (!jobCostAssessment?.length) {
                    return (
                        <div className="text-[#FAC256]">{Messages.pending}</div>
                    );
                }
                const formulaData =
                    jobCostAssessment?.[jobCostAssessment?.length - 1]?.[
                        item?.id
                    ];
                return (
                    <div className="flex flex-row items-center justify-center">
                        <CurrencyFormat
                            value={amount}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                        <Button
                            iconName="calculate"
                            variant="trans"
                            onClick={() =>
                                setOpenFormulaModal({
                                    open: true,
                                    data: formulaData,
                                })
                            }
                        />
                    </div>
                );
            },
        },
        {
            title: Messages.manualInputAmount,
            dataIndex: "manualAmount",
            align: "center",
            width: 150,
            render: (manualAmount, item) => {
                return (
                    <div className="flex flex-row items-center justify-center">
                        <CurrencyFormat
                            value={manualAmount || ""}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </div>
                );
            },
        },
    ];

    const renderFormula = (formula: any) => {
        if (!formula) {
            return null;
        }
        if (typeof formula === "string") {
            return (
                <div className="text">
                    <div className="label">Formula:</div>
                    <div className="my-3">
                        <span className="font-semibold">Result</span> ={" "}
                        {formula}
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="label">Formula:</div>
                <ul className="text ml-3">
                    {Object.keys(formula)
                        .reverse()
                        .map((key) => {
                            return (
                                <li className="flex-center-y my-2">
                                    <div className="font-semibold">
                                        {key} ={" "}
                                    </div>
                                    <div className="ml-1">
                                        {" "}
                                        {formula?.[key] ?? "N/A"}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };

    const renderFormulaRes = (formula: any, params: any) => {
        if (!formula) {
            return null;
        }
        if (typeof formula === "string") {
            return (
                <div className="text">
                    <div className="label">Formula:</div>
                    <div className="my-3">
                        <span className="font-semibold">Result</span> ={" "}
                        {formula}
                    </div>
                </div>
            );
        }
        const getMessage = (res: any) => {
            let message = res;

            forEach(Object.keys(params), (key) => {
                message = replace(
                    message,
                    `$${key}`,
                    JSON.stringify(params[`${key}`])
                );
            });
            return message;
        };

        return (
            <div>
                <div className="label">Final Result:</div>
                <ul className="text ml-3">
                    <li className="flex-center-y my-2">
                        <div className="font-semibold">result = </div>
                        <div className="ml-1">
                            {" "}
                            {getMessage(formula?.result)}
                        </div>
                    </li>
                </ul>
            </div>
        );
    };

    const renderParams = (params: any) => {
        if (!params) {
            return null;
        }
        const notes = openFormulaModal?.data?.calculator?.notes ?? [];
        return (
            <div>
                <div className="label">Params:</div>
                <ul className="text ml-3">
                    {Object.keys(params).map((key) => {
                        const foundNotes = filter(
                            notes,
                            (item) => item?.key === key
                        );
                        return (
                            <li className="my-2">
                                <div className="flex-center-y ">
                                    <div className="font-semibold">
                                        {key} ={" "}
                                    </div>
                                    <div className="ml-1">
                                        {" "}
                                        {params?.[key]
                                            ? JSON.stringify(params?.[key])
                                            : "N/A"}
                                    </div>
                                </div>
                                {foundNotes?.length > 0
                                    ? map(foundNotes, (note) => {
                                          return (
                                              <div className="ml-3 mt-2">
                                                  * {note?.note}
                                              </div>
                                          );
                                      })
                                    : null}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const exportJobCost = () => {
        const exportData = picDataSource.map((pic) => ({
            "Job Cost Role": pic.type,
            "Employee ID": pic?.adminUser?.companyId,
            Payee: `${pic?.adminUser?.firstName} ${pic?.adminUser?.lastName} (${pic?.adminUser?.nickName})`,
            "Auto Calculated Amount": pic.amount,
            "Manual Input Amount": pic.manualAmount,
        }));
        exportToCSV(exportData, `jobcost_${treatment.treatmentNo}`);
    };

    const renderFormulaView = useMemo(() => {
        const { data } = openFormulaModal || {};
        if (!data) {
            return (
                <div className="w-full text-center py-[50px] text-gray-500 h4">
                    There is not formula applied for this user
                </div>
            );
        }
        return (
            <Fragment>
                {renderFormulaRes(
                    openFormulaModal?.data?.calculator?.formula,
                    openFormulaModal?.data?.calculator?.params
                )}
                {renderFormula(openFormulaModal?.data?.calculator?.formula)}
                {renderParams(openFormulaModal?.data?.calculator?.params)}
            </Fragment>
        );
    }, [openFormulaModal.data]);

    const renderJobCostView = () => {
        if (!jobCostFeature) return null;
        return (
            <Fragment>
                <div className="flex-row-between-center my-3 flex items-center">
                    <h5 className="flex-1">{Messages.jobCost}</h5>
                    <Button size="small" onClick={() => exportJobCost()}>
                        {Messages.export}
                    </Button>
                </div>
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={picDataSource}
                    className="mt-3"
                    pagination={false}
                />
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="flex-row-between-center mb-3">
                <h5>{Messages.summary}</h5>
            </div>

            <div className="border p-3 mt-3">
                <ViewRowInterchange
                    keyList={SUMMARY_KEYS}
                    dataSource={treatment}
                    variant="background"
                />
            </div>
            {renderJobCostView()}
            {openBomDetail && (
                <BomDetailDrawer
                    open={openBomDetail}
                    onClose={() => setOpenBomDetail(false)}
                />
            )}
            {openFormulaModal?.open && (
                <Modal
                    size="medium"
                    title="Formula"
                    showFooter={false}
                    open={openFormulaModal?.open}
                    onClose={() => setOpenFormulaModal({ open: false })}
                >
                    {renderFormulaView}
                </Modal>
            )}
        </Fragment>
    );
};

export default TreatmentDetailJobCost;
