import { Notifications } from "d-react-components";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useState } from "react";
import { QuotationCreateContext } from "../../../context/quotation";
import Messages from "../../../languages/Messages";
import ProductGrid from "../share/ProductGrid";
import ProductList from "../share/ProductList";
import { SalesPersonType } from "../../../api/hooks";

export const SIZE_OPTIONS = [18, 36, 72];

const QuotationCreateProductList = () => {
    const {
        productFilters,
        setProductFilters,
        productList,
        setProductList,
        selectedCustomer,
    } = useContext(QuotationCreateContext);
    const { values } = useFormikContext<any>();
    const { customer } = values;
    const [pagination, setPagination] = useState<any>({
        pageIndex: 1,
        pageSize: SIZE_OPTIONS[0],
    });

    const addNewProduct = useCallback(
        (product: any) => {
            const notAvailable = !product?.isSellOutOfStock;
            if (notAvailable) {
                Notifications.showError(Messages.thisItemOutOfStock);
                return;
            }
            let isProductExisted = false;
            const productsResult = productList.map((item: any) => {
                if (product.id === item.id && !item?.doctorOrder?.id) {
                    isProductExisted = true;
                    return {
                        ...item,
                        quantity: item.quantity + 1,
                    };
                }
                return item;
            });
            if (!isProductExisted) {
                productsResult.push({
                    ...product,
                    quantity: 1,
                    productOrderId: product?.id,
                    salesPerson: values.defaultCoSalePerson
                        ? [
                              {
                                  salesPerson: values.defaultCoSalePerson,
                                  type: SalesPersonType.Sales,
                              },
                          ]
                        : [],
                });
                setProductList(productsResult);
            } else {
                setProductList(productsResult);
            }
            Notifications.showSuccess(Messages.itemAddedSuccessfully);
        },
        [productList, setProductList]
    );

    if (!selectedCustomer && !customer) {
        return <div />;
    }

    return (
        <div>
            {productFilters?.viewMode === "grid" && (
                <ProductGrid
                    {...{
                        pagination,
                        setPagination,
                        addNewProduct,
                    }}
                />
            )}
            {productFilters?.viewMode === "list" && (
                <ProductList
                    {...{
                        pagination,
                        setPagination,
                        addNewProduct,
                    }}
                />
            )}
        </div>
    );
};

export default QuotationCreateProductList;
