import {
    AwesomeTableComponent,
    Button,
    ButtonProps,
    HeaderTable,
    IColumnsProps,
    Icon,
    Notifications,
} from "d-react-components";
import { Link, generatePath } from "react-router-dom";
import { debounce, filter, find, isUndefined, map } from "lodash";
import { useRef, useState } from "react";

import {
    MaterialPurchaseRequestStatus,
    useSearchMaterialLazyQuery,
    useCheckMaterialPurchaseRequestLazyQuery,
    useCheckMaterialStockAdjustmentLazyQuery,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { IMaterial } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";
import MaterialNameView from "./MaterialNameView";
import { IBranch } from "../../../interfaces/branch";
import Path from "../../Path";

interface IModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (values: any) => void;
    defaultValue?: any[];
    multiple?: boolean;
    keepOrigin?: boolean;
    filterSource?: any;
    forForm?: "adjustment" | "request";
    branch: IBranch;
}

interface IProps extends Omit<IModalProps, "open" | "onClose"> {
    isInvalidateOpenSearch?: () => boolean;
    children?: any;
    buttonProps?: ButtonProps;
    forForm?: "adjustment" | "request";
    branch: IBranch;
}

interface MaterialValidationItem {
    materials: {
        material: IMaterial;
        quantity: number;
    }[];
    [key: string]: any;
}

export const MaterialSearchModal = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    multiple = true,
    keepOrigin = true,
    filterSource,
    forForm = "adjustment",
    branch,
}: IModalProps) => {
    const [materialAssignedItemList, setMaterialAssignedItemList] = useState<
        MaterialValidationItem[]
    >([]);

    const checkForAssignedItem = (material: IMaterial) => {
        return find(materialAssignedItemList, (item) =>
            item?.materials?.map((i) => i.material.id)?.includes(material.id)
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",

            render: (material: IMaterial) => {
                const materialAssignedItem = checkForAssignedItem(material);
                return (
                    <>
                        <MaterialNameView material={material} />
                        {materialAssignedItem && (
                            <div className="py-2 px-2 bg-amber-100 text-yellow-500 ml-1 flex items-center">
                                <Icon name="error" />
                                <span className="mr-1">
                                    {forForm === "adjustment"
                                        ? Messages.assignedToRequest
                                        : Messages.assignedToAdjustmentRequest}
                                </span>
                                {forForm === "adjustment" ? (
                                    <Link
                                        to={generatePath(
                                            Path.MATERIAL_REQUEST_DETAIL,
                                            {
                                                requestId:
                                                    materialAssignedItem.id,
                                            }
                                        )}
                                        target="_blank"
                                    >
                                        {
                                            materialAssignedItem.materialPurchaseRequestNo
                                        }
                                    </Link>
                                ) : (
                                    <Link
                                        to={generatePath(
                                            Path.MATERIAL_ADJUST_REQUEST_DETAIL,
                                            {
                                                requestId:
                                                    materialAssignedItem.id,
                                            }
                                        )}
                                        target="_blank"
                                    >
                                        {materialAssignedItem.adjustmentNo}
                                    </Link>
                                )}
                            </div>
                        )}
                    </>
                );
            },
        },
    ];

    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");

    const [checkMaterialPurchaseRequest] =
        useCheckMaterialPurchaseRequestLazyQuery();
    const [checkMaterialStockAdjustment] =
        useCheckMaterialStockAdjustmentLazyQuery();

    const [materialSelect, setMaterialSelect] =
        useState<IMaterial[]>(defaultValue);

    const [searchMaterial] = useSearchMaterialLazyQuery();

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const source = (pagingData: any) => {
        return searchMaterial({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
            fetchPolicy: "no-cache",
        }).then(
            (resp) => {
                console.log(resp);
                const materialList = resp?.data?.data?.data ?? [];
                const api =
                    forForm === "adjustment"
                        ? checkMaterialPurchaseRequest
                        : checkMaterialStockAdjustment;
                api({
                    variables: {
                        payload: {
                            branch: isUndefined(branch) ? [] : [branch.id],
                            material: (materialList ?? [])?.map(
                                (m) => m.id
                            ) as any,
                            status: [
                                MaterialPurchaseRequestStatus.Pending,
                                MaterialPurchaseRequestStatus.Approved,
                            ] as any,
                        },
                    },
                }).then((resp) => {
                    setMaterialAssignedItemList(resp?.data?.data?.data as any);
                });
                return resp;
            },
            (err) => {
                return err;
            }
        );
    };

    const onClickSave = () => {
        onSave(materialSelect);
        onClose();
    };

    const getSelectProps = (material: any) => {
        const materialAssignedItem = checkForAssignedItem(material);
        // const disabled = false;
        // if (
        //     product?.productType === PRODUCT_TYPE.SIMPLE_PRODUCT &&
        //     product.allAvailableCW === 0 &&
        //     !product.sellingOutOfStock
        // ) {
        //     disabled = true;
        // }
        return {
            disabled: !isUndefined(materialAssignedItem),
        };
    };

    const onChangeSelectMaterial = (
        selectedRowKeys: string[],
        selectRows: any[]
    ) => {
        const existedSelectRow = filter(selectRows, (item) => !!item);
        const materials = map(selectedRowKeys, (materialId) => {
            const origin = find(defaultValue, (i) => i?.id === materialId);
            let selectRow = find(
                existedSelectRow,
                (item) => item?.id === materialId
            );
            if (!selectRow) {
                selectRow = find(
                    materialSelect,
                    (item) => item.id === materialId
                );
            } else if (keepOrigin && origin) {
                selectRow = origin;
            }
            return selectRow;
        });
        setMaterialSelect(materials);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.search}
            onSave={onClickSave}
            size="auto"
            width="800px"
        >
            <div className="p-4">
                <HeaderTable
                    // onClickFilter={() => {}}
                    onChangeText={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                    className="mb-3"
                />
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    source={source}
                    columns={columns}
                    transformer={(res) => {
                        return res?.data?.data?.data ?? [];
                    }}
                    baseColumnProps={{ width: 600 }}
                    bordered={false}
                    rowSelection={{
                        type: multiple ? "checkbox" : "radio",
                        selectedRowKeys: map(materialSelect, (item) => item.id),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectMaterial as any,
                        getCheckboxProps: getSelectProps,
                    }}
                />
            </div>
        </Drawer>
    );
};

const MaterialSearchButton = ({
    isInvalidateOpenSearch = () => false,
    children = Messages.search,
    buttonProps = {},
    ...modalProps
}: IProps) => {
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const onClickSearch = () => {
        if (!modalProps.branch) {
            Notifications.showError(Messages.pleaseSelectProductChannelFirst);
            return;
        }
        if (isInvalidateOpenSearch()) {
            return;
        }
        setOpenSearch(true);
    };

    return (
        <div>
            <Button iconName="search" onClick={onClickSearch} {...buttonProps}>
                {children}
            </Button>
            {openSearch && (
                <MaterialSearchModal
                    open={openSearch}
                    onClose={() => setOpenSearch(false)}
                    {...modalProps}
                />
            )}
        </div>
    );
};

export default MaterialSearchButton;
