import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useGetProductCategoriesLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";

import TableAction from "../../../common/TableActions";
import {
    PRODUCT_CATEGORY_COMMISSION_TYPES,
    PRODUCT_CATEGORY_STATUSES,
} from "../../../constant/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const ProductCategoryTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const [getProductCategories] = useGetProductCategoriesLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.PRODUCT_CATEGORY_DETAIL, {
                        categoryId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={PRODUCT_CATEGORY_STATUSES}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.commissionType,
            dataIndex: "commissionType",
            render: (commissionType) => {
                const type = find(
                    PRODUCT_CATEGORY_COMMISSION_TYPES,
                    (item) => item.id === commissionType
                );
                return Messages[type?.label as any];
            },
        },

        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (category: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, category)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return getProductCategories({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickTableAction = (actionId: string, category: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.PRODUCT_CATEGORY_DETAIL, {
                        categoryId: category.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.productCategory}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                onClickNew={() => navigate(Path.PRODUCT_CATEGORY_CREATE)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default ProductCategoryTable;
