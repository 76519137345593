import { TimeUtils } from "d-react-components";
import ClassNames from "classnames";
import { useContext } from "react";
import CurrencyFormat from "react-currency-format";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";

const ProductDetailInfo = () => {
    const { product } = useContext(ProductDetailContext);

    const {
        name,
        createByAdmin,
        createdAt,
        updatedAt,
        status,
        sku,
        category,
        regularPrice,
        salePrice,
        gallery,
    } = product ?? {};

    const renderInfoRow = (title: any, content: any, showBorder = true) => {
        return (
            <div
                className={ClassNames("flex-row-between-center py-3 w-100", {
                    "border-bottom": showBorder,
                })}
            >
                <div className="text-bold">{title}</div>
                <div className="text">{content}</div>
            </div>
        );
    };

    return (
        <div className="w-100 bg-white">
            <div className="flex-column flex-center border py-5 px-4">
                <img
                    src={gallery?.[0] ?? "/images/contract.png"}
                    className="image-square-large"
                    style={{ objectFit: "fill" }}
                />
                <h4 className="text-primary mt-2 text-center">{name}</h4>
                {/* <ViewLabelStatus
                    status={status}
                    listStatus={SERVICE_CONFIG_STATUSES}
                    className="mt-3"
                /> */}
            </div>
            <div className="flex-column flex-center border p-3">
                {renderInfoRow(Messages.sku, sku)}
                {renderInfoRow(Messages.category, category?.name)}
                {renderInfoRow(
                    Messages.regularPrice,
                    <CurrencyFormat
                        value={regularPrice}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                )}
                {renderInfoRow(
                    Messages.salePrice,
                    <CurrencyFormat
                        value={salePrice}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                )}
                {renderInfoRow(Messages.createdAt, TimeUtils.toDate(createdAt))}
                {renderInfoRow(
                    Messages.lastUpdated,
                    TimeUtils.toDate(updatedAt),
                    false
                )}
            </div>
        </div>
    );
};

export default ProductDetailInfo;
