import classNames from "classnames";
import { Badge, Button, TimeUtils } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import Drawer, { CustomDrawerProps } from "../../../common/Drawer";

const OrderVoucherReferences = () => {
    const [showVoucherDrawer, setShowVoucherDrawer] = useState(false);
    const { order } = useContext(OrderDetailContext);
    const { voucher } = (order as IOrder) ?? {};

    const itemToDisplay = useMemo(() => {
        return voucher?.slice(0, 2) ?? [];
    }, [voucher]);

    if (!voucher || !voucher.length) {
        return <></>;
    }
    return (
        <div className="border p-3 mb-3">
            <div className="flex">
                <h5 className="font-semibold mb-2">
                    {Messages.voucherReferences}
                </h5>
            </div>
            {itemToDisplay.map((v, index) => {
                const { voucher: voucherItem } = v;
                return (
                    <div
                        key={index}
                        className={classNames("pt-2 flex-column", {
                            "pb-2 border-b": index + 1 !== voucher.length,
                        })}
                    >
                        <span>
                            <AppLink
                                to={generatePath(Path.VOUCHER_DETAIL, {
                                    voucherId: voucherItem?.id ?? "",
                                })}
                            >
                                #{voucherItem.voucherNo}
                            </AppLink>
                        </span>
                        <span>
                            {`${Messages.voucherCode}: ${voucherItem?.code}`}
                        </span>
                        <span>
                            {Messages.appliedOn}{" "}
                            {TimeUtils.toDateTime(voucherItem.createdAt)}
                        </span>
                    </div>
                );
            })}
            {voucher.length > 2 && (
                <div className="flex justify-center">
                    <Button
                        onClick={() => setShowVoucherDrawer(!showVoucherDrawer)}
                        id="buttonTrack"
                        color="primary"
                        className="mt-3 w-100"
                    >
                        <span className="text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
                            {Messages.viewAll}
                            <Badge
                                index={voucher.length}
                                variant="index"
                                size="x-large"
                                color="red"
                                className="ml-2"
                            />
                        </span>
                    </Button>
                </div>
            )}
            {showVoucherDrawer && (
                <AllVouchersDrawer
                    open={showVoucherDrawer}
                    onClose={() => setShowVoucherDrawer(false)}
                    vouchers={voucher}
                />
            )}
        </div>
    );
};

const AllVouchersDrawer = ({ open, onClose, vouchers }: CustomDrawerProps) => {
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.voucherReferences}
            hideFooter
        >
            <div className="p-4">
                {vouchers.map((v: any, index: string) => {
                    const { voucher: voucherItem } = v;
                    return (
                        <div
                            key={index}
                            className={classNames("pt-2 flex-column", {
                                "pb-2 border-b": index + 1 !== vouchers.length,
                            })}
                        >
                            <span>
                                <AppLink
                                    to={generatePath(Path.VOUCHER_DETAIL, {
                                        voucherId: voucherItem?.id ?? "",
                                    })}
                                    target="_blank"
                                >
                                    #{voucherItem.voucherNo}
                                </AppLink>
                            </span>
                            <span>
                                {`${Messages.voucherCode}: ${voucherItem?.code}`}
                            </span>
                            <span>
                                {Messages.appliedOn}{" "}
                                {TimeUtils.toDateTime(voucherItem.createdAt)}
                            </span>
                        </div>
                    );
                })}
            </div>
        </Drawer>
    );
};

export default OrderVoucherReferences;
