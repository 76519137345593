/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from "classnames";
import {
    Button,
    Icon,
    ModalLightBox,
    Notifications,
    ObjectUtils,
    StringUtils,
    useFirstTime,
} from "d-react-components";
import { find, includes, some, split } from "lodash";
import {
    Fragment,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { useDropzone } from "react-dropzone";
import MediaAPI from "../../api/queries/media";
import Messages from "../../languages/Messages";
import "./UploadButton.scss";

export const DOC = {
    extension: ["docx", "doc", "pptx", "pps"],
    iconFile: "/images/placeholder.png",
};
export const EXCEL = {
    extension: ["xls", "csv", "xlsx"],
    iconFile: "/images/placeholder.png",
};
export const PDF = { extension: ["pdf"], iconFile: "/images/files/pdf.svg" };
export const IMAGE = {
    extension: [
        "jpeg",
        "bmp",
        "png",
        "jpg",
        "heic",
        "PNG",
        "HEIC",
        "JPG",
        "JPEG",
        "BMP",
        "webp",
    ],
    iconFile: "",
};
const FILE_TYPE = [DOC, EXCEL, PDF];

const UPLOAD_MODE = {
    PENDING: "pending",
    SUCCESS: "success",
    ERROR: "error",
};

export const IMAGE_FORMAT =
    "image/x-png,image/jpeg,image/jpg,image/heic,image/png";

export const ACCEPT_FORMAT = "image/*,.pdf,.doc,.docx,.xls,.xlsx";

const FileUploadItem = ({ file, onRemove, onChange, onClickImage }: any) => {
    const isPending = file.status === UPLOAD_MODE.PENDING;
    const isError = file.status === UPLOAD_MODE.ERROR;
    const isSuccess = file.status === UPLOAD_MODE.SUCCESS;

    useEffect(() => {
        isPending && onUploadFile();
    }, [file.status]);

    const onRetry = () => {
        onChange({ ...file, status: UPLOAD_MODE.PENDING });
    };

    const onUploadFile = async () => {
        MediaAPI.uploadFile(file.fileData)
            .then(({ fileUrl }) =>
                onChange({
                    ...file,
                    fileUrl,
                    status: UPLOAD_MODE.SUCCESS,
                })
            )
            .catch(() => {
                onChange({ ...file, status: UPLOAD_MODE.ERROR });
            });
    };

    const classLoading = classNames("file-upload__item-loading", {});

    const classIconSuccess = classNames(
        "btn btn-trans file-upload__item-icon text-success"
    );
    const classIconError = classNames(
        "btn btn-trans file-upload__item-icon text-danger"
    );
    const classIconFooter = classNames(
        "btn btn-trans text-white p-1 flex-center"
    );

    const renderItemFooter = () => {
        if (isPending) return <div />;
        return (
            <div className="file-upload__item-footer">
                {onRemove ? (
                    <button
                        className={classIconFooter}
                        onClick={() => onRemove && onRemove(file)}
                    >
                        <Icon name="delete" size="small" />
                    </button>
                ) : null}
                {isError && (
                    <button className={classIconFooter} onClick={onRetry}>
                        <Icon name="rotate_left" size="small" />
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="file-upload__item">
            {isSuccess && (
                <button className={classIconSuccess}>
                    <Icon name="check_circle" />
                </button>
            )}
            {isError && (
                <button className={classIconError}>
                    <Icon name="info" />
                </button>
            )}
            {renderItemFooter()}
            <img
                onClick={onClickImage}
                src={file.imageData || file?.fileUrl}
                className="file-upload__item-image hover:pointer"
            />

            {isPending && (
                <div className={classLoading}>
                    <div className="spinner-border text-white" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};
/**
 *
 * @param {
 * buttonText: customize text in main button
 * disabled: true => disabled button upload
 * onChange: only invoke when file uploaded
 * defaultFiles: Default files before upload, must follow object's format:
 * {
 *    id: 91208391823,
 *    imageData: "uri"
 * }
 * } param0
 * @returns
 */
interface IIButtonFileUploadDefaultFile {
    id: any;
    imageData: string;
}

interface IButtonFileUpload {
    defaultFiles?: IIButtonFileUploadDefaultFile[];
    buttonClassName?: any;
    className?: any;
    maxFiles?: number;
    inputParam?: any;
    onChange?: any;
    disabled?: boolean;
    buttonText?: any;
    hidePreview?: boolean;
    customButton?: any;
    variant?: "button" | "square" | "drag&drop";
    getImage?: (props?: any) => any;
}

const UploadButtonRef = (
    {
        buttonText = "Browse files",
        variant = "button",
        disabled = false,
        onChange,
        inputParam = {},
        className = "",
        maxFiles = 0,
        buttonClassName = "",
        defaultFiles = [],
        hidePreview = false,
        customButton,
        getImage,
    }: IButtonFileUpload,
    ref: any
) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    } as any);
    const firstTime = useFirstTime();
    const listDefaultFile = defaultFiles.map((item: any) => ({
        ...item,
        status: UPLOAD_MODE.SUCCESS,
    }));
    const [listFile, setListFile] = useState(listDefaultFile);
    const [openImageModal, setOpenImageModal] = useState<{
        open: boolean;
        index?: number;
    }>({ open: false });

    const wrapperClass = classNames(
        { "d-flex align-items-center": variant === "square" },
        className
    );

    useImperativeHandle(ref, () => ({
        refresh: () => {
            setListFile([]);
        },
    }));

    useEffect(() => {
        if (firstTime) return;
        const uploadedFiles = listFile.filter(
            (item: any) => item.status === UPLOAD_MODE.SUCCESS
        );
        onChange && onChange(uploadedFiles);
    }, [listFile]);

    const validateFileLimitNumber = (fileUpload: any) => {
        if (!!maxFiles && listFile.length + fileUpload.length > maxFiles) {
            Notifications.showError(`Only max ${maxFiles} files!`);
            return true;
        }
        return false;
    };

    /**
     * validate format of file follow inputParam
     * normally, its prevented on mode select but not in drag and drop mode
     * @param {*} fileUpload
     * @returns
     */
    const validateFileType = (fileUpload = []) => {
        if (inputParam?.accept) {
            const acceptTypes = split(inputParam?.accept, ",");
            const isUnValidType = some(
                fileUpload,
                (item: any) => !includes(acceptTypes, item.type)
            );
            if (isUnValidType) {
                Notifications.showError(`Only accept ${inputParam?.accept}`);
                return true;
            }
        }
        return false;
    };

    const validateFileInput = (fileUpload: any) => {
        if (validateFileLimitNumber(fileUpload)) {
            return true;
        }
        if (validateFileType(fileUpload)) {
            return true;
        }
        return false;
    };

    /**
     * depend on extension that get from fileName =>
     * if file is an image => return fileData to display in preview
     * if file is an pdf/doc/excel => return local image file to display in preview
     * @param {*} fileName
     * @param {*} fileData
     * @returns
     */
    const getImageDataPreview = (fileName = "", fileData: any) => {
        const extension = StringUtils.getExtensionFromFilename(fileName);
        const isImage = IMAGE.extension.includes(extension);
        if (isImage) return fileData;
        const fileFormat = find(FILE_TYPE, (fileTypeItem) =>
            fileTypeItem.extension.includes(extension)
        );
        if (fileFormat) {
            return fileFormat.iconFile;
        }
        return "";
    };

    function onDrop(fileUpload = []) {
        if (validateFileInput(fileUpload)) {
            return;
        }

        const fileResult: any = [];
        fileUpload.forEach((file: any, index) => {
            const reader = new FileReader();
            const url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                fileResult.push({
                    id: StringUtils.getUniqueID(),
                    fileData: file,
                    imageData: getImageDataPreview(file.name, reader.result),
                    status: UPLOAD_MODE.PENDING,
                });
                if (index === fileUpload.length - 1) {
                    const clone = [...fileResult];
                    setListFile([...listFile, ...clone]);
                }
            };
        });
    }

    const onClickRemoveFile = (item: any) => {
        const listFileResult = ObjectUtils.removeArrayById(listFile, item.id);
        setListFile(listFileResult);
    };

    const onChangeFile = (file: any) => {
        const listFileResult = ObjectUtils.updateArrayById(
            listFile as any,
            file
        );
        setListFile(listFileResult);
    };

    const renderButton = () => {
        if (customButton) return customButton;
        if (variant === "button") {
            return (
                <Button
                    className={classNames(buttonClassName)}
                    disabled={disabled}
                    {...(getRootProps() as any)}
                    iconName="cloud_upload"
                >
                    <div>
                        <input
                            {...getInputProps({
                                accept: ACCEPT_FORMAT,
                                ...inputParam,
                            })}
                        />
                        {buttonText}
                    </div>
                </Button>
            );
        }
        if (disabled) return null;
        return (
            <div
                className="border-dashed text-x-small border-primary text-center d-flex align-items-center justify-content-center  hover-pointer"
                style={{
                    width: "10rem",
                    height: "10rem",
                    marginBottom: "1rem",
                }}
                {...getRootProps()}
            >
                <input {...getInputProps(inputParam as any)} />
                <div className="text-center text-primary text">
                    {Messages.addFile}
                </div>
            </div>
        );
    };

    const renderDragDrop = () => {
        return (
            <div
                className=" border-primary border-dashed  flex flex-col justify-center items-center py-3 mb-3"
                {...getRootProps()}
            >
                <input {...getInputProps(inputParam as any)} />
                <img src="/icons/upload.svg" />
                <div className="text-primary font-bold text-center mt-3">
                    {Messages.dragDropOrBrowseFiles}
                </div>
                <small className="mt-1 text-gray-300 block">
                    {Messages.supportUploadFiles}
                </small>
            </div>
        );
    };

    return (
        <Fragment>
            <div className={wrapperClass}>
                {variant === "drag&drop" ? renderDragDrop() : null}
                <div className="d-flex" hidden={hidePreview}>
                    {listFile.map((fileItem: any, index) => (
                        <FileUploadItem
                            key={`${fileItem.id}_${index}_${fileItem?.fileUrl}`}
                            file={fileItem}
                            onChange={onChangeFile}
                            onRemove={!disabled ? onClickRemoveFile : undefined}
                            onClickImage={() =>
                                setOpenImageModal({ open: true, index })
                            }
                        />
                    ))}
                </div>
                {variant !== "drag&drop" ? renderButton() : null}
            </div>
            <ModalLightBox
                open={openImageModal.open}
                onClose={() => setOpenImageModal({ open: false })}
                currentIndex={openImageModal?.index!}
                images={
                    getImage
                        ? getImage(listFile)
                        : listFile?.map((image) => {
                              if (typeof image === "string") {
                                  return { src: image };
                              }
                              return {
                                  src: image?.imageData || image.fileUrl,
                              };
                          })
                }
            />
        </Fragment>
    );
};

const UploadButton = forwardRef(UploadButtonRef);
export default UploadButton;
