import { TabBar, ViewLabelStatus } from "d-react-components";
import { filter, find, map } from "lodash";
import { useContext, useMemo, useState, Fragment } from "react";
import { useParams } from "react-router";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import DeliveryConfirmationView from "../../../delivery-driver/shared/DeliveryConfirmationView";
import Messages from "../../../../languages/Messages";
import { DeliveryConfirmationStatus } from "../../../../api/hooks";
import { DELIVERY_DRIVER_CONFIRMATION_STATUSES } from "../../../../constant/delivery-driver";

const StockTransferOrigin = () => {
    const { transferId } = useParams<any>();
    const { stockTransfer, loadTransferDetail } = useContext(
        StockTransferDetailContext
    );

    const { deliveries } = stockTransfer;

    const TABS = useMemo(() => {
        return map(deliveries, (deliveryItem, index) => {
            return {
                id: deliveryItem?.id ?? index,
                label: deliveryItem.deliveryNo,
            };
        });
    }, [deliveries ?? []]);
    const [tab, setTab] = useState(TABS[0]);

    const renderContent = () => {
        const renderTabContent = () => {
            const activeDelivery = find(deliveries, (i) => i?.id === tab?.id);
            const { originConfirmation } = activeDelivery ?? {};

            if (!activeDelivery) {
                return <div />;
            }

            if (
                originConfirmation?.status ===
                DeliveryConfirmationStatus.Pending
            ) {
                return (
                    <ViewLabelStatus
                        className="mt-3"
                        status={originConfirmation?.status}
                        listStatus={DELIVERY_DRIVER_CONFIRMATION_STATUSES}
                    />
                );
            }

            return (
                <DeliveryConfirmationView
                    status={originConfirmation?.status}
                    giver={originConfirmation?.staff}
                    receiver={originConfirmation?.driver}
                    delivery={activeDelivery}
                    confirmationTime={originConfirmation?.driver?.createdAt}
                />
            );
        };

        return (
            <Fragment>
                <TabBar
                    dataSource={TABS}
                    onChange={(v) => {
                        setTab(v as any);
                    }}
                    value={tab}
                />
                {renderTabContent()}
            </Fragment>
        );
    };

    return (
        <div className="py-3 px-4 bg-white border mt-3">
            <h5 className="mb-3">{Messages.originPickupConfirmation}</h5>
            {TABS?.length > 0 && renderContent()}
        </div>
    );
};

export default StockTransferOrigin;
