import {
    IRowsKey,
    InputText,
    Notifications,
    Progress,
    Select,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { find, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import {
    useSearchCategoryMaterialLazyQuery,
    useSearchVendorLazyQuery,
    useUpdateMaterialMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import GalleryUpload from "../../../common/gallery/GalleryUpload";
import { MATERIAL_STATUSES } from "../../../constant/material";
import { MaterialDetailContext } from "../../../context/material";
import { MaterialUpdateSchema } from "../../../formschema/material";
import { IMaterial } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";
import Gallery from "../../../common/gallery/Gallery";

const MaterialDetailGeneralInfo = () => {
    const { material, setMaterial } = useContext(MaterialDetailContext);
    const [showEditModal, setShowEditModal] = useState(false);

    const DETAILS_KEY: IRowsKey<IMaterial>[] = [
        {
            id: "materialNo",
            label: Messages.id,
        },
        {
            id: "category",
            label: Messages.category,
            renderContent: ({ data }) => {
                return data?.name;
            },
        },
        {
            id: "sku",
            label: Messages.sku,
        },
        {
            id: "purchaseUnit",
            label: Messages.purchasingUnit,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "trackingUnit",
            label: Messages.trackingUnit,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "trackingValue",
            label: Messages.unitConversionRate,
            renderContent: ({ data, item }) => {
                return `${item.purchaseValue} ${item.purchaseUnit?.name} = ${data} ${item.trackingUnit?.name}`;
            },
        },
        {
            id: "lowStockQuantity",
            label: Messages.lowStockQuantity,
            renderContent: ({ data, item }) => {
                return `${data} ${item.trackingUnit?.name}`;
            },
        },
        {
            id: "lastPurchasingUnitCost",
            label: Messages.lastPurchasingUnitCost,
            renderContent: ({ data, item }) => {
                return `${data} THB/ ${item.purchaseUnit?.name}`;
            },
        },
        {
            id: "lastTrackingUnitCost",
            label: Messages.lastTrackingUnitCost,
            renderContent: ({ data, item }) => {
                return `${data} THB/ ${item.trackingUnit?.name}`;
            },
        },
        {
            id: "totalQuantityOnPurchase",
            label: Messages.onOrderQty,
        },
        //@ts-ignore
        ...(material?.gallery?.length
            ? [
                  {
                      id: "gallery",
                      label: Messages.gallery,
                      renderContent: ({ data }: any) => (
                          <Gallery
                              gallery={data}
                              itemClassName="w-16 h-16 mr-2"
                          />
                      ),
                  },
              ]
            : []),
        //@ts-ignore
        ...(material?.description?.length
            ? [
                  {
                      id: "description",
                      label: Messages.description,
                  },
              ]
            : []),
        {
            //@ts-ignore
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            //@ts-ignore
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];
    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <ViewRowInterchange
                keyList={DETAILS_KEY}
                dataSource={material}
                variant="background"
            />
            {showEditModal && (
                <ModalEditInfoContent
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    onSave={() => {
                        setMaterial();
                        setShowEditModal(false);
                    }}
                />
            )}
        </div>
    );
};

const ModalEditInfoContent = ({ open, onClose, onSave }: any) => {
    const { material } = useContext(MaterialDetailContext);
    const [searchVendor] = useSearchVendorLazyQuery();
    const [searchCategoryMaterial] = useSearchCategoryMaterialLazyQuery();
    const [updateMaterial] = useUpdateMaterialMutation();
    const {
        name,
        sku,
        status,
        category,
        description,
        lowStockQuantity,
        vendor,
        gallery,
    } = material || {};
    const materialForm = useFormik<any>({
        initialValues: {
            name,
            sku,
            status,
            category: [category],
            description,
            lowStockQuantity,
            vendor: [vendor],
            gallery: map(gallery, (item) => ({ fileUrl: item })),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MaterialUpdateSchema,
        onSubmit: (values: any) => {
            const {
                name,
                sku,
                status,
                category,
                description,
                lowStockQuantity,
                vendor,
                gallery,
            } = values;

            const payload = {
                name,
                sku,
                status,
                ...(category ? { category: category?.[0]?.value } : {}),
                description,
                lowStockQuantity: Number(lowStockQuantity),
                ...(vendor ? { vendor: vendor?.[0]?.value } : {}),
                gallery: map(gallery, (item) => item.fileUrl),
            };

            Progress.show(
                {
                    method: updateMaterial,
                    params: {
                        variables: {
                            payload,
                            ...(material?.id ? { id: material?.id } : {}),
                        },
                    },
                },
                (res: any) => {
                    Notifications.showSuccess(Messages.updateSuccess);
                    onSave && onSave();
                }
            );
        },
    });
    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = materialForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="600px"
            title={Messages.generalInformation}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <div className="row w-fill-available">
                    <div className="col-md-6 mb-3">
                        <InputText
                            value={formValues?.name ?? ""}
                            label={Messages.name}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                materialForm.setFieldValue(
                                    `name`,
                                    event?.target?.value
                                )
                            }
                            error={
                                (formErrors?.name || formErrors?.name) as string
                            }
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <InputText
                            value={formValues?.sku ?? ""}
                            label={Messages.sku}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                materialForm.setFieldValue(
                                    `sku`,
                                    event?.target?.value
                                )
                            }
                            error={
                                (formErrors?.sku || formErrors?.sku) as string
                            }
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <Select
                            dataSource={MATERIAL_STATUSES}
                            value={formValues?.status}
                            label={Messages.status}
                            placeholder={Messages.pleaseSelect}
                            getLabel={(item) => Messages[item.label]}
                            onChange={(value) =>
                                materialForm.setFieldValue(`status`, value)
                            }
                            error={
                                (formErrors?.status ||
                                    formErrors?.status) as string
                            }
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <SelectInfinityFilter
                            fetchFn={(params: any, paging: any) =>
                                searchCategoryMaterial({
                                    variables: {
                                        paginate: {
                                            search: params?.search ?? "",
                                            limit: paging.pageSize,
                                            page: paging.pageIndex,
                                        },
                                    },
                                })
                            }
                            dataKey="data"
                            getLabel={(item) => (item ? item.name : null)}
                            value={formValues?.category}
                            label={Messages.category}
                            placeholder={Messages.pleaseSelect}
                            onChange={(value) =>
                                materialForm.setFieldValue(`category`, value)
                            }
                            error={
                                (formErrors?.category ||
                                    formErrors?.category) as string
                            }
                        />
                    </div>
                    <div className="col-md-12 mb-3">
                        <InputText
                            multiple
                            value={formValues?.description ?? ""}
                            label={Messages.description}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                materialForm.setFieldValue(
                                    `description`,
                                    event?.target?.value
                                )
                            }
                            error={
                                (formErrors?.description ||
                                    formErrors?.description) as string
                            }
                        />
                    </div>
                </div>
                <div className="border-t border-solid border-gray-200 mt-4 pt-4">
                    <div className="row w-fill-available">
                        <div className="col-md-6 mb-3">
                            <SelectInfinityFilter
                                value={formValues?.vendor}
                                label={Messages.preferredVendor}
                                placeholder={Messages.pleaseSelect}
                                onChange={(value) =>
                                    materialForm.setFieldValue(`vendor`, value)
                                }
                                error={
                                    (formErrors?.vendor ||
                                        formErrors?.vendor) as string
                                }
                                fetchFn={(params: any, paging: any) =>
                                    searchVendor({
                                        variables: {
                                            paginate: {
                                                search: params?.search ?? "",
                                                limit: paging.pageSize,
                                                page: paging.pageIndex,
                                            },
                                        },
                                    })
                                }
                                dataKey="data"
                                getLabel={(item) => (item ? item.name : null)}
                            />
                        </div>
                    </div>
                </div>
                <div className="border-t border-solid border-gray-200 mt-4 pt-4">
                    <GalleryUpload form={materialForm} />
                </div>
            </div>
        </Drawer>
    );
};

export default MaterialDetailGeneralInfo;
