const translations: { [key: string]: string } = {
    tel: "โทร",
    taxIDNo: "เลขประจำตัวผู้เสียภาษี",
    fax: "แฟกซ์",
    receipt: "ใบเสร็จรับเงิน",
    taxInvoice: "ใบกำกับภาษีอย่างย่อ",
    order: "คำสั่งซื้อ",
    no: "No",
    storeCode: "รหัสร้าน",
    staffCode: "หัสพนักงาน",
    termCondition: "หมายเหตุ",
    patientsMayReturn:
        "- บริษัทจะรับคืนยาเฉพาะรายการที่มีอาการแพ้ยาซึ่งวินิจฉัยโดยแพทย์เท่านั้น กรุณาเก็บใบเสร็จเป็นหลักฐานในการติดต่อกับบริษัททุกครั้ง",
    shippingInfo: "ข้อมูลการเรียกเก็บเงิน",
    billingInfo: "ข้อมูลการเรียกเก็บเงิน",
    name: "รายการ",
    price: "ราคารวม",
    quantity: "จำนวน",
    subTotal: "มูลค่า",
    total: "ยอดรวมทั้งหมด",
    shippingCost: "ค่าขนส่ง",
    unitPrice: "ราคาต่อหน่วย",
    billingInformation: "ข้อมูลการเรียกเก็บเงิน",
    shippingInformation: "ข้อมูลการจัดส่งสินค้า",
    taxIncluded: "ภาษี (รวมภาษีมูลค่าเพิ่ม 7%)",
    configuration: "Configuration",
    equipmentConfiguration: "Equipment configuration",
    simple: "Simple",
    configurable: "Configurable",
    thisIsSimpleEquipment: "This is simple equipment",
    thisIsConfigurableEquipment: "This is configurable equipment",
    image: "Image",
    basicHealthInformation: "Basic health information",
    report: "Report",
    cashierReport: "Cashier report",
    previous: "Previous",
    totalInvoice: "Total invoice",
    settleAmount: "Settle amount",
    unsettleAmount: "Unsettle amount",
    balanceOfInvoice: "Balance of invoice",
    createdDate: "Created date",
    salespersonUserReport: "Salesperson report by user",
    salesperson: "Salesperson",
    reportDate: "Report date",
    totalSaleAmountConfirmed: "Total Sales Amount (Only Confirmed SO)",
    totalPendingAmountConfirm:
        "Total Sales Amount (Incl. Pending Confirmation SO)",
    totalCustomerServed: "Total Customer Served",
    SO: "SO",
    actualUsed: "Actual used",
    staffName: "Staff name",
    quantityActual: "Quantity (Actual)",

};

export default translations;
