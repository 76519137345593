import {
    Button,
    DialogManager,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import VoucherAPI from "../../../api/queries/voucher";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    VOUCHER_OWNER_STATUS,
    VOUCHER_STATUS,
    VOUCHER_STATUSES,
} from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import { VoucherCancelSchema } from "../../../formschema/voucher";
import Messages from "../../../languages/Messages";

interface IVoucherCancelDrawer {
    open: boolean;
    onClose: () => void;
}

const VoucherCancelDrawer = ({ open, onClose }: IVoucherCancelDrawer) => {
    const { setVoucher, voucher } = useContext(VoucherDetailContext);

    const cancelForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VoucherCancelSchema,
        onSubmit: (values: any) => {
            onCancelVoucher(values);
        },
    });

    const onCancelVoucher = (input: any) => {
        if (!input?.remark) return;
        Progress.show(
            {
                method: VoucherAPI.cancel,
                params: [voucher?.id, input?.remark],
            },
            (newVoucher: any) => {
                setVoucher(newVoucher);
                Notifications.showSuccess(Messages.cancelSuccess);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.cancel}
            onSave={() => cancelForm.handleSubmit()}
            size="medium"
        >
            <div className="p-3">
                <InputTextForm form={cancelForm} keyData="remark" multiple />
            </div>
        </Drawer>
    );
};

const VoucherDetailHeader = () => {
    const { voucher, setVoucher } = useContext(VoucherDetailContext);
    const [openCancel, setOpenCancel] = useState(false);

    const hiddenCancel = useMemo(() => {
        return (
            (voucher.status !== VOUCHER_STATUS.AVAILABLE &&
                voucher.status !== VOUCHER_STATUS.EXPIRING) ||
            voucher?.ownership?.status === VOUCHER_OWNER_STATUS.CANCELLED
        );
    }, [voucher?.ownership?.status]);

    return (
        <div className="w-100 p-3 flex-row-between-center border-bottom">
            <div className="d-flex">
                <h4 className="text-primary mr-3">{voucher?.code}</h4>
                <ViewLabelStatus
                    listStatus={VOUCHER_STATUSES}
                    status={voucher?.status}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            </div>
            <div className="d-flex">
                <Button onClick={() => {}} className="mr-3" variant="outline">
                    {Messages.print}
                </Button>
                <Button
                    onClick={() => setOpenCancel(true)}
                    className=""
                    variant="outline"
                    hidden={hiddenCancel}
                >
                    {Messages.cancel}
                </Button>
            </div>
            {openCancel && (
                <VoucherCancelDrawer
                    open={openCancel}
                    onClose={() => setOpenCancel(false)}
                />
            )}
        </div>
    );
};

export default VoucherDetailHeader;
