import {
    Button,
    InputText,
    Notifications,
    Select,
    StringUtils,
} from "d-react-components";
import { filter, find, includes, map, round } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { PaymentMethod, PaymentStatus } from "../../../../api/hooks";
import Drawer from "../../../../common/Drawer";
import { PaymentContext } from "../../../../context/payment";
import Messages from "../../../../languages/Messages";
import { getPaidFromPayments } from "../../../../utils/payment";
import PriceView from "../../../../common/views/PriceView";

const SplitPaymentInputItem = ({
    index,
    onClickDelete,
    payment,
    onChangeValue,
    paymentMethods = [],
    mtBankList = [],
}: any) => {
    const {
        status,
        total,
        paymentMethod,
        numberInstalment,
        bankName,
        paymentOption,
    } = payment;
    const disabled = status === PaymentStatus.Confirmed;
    const { payment2C2POptionsList } = useContext(PaymentContext);

    // const renderAdditionalModernTrade = () => {
    //     return (
    //         <>
    //             <Select
    //                 disabled={disabled}
    //                 className="mt-3"
    //                 label={t("updatePaymentMethodSuccessfully")}
    //                 dataSource={NUMBER_INSTALMENT}
    //                 value={numberInstalment}
    //                 onChange={(value) => {
    //                     onChangeValue("numberInstalment", value);
    //                 }}
    //                 placeholder={t("pleaseSelect")}
    //             />
    //             <Select
    //                 className="mt-3"
    //                 dataSource={mtBankList}
    //                 getLabel={(item) => (
    //                     <div className="flex items-center">
    //                         {item.logo && (
    //                             <img
    //                                 src={item.logo}
    //                                 className="w-4 h-4 mr-2"
    //                                 alt=""
    //                             />
    //                         )}
    //                         {item.code}
    //                     </div>
    //                 )}
    //                 label={t("bankName")}
    //                 value={bankName}
    //                 onChange={(value) => onChangeValue("bankName", value)}
    //                 placeholder={t("pleaseSelect")}
    //             />
    //         </>
    //     );
    // };

    const renderAdditional2c2p = () => (
        <Select
            className="mt-3"
            label={Messages.paymentOption}
            dataSource={payment2C2POptionsList}
            value={paymentOption}
            getLabel={(item) => item.title}
            getValue={(item: any) => item?.option}
            onChange={(value) => {
                onChangeValue("paymentOption", value);
            }}
        />
    );

    const renderAdditionalInput = () => {
        switch (paymentMethod) {
            // case PAYMENT_MODERN_TRADE:
            //     return renderAdditionalModernTrade();
            case PaymentMethod.Payment_2C2P:
                return renderAdditional2c2p();
            default:
                return <div />;
        }
    };

    return (
        <div className="flex-column mt-3">
            <div className="d-flex flex-center-y border-bottom border-primary">
                <label className="text-primary text-uppercase w-100">
                    {Messages.payment} {index + 1}
                </label>
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={onClickDelete}
                    disabled={disabled}
                />
            </div>
            <Select
                className="mt-3"
                label={Messages.method}
                dataSource={paymentMethods}
                value={paymentMethod}
                getValue={(item: any) => item?.paymentConfigurationType}
                getLabel={(item) => {
                    const selected = find(paymentMethods, {
                        paymentConfigurationType:
                            item?.paymentConfigurationType,
                    });
                    return selected ? selected.title : "N/A";
                }}
                key={index}
                onChange={(value) => {
                    const selected = find(paymentMethods, {
                        paymentConfigurationType: value,
                    });
                    onChangeValue(
                        "paymentMethod",
                        selected?.paymentConfigurationType
                    );
                }}
                disabled={disabled}
                allowClear={false}
            />

            <InputText
                className="mt-3"
                label={Messages.amount}
                // value={total.toLocaleString()}
                value={total}
                onChange={(event) => {
                    // const result = event.target.value.replace(/\D/g, "");
                    // let value = parseInt(result, 10);
                    // if (!value) {
                    //     value = 0;
                    // }
                    const result = event.target.value;
                    onChangeValue("total", result);
                }}
                suffix="฿"
                disabled={status === PaymentStatus.Confirmed}
            />
            {renderAdditionalInput()}
        </div>
    );
};

const SplitPaymentInput = ({
    total,
    setRemainAmount,
    listPayment,
    setListPayment,
}: any) => {
    const [paidTotal, setPaidTotal] = useState<any>();
    const [mtBankList, setMTBankList] = useState([]);
    const { paymentMethodsList } = useContext(PaymentContext);
    const defaultMethod = paymentMethodsList?.[0];
    const defaultPayment = {
        total: "0",
        paymentMethod: defaultMethod?.paymentConfigurationType,
    };

    useEffect(() => {
        let result = 0;
        const clone = [...listPayment];
        clone.forEach((item) => {
            result += parseFloat(item.total);
        });
        setPaidTotal(result);
    }, [listPayment]);

    // useEffect(() => {
    //     modernTradeBankList().then((res) => {
    //         const bankList = res?.data?.data?.brand_name ?? [];
    //         setMTBankList(bankList);
    //     });
    // }, []);

    useEffect(() => {
        setRemainAmount(total - paidTotal);
    }, [paidTotal]);

    const handleOnClickSplitMore = () => {
        if (listPayment.length === 10) {
            return;
        }
        const clone = [...listPayment];
        clone.push({ ...defaultPayment, id: Math.random() });
        setListPayment(clone);
    };

    const setChangeValuePaymentItem = (key: string, value: any, id: string) => {
        const cloneList = [...listPayment];
        // const clonePaidTotal = paidTotal;
        // const oldValue = cloneList.find((item) => item.id === id).total;
        // if (key === "total" && clonePaidTotal - oldValue + value > total) {
        //     Notifications.showError(
        //         Messages.totalPaidAmountCannotGreaterThanTotalCart
        //     );
        //     return;
        // }
        const newList = cloneList.map((i) => {
            const item = { ...i };
            if (item.id === id) {
                item[key] = value;
                return item;
            }
            return item;
        });
        setListPayment(newList);
    };

    const onClickDeletePaymentHandle = (id: string) => {
        if (listPayment.length === 1) {
            return;
        }
        const clone = listPayment.filter((item: any) => item.id !== id);
        setListPayment(clone);
    };

    return (
        <div className="mt-3">
            <div className="flex-column border-bottom pb-3">
                {map(listPayment, (payment, index) => (
                    <SplitPaymentInputItem
                        key={index}
                        index={index}
                        onClickDelete={() =>
                            onClickDeletePaymentHandle(payment.id)
                        }
                        payment={payment}
                        paymentMethods={
                            listPayment.length > 1
                                ? filter(
                                      paymentMethodsList,
                                      (item) =>
                                          item.paymentConfigurationType !==
                                          (PaymentMethod.Default as any)
                                  )
                                : paymentMethodsList
                        }
                        onChangeValue={(key: string, value: any) =>
                            setChangeValuePaymentItem(key, value, payment.id)
                        }
                        // mtBankList={mtBankList}
                    />
                ))}
            </div>
            <div
                onClick={handleOnClickSplitMore}
                className="cursor-pointer text-primary mt-3"
            >
                {Messages.splitMore}
            </div>
        </div>
    );
};

const SplitPaymentDrawer = ({
    open,
    onClose,
    paymentList: defaultPaymentList,
    onSave,
}: any) => {
    const { ref: order } = useContext(PaymentContext);
    const [paymentList, setPaymentList] = useState(defaultPaymentList);
    const [remainAmount, setRemainAmount] = useState(0);

    const onClickSaveHandle = () => {
        onSave(paymentList, remainAmount);
    };

    const getTotalRemainingOrder = () => {
        const totalAmount = order?.total ?? 0;
        const paid = getPaidFromPayments(paymentList);
        return totalAmount - paid;
    };

    const setListPaymentHandle = (list: any) => {
        setPaymentList(list);
    };

    return (
        <Drawer
            title={Messages.splitPayment}
            open={open}
            onClose={onClose}
            onSave={onClickSaveHandle}
            size="auto"
            width={600}
        >
            <div className="p-3">
                <div className="px-3 py-2 bg-muted w-100">
                    <div className="flex flex-col md:flex-row justify-content-between py-2">
                        <label className="text-primary">
                            {Messages.totalCart}
                        </label>
                        <PriceView value={getTotalRemainingOrder()} />
                    </div>
                    <div className="flex flex-col md:flex-row justify-content-between py-2 border-top-dashed">
                        <label className="text-primary">
                            {Messages.splitAmount}
                        </label>
                        <PriceView
                            value={getTotalRemainingOrder() - remainAmount}
                        />
                    </div>
                    <div className="flex flex-col md:flex-row justify-content-between py-2 border-top-dashed">
                        <label className="text-primary">
                            {Messages.remainingAmount}
                        </label>
                        <PriceView value={round(remainAmount * 100) / 100} />
                    </div>
                </div>
                <SplitPaymentInput
                    total={getTotalRemainingOrder()}
                    setRemainAmount={(value: any) => setRemainAmount(value)}
                    listPayment={paymentList}
                    setListPayment={setListPaymentHandle}
                    order={order}
                />
            </div>
        </Drawer>
    );
};

export default SplitPaymentDrawer;
