import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useSelectVehicleForAdminLazyQuery } from "../../../api/hooks";
import { IVehicle } from "../../../interfaces/vehicle";
import Messages from "../../../languages/Messages";

interface IVehicleSelect {
    className?: string;
    value?: IVehicle | IVehicle[];
    multiple?: boolean;
    onChange: (value: IVehicle | IVehicle[]) => void;
    [key: string]: any;
}

const VehicleSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IVehicleSelect) => {
    const [vehicleList, setVehicleList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getVehicleList] = useSelectVehicleForAdminLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setVehicleList([]);
        } else {
            getVehicleList({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 15,
                        search: textSearch,
                    },
                },
            }).then((res: any) => {
                const branchRes = res?.data?.data?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterBranch = map(
                    [...selectedValue, ...branchRes],
                    (item) => ({
                        ...item,
                    })
                );
                const uniqBranch = uniqBy(filterBranch, (item) => item.id);
                setVehicleList(uniqBranch);
            });
        }
    }, 500);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(vehicleList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(vehicleList, id));
    };

    const branchValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.vehicle}
            dataSource={vehicleList}
            getLabel={(item) => `${item.brand} ${item.model} - ${item.internalCode}`}
            value={branchValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            filterOption={false}
            {...selectProps}
        />
    );
};

export default VehicleSelect;
