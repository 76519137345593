import { CSSProperties } from "react";
import { generatePath } from "react-router-dom";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { IMaterial } from "../../../interfaces/material";

interface IMaterialNameView {
    material: IMaterial;
    isHideImage?: boolean;
    subContent?: any;
    subName?: any;
    className?: string;
    classNameLabel?: string;
    classNameContent?: string;
    classNameImage?: string;
    styleImage?: CSSProperties;
    showLink?: boolean;
}

const MaterialNameView = ({
    material,
    isHideImage = false,
    subContent = <div />,
    subName,
    className,
    classNameLabel,
    classNameContent,
    classNameImage,
    styleImage = {},
    showLink = true,
}: IMaterialNameView) => {
    const { name, gallery } = material ?? {};

    const getNameMaterial = () => {
        return showLink ? (
            <a
                className={`h5 ${classNameLabel}`}
                href={generatePath(Path.MATERIAL_DETAIL, {
                    materialId: material?.id ?? "id",
                })}
            >
                {name}
            </a>
        ) : (
            <span className={`h5 text-primary ${classNameLabel}`}>{name}</span>
        );
    };

    const renderImage = () => {
        const urlDefault = "/images/placeholder.png";
        if (isHideImage) {
            return <div />;
        }
        return (
            <img
                src={gallery?.[0] ?? urlDefault}
                className={`image-square-small ${classNameImage}`}
                style={styleImage}
            />
        );
    };

    return (
        <div className={`d-flex ${className}`}>
            {renderImage()}
            <div className={`flex-column ml-2 ${classNameContent}`}>
                <div className="d-flex">
                    <text className="d-flex flex-wrap align-items-center">
                        {getNameMaterial()}
                        <div
                            hidden={!subName}
                            className="width-fit-content ml-1"
                        >
                            {subName && subName}
                        </div>
                    </text>
                </div>
                <div className="small">{`${Messages.sku}: ${material?.sku}`}</div>

                {subContent}
            </div>
        </div>
    );
};

export default MaterialNameView;
