import { gql } from "@apollo/client";
import { F_ADMIN_USER, F_ADMIN_USER_SCHEDULE } from "../fragments/admin";
import { F_PAGINATE } from "../fragments/app";
import { F_ROLE } from "../fragments/role";
import API from "../API";
import { ListAdminUserRequest } from "../hooks";

export const listUserForAdmin = gql`
    query userList($paginate: ListAdminUserRequest!) {
        listAdminUserForAdmin(paginate: $paginate) {
            data {
                ...F_ADMIN_USER
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
    ${F_ADMIN_USER}
    ${F_PAGINATE}
`;

export const listRole = gql`
    query roleList($paginate: ListAdminRoleRequest!) {
        listAdminRoleForAdmin(paginate: $paginate) {
            data {
                ...F_ROLE
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
    ${F_ROLE}
    ${F_PAGINATE}
`;

export const createUser = gql`
    mutation createAdminUser($payload: CreateAdminUserRequest!) {
        createAdminUserForAdmin(payload: $payload) {
            data {
                ...F_ADMIN_USER
            }
        }
    }
    ${F_ADMIN_USER}
`;

export const updateAdminUser = gql`
    mutation updateAdminUser($id: String!, $payload: UpdateAdminUserRequest!) {
        updateAdminUserForAdmin(id: $id, payload: $payload) {
            data {
                ...F_ADMIN_USER
            }
        }
    }
    ${F_ADMIN_USER}
`;

export const generateAuthenticator = gql`
    mutation generateAuthenticatorSecret {
        generateAuthenticatorSecret {
            secret
        }
    }
`;

export const updateAdminUserTherapistFee = gql`
    mutation updateAdminUserTherapistFee(
        $id: String!
        $payload: [AdminUserJobCostRequest!]!
    ) {
        updateTherapistFeeAdminUserForAdmin(id: $id, payload: $payload) {
            data {
                ...F_ADMIN_USER
            }
        }
    }
    ${F_ADMIN_USER}
`;

export const updateAdminUserSpecialAccess = gql`
    mutation updateAdminUserSpecialAccess(
        $id: String!
        $payload: UpdateSpecialAccessAdminUserRequest!
    ) {
        updateSpecialAccessAdminUserForAdmin(id: $id, payload: $payload) {
            data {
                ...F_ADMIN_USER
            }
        }
    }
    ${F_ADMIN_USER}
`;

export const removeBranchFromAdmin = gql`
    mutation removeBranchFromAdmin($id: String!, $branchIds: [String!]!) {
        removeBranchFromAdminForAdmin(id: $id, branchIds: $branchIds) {
            data {
                ...F_ADMIN_USER
            }
        }
    }
    ${F_ADMIN_USER}
`;

export const getUserScheduleByDate = gql`
    query getUserScheduleByDate(
        $userId: String!
        $branchId: String!
        $date: DateTime!
    ) {
        data: getUserScheduleByDateForAdmin(
            userId: $userId
            branchId: $branchId
            date: $date
        ) {
            data {
                ...F_ADMIN_USER_SCHEDULE
            }
        }
    }
    ${F_ADMIN_USER_SCHEDULE}
`;

export const updateCommissionServiceAdmin = gql`
    mutation updateCommissionServiceAdmin(
        $id: String!
        $payload: AdminUserCommissionServiceRequest!
    ) {
        updateCommissionServiceAdminUserForAdmin(id: $id, payload: $payload) {
            data {
                ...F_ADMIN_USER
            }
        }
    }
    ${F_ADMIN_USER}
`;

const UserAPI = {
    updateCommissionServiceAdmin: async (id: string, payload: any) =>
        API.mutate({
            mutation: updateCommissionServiceAdmin,
            variables: { id, payload },
        }),

    list: async (paginate: ListAdminUserRequest) =>
        API.query({
            query: listUserForAdmin,
            variables: { paginate },
        }),
};

export default UserAPI;
