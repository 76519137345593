import { filter, includes, map, method } from "lodash";
import { CheckboxGroup, Select } from "d-react-components";
import { useImportContext } from "./context";
import Messages from "../../languages/Messages";

const SelectImportMethod = () => {
    const { step, formImport, importFieldSource, importData } =
        useImportContext();

    const { importType, importFields } = formImport?.values;

    const onChangeType = (value: any) => {
        // const importFieldsNew = [...importFieldSource];
        // // if (value === "create") {
        // //     importFieldsNew = importFieldSource;
        // // }
        formImport.setValues({
            ...formImport?.values,
            importType: value,
            // importFields: importFieldsNew,
        });
    };

    const onChangeImportField = (values: any = []) => {
        formImport.setFieldValue(
            "importFields",
            filter(
                importFieldSource,
                (item) => includes(values, item.name) || item.required
            )
        );
    };

    return (
        <div className="border p-4">
            <text>{Messages[step?.desc]}</text>
            <Select
                className="mt-3 mb-4"
                placeholder={Messages.selectImportTypeHint}
                dataSource={importData?.methods}
                value={importType}
                getLabel={(item) => Messages[item.label]}
                onChange={onChangeType}
            />
            <span>{Messages.elementToUpdate}</span>
            <CheckboxGroup
                label={Messages.whichElementDoYouWantUpdate}
                className="mt-3"
                dataSource={importFieldSource}
                value={map(importFields, (item) => item.name)}
                getValue={(item) => item.name}
                getLabel={(item) => Messages[item.label]}
                onChange={onChangeImportField}
                // disabled={importType === "create"}
                showSelectAll
            />
        </div>
    );
};
export default SelectImportMethod;
