import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import EquipmentAPI from "../../../api/queries/equipment";
import TimeSlotSelect, { ITimeSlotValue } from "../../../common/TimeSlotSelect";
import { IBranch } from "../../../interfaces/branch";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";
import EquipmentNameView from "../../equipment/share/EquipmentNameView";
import EquipmentSelect from "../../equipment/share/EquipmentSelect";

interface IEquipmentSelectValue {
    equipment: IEquipment | null;
    timeSlots: ITimeSlotValue[];
}

interface ITreatmentEquipmentSelect<T> {
    date: string;
    branch: IBranch | null;
    className?: any;
    form: any;
}

const TreatmentEquipmentSelect = ({
    date,
    form,
    branch,
    className,
}: ITreatmentEquipmentSelect<IEquipmentSelectValue>) => {
    const [equipmentSchedule, setEquipmentSchedule] = useState<any>();

    const formValues = form?.values;

    useEffect(() => {
        if (!formValues?.equipment || !branch || !date) {
            return;
        }
        const body = {
            equipmentId: formValues?.equipment?.id,
            branchId: branch?.id,
            date,
        };
        EquipmentAPI.getEquipmentSchedule(body).then((res) => {
            const equipmentSchedule = res?.data?.data?.data;
            setEquipmentSchedule(equipmentSchedule as any);
        });
    }, [formValues?.equipment, branch, date]);

    const equipmentOccupiedSchedule = useMemo(() => {
        return map(equipmentSchedule?.occupiedSlots ?? [], (item) => ({
            start: item?.schedule?.start / 3600,
            end: item?.schedule?.end / 3600,
        }));
    }, [formValues?.equipment, date, equipmentSchedule, branch]);

    const renderEquipmentSchedule = () => {
        if (!equipmentSchedule) return <div />;
        if (!equipmentSchedule?.isWorking) {
            return (
                <div className="mt-3 text-gray italic">
                    {Messages.equipmentNotAvailable}
                </div>
            );
        }
        return (
            <TimeSlotSelect
                workingSchedule={[{ start: 0, end: 24 }]}
                occupiedSchedule={equipmentOccupiedSchedule}
                className="mt-3"
                value={formValues?.timeSlot}
                onChange={(value) => form.setFieldValue("timeSlot", value)}
                multiple
            />
        );
    };

    return (
        <div className={className}>
            <EquipmentSelect
                onChange={(value) => form.setFieldValue("equipment", value)}
            />
            {formValues.equipment && (
                <EquipmentNameView
                    equipment={formValues.equipment}
                    className="mt-3"
                />
            )}
            {renderEquipmentSchedule()}
        </div>
    );
};

export default TreatmentEquipmentSelect;
