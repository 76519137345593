import React from "react";
import { IAgency } from "../interfaces/agency";

interface IAgencyDetailState {
    agencyDetail: IAgency;
    setAgencyDetail: any;
}

const agencyDetailState: IAgencyDetailState = {
    agencyDetail: {} as any,
    setAgencyDetail: () => {},
};

export const AgencyDetailContext = React.createContext(agencyDetailState);
