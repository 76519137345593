import {
    AwesomeTableComponent,
    IColumnsProps,
    Icon,
    InputTextSearch,
    ViewLabelStatus,
} from "d-react-components";
import {
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    join,
    map,
    toLower,
} from "lodash";
import CurrencyFormat from "react-currency-format";
import { useRef, useState } from "react";
import { generatePath, useParams } from "react-router";
import CustomerAPI from "../../../api/queries/customer";
import AppLink from "../../../common/AppLink";
import { VOUCHER_STATUSES, VOUCHER_TYPES } from "../../../constant/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import Drawer from "../../../common/Drawer";
import { getVoucherTableColumns } from "../../../common/vouchers/VoucherTableColumns";

export const VoucherCodeView = ({
    voucherList,
}: {
    voucherList: IVoucher[];
}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const tableRef = useRef<any>(null);

    const columns: IColumnsProps = [
        {
            title: Messages.code,
            dataIndex: "code",
            render: (code, voucher) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })}
                >
                    {code}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "type",
            render: (data) => {
                const voucherType = find(VOUCHER_TYPES, { id: data });
                return Messages[voucherType?.label as string];
            },
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    listStatus={VOUCHER_STATUSES}
                    status={status}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.usedTimes,
            dataIndex: "totalUsedTimes",
        },
        {
            title: Messages.usedValues,
            dataIndex: "totalUsedValues",
            render: (value) => (
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
    ];

    return (
        <div className="d-flex flex-row">
            {voucherList?.[0]?.code}
            {voucherList?.length > 0 && (
                <div
                    className="flex items-center text-primary cursor-pointer"
                    onClick={() => setOpenDrawer(true)}
                >
                    <Icon
                        name="visibility"
                        className="ml-2 mr-1 cursor-default"
                    />
                    ({voucherList?.length})
                </div>
            )}
            {openDrawer && (
                <Drawer
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    title={Messages.voucher}
                    size="auto"
                    width="800px"
                >
                    <div className="d-flex flex-column p-3">
                        <AwesomeTableComponent
                            ref={(ref) => {
                                tableRef.current = ref;
                            }}
                            dataSource={voucherList}
                            getTotalItems={(res) => {
                                return res?.pagination?.items ?? 0;
                            }}
                            columns={columns}
                            showSelectColumn
                            baseColumnProps={{ width: 100 }}
                        />
                    </div>
                </Drawer>
            )}
        </div>
    );
};

const CustomerDetailServiceHistory = () => {
    const { customerId } = useParams<any>();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const columns = getVoucherTableColumns({});

    const source = (pagingData: any) => {
        if (!customerId) {
            return Promise.resolve();
        }
        return CustomerAPI.serviceHistory({ customerId });
    };

    const transformer = (res: any) => {
        const sourceData = res?.data?.data?.data ?? [];

        if (isEmpty(searchRef.current)) {
            return sourceData;
        }

        const dataFiltered = filter(sourceData, (data) => {
            const { order, vouchers, product } = data;
            const voucherCodes = join(
                map(vouchers, (item) => item.code),
                ""
            );
            const voucherNo = join(
                map(vouchers, (item) => item.voucherNo),
                ""
            );

            const textSearch = toLower(
                order?.orderNo +
                    voucherCodes +
                    product?.product?.name +
                    voucherNo
            );

            return includes(textSearch, toLower(searchRef.current));
        });

        return dataFiltered;
    };

    const refreshTable = () => {
        return tableRef.current && tableRef.current.refresh();
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        refreshTable();
    }, 500);

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.voucher}</h5>
            </div>
            <div className="flex-center-y mt-3 gap-3">
                <InputTextSearch
                    className=""
                    onChange={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                />
            </div>
            <div className="mt-3">
                <AwesomeTableComponent
                    // baseColumnProps={{ width: 70 }}
                    ref={tableRef}
                    columns={columns}
                    source={source}
                    transformer={transformer}
                    baseColumnProps={{ width: 100 }}
                />
            </div>
        </div>
    );
};

export default CustomerDetailServiceHistory;
