import { Button } from "d-react-components";
import React, { useContext, useMemo, useState } from "react";
import {
    OrderStatus,
    PaymentMethod,
    PaymentStatus,
} from "../../../../api/hooks";
import { PAYMENT_CONFIRM_STATUS } from "../../../../constant/payment";
import { OrderRefType, PaymentContext } from "../../../../context/payment";
import { IPayment } from "../../../../interfaces/payment";
import Messages from "../../../../languages/Messages";
import PaymentConfirmDrawer from "./PaymentConfirmDrawer";

const PaymentConfirmButton = ({
    payment,
    onChangePaymentInfo,
}: {
    payment: IPayment;
    onChangePaymentInfo: any;
}) => {
    const { ref: order, refType } = useContext(PaymentContext);
    const [openModal, setOpenModal] = useState<any>({
        type: undefined,
        isVisible: false,
    });
    const isReject = payment?.status === PaymentStatus.Rejected;
    const isApprove = payment?.status === PaymentStatus.Confirmed;

    const onClickConfirm = () => {
        setOpenModal({
            isVisible: true,
            type: PAYMENT_CONFIRM_STATUS.APPROVE,
        });
    };
    const onClickReject = () => {
        setOpenModal({
            isVisible: true,
            type: PAYMENT_CONFIRM_STATUS.REJECTED,
        });
    };
    const onConfirmPayment = () => {
        setOpenModal({ isVisible: false });
        onChangePaymentInfo && onChangePaymentInfo();
    };

    const isShowButton = useMemo(() => {
        // if (payment.paymentMethod === PaymentMethod.Payment_2C2P) {
        //     return false;
        // }
        if (isApprove || isReject) {
            return false;
        }
        if (
            refType === OrderRefType.ORDER &&
            order?.status &&
            order.status !== OrderStatus.PendingPayment &&
            order.status !== OrderStatus.PendingConfirmation &&
            order.status !== OrderStatus.PartiallyPaid
        ) {
            return false;
        }
        if (refType === OrderRefType.QUOTATION && order?.hasOrder) {
            return false;
        }
        if (!payment?.proof || payment?.proof?.length === 0) {
            return false;
        }
        return true;
    }, [payment]);

    return isShowButton ? (
        <div className="flex">
            <Button
                onClick={onClickConfirm}
                className="mr-3"
                // disabled={isChainExternalSuspicious || hasCustomSizeProduct}
            >
                {Messages.confirm}
            </Button>
            <Button
                onClick={onClickReject}
                color="error"
                // disabled={isChainExternalSuspicious || hasCustomSizeProduct}
            >
                {Messages.reject}
            </Button>
            {openModal.isVisible && (
                <PaymentConfirmDrawer
                    open={openModal.isVisible}
                    onClose={() => setOpenModal({ isVisible: false })}
                    type={openModal.type}
                    payment={payment}
                    onSave={onConfirmPayment}
                />
            )}
        </div>
    ) : (
        <></>
    );
};

export default PaymentConfirmButton;
