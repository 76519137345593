import React from "react";
import { IVendor } from "../interfaces/vendor";

interface IVendorDetailState {
    vendor: IVendor;
    setVendor: any;
}

const vendorDetailState: IVendorDetailState = {
    vendor: {} as any,
    setVendor: () => {},
};

export const VendorDetailContext = React.createContext(
    vendorDetailState
);
