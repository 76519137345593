import { gql } from "@apollo/client";
import API from "../API";
import {
    F_EQUIPMENT_CATEGORY_LIST,
    F_EQUIPMENT_CATEGORY,
    F_EQUIPMENT,
    F_EQUIPMENT_LIST,
    F_EQUIPMENT_DETAIL,
    F_EQUIPMENT_SCHEDULE,
} from "../fragments/equipment";

export const getEquipmentCategoryList = gql`
    ${F_EQUIPMENT_CATEGORY_LIST}
    query getEquipmentCategoryList(
        $paginate: CategoryEquipmentPaginateRequest!
    ) {
        listCategoryEquipmentForAdmin(paginate: $paginate) {
            ...F_EQUIPMENT_CATEGORY_LIST
        }
    }
`;

export const createEquipmentCategory = gql`
    ${F_EQUIPMENT_CATEGORY}
    mutation createCategoryEquipmentForAdmin(
        $payload: CreateAdminCategoryEquipmentRequest!
    ) {
        createCategoryEquipmentForAdmin(payload: $payload) {
            data {
                ...F_EQUIPMENT_CATEGORY
            }
        }
    }
`;

export const getEquipmentCategoryDetail = gql`
    query detailCategoryEquipmentForAdmin($id: String!) {
        detailCategoryEquipmentForAdmin(id: $id) {
            data {
                ...F_EQUIPMENT_CATEGORY
            }
        }
    }
    ${F_EQUIPMENT_CATEGORY}
`;

export const updateCategoryEquipment = gql`
    mutation updateCategoryEquipmentForAdmin(
        $id: String!
        $payload: UpdateAdminCategoryEquipmentRequest!
    ) {
        updateCategoryEquipmentForAdmin(id: $id, payload: $payload) {
            data {
                ...F_EQUIPMENT_CATEGORY
            }
        }
    }
    ${F_EQUIPMENT_CATEGORY}
`;

export const getEquipmentList = gql`
    ${F_EQUIPMENT_LIST}
    query listEquipmentForAdmin($paginate: EquipmentPaginateRequest!) {
        data: listEquipmentForAdmin(paginate: $paginate) {
            ...F_EQUIPMENT_LIST
        }
    }
`;

export const getEquipmentToAddChildList = gql`
    ${F_EQUIPMENT_LIST}
    query listEquipmentToAddAsChildForAdmin(
        $paginate: EquipmentPaginateRequest!
    ) {
        data: listEquipmentToAddAsChildForAdmin(paginate: $paginate) {
            ...F_EQUIPMENT_LIST
        }
    }
`;

export const createEquipment = gql`
    ${F_EQUIPMENT}
    mutation createEquipmentForAdmin($payload: EquipmentCreateRequest!) {
        createEquipmentForAdmin(payload: $payload) {
            data {
                ...F_EQUIPMENT
            }
        }
    }
`;

export const updateEquipment = gql`
    ${F_EQUIPMENT}
    mutation updateEquipmentForAdmin(
        $id: String!
        $payload: EquipmentUpdateRequest!
    ) {
        updateEquipmentForAdmin(id: $id, payload: $payload) {
            data {
                ...F_EQUIPMENT
            }
        }
    }
`;

export const getEquipmentDetail = gql`
    query detailEquipmentForAdmin($id: String!) {
        detailEquipmentForAdmin(id: $id) {
            data {
                ...F_EQUIPMENT_DETAIL
            }
        }
    }
    ${F_EQUIPMENT_DETAIL}
`;

export const getEquipmentSchedule = gql`
    query getEquipmentSchedule(
        $equipmentId: String!
        $branchId: String!
        $date: DateTime!
    ) {
        data: getEquipmentScheduleByDateForAdmin(
            equipmentId: $equipmentId
            branchId: $branchId
            date: $date
        ) {
            data {
                ...F_EQUIPMENT_SCHEDULE
            }
        }
    }
    ${F_EQUIPMENT_SCHEDULE}
`;

const EquipmentAPI = {
    getEquipmentSchedule: async (input: any) =>
        API.query({
            query: getEquipmentSchedule,
            variables: input,
            fetchPolicy: "no-cache",
        }),
};

export default EquipmentAPI;
