import { Notifications } from "d-react-components";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useState } from "react";
import { MaterialPurchaseContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import ProductGrid from "../share/ProductGrid";
import ProductList from "../share/ProductList";

export const SIZE_OPTIONS = [18, 36, 72];

const MaterialPurchaseMaterialList = () => {
    const {
        productFilters,
        setProductFilters,
        productList,
        setProductList,
        materialRequest,
    } = useContext(MaterialPurchaseContext);
    const { values } = useFormikContext<any>();
    const [pagination, setPagination] = useState<any>({
        pageIndex: 1,
        pageSize: SIZE_OPTIONS[0],
    });

    const addNewProduct = useCallback(
        (product: any) => {
            let isProductExisted = false;
            let itemQuantity = 0;
            const productsResult = productList.map((item: any) => {
                if (product.id === item.id && !item?.doctorOrder?.id) {
                    isProductExisted = true;
                    itemQuantity = item.quantity + 1;
                    return {
                        ...item,
                        quantity: item.quantity + 1,
                    };
                }
                return item;
            });
            if (!isProductExisted) {
                productsResult.push({
                    ...product,
                    quantity: 1,
                    productOrderId: product?.id,
                });
                setProductList(productsResult);
            } else {
                setProductList(productsResult);
            }
            Notifications.showSuccess(Messages.itemAddedSuccessfully);
        },
        [productList, setProductList]
    );

    return (
        <div>
            {productFilters?.viewMode === "grid" && (
                <ProductGrid
                    {...{
                        pagination,
                        setPagination,
                        addNewProduct,
                    }}
                />
            )}
            {productFilters?.viewMode === "list" && (
                <ProductList
                    {...{
                        pagination,
                        setPagination,
                        addNewProduct,
                    }}
                />
            )}
        </div>
    );
};

export default MaterialPurchaseMaterialList;
