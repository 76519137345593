import { AwesomeTableComponent, Icon } from "d-react-components";
import { useFormikContext } from "formik";
import { useContext, useEffect, useRef } from "react";
import { useGetProductsFullDetailsLazyQuery } from "../../../api/hooks";
import { QuotationCreateContext } from "../../../context/quotation";
import { getProductPrice } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import { SIZE_OPTIONS } from "../crud/QuotationCreateProductList";
import ProductName from "./ProductName";

const ProductList = ({ pagination, setPagination, addNewProduct }: any) => {
    const productItemTable = useRef<any>(null);
    const { productFilters, orderMetrics } = useContext(QuotationCreateContext);
    const { values } = useFormikContext<any>();
    const { store } = values;
    const [getProductList] = useGetProductsFullDetailsLazyQuery();

    const getProduct = async (pagination: any) => {
        return getProductList({
            variables: {
                paginate: {
                    page: pagination?.pageIndex as any,
                    limit: pagination?.pageSize,
                    search: productFilters?.textSearch ?? "",
                    sort: {
                        createdAt: -1,
                    },
                    ...(productFilters?.branches?.length
                        ? {
                              branches: productFilters.branches,
                          }
                        : {}),
                    ...(productFilters?.categories?.length
                        ? {
                              categories: productFilters?.categories?.map(
                                  (item: any) => item.id
                              ),
                              isVisibility: true,
                          }
                        : {
                              isVisibility: true,
                          }),
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const columns = [
        {
            title: Messages.item,
            dataIndex: "product",
            width: 300,
            ellipsis: true,
            render: (product: any, item: any) => {
                return (
                    <ProductName
                        item={item}
                        applyPriceSet={orderMetrics?.applyPriceSet}
                    />
                );
            },
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            width: 120,
            render: (price: any, item: any) =>
                getProductPrice(
                    "regularPrice",
                    item,
                    orderMetrics?.applyPriceSet,
                    { addPrefix: true }
                ),
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            width: 120,
            render: (price: any, item: any) =>
                getProductPrice(
                    "salePrice",
                    item,
                    orderMetrics?.applyPriceSet,
                    {
                        addPrefix: true,
                    }
                ),
        },
        {
            title: Messages.weight,
            dataIndex: "weight",
            width: 120,
            render: (weight: any) => (weight ? `${weight}g` : "N/A"),
        },
        {
            title: Messages.action,
            dataIndex: "",
            width: 30,
            render: (productItem: any, item: any) => {
                return (
                    <span
                        className="cursor-pointer text-primary"
                        onClick={() => addNewProduct(item)}
                    >
                        <Icon name="add_shopping_cart_icon" />
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        productItemTable?.current?.refresh();
    }, [
        productFilters?.textSearch,
        productFilters?.categories,
        productFilters?.branches?.[0],
    ]);

    return (
        <div>
            <AwesomeTableComponent
                ref={productItemTable}
                source={(currentPage) => {
                    setPagination(currentPage);
                    return getProduct(currentPage);
                }}
                transformer={(res) => res?.data?.data?.data}
                columns={columns}
                isScroll={false}
                className="height-max-content mt-3 overflow-auto"
                defaultPagination={{
                    pageSize: SIZE_OPTIONS[0],
                    pageIndex: 1,
                    showQuickJumper: false,
                    pageSizeOptions: SIZE_OPTIONS,
                }}
            />
        </div>
    );
};

export default ProductList;
