import * as Yup from "yup";

export const ProductCategorySchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    description: Yup.string().required("Required field!"),
});

export const ProductInfoSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    description: Yup.string().required("Required field!"),
});

export const ProductSchema = Yup.object().shape({
    sku: Yup.string().required("Required field!"),
    regularPrice: Yup.number().required("Required field!"),
    salePrice: Yup.number().required("Required field!"),
    en: ProductInfoSchema,
    // th: ProductInfoSchema,
});

export const ProductGeneralSchema = Yup.object().shape({
    sku: Yup.string().required("Required field!"),
    en: Yup.object().shape({
        name: Yup.string().required("Required field!"),
    }),
    // th: ProductInfoSchema,
});
