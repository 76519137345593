import { useContext } from "react";
import { CustomerDetailContext } from "../../../context/customer";
import CustomerInfoBar from "../share/CustomerInfoBar";

const CustomerDetailInfo = () => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    return (
        <CustomerInfoBar
            customer={customer}
            onUpdate={setCustomer}
            showInfo={{ showCredit: true }}
        />
    );
};

export default CustomerDetailInfo;
