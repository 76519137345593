import { Avatar, Icon, TimeUtils } from "d-react-components";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const OrderHeader = () => {
    const { order } = useContext(OrderDetailContext);
    const isExpired = useMemo(() => {
        if (order?.expired) {
            return moment().isAfter(moment(order?.expired));
        }
        return false;
    }, [order]);
    return (
        <div className="flex items-center border p-3">
            <div className="flex flex-1">
                <div className="flex items-center mr-3">
                    <Icon name="date_range" />
                    <div className="ml-2 text-sm text-gray">{`${
                        Messages.createdOn
                    } ${TimeUtils.convertMiliToDateTime(
                        order?.createdAt
                    )}`}</div>
                </div>
                <div
                    className={`flex items-center ${
                        isExpired && "text-red-500"
                    }`}
                >
                    <Icon name={isExpired ? "access_alarm" : "access_timer"} className="w-[16px]" />
                    <div
                        className={`ml-2 text-sm ${
                            isExpired ? "text-red-500" : "text-gray "
                        }`}
                    >{`${
                        isExpired ? Messages.expiredAt : Messages.expiresOn
                    } ${TimeUtils.convertMiliToDateTime(order?.expired)}`}</div>
                </div>
            </div>
            <div className="flex items-center">
                <span className="text-sm">
                    {Messages.by} {order?.createByAdmin?.firstName}{" "}
                    {order?.createByAdmin?.lastName}
                </span>
                <Avatar
                    className="w-8 h-8 rounded object-cover ml-2"
                    alt=""
                    src={order?.createByAdmin?.avatar}
                    size="x-small"
                    text={order?.createByAdmin?.firstName}
                />
            </div>
        </div>
    );
};

export default OrderHeader;
