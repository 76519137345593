import {
    IDrawerProps,
    InputText,
    TabBar,
    ViewTextError,
} from "d-react-components";
import { FormikFormProps, useFormik } from "formik";
import * as Yup from "yup";
import React, { useMemo, useState } from "react";
import Drawer from "../../../common/Drawer";
import SignatureField from "../../../common/signature/SignatureField";
import UploadButton from "../../../common/upload/UploadButton";
import Messages from "../../../languages/Messages";

export interface IDeliveryConfirmDrawerProps extends IDrawerProps {
    onSave: (values: IDeliveryConfirmationInput) => any;
    receiverLabel?: string;
    giverLabel?: string;
}

export interface IDeliveryUserConfirmation {
    remark?: string;
    fullName?: string;
    proofs?: any[];
    signature: string;
}

export interface IDeliveryConfirmationInput {
    giver: IDeliveryUserConfirmation;
    receiver: IDeliveryUserConfirmation;
}

export const DeliveryDriverConfirmSchema = Yup.object().shape({
    giver: Yup.object().shape({
        signature: Yup.string().required(Messages.fieldRequired),
        fullName: Yup.string().required(Messages.fieldRequired),
    }),
    receiver: Yup.object().shape({
        signature: Yup.string().required(Messages.fieldRequired),
        remark: Yup.string().required(Messages.fieldRequired),
        proofs: Yup.array().required(Messages.fieldRequired),
    }),
});

const giverForm = (form: any) => {
    const { values, errors, setFieldValue, handleSubmit, setValues } = form;
    return (
        <React.Fragment>
            <SignatureField
                value={values?.giver?.signature}
                error={errors?.giver?.signature}
                onChange={(v: any) =>
                    setValues({
                        ...values,
                        giver: { ...(values?.giver ?? {}), signature: v },
                    })
                }
            />
            <InputText
                className="mt-3"
                label={Messages.fullName}
                value={values?.giver?.fullName}
                error={errors?.giver?.fullName}
                onChange={(e) =>
                    setFieldValue("giver.fullName", e?.target?.value)
                }
            />
        </React.Fragment>
    );
};

const receiverForm = (form: any) => {
    const { values, errors, setFieldValue, handleSubmit, setValues } = form;
    return (
        <React.Fragment>
            <SignatureField
                value={values?.receiver?.signature}
                error={errors?.receiver?.signature}
                onChange={(v: any) => setFieldValue("receiver.signature", v)}
            />
            <label className="mt-3 font-semibold">{Messages.proofs}</label>
            <UploadButton
                variant="square"
                onChange={(file: any) => {
                    setFieldValue("receiver.proofs", file);
                }}
                defaultFiles={values?.receiver?.proofs ?? []}
            />
            {errors?.receiver?.proofs && (
                <ViewTextError error={errors?.receiver?.proofs} />
            )}
            <InputText
                multiple
                label={Messages.remark}
                value={values?.receiver?.remark}
                error={errors?.receiver?.remark}
                onChange={(e) =>
                    setFieldValue("receiver.remark", e?.target?.value)
                }
            />
        </React.Fragment>
    );
};

const DeliveryConfirmDrawer: React.FC<IDeliveryConfirmDrawerProps> = ({
    open,
    onClose,
    onSave,
    receiverLabel = Messages.driver,
    giverLabel = Messages.staff,
    ...rest
}) => {
    const confirmForm = useFormik<IDeliveryConfirmationInput>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DeliveryDriverConfirmSchema,
        onSubmit: (values) => {
            onSave && onSave(values);
            onClose && onClose(values as any);
        },
    });
    const { values, errors, setFieldValue, handleSubmit, setValues } =
        confirmForm;

    const TABS = useMemo(
        () => [
            {
                id: "receiver",
                label: receiverLabel,
            },
            {
                id: "giver",
                label: giverLabel,
            },
        ],
        []
    );
    const [tab, setTab] = useState(TABS[0]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="800px"
            title={Messages.confirmation}
            {...rest}
            onSave={handleSubmit}
        >
            <div className="p-3">
                <TabBar
                    dataSource={TABS}
                    onChange={(v) => {
                        setTab(v as any);
                    }}
                    value={tab}
                />
                <div className="bg-white mt-3">
                    {tab?.id === "receiver" && receiverForm(confirmForm)}
                    {tab?.id === "giver" && giverForm(confirmForm)}
                </div>
            </div>
        </Drawer>
    );
};

export default DeliveryConfirmDrawer;
