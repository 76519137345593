import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import {
    filter,
    find,
    groupBy,
    includes,
    join,
    map,
    reduce,
    uniq,
} from "lodash";
import moment from "moment";
import { useState } from "react";
import { JobCostType, JobTypeAdmin } from "../../api/hooks";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import { ServiceCommissionType } from "../../constant/user";
import { ITreatment } from "../../interfaces/treatment";
import Messages from "../../languages/Messages";
import { exportToCSV } from "../../utils/file";
import { getFullName } from "../../utils/Utils";
import UserSelect from "../user/share/UserSelect";
import ReportFooter from "./ReportFooter";
import UserAPI from "../../api/queries/user";
import { JobType } from "../../interfaces/user";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    user: [],
    timeRange: null,
};

const ReportNurseDetail = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = async (values: any) => {
        let userList = values.user;

        if (!userList?.length) {
            const userListRes = await UserAPI.list({
                jobTypes: [JobTypeAdmin.Nurse],
                page: 1,
                limit: 100000,
            });
            userList = userListRes?.data?.listAdminUserForAdmin?.data ?? [];
        }
        const payload = {
            user: map(userList, (item) => item.id),
            from: values?.timeRange?.[0]?.valueOf(),
            to: values?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.treatmentUserDetail, params: [payload] },
            (dataList: any) => loadData(dataList, userList)
        );
    };

    const getMinutesOfTreatment = (treatment: ITreatment, userId: string) => {
        const pic = find(
            treatment.PIC,
            (item) => item?.adminUser.id === userId
        );
        // const jobCostList = pic?.jobCost ?? [];
        // const totalTime = reduce(
        //     jobCostList,
        //     (sum, jobCost) => sum + (jobCost?.end ?? 0) - (jobCost?.start ?? 0),
        //     0
        // );
        const totalTime = pic?.schedule?.end ?? 0 - (pic?.schedule?.start ?? 0);
        return totalTime;
    };

    const getNurseFeeOfTreatment = (treatment: ITreatment, userId: string) => {
        // const pic = find(
        //     treatment.PIC,
        //     (item) => item?.adminUser.id === userId
        // );
        const manualNurseFee =
            treatment?.manualJobCostAssessment?.[
                `${userId}&${JobCostType.NurseFee}`
            ];
        if (manualNurseFee) {
            return manualNurseFee?.amount ?? 0;
        }
        const totalAutoAssign = reduce(
            treatment.jobCostAssessment,
            (sum, jobCostAssessment) =>
                sum +
                (jobCostAssessment?.[`${userId}&${JobCostType.NurseFee}`]
                    ?.amount ?? 0),
            0
        );

        return totalAutoAssign;
    };

    const loadData = (dataList: any[], userList: any[]) => {
        const exportData: any = [];
        const firstDayMonth = moment(formValues?.timeRange?.[0]);
        const lastDayMonth = moment(formValues?.timeRange?.[1]);
        const dateRangeText = join(
            [
                firstDayMonth.format("DD/MM/YYYY"),
                lastDayMonth.format("DD/MM/YYYY"),
            ],
            " - "
        );

        try {
            for (let index = 0; index < userList?.length; index += 1) {
                const user = userList[index];
                const userId = user?.id;

                const userTreatments = filter(
                    dataList,
                    (treatment: ITreatment) => {
                        const picIds = map(
                            treatment.PIC,
                            (item) => item?.adminUser?.id
                        );
                        return includes(picIds, userId);
                    }
                );

                const totalMinutes = reduce(
                    userTreatments,
                    (sum, item) => sum + getMinutesOfTreatment(item, userId),
                    0
                );
                const totalServices = reduce(
                    userTreatments,
                    (sum, item) => sum + item?.services?.length,
                    0
                );
                const totalNurseFee = reduce(
                    userTreatments,
                    (sum, item) => sum + getNurseFeeOfTreatment(item, userId),
                    0
                );
                exportData.push({
                    "Report Date": dateRangeText,
                    "Name of nurse": getFullName(user),
                    "EmployeeID of nurse": user?.companyId,
                    "Type of money": "",
                    Date: "",
                    Branch: "",
                    "Treatment no.": "",
                    Type: "",
                    CN: "",
                    "Customer name": "",
                    Treatment: "",
                    "Minutes of treatment": totalMinutes,
                    "Quantity of treatment": totalServices,
                    "Sale price": "",
                    "Quantity For Fee Calc.": "",
                    "Calculated from the total": "",
                    "Nurse fee": totalNurseFee,
                });

                for (
                    let treatmentIndex = 0;
                    treatmentIndex < userTreatments.length;
                    treatmentIndex += 1
                ) {
                    const treatment: ITreatment =
                        userTreatments[treatmentIndex];
                    const {
                        day,
                        branch,
                        treatmentNo,
                        services,
                        customer,
                        PIC,
                        doctorOrder,
                    } = treatment;

                    const serviceTypes = uniq(
                        map(
                            services,
                            (item) => item?.productFull?.category?.name
                        )
                    );

                    const serviceTypeText = join(serviceTypes, ", ");
                    const serviceNameText = join(
                        map(services, (item) => item.productFull?.name),
                        ", "
                    );
                    const serviceSalePriceText = join(
                        map(services, (service) => {
                            const orderProduct = find(
                                doctorOrder?.order?.products,
                                (product: any) =>
                                    product?.product?.id ===
                                    service?.productFull?.id
                            );
                            return StringUtils.moneyThaiFormat(
                                orderProduct?.salePrice ?? 0
                            );
                        }),
                        ", "
                    );
                    const serviceQuantityText = join(
                        map(services, (service) => {
                            const orderProduct = find(
                                doctorOrder?.order?.products,
                                (product: any) =>
                                    product?.product?.id ===
                                    service?.productFull?.id
                            );
                            return `${orderProduct?.quantity ?? 0}`;
                        }),
                        ", "
                    );

                    const serviceCommissionText = join(
                        map(services, (service) => {
                            const orderProduct = find(
                                doctorOrder?.order?.products,
                                (product: any) =>
                                    product?.product?.id ===
                                    service?.productFull?.id
                            );
                            const productPrice =
                                (orderProduct?.quantity ?? 0) *
                                (orderProduct?.salePrice ?? 0);

                            const userCommissionServices =
                                user?.adminUser?.commissionService ?? [];

                            const userCommissionService = find(
                                userCommissionServices,
                                (item) =>
                                    item?.service?.id ===
                                    service?.productFull?.service?.id
                            );
                            if (
                                user?.adminUser?.commissionServiceType ===
                                    ServiceCommissionType.DEFAULT ||
                                !userCommissionService
                            ) {
                                return (
                                    (productPrice *
                                        (service?.productFull?.service
                                            ?.defaultSaleCommission ?? 0)) /
                                    100
                                );
                            }
                            return `${
                                (productPrice *
                                    (userCommissionService?.value ?? 0)) /
                                100
                            }`;
                        }),
                        ", "
                    );

                    console.log("serviceSalePriceText", serviceSalePriceText);

                    const isUserInPic = !!find(
                        PIC,
                        (pic) => pic.adminUser?.id === user?.id
                    );
                    const typeOfMoney = isUserInPic ? "Fee" : "Commission";

                    exportData.push({
                        "Report Date": "",
                        "Name of nurse": "",
                        "EmployeeID of nurse": "",
                        "Type of money": typeOfMoney,
                        Date: day,
                        Branch: branch?.name,
                        "Treatment no.": treatmentNo,
                        Type: serviceTypeText,
                        CN: customer?.customerNo,
                        "Customer name": customer?.fullNameEn,
                        Treatment: serviceNameText,
                        "Minutes of treatment": getMinutesOfTreatment(
                            treatment,
                            userId
                        ),
                        "Quantity of treatment": services?.length,
                        "Sale price": serviceSalePriceText,
                        "Quantity For Fee Calc.": serviceQuantityText,
                        "Calculated from the total": serviceCommissionText,
                        "Nurse fee": getNurseFeeOfTreatment(treatment, userId),
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Summary Nurse Report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <UserSelect
                    params={{ jobType: JobTypeAdmin.Nurse }}
                    className={classNameInput}
                    value={formValues?.user}
                    onChange={(value) =>
                        exportForm.setFieldValue("user", value)
                    }
                    multiple
                    label={Messages.nurse}
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: "Report Date",
            dataIndex: "Report Date",
        },
        {
            title: "Name of nurse",
            dataIndex: "Name of nurse",
        },
        {
            title: "EmployeeID of nurse",
            dataIndex: "EmployeeID of nurse",
        },
        {
            title: "Type of money",
            dataIndex: "Type of money",
        },
        {
            title: "Date",
            dataIndex: "Date",
        },
        {
            title: "Branch",
            dataIndex: "Branch",
        },
        {
            title: "Treatment no.",
            dataIndex: "Treatment no.",
        },
        {
            title: "Type",
            dataIndex: "Type",
            width: 200,
        },
        {
            title: "CN",
            dataIndex: "CN",
        },
        {
            title: "Customer name",
            dataIndex: "Customer name",
        },
        {
            title: "Treatment",
            dataIndex: "Treatment",
            width: 300,
        },
        {
            title: "Minutes of treatment",
            dataIndex: "Minutes of treatment",
            width: 200,
        },
        {
            title: "Quantity of treatment",
            dataIndex: "Quantity of treatment",
            width: 200,
        },
        {
            title: "Sale price",
            dataIndex: "Sale price",
            width: 200,
        },
        {
            title: "Quantity For Fee Calc.",
            dataIndex: "Quantity For Fee Calc.",
            width: 200,
        },
        {
            title: "Calculated from the total",
            dataIndex: "Calculated from the total",
            width: 200,
        },
        {
            title: "Nurse fee",
            dataIndex: "Nurse fee",
            width: 200,
            render: StringUtils.moneyThaiFormat,
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                baseColumnProps={{ width: 100 }}
                columns={columns}
                dataSource={dataTable}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.nurseDetailReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={!formValues?.timeRange?.length}
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportNurseDetail;
