import { FormikProps } from "formik";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { SIMPLE_CHOICES } from "../../../constant/customer";
import Messages from "../../../languages/Messages";

interface ICustomerSettingForm {
    form: FormikProps<any>;
}

const CustomerSettingForm = ({ form: customerForm }: ICustomerSettingForm) => {
    const { values, setFieldValue } = customerForm || {};

    const inputClassName = "col-12 mt-3";

    return (
        <div className="row p-3 w-full">
            <InputSelectForm
                label={Messages.vipGroup}
                keyData="isVip"
                form={customerForm}
                className={inputClassName}
                dataSource={SIMPLE_CHOICES}
            />
        </div>
    );
};

export default CustomerSettingForm;
