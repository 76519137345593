import {
    AwesomeTableComponent,
    Button,
    InputTextSearch,
    useFirstTime,
} from "d-react-components";
import {
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    join,
    map,
    toLower,
} from "lodash";
import { useEffect, useRef, useState } from "react";
import CustomerAPI from "../../../api/queries/customer";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";
import { getVoucherTableColumns } from "../../../common/vouchers/VoucherTableColumns";
import { VoucherStatus } from "../../../api/hooks";

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

interface IVoucherFromOrderSelectDrawer {
    open: boolean;
    onClose: any;
    onSave: any;
    defaultValue: any[];
    customerId: string;
}

const VoucherFromOrderSelectDrawer = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    customerId,
}: IVoucherFromOrderSelectDrawer) => {
    const [orderSelected, setOrderSelected] = useState<any[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();
    const columns = getVoucherTableColumns({});

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const source = () => {
        if (filterTab === "selected") {
            return Promise.resolve({
                data: { data: { data: orderSelected } },
            });
        }
        if (!customerId) {
            return Promise.resolve();
        }
        return CustomerAPI.serviceHistory({
            customerId,
            status: VoucherStatus.Available,
        });
    };

    const transformer = (res: any) => {
        const sourceFiltered = filter(
            res?.data?.data?.data ?? [],
            (item) => item.vouchers?.length
        );
        const sourceData = map(sourceFiltered, (item) => ({
            ...item,
            id: item?.order?.id + item?.product?.id,
        }));

        if (isEmpty(searchRef.current)) {
            return sourceData;
        }

        const dataFiltered = filter(sourceData, (data) => {
            const { order, vouchers, product } = data;
            const voucherCodes = join(
                map(vouchers, (item) => item.code),
                ""
            );
            const voucherNo = join(
                map(vouchers, (item) => item.voucherNo),
                ""
            );

            const textSearch = toLower(
                order?.orderNo +
                    voucherCodes +
                    product?.product?.name +
                    voucherNo
            );

            return includes(textSearch, toLower(searchRef.current));
        });

        return dataFiltered;
    };

    const onAddVoucher = () => {
        onSave && onSave(orderSelected);
        onClose && onClose();
    };

    const onChangeSelectOrder = (keys: string[], orders: any[]) => {
        const result = map(orders, (order) => {
            const itemSelected = find(
                orderSelected,
                (item) => item.id === order.id
            );

            if (itemSelected) return itemSelected;
            return order;
        });
        setOrderSelected(result as any);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.selectVoucher}
            onSave={onAddVoucher}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item, index) => (
                        <Button
                            key={`${item?.id}_${index}`}
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={transformer}
                    rowSelection={{
                        selectedRowKeys: map(orderSelected, (item) => item.id),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectOrder as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default VoucherFromOrderSelectDrawer;
