import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath } from "react-router-dom";
import VoucherAPI from "../../../api/queries/voucher";
import AppLink from "../../../common/AppLink";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import Drawer from "../../../common/Drawer";
import { VOUCHER_STATUSES } from "../../../constant/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { VoucherStatus } from "../../../api/hooks";
import { IOrder } from "../../../interfaces/order";

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

const VoucherSelectDrawer = ({
    open,
    onClose,
    onSave,
    defaultValue = [],
    params = {},
}: any) => {
    const [voucherSelected, setVoucherSelected] = useState<any[]>(defaultValue);
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.code,
            dataIndex: "code",
            render: (code, voucher) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })}
                >
                    {code}
                </AppLink>
            ),
        },
        {
            title: Messages.orderNo,
            dataIndex: "order",
            render: (order: IOrder) => {
                if (!order?.id) return "N/A";
                return (
                    <AppLink
                        to={generatePath(Path.ORDER_DETAIL, {
                            orderId: order?.id ?? "123",
                        })}
                    >
                        {order?.orderNo ?? "N/A"}
                    </AppLink>
                );
            },
        },
        {
            title: Messages.serviceName,
            dataIndex: "services",
            render: (services) => {
                if (!services?.length) return "N/A";
                return (
                    <div className="">
                        {map(services, (service) => (
                            <div>
                                <AppLink
                                    key={service.id}
                                    to={generatePath(Path.PRODUCT_DETAIL, {
                                        productId: service?.id,
                                    })}
                                >
                                    {service?.name}
                                </AppLink>{" "}
                                ,
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            title: Messages.amount,
            dataIndex: "value",
            render: (value) => {
                return (
                    <CurrencyFormat
                        value={value ?? ""}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },
        {
            title: Messages.price,
            dataIndex: "salePrice",
            render: (salePrice) => {
                return (
                    <CurrencyFormat
                        value={salePrice ?? ""}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },
        {
            title: Messages.balance,
            dataIndex: "balance",
            render: (value, item) => {
                const balance = (value || 0) - (item?.totalUsedValues || 0);
                return (
                    <CurrencyFormat
                        value={value ?? ""}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },
        {
            title: "Sale Date",
            dataIndex: "createdAt",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.expiryDate,
            dataIndex: "expiryDate",
            render: (data) => {
                return data ? TimeUtils.toDate(data) : "N/A";
            },
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer) => (
                <CustomerAvatarName customer={customer} size="xx-small" />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    listStatus={VOUCHER_STATUSES}
                    status={status}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
    ];

    const source = (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: voucherSelected });
        }
        return VoucherAPI.listForSelect({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            status: [VoucherStatus.Expiring, VoucherStatus.Available],
            ...params,
            sort: {
                createdAt: -1,
            },
        });
    };

    const onAddVoucher = () => {
        onSave && onSave(voucherSelected);
        onClose && onClose();
    };

    const onChangeSelectVoucher = (keys: string[], vouchers: any[]) => {
        const result = map(vouchers, (voucher) => {
            const { services } = voucher || {};
            const itemSelected = find(
                voucherSelected,
                (item) => item.id === voucher.id
            );

            if (itemSelected) return itemSelected;
            return voucher;
        });
        setVoucherSelected(result as any);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.selectVoucher}
            onSave={onAddVoucher}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item, index) => (
                        <Button
                            key={`${item?.id}_${index}`}
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => {
                        return res?.data || [];
                    }}
                    rowSelection={{
                        selectedRowKeys: map(
                            voucherSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectVoucher as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default VoucherSelectDrawer;
