import { gql } from "@apollo/client";
import { F_BILLING, F_COUNTRY } from "./address";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";
import { F_VENDOR, F_VENDOR_BASIC } from "./vendor";
import { F_ENUM } from "./common";

export const F_CATEGORY_MATERIAL_BASIC = gql`
    fragment F_CATEGORY_MATERIAL_BASIC on AdminCategoryMaterialBasic {
        id
        name
        status
        categoryNo
    }
`;

export const F_CATEGORY_MATERIAL = gql`
    fragment F_CATEGORY_MATERIAL on AdminCategoryMaterial {
        id
        name
        status
        description
        categoryNo
        createdAt
        updatedAt
    }
`;

export const F_CATEGORY_MATERIAL_SEARCH = gql`
    fragment F_CATEGORY_MATERIAL_SEARCH on AdminSearchCategoryMaterial {
        id
        name
        status
        categoryNo
    }
`;

export const F_CATEGORY_MATERIALS = gql`
    ${F_CATEGORY_MATERIAL}
    ${F_PAGINATE}
    fragment F_CATEGORY_MATERIALS on ListAdminCategoryMaterialResponse {
        data {
            ...F_CATEGORY_MATERIAL
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_MATERIAL_SEARCH = gql`
    ${F_ENUM}
    fragment F_MATERIAL_SEARCH on AdminSearchMaterial {
        id
        name
        materialNo
        sku
        status
        purchaseUnit {
            ...F_ENUM
        }
        trackingUnit {
            ...F_ENUM
        }
        purchaseValue
        trackingValue
        avgPurchasingUnitCost
        avgTrackingUnitCost
        lastPurchasingUnitCost
        lastTrackingUnitCost
        gallery
    }
`;

export const F_MATERIAL_BASIC = gql`
    ${F_ENUM}
    fragment F_MATERIAL_BASIC on AdminMaterialBasic {
        id
        name
        sku
        status
        materialNo
        purchaseUnit {
            ...F_ENUM
        }
        trackingUnit {
            ...F_ENUM
        }
        purchaseValue
        trackingValue
        avgPurchasingUnitCost
        avgTrackingUnitCost
        lastPurchasingUnitCost
        lastTrackingUnitCost
        gallery
    }
`;

export const F_MATERIAL = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_VENDOR_BASIC}
    ${F_CATEGORY_MATERIAL_BASIC}
    ${F_ENUM}
    fragment F_MATERIAL on AdminMaterial {
        id
        name
        sku
        status
        description
        materialNo
        purchaseUnit {
            ...F_ENUM
        }
        trackingUnit {
            ...F_ENUM
        }
        purchaseValue
        trackingValue
        lowStockQuantity
        gallery
        createdAt
        updatedAt
        avgPurchasingUnitCost
        avgTrackingUnitCost
        lastPurchasingUnitCost
        lastTrackingUnitCost
        category {
            ...F_CATEGORY_MATERIAL_BASIC
        }
        vendor {
            ...F_VENDOR_BASIC
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        totalQuantityOnPurchase
    }
`;

export const F_MATERIALS = gql`
    ${F_MATERIAL}
    ${F_PAGINATE}
    fragment F_MATERIALS on ListAdminMaterialResponse {
        data {
            ...F_MATERIAL
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_MATERIAL_REQUEST_SEARCH = gql`
    fragment F_MATERIAL_REQUEST_SEARCH on AdminSearchMaterialPurchaseRequest {
        id
        materialPurchaseRequestNo
        status
    }
`;

export const F_MATERIAL_PURCHASE_REQUEST_ITEM = gql`
    ${F_MATERIAL_BASIC}
    fragment F_MATERIAL_PURCHASE_REQUEST_ITEM on AdminMaterialPurchaseRequestItem {
        quantity
        material {
            ...F_MATERIAL_BASIC
            gallery
        }
    }
`;

export const F_MATERIAL_PURCHASE_SEARCH = gql`
    fragment F_MATERIAL_PURCHASE_SEARCH on AdminSearchMaterialPurchase {
        id
        materialPurchaseNo
        status
        createdAt
        updatedAt
    }
`;

export const F_MATERIAL_PURCHASE_REQUEST = gql`
    ${F_MATERIAL_PURCHASE_REQUEST_ITEM}
    ${F_ADMIN_USER_BASIC}
    fragment F_MATERIAL_PURCHASE_REQUEST on AdminMaterialPurchaseRequest {
        id
        materialPurchaseRequestNo
        status
        remark
        attachments
        expectedDateOfArrival
        hasMaterialPurchase
        hasQuotationApproved
        createdAt
        updatedAt
        branch {
            id
            name
        }
        confirm {
            at
            remark
            by {
                ...F_ADMIN_USER_BASIC
            }
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        materials {
            ...F_MATERIAL_PURCHASE_REQUEST_ITEM
        }
    }
`;

export const F_MATERIAL_PURCHASE_QUOTATION = gql`
    fragment F_MATERIAL_PURCHASE_QUOTATION on AdminMaterialPurchaseQuotation {
        id
        name
        status
        materialPurchaseQuotationNo
        taxId
        address
        phone
        totalPrice
        file
        remark
        createdAt
        updatedAt
    }
`;

export const F_MATERIAL_ORDER_SEARCH = gql`
    fragment F_MATERIAL_ORDER_SEARCH on AdminSearchMaterialPurchase {
        id
        materialPurchaseNo
        status
        materialPurchaseOrderStatus
        total
        subtotal
        createdAt
        updatedAt
    }
`;

export const F_MATERIAL_PURCHASE_BILLING = gql`
    ${F_COUNTRY}
    fragment F_MATERIAL_PURCHASE_BILLING on AdminMaterialPurchaseBilling {
        country {
            ...F_COUNTRY
        }
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        typeOfCustomer
        firstName
        lastName
        citizenId
        taxId
        companyName
        companyPhone
        email
        phone
        address
    }
`;

export const F_MATERIAL_ORDER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_MATERIAL_BASIC}
    ${F_MATERIAL_PURCHASE_BILLING}
    fragment F_MATERIAL_ORDER on AdminMaterialPurchase {
        id
        materialPurchaseNo
        status
        materialPurchaseOrderStatus
        total
        subtotal
        createdAt
        updatedAt
        vendor {
            id
            name
            vendorNo
        }
        materials {
            price
            remark
            quantity
            material {
                ...F_MATERIAL_BASIC
            }
        }
        materialPurchaseRequest {
            id
            materialPurchaseRequestNo
            status
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_MATERIAL_ORDER_DETAIL = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_MATERIAL_BASIC}
    ${F_MATERIAL_PURCHASE_BILLING}
    fragment F_MATERIAL_ORDER_DETAIL on AdminMaterialPurchase {
        id
        materialPurchaseNo
        status
        materialPurchaseOrderStatus
        total
        subtotal
        createdAt
        updatedAt
        remark
        isSameShippingAddress
        discount {
            discountType
            discountValue
            total
            material {
                ...F_MATERIAL_BASIC
            }
        }
        vendor {
            id
            name
            vendorNo
            picture
        }
        materials {
            price
            remark
            quantity
            material {
                ...F_MATERIAL_BASIC
            }
        }
        materialPurchaseRequest {
            id
            materialPurchaseRequestNo
            status
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        billing {
            ...F_MATERIAL_PURCHASE_BILLING
        }
        confirm {
            remark
            at
            attachment
            by {
                ...F_ADMIN_USER_BASIC
            }
        }
        contact {
            firstName
            lastName
            phone
            email
        }
    }
`;

export const F_MATERIAL_PAYMENT_PROOF = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_MATERIAL_PAYMENT_PROOF on AdminMaterialPurchasePaymentProof {
        remark
        attachments
        at
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_MATERIAL_PAYMENT_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_MATERIAL_PAYMENT_CONFIRMATION on AdminMaterialPurchasePaymentConfirmation {
        at
        attachments
        remark
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_MATERIAL_PURCHASE_PAYMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_MATERIAL_PAYMENT_CONFIRMATION}
    ${F_MATERIAL_PAYMENT_PROOF}
    fragment F_MATERIAL_PURCHASE_PAYMENT on AdminMaterialPurchasePayment {
        id
        paymentNo
        total
        status
        paymentMethod
        refId
        createdAt
        updatedAt
        creditNote
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        proof {
            ...F_MATERIAL_PAYMENT_PROOF
        }
        confirmation {
            ...F_MATERIAL_PAYMENT_CONFIRMATION
        }
    }
`;

export const F_MATERIAL_PURCHASE_SHIPPING = gql`
    ${F_COUNTRY}
    ${F_ADMIN_USER_BASIC}
    fragment F_MATERIAL_PURCHASE_SHIPPING on AdminMaterialPurchaseShipping {
        id
        firstName
        lastName
        nickName
        gender
        phone
        address
        latitude
        longitude
        typeOfPlace
        nameOfPlace
        remark
        country {
            ...F_COUNTRY
        }
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        createdAt
        updatedAt
    }
`;

export const F_MATERIAL_PURCHASE_DELIVERY = gql`
    ${F_BRANCH_BASIC}
    ${F_MATERIAL_PURCHASE_SHIPPING}
    ${F_MATERIAL_BASIC}
    ${F_ADMIN_USER_BASIC}
    fragment F_MATERIAL_PURCHASE_DELIVERY on AdminMaterialPurchaseDelivery {
        id
        deliveryNo
        status
        receiptNo
        deliveryTime
        remark
        attachment
        createdAt
        updatedAt
        lotNo
        destination {
            ...F_BRANCH_BASIC
        }
        materialPurchaseShipping {
            ...F_MATERIAL_PURCHASE_SHIPPING
        }
        materials {
            quantity
            material {
                ...F_MATERIAL_BASIC
            }
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        received {
            ...F_ADMIN_USER_BASIC
        }
    }
`;

export const F_MATERIAL_PURCHASE_DELIVERY_MATERIAL = gql`
    ${F_MATERIAL_BASIC}
    fragment F_MATERIAL_PURCHASE_DELIVERY_MATERIAL on AdminMaterialPurchaseDeliveryMaterial {
        quantity
        material {
            ...F_MATERIAL_BASIC
        }
    }
`;
