import { DateSchedule, DeliveryStatus } from "../api/hooks";
import {
    JobType,
    OrderStatusManagement,
    SpecialAccessType,
} from "../interfaces/user";

export const PERMISSION_TYPE = {
    ALL: "ALL",
    CUSTOM: "CUSTOM",
};

export const PERMISSION_TYPES = [
    {
        id: PERMISSION_TYPE.ALL,
        label: "All",
    },
    {
        id: PERMISSION_TYPE.CUSTOM,
        label: "Custom",
    },
];

export const ROLE_ACCESS_TYPE = {
    LIST: "list",
    EXPORT: "export",
    DETAIL: "detail",
    CREATE: "create",
    EDIT: "edit",
    DELETE: "delete",
};

export const ROLE_ACCESS_TYPES = [
    // { id: "list", label: "list" },
    // { id: "export", label: "export" },
    { id: "list", label: "list", key: "LIST" },
    { id: "detail", label: "detail", key: "DETAIL" },
    { id: "create", label: "create", key: "CREATE" },
    { id: "update", label: "update", key: "UPDATE" },
];

export const ORDER_ACCESS_TYPE = {
    ...ROLE_ACCESS_TYPE,
    RESET_PAYMENT: "resetPayment",
    CANCEL_ORDER: "cancelOrder",
};

export const ORDER_ACCESS_TYPES = [
    ...ROLE_ACCESS_TYPES,
    { id: "resetPayment", label: "resetPayment" },
    { id: "cancelOrder", label: "cancelOrder" },
];

export const getCommonAccessTypes = (parentPermissionKey: string) => {
    return ROLE_ACCESS_TYPES.map((type) => ({
        ...type,
        key: `${parentPermissionKey}.${type.key}`,
    }));
};

export const PERMISSION = {
    COMMON: "COMMON",

    PRODUCT: {
        CREATE: "PRODUCT.CREATE",
        UPDATE: "PRODUCT.UPDATE",
        LIST: "PRODUCT.LIST",
        DETAIL: "PRODUCT.DETAIL",
        SEARCH: "PRODUCT.SEARCH",
    },
    ADMIN: {
        ROLE: {
            ALL: "ROLE.ALL",
            LIST: "ROLE.LIST",
            DETAIL: "ROLE.DETAIL",
            CREATE: "ROLE.CREATE",
            UPDATE: "ROLE.UPDATE",
        },
        USER: {
            ALL: "USER.ALL",
            LIST: "USER.LIST",
            DETAIL: "USER.DETAIL",
            CREATE: "USER.CREATE",
            UPDATE: "USER.UPDATE",
            UPDATE_SPECIAL_ACCESS: "UPDATE_SPECIAL_ACCESS",
            SEARCH: "USER.SEARCH",
        },
    },
    CUSTOMER: {
        LIST: "CUSTOMER.LIST",
        SEARCH: "CUSTOMER.SEARCH",
        DETAIL: "CUSTOMER.DETAIL",
        QUICK_VIEW: "CUSTOMER.QUICK_VIEW",
        CREATE: "CUSTOMER.CREATE",
        UPDATE_PERSONAL: "CUSTOMER.UPDATE_PERSONAL",
        UPDATE_PRIVACY: "CUSTOMER.UPDATE_PRIVACY",
        UPDATE_CONTACT: "CUSTOMER.UPDATE_CONTACT",
        UPDATE_FAMILY_EMERGENCY: "CUSTOMER.UPDATE_FAMILY_EMERGENCY",
        UPDATE_MEDICAL: "CUSTOMER.UPDATE_MEDICAL",
        UPDATE_ADDRESS: "CUSTOMER.UPDATE_ADDRESS",
        UPDATE_BILLING: "CUSTOMER.UPDATE_BILLING",
        CREATE_ADDRESS: "CUSTOMER.CREATE_ADDRESS",
        CREATE_BILLING: "CUSTOMER.CREATE_BILLING",
        REMOVE_ADDRESS: "CUSTOMER.REMOVE_ADDRESS",
        REMOVE_BILLING: "CUSTOMER.REMOVE_BILLING",
        ADDRESS_DETAIL: "CUSTOMER.ADDRESS_DETAIL",
        ADDRESS_ALL: "CUSTOMER.ADDRESS_ALL",
        BILLING_DETAIL: "CUSTOMER.BILLING_DETAIL",
        BILLING_ALL: "CUSTOMER.BILLING_ALL",
        CREATE_NOTE: "CUSTOMER.CREATE_NOTE",
        UPDATE_NOTE: "CUSTOMER.UPDATE_NOTE",
        REMOVE_NOTE: "CUSTOMER.REMOVE_NOTE",
        LIST_NOTE: "CUSTOMER.LIST_NOTE",
        UPDATE_TAG: "CUSTOMER.UPDATE_TAG",
        UPDATE_SURVEY: "CUSTOMER.UPDATE_SURVEY",
        UPDATE_SETTING: "CUSTOMER.UPDATE_SETTING",
        VERIFY_CUSTOMER_INFO: "CUSTOMER.VERIFY_CUSTOMER_INFO",
        APPROVE_UPDATE_INFO: "CUSTOMER.APPROVE_UPDATE_INFO",
        //HISTORY
        BOOKING_HISTORY: "CUSTOMER.DETAIL.BOOKING_HISTORY",
        DOCTOR_ORDER_HISTORY: "CUSTOMER.DETAIL.DOCTOR_ORDER_HISTORY",
        ORDER_HISTORY: "CUSTOMER.DETAIL.ORDER_HISTORY",
        QUOTATION_HISTORY: "CUSTOMER.DETAIL.QUOTATION_HISTORY",
        TREATMENT_HISTORY: "CUSTOMER.DETAIL.TREATMENT_HISTORY",
        VOUCHERS: "CUSTOMER.DETAIL.VOUCHERS",
        //SECRET_CODE
        GENERATE_SELF_REGISTRATION_CODE:
            "CUSTOMER.GENERATE_SELF_REGISTRATION_CODE",
        GENERATE_UPDATE_INFO_CODE: "CUSTOMER.GENERATE_UPDATE_INFO_CODE",
        GENERATE_HEALTH_ASSESSMENT_CODE: "CUSTOMER.GENERATE_UPDATE_INFO_CODE",
        //OTP
        REQUEST_OTP: "CUSTOMER.REQUEST_OTP",
        VERIFY_OTP: "CUSTOMER.VERIFY_OTP",
    },
    CATEGORY_SERVICE: {
        LIST: "CATEGORY_SERVICE.LIST",
        CREATE: "CATEGORY_SERVICE.CREATE",
        DETAIL: "CATEGORY_SERVICE.DETAIL",
        UPDATE: "CATEGORY_SERVICE.UPDATE",
        SEARCH: "CATEGORY_SERVICE.SEARCH",
    },
    CATEGORY_MATERIAL: {
        LIST: "CATEGORY_MATERIAL.LIST",
        CREATE: "CATEGORY_MATERIAL.CREATE",
        DETAIL: "CATEGORY_MATERIAL.DETAIL",
        UPDATE: "CATEGORY_MATERIAL.UPDATE",
        SEARCH: "CATEGORY_MATERIAL.SEARCH",
    },
    CATEGORY_PRODUCT: {
        LIST: "CATEGORY_PRODUCT.LIST",
        CREATE: "CATEGORY_PRODUCT.CREATE",
        DETAIL: "CATEGORY_PRODUCT.DETAIL",
        UPDATE: "CATEGORY_PRODUCT.UPDATE",
    },
    CATEGORY_EQUIPMENT: {
        LIST: "CATEGORY_EQUIPMENT.LIST",
        CREATE: "CATEGORY_EQUIPMENT.CREATE",
        DETAIL: "CATEGORY_EQUIPMENT.DETAIL",
        UPDATE: "CATEGORY_EQUIPMENT.UPDATE",
    },

    EQUIPMENT: {
        LIST: "EQUIPMENT.LIST",
        CREATE: "EQUIPMENT.CREATE",
        DETAIL: "EQUIPMENT.DETAIL",
        UPDATE: "EQUIPMENT.UPDATE",
    },

    DRIVER_DELIVERY: {
        LIST: "DRIVER_DELIVERY.LIST",
        DETAIL: "DRIVER_DELIVERY.DETAIL",
        CONFIRM: "DRIVER_DELIVERY.CONFIRM",
    },

    VEHICLE: {
        LIST: "VEHICLE.LIST",
        CREATE: "VEHICLE.CREATE",
        DETAIL: "VEHICLE.DETAIL",
        UPDATE: "VEHICLE.UPDATE",
    },

    EQUIPMENT_INVENTORY: {
        LIST: "EQUIPMENT_INVENTORY.LIST",
        HISTORY: "EQUIPMENT_INVENTORY.HISTORY",
    },

    EQUIPMENT_REQUEST: {
        LIST: "EQUIPMENT_REQUEST.LIST",
        CREATE: "EQUIPMENT_REQUEST.CREATE",
        UPDATE: "EQUIPMENT_REQUEST.UPDATE",
        DETAIL: "EQUIPMENT_REQUEST.DETAIL",
        CONFIRM: "EQUIPMENT_REQUEST.CONFIRM",
    },

    EQUIPMENT_TRANSFER: {
        LIST: "EQUIPMENT_TRANSFER.LIST",
        CREATE: "EQUIPMENT_TRANSFER.CREATE",
        DETAIL: "EQUIPMENT_TRANSFER.DETAIL",
        CONFIRM: "EQUIPMENT_TRANSFER.CONFIRM",
        CANCEL: "EQUIPMENT_TRANSFER.CANCEL",
        DETAIL_DELIVERY: "EQUIPMENT_TRANSFER.DETAIL_DELIVERY",
        CREATE_DELIVERY: "EQUIPMENT_TRANSFER.CREATE_DELIVERY",
        UPDATE_DELIVERY: "EQUIPMENT_TRANSFER.UPDATE_DELIVERY",
        CONFIRM_DELIVERY: "EQUIPMENT_TRANSFER.CONFIRM_DELIVERY",
        RECEIVE_DELIVERY: "EQUIPMENT_TRANSFER.RECEIVE_DELIVERY",
    },

    EQUIPMENT_STOCK_ADJUSTMENT: {
        LIST: "EQUIPMENT_STOCK_ADJUSTMENT.LIST",
        CREATE: "EQUIPMENT_STOCK_ADJUSTMENT.CREATE",
        DETAIL: "EQUIPMENT_STOCK_ADJUSTMENT.DETAIL",
        CONFIRM: "EQUIPMENT_STOCK_ADJUSTMENT.CONFIRM",
        REJECT: "EQUIPMENT_STOCK_ADJUSTMENT.REJECT",
    },

    TEMPORARY_SCHEDULE: {
        LIST: "TEMPORARY_SCHEDULE.LIST",
        CREATE: "TEMPORARY_SCHEDULE.CREATE",
        DETAIL: "TEMPORARY_SCHEDULE.DETAIL",
        UPDATE: "TEMPORARY_SCHEDULE.UPDATE",
        REMOVE: "TEMPORARY_SCHEDULE.REMOVE",
    },

    SERVICE_CONFIGURATION: {
        SEARCH: "SERVICE_CONFIGURATION.SEARCH",
        LIST: "SERVICE_CONFIGURATION.LIST",
        DETAIL: "SERVICE_CONFIGURATION.DETAIL",
        CREATE: "SERVICE_CONFIGURATION.CREATE",
        UPDATE: "SERVICE_CONFIGURATION.UPDATE",
        UPDATE_JOB_COST: "SERVICE_CONFIGURATION.UPDATE.JOB_COST",
        MATERIAL_PRESET: "SERVICE_CONFIGURATION.UPDATE.MATERIAL_PRESET",
        MEDICINE_PRESET: "SERVICE_CONFIGURATION.UPDATE.MEDICINE_PRESET",
        SERVICE_PRESET: "SERVICE_CONFIGURATION.UPDATE.SERVICE_PRESET",
    },

    BRANCH: {
        LIST: "BRANCH.LIST",
        DETAIL: "BRANCH.DETAIL",
        CREATE: "BRANCH.CREATE",
        UPDATE: "BRANCH.UPDATE",
        UPDATE_ADDRESS: "BRANCH.UPDATE_ADDRESS",
        UPDATE_WAREHOUSE: "BRANCH.UPDATE_WAREHOUSE",
        UPDATE_PIC: "BRANCH.UPDATE_PIC",
    },

    MEDIA: {
        UPLOAD: {
            GET_URL: "MEDIA.UPLOAD.GET_URL",
        },
        FILE: {
            ALL: "MEDIA.FILE.ALL",
            LIST: "MEDIA.FILE.LIST",
            DETAIL: "MEDIA.FILE.DETAIL",
            CREATE: "MEDIA.FILE.CREATE",
            UPDATE: "MEDIA.FILE.UPDATE",
            MOVE: "MEDIA.FILE.MOVE",
            DELETE: "MEDIA.FILE.DELETE",
        },
        FOLDER: {
            ALL: "MEDIA.FOLDER.ALL",
            CREATE: "MEDIA.FOLDER.CREATE",
            UPDATE: "MEDIA.FOLDER.UPDATE",
            MOVE: "MEDIA.FOLDER.MOVE",
            DELETE: "MEDIA.FOLDER.DELETE",
        },
    },

    CART_RULE: {
        LIST: "CART_RULE.LIST",
        DETAIL: "CART_RULE.DETAIL",
        CREATE: "CART_RULE.CREATE",
        UPDATE: "CART_RULE.UPDATE",
    },

    VOUCHER: {
        LIST: "VOUCHER.LIST",
        DETAIL: "VOUCHER.DETAIL",
        CREATE: "VOUCHER.CREATE",
        UPDATE: "VOUCHER.UPDATE",
        CANCEL: "VOUCHER.CANCEL",
    },

    QUOTATION: {
        LIST: "QUOTATION.LIST",
        DETAIL: "QUOTATION.DETAIL",
        CREATE: "QUOTATION.CREATE",
        UPDATE: "QUOTATION.UPDATE",
        UPDATE_EXPIRED: "QUOTATION.UPDATE_EXPIRED",
    },

    ORDER: {
        LIST: "ORDER.LIST",
        DETAIL: "ORDER.DETAIL",
        CREATE: "ORDER.CREATE",
        UPDATE: "ORDER.UPDATE",
        UPDATE_BILLING: "ORDER.UPDATE_BILLING",
        UPDATE_QUOTATION: "ORDER.UPDATE_QUOTATION",
        UPDATE_EXPIRED: "ORDER.UPDATE_EXPIRED",
        CHECK_DISCOUNT: "ORDER.CHECK_DISCOUNT",
        SUMMARY: "ORDER.SUMMARY",
        CHECK_DOCTOR_ORDER: "ORDER.CHECK_DOCTOR_ORDER",
        MAP_MANUAL_PRODUCT: "ORDER.MAP_MANUAL_PRODUCT",
        CHECK_VOUCHER_CUSTOMER: "ORDER.CHECK_VOUCHER_CUSTOMER",
        CANCEL_ORDER: "ORDER.CANCEL_ORDER",
        VOUCHER_LIST: "ORDER.VOUCHER_LIST",
    },

    ORDER_DELIVERY: {
        LIST: "ORDER_DELIVERY.LIST",
        DETAIL: "ORDER_DELIVERY.DETAIL",
        CREATE: "ORDER_DELIVERY.CREATE",
        UPDATE: "ORDER_DELIVERY.UPDATE",
        ALL: "ORDER_DELIVERY.ALL",
        PRODUCT: "ORDER_DELIVERY.PRODUCT",
        UPDATE_STATUS: "ORDER_DELIVERY.UPDATE_STATUS",
    },

    APPOINTMENT: {
        LIST: "BOOKING.LIST",
        DETAIL: "BOOKING.DETAIL",
    },

    BOOKING: {
        LIST: "BOOKING.LIST",
        DETAIL: "BOOKING.DETAIL",
        CREATE: "BOOKING.CREATE",
        UPDATE: "BOOKING.UPDATE",
        UPDATE_DOCTOR: "BOOKING.UPDATE_DOCTOR",
        UPDATE_PIC: "BOOKING.UPDATE_PIC",
        UPDATE_SERVICE: "BOOKING.UPDATE_SERVICE",
        CONFIRM: "BOOKING.CONFIRM",
        CANCEL: "BOOKING.CANCEL",
        CREATE_NOTE: "BOOKING.CREATE_NOTE",
        UPDATE_NOTE: "BOOKING.UPDATE_NOTE",
        REMOVE_NOTE: "BOOKING.REMOVE_NOTE",
        LIST_NOTE: "BOOKING.LIST_NOTE",
    },

    DOCTOR_ORDER: {
        LIST: "DOCTOR_ORDER.LIST",
        DETAIL: "DOCTOR_ORDER.DETAIL",
        CREATE: "DOCTOR_ORDER.CREATE",
        UPDATE: "DOCTOR_ORDER.UPDATE",

        CANCEL_DOCTOR_ORDER: "DOCTOR_ORDER.UPDATE.CANCEL",
        COMPLETE_DOCTOR_ORDER: "DOCTOR_ORDER.UPDATE.COMPLETE",

        UPDATE_SERVICE: "DOCTOR_ORDER.UPDATE_SERVICE",
        UPDATE_PRODUCT: "DOCTOR_ORDER.UPDATE_PRODUCT",

        CREATE_NOTE: "BOOKING.CREATE_NOTE",
        UPDATE_NOTE: "BOOKING.UPDATE_NOTE",
        LIST_NOTE: "BOOKING.LIST_NOTE",

        SEARCH: "DOCTOR_ORDER.SEARCH",
    },

    TREATMENT: {
        LIST: "TREATMENT.LIST",
        DETAIL: "TREATMENT.DETAIL",
        CREATE: "TREATMENT.CREATE",
        UPDATE: "TREATMENT.UPDATE",
        UPDATE_DOCTOR: "TREATMENT.UPDATE_DOCTOR",
        UPDATE_PIC: "TREATMENT.UPDATE_PIC",
        UPDATE_EQUIPMENT: "TREATMENT.UPDATE_EQUIPMENT",

        UPDATE_SERVICE: "TREATMENT.UPDATE_SERVICE",
        UPDATE_MATERIAL: "TREATMENT.UPDATE_MATERIAL",
        UPDATE_MEDICINE: "TREATMENT.UPDATE_MEDICINE",
        UPDATE_ADD_ON_SERVICE: "TREATMENT.UPDATE_ADD_ON_SERVICE",

        START_TREATMENT: "TREATMENT.UPDATE.START",
        CANCEL_TREATMENT: "TREATMENT.UPDATE.CANCEL",
        COMPLETE_TREATMENT: "TREATMENT.UPDATE.COMPLETE",

        CREATE_BEFORE_AFTER: "TREATMENT.CREATE_BEFORE_AFTER",
        UPDATE_BEFORE_AFTER: "TREATMENT.UPDATE_BEFORE_AFTER",
        REMOVE_BEFORE_AFTER: "TREATMENT.REMOVE_BEFORE_AFTER",

        CREATE_NOTE: "TREATMENT.CREATE_NOTE",
        UPDATE_NOTE: "TREATMENT.UPDATE_NOTE",
        REMOVE_NOTE: "TREATMENT.REMOVE_NOTE",
        LIST_NOTE: "TREATMENT.LIST_NOTE",

        UPDATE_JOB_ASSESSMENT: "TREATMENT.UPDATE_JOB_ASSESSMENT",
        UPDATE_MANUAL_JOB_ASSESSMENT: "TREATMENT.UPDATE_MANUAL_JOB_ASSESSMENT",

        VIEW_DETAIL_MATERIAL: "TREATMENT.VIEW_DETAIL_MATERIAL",
        CREATE_DETAIL_MATERIAL: "TREATMENT.CREATE_DETAIL_MATERIAL",
    },

    SHIPPING: {
        LIST: "SHIPPING.LIST",
        DETAIL: "SHIPPING.DETAIL",
        CREATE: "SHIPPING.CREATE",
        UPDATE: "SHIPPING.UPDATE",
        DELETE: "SHIPPING.DELETE",
    },

    PAYMENT: {
        LIST: "PAYMENT.LIST",
        DETAIL: "PAYMENT.DETAIL",
        CREATE: "PAYMENT.CREATE",
        UPDATE: "PAYMENT.UPDATE",
        UPLOAD_PROOF: "UPLOAD_PROOF",
        CONFIRMATION: "CONFIRMATION",
        ALL: "PAYMENT.AlL",
        SUMMARY: "PAYMENT.SUMMARY",
        UPDATE_PAYMENT_METHOD: "PAYMENT.UPDATE_PAYMENT_METHOD",
    },
    CALENDAR: {
        LIST: "CALENDAR.LIST",
        SCHEDULE: "CALENDAR.SCHEDULE",
    },

    BANK: {
        CREATE: "BANK.CREATE",
        UPDATE: "BANK.UPDATE",
        LIST: "BANK.LIST",
        DETAIL: "BANK.DETAIL",
        ALL: "BANK.ALL",
    },

    TRANSFER_VOUCHER: {
        CREATE: "TRANSFER_VOUCHER.CREATE",
        UPDATE: "TRANSFER_VOUCHER.UPDATE",
        LIST: "TRANSFER_VOUCHER.LIST",
        DETAIL: "TRANSFER_VOUCHER.DETAIL",
        APPROVE: "TRANSFER_VOUCHER.APPROVE",
        CANCEL: "TRANSFER_VOUCHER.CANCEL",
    },

    CASE_STUDY: {
        CREATE: "CASE_STUDY.CREATE",
        UPDATE: "CASE_STUDY.UPDATE",
        LIST: "CASE_STUDY.LIST",
        DETAIL: "CASE_STUDY.DETAIL",
        UPDATE_TREATMENT: "CASE_STUDY.UPDATE_TREATMENT",
        LIST_NOTE: "CASE_STUDY.LIST_NOTE",
        CREATE_NOTE: "CASE_STUDY.CREATE_NOTE",
        UPDATE_NOTE: "CASE_STUDY.UPDATE_NOTE",
        REMOVE_NOTE: "CASE_STUDY.REMOVE_NOTE",
        LIST_MEDIA: "CASE_STUDY.LIST_MEDIA",
        CREATE_MEDIA: "CASE_STUDY.CREATE_MEDIA",
        UPDATE_MEDIA: "CASE_STUDY.UPDATE_MEDIA",
        REMOVE_MEDIA: "CASE_STUDY.REMOVE_MEDIA",
    },

    PAYMENT_CONFIGURATION: {
        CREATE: "PAYMENT_CONFIGURATION.CREATE",
        UPDATE: "PAYMENT_CONFIGURATION.UPDATE",
        LIST: "PAYMENT_CONFIGURATION.LIST",
        DETAIL: "PAYMENT_CONFIGURATION.DETAIL",
    },

    PAYMENT_2C2P: {
        CREATE: "PAYMENT_2C2P.CREATE",
        UPDATE: "PAYMENT_2C2P.UPDATE",
        LIST: "PAYMENT_2C2P.LIST",
        DETAIL: "PAYMENT_2C2P.DETAIL",
        ALL: "PAYMENT_2C2P.ALL",
    },

    SMART_SUPPORT: {
        UPDATE: "SMART_SUPPORT.UPDATE",
        DETAIL: "SMART_SUPPORT.DETAIL",
        CHANNEL: {
            CREATE: "SMART_SUPPORT.CHANNEL.CREATE",
            UPDATE: "SMART_SUPPORT.CHANNEL.UPDATE",
            DELETE: "SMART_SUPPORT.CHANNEL.DELETE",
        },
    },
    PRODUCT_STOCK: {
        SEARCH: "PRODUCT_STOCK.SEARCH",
        LIST: "PRODUCT_STOCK.LIST",
    },
    PRODUCT_STOCK_HISTORY: {
        LIST: "PRODUCT_STOCK_HISTORY.LIST",
    },
    PRODUCT_STOCK_ADJUSTMENT: {
        CREATE: "PRODUCT_STOCK_ADJUSTMENT.CREATE",
        UPDATE: "PRODUCT_STOCK_ADJUSTMENT.UPDATE",
        LIST: "PRODUCT_STOCK_ADJUSTMENT.LIST",
        DETAIL: "PRODUCT_STOCK_ADJUSTMENT.DETAIL",
        APPROVE: "PRODUCT_STOCK_ADJUSTMENT.APPROVE",
        CANCEL: "PRODUCT_STOCK_ADJUSTMENT.CANCEL",
        SUMMARY: "PRODUCT_STOCK_ADJUSTMENT.SUMMARY",
    },

    PRODUCT_STOCK_REQUEST: {
        CREATE: "PRODUCT_STOCK_REQUEST.CREATE",
        UPDATE: "PRODUCT_STOCK_REQUEST.UPDATE",
        LIST: "PRODUCT_STOCK_REQUEST.LIST",
        DETAIL: "PRODUCT_STOCK_REQUEST.DETAIL",
        APPROVE: "PRODUCT_STOCK_REQUEST.APPROVE",
        CANCEL: "PRODUCT_STOCK_REQUEST.CANCEL",
        SEARCH: "PRODUCT_STOCK_REQUEST.SEARCH",
        SUMMARY: "PRODUCT_STOCK_REQUEST.SUMMARY",
    },

    PRODUCT_STOCK_TRANSFER: {
        CREATE: "PRODUCT_STOCK_TRANSFER.CREATE",
        UPDATE: "PRODUCT_STOCK_TRANSFER.UPDATE",
        LIST: "PRODUCT_STOCK_TRANSFER.LIST",
        DETAIL: "PRODUCT_STOCK_TRANSFER.DETAIL",
        APPROVE: "PRODUCT_STOCK_TRANSFER.APPROVE",
        CANCEL: "PRODUCT_STOCK_TRANSFER.CANCEL",
        PRODUCT: "PRODUCT_STOCK_TRANSFER.PRODUCT",
        SUMMARY: "PRODUCT_STOCK_TRANSFER.SUMMARY",
    },

    PRODUCT_STOCK_DELIVERY: {
        CREATE: "PRODUCT_STOCK_DELIVERY.CREATE",
        UPDATE: "PRODUCT_STOCK_DELIVERY.UPDATE",
        LIST: "PRODUCT_STOCK_DELIVERY.LIST",
        DETAIL: "PRODUCT_STOCK_DELIVERY.DETAIL",
        APPROVE: "PRODUCT_STOCK_DELIVERY.APPROVE",
        CANCEL: "PRODUCT_STOCK_DELIVERY.CANCEL",
        ALL: "PRODUCT_STOCK_DELIVERY.ALL",
        CONFIRM_PICK_UP_DESTINATION:
            "PRODUCT_STOCK_DELIVERY.CONFIRM_PICK_UP_DESTINATION",
        RECEIVE_STOCK_DESTINATION:
            "PRODUCT_STOCK_DELIVERY.RECEIVE_STOCK_DESTINATION",
        CONFIRM_PICK_UP_ORIGIN: "PRODUCT_STOCK_DELIVERY.CONFIRM_PICK_UP_ORIGIN",
    },

    MATERIAL_STOCK: {
        SEARCH: "MATERIAL_STOCK.SEARCH",
        LIST: "MATERIAL_STOCK.LIST",
    },
    MATERIAL_STOCK_HISTORY: {
        LIST: "MATERIAL_STOCK_HISTORY.LIST",
    },

    AGENCY: {
        CREATE: "AGENCY.CREATE",
        UPDATE: "AGENCY.UPDATE",
        LIST: "AGENCY.LIST",
        DETAIL: "AGENCY.DETAIL",
    },

    VENDOR: {
        LIST: "VENDOR.LIST",
        CREATE: "VENDOR.CREATE",
        DETAIL: "VENDOR.DETAIL",
        UPDATE: "VENDOR.UPDATE",
        SEARCH: "VENDOR.SEARCH",
        PURCHASE: "VENDOR.PURCHASE",
    },

    MATERIAL: {
        LIST: "MATERIAL.LIST",
        CREATE: "MATERIAL.CREATE",
        DETAIL: "MATERIAL.DETAIL",
        UPDATE: "MATERIAL.UPDATE",
        SEARCH: "MATERIAL.SEARCH",
        PURCHASE: "MATERIAL.PURCHASE",
        STOCK_ADJUSTMENT: "MATERIAL.STOCK_ADJUSTMENT",
    },

    MATERIAL_STOCK_ADJUSTMENT: {
        CREATE: "MATERIAL_STOCK_ADJUSTMENT.CREATE",
        UPDATE: "MATERIAL_STOCK_ADJUSTMENT.UPDATE",
        LIST: "MATERIAL_STOCK_ADJUSTMENT.LIST",
        DETAIL: "MATERIAL_STOCK_ADJUSTMENT.DETAIL",
        APPROVE: "MATERIAL_STOCK_ADJUSTMENT.APPROVE",
        CANCEL: "MATERIAL_STOCK_ADJUSTMENT.CANCEL",
        SUMMARY: "MATERIAL_STOCK_ADJUSTMENT.SUMMARY",
        CHECK: "MATERIAL_STOCK_ADJUSTMENT.CHECK",
    },

    MATERIAL_PURCHASE: {
        LIST: "MATERIAL_PURCHASE.LIST",
        CREATE: "MATERIAL_PURCHASE.CREATE",
        DETAIL: "MATERIAL_PURCHASE.DETAIL",
        UPDATE: "MATERIAL_PURCHASE.UPDATE",
        SEARCH: "MATERIAL_PURCHASE.SEARCH",
        APPROVAL: "MATERIAL_PURCHASE.APPROVAL",
        CANCEL: "MATERIAL_PURCHASE.CANCEL",
        UPDATE_BILLING: "MATERIAL_PURCHASE.UPDATE_BILLING",
    },

    MATERIAL_PURCHASE_REQUEST: {
        LIST: "MATERIAL_PURCHASE_REQUEST.LIST",
        CREATE: "MATERIAL_PURCHASE_REQUEST.CREATE",
        DETAIL: "MATERIAL_PURCHASE_REQUEST.DETAIL",
        UPDATE: "MATERIAL_PURCHASE_REQUEST.UPDATE",
        SEARCH: "MATERIAL_PURCHASE_REQUEST.SEARCH",
        APPROVAL: "MATERIAL_PURCHASE_REQUEST.APPROVAL",
        CANCEL: "MATERIAL_PURCHASE_REQUEST.CANCEL",
        PURCHASE: "MATERIAL_PURCHASE_REQUEST.PURCHASE",
        CHECK: "MATERIAL_PURCHASE_REQUEST.CHECK",
    },

    MATERIAL_PURCHASE_QUOTATION: {
        LIST: "MATERIAL_PURCHASE_QUOTATION.LIST",
        CREATE: "MATERIAL_PURCHASE_QUOTATION.CREATE",
        DETAIL: "MATERIAL_PURCHASE_QUOTATION.DETAIL",
        UPDATE: "MATERIAL_PURCHASE_QUOTATION.UPDATE",
        APPROVAL: "MATERIAL_PURCHASE_QUOTATION.APPROVAL",
        CANCEL: "MATERIAL_PURCHASE_QUOTATION.CANCEL",
    },

    MATERIAL_PURCHASE_SHIPPING: {
        LIST: "MATERIAL_PURCHASE_SHIPPING.LIST",
        CREATE: "MATERIAL_PURCHASE_SHIPPING.CREATE",
        DETAIL: "MATERIAL_PURCHASE_SHIPPING.DETAIL",
        UPDATE: "MATERIAL_PURCHASE_SHIPPING.UPDATE",
    },

    MATERIAL_PURCHASE_PAYMENT: {
        LIST: "MATERIAL_PURCHASE_PAYMENT.LIST",
        DETAIL: "MATERIAL_PURCHASE_PAYMENT.DETAIL",
        CREATE: "MATERIAL_PURCHASE_PAYMENT.CREATE",
        UPDATE: "MATERIAL_PURCHASE_PAYMENT.UPDATE",
        UPLOAD_PROOF: "MATERIAL_PURCHASE_PAYMENT.UPLOAD_PROOF",
        ALL: "MATERIAL_PURCHASE_PAYMENT.AlL",
        UPDATE_PAYMENT_METHOD: "PAYMENT.UPDATE_PAYMENT_METHOD",
    },

    MATERIAL_PURCHASE_DELIVERY: {
        LIST: "MATERIAL_PURCHASE_DELIVERY.LIST",
        CREATE: "MATERIAL_PURCHASE_DELIVERY.CREATE",
        DETAIL: "MATERIAL_PURCHASE_DELIVERY.DETAIL",
        UPDATE: "MATERIAL_PURCHASE_DELIVERY.UPDATE",
        UPDATE_STATUS: "MATERIAL_PURCHASE_DELIVERY.UPDATE_STATUS",
        ALL: "MATERIAL_PURCHASE_DELIVERY.ALL",
        MATERIAL: "MATERIAL_PURCHASE_DELIVERY.MATERIAL",
    },

    MATERIAL_LOT: {
        LIST: "MATERIAL_LOT.LIST",
    },

    REPORT: {
        CASHIER: "REPORT.CASHIER",
        CASHIER_PAYMENT: "REPORT.CASHIER_PAYMENT",
        SALESPERSON_USER: "REPORT.SALESPERSON_USER",
        SALESPERSON_MONTHLY: "REPORT.SALESPERSON_MONTHLY",
        CUSTOMER_SOURCE: "REPORT.CUSTOMER_SOURCE",
        DOCTOR_SUMMARY: "REPORT.DOCTOR_SUMMARY",
        DOCTOR_DETAIL: "REPORT.DOCTOR_DETAIL",
        NURSE_DETAIL: "REPORT.NURSE_DETAIL",
        NURSE_SUMMARY: "REPORT.NURSE_SUMMARY",
        THERAPIST_DETAIL: "REPORT.THERAPIST_DETAIL",
        THERAPIST_SUMMARY: "REPORT.THERAPIST_SUMMARY",
        CUSTOMER_VOUCHER: "REPORT.CUSTOMER_VOUCHER",
        TREATMENT_DAILY_USAGE: "REPORT.TREATMENT_DAILY_USAGE",
    },

    CREDIT: {
        LIST: "CREDIT.LIST",
        DETAIL: "CREDIT.DETAIL",
    },

    PROMOTION: {
        LIST: "PROMOTION.LIST",
        DETAIL: "PROMOTION.DETAIL",
        CREATE: "PROMOTION.CREATE",
        UPDATE: "PROMOTION.UPDATE",
        STOP: "PROMOTION.STOP",
    },
};

export const PERMISSION_MODULE = {
    COMMON: "COMMON",
    PRODUCT: "PRODUCT",
    TREATMENT: "TREATMENT",
    USER: "ADMIN.USER",
    ROLE: "ADMIN.ROLE",
    CUSTOMER: "CUSTOMER",
    CATEGORY_SERVICE: "CATEGORY_SERVICE",
    CATEGORY_PRODUCT: "CATEGORY_PRODUCT",
    CATEGORY_EQUIPMENT: "CATEGORY_EQUIPMENT",
    CATEGORY_MATERIAL: "CATEGORY_MATERIAL",
    EQUIPMENT: "EQUIPMENT",
    TEMPORARY_SCHEDULE: "TEMPORARY_SCHEDULE",
    SERVICE_CONFIGURATION: "SERVICE_CONFIGURATION",
    BRANCH: "BRANCH",
    MEDIA_UPLOAD: "MEDIA.UPLOAD",
    MEDIA_FILE: "MEDIA.FILE",
    MEDIA_FOLDER: "MEDIA.FOLDER",
    CART_RULE: "CART_RULE",
    QUOTATION: "QUOTATION",
    SHIPPING: "SHIPPING",
    MATERIAL: "MATERIAL",
};

export const PERMISSION_MODULES: { [key: string]: any }[] = [
    {
        key: PERMISSION_MODULE.COMMON,
        label: "common",
        accessTypes: null,
        hasChild: false,
    },

    {
        key: PERMISSION_MODULE.CUSTOMER,
        label: "customer",
        accessTypes: [
            { key: PERMISSION.CUSTOMER.LIST, label: "list" },
            { key: PERMISSION.CUSTOMER.DETAIL, label: "detail" },
            { key: PERMISSION.CUSTOMER.CREATE, label: "create" },
            {
                key: PERMISSION.CUSTOMER.UPDATE_PERSONAL,
                label: "updatePersonal",
            },
            { key: PERMISSION.CUSTOMER.UPDATE_MEDICAL, label: "updateMedical" },
            { key: PERMISSION.CUSTOMER.UPDATE_ADDRESS, label: "updateAddress" },
            { key: PERMISSION.CUSTOMER.UPDATE_BILLING, label: "updateBilling" },
            { key: PERMISSION.CUSTOMER.CREATE_ADDRESS, label: "createAddress" },
            { key: PERMISSION.CUSTOMER.CREATE_BILLING, label: "createBilling" },
            { key: PERMISSION.CUSTOMER.REMOVE_ADDRESS, label: "removeAddress" },
            { key: PERMISSION.CUSTOMER.REMOVE_BILLING, label: "removeBilling" },
            { key: PERMISSION.CUSTOMER.ADDRESS_ALL, label: "addressAll" },
            { key: PERMISSION.CUSTOMER.ADDRESS_DETAIL, label: "addressDetail" },
            { key: PERMISSION.CUSTOMER.BILLING_ALL, label: "billingAll" },
            { key: PERMISSION.CUSTOMER.BILLING_DETAIL, label: "billingDetail" },
            { key: PERMISSION.CUSTOMER.CREATE_NOTE, label: "createNote" },
            { key: PERMISSION.CUSTOMER.UPDATE_NOTE, label: "updateNote" },
            { key: PERMISSION.CUSTOMER.REMOVE_NOTE, label: "removeNote" },
            { key: PERMISSION.CUSTOMER.LIST_NOTE, label: "listNote" },
            { key: PERMISSION.CUSTOMER.UPDATE_TAG, label: "updateTag" },
            { key: PERMISSION.CUSTOMER.UPDATE_SURVEY, label: "updateSurvey" },
        ],
    },
    {
        key: PERMISSION_MODULE.QUOTATION,
        label: "quotation",
    },
    {
        key: PERMISSION_MODULE.TREATMENT,
        label: "treatment",
        accessTypes: [
            ...getCommonAccessTypes("TREATMENT"),
            { key: PERMISSION.TREATMENT.UPDATE_DOCTOR, label: "updateDoctor" },
            { key: PERMISSION.TREATMENT.UPDATE_PIC, label: "updatePIC" },
            {
                key: PERMISSION.TREATMENT.UPDATE_EQUIPMENT,
                label: "updateEquipment",
            },

            {
                key: PERMISSION.TREATMENT.UPDATE_SERVICE,
                label: "updateService",
            },
            {
                key: PERMISSION.TREATMENT.UPDATE_MATERIAL,
                label: "updateMaterial",
            },
            {
                key: PERMISSION.TREATMENT.UPDATE_MEDICINE,
                label: "updateMedicine",
            },
            {
                key: PERMISSION.TREATMENT.UPDATE_ADD_ON_SERVICE,
                label: "updateAddOnService",
            },

            {
                key: PERMISSION.TREATMENT.START_TREATMENT,
                label: "startTreatment",
            },
            {
                key: PERMISSION.TREATMENT.CANCEL_TREATMENT,
                label: "cancelTreatment",
            },
            {
                key: PERMISSION.TREATMENT.COMPLETE_TREATMENT,
                label: "completeTreatment",
            },

            {
                key: PERMISSION.TREATMENT.CREATE_BEFORE_AFTER,
                label: "createBeforeAndAfter",
            },
            {
                key: PERMISSION.TREATMENT.UPDATE_BEFORE_AFTER,
                label: "updateBeforeAndAfter",
            },
            {
                key: PERMISSION.TREATMENT.REMOVE_BEFORE_AFTER,
                label: "removeBeforeAndAfter",
            },

            { key: PERMISSION.TREATMENT.CREATE_NOTE, label: "createNote" },
            { key: PERMISSION.TREATMENT.UPDATE_NOTE, label: "updateNote" },
            { key: PERMISSION.TREATMENT.REMOVE_NOTE, label: "removeNote" },
            { key: PERMISSION.TREATMENT.LIST_NOTE, label: "listNote" },

            {
                key: PERMISSION.TREATMENT.UPDATE_JOB_ASSESSMENT,
                label: "autoJobAssessment",
            },
            {
                key: PERMISSION.TREATMENT.UPDATE_MANUAL_JOB_ASSESSMENT,
                label: "manualJobAssessment",
            },

            {
                key: PERMISSION.TREATMENT.VIEW_DETAIL_MATERIAL,
                label: "viewDetailMaterial",
            },
            {
                key: PERMISSION.TREATMENT.CREATE_DETAIL_MATERIAL,
                label: "createDetailMaterial",
            },
        ],
    },
    {
        key: PERMISSION_MODULE.PRODUCT,
        label: "product",
    },
    {
        key: PERMISSION_MODULE.CATEGORY_SERVICE,
        label: "categoryService",
    },
    {
        key: PERMISSION_MODULE.CATEGORY_PRODUCT,
        label: "categoryProduct",
    },
    {
        key: PERMISSION_MODULE.CATEGORY_EQUIPMENT,
        label: "categoryEquipment",
    },
    {
        key: PERMISSION_MODULE.CATEGORY_MATERIAL,
        label: "categoryMaterial",
    },
    {
        key: PERMISSION_MODULE.EQUIPMENT,
        label: "equipment",
    },
    {
        key: PERMISSION_MODULE.TEMPORARY_SCHEDULE,
        label: "temporarySchedule",
        accessTypes: [
            ...getCommonAccessTypes("TEMPORARY_SCHEDULE"),
            { key: PERMISSION.TEMPORARY_SCHEDULE.REMOVE, label: "remove" },
        ],
    },
    {
        key: PERMISSION_MODULE.SERVICE_CONFIGURATION,
        label: "serviceConfiguration",
    },
    {
        key: PERMISSION_MODULE.BRANCH,
        label: "branch",
        accessTypes: [
            ...getCommonAccessTypes("BRANCH"),
            { key: PERMISSION.BRANCH.UPDATE_ADDRESS, label: "updateAddress" },
            {
                key: PERMISSION.BRANCH.UPDATE_WAREHOUSE,
                label: "updateWarehouse",
            },
            { key: PERMISSION.BRANCH.UPDATE_PIC, label: "updatePic" },
        ],
    },
    {
        key: PERMISSION_MODULE.MEDIA_UPLOAD,
        label: "mediaUpload",
        accessTypes: [
            { key: PERMISSION.MEDIA.UPLOAD.GET_URL, label: "getUrl" },
        ],
    },
    {
        key: PERMISSION_MODULE.MEDIA_FILE,
        label: "mediaFile",
        accessTypes: [
            { key: PERMISSION.MEDIA.FILE.ALL, label: "all" },
            ...getCommonAccessTypes("MEDIA.FILE"),
            { key: PERMISSION.MEDIA.FILE.MOVE, label: "move" },
            { key: PERMISSION.MEDIA.FILE.DELETE, label: "delete" },
        ],
    },
    {
        key: PERMISSION_MODULE.MEDIA_FOLDER,
        label: "mediaFolder",
        accessTypes: [
            { key: PERMISSION.MEDIA.FOLDER.ALL, label: "all" },
            { key: PERMISSION.MEDIA.FOLDER.CREATE, label: "create" },
            { key: PERMISSION.MEDIA.FOLDER.UPDATE, label: "update" },
            { key: PERMISSION.MEDIA.FOLDER.MOVE, label: "move" },
            { key: PERMISSION.MEDIA.FOLDER.DELETE, label: "delete" },
        ],
    },
    {
        key: PERMISSION_MODULE.CART_RULE,
        label: "cartRule",
    },

    {
        key: PERMISSION_MODULE.SHIPPING,
        label: "shipping",
        accessTypes: [
            ...getCommonAccessTypes("SHIPPING"),
            { key: PERMISSION.SHIPPING.DELETE, label: "delete" },
        ],
    },
    {
        key: PERMISSION_MODULE.MATERIAL,
        label: "material",
    },
    {
        key: PERMISSION_MODULE.USER,
        label: "user",
        accessTypes: [
            { key: PERMISSION.ADMIN.USER.ALL, label: "all" },
            ...getCommonAccessTypes("USER"),
            {
                key: PERMISSION.ADMIN.USER.UPDATE_SPECIAL_ACCESS,
                label: "updateSpecialAccess",
            },
        ],
    },
    {
        key: PERMISSION_MODULE.ROLE,
        label: "role",
        accessTypes: [
            { key: PERMISSION.ADMIN.ROLE.ALL, label: "all" },
            ...getCommonAccessTypes("ROLE"),
        ],
    },
];

export const USER_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const USER_STATUSES = [
    { id: USER_STATUS.ACTIVE, label: "enabled", color: "#33B950" },
    { id: USER_STATUS.INACTIVE, label: "disabled", color: "#C4C4C4" },
];

export const USER_STATUSES_BOOLEAN = [
    { id: true, label: "enabled", color: "#33B950" },
    { id: false, label: "disabled", color: "#C4C4C4" },
];

export const JOB_TYPES = [
    {
        key: JobType.NORMAL_EMPLOYEE,
        label: "normalEmployee",
    },
    {
        key: JobType.DOCTOR,
        label: "doctor",
    },
    {
        key: JobType.THERAPIST,
        label: "therapist",
    },
    {
        key: JobType.NURSE,
        label: "nurse",
    },
    {
        key: JobType.DRIVER,
        label: "driver",
    },
];

export const ORDER_STATUS_MANAGEMENTS = [
    {
        key: OrderStatusManagement.PENDING_PAYMENT,
        label: "pendingPayment",
    },
    {
        key: OrderStatusManagement.PENDING_CONFIRMATION,
        label: "pendingConfirmation",
    },
    {
        key: OrderStatusManagement.PARTIALLY_PAID,
        label: "partiallyPaid",
    },
    {
        key: OrderStatusManagement.ORDER_PROCESSING,
        label: "orderProcessing",
    },
    {
        key: OrderStatusManagement.DELIVERY_PROCESSING,
        label: "deliveryProcessing",
    },
    {
        key: OrderStatusManagement.DELIVERED,
        label: "delivered",
    },
    {
        key: OrderStatusManagement.COMPLETED,
        label: "completed",
    },
    {
        key: OrderStatusManagement.CANCELLED,
        label: "cancelled",
    },
    {
        key: OrderStatusManagement.REFUNDED,
        label: "refunded",
    },
];

export const DELIVERY_STATUS_MANAGEMENTS = [
    {
        key: DeliveryStatus.DeliveryPending,
        label: "deliveryPending",
    },
    {
        key: DeliveryStatus.DeliveryBooked,
        label: "deliveryBooked",
    },
    {
        key: DeliveryStatus.InDelivery,
        label: "inDelivery",
    },
    {
        key: DeliveryStatus.DeliveryCompleted,
        label: "deliveryCompleted",
    },
    {
        key: DeliveryStatus.DeliveryCancelled,
        label: "deliveryCancelled",
    },
    {
        key: DeliveryStatus.DeliveryReturned,
        label: "deliveryReturned",
    },
];

export const SPECIAL_ACCESS_TYPES = [
    {
        key: SpecialAccessType.ONE_TIME_USE,
        label: "oneTimeUse",
    },
    {
        key: SpecialAccessType.MULTIPLE_TIME_USE,
        label: "multipleTimeUse",
    },
];

export const WORKING_SCHEDULES_DATES = [
    {
        key: DateSchedule.Monday,
        label: "monday",
    },
    {
        key: DateSchedule.Tuesday,
        label: "tuesday",
    },
    {
        key: DateSchedule.Wednesday,
        label: "wednesday",
    },
    {
        key: DateSchedule.Thursday,
        label: "thursday",
    },
    {
        key: DateSchedule.Friday,
        label: "friday",
    },
    {
        key: DateSchedule.Saturday,
        label: "saturday",
    },
    {
        key: DateSchedule.Sunday,
        label: "sunday",
    },
];

export enum ServiceCommissionType {
    DEFAULT = "DEFAULT",
    CUSTOMIZE = "CUSTOMIZE",
}

export const ServiceCommissionTypes = [
    { id: ServiceCommissionType.DEFAULT, label: "default" },
    { id: ServiceCommissionType.CUSTOMIZE, label: "customize" },
];
