import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import React, { useContext } from "react";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { DELIVERY_DRIVER_TYPES } from "../../../constant/delivery-driver";
import { DeliveryDriverDetailContext } from "../../../context/delivery-driver";
import { IDeliveryDriver } from "../../../interfaces/delivery-driver";
import Messages from "../../../languages/Messages";

export interface IDeliveryDriverSideViewProps {
    [key: string]: any;
}

const KEYS_LIST: IRowsKey<IDeliveryDriver>[] = [
    {
        id: "vehicle",
        label: Messages.vehicleNo,
        renderContent: ({ data }) => data?.name ?? "N/A",
    },
    {
        id: "driver",
        label: Messages.driver,
        renderContent: ({ data }) => (
            <UserAvatarName user={data} size="xx-small" />
        ),
    },
    {
        id: "createdAt",
        label: Messages.assignedDate,
        renderContent: ({ data }) =>
            data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
    },
];

const DeliveryDriverSideView: React.FC<IDeliveryDriverSideViewProps> = ({
    id,
}) => {
    const { delivery } = useContext(DeliveryDriverDetailContext);

    const { origin, type, destination, estDateOfArrival, remark } =
        delivery || {};

    const renderBlock = (label: string, content: any, className?: string) => {
        return (
            <div className={`border p-4 ${className}`}>
                <h5 className="mb-3">{label}</h5>
                {typeof content === "string" ? (
                    <div className="text">{content}</div>
                ) : (
                    content
                )}
            </div>
        );
    };

    return (
        <div className="col-span-1">
            {renderBlock(
                Messages.assignedTo,
                <ViewRowInterchange
                    variant="border"
                    dataSource={delivery}
                    keyList={KEYS_LIST}
                />
            )}
            {renderBlock(
                Messages.branchWarehouseOrigin,
                origin?.name ?? "N/A",
                "mt-3"
            )}
            {renderBlock(
                Messages.branchWarehouseDestination,
                destination?.name ?? "N/A",
                "mt-3"
            )}
            {renderBlock(
                Messages.type,
                (Messages as any)[
                    DELIVERY_DRIVER_TYPES.find((item) => item?.id === type)
                        ?.label ?? ""
                ],
                "mt-3"
            )}
            {renderBlock(
                Messages.estDateOfArrival,
                estDateOfArrival
                    ? TimeUtils.convertMiliToDateTime(estDateOfArrival)
                    : "N/A",
                "mt-3"
            )}
            {renderBlock(Messages.remark, remark ?? "N/A", "mt-3")}
        </div>
    );
};

export default DeliveryDriverSideView;
