import {
    AwesomeTableComponent,
    DateInput,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { find, isUndefined } from "lodash";
import moment from "moment";
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
    JobTypeAdmin,
    useCreateOrderDeliveryForAdminMutation,
    useUpdateOrderDeliveryForAdminMutation,
} from "../../../../api/hooks";
import StockTransferAPI from "../../../../api/queries/stock-transfer";
import Drawer from "../../../../common/Drawer";
import InputSelectForm from "../../../../common/input/InputSelectForm";
import InputTextForm from "../../../../common/input/InputTextForm";
import { DELIVERY_DRIVER_TYPES } from "../../../../constant/delivery-driver";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import Messages from "../../../../languages/Messages";
import ProductName from "../../../quotation/share/ProductName";
import QuantityInputField from "../../../quotation/share/QuantityInputField";
import UserSelect from "../../../user/share/UserSelect";
import VehicleSelect from "../../../vehicle/share/VehicleSelect";

export const DeliveryFormSchema = Yup.object().shape({
    deliveryType: Yup.mixed().required(Messages.fieldRequired),
    // vehicle: Yup.mixed().required(Messages.fieldRequired),
    driver: Yup.mixed().required(Messages.fieldRequired),
    deliveryNo: Yup.string().required(Messages.fieldRequired),
    estDateOfArrival: Yup.mixed().required(Messages.fieldRequired),
    remark: Yup.string().required(Messages.fieldRequired),
});

const StockTransferDeliveryDrawer = ({
    open,
    onClose,
    onSave,
    isView,
    delivery,
    transferItems,
}: any) => {
    const isEdit = !isUndefined(delivery);
    const { transferId } = useParams<any>();
    const { stockTransfer } = useContext(StockTransferDetailContext);
    const [deliveryProducts, setDeliveryProducts] = useState<any[]>(
        delivery ? delivery.products : []
    );
    const [createOrderDelivery] = useCreateOrderDeliveryForAdminMutation();
    const [updateOrderDelivery] = useUpdateOrderDeliveryForAdminMutation();

    const deliveryForm = useFormik<any>({
        initialValues: delivery
            ? {
                  deliveryType: delivery.type,
                  vehicle: delivery.vehicle,
                  driver: delivery.driver,
                  deliveryNo: delivery.deliveryNo,
                  estDateOfArrival: moment(delivery.estDateOfArrival),
                  remark: delivery.remark,
              }
            : ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DeliveryFormSchema,
        onSubmit: (values: any) => {
            const {
                deliveryType,
                vehicle,
                driver,
                deliveryNo,
                estDateOfArrival,
                remark,
            } = values;
            const payload = isEdit
                ? {
                      transferId,
                      id: delivery?.id,
                      payload: {
                          vehicle: vehicle?.id ?? "63b8f06bb96fb867137e4b8f",
                          driver: driver?.id,
                          remark,
                      },
                  }
                : {
                      transferId,
                      payload: {
                          ...(isEdit ? {} : { type: deliveryType }),
                          vehicle: vehicle?.id ?? "63b8f06bb96fb867137e4b8f",
                          driver: driver?.id,
                          deliveryNo,
                          estDateOfArrival: moment(estDateOfArrival).toDate(),
                          remark,
                          stocks: deliveryProducts
                              .map((item) => ({
                                  product: item?.product?.id,
                                  quantity: item.stock,
                              }))
                              .filter((item) => item.quantity > 0),
                      },
                  };
            Progress.show(
                {
                    method: isEdit
                        ? StockTransferAPI.updateDelivery
                        : StockTransferAPI.createDelivery,
                    params: [payload],
                },
                (resp) => {
                    Notifications.showSuccess(
                        Messages.createDeliverySuccessfully
                    );
                    onSave && onSave();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    const { values, errors, setFieldValue, handleSubmit } = deliveryForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="800px"
            title={isEdit ? Messages.updateDelivery : Messages.createDelivery}
            onSave={handleSubmit}
            disableSave={isView}
        >
            <div className="p-3">
                <div className="mb-3">
                    <InputSelectForm
                        form={deliveryForm}
                        keyData="deliveryType"
                        dataSource={DELIVERY_DRIVER_TYPES}
                        getLabel={(i) => Messages?.[i?.label]}
                        label={Messages.deliveryType}
                        disabled={isEdit || isView}
                    />
                </div>
                <div className="mb-3">
                    <VehicleSelect
                        value={values?.vehicle}
                        onChange={(value) => setFieldValue("vehicle", value)}
                        error={errors?.vehicle}
                        label={Messages.vehicleNo}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <UserSelect
                        onChange={(value) => setFieldValue("driver", value)}
                        label={Messages.driver}
                        params={{ jobType: JobTypeAdmin.Driver }}
                        value={values?.driver}
                        error={errors?.driver as string}
                        disabled={isView}
                    />
                </div>
                <div className="mb-3">
                    <InputTextForm
                        form={deliveryForm}
                        keyData="deliveryNo"
                        label={Messages.deliveryNo}
                        disabled={isView || isEdit}
                    />
                </div>
                <div className="mb-3">
                    <DateInput
                        label={Messages.estDateOfArrival}
                        onChange={(value) => {
                            setFieldValue("estDateOfArrival", value);
                        }}
                        value={
                            values?.estDateOfArrival
                                ? moment(values?.estDateOfArrival)
                                : (null as any)
                        }
                        format="DD/MM/YYYY"
                        error={errors?.estDateOfArrival as any}
                        disabled={isView || isEdit}
                    />
                </div>
                <div className="mb-3">
                    <TableItemDelivery
                        products={transferItems ?? []}
                        onChange={(values: any) => setDeliveryProducts(values)}
                        disabled={isEdit}
                        isEdit={isEdit}
                        inDeliveryProducts={delivery?.stocks}
                    />
                </div>
                <div className="mb-3">
                    <InputTextForm
                        form={deliveryForm}
                        keyData="remark"
                        label={Messages.remark}
                        multiple
                        disabled={isView}
                    />
                </div>
            </div>
        </Drawer>
    );
};

//fake stock
const addStockToProducts = (products: any[], inDeliveryProducts: any[]) => {
    return products.map((product) => {
        const inDeliveryProduct = find(
            inDeliveryProducts,
            (p) => p?.product?.id === product?.product?.id
        );
        const currentStock = inDeliveryProduct
            ? product.stock - inDeliveryProduct.quantity
            : 0;
        return {
            ...product,
            stock: currentStock,
            deliverQty: inDeliveryProduct?.quantity ?? 0,
            leftItemCount: product.stock - currentStock,
            id: product.id,
        };
    });
};

const TableItemDelivery = ({
    onChange,
    products = [],
    inDeliveryProducts = [],
    disabled = false,
    isEdit = false,
    transferDelivery = false,
    selectedWarehouse,
}: any) => {
    const [productDelivery, setProductDelivery] = useState(
        addStockToProducts(products, inDeliveryProducts)
    );
    const deliveryItemList = useRef<any>(null);

    useEffect(() => {
        deliveryItemList && deliveryItemList.current.refreshData();
        onChange && onChange(productDelivery);
    }, [productDelivery]);

    const onChangeQuantitySubPro = useCallback(
        (parentPro, childPro, quantity) => {
            if (disabled) {
                return;
            }
            if (quantity > childPro.quantity || quantity < 0) {
                return;
            }
            if (quantity > childPro.stock && childPro.leftItemCount === 0) {
                Notifications.showError(Messages.noLeftItem);
                return;
            }
            const productResult = productDelivery.map((itemParam: any) => {
                if (parentPro.id === itemParam.id) {
                    const { groups } = itemParam;
                    const groupResult = setChangeQuantityInProducts(
                        groups,
                        childPro,
                        quantity
                    );

                    return {
                        ...itemParam,
                        groups: groupResult,
                    };
                }
                return itemParam;
            });
            setProductDelivery(productResult);
        },
        [disabled, productDelivery]
    );

    const onChangeQuantity = useCallback(
        (quantity, item) => {
            if (disabled) {
                return;
            }
            if (quantity > item.quantity || quantity < 0) {
                return;
            }
            if (quantity > item.stock && item.leftItemCount === 0) {
                Notifications.showError(Messages.noLeftItem);
                return;
            }
            const productResult = setChangeQuantityInProducts(
                productDelivery,
                item,
                quantity
            );
            setProductDelivery(productResult);
        },
        [disabled, productDelivery]
    );

    const setChangeQuantityInProducts = (
        products: any,
        productItem: any,
        quantity: any
    ) => {
        const list = products.map((itemParam: any) => {
            if (productItem?.product?.id === itemParam?.product?.id) {
                return {
                    ...itemParam,
                    stock: quantity,
                    leftItemCount:
                        itemParam.leftItemCount + itemParam.stock - quantity,
                };
            }
            return itemParam;
        });
        return list;
    };

    const columns = useMemo(
        () => [
            {
                title: Messages.item,
                dataIndex: "product",
                render: (product: any, item: any) => {
                    const isNoneItem = item.leftItemCount === 0;
                    let subBody = <div />;
                    if (!item.groups || item.groups.length === 0) {
                        subBody = (
                            <div
                                className={
                                    isNoneItem
                                        ? "text-xs text-green-500"
                                        : "text-xs text-orange-500"
                                }
                            >
                                {item.leftItemCount + Messages.willBeLeft}
                            </div>
                        );
                    }
                    return <ProductName item={product} subBody={subBody} />;
                },
                width: 500,
            },
            {
                title: Messages.quantity,
                dataIndex: "stock",
                width: disabled ? 70 : 200,
                render: (stock, item) => {
                    const isGroupProduct = item?.groups?.length > 0;
                    const parentPro = item?.parentPro;
                    const isSubProductTable = !!parentPro;
                    let quantityView = (
                        <QuantityInputField
                            quantity={stock}
                            onChangeQuantity={(quantity: any) =>
                                isSubProductTable
                                    ? onChangeQuantitySubPro(
                                          parentPro,
                                          item,
                                          quantity
                                      )
                                    : onChangeQuantity(quantity, item)
                            }
                        />
                    );
                    if (disabled) {
                        quantityView = item?.deliverQty;
                    }
                    return isGroupProduct ? <div /> : quantityView;
                },
            },
        ],
        [disabled, onChangeQuantity, onChangeQuantitySubPro, transferDelivery]
    );

    const source = () => {
        return Promise.resolve(productDelivery);
    };

    const transformer = (products: any) => {
        const transformedProduct = products.map((product: any = {}) => {
            let { groups = [] } = product;
            if (groups?.length > 0) {
                groups = groups.map((item: any) => ({
                    ...item,
                    parentPro: product,
                }));
            }
            if (groups?.length > 0) {
                return {
                    ...product,
                    children: groups,
                };
            }
            return product;
        });
        return transformedProduct;
    };

    return (
        <AwesomeTableComponent
            className="height-auto"
            baseColumnProps={{ width: 150, align: "center" }}
            ref={(ref) => {
                deliveryItemList.current = ref;
            }}
            source={() => source()}
            transformer={transformer}
            columns={columns}
            isScroll={false}
            isPagination={false}
            classNameTable="tableItemDelivery"
            rowClassName={(product) => {
                let rowClassName = "rowAwesomeTable ";
                if (product.isGift) {
                    rowClassName += "productGiftTableItemRow";
                }
                return rowClassName;
            }}
        />
    );
};

export default StockTransferDeliveryDrawer;
