import { PromotionStatus } from "../api/hooks";
import { PRICE_SET_TYPES } from "./product";

export const PROMOTIONS_STATUSES = [
    { id: PromotionStatus.Draft, label: "scheduled", color: "#C4C4C4" },
    { id: PromotionStatus.Published, label: "onGoing", color: "#33B950" },
    { id: PromotionStatus.Ended, label: "ended", color: "#ED6969" },
];

export const PROMOTIONS_FILTER_STATUSES = [
    { id: "all", label: "all", color: "#C4C4C4" },
    ...PROMOTIONS_STATUSES,
];

export const PROMOTIONS_SEARCH_KEYS = [
    { id: "promotionNo", label: "promotionId" },
    { id: "title", label: "promotionTitle" },
    { id: "memo", label: "promotionMemo" },
];

export const IMPORT_PROMOTION_SAMPLE_DATA = Array.from({ length: 9 }).map(
    (item, idx) => {
        const res: any = { sku: `8888880${idx}` };
        PRICE_SET_TYPES.forEach((type) => {
            res[type.id] = 1000;
        });
        return res;
    }
);
