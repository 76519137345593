import React from "react";
import ImportScreen from "../ImportScreen";
import { ImportType } from "../constant";

const ImportCustomer = () => {
    return (
        <div>
            <ImportScreen type={ImportType.CUSTOMER} />
        </div>
    );
};

export default ImportCustomer;
