import {
    Button,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import React, { Fragment } from "react";
import PromotionAPI from "../../../api/queries/promotion";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadButton from "../../../common/upload/UploadButton";
import {
    PromotionUpdateDrawerType,
    usePromotionDetailContext,
} from "../../../context/promotion";
import Messages from "../../../languages/Messages";
import { PromotionStatus } from "../../../api/hooks";

export interface IPromotionDetailInfoProps {
    [key: string]: any;
}

const PromotionDetailInfo: React.FC<IPromotionDetailInfoProps> = ({ id }) => {
    const { promotion, setOpenUpdateDrawer } = usePromotionDetailContext();
    const { memo, documents, updatedAt } = promotion || {};

    return (
        <Fragment>
            <div className="p-4 border bg-yellowLight ">
                <div className="flex-center-y justify-between">
                    <h5 className="mb-3">{Messages.memo}</h5>
                    {promotion?.status !== PromotionStatus.Ended && (
                        <Button
                            iconName="edit"
                            size="small"
                            variant="outline"
                            className="rounded-2"
                            onClick={() => {
                                setOpenUpdateDrawer({
                                    open: true,
                                    type: PromotionUpdateDrawerType.MEMO,
                                });
                            }}
                        />
                    )}
                </div>
                <div className="text-sm">{memo}</div>
            </div>
            <div className="p-4 border">
                <div className="flex-center-y justify-between">
                    <h5 className="mb-3">{Messages.documents}</h5>
                    {promotion?.status !== PromotionStatus.Ended && (
                        <Button
                            iconName="edit"
                            size="small"
                            variant="outline"
                            className="rounded-2"
                            onClick={() => {
                                setOpenUpdateDrawer({
                                    open: true,
                                    type: PromotionUpdateDrawerType.DOCUMENT,
                                });
                            }}
                        />
                    )}
                </div>
                <UploadButton
                    key={`${documents?.length}_${updatedAt}`}
                    variant="square"
                    disabled
                    defaultFiles={
                        (documents || [])?.map((item) => {
                            return {
                                id: StringUtils.getUniqueID(),
                                imageData: item,
                                fileUrl: item,
                            };
                        }) ?? []
                    }
                />
            </div>
        </Fragment>
    );
};

export default PromotionDetailInfo;
