import { Spin } from "antd";
import { Icon, ObjectUtils } from "d-react-components";
import { forEach, map } from "lodash";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Messages from "../../languages/Messages";
import { getImportCustomer } from "./FileValidationAndImport";
import { useImportContext } from "./context";

const ImportResult = () => {
    const { formImport, importData } = useImportContext();
    const { importType, importFields, fileImport } = formImport.values;
    //detailed error by items
    const [errorValidation, setErrorValidation] = useState([]);
    const [importSuccess, setImportSuccess] = useState([]);
    const [hasError, setHasError] = useState(false);
    //general errors from server
    const [errorImport, setErrorImport] = useState<any>([]);
    const [loadingImport, setLoadingImport] = useState(true);
    const { apiImport } = importData;

    useEffect(() => onImportProduct(), [fileImport.data]);

    const onImportProduct = () => {
        const mulCodeList = ObjectUtils.sliceArrayToMui(fileImport.data, 300);
        const promisesList: any[] = [];
        const importResult: any = { errors: [], success: [] };
        const serverErrors: any[] = [];

        const getImportUserAPI = async (importData: any) => {
            return new Promise((resolve, reject) => {
                const body = {
                    customers: map(importData, (data) =>
                        getImportCustomer(data, importFields)
                    ),
                };

                apiImport({
                    typeOfImport: importType,
                    input: body,
                }).then(
                    (res: any) => {
                        const errors = res?.data?.data?.errors || [];
                        const success = res?.data?.data?.success || [];
                        importResult.errors.push(...errors);
                        importResult.success.push(...success);
                        resolve({ errors, success });
                    },
                    (err: any) => {
                        serverErrors.push(err.message);
                        reject(err);
                    }
                );
            });
        };

        forEach(mulCodeList, (chunk) => {
            promisesList.push(() => getImportUserAPI(chunk));
        });

        const runSerial = (promises: any) => {
            let result = Promise.resolve();
            promises.forEach((promise: any) => {
                result = result.then(
                    () => typeof promise === "function" && promise()
                );
            });
            return result;
        };

        setHasError(false);
        runSerial(promisesList)
            .then((res: any) => {
                setErrorValidation(importResult?.errors);
                setImportSuccess(importResult?.success);
                setLoadingImport(false);
            })
            .catch((err: any) => {
                setErrorImport(serverErrors);
                setHasError(true);
                setLoadingImport(false);
            });
    };

    const getErrorItemsData = () => {
        const header = ["Branch", "Customer No", "Messages"];
        const dataResult = [header];
        errorValidation.forEach((validation: any) => {
            // forEach(validation ?? [], (item) => {
            dataResult.push([
                validation.branchId,
                validation.customerNo,
                validation.messages.join(", "),
            ]);
            // });
        });
        return dataResult;
    };
    const getSuccessItemsData = () => {
        const header = [Messages.customerNo];
        const dataResult = [header];
        forEach(importSuccess ?? [], (data: any) => {
            dataResult.push([data?.customerNo]);
        });
        return dataResult;
    };

    if (loadingImport) {
        return (
            <div className="p-4 flex-center">
                <Spin />
            </div>
        );
    }
    return (
        <div className="p-4 border mb-4">
            {!hasError && errorValidation?.length === 0 && (
                <div className="d-flex mt-3">
                    <Icon
                        name="check_circle_outline"
                        className="mr-3 text-success"
                    />
                    <div>
                        {fileImport.data?.length - errorValidation?.length}{" "}
                        {Messages.customersHaveBeenImportedSuccessfully}
                    </div>
                    <CSVLink
                        data={getSuccessItemsData()}
                        filename={`Import_customer_result_${new Date().valueOf()}`}
                    >
                        <div className="ml-1 text-primary">
                            {Messages.downloadLog}
                        </div>
                    </CSVLink>
                </div>
            )}

            {!hasError && errorValidation?.length > 0 && (
                <div className="d-flex mt-3">
                    <Icon name="error" className="mr-3 text-error" />
                    <div>
                        {errorValidation?.length}{" "}
                        {Messages.customersWereFailedToImport}
                    </div>
                    <CSVLink
                        data={getErrorItemsData()}
                        filename={`Import_customer_result_${new Date().valueOf()}`}
                    >
                        <div className="ml-1 text-primary">
                            {Messages.downloadLog}
                        </div>
                    </CSVLink>
                </div>
            )}

            {hasError && errorImport?.length > 0 && (
                <div className="d-flex mt-3">
                    <Icon name="error" className="mr-3 text-error" />
                    {errorImport?.join(", ")}
                </div>
            )}
        </div>
    );
};

export default ImportResult;
