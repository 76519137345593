import React from "react";
import ListDataButton, {
    IListDataButtonProps,
} from "../../../common/button/ListDataButton";
import { IBranch } from "../../../interfaces/branch";
import { BranchSolidItem } from "./BranchSolidSelect";

export interface IBranchListDataButtonProps
    extends Partial<IListDataButtonProps<IBranch>> {
    [key: string]: any;
}

const BranchListDataButton: React.FC<IBranchListDataButtonProps> = ({
    data,
    ...rest
}) => {
    const renderItem = () => {};
    return (
        <ListDataButton
            data={data || []}
            renderItem={(item) => {
                return <BranchSolidItem branch={item} hideAddress />;
            }}
            {...rest}
        />
    );
};

export default BranchListDataButton;
