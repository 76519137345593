import { useContext } from "react";
import { ProductCreateContext } from "../../../context/product";
import ProductConfigMedicineForm from "../share/ProductConfigMedicineForm";

const ProductCreateMedicineConfig = () => {
    const { productForm } = useContext(ProductCreateContext);
    return <ProductConfigMedicineForm configForm={productForm} />;
};

export default ProductCreateMedicineConfig;
