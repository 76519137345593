import { map } from "lodash";
import moment from "moment";
import { fmtMSS, getSecondFromTime } from "../utils/Utils";
import { IBranch } from "./branch";
import { IUser } from "./user";

export interface IScheduleTime {
    from: string;
    to: string;
}

export interface ITemporarySchedule {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: IUser;
    day: string;
    user: IUser;
    branch: IBranch;
    schedule: IScheduleTime;
}

export const mapScheduleWorkingTimeFromServer = (values: any) => ({
    ...values,
    date: moment(values?.day, "DD/MM/YYYY"),
    from: moment(fmtMSS(values?.schedule?.start), "HH:mm"),
    to: moment(fmtMSS(values?.schedule?.end), "HH:mm"),
});

export const mapScheduleWorkingTimeToServer = (input: any) => ({
    branch: input?.branch?.id,
    scheduleDate: input?.date,
    schedule: {
        start: getSecondFromTime(input?.from),
        end: getSecondFromTime(input?.to),
    },
});

export const mapTemporaryScheduleToServer = (input: any) => {
    const { user, schedules } = input;
    return {
        userId: user.id,
        inputs: map(schedules, mapScheduleWorkingTimeToServer),
    };
};
