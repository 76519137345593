import React, { useContext, useMemo, useState } from "react";
import {
    Button,
    DateInput,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { MaterialRequestContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import { IMaterialPurchaseRequest } from "../../../interfaces/material";
import { MATERIAL_REQUEST_STATUSES } from "../../../constant/material";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import UploadButton from "../../../common/upload/UploadButton";
import {
    MaterialRequestGeneralInfoSchema,
    mapRequestToServer,
} from "../create/MaterialRequestCreate";
import {
    MaterialPurchaseRequestStatus,
    useUpdateMaterialPurchaseRequestMutation,
} from "../../../api/hooks";
import InputTextForm from "../../../common/input/InputTextForm";
import Gallery from "../../../common/gallery/Gallery";

const MaterialRequestDetailGeneralInformation = () => {
    const { request, reloadRequest } = useContext(MaterialRequestContext);
    const [showEditModal, setShowEditModal] = useState(false);

    //@ts-ignore
    const DETAILS_KEY: IRowsKey<IMaterialPurchaseRequest>[] = useMemo(
        () => [
            {
                id: "materialPurchaseRequestNo",
                label: Messages.id,
            },
            {
                id: "branch",
                label: Messages.branch,
                renderContent: ({ data }) => {
                    return data?.name ?? "N/A";
                },
            },
            {
                id: "expectedDateOfArrival",
                label: Messages.expectedDateOfArrival,
                renderContent: ({ data }) => {
                    return !data ? "N/A" : TimeUtils.convertMiliToDate(data);
                },
            },
            ...(request?.remark
                ? [
                      {
                          id: "remark",
                          label: Messages.remark,
                      },
                  ]
                : []),
            {
                id: "createByAdmin",
                label: Messages.createdBy,
                renderContent: ({ data }) => (
                    <UserAvatarName user={data} size="xx-small" />
                ),
            },
            {
                id: "createdAt",
                label: Messages.createdAt,
                renderContent: ({ data }) => {
                    return !data
                        ? "N/A"
                        : TimeUtils.convertMiliToDateTime(data);
                },
            },
            ...(request?.confirm
                ? [
                      {
                          id: "confirm",
                          label:
                              request.status ===
                              MaterialPurchaseRequestStatus.Cancelled
                                  ? Messages.cancelledBy
                                  : Messages.approvedBy,
                          renderContent: ({ data }: any) => (
                              <UserAvatarName user={data?.by} size="xx-small" />
                          ),
                      },
                      {
                          id: "confirm",
                          label:
                              request.status ===
                              MaterialPurchaseRequestStatus.Cancelled
                                  ? Messages.cancelledAt
                                  : Messages.approvedAt,
                          renderContent: ({ data }: any) => {
                              return !data
                                  ? "N/A"
                                  : TimeUtils.convertMiliToDateTime(data?.at);
                          },
                      },
                      {
                          id: "remark",
                          label:
                              request.status ===
                              MaterialPurchaseRequestStatus.Cancelled
                                  ? Messages.cancelledRemark
                                  : Messages.approvalRemark,
                      },
                  ]
                : []),
            ...(request?.attachments?.length
                ? [
                      {
                          id: "attachments",
                          label: Messages.attachments,
                          renderContent: ({ data }: any) => (
                              <Gallery gallery={data} itemClassName="w-16 h-16 mr-2" />
                          ),
                      },
                  ]
                : []),
            {
                id: "status",
                label: Messages.status,
                renderContent: ({ data }) => (
                    <ViewLabelStatus
                        status={data}
                        listStatus={MATERIAL_REQUEST_STATUSES}
                        getLabel={(item) => (Messages as any)[item.label]}
                    />
                ),
            },
            {
                id: "updatedAt",
                label: Messages.lastUpdated,
                renderContent: ({ data }) => {
                    return !data
                        ? "N/A"
                        : TimeUtils.convertMiliToDateTime(data);
                },
            },
        ],
        [request]
    );

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <ViewRowInterchange
                keyList={DETAILS_KEY}
                dataSource={request}
                variant="background"
            />
            {showEditModal && (
                <ModalEditInfoContent
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    onSave={() => {
                        reloadRequest();
                        setShowEditModal(false);
                    }}
                />
            )}
        </div>
    );
};

const ModalEditInfoContent = ({ open, onClose, onSave }: any) => {
    const { request } = useContext(MaterialRequestContext);
    const [updateMaterialRequest] = useUpdateMaterialPurchaseRequestMutation();
    const { expectedDateOfArrival, remark, attachments, materials } =
        request as IMaterialPurchaseRequest;
    const requestForm = useFormik<any>({
        initialValues: {
            expectedDateOfArrival: expectedDateOfArrival
                ? moment(expectedDateOfArrival)
                : undefined,
            remark,
            attachments: attachments?.map((item) => ({ fileUrl: item })) ?? [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MaterialRequestGeneralInfoSchema,
        onSubmit: (values: any) => {
            const payload = mapRequestToServer(values);
            Progress.show(
                {
                    method: updateMaterialRequest,
                    params: [
                        {
                            variables: {
                                payload,
                                id: request?.id as string,
                            },
                        },
                    ],
                },
                (res: any) => {
                    Notifications.showSuccess(Messages.updateSuccess);
                    onSave && onSave();
                }
            );
        },
    });
    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = requestForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="600px"
            title={Messages.generalInformation}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <DateInput
                    label={Messages.expectedDateOfArrival}
                    value={formValues.expectedDateOfArrival}
                    onChange={(value) =>
                        setFieldValue("expectedDateOfArrival", value)
                    }
                    className="mb-3"
                    error={formErrors.expectedDateOfArrival as string}
                />
                <InputTextForm
                    form={requestForm}
                    keyData="remark"
                    multiple
                    className="mb-3"
                />
                <div className="mb-3">
                    <label>{Messages.attachment}</label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) => {
                            requestForm.setFieldValue("attachments", file);
                        }}
                        defaultFiles={formValues?.attachments ?? []}
                    />
                    <ViewTextError error={formErrors.attachments} />
                </div>
            </div>
        </Drawer>
    );
};

export default MaterialRequestDetailGeneralInformation;
