import {
    AwesomeTableComponent,
    Button,
    DialogComponent,
    DialogManager,
    IColumnsProps,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import React, { useRef, useState } from "react";
import { useParams } from "react-router";
import TemporaryScheduleAPI from "../../../api/queries/temporary-schedule";
import TableAction from "../../../common/TableActions";
import { mapScheduleWorkingTimeToServer } from "../../../interfaces/temporary-schedule";
import Messages from "../../../languages/Messages";
import AddScheduleWorkingTimeDrawer from "../share/AddScheduleWorkingTimeDrawer";

export const TABLE_ACTIONS = [
    { id: "update", label: "update" },
    { id: "delete", label: "delete" },
];

const ScheduleDetailWorkingTime = () => {
    const { userId } = useParams<any>();
    const [openCrud, setOpenCrud] = useState({
        visible: false,
        schedule: null,
    });

    const tableRef = useRef<any>();

    const columns: IColumnsProps = [
        {
            title: Messages.date,
            dataIndex: "date",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.from,
            dataIndex: "from",
            render: TimeUtils.toTime,
        },
        {
            title: Messages.to,
            dataIndex: "to",
            render: TimeUtils.toTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (config: any) => {
                return (
                    <TableAction
                        dataSource={TABLE_ACTIONS}
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, config)
                        }
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, schedule: any) => {
        switch (actionId) {
            case "update":
                setOpenCrud({ visible: true, schedule });
                break;
            case "delete":
                onDeleteSchedule(schedule);
                break;
            default:
        }
    };

    const source = (paging: any) => {
        if (!userId) return Promise.resolve([]);

        return TemporaryScheduleAPI.temporarySchedules(userId, {
            page: paging?.pageIndex,
            limit: paging?.pageSize,
        });
    };

    const onDeleteSchedule = (schedule: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: TemporaryScheduleAPI.removeSchedule,
                        params: [schedule?.id],
                    },
                    () => {
                        Notifications.showSuccess(Messages.removeSuccess);
                        tableRef.current.refresh();
                    }
                )
        );
    };

    const onAddWorkingTime = (values: any) => {
        const inputs = mapScheduleWorkingTimeToServer(values);
        Progress.show(
            {
                method: TemporaryScheduleAPI.createSchedule,
                params: [{ userId, inputs }],
            },
            () => {
                tableRef.current.refresh();
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const onUpdateWorkingTime = (values: any) => {
        const input = mapScheduleWorkingTimeToServer(values);
        Progress.show(
            {
                method: TemporaryScheduleAPI.updateSchedule,
                params: [values?.id, input],
            },
            () => {
                tableRef.current.refresh();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.detailOfSchedule}</label>
                <Button
                    onClick={() =>
                        setOpenCrud({ visible: true, schedule: null })
                    }
                    className=""
                >
                    {Messages.add}
                </Button>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                source={source}
                className="mt-3"
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openCrud?.visible && (
                <AddScheduleWorkingTimeDrawer
                    schedule={openCrud?.schedule}
                    open={openCrud?.visible}
                    onClose={() =>
                        setOpenCrud({ visible: false, schedule: null })
                    }
                    onSave={(input) => {
                        openCrud.schedule
                            ? onUpdateWorkingTime(input)
                            : onAddWorkingTime(input);
                    }}
                />
            )}
        </div>
    );
};

export default ScheduleDetailWorkingTime;
