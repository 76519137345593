import { Select as SelectAntd } from "antd";
import ClassNames from "classnames";
import { Icon, ObjectUtils, Select } from "d-react-components";
import { debounce, find, isArray, isEmpty, map, uniqBy } from "lodash";
import {
    forwardRef,
    ForwardRefRenderFunction,
    Fragment,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useSearchDoctorOrderForAdminLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { IDoctorOrder } from "../../../interfaces/doctor-order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const { Option } = SelectAntd;

export interface IDoctorOrderSelectProps {
    className?: string;
    placeholder?: string;
    value?: IDoctorOrder | IDoctorOrder[];
    multiple?: boolean;
    onChange: (value: IDoctorOrderSelectProps["value"]) => void;
    variant?: "select" | "search";
    showLabel?: boolean;
}

export interface IDoctorOrderSearchOptions {
    showEmptyNote?: boolean;
    filter?: any;
    onFoundCallback?: (props: any) => void;
}

export interface IDoctorOrderSelectMethods {
    onSearch: (text: string, options?: IDoctorOrderSearchOptions) => void;
}

const DoctorOrderSelect: ForwardRefRenderFunction<
    IDoctorOrderSelectMethods,
    IDoctorOrderSelectProps
> = (
    {
        value = [],
        multiple,
        className,
        onChange,
        showLabel = true,
        variant = "select",
        placeholder = Messages.pleaseSearchAndSelect,
        ...selectProps
    },
    ref
) => {
    const navigate = useNavigate();
    const selectClass = ClassNames(`customer-select--${variant}`, className);
    const [doctorOrderList, setDoctorOrderList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getDoctorOrderList] = useSearchDoctorOrderForAdminLazyQuery();
    const [searchValue, setSearchValue] = useState<any>();
    const [displayEmptyNote, setDisplayEmptyNote] = useState(false);

    useImperativeHandle(ref, () => ({
        onSearch: (text, option) => {
            setSearchValue(text);
            onChangeTextSearch(text, option);
        },
    }));

    const onChangeTextSearch = debounce(
        (textSearch, option?: IDoctorOrderSearchOptions) => {
            setDisplayEmptyNote(false);
            if (isEmpty(textSearch)) {
                setDoctorOrderList([]);
            } else {
                const { filter, showEmptyNote, onFoundCallback } = option || {};
                getDoctorOrderList({
                    variables: {
                        paginate: {
                            page: 1,
                            limit: 50,
                            search: textSearch?.trim?.() ?? "",
                            sort: {
                                status: -1,
                            },
                            ...(filter || {}),
                        },
                    },
                }).then((res: any) => {
                    const doctorOrderRes = res?.data?.data?.data ?? [];
                    const selectedValue = isArray(value) ? value : [value];
                    const filterDoctorOrder = map(
                        [...selectedValue, ...doctorOrderRes],
                        (item) => ({
                            ...item,
                            id: item.id,
                            doctorOrderNo: item.doctorOrderNo,
                        })
                    );
                    const uniqData = uniqBy(
                        filterDoctorOrder,
                        (item) => item.id
                    );
                    if (showEmptyNote && isEmpty(uniqData)) {
                        setDisplayEmptyNote(true);
                    }
                    if (
                        onFoundCallback &&
                        typeof onFoundCallback === "function"
                    ) {
                        onFoundCallback(uniqData);
                    }
                    setDoctorOrderList(uniqData);
                });
            }
        },
        500
    );

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(doctorOrderList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(doctorOrderList, id));
    };

    const doctorOrderValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    const labelContent = (item: IDoctorOrder) => {
        return (
            <div className={`p-2 flex items-center relative `}>
                <div className="ml-2 flex-column">
                    <div className="flex-center-y">
                        <h5 className="mr-1">{item.doctorOrderNo}</h5>
                    </div>
                </div>
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: item?.id,
                    })}
                    target="_blank"
                >
                    <div
                        style={{ pointerEvents: "all" }}
                        className="p-0 absolute right-[10px] bottom-[5px]"
                    >
                        <Icon name="visibility" />
                    </div>
                </AppLink>
            </div>
        );
    };

    const renderSelectLabel = (item: IDoctorOrder) => {
        return labelContent(item);
    };

    const renderSelectTag = (tagProps: any) => {
        let doctorOrder: any = value;
        if (multiple) {
            doctorOrder = find(
                value,
                (item: any) => item.id === tagProps.value
            );
        }

        return (
            <div className="text-white bg-primary px-3 py-1 mr-2 my-1 small d-flex">
                {doctorOrder?.doctorOrderNo}
                <Icon
                    name="clear"
                    onClick={tagProps.onClose}
                    className="text-white small ml-2 cursor-pointer"
                />
            </div>
        );
    };

    const content = (
        <Select
            onSearch={(text) => {
                setSearchValue(text);
                onChangeTextSearch(text);
            }}
            className={selectClass}
            label={showLabel ? Messages.doctorOrder : ""}
            dataSource={doctorOrderList}
            value={doctorOrderValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={placeholder}
            showSearch
            searchValue={searchValue}
            filterOption={false}
            getLabel={renderSelectLabel}
            // for multiple select
            tagRender={renderSelectTag}
            //for single select
            optionLabelProp="label"
            {...selectProps}
        />
    );
    if (variant === "search" && displayEmptyNote) {
        return (
            <Fragment>
                {content}
                <div className="small text-secondary mt-3">
                    {Messages.emptySearchCustomerNote}
                </div>
            </Fragment>
        );
    }
    return content;
};

export default forwardRef(DoctorOrderSelect);
