import React, { useContext } from "react";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const OrderBranch = () => {
    const { order } = useContext(OrderDetailContext);
    return (
        <div className="border p-3 mb-3 mb-3">
            <h5 className="font-semibold mb-2">{Messages.branchWarehouse}</h5>
            <p className="text-base mb-0">{order?.store?.name}</p>
        </div>
    );
};

export default OrderBranch;
