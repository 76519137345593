import classNames from "classnames";
import {
    Avatar,
    AwesomeListComponent,
    Button,
    InputText,
    Notifications,
    TimeUtils,
} from "d-react-components";
import _, { forEach, isEmpty, map, replace } from "lodash";
import { useEffect, useRef, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
    useCreateCommentMutation,
    useGetActivityListLazyQuery,
} from "../../api/hooks";

import { IActivity } from "../../interfaces/activity";
import Messages from "../../languages/Messages";
// import ButtonFileUpload from "../button/ButtonFileUpload";
import Image from "../Image";
import UploadButton from "../upload/UploadButton";

interface IActivityItem {
    activity: IActivity;
    onClickImage: any;
}

export const ActivityItem = ({ activity, onClickImage }: IActivityItem) => {
    const getMessage = () => {
        let { message } = activity;

        forEach(Object.keys(activity?.inputKeys), (key) => {
            message = replace(
                message,
                `:${key}`,
                JSON.stringify(activity?.inputKeys?.[`${key}`])
            );
        });
        return message;
    };

    return (
        <div className="d-flex mt-3 border-bottom pb-3">
            <Avatar
                src={activity?.createByAdmin?.avatar}
                text={activity?.createByAdmin?.firstName}
                size="x-small"
            />

            <div className="ml-3 w-100">
                <div className="d-flex align-center-center w-100">
                    <div className="flex-grow-1">
                        <div
                            className="text "
                            dangerouslySetInnerHTML={{
                                __html: `<span class="font-semibold">${
                                    activity?.createByAdmin?.nickName ??
                                    "System"
                                }</span> ${getMessage()}`,
                            }}
                        />
                    </div>

                    <div
                        className="small d-flex justify-content-end"
                        style={{ minWidth: 120 }}
                    >
                        {TimeUtils.toDateTime(activity.createdAt)}
                    </div>
                </div>
                <div className="d-flex mt-2">
                    {map(activity?.fileUrl ?? [], (item) => (
                        <Image
                            className="image-reg-large mr-2"
                            src={item}
                            onClick={() => {
                                onClickImage && onClickImage();
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

interface IActivityView {
    setRef?: (ref: any) => void;
    renderSubTitle?: () => any;
    className?: string;
    activityRef: string;
    activityRefId: string;
}

const ActivityView = ({
    setRef,
    renderSubTitle,
    className,
    activityRef,
    activityRefId,
}: IActivityView) => {
    const [imageToView, setImageToView] = useState<any[]>([]);
    const [attachments, setAttachments] = useState<any[]>([]);

    const [commentText, setCommentText] = useState("");

    const activityList = useRef<any>(null);
    const buttonUploadRef = useRef<any>(null);
    const [createComment, { loading }] = useCreateCommentMutation();
    const [getActivityList] = useGetActivityListLazyQuery();

    useEffect(() => {
        setRef && setRef(activityList.current);
    }, []);

    const renderItem = (activity: IActivity) => {
        return (
            <ActivityItem
                activity={activity}
                onClickImage={() => {
                    setImageToView(activity.fileUrl);
                }}
            />
        );
    };

    const onClickSend = _.throttle(() => {
        if (loading) {
            return;
        }

        createComment({
            variables: {
                ref: activityRef as any,
                refId: activityRefId,
                payload: {
                    comment: commentText,
                    fileUrl: map(attachments, (item) => item.fileUrl),
                },
            },
        }).then(() => {
            setAttachments([]);
            setCommentText("");
            activityList.current.refresh();
            buttonUploadRef.current && buttonUploadRef.current.refresh();
            Notifications.showSuccess(Messages.addCommentSuccess);
        });
    }, 1000);

    const source = (paging: any) => {
        return getActivityList({
            variables: {
                ref: activityRef as any,
                refId: activityRefId as any,
                paginate: {
                    page: paging?.pageIndex,
                    limit: paging?.pageSize,
                    sort: { createdAt: -1 },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const transformer = (res: any) => res?.data?.data?.data;

    const classNameContainer = classNames(
        className,
        "card-container p-4 w-100"
    );

    return (
        <div className={classNameContainer}>
            <h5>
                {Messages.activities}
                {renderSubTitle && renderSubTitle()}
            </h5>
            <div className="mt-3">
                <div className="d-flex">
                    <InputText
                        className="w-100"
                        placeholder={Messages.leaveAComment}
                        multiple
                        value={commentText}
                        onChange={(event) => setCommentText(event.target.value)}
                    />
                </div>
                <div className="d-flex mt-3 justify-content-end">
                    <UploadButton
                        onChange={setAttachments}
                        maxFiles={1}
                        className="d-flex"
                        ref={buttonUploadRef}
                    />
                    <Button
                        className="ml-3 "
                        onClick={onClickSend}
                        iconName="send"
                        disabled={isEmpty(commentText)}
                    >
                        {Messages.send}
                    </Button>
                </div>
            </div>
            <ModalGateway>
                {imageToView && imageToView.length > 0 ? (
                    <Modal onClose={() => setImageToView([])}>
                        <Carousel
                            views={map(imageToView, (file) => ({
                                source: file,
                                caption: "",
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <AwesomeListComponent
                ref={(ref) => {
                    activityList.current = ref;
                }}
                source={source}
                transformer={transformer}
                renderItem={renderItem}
                styleContainer={{ overflowY: "auto" }}
                variant="load-more"
            />
        </div>
    );
};

export default ActivityView;
