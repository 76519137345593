import {
    Button,
    Header,
    InputText,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import React from "react";
import { generatePath, useNavigate } from "react-router";
import { useCreateBranchForAdminMutation } from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import {
    BRANCH_STATUSES,
    STOCK_ADJUSTMENT_STATUSES,
} from "../../../constant/branch";
import { PERMISSION } from "../../../constant/user";
import { BranchSchema } from "../../../formschema/branch";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const BranchCreate = () => {
    const [createBranch] = useCreateBranchForAdminMutation();
    const navigate = useNavigate();

    const branchForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BranchSchema,
        onSubmit: (values) => {
            onCreateBranch(values);
        },
    });

    const onCreateBranch = (input: any) => {
        const body = {
            variables: {
                payload: { ...input },
            },
        };

        Progress.show(
            {
                method: createBranch,
                params: [body],
            },
            (resp: any) => {
                const newUser = resp?.data?.createBranchForAdmin?.data;
                navigate(
                    generatePath(Path.BRANCH_DETAIL, { branchId: newUser.id })
                );
                Notifications.showSuccess(Messages.createBranchSuccessfully);
            }
        );
    };

    return (
        <div>
            <Header
                showCancel={false}
                title={Messages.newBranch}
                className="sticky top-0"
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.BRANCH.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => branchForm.handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-3 bg-white">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <InputText
                            label={Messages.name}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "name",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.name}
                            error={branchForm?.errors?.name as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.code}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "code",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.code}
                            error={branchForm?.errors?.code as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.pickupCode}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "pickupCode",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.pickupCode}
                            error={branchForm?.errors?.pickupCode as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.brandTaxId}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "brandTaxId",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.brandTaxId}
                            error={branchForm?.errors?.brandTaxId as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.phone}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "phone",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.phone}
                            error={branchForm?.errors?.phone as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.status}
                            value={branchForm.values?.status}
                            error={branchForm.errors?.status}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={BRANCH_STATUSES}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                branchForm.setFieldValue("status", value)
                            }
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.manualStockAdjustment}
                            value={branchForm.values?.manualStockAdjustment}
                            error={branchForm.errors?.manualStockAdjustment}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={STOCK_ADJUSTMENT_STATUSES}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                branchForm.setFieldValue(
                                    "manualStockAdjustment",
                                    value
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BranchCreate;
