/* eslint-disable eqeqeq */
import { ViewRowInterchange, IRowsKey, TimeUtils } from "d-react-components";
import { find } from "lodash";
import { useContext } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { VOUCHER_TYPE, VOUCHER_TYPES } from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const GENERAL_KEYS_CASH: IRowsKey<IVoucher>[] = [
    {
        id: "code",
        label: Messages.code,
    },
    {
        id: "type",
        label: Messages.type,
        renderContent: ({ data }) => {
            const voucherType = find(VOUCHER_TYPES, { id: data });
            return Messages[voucherType?.label as string];
        },
    },
    {
        id: "effectiveDate",
        label: Messages.effectiveDate,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
    {
        id: "expiryDate",
        label: Messages.expiryDate,
        renderContent: ({ data }) =>
            data ? TimeUtils.toDateTime(data) : Messages.never,
    },
    {
        id: "limit",
        label: Messages.usageType,
        renderContent: ({ data }) =>
            data == 1 ? Messages.single : Messages.multiple,
    },
    {
        id: "value",
        label: Messages.initialSpendingCap,
        renderContent: ({ data }) => {
            return (
                <CurrencyFormat
                    value={data}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            );
        },
    },
    {
        id: "totalUsedValues",
        label: Messages.balanceAmount,
        renderContent: ({ data, item }) => {
            const leftValue = (item?.value ?? 0) - data;
            return (
                <CurrencyFormat
                    value={leftValue}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            );
        },
    },
    {
        id: "order",
        label: Messages.orderRef,
        renderContent: ({ data }) =>
            data?.orderNo ? (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: data?.id ?? "",
                    })}
                    target="_blank"
                >
                    {data?.orderNo}
                </AppLink>
            ) : (
                "N/A"
            ),
    },
];

const GENERAL_KEYS_GIFT: IRowsKey<IVoucher>[] = [
    {
        id: "code",
        label: Messages.code,
    },
    {
        id: "type",
        label: Messages.type,
        renderContent: ({ data }) => {
            const voucherType = find(VOUCHER_TYPES, { id: data });
            return Messages[voucherType?.label as string];
        },
    },
    {
        id: "effectiveDate",
        label: Messages.effectiveDate,
        renderContent: ({ data }) => TimeUtils.toDateTime(data),
    },
    {
        id: "expiryDate",
        label: Messages.expiryDate,
        renderContent: ({ data }) =>
            data ? TimeUtils.toDateTime(data) : Messages.never,
    },
    {
        id: "order",
        label: Messages.orderRef,
        renderContent: ({ data }) =>
            data?.orderNo ? (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: data?.id ?? "",
                    })}
                    target="_blank"
                >
                    {data?.orderNo}
                </AppLink>
            ) : (
                "N/A"
            ),
    },
];

const VoucherDetailGeneralInfo = () => {
    const { voucher } = useContext(VoucherDetailContext);

    const { type } = voucher;
    return (
        <div className="border p-3 mt-3">
            <label>{Messages.generalInfo}</label>
            <ViewRowInterchange
                keyList={
                    type === VOUCHER_TYPE.CASH_VOUCHER
                        ? GENERAL_KEYS_CASH
                        : GENERAL_KEYS_GIFT
                }
                dataSource={voucher}
                variant="background"
            />
        </div>
    );
};

export default VoucherDetailGeneralInfo;
