import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { isEmpty } from "lodash";
import { useContext } from "react";
import UserAvatarName from "../../../../common/avatar/UserAvatarName";
import { STOCK_REQUEST_STATUSES } from "../../../../constant/stock-request";
import { StockRequestDetailContext } from "../../../../context/stock-request";
import Messages from "../../../../languages/Messages";

const StockRequestGeneral = () => {
    const { stockRequest } = useContext(StockRequestDetailContext);

    let GENERAL_KEYS: IRowsKey<any>[] = [
        {
            id: "requestNo",
            label: "ID",
        },
        {
            id: "branch",
            label: Messages.branchWarehouse,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "branch",
            label: Messages.branchWarehouseCode,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "remark",
            label: Messages.remark,
            renderContent: ({ data }) => data || "N/A",
        },
        {
            id: "status",
            label: Messages.status,
            renderContent: ({ data }) => (
                <ViewLabelStatus
                    status={data}
                    listStatus={STOCK_REQUEST_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
    ];

    const { confirmation } = stockRequest;

    if (!isEmpty(confirmation?.createByAdmin)) {
        GENERAL_KEYS = [
            ...GENERAL_KEYS,
            {
                id: "confirmation",
                label: Messages.approvedBy,
                renderContent: ({ data }) => (
                    <UserAvatarName user={data?.createByAdmin} size="x-small" />
                ),
            },
            {
                id: "confirmation",
                label: Messages.approvedAt,
                renderContent: ({ data }) =>
                    data?.at ? TimeUtils.toDateTime(data?.at) : "N/A",
            },
            {
                id: "confirmation",
                label: Messages.approvalRemark,
                renderContent: ({ data }) => data?.remark || "N/A",
            },
        ];
    }

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={stockRequest}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default StockRequestGeneral;
