import { gql } from "@apollo/client";
import API from "../API";
import {
    F_INVENTORIES,
    F_PRODUCT_STOCK_HISTORIES,
} from "../fragments/inventory";

export const checkProductStock = gql`
    query checkProductStock($payload: SearchAdminProductStockRequest!) {
        data: searchProductStockForAdmin(payload: $payload) {
            data {
                id
                hold
                quantity
            }
        }
    }
`;

export const listProductWithStock = gql`
    ${F_INVENTORIES}
    query listProductWithStock($payload: SearchProductWithStockRequest!) {
        data: listProductWithStockForAdmin(payload: $payload) {
            ...F_INVENTORIES
        }
    }
`;

export const listProductStockHistory = gql`
    ${F_PRODUCT_STOCK_HISTORIES}
    query listProductStockHistory(
        $product: String!
        $paginate: ListAdminProductStockHistoryRequest!
    ) {
        data: listProductStockHistoryForAdmin(
            paginate: $paginate
            product: $product
        ) {
            ...F_PRODUCT_STOCK_HISTORIES
        }
    }
`;

const InventoryAPI = {
    checkProductStock: async (payload: {
        branch?: string[];
        product?: string[];
    }) =>
        API.query({
            query: checkProductStock,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),
    list: async (payload: any) =>
        API.query({
            query: listProductWithStock,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    history: async (payload: any) =>
        API.query({
            query: listProductStockHistory,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
};

export default InventoryAPI;
