import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
    useGetBranchListLazyQuery,
    useGetBranchListQuery,
} from "../../../api/hooks";
import { IBranch } from "../../../interfaces/branch";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";

export interface IBranchSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
    hideLabel?: boolean;
}

const BranchSearchSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IBranchSelect<IBranch>) => {
    const [branchList, setBranchList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getBranchList] = useGetBranchListLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setBranchList([]);
        } else {
            getBranchList({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 15,
                        search: textSearch,
                    },
                },
            }).then((res: any) => {
                const branchRes = res?.data?.listBranchForAdmin?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterBranch = map(
                    [...selectedValue, ...branchRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqBranch = uniqBy(filterBranch, (item) => item.id);
                setBranchList(uniqBranch);
            });
        }
    }, 500);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(branchList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(branchList, id));
    };

    const branchValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.branchWarehouse}
            dataSource={branchList}
            getLabel={(item) => item?.name}
            value={branchValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            filterOption={false}
            {...selectProps}
        />
    );
};

const BranchSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    hideLabel,
    ...selectProps
}: IBranchSelect<IBranch>) => {
    const [branchList, setBranchList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const { data } = useGetBranchListQuery({
        variables: {
            paginate: {
                page: 1,
                limit: 100,
                search: "",
            },
        },
    });

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(branchList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(branchList, id));
    };

    const branchValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    useEffect(() => {
        if (!isEmpty(data)) {
            setBranchList(data?.listBranchForAdmin?.data ?? []);
        }
    }, [data]);

    return (
        <Select
            className={className}
            label={hideLabel ? undefined : Messages.branchWarehouse}
            dataSource={branchList}
            getLabel={(item) => item?.name}
            value={branchValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default BranchSelect;
