import { find, isEmpty, pick } from "lodash";
import {
    FeeType,
    FeeUnit,
    JobCostTemplate,
    JobCostType,
    ServiceSurgeryType,
} from "../api/hooks";
import { PurchaseType } from "../constant/quotation";
import { getMinutesFromHhMm } from "../utils/Utils";
import IUser, { JobType } from "./user";
import { IProduct } from "./product";
import { IMaterial } from "./material";
import Messages from "../languages/Messages";
import { JOB_COST_TEMPLATES } from "../constant/service";

export interface IServiceCategory {
    id: string;
    updatedAt: string;
    createdAt: string;
    name: string;
    categoryNo: string;
    status: string;
    description: string;
}

export interface IJobCostFee {
    amount?: number;
    amountSecondary?: number;
    applySecondary?: boolean;
    feeType?: FeeType;
    unit?: FeeUnit;
    unitQty?: number;
    isFlexible?: boolean;
    triggerThresholdQty?: number;
}

export interface IJobCost {
    id?: string;
    primaryFee?: IJobCostFee;
    purchaseType?: PurchaseType;
    role?: JobType;
    secondaryFee?: IJobCostFee;
    type?: JobCostType;
}

export interface IServiceMaterial {
    quantity: number;
    hasPermissionAdjustment: string;
    material: IMaterial;
}

export interface IServiceMedicine {
    hasPermissionAdjustment: string;
    quantity: number;
    product: IProduct;
}

export interface IServiceAddon {
    quantity: number;
    product: IProduct;
    hasPermissionAdjustment: string;
}

export interface IServiceConfiguration {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: IUser;
    name: string;
    serviceNo: string;
    status: string;
    procedureTime: string;
    description: string;
    jobCostTemplate: JobCostTemplate;
    surgeryType: ServiceSurgeryType;
    jobCost: IJobCost[];
    category: IServiceCategory;
    materialPreset: IServiceMaterial[];
    servicePreset: IServiceAddon[];
    medicinePreset: IServiceMedicine[];
    addonPreset: IServiceAddon[];
    defaultSaleCommission?: number;
    isModifiedJobCost?: boolean;
}

export interface IJobCostPreset {
    id: JobCostTemplate;
    presets: IJobCost[];
}

export const mapServiceCategoryToServer = (input: any) => {
    const { name, status, description } = input;
    return {
        name,
        status,
        description,
    };
};

export const mapJobCostFeeToServer = (fee?: IJobCostFee) => {
    if (isEmpty(fee)) {
        return null;
    }
    const {
        amount,
        amountSecondary,
        applySecondary,
        unit,
        unitQty,
        feeType,
        isFlexible,
        triggerThresholdQty,
    } = fee || {};
    return {
        amount: parseFloat(amount as any),
        amountSecondary: amountSecondary
            ? parseFloat(amountSecondary as any)
            : 0,
        applySecondary,
        unitQty: unitQty ? parseInt(unitQty as any, 10) : null,
        unit,
        feeType,
        isFlexible,
        triggerThresholdQty: triggerThresholdQty
            ? parseFloat(triggerThresholdQty as any)
            : null,
    };
};

export const mapServiceJobCostToServer = (input: IJobCost) => {
    const { id, type, purchaseType, role, primaryFee, secondaryFee } = input;
    return {
        id,
        type,
        purchaseType,
        role,
        primaryFee: mapJobCostFeeToServer(primaryFee),
        secondaryFee: mapJobCostFeeToServer(secondaryFee),
    };
};

export const mapServiceConfigToServer = (input: any) => {
    const {
        name,
        status,
        description,
        procedureTime,
        category,
        jobCostTemplate,
        surgeryType,
        defaultSaleCommission,
    } = input;
    return {
        name,
        status,
        description,
        jobCostTemplate,
        surgeryType,
        procedureTime: parseFloat(`${getMinutesFromHhMm(procedureTime)}`),
        category: category?.id,
        defaultSaleCommission,
    };
};

export const getJobCostTemplateLabelById = (data: JobCostTemplate) => {
    return (
        Messages?.[
            find(JOB_COST_TEMPLATES, (item) => item?.id === data)?.label ?? ""
        ] ?? "N/A"
    );
};
