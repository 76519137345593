import {
    AwesomeTableComponent,
    IColumnsProps,
    InputText,
    Select,
} from "d-react-components";
import { useContext, useRef } from "react";
// import UploadAvatar from "../../../common/upload/UploadAvatar";
import { find, isNaN, uniqBy } from "lodash";
import { generatePath } from "react-router";
import { useGetServiceConfigurationsLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import {
    ServiceCommissionType,
    ServiceCommissionTypes,
} from "../../../constant/user";
import { UserCrudContext } from "../../../context/user";
import { IServiceConfiguration } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const UserCrudCommission = () => {
    const { userForm } = useContext(UserCrudContext);
    const formValues = userForm.values ?? {};
    const formErrors = userForm.errors ?? {};
    const [getServiceConfigurations] = useGetServiceConfigurationsLazyQuery();
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");

    const onChangeCommissionValue = (service: any, value: any) => {
        const commissionValue = isNaN(value) ? 0 : value;
        const commissionService = [
            { value: commissionValue, service },
            ...(formValues.commissionService ?? []),
        ];

        const commissionServices = uniqBy(
            commissionService,
            (item) => item.service?.serviceNo
        );

        userForm.setFieldValue("commissionService", commissionServices);
    };

    const renderInputRate = (serviceConfig: IServiceConfiguration) => {
        let value = serviceConfig?.defaultSaleCommission ?? 0;
        const serviceCommission = find(
            formValues.commissionService,
            (item) => item.service.serviceNo === serviceConfig.serviceNo
        );

        if (serviceCommission) {
            value = serviceCommission?.value;
        }
        return (
            <InputText
                value={value}
                suffix="%"
                onChange={(event) =>
                    onChangeCommissionValue(
                        serviceConfig,
                        parseFloat(event?.target.value)
                    )
                }
            />
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.SERVICE_CONFIGURATION_DETAIL, {
                        configId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.rate,
            dataIndex: "",
            render: renderInputRate,
        },
    ];

    const source = (pagingData: any) => {
        return getServiceConfigurations({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const renderServicesTable = () => {
        if (
            formValues.commissionServiceType === ServiceCommissionType.DEFAULT
        ) {
            return <div />;
        }
        return (
            <div>
                <AwesomeTableComponent
                    source={source}
                    transformer={(res) => {
                        return res?.data?.data?.data ?? [];
                    }}
                    columns={columns}
                    className="mt-3"
                />
            </div>
        );
    };

    return (
        <div className="bg-white p-4">
            <div className="row">
                <div className="col-12">
                    <Select
                        label={Messages.commission}
                        value={formValues?.commissionServiceType}
                        error={formErrors?.commissionServiceType}
                        className="mb-3"
                        dataSource={ServiceCommissionTypes}
                        getLabel={(item) => (Messages as any)[item?.label]}
                        onChange={(value) =>
                            userForm.setFieldValue(
                                "commissionServiceType",
                                value
                            )
                        }
                    />
                </div>
                <div className="col-12">{renderServicesTable()}</div>
            </div>
        </div>
    );
};

export default UserCrudCommission;
