/* eslint-disable no-await-in-loop */
import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, groupBy, join, map, reduce } from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { PAYMENTS_METHOD, PAYMENTS_METHODS } from "../../constant/payment";
import { EXPORT_MODE } from "../../constant/report";
import Messages from "../../languages/Messages";
import { exportToCSV } from "../../utils/file";
import BranchSelect from "../branch/share/BranchSelect";
import ReportFooter from "./ReportFooter";

export const EXPORT_STEPS = [
    { id: "input", title: "input" },
    { id: "export", title: "export" },
];

export const INIT_EXPORT_FORM = {
    branch: [],
    timeRange: [],
};

const ReportCashier = () => {
    const [mode, setMode] = useState(EXPORT_MODE.INPUT);
    const [cashierDataTable, setCashierDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            const payload = {
                branch: map(values.branch, (item) => item.id),
                from: values?.timeRange?.[0]?.valueOf(),
                to: values?.timeRange?.[1]?.valueOf(),
            };
            onExportReport(payload);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onExportReport = (payload: any) => {
        Progress.show(
            { method: ReportAPI.cashier, params: [payload] },
            async (res) => {
                const orderList = await onProcessData(res);
                exportCSV(orderList);
            }
        );
    };

    const getCashierDataFromOrders = (orders: any[]) => {
        const totalInvoice = reduce(orders, (sum, item) => sum + item.total, 0);
        const totalSettleAmount = reduce(
            orders,
            (sum, item) => sum + item.settleAmount,
            0
        );
        const totalUnSettleAmount = reduce(
            orders,
            (sum, item) => sum + (item.unSettleAmount ?? 0),
            0
        );
        const totalRemainingAmount = reduce(
            orders,
            (sum, item) => sum + (item.remainingAmount ?? 0),
            0
        );
        const paymentTotal = reduce(
            orders,
            (sum, item) => sum + parseFloat(item.paymentTotal ?? 0),
            0
        );

        return {
            totalInvoice,
            totalSettleAmount,
            totalUnSettleAmount,
            totalRemainingAmount,
            paymentTotal,
        };
    };

    const exportCSV = (dataList: any[]) => {
        const exportData: any = [];

        const dataGroupByBranch = groupBy(dataList, (item) => item.store.id);
        const branchIds = Object.keys(dataGroupByBranch);

        for (let index = 0; index < branchIds.length; index += 1) {
            const branchId = branchIds[index];
            const branchOrders = dataGroupByBranch[branchId];
            const dateRangeText = join(
                map(formValues.timeRange, (item) => item.format("DD/MM/YYYY")),
                " - "
            );

            const branchCashierData = getCashierDataFromOrders(branchOrders);

            exportData.push({
                "Created Date": dateRangeText,
                SO: "",
                CN: "",
                RecieptNo: "",
                "Payment Method": "",
                CardNumber: "",
                CardType: "",
                "Income Type": "",
                "Bank Of Transfer ": "",
                BankNumber: "",
                Amount: branchCashierData.paymentTotal,
                "Paid by": "",
                Branch: branchOrders[0]?.store?.name,
                "Total invoice": branchCashierData.totalInvoice,
                "Settle amount": branchCashierData.totalSettleAmount,
                "Unsettle amount (Deposit)":
                    branchCashierData.totalUnSettleAmount,
                "Balance of invoice": branchCashierData.totalRemainingAmount,
            });

            const orderGroupByDate = groupBy(branchOrders, (item) =>
                moment(item.createdAt).format("DD/MM/YYYY")
            );

            const dateList = Object.keys(orderGroupByDate);
            for (
                let dateIndex = 0;
                dateIndex < dateList.length;
                dateIndex += 1
            ) {
                const date = dateList[dateIndex];
                const dateOrders = orderGroupByDate[date];
                const dateCashierData = getCashierDataFromOrders(dateOrders);

                exportData.push({
                    "Created Date": date,
                    SO: "All SO",
                    CN: "",
                    RecieptNo: "",
                    "Payment Method": "",
                    CardNumber: "",
                    CardType: "",
                    "Income Type": "",
                    "Bank Of Transfer ": "",
                    BankNumber: "",
                    Amount: dateCashierData.paymentTotal,
                    "Paid by": "",
                    Branch: dateOrders[0]?.store?.name,
                    "Total invoice": dateCashierData.totalInvoice,
                    "Settle amount": dateCashierData.totalSettleAmount,
                    "Unsettle amount (Deposit)":
                        dateCashierData.totalUnSettleAmount,
                    "Balance of invoice": dateCashierData.totalRemainingAmount,
                });

                for (
                    let orderIndex = 0;
                    orderIndex < dateOrders.length;
                    orderIndex += 1
                ) {
                    const order = dateOrders[orderIndex];

                    const {
                        receiptNo,
                        paymentMethod,
                        cardType,
                        cardNumber,
                        incomeType,
                        bankTransfer,
                        bankTransferNo,
                        paymentTotal,
                        paidByText,
                        customer,
                    } = order;

                    exportData.push({
                        "Created Date": "",
                        SO: order?.orderNo,
                        CN: customer?.customerNo,
                        RecieptNo: receiptNo,
                        "Payment Method": paymentMethod,
                        CardNumber: cardNumber,
                        CardType: cardType,
                        "Income Type": incomeType,
                        "Bank Of Transfer ": bankTransfer,
                        BankNumber: bankTransferNo,
                        Amount: paymentTotal,
                        "Paid by": paidByText,
                        Branch: order?.store?.name,
                        "Total invoice": order.total,
                        "Settle amount": order.settleAmount,
                        "Unsettle amount (Deposit)": order.unSettleAmount ?? 0,
                        "Balance of invoice": order.remainingAmount,
                    });
                }
            }
        }

        exportToCSV(exportData, `Report_Cashier_By_Order`);
    };

    const onPreview = () => {
        const payload = {
            branch: map(formValues.branch, (item) => item.id),
            from: formValues?.timeRange?.[0]?.valueOf(),
            to: formValues?.timeRange?.[1]?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.cashier, params: [payload] },
            async (res) => {
                const orderList = await onProcessData(res);
                onLoadDataToView(orderList);
            }
        );
    };

    const onProcessData = async (orderList: any) => {
        const orderResults = [];
        for (let index = 0; index < orderList.length; index += 1) {
            const order = orderList[index];
            // const paymentList = await PaymentAPI.paymentsByRef(order?.id);
            const paymentList = order?.payments ?? [];
            const receiptNo = join(
                map(paymentList, (payment) => payment.paymentNo),
                ", "
            );

            const paymentMethod = join(
                map(paymentList, (payment) => {
                    if (
                        payment.paymentMethod ===
                        PAYMENTS_METHOD.CREDIT_CARD_POS
                    ) {
                        return "POS Card Device";
                    }
                    const method = find(
                        PAYMENTS_METHODS,
                        (item) => item.value === payment.paymentMethod
                    );
                    return Messages[method?.label as any];
                }),
                ", "
            );

            const paymentCreditCard = filter(
                paymentList,
                (item) =>
                    item.paymentMethod === PAYMENTS_METHOD.CREDIT_CARD ||
                    item.paymentMethod === PAYMENTS_METHOD.CREDIT_CARD_POS
            );

            const paymentBankTransfer = filter(
                paymentList,
                (item) => item.paymentMethod === PAYMENTS_METHOD.BANK_TRANSFER
            );

            const cardType = join(
                map(paymentCreditCard, (payment) => {
                    return payment?.creditCard?.type;
                }),
                ", "
            );
            const cardNumber = join(
                map(paymentCreditCard, (payment) => {
                    return payment?.creditCard?.number;
                }),
                ", "
            );

            const incomeType = join(
                map(paymentList, (payment) => {
                    const method = find(
                        PAYMENTS_METHODS,
                        (item) => item.value === payment.paymentMethod
                    );
                    return method?.type;
                }),
                ", "
            );
            const bankTransfer = join(
                map(paymentBankTransfer, (payment) => {
                    return payment?.proof?.[0]?.bank?.bankName;
                }),
                ", "
            );
            const bankTransferNo = join(
                map(paymentBankTransfer, (payment) => {
                    return payment?.proof?.[0]?.bank?.accountNumber;
                }),
                ", "
            );

            const paymentTotal = reduce(
                paymentList,
                (sum, payment) => sum + payment.total,
                0
            );
            const paidByText = `${paymentMethod} ${bankTransfer} ${paymentTotal}`;
            orderResults.push({
                ...order,
                receiptNo,
                paymentMethod,
                cardType,
                cardNumber,
                incomeType,
                bankTransfer,
                bankTransferNo,
                paymentTotal,
                paidByText,
            });
        }

        return orderResults;
    };

    const onLoadDataToView = (dataList: any) => {
        const tableData: any = [];

        const dataGroupByBranch = groupBy(dataList, (item) => item.store.id);
        const branchIds = Object.keys(dataGroupByBranch);

        for (let index = 0; index < branchIds.length; index += 1) {
            const branchId = branchIds[index];
            const branchOrders = dataGroupByBranch[branchId];
            const dateRangeText = join(
                map(formValues.timeRange, (item) => item.format("DD/MM/YYYY")),
                " - "
            );

            const branchCashierData = getCashierDataFromOrders(branchOrders);
            const tableDataItem: any = {
                id: branchOrders[0]?.store?.name,
                createdDate: dateRangeText,
                orderNo: "",
                branchName: branchOrders[0]?.store?.name,
                total: branchCashierData.totalInvoice,
                settleAmount: branchCashierData.totalSettleAmount,
                unsettleAmount: branchCashierData.totalUnSettleAmount,
                remainingAmount: branchCashierData.totalRemainingAmount,
                paymentTotal: branchCashierData.paymentTotal,
                children: [],
            };
            const orderGroupByDate = groupBy(branchOrders, (item) =>
                moment(item.createdAt).format("DD/MM/YYYY")
            );

            const dateList = Object.keys(orderGroupByDate);
            for (
                let dateIndex = 0;
                dateIndex < dateList.length;
                dateIndex += 1
            ) {
                const date = dateList[dateIndex];
                const dateOrders = orderGroupByDate[date];
                const dateCashierData = getCashierDataFromOrders(dateOrders);
                const dateDataItem: any = {
                    id: date,

                    createdDate: date,
                    orderNo: "All SO",
                    branchName: dateOrders[0]?.store?.name,
                    total: dateCashierData.totalInvoice,
                    settleAmount: dateCashierData.totalSettleAmount,
                    unsettleAmount: dateCashierData.totalUnSettleAmount,
                    remainingAmount: dateCashierData.totalRemainingAmount,
                    paymentTotal: branchCashierData.paymentTotal,
                    children: [],
                };

                for (
                    let orderIndex = 0;
                    orderIndex < dateOrders.length;
                    orderIndex += 1
                ) {
                    const order = dateOrders[orderIndex];
                    const {
                        receiptNo,
                        paymentMethod,
                        cardType,
                        cardNumber,
                        incomeType,
                        bankTransfer,
                        bankTransferNo,
                        paymentTotal,
                        paidByText,
                        orderNo,
                        store,
                        total,
                        settleAmount,
                        unSettleAmount,
                        remainingAmount,
                        customer,
                    } = order;
                    dateDataItem.children.push({
                        id: orderNo,
                        createdDate: "",
                        orderNo,
                        branchName: store?.name,
                        total,
                        settleAmount,
                        unsettleAmount: unSettleAmount ?? 0,
                        remainingAmount,
                        customerNo: customer?.customerNo,
                        receiptNo,
                        paymentMethod,
                        cardType,
                        cardNumber,
                        incomeType,
                        bankTransfer,
                        bankTransferNo,
                        paymentTotal,
                        paidByText,
                    });
                }

                tableDataItem.children.push(dateDataItem as any);
            }

            tableData.push(tableDataItem);
        }

        setCashierDataTable(tableData);
        setMode(EXPORT_MODE.VIEW);
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    isRangePicker
                    label={Messages.dateRange}
                    value={
                        map(formValues?.timeRange, (time) =>
                            moment(time)
                        ) as any
                    }
                    onChange={(value) =>
                        exportForm.setFieldValue("timeRange", value)
                    }
                />
                <BranchSelect
                    className={classNameInput}
                    value={formValues?.branch}
                    onChange={(value) =>
                        exportForm.setFieldValue("branch", value)
                    }
                    multiple
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.createdDate,
            dataIndex: "createdDate",
            width: 400,
        },
        {
            title: "SO",
            dataIndex: "orderNo",
        },
        {
            title: "CN",
            dataIndex: "customerNo",
        },
        {
            title: Messages.receiptNo,
            dataIndex: "receiptNo",
        },
        {
            title: Messages.paymentMethod,
            dataIndex: "paymentMethod",
        },
        {
            title: Messages.cardNumber,
            dataIndex: "cardNumber",
            width: 150,
        },
        {
            title: Messages.cardType,
            dataIndex: "cardType",
        },
        {
            title: Messages.incomeType,
            dataIndex: "incomeType",
        },
        {
            title: Messages.bankTransfer,
            dataIndex: "bankTransfer",
        },
        {
            title: Messages.bankNumber,
            dataIndex: "bankTransferNo",
        },
        {
            title: Messages.amount,
            dataIndex: "paymentTotal",
            render: StringUtils.moneyFormat,
        },
        {
            title: Messages.paidBy,
            dataIndex: "paidByText",
            width: 300,
        },
        {
            title: Messages.branch,
            dataIndex: "branchName",
        },
        {
            title: Messages.totalInvoice,
            dataIndex: "total",
            render: StringUtils.moneyFormat,
        },
        {
            title: Messages.settleAmount,
            dataIndex: "settleAmount",
            render: StringUtils.moneyFormat,
            titleTooltip: "Order with fully paid",
        },
        {
            title: Messages.unsettleAmount,
            dataIndex: "unsettleAmount",
            render: StringUtils.moneyFormat,
            width: 200,
            titleTooltip: "Order with partially paid",
        },
        {
            title: Messages.balanceOfInvoice,
            dataIndex: "remainingAmount",
            render: StringUtils.moneyFormat,
            width: 200,
            titleTooltip: "Remaining amount",
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                baseColumnProps={{ width: 100 }}
                columns={columns}
                dataSource={cashierDataTable}
                // source={() => Promise.resolve(cashierDataTable)}
                className=""
                expandable={{
                    defaultExpandAllRows: true,
                }}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (mode) {
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.cashierReportByOrder}
                    </div>

                    {/* <div className="bg-dark px-3 py-1">
                        <small className="text-white">{`Step ${mode + 1} of ${
                            EXPORT_STEPS.length
                        }`}</small>
                    </div> */}
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={mode === EXPORT_MODE.INPUT}
                onClickPrevious={() => setMode(EXPORT_MODE.INPUT)}
                onClickExport={() => exportForm.handleSubmit()}
                onClickView={onPreview}
                exportDisabled={
                    !formValues?.branch?.length ||
                    !formValues?.timeRange?.length
                }
            />
            {mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportCashier;
