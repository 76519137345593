import React from "react";

interface ImportState {
    step: any;
    formImport: any;
    importFieldSource: any[];
    importData?: any;
    stepIndex?: any;
    setStepIndex?: any;
    type?: any;
}

const importState: ImportState = {
    step: null,
    formImport: null,
    importFieldSource: [],
    importData: null,
    stepIndex: null,
    setStepIndex: null,
    type: null,
};

export const ImportContext = React.createContext(importState);
export const useImportContext = () => React.useContext(ImportContext);
