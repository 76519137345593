import classNames from "classnames";
import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { find } from "lodash";
import React, { useContext } from "react";
import { EQUIPMENT_CONDITIONS, EQUIPMENT_VISIBILITIES } from "../../../constant/equipment";
import { EquipmentDetailContext } from "../../../context/equipment";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";

const GENERAL_KEYS: IRowsKey<IEquipment>[] = [
    {
        id: "equipmentNo",
        label: Messages.id,
    },
    {
        id: "name",
        label: Messages.name,
    },
    {
        id: "category",
        label: Messages.category,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "model",
        label: Messages.model,
    },
    {
        id: "serialNo",
        label: Messages.serialNo,
    },
    {
        id: "branch",
        label: Messages.branch,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "visibility",
        label: Messages.visibility,
        renderContent: ({ data }) => {
            const statusItem = find(EQUIPMENT_VISIBILITIES, {
                id: data,
            })?.label;
            return statusItem ? Messages[statusItem] : "N/A";
        },
    },
    {
        id: "condition",
        label: Messages.condition,
        renderContent: ({ data }) => {
            const statusItem = find(EQUIPMENT_CONDITIONS, {
                id: data,
            })?.label;
            return statusItem ? Messages[statusItem] : "N/A";
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
    {
        id: "updatedAt",
        label: Messages.updatedAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
];
const EquipmentGeneralInformation = () => {
    const { equipment } = useContext(EquipmentDetailContext);
    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
            </div>
            <ViewRowInterchange
                keyList={GENERAL_KEYS}
                dataSource={equipment}
                variant="background"
            />
        </div>
    );
};

export default EquipmentGeneralInformation;
