import { useFormik } from "formik";
import { JsonParam, ObjectParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";
import { mapBookingFilterToQueryParams } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import BookingFilterForm from "../share/BookingFilterForm";

interface IBookingTableFilterDrawer {
    open: boolean;
    onClose: () => void;
}

const BookingTableFilterDrawer = ({
    open,
    onClose,
}: IBookingTableFilterDrawer) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const params = mapBookingFilterToQueryParams(values);
            setFilter(params);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
        >
            <BookingFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default BookingTableFilterDrawer;
