import {
    AwesomeTableComponent,
    Button,
    HeaderTable,
    IColumnsProps,
    useFirstTime,
} from "d-react-components";
import { debounce, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { JsonParam, useQueryParam } from "use-query-params";
import MaterialInventoryAPI from "../../../api/queries/material-inventory";
import AppLink from "../../../common/AppLink";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import InventoryMaterialHistoryDrawer from "./InventoryMaterialHistoryDrawer";
import MaterialInventoryFilterDrawer from "./MaterialInventoryFilterDrawer";

export interface IInventoryTableProps {
    [key: string]: any;
}

const MaterialInventoryTable: React.FC<IInventoryTableProps> = ({ id }) => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter] = useQueryParam<any>("filter", JsonParam);
    const [openHistoryModal, setOpenHistoryModal] = useState<{
        open: boolean;
        material?: any;
    }>({ open: false });
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_DETAIL, {
                        materialId: item?.id ?? "",
                    })}
                >
                    {name}
                </AppLink>
            ),
        },

        {
            title: Messages.sku,
            dataIndex: "sku",
        },
        {
            title: Messages.trackingUnitOnHandCurrentWh,
            dataIndex: "currentQuantity",
            render: (quantity, material) =>
                `${quantity} ${material?.trackingUnit?.name}`,
        },
        {
            title: Messages.trackingUnitOnHandAllWh,
            dataIndex: "quantity",
            render: (quantity, material) =>
                `${quantity} ${material?.trackingUnit?.name}`,
        },
        {
            title: Messages.trackingUnitOnOrder,
            dataIndex: "totalQuantityOnPurchase",
            render: (quantity, material) =>
                `${quantity} ${material?.trackingUnit?.name}`,
        },

        {
            title: Messages.history,
            dataIndex: "",
            width: 50,
            render: (material: any) => {
                return (
                    <Button
                        className="w-auto"
                        iconName="history"
                        variant="trans"
                        onClick={() =>
                            setOpenHistoryModal({ open: true, material })
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const { branch, status, category } = filter ?? {};

        const filterParam = {
            // branch: map(branch, (item) => item.id),
            // category: map(category, (item) => item.id),
            // status,
            search: searchRef.current ?? "",
        };
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return MaterialInventoryAPI.list(input);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.materialInventory}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
            {openFilter && (
                <MaterialInventoryFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
            {openHistoryModal?.open && openHistoryModal?.material && (
                <InventoryMaterialHistoryDrawer
                    material={openHistoryModal.material}
                    open={openHistoryModal.open}
                    onClose={() => setOpenHistoryModal({ open: false })}
                />
            )}
        </div>
    );
};

export default MaterialInventoryTable;
