/* eslint-disable react/jsx-no-constructed-context-values */
import {
    Checkbox,
    InputText,
    Notifications,
    TabBar,
    TimeUtils,
} from "d-react-components";
import { useFormikContext } from "formik";
import { chain, filter, find, isUndefined, map } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import CustomerAPI from "../../../api/queries/customer";
import Drawer from "../../../common/Drawer";
import { OrderCreditContext } from "../../../context/order";
import { QuotationCreateContext } from "../../../context/quotation";
import { ICredit } from "../../../interfaces/credit";
import Messages from "../../../languages/Messages";

const AllCredits = () => {
    const { cartValue } = useContext(QuotationCreateContext);
    const {
        creditList,
        selectedCreditList,
        setSelectedCreditList,
        searchText,
    } = useContext(OrderCreditContext);

    const onChangeCredit = (v: ICredit) => {
        const isChecked = !isUndefined(
            find(selectedCreditList, { creditNo: v.creditNo })
        );

        if (isChecked) {
            setSelectedCreditList(
                filter(
                    selectedCreditList,
                    (credit) => credit.creditNo !== v.creditNo
                )
            );
        } else {
            setSelectedCreditList([...selectedCreditList, v]);
        }
    };

    useEffect(() => {
        setSelectedCreditList(map(cartValue?.credits, (v) => v.credit));
    }, []);

    const displayItems = useMemo(() => {
        return (
            chain(creditList)
                .filter((credit) =>
                    credit?.creditNo
                        ?.toLowerCase()
                        .includes(searchText?.toLowerCase())
                )
                .orderBy("createdAt", "desc")
                .value() ?? []
        );
    }, [searchText, creditList]);

    return (
        <>
            <div className="flex items-center mb-3">
                <div className="flex-1 flex items-center">
                    <Checkbox
                        checked={
                            displayItems.length === selectedCreditList.length
                        }
                        onChange={() => {
                            if (
                                displayItems.length ===
                                selectedCreditList.length
                            ) {
                                setSelectedCreditList([]);
                            } else {
                                setSelectedCreditList(displayItems);
                            }
                        }}
                    />
                    <span className="ml-3">{Messages.selectAll}</span>
                </div>
                <span className="text-primary">
                    {selectedCreditList.length} {Messages.selected}
                </span>
            </div>
            {displayItems?.map((credit, index) => (
                <div className="flex items-center" key={index}>
                    <Checkbox
                        checked={
                            !isUndefined(
                                find(selectedCreditList, {
                                    creditNo: credit.creditNo,
                                })
                            )
                        }
                        onChange={() => onChangeCredit(credit)}
                        className="mr-3"
                        disabled={!credit?.value}
                    />
                    <CreditItem credit={credit as any} />
                </div>
            ))}
        </>
    );
};

const CreditItem = ({ credit }: { credit: ICredit }) => {
    return (
        <div className="drop-shadow-lg bg-white w-100 mb-3 p-3 relative">
            <div className="absolute block bg-primary text-xs top-0 right-0 text-white min-w-[20px] h-[20px] flex justify-content-center items-center px-1">
                x1
            </div>
            <div className="flex">
                <div className="flex justify-content-center items-center w-[36px] h-[36px]">
                    <img
                        src="/icons/voucher-green.svg"
                        className="w-[30px] h-[30px]"
                    />
                </div>
                <div className="flex flex-column ml-3">
                    <span className="text-primary">{credit.creditNo}</span>
                    <div className="text-sm text-gray-400">
                        <span>{Messages.creditVoucher}</span> |{" "}
                        <span>
                            {Messages.balance}:{" "}
                            <CurrencyFormat
                                value={
                                    (Number(credit.value) || 0) -
                                    (Number(credit.totalUsedValues) || 0)
                                }
                                thousandSeparator
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </span>{" "}
                        |{" "}
                        {credit.expiryDate ? (
                            <span>
                                {Messages.expiresAt}:{" "}
                                {TimeUtils.toDateTime(credit.expiryDate)}
                            </span>
                        ) : (
                            <span>{Messages.neverExpires}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TABS = [
    {
        id: "allCredits",
        label: Messages.customerAllCredits,
        component: <AllCredits />,
    },
];

const CreditVoucherDrawer = ({ open, onClose, onSave }: any) => {
    const [searchText, setSearchText] = useState("");
    const { cartValue, checkCartValue } = useContext(QuotationCreateContext);
    const { values } = useFormikContext<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [creditList, setCreditList] = useState<ICredit[]>([]);
    const [selectedCreditList, setSelectedCreditList] = useState<ICredit[]>([]);

    const handleAddCredit = () => {
        const appliedCredit =
            cartValue?.credits?.map((v) => v?.credit?.id) ?? [];
        const selectedCreditCodes = selectedCreditList.map((v) => v?.id) ?? [];
        checkCartValue(values, null, true, [
            ...appliedCredit,
            ...selectedCreditCodes,
        ]).then(() => {
            onClose();
        });
    };

    useEffect(() => {
        if (values.customer && values?.customer?.[0]?.id) {
            const customerId = values?.customer?.[0]?.id;
            CustomerAPI.creditList(
                customerId,
                { pageIndex: 1, pageSize: 500 },
                { status: ["AVAILABLE"], excludeExpired: true }
            )
                .then((resp: any) => {
                    setCreditList(resp?.data?.data?.data ?? ([] as any));
                })
                .catch((e: any) => {
                    Notifications.showError(e);
                });
        }
    }, []);

    return (
        <Drawer
            open={open}
            title={Messages.apply}
            onClose={() => onClose()}
            onSave={handleAddCredit}
            destroyOnClose
            size="auto"
            width="600px"
        >
            <OrderCreditContext.Provider
                value={{
                    creditList,
                    selectedCreditList,
                    setSelectedCreditList,
                    searchText,
                }}
            >
                <div className="bg-white sticky top-0 z-10 pt-2 pb-2 px-2">
                    <div className="px-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />
                    </div>
                    {tab.id !== "guestVouchers" && (
                        <div className="px-3 pt-3">
                            <InputText
                                value={searchText}
                                onChange={(e) =>
                                    setSearchText(e?.target?.value)
                                }
                                placeholder={Messages.search}
                            />
                        </div>
                    )}
                </div>
                <div className="pt-0 pb-4 px-4">
                    <div className="mt-3">{tab.component}</div>
                </div>
            </OrderCreditContext.Provider>
        </Drawer>
    );
};

export default CreditVoucherDrawer;
