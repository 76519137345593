import { TimeUtils } from "d-react-components";
import { find } from "lodash";
import { useContext, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import {
    MaterialPurchasePaymentStatus,
    PaymentMethod,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { MATERIAL_PAYMENT_STATUSES } from "../../../constant/material";
import { PERMISSION } from "../../../constant/user";
import { PaymentContext } from "../../../context/payment";
import { IPayment } from "../../../interfaces/payment";
import { IPaymentMethod } from "../../../interfaces/payment-method";
import Messages from "../../../languages/Messages";
import PaymentConfirmButton from "../../order/share/payment/PaymentConfirmButton";
import ChangePaymentMethodDrawer from "./ChangePaymentMethodDrawer";
import UploadPaymentInfoView from "./UploadPaymentInfo";

const PaymentInfoItem = ({
    payment,
    index,
    onChangePaymentInfo,
}: {
    payment: IPayment;
    index: number;
    onChangePaymentInfo: any;
}) => {
    const {
        total,
        paymentNo,
        paymentMethod,
        status,
        confirmation,
        creditNote,
    } = payment;
    const { paymentMethodsList, ref: order } = useContext(PaymentContext);
    const [showChangeMethod, setShowChangeMethod] = useState(false);
    const isReject = status === MaterialPurchasePaymentStatus.Rejected;
    const isApprove = status === MaterialPurchasePaymentStatus.Confirmed;

    const isShowChangePaymentMethod = useMemo(() => {
        if (isApprove || isReject) {
            return false;
        }
        // if (
        //     order?.status &&
        //     order?.status !== ORDER_STATUS.PENDING_PAYMENT &&
        //     order?.status !== ORDER_STATUS.PENDING_CONFIRMATION &&
        //     order?.status !== ORDER_STATUS.PARTIALLY_PAID
        // ) {
        //     return false;
        // }
        return true;
    }, [order]);

    const onClickChangeMethod = () => {
        setShowChangeMethod(true);
    };

    const onMethodChanged = () => {
        onChangePaymentInfo();
        setShowChangeMethod(false);
    };

    const paymentMethodLabel = useMemo(() => {
        const selected = find(paymentMethodsList, {
            value: paymentMethod,
        });
        return selected ? Messages[(selected as IPaymentMethod)?.label] : "N/A";
    }, [paymentMethod, paymentMethodsList]);

    const paymentStatus = useMemo(() => {
        return find(MATERIAL_PAYMENT_STATUSES, { id: payment.status });
    }, [payment]);

    return (
        <div className="border-dashed p-3 my-3 w-100">
            <div className="flex flex-col md:flex-row justify-content-between">
                <div>
                    {total && (
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.total}
                            </span>
                            <CurrencyFormat
                                displayType="text"
                                value={total}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </div>
                    )}
                    {paymentNo && (
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.paymentRef}
                            </span>
                            {paymentNo}
                        </div>
                    )}
                    {creditNote && (
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.creditNote}
                            </span>
                            {Messages.scheduledToBePaidOn}{" "}
                            {TimeUtils.toDateTime(creditNote)}
                        </div>
                    )}
                    {/* {paymentMethod && ( */}
                    <PermissibleRender
                        permission={PERMISSION.MATERIAL_PURCHASE.UPDATE}
                    >
                        <div className="my-1">
                            <span className="font-weight-bold mr-2">
                                {Messages.method}
                            </span>
                            {paymentMethodLabel}
                            {isShowChangePaymentMethod && (
                                <span
                                    className="text-primary cursor-pointer ml-3"
                                    onClick={onClickChangeMethod}
                                >
                                    {Messages.change}
                                </span>
                            )}
                        </div>
                    </PermissibleRender>
                    {/* )} */}
                    <div className="my-1">
                        <span className="font-weight-bold mr-2">
                            {Messages.status}
                        </span>
                        <span style={{ color: (paymentStatus as any)?.color }}>
                            {Messages[(paymentStatus as any)?.label]}
                        </span>
                    </div>
                </div>
                {paymentMethod !== PaymentMethod.Payment_2C2P && (
                    <PaymentConfirmButton
                        payment={payment}
                        onChangePaymentInfo={onChangePaymentInfo}
                    />
                )}
            </div>
            {showChangeMethod && (
                <ChangePaymentMethodDrawer
                    open={showChangeMethod}
                    payment={payment}
                    onClose={() => setShowChangeMethod(false)}
                    onSave={() => onMethodChanged()}
                />
            )}
            <UploadPaymentInfoView
                payment={payment}
                onChangePaymentInfo={onChangePaymentInfo}
            />
            {confirmation?.dateOfReceiving && (
                <div className=" mt-1 text-success">
                    {`${
                        Messages.actualDatePayment
                    }: ${TimeUtils.convertMiliToDateTime(
                        confirmation?.dateOfReceiving
                    )}`}
                </div>
            )}
            {isApprove && (
                <div className="flex flex-col mt-2">
                    <div className="mb-2 flex items-center">
                        <span className="text-bold mr-1">
                            {Messages.confirmBy}:{" "}
                        </span>
                        <UserAvatarName
                            user={confirmation.createByAdmin}
                            size="xx-small"
                            placeholder={Messages.system}
                            showInfo={{ showEmployeeId: false, showOPD: false }}
                        />
                    </div>
                    {confirmation.at && (
                        <div className="mb-2">
                            <span className="text-bold">
                                {Messages.confirmedAt}:{" "}
                            </span>
                            <span>
                                {TimeUtils.toDateTime(confirmation.at)}{" "}
                            </span>
                        </div>
                    )}
                    {confirmation.remark && (
                        <div className="">
                            <span className="text-bold">
                                {Messages.confirmationRemark}:{" "}
                            </span>
                            <div className="mt-1 bg-[#e5ecec] py-3 px-3">
                                {confirmation.remark}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isReject && (
                <div className="flex flex-col mt-2">
                    <div className="mb-2 flex items-center">
                        <span className="text-bold mr-1">
                            {Messages.rejectBy}:{" "}
                        </span>
                        <UserAvatarName
                            user={confirmation.createByAdmin}
                            size="xx-small"
                            placeholder={Messages.system}
                            showInfo={{ showEmployeeId: false, showOPD: false }}
                        />
                    </div>
                    <div className="mb-2">
                        <span className="text-bold">
                            {Messages.rejectedAt}:{" "}
                        </span>
                        <span>{TimeUtils.toDateTime(confirmation.at)} </span>
                    </div>
                    {confirmation.remark && (
                        <div className="">
                            <span className="text-bold">
                                {Messages.remark}:{" "}
                            </span>
                            <div className="mt-1 bg-[#e5ecec] py-3 px-3">
                                {confirmation.remark}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PaymentInfoItem;
