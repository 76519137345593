import * as Yup from "yup";
import { JobType } from "../interfaces/user";
import { RegNumber } from "../screen/user/detail/UserDetailSetting";

export const UserPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Required field!"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "Passwords must match")
        .required("Required field!"),
});

export const UserUpdateInfoSchema = Yup.object().shape({
    // email: Yup.string().required("Required field!"),
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    nickName: Yup.string().required("Required field!"),
    companyId: Yup.string().required("Required field!"),
    status: Yup.string().required("Required field!"),
    username: Yup.string().required("Required field!"),
    feeTemplate1: Yup.string().when("jobType", (jobType, yup) => {
        if (jobType === JobType.THERAPIST) {
            return Yup.string().required("Required field!").matches(RegNumber, {
                message: "Not a number",
            });
        }
        return Yup.string().nullable();
    }),
    feeTemplate3: Yup.string().when("jobType", (jobType, yup) => {
        if (jobType === JobType.THERAPIST) {
            return Yup.string().required("Required field!").matches(RegNumber, {
                message: "Not a number",
            });
        }
        return Yup.string().nullable();
    }),
});

export const UserCreateSchema = UserUpdateInfoSchema.concat(
    UserPasswordSchema as any
);

export const UserRoleSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
});

export const WorkingScheduleSchema = Yup.object().shape({
    date: Yup.mixed().required("Required field!"),
    branch: Yup.string().required("Required field!"),
    start: Yup.string().required("Required field!"),
    end: Yup.string().required("Required field!"),
});
