import { TypeOfIdentity } from "../../../api/hooks";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import {
    AGENCY_STATUSES,
    AGENCY_TYPES,
    AGENCY_TYPE_OF_IDENTITIES,
} from "../../../constant/agency";
import Messages from "../../../languages/Messages";
import CountrySelect from "../../address/share/CountrySelect";

interface IAgencyFormProps {
    agencyForm: any;
    variant?: "create" | "update";
}

const AgencyForm = ({ agencyForm, variant = "update" }: IAgencyFormProps) => {
    const classNameInput = variant === "create" ? "col-6 px-0" : "col-12 mt-3";
    const classNameRow =
        variant === "create"
            ? "w-100 flex gap-5 mt-3"
            : "w-100 flex gap-5 mt-3 flex-wrap";
    const formValues = agencyForm?.values;
    const formErrors = agencyForm?.errors;

    const { typeOfIdentity } = formValues || {};

    return (
        <div className="p-4">
            <div className="col-12">
                <label className="mb-3">{Messages.profilePicture}</label>
                <UploadAvatar
                    value={formValues?.picture}
                    onChange={(file: any) => {
                        agencyForm.setFieldValue("picture", file?.fileUrl);
                    }}
                />
            </div>
            <div className={classNameRow}>
                <InputSelectForm
                    dataSource={AGENCY_TYPES}
                    form={agencyForm}
                    keyData="typeOfAgency"
                    className={classNameInput}
                />
                <InputSelectForm
                    dataSource={AGENCY_TYPE_OF_IDENTITIES}
                    form={agencyForm}
                    keyData="typeOfIdentity"
                    className={classNameInput}
                />
            </div>
            <div className={classNameRow}>
                <CountrySelect
                    value={formValues?.countryOfOrigin}
                    onChange={(value) =>
                        agencyForm.setFieldValue("countryOfOrigin", value)
                    }
                    className={classNameInput}
                    error={formErrors?.countryOfOrigin}
                />
                <InputTextForm
                    form={agencyForm}
                    keyData="name"
                    className={classNameInput}
                    label={
                        typeOfIdentity === TypeOfIdentity.Business
                            ? Messages.companyName
                            : Messages.individualName
                    }
                />
            </div>
            <div className={classNameRow}>
                <InputTextForm
                    form={agencyForm}
                    keyData="codeNumber"
                    className={classNameInput}
                    label={
                        typeOfIdentity === TypeOfIdentity.Business
                            ? Messages.companyRegistrationNo
                            : Messages.citizenId
                    }
                />
                <InputTextForm
                    form={agencyForm}
                    keyData="address"
                    className={classNameInput}
                    label={
                        typeOfIdentity === TypeOfIdentity.Business
                            ? Messages.companyAddress
                            : Messages.residenceAddress
                    }
                />
            </div>
            {typeOfIdentity === TypeOfIdentity.Business && (
                <div className={classNameRow}>
                    <InputTextForm
                        form={agencyForm}
                        keyData="phone"
                        className={classNameInput}
                        label={Messages.companyPhoneNo}
                    />
                    <InputTextForm
                        form={agencyForm}
                        keyData="representativesName"
                        className={classNameInput}
                        label={Messages.representativesName}
                    />
                </div>
            )}
            <div className={classNameRow}>
                <InputTextForm
                    form={agencyForm}
                    keyData={
                        typeOfIdentity === TypeOfIdentity.Business
                            ? "representativesPrimaryPhone"
                            : "phone"
                    }
                    className={classNameInput}
                    label={
                        typeOfIdentity === TypeOfIdentity.Business
                            ? Messages.representativesPrimaryPhone
                            : Messages.phoneNo
                    }
                />
                <InputTextForm
                    form={agencyForm}
                    keyData="representativesAlternatePhone"
                    className={classNameInput}
                    label={
                        typeOfIdentity === TypeOfIdentity.Business
                            ? Messages.representativesAlternatePhone
                            : Messages.alternativePhoneNo
                    }
                />
            </div>
            {variant === "update" && (
                <div className={classNameRow}>
                    <InputSelectForm
                        dataSource={AGENCY_STATUSES}
                        form={agencyForm}
                        keyData="status"
                        className={classNameInput}
                    />
                </div>
            )}
            <div className="w-100 mt-3">
                <InputTextForm
                    multiple
                    form={agencyForm}
                    keyData="remark"
                    className={classNameInput}
                />
            </div>
        </div>
    );
};

export default AgencyForm;
