import { gql } from "@apollo/client";
import { F_PAGINATE } from "./app";

export const F_VEHICLE_BASIC = gql`
    fragment F_VEHICLE_BASIC on AdminVehicleBasic {
        id
        brand
        color
        internalCode
        model
        registrationNo
    }
`;

export const F_VEHICLE = gql`
    fragment F_VEHICLE on VehicleDto {
        id
        brand
        color
        internalCode
        model
        registrationNo
    }
`;

export const F_VEHICLES = gql`
    ${F_VEHICLE}
    ${F_PAGINATE}
    fragment F_VEHICLES on VehiclePaginateResponse {
        data {
            ...F_VEHICLE
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
