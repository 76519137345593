export enum TypeOfTreatment {
    AESTHETIC = "AESTHETIC",
}

export const TREATMENT_TYPES = [
    {
        id: TypeOfTreatment.AESTHETIC,
        label: "aesthetic",
    },
];

export enum PurchaseType {
    NORMAL = "NORMAL",
    AGENCY = "AGENCY",
    MARKETING = "MARKETING",
    COMPLICATION = "COMPLICATION",
    VIP = "VIP",
    STAFF = "STAFF",
}

export const PURCHASE_TYPES = [
    {
        id: PurchaseType.NORMAL,
        label: "normal",
    },
    {
        id: PurchaseType.AGENCY,
        label: "agency",
    },
    {
        id: PurchaseType.MARKETING,
        label: "marketing",
    },
    {
        id: PurchaseType.COMPLICATION,
        label: "complication",
    },
    {
        id: PurchaseType.VIP,
        label: "vip",
    },
    {
        id: PurchaseType.STAFF,
        label: "staff",
    },
];
export const QUOTATION_STATUS = {
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
};
export const QUOTATION_STATUSES = [
    {
        id: QUOTATION_STATUS.ACTIVE,
        label: "active",
        color: "#33B950",
    },
    {
        id: QUOTATION_STATUS.CANCELLED,
        label: "cancelled",
        color: "#ED6969",
    },
];

export enum QuotationAction {
    ADD = "ADD",
    REMOVE = "REMOVE",
}
