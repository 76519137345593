import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { find } from "lodash";
import { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import {
    STOCK_REQUEST_STATUSES,
    STOCK_REQUEST_TYPES,
} from "../../../../constant/stock-request";
import { EquipmentStockRequestDetailContext } from "../../../../context/equipment-stock-request";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";

const EquipmentStockRequestSummary = ({ loading }: { loading?: boolean }) => {
    const { stockRequest } = useContext(EquipmentStockRequestDetailContext);
    const STOCK_REQUEST_KEY: IRowsKey<any>[] = [
        {
            id: "requestNo",
            label: "ID",
        },
        {
            id: "branch",
            label: Messages.branchWarehouse,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "branch",
            label: Messages.branchWarehouseCode,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "type",
            label: Messages.type,
            renderContent: ({ data }) =>
                (Messages as any)[
                    find(STOCK_REQUEST_TYPES, (i) => i?.id === data)?.label ??
                        ""
                ],
        },
        {
            id: "expectedDateOfArrival",
            label: Messages.expectedDateOfArrival,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {stockRequest && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            <Link
                                to={generatePath(
                                    Path.INVENTORY_REQUEST_STOCK_DETAIL,
                                    {
                                        stockRequestId: stockRequest?.id,
                                    }
                                )}
                            >
                                {stockRequest?.requestNo}
                            </Link>
                        </div>
                        <ViewLabelStatus
                            status={stockRequest?.status}
                            listStatus={STOCK_REQUEST_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={stockRequest}
                            keyList={STOCK_REQUEST_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
            {/* {loading && <Skeleton count={5} />} */}
        </div>
    );
};

export default EquipmentStockRequestSummary;
