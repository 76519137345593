import { Drawer, IDrawerProps } from "d-react-components";
import { Popover } from "antd";
import moment from "moment";
import React, { useState } from "react";
import BigCalendar, {
    IBigCalendarProps,
} from "../../../common/calendar/BigCalendar";
import Messages from "../../../languages/Messages";
import { CalendarEventItem, CalendarEventWrapper } from "./CalendarBody";

export interface ICalendarDayDrawerProps extends IDrawerProps {
    calendarProps?: IBigCalendarProps;
}

const CalendarDayDrawer: React.FC<ICalendarDayDrawerProps> = ({
    open,
    onClose,
    calendarProps = {
        date: new Date(),
    },
}) => {
    const { date } = calendarProps || {};
    const [activeDate, setActiveDate] = useState<Date>(date as any);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.daySchedule}
            // onSave={onClose}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <BigCalendar
                    classNameContainer="calendar-day-drawer_container"
                    components={{
                        event: (props) => (
                            <CalendarEventItem
                                {...props}
                                classNameContainer="calendar-day-drawer_event-container"
                            />
                        ),
                        eventWrapper: (props) => (
                            <CalendarEventWrapper
                                {...props}
                                classNamePopOver="calendar-event-wrapper_popover"
                            />
                        ),
                    }}
                    {...(calendarProps as any)}
                    defaultDate={moment(activeDate).toDate()}
                    dateRange={activeDate}
                    date={activeDate}
                    onNavigate={(value) => {
                        setActiveDate(moment(value)?.toDate());
                    }}
                    style={{ height: "auto" }}
                    defaultView="day"
                    views={["day"]}
                    dayLayoutAlgorithm="no-overlap"
                />
            </div>
        </Drawer>
    );
};

export default CalendarDayDrawer;
