import {
    Button,
    DateInput,
    DialogManager,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BookingAPI from "../../../api/queries/booking";
import Drawer from "../../../common/Drawer";
import { BOOKING_STATUS, BOOKING_STATUSES } from "../../../constant/booking";
import { BookingDetailContext } from "../../../context/booking";
import { mapBookingConfirmToServer } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import BookingDoctorSelect from "../share/BookingDoctorSelect";
import ActionMenu, { ActionButton } from "../../../common/ActionButton";

interface IBookingConfirmDrawer {
    open: boolean;
    onClose: () => void;
}

const BookingConfirmDrawer = ({ open, onClose }: IBookingConfirmDrawer) => {
    const { booking, setBooking } = useContext(BookingDetailContext);

    const doctorForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapBookingConfirmToServer(values);
            onConfirmBooking(input);
        },
    });

    const onConfirmBooking = (input: any) => {
        Progress.show(
            { method: BookingAPI.confirm, params: [booking?.id, input] },
            (bookingRes: any) => {
                Notifications.showSuccess(Messages.confirmSuccess);
                setBooking(bookingRes);
                onClose();
            }
        );
    };

    const formValues = doctorForm?.values;
    const formErrors = doctorForm?.errors;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.doctor}
            onSave={() => doctorForm.handleSubmit()}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <DateInput
                    label={Messages.confirmDateOfVisit}
                    onChange={(value) => {
                        doctorForm.setFieldValue("date", value);
                    }}
                    value={
                        formValues?.date
                            ? moment(formValues?.date)
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    error={formErrors?.date as any}
                />
                <BookingDoctorSelect
                    className="mt-3"
                    branch={booking?.branch}
                    date={formValues?.date}
                    form={doctorForm}
                />
            </div>
        </Drawer>
    );
};

const BookingDetailHeader = () => {
    const { booking, loadBookingDetail } = useContext(BookingDetailContext);
    const [openConfirm, setOpenConfirm] = useState(false);
    const navigate = useNavigate();

    const isShowConfirm = useMemo(() => {
        return booking?.status === BOOKING_STATUS.PENDING;
    }, [booking?.status]);

    const onClickAction = (item: any) => {
        if (item.id === "cancelBooking") {
            DialogManager.showConfirm(
                Messages.confirm,
                Messages.areYouSureWantCancel,
                () =>
                    Progress.show(
                        {
                            method: BookingAPI.cancel,
                            params: [booking?.id],
                        },
                        () => {
                            loadBookingDetail();
                        }
                    )
            );
        }
    };

    return (
        <div className="bg-white p-3 flex-row-between-center border-bottom">
            <div className="d-flex">
                <h5 className="text-primary">{`${booking?.bookingNo}`}</h5>
                <ViewLabelStatus
                    status={booking?.status}
                    listStatus={BOOKING_STATUSES}
                    className="ml-3"
                />
            </div>
            <div className="d-flex">
                {isShowConfirm && (
                    <ActionButton
                        className="mr-2"
                        onClick={() => {
                            setOpenConfirm(true);
                        }}
                    >
                        {Messages.confirm}
                    </ActionButton>
                )}
                <ActionButton
                    className="mr-2"
                    onClick={() => {
                        navigate(
                            `${Path.DOCTOR_ORDER_CREATE.replace(
                                ":bookingId",
                                booking?.id
                            )}`
                        );
                    }}
                >
                    {Messages.createDoctorOrder}
                </ActionButton>
                {/* <Button variant="outline" onClick={() => {}} className="ml-2">
                    {Messages.print}
                </Button> */}

                <ActionMenu
                    dataSource={[
                        {
                            id: "cancelBooking",
                            label: Messages.cancelBooking,
                        },
                    ]}
                    onClick={onClickAction}
                />
            </div>
            {openConfirm && (
                <BookingConfirmDrawer
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                />
            )}
        </div>
    );
};

export default BookingDetailHeader;
