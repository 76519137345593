import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useListEquipmentForAdminLazyQuery } from "../../../api/hooks";
import { IEquipment } from "../../../interfaces/equipment";
import Messages from "../../../languages/Messages";

interface IEquipmentSearchSelect<T> {
    value?: T | T[];
    error?: string;
    className?: string;
    label?: string;
    multiple?: boolean;
    disabled?: boolean;
    onChange: (value: T) => void;
    params?: any;
}

const EquipmentSelect = ({
    value = [],
    error,
    className,
    multiple,
    label = Messages.equipment,
    onChange,
    params = {},
    disabled = false,
}: IEquipmentSearchSelect<IEquipment>) => {
    const [equipmentList, setEquipmentList] = useState<IEquipment[]>(
        isArray(value) ? value : [value]
    );
    const [getListEquipment] = useListEquipmentForAdminLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setEquipmentList([]);
        } else {
            getListEquipment({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 50,
                        search: textSearch,
                        ...params,
                    },
                },
                fetchPolicy: "no-cache",
            }).then((res) => {
                const equipmentList = res?.data?.data?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const uniqEquipment = uniqBy(
                    [...selectedValue, ...equipmentList],
                    (item: any) => item.id
                );

                setEquipmentList(uniqEquipment as any);
            });
        }
    }, 500);

    const onSelectEquipment = (ids: any) => {
        if (multiple) {
            onChange(
                map(ids, (id) =>
                    ObjectUtils.findItemFromId(equipmentList, id)
                ) as any
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(equipmentList, ids));
    };

    const equipmentValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            label={label}
            onSearch={onChangeTextSearch}
            dataSource={equipmentList}
            getLabel={(item: IEquipment) => `${item.name}(${item.equipmentNo})`}
            onChange={onSelectEquipment}
            showSearch
            error={error}
            filterOption={false}
            className={className}
            multiple={multiple}
            value={equipmentValue}
            disabled={disabled}
        />
    );
};

export default EquipmentSelect;
