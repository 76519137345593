import { useApolloClient } from "@apollo/client";
import {
    Checkbox,
    InputText,
    Notifications,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { useFormikContext } from "formik";
import { filter, find, forEach, isEmpty, isUndefined } from "lodash";
import { useContext, useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import {
    DoctorOrderType,
    ListProductDoctorOrderForAdminDocument,
    PurchaseType,
    TreatmentStatus,
    useListProductDoctorOrderForAdminLazyQuery,
    useSearchDoctorOrderForAdminLazyQuery,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import { QuotationCreateContext as OrderCreateContext } from "../../../context/quotation";
import { IDoctorOrder } from "../../../interfaces/doctor-order";
import { ITreatment } from "../../../interfaces/treatment";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const DoctorOrderItem = ({ order }: { order: IDoctorOrder }) => {
    const unCompletedTreatments = filter(
        order.treatments,
        (order) =>
            order.status === TreatmentStatus.Scheduled ||
            order.status === TreatmentStatus.InProgress
    );

    return (
        <div className="drop-shadow-lg bg-white w-100 mb-3 p-3 relative">
            <div className="absolute top-[12px] right-[12px]">
                <ViewLabelStatus
                    status={order.status}
                    listStatus={DOCTOR_ORDER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                    className="py-1 px-2"
                />
            </div>
            <div className="flex flex-column">
                <span className="text-primary text-sm">
                    {order.doctorOrderNo}
                </span>
                <div className="text-gray-500 text-xs">
                    {Messages.recommendedTreatmentQty}:{" "}
                    {order?.services?.length}
                </div>
                <div className="text-gray-500 text-xs">
                    {Messages.completedTreatmentQty}:{" "}
                    {filter(
                        order.treatments,
                        (order) => order.status === TreatmentStatus.Completed
                    )?.length ?? 0}{" "}
                    | {Messages.uncompletedTreatmentQty}:{" "}
                    {unCompletedTreatments.length ?? 0}
                </div>
                <div className="text-gray-500 text-xs">
                    {Messages.createdAt}:{" "}
                    {TimeUtils.convertMiliToDateTime(order.createdAt)} |{" "}
                    {Messages.createdBy}{" "}
                    <UserAvatarName
                        user={order.createByAdmin}
                        size="xx-small"
                        showInfo={{ showOPD: false, showEmployeeId: false }}
                    />
                </div>
                {unCompletedTreatments?.length > 0 && (
                    <div className="bg-rose-400 p-3 text-white mt-3 text-xs">
                        {Messages.orderHasUncompletedTreatment.replace(
                            "{id}",
                            `#${order.doctorOrderNo}`
                        )}
                        {unCompletedTreatments?.map(
                            (treatment: ITreatment, index) => (
                                <span key={index} className="block my-1">
                                    <AppLink
                                        to={generatePath(
                                            Path.TREATMENT_DETAIL,
                                            {
                                                treatmentId: treatment.id,
                                            }
                                        )}
                                        target="_blank"
                                    >
                                        <span className="text-white">
                                            #{treatment.treatmentNo}
                                        </span>
                                    </AppLink>
                                </span>
                            )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const LoadDoctorOrderDrawer = ({ open, onClose, onSave }: any) => {
    const client = useApolloClient();
    const { selectedCustomer } = useContext(OrderCreateContext);
    const { values } = useFormikContext<any>();
    const { purchaseTypes } = values;
    const [getDoctorOrderProduct] =
        useListProductDoctorOrderForAdminLazyQuery();
    const [getDoctorOrderList, { data }] =
        useSearchDoctorOrderForAdminLazyQuery();
    const [doctorOrderNo, setDoctorOrderNo] = useState("");
    const [selectedOrder, setSelectedOrder] = useState<IDoctorOrder[]>([]);

    const handleSave = () => {
        if (!selectedCustomer && doctorOrderNo) {
            getDoctorOrderProduct({
                variables: {
                    payload: {
                        doctorOrderNo,
                    },
                },
                fetchPolicy: "no-cache",
            }).then((resp) => {
                if (resp?.data?.data?.data?.length) {
                    onSave && onSave(resp?.data?.data?.data);
                } else {
                    Notifications.showError(Messages.noProductFound);
                }
            });
        } else if (selectedCustomer && selectedOrder?.length) {
            const promises: any[] = [];
            forEach(selectedOrder, (order) => {
                const { doctorOrderNo } = order;
                promises.push(
                    // getDoctorOrderProduct({
                    //     variables: {
                    //         payload: {
                    //             doctorOrderNo,
                    //         },
                    //     },
                    //     fetchPolicy: "no-cache",
                    // })
                    client.query({
                        query: ListProductDoctorOrderForAdminDocument,
                        variables: {
                            payload: {
                                doctorOrderNo,
                            },
                        },
                        fetchPolicy: "no-cache",
                    })
                );
            });
            Promise.all(promises)
                .then((resp) => {
                    const products = resp.reduce((values, curr) => {
                        // return item;
                        return [...values, ...(curr?.data?.data?.data ?? [])];
                    }, []);
                    if (products?.length) {
                        onSave && onSave(products);
                    } else {
                        Notifications.showError(Messages.noProductFound);
                    }
                })
                .catch((err: any) => Notifications.showError(err));
        }
    };

    useEffect(() => {
        if (!isEmpty(selectedCustomer)) {
            const payload = {
                page: 1,
                limit: 100,
                customer: [selectedCustomer.id as string],
            };
            if (purchaseTypes === PurchaseType.Complication) {
                Object.assign(payload, {
                    doctorOrderType: [DoctorOrderType.Complication],
                });
            }
            getDoctorOrderList({
                variables: {
                    paginate: payload,
                },
            });
        }
    }, [selectedCustomer]);

    const onChangeSelectOrder = (order: IDoctorOrder) => {
        const isChecked = !isUndefined(find(selectedOrder, { id: order.id }));
        if (isChecked) {
            setSelectedOrder(filter(selectedOrder, (o) => o.id !== order.id));
        } else {
            setSelectedOrder([...selectedOrder, order]);
        }
    };

    return (
        <Drawer
            open={open}
            title={Messages.loadDoctorOrder}
            onClose={() => onClose()}
            onSave={handleSave}
            destroyOnClose
            size="auto"
            width="500px"
        >
            {!selectedCustomer && (
                <div className="p-4">
                    <div>{Messages.pleaseInputDoctorOrderNo}</div>
                    <InputText
                        label={Messages.doctorOrderNo}
                        onChange={(event) =>
                            setDoctorOrderNo(event.target.value)
                        }
                        className="mt-3"
                    />
                </div>
            )}
            {selectedCustomer && (
                <div className="p-4">
                    {data?.data?.data?.map((order, index) => {
                        const unCompletedTreatments = filter(
                            order.treatments,
                            (order) =>
                                order.status === TreatmentStatus.Scheduled ||
                                order.status === TreatmentStatus.InProgress
                        );
                        return (
                            <div className="flex items-center" key={index}>
                                <Checkbox
                                    checked={
                                        !isUndefined(
                                            find(selectedOrder, {
                                                id: order.id,
                                            })
                                        )
                                    }
                                    onChange={() =>
                                        onChangeSelectOrder(order as any)
                                    }
                                    disabled={unCompletedTreatments?.length > 0}
                                    className="mr-3"
                                />
                                <DoctorOrderItem
                                    key={index}
                                    order={order as any}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </Drawer>
    );
};

export default LoadDoctorOrderDrawer;
