import { useEffect, useState } from "react";
import { Select } from "d-react-components";
import Messages from "../languages/Messages";
import { useRoleListLazyQuery } from "../api/hooks";

interface SelectProps {
    [key: string]: any;
}

const RoleSelect = ({ form, formKey, ...rest }: SelectProps) => {
    const [roleList, setRoleList] = useState<any[]>([]);
    const [getRoleList] = useRoleListLazyQuery();

    const fetchData = (textSearch: string) => {
        getRoleList({
            variables: {
                paginate: {
                    page: 1,
                    limit: 100,
                    search: textSearch,
                },
            },
        }).then((res: any) => {
            const roles = res?.data?.listAdminRoleForAdmin?.data ?? [];
            setRoleList(roles);
        });
    };

    useEffect(() => {
        fetchData("");
    }, []);

    const { values, errors, setFieldValue } = form;

    return (
        <Select
            label={Messages.role}
            value={values?.[formKey]}
            error={errors?.[formKey]}
            dataSource={roleList}
            getLabel={(item) => item.name}
            onChange={(value) => setFieldValue(formKey, value)}
            {...rest}
        />
    );
};

export default RoleSelect;
