import { Button, Checkbox, Icon } from "d-react-components";
import { useFormikContext } from "formik";
import { isUndefined, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useAllCustomerBillingForAdminLazyQuery } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { MaterialPurchaseContext } from "../../../context/material";
import { IBillingAddress } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import {
    AddressItem,
    CustomerUpdateAddressModal,
} from "../../customer/detail/CustomerDetailBilling";

const BillingDrawer = ({ open, onClose, onSave }: any) => {
    const { billing, setBilling } = useContext(MaterialPurchaseContext);
    const { values, setFieldValue } = useFormikContext<any>();
    const [showAddBillingDrawer, setShowAddBillingDrawer] = useState(false);
    const [showEditBilling, setShowEditBilling] = useState<any>(undefined);
    const { isSameShippingAddress, customer } = values;
    const isGuest = isUndefined(customer?.[0]?.id);

    const onCloseAddBilling = (billing: any) => {
        setBilling(billing);
        setShowAddBillingDrawer(false);
    };

    const onCloseEditBilling = (billing: any) => {
        setBilling({
            ...billing,
            ...(!billing.id ? { id: Math.random() } : {}),
        });
        setShowEditBilling(undefined);
    };

    const onClickRemoveBilling = () => {
        setBilling(undefined);
    };

    return (
        <Drawer
            title={Messages.billingInformation}
            open={open}
            onClose={onClose}
            destroyOnClose
            onSave={onSave || onClose}
        >
            <div className="p-4">
                <Checkbox
                    checked={isSameShippingAddress}
                    onChange={() =>
                        setFieldValue(
                            "isSameShippingAddress",
                            !isSameShippingAddress
                        )
                    }
                    name="checkedB"
                    color="primary"
                    label={Messages.useSameShipping}
                />
                {!isSameShippingAddress && (
                    <>
                        {billing && (
                            <>
                                <AddressItem
                                    address={billing as any}
                                    showAction={false}
                                    index={1}
                                />
                                <div className="mt-2">
                                    <div
                                        className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none mr-1"
                                        onClick={() => {
                                            setShowEditBilling(billing);
                                        }}
                                    >
                                        <Icon name="edit" size="x-small" />
                                    </div>
                                    <div
                                        className="cursor-pointer bg-primary text-white p-1 w-fit inline-block leading-none"
                                        onClick={() => {
                                            onClickRemoveBilling();
                                        }}
                                    >
                                        <Icon name="delete" size="x-small" />
                                    </div>
                                </div>
                            </>
                        )}
                        <Button
                            iconName="add"
                            onClick={() =>
                                isGuest
                                    ? setShowEditBilling({})
                                    : setShowAddBillingDrawer(true)
                            }
                            className="mt-4"
                        >
                            {Messages.add}
                        </Button>
                    </>
                )}

                {showAddBillingDrawer && (
                    <AddBillingDrawer
                        open={showAddBillingDrawer}
                        onSave={onCloseAddBilling}
                        onClose={() => setShowAddBillingDrawer(false)}
                    />
                )}
                {!isUndefined(showEditBilling) && (
                    <CustomerUpdateAddressModal
                        open={!isUndefined(showEditBilling)}
                        onSaveAddress={onCloseEditBilling}
                        onClose={() => setShowEditBilling(undefined)}
                        addressDefault={showEditBilling}
                    />
                )}
            </div>
        </Drawer>
    );
};

const AddBillingDrawer = ({ open, onClose, onSave }: any) => {
    const { values } = useFormikContext<any>();
    const { customer } = values ?? {};
    const { billing, setBilling } = useContext(MaterialPurchaseContext);
    const [addresses, setAddresses] = useState<any[]>([]);
    const [selected, setSelected] = useState<IBillingAddress>(
        !isUndefined(billing) ? (billing as any) : undefined
    );
    const [showAddNewBilling, setShowAddNewBilling] = useState(false);
    const [getBillingAddress] = useAllCustomerBillingForAdminLazyQuery();

    const onChangeCheckbox = (address: any) => {
        setSelected(address);
    };

    const onSaveBilling = () => {
        onSave(selected);
    };

    const onSaveNewBilling = () => {
        setShowAddNewBilling(false);
        loadBillingAddress();
    };

    const loadBillingAddress = () => {
        const customerId = customer?.[0]?.id;
        if (customer?.[0]?.id) {
            getBillingAddress({
                variables: {
                    customerId,
                },
                fetchPolicy: "no-cache",
            }).then((resp) => {
                setAddresses(resp?.data?.data?.data ?? []);
            });
        }
    };

    useEffect(() => {
        loadBillingAddress();
    }, [customer]);

    return (
        <Drawer
            title={
                <div className="flex items-center">
                    <span className="flex-1">
                        {Messages.addBillingInformation}
                    </span>
                    <Button
                        size="medium"
                        onClick={() => {
                            setShowAddNewBilling(true);
                        }}
                    >
                        <Icon name="add" /> {Messages.add}
                    </Button>
                </div>
            }
            open={open}
            onClose={onClose}
            destroyOnClose
            onSave={onSaveBilling}
        >
            <div className="p-4">
                {map(addresses ?? [], (address, index) => (
                    <div key={index} className="flex border-b pt-1 pb-3">
                        <Checkbox
                            className="mr-3"
                            checked={selected?.id === address.id}
                            onChange={() => onChangeCheckbox(address)}
                            variant="radio"
                        />
                        <AddressItem
                            address={address}
                            index={index + 1}
                            showAction={false}
                        />
                    </div>
                ))}
            </div>
            {showAddNewBilling && (
                <CustomerUpdateAddressModal
                    open={showAddNewBilling}
                    onClose={() => onSaveNewBilling()}
                    isEdit={false}
                />
            )}
        </Drawer>
    );
};

export default BillingDrawer;
