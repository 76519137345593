import { useContext } from "react";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { UserCrudContext } from "../../../context/user";
import UserInputForm from "./UserInputForm";

const UserCrudInfo = () => {
    const { userForm } = useContext(UserCrudContext);

    return (
        <div className="bg-white">
            <UploadAvatar
                onChange={(value: any) =>
                    userForm.setFieldValue("avatar", value?.fileUrl)
                }
                value={userForm?.values?.avatar}
            />
            <div className="row ">
                <UserInputForm keyData="username" />
                <UserInputForm keyData="companyId" />
                <UserInputForm keyData="firstName" />
                <UserInputForm keyData="lastName" />
                <UserInputForm keyData="nickName" />
                <UserInputForm keyData="email" />
            </div>
        </div>
    );
};

export default UserCrudInfo;
