import {
    Button,
    DialogManager,
    Notifications,
    Progress,
    TabBar,
    ViewLabelStatus,
} from "d-react-components";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import {
    useGenerateUpdateInfoCodeMutation,
    useGetCustomerDetailLazyQuery,
    useVerifyCustomerMutation,
} from "../../../api/hooks";
import ActionMenu from "../../../common/ActionButton";
import { CUSTOMER_STATUS } from "../../../constant/customer";
import { CustomerDetailContext } from "../../../context/customer";
import { CustomerStatus, ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import CustomerDetailAddress from "./CustomerDetailAddress";
import CustomerDetailBilling from "./CustomerDetailBilling";
import CustomerDetailBooking from "./CustomerDetailBooking";
import CustomerDetailCaseStudy from "./CustomerDetailCaseStudy";
import CustomerDetailCredits from "./CustomerDetailCredits";
import CustomerDetailDashboard from "./CustomerDetailDashboard";
import CustomerDetailDoctorOrder from "./CustomerDetailDoctorOrder";
import CustomerDetailHealthAssessment from "./CustomerDetailHealthAssessment";
import CustomerDetailInfo from "./CustomerDetailInfo";
import CustomerDetailMedicalProfile from "./CustomerDetailMedicalProfile";
import { CustomerDetailNotes } from "./CustomerDetailNotes";
import CustomerDetailOrder from "./CustomerDetailOrder";
import CustomerDetailPersonalInfo from "./CustomerDetailPersonalInfo";
import CustomerDetailQuotation from "./CustomerDetailQuotation";
import CustomerDetailSetting from "./CustomerDetailSetting";
import CustomerDetailSurvey from "./CustomerDetailSurvey";
import CustomerDetailTreatment from "./CustomerDetailTreatment";
import CustomerDetailVoucher from "./CustomerDetailVoucher";
import CustomerDetailServiceHistory from "./CustomerDetailServiceHistory";
import CustomerAPI from "../../../api/queries/customer";

export const TABS = [
    {
        id: "dashboard",
        label: Messages.dashboard,
        component: <CustomerDetailDashboard />,
    },
    {
        id: "personal",
        label: Messages.personalInfo,
        component: <CustomerDetailPersonalInfo />,
    },
    {
        id: "credits",
        label: Messages.credits,
        component: <CustomerDetailCredits />,
    },
    {
        id: "voucher",
        label: Messages.voucher,
        component: <CustomerDetailServiceHistory />,
    },
    {
        id: "medicalProfile",
        label: Messages.medicalProfile,
        component: <CustomerDetailMedicalProfile />,
    },

    {
        id: "shippingInfo",
        label: Messages.shippingInfo,
        component: <CustomerDetailAddress />,
    },
    {
        id: "billingInfo",
        label: Messages.billingInfo,
        component: <CustomerDetailBilling />,
    },

    {
        id: "caseStudy",
        label: Messages.recentCaseStudy,
        component: <CustomerDetailCaseStudy />,
    },
    {
        id: "booking",
        label: Messages.bookingHistory,
        component: <CustomerDetailBooking />,
    },
    {
        id: "doctorOrder",
        label: Messages.doctorOrder,
        component: <CustomerDetailDoctorOrder />,
    },
    {
        id: "quotationHistory",
        label: Messages.quotationHistory,
        component: <CustomerDetailQuotation />,
    },
    {
        id: "orderHistory",
        label: Messages.orderHistory,
        component: <CustomerDetailOrder />,
    },
    {
        id: "treatmentHistory",
        label: Messages.treatmentHistory,
        component: <CustomerDetailTreatment />,
    },

    {
        id: "survey",
        label: Messages.survey,
        component: <CustomerDetailSurvey />,
    },
    {
        id: "quotation",
        label: Messages.quotation,
        component: <CustomerDetailQuotation />,
    },
    {
        id: "assessmentForm",
        label: Messages.assessmentForm,
        component: <CustomerDetailHealthAssessment />,
    },
    {
        id: "setting",
        label: Messages.setting,
        component: <CustomerDetailSetting />,
    },
    {
        id: "notes",
        label: Messages.notes,
        component: <CustomerDetailNotes />,
    },
];

const CustomerDetail = () => {
    const { customerId } = useParams<any>();
    const [layoutParams, setLayoutParams] = useQueryParam<any>(
        "params",
        JsonParam
    );
    const [customer, setCustomer] = useState<ICustomer>();
    const [tab, setTab] = useState(TABS[0]);
    const [getCustomerDetail, { data, refetch, loading }] =
        useGetCustomerDetailLazyQuery();
    const [generateUpdateInfoCode] = useGenerateUpdateInfoCodeMutation();
    const [verifyCustomer] = useVerifyCustomerMutation();

    useEffect(() => {
        if (layoutParams) {
            const foundTab = find(TABS, (i) => i?.id === layoutParams?.tab);
            if (foundTab) {
                setTab(foundTab);
            }
        }
    }, [layoutParams]);

    useEffect(() => {
        loadCustomerDetail();
    }, []);

    const loadCustomerDetail = () => {
        Progress.show(
            {
                method: getCustomerDetail,
                params: [
                    { variables: { id: customerId }, fetchPolicy: "no-cache" },
                ],
            },
            (res: any) => {
                const customerDetail = res?.data?.data?.data ?? {};
                setCustomer(customerDetail);
            }
        );
    };

    const onClickAction = (item: any) => {
        if (item.id === "enableSelfRegistration") {
            return Progress.show(
                {
                    method: generateUpdateInfoCode,
                    params: [
                        {
                            variables: {
                                customerId: customerId as string,
                            },
                        },
                    ],
                },
                () => {
                    Notifications.showSuccess(
                        Messages.successfullyEnableSelfRegistration
                    );
                    loadCustomerDetail();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        }
        if (item.id === "cancelCustomer") {
            return DialogManager.showConfirm(
                "Confirm Cancel",
                "Are you sure want to cancel this customer permanently? Please note that this action cannot be undone!",
                () => {
                    onCancelCustomer();
                }
            );
        }
        return null;
    };

    const onCancelCustomer = () => {
        return Progress.show(
            {
                method: CustomerAPI.cancelCustomer,
                params: [customer?.id],
            },
            () => {
                Notifications.showSuccess(Messages.updateCustomerSuccessfully);
                loadCustomerDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    const onVerifyCustomer = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantVerifyThisCustomer,
            () =>
                Progress.show(
                    {
                        method: verifyCustomer,
                        params: [
                            {
                                variables: {
                                    customerId: customerId as string,
                                    remark: "",
                                },
                            },
                        ],
                    },
                    (res) => {
                        Notifications.showSuccess(Messages.updateSuccess);
                        loadCustomerDetail();
                    }
                )
        );
    };

    if (!customer) {
        return <div />;
    }

    const renderActionGroup = () => {
        const actionData = [
            {
                id: "enableSelfRegistration",
                label: Messages.enableSelfRegistration,
            },
        ];
        if (customer?.status === CustomerStatus.UN_VERIFIED) {
            actionData.push({
                id: "cancelCustomer",
                label: Messages.cancelCustomer,
            });
        }
        return (
            <div className="flex flex-row items-center">
                {customer?.status === CustomerStatus.UN_VERIFIED && (
                    <Button
                        variant="trans"
                        size="small"
                        className="mr-3 button-action"
                        onClick={onVerifyCustomer}
                    >
                        {Messages.verify}
                    </Button>
                )}
                <ActionMenu dataSource={actionData} onClick={onClickAction} />
            </div>
        );
    };

    return (
        <CustomerDetailContext.Provider
            value={{ customer, setCustomer, setTab, tab, loadCustomerDetail }}
        >
            <div className="flex-column-container bg-white">
                <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                    <div className="flex-center-y">
                        <h5 className="text-primary">{`${customer?.fullNameTh}(ID: ${customer?.customerNo})`}</h5>
                        <ViewLabelStatus
                            className="ml-3"
                            status={customer?.status || "UN_VERIFIED"}
                            listStatus={CUSTOMER_STATUS}
                            getLabel={(item) => Messages[item.label]}
                        />
                    </div>
                    {renderActionGroup()}
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <CustomerDetailInfo />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={(v) => {
                                    setTab(v as any);
                                    setLayoutParams({ tab: v?.id });
                                }}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomerDetailContext.Provider>
    );
};

export default CustomerDetail;
