import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";

export const F_DIRECTORY = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_DIRECTORY on MediaFolderDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        name
        parent {
            id
            name
        }
        size
        children {
            id
            name
        }
    }
`;

export const F_MEDIA = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_DIRECTORY}
    fragment F_MEDIA on MediaFileDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        title
        description
        fileName
        fileUrl
        type
        folder {
            ...F_DIRECTORY
        }
        size
        dimension
    }
`;
