import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";

export const ScheduleDetailActivity = () => {
    const { userId } = useParams<any>();

    return (
        <ActivityView
            className="mt-3 border"
            activityRef={RefActivity?.TemporarySchedule}
            activityRefId={userId ?? ""}
        />
    );
};
