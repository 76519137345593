import { Button } from "d-react-components";
import React from "react";
import Messages from "../../languages/Messages";

interface IReportFooter {
    previousDisabled?: boolean;
    exportDisabled?: boolean;
    viewDisabled?: boolean;
    onClickPrevious: any;
    onClickView: any;
    onClickExport: any;
}

const ReportFooter = ({
    onClickPrevious,
    onClickView,
    onClickExport,
    previousDisabled,
    exportDisabled,
    viewDisabled,
}: IReportFooter) => {
    return (
        <div className="p-4 bg-gray-light flex-row-between-center">
            {/* <Button
                disabled={previousDisabled}
                className="mr-3"
                onClick={onClickPrevious}
                variant="outline"
            >
                {Messages.previous}
            </Button> */}
            <div />
            <div className="d-flex flex-row">
                <Button
                    disabled={exportDisabled || viewDisabled}
                    className="mr-3"
                    onClick={onClickView}
                >
                    {Messages.view}
                </Button>
                <Button disabled={exportDisabled} onClick={onClickExport}>
                    {Messages.export}
                </Button>
            </div>
        </div>
    );
};

export default ReportFooter;
