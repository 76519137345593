import { gql } from "@apollo/client";
import API from "../API";
import {
    F_DELIVERY_DRIVER,
    F_DELIVERY_DRIVERS,
} from "../fragments/delivery-driver";
import {
    F_STOCK_TRANSFER,
    F_STOCK_TRANSFERS,
} from "../fragments/stock-transfer";

export const getStockTransferList = gql`
    ${F_STOCK_TRANSFERS}
    query getStockTransferList($input: ListAdminProductStockTransferRequest!) {
        data: listProductStockTransferForAdmin(paginate: $input) {
            ...F_STOCK_TRANSFERS
        }
    }
`;

export const getStockTransferDetail = gql`
    ${F_STOCK_TRANSFER}
    query getStockTransferDetail($id: String!) {
        data: detailProductStockTransferForAdmin(id: $id) {
            data {
                ...F_STOCK_TRANSFER
            }
        }
    }
`;

export const createStockTransfer = gql`
    ${F_STOCK_TRANSFER}
    mutation createStockTransfer(
        $payload: CreateAdminProductStockTransferRequest!
    ) {
        data: createProductStockTransferForAdmin(payload: $payload) {
            data {
                ...F_STOCK_TRANSFER
            }
        }
    }
`;

export const updateStockTransfer = gql`
    ${F_STOCK_TRANSFER}
    mutation updateStockTransfer(
        $id: String!
        $payload: UpdateAdminProductStockTransferRequest!
    ) {
        data: updateProductStockTransferForAdmin(id: $id, payload: $payload) {
            data {
                ...F_STOCK_TRANSFER
            }
        }
    }
`;

export const cancelStockTransfer = gql`
    ${F_STOCK_TRANSFER}
    mutation cancelStockTransfer($id: String!, $remark: String!) {
        data: cancelProductStockTransferForAdmin(id: $id, remark: $remark) {
            data {
                ...F_STOCK_TRANSFER
            }
        }
    }
`;

export const allDeliveriesStockTransfer = gql`
    ${F_DELIVERY_DRIVERS}
    query allDeliveriesStockTransfer($transferId: String!) {
        data: allProductStockDeliveryForAdmin(transferId: $transferId) {
            ...F_DELIVERY_DRIVERS
        }
    }
`;

export const destinationReceiveProductInDelivery = gql`
    ${F_DELIVERY_DRIVER}
    mutation destinationReceiveProductInDelivery(
        $deliveryId: String!
        $payload: DeliveryStockReceiveRequest!
    ) {
        data: destinationReceiveProductInDeliveryForAdmin(
            deliveryId: $deliveryId
            payload: $payload
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

export const confirmPickUpDestinationProductStockDelivery = gql`
    ${F_DELIVERY_DRIVER}
    mutation confirmPickUpDestinationProductStockDelivery(
        $id: String!
        $payload: DeliveryConfirmationRequest!
    ) {
        data: confirmPickUpDestinationProductStockDeliveryForAdmin(
            id: $id
            payload: $payload
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

export const createStockTransferDelivery = gql`
    ${F_DELIVERY_DRIVER}
    mutation createStockTransferDelivery(
        $transferId: String!
        $payload: CreateAdminProductStockDeliveryRequest!
    ) {
        data: createProductStockDeliveryForAdmin(
            transferId: $transferId
            payload: $payload
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

export const updateStockTransferDelivery = gql`
    ${F_DELIVERY_DRIVER}
    mutation updateStockTransferDelivery(
        $transferId: String!
        $id: String!
        $payload: UpdateAdminProductStockDeliveryRequest!
    ) {
        data: updateProductStockDeliveryForAdmin(
            transferId: $transferId
            id: $id
            payload: $payload
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

export const cancelStockTransferDelivery = gql`
    ${F_DELIVERY_DRIVER}
    mutation cancelStockTransferDelivery(
        $transferId: String!
        $id: String!
        $remark: String!
    ) {
        data: cancelProductStockDeliveryForAdmin(
            transferId: $transferId
            id: $id
            remark: $remark
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

const stockTransferListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const stockTransferConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

const StockTransferAPI = {
    list: async (input: any) =>
        API.withConverter(stockTransferListConverter).query({
            query: getStockTransferList,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(stockTransferConverter).query({
            query: getStockTransferDetail,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: createStockTransfer,
            variables: { payload },
        }),

    update: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: updateStockTransfer,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    cancel: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: cancelStockTransfer,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
    createDelivery: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: createStockTransferDelivery,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
    updateDelivery: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: updateStockTransferDelivery,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
    cancelDelivery: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: cancelStockTransferDelivery,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
    allDeliveries: async (input: any) =>
        API.withConverter(stockTransferListConverter).query({
            query: allDeliveriesStockTransfer,
            variables: { ...input },
            fetchPolicy: "no-cache",
        }),
    destinationReceiveProduct: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: destinationReceiveProductInDelivery,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
    confirmPickUpDestination: async (payload: any) =>
        API.withConverter(stockTransferConverter).mutate({
            mutation: confirmPickUpDestinationProductStockDelivery,
            variables: { ...payload },
            fetchPolicy: "no-cache",
        }),
};

export default StockTransferAPI;
