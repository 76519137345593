import {
    AwesomeTableComponent,
    Button,
    DateInput,
    IColumnsProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import BookingAPI from "../../../api/queries/booking";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import { BookingDetailContext } from "../../../context/booking";
import { mapBookingConfirmToServer } from "../../../interfaces/booking";
import IUser from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import BookingDoctorSelect from "../share/BookingDoctorSelect";

interface IBookingDoctorUpdateDrawer {
    open: boolean;
    onClose: () => void;
}

const BookingDoctorUpdateDrawer = ({
    open,
    onClose,
}: IBookingDoctorUpdateDrawer) => {
    const { booking, setBooking } = useContext(BookingDetailContext);

    const doctorForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapBookingConfirmToServer(values);
            onUpdateDoctorBooking(input);
        },
    });

    const onUpdateDoctorBooking = (input: any) => {
        Progress.show(
            { method: BookingAPI.updateDoctor, params: [booking?.id, input] },
            (bookingRes: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setBooking(bookingRes);
                onClose();
            }
        );
    };

    const formValues = doctorForm?.values;
    const formErrors = doctorForm?.errors;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.doctor}
            onSave={() => doctorForm.handleSubmit()}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <DateInput
                    label={Messages.confirmDateOfVisit}
                    onChange={(value) => {
                        doctorForm.setFieldValue("date", value);
                    }}
                    value={
                        formValues?.date
                            ? moment(formValues?.date)
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    error={formErrors?.date as any}
                />
                <BookingDoctorSelect
                    className="mt-3"
                    branch={booking?.branch}
                    date={formValues?.date}
                    form={doctorForm}
                />
            </div>
        </Drawer>
    );
};

const BookingDetailDoctor = () => {
    const { booking } = useContext(BookingDetailContext);
    const [openUpdate, setOpenUpdate] = useState(false);

    const columns: IColumnsProps = [
        {
            title: Messages.doctor,
            dataIndex: "",
            render: (doctor) => <UserAvatarName user={doctor} />,
        },
        {
            title: Messages.jobType,
            dataIndex: "",
            render: (doctor: IUser) => doctor?.jobType,
        },
    ];
    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.doctor}</label>
                <Button onClick={() => setOpenUpdate(true)} className="">
                    {Messages.update}
                </Button>
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={booking?.doctor ? [booking?.doctor] : []}
                className="mt-3"
                pagination={false}
            />
            {openUpdate && (
                <BookingDoctorUpdateDrawer
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                />
            )}
        </div>
    );
};

export default BookingDetailDoctor;
