import { Button } from "d-react-components";
import React from "react";
import Messages from "../../../languages/Messages";

interface IAddressItemHeader {
    label: string;
    isDefault: boolean;
    onClickEdit: () => any;
    onClickSetDefault: () => any;
    onClickDelete: () => any;
    showAction?: boolean;
}

const AddressItemHeader = ({
    label,
    isDefault,
    onClickEdit,
    onClickSetDefault,
    onClickDelete,
    showAction = true,
}: IAddressItemHeader) => (
    <div className="d-flex justify-content-between">
        <div className="flex-center">
            <label className="mr-3">{label}</label>
            <div
                className="bg-primary px-3 py-1 text-white height-fit-content"
                hidden={!isDefault}
            >
                {Messages.default}
            </div>
        </div>
        {showAction && (
            <div className="d-flex">
                <Button variant="trans" onClick={onClickDelete}>
                    {Messages.delete}
                </Button>
                <Button variant="trans" onClick={onClickEdit}>
                    {Messages.edit}
                </Button>
                {!isDefault && (
                    <Button variant="trans" onClick={onClickSetDefault}>
                        {Messages.setAsDefault}
                    </Button>
                )}
            </div>
        )}
    </div>
);

export default AddressItemHeader;
