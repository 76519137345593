import * as Yup from "yup";

export const NoteSchema = Yup.object().shape({
    title: Yup.string().required("Required field!"),
    content: Yup.string().required("Required field!"),
});

export const BookingGuestSchema = Yup.object().shape({
    firstName: Yup.string().required("Required field!"),
    lastName: Yup.string().required("Required field!"),
    // nickname: Yup.string().required("Required field!"),
    phone: Yup.string().required("Required field!"),
    // email: Yup.string().email().required("Required field!"),
});

export const BookingSchema = Yup.lazy((value: any) => {
    let schema = Yup.object().shape({
        branch: Yup.object().required("Required field!"),
        customerSource: Yup.string().required("Required field!"),
        date: Yup.object().required("Required field!"),
        products: Yup.array().min(1).required("Required field!"),
        // doctor: Yup.object().required("Required field!"),
        // remark: Yup.string().required("Required field!"),
    });
    if (value.isGuestVisit) {
        schema = schema.concat(
            Yup.object().shape({
                guestInfo: BookingGuestSchema,
            }) as any
        );
    } else {
        schema = schema.concat(
            Yup.object().shape({
                customer: Yup.object().required("Required field!"),
            }) as any
        );
    }

    if (value?.doctor) {
        schema = schema.concat(
            Yup.object().shape({
                timeSlot: Yup.array()
                    .min(1)
                    .required("Doctor time slot required field!"),
            }) as any
        );
    } else {
        schema = schema.concat(
            Yup.object().shape({
                estTimeVisit: Yup.array()
                    .min(1)
                    .required("Time slot required field!"),
            }) as any
        );
    }
    return schema;
});
