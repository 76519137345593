import React, { useState } from "react";
import { TabBar } from "d-react-components";
import { useParams } from "react-router";
import { MaterialDetailContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import ActionMenu from "../../../common/ActionButton";
import MaterialDetailSummaryView from "./MaterialDetailSummaryView";
import MaterialDetailGeneralInfo from "./MaterialDetailGeneralInfo";
import MaterialDetailPurchases from "./MaterialDetailPurchases";
import MaterialAdjustments from "./MaterialAdjustments";
import MaterialActivity from "./MaterialActivity";
import { useDetailMaterialQuery } from "../../../api/hooks";

const TABS = [
    {
        id: "personal",
        label: Messages.generalInformation,
        component: <MaterialDetailGeneralInfo />,
    },
    {
        id: "purchases",
        label: Messages.purchases,
        component: <MaterialDetailPurchases />,
    },
    {
        id: "adjustments",
        label: Messages.adjustments,
        component: <MaterialAdjustments />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <MaterialActivity />,
    },
];

const MaterialDetail = () => {
    const { materialId } = useParams();
    const [tab, setTab] = useState(TABS[0]);
    const { data, refetch } = useDetailMaterialQuery({
        variables: {
            id: materialId as string,
        },
        skip: !materialId
    });
    const onClickAction = () => {};

    const material = data?.data?.data ?? {};

    return (
        <MaterialDetailContext.Provider
            value={{
                //@ts-ignore
                material,
                setMaterial: refetch,
            }}
        >
            <div className="px-4 py-3 bg-white flex border-b items-center">
                <div className="flex flex-1 align-items-center">
                    <h5 className="text-primary mr-3">{`${material?.name}`}</h5>
                </div>
                <div className="flex items-center">
                    <ActionMenu dataSource={[]} onClick={onClickAction} />
                </div>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <MaterialDetailSummaryView />
                    </div>
                    <div className="col-span-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
            </div>
        </MaterialDetailContext.Provider>
    );
};

export default MaterialDetail;
