import React from "react";
import { IPaymentMethod } from "../interfaces/payment-method";

////////// DETAIL CONTEXT

interface IPaymentMethodDetailState {
    paymentMethod?: IPaymentMethod;
    loadPaymentMethodDetail: () => void;
}

const paymentMethodDetailState: IPaymentMethodDetailState = {
    paymentMethod: undefined,
    loadPaymentMethodDetail: () => {},
};

export const PaymentMethodDetailContext = React.createContext(
    paymentMethodDetailState
);
