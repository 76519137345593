import { useContext } from "react";
import { ProductCreateContext } from "../../../context/product";
import ProductConfigVoucherForm from "../share/ProductConfigVoucherForm";

const ProductCreateGiftVoucherConfig = () => {
    const { productForm } = useContext(ProductCreateContext);
    return <ProductConfigVoucherForm configForm={productForm} />;
};

export default ProductCreateGiftVoucherConfig;
