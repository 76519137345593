import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AppointmentAPI from "../../../api/queries/appointment";
import { AppointmentDetailContext } from "../../../context/appointment";
import { IAppointment } from "../../../interfaces/appointment";
import Messages from "../../../languages/Messages";
import CustomerInfoBar from "../../customer/share/CustomerInfoBar";
import AppointmentDetailActivity from "./AppointmentDetailActivity";
import AppointmentDetailGeneral from "./AppointmentDetailGeneral";
import AppointmentDetailHeader from "./AppointmentDetailHeader";
import AppointmentDetailServices from "./AppointmentDetailServices";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <AppointmentDetailGeneral />,
    },
    {
        id: "services",
        label: Messages.services,
        component: <AppointmentDetailServices />,
    },

    {
        id: "activity",
        label: Messages.activity,
        component: <AppointmentDetailActivity />,
    },
];

const AppointmentDetail = () => {
    const { appointmentId } = useParams<any>();
    const [appointment, setAppointment] = useState<IAppointment>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadAppointmentDetail();
    }, []);

    const loadAppointmentDetail = () => {
        Progress.show(
            {
                method: AppointmentAPI.detail,
                params: [appointmentId],
            },
            setAppointment as any
        );
    };

    if (!appointment) {
        return <div />;
    }

    const renderInfoRow = (title: any, content: any) => {
        return (
            <div className="d-flex align-items-start w-100 justify-content-between py-3">
                <div className="text-small w-100 font-weight-bold">{title}</div>
                <div className="w-100 text text-end">{content}</div>
            </div>
        );
    };

    return (
        <AppointmentDetailContext.Provider
            value={{ appointment, setAppointment }}
        >
            <div className="flex-column-container bg-white">
                <AppointmentDetailHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            {appointment?.customer && (
                                <CustomerInfoBar
                                    customer={appointment?.customer}
                                    onUpdate={() => loadAppointmentDetail()}
                                />
                            )}
                            {appointment?.contact && (
                                <div className="flex-column flex-center border p-3">
                                    {renderInfoRow(
                                        Messages.firstName,
                                        appointment?.contact?.firstName
                                    )}
                                    {renderInfoRow(
                                        Messages.lastName,
                                        appointment?.contact?.lastName
                                    )}
                                    {renderInfoRow(
                                        Messages.email,
                                        appointment?.contact?.email
                                    )}
                                    {renderInfoRow(
                                        Messages.phone,
                                        appointment?.contact?.phone
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </AppointmentDetailContext.Provider>
    );
};

export default AppointmentDetail;
