import { gql } from "@apollo/client";

export const F_ADMIN_USER = gql`
    fragment F_ADMIN_USER on AdminUser {
        id
        adminNo
        username
        firstName
        lastName
        nickName
        email
        avatar
        companyId
        jobType
        role {
            name
            id
        }
        lastLogin
        createdAt
        updatedAt
        status
        authenticationStatus
        jobCostFeature
        defaultWorkingSchedule {
            date
            branch {
                id
                name
            }
            schedule {
                start
                end
            }
            order
        }
        commissionServiceType
        commissionService {
            value
            service {
                id
                name
                serviceNo
            }
        }
    }
`;

export const F_ADMIN_USER_DETAIL = gql`
    fragment F_ADMIN_USER_DETAIL on AdminUser {
        ...F_ADMIN_USER
        jobType
        orderStatusManagement
        # deliveryStatusManagement
        lastLogin
        authenticationCode
        branch {
            id
            name
            code
        }
        specialAccess {
            status
            code
            expired
            specialAccessType
        }
        defaultWorkingSchedule {
            date
            branch {
                id
                name
            }
            schedule {
                start
                end
            }
        }
        # jobCostFee {
        #     template
        #     presets {
        #         id
        #         type
        #         role
        #         purchaseType
        #         primaryFee {
        #             feeType
        #             amount
        #             amountSecondary
        #             applySecondary
        #             unit
        #             unitQty
        #             isFlexible
        #             triggerThresholdQty
        #         }
        #         secondaryFee {
        #             feeType
        #             amount
        #             amountSecondary
        #             applySecondary
        #             unit
        #             unitQty
        #             isFlexible
        #             triggerThresholdQty
        #         }
        #     }
        # }
    }
    ${F_ADMIN_USER}
`;

export const F_ADMIN_USER_BASIC = gql`
    fragment F_ADMIN_USER_BASIC on AdminUserBasic {
        id
        adminNo
        firstName
        lastName
        nickName
        avatar
        companyId
        jobType
    }
`;

export const F_ADMIN_USER_SCHEDULE = gql`
    fragment F_ADMIN_USER_SCHEDULE on UserEquipmentScheduleDto {
        day
        isWorking
        workingSchedule {
            date
            branch {
                id
                name
                code
            }
            schedule {
                start
                end
            }
        }
        occupiedSlots {
            ref
            refId
            schedule {
                start
                end
            }
        }
    }
`;
