import classNames from "classnames";
import { map } from "lodash";
import React from "react";
import { LANGUAGES } from "../../constant/app";
import Image from "../Image";

interface ILabelLanguage {
    label?: any;
    language: any;
    onChange: any;
    className?: any;
    classNameLabel?: any;
}

const LabelLanguage = ({
    label,
    className,
    classNameLabel,
    language,
    onChange,
}: ILabelLanguage) => {
    return (
        <div className={classNames("flex-row-between-center", className)}>
            {label && <label className={classNameLabel}>{label}</label>}
            <div className="d-flex">
                {map(LANGUAGES, (languageItem) => {
                    const isSelect = languageItem?.id === language;
                    return (
                        <div
                            className={classNames(
                                { "bg-primary": isSelect },
                                "p-1 cursor-pointer"
                            )}
                            onClick={() => onChange(languageItem?.id)}
                        >
                            <Image
                                src={languageItem.icon}
                                className="image-square-xx-small object-fit-contain"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default LabelLanguage;
