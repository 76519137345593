import { isEmpty } from "lodash";
import { ORDER_PAYMENT_STATUS, PAYMENT_CONFIRM_STATUS, REFUND_STATUS } from "../constant/payment";

export const getPaidFromPayments = (payments: any[]) => {
  if (!payments || payments.length === 0) return 0;
  let paid = 0;
  payments.forEach((item) => {
    if (item.status === PAYMENT_CONFIRM_STATUS.APPROVE) {
      paid += item.total;
    }
  });
  return paid;
};

export const getRefundedAmountFromRefunds = (refunds: any[]) => {
  if (!refunds || refunds.length === 0) return 0;
  let paid = 0;
  refunds.forEach((item) => {
    if (
      item.status !== REFUND_STATUS.CANCELLED &&
      item.status !== REFUND_STATUS.REJECTED
    ) {
      paid += item?.amount;
    }
  });
  return paid;
};

// export const getPaymentStatus = (paymentStatus: any) => {
//   if (!isEmpty(paymentStatus)) {
//     // eslint-disable-next-line no-restricted-syntax
//     for (const key in ORDER_PAYMENT_STATUS) {
//       if (ORDER_PAYMENT_STATUS[key] === paymentStatus) {
//         return ORDER_PAYMENT_STATUS[key];
//       }
//     }
//     return ORDER_PAYMENT_STATUS.PENDING_PAYMENT;
//   }
//   return ORDER_PAYMENT_STATUS.PENDING_PAYMENT;
// };