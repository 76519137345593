import { Pagination } from "antd";
import {
    Button,
    DialogManager,
    IDrawerProps,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import { useContext, useEffect, useState } from "react";
import BookingAPI from "../../../api/queries/booking";
import TreatmentAPI from "../../../api/queries/treatment";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { TreatmentDetailContext } from "../../../context/treatment";
import { NoteSchema } from "../../../formschema/booking";
import { INote } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";

export const SIZE_OPTIONS = [20, 40, 60];

interface ITreatmentNoteCrudDrawer {
    note?: INote;
    open: boolean;
    onClose: () => void;
    onSave: (values: any) => void;
    inputType?: "normal" | "richText";
    drawerProps?: Partial<IDrawerProps>;
}
export interface INoteItem {
    note: INote;
    onUpdated: () => void;
}

export const TreatmentNoteCrudDrawer = ({
    note,
    open,
    onClose,
    onSave,
    inputType = "normal",
    drawerProps = {},
}: ITreatmentNoteCrudDrawer) => {
    const noteForm = useFormik<any>({
        initialValues: note ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: NoteSchema,
        onSubmit: (values: any) => {
            const input = { title: values?.title, content: values?.content };
            onSave && onSave(input);
            onClose();
        },
    });
    const formValues = noteForm?.values;
    const formErrors = noteForm?.errors;

    const classNameInput = "col-12 mt-3";
    return (
        <Drawer
            {...drawerProps}
            open={open}
            onClose={onClose}
            title={Messages.treatmentNote}
            onSave={() => noteForm.handleSubmit()}
            destroyOnClose
        >
            <div className="rows">
                <InputTextForm
                    keyData="title"
                    form={noteForm}
                    className={classNameInput}
                />
                {inputType === "normal" && (
                    <InputTextForm
                        keyData="content"
                        form={noteForm}
                        multiple
                        className={classNameInput}
                    />
                )}
                {inputType === "richText" && (
                    <div className="col-12 mt-3">
                        <RichTextTiny
                            label={Messages.content}
                            value={noteForm?.values?.content ?? ""}
                            onChange={(value: any) =>
                                noteForm.setFieldValue(
                                    `content`,
                                    value.toString("html")
                                )
                            }
                            placeholder={Messages.content}
                            error={noteForm?.errors?.content}
                        />
                    </div>
                )}
            </div>
        </Drawer>
    );
};

const NoteItem = ({ note, onUpdated }: INoteItem) => {
    const [openUpdate, setOpenUpdate] = useState(false);

    const onUpdateNote = (input: any) => {
        Progress.show(
            { method: BookingAPI.updateNote, params: [note?.id, input] },
            () => {
                onUpdated();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    const onRemoveNote = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    { method: BookingAPI.removeNote, params: [note?.id] },
                    () => {
                        onUpdated();
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    return (
        <div className="note-item__container border-top py-3">
            <div className=" w-100 flex-row-between-center">
                <label>{note?.title}</label>
                <small>{TimeUtils.toDateTime(note?.createdAt)}</small>
            </div>
            <div className="text mt-1">{note?.content}</div>
            <div className="note-item__edit-delete">
                <Button
                    onClick={() => setOpenUpdate(true)}
                    className="mr-1"
                    iconName="edit"
                    size="x-small"
                />
                <Button
                    onClick={onRemoveNote}
                    iconName="delete"
                    size="x-small"
                />
            </div>
            {openUpdate && (
                <TreatmentNoteCrudDrawer
                    note={note}
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                    onSave={onUpdateNote}
                />
            )}
        </div>
    );
};

const TreatmentDetailNote = () => {
    const { treatment } = useContext(TreatmentDetailContext);
    const [noteList, setNoteList] = useState<INote[]>([]);
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [openAdd, setOpenAdd] = useState(false);

    useEffect(() => {
        loadNoteList();
    }, [pagination]);

    const loadNoteList = () => {
        TreatmentAPI.noteList(treatment?.id, {
            limit: pagination?.pageSize,
            page: pagination?.pageIndex,
        }).then((res) => {
            const noteListRes = res?.data?.data?.data ?? [];
            const total = res?.data?.data?.pagination?.items ?? 0;
            setNoteList(noteListRes);
            setTotal(total);
        });
    };

    const onAddNote = (input: any) => {
        Progress.show(
            { method: TreatmentAPI.createNote, params: [treatment?.id, input] },
            () => {
                loadNoteList();
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    return (
        <div className="mt-3 border p-4">
            <div className="flex-row-between-center">
                <label>{Messages.note}</label>
                <Button onClick={() => setOpenAdd(true)}>{Messages.add}</Button>
            </div>
            <div className="flex-column mt-3">
                {map(noteList, (node) => (
                    <NoteItem note={node} onUpdated={loadNoteList} />
                ))}
            </div>
            <div className="d-flex justify-end mt-3">
                <Pagination
                    showTotal={(total: any) => (
                        <div className="captionText">{`Total ${total} items`}</div>
                    )}
                    current={pagination.pageIndex}
                    total={total}
                    pageSizeOptions={SIZE_OPTIONS}
                    onChange={(pageIndex, pageSize) =>
                        setPagination({ pageIndex, pageSize })
                    }
                    pageSize={pagination.pageSize}
                />
            </div>
            {openAdd && (
                <TreatmentNoteCrudDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    onSave={onAddNote}
                />
            )}
        </div>
    );
};

export default TreatmentDetailNote;
