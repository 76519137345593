import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import {
    debounce,
    filter,
    includes,
    isEmpty,
    map,
    sortBy,
    toLower,
} from "lodash";
import { useContext, useMemo, useState } from "react";
import { JobCostTemplate, ServiceSurgeryType } from "../../../api/hooks";
import TreatmentAPI from "../../../api/queries/treatment";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import {
    JOB_COST_NOT_APPLY_FOR_LA_SURGERY,
    JOB_COST_TYPES,
} from "../../../constant/service";
import { TreatmentDetailContext } from "../../../context/treatment";
import {
    ITreatmentUser,
    mapTreatmentPicToServer,
} from "../../../interfaces/treatment";
import { JobType } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";
import {
    UserJobCostRoleView,
    UserJobTypeView,
} from "../../user/share/UserSubView";
import TreatmentUserSelect from "../share/TreatmentUserSelect";

interface ITreatmentPicAddDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue?: any;
}

export const TREATMENT_PIC_JOB_TYPES = [
    JobType.NORMAL_EMPLOYEE,
    JobType.DRIVER,
    JobType.NURSE,
    JobType.THERAPIST,
    JobType.DOCTOR,
];

const TreatmentPicAddDrawer = ({
    open,
    onClose,
    defaultValue,
}: ITreatmentPicAddDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const { services } = treatment || {};
    const { serviceConfiguration } = services?.[0] ?? {};
    const jobCostTypes = useMemo(() => {
        if (
            serviceConfiguration?.jobCostTemplate ===
                JobCostTemplate.Jc2Template &&
            serviceConfiguration?.surgeryType === ServiceSurgeryType.La
        ) {
            return JOB_COST_TYPES.filter((item) => {
                return (
                    !JOB_COST_NOT_APPLY_FOR_LA_SURGERY.includes(item?.id) &&
                    item?.templates?.includes(
                        serviceConfiguration?.jobCostTemplate
                    )
                );
            });
        }

        const jobCostTypeFilter = JOB_COST_TYPES.filter((item) =>
            item?.templates?.includes(
                serviceConfiguration?.jobCostTemplate as any
            )
        );

        const jobCostTypeSort = sortBy(jobCostTypeFilter, (item) => item.id);
        return jobCostTypeSort;
    }, [
        serviceConfiguration?.jobCostTemplate,
        serviceConfiguration?.surgeryType,
    ]);

    const picForm = useFormik<any>({
        initialValues: { ...(defaultValue || {}) } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapTreatmentPicToServer(values);
            onAddPicTreatment(input);
        },
    });
    const onAddPicTreatment = (input: any) => {
        const body = {
            treatmentId: treatment?.id,
            payload: [input],
        };

        Progress.show(
            {
                method: TreatmentAPI.updatePic,
                params: [body],
            },
            (treatmentRes: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                setTreatment((prev: any) => ({ ...prev, ...treatmentRes }));
                onClose();
            }
        );
    };
    const { values, errors, setFieldValue } = picForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.pic}
            onSave={() => picForm.handleSubmit()}
            size="auto"
            width="80%"
            // disableSave={isEmpty(picForm?.values?.timeSlot)}
        >
            <div className="p-4">
                <TreatmentUserSelect
                    className="mt-3"
                    branch={treatment?.branch}
                    date={treatment?.scheduleDate}
                    form={picForm}
                    userJobTypes={TREATMENT_PIC_JOB_TYPES}
                />
                {values?.user && (
                    <InputSelectForm
                        multiple
                        label={Messages.jobRoleInTheTreatment}
                        form={picForm}
                        keyData="jobCostType"
                        dataSource={jobCostTypes}
                        className="mt-3"
                    />
                )}
            </div>
        </Drawer>
    );
};

const TreatmentDetailPic = () => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);
    const [openAdd, setOpenAdd] = useState<{ open: boolean; data?: any }>({
        open: false,
    });
    const [textSearch, setTextSearch] = useState("");
    const { doctor, PIC } = treatment || {};

    const columns: IColumnsProps = [
        {
            title: Messages.staff,
            dataIndex: "",
            render: (user: ITreatmentUser) => (
                <UserAvatarName user={user.adminUser} />
            ),
        },
        {
            title: Messages.jobType,
            dataIndex: "",
            render: (user: ITreatmentUser) => (
                <UserJobTypeView jobType={user?.adminUser?.jobType} />
            ),
        },
        {
            title: Messages.jobCostRole,
            dataIndex: "",
            width: 300,
            render: (user: ITreatmentUser) => {
                const { jobCost } = user || {};
                if (!jobCost || !jobCost.length) {
                    return "N/A";
                }
                return (
                    <div className="text-nowrap max-w-xl">
                        {map(jobCost, (i) => {
                            return (
                                <UserJobCostRoleView
                                    jobCostRole={i?.type}
                                    className="mt-2"
                                />
                            );
                        })}
                    </div>
                );
            },
        },
        {
            title: Messages.bookedSchedule,
            dataIndex: "schedule",
            render: (schedule: ITreatmentUser["schedule"]) =>
                `${fmtMSS(schedule?.start)} - ${fmtMSS(schedule?.end)}`,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (pic, data) => {
                if (pic?.isDoctor) {
                    return <div />;
                }
                return (
                    <div className="flex-center-y">
                        <Icon
                            name="edit"
                            className="cursor-pointer mr-3"
                            onClick={() => {
                                const mappedData = {
                                    user: {
                                        ...(data?.adminUser ?? {}),
                                        schedule: data?.schedule ?? {},
                                    },
                                    jobCostType: map(
                                        data?.jobCost ?? [],
                                        (i) => i?.type
                                    ),
                                };
                                setOpenAdd({ open: true, data: mappedData });
                            }}
                        />
                        <Icon
                            name="remove_circle"
                            color="secondary"
                            className="cursor-pointer"
                            onClick={() => onRemove(pic)}
                        />
                    </div>
                );
            },
        },
    ];

    const onRemove = (pic: ITreatmentUser) => {
        const body = {
            treatmentId: treatment?.id,
            picId: pic.adminUser?.id,
        };
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    { method: TreatmentAPI.removePic, params: [body] },
                    (treatmentRes) => {
                        setTreatment((prev: any) => ({
                            ...prev,
                            ...treatmentRes,
                        }));
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const picDataSource = useMemo(() => {
        const allPics = PIC ? [...PIC] : [];
        if (doctor?.adminUser) {
            allPics.unshift({ ...doctor, isDoctor: true } as any);
        }
        if (isEmpty(textSearch)) {
            return allPics ?? [];
        }

        return filter(allPics ?? [], (user) =>
            includes(
                toLower(
                    user?.adminUser?.firstName +
                        user?.adminUser?.firstName +
                        user?.adminUser?.nickName
                ),
                toLower(textSearch)
            )
        );
    }, [textSearch, doctor, PIC]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.pic}</label>
                <Button onClick={() => setOpenAdd({ open: true })} className="">
                    {Messages.add}
                </Button>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={picDataSource}
                className="mt-3"
                pagination={false}
            />
            {openAdd?.open && (
                <TreatmentPicAddDrawer
                    open={openAdd.open}
                    onClose={() => setOpenAdd({ open: false })}
                    defaultValue={openAdd?.data}
                />
            )}
        </div>
    );
};

export default TreatmentDetailPic;
