import { Button } from "d-react-components";
import React, { useContext, useMemo } from "react";
import { useImportContext } from "./context";
import Messages from "../../languages/Messages";

export const INIT_IMPORT_FORM = {
    importFields: [],
    importType: null,
    fileImport: null,
};

const ImportFooter = () => {
    const { stepIndex, setStepIndex, formImport, importData } =
        useImportContext();
    const stepNextValue = useMemo(
        () => importData?.steps?.[stepIndex + 1],
        [stepIndex]
    );
    const classNameFooter =
        "d-flex justify-content-end align-items-center p-4 bg-gray-light";

    const getDisabledNextStep = () => {
        const { importType, importFields, fileImport } = formImport.values;
        const { data, errors } = fileImport ?? {};

        switch (stepIndex) {
            case 0:
                return !importType || importFields?.length === 0;
            case 1:
                return !data;
            case 2:
                return !errors || errors?.length !== 0;
            default:
                return false;
        }
    };

    const onClickResetDefault = () => {
        setStepIndex(0);
        formImport.setValues(INIT_IMPORT_FORM);
    };

    if (stepIndex === 3) {
        return (
            <>
                <div className={classNameFooter}>
                    {stepIndex !== 0 && (
                        <Button
                            variant="outline"
                            iconName="chevron_left"
                            className="mr-3"
                            onClick={() => setStepIndex(stepIndex - 1)}
                        >
                            {Messages.previousStep}
                        </Button>
                    )}
                    <Button onClick={onClickResetDefault}>
                        {Messages.newImport}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <div className={classNameFooter}>
            {stepIndex !== 0 && (
                <Button
                    variant="outline"
                    iconName="chevron_left"
                    className="mr-3"
                    onClick={() => setStepIndex(stepIndex - 1)}
                >
                    {Messages.previousStep}
                </Button>
            )}
            <Button
                disabled={getDisabledNextStep()}
                onClick={() => setStepIndex(stepIndex + 1)}
            >{`Step ${stepIndex + 2}: ${
                Messages[stepNextValue?.title]
            }`}</Button>
        </div>
    );
};

export default ImportFooter;
