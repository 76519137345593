import { Table } from "antd";
import { IColumnsProps, TimeUtils } from "d-react-components";
import { useContext } from "react";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { QuotationDetailContext } from "../../../context/quotation";
import Messages from "../../../languages/Messages";

const QuotationSalePerson = () => {
    const { quotation } = useContext(QuotationDetailContext);
    const { createByAdmin, salesPerson } = quotation || {};

    const columns: IColumnsProps = [
        {
            title: Messages.staff,
            dataIndex: "id",
            render: (id, item) => <UserAvatarName size="x-small" user={item} />,
        },
        {
            title: Messages.employeeId,
            dataIndex: "companyId",
            render: (companyId) => companyId,
        },
        {
            title: Messages.createdAt,
            dataIndex: "",
            render: (item) => TimeUtils.convertMiliToDateTime(new Date()),
        },
    ];

    return (
        <div className="px-4 py-3 border my-3">
            <h5>{Messages.coSalePerson}</h5>
            <Table
                columns={columns}
                dataSource={[createByAdmin as any, salesPerson as any]}
                pagination={false}
            />
        </div>
    );
};

export default QuotationSalePerson;
