import { gql } from "@apollo/client";
import API from "../API";
import {
    F_PROMOTION,
    F_PROMOTIONS,
    F_VALIDATE_PROMOTION_PRODUCT,
} from "../fragments/promotion";

export const getPromotionList = gql`
    ${F_PROMOTIONS}
    query listPromotionForAdmin($paginate: PromotionPaginateRequest!) {
        data: listPromotionForAdmin(paginate: $paginate) {
            ...F_PROMOTIONS
        }
    }
`;

export const getPromotionDetail = gql`
    ${F_PROMOTION}
    query detailPromotionForAdmin($id: String!) {
        data: detailPromotionForAdmin(id: $id) {
            data {
                ...F_PROMOTION
            }
        }
    }
`;

export const createPromotion = gql`
    ${F_VALIDATE_PROMOTION_PRODUCT}
    mutation createPromotionForAdmin($payload: PromotionCreateRequest!) {
        data: createPromotionForAdmin(payload: $payload) {
            data {
                id
            }
            error {
                ...F_VALIDATE_PROMOTION_PRODUCT
            }
        }
    }
`;

export const updatePromotion = gql`
    ${F_PROMOTION}
    mutation updatePromotionForAdmin(
        $id: String!
        $payload: PromotionUpdateRequest!
    ) {
        data: updatePromotionForAdmin(id: $id, payload: $payload) {
            data {
                ...F_PROMOTION
            }
        }
    }
`;

export const stopPromotion = gql`
    ${F_PROMOTION}
    mutation stopPromotionProductsForAdmin($id: String!) {
        data: stopPromotionProductsForAdmin(id: $id) {
            data {
                ...F_PROMOTION
            }
        }
    }
`;

export const updatePromotionProducts = gql`
    ${F_PROMOTION}
    ${F_VALIDATE_PROMOTION_PRODUCT}
    mutation updatePromotionProductsForAdmin(
        $id: String!
        $products: [PromotionProductRequest!]!
    ) {
        data: updatePromotionProductsForAdmin(id: $id, products: $products) {
            data {
                ...F_PROMOTION
            }
            error {
                ...F_VALIDATE_PROMOTION_PRODUCT
            }
        }
    }
`;

export const validatePromotionProducts = gql`
    ${F_VALIDATE_PROMOTION_PRODUCT}
    query validatePromotionProductsForAdmin(
        $payload: ValidatePromotionProductRequest!
        $promotionId: String
    ) {
        data: validatePromotionProductsForAdmin(
            payload: $payload
            promotionId: $promotionId
        ) {
            data {
                ...F_VALIDATE_PROMOTION_PRODUCT
            }
        }
    }
`;

const PromotionAPI = {
    list: async (paginate: any) =>
        API.instance.query({
            query: getPromotionList,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.instance.query({
            query: getPromotionDetail,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.instance.mutate({
            mutation: createPromotion,
            variables: { payload },
        }),

    update: async (id: string, payload: any) =>
        API.instance.mutate({
            mutation: updatePromotion,
            variables: { id, payload },
        }),

    stop: async (id: string) =>
        API.instance.mutate({
            mutation: stopPromotion,
            variables: { id },
        }),

    updateProducts: async (id: string, products: any) =>
        API.instance.mutate({
            mutation: updatePromotionProducts,
            variables: { id, products },
        }),

    validateProducts: async (payload: any, promotionId?: string) =>
        API.instance.query({
            query: validatePromotionProducts,
            variables: { promotionId, payload },
            fetchPolicy: "no-cache",
        }),
};

export default PromotionAPI;
