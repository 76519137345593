import { Avatar, AvatarProps } from "d-react-components";
import React, { useContext } from "react";
import { AppStateContext } from "../../context/app";
import { IUser } from "../../interfaces/user";
import Messages from "../../languages/Messages";

interface IShowInfoConfig {
    showOPD?: boolean;
    showEmployeeId?: boolean;
}

interface IUserAvatarName {
    user: IUser;
    placeholder?: any;
    size?: AvatarProps["size"];
    variant?: AvatarProps["variant"];
    showInfo?: IShowInfoConfig;
    className?: string;
    classNameText?: string;
}

const UserAvatarName = ({
    user,
    placeholder = "N/A",
    size = "xx-small",
    variant = "rounded",
    showInfo = {},
    className,
    classNameText = "font-semibold",
}: IUserAvatarName) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    if (!user || !user?.id) return placeholder;
    const { firstName, lastName, companyId, nickName, id } = user ?? {};
    const { showOPD = false, showEmployeeId = false } = { ...showInfo };
    return (
        <div className={`flex-center-y ${className}`}>
            {user?.avatar ? (
                <Avatar
                    src={user?.avatar}
                    size={size}
                    variant={variant}
                    text={firstName}
                />
            ) : (
                <div className="image-square-x-small bg-gray-200 flex items-center justify-center">
                    <span className="!text-white text-center font-weight-normal h4">
                        {firstName?.charAt(0)}
                    </span>
                </div>
            )}
            <div className="ml-2 flex-column">
                <span
                    className={`block hover:cursor-pointer text-theme hover:text-blue-500 ${classNameText} `}
                    onClick={() => {
                        setOpenAppDrawer({ open: true, userId: id });
                    }}
                >
                    {`${firstName} ${lastName} (${nickName})`}
                </span>
                {showEmployeeId && (
                    <span className="block text-gray-400 text-sm opacity-75">{`${Messages.employeeId} : ${companyId}`}</span>
                )}
                {showOPD && (
                    <span className="block text-gray-400 text-sm opacity-75">{`${Messages.lastOpd}: `}</span>
                )}
            </div>
        </div>
    );
};

export default UserAvatarName;
