import { gql } from "@apollo/client";

export const F_ROLE = gql`
    fragment F_ROLE on AdminRole {
        name
        id
    }
`;

export const F_ROLE_LIST = gql`
    fragment F_ROLE_LIST on AdminRole {
        ...F_ROLE
        roleNo
        isAdmin
        updatedAt
        createdAt
    }
    ${F_ROLE}
`;

export const F_ROLE_DETAIL = gql`
    fragment F_ROLE_DETAIL on AdminRole {
        ...F_ROLE
        roleNo
        isAdmin
        updatedAt
        createdAt
        permissions
    }
    ${F_ROLE}
`;