import {
    ProductStockTransferStatus,
    ProductStockTransferType,
} from "../api/hooks";

export const STOCK_TRANSFER_STATUS = {
    PENDING: ProductStockTransferStatus.Pending,
    PARTIAL: ProductStockTransferStatus.Partial,
    PROCESSING: ProductStockTransferStatus.Processing,
    TRANSFERRED: ProductStockTransferStatus.Transferred,
    COMPLETED: ProductStockTransferStatus.Completed,
    CANCELLED: ProductStockTransferStatus.Cancelled,
};

export const STOCK_TRANSFER_STATUSES = [
    {
        id: ProductStockTransferStatus.Pending,
        label: "pending",
        color: "#FAC256",
    },

    {
        id: ProductStockTransferStatus.Partial,
        label: "partial",
        color: "#FAC256",
    },
    {
        id: ProductStockTransferStatus.Processing,
        label: "processing",
        color: "#84C4FF",
    },
    {
        id: ProductStockTransferStatus.Transferred,
        label: "transferred",
        color: "#33B950",
    },
    {
        id: ProductStockTransferStatus.Completed,
        label: "completed",
        color: "#33B950",
    },
    {
        id: ProductStockTransferStatus.Cancelled,
        label: "cancelled",
        color: "#C4C4C4",
    },
];

export const STOCK_TRANSFER_TYPE = {
    STOCK_IN: ProductStockTransferType.StockIn,
    RETURNING_GOODS: ProductStockTransferType.ReturningGoods,
};

export const STOCK_TRANSFER_TYPES = [
    { id: STOCK_TRANSFER_TYPE.RETURNING_GOODS, label: "returningGoods" },
    { id: STOCK_TRANSFER_TYPE.STOCK_IN, label: "stockIn" },
];
