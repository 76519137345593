import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import React from "react";
// import Skeleton from "react-loading-skeleton";
import { generatePath, Link } from "react-router-dom";
import { useUpdateAdminUserMutation } from "../../../api/hooks";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { USER_STATUS, USER_STATUSES } from "../../../constant/user";
import IUser from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

interface CustomerProps {
    user: IUser;
}

export const UserSummaryView = ({ user }: CustomerProps) => {
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const CUSTOMER_GENERAL_KEY: IRowsKey<IUser>[] = [
        {
            id: "companyId",
            label: Messages.employeeId,
        },
        {
            id: "username",
            label: Messages.username,
        },
        {
            id: "firstName",
            label: Messages.firstName,
        },
        {
            id: "lastName",
            label: Messages.lastName,
        },
        {
            id: "nickName",
            label: Messages.nickName,
            renderContent: ({ data }) => data ?? "N/A",
        },
        {
            id: "status",
            label: Messages.status,
            renderContent: ({ data }) => (
                <div className="float-right">
                    <ViewLabelStatus
                        status={
                            data ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE
                        }
                        listStatus={USER_STATUSES}
                        getLabel={(item) => (Messages as any)[item.label]}
                    />
                </div>
            ),
        },
        {
            id: "lastLogin",
            label: Messages.lastLogin,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.updatedAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <React.Fragment>
            {user && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 border-b py-5 px-3">
                        <UploadAvatar
                            onChange={(value: any) => {
                                updateAdminUser({
                                    variables: {
                                        id: user.id as string,
                                        payload: {
                                            avatar: value?.fileUrl,
                                        },
                                    },
                                });
                            }}
                            value={user?.avatar}
                            className="w-[72px] h-[72px]"
                            text={user.firstName}
                        />
                        <div className="text-large-bold mt-3">
                            <Link
                                to={generatePath(Path.USER_DETAIL, {
                                    userId: user?.id,
                                })}
                            >
                                {user.firstName} {user.lastName}
                            </Link>
                        </div>
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={user}
                            keyList={CUSTOMER_GENERAL_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
            {/* {loading && <Skeleton count={5} />} */}
        </React.Fragment>
    );
};

export default UserSummaryView;
