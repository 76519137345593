import { Button, Header, Notifications, Progress } from "d-react-components";
import { Formik } from "formik";
import { forEach, isUndefined, omit, pick } from "lodash";
import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
    ShippingType,
    useCreateQuotationForAdminMutation,
    useCreateShippingForAdminMutation,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import { PurchaseType } from "../../../constant/quotation";
import { PERMISSION } from "../../../constant/user";
import { AppStateContext } from "../../../context/app";
import {
    IOrderMetrics,
    QuotationCreateContext,
} from "../../../context/quotation";
import { useCheckCartValue } from "../../../hooks/order";
import { mapAddressToSer } from "../../../interfaces/address";
import { CartValueState } from "../../../interfaces/order";
import { getProductPrice, IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import QuotationCreateHeader from "./QuotationCreateHeader";
import QuotationCreateProductList from "./QuotationCreateProductList";
import QuotationProductsCart from "./QuotationProductsCart";
import { getManualDiscountPayload } from "../../../utils/order";

const QuotationSchema = Yup.object().shape({
    store: Yup.mixed().required(Messages.fieldRequired),
    // typeOfTreatment: Yup.mixed().required(Messages.fieldRequired),
    purchaseTypes: Yup.mixed().required(Messages.fieldRequired),
    customer: Yup.mixed().required(Messages.fieldRequired),
    primarySalesPerson: Yup.mixed().required(Messages.fieldRequired),
    sourceOfCustomer: Yup.string().required(Messages.fieldRequired),
    agency: Yup.mixed().when(
        "purchaseTypes",
        (purchaseTypes: string, yup: any) => {
            if (purchaseTypes === PurchaseType.AGENCY) {
                return Yup.mixed().required(Messages.fieldRequired);
            }
            return Yup.mixed().nullable();
        }
    ),
});

const QuotationCreate = () => {
    const params = useParams();
    const { me } = useContext(AppStateContext);
    const [productList, setProductList] = useState<IProduct[]>([]);
    const [productCustomList, setProductCustomList] = useState<IProduct[]>([]);
    const [cartValue, setCartValue] = useState<CartValueState>({
        total: 0,
        subtotal: 0,
    });
    const [manualDiscount, setManualDiscount] = useState<any>();
    const [orderMetrics, setOrderMetrics] = useState<IOrderMetrics>();
    const [shipping, setShipping] = useState<any[]>([]);
    const navigate = useNavigate();
    const [createShipping] = useCreateShippingForAdminMutation();
    const isEdit = !isUndefined(params.quotationId);
    const [createQuotation] = useCreateQuotationForAdminMutation();
    const [productFilters, setProductFilters] = useState({
        viewMode: "grid",
    });
    const { checkCartValue } = useCheckCartValue({
        setCartValue,
        productList,
        productCustomList,
        manualDiscount,
        cartValue,
    });

    const onUpdateQuotation = (values: any) => {};
    const onCreateQuotation = (values: any) => {
        if (!productList.length && !productCustomList.length) {
            Notifications.showError(Messages.youHaveToChooseProductsFirst);
        }
        const {
            store,
            purchaseTypes,
            // typeOfTreatment,
            customer,
            remark,
            agency,
            primarySalesPerson,
            sourceOfCustomer,
        } = values;
        Progress.show(
            {
                method: createQuotation,
                params: [
                    {
                        variables: {
                            payload: {
                                store: store?.id,
                                purchaseType: purchaseTypes,
                                agency: agency?.id,
                                salesPerson: primarySalesPerson?.id,
                                sourceOfCustomer,
                                // typeOfTreatment: typeOfTreatment?.[0]?.id,
                                ...(customer?.[0]?.id
                                    ? { customer: customer?.[0]?.id }
                                    : {
                                          contact: {
                                              ...(customer?.[0] || {}),
                                              nationality:
                                                  customer?.[0]?.nationality
                                                      ?.id,
                                          },
                                      }),
                                remark,
                                ...(productList?.length
                                    ? {
                                          products: productList.map(
                                              (product: any) => ({
                                                  product: product.id,
                                                  promotion:
                                                      product?.promotion ??
                                                      null,
                                                  quantity: product.quantity,
                                                  hasOpeningPrice:
                                                      !!product.openingPrice,
                                                  salePrice:
                                                      product.openingPrice ||
                                                      getProductPrice(
                                                          "salePrice",
                                                          product,
                                                          orderMetrics?.applyPriceSet
                                                      ),
                                                  ...(isUndefined(
                                                      product.isGift
                                                  )
                                                      ? {}
                                                      : {
                                                            isGift: product.isGift,
                                                        }),
                                              })
                                          ),
                                      }
                                    : { products: [] }),
                                ...(productCustomList?.length
                                    ? {
                                          manualProduct: productCustomList.map(
                                              (product: any) =>
                                                  pick(product, [
                                                      "name",
                                                      "quantity",
                                                      "remark",
                                                      "salePrice",
                                                      "sku",
                                                      "isNonVAT",
                                                  ])
                                          ),
                                      }
                                    : {}),
                                ...getManualDiscountPayload(
                                    manualDiscount,
                                    productList
                                ),
                            },
                        },
                    },
                ],
            },
            (resp: any) => {
                Notifications.showSuccess(Messages.createQuotationSuccessfull);
                const quotationId = resp?.data?.data?.data?.id;
                if (shipping && shipping.length) {
                    forEach(shipping, async (shippingItem) => {
                        await createShipping({
                            variables: {
                                refId: quotationId,
                                shippingType: ShippingType.Quotation as any,
                                payload: omit(
                                    mapAddressToSer(shippingItem),
                                    "isDefault"
                                ),
                            },
                        }).catch((err) => {
                            Notifications.showError(err);
                        });
                    });
                }
                navigate(
                    generatePath(Path.QUOTATION_DETAIL, {
                        quotationId: resp?.data?.data?.data?.id,
                    })
                );
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <QuotationCreateContext.Provider
            value={{
                productFilters,
                setProductFilters,
                productList,
                setProductList,
                productCustomList,
                setProductCustomList,
                cartValue,
                setCartValue,
                checkCartValue,
                shipping,
                setShipping,
                manualDiscount,
                setManualDiscount,
                orderMetrics,
                setOrderMetrics,
            }}
        >
            <Formik
                initialValues={{
                    isSameShippingAddress: true,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={QuotationSchema}
                onSubmit={(values) => {
                    isEdit
                        ? onUpdateQuotation(values)
                        : onCreateQuotation(values);
                }}
            >
                {({ handleSubmit }) => (
                    <>
                        <Header
                            className="sticky top-0"
                            showCancel={false}
                            title={Messages.newQuotation}
                            customRight={() => (
                                <>
                                    <Button
                                        size="small"
                                        className="ml-2"
                                        variant="outline"
                                        onClick={() => navigate(-1)}
                                    >
                                        {Messages.back}
                                    </Button>
                                    <PermissibleRender
                                        permission={PERMISSION.QUOTATION.CREATE}
                                    >
                                        <Button
                                            size="small"
                                            className="ml-2"
                                            onClick={() => handleSubmit()}
                                        >
                                            {Messages.save}
                                        </Button>
                                    </PermissibleRender>
                                </>
                            )}
                        />
                        <div className="grid grid-cols-3 gap-4 p-4">
                            <div className="col-span-2">
                                <QuotationCreateHeader />
                                <QuotationCreateProductList />
                            </div>
                            <div className="col-span-1">
                                <QuotationProductsCart />
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </QuotationCreateContext.Provider>
    );
};

export default QuotationCreate;
