import {
    Button,
    DateInput,
    Header,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { map, values } from "lodash";
import { generatePath, useNavigate } from "react-router";
import { useFormik } from "formik";
import Messages from "../../../languages/Messages";
import PermissibleRender from "../../../common/PermissibleComponent";
import { PERMISSION } from "../../../constant/user";
import { MaterialAdjustmentItems } from "../../material-adjustment-request/create/MaterialAdjustmentRequestCreate";
import UploadButton from "../../../common/upload/UploadButton";
import InputTextForm from "../../../common/input/InputTextForm";
import { useCreateMaterialPurchaseRequestMutation } from "../../../api/hooks";
import Path from "../../Path";
import BranchSelect from "../../branch/share/BranchSelect";

export const MaterialRequestSchema = Yup.object().shape({
    remark: Yup.string().required("Required field!"),
    materials: Yup.array().min(1).required("Required field!"),
    attachments: Yup.array().min(1).required("Required field!"),
    expectedDateOfArrival: Yup.string().required("Required field!"),
    branch: Yup.mixed().required("Required field!"),
});

export const MaterialRequestGeneralInfoSchema = Yup.object().shape({
    remark: Yup.string().required("Required field!"),
    attachments: Yup.array().min(1).required("Required field!"),
    expectedDateOfArrival: Yup.string().required("Required field!"),
});

export const mapRequestToServer = (values: any) => {
    const { expectedDateOfArrival, materials, attachments, remark, branch } =
        values;
    return {
        remark: remark || null,
        expectedDateOfArrival: moment(expectedDateOfArrival).toDate(),
        branch: branch?.id,
        attachments:
            attachments?.length > 0
                ? map(attachments, (att) => att?.fileUrl)
                : null,
        ...(materials
            ? {
                  materials: map(materials, (pro) => ({
                      material: pro?.id,
                      quantity: pro?.quantity,
                  })),
              }
            : {}),
    };
};

const MaterialRequestCreate = () => {
    const navigate = useNavigate();
    const [createMaterialRequest] = useCreateMaterialPurchaseRequestMutation();
    const requestForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MaterialRequestSchema,
        onSubmit: (values: any) => {
            const payload = mapRequestToServer(values);
            Progress.show(
                {
                    method: createMaterialRequest,
                    params: [
                        {
                            variables: {
                                payload,
                            },
                        },
                    ],
                },
                (res: any) => {
                    navigate(
                        generatePath(Path.MATERIAL_REQUEST_DETAIL, {
                            requestId: res?.data?.data?.data?.id,
                        })
                    );
                    Notifications.showSuccess(Messages.createSuccess);
                }
            );
        },
    });

    const {
        values: formValues,
        errors: formErrors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = requestForm;

    const classNameInput = "mt-3";

    return (
        <div className="flex-column-container bg-white w-100">
            <Header
                className="sticky top-0"
                showCancel={false}
                title={Messages.newMaterialRequest}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={
                                PERMISSION.MATERIAL_PURCHASE_REQUEST.CREATE
                            }
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-4">
                <BranchSelect
                    value={formValues.branch}
                    onChange={(value) => setFieldValue("branch", value)}
                    error={formErrors.branch}
                    className="mb-3"
                />
                <MaterialAdjustmentItems
                    forForm="request"
                    form={requestForm}
                    requireBranch={false}
                />
                <DateInput
                    label={Messages.expectedDateOfArrival}
                    value={formValues.expectedDateOfArrival}
                    onChange={(value) =>
                        setFieldValue("expectedDateOfArrival", value)
                    }
                    className={classNameInput}
                    error={formErrors.expectedDateOfArrival as string}
                />
                <div className={classNameInput}>
                    <label>{Messages.attachment}</label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) => {
                            requestForm.setFieldValue("attachments", file);
                        }}
                        defaultFiles={formValues?.attachments ?? []}
                    />
                    <ViewTextError error={formErrors.attachments} />
                </div>

                <InputTextForm
                    form={requestForm}
                    keyData="remark"
                    multiple
                    className={classNameInput}
                />
            </div>
        </div>
    );
};

export default MaterialRequestCreate;
