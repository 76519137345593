import {
    Button,
    Icon,
    InputText,
    Notifications,
    StringUtils,
    TimeUtils,
} from "d-react-components";
import moment from "moment";
import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import { ENV } from "../../../configuration/AppConfig";
import DevConfig from "../../../configuration/DevConfig";
import ProConfig from "../../../configuration/ProConfig";
import StagingConfig from "../../../configuration/StagingConfig";
import {
    ICustomer,
    ISecretCode,
    SecretCodeStatus,
} from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

export interface ISecretCodeViewProps {
    variant: "selfRegistration" | "updateInfo" | "healthAssessment";
    secretCode: ISecretCode;
    className?: string;
    customer?: ICustomer;
    onRegenerateQrCode?: any;
    onChangeExpiryTime?: any;
}

const getConfigUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case ENV.PRO:
            return ProConfig.CUSTOMER_PAGE_URL;
        case ENV.STAGING:
            return StagingConfig.CUSTOMER_PAGE_URL;
        default:
            return DevConfig.CUSTOMER_PAGE_URL;
    }
};

const SecretCodeView: React.FC<ISecretCodeViewProps> = ({
    secretCode,
    className,
    customer,
    onRegenerateQrCode,
    onChangeExpiryTime,
    variant,
}) => {
    const { code, expiryTime, status } = secretCode || {};
    const LINK = [
        {
            id: "selfRegistration",
            link: `${getConfigUrl()}/sr`,
        },
        {
            id: "updateInfo",
            link: `${getConfigUrl()}/ui/${customer?.id}`,
        },
        {
            id: "healthAssessment",
            link: `${getConfigUrl()}/ha/${customer?.id}`,
        },
    ];
    const qrCode = useMemo(() => {
        return LINK.find((i) => i?.id === variant)?.link;
    }, [variant]);

    const getDifferTime = () => {
        const { hours, minutes } = TimeUtils.calculatePreciseDifferentTime({
            from: new Date() as any,
            to: expiryTime as any,
        });
        return `${hours} ${Messages.hours} ${minutes} ${Messages.minutes}`;
    };

    const renderButtons = () => {
        return (
            <div className="flex-center-y mt-3">
                {onRegenerateQrCode && (
                    <Button
                        onClick={() => {
                            onRegenerateQrCode && onRegenerateQrCode();
                        }}
                        className="mx-2"
                    >
                        {Messages.regenerateCode}
                    </Button>
                )}
                {onChangeExpiryTime && (
                    <Button
                        onClick={() => {
                            onChangeExpiryTime && onChangeExpiryTime();
                        }}
                        className="mx-2"
                    >
                        {Messages.changeExpiryTime}
                    </Button>
                )}
                <Button onClick={() => {}} className="mx-2">
                    {Messages.sendSms}
                </Button>
                <Button onClick={() => {}} className="mx-2">
                    {Messages.sendEmail}
                </Button>
                <Button onClick={() => {}} className="ml-2">
                    {Messages.print}
                </Button>
            </div>
        );
    };

    return (
        <div className={`flex flex-col justify-center  w-full ${className}`}>
            <label className="mb-3">{Messages.qrCode}</label>
            <QRCode
                value={qrCode || ""}
                size={150}
                className="mt-3"
                onClick={() => window.open(qrCode, "_blank")}
            />
            <InputText
                className="mt-3"
                disabled
                value={code}
                label={Messages.secretCode}
                suffix={
                    <Icon
                        className="hover:cursor-pointer"
                        name="content_copy"
                        onClick={() => {
                            navigator.clipboard.writeText(code as any);
                            Notifications.showSuccess(
                                Messages.copiedToClipboard
                            );
                        }}
                    />
                }
            />
            {status === SecretCodeStatus.ACTIVE ? (
                <div className="text-sm mt-3">
                    {Messages.thisQRCodeIsScheduled
                        ?.replace("$inTime", getDifferTime())
                        .replace(
                            "$time",
                            TimeUtils.convertMiliToDateTime(expiryTime)
                        )}
                </div>
            ) : (
                <div className="text-sm mt-3 text-error">
                    {Messages.codeIsExpired}
                </div>
            )}
            {renderButtons()}
        </div>
    );
};

export default SecretCodeView;
