/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Icon, StringUtils } from "d-react-components";
import Carousel, { Modal, ModalGateway } from "react-images";
import { map } from "lodash";
import React, { useState } from "react";
import { IMedia } from "../../interfaces/media";
import { IMAGE, PDF } from "../upload/UploadButton";

const ImageItemView = ({ media, onClick, itemClassName }: any) => {
    return (
        <div
            className="d-flex flex-column position-relative"
            onClick={onClick}
        >
            <img src={media} className={itemClassName} />
        </div>
    );
};

const FileItemView = ({ media, onClick, itemClassName }: any) => {
    return (
        <div className="d-flex flex-column position-relative" onClick={onClick}>
            <a href={media}>
                <img src={PDF.iconFile} className={itemClassName} />
            </a>
        </div>
    );
};

const VideoItemView = ({ videoLinkData }: any) => {
    const { thumbnail } = videoLinkData;
    return (
        <div className="mr-3">
            <div className="position-relative">
                <img src={thumbnail} className="image-square-x-large" />
                <div className="product-create__gallery-video-icon">
                    <Icon name="video_library" />
                </div>
            </div>
        </div>
    );
};

const Gallery = ({
    gallery,
    itemClassName = "w-16 h-16 mr-2",
}: {
    gallery: IMedia[];
    itemClassName?: string;
}) => {
    const [modalImageView, setModalImageView] = useState({
        isVisible: false,
        index: 0,
    });

    const renderGalleryList = () => {
        return (
            <div className="product-create__gallery-images">
                {map(gallery, (mediaItem: any, index: number) => {
                     const extension =
                         StringUtils.getExtensionFromFilename(mediaItem);
                    const isImage = IMAGE.extension.includes(extension);
                    // if (imageItem.isVideo) {
                    //     return (
                    //         <VideoItemView
                    //             index={index}
                    //             videoLinkData={imageItem}
                    //             onClick={() => {
                    //                 setModalImageView({
                    //                     isVisible: true,
                    //                     index,
                    //                 });
                    //             }}
                    //         />
                    //     );
                    // }
                    return isImage ? (
                        <ImageItemView
                            media={mediaItem}
                            key={index}
                            itemClassName={itemClassName}
                            onClick={() => {
                                setModalImageView({ isVisible: true, index });
                            }}
                        />
                    ) : (
                        <FileItemView
                            media={mediaItem}
                            key={index}
                            itemClassName={itemClassName}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className="product-create__gallery">
            {renderGalleryList()}
            {/* @ts-ignore */}
            <ModalGateway>
                {modalImageView.isVisible ? (
                    //  @ts-ignore
                    <Modal
                        onClose={() =>
                            setModalImageView({ index: 0, isVisible: false })
                        }
                    >
                        {/* @ts-ignore  */}
                        <Carousel
                            currentIndex={modalImageView.index}
                            views={gallery?.map((file: any) => ({
                                caption: "",
                                source: file,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
};

export default Gallery;
