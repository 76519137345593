import { gql } from "@apollo/client";
import API from "../API";
import {
    F_DELIVERY_DRIVER,
    F_DELIVERY_DRIVERS,
} from "../fragments/delivery-driver";

export const getDeliveryDriverList = gql`
    ${F_DELIVERY_DRIVERS}
    query getDeliveryDriverList($input: DeliveryPaginateRequest!) {
        data: listDriverDeliveryForAdmin(paginate: $input) {
            ...F_DELIVERY_DRIVERS
        }
    }
`;

export const getDeliveryDriverDetail = gql`
    ${F_DELIVERY_DRIVER}
    query getDeliveryDriverDetail($id: String!) {
        data: detailDriverDeliveryForAdmin(id: $id) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

export const originConfirmDeliveryDriver = gql`
    ${F_DELIVERY_DRIVER}
    mutation originConfirmDriverDelivery(
        $deliveryId: String!
        $payload: DeliveryConfirmationRequest!
    ) {
        data: originConfirmDriverDeliveryForAdmin(
            payload: $payload
            deliveryId: $deliveryId
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

const deliveryDriverListConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const deliveryDriverConverter = {
    fromServer: (res: any) => {
        return res?.data?.data?.data ?? {};
    },
};

export const confirmPickUpOriginProductStockDelivery = gql`
    ${F_DELIVERY_DRIVER}
    mutation confirmPickUpOriginProductStockDeliveryForAdmin(
        $id: String!
        $payload: DeliveryConfirmationRequest!
    ) {
        data: confirmPickUpOriginProductStockDeliveryForAdmin(
            id: $id
            payload: $payload
        ) {
            data {
                ...F_DELIVERY_DRIVER
            }
        }
    }
`;

const DeliveryDriverAPI = {
    list: async (input: any) =>
        API.withConverter(deliveryDriverListConverter).query({
            query: getDeliveryDriverList,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(deliveryDriverConverter).query({
            query: getDeliveryDriverDetail,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    originConfirmEquipment: async (input: any) =>
        API.withConverter(deliveryDriverConverter).mutate({
            mutation: originConfirmDeliveryDriver,
            variables: { ...input },
        }),

    originConfirmStock: async (input: any) =>
        API.withConverter(deliveryDriverConverter).mutate({
            mutation: confirmPickUpOriginProductStockDelivery,
            variables: { ...input },
        }),
};

export default DeliveryDriverAPI;
