import { ViewLabelStatus } from "d-react-components";
import { useContext } from "react";
import { ActionButton } from "../../../common/ActionButton";
import { VENDOR_STATUSES } from "../../../constant/vendor";
import { VendorDetailContext } from "../../../context/vendor";
import Messages from "../../../languages/Messages";

const VendorDetailHeader = () => {
    const { vendor } = useContext(VendorDetailContext);

    return (
        <div className="bg-white px-4 py-3 flex-row-between-center border-bottom">
            <div className="flex flex-1 align-items-center">
                <h5 className="text-primary">{`${vendor?.vendorNo}`}</h5>
                <ViewLabelStatus
                    status={vendor?.status}
                    listStatus={VENDOR_STATUSES}
                    className="ml-3"
                />
            </div>
            <div className="d-flex">
                <ActionButton onClick={() => {}} className="ml-2">
                    {Messages.print}
                </ActionButton>
            </div>
        </div>
    );
};

export default VendorDetailHeader;
