import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import {
    useGetServiceCategoryLazyQuery,
    useUpdateServiceCategoryMutation,
    useCreateServiceCategoryMutation,
} from "../../../api/hooks";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { SERVICE_CATEGORY_STATUSES } from "../../../constant/service";
import { ServiceCategorySchema } from "../../../formschema/service";
import { mapServiceCategoryToServer } from "../../../interfaces/service";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const ServiceCategoryCrud = () => {
    const { categoryId } = useParams<any>();
    const navigate = useNavigate();
    const [getServiceCategory] = useGetServiceCategoryLazyQuery();
    const [updateServiceCategory] = useUpdateServiceCategoryMutation();
    const [createServiceCategory] = useCreateServiceCategoryMutation();
    const isEdit = !!categoryId;

    const categoryForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ServiceCategorySchema,
        onSubmit: (values: any) => {
            const input = mapServiceCategoryToServer(values);
            isEdit ? onUpdateCategory(input) : onCreateCategory(input);
        },
    });

    const formValues = categoryForm?.values;
    const formErrors = categoryForm?.errors;

    useEffect(() => {
        if (isEdit) {
            loadServiceCategory();
        }
    }, [isEdit]);

    const loadServiceCategory = () => {
        Progress.show(
            {
                method: getServiceCategory,
                params: [{ variables: { id: categoryId } }],
            },
            (res: any) => {
                const category = res?.data?.data?.data ?? {};
                categoryForm.setValues(category);
            }
        );
    };

    const onUpdateCategory = (payload: any) => {
        Progress.show(
            {
                method: updateServiceCategory,
                params: [{ variables: { id: categoryId, payload } }],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.updateCategorySuccessful);
                categoryForm.setValues(res?.data?.data?.data);
            }
        );
    };

    const onCreateCategory = (payload: any) => {
        Progress.show(
            {
                method: createServiceCategory,
                params: [{ variables: { payload } }],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.createCategorySuccessful);
                navigate(
                    generatePath(Path.SERVICE_CATEGORY_DETAIL, {
                        categoryId: res?.data?.data?.data?.id,
                    })
                );
            }
        );
    };

    const classNameInput = "col-12 mt-3";
    return (
        <div className="flex-column-container bg-white w-100">
            <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                <h5 className="text-primary">{formValues?.name}</h5>
                <Button onClick={() => categoryForm.handleSubmit()}>
                    {isEdit ? Messages.update : Messages.create}
                </Button>
            </div>

            <div className="p-4 row">
                <InputTextForm
                    form={categoryForm}
                    keyData="name"
                    className={classNameInput}
                />
                <InputSelectForm
                    dataSource={SERVICE_CATEGORY_STATUSES}
                    form={categoryForm}
                    keyData="status"
                    className={classNameInput}
                />
                <div className={classNameInput}>
                    <label>{Messages.description}</label>
                    <RichTextTiny
                        value={formValues?.description ?? ""}
                        onChange={(value: any) =>
                            categoryForm.setFieldValue(
                                `description`,
                                value.toString("html")
                            )
                        }
                        placeholder={Messages.description}
                        size="large"
                        error={formErrors.description || formErrors.description}
                    />
                </div>
            </div>
        </div>
    );
};

export default ServiceCategoryCrud;
