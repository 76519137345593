import {
    DeliveryType,
    DoctorOrderStatus,
    OrderDeliveryService,
    OrderDeliveryStatus,
    OrderDeliveryType,
    OrderStatus,
    SalesPersonType,
} from "../api/hooks";

export const ORDER_PENDING_CONFIRM_STATUSES = [
    OrderStatus.PendingConfirmation,
    OrderStatus.PartiallyPaid,
];

export const ORDER_CONFIRMED_STATUSES = [
    OrderStatus.OrderProcessing,
    OrderStatus.DeliveryProcessing,
    OrderStatus.Delivered,
    OrderStatus.Completed,
];

export const ORDER_STATUSES = [
    {
        id: OrderStatus.PendingPayment,
        label: "pendingPayment",
        color: "#FAC256",
    },
    {
        id: OrderStatus.PendingConfirmation,
        label: "pendingConfirmation",
        color: "#FAC256",
    },
    {
        id: OrderStatus.PartiallyPaid,
        label: "partiallyPaid",
        color: "#FAC256",
    },
    {
        id: OrderStatus.OrderProcessing,
        label: "orderProcessing",
        color: "#84C4FF",
    },
    {
        id: OrderStatus.DeliveryProcessing,
        label: "deliveryProcessing",
        color: "#29B3BC",
    },
    {
        id: OrderStatus.Delivered,
        label: "delivered",
        color: "#33B950",
    },
    {
        id: OrderStatus.Completed,
        label: "completed",
        color: "#33B950",
    },
    {
        id: OrderStatus.Cancelled,
        label: "cancelled",
        color: "#C4C4C4",
    },
    {
        id: OrderStatus.Refunded,
        label: "refunded",
        color: "#33B950",
    },
];

export const DOCTOR_ORDER_STATUSES = [
    {
        id: DoctorOrderStatus.Completed,
        label: "completed",
        color: "#33B950",
    },
    {
        id: DoctorOrderStatus.Cancelled,
        label: "cancelled",
        color: "#C4C4C4",
    },
    {
        id: DoctorOrderStatus.Processing,
        label: "processing",
        color: "#29B3BC",
    },
];

export const ORDER_DELIVERY_TYPES = [
    {
        id: OrderDeliveryType.Truck,
        label: "truck",
    },
    {
        id: OrderDeliveryType.Shippop,
        label: "shippop",
    },
];

export const ORDER_DELIVERY_SERVICES = [
    {
        id: OrderDeliveryService.Ems,
        label: "ems",
    },
    {
        id: OrderDeliveryService.Dhl,
        label: "dhl",
    },
];

export const ORDER_DELIVERY_STATUSES = [
    {
        key: OrderDeliveryStatus.Pending,
        label: "deliveryPending",
    },
    {
        key: OrderDeliveryStatus.Booked,
        label: "deliveryBooked",
    },
    {
        key: OrderDeliveryStatus.InDelivery,
        label: "inDelivery",
    },
    {
        key: OrderDeliveryStatus.DeliveryCompleted,
        label: "deliveryCompleted",
    },
    {
        key: OrderDeliveryStatus.DeliveryCancelled,
        label: "deliveryCancelled",
    },
    {
        key: OrderDeliveryStatus.DeliveryReturned,
        label: "deliveryReturned",
    },
];

export const SALES_PERSON_TYPE = [
    {
        id: SalesPersonType.Sales,
        label: "cosalePersonBySales",
    },
    {
        id: SalesPersonType.Doctor,
        label: "cosalePersonByDoctor",
    },
];
