import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { findIndex, forEach } from "lodash";
import { useEffect, useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { PromotionCreateContext } from "../../../context/promotion";
import { PromotionSchema } from "../../../formschema/promotion";
import {
    mapPromotionDetailToValues,
    mapPromotionToServer,
} from "../../../interfaces/promotion";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import PromotionCreateInformationForm from "./PromotionCreateInformationForm";
import PromotionCreateSideView from "./PromotionCreateSideView";
import PromotionItemsForm from "./PromotionItemsForm";
import PromotionCreateDocumentsForm from "./PromotionCreateDocumentsForm";
import PromotionAPI from "../../../api/queries/promotion";

const PromotionCreate = () => {
    const navigate = useNavigate();
    const { duplicateId } = useParams<any>();

    const promotionForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PromotionSchema,
        onSubmit: (values: any) => {
            const input = mapPromotionToServer(values);
            onCreatePromotion(input);
        },
    });

    const { values, errors, setFieldValue, setValues } = promotionForm;

    useEffect(() => {
        if (duplicateId && duplicateId?.length === 24) {
            loadPromotionDetail();
        }
    }, [duplicateId]);

    const loadPromotionDetail = () => {
        Progress.show(
            { method: PromotionAPI.detail, params: [duplicateId] },
            (res: any) => {
                const { data, error } = res?.data?.data ?? {};
                if (error) {
                    Notifications.showError(Messages.loadError);
                    return;
                }
                const mapped = mapPromotionDetailToValues(data);
                setValues(mapped);
            }
        );
    };

    useEffect(() => {
        forEach(Object.keys(promotionForm.errors), (key) =>
            Notifications.showError(`${promotionForm.errors[key]}`)
        );
    }, [promotionForm.errors]);

    const onCreatePromotion = (payload: any) => {
        Progress.show(
            {
                method: PromotionAPI.create,
                params: [payload],
            },
            (res: any) => {
                const { data, error } = res?.data?.data ?? {};
                if (error) {
                    if (error?.length > 0) {
                        const clone = values?.products ?? [];
                        forEach(error, (e: any) => {
                            const foundIdx = findIndex(
                                clone,
                                (i: any) => i?.id === e?.product?.id
                            );
                            if (foundIdx !== -1) {
                                clone[foundIdx].error = e;
                            }
                        });
                        setFieldValue("products", clone);
                    }
                } else {
                    navigate(
                        generatePath(Path.PROMOTION_DETAIL, {
                            promotionId: data?.id,
                        })
                    );
                    Notifications.showSuccess(Messages.createSuccess);
                }
            }
        );
    };

    const renderHeader = useMemo(() => {
        return (
            <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                <h5 className="text-primary">{Messages.newPromotionPricing}</h5>
                <div className="flex-center-y gap-3">
                    <Button
                        onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            history.back();
                        }}
                        variant="outline"
                    >
                        {Messages.back}
                    </Button>
                    <Button onClick={() => promotionForm.handleSubmit()}>
                        {Messages.save}
                    </Button>
                </div>
            </div>
        );
    }, []);

    return (
        <PromotionCreateContext.Provider value={{ promotionForm }}>
            <div className="flex-column-container bg-white w-100 pb-4">
                {renderHeader}
                <div className="grid grid-cols-12 mt-4 gap-4 items-start px-6">
                    <div className="col-span-3 border">
                        <PromotionCreateSideView />
                    </div>
                    <div className="col-span-9 flex flex-col gap-4">
                        <PromotionCreateInformationForm />
                        <PromotionItemsForm form={promotionForm} />
                        <PromotionCreateDocumentsForm />
                    </div>
                </div>
            </div>
        </PromotionCreateContext.Provider>
    );
};

export default PromotionCreate;
