import {
    AwesomeTableComponent,
    IColumnsProps,
    Summary,
} from "d-react-components";
import { reduce } from "lodash";
import React, { useContext, useMemo } from "react";
import { DeliveryDriverDetailContext } from "../../../context/delivery-driver";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/share/ProductNameView";

export interface IDeliveryDriverItemsProps {
    [key: string]: any;
}

const DeliveryDriverItems: React.FC<IDeliveryDriverItemsProps> = ({ id }) => {
    const { delivery } = useContext(DeliveryDriverDetailContext);

    const total = useMemo(() => {
        return reduce(
            delivery?.stocks ?? [],
            (res, stock, index) => res + (stock?.quantity || 0),
            0
        );
    }, [delivery?.stocks]);

    const columns: IColumnsProps = [
        {
            title: Messages.no,
            dataIndex: "",
            render: (product, item, index) => index + 1,
        },
        {
            title: Messages.item,
            dataIndex: "",
            render: (product, item) => (
                <ProductNameView product={item?.product} />
            ),
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
        },
    ];

    const renderSummaryRow = () => {
        return (
            <Summary.Row>
                <Summary.Cell
                    index={1}
                    colSpan={2}
                    className="text-right font-weight-bold"
                >
                    {Messages.total}
                </Summary.Cell>
                <Summary.Cell
                    index={2}
                    className="text-center font-weight-bold"
                    colSpan={2}
                >
                    {total}
                </Summary.Cell>
            </Summary.Row>
        );
    };

    return (
        <div className="border p-4">
            <AwesomeTableComponent
                columns={columns}
                dataSource={delivery?.stocks ?? []}
                className="mt-3"
                pagination={false}
                summary={() => renderSummaryRow()}
            />
        </div>
    );
};

export default DeliveryDriverItems;
