import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import BranchSelect from "../../branch/share/BranchSelect";

export const CustomerCreateBranch = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    return (
        <div className="card-container p-3">
            <BranchSelect
                className="p-3"
                value={customerForm?.values?.branch}
                onChange={(value) =>
                    customerForm.setFieldValue("branch", value)
                }
            />
        </div>
    );
};
