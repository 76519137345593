import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { useFormik } from "formik";
import parse from "html-react-parser";
import { useContext, useState } from "react";
import { useUpdateServiceConfigurationMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import RichTextTiny from "../../../common/richtext/RichTextTiny";
import { ServiceConfigContext } from "../../../context/service";
import { ServiceConfigSchema } from "../../../formschema/service";
import {
    IServiceConfiguration,
    mapServiceConfigToServer,
} from "../../../interfaces/service";
import Messages from "../../../languages/Messages";

interface IEditGeneralInfoDrawer {
    open: boolean;
    config: any;
    onClose: () => void;
    onUpdated: any;
}

const EditDescriptionDrawer = ({
    config,
    open,
    onClose,
    onUpdated,
}: IEditGeneralInfoDrawer) => {
    const classNameInput = "col-12 mt-3";
    const [updateServiceConfiguration] =
        useUpdateServiceConfigurationMutation();
    const configForm = useFormik<any>({
        initialValues: config as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ServiceConfigSchema,
        onSubmit: (values: any) => {
            const input = mapServiceConfigToServer(values);
            onUpdateConfig({ description: values?.description });
        },
    });

    const formValues = configForm?.values;
    const formErrors = configForm?.errors;

    const onUpdateConfig = (payload: any) => {
        Progress.show(
            {
                method: updateServiceConfiguration,
                params: [{ variables: { id: config?.id, payload } }],
            },
            (res: any) => {
                const config = res?.data?.data?.data;
                onUpdated && onUpdated(config);
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.serviceDescription}
            onSave={() => configForm.handleSubmit()}
            size="large"
        >
            <div className={classNameInput}>
                <label>{Messages.description}</label>
                <RichTextTiny
                    value={formValues?.description ?? ""}
                    onChange={(value: any) =>
                        configForm.setFieldValue(
                            `description`,
                            value.toString("html")
                        )
                    }
                    placeholder={Messages.description}
                    size="large"
                    error={formErrors.description || formErrors.description}
                    key={`${config?.description}`}
                />
            </div>
        </Drawer>
    );
};

const GENERAL_INFO_KEYS: IRowsKey<IServiceConfiguration>[] = [
    {
        id: "description",
        label: Messages.description,
    },
];

const ServiceConfigDescription = () => {
    const { configuration, setConfiguration } =
        useContext(ServiceConfigContext);
    const [openUpdate, setOpenUpdate] = useState(false);

    const renderHeader = (title: any, onClickEdit: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                <Button
                    onClick={onClickEdit}
                    className=""
                    variant="trans"
                    size="small"
                    color="red"
                >
                    {Messages.edit}
                </Button>
            </div>
        );
    };

    return (
        <div className="border p-4 relative note-item__container mb-3">
            <div className=" w-100 flex-row-between-center">
                <label>{Messages.description}</label>
            </div>
            <div className="text mt-2">
                {parse(configuration?.description ?? "")}
            </div>
            <div className="border-t mt-2 py-2 text-gray">
                <small>
                    {Messages.lastUpdated}:{" "}
                    {TimeUtils.toDateTime(configuration?.updatedAt)}
                </small>
            </div>
            <div className="absolute top-[12px] right-[10px]">
                <div className="note-item__edit-delete">
                    <Button
                        onClick={() => setOpenUpdate(true)}
                        className="mr-1"
                        iconName="edit"
                        size="x-small"
                    />
                </div>
            </div>
            {openUpdate && (
                <EditDescriptionDrawer
                    config={configuration}
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                    onUpdated={setConfiguration}
                />
            )}
        </div>
    );
};

export default ServiceConfigDescription;
