import { InputText, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useUpdateAdminUserMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { UserDetailContext } from "../../../context/user";
import { UserPasswordSchema } from "../../../formschema/user";
import Messages from "../../../languages/Messages";

const UserPassword = ({ open, onClose }: any) => {
    const [updateAdminUser] = useUpdateAdminUserMutation();
    const { user } = useContext(UserDetailContext);

    const userForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UserPasswordSchema,
        onSubmit: (values) => {
            const body = {
                variables: {
                    id: user?.id as string,
                    payload: {
                        password: values?.password,
                    },
                },
            };
            Progress.show(
                {
                    method: updateAdminUser,
                    params: [body],
                },
                (resp: any) => {
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    const { errors, values, setFieldValue, handleSubmit } = userForm;

    const onSave = () => {
        handleSubmit();
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.updatePassword}
            onSave={onSave}
        >
            <div className="p-3">
                <InputText
                    label={Messages.newPassword}
                    onChange={(event) => {
                        const { value } = event.target;
                        setFieldValue("password", value);
                    }}
                    value={values?.password}
                    error={errors?.password as string}
                    type="password"
                    placeholder={Messages.pleaseInput}
                    className="mb-3"
                />
                <InputText
                    label={Messages.newPasswordConfirm}
                    onChange={(event) => {
                        const { value } = event.target;
                        userForm.setFieldValue("confirmPassword", value);
                    }}
                    value={values?.confirmPassword}
                    error={errors?.confirmPassword as string}
                    type="password"
                    placeholder={Messages.pleaseInput}
                />
            </div>
        </Drawer>
    );
};

export default UserPassword;
