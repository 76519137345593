import React, { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import MedicalProfileForm from "../share/MedicalProfileForm";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";
import {
    AllergyHistoryForm,
    SurgeryHistoryForm,
} from "../detail/CustomerDetailMedicalProfile";

const CustomerCreateMedicalProfile = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    return (
        <div className="card-container p-3">
            <div className="my-3">
                <h5 className="text-primary px-3">
                    {Messages.basicHealthInformation}
                </h5>
                <MedicalProfileForm form={customerForm} isModal={false} />
            </div>
            <div className="my-3">
                <h5 className="text-primary px-3">
                    {Messages.allergyHistoryAnd}
                </h5>
                <AllergyHistoryForm form={customerForm} />
            </div>
            <div className="mt-8 mb-3 px-3">
                <h5 className="text-primary mb-3">{Messages.surgeryHistory}</h5>
                <div className="bg-[#e5ecec] pb-3 pt-1">
                    <div className="px-3">
                        <SurgeryHistoryForm form={customerForm} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerCreateMedicalProfile;
