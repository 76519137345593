import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    ObjectUtils,
    Progress,
    Select,
    useFirstTime,
} from "d-react-components";
import {
    debounce,
    filter,
    find,
    includes,
    isEmpty,
    map,
    toLower,
} from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ServiceConfigAPI } from "../../../api/queries/service";
import Drawer from "../../../common/Drawer";
import { SERVICE_ADJUSTMENT_CONFIGS } from "../../../constant/service";
import { ServiceConfigContext } from "../../../context/service";
import Messages from "../../../languages/Messages";
import { IProduct } from "../../../interfaces/product";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import ProductNameView from "../../product/share/ProductNameView";
import { TypeOfProduct, useGetProductsFullDetailsLazyQuery } from "../../../api/hooks";

interface IProductItem extends IProduct {
    quantity: number;
    hasPermissionAdjustment: string;
}

interface IProductAddDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IProductItem[];
}

interface ITreatmentServiceUpdateDrawer {
    open: boolean;
    onClose: () => void;
    defaultValue: IProductItem;
}

const FILTER_TABS = [
    {
        id: "all",
        label: "all",
    },
    {
        id: "selected",
        label: "selected",
    },
];

const ServiceAddDrawer = ({
    open,
    onClose,
    defaultValue = [],
}: IProductAddDrawer) => {
    const { setConfiguration, configuration } =
        useContext(ServiceConfigContext);
    const [serviceSelected, setServiceSelected] =
        useState<IProductItem[]>(defaultValue);
    const [getProductList] = useGetProductsFullDetailsLazyQuery();
    const [filterTab, setFilterTab] = useState("all");
    const searchRef = useRef<string>();
    const tableRef = useRef<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filterTab]);

    const onAddService = () => {
        const serviceFiltered = filter(
            serviceSelected,
            (item) => !!item.quantity
        );
        const services = map(serviceFiltered, (item) => ({
            product: item.id,
            quantity: item.quantity,
            hasPermissionAdjustment: item.hasPermissionAdjustment,
        }));

        Progress.show(
            {
                method: ServiceConfigAPI.addServiceAddonPreset,
                params: [configuration?.id, services],
            },
            (configRes: any) => {
                Notifications.showSuccess(Messages.addSuccess);
                setConfiguration(configRes);
                onClose();
            }
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        searchRef.current = search;
        tableRef.current.refresh();
    }, 400);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.adjustAfterTreatment,
            dataIndex: "",
            render: (product: any) => {
                const itemSelected = find(
                    serviceSelected,
                    (item) => item.id === product.id
                );
                if (!itemSelected) return <div />;
                return (
                    <Select
                        className="width-[75px]"
                        getLabel={(item) => Messages[item.label]}
                        value={itemSelected?.hasPermissionAdjustment}
                        dataSource={SERVICE_ADJUSTMENT_CONFIGS}
                        onChange={(value) =>
                            onChangeIsAdjust(value, itemSelected)
                        }
                    />
                );
            },
            width: 200,
        },
        {
            title: Messages.quantity,
            dataIndex: "",
            render: (product: any) => {
                const itemSelected = find(
                    serviceSelected,
                    (item) => item.id === product.id
                );
                if (!itemSelected) return <div />;
                if (itemSelected?.hasPermissionAdjustment === "YES") {
                    return Messages.provideAfterTreatment;
                }
                return (
                    <QuantityInputField
                        quantity={itemSelected?.quantity}
                        onChangeQuantity={(quantity: number) =>
                            onChangeQuantity(quantity, itemSelected)
                        }
                        min={1}
                    />
                );
            },
            width: 200,
        },
    ];

    const onChangeQuantity = (quantity: number, service: IProduct) => {
        const result = ObjectUtils.updateArrayById(serviceSelected as any, {
            ...service,
            quantity,
        });
        setServiceSelected(result);
    };

    const onChangeIsAdjust = (
        hasPermissionAdjustment: string,
        service: IProduct & { quantity: number }
    ) => {
        const result = ObjectUtils.updateArrayById(serviceSelected as any, {
            ...service,
            hasPermissionAdjustment,
            quantity: hasPermissionAdjustment === "YES" ? 0 : service?.quantity,
        });

        setServiceSelected(result);
    };

    const source = (pagingData: any) => {
        if (filterTab === "selected") {
            return Promise.resolve({ data: serviceSelected });
        }
        return getProductList({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                    isOperationService: false,
                    typeOfProduct: [TypeOfProduct.ServiceProduct],
                },
            },
        });
    };

    const onChangeSelectService = (keys: string[], products: IProduct[]) => {
        const result = map(products, (product) => {
            const itemSelected = find(
                serviceSelected,
                (item) => item.id === product.id
            );

            if (itemSelected) return itemSelected;
            return { ...product, quantity: 1, hasPermissionAdjustment: "YES" };
        });
        setServiceSelected(result);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.service}
            onSave={onAddService}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <InputTextSearch onChange={onChangeSearch} />
                <div className="d-flex mt-3">
                    {map(FILTER_TABS, (item) => (
                        <Button
                            onClick={() => setFilterTab(item?.id)}
                            variant={
                                item.id === filterTab ? "standard" : "outline"
                            }
                            className="mr-2"
                            size="small"
                        >
                            {Messages[item.label]}
                        </Button>
                    ))}
                </div>
                <AwesomeTableComponent
                    className="mt-3"
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    columns={columns}
                    source={source}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => res?.data?.data?.data ?? []}
                    rowSelection={{
                        selectedRowKeys: map(
                            serviceSelected,
                            (item) => item.id
                        ),
                        preserveSelectedRowKeys: true,
                        onChange: onChangeSelectService as any,
                    }}
                />
            </div>
        </Drawer>
    );
};

const TreatmentServiceUpdateDrawer = ({
    open,
    onClose,
    defaultValue,
}: ITreatmentServiceUpdateDrawer) => {
    const { setConfiguration, configuration } =
        useContext(ServiceConfigContext);
    const [product, setProduct] = useState<IProductItem>(defaultValue);

    const onUpdateServiceTreatment = () => {
        const input = {
            product: product?.id,
            quantity: product?.quantity,
            hasPermissionAdjustment: product?.hasPermissionAdjustment,
        };
        Progress.show(
            {
                method: ServiceConfigAPI.updateServiceAddonPreset,
                params: [configuration?.id, input],
            },
            (res: any) => {
                Notifications.showSuccess(Messages.updateSuccess);
                setConfiguration(res);
                onClose();
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product) => <ProductNameView product={product} />,
        },
        {
            title: Messages.unit,
            dataIndex: "unit",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity: any, item) => {
                if (!item) return <div />;
                if (item?.hasPermissionAdjustment === "YES") {
                    return Messages.provideAfterTreatment;
                }
                return (
                    <QuantityInputField
                        quantity={quantity}
                        onChangeQuantity={(quantity: number) =>
                            setProduct({ ...product, quantity })
                        }
                        min={1}
                    />
                );
            },
        },
    ];

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.service}
            onSave={onUpdateServiceTreatment}
            size="auto"
            width="80%"
        >
            <div className="p-4">
                <AwesomeTableComponent
                    className="mt-3"
                    columns={columns}
                    dataSource={[product]}
                    pagination={false}
                />
                <Select
                    className="mt-3"
                    label={Messages.adjustAfterTreatment}
                    getLabel={(item) => Messages[item.label]}
                    value={product?.hasPermissionAdjustment}
                    dataSource={SERVICE_ADJUSTMENT_CONFIGS}
                    onChange={(hasPermissionAdjustment) =>
                        setProduct({
                            ...product,
                            hasPermissionAdjustment,
                            quantity:
                                hasPermissionAdjustment === "YES"
                                    ? 0
                                    : product?.quantity,
                        })
                    }
                />
            </div>
        </Drawer>
    );
};

const ServiceConfigAddon = () => {
    const { setConfiguration, configuration } =
        useContext(ServiceConfigContext);

    const [openAdd, setOpenAdd] = useState(false);
    const [updatingService, setUpdatingService] =
        useState<IProductItem | null>(null);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
        {
            title: Messages.unit,
            dataIndex: "product",
            render: (product: IProduct) => product.unit
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            width: 200,
            render: (quantity: any, item: any) => {
                if (item?.hasPermissionAdjustment === "YES") {
                    return Messages.provideAfterTreatment;
                }
                return quantity;
            },
        },
        {
            title: Messages.adjustAfterTreatment,
            dataIndex: "hasPermissionAdjustment",
            render: (isAdjust: string) => {
                const adjustConfig = find(
                    SERVICE_ADJUSTMENT_CONFIGS,
                    (item) => item.id === isAdjust
                );
                return Messages[adjustConfig?.label as any];
            },
            width: 200,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (item) => (
                <div className="d-flex">
                    <Icon
                        name="edit"
                        className="cursor-pointer mr-3 text-primary"
                        onClick={() =>
                            setUpdatingService({
                                ...item.product,
                                quantity: item?.quantity,
                                hasPermissionAdjustment:
                                    item?.hasPermissionAdjustment,
                            })
                        }
                    />
                    <Icon
                        name="remove_circle_outline"
                        className="cursor-pointer text-primary"
                        onClick={() => onRemove(item)}
                    />
                </div>
            ),
        },
    ];

    const onRemove = (service: any) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        method: ServiceConfigAPI.removeServiceAddonPreset,
                        params: [configuration?.id, service.product?.id],
                    },
                    (treatmentRes) => {
                        setConfiguration(treatmentRes);
                        Notifications.showSuccess(Messages.removeSuccess);
                    }
                )
        );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const dataSource = useMemo(() => {
        if (isEmpty(textSearch)) {
            return configuration?.servicePreset ?? [];
        }

        return filter(configuration?.servicePreset ?? [], (item) =>
            includes(
                toLower(item?.product?.name + item?.product?.sku),
                toLower(textSearch)
            )
        );
    }, [textSearch, configuration?.servicePreset]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <h5>{Messages.addonPreset}</h5>
                <Button onClick={() => setOpenAdd(true)} className="">
                    {Messages.add}
                </Button>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={dataSource}
                className="mt-3"
                pagination={false}
            />
            {openAdd && (
                <ServiceAddDrawer
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                    defaultValue={map(configuration?.servicePreset, (item) => ({
                        ...item.product,
                        quantity: item.quantity,
                        hasPermissionAdjustment: item?.hasPermissionAdjustment,
                    }))}
                />
            )}
            {updatingService && (
                <TreatmentServiceUpdateDrawer
                    defaultValue={updatingService}
                    open={!!updatingService}
                    onClose={() => setUpdatingService(null)}
                />
            )}
        </div>
    );
};

export default ServiceConfigAddon;
