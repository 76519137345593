import {
    Checkbox,
    Icon,
    InputText,
    Notifications,
    Progress,
    TabBar,
    TimeUtils,
} from "d-react-components";
import { useFormikContext } from "formik";
import CurrencyFormat from "react-currency-format";
import {
    chain,
    filter,
    find,
    isEmpty,
    isUndefined,
    orderBy,
    uniqBy,
} from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    useCheckVoucherCustomerOrderForAdminLazyQuery,
    useDetailVoucherByCodeForAdminLazyQuery,
    VoucherStatus,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { QuotationCreateContext } from "../../../context/quotation";
import { VOUCHER_TYPES } from "../../../constant/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import { OrderVoucherContext } from "../../../context/order";

const AutoSelectedVoucher = () => {
    const { checkCartValue } = useContext(QuotationCreateContext);
    const { voucherList, setSelectedVoucherList, searchText } =
        useContext(OrderVoucherContext);
    const { values } = useFormikContext<any>();
    const [autoSelectedVouchers, setAutoSelectedVouchers] = useState<
        IVoucher[]
    >([]);

    useEffect(() => {
        if (voucherList.length) {
            checkCartValue(
                values,
                voucherList.map((v) => v.code),
                false
            ).then((resp: any) => {
                const autoSelectedVouchers =
                    resp?.voucher?.map((v: any) => v.voucher) ?? [];
                setAutoSelectedVouchers(autoSelectedVouchers);
                setSelectedVoucherList(autoSelectedVouchers);
            });
        }
    }, [voucherList]);

    const displayItems = useMemo(() => {
        return (
            chain(autoSelectedVouchers)
                .uniqBy("code")
                .filter((voucher) =>
                    voucher?.code
                        ?.toLowerCase()
                        .includes(searchText?.toLowerCase())
                )
                .orderBy("createdAt", "desc")
                .value() ?? []
        );
    }, [autoSelectedVouchers, searchText]);

    return (
        <>
            {displayItems?.map((code, index) => (
                <VoucherItem key={index} voucher={code as any} />
            ))}
        </>
    );
};

const AllVouchers = () => {
    const { cartValue } = useContext(QuotationCreateContext);
    const {
        voucherList,
        selectedVoucherList,
        setSelectedVoucherList,
        searchText,
    } = useContext(OrderVoucherContext);

    const onChangeVoucher = (v: IVoucher) => {
        const isChecked = !isUndefined(
            find(selectedVoucherList, { code: v.code })
        );
        if (isChecked) {
            setSelectedVoucherList(
                filter(
                    selectedVoucherList,
                    (voucher) => voucher.code !== v.code
                )
            );
        } else {
            setSelectedVoucherList([...selectedVoucherList, v]);
        }
    };

    useEffect(() => {
        setSelectedVoucherList(cartValue?.voucher?.map((v) => v.voucher) ?? []);
    }, []);

    const displayItems = useMemo(() => {
        return (
            chain(voucherList)
                .filter((voucher) =>
                    voucher?.code
                        ?.toLowerCase()
                        .includes(searchText?.toLowerCase())
                )
                .orderBy("createdAt", "desc")
                .value() ?? []
        );
    }, [searchText, voucherList]);

    return (
        <>
            {displayItems?.map((v, index) => (
                <div className="flex items-center" key={index}>
                    <Checkbox
                        checked={
                            !isUndefined(
                                find(selectedVoucherList, { code: v.code })
                            )
                        }
                        onChange={() => onChangeVoucher(v)}
                        className="mr-3"
                    />
                    <VoucherItem voucher={v as any} />
                </div>
            ))}
        </>
    );
};

const GuestVouchers = () => {
    const { cartValue, checkCartValue } = useContext(QuotationCreateContext);
    const { values } = useFormikContext<any>();
    const { voucherList, selectedVoucherList, setSelectedVoucherList } =
        useContext(OrderVoucherContext);
    const [voucherCode, setVoucherCode] = useState("");
    const [getVoucher] = useDetailVoucherByCodeForAdminLazyQuery();
    const [voucherCodes, setVoucherCodes] = useState<IVoucher[]>([]);
    const { customer } = values;

    const checkIsInvalidCustomer = (v: IVoucher) => {
        const currentCustomer = values?.customer?.[0]?.id;
        const voucherCustomer = v?.customer?.id;
        if (
            currentCustomer &&
            voucherCustomer &&
            currentCustomer !== voucherCustomer
        ) {
            return true;
        }
        return false;
    };

    const addVoucherCode = (code: string) => {
        if (!code || find(voucherCodes, (v) => v.code === code)) {
            return;
        }
        Progress.show(
            {
                method: getVoucher,
                params: [
                    {
                        variables: {
                            code,
                        },
                    },
                ],
            },
            (resp: any) => {
                const voucherDetail = resp?.data?.data?.data;
                if (
                    isEmpty(voucherDetail) ||
                    checkIsInvalidCustomer(voucherDetail) ||
                    voucherDetail.status === VoucherStatus.Expired
                ) {
                    Notifications.showError(Messages.invalidVoucher);
                    return;
                }
                setVoucherCodes([...voucherCodes, voucherDetail]);
                setVoucherCode("");
            }
        );
    };

    const removeVoucher = (v: IVoucher) => {
        setVoucherCodes(voucherCodes.filter((c) => c.code !== v.code));
    };

    useEffect(() => {
        setSelectedVoucherList(voucherCodes);
    }, [voucherCodes]);

    return (
        <>
            <InputText
                value={voucherCode}
                onChange={(e) => setVoucherCode(e?.target?.value)}
                label={Messages.voucherCode}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        addVoucherCode(e.currentTarget.value);
                    }
                }}
            />
            <div className="mt-4 flex flex-wrap">
                {voucherCodes?.map((code, index) => (
                    <div className="flex items-center w-100" key={index}>
                        <div className="mr-2">
                            <Icon
                                name="close"
                                className="cursor-pointer text-red-500"
                                onClick={() => removeVoucher(code)}
                            />
                        </div>
                        <div className="flex-1">
                            <VoucherItem voucher={code as any} />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

const VoucherItem = ({ voucher }: { voucher: IVoucher }) => {
    const voucherType = find(VOUCHER_TYPES, { id: voucher.type });
    return (
        <div className="drop-shadow-lg bg-white w-100 mb-3 p-3 relative">
            <div className="absolute block bg-primary text-xs top-0 right-0 text-white min-w-[20px] h-[20px] flex justify-content-center items-center px-1">
                x1
            </div>
            <div className="flex">
                <div className="flex justify-content-center items-center w-[36px] h-[36px]">
                    <img
                        src="/icons/voucher-green.svg"
                        className="w-[30px] h-[30px]"
                    />
                </div>
                <div className="flex flex-column ml-3">
                    <span className="text-primary">{voucher.code}</span>
                    <div className="text-sm text-gray-400">
                        <span>{Messages[voucherType?.label as string]}</span> |{" "}
                        <span>
                            {Messages.balance}:{" "}
                            <CurrencyFormat
                                value={
                                    (Number(voucher.value) || 0) -
                                    (Number(voucher.totalUsedValues) || 0)
                                }
                                thousandSeparator
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </span>{" "}
                        |{" "}
                        {voucher.expiryDate ? (
                            <span>
                                {Messages.expiresAt}:{" "}
                                {TimeUtils.toDateTime(voucher.expiryDate)}
                            </span>
                        ) : (
                            <span>{Messages.neverExpires}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TABS = [
    {
        id: "autoSelected",
        label: Messages.autoSelectedToApply,
        component: <AutoSelectedVoucher />,
    },
    {
        id: "allVouchers",
        label: Messages.customerAllVouchers,
        component: <AllVouchers />,
    },
    {
        id: "guestVouchers",
        label: Messages.guestVouchers,
        component: <GuestVouchers />,
    },
];

const VoucherDrawer = ({ open, onClose, onSave }: any) => {
    const [searchText, setSearchText] = useState("");
    const { cartValue, checkCartValue } = useContext(QuotationCreateContext);
    const { values } = useFormikContext<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [voucherList, setVoucherList] = useState<IVoucher[]>([]);
    const [selectedVoucherList, setSelectedVoucherList] = useState<IVoucher[]>(
        []
    );
    const [checkVoucher] = useCheckVoucherCustomerOrderForAdminLazyQuery();

    const handleAddVoucher = () => {
        const appliedVouchers =
            cartValue?.voucher?.map((v) => v?.voucher?.code) ?? [];
        const selectedVoucherCodes =
            selectedVoucherList.map((v) => v?.code) ?? [];
        checkCartValue(values, [
            ...appliedVouchers,
            ...selectedVoucherCodes,
        ]).then(() => {
            onClose();
        });
    };

    useEffect(() => {
        if (values.customer && values?.customer?.[0]?.id) {
            const customerId = values?.customer?.[0]?.id;
            checkVoucher({
                variables: {
                    id: customerId,
                },
            })
                .then((resp: any) => {
                    setVoucherList(resp?.data?.data?.data ?? ([] as any));
                })
                .catch((e: any) => {
                    Notifications.showError(e);
                });
        }
    }, []);

    return (
        <Drawer
            open={open}
            title={Messages.applyVoucher}
            onClose={() => onClose()}
            onSave={handleAddVoucher}
            destroyOnClose
            // disableSave={!voucherCodes.length}
            size="auto"
            width="600px"
        >
            <OrderVoucherContext.Provider
                value={{
                    voucherList,
                    selectedVoucherList,
                    setSelectedVoucherList,
                    searchText,
                }}
            >
                <div className="bg-white sticky top-0 z-10 pt-2 pb-2 px-2">
                    <div className="px-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />
                    </div>
                    {tab.id !== "guestVouchers" && (
                        <div className="px-3 pt-3">
                            <InputText
                                value={searchText}
                                onChange={(e) =>
                                    setSearchText(e?.target?.value)
                                }
                                placeholder={Messages.search}
                            />
                        </div>
                    )}
                </div>
                <div className="pt-0 pb-4 px-4">
                    <div className="mt-3">{tab.component}</div>
                </div>
            </OrderVoucherContext.Provider>
        </Drawer>
    );
};

export default VoucherDrawer;
