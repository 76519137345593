import { gql } from "@apollo/client";
import { F_ADDRESS, F_BILLING, F_COUNTRY } from "./address";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_AGENCY_BASIC } from "./agency";
import { F_PAGINATE } from "./app";

export const F_SECRET_CODE = gql`
    fragment F_SECRET_CODE on SecretCodeDto {
        id
        secretCodeNo
        ref
        refId
        code
        expiryTime
        status
    }
`;

export const F_CUSTOMER_UPDATE_INFO = gql`
    ${F_COUNTRY}
    ${F_ADDRESS}
    fragment F_CUSTOMER_UPDATE_INFO on CustomerDto {
        avatar
        customerNo
        patientType
        title
        fullNameTh
        fullNameEn
        firstNameTh
        lastNameTh
        firstNameEn
        lastNameEn
        nickname
        gender
        birthDay
        citizenId
        nationality {
            ...F_COUNTRY
        }
        passportNo
        passportExp
        religion
        maritalStatus
        numberOfChild
        phone
        alternativePhone
        email
        lineId
        instagram
        facebook
        address {
            ...F_ADDRESS
        }
        familyInformation {
            name
            relationship
            dateOfBirth
            phone
        }
        emergencyContact {
            name
            relationship
            residence
            phone
        }
        branch {
            id
            name
            code
        }
        updatedAt
    }
`;

export const F_CUSTOMER_SETTING = gql`
    fragment F_CUSTOMER_SETTING on CustomerSettingDto {
        isVip
    }
`;

export const F_CUSTOMER_REFERRER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_AGENCY_BASIC}
    fragment F_CUSTOMER_REFERRER on CustomerReferrerDto {
        salePerson {
            ...F_ADMIN_USER_BASIC
        }
        agency {
            ...F_AGENCY_BASIC
        }
    }
`;

export const F_CUSTOMER_ITEM = gql`
    ${F_COUNTRY}
    ${F_CUSTOMER_SETTING}
    fragment F_CUSTOMER_ITEM on CustomerDto {
        id
        customerNo
        patientType
        birthDay
        status
        updatedAt
        createdAt
        fullNameTh
        fullNameEn
        avatar
        nickname
        customerNo
        isThai
        updatedAt
        phone
        email
        citizenId
        summary {
            totalCourseUsage
            totalPurchase
        }
        nationality {
            ...F_COUNTRY
        }
        setting {
            ...F_CUSTOMER_SETTING
        }
        branch {
            id
            name
            code
        }
    }
`;

export const F_CUSTOMERS = gql`
    ${F_CUSTOMER_ITEM}
    ${F_PAGINATE}
    fragment F_CUSTOMERS on CustomerPaginateResponse {
        data {
            ...F_CUSTOMER_ITEM
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_CUSTOMER_TAG = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_CUSTOMER_TAG on TagDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        tagId
        name
        ref
    }
`;

export const F_CUSTOMER_TAG_BASIC = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_CUSTOMER_TAG_BASIC on TagBasicDto {
        id
        tagId
        name
        ref
    }
`;

export const F_CUSTOMER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_COUNTRY}
    ${F_ADDRESS}
    ${F_BILLING}
    ${F_CUSTOMER_TAG_BASIC}
    ${F_SECRET_CODE}
    ${F_CUSTOMER_UPDATE_INFO}
    ${F_CUSTOMER_SETTING}
    ${F_CUSTOMER_REFERRER}
    fragment F_CUSTOMER on CustomerDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        avatar
        status
        customerNo
        patientType
        title
        fullNameTh
        fullNameEn
        firstNameTh
        lastNameTh
        firstNameEn
        lastNameEn
        nickname
        gender
        birthDay
        citizenId
        nationality {
            ...F_COUNTRY
        }
        phoneCodeCountry {
            ...F_COUNTRY
        }
        passportNo
        passportExp
        religion
        maritalStatus
        numberOfChild
        phone
        phoneCode
        phoneNumberStatus
        alternativePhone
        email
        lineId
        instagram
        facebook
        address {
            ...F_ADDRESS
        }
        billing {
            ...F_BILLING
        }
        familyInformation {
            name
            relationship
            dateOfBirth
            phone
        }
        emergencyContact {
            name
            relationship
            residence
            phone
        }
        medicalProfile {
            height
            weight
            bloodGroup
            bloodPressure
            allergyHis
            underDisease
            surgeryHis {
                date
                surgery
                hospital
            }
        }
        branch {
            id
            name
            code
        }
        summary {
            totalPurchase
            totalCourseUsage
        }

        tags {
            ...F_CUSTOMER_TAG_BASIC
        }

        survey {
            updatedAt
            surveyAnswers {
                questionId
                answers
            }
        }

        updateInfo {
            ...F_CUSTOMER_UPDATE_INFO
        }
        updateInfoCode {
            ...F_SECRET_CODE
        }
        updateInfoStatus
        healthAssessmentCode {
            ...F_SECRET_CODE
        }
        healthAssessment {
            updatedAt
            surveyAnswers {
                questionId
                answers
            }
            confirmation
        }
        setting {
            ...F_CUSTOMER_SETTING
        }
        referrer {
            ...F_CUSTOMER_REFERRER
        }
        totalCredit
    }
`;

export const F_CUSTOMER_QUICK_VIEW = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_COUNTRY}
    ${F_ADDRESS}
    ${F_BILLING}
    ${F_CUSTOMER_TAG_BASIC}
    ${F_SECRET_CODE}
    ${F_CUSTOMER_UPDATE_INFO}
    ${F_CUSTOMER_SETTING}
    ${F_CUSTOMER_REFERRER}
    fragment F_CUSTOMER_QUICK_VIEW on CustomerDto {
        id
        updatedAt
        createdAt
        avatar
        status
        customerNo
        patientType
        title
        fullNameTh
        fullNameEn
        firstNameTh
        lastNameTh
        firstNameEn
        lastNameEn
        nickname
        gender
        birthDay
        phoneCodeCountry {
            ...F_COUNTRY
        }
        phone
        phoneCode
        phoneNumberStatus
        alternativePhone
        email
        lineId
        instagram
        facebook
        medicalProfile {
            height
            weight
            bloodGroup
            bloodPressure
            allergyHis
            underDisease
            surgeryHis {
                date
                surgery
                hospital
            }
        }
        tags {
            ...F_CUSTOMER_TAG_BASIC
        }
        totalCredit
    }
`;

export const F_CUSTOMER_NOTE = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_CUSTOMER_NOTE on NoteDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        noteNo
        title
        content
        refId
        ref
    }
`;

export const F_CUSTOMER_SURVEY = gql`
    fragment F_CUSTOMER_SURVEY on SurveyDto {
        id
        label
        type
        translate {
            language
            label
        }
        answers {
            id
            label
            translate {
                language
                label
            }
        }
    }
`;

export const F_CUSTOMER_BASIC = gql`
    fragment F_CUSTOMER_BASIC on AdminCustomerBasicDto {
        id
        avatar
        status
        firstNameTh
        lastNameTh
        firstNameEn
        lastNameEn
        nickname
        gender
        phone
        email
        customerNo
    }
`;

export const F_CUSTOMER_NOTES = gql`
    ${F_CUSTOMER_NOTE}
    ${F_PAGINATE}
    fragment F_CUSTOMER_NOTES on NotePaginateResponse {
        data {
            ...F_CUSTOMER_NOTE
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_CUSTOMER_CREDIT = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_CUSTOMER_CREDIT on CreditDto {
        id
        updatedAt
        createdAt
        creditNo
        status
        initialValue
        value
        totalUsedTimes
        totalUsedValues
        expiryDate
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        voucher {
            id
            voucherNo
            status
            type
            code
            value
            limit
        }
        vouchers {
            id
            voucherNo
            status
            type
            code
            value
            limit
            totalUsedTimes
            totalUsedValues
        }
    }
`;

export const F_CUSTOMER_CREDITS = gql`
    ${F_CUSTOMER_CREDIT}
    ${F_PAGINATE}
    fragment F_CUSTOMER_CREDITS on CreditPaginateResponse {
        data {
            ...F_CUSTOMER_CREDIT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_CUSTOMER_EXPORT = gql`
    ${F_CUSTOMER}
    ${F_PAGINATE}
    fragment F_CUSTOMER_EXPORT on CustomerPaginateResponse {
        data {
            ...F_CUSTOMER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
export const F_CUSTOMER_SERVICE_HISTORY = gql`
    fragment F_CUSTOMER_SERVICE_HISTORY on AdminCustomerServicesResponse {
        data {
            order {
                id
                updatedAt
                createdAt
                orderNo
                status
                purchaseType
                remark
                subtotal
                hasAutoGenerateVoucher
                isSameShippingAddress
                total
                expired
                priceSetType
                sourceOfCustomer
                salesPerson {
                    id
                    updatedAt
                    createdAt
                    adminNo
                    firstName
                    lastName
                    nickName
                    avatar
                    companyId
                    jobType
                    commissionServiceType
                }
                branch: store {
                    id
                    name
                    code
                }
            }
            product {
                id
                typeOfProduct
                quantity
                salePrice
                hasOpeningPrice
                regularPrice
                manualProduct
                expired
                isGift

                salesPerson {
                    type
                    isPrimary
                    salesPerson {
                        id
                        updatedAt
                        createdAt
                        adminNo
                        firstName
                        lastName
                        nickName
                        avatar
                        companyId
                        jobType
                        commissionServiceType
                    }
                }

                product {
                    id
                    name
                    productNo
                    status
                    sku
                    unit
                    thumbnail
                    gallery
                    regularPrice
                    salePrice
                    isNonVAT
                    description
                }
            }
            vouchers {
                id
                updatedAt
                createdAt
                voucherNo
                status
                type
                typeOfIssuance
                code
                value
                limit
                totalUsedTimes
                totalUsedValues
                balance
                usageLimitPerCoupon
                effectiveDate
                expiryDate
                remark

                usage {
                    usedAt
                    usedValue
                    balance
                    ref
                    refId
                }
            }
        }
    }
`;
