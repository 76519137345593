import { TimePicker } from "antd";
import { DateInput } from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { ScheduleSchema } from "../../../formschema/temporary-schedule";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";

interface IAddScheduleWorkingTimeDrawer {
    schedule?: any;
    open: boolean;
    onClose: () => void;
    onSave: (input: any) => void;
}

const AddScheduleWorkingTimeDrawer = ({
    open,
    schedule,
    onClose,
    onSave,
}: IAddScheduleWorkingTimeDrawer) => {
    const scheduleForm = useFormik<any>({
        initialValues: schedule ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ScheduleSchema,
        onSubmit: (values: any) => {
            onSave(values);
            onClose();
        },
    });
    const formValues = scheduleForm?.values;
    const formErrors = scheduleForm?.errors;

    const inputClassName = "col-12 mt-3 flex-column";
    return (
        <Drawer
            title={Messages.temporarySchedule}
            open={open}
            onClose={onClose}
            size="auto"
            width="800px"
            onSave={() => {
                scheduleForm.handleSubmit();
            }}
        >
            <div className="row p-4">
                <DateInput
                    label={Messages.date}
                    onChange={(value) =>
                        scheduleForm.setFieldValue("date", value)
                    }
                    value={
                        formValues?.date
                            ? moment(formValues?.date, "DD/MM/YYYY")
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    className={inputClassName}
                    error={formErrors?.date as any}
                />
                <BranchSelect
                    value={formValues?.branch}
                    onChange={(value) =>
                        scheduleForm.setFieldValue("branch", value)
                    }
                    className={inputClassName}
                    error={formErrors?.branch as any}
                />
                {/* <InputTextForm
                    keyData="from"
                    form={scheduleForm}
                    className={inputClassName}
                />
                <InputTextForm
                    keyData="to"
                    form={scheduleForm}
                    className={inputClassName}
                /> */}
                <div className={`${inputClassName} d-date-input`}>
                    <label>{Messages.from}</label>
                    <TimePicker
                        onChange={(value) => {
                            scheduleForm.setFieldValue("from", value);
                        }}
                        value={
                            formValues?.from
                                ? moment(formValues?.from, "HH:mm")
                                : (null as any)
                        }
                        format="HH:mm"
                        className="d-date-input__input"
                    />
                </div>
                <div className={`${inputClassName} d-date-input`}>
                    <label>{Messages.to}</label>
                    <TimePicker
                        onChange={(value) => {
                            scheduleForm.setFieldValue("to", value);
                        }}
                        value={
                            formValues?.to
                                ? moment(formValues?.to, "HH:mm")
                                : (null as any)
                        }
                        format="HH:mm"
                        className="d-date-input__input"
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default AddScheduleWorkingTimeDrawer;
