import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_CUSTOMER_BASIC } from "./customer";
import { F_ORDER_BASIC, F_ORDER_PRODUCT, F_PRODUCT_SALESPERSON } from "./order";
import { F_PRODUCT, F_PRODUCT_ITEM } from "./product";

export const F_VOUCHER_ITEM = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_CUSTOMER_BASIC}
    fragment F_VOUCHER_ITEM on AdminVoucherDto {
        id
        code
        type
        status
        voucherNo
        value
        salePrice
        totalUsedValues
        balance
        customer {
            ...F_CUSTOMER_BASIC
        }
        effectiveDate
        expiryDate
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        order {
            id
            orderNo
        }
        updatedAt
        createdAt
    }
`;

export const F_VOUCHER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_PRODUCT}
    ${F_CUSTOMER_BASIC}
    ${F_ORDER_BASIC}
    fragment F_VOUCHER on AdminVoucherDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        voucherNo
        status
        type
        code
        value
        limit
        totalUsedTimes
        totalUsedValues
        balance
        effectiveDate
        expiryDate
        remark
        services {
            ...F_PRODUCT
        }
        usage {
            usedAt
            usedValue
            ref
            refId
            usedBy {
                ...F_CUSTOMER_BASIC
            }
            order {
                ...F_ORDER_BASIC
            }
            balance
        }
        ownership {
            status
            remark
            ownedBy {
                ...F_CUSTOMER_BASIC
            }
        }
        customer {
            ...F_CUSTOMER_BASIC
        }
        order {
            id
            orderNo
        }
    }
`;

export const F_CUSTOMER_VOUCHER_REPORT = gql`
    ${F_CUSTOMER_BASIC}
    ${F_PRODUCT_SALESPERSON}
    fragment F_CUSTOMER_VOUCHER_REPORT on AdminVoucherDto {
        id
        updatedAt
        createdAt
        voucherNo
        status
        type
        code
        value
        limit
        totalUsedTimes
        totalUsedValues
        balance
        effectiveDate
        expiryDate

        services {
            id
            name
            productNo
            sku
        }
        usage {
            usedAt
            usedValue
            ref
            refId
            # usedBy {
            #     ...F_CUSTOMER_BASIC
            # }
            # order {
            #     ...F_ORDER_BASIC
            # }
            balance
        }
        ownership {
            status
            remark
            ownedBy {
                ...F_CUSTOMER_BASIC
            }
        }
        customer {
            ...F_CUSTOMER_BASIC
        }
        order {
            id
            orderNo
            store {
                name
            }
            salesPerson {
                id
                firstName
                lastName
                nickName
                companyId
            }
            products {
                id
                salesPerson {
                    ...F_PRODUCT_SALESPERSON
                }
            }
        }
    }
`;

export const F_VOUCHER_BASIC = gql`
    fragment F_VOUCHER_BASIC on AdminVoucherBasicDto {
        id
        code
        type
        status
        voucherNo
        value
        limit
        totalUsedValues
        totalUsedTimes
        updatedAt
        createdAt
        expiryDate
        effectiveDate
    }
`;

export const F_VOUCHERS = gql`
    ${F_VOUCHER_ITEM}
    ${F_PRODUCT_ITEM}
    ${F_PAGINATE}
    fragment F_VOUCHERS on AdminVoucherPaginateResponse {
        data {
            ...F_VOUCHER_ITEM
            services {
                ...F_PRODUCT_ITEM
            }
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
