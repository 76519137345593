import {
    AwesomeTableComponent,
    Button,
    DateInput,
    DialogManager,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, map, orderBy, sortBy } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import {
    useGetBranchListQuery,
    useUpdateAdminUserMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { WORKING_SCHEDULES_DATES } from "../../../constant/user";
import { UserDetailContext } from "../../../context/user";
import { WorkingScheduleSchema } from "../../../formschema/user";
import Messages from "../../../languages/Messages";
import { fmtMSS, getSecondFromTime } from "../../../utils/Utils";

const UserDetailWorkingSchedule = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [updateAdminUser] = useUpdateAdminUserMutation();
    const tableRef = useRef<any>(null);
    const { user, loadAdminDetail } = useContext(UserDetailContext);

    const { defaultWorkingSchedule } = user || {};
    const userSchedules = useMemo(() => {
        if (
            isEmpty(
                defaultWorkingSchedule || !Array.isArray(defaultWorkingSchedule)
            )
        ) {
            return [];
        }
        const shortedList = orderBy(defaultWorkingSchedule, "order", ["asc"]);
        return shortedList;
    }, [defaultWorkingSchedule]);

    const removeSchedule = (id: number) => {
        const scheduleToUpdate =
            userSchedules
                ?.map((schedule) => ({
                    date: schedule.date,
                    branch: schedule.branch.id,
                    schedule: {
                        start: schedule.schedule.start,
                        end: schedule.schedule.end,
                    },
                }))
                .filter((s, index) => index !== id) ?? [];
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.confirmDelete,
            () =>
                Progress.show(
                    {
                        method: updateAdminUser,
                        params: [
                            {
                                variables: {
                                    id: user?.id as string,
                                    payload: {
                                        defaultWorkingSchedule:
                                            scheduleToUpdate,
                                    },
                                },
                            },
                        ],
                    },
                    () => {
                        loadAdminDetail();
                    }
                )
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.date,
            dataIndex: "date",
        },
        {
            title: Messages.branch,
            dataIndex: "branch",
            render: (data) => data?.name,
        },
        {
            title: Messages.start,
            dataIndex: "schedule",
            render: (data) => fmtMSS(data.start),
        },
        {
            title: Messages.end,
            dataIndex: "schedule",
            render: (data) => fmtMSS(data.end),
        },
        {
            title: Messages.action,
            dataIndex: "index",
            width: 30,
            render: (data, item, index) => {
                return (
                    <Icon
                        className="text-primary cursor-pointer"
                        name="remove_circle_outline"
                        onClick={() => removeSchedule(index)}
                    />
                );
            },
        },
    ];

    return (
        <div className="my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.defaultWorkingSchedule}
                </h5>
                <Button
                    size="small"
                    iconName="add"
                    classNameIcon="mx-0 mr-1"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.add}
                </Button>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                dataSource={userSchedules}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
            {showEditModal && (
                <UserDetailWorkingScheduleUpdate
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                />
            )}
        </div>
    );
};

const UserDetailWorkingScheduleUpdate = ({ open, onClose }: any) => {
    const [updateAdminUser] = useUpdateAdminUserMutation();
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const scheduleForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: WorkingScheduleSchema,
        onSubmit: () => handleSubmitSchedule(),
    });
    const { values, errors, handleSubmit, setFieldValue } = scheduleForm;
    const { data: branchList } = useGetBranchListQuery({
        variables: {
            paginate: {
                page: 1,
                limit: 1000,
            },
        },
    });
    const handleSubmitSchedule = () => {
        const payload = [
            ...values?.date?.map((date: string) => ({
                date,
                branch: values.branch,
                schedule: {
                    start: getSecondFromTime(values.start),
                    end: getSecondFromTime(values.end),
                },
            })),
            ...(user?.defaultWorkingSchedule?.map((schedule) => ({
                date: schedule.date,
                branch: schedule.branch.id,
                schedule: {
                    start: schedule.schedule.start,
                    end: schedule.schedule.end,
                },
            })) ?? []),
        ];
        Progress.show(
            {
                method: updateAdminUser,
                params: [
                    {
                        variables: {
                            id: user?.id as string,
                            payload: {
                                defaultWorkingSchedule: payload,
                            },
                        },
                    },
                ],
            },
            (resp: any) => {
                loadAdminDetail();
                Notifications.showSuccess(Messages.updateUserSuccessfully);
                onClose();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <Drawer
            title={Messages.defaultWorkingSchedule}
            open={open}
            onSave={() => handleSubmit()}
            onClose={onClose}
            size="auto"
            width="600px"
        >
            <div className="p-4">
                <Select
                    className="w-100 mb-3"
                    label={Messages.date}
                    value={values?.date}
                    error={errors?.date as string}
                    dataSource={WORKING_SCHEDULES_DATES}
                    onChange={(value) => setFieldValue("date", value)}
                    getLabel={(item) => Messages[item.label]}
                    getValue={(item) => item.key}
                    multiple
                />
                <Select
                    className="w-100 mb-3"
                    label={Messages.branch}
                    value={values?.branch}
                    error={errors?.branch as string}
                    dataSource={branchList?.listBranchForAdmin?.data ?? []}
                    onChange={(value) => setFieldValue("branch", value)}
                    getLabel={(item) => item.name}
                    getValue={(item) => item.id}
                />
                <div className="row">
                    <div className="col-6">
                        <DateInput
                            format="HH:mm"
                            type="time"
                            label={Messages.start}
                            value={values?.start}
                            error={errors?.start as string}
                            onChange={(value) => setFieldValue("start", value)}
                        />
                    </div>
                    <div className="col-6">
                        <DateInput
                            format="HH:mm"
                            type="time"
                            label={Messages.end}
                            value={values?.end}
                            error={errors?.end as string}
                            onChange={(value) => setFieldValue("end", value)}
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default UserDetailWorkingSchedule;
