import classNames from "classnames";
import { FormikProps } from "formik";
import CountrySelect from "../../address/share/CountrySelect";

interface ICustomerFilterForm {
    filterForm: FormikProps<any>;
}

const CustomerFilterForm = ({ filterForm }: ICustomerFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const { values, setFieldValue, setValues } = filterForm;

    return (
        <div className="px-2 py-3">
            <CountrySelect
                className={classNameInput}
                multiple
                value={values?.nationality}
                onChange={(e) => setFieldValue("nationality", e)}
            />
        </div>
    );
};

export default CustomerFilterForm;
