import { ViewLabelStatus } from "d-react-components";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { ActionMenu } from "../../../common/ActionButton";
import { DELIVERY_DRIVER_STATUSES } from "../../../constant/delivery-driver";
import { DeliveryDriverDetailContext } from "../../../context/delivery-driver";
import Messages from "../../../languages/Messages";

export interface IDeliveryDriverHeaderProps {
    [key: string]: any;
}

const DeliveryDriverHeader: React.FC<IDeliveryDriverHeaderProps> = ({ id }) => {
    const { deliveryId } = useParams<any>();
    const { delivery } = useContext(DeliveryDriverDetailContext);

    const onClickAction = () => {};

    return (
        <div className="py-3 px-4 bg-white flex border-b items-center">
            <div className="flex flex-1 align-items-center">
                <h5 className="text-primary mr-3">
                    #{`${delivery?.deliveryNo}`}
                </h5>
                <ViewLabelStatus
                    status={delivery?.status}
                    listStatus={DELIVERY_DRIVER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            </div>
            <div className="flex items-center">
                <ActionMenu
                    dataSource={[
                        {
                            id: "cancelOrder",
                            label: Messages.cancelOrder,
                        },
                    ]}
                    onClick={onClickAction}
                />
            </div>
        </div>
    );
};

export default DeliveryDriverHeader;
