import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { isUndefined, unionBy } from "lodash";
import { IMPORT_DATA, ImportMethod, ImportType } from "./constant";
import Messages from "../../languages/Messages";
import { ImportContext } from "./context";
import ImportFooter from "./ImportFooter";

interface ImportProps {
    type: ImportType;
}

const ImportScreen = ({ type }: ImportProps) => {
    const [stepIndex, setStepIndex] = useState(0);
    const importData = IMPORT_DATA[type];
    const { steps } = importData;
    const step = useMemo(() => steps[stepIndex], [stepIndex]);
    const formImport = useFormik({
        initialValues: {
            importFields: importData.fields,
            importType: ImportMethod.UPDATE,
            fileImport: null,
        } as any,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: () => {},
    });

    const importFieldSource = useMemo(() => {
        if (
            formImport?.values?.importType === ImportMethod.CREATE &&
            type === ImportType.CUSTOMER
        ) {
            return importData.fields.filter((item) => !item.required);
        }
        return importData.fields;
    }, [importData, formImport?.values?.importType]);

    useEffect(() => {
        const requiredFields = importData.fields.filter(
            (item) => item.required
        );
        formImport.setValues({
            ...formImport?.values,
            importFields: unionBy(
                [...formImport?.values?.importFields, ...requiredFields],
                "key"
            ).filter((item) =>
                importFieldSource.some((field) => field.key === item.key)
            ),
        });
    }, [importFieldSource, formImport?.values?.importType]);

    const contextValue = useMemo(() => {
        return {
            step,
            formImport,
            importFieldSource,
            importData,
            stepIndex,
            setStepIndex,
            type,
        };
    }, [
        step,
        importFieldSource,
        formImport,
        importData,
        stepIndex,
        setStepIndex,
    ]);

    return (
        <ImportContext.Provider value={contextValue}>
            <div className="inline-block">
                <div className="bg-dark px-3 py-1">
                    <span className="text-white text-xs">
                        {`Step ${stepIndex + 1} of ${steps.length}`}:{" "}
                        {Messages[step?.title]}
                    </span>
                </div>
            </div>
            <div className="mt-4">
                {!isUndefined(step?.component) && step.component}
            </div>
            <ImportFooter />
        </ImportContext.Provider>
    );
};

export default ImportScreen;
