import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetProductCategoriesLazyQuery } from "../../../api/hooks";
import { IProductCategory } from "../../../interfaces/product";

import Messages from "../../../languages/Messages";

interface IProductCategorySelect {
    className?: string;
    value: IProductCategory | IProductCategory[];
    multiple?: boolean;
    onChange: (value: IProductCategory | IProductCategory[]) => void;
    [key: string]: any;
}

const ProductCategorySelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IProductCategorySelect) => {
    const getInitLocalValue = () => {
        if (!value) return [];
        if (isArray(value)) return value;
        return [value];
    };
    const [categoryList, setCategoryList] = useState<any[]>(
        getInitLocalValue()
    );
    const [getCategoryList] = useGetProductCategoriesLazyQuery();

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(categoryList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(categoryList, id));
    };

    const categoryValue = useMemo(() => {
        if (!value) return value;

        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    useEffect(() => {
        onLoadData();
    }, []);

    const onLoadData = () => {
        getCategoryList({
            variables: {
                paginate: {
                    page: 1,
                    limit: 1000,
                    // search: textSearch,
                },
            },
        }).then((res: any) => {
            const categoryRes = res?.data?.data?.data ?? [];
            const selectedValue = getInitLocalValue();
            const filterCategory = map(
                [...selectedValue, ...categoryRes],
                (item) => ({
                    id: item.id,
                    name: item.name,
                })
            );
            const uniqCat = uniqBy(filterCategory, (item) => item.id);
            setCategoryList(uniqCat);
        });
    };

    return (
        <Select
            onSearch={onLoadData}
            className={className}
            label={Messages.category}
            dataSource={categoryList}
            getLabel={(item) => item?.name}
            value={categoryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            hasFilter
            {...selectProps}
        />
    );
};

export default ProductCategorySelect;
