export const TRANSFER_VOUCHER_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
};

export const TRANSFER_VOUCHER_STATUSES = [
    { id: "PENDING", label: "scheduled", color: "#FAC256" },
    { id: "APPROVED", label: "inProcess", color: "#29B3BC" },
    { id: "COMPLETED", label: "completed", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#ED6969" },
];

export enum TRANSFER_VOUCHER_TYPE {
    CUSTOMER_TO_CUSTOMER = "CUSTOMER_TO_CUSTOMER",
    GUEST_TO_CUSTOMER = "GUEST_TO_CUSTOMER",
    CONVERT_TO_CREDIT_VOUCHER = "CONVERT_TO_CREDIT_VOUCHER",
}

export const TRANSFER_VOUCHER_TYPES = [
    { id: "CUSTOMER_TO_CUSTOMER", label: "customerToCustomer" },
    { id: "GUEST_TO_CUSTOMER", label: "guestToCustomer" },
    { id: "CONVERT_TO_CREDIT_VOUCHER", label: "convertToCreditVoucher" },
];

export const TRANSFER_VOUCHER_REASONS = [
    { id: "CUSTOMER_WANT_CHANGE", label: "customerWantChange" },
    { id: "OTHER", label: "other" },
];
