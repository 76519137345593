import React from "react";
import { IPaymentMethod } from "../interfaces/payment-method";

export enum OrderRefType {
    ORDER = "ORDER",
    QUOTATION = "QUOTATION",
}

interface IPaymentState {
    paymentMethodsList: Partial<IPaymentMethod>[];
    payment2C2POptionsList: any[];
    ref: any;
    reloadRef: any;
    refType: OrderRefType;
    // setPaymentMethodsList: any;
}

const paymentState: IPaymentState = {
    paymentMethodsList: [],
    payment2C2POptionsList: [],
    ref: undefined,
    reloadRef: () => {},
    refType: OrderRefType.ORDER,
    // setPaymentMethodsList: () => {},
};

export const PaymentContext = React.createContext(paymentState);
