import { useFormik } from "formik";
import { Select } from "d-react-components";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../../common/Drawer";
import Messages from "../../../../languages/Messages";
import RoleSelect from "../../../../common/RoleSelect";
import { JOB_TYPES, USER_STATUSES_BOOLEAN } from "../../../../constant/user";
import { SIMPLE_CHOICES } from "../../../../constant/customer";

interface UserTableFilterDrawerProps {
    open: boolean;
    onClose: () => void;
}

const UserTableFilterDrawer = ({
    open,
    onClose,
}: UserTableFilterDrawerProps) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            setFilter(values);
            onClose();
        },
    });

    const { values, setFieldValue, errors } = filterForm;

    return (
        <Drawer
            open={open}
            size="x-large"
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
            cancelText={Messages.clearAll}
            cancelButtonProps={{
                color: "error",
                onClick: () => {
                    filterForm.setValues({});
                },
            }}
        >
            <div className="px-4 py-3">
                <Select
                    label={Messages.status}
                    value={values?.statuses}
                    error={errors?.statuses}
                    dataSource={USER_STATUSES_BOOLEAN}
                    getLabel={(item) => (Messages as any)[item?.label]}
                    multiple
                    onChange={(value) => setFieldValue("statuses", value)}
                    className="mb-3"
                />
                <RoleSelect multiple form={filterForm} formKey="roleIds" />
                <Select
                    label={Messages.jobType}
                    value={values?.jobTypes}
                    error={errors?.jobTypes}
                    dataSource={JOB_TYPES}
                    getLabel={(item) => Messages[item.label] || item.label}
                    getValue={(item) => item.key}
                    onChange={(value) => setFieldValue("jobTypes", value)}
                    multiple
                    className="my-3"
                />
                <Select
                    label={Messages.jobCostFeature}
                    value={values?.jobCostFeatures}
                    error={errors?.jobCostFeatures}
                    dataSource={SIMPLE_CHOICES}
                    getLabel={(item) => Messages[item.label]}
                    multiple
                    onChange={(value) =>
                        setFieldValue("jobCostFeatures", value)
                    }
                />
            </div>
        </Drawer>
    );
};

export default UserTableFilterDrawer;
