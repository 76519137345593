import React from "react";
import { IVoucher } from "../interfaces/voucher";
import { ITransferVoucher } from "../interfaces/transfer-voucher";

interface IVoucherCreateState {
    voucherForm: any;
}

const voucherCreateState: IVoucherCreateState = {
    voucherForm: null,
};

export const VoucherCreateContext = React.createContext(voucherCreateState);

interface IVoucherDetailState {
    voucher: IVoucher;
    setVoucher: (voucher: IVoucher) => void;
}

const voucherDetailState: IVoucherDetailState = {
    voucher: {} as any,
    setVoucher: () => {},
};

export const VoucherDetailContext = React.createContext(voucherDetailState);

export interface ITransferVoucherDetailState {
    transferDetail?: ITransferVoucher | null;
    loadTransferDetail: () => void;
}

const transferDetailState: ITransferVoucherDetailState = {
    transferDetail: undefined,
    loadTransferDetail: () => {},
};

export const TransferVoucherContext = React.createContext(transferDetailState);

interface ITransferVoucherCreateState {
    transferForm: any;
}

const transferVoucherCreateState: ITransferVoucherCreateState = {
    transferForm: {} as any,
};

export const TransferVoucherCreateContext = React.createContext(
    transferVoucherCreateState
);
