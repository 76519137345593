import { EquipmentRequestStatus } from "../api/hooks";

export const EQUIPMENT_STOCK_REQUEST_STATUS = {
    PENDING: EquipmentRequestStatus.Pending,
    CONFIRMED: EquipmentRequestStatus.Confirmed,
    REJECTED: EquipmentRequestStatus.Rejected,
};

export const EQUIPMENT_STOCK_REQUEST_STATUSES = [
    {
        id: EQUIPMENT_STOCK_REQUEST_STATUS.PENDING,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: EQUIPMENT_STOCK_REQUEST_STATUS.CONFIRMED,
        label: "confirmed",
        color: "#33B950",
    },
    // {
    //     id: EQUIPMENT_STOCK_REQUEST_STATUS.CANCELLED,
    //     label: "cancelled",
    //     color: "#C4C4C4",
    // },
    {
        id: EQUIPMENT_STOCK_REQUEST_STATUS.REJECTED,
        label: "rejected",
        color: "#ED6969",
    },
];
