import {
    Button,
    Header,
    InputText,
    Notifications,
    Progress,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { generatePath, useNavigate } from "react-router";
import PermissibleRender from "../../../common/PermissibleComponent";
import UploadButton from "../../../common/upload/UploadButton";
import { PERMISSION } from "../../../constant/user";
import { VendorSchema } from "../../../formschema/vendor";
import Messages from "../../../languages/Messages";
import CountrySelect from "../../address/share/CountrySelect";
import VendorAPI from "../../../api/queries/vendor";
import Path from "../../Path";

const VendorCreate = () => {
    const navigate = useNavigate();

    const vendorForm = useFormik({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VendorSchema,
        onSubmit: (values) => {
            const input = {
                ...values,
                creditTerm: parseFloat(values?.creditTerm),
                picture: values?.picture?.[0]?.fileUrl,
                countryOfOrigin: values?.countryOfOrigin?.id,
            };
            onCreateVendor(input);
        },
    });

    const formValues = vendorForm?.values;
    const formErrors = vendorForm?.errors;

    const onCreateVendor = (input: any) => {
        Progress.show(
            {
                method: VendorAPI.create,
                params: [input],
            },
            (resp: any) => {
                const vendor = resp?.data?.data?.data;
                navigate(
                    generatePath(Path.VENDOR_DETAIL, { vendorId: vendor.id })
                );
                Notifications.showSuccess(Messages.createSuccessfully);
            }
        );
    };
    const classNameInput = "col-span-1 mt-3";

    return (
        <div>
            <Header
                className="sticky top-0"
                showCancel={false}
                title={Messages.newVendor}
                customRight={() => (
                    <>
                        <Button
                            size="small"
                            className="ml-2"
                            variant="outline"
                            onClick={() => navigate(-1)}
                        >
                            {Messages.back}
                        </Button>
                        <PermissibleRender
                            permission={PERMISSION.VENDOR.CREATE}
                        >
                            <Button
                                size="small"
                                className="ml-2"
                                onClick={() => vendorForm.handleSubmit()}
                            >
                                {Messages.save}
                            </Button>
                        </PermissibleRender>
                    </>
                )}
            />
            <div className="p-3 bg-white">
                <div className="grid grid-cols-2 gap-4">
                    <CountrySelect
                        className={classNameInput}
                        value={formValues?.countryOfOrigin}
                        onChange={(value) =>
                            vendorForm.setFieldValue("countryOfOrigin", value)
                        }
                        error={formErrors?.countryOfOrigin}
                    />
                    <InputText
                        label={Messages.companyName}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue("name", event.target.value)
                        }
                        value={vendorForm?.values?.name}
                        error={vendorForm?.errors?.name as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.companyRegistrationNo}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "codeNumber",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.codeNumber}
                        error={vendorForm?.errors?.codeNumber as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.companyAddress}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "address",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.address}
                        error={vendorForm?.errors?.address as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.companyPhoneNo}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "phone",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.phone}
                        error={vendorForm?.errors?.phone as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativeFirstName}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesFirstName",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesFirstName}
                        error={
                            vendorForm?.errors?.representativesFirstName as any
                        }
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativeLastName}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesLastName",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesLastName}
                        error={
                            vendorForm?.errors?.representativesLastName as any
                        }
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativePhoneNo}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesPrimaryPhone",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesPrimaryPhone}
                        error={
                            vendorForm?.errors
                                ?.representativesPrimaryPhone as any
                        }
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativeEmail}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesEmail",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesEmail}
                        error={vendorForm?.errors?.representativesEmail as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.creditTermDays}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "creditTerm",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.creditTerm}
                        error={vendorForm?.errors?.creditTerm as any}
                        className={classNameInput}
                    />
                    <div className="col-span-2 mt-3">
                        <label>{Messages.vendorProfilePicture}</label>
                        <UploadButton
                            onChange={(file: any) => {
                                vendorForm.setFieldValue("picture", file);
                            }}
                            defaultFiles={formValues?.picture ?? []}
                            maxFiles={1}
                        />
                        {formErrors.picture && (
                            <ViewTextError error={formErrors.picture} />
                        )}
                    </div>
                    <InputText
                        label={Messages.remark}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "remark",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.remark}
                        error={vendorForm?.errors?.remark as any}
                        className="col-span-2 mt-3"
                        multiple
                    />
                </div>
            </div>
        </div>
    );
};

export default VendorCreate;
