import { AwesomeTableComponent, IColumnsProps } from "d-react-components";
import { useContext, useRef } from "react";
import { MaterialRequestContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import MaterialNameView from "../../material/share/MaterialNameView";

const MaterialRequestDetailItems = () => {
    const { request } = useContext(MaterialRequestContext);
    const tableRef = useRef<any>(null);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "material",
            render: (data) => <MaterialNameView material={data} />,
        },
        {
            title: Messages.unit,
            dataIndex: "material",
            render: (material) => material?.purchaseUnit?.name,
        },
        {
            title: Messages.requestQuantity,
            dataIndex: "quantity",
        },
    ];

    return (
        <div className="my-3">
            <div className="flex items-center mb-3">
                <h5 className="text-[16px] flex-1">
                    {Messages.materialItemsList}
                </h5>
            </div>

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                dataSource={request?.materials}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default MaterialRequestDetailItems;
