import {
    AwesomeTableComponent,
    IColumnsProps,
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { chain, filter, forEach, sumBy } from "lodash";
import { useContext, useMemo } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import {
    VOUCHER_STATUS,
    VOUCHER_STATUSES,
    VOUCHER_TYPE,
} from "../../../constant/voucher";
import { VoucherDetailContext } from "../../../context/voucher";
import { IVoucher } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { groupVouchers } from "../../../utils/Utils";

const USAGE_KEYS: IRowsKey<IVoucher>[] = [
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => (
            <ViewLabelStatus
                status={data}
                listStatus={VOUCHER_STATUSES}
                getLabel={(item) => (Messages as any)[item.label]}
            />
        ),
    },
    {
        id: "usage",
        label: Messages.usedAt,
        renderContent: ({ data }) => TimeUtils.toDateTime(data?.usage?.usedAt),
    },
    {
        id: "usage",
        label: Messages.usedBy,
        renderContent: ({ data }) => (
            <CustomerAvatarName customer={data?.usage?.usedBy} />
        ),
    },
    {
        id: "usage",
        label: Messages.treatmentRef,
        renderContent: ({ data }) => data?.ref,
    },
];

const VoucherUsageStatus = () => {
    const { voucher } = useContext(VoucherDetailContext);

    const { type } = voucher;

    const usageKeys = useMemo(() => {
        if (voucher?.status !== VOUCHER_STATUS.USED) {
            return filter(USAGE_KEYS, (item) => item.id !== "usage");
        }
        return USAGE_KEYS;
    }, [voucher?.status]);

    const columns: IColumnsProps = [
        {
            title: Messages.date,
            dataIndex: "usedAt",
            render: (data) =>
                data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
        },
        {
            title: Messages.status,
            dataIndex: "usedAt",
            render: (data) => (
                <ViewLabelStatus
                    status={VOUCHER_STATUS.USED}
                    listStatus={VOUCHER_STATUSES}
                    getLabel={(item) => Messages?.[item?.label]}
                />
            ),
        },
        {
            title: Messages.usedAmount,
            dataIndex: "usedValue",
            render: (data) =>
                data ? (
                    <CurrencyFormat
                        displayType="text"
                        value={data}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                ) : (
                    "N/A"
                ),
        },
        {
            title: Messages.balanceAmount,
            dataIndex: "balance",
            render: (data) =>
                data ? (
                    <CurrencyFormat
                        displayType="text"
                        value={data}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                ) : (
                    "0"
                ),
        },
        // {
        //     title: Messages.usedBy,
        //     dataIndex: "usedBy",
        //     render: (data) => (
        //         <CustomerAvatarName customer={data?.usage?.usedBy} />
        //     ),
        // },
        {
            title: Messages.orderRef,
            dataIndex: "order",
            render: (data) =>
                data?.orderNo ? (
                    <AppLink
                        to={generatePath(Path.ORDER_DETAIL, {
                            orderId: data?.id ?? "",
                        })}
                        target="_blank"
                    >
                        {data?.orderNo}
                    </AppLink>
                ) : (
                    "N/A"
                ),
        },
    ];

    const vouchersUsage = useMemo(() => {
        const groupedOrder = chain(voucher?.usage)
            //@ts-ignore
            ?.groupBy("order.id")
            .value();
        const vouchersList: any[] = [];
        forEach(groupedOrder, (orders) => {
            vouchersList.push({
                ...orders[0],
                usedValue: sumBy(orders, "usedValue"),
            });
        });
        return vouchersList;
    }, [voucher]);

    return (
        <div className="border p-3 mt-3">
            <label>{Messages.usageStatus}</label>

            {type === VOUCHER_TYPE.CASH_VOUCHER && (
                <AwesomeTableComponent
                    columns={columns}
                    className=""
                    dataSource={vouchersUsage ?? []}
                    pagination={false}
                />
            )}

            {type === VOUCHER_TYPE.GIFT_VOUCHER && (
                <ViewRowInterchange
                    keyList={usageKeys}
                    dataSource={voucher}
                    variant="background"
                />
            )}
        </div>
    );
};

export default VoucherUsageStatus;
