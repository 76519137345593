import LocalizedStrings from "react-localization";
import en from "./en";
import th from "./th";

const Messages: any = new LocalizedStrings({
    en,
    // th,
});

Messages.bothLangsText = (key: string, separate = "/", newLine = false) => {
    if (newLine) {
        return (
            <>
                {th[key]}
                {separate}
                <br />
                {en[key]}
            </>
        );
    }
    return (th[key] ?? "") + (th[key] && en[key] ? separate : "") + en[key];
};

Messages.getBothLangsText = (
    key: string,
    isBothLanguage: boolean,
    newLine = false
) => {
    if (isBothLanguage) {
        return Messages.bothLangsText(key, undefined, newLine);
    }
    return Messages[key];
};

Messages.getMessageByLang = (key: string, lang: string) => {
    return lang === "th" ? th[key] : en[key];
};

export default Messages;
