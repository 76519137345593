import { useContext, useEffect, useState } from "react";
import { Notifications } from "d-react-components";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";
import UserSelect from "../../user/share/UserSelect";
import UserSelectedView from "../../user/share/UserSelectedView";

const EditSalespersonDrawer = ({
    open,
    onClose,
    onSave,
    salesPerson,
    products,
}: any) => {
    const [selectedCoSalePerson, setSelectedCoSalePerson] =
        useState<any>(salesPerson);

    const handleSave = () => {
        //concat all salesPerson of all iteration of products into one array
        const allSalesPerson = products
            .map((product: any) => product.salesPerson)
            .flat();
        // find the salesPerson that is selected in the salesPerson array
        const selectedSalesPerson = allSalesPerson.find(
            (sp: any) => sp?.salesPerson?.id === selectedCoSalePerson.id
        );
        if (!selectedSalesPerson) {
            onSave && onSave(selectedCoSalePerson);
        } else {
            Notifications.showError(Messages.salesPersonExistInOrder);
        }
    };

    return (
        <Drawer
            open={open}
            title={`${Messages.edit} ${Messages.primarySalesPerson}`}
            onClose={() => onClose()}
            onSave={handleSave}
            destroyOnClose
            size="auto"
            width="500px"
        >
            <div className="p-3">
                <UserSelect
                    onChange={(value) => {
                        setSelectedCoSalePerson(value);
                    }}
                    label={Messages.primarySalesPerson}
                    value={selectedCoSalePerson}
                    allowClear={false}
                    // params={{ jobType: JobTypeAdmin.Doctor }}
                />
                {selectedCoSalePerson && (
                    <UserSelectedView user={selectedCoSalePerson} />
                )}
            </div>
        </Drawer>
    );
};

export default EditSalespersonDrawer;
