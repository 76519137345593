import classNames from "classnames";
import { Icon } from "d-react-components";
import Messages from "../../languages/Messages";
import { base64ImgSource, isBase64String } from "../../utils/Utils";
import "./UploadAvatar.scss";
import UploadField from "./UploadField";

export default function UploadAvatar({
    onChange,
    disabled = false,
    value = null,
    className = "",
    classNameImg = "",
    text = "",
    variant = "rounded",
}: any) {
    const iconClass = classNames("d-none", {
        "uploadImageModule_avatar-container-icon": !disabled,
    });

    return (
        <UploadField
            className={`uploadImagesModule ${className}`}
            onChange={(value) => onChange(value)}
        >
            {value ? (
                <div
                    className={`uploadImageModule_avatar-container rounded w-100 h-100 ${classNames(
                        { rounded: variant === "rounded" }
                    )}`}
                >
                    <img
                        className={`avatarPhoto ${classNameImg}`}
                        src={
                            isBase64String(value)
                                ? base64ImgSource(value)
                                : value
                        }
                        alt=""
                    />
                    <div className={iconClass}>
                        <Icon name="photo_camera" size="large" />
                    </div>
                </div>
            ) : (
                <div className="flex flex-column items-center justify-items-center">
                    {text ? (
                        <div className="text-white text-center font-weight-normal h1">
                            {text.charAt(0)}
                        </div>
                    ) : (
                        <>
                            <Icon name="photo_camera" />
                            <text className="mt-2">{Messages.upload}</text>
                        </>
                    )}
                </div>
            )}
        </UploadField>
    );
}
