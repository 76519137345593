import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";
import { F_DELIVERY_DRIVER } from "./delivery-driver";
import { F_PRODUCT_BASIC } from "./product";

export const F_STOCK_TRANSFER_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_STOCK_TRANSFER_CONFIRMATION on AdminProductStockTransferConfirmation {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        at
        remark
    }
`;

export const F_STOCK_TRANSFER = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_BRANCH_BASIC}
    ${F_PRODUCT_BASIC}
    ${F_STOCK_TRANSFER_CONFIRMATION}
    ${F_DELIVERY_DRIVER}
    fragment F_STOCK_TRANSFER on AdminProductStockTransfer {
        id
        updatedAt
        createdAt
        transferNo
        status
        type
        remark
        expectedDateOfTransfer
        expectedDateOfArrival
        attachments
        origin {
            ...F_BRANCH_BASIC
        }
        destination {
            ...F_BRANCH_BASIC
        }
        products {
            product {
                ...F_PRODUCT_BASIC
            }
            stock
        }
        deliveries {
            ...F_DELIVERY_DRIVER
        }
        confirmation {
            ...F_STOCK_TRANSFER_CONFIRMATION
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        requests {
            id
            requestNo
            createByAdmin {
                ...F_ADMIN_USER_BASIC
            }
            createdAt
        }
    }
`;

export const F_STOCK_TRANSFERS = gql`
    ${F_STOCK_TRANSFER}
    ${F_PAGINATE}
    fragment F_STOCK_TRANSFERS on ListAdminProductStockTransferResponse {
        data {
            ...F_STOCK_TRANSFER
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
