import {
    AwesomeTableComponent,
    IColumnsProps,
    InputTextSearch,
    Summary,
} from "d-react-components";
import { debounce, filter, find, map, reduce } from "lodash";
import { useContext, useMemo, useState } from "react";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/share/ProductNameView";

const StockTransferItems = () => {
    const { stockTransfer } = useContext(StockTransferDetailContext);
    const { deliveries } = stockTransfer;
    const [textSearch, setTextSearch] = useState<string>("");

    const proComputed = useMemo(() => {
        const mappedPros = map(stockTransfer?.products ?? [], (item) => {
            const driverReceived = reduce(
                deliveries,
                (res, iDel, index) => {
                    const qty =
                        find(
                            iDel?.stocks,
                            (i) => i?.product?.id === item?.product?.id
                        )?.driverReceived ?? 0;
                    return res + qty;
                },
                0
            );
            const staffReceived = reduce(
                deliveries,
                (res, iDel, index) => {
                    const qty =
                        find(
                            iDel?.stocks,
                            (i) => i?.product?.id === item?.product?.id
                        )?.staffReceived ?? 0;
                    return res + qty;
                },
                0
            );
            return {
                ...(item?.product ?? {}),
                stock: item?.stock ?? 0,
                driverReceived,
                staffReceived,
            };
        });
        return mappedPros;
    }, [textSearch, stockTransfer?.products]);

    const proSource = useMemo(() => {
        const mappedPros = proComputed;
        if (textSearch) {
            return filter(mappedPros, (item) => {
                const { name, sku } = item;
                const text = textSearch?.toLocaleLowerCase?.();
                return (
                    name?.toLocaleLowerCase?.()?.indexOf(text) !== -1 ||
                    sku?.toLocaleLowerCase?.()?.indexOf(text) !== -1
                );
            });
        }
        return mappedPros;
    }, [textSearch, proComputed]);

    const { total, driverReceived, staffReceived } = useMemo(() => {
        const total = reduce(
            proComputed,
            (res, i, index) => {
                return res + (i?.stock ?? 0);
            },
            0
        );
        const driverReceived = reduce(
            proComputed,
            (res, i, index) => {
                return res + (i?.driverReceived ?? 0);
            },
            0
        );
        const staffReceived = reduce(
            proComputed,
            (res, i, index) => {
                return res + (i?.staffReceived ?? 0);
            },
            0
        );
        return { total, driverReceived, staffReceived };
    }, [proComputed]);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            width: 500,
            render: (service) => <ProductNameView product={service} />,
        },
        {
            title: Messages.initialTransferred,
            dataIndex: "stock",
            align: "center",
        },
        {
            title: Messages.driverPickedUp,
            dataIndex: "driverReceived",
            align: "center",
        },
        {
            title: Messages.staffReceived,
            dataIndex: "staffReceived",
            align: "center",
        },
    ];

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 0);

    const renderSummaryRow = () => {
        return (
            <Summary.Row>
                <Summary.Cell
                    index={1}
                    colSpan={1}
                    className="text-right font-weight-bold"
                >
                    {Messages.total}
                </Summary.Cell>
                <Summary.Cell
                    index={2}
                    className="text-center font-weight-bold"
                    colSpan={1}
                >
                    {total}
                </Summary.Cell>
                <Summary.Cell
                    index={2}
                    className="text-center font-weight-bold"
                    colSpan={1}
                >
                    {driverReceived}/{total}
                </Summary.Cell>
                <Summary.Cell
                    index={2}
                    className="text-center font-weight-bold"
                    colSpan={1}
                >
                    {staffReceived}/{total}
                </Summary.Cell>
            </Summary.Row>
        );
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={proSource}
                className="mt-3"
                pagination={false}
                baseColumnProps={{ width: 150 }}
                summary={() => renderSummaryRow()}
            />
        </div>
    );
};

export default StockTransferItems;
