import Checkbox from "antd/lib/checkbox/Checkbox";
import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useCallback, useContext, useRef, useState } from "react";
import {
    TypeOfProduct,
    useMapManualProductOrderForAdminMutation,
} from "../../../api/hooks";
import ProductAPI from "../../../api/queries/product";
import Drawer from "../../../common/Drawer";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const SelectProductDrawer = ({
    open,
    onClose,
    onSave,
    isSingleSelect = true,
}: any) => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [selectingProduct, setSelectingProduct] = useState<any[]>([]);
    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const handleRemoveProductFromCart = useCallback(
        (product) => {
            const clone =
                selectingProduct &&
                selectingProduct?.filter((item) => item?.id !== product?.id);
            setSelectingProduct(clone);
        },
        [selectingProduct]
    );

    const handleAddProductToCart = useCallback(
        (product) => {
            if (isSingleSelect) {
                setSelectingProduct([product]);
            } else {
                setSelectingProduct([...selectingProduct, product]);
            }
        },
        [isSingleSelect, selectingProduct]
    );

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
        },
        {
            title: Messages.sku,
            dataIndex: "sku",
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
        },
        {
            title: "",
            dataIndex: "",
            width: 50,
            render: (productItem: any) => {
                const isSelected =
                    selectingProduct &&
                    selectingProduct.filter(
                        (product) => product.id === productItem.id
                    ).length > 0;

                return (
                    <Checkbox
                        className="checkBoxSelectAllProducts"
                        checked={isSelected}
                        onClick={() =>
                            isSelected
                                ? handleRemoveProductFromCart(productItem)
                                : handleAddProductToCart(productItem)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return ProductAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            typeOfProduct: TypeOfProduct.SimpleProduct,
            sort: {
                createdAt: -1,
            },
        });
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width="800px"
            size="auto"
            title={Messages.mapProduct}
            maskClosable={false}
            destroyOnClose
            onSave={() => onSave && onSave(selectingProduct)}
        >
            <div className="p-4">
                <HeaderTable
                    onChangeText={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                    className="app-layout__page-header"
                />
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    source={source}
                    columns={columns}
                    baseColumnProps={{ width: 100 }}
                    getTotalItems={(res) => {
                        return res?.pagination?.items ?? 0;
                    }}
                    transformer={(res) => res?.data ?? []}
                />
            </div>
        </Drawer>
    );
};

const MapCustomProductDrawer = ({
    product,
    isRemap = false,
    onUpdated,
}: {
    product: any;
    isRemap?: boolean;
    onUpdated?: () => void;
}) => {
    const [mapProduct] = useMapManualProductOrderForAdminMutation();
    const [showSelectProduct, setShowSelectProduct] = useState(false);
    const { order } = useContext(OrderDetailContext);
    const handleMapProduct = (products: any[]) => {
        if (!products.length) {
            setShowSelectProduct(false);
            return;
        }
        Progress.show(
            {
                method: mapProduct,
                params: [
                    {
                        variables: {
                            id: order?.id as string,
                            payload: {
                                manualProductId: product.id,
                                productId: products?.[0]?.id,
                            },
                        },
                    },
                ],
            },
            () => {
                Notifications.showSuccess(Messages.updateSuccess);
                setShowSelectProduct(false);
                onUpdated && onUpdated();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <>
            {isRemap && (
                <div className="px-1 text-xs ml-1 mr-1 relative bg-gray-600 text-white">
                    {Messages.mapped}
                </div>
            )}
            <div
                className="cursor-pointer px-1 text-xs ml-1 relative bg-gray-600 text-white"
                onClick={() => setShowSelectProduct(true)}
            >
                {isRemap ? Messages.reMap : Messages.map}
            </div>
            {showSelectProduct && (
                <SelectProductDrawer
                    open={showSelectProduct}
                    onClose={() => setShowSelectProduct(false)}
                    onSave={handleMapProduct}
                />
            )}
        </>
    );
};

export default MapCustomProductDrawer;
