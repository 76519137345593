import {
    ViewLabelStatus,
    IColumnsProps,
    TimeUtils,
    AwesomeTableComponent,
} from "d-react-components";
import React, { useRef, useContext } from "react";
import { generatePath, useParams } from "react-router";
import { useListMaterialPurchaseLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import { MATERIAL_PURCHASE_STATUSES } from "../../../constant/material";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const MaterialDetailPurchases = () => {
    const tableRef = useRef<any>(null);
    const { materialId } = useParams();
    const [listMaterialPurchase] = useListMaterialPurchaseLazyQuery();

    const source = (pagingData: any) => {
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            material: [materialId] as any,
        };
        return listMaterialPurchase({
            variables: {
                paginate: input,
            },
            fetchPolicy: "no-cache",
        });
    };

    const columns: IColumnsProps = [
        {
            title: Messages.poNo,
            dataIndex: "materialPurchaseNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            width: 100,
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_PURCHASE_STATUSES}
                    getLabel={(item) => {
                        return Messages[item.label];
                    }}
                />
            ),
        },
    ];

    return (
        <div className="p-4">
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
        </div>
    );
};

export default MaterialDetailPurchases;
