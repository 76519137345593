import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";
import { F_CUSTOMER } from "./customer";
import { F_EQUIPMENT_DETAIL } from "./equipment";
import { F_MATERIAL_BASIC } from "./material";
import { F_PRODUCT_SALESPERSON } from "./order";
import { F_PRODUCT_BASIC, F_PRODUCT_CATEGORY } from "./product";

export const F_TREATMENT_PRODUCT = gql`
    ${F_PRODUCT_BASIC}
    fragment F_TREATMENT_PRODUCT on AdminTreatmentProductDto {
        quantity
        hasPermissionDelete
        hasPermissionAdjustment
        provideAfterTreatment
        isPreset
        product {
            ...F_PRODUCT_BASIC
        }
    }
`;

export const F_TREATMENT_SERVICE = gql`
    ${F_PRODUCT_BASIC}
    fragment F_TREATMENT_SERVICE on AdminTreatmentService {
        quantity
        hasPermissionAdjustment
        hasPermissionDelete
        provideAfterTreatment
        isPreset
        product {
            ...F_PRODUCT_BASIC
        }
    }
`;

export const F_TREATMENT_MATERIAL = gql`
    ${F_MATERIAL_BASIC}
    fragment F_TREATMENT_MATERIAL on AdminTreatmentMaterial {
        quantity
        hasPermissionAdjustment
        hasPermissionDelete
        isPreset
        provideAfterTreatment
        material {
            ...F_MATERIAL_BASIC
        }
    }
`;

export const F_TREATMENT_USER_JOB_COST = gql`
    fragment F_TREATMENT_USER_JOB_COST on TreatmentUserJobCostDto {
        type
        quantity
        amount
        manualAmount
        updatedAt
        unit
        start
        end
    }
`;

export const F_TREATMENT_USER = gql`
    ${F_TREATMENT_USER_JOB_COST}
    fragment F_TREATMENT_USER on AdminTreatmentUserDto {
        adminUser {
            id
            adminNo
            firstName
            lastName
            nickName
            avatar
            companyId
            jobType
            commissionServiceType
            commissionService {
                value
                service {
                    id
                    name
                    serviceNo
                }
            }
        }
        schedule {
            start
            end
        }
        jobCost {
            ...F_TREATMENT_USER_JOB_COST
        }
    }
`;

export const F_TREATMENT_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    fragment F_TREATMENT_CONFIRMATION on AdminTreatmentConfirmationDto {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        createdAt
        actualTime
        remark
    }
`;

export const F_TREATMENT_JOB_COST_METRIC = gql`
    fragment F_TREATMENT_JOB_COST_METRIC on TreatmentJobCostMetricDto {
        jobCostPreset
        valid
        reasonOfInvalid
    }
`;

export const F_TREATMENT = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_TREATMENT_PRODUCT}
    ${F_BRANCH_BASIC}
    ${F_TREATMENT_USER}
    ${F_CUSTOMER}
    ${F_EQUIPMENT_DETAIL}
    ${F_TREATMENT_CONFIRMATION}
    ${F_TREATMENT_MATERIAL}
    ${F_TREATMENT_SERVICE}
    fragment F_TREATMENT on AdminTreatmentDto {
        id
        treatmentNo
        updatedAt
        createdAt
        status
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        beforeAfter {
            id
            refNo
            createdAt
            updatedAt
            recordedAt
            description
            before
            after
        }
        doctorOrder {
            id
            updatedAt
            createdAt
            doctorOrderNo
            doctorSubject
            status
            order {
                id
                status
                orderNo
                subtotal
                total
                salesPerson {
                    id
                    adminNo
                    firstName
                    lastName
                    nickName
                    companyId
                }
            }
        }
        customer {
            ...F_CUSTOMER
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        products {
            ...F_TREATMENT_PRODUCT
        }
        services {
            ...F_TREATMENT_PRODUCT
            serviceConfiguration {
                id
                jobCostTemplate
                surgeryType
                materialPreset {
                    quantity
                    material {
                        id
                        name
                    }
                }
            }
        }
        materials {
            ...F_TREATMENT_MATERIAL
        }
        doctor {
            ...F_TREATMENT_USER
        }
        branch {
            ...F_BRANCH_BASIC
        }
        PIC {
            ...F_TREATMENT_USER
        }
        equipments {
            equipment {
                ...F_EQUIPMENT_DETAIL
            }
            schedule {
                start
                end
            }
        }
        starting {
            ...F_TREATMENT_CONFIRMATION
        }
        completion {
            ...F_TREATMENT_CONFIRMATION
        }
        cancellation {
            ...F_TREATMENT_CONFIRMATION
        }
        bom
        hasActionCalculatorBom
        complicationTreatments {
            id
            treatmentNo
        }
        addOnServices {
            ...F_TREATMENT_SERVICE
        }
    }
`;

export const F_TREATMENT_ITEM = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_TREATMENT_PRODUCT}
    ${F_BRANCH_BASIC}
    ${F_TREATMENT_USER}
    ${F_CUSTOMER}
    fragment F_TREATMENT_ITEM on AdminTreatmentDto {
        id
        treatmentNo
        updatedAt
        createdAt
        status
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        doctorOrder {
            id
            doctorOrderNo
        }
        customer {
            ...F_CUSTOMER
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        products {
            ...F_TREATMENT_PRODUCT
        }
        services {
            ...F_TREATMENT_PRODUCT
        }
        doctor {
            ...F_TREATMENT_USER
        }
        branch {
            ...F_BRANCH_BASIC
        }
    }
`;

export const F_TREATMENTS = gql`
    ${F_TREATMENT_ITEM}
    ${F_PAGINATE}
    fragment F_TREATMENTS on AdminTreatmentPaginateResponse {
        data {
            ...F_TREATMENT_ITEM
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_TREATMENT_CALENDAR = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_PRODUCT_BASIC}
    fragment F_TREATMENT_CALENDAR on AdminTreatmentDto {
        id
        treatmentNo
        status
        doctor {
            adminUser {
                ...F_ADMIN_USER_BASIC
            }
            schedule {
                start
                end
            }
        }
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        branch {
            id
            name
            code
        }
        services {
            quantity
            product {
                ...F_PRODUCT_BASIC
            }
        }
    }
`;

export const F_TREATMENT_PRODUCT_DOCTOR_REPORT = gql`
    ${F_PRODUCT_CATEGORY}
    fragment F_TREATMENT_PRODUCT_DOCTOR_REPORT on AdminTreatmentProductDto {
        quantity
        productFull {
            id
            name
            productNo
            status
            sku
            salePrice
            regularPrice
            category {
                ...F_PRODUCT_CATEGORY
            }
            service {
                id
                name
                serviceNo
                defaultSaleCommission
            }
        }
    }
`;

export const F_TREATMENT_USER_REPORT = gql`
    ${F_TREATMENT_PRODUCT_DOCTOR_REPORT}
    ${F_BRANCH_BASIC}
    ${F_TREATMENT_USER}
    ${F_PRODUCT_BASIC}
    ${F_PRODUCT_SALESPERSON}
    fragment F_TREATMENT_USER_REPORT on AdminTreatmentDto {
        id
        treatmentNo
        updatedAt
        createdAt
        status
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }

        customer {
            id
            customerNo
            title
            fullNameTh
            fullNameEn
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            nickname
        }

        services {
            ...F_TREATMENT_PRODUCT_DOCTOR_REPORT
        }

        doctor {
            ...F_TREATMENT_USER
        }
        branch {
            ...F_BRANCH_BASIC
        }

        jobCostAssessment
        manualJobCostAssessment

        PIC {
            ...F_TREATMENT_USER
        }

        doctorOrder {
            id
            updatedAt
            createdAt
            doctorOrderNo
            doctorSubject
            status
            order {
                id
                status
                orderNo
                subtotal
                total
                products {
                    quantity
                    regularPrice
                    salePrice
                    product {
                        ...F_PRODUCT_BASIC
                    }
                    salesPerson {
                        ...F_PRODUCT_SALESPERSON
                    }

                    doctorOrder {
                        id
                        doctorOrderNo
                    }
                }

                voucher {
                    quantity
                    amount
                    voucherFull {
                        id
                        voucherNo
                        totalUsedTimes
                        totalUsedValues
                        usageLimitPerCoupon
                        effectiveDate
                        expiryDate
                        usage {
                            usedAt
                            usedValue
                            ref
                            refId
                            balance
                        }
                        order {
                            id
                            orderNo
                            status
                            subtotal
                            total
                            products {
                                id
                                typeOfProduct
                                quantity
                                salePrice
                                hasOpeningPrice
                                regularPrice
                                manualProduct
                                premiumService {
                                    spending
                                    premiumServiceType
                                    product {
                                        ...F_PRODUCT_BASIC
                                    }
                                }
                                product {
                                    id
                                    name
                                    sku
                                    regularPrice
                                    salePrice
                                }
                            }
                        }
                    }

                    product {
                        id
                        name
                        productNo
                        sku
                    }
                }
            }
        }
    }
`;
