import styled from "@emotion/styled";
import { Button, Checkbox, InputText } from "d-react-components";
import { find, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { DiscountType } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { MaterialPurchaseContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import ProductSolidSelect from "../../product/share/ProductSolidSelect";
import { IOrderManualDiscount } from "../../../interfaces/order";

export interface IDiscountDrawerProps {
    [key: string]: any;
}

export interface IDiscountInputProps {
    [key: string]: any;
}

export enum ApplyDiscountType {
    sku = "sku",
    cart = "cart",
}

export interface IManualDiscountSelector {
    type?: ApplyDiscountType;
}

const DiscountDrawer: React.FC<IDiscountDrawerProps> = ({
    open,
    onClose,
    onSave,
}: any) => {
    const { productList, manualDiscount } = useContext(MaterialPurchaseContext);
    const [selectManualDiscount, setSelectManualDiscount] = useState<
        IOrderManualDiscount[]
    >([]);
    const [activeSku, setActiveSku] = useState<any>();
    const [applyDiscountType, setApplyDiscountType] =
        useState<ApplyDiscountType>();

    useEffect(() => {
        if (open) {
            setSelectManualDiscount(manualDiscount || []);
        }
    }, [open]);

    const handleAddDiscount = () => {
        onSave && onSave(selectManualDiscount);
        onClose && onClose();
    };

    const getActiveSkuDiscount = () => {
        if (!activeSku) {
            return null;
        }
        const foundValue =
            find(
                selectManualDiscount ?? [],
                (i) => i?.product?.id === activeSku?.id
            ) ?? null;
        return foundValue;
    };
    const getWholeCartDiscount = () => {
        const foundValue =
            find(selectManualDiscount ?? [], (i) => i?.isWholeCart) ?? null;
        return foundValue;
    };

    const onUpdateActiveSkuValue = (updateValue: any) => {
        if (!activeSku) {
            return;
        }
        const skuValue = activeSku;
        const isExisted = !!find(
            selectManualDiscount ?? [],
            (i) => i?.product?.id === skuValue?.id
        );

        if (isExisted) {
            setSelectManualDiscount(
                map(selectManualDiscount || [], (item) => {
                    if (
                        item?.product?.id &&
                        item?.product?.id === skuValue?.id
                    ) {
                        return {
                            ...item,
                            ...updateValue,
                        };
                    }
                    return item;
                })
            );
        } else {
            setSelectManualDiscount([
                ...selectManualDiscount,
                { ...updateValue, product: skuValue },
            ]);
        }
    };

    const onUpdateDiscountCart = (updateValue: any) => {
        const isExisted = !!find(
            selectManualDiscount ?? [],
            (i) => i?.isWholeCart
        );
        if (isExisted) {
            setSelectManualDiscount(
                map(selectManualDiscount || [], (item) => {
                    if (item?.isWholeCart) {
                        return {
                            ...item,
                            ...updateValue,
                        };
                    }
                    return item;
                })
            );
        } else {
            setSelectManualDiscount([
                ...selectManualDiscount,
                { ...updateValue, isWholeCart: true },
            ]);
        }
    };

    return (
        <Drawer
            open={open}
            title={Messages.addDiscount}
            onClose={() => onClose()}
            onSave={handleAddDiscount}
            closable={false}
            maskClosable={false}
            destroyOnClose
            // disableSave={!voucherCodes.length}
            size="auto"
            width="600px"
        >
            <div className="p-4">
                <Checkbox
                    variant="radio"
                    label={Messages.discountForASpecificSku}
                    onChange={() => {
                        setApplyDiscountType(ApplyDiscountType.sku);
                    }}
                    checked={applyDiscountType === ApplyDiscountType.sku}
                />
                {applyDiscountType === ApplyDiscountType.sku && (
                    <div className="pl-3">
                        <ProductSolidSelect
                            showSearch={false}
                            dataSource={productList}
                            label="SKU"
                            value={activeSku || null}
                            className="mt-3"
                            onChange={(v: any) => {
                                setActiveSku(v);
                            }}
                            getValue={(item) => item?.id}
                        />
                        <DiscountInput
                            disabled={
                                applyDiscountType !== ApplyDiscountType.sku
                            }
                            key={activeSku?.id}
                            value={getActiveSkuDiscount()}
                            onChange={(v: any) => {
                                onUpdateActiveSkuValue(v);
                            }}
                        />
                    </div>
                )}
                <Checkbox
                    className="mt-4"
                    variant="radio"
                    label={Messages.discountForWholeCart}
                    onChange={() => {
                        setApplyDiscountType(ApplyDiscountType.cart);
                    }}
                    checked={applyDiscountType === ApplyDiscountType.cart}
                />
                {applyDiscountType === ApplyDiscountType.cart && (
                    <div className="pl-3">
                        <DiscountInput
                            disabled={
                                applyDiscountType !== ApplyDiscountType.cart
                            }
                            key={applyDiscountType}
                            value={getWholeCartDiscount()}
                            onChange={(v: any) => {
                                onUpdateDiscountCart(v);
                            }}
                        />
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default DiscountDrawer;

const DiscountInput: React.FC<IDiscountInputProps> = ({
    label = Messages.amount,
    value,
    onChange,
    disabled,
}) => {
    return (
        <DiscountInputStyled className="mt-3">
            <label className="font-semibold">{label}</label>
            <div className="flex-center-y">
                <Button
                    disabled={disabled}
                    color={
                        value?.discountType === DiscountType?.Amount
                            ? "primary"
                            : "gray"
                    }
                    onClick={() =>
                        onChange &&
                        onChange({
                            discountType: DiscountType.Amount,
                        })
                    }
                >
                    THB
                </Button>
                <Button
                    color={
                        value?.discountType === DiscountType?.Percentage
                            ? "primary"
                            : "gray"
                    }
                    onClick={() => {
                        onChange &&
                            onChange({
                                discountType: DiscountType.Percentage,
                            });
                    }}
                    disabled={disabled}
                >
                    %
                </Button>
                <InputText
                    disabled={disabled || !value?.discountType}
                    className="w-full"
                    value={value?.discountValue}
                    onChange={(e) => {
                        onChange &&
                            onChange({
                                ...(value || {}),
                                discountValue: e?.target?.value,
                            });
                    }}
                />
            </div>
        </DiscountInputStyled>
    );
};

const DiscountInputStyled = styled.div`
    button {
        transition: all 0.1s linear !important;
    }
`;
