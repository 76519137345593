import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
} from "d-react-components";
import { debounce, find, map, split } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import { useGetServiceConfigurationsLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import ExportProgress from "../../../common/views/ExportProgress";
import {
    JOB_COST_TEMPLATES,
    SERVICE_SURGERY_TYPES,
} from "../../../constant/service";
import { mapServiceConfigurationFilterToServer } from "../../../interfaces/service-configuration";
import Messages from "../../../languages/Messages";
import { getHhMmFromMinutes } from "../../../utils/Utils";
import Path from "../../Path";
import ServiceConfigurationTableFilterDrawer from "./ServiceConfigurationTableFilterDrawer";

const ServiceConfigurationTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    const [getServiceConfigurations] = useGetServiceConfigurationsLazyQuery();
    const [startExport, setStartExport] = useState(false);

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "serviceNo",
            width: 25,
            render: (serviceNo, item) => (
                <AppLink
                    to={generatePath(Path.SERVICE_CONFIGURATION_DETAIL, {
                        configId: item.id,
                    })}
                >
                    {serviceNo}
                </AppLink>
            ),
        },
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.SERVICE_CONFIGURATION_DETAIL, {
                        configId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (item) => item?.name,
        },
        {
            title: Messages.estProcedureTime,
            dataIndex: "procedureTime",
        },

        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        // {
        //     title: Messages.action,
        //     dataIndex: "",
        //     align: "center",
        //     render: (config: any) => {
        //         return (
        //             <TableAction
        //                 onClick={(actionId: string) =>
        //                     onClickTableAction(actionId, config)
        //                 }
        //             />
        //         );
        //     },
        // },
    ];

    const source = (pagingData: any) => {
        const filterInput = mapServiceConfigurationFilterToServer(filter);

        return getServiceConfigurations({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex as any,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: {
                        createdAt: -1,
                    },
                    ...filterInput,
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickTableAction = (actionId: string, config: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.SERVICE_CONFIGURATION_DETAIL, {
                        configId: config.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.serviceConfiguration}
                onClickFilter={() => setOpenFilter(true)}
                onClickExport={() => {
                    setStartExport(true);
                }}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                onClickNew={() => navigate(Path.SERVICE_CONFIGURATION_CREATE)}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
            <ExportProgress
                source={(props = {}) => {
                    return getServiceConfigurations({
                        variables: {
                            paginate: {
                                page: 1,
                                limit: 30,
                                sort: {
                                    createdAt: -1,
                                },
                                ...props,
                            },
                        },
                        fetchPolicy: "no-cache",
                    });
                }}
                start={startExport}
                onStop={() => setStartExport(false)}
                mapDataToExport={(data: any) =>
                    mapServiceConfigDataToExport(data)
                }
                exportName="Der_Service_Configuration"
            />

            {openFilter && (
                <ServiceConfigurationTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default ServiceConfigurationTable;

const EXPORT_KEYS = [
    {
        id: "serviceNo",
        label: "System ID",
    },
    {
        id: "name",
        label: Messages.name,
    },
    {
        id: "category",
        label: Messages.category,
        getContent: ({ data }: any) => data?.name,
    },
    {
        id: "procedureTime",
        label: Messages.procedureTime,
        getContent: ({ data }: any) => {
            const HhMm = getHhMmFromMinutes(data);
            const arr = split(HhMm, ":");
            return `${arr?.[0]} ${Messages.hours} ${arr?.[1]} ${Messages.minutes}`;
        },
    },
    {
        id: "jobCostTemplate",
        label: Messages.jobCostTemplate,
        getContent: ({ data }: any) => {
            return (
                Messages?.[
                    find(JOB_COST_TEMPLATES, (item) => item?.id === data)
                        ?.label ?? ""
                ] ?? "N/A"
            );
        },
    },
    {
        id: "defaultSaleCommission",
        label: Messages.defaultSaleCommission,
        getContent: ({ data }: any) => `${data}%`,
    },
    {
        id: "surgeryType",
        label: Messages.surgeryType,
        getContent: ({ data }: any) => {
            return (
                Messages?.[
                    find(SERVICE_SURGERY_TYPES, (item) => item?.id === data)
                        ?.label ?? ""
                ] ?? "N/A"
            );
        },
    },
    {
        id: "createByAdmin",
        label: Messages.createdBy,
        getContent: ({ data }: any) => {
            const { firstName, lastName, companyId, nickName, id } = data ?? {};
            return `${firstName} ${lastName} (${nickName})`;
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        getContent: ({ data }: any) => TimeUtils.toDateTime(data),
    },
    {
        id: "updatedAt",
        label: Messages.updatedAt,
        getContent: ({ data }: any) => TimeUtils.toDateTime(data),
    },
];

const mapServiceConfigDataToExport = (data: any[]) => {
    const header: any[] = EXPORT_KEYS.map((item) => item.label);
    const rows = map(data, (item) => {
        const row: any[] = [];
        EXPORT_KEYS.forEach((i) => {
            const { id, getContent, label } = i;
            const dataItem = item[id];
            const v = getContent ? getContent({ data: dataItem }) : dataItem;
            row.push(v);
        });
        return row;
    });
    return [header, ...rows];
};

const checkDuplicate = () => {
    const array = [] as any;
    const elementTracker: any = {};
    const duplicates: any[] = [];

    array?.forEach?.((item: any) => {
        if (elementTracker[item?.serviceNo]) {
            duplicates.push(item);
        } else {
            elementTracker[item?.serviceNo] = true;
        }
    });
};
