import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
} from "d-react-components";
import { orderBy } from "lodash";
import React, { useContext, useRef, useState } from "react";
import {
    useGetUnassigedBranchListLazyQuery,
    useRemoveBranchFromAdminMutation,
    useUpdateAdminUserMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { UserDetailContext } from "../../../context/user";
import Messages from "../../../languages/Messages";

const UserDetailBranches = () => {
    const tableRef = useRef<any>(null);
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const [showModal, setShowModal] = useState(false);
    const [removeBranchFromAdmin] = useRemoveBranchFromAdminMutation();

    const removeBranch = (id: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemoveBranch,
            () =>
                Progress.show(
                    {
                        method: removeBranchFromAdmin,
                        params: [
                            {
                                variables: {
                                    id: user?.id as string,
                                    branchIds: [id],
                                },
                            },
                        ],
                    },
                    () => {
                        loadAdminDetail();
                    }
                )
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
        },
        {
            title: Messages.code,
            dataIndex: "code",
        },
        {
            title: Messages.action,
            dataIndex: "id",
            width: 30,
            render: (data) => {
                return (
                    <Icon
                        className="text-primary cursor-pointer"
                        name="remove_circle_outline"
                        onClick={() => removeBranch(data)}
                    />
                );
            },
        },
    ];

    return (
        <div className="my-3">
            <div className="flex items-center mb-3">
                <h5 className="text-[16px] flex-1">
                    {Messages.branchWarehouse}
                </h5>
                <Button
                    size="small"
                    iconName="add"
                    classNameIcon="mx-0 mr-1"
                    onClick={() => setShowModal(true)}
                >
                    {Messages.add}
                </Button>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                dataSource={orderBy(user?.branch, "name")}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
            {showModal && (
                <UserDetailBranchesUpdate
                    open={showModal}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

const UserDetailBranchesUpdate = ({ open, onClose }: any) => {
    const [getBranchList] = useGetUnassigedBranchListLazyQuery({
        fetchPolicy: "no-cache",
    });
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const [selectedRows, setSelectedRows] = useState<Array<any>>(
        (user?.branch ?? []).map((i: any) => i.id)
    );
    const tableRef = useRef<any>(null);

    const loadTableData = (paging: any) => {
        return getBranchList({
            variables: {
                adminId: user?.id as string,
                paginate: {
                    page: paging.pageIndex,
                    limit: paging.pageSize,
                },
            },
        });
    };

    const tableColumns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            align: "left",
            width: 60,
            render: (data, item) => data,
        },
        {
            title: Messages.code,
            dataIndex: "code",
            align: "left",
            width: 75,
            render: (data: any, item: any, index) => data,
        },
    ];

    const addBranchesToAdmin = () => {
        Progress.show(
            {
                method: updateAdminUser,
                params: [
                    {
                        variables: {
                            id: user?.id as string,
                            payload: {
                                branch: [
                                    ...(user?.branch?.map(
                                        (branch) => branch.id
                                    ) ?? []),
                                    ...selectedRows,
                                ],
                            },
                        },
                    },
                ],
            },
            (resp: any) => {
                loadAdminDetail();
                Notifications.showSuccess(Messages.updateUserSuccessfully);
                onClose();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <Drawer
            title={Messages.branch}
            open={open}
            onSave={() => addBranchesToAdmin()}
            onClose={onClose}
            size="auto"
            width="600px"
        >
            <div className="p-4">
                <AwesomeTableComponent
                    source={loadTableData}
                    transformer={(res) => {
                        return (
                            res?.data?.listUnassignedBranchForAdmin?.data ?? []
                        );
                    }}
                    getTotalItems={(res) => {
                        return (
                            res?.data?.listUnassignedBranchForAdmin?.pagination
                                ?.items ?? 0
                        );
                    }}
                    columns={tableColumns}
                    ref={tableRef}
                    showSelectColumn={false}
                    selectingRows={selectedRows}
                    rowSelection={{
                        onChange: (value) => setSelectedRows(value),
                        selectedRowKeys: selectedRows,
                        preserveSelectedRowKeys: true,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default UserDetailBranches;
