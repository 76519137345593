/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Icon, ObjectUtils } from "d-react-components";
import { filter, map, uniqBy } from "lodash";
import React from "react";
import DragContainer from "../../../common/drag/DragContainer";
import DragItem from "../../../common/drag/DragItem";
import Messages from "../../../languages/Messages";
import SelectMediaManagerButton from "../../media/selectmedia/SelectMediaManagerButton";

interface IGalleryValue {
    id: string;
    fileUrl: string;
}
interface IProductGalleryInput<T> {
    value: T[];
    onChange: (value: T[]) => void;
}

interface IImageItemView {
    media: IGalleryValue;
    onClick?: () => void;
    onClickDelete: () => void;
}

const ImageItemView = ({
    media,
    onClick,
    // onChangeRoleMedia,
    onClickDelete,
}: IImageItemView) => {
    const { fileUrl } = media;

    return (
        <div className="d-flex flex-column mr-3 mt-3 position-relative">
            <img
                src={fileUrl}
                className="image-square-x-large"
                onClick={onClick}
            />
            <div
                className=" product-create__gallery-image-remove"
                onClick={onClickDelete}
            >
                <Icon name="cancel" className="color-primary" />
            </div>

            {/* <Select
                className="mt-3"
                dataSource={PRODUCT_GALLERY_TAGS}
                multiple
                onChange={onChangeRoleMedia}
                value={roles}
            /> */}
        </div>
    );
};

const AddMediaView = () => {
    return (
        <div className="flex-center flex-column border-dashed image-square-x-large mt-3">
            <img src="/images/placeholder.png" className="image-square-small" />
            <small className="text-center mt-1">{Messages.browseImages}</small>
        </div>
    );
};

const ProductGalleryInput = ({
    value,
    onChange,
}: IProductGalleryInput<IGalleryValue>) => {
    const onClickDeleteMedia = (mediaItem: any) => {
        const result = filter(value, (item: any) => item?.id !== mediaItem.id);
        onChange(result);
    };

    const onMoveMedia = (dragIndex: number, hoverIndex: number) => {
        const results = ObjectUtils.arrayMove(
            value as any,
            dragIndex,
            hoverIndex
        );

        onChange([...results]);
    };

    const onSelectMedia = (mediaList: any) => {
        const allGallery = [...value, ...mediaList];
        const uniqueGallery = uniqBy(allGallery, (item) => item.id);
        onChange(uniqueGallery);
    };

    return (
        <div className="border p-3 product-create__gallery-images">
            <DragContainer>
                {map(value, (imageItem: any, index: number) => {
                    // if (imageItem.isVideo) {
                    //     return (
                    //         <DragItem
                    //             id={imageItem.id}
                    //             onMoveItem={onMoveMedia}
                    //             index={index}
                    //         >
                    //             <VideoItemView
                    //                 videoLinkData={imageItem}
                    //                 onClickDelete={() =>
                    //                     onClickDeleteMedia(imageItem)
                    //                 }
                    //                 onSaveVideo={onSaveVideoLink}
                    //             />
                    //         </DragItem>
                    //     );
                    // }
                    return (
                        <DragItem
                            id={imageItem.id}
                            onMoveItem={onMoveMedia}
                            index={index}
                        >
                            <ImageItemView
                                media={imageItem}
                                key={index}
                                // onClick={() =>
                                //     setMediaDetailModal({
                                //         open: true,
                                //         mediaDetail: imageItem,
                                //     })
                                // }
                                // onChangeRoleMedia={(value: any) =>
                                //     onChangeRoleMedia(imageItem, value)
                                // }
                                onClickDelete={() =>
                                    onClickDeleteMedia(imageItem)
                                }
                            />
                        </DragItem>
                    );
                })}
            </DragContainer>

            <SelectMediaManagerButton
                renderButton={() => <AddMediaView />}
                onSelect={onSelectMedia}
            />
        </div>
    );
};

export default ProductGalleryInput;
