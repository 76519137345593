import classNames from "classnames";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { MATERIAL_INVENTORY_STATUSES } from "../../../constant/material";
import BranchSelect from "../../branch/share/BranchSelect";
import MaterialCategorySelect from "../../material-category/share/MaterialCategorySelect";

interface IBookingFilterForm {
    filterForm: any;
}

const MaterialInventoryFilterForm = ({ filterForm }: IBookingFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <BranchSelect
                className={classNameInput}
                value={formValues?.branch}
                onChange={(value) => filterForm.setFieldValue("branch", value)}
                multiple
            />
            <InputSelectForm
                keyData="status"
                dataSource={MATERIAL_INVENTORY_STATUSES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <MaterialCategorySelect
                className={classNameInput}
                multiple
                value={formValues?.category}
                onChange={(value) =>
                    filterForm.setFieldValue("category", value)
                }
            />
        </div>
    );
};

export default MaterialInventoryFilterForm;
