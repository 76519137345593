import { useFormik } from "formik";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";

import Messages from "../../../languages/Messages";
import { CreditFilterForm } from "./CreditFilterForm";
import { mapVoucherFilterToQueryParams } from "../../../interfaces/voucher";

interface IVoucherTableFilterDrawer {
    open: boolean;
    onClose: () => void;
}

const VoucherTableFilterDrawer = ({
    open,
    onClose,
}: IVoucherTableFilterDrawer) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const params = mapVoucherFilterToQueryParams(values);
            setFilter(params);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
            size="auto"
            width="30%"
        >
            <CreditFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default VoucherTableFilterDrawer;
