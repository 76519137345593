import React, { useMemo, useRef, useState } from "react";
import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
} from "d-react-components";
import { debounce } from "lodash";
import CurrencyFormat from "react-currency-format";
import Messages from "../../../languages/Messages";
import Drawer, { CustomDrawerProps } from "../../../common/Drawer";

export const SIZE_OPTIONS = [10, 20, 50, 100];

const AllCreditsDrawer = ({ open, onClose, credits }: CustomDrawerProps) => {
    const tableRef = useRef<any>(null);
    const [searchValue, setSearchValue] = useState("");
    const searchRef = useRef<string>("");
    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        setSearchValue(text);
    }, 500);

    const columns: IColumnsProps = [
        {
            title: Messages.creditVoucher,
            dataIndex: "credit",
            render: (credit: any) => <div>{credit?.creditNo}</div>,
        },
        {
            title: Messages.amount,
            dataIndex: "amount",
            align: "right",
            render: (data: any) => (
                <div className="text-green-500 text-right">
                    {` - `}
                    <CurrencyFormat
                        value={data}
                        thousandSeparator
                        displayType="text"
                        suffix=" THB"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </div>
            ),
        },
    ];

    const dataSource = useMemo(() => {
        if (searchValue) {
            return credits.filter((item: any) =>
                item.credit?.creditNo
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            );
        }
        return credits;
    }, [credits, searchValue]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.creditVoucher}
            hideFooter
            size="auto"
            width={500}
        >
            <div className="px-4 py-0">
                <HeaderTable
                    onChangeText={(event: any) =>
                        onChangeSearch(event.target.value)
                    }
                    className="border-l-1 border-r-1 mb-3"
                />
                <AwesomeTableComponent
                    ref={(ref) => {
                        tableRef.current = ref;
                    }}
                    dataSource={dataSource}
                    columns={columns}
                    defaultPagination={{
                        pageSize: SIZE_OPTIONS[1],
                        pageIndex: 1,
                        showQuickJumper: false,
                        pageSizeOptions: SIZE_OPTIONS,
                    }}
                />
            </div>
        </Drawer>
    );
};

export default AllCreditsDrawer;
