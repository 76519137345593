import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import ContactForm from "../share/ContactForm";

const CustomerCreateContact = () => {
    const { customerForm } = useContext(CustomerCreateContext);

    return (
        <div className="card-container px-3">
            <ContactForm form={customerForm} />
        </div>
    );
};

export default CustomerCreateContact;
