/* eslint-disable no-case-declarations */
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    IDrawerProps,
    InputText,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useRef, useState } from "react";
import {
    useGet2C2PMethodSettingListLazyQuery,
    useUpdate2C2PMethodSettingMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import LabelLanguage from "../../../common/label/LabelLanguage";
import TableAction from "../../../common/TableActions";
import { PAYMENT_METHOD_STATUSES } from "../../../constant/payment";
import { PaymentMethodDetailContext } from "../../../context/payment-method";
import {
    map2C2PMethodFromServer,
    map2C2PMethodToServer,
} from "../../../interfaces/payment-method";
import Messages from "../../../languages/Messages";

interface IDrawer2C2PMethodCrud {
    method?: any;
    open: boolean;
    onClose: () => void;
    onSave: (values: any) => void;
    inputType?: "normal" | "richText";
    drawerProps?: Partial<IDrawerProps>;
}

const PaymentMethod2C2PMethod = () => {
    const { paymentMethod, loadPaymentMethodDetail } = useContext(
        PaymentMethodDetailContext
    );
    const tableRef = useRef<any>(null);
    const [showModal, setShowModal] = useState<{ open: boolean; data?: any }>({
        open: false,
    });
    const [get2C2PMethodSettingList] = useGet2C2PMethodSettingListLazyQuery();
    const [update2C2PMethod] = useUpdate2C2PMethodSettingMutation();

    const onUpdateHandler = (id: string, payload: any) => {
        return Progress.show(
            {
                method: update2C2PMethod,
                params: {
                    variables: {
                        id,
                        payload,
                    },
                },
            },
            (res) => {
                refreshTable();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };
    const onCreateHandler = (payload: any) => {
        return Progress.show(
            {
                method: update2C2PMethod,
                params: {
                    variables: {
                        payload,
                    },
                },
            },
            (res) => {
                refreshTable();
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const columns: IColumnsProps = [
        {
            title: Messages.priority,
            dataIndex: "priority",
        },
        {
            title: Messages.method,
            dataIndex: "title",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={PAYMENT_METHOD_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "id",
            align: "center",
            width: 30,
            render: (data: any, item) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, item)
                        }
                        dataSource={[{ id: "update", label: "update" }]}
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                const mappedData = map2C2PMethodFromServer(item);
                setShowModal({ open: true, data: mappedData });
        }
    };

    const source = (paging: any) => {
        return get2C2PMethodSettingList({
            variables: {
                paginate: {
                    page: paging.pageIndex,
                    limit: paging.pageSize,
                },
            },
        });
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current?.refresh?.();
    };

    return (
        <div className="my-3">
            <div className="flex items-center mb-3">
                <h5 className="text-[16px] flex-1">{Messages.method2C2P}</h5>
                {/* <Button
                    size="small"
                    iconName="add"
                    classNameIcon="mx- 0 mr-1"
                    onClick={() => setShowModal({ open: true })}
                >
                    {Messages.add}
                </Button> */}
            </div>

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
            {showModal?.open && (
                <Drawer2C2PMethodCrud
                    open={showModal?.open}
                    onClose={() => setShowModal({ open: false })}
                    onSave={(input) => {
                        if (showModal?.data?.id) {
                            onUpdateHandler(showModal?.data?.id, input);
                        } else {
                            onCreateHandler(input);
                        }
                        setShowModal({ open: false });
                    }}
                    method={showModal?.data}
                />
            )}
        </div>
    );
};

const Drawer2C2PMethodCrud = ({
    method,
    open,
    onClose,
    onSave,
    inputType = "normal",
    drawerProps = {},
}: IDrawer2C2PMethodCrud) => {
    const methodForm = useFormik<any>({
        initialValues: method ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: NoteSchema,
        onSubmit: (values: any) => {
            const input = map2C2PMethodToServer(values);
            onSave && onSave(input);
        },
    });
    const classNameInput = "col-12 mt-3";

    const {
        values: formValues,
        errors: formErrors,
        handleSubmit,
        setFieldValue,
        handleChange,
    } = methodForm;
    const [language, setLanguage] = useState("en");

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.method2C2P}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <div className={classNameInput}>
                    <LabelLanguage
                        label={Messages.title}
                        language={language}
                        onChange={setLanguage}
                    />
                    <InputText
                        value={
                            formValues?.[language as keyof typeof formValues]
                                ?.title ?? ""
                        }
                        onChange={(event) =>
                            setFieldValue(
                                `${language}.title`,
                                event?.target?.value
                            )
                        }
                        //@ts-ignore
                        error={formErrors?.en?.title || formErrors?.th?.title}
                    />
                </div>
                <InputSelectForm
                    dataSource={PAYMENT_METHOD_STATUSES}
                    form={methodForm}
                    keyData="status"
                    className={classNameInput}
                />
                <div className={classNameInput}>
                    <LabelLanguage
                        label={Messages.description}
                        language={language}
                        onChange={setLanguage}
                    />
                    <InputText
                        multiple
                        value={
                            formValues?.[language as keyof typeof formValues]
                                ?.description ?? ""
                        }
                        onChange={(event) =>
                            setFieldValue(
                                `${language}.description`,
                                event?.target?.value
                            )
                        }
                        error={
                            //@ts-ignore
                            formErrors?.en?.description ||
                            //@ts-ignore
                            formErrors?.th?.description
                        }
                    />
                </div>
                <InputTextForm
                    form={methodForm}
                    keyData="priority"
                    className={classNameInput}
                />
            </div>
        </Drawer>
    );
};

export default PaymentMethod2C2PMethod;
