import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_AGENCY_BASIC } from "./agency";
import { F_PAGINATE } from "./app";
import { F_PRODUCT_BASIC } from "./product";

export const F_QUOTATION = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_AGENCY_BASIC}
    fragment F_QUOTATION on AdminQuotation {
        id
        quotationNo
        status
        purchaseType
        remark
        subtotal
        total
        createdAt
        updatedAt
        hasOrder
        expired
        priceSetType
        sourceOfCustomer
        customer {
            avatar
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            id
            nickname
            phone
            email
            gender
        }
        contact {
            firstName
            lastName
            email
            phone
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        salesPerson {
            ...F_ADMIN_USER_BASIC
        }
        store {
            id
            name
        }
        fee {
            name
            total
        }
        agency {
            ...F_AGENCY_BASIC
        }
        manualDiscount {
            discountType
            discountValue
            total
            product {
                id
                name
                sku
            }
        }
    }
`;

export const F_QUOTATIONS = gql`
    ${F_QUOTATION}
    ${F_PAGINATE}
    fragment F_QUOTATIONS on ListAdminQuotationResponse {
        data {
            ...F_QUOTATION
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_QUOTATION_PRODUCT = gql`
    ${F_PRODUCT_BASIC}
    fragment F_QUOTATION_PRODUCT on AdminQuotationProduct {
        quantity
        regularPrice
        hasOpeningPrice
        salePrice
        product {
            ...F_PRODUCT_BASIC
        }
        service {
            id
            name
        }
        premiumService {
            spending
            premiumServiceType
            product {
                ...F_PRODUCT_BASIC
            }
        }
        groups {
            quantity
            salePrice
            isDynamicPrice
            product {
                ...F_PRODUCT_BASIC
            }
        }
    }
`;

export const F_QUOTATION_MANUAL_PRODUCT = gql`
    fragment F_QUOTATION_MANUAL_PRODUCT on AdminQuotationManualProduct {
        quantity
        salePrice
        name
        remark
        sku
    }
`;
