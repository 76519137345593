import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Icon,
    InputTextSearch,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import React, { useContext, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty, isUndefined } from "lodash";
import CurrencyFormat from "react-currency-format";
import Messages from "../../../languages/Messages";
import Drawer, { CustomDrawerProps } from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadButton from "../../../common/upload/UploadButton";
import {
    useApprovalMaterialPurchaseQuotationMutation,
    useCancelMaterialPurchaseQuotationMutation,
    useCreateMaterialPurchaseQuotationMutation,
    useListMaterialPurchaseQuotationLazyQuery,
} from "../../../api/hooks";
import { MaterialRequestContext } from "../../../context/material";
import { MATERIAL_QUOTATION_STATUSES } from "../../../constant/material";

const MaterialRequestDetailQuotation = () => {
    const tableRef = useRef<any>(null);
    const [searchValue, setSearchValue] = useState("");
    const { request } = useContext(MaterialRequestContext);
    const [showQuotationModal, setShowQuotationModal] = useState(false);
    const [showQuotationDetailModal, setShowQuotationDetailModal] =
        useState(false);
    const [showApproveDrawer, setShowApproveDrawer] = useState(false);
    const [showCancelDrawer, setShowCancelDrawer] = useState(false);
    const [selectedQuotation, setSelectedQuotation] = useState<any>();

    const handleAddQuotation = () => {
        setShowQuotationModal(true);
    };
    const [listMaterialPurchaseQuotation] =
        useListMaterialPurchaseQuotationLazyQuery();

    const source = (pagingData: any) => {
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            search: searchValue ?? "",
        } as any;
        return listMaterialPurchaseQuotation({
            variables: {
                paginate: input,
                materialPurchaseRequestId: request?.id as string,
            },
            fetchPolicy: "no-cache",
        });
    };

    const reloadTable = () => {
        tableRef.current.refresh();
    };

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "materialPurchaseQuotationNo",
            render: (name: string, item: any) => (
                <span
                    className="text-primary cursor-pointer"
                    onClick={() => {
                        setSelectedQuotation(item);
                        setShowQuotationDetailModal(true);
                    }}
                >
                    {name}
                </span>
            ),
        },
        {
            title: Messages.vendorName,
            dataIndex: "name",
        },
        {
            title: Messages.approvalStatus,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_QUOTATION_STATUSES}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.totalPrice,
            dataIndex: "totalPrice",
            render: (value) => (
                <CurrencyFormat
                    value={value}
                    thousandSeparator
                    displayType="text"
                    prefix="THB "
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.quotation,
            dataIndex: "file",
            render: (file) =>
                file ? (
                    <a href={file} rel="noreferrer" target="_blank">
                        <Icon name="download" className="text-primary" />
                    </a>
                ) : (
                    ""
                ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data, item) => {
                return (
                    <>
                        <a
                            className={
                                request?.hasQuotationApproved
                                    ? "opacity-50 cursor-default mr-2"
                                    : "mr-2"
                            }
                            onClick={() => {
                                if (!request?.hasQuotationApproved) {
                                    setShowApproveDrawer(true);
                                    setSelectedQuotation(item);
                                }
                            }}
                        >
                            <Icon
                                name="check_circle"
                                className="text-primary"
                            />
                        </a>
                        <a
                            className={
                                request?.hasQuotationApproved
                                    ? "opacity-50 cursor-default"
                                    : ""
                            }
                            onClick={() => {
                                if (!request?.hasQuotationApproved) {
                                    setShowCancelDrawer(true);
                                    setSelectedQuotation(item);
                                }
                            }}
                        >
                            <Icon
                                name="remove_circle_outline"
                                className="text-primary"
                            />
                        </a>
                    </>
                );
            },
        },
    ];

    return (
        <div className="my-3">
            <div className="flex-row-between-center mb-2">
                <InputTextSearch
                    className="mr-2"
                    placeholder={Messages.search}
                    value={searchValue}
                    onChange={(e: any) => setSearchValue(e?.target?.value)}
                    onSubmit={() => reloadTable()}
                />
                <Button
                    onClick={handleAddQuotation}
                    disabled={request?.hasQuotationApproved}
                >
                    {Messages.add}
                </Button>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
            {showQuotationModal && (
                <NewQuotationModal
                    open={showQuotationModal}
                    onClose={() => setShowQuotationModal(false)}
                    onSave={() => {
                        setShowQuotationModal(false);
                        reloadTable();
                    }}
                    item={request}
                />
            )}
            {showQuotationDetailModal && (
                <NewQuotationModal
                    open={showQuotationDetailModal}
                    onClose={() => setShowQuotationDetailModal(false)}
                    onSave={() => {
                        setShowQuotationDetailModal(false);
                    }}
                    item={request}
                    quotation={selectedQuotation}
                />
            )}
            {showApproveDrawer && (
                <ApproveCancelQuotationDrawer
                    open={showApproveDrawer}
                    onClose={() => setShowApproveDrawer(false)}
                    type="approve"
                    onSave={() => reloadTable()}
                    item={selectedQuotation}
                />
            )}
            {showCancelDrawer && (
                <ApproveCancelQuotationDrawer
                    open={showCancelDrawer}
                    onClose={() => setShowCancelDrawer(false)}
                    type="cancel"
                    onSave={() => reloadTable()}
                    item={selectedQuotation}
                />
            )}
        </div>
    );
};

export const QuotationCreateSchema = Yup.object().shape({
    name: Yup.string().required("Required field!"),
    remark: Yup.string().required("Required field!"),
    taxId: Yup.string().required("Required field!"),
    address: Yup.string().required("Required field!"),
    phone: Yup.string().required("Required field!"),
    totalPrice: Yup.number().required("Required field!"),
    // file: Yup.mixed().required("Required field!"),
});

const NewQuotationModal = ({
    open,
    onClose,
    onSave,
    item,
    quotation = {},
}: CustomDrawerProps) => {
    const [createMaterialPurchaseQuotation] =
        useCreateMaterialPurchaseQuotationMutation();
    const isView = !isEmpty(quotation);

    const quotationForm = useFormik<any>({
        initialValues: {
            ...quotation,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: QuotationCreateSchema,
        onSubmit: (values: any) => {
            const payload = {
                ...values,
                file: values?.file?.[0]?.fileUrl ?? "",
                totalPrice: Number(values.totalPrice),
            };
            Progress.show(
                {
                    method: createMaterialPurchaseQuotation,
                    params: [
                        {
                            variables: {
                                payload,
                                materialPurchaseRequestId: item.id,
                            },
                        },
                    ],
                },
                (res: any) => {
                    Notifications.showSuccess(Messages.createSuccess);
                    onSave && onSave();
                }
            );
        },
    });
    const classNameInput = "mb-3";
    const { handleSubmit } = quotationForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={
                isView
                    ? `${Messages.quotationId}: ${quotation.materialPurchaseQuotationNo}`
                    : Messages.addQuotation
            }
            onSave={() => handleSubmit()}
        >
            <div className="p-4">
                <InputTextForm
                    form={quotationForm}
                    keyData="name"
                    className={classNameInput}
                    label={Messages.vendorName}
                    disabled={isView}
                />
                <InputTextForm
                    form={quotationForm}
                    keyData="taxId"
                    className={classNameInput}
                    label={Messages.companyTaxId}
                    disabled={isView}
                />
                <InputTextForm
                    form={quotationForm}
                    keyData="address"
                    className={classNameInput}
                    label={Messages.companyAddress}
                    disabled={isView}
                />
                <InputTextForm
                    form={quotationForm}
                    keyData="phone"
                    className={classNameInput}
                    label={Messages.companyPhoneNo}
                    disabled={isView}
                />
                <InputTextForm
                    form={quotationForm}
                    keyData="totalPrice"
                    className={classNameInput}
                    label={Messages.totalPrice}
                    disabled={isView}
                />
                {!isView && (
                    <UploadButton
                        variant="button"
                        onChange={(file: any) => {
                            quotationForm.setFieldValue("file", file);
                        }}
                        maxFiles={1}
                        defaultFiles={quotationForm?.values?.file ?? []}
                        className="mb-3"
                    />
                )}
                {isView && quotation.file && (
                    <a href={quotation.file} className="mb-3 block">
                        <span className="text-red-500">
                            {Messages.quotationFile}
                        </span>
                    </a>
                )}
                <InputTextForm
                    form={quotationForm}
                    keyData="remark"
                    className={classNameInput}
                    label={Messages.remark}
                    multiple
                    disabled={isView}
                />
            </div>
        </Drawer>
    );
};

const ApproveCancelQuotationDrawer = ({
    open,
    onClose,
    item,
    type,
    onSave,
}: CustomDrawerProps) => {
    const [approveQuotation] = useApprovalMaterialPurchaseQuotationMutation();
    const [cancelQuotation] = useCancelMaterialPurchaseQuotationMutation();
    const { request, reloadRequest } = useContext(MaterialRequestContext);

    const approveForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: QuotationCreateSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method:
                        type === "approve" ? approveQuotation : cancelQuotation,
                    params: [
                        {
                            variables: {
                                materialPurchaseRequestId: request?.id,
                                id: item?.id,
                                remark: values?.remark ?? "",
                            },
                        },
                    ],
                },
                (res: any) => {
                    Notifications.showSuccess(
                        type === "approve"
                            ? Messages.approveSuccessfully
                            : Messages.cancelSuccess
                    );
                    onClose();
                    onSave && onSave();
                }
            );
        },
    });
    const classNameInput = "mb-3";
    const { handleSubmit } = approveForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={
                type === "approve"
                    ? Messages.approveQuotation
                    : Messages.cancelQuotation
            }
            onSave={() => handleSubmit()}
        >
            <div className="p-4">
                <InputTextForm
                    form={approveForm}
                    keyData="remark"
                    className={classNameInput}
                    label={Messages.remark}
                    multiple
                />
            </div>
        </Drawer>
    );
};

export default MaterialRequestDetailQuotation;
