import styled from "@emotion/styled";
import { Avatar, Icon } from "d-react-components";
import { useFormikContext } from "formik";
import { isUndefined } from "lodash";
import { Fragment, useContext, useState } from "react";
import { AppStateContext } from "../../../context/app";
// import NewCustomerDrawer from "./NewCustomerDrawer";

const HiddenContent = ({ children, hidden }: any) => {
    return hidden ? null : children;
};

const VendorInfo = ({ vendor }: { vendor: any }) => {
    const isGuest = isUndefined(vendor?.id);
    const { setOpenAppDrawer } = useContext(AppStateContext);
    const { setFieldValue } = useFormikContext<any>();
    const [expanded, setExpanded] = useState(false);
    const [editGuestModal, setEditGuestModal] = useState(false);
    const customerName = vendor.name;

    if (!vendor) {
        return <></>;
    }
    const firstNameCharacter = customerName ? customerName.charAt(0) : "";

    return (
        <Fragment>
            <CustomerInfoStyled className="flex bg-amber-100 p-3 my-2">
                <div className="flex-1 flex">
                    {vendor.picture ? (
                        <Avatar
                            className="w-8 h-8 object-cover"
                            src={vendor.picture}
                            size="small"
                            variant="square"
                            text={firstNameCharacter}
                        />
                    ) : (
                        <div className="w-8 h-8 flex bg-gray-400 justify-content-center items-center">
                            <span
                                className="text-white"
                                style={{ fontSize: "1.5rem" }}
                            >
                                {firstNameCharacter}
                            </span>
                        </div>
                    )}
                    <div className="customer-info ml-2 text-sm">
                        <span className="block flex items-center">
                            <span className="">{customerName}</span>
                        </span>
                        <span className="block">ID: {vendor.vendorNo}</span>
                        <HiddenContent hidden={!expanded}>
                            <>
                                {/* <div
                                    className="block"
                                    hidden={!customer.email}
                                >{`${Messages.email}: ${customer.email}`}</div>
                                <div
                                    className="block"
                                    hidden={!customer.birthDay}
                                >{`${
                                    Messages.dateOfBirth
                                }: ${TimeUtils.convertMiliToDate(
                                    customer.birthDay
                                )}`}</div>
                                {!isGuest && (
                                    <ViewLabelStatus
                                        className="mt-2"
                                        status={
                                            customer?.status ??
                                            CustomerStatus.UN_VERIFIED
                                        }
                                        listStatus={CUSTOMER_STATUS}
                                        getLabel={(item) =>
                                            Messages[item.label]
                                        }
                                    />
                                )} */}
                            </>
                        </HiddenContent>
                    </div>
                </div>
                <Icon
                    size="large"
                    className="cursor-pointer pt-2 text-red-500 mr-2"
                    onClick={() => setFieldValue("vendor", [])}
                    name="delete"
                />
                <Icon
                    size="large"
                    className="cursor-pointer pt-2"
                    onClick={() => setExpanded(!expanded)}
                    name={expanded ? "expand_less" : "expand_more"}
                />
            </CustomerInfoStyled>
        </Fragment>
    );
};

export default VendorInfo;

const CustomerInfoStyled = styled.div`
    .d-view-label-status {
        border-radius: 0 !important;
    }
`;
