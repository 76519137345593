import { Button, Dropdown, Icon, Notifications } from "d-react-components";
import { useFormikContext } from "formik";
import { filter, forEach, isEmpty, map, orderBy, sum, sumBy } from "lodash";
import React, { useContext, useState } from "react";
import HoverableButton from "../../../common/HoverableButton";
import { MaterialPurchaseContext } from "../../../context/material";
import { OrderType } from "../../../interfaces/order";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import BillingDrawer from "../share/BillingDrawer";
import ContactDrawer from "../share/ContactDrawer";
import DiscountDrawer from "../share/DiscountDrawer";
import RemarkDrawer from "../share/RemarkDrawer";
import ShippingDrawer from "../share/ShippingDrawer";
import { DiscountType } from "../../../api/hooks";
// import AddCustomItemDrawer from "../share/AddCustomItemDrawer";

const MaterialProductActions = React.memo(
    ({ applyCoupon, submitOrder, type = OrderType.QUOTATION }: any) => {
        const {
            productList,
            setProductList,
            productCustomList,
            setProductCustomList,
            shipping,
            billing,
            cartValue,
            setManualDiscount,
        } = useContext(MaterialPurchaseContext);
        const { values, errors, handleSubmit, setFieldValue, setValues } =
            useFormikContext<any>();
        const [openDiscountModal, setOpenDiscountModal] = useState(false);
        const [openShippingModal, setOpenShippingModal] = useState(false);
        const [openBillingModal, setOpenBillingModal] = useState(false);
        const [openOrderNote, setOpenOrderNote] = useState(false);
        const [openAddRepresentative, setOpenAddRepresentative] =
            useState(false);
        const { store, remark, customer, contact } = values;

        const handleOnClickAddDiscount = () => {
            // if (isEmpty(store)) {
            //     Notifications.showError(
            //         Messages.pleaseSelectProductChannelFirst
            //     );
            //     return;
            // }
            if (productList?.length === 0 && productCustomList.length === 0) {
                Notifications.showError(Messages.pleaseAddProductToCartFirst);
                return;
            }
            setOpenDiscountModal(true);
        };

        const onSaveRemark = (remark: string) => {
            setFieldValue("remark", remark);
            setOpenOrderNote(false);
        };

        // const addProductsToOrder = (products: any) => {
        //     const productOrderIds = map(
        //         productList,
        //         (item) => item?.productOrderId
        //     );
        //     const listToAdd = filter(products, (item) => {
        //         return !productOrderIds.includes(
        //             getProductOrderId(item?.product, item?.doctorOrder)
        //         );
        //     });
        //     //change later
        //     setProductList([
        //         ...productList,
        //         ...map(listToAdd, (product: any) => ({
        //             ...product.product,
        //             quantity: product.quantity,
        //             doctorOrder: product?.doctorOrder,
        //             treatment: product?.treatment,
        //             productOrderId: getProductOrderId(
        //                 product?.product,
        //                 product?.doctorOrder
        //             ),
        //         })),
        //     ]);
        // };

        const onClickMoreActionItem = (item: any) => {
            switch (item?.id) {
                case "addRepresentative":
                    setOpenAddRepresentative(true);
                    break;
                default:
                    break;
            }
        };

        const calculateDiscount = (value: any) => {
            const listDiscount = orderBy(value, "isWholeCart", "desc");
            forEach(listDiscount, (item, index) => {
                const {
                    discountType,
                    discountValue,
                    product,
                    doctorOrder,
                    // discountValue: total,
                } = item || {};
                let total = 0;
                if (product) {
                    if (discountType === DiscountType.Percentage) {
                        total =
                            ((product.price || 0) *
                                (product.quantity || 0) *
                                discountValue) /
                            100;
                    } else {
                        total = discountValue;
                    }
                } else {
                    const allSkuDiscountValue = sumBy(
                        filter(listDiscount, (item) => !item.isWholeCart),
                        "total"
                    );
                    if (discountType === DiscountType.Percentage) {
                        total =
                            (((cartValue?.subtotal ?? 0) -
                                allSkuDiscountValue) *
                                discountValue) /
                            100;
                    } else {
                        total = discountValue;
                    }
                }
                listDiscount[index].total = total;
            });
            setManualDiscount && setManualDiscount(listDiscount);
        };

        return (
            <>
                <div className="">
                    <div className="flex items-center">
                        <HoverableButton
                            onClick={(item) => {
                                setOpenShippingModal(true);
                            }}
                            className="text-white inline-block px-2"
                            icon={<Icon name="local_shipping" />}
                            content={Messages.shippingAddress}
                            hasData={!isEmpty(shipping)}
                            dataNumber={shipping?.length}
                        />
                        <HoverableButton
                            className="text-white inline-block px-2"
                            icon={<img alt="" src="/icons/bill.svg" />}
                            content={Messages.billingAddress}
                            onClick={(item) => {
                                setOpenBillingModal(true);
                            }}
                            hasData={!isEmpty(billing)}
                        />
                        <HoverableButton
                            onClick={() => handleOnClickAddDiscount()}
                            className="text-white inline-block px-2"
                            icon={<img alt="" src="/icons/discount.svg" />}
                            content={Messages.discount}
                            // hasData={!isEmpty(cartValue?.voucher)}
                        />
                        {/* <HoverableButton
                            className="text-white inline-block px-2"
                            icon={<Icon name="settings" />}
                            content={Messages.customProduct}
                            onClick={(item) => setOpenAddCustomItem(true)}
                        /> */}
                        <HoverableButton
                            className="text-white inline-block px-2"
                            icon={<img alt="" src="/icons/notes.svg" />}
                            content={Messages.remark}
                            onClick={() => setOpenOrderNote(true)}
                            hasData={!isEmpty(remark)}
                        />
                        <Dropdown
                            dataSource={[
                                {
                                    id: "addRepresentative",
                                    label: Messages.addRepresentative,
                                },
                            ]}
                            onClick={onClickMoreActionItem}
                        >
                            <HoverableButton
                                className="text-white inline-block px-2"
                                icon={
                                    <img alt="" src="/icons/more-action.svg" />
                                }
                                content={Messages.moreActions}
                            />
                        </Dropdown>
                    </div>
                    <Button
                        color="primary"
                        className="w-full mt-2"
                        style={{ width: "100%" }}
                        onClick={() => submitOrder()}
                    >
                        {Messages.placeOrder}
                    </Button>
                </div>
                {openDiscountModal && (
                    <DiscountDrawer
                        open={openDiscountModal}
                        onClose={() => setOpenDiscountModal(false)}
                        onSave={(value: any) => calculateDiscount(value)}
                    />
                )}
                {/* {openAddCustomItem && (
                    <AddCustomItemDrawer
                        open={openAddCustomItem}
                        onClose={() => setOpenAddCustomItem(false)}
                        // onSave={(item: any) => onSaveCustomItem(item)}
                    />
                )} */}
                {openOrderNote && (
                    <RemarkDrawer
                        open={openOrderNote}
                        onClose={() => setOpenOrderNote(false)}
                        onSave={onSaveRemark}
                        remark={remark}
                    />
                )}
                {openShippingModal && (
                    <ShippingDrawer
                        open={openShippingModal}
                        onClose={() => setOpenShippingModal(false)}
                        onSave={() => setOpenShippingModal(false)}
                    />
                )}
                {openBillingModal && (
                    <BillingDrawer
                        open={openBillingModal}
                        onClose={() => setOpenBillingModal(false)}
                    />
                )}
                {openAddRepresentative && (
                    <ContactDrawer
                        open={openAddRepresentative}
                        onClose={() => setOpenAddRepresentative(false)}
                        onSave={(value: any) => {
                            setFieldValue("contact", value);
                            setOpenAddRepresentative(false);
                        }}
                        contact={values?.contact}
                    />
                )}
            </>
        );
    }
);

export default MaterialProductActions;
