import {
    AwesomeTableComponent,
    Button,
    IDrawerProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { map, pick } from "lodash";
import React, { Fragment, useContext, useMemo, useState } from "react";
import StockTransferAPI from "../../../../api/queries/stock-transfer";
import { Drawer } from "../../../../common/Drawer";
import InputTextForm from "../../../../common/input/InputTextForm";
import { PRODUCT_TYPE } from "../../../../constant/product";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import { IDeliveryDriver } from "../../../../interfaces/delivery-driver";
import Messages from "../../../../languages/Messages";
import ProductSearchButton from "../../../product/share/ProductSearchButton";
import ProductSelect from "../../../product/share/ProductSelect";
import ProductName from "../../../quotation/share/ProductName";
import QuantityInputField from "../../../quotation/share/QuantityInputField";

export interface IStockTransferReceiveButtonProps {
    delivery: IDeliveryDriver;
}

export interface IStockTransferReceiveDrawerProps extends IDrawerProps {
    delivery: IDeliveryDriver;
    onSave?: (values?: any) => any;
}

const StockTransferReceiveButton: React.FC<
    IStockTransferReceiveButtonProps
> = ({ delivery }) => {
    const { stockTransfer, loadTransferDetail } = useContext(
        StockTransferDetailContext
    );

    const [openReceiveDrawer, setOpenReceiveDrawer] = useState(false);

    return (
        <Fragment>
            <Button
                variant="outline"
                onClick={() => {
                    setOpenReceiveDrawer(true);
                }}
            >
                {Messages.receiveStock}
            </Button>
            {openReceiveDrawer && (
                <StockTransferReceiveDrawer
                    open={openReceiveDrawer}
                    onClose={() => {
                        setOpenReceiveDrawer(false);
                    }}
                    delivery={delivery}
                    onSave={() => {
                        loadTransferDetail();
                    }}
                />
            )}
        </Fragment>
    );
};

export default StockTransferReceiveButton;

const StockTransferReceiveDrawer: React.FC<
    IStockTransferReceiveDrawerProps
> = ({ open, onClose, onSave, delivery, ...rest }) => {
    const { stockOffList = [], stocks = [] } = delivery;

    const receiveForm = useFormik<any>({
        initialValues: delivery
            ? {
                  stocks:
                      stocks?.length > 0
                          ? map(stocks, (item) => ({
                                ...item,
                                receiveQty: item?.staffReceived ?? 0,
                            }))
                          : [],
                  stockOffList:
                      stockOffList?.length > 0
                          ? map(stockOffList, (item) => ({
                                ...item,
                                receiveQty: item?.staffReceived ?? 0,
                            }))
                          : [],
                  remark: null,
              }
            : ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: DeliveryFormSchema,
        onSubmit: (values: any) => {
            // onSave && onSave(values);
            onSubmitHandler();
        },
    });
    const { values, errors, setFieldValue, handleSubmit } = receiveForm;

    const columns = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product: any, item: any) => {
                return <ProductName item={product} />;
            },
            width: 500,
        },
        {
            title: Messages.quantity,
            dataIndex: "receiveQty",
            width: 200,
            render: (receiveQty: any, item: any) => {
                const quantityView = (
                    <QuantityInputField
                        quantity={receiveQty}
                        onChangeQuantity={(quantity: any) => {
                            setFieldValue(
                                "stocks",
                                map(values?.stocks ?? [], (i) =>
                                    i?.product?.id === item?.product?.id
                                        ? { ...i, receiveQty: quantity }
                                        : i
                                )
                            );
                        }}
                    />
                );
                return quantityView;
            },
        },
    ];

    const columnsOffList = [
        {
            title: Messages.item,
            dataIndex: "product",
            render: (product: any, item: any) => {
                return <ProductName item={product} />;
            },
            width: 500,
        },
        {
            title: Messages.quantity,
            dataIndex: "receiveQty",
            width: 200,
            render: (receiveQty: any, item: any) => {
                const quantityView = (
                    <QuantityInputField
                        quantity={receiveQty}
                        onChangeQuantity={(quantity: any) => {
                            setFieldValue(
                                "stockOffList",
                                map(values?.stockOffList ?? [], (i) =>
                                    i?.product?.id === item?.product?.id
                                        ? { ...i, receiveQty: quantity }
                                        : i
                                )
                            );
                        }}
                    />
                );
                return quantityView;
            },
        },
    ];

    const onSubmitHandler = () => {
        const input = {
            deliveryId: delivery?.id,
            payload: {
                stocks: map(values?.stocks, (item) => ({
                    product: item?.product?.id,
                    staffReceived: item?.receiveQty ?? 0,
                })),
                stockOffList: map(values?.stockOffList, (item) => ({
                    product: item?.product?.id,
                    staffReceived: item?.receiveQty ?? 0,
                })),
            },
        };

        return Progress.show(
            {
                method: StockTransferAPI.destinationReceiveProduct,
                params: [input],
            },
            (res) => {
                Notifications.showSuccess(Messages.updateSuccess);
                onClose && onClose(res as any);
                onSave && onSave(res);
            }
        );
    };

    const renderStockList = () => {
        return (
            <AwesomeTableComponent
                className="height-auto"
                baseColumnProps={{ width: 150, align: "center" }}
                dataSource={values?.stocks ?? []}
                columns={columns}
                isScroll={false}
                isPagination={false}
                classNameTable="tableItemDelivery"
                rowClassName={(product) => {
                    let rowClassName = "rowAwesomeTable ";
                    if (product.isGift) {
                        rowClassName += "productGiftTableItemRow";
                    }
                    return rowClassName;
                }}
            />
        );
    };

    const renderStockOffList = () => {
        return (
            <div>
                <div className="flex-center-y mt-3">
                    <div>{Messages.receiveExtraStock}</div>
                    <ProductSearchButton
                        onSave={(values) => {
                            const mappedValue = map(values, (item) => {
                                return {
                                    product: item,
                                    quantity: item?.quantity ?? 0,
                                    receiveQty: item?.receiveQty ?? 0,
                                };
                            });
                            setFieldValue("stockOffList", mappedValue);
                        }}
                        defaultValue={
                            map(values?.stockOffList ?? [], (i) => ({
                                ...(i?.product ?? {}),
                                ...i,
                            })) ?? []
                        }
                        filterSource={{
                            productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                        }}
                        buttonProps={{
                            variant: "trans",
                            className: "px-0 ml-2",
                            iconName: "",
                        }}
                    >
                        {Messages.clickHere}
                    </ProductSearchButton>
                </div>
                <AwesomeTableComponent
                    className="height-auto mt-3"
                    baseColumnProps={{ width: 150, align: "center" }}
                    dataSource={values?.stockOffList ?? []}
                    columns={columnsOffList}
                    isScroll={false}
                    isPagination={false}
                    classNameTable="tableItemDelivery"
                    rowClassName={(product) => {
                        let rowClassName = "rowAwesomeTable ";
                        if (product.isGift) {
                            rowClassName += "productGiftTableItemRow";
                        }
                        return rowClassName;
                    }}
                />
            </div>
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            width="650px"
            title={Messages.receiveStock}
            {...rest}
            onSave={handleSubmit}
        >
            <div className="p-4">
                {renderStockList()}
                {renderStockOffList()}
                <InputTextForm
                    multiple
                    form={receiveForm}
                    className="mt-3"
                    keyData="remark"
                />
            </div>
        </Drawer>
    );
};
