import { Avatar, TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import { getCustomerFullNameByLanguage } from "../../../utils/Utils";
import Path from "../../Path";

const OrderCustomer = () => {
    const { order } = useContext(OrderDetailContext);
    const { customer } = (order as IOrder) ?? {};
    if (!customer) {
        return <></>;
    }
    return (
        <div className="border p-3 mb-3 mb-3">
            <h5 className="font-semibold mb-2">{Messages.customer}</h5>
            <Avatar
                src={customer.avatar}
                className="w-16 h-16 rounded-full my-2"
                text={customer.firstNameTh}
            />
            <span className="block mb-1">
                {Messages.id}: {customer.customerNo}
            </span>
            <span className="block mb-1">
                <AppLink
                    to={generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: customer.id,
                    })}
                    target="_blank"
                >
                    {getCustomerFullNameByLanguage(customer)}
                </AppLink>
            </span>
            {customer.birthDay && (
                <span className="block mb-1">
                    {Messages.dob}:{" "}
                    {TimeUtils.convertMiliToDate(customer.birthDay)}
                </span>
            )}
            <span className="block mb-1">{customer.email}</span>
            <span className="block mb-1">{customer.phone}</span>
        </div>
    );
};

export default OrderCustomer;
