import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    HeaderTable,
    IColumnsProps,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useListAdminRoleForAdminLazyQuery } from "../../../api/hooks";
// import UserAPI from "../../../api/user/UserAPI";
import AppLink from "../../../common/AppLink";
import PermissibleRender from "../../../common/PermissibleComponent";
import TableAction from "../../../common/TableActions";
import { PERMISSION } from "../../../constant/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

export const TABLE_ACTIONS = [{ id: "remove", label: "remove" }];

const UserRoleTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [getListRole] = useListAdminRoleForAdminLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.role,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.USER_ROLE_DETAIL, {
                        roleId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.preset,
            dataIndex: "isAdmin",
            render: (isAdmin, item) =>
                isAdmin ? Messages.admin : Messages.custom,
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.updatedAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            width: 30,
            render: (role: any) => {
                return (
                    <TableAction
                        dataSource={TABLE_ACTIONS}
                        cl
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, role)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return getListRole({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                },
            },
        });
    };

    const onClickTableAction = (actionId: string, role: any) => {
        switch (actionId) {
            default:
                onRemoveRole(role?.id);
        }
    };

    const onRemoveRole = (roleId: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemove,
            () =>
                Progress.show(
                    {
                        // method: UserAPI.removeRole,
                        method: () => Promise.resolve([]),
                        params: [roleId],
                    },
                    () => {
                        tableRef.current.refresh();
                    }
                )
        );
    };

    const onClickAddNew = () => {
        navigate(Path.USER_ROLE_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                label={Messages.allRoles}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                customButtons={() => (
                    <PermissibleRender
                        permission={PERMISSION.ADMIN.ROLE.CREATE}
                    >
                        <Button size="small" onClick={onClickAddNew}>
                            {Messages.new}
                        </Button>
                    </PermissibleRender>
                )}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.listAdminRoleForAdmin?.data ?? [];
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                isPagination={false}
            />
        </div>
    );
};

export default UserRoleTable;
