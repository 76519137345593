import classNames from "classnames";
import { DateInput } from "d-react-components";
import { map } from "lodash";
import moment from "moment";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { APPOINTMENT_STATUSES } from "../../../constant/appointment";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";

interface IAppointmentFilterForm {
    filterForm: any;
}

const AppointmentFilterForm = ({ filterForm }: IAppointmentFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <BranchSelect
                className={classNameInput}
                value={formValues?.branch}
                onChange={(value) => filterForm.setFieldValue("branch", value)}
                multiple
            />
            <InputSelectForm
                keyData="status"
                dataSource={APPOINTMENT_STATUSES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <DateInput
                className={classNameInput}
                isRangePicker
                label={Messages.estDateOfVisit}
                value={
                    map(formValues?.timeRange, (time) => moment(time)) as any
                }
                onChange={(value) =>
                    filterForm.setFieldValue("timeRange", value)
                }
            />
        </div>
    );
};

export default AppointmentFilterForm;
