import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { useContext } from "react";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { MaterialStockRequestDetailContext } from "../../../context/material";
import { IMaterialRequestAdjustment } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";

const MaterialAdjustRequestGeneral = ({ loading }: { loading?: boolean }) => {
    const { requestDetail } = useContext(MaterialStockRequestDetailContext);
    const GENERAL_KEYS: IRowsKey<IMaterialRequestAdjustment>[] = [
        {
            id: "adjustmentNo",
            label: "ID",
        },
        {
            id: "branch",
            label: Messages.branchWarehouse,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "branch",
            label: Messages.branchWarehouseCode,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "totalAdjustment",
            label: Messages.change,
            renderContent: ({ data, item }) =>
                `${item.totalStock} > ${item.totalAdjustment} (${
                    item.totalAdjustment - item.totalStock
                })`,
        },
        {
            id: "differentRate",
            label: Messages.avgDiffRate,
        },
        {
            id: "createByAdmin",
            label: Messages.createdBy,
            renderContent: ({ data }) => <UserAvatarName user={data} />,
        },

        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.toDateTime(data);
            },
        },
        {
            id: "remark",
            label: Messages.remark,
        },
    ];

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={requestDetail}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default MaterialAdjustRequestGeneral;
