import React, { useContext, useMemo } from "react";
import { find, isEmpty, join, map } from "lodash";
import { TimeUtils } from "d-react-components";
import styled from "@emotion/styled";
import Barcode from "react-barcode";
import { OrderDetailContext } from "../../../context/order";
import {
    AdminShipping,
    BillingDto,
    BranchDto,
    DistrictTranslationDto,
    PaymentMethod,
    ProvinceTranslationDto,
    SubDistrictTranslationDto,
    useDetailBranchForAdminQuery,
} from "../../../api/hooks";
import Messages from "../../../languages/Messages";
import QuotationPriceTable, {
    LayoutTable,
} from "../../quotation/share/QuotationPriceTable";

const getNameByLang = (
    lang: string,
    trans?: (
        | DistrictTranslationDto
        | ProvinceTranslationDto
        | SubDistrictTranslationDto
    )[]
) => {
    return trans?.find((transItem) => transItem.language === lang)?.name ?? "";
};

const InfoItemMultiLangs = ({
    title = "",
    content,
    containerClass = "",
}: {
    title: string;
    content?: string;
    containerClass?: string;
}) => {
    return (
        <div className={`${containerClass}`}>
            {title && (
                <div className="font-semibold">{`${Messages.getBothLangsText(
                    title,
                    true
                )}: `}</div>
            )}
            {!isEmpty(content) && <div className="ml-1">{content}</div>}
        </div>
    );
};

const CompanyInfo = ({ branchInfo }: { branchInfo: BranchDto }) => {
    const fullAddressTh = [
        branchInfo?.address?.address,
        getNameByLang("th", branchInfo?.address?.subDistrict?.translations),
        getNameByLang("th", branchInfo?.address?.district?.translations),
        getNameByLang("th", branchInfo?.address?.province?.translations),
        branchInfo?.address?.postcode,
    ];
    const fullAddressEn = [
        branchInfo?.address?.address,
        getNameByLang("en", branchInfo?.address?.subDistrict?.translations),
        getNameByLang("en", branchInfo?.address?.district?.translations),
        getNameByLang("en", branchInfo?.address?.province?.translations),
        branchInfo?.address?.postcode,
    ];
    return (
        <div className="basis-2/4 grow-0">
            <p className="mb-1 text-base">{fullAddressEn.join(", ")}</p>
            {/* <p className="mb-1 text-base">
                {Messages.getMessageByLang("tel", "th")}: {branchInfo?.phone}{" "}
                {Messages.getMessageByLang("taxIDNo", "th")}{" "}
                {branchInfo?.brandTaxId}
            </p>
            <p className="mb-1 text-base">{fullAddressTh.join(", ")}</p> */}
            <p className="mb-1 text-base">
                {Messages.getMessageByLang("tel", "en")}: {branchInfo?.phone}
            </p>
            <p className="mb-1 text-base">
                {Messages.getMessageByLang("taxIDNo", "en")}{" "}
                {branchInfo?.brandTaxId}
            </p>
        </div>
    );
};

const OrderReceipt = ({ branchInfo }: { branchInfo: BranchDto }) => {
    const { order, paymentList, paymentMethodsList } =
        useContext(OrderDetailContext);

    const getPaymentMethodsText = useMemo(() => {
        return join(
            map(paymentList, (paymentItem) => {
                const payment = find(paymentMethodsList, {
                    paymentConfigurationType: paymentItem.paymentMethod,
                });
                return paymentItem?.paymentNo === PaymentMethod.Default
                    ? Messages.default
                    : payment?.title ?? "";
            }),
            ", "
        );
    }, [order, paymentList, paymentMethodsList]);

    return (
        <div className="text-right basis-2/4 grow-0">
            <p className="mb-1 text-xl font-semibold">
                {Messages.getMessageByLang("receipt", "th")}/{" "}
                {Messages.getMessageByLang("taxInvoice", "th")}
            </p>
            <p className="mb-1 text-xl font-semibold">
                {Messages.getMessageByLang("receipt", "en")}/{" "}
                {Messages.getMessageByLang("taxInvoice", "en")}
            </p>
            <InfoItemMultiLangs
                containerClass="flex justify-end"
                title="order"
                content={order?.orderNo}
            />
            <InfoItemMultiLangs
                containerClass="flex justify-end"
                title="createdAt"
                content={TimeUtils.toDateTime(order?.createdAt)}
            />
            <InfoItemMultiLangs
                containerClass="flex justify-end"
                title="paymentMethod"
                content={getPaymentMethodsText}
            />
            <InfoItemMultiLangs
                containerClass="flex justify-end"
                title="storeCode"
                content={branchInfo?.code ?? ""}
            />
            <InfoItemMultiLangs
                containerClass="flex justify-end"
                title="staffCode"
                content={order?.salesPerson?.companyId ?? ""}
            />
        </div>
    );
};

const AddressInfo = ({ address }: { address: BillingDto | AdminShipping }) => {
    return (
        <div>
            <p className="mb-1 text-base">
                {address?.firstName} {address?.lastName} ({address?.phone})
            </p>
            <p className="mb-1 text-base">{address?.address}</p>
            <p className="mb-1 text-base">{address?.district?.name}</p>
            <p className="mb-1 text-base">{address?.province?.name}</p>
            {(address as AdminShipping)?.typeOfPlace && (
                <p className="mb-1 text-base">
                    {
                        Messages[
                            (address as AdminShipping)?.typeOfPlace as string
                        ]
                    }
                </p>
            )}
        </div>
    );
};

const OrderPrintLayout = React.forwardRef<HTMLDivElement, unknown>(
    (props, ref) => {
        const { order, shippingList } = useContext(OrderDetailContext);
        const {
            data: branchInfo,
            loading,
            refetch,
        } = useDetailBranchForAdminQuery({
            variables: {
                id: order?.store?.id as string,
            },
            skip: !order?.store?.id,
        });
        return (
            <StyledOrderPrintLayout>
                <div className="p-4" ref={ref}>
                    <div className="flex overflow-hidden">
                        <div className="flex-1">
                            <img
                                src="/images/logo-h.png"
                                alt="Logo"
                                className="w-[300px] m-[-20px] mb-2"
                            />
                        </div>
                        {order?.orderNo && (
                            <Barcode
                                value={order?.orderNo as string}
                                width={1.5}
                                height={55}
                                fontSize={10}
                            />
                        )}
                    </div>
                    <div className="flex">
                        <CompanyInfo
                            branchInfo={
                                branchInfo?.detailBranchForAdmin
                                    ?.data as BranchDto
                            }
                        />
                        <OrderReceipt
                            branchInfo={
                                branchInfo?.detailBranchForAdmin
                                    ?.data as BranchDto
                            }
                        />
                    </div>
                    <div className="flex mt-3 mb-3 border-t pt-3">
                        <div className="flex-1">
                            <p className="font-semibold mb-1 text-base system-font">
                                {Messages.getBothLangsText(
                                    "shippingInformation",
                                    true
                                )}
                            </p>
                            {shippingList?.map((shipping) => (
                                <AddressInfo
                                    key={shipping.id}
                                    address={shipping as any}
                                />
                            ))}
                        </div>
                        <div className="flex-1 justify-end text-right">
                            <p className="font-semibold mb-1 text-base">
                                {Messages.getBothLangsText(
                                    "billingInformation",
                                    true
                                )}
                            </p>
                            <AddressInfo address={order?.billing as any} />
                        </div>
                    </div>

                    <QuotationPriceTable
                        order={order as any}
                        layout={LayoutTable.PRINT}
                    />

                    <div className="mt-4">
                        <p className="mb-1 text-base font-semibold">
                            {Messages.getBothLangsText("termCondition", true)}
                        </p>
                        <p className="mb-1 text-base">
                            {Messages.getMessageByLang(
                                "patientsMayReturn",
                                "th"
                            )}
                        </p>
                        <p className="mb-1 text-base">
                            {Messages.getMessageByLang(
                                "patientsMayReturn",
                                "en"
                            )}
                        </p>
                    </div>
                </div>
            </StyledOrderPrintLayout>
        );
    }
);

const StyledOrderPrintLayout = styled.div`
    height: 0px;
    overflow: hidden;
    @media print {
        height: auto;
    }
`;

export default OrderPrintLayout;
