import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, join, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import TreatmentAPI from "../../../api/queries/treatment";

import AppLink from "../../../common/AppLink";

import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { TREATMENT_STATUSES } from "../../../constant/treatment";
import { mapTreatmentFilterToServer } from "../../../interfaces/treatment";

import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";
import Path from "../../Path";
import TreatmentTableFilterDrawer from "./TreatmentTableFilterDrawer";

const TreatmentTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "treatmentNo",
            render: (treatmentNo, item) => (
                <AppLink
                    to={generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: item.id,
                    })}
                >
                    {treatmentNo}
                </AppLink>
            ),
        },
        {
            title: Messages.doctorOrder,
            dataIndex: "doctorOrder",
            render: (doctorOrder) => (
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: doctorOrder.id,
                    })}
                    target="_blank"
                >
                    {doctorOrder?.doctorOrderNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.service,
            dataIndex: "services",
            render: (services) =>
                join(
                    map(services, (item) => item?.product?.name),
                    ", "
                ),
        },
        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor) => (
                <UserAvatarName
                    showInfo={{ showOPD: false }}
                    user={doctor?.adminUser}
                    size="xx-small"
                    variant="square"
                />
            ),
        },
        {
            title: Messages.doctorSlot,
            dataIndex: "doctor",
            render: (doctor, item) =>
                `${item.day} ${fmtMSS(doctor?.schedule?.start)}-${fmtMSS(
                    doctor?.schedule?.end
                )}`,
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer) => (
                <CustomerAvatarName size="xx-small" customer={customer} />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={TREATMENT_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = mapTreatmentFilterToServer(filter);
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            search: searchRef.current ?? "",
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return TreatmentAPI.list(input);
    };

    const onClickAddNew = () => {
        navigate(Path.TREATMENT_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                // onClickNew={onClickAddNew}
                label={Messages.allTreatment}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <TreatmentTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default TreatmentTable;
