import { map } from "lodash";
import { IBooking } from "./booking";
import { ICustomer } from "./customer";
import { IProduct } from "./product";
import { ITreatment } from "./treatment";
import IUser from "./user";

export interface IDoctorOrder {
    id: string;
    doctorOrderNo: string;
    updatedAt: Date;
    createdAt: Date;
    status: string;
    createByAdmin?: any;
    products: IProduct[];
    services: {
        quantity: number;
        product: IProduct[];
        complicationTreatment?: ITreatment;
    }[];
    treatments: ITreatment[];
    complicationTreatments: ITreatment[];
    customer: ICustomer;
    doctor: IUser;
    doctorSubject?: string;
    booking?: IBooking;
}

export const mapDoctorOrderFilterToQueryParams = (input: any) => {
    return {
        branch: map(input?.branch, (item) => ({
            id: item.id,
            name: item.name,
        })),
        status: input?.status,
        createdBy: map(input?.createdBy, (item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        })),
        doctor: map(input?.doctor, (item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        })),
        customer: map(input?.customer, (item) => ({
            id: item.id,
            nickname: item.nickname,
        })),
        services: map(input?.services, (item) => ({
            id: item.id,
            name: item.name,
        })),
    };
};

export const mapDoctorOrderFilterToServer = (input: any) => {
    return {
        branch: map(input?.branch, (item) => item.id),
        status: input?.status,
        createdBy: map(input?.createdBy, (item) => item.id),
        doctor: map(input?.doctor, (item) => item.id),
        customer: map(input?.customer, (item) => item.id),
        services: map(input?.services, (item) => item.id),
    };
};
