import {
    DialogManager,
    Notifications,
    Progress,
    TabBar,
    ViewLabelStatus,
} from "d-react-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    useDetailQuotationForAdminLazyQuery,
    useUpdateQuotationExpiredForAdminMutation,
} from "../../../api/hooks";
import ActionMenu, { ActionButton } from "../../../common/ActionButton";
import { CONFIG } from "../../../configuration/AppConfig";
import { QUOTATION_STATUSES } from "../../../constant/quotation";
import { QuotationDetailContext } from "../../../context/quotation";
import Messages from "../../../languages/Messages";
import ChangeExpiryDateDrawer from "../../order/detail/OrderChangeExpiryDateDrawer";
import QuotationActivity from "./QuotationActivity";
import QuotationAddress from "./QuotationAddress";
import QuotationContactInformation from "./QuotationContactInformation";
import QuotationGeneralInformation from "./QuotationGeneralInformation";
import QuotationPayment from "./QuotationPayment";
import QuotationPrice from "./QuotationPrice";
import QuotationSalePerson from "./QuotationSalePerson";
import QuotationSummaryView from "./QuotationSummaryView";
import QuotationAPI from "../../../api/queries/quotation";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <QuotationGeneralInformation />,
    },
    {
        id: "payment",
        label: Messages.payment,
        component: <QuotationPayment />,
    },
    {
        id: "contactInformation",
        label: Messages.contactInformation,
        component: <QuotationContactInformation />,
    },
    {
        id: "detail",
        label: Messages.detail,
        component: <QuotationPrice />,
    },
    {
        id: "address",
        label: Messages.address,
        component: <QuotationAddress />,
    },
    {
        id: "coSalePerson",
        label: Messages.coSalePerson,
        component: <QuotationSalePerson />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <QuotationActivity />,
    },
];

const QuotationDetail = () => {
    const { quotationId } = useParams<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [showChangeExpiry, setShowChangeExpiry] = useState(false);
    const navigate = useNavigate();
    const [getQuotation, { data, loading, refetch }] =
        useDetailQuotationForAdminLazyQuery();
    const [updateQuotationExpiry] = useUpdateQuotationExpiredForAdminMutation();

    useEffect(() => {
        loadQuotationDetail();
    }, []);

    const loadQuotationDetail = () => {
        Progress.show(
            {
                method: getQuotation,
                params: [
                    {
                        variables: {
                            id: quotationId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };
    const quotationDetail = data?.data?.data;

    const onClickAction = (item: any) => {
        if (item.id === "changeExpiryDate") {
            setShowChangeExpiry(true);
        }
        if (item.id === "cancelQuotation") {
            DialogManager.showConfirm(
                Messages.confirm,
                Messages.areYouSureWantCancel,
                () =>
                    Progress.show(
                        {
                            method: QuotationAPI.cancel,
                            params: [quotationId],
                        },
                        () => {
                            loadQuotationDetail();
                        }
                    )
            );
        }
    };

    const onCloseChangeExpiry = (date: any) => {
        updateQuotationExpiry({
            variables: {
                id: quotationDetail?.id as string,
                payload: {
                    expired: moment(date).toDate(),
                },
            },
        })
            .then(() => {
                setShowChangeExpiry(false);
                loadQuotationDetail();
                Notifications.showSuccess(Messages.updateOrderSuccessfully);
            })
            .catch((err: any) => {
                Notifications.showError(err);
            });
    };

    return (
        <QuotationDetailContext.Provider
            value={{
                quotation: quotationDetail as any,
                loadQuotationDetail: refetch,
            }}
        >
            <div className="px-4 py-3 bg-white flex border-b items-center">
                <div className="flex flex-1 align-items-center">
                    <h5 className="text-primary mr-3">
                        {Messages.quotation} #
                        {`${quotationDetail?.quotationNo}`}
                    </h5>
                    <ViewLabelStatus
                        status={quotationDetail?.status}
                        listStatus={QUOTATION_STATUSES}
                        getLabel={(item) => (Messages as any)[item.label]}
                    />
                </div>
                <div className="flex items-center">
                    <ActionButton
                        className="mr-2"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${CONFIG.CUSTOMER_PAGE_URL}/q/${quotationDetail?.id}`
                            );
                            Notifications.showInfo(Messages.copySuccess);
                        }}
                    >
                        {Messages.quotationUrl}
                    </ActionButton>
                    {!quotationDetail?.hasOrder && (
                        <ActionMenu
                            dataSource={[
                                {
                                    id: "changeExpiryDate",
                                    label: Messages.changeExpiryDate,
                                },
                                {
                                    id: "cancelQuotation",
                                    label: Messages.cancelQuotation,
                                },
                            ]}
                            onClick={onClickAction}
                        />
                    )}
                </div>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <QuotationSummaryView />
                    </div>
                    <div className="col-span-3">
                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
            </div>
            {showChangeExpiry && (
                <ChangeExpiryDateDrawer
                    open={showChangeExpiry}
                    onClose={() => setShowChangeExpiry(false)}
                    defaultDate={quotationDetail?.expired}
                    onSave={(date: any) => onCloseChangeExpiry(date)}
                />
            )}
        </QuotationDetailContext.Provider>
    );
};

export default QuotationDetail;
