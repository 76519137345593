import UploadButton from "../../../common/upload/UploadButton";
import { usePromotionCreateForm } from "../../../context/promotion";
import Messages from "../../../languages/Messages";

const PromotionCreateDocumentsForm = () => {
    const { values, errors, setFieldValue } = usePromotionCreateForm();

    return (
        <div className="p-4 border">
            <h5 className="mb-3">{Messages.documents}</h5>
            <UploadButton
                key={values?.documents?.length}
                variant="drag&drop"
                onChange={(file: any) => {
                    setFieldValue("documents", file);
                }}
                defaultFiles={values?.documents ?? []}
            />
        </div>
    );
};

export default PromotionCreateDocumentsForm;
