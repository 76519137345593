import { Route, Routes } from "react-router-dom";
import Path from "./Path";
import AgencyCreate from "./agency/create/AgencyCreate";
import AgencyDetail from "./agency/detail/AgencyDetail";
import AgencyTable from "./agency/table/AgencyTable";
import AppointmentDetail from "./appointment/detail/AppointmentDetail";
import AppointmentTable from "./appointment/table/AppointmentTable";
import BookingCreate from "./booking/create/BookingCreate";
import BookingDetail from "./booking/detail/BookingDetail";
import BookingTable from "./booking/table/BookingTable";
import BranchCreate from "./branch/crud/BranchCreate";
import BranchDetail from "./branch/detail/BranchDetail";
import BranchTable from "./branch/table/BranchTable";
import Calendar from "./calendar/Calendar";
import CustomerCreate from "./customer/create/CustomerCreate";
import CustomerDetail from "./customer/detail/CustomerDetail";
import CustomerTable from "./customer/table/CustomerTable";
import Dashboard from "./dashboard/Dashboard";
import DeliveryDriverDetail from "./delivery-driver/detail/DeliveryDriverDetail";
import DeliveryDriverTable from "./delivery-driver/table/DeliveryDriverTable";
import DoctorOrderCreate from "./doctor-order/crud/DoctorOrderCreate";
import DoctorOrderDetail from "./doctor-order/detail/DoctorOrderDetail";
import DoctorTable from "./doctor-order/table/DoctorOrderTable";
import EquipmentStockAdjustmentCreate from "./equipment-inventory/equipment-stock-adjustment/create/EquipmentStockAdjustmentCreate";
import EquipmentStockAdjustmentDetail from "./equipment-inventory/equipment-stock-adjustment/detail/EquipmentStockAdjustmentDetail";
import EquipmentStockAdjustmentTable from "./equipment-inventory/equipment-stock-adjustment/table/EquipmentStockAdjustmentTable";
import EquipmentStockRequestCreate from "./equipment-inventory/equipment-stock-request/create/EquipmentStockRequestCreate";
import EquipmentStockRequestDetail from "./equipment-inventory/equipment-stock-request/detail/EquipmentStockRequestDetail";
import EquipmentStockRequestTable from "./equipment-inventory/equipment-stock-request/table/EquipmentStockRequestTable";
import EquipmentCategoryCreate from "./equipment/crud/EquipmentCategoryCreate";
import EquipmentCreate from "./equipment/crud/EquipmentCreate";
import EquipmentDetail from "./equipment/detail/EquipmentDetail";
import EquipmentCategoryTable from "./equipment/table/EquipmentCategoryTable";
import EquipmentTable from "./equipment/table/EquipmentTable";
import InventoryHistoryTable from "./inventory/inventory-history/InventoryHistoryTable";
import StockAdjustmentCreate from "./inventory/stock-adjustment/create/StockAdjustmentCreate";
import StockAdjustmentDetail from "./inventory/stock-adjustment/detail/StockAdjustmentDetail";
import StockAdjustmentTable from "./inventory/stock-adjustment/table/StockAdjustmentTable";
import StockRequestCreate from "./inventory/stock-request/create/StockRequestCreate";
import StockRequestDetail from "./inventory/stock-request/detail/StockRequestDetail";
import StockRequestTable from "./inventory/stock-request/table/StockRequestTable";
import StockTransferCreate from "./inventory/stock-transfer/create/StockTransferCreate";
import StockTransferDetail from "./inventory/stock-transfer/detail/StockTransferDetail";
import StockTransferTable from "./inventory/stock-transfer/table/StockTransferTable";
import MaterialAdjustmentRequestCreate from "./material-adjustment-request/create/MaterialAdjustmentRequestCreate";
import MaterialAdjustRequestDetail from "./material-adjustment-request/detail/MaterialAdjustRequestDetail";
import MaterialAdjustmentRequestTable from "./material-adjustment-request/table/MaterialAdjustmentRequestTable";
import MaterialCategoryCreate from "./material-category/crud/MaterialCategoryCreate";
import MaterialCategory from "./material-category/table/MaterialCategory";
import MaterialInventoryTable from "./material-inventory/table/MaterialInventoryTable";
import MaterialPurchaseOrderCreate from "./material-purchase-order/crud/MaterialPurchaseOrderCreate";
import MaterialOrderDetail from "./material-purchase-order/detail/MaterialOrderDetail";
import MaterialPurchaseTable from "./material-purchase-order/table/MaterialPurchaseTable";
import MaterialRequestCreate from "./material-purchase-request/create/MaterialRequestCreate";
import MaterialRequestDetail from "./material-purchase-request/detail/MaterialRequestDetail";
import MaterialRequestTable from "./material-purchase-request/table/MaterialRequestTable";
import MaterialCreate from "./material/crud/MaterialCreate";
import MaterialDetail from "./material/detail/MaterialDetail";
import MaterialTable from "./material/table/MaterialTable";
import MediaManager from "./media/MediaManager";
import OrderCreate from "./order/crud/OrderCreate";
import OrderDetail from "./order/detail/OrderDetail";
import OrderTable from "./order/table/OrderTable";
import PaymentMethodDetail from "./payment-method/detail/PaymentMethodDetail";
import PaymentMethodTable from "./payment-method/table/PaymentMethodTable";
import ProductCategoryCrud from "./product-category/crud/ProductCategoryCrud";
import ProductCategoryTable from "./product-category/table/ProductCategoryTable";
import ProductCreate from "./product/create/ProductCreate";
import ProductDetail from "./product/detail/ProductDetail";
import ProductTable from "./product/table/ProductTable";
import QuotationCreate from "./quotation/crud/QuotationCreate";
import QuotationDetail from "./quotation/detail/QuotationDetail";
import QuotationTable from "./quotation/table/QuotationTable";
import ReportCashier from "./report/ReportCashier";
import ServiceCategoryCrud from "./service-category/curd/ServiceCategoryCrud";
import ServiceCategoryTable from "./service-category/table/ServiceCategoryTable";
import ServiceConfigurationCreate from "./service-config/create/ServiceConfigurationCreate";
import ServiceConfigurationDetail from "./service-config/detail/ServiceConfigurationDetail";
import ServiceConfigurationTable from "./service-config/table/ServiceConfigurationTable";
import TemporaryScheduleCreate from "./temporary-schedule/create/TemporaryScheduleCreate";
import TemporaryScheduleDetail from "./temporary-schedule/detail/TemporaryScheduleDetail";
import TemporaryScheduleTable from "./temporary-schedule/table/TemporaryScheduleTable";
import TransferVoucherCreate from "./transfer-voucher/create/TransferVoucherCreate";
import TransferVoucherDetail from "./transfer-voucher/detail/TransferVoucherDetail";
import TransferVoucherTable from "./transfer-voucher/table/TransferVoucherTable";
import TreatmentCreate from "./treatment/create/TreatmentCreate";
import TreatmentDetail from "./treatment/detail/TreatmentDetail";
import TreatmentTable from "./treatment/table/TreatmentTable";
import UserCrud from "./user/crud/UserCrud";
import UserDetail from "./user/detail/UserDetail";
import UserRoleCrud from "./user/role/UserRoleCrud";
import UserRoleTable from "./user/role/UserRoleTable";
import UserTable from "./user/table/UserTable";
import VendorCreate from "./vendor/create/VendorCreate";
import VendorDetail from "./vendor/detail/VendorDetail";
import VendorTable from "./vendor/table/VendorTable";
import VoucherCreate from "./voucher/create/VoucherCreate";
import VoucherDetail from "./voucher/detail/VoucherDetail";
import VoucherTable from "./voucher/table/VoucherTable";
import ReportSalespersonUser from "./report/ReportSalespersonUser";
import Import from "./import/Import";
import ImportCustomer from "./import/import-customer/ImportCustomer";
import ReportSalespersonMonthly from "./report/ReportSalespersonMonthly";
import ReportCustomerSource from "./report/ReportCustomerSource";
import ReportDoctorSummary from "./report/ReportDoctorSummary";
import ReportDoctorDetail from "./report/ReportDoctorDetail";
import PromotionTable from "./promotion/table/PromotionTable";
import PromotionCreate from "./promotion/create/PromotionCreate";
import PromotionDetail from "./promotion/detail/PromotionDetail";
import ReportCashierByPayment from "./report/ReportCashierByPayment";
import ReportCustomerVoucher from "./report/ReportCustomerVoucher";
import ReportTreatmentDailyUsage from "./report/ReportTreatmentDailyUsage";
import ReportNurseDetail from "./report/ReportNurseDetail";
import ReportTherapistDetail from "./report/ReportTherapistDetail";
import ReportNurseSummary from "./report/ReportNurseSummary";
import ReportTherapistSummary from "./report/ReportTherapistSummary";
import CreditTable from "./credit/table/CreditTable";

const RoutesLayout = () => {
    return (
        <div className="overflow-auto scroll-hide-indicator">
            <Routes>
                <Route path={Path.ROOT} element={<Dashboard />} />
                {/* ****************************************************************
                 ************************USERS *********************************
                 ****************************************************************/}
                <Route path={Path.USER_TABLE} element={<UserTable />} />
                <Route path={Path.USER_DETAIL} element={<UserDetail />} />
                <Route path={Path.USER_CREATE} element={<UserCrud />} />
                <Route path={Path.USER_ROLES} element={<UserRoleTable />} />
                <Route
                    path={Path.USER_ROLE_CREATE}
                    element={<UserRoleCrud />}
                />
                <Route
                    path={Path.USER_ROLE_DETAIL}
                    element={<UserRoleCrud />}
                />
                {/* ****************************************************************
                 ************************BRANCH *********************************
                 ****************************************************************/}
                <Route path={Path.BRANCH_TABLE} element={<BranchTable />} />
                <Route path={Path.BRANCH_DETAIL} element={<BranchDetail />} />
                <Route path={Path.BRANCH_CREATE} element={<BranchCreate />} />
                {/* ****************************************************************
                 ************************CUSTOMER *********************************
                 ****************************************************************/}
                <Route path={Path.CUSTOMER_TABLE} element={<CustomerTable />} />
                <Route
                    path={Path.CUSTOMER_DETAIL}
                    element={<CustomerDetail />}
                />
                <Route
                    path={Path.CUSTOMER_CREATE}
                    element={<CustomerCreate />}
                />
                {/* ****************************************************************
                 ************************SERVICE *********************************
                 ****************************************************************/}
                <Route
                    path={Path.SERVICE_CATEGORY}
                    element={<ServiceCategoryTable />}
                />
                <Route
                    path={Path.SERVICE_CATEGORY_CREATE}
                    element={<ServiceCategoryCrud />}
                />
                <Route
                    path={Path.SERVICE_CATEGORY_DETAIL}
                    element={<ServiceCategoryCrud />}
                />
                <Route
                    path={Path.SERVICE_CONFIGURATION}
                    element={<ServiceConfigurationTable />}
                />
                <Route
                    path={Path.SERVICE_CONFIGURATION_CREATE}
                    element={<ServiceConfigurationCreate />}
                />
                <Route
                    path={Path.SERVICE_CONFIGURATION_DETAIL}
                    element={<ServiceConfigurationDetail />}
                />
                {/* ****************************************************************
                 ************************PRODUCT *********************************
                 ****************************************************************/}
                <Route path={Path.PRODUCT_TABLE} element={<ProductTable />} />
                <Route path={Path.PRODUCT_CREATE} element={<ProductCreate />} />
                <Route path={Path.PRODUCT_DETAIL} element={<ProductDetail />} />
                <Route
                    path={Path.PRODUCT_CATEGORY}
                    element={<ProductCategoryTable />}
                />
                <Route
                    path={Path.PRODUCT_CATEGORY_DETAIL}
                    element={<ProductCategoryCrud />}
                />
                <Route
                    path={Path.PRODUCT_CATEGORY_CREATE}
                    element={<ProductCategoryCrud />}
                />
                {/* ****************************************************************
                 ************************INVENTORY *********************************
                 ****************************************************************/}
                <Route
                    path={Path.INVENTORY_TABLE}
                    element={<InventoryHistoryTable />}
                />
                <Route
                    path={Path.INVENTORY_STOCK_ADJUSTMENT_TABLE}
                    element={<StockAdjustmentTable />}
                />
                <Route
                    path={Path.INVENTORY_STOCK_ADJUSTMENT_CREATE}
                    element={<StockAdjustmentCreate />}
                />
                <Route
                    path={Path.INVENTORY_STOCK_ADJUSTMENT_DETAIL}
                    element={<StockAdjustmentDetail />}
                />
                <Route
                    path={Path.INVENTORY_REQUEST_STOCK_TABLE}
                    element={<StockRequestTable />}
                />
                <Route
                    path={Path.INVENTORY_REQUEST_STOCK_CREATE}
                    element={<StockRequestCreate />}
                />
                <Route
                    path={Path.INVENTORY_REQUEST_STOCK_DETAIL}
                    element={<StockRequestDetail />}
                />
                <Route
                    path={Path.INVENTORY_TRANSFER_STOCK_TABLE}
                    element={<StockTransferTable />}
                />
                <Route
                    path={Path.INVENTORY_TRANSFER_STOCK_CREATE}
                    element={<StockTransferCreate />}
                />
                <Route
                    path={Path.INVENTORY_TRANSFER_STOCK_DETAIL}
                    element={<StockTransferDetail />}
                />
                {/* ****************************************************************
                 ************************DELIVERY DRIVER *********************************
                 ****************************************************************/}
                <Route
                    path={Path.DELIVERY_DRIVER_TABLE}
                    element={<DeliveryDriverTable />}
                />
                <Route
                    path={Path.DELIVERY_DRIVER_DETAIL}
                    element={<DeliveryDriverDetail />}
                />
                {/* ****************************************************************
                 ************************MEDIA *********************************
                 ****************************************************************/}
                <Route path={Path.MEDIA} element={<MediaManager />} />
                <Route path={Path.MEDIA_FOLDER} element={<MediaManager />} />
                <Route path={Path.MEDIA_DETAIL} element={<MediaManager />} />
                {/* ****************************************************************
                 ************************ BOOKING *********************************
                 ****************************************************************/}
                <Route path={Path.BOOKING_TABLE} element={<BookingTable />} />
                <Route path={Path.BOOKING_DETAIL} element={<BookingDetail />} />
                <Route path={Path.BOOKING_CREATE} element={<BookingCreate />} />
                {/* ****************************************************************
                 ************************EQUIPMENT *********************************
                 ****************************************************************/}
                <Route
                    path={Path.EQUIPMENT_CATEGORY_TABLE}
                    element={<EquipmentCategoryTable />}
                />
                <Route
                    path={Path.EQUIPMENT_CATEGORY_CREATE}
                    element={<EquipmentCategoryCreate />}
                />
                <Route
                    path={Path.EQUIPMENT_CATEGORY_DETAIL}
                    element={<EquipmentCategoryCreate />}
                />
                <Route
                    path={Path.EQUIPMENT_TABLE}
                    element={<EquipmentTable />}
                />
                <Route
                    path={Path.EQUIPMENT_CREATE}
                    element={<EquipmentCreate />}
                />
                <Route
                    path={Path.EQUIPMENT_DETAIL}
                    element={<EquipmentDetail />}
                />
                <Route
                    path={Path.EQUIPMENT_EDIT}
                    element={<EquipmentCreate />}
                />
                {/* ****************************************************************
                 ************************INVENTORY *********************************
                 ****************************************************************/}
                <Route
                    path={Path.EQUIPMENT_INVENTORY_TABLE}
                    element={<InventoryHistoryTable />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_TABLE}
                    element={<EquipmentStockAdjustmentTable />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_CREATE}
                    element={<EquipmentStockAdjustmentCreate />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_STOCK_ADJUSTMENT_DETAIL}
                    element={<EquipmentStockAdjustmentDetail />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_TABLE}
                    element={<EquipmentStockRequestTable />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_CREATE}
                    element={<EquipmentStockRequestCreate />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_REQUEST_STOCK_DETAIL}
                    element={<EquipmentStockRequestDetail />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_TABLE}
                    element={<StockTransferTable />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_CREATE}
                    element={<StockTransferCreate />}
                />
                <Route
                    path={Path.EQUIPMENT_INVENTORY_TRANSFER_STOCK_DETAIL}
                    element={<StockTransferDetail />}
                />
                {/* ****************************************************************
                 ************************TEMPORARY SCHEDULE ************************
                 ****************************************************************/}
                <Route
                    path={Path.TEMPORARY_SCHEDULE}
                    element={<TemporaryScheduleTable />}
                />
                <Route
                    path={Path.TEMPORARY_SCHEDULE_CREATE}
                    element={<TemporaryScheduleCreate />}
                />
                <Route
                    path={Path.TEMPORARY_SCHEDULE_DETAIL}
                    element={<TemporaryScheduleDetail />}
                />
                {/* ****************************************************************
                 ************************ QUOTATION ************************
                 ****************************************************************/}
                <Route
                    path={Path.QUOTATION_TABLE}
                    element={<QuotationTable />}
                />
                <Route
                    path={Path.QUOTATION_DETAIL}
                    element={<QuotationDetail />}
                />
                <Route
                    path={Path.QUOTATION_CREATE}
                    element={<QuotationCreate />}
                />
                {/* ****************************************************************
                 ************************ SO ************************
                 ****************************************************************/}
                <Route path={Path.ORDER_TABLE} element={<OrderTable />} />
                <Route path={Path.ORDER_DETAIL} element={<OrderDetail />} />
                <Route path={Path.ORDER_CREATE} element={<OrderCreate />} />
                {/* ****************************************************************
                 ************************ DOCTOR ORDER ************************
                 ****************************************************************/}
                <Route
                    path={Path.DOCTOR_ORDER_TABLE}
                    element={<DoctorTable />}
                />
                <Route
                    path={Path.DOCTOR_ORDER_DETAIL}
                    element={<DoctorOrderDetail />}
                />
                <Route
                    path={Path.DOCTOR_ORDER_CREATE}
                    element={<DoctorOrderCreate />}
                />
                {/* ****************************************************************
                 ************************CALENDAR *********************************
                 ****************************************************************/}
                <Route path={Path.CALENDAR} element={<Calendar />} />
                {/* ****************************************************************
                 ************************VOUCHER *********************************
                 ****************************************************************/}
                <Route path={Path.VOUCHER_TABLE} element={<VoucherTable />} />
                <Route path={Path.VOUCHER_CREATE} element={<VoucherCreate />} />
                <Route path={Path.VOUCHER_DETAIL} element={<VoucherDetail />} />
                {/* ****************************************************************
                 ************************CREDIT *********************************
                 ****************************************************************/}
                <Route path={Path.CREDIT_TABLE} element={<CreditTable />} />
                {/* ****************************************************************
                 ************************PROMOTION *********************************
                 ****************************************************************/}
                <Route
                    path={Path.PROMOTION_TABLE}
                    element={<PromotionTable />}
                />
                <Route
                    path={Path.PROMOTION_CREATE}
                    element={<PromotionCreate />}
                />
                <Route
                    path={Path.PROMOTION_DETAIL}
                    element={<PromotionDetail />}
                />
                {/* ****************************************************************
                 ************************TREATMENT *********************************
                 ****************************************************************/}
                <Route
                    path={Path.TREATMENT_TABLE}
                    element={<TreatmentTable />}
                />
                <Route
                    path={Path.TREATMENT_CREATE}
                    element={<TreatmentCreate />}
                />
                <Route
                    path={Path.TREATMENT_DETAIL}
                    element={<TreatmentDetail />}
                />
                {/* ****************************************************************
                 ************************APPOINTMENT *********************************
                 ****************************************************************/}
                <Route
                    path={Path.APPOINTMENT_TABLE}
                    element={<AppointmentTable />}
                />
                <Route
                    path={Path.APPOINTMENT_DETAIL}
                    element={<AppointmentDetail />}
                />
                {/* ****************************************************************
                 ************************PAYMENT METHOD *********************************
                 ****************************************************************/}
                <Route
                    path={Path.PAYMENT_METHOD_TABLE}
                    element={<PaymentMethodTable />}
                />
                <Route
                    path={Path.PAYMENT_METHOD_DETAIL}
                    element={<PaymentMethodDetail />}
                />
                {/* ****************************************************************
                 ************************TRANSFER VOUCHER *********************************
                 ****************************************************************/}
                <Route
                    path={Path.TRANSFER_VOUCHER_TABLE}
                    element={<TransferVoucherTable />}
                />
                <Route
                    path={Path.TRANSFER_VOUCHER_CREATE}
                    element={<TransferVoucherCreate />}
                />
                <Route
                    path={Path.TRANSFER_VOUCHER_DETAIL}
                    element={<TransferVoucherDetail />}
                />
                {/* ****************************************************************
                 ************************AGENCY *********************************
                 ****************************************************************/}
                <Route path={Path.AGENCY_TABLE} element={<AgencyTable />} />
                <Route path={Path.AGENCY_CREATE} element={<AgencyCreate />} />
                <Route path={Path.AGENCY_DETAIL} element={<AgencyDetail />} />
                {/* ****************************************************************
                 ************************MATERIAL *********************************
                 ****************************************************************/}
                <Route path={Path.MATERIAL} element={<MaterialTable />} />
                <Route
                    path={Path.MATERIAL_CREATE}
                    element={<MaterialCreate />}
                />
                <Route
                    path={Path.MATERIAL_UPDATE}
                    element={<MaterialCreate />}
                />
                <Route
                    path={Path.MATERIAL_DETAIL}
                    element={<MaterialDetail />}
                />
                <Route
                    path={Path.MATERIAL_CATEGORY}
                    element={<MaterialCategory />}
                />
                <Route
                    path={Path.MATERIAL_CATEGORY_CREATE}
                    element={<MaterialCategoryCreate />}
                />
                <Route
                    path={Path.MATERIAL_CATEGORY_DETAIL}
                    element={<MaterialCategoryCreate />}
                />
                <Route
                    path={Path.MATERIAL_ORDER_CREATE}
                    element={<MaterialPurchaseOrderCreate />}
                />
                <Route
                    path={Path.MATERIAL_ORDER_DETAIL}
                    element={<MaterialOrderDetail />}
                />
                <Route
                    path={Path.MATERIAL_ORDER}
                    element={<MaterialPurchaseTable />}
                />
                <Route
                    path={Path.MATERIAL_INVENTORY_TABLE}
                    element={<MaterialInventoryTable />}
                />
                <Route
                    path={Path.MATERIAL_ADJUST_REQUEST_TABLE}
                    element={<MaterialAdjustmentRequestTable />}
                />
                <Route
                    path={Path.MATERIAL_ADJUST_REQUEST_CREATE}
                    element={<MaterialAdjustmentRequestCreate />}
                />
                <Route
                    path={Path.MATERIAL_ADJUST_REQUEST_DETAIL}
                    element={<MaterialAdjustRequestDetail />}
                />
                <Route
                    path={Path.MATERIAL_REQUEST}
                    element={<MaterialRequestTable />}
                />
                <Route
                    path={Path.MATERIAL_REQUEST_CREATE}
                    element={<MaterialRequestCreate />}
                />
                <Route
                    path={Path.MATERIAL_REQUEST_DETAIL}
                    element={<MaterialRequestDetail />}
                />
                {/* ****************************************************************
                 ************************VENDOR *********************************
                 ****************************************************************/}
                <Route path={Path.VENDOR} element={<VendorTable />} />
                <Route path={Path.VENDOR_CREATE} element={<VendorCreate />} />
                <Route path={Path.VENDOR_DETAIL} element={<VendorDetail />} />
                {/* ****************************************************************
                 ************************REPORT *********************************
                 ****************************************************************/}
                <Route path={Path.REPORT_CASHIER} element={<ReportCashier />} />
                <Route
                    path={Path.REPORT_CASHIER_PAYMENT}
                    element={<ReportCashierByPayment />}
                />
                <Route
                    path={Path.REPORT_SALESPERSON_USER}
                    element={<ReportSalespersonUser />}
                />
                <Route
                    path={Path.REPORT_SALESPERSON_MONTHLY}
                    element={<ReportSalespersonMonthly />}
                />
                <Route
                    path={Path.REPORT_CUSTOMER_SOURCE}
                    element={<ReportCustomerSource />}
                />
                <Route
                    path={Path.REPORT_DOCTOR_SUMMARY}
                    element={<ReportDoctorSummary />}
                />
                <Route
                    path={Path.REPORT_DOCTOR_DETAIL}
                    element={<ReportDoctorDetail />}
                />
                <Route
                    path={Path.REPORT_NURSE_DETAIL}
                    element={<ReportNurseDetail />}
                />
                <Route
                    path={Path.REPORT_NURSE_SUMMARY}
                    element={<ReportNurseSummary />}
                />
                <Route
                    path={Path.REPORT_THERAPIST_DETAIL}
                    element={<ReportTherapistDetail />}
                />
                <Route
                    path={Path.REPORT_THERAPIST_SUMMARY}
                    element={<ReportTherapistSummary />}
                />
                <Route
                    path={Path.REPORT_CUSTOMER_VOUCHER}
                    element={<ReportCustomerVoucher />}
                />
                <Route
                    path={Path.REPORT_TREATMENT_DAILY_USAGE}
                    element={<ReportTreatmentDailyUsage />}
                />
                <Route path={Path.IMPORT} element={<Import />}>
                    <Route
                        path={Path.IMPORT_CUSTOMER}
                        element={<ImportCustomer />}
                    />
                </Route>
            </Routes>
        </div>
    );
};

export default RoutesLayout;
