import classNames from "classnames";
import { TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import { OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const OrderPORequest = () => {
    const { order } = useContext(MaterialPurchaseDetailContext);
    const { materialPurchaseRequest } = order || {};
    if (!materialPurchaseRequest) {
        return null;
    }
    return (
        <div className="border p-3 mb-3">
            <div className="flex">
                <h5 className="font-semibold mb-2">{Messages.poRequest}</h5>
            </div>
            <div
                className={classNames("pt-2 flex-column")}
            >
                <span>
                    <AppLink
                        to={generatePath(Path.MATERIAL_REQUEST_DETAIL, {
                            requestId: materialPurchaseRequest.id,
                        })}
                    >
                        #{materialPurchaseRequest.materialPurchaseRequestNo}
                    </AppLink>
                </span>
            </div>
        </div>
    );
};

export default OrderPORequest;
