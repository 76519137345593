import { FormikProps } from "formik";
import React, { Dispatch } from "react";
import { IPromotion } from "../interfaces/promotion";

interface IPromotionCreateState {
    promotionForm: FormikProps<any>;
}

const promotionCreateState: IPromotionCreateState = {
    promotionForm: {} as any,
};

export const PromotionCreateContext = React.createContext(promotionCreateState);

export const usePromotionCreateContext = () => {
    return React.useContext(PromotionCreateContext);
};

export const usePromotionCreateForm = () => {
    const { promotionForm } = usePromotionCreateContext();
    return promotionForm;
};

export enum PromotionUpdateDrawerType {
    MEMO = "MEMO",
    DOCUMENT = "DOCUMENT",
    PERIOD = "PERIOD",
    BRANCH = "BRANCH",
}

export interface IPromotionUpdateDrawerState {
    open: boolean;
    type?: PromotionUpdateDrawerType;
}

interface IPromotionDetailState {
    promotion: IPromotion;
    setPromotion: Dispatch<IPromotion>;
    openUpdateDrawer: IPromotionUpdateDrawerState;
    setOpenUpdateDrawer: Dispatch<IPromotionUpdateDrawerState>;
}

const promotionDetailState: IPromotionDetailState = {
    promotion: {} as any,
    setPromotion: () => {},
    openUpdateDrawer: {} as any,
    setOpenUpdateDrawer: () => {},
};

export const PromotionDetailContext = React.createContext(promotionDetailState);

export const usePromotionDetailContext = () => {
    return React.useContext(PromotionDetailContext);
};
