import { find } from "lodash";
import { useContext } from "react";
import { CUSTOMER_SOURCES } from "../../../constant/customer";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";

const OrderSourceCustomer = () => {
    const { order } = useContext(OrderDetailContext);

    const sourceCustomer = find(
        CUSTOMER_SOURCES,
        (item) => item.id === order?.sourceOfCustomer
    );
    return (
        <div className="border p-3 mt-3 mb-3">
            <h5 className="font-semibold mb-2">{Messages.customerSource}</h5>
            <p className="text-base mb-0">
                {(Messages as any)[sourceCustomer?.label as any]}
            </p>
        </div>
    );
};

export default OrderSourceCustomer;
