import classNames from "classnames";
import { Notifications } from "d-react-components";
import {
    ceil,
    every,
    filter,
    find,
    floor,
    maxBy,
    minBy,
    some,
    sortBy,
} from "lodash";
import Messages from "../languages/Messages";

export interface ITimeSlotValue {
    start: number;
    end: number;
}

interface ITimeSlotSelect<T> {
    label?: string;
    className?: any;
    workingSchedule: T[];
    occupiedSchedule?: T[];

    value: T[];
    onChange: (value: T[]) => void;
    multiple?: boolean;
    interruptValue?: boolean;
    occupiedSelectable?: boolean;

    timeAtom?: number;
}

interface ISlotItem {
    start: number;
    end: number;
    selected: boolean;
    disabled: boolean;
    occupiedSelectable?: boolean;
    onClick: () => void;
}

const SlotItem = ({
    start,
    end,
    selected,
    disabled,
    onClick,
    occupiedSelectable,
}: ISlotItem) => {
    const containerClassName = classNames(
        "bg-[#f6f6f6] flex-row flex-center px-3 py-1 mr-2 mt-2 cursor-pointer",
        {
            "bg-gray text-gray": disabled && !occupiedSelectable,
        },
        {
            "bg-yellow-30": disabled && occupiedSelectable,
        },
        {
            "bg-primary text-white": selected,
        }
    );

    const timeFormat = (time: number) => {
        const timeRounded = floor(time);
        const remainingMinute = (time - timeRounded) * 60;

        const timeRoundedFormatted =
            timeRounded < 10 ? `0${timeRounded}` : timeRounded;
        const minuteFormatted =
            remainingMinute < 10 ? `0${remainingMinute}` : remainingMinute;

        return `${timeRoundedFormatted}:${minuteFormatted}`;
    };
    return (
        <div className={containerClassName} onClick={onClick}>
            <small>{`${timeFormat(start)} - ${timeFormat(end)}`}</small>
        </div>
    );
};

const TimeSlotSelect = ({
    label = Messages.availableDoctorTimeSlot,
    workingSchedule,
    occupiedSchedule = [],
    className,
    value = [],
    onChange,
    multiple = false,
    interruptValue = false,
    occupiedSelectable,
    timeAtom = 0.5,
}: ITimeSlotSelect<ITimeSlotValue>) => {
    const isDisabledSlot = (start: number, end: number) => {
        return (
            !some(
                workingSchedule,
                (item) => item?.start <= start && item?.end >= end
            ) ||
            !every(
                occupiedSchedule,
                (item) => end <= item?.start || start >= item?.end
            )
        );
    };

    const isSelectedSlot = (start: number, end: number) => {
        return !!find(
            value,
            (item) => item?.start === start && item?.end === end
        );
    };

    const onClickSlot = (start: number, end: number, selected: boolean) => {
        if (!multiple) {
            onChange([{ start, end }]);
            return;
        }

        let result = [];
        if (selected) {
            result = filter(
                value,
                (item) => !(item?.start === start && item?.end === end)
            );
        } else {
            result = [...value, { start, end }];
        }
        if (!interruptValue && isInterruptSlots(result)) {
            Notifications.showError(Messages.canNotSelectInterruptSlots);
            return;
        }

        onChange(result);
    };

    const isInterruptSlots = (timeSlots: ITimeSlotValue[]) => {
        const timeSlotSorted = sortBy(timeSlots, (item) => item.start);

        return !every(timeSlotSorted, (item, index) => {
            if (index === 0) return true;
            return item.start === timeSlotSorted[index - 1]?.end;
        });
    };

    const renderScheduleSlots = () => {
        const slots = [];
        const endWorkingScheduleTime = ceil(
            maxBy(workingSchedule, (item) => item?.end)?.end ?? 0
        );
        const startWorkingScheduleTime = floor(
            minBy(workingSchedule, (item) => item?.start)?.start ?? 0
        );

        // const endWorkingScheduleTime =
        //     maxBy(workingSchedule, (item) => item?.end)?.end ?? 0;
        // const startWorkingScheduleTime =
        //     minBy(workingSchedule, (item) => item?.start)?.start ?? 0;

        for (
            let index = startWorkingScheduleTime;
            index < endWorkingScheduleTime;
            index += timeAtom
        ) {
            const disabled = isDisabledSlot(index, index + timeAtom);
            const selected = isSelectedSlot(index, index + timeAtom);
            slots.push(
                <SlotItem
                    key={index}
                    start={index}
                    end={index + timeAtom}
                    selected={selected}
                    disabled={disabled}
                    occupiedSelectable={occupiedSelectable}
                    onClick={() => {
                        if (disabled && !occupiedSelectable) {
                            return;
                        }
                        onClickSlot(index, index + timeAtom, selected);
                    }}
                />
            );
        }
        return slots;
    };

    return (
        <div className={classNames("bg-primary-trans-15 p-2 w-100", className)}>
            <label>{label}</label>
            <div className="d-flex flex-wrap">{renderScheduleSlots()}</div>
        </div>
    );
};

export default TimeSlotSelect;
