/* eslint-disable no-plusplus */
import {
    DateInput,
    InputText,
    Notifications,
    Select,
} from "d-react-components";
import { DatePicker } from "antd";
import moment from "moment";
import { useFormik } from "formik";
import { Fragment, useContext } from "react";
import * as Yup from "yup";
import Drawer from "../../../../common/Drawer";
import Messages from "../../../../languages/Messages";

import {
    PaymentMethod,
    SourceOfPayment,
    useUpdatePaymentMethodForAdminMutation,
} from "../../../../api/hooks";
import {
    CREDIT_CARD_TYPES,
    PAYMENT_CARD_DEVICES,
} from "../../../../constant/payment";
import { PaymentContext } from "../../../../context/payment";

const getCreditCardDisplayNumber = (value: string) => {
    if (!value) return "";
    let newValue = "";
    for (let i = 0; i < value.length; i++) {
        if (i % 4 === 0 && i !== 0) {
            newValue = newValue.concat(" ");
        }
        newValue = newValue.concat(value[i]);
    }
    return newValue;
};

const ChangePaymentMethodDrawer = ({ open, onClose, payment, onSave }: any) => {
    // const { paymentMethodsList, paymentOptions } = useContext(AppContext);
    // const [mtBankList, setMTBankList] = useState([]);
    const [updatePaymentMethod] = useUpdatePaymentMethodForAdminMutation();
    const {
        ref: order,
        paymentMethodsList,
        payment2C2POptionsList,
    } = useContext(PaymentContext);
    const ChangeMethodSchema = Yup.lazy((value) => {
        const schema = Yup.object().shape({
            // remark: Yup.string().required(Messages.fieldRequired),
        });
        // if (value.method === PAYMENT_MODERN_TRADE) {
        //     schema = schema.concat(
        //         Yup.object().shape({
        //             numberInstalment: Yup.number().required(t("fieldRequired")),
        //         })
        //     );
        // }
        return schema;
    });

    const validate = (values: any) => {
        const errors: any = {};

        if (
            values?.paymentMethod === PaymentMethod.CreditCardPos &&
            !values.paymentCardDevice
        ) {
            Notifications.showError(Messages.requirePaymentCardDevice);
            errors.bankTransfer = Messages.requirePaymentCardDevice;
        }

        return errors;
    };

    const methodForm = useFormik<any>({
        initialValues: {
            paymentMethod: payment.paymentMethod,
            paymentOption: payment.paymentOption,
            paymentCardDevice: payment.paymentCardDevice,
            creditType: payment?.creditCard?.type,
            creditNumber: payment?.creditCard?.number,
            expiryDate: payment?.creditCard?.expiryDate
                ? moment(payment?.creditCard?.expiryDate)
                : null,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: ChangeMethodSchema,
        validate,
        onSubmit: (values: any) => {
            updatePaymentMethod({
                variables: {
                    refId: order?.id as string,
                    paymentId: payment?.id as string,
                    sourceOfPayment: SourceOfPayment.Order,
                    payload: {
                        paymentMethod: values?.paymentMethod,
                        ...(values?.paymentOption
                            ? { paymentOption: values?.paymentOption }
                            : {}),
                        ...(values?.paymentCardDevice
                            ? { paymentCardDevice: values?.paymentCardDevice }
                            : {}),
                        ...(values?.paymentMethod ===
                        PaymentMethod.CreditCardPos
                            ? {
                                  creditCard: {
                                      type: values?.creditType,
                                      number: values?.creditNumber,
                                      expiryDate: values?.expiryDate,
                                  },
                              }
                            : {}),
                    },
                },
                fetchPolicy: "no-cache",
            })
                .then(() => {
                    onSave && onSave();
                })
                .catch((err) => {
                    Notifications.showError(err);
                });
        },
    });

    const { values, errors, setFieldValue } = methodForm || {};
    const { paymentMethod } = values || {};

    // useEffect(() => {
    //     modernTradeBankList().then((res) => {
    //         const bankList = res?.data?.data?.brand_name ?? [];
    //         setMTBankList(bankList);
    //     });
    // }, []);

    const renderCreditCardInfoForm = () => {
        return (
            <Fragment>
                <Select
                    className="mt-3"
                    hidden={
                        methodForm.values.paymentMethod !==
                        PaymentMethod.Payment_2C2P
                    }
                    label={Messages.paymentOption}
                    dataSource={payment2C2POptionsList}
                    value={methodForm.values.paymentOption}
                    getValue={(item: any) => item?.option}
                    getLabel={(item: any) => item?.title}
                    onChange={(value) => {
                        methodForm.setFieldValue("paymentOption", value);
                    }}
                />
                <Select
                    className="mt-3"
                    label="Card Type"
                    dataSource={CREDIT_CARD_TYPES}
                    getLabel={(item: any) => item?.label}
                    value={values.creditType}
                    onChange={(value) => {
                        methodForm.setFieldValue("creditType", value);
                    }}
                />
                <InputText
                    label="Card Number"
                    className="mt-3"
                    autoComplete="cc-number"
                    maxLength={19}
                    placeholder="xxxx xxxx xxxx xxxx"
                    value={getCreditCardDisplayNumber(values.creditNumber)}
                    onChange={(e) => {
                        const text = e?.target?.value;
                        let res = text;
                        if (text) {
                            res = text.replace(/\D/g, "");
                        }
                        methodForm.setFieldValue("creditNumber", res);
                    }}
                />
                <div className="label mt-3 mb-1">Expiry Date</div>
                <DatePicker
                    style={{ height: 40 }}
                    className="w-full"
                    format="MM/YY"
                    value={values?.expiryDate ?? null}
                    onChange={(v) => {
                        setFieldValue("expiryDate", v);
                    }}
                    picker="month"
                />
            </Fragment>
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.changePaymentMethod}
            onSave={() => methodForm.handleSubmit()}
        >
            <div className="p-3">
                <Select
                    dataSource={paymentMethodsList}
                    label={Messages.paymentMethod}
                    value={methodForm.values.paymentMethod}
                    error={methodForm.errors.paymentMethod}
                    getValue={(item: any) => item?.paymentConfigurationType}
                    getLabel={(item) => item?.title}
                    onChange={(value) =>
                        methodForm.setFieldValue("paymentMethod", value)
                    }
                />
                {/* <Select
                className="mt-3"
                hidden={methodForm.values.method !== PAYMENT_MODERN_TRADE}
                dataSource={mtBankList}
                getLabel={(item) => (
                    <div className="flex items-center">
                        {item.logo && (
                            <img
                                src={item.logo}
                                className="w-4 h-4 mr-2"
                                alt=""
                            />
                        )}
                        {item.code}
                    </div>
                )}
                label={t("bankName")}
                value={methodForm.values.bankName}
                error={methodForm.errors.bankName}
                onChange={(value) =>
                    methodForm.setFieldValue("bankName", value)
                }
                placeholder={t("pleaseSelect")}
            /> */}
                {/* <Select
                className="mt-3"
                dataSource={NUMBER_INSTALMENT}
                label={t("numberOfInstalment")}
                value={methodForm.values.numberInstalment}
                error={methodForm.errors.numberInstalment}
                onChange={(value) =>
                    methodForm.setFieldValue("numberInstalment", value)
                }
                hidden={methodForm.values.method !== PAYMENT_MODERN_TRADE}
                placeholder={t("pleaseSelect")}
            /> */}
                <Select
                    hidden={paymentMethod !== PaymentMethod.CreditCardPos}
                    className="mt-3"
                    label={`${Messages.posCardDevice} *`}
                    name="paymentCardDevice"
                    value={values.paymentCardDevice}
                    onChange={(value) => {
                        setFieldValue("paymentCardDevice", value);
                    }}
                    error={errors.paymentCardDevice}
                    dataSource={PAYMENT_CARD_DEVICES}
                    getLabel={(pro) => pro.label}
                />

                <Select
                    className="mt-3"
                    hidden={
                        methodForm.values.paymentMethod !==
                        PaymentMethod.Payment_2C2P
                    }
                    label={Messages.paymentOption}
                    dataSource={payment2C2POptionsList}
                    value={methodForm.values.paymentOption}
                    getValue={(item: any) => item?.option}
                    getLabel={(item: any) => item?.title}
                    onChange={(value) => {
                        methodForm.setFieldValue("paymentOption", value);
                    }}
                />
                {methodForm?.values?.paymentMethod ===
                    PaymentMethod.CreditCardPos && renderCreditCardInfoForm()}
                {/* <InputText
                    className="mt-3"
                    label={Messages.remark}
                    value={methodForm.values.remark}
                    error={methodForm.errors.remark as string}
                    name="remark"
                    onChange={methodForm.handleChange}
                    multiple
                /> */}
            </div>
        </Drawer>
    );
};

export default ChangePaymentMethodDrawer;
