import { Switch } from "antd";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { PRODUCT_TYPE } from "../../../constant/product";
import Messages from "../../../languages/Messages";
import { getSourceSelectIntegerFromLength } from "../../../utils/Utils";

interface IProductVoucherForm {
    configForm: any;
    isEdit?: boolean;
}

const ProductConfigVoucherForm = ({
    configForm,
    isEdit = false,
}: IProductVoucherForm) => {
    const productFormValue = configForm.values;

    /**
     * This one can be combined with "This is a simple product" only. Cannot combine with other options in Product Configuration. => Jack note in figma
     */
    if (productFormValue?.typeOfProduct !== PRODUCT_TYPE.SIMPLE_PRODUCT) {
        return <div />;
    }

    const inputClassName = "mt-3";

    return (
        <div className="mt-3">
            <label>{Messages.giftVoucherConfiguration}</label>
            <div className="border p-3">
                <div className="d-flex align-items-center">
                    <Switch
                        checked={productFormValue?.isGenerateGiftVoucher}
                        onChange={() =>
                            configForm.setFieldValue(
                                "isGenerateGiftVoucher",
                                !productFormValue?.isGenerateGiftVoucher
                            )
                        }
                        disabled={isEdit}
                    />
                    <text className="ml-3">
                        {Messages.selectGenerateVoucher}
                    </text>
                </div>
                <InputSelectForm
                    hidden={!productFormValue?.isGenerateGiftVoucher}
                    dataSource={getSourceSelectIntegerFromLength(10)}
                    keyData="expiredAfterYear"
                    form={configForm}
                    className={inputClassName}
                />
                <InputSelectForm
                    hidden={
                        !productFormValue?.isGenerateGiftVoucher ||
                        (!productFormValue?.expiredAfterYear &&
                            productFormValue?.expiredAfterYear !== 0)
                    }
                    dataSource={getSourceSelectIntegerFromLength(12)}
                    keyData="expiredAfterMonth"
                    form={configForm}
                    className={inputClassName}
                />
                <InputSelectForm
                    hidden={
                        !productFormValue?.isGenerateGiftVoucher ||
                        (!productFormValue?.expiredAfterYear &&
                            productFormValue?.expiredAfterYear !== 0) ||
                        (!productFormValue?.expiredAfterMonth &&
                            productFormValue?.expiredAfterMonth !== 0)
                    }
                    dataSource={getSourceSelectIntegerFromLength(31)}
                    keyData="expiredAfterDay"
                    form={configForm}
                    className={inputClassName}
                />
            </div>
        </div>
    );
};

export default ProductConfigVoucherForm;
