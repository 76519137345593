import React from "react";
import CurrencyFormat from "react-currency-format";

export type IPriceViewProps = CurrencyFormat.Props & {
    addPrefix?: boolean;
};

const PriceView: React.FC<IPriceViewProps> = ({
    value,
    displayType = "text",
    prefix = "฿",
    addPrefix = true,
    decimalScale = 2,
    ...rest
}) => {
    return (
        <CurrencyFormat
            value={value as any}
            thousandSeparator
            displayType={displayType}
            decimalScale={decimalScale}
            prefix={addPrefix ? prefix : ""}
            fixedDecimalScale
            {...(rest as any)}
        />
    );
};

export default PriceView;
