import { Icon } from "d-react-components";
import { isEmpty, map } from "lodash";
import { useContext } from "react";
import Image from "../../../common/Image";
import { AppStateContext } from "../../../context/app";
import { CustomerStatus, ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import CustomerSelect, { ICustomerSelect } from "./CustomerSelect";

interface ICustomerSolidSelect<T> extends ICustomerSelect<T> {
    className?: any;
}

interface ICustomerSolidItem {
    customer: ICustomer;
    className?: string;
    customRight?: () => any;
}

export const CustomerSolidItem = ({
    customer,
    className,
    customRight,
}: ICustomerSolidItem) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    const {
        nickname,
        customerNo,
        avatar,
        id,
        firstNameTh,
        lastNameTh,
        fullNameTh,
    } = customer;

    const fullName = fullNameTh || `${firstNameTh} ${lastNameTh}`;
    return (
        <div className={`w-100 bg-primary flex items-center ${className}`}>
            <div className="flex-1 flex items-center w-full">
                <Image src={avatar} className="image-square-medium" />
                <div className="p-2 flex-column">
                    <div className="flex-center-y mb-1">
                        <span
                            className="block text-white font-weight-bold hover:text-primary hover-pointer mr-1"
                            onClick={() =>
                                setOpenAppDrawer({ open: true, customerId: id })
                            }
                        >
                            {`${fullName} (${nickname || "N/A"})`}
                        </span>
                        <Icon
                            name="check_circle"
                            className={
                                customer.status === CustomerStatus.VERIFIED
                                    ? "text-green-500"
                                    : "text-yellow-300"
                            }
                        />
                    </div>

                    <span className="block text-white text-sm opacity-75">{`${Messages.customerNo}: ${customerNo}`}</span>
                    {/* <span className="block text-white text-sm opacity-75">{`${Messages.opdQuantity}: `}</span> */}
                </div>
            </div>
            {customRight ? (
                customRight()
            ) : (
                <div className="flex items-center p-3">
                    <div
                        className="text-primary bg-white py-2 px-3 text-bold hover:cursor-pointer"
                        onClick={() => {
                            setOpenAppDrawer({ open: true, customerId: id });
                        }}
                    >
                        {Messages.medicalProfile}
                    </div>
                </div>
            )}
        </div>
    );
};

const CustomerSolidSelect = ({
    className,
    ...selectProps
}: ICustomerSolidSelect<ICustomer>) => {
    const renderCustomerSelected = () => {
        if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
        if (selectProps?.multiple) {
            return map(selectProps?.value, (customer) => (
                <CustomerSolidItem customer={customer} />
            ));
        }
        return <CustomerSolidItem customer={selectProps?.value as ICustomer} />;
    };

    return (
        <div className={className}>
            <CustomerSelect {...selectProps} />
            {renderCustomerSelected()}
        </div>
    );
};

export default CustomerSolidSelect;
