import classNames from "classnames";
import { TimeUtils } from "d-react-components";
import React, { useContext } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../common/AppLink";
import { OrderDetailContext } from "../../../context/order";
import { IOrder } from "../../../interfaces/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { useListTreatmentForAdminQuery } from "../../../api/hooks";

const OrderTreatmentReferences = () => {
    const { order } = useContext(OrderDetailContext);
    const { doctorOrder } = (order as IOrder) ?? {};
    const { data } = useListTreatmentForAdminQuery({
        variables: {
            input: {
                page: 1,
                limit: 100,
                doctorOrder: order?.doctorOrder?.map((order) => order.id),
            },
        },
        skip: !doctorOrder,
    });
    const treatmentList = data?.data?.data ?? [];
    if (!doctorOrder) {
        return <></>;
    }
    return (
        <div className="border p-3 mb-3">
            <div className="flex">
                <h5 className="font-semibold mb-2">
                    {Messages.treatmentReferences}
                </h5>
            </div>
            {treatmentList.map((treatment, index) => (
                <div
                    key={index}
                    className={classNames("pt-2 flex-column", {
                        "pb-2 border-b": index + 1 !== treatmentList.length,
                    })}
                >
                    <span>
                        <AppLink
                            to={generatePath(Path.TREATMENT_DETAIL, {
                                treatmentId: treatment.id,
                            })}
                        >
                            #{treatment.treatmentNo}
                        </AppLink>
                    </span>
                    <span>
                        {Messages.createdOn}{" "}
                        {TimeUtils.convertMiliToDateTime(treatment.createdAt)}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default OrderTreatmentReferences;
