import styled from "@emotion/styled";
import { Avatar, Icon, TimeUtils, ViewLabelStatus } from "d-react-components";
import { useFormikContext } from "formik";
import { isUndefined } from "lodash";
import { Fragment, useContext, useState } from "react";
import { CUSTOMER_STATUS } from "../../../constant/customer";
import { AppStateContext } from "../../../context/app";
import { CustomerStatus } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import NewCustomerDrawer from "./NewCustomerDrawer";

const HiddenContent = ({ children, hidden }: any) => {
    return hidden ? null : children;
};

const CustomerInfo = ({ customer }: { customer: any }) => {
    const isGuest = isUndefined(customer?.id);
    const { setOpenAppDrawer } = useContext(AppStateContext);
    const { setFieldValue } = useFormikContext<any>();
    const [expanded, setExpanded] = useState(false);
    const [editGuestModal, setEditGuestModal] = useState(false);
    const customerName = isGuest
        ? `${customer.firstName} ${customer.lastName}`
        : // : getCustomerNameByLanguage(customer);
          customer?.fullNameTh;

    if (!customer) {
        return <></>;
    }
    const firstNameCharacter = customerName ? customerName.charAt(0) : "";
    // const isShowRemoveCustomer = () => {
    //     if (!customer || isCreateOrder) {
    //         return false;
    //     }
    //     const isGrantPermission = isGrantURLPermission(
    //         API_DELETE_CUSTOMER_ORDER
    //     );
    //     if (!isGrantPermission) {
    //         return false;
    //     }

    //     if (
    //         !order ||
    //         (order.status !== ORDER_STATUS.pending.value &&
    //             order.status !==
    //                 ORDER_STATUS.pendingPaymentConfirmation.value &&
    //             order.status !== ORDER_STATUS.partiallyPaid.value)
    //     ) {
    //         return false;
    //     }
    //     return true;
    // };

    // const onClickRemove = () => {
    //     DialogManager.showConfirm(Messages.confirm"), Messages.wantToRemoveCustomer"), () =>
    //         onRemoveCustomer()
    //     );
    // };

    // const onRemoveCustomer = () => {
    //     setLoadingRemove(true);
    //     const body = {
    //         id: order.id,
    //     };
    //     deleteCustomerOrder(body)
    //         .then((res) => {
    //             setLoadingRemove(false);
    //             onReloadOrderData && onReloadOrderData();
    //             Notifications.showSuccess(
    //                 Messages.notification:removeCustomerOrderSuccess")
    //             );
    //         })
    //         .catch((err) => {
    //             setLoadingRemove(false);
    //         });
    // };

    const onCloseCustomerInfo = (customer: any) => {
        setFieldValue("customer", [customer]);
        setEditGuestModal(false);
    };

    return (
        <Fragment>
            <CustomerInfoStyled className="flex bg-amber-100 p-3 my-2">
                <div className="flex-1 flex">
                    {customer.avatar ? (
                        <Avatar
                            className="w-8 h-8 object-cover"
                            src={customer.avatar}
                            size="small"
                            variant="square"
                            text={firstNameCharacter}
                        />
                    ) : (
                        <div className="w-8 h-8 flex bg-gray-400 justify-content-center items-center">
                            <span
                                className="text-white"
                                style={{ fontSize: "1.5rem" }}
                            >
                                {firstNameCharacter}
                            </span>
                        </div>
                    )}
                    <div className="customer-info ml-2 text-sm">
                        <span className="block flex items-center">
                            <span className="">
                                {customerName +
                                    (customer?.nickname
                                        ? ` (${customer.nickname})`
                                        : "")}
                            </span>
                            {isGuest && (
                                <div className="flex-center-y">
                                    <div className="px-2 text-white text-xs ml-3 py-2 bg-primary">
                                        {Messages.guest}
                                    </div>
                                    <div
                                        className="px-2 text-white text-xs ml-2 py-1 bg-primary hover-pointer"
                                        onClick={() => setEditGuestModal(true)}
                                    >
                                        <Icon
                                            name="edit"
                                            style={{ fontSize: "18px" }}
                                        />
                                    </div>
                                </div>
                            )}
                        </span>
                        <span className="block">{customer.phone}</span>
                        {!isGuest && (
                            <span className="flex">
                                {Messages.customerId}:&nbsp;
                                <div
                                    className="text-primary cursor-pointer"
                                    onClick={() => {
                                        setOpenAppDrawer({
                                            open: true,
                                            customerId: customer.id,
                                        });
                                    }}
                                >{`${customer.customerNo}`}</div>
                            </span>
                        )}
                        <HiddenContent hidden={!expanded}>
                            <>
                                <div
                                    className="block"
                                    hidden={!customer.email}
                                >{`${Messages.email}: ${customer.email}`}</div>
                                <div
                                    className="block"
                                    hidden={!customer.birthDay}
                                >{`${
                                    Messages.dateOfBirth
                                }: ${TimeUtils.convertMiliToDate(
                                    customer.birthDay
                                )}`}</div>
                                {!isGuest && (
                                    <ViewLabelStatus
                                        className="mt-2"
                                        status={
                                            customer?.status ??
                                            CustomerStatus.UN_VERIFIED
                                        }
                                        listStatus={CUSTOMER_STATUS}
                                        getLabel={(item) =>
                                            Messages[item.label]
                                        }
                                    />
                                )}
                            </>
                        </HiddenContent>
                    </div>
                </div>
                <Icon
                    size="large"
                    className="cursor-pointer pt-3 text-red-500 mr-2"
                    onClick={() => setFieldValue("customer", null)}
                    name="delete"
                />
                <Icon
                    size="large"
                    className="cursor-pointer pt-3"
                    onClick={() => setExpanded(!expanded)}
                    name={expanded ? "expand_less" : "expand_more"}
                />
            </CustomerInfoStyled>
            {editGuestModal && (
                <NewCustomerDrawer
                    open={editGuestModal}
                    onClose={() => setEditGuestModal(false)}
                    onSave={onCloseCustomerInfo}
                    contact={customer}
                />
            )}
        </Fragment>
    );
};

export default CustomerInfo;

const CustomerInfoStyled = styled.div`
    .d-view-label-status {
        border-radius: 0 !important;
    }
`;
