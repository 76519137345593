import { Progress, TabBar } from "d-react-components";
import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import { useGetAdminDetailLazyQuery } from "../../../api/hooks";
import { UserDetailContext } from "../../../context/user";
import Messages from "../../../languages/Messages";
import UserAuthenticationSetting from "./UserAuthenticationSetting";
import UserDetailBranches from "./UserDetailBranches";
import UserDetailDashboard from "./UserDetailDashboard";
import UserDetailInformation from "./UserDetailInformation";
import UserDetailSetting from "./UserDetailSetting";
import UserDetailSpecialAccess from "./UserDetailSpecialAccess";
import UserDetailWorkingSchedule from "./UserDetailWorkingSchedule";
import UserPassword from "./UserPassword";
import UserSummaryView from "../share/UserSummaryView";
import ActionMenu from "../../../common/ActionButton";
import Path from "../../Path";
import UserDetailCommission from "./UserDetailCommission";

const TABS = [
    {
        id: "dashboard",
        label: Messages.dashboard,
        component: <UserDetailDashboard />,
    },
    {
        id: "personalInfo",
        label: Messages.personalInfo,
        component: <UserDetailInformation />,
    },
    {
        id: "branchWarehouse",
        label: Messages.branchWarehouse,
        component: <UserDetailBranches />,
    },
    {
        id: "defaultWorkingSchedule",
        label: Messages.defaultWorkingSchedule,
        component: <UserDetailWorkingSchedule />,
    },
    {
        id: "settings",
        label: Messages.settings,
        component: <UserDetailSetting />,
    },
    {
        id: "commission",
        label: Messages.commission,
        component: <UserDetailCommission />,
    },
    {
        id: "authentication",
        label: Messages.authentication,
        component: <UserAuthenticationSetting />,
    },
    {
        id: "specialAccess",
        label: Messages.specialAccess,
        component: <UserDetailSpecialAccess />,
    },
];

const UserDetail = () => {
    const navigate = useNavigate();
    const { userId } = useParams<any>();
    const [showUpdatePassword, setShowUpdatePassword] = useState(false);
    const [tab, setTab] = useState(TABS[0]);
    const [getAdminDetail, { data, loading, refetch }] =
        useGetAdminDetailLazyQuery();

    useEffect(() => {
        loadAdminDetail();
    }, []);

    const loadAdminDetail = () => {
        Progress.show(
            {
                method: getAdminDetail,
                params: [
                    {
                        variables: {
                            id: userId as string,
                        },
                    },
                ],
            },
            (res: any) => {}
        );
    };

    const adminDetail = data?.detailAdminUserForAdmin?.data;

    const onClickAction = (item: any) => {
        if (item.id === "updatePassword") {
            setShowUpdatePassword(true);
        }
        if (item.id === "duplicate") {
            navigate(
                generatePath(Path.USER_CREATE, {
                    duplicateId: userId,
                })
            );
        }
    };

    return (
        <UserDetailContext.Provider
            value={{
                user: data?.detailAdminUserForAdmin?.data as any,
                loadAdminDetail: refetch,
            }}
        >
            <div className="bg-white flex-column-container bg-trans">
                <div className="border-b p-3 d-flex justify-content-between align-items-center">
                    <h5 className="text-primary">{`${adminDetail?.firstName} ${adminDetail?.lastName}`}</h5>
                    <div>
                        <ActionMenu
                            dataSource={[
                                {
                                    label: Messages.updatePassword,
                                    id: "updatePassword",
                                },
                                {
                                    label: Messages.duplicate,
                                    id: "duplicate",
                                },
                            ]}
                            onClick={onClickAction}
                        />
                    </div>
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <UserSummaryView
                                user={
                                    data?.detailAdminUserForAdmin?.data as any
                                }
                            />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />

                            {tab.component}
                        </div>
                    </div>
                </div>
            </div>
            {showUpdatePassword && (
                <UserPassword
                    open={showUpdatePassword}
                    onClose={() => setShowUpdatePassword(false)}
                />
            )}
        </UserDetailContext.Provider>
    );
};

export default UserDetail;
