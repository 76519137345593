import { Notifications, Progress } from "d-react-components";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useUpdateMaterialPurchaseMutation } from "../../../api/hooks";
import Messages from "../../../languages/Messages";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import ContactDrawer from "../share/ContactDrawer";
import VendorAPI from "../../../api/queries/vendor";

const OrderVendorContact = () => {
    const { order, loadOrderDetail } = useContext(
        MaterialPurchaseDetailContext
    );
    const [showAddModal, setShowAddModal] = useState(false);
    const [vendorContact, setVendorContact] = useState<any>();
    const { contact } = order ?? {};
    const [updateOrder] = useUpdateMaterialPurchaseMutation();

    const onSaveOrder = (contactValue: any) => {
        Progress.show(
            {
                method: updateOrder,
                params: [
                    {
                        variables: {
                            payload: {
                                contact: contactValue,
                            },
                            id: order?.id,
                        },
                    },
                ],
            },
            (resp: any) => {
                Notifications.showSuccess(Messages.updateSuccessfully);
                setShowAddModal(false);
                loadOrderDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    useEffect(() => {
        if (isEmpty(contact) && order?.vendor) {
            VendorAPI.detail(order?.vendor.id).then((resp) => {
                setVendorContact({
                    firstName: resp.representativesFirstName,
                    lastName: resp.representativesLastName,
                    phone:
                        resp.representativesPrimaryPhone ||
                        resp.representativesAlternatePhone,
                    email: resp.representativesEmail,
                });
            });
        }
    }, [order]);

    const contactInfo = isEmpty(contact) ? vendorContact : contact;

    return (
        <>
            <div className="border p-3 mb-3">
                <div className="flex items-center mb-2">
                    <h5 className="font-semibold flex-1">
                        {Messages.vendorContact}
                    </h5>
                    <a onClick={() => setShowAddModal(true)}>{Messages.edit}</a>
                </div>
                <div className="flex flex-col">
                    <span>
                        {contactInfo?.firstName} {contactInfo?.lastName}
                    </span>
                    <span>{contactInfo?.email}</span>
                    <span>{contactInfo?.phone}</span>
                </div>
            </div>

            {showAddModal && (
                <ContactDrawer
                    open={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    onSave={(value: any) => {
                        onSaveOrder(value);
                    }}
                    contact={contactInfo}
                />
            )}
        </>
    );
};

export default OrderVendorContact;
