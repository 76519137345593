import {
    Button,
    DateInput,
    InputText,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, toUpper } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import VoucherAPI from "../../../api/queries/voucher";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    VOUCHER_ISSUANCE_TYPE,
    VOUCHER_ISSUANCE_TYPES,
    VOUCHER_TYPE,
    VOUCHER_TYPES,
    VOUCHER_USAGE_TYPES,
} from "../../../constant/voucher";
import { VoucherCreateContext } from "../../../context/voucher";
import { VoucherSchema } from "../../../formschema/voucher";
import { mapVoucherToServer } from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import CustomerSolidSelect from "../../customer/share/CustomerSolidSelect";
import VoucherCreateAutoGenFields, {
    InputTextNumberForm,
} from "./VoucherCreateAutoGenFields";
import VoucherCreateService from "./VoucherCreateService";

const VoucherCreate = () => {
    const navigate = useNavigate();

    const voucherForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VoucherSchema,
        onSubmit: (values: any) => {
            const input = mapVoucherToServer(values);
            onCreateVoucher(input);
        },
    });

    const formValues = voucherForm?.values;
    const formErrors = voucherForm?.errors;

    useEffect(() => {
        forEach(Object.keys(voucherForm.errors), (key) =>
            Notifications.showError(`${voucherForm.errors[key]}`)
        );
    }, [voucherForm.errors]);

    const onCreateVoucher = (payload: any) => {
        Progress.show(
            {
                method: VoucherAPI.create,
                params: [payload],
            },
            (res: any) => {
                navigate(
                    generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: res?.id,
                    })
                );
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const classNameInput = "mt-3 col-sm-12";
    return (
        <VoucherCreateContext.Provider value={{ voucherForm }}>
            <div className="flex-column-container bg-white w-100 pb-4">
                <div className="bg-white p-3 d-flex justify-content-between align-items-center border-bottom">
                    <h5 className="text-primary">{Messages.newVoucher}</h5>
                    <Button onClick={() => voucherForm.handleSubmit()}>
                        {Messages.save}
                    </Button>
                </div>
                <div className="row m-0">
                    <InputSelectForm
                        dataSource={VOUCHER_TYPES}
                        keyData="type"
                        form={voucherForm}
                        className={classNameInput}
                    />
                    <InputSelectForm
                        dataSource={VOUCHER_ISSUANCE_TYPES}
                        keyData="typeOfIssuance"
                        form={voucherForm}
                        className={classNameInput}
                    />
                    <VoucherCreateAutoGenFields />
                    {formValues?.type === VOUCHER_TYPE.CASH_VOUCHER && (
                        <InputTextNumberForm
                            form={voucherForm}
                            keyData="cashValue"
                            className="mt-3 col-sm-6"
                        />
                    )}
                    {formValues?.type === VOUCHER_TYPE.CASH_VOUCHER && (
                        <InputSelectForm
                            dataSource={VOUCHER_USAGE_TYPES}
                            form={voucherForm}
                            keyData="usageType"
                            className="mt-3 col-sm-6"
                        />
                    )}
                    {formValues?.type === VOUCHER_TYPE.CASH_VOUCHER && (
                        <InputTextNumberForm
                            form={voucherForm}
                            keyData="usageLimit"
                            className={classNameInput}
                        />
                    )}
                    <InputText
                        label={Messages.code}
                        hidden={
                            formValues.typeOfIssuance !==
                            VOUCHER_ISSUANCE_TYPE.MANUAL
                        }
                        className={classNameInput}
                        value={formValues?.code}
                        onChange={(event) => {
                            const value = toUpper(event.target.value ?? "");
                            voucherForm.setFieldValue("code", value);
                        }}
                    />
                    <DateInput
                        label={Messages.effectiveDate}
                        onChange={(value) => {
                            voucherForm.setFieldValue("effectiveDate", value);
                        }}
                        value={
                            formValues?.effectiveDate
                                ? moment(formValues?.effectiveDate)
                                : (null as any)
                        }
                        format="DD/MM/YYYY"
                        error={formErrors?.effectiveDate as any}
                        className="col-6 mt-3"
                    />
                    <div className="col-6 mt-3">
                        <DateInput
                            label={Messages.expiryDate}
                            onChange={(value) => {
                                voucherForm.setFieldValue("expiryDate", value);
                            }}
                            value={
                                formValues?.expiryDate
                                    ? moment(formValues?.expiryDate)
                                    : (null as any)
                            }
                            format="DD/MM/YYYY"
                            error={formErrors?.expiryDate as any}
                            className="w-100"
                        />
                        <small>{`*${Messages.leaveEmptyForNoExp}`}</small>
                    </div>
                    {/* <ProductSolidSelect
                        value={formValues?.products}
                        onChange={(value) => {
                            voucherForm.setFieldValue("products", value);
                        }}
                        error={voucherForm?.errors?.products}
                        multiple
                        className={classNameInput}
                    /> */}
                    <VoucherCreateService />
                    <CustomerSolidSelect
                        value={formValues?.customer}
                        onChange={(value) =>
                            voucherForm?.setFieldValue("customer", value)
                        }
                        error={voucherForm?.errors?.customer}
                        className={classNameInput}
                    />
                    <InputTextForm
                        form={voucherForm}
                        keyData="remark"
                        multiple
                        className={classNameInput}
                    />
                </div>
            </div>
        </VoucherCreateContext.Provider>
    );
};

export default VoucherCreate;
