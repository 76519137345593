import { StringUtils } from "d-react-components";
import { filter, find, forEach, map, omit, pick, reduce } from "lodash";
import {
    AdminUserBasic,
    PriceSetDto,
    PriceSetType,
    SalesPersonType,
    TypeOfProduct,
} from "../api/hooks";
import { LANGUAGES, LANGUAGE_DEFAULT } from "../constant/app";
import {
    PriceSetTypeFe,
    PRODUCT_TYPE,
    SPECIAL_PRICE_SETS,
} from "../constant/product";
import { IDoctorOrder } from "./doctor-order";
import { IServiceConfiguration } from "./service";

export interface IProductCategory {
    id: string;
    updatedAt: string;
    createdAt: string;
    name: string;
    categoryNo: string;
    status: string;
    description: string;
}
export interface IProductTranslation {
    language: string;
    name: string;
    description: string;
}

export interface IProductGroupItem extends IProduct {
    quantity: number;
    salePrice: number;
    newSalePrice: number;
    isDynamicPrice?: number;
}

export interface IProductGallery {
    id: string;
    fileUrl: string;
}

export interface ISalesPerson {
    salesPerson: any;
    type: SalesPersonType;
    isPrimary?: boolean;
}

export interface IProduct {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: AdminUserBasic;
    name: string;
    productNo: string;
    status: string;
    sku: string;
    category: IProductCategory;
    isVisibility: boolean;
    weight: string;
    regularPrice: number;
    salePrice: number;
    isSellOutOfStock: boolean;
    description: string;
    thumbnail: string;
    gallery: string[];
    productGalleries: IProductGallery[];
    typeOfProduct: string;
    service: IServiceConfiguration;
    unit: string;
    premiumService: {
        spending: number;
        premiumServiceType: string;
        product: IProduct[];
    };
    translations: IProductTranslation;
    groups: IProductGroupItem[];
    specialGroups: IProductGroupItem[];
    bundleProducts: IProductGroupItem[];
    isMedicine: boolean;
    isOperationService?: boolean;
    isNonVAT?: boolean;

    isGenerateGiftVoucher: boolean;
    expiredAfterDay: number;
    expiredAfterMonth: number;
    expiredAfterYear: number;

    priceSet?: PriceSetDto[];

    originalPrice?: {
        regularPrice: any;
        salePrice: any;
    };
    price?: any;
    quantity?: number;
    salesPerson?: ISalesPerson[];
}

export interface IProductOrder extends IProduct {
    treatmentNo?: string;
    doctorOrderNo?: string;
    productOrderId?: string;
    doctorOrder?: IDoctorOrder;
}

export const isSpecialPriceSet = (groupType: string) => {
    return SPECIAL_PRICE_SETS.includes(groupType as any);
};

export const mapProductGroupFromServer = (product: any) => {
    const { typeOfProduct, groups, specialGroups, bundleProducts } = product;
    switch (typeOfProduct) {
        case PRODUCT_TYPE.GROUP_PRODUCT:
            return map(groups, (item) => ({
                product: item.id,
                quantity: item.quantity,
                salePrice: item.salePrice,
                voucherType: item.voucherType,
                usageType: item.usageType,
                priceSet: item.priceSet,
            }));
        case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
            return map(specialGroups, (item) => ({
                product: item.id,
                quantity: item.quantity,
                salePrice: item.newSalePrice,
                isDynamicPrice: item.isDynamicPrice,
                voucherType: item.voucherType,
                usageType: item.usageType,
                priceSet: item.priceSet,
            }));
        case PRODUCT_TYPE.BUNDLE_PRODUCT:
            return map(bundleProducts, (item) => ({
                product: item.id,
                quantity: item.quantity,
                salePrice: item.salePrice,
                regularPrice: item.regularPrice,
                priceSet: item.priceSet,
            }));

        default:
            return [];
    }
};

export const mapProductConfigFromServer = (product: any) => {
    const {
        typeOfProduct,
        service,
        groups,
        isGenerateGiftVoucher,
        expiredAfterDay,
        expiredAfterMonth,
        expiredAfterYear,
    } = product;
    const config: any = {
        typeOfProduct,
        service,

        isGenerateGiftVoucher,
        expiredAfterDay,
        expiredAfterMonth,
        expiredAfterYear,
    };
    if (typeOfProduct === PRODUCT_TYPE.GROUP_PRODUCT) {
        config.groups = map(groups, (item) => ({
            ...item.product,
            quantity: item.quantity,
            salePrice: item.salePrice,
            regularPrice: item.regularPrice,
            voucherType: item.voucherType,
            usageType: item.usageType,
            priceSet: item.priceSet,
        }));
    }
    if (typeOfProduct === PRODUCT_TYPE.SPECIAL_GROUP_SERVICE) {
        config.specialGroups = map(groups, (item) => ({
            ...item.product,
            quantity: item.quantity,
            regularPrice: item.salePrice,
            newSalePrice: item.salePrice,
            salePrice: item.salePrice,
            isDynamicPrice: item.isDynamicPrice,
            voucherType: item.voucherType,
            usageType: item.usageType,
            priceSet: item.priceSet,
        }));
    }
    if (typeOfProduct === PRODUCT_TYPE.BUNDLE_PRODUCT) {
        config.bundleProducts = map(groups, (item) => ({
            ...item.product,
            quantity: item.quantity,
            regularPrice: item.regularPrice,
            salePrice: item.salePrice,
            priceSet: item.priceSet,
        }));
    }
    return config;
};

export const mapProductFromServer = (product: any) => {
    const { translations, gallery } = product ?? {};
    const productTranslator: any = {};
    forEach(translations, (translate) => {
        productTranslator[translate?.language] = {
            name: translate?.name,
            description: translate?.description,
        };
    });

    const productGalleries = map(gallery, (item) => ({
        id: item,
        fileUrl: item,
    }));

    const productConfig = mapProductConfigFromServer(product);

    return {
        ...product,
        ...productTranslator,
        ...productConfig,
        productGalleries,
    };
};

export const mapProductCategoryToServer = (input: any) => {
    const { name, status, description, commissionType } = input;
    return {
        name,
        status,
        description,
        commissionType,
    };
};

export const mapProductGroupToServer = (product: any) => {
    const { typeOfProduct, groups, specialGroups, bundleProducts } = product;
    switch (typeOfProduct) {
        case PRODUCT_TYPE.GROUP_PRODUCT:
            return map(groups, (item) => ({
                product: item.id,
                quantity: item.quantity,
                salePrice: item.salePrice,
                voucherType: item.voucherType,
                usageType: item.usageType,
            }));
        case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
            return map(specialGroups, (item) => {
                let salePrice = item.newSalePrice;

                if (item.isDynamicPrice) {
                    salePrice =
                        getPriceDynamicItemInSpecialGroupProduct(
                            item.id,
                            product
                        ) / item.quantity;
                }

                return {
                    product: item.id,
                    quantity: item.quantity,
                    salePrice,
                    isDynamicPrice: item.isDynamicPrice,
                    voucherType: item.voucherType,
                    usageType: item.usageType,
                };
            });
        case PRODUCT_TYPE.BUNDLE_PRODUCT:
            return map(bundleProducts, (item) => ({
                product: item.id,
                quantity: item.quantity,
                salePrice: item.salePrice,
                regularPrice: item.regularPrice,
            }));

        default:
            return [];
    }
};

const renormalizeProductPriceToServer = (
    item: any,
    groupType: string,
    options?: any
) => {
    const { salePrice, regularPrice, originalPrice, typeOfProduct, priceSet } =
        item;

    const { salePrice: newSalePrice, hasRegularPrice } = options || {};

    if (groupType === PriceSetTypeFe.Thai) {
        const res = {
            priceSet: priceSet
                ? map(priceSet, (item) => omit(item, ["__typename"]))
                : map(SPECIAL_PRICE_SETS, (type) => ({
                      priceSetType: type,
                      regularPrice,
                      salePrice,
                  })),
            salePrice: parseFloat(newSalePrice || salePrice),
        };
        if (hasRegularPrice) {
            Object.assign(res, { regularPrice: parseFloat(regularPrice) });
        }
        return res;
    }
    if (isSpecialPriceSet(groupType)) {
        const res: any = {
            salePrice: parseFloat(originalPrice?.salePrice),
            regularPrice: parseFloat(originalPrice?.regularPrice),
        };

        const mappedPriceSet = map(SPECIAL_PRICE_SETS, (type) => {
            if (groupType === type) {
                return {
                    priceSetType: type,
                    regularPrice: parseFloat(regularPrice),
                    salePrice: parseFloat(newSalePrice || salePrice),
                };
            }
            const foundSet = find(priceSet, (i) => i?.priceSetType === type);
            return {
                priceSetType: type,
                regularPrice: parseFloat(
                    foundSet?.regularPrice ?? originalPrice?.regularPrice
                ),
                salePrice: parseFloat(
                    foundSet?.salePrice ?? originalPrice?.salePrice
                ),
            };
        });
        res.priceSet = mappedPriceSet;

        return res;
    }

    return {};
};

export const mapProductGroupPriceToServer = (product: any) => {
    const { typeOfProduct, groups, specialGroups, bundleProducts, groupType } =
        product;
    switch (typeOfProduct) {
        case PRODUCT_TYPE.GROUP_PRODUCT:
            return map(groups, (item) => {
                const normalizedPrice = renormalizeProductPriceToServer(
                    item,
                    groupType
                );
                return {
                    product: item.id,
                    quantity: item.quantity,
                    voucherType: item.voucherType,
                    usageType: item.usageType,
                    ...normalizedPrice,
                };
            });
        case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
            return map(specialGroups, (item) => {
                let salePrice = item.newSalePrice;

                if (item.isDynamicPrice) {
                    salePrice =
                        getPriceDynamicItemInSpecialGroupProduct(
                            item.id,
                            product
                        ) / item.quantity;
                }
                const normalizedPrice = renormalizeProductPriceToServer(
                    item,
                    groupType,
                    {
                        salePrice,
                    }
                );
                return {
                    product: item.id,
                    quantity: item.quantity,
                    isDynamicPrice: item.isDynamicPrice,
                    voucherType: item.voucherType,
                    usageType: item.usageType,
                    ...normalizedPrice,
                };
            });
        case PRODUCT_TYPE.BUNDLE_PRODUCT:
            return map(bundleProducts, (item) => {
                const normalizedPrice = renormalizeProductPriceToServer(
                    item,
                    groupType,
                    {
                        hasRegularPrice: true,
                    }
                );
                return {
                    product: item.id,
                    quantity: item.quantity,
                    ...normalizedPrice,
                };
            });

        default:
            return [];
    }
};

export const mapProductPriceToServer = (input: any) => {
    const { regularPrice, salePrice } = input;
    return {
        regularPrice,
        salePrice,
    };
};

export const mapProductGeneralInfoToServer = (input: any) => {
    const { sku, category, visibility, weight, isSellOutOfStock } = input;

    const translations = map(LANGUAGES, (item) => ({
        language: item.id,
        name: input[item?.id]?.name || input?.[LANGUAGE_DEFAULT]?.name,
        description:
            input[item?.id]?.description ||
            input?.[LANGUAGE_DEFAULT]?.description,
    }));
    return {
        sku,
        category: category?.id,
        isVisibility: visibility,
        weight,
        isSellOutOfStock,
        translations,
    };
};

export const mapProductConfigToServer = (input: any) => {
    const {
        typeOfProduct,
        service,
        premiumService,
        isMedicine,
        isOperationService = false,
        isNonVAT = false,
        isGenerateGiftVoucher,
        expiredAfterYear = null,
        expiredAfterMonth,
        expiredAfterDay,
    } = input;
    const groups = mapProductGroupToServer(input);

    return {
        isMedicine,
        isOperationService,
        isNonVAT,
        groups,
        ...(typeOfProduct === TypeOfProduct.PremiumService
            ? {
                  premiumService: {
                      product: map(premiumService?.product, (item) => item.id),
                      spending: input?.salePrice,
                      premiumServiceType: premiumService?.premiumServiceType,
                  },
              }
            : {}),
        service: service?.id,
        isGenerateGiftVoucher,
        expiredAfterYear,
        expiredAfterMonth,
        expiredAfterDay,
    };
};

export const mapUpdateProductPriceToServer = (input: any) => {
    const {
        salePrice,
        regularPrice,
        originalPrice,
        groups,
        specialGroups,
        bundleProducts,
        groupType,
        typeOfProduct,
        premiumService,
        service,
        priceSet,
    } = input;
    const mappedGroups = mapProductGroupPriceToServer(input);
    const mappedPrices = {
        regularPrice: parseFloat(regularPrice),
        salePrice: parseFloat(salePrice),
    };
    const mappedPremiumService = premiumService
        ? {
              premiumService: {
                  product: map(premiumService?.product, (item) => item.id),
                  spending: parseFloat(
                      input?.originalPrice?.salePrice ?? salePrice
                  ),
                  premiumServiceType: premiumService?.premiumServiceType,
              },
          }
        : {};

    if (groupType === PriceSetTypeFe.Thai) {
        return {
            ...mappedPrices,
            groups: mappedGroups,
            ...(typeOfProduct === PRODUCT_TYPE.SERVICE_PRODUCT
                ? { service: service?.id }
                : {}),
            ...(typeOfProduct === PRODUCT_TYPE.PREMIUM_SERVICE
                ? mappedPremiumService
                : {}),
        };
    }
    if (isSpecialPriceSet(groupType)) {
        return {
            priceSet: map(SPECIAL_PRICE_SETS, (type) => {
                if (type === groupType) {
                    return {
                        priceSetType: type,
                        ...mappedPrices,
                    };
                }
                const foundSet = find(
                    priceSet,
                    (i) => i?.priceSetType === type
                );
                return {
                    priceSetType: type,
                    regularPrice: parseFloat(
                        foundSet?.regularPrice ?? originalPrice?.regularPrice
                    ),
                    salePrice: parseFloat(
                        foundSet?.salePrice ?? originalPrice?.salePrice
                    ),
                };
            }),
            groups: mappedGroups,
        };
    }
    return {};
};

export const mapProductToServer = (input: any) => {
    const { gallery, typeOfProduct } = input;

    const generalInfo = mapProductGeneralInfoToServer(input);

    const productConfig = mapProductConfigToServer(input);

    const productPrice = mapProductPriceToServer(input);

    return {
        gallery: map(gallery, (item) => item.fileUrl),
        typeOfProduct,
        ...generalInfo,
        ...productConfig,
        ...productPrice,
    };
};

export const getPriceDynamicItemInSpecialGroupProduct = (
    dynamicProId: string,
    product: IProduct,
    options?: any
) => {
    const { prefixSalePrice } = options || {};
    const total = prefixSalePrice || product?.salePrice;
    const specialGroup = product?.specialGroups ?? [];

    const groupIgnoreDynamic = filter(
        specialGroup,
        (item) => item.id !== dynamicProId
    );

    const totalIgnoreDynamic = reduce(
        groupIgnoreDynamic,
        (sum, item) => sum + item.quantity * item.newSalePrice,
        0
    );

    return total - totalIgnoreDynamic;
};

export const getProductPrice = (
    key: "salePrice" | "regularPrice",
    product: IProduct,
    applyFor?: PriceSetType,
    option?: { addPrefix?: boolean }
): any => {
    const { addPrefix } = option || {};
    const { salePrice, regularPrice, priceSet } = product;
    const foundSet = find(priceSet, (i) => i?.priceSetType === applyFor);
    const priceObj = {
        salePrice: foundSet?.salePrice ?? salePrice,
        regularPrice: foundSet?.regularPrice ?? regularPrice,
    };
    let res: any = priceObj?.[key] ?? 0;
    if (addPrefix) {
        res = StringUtils.moneyThaiFormat(res);
    }
    return res;
};

export const mapProductFilterToQueryParams = (values: any) => {
    return values;
};
