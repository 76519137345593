import Messages from "../languages/Messages";

export enum TreatmentJobCostAssessmentOptionType {
    AUTO_CALCULATE = "AUTO_CALCULATE",
    MANUAL_IMPORT = "MANUAL_IMPORT",
    MANUAL_INPUT = "MANUAL_INPUT",
}

export const TREATMENT_STATUS = {
    SCHEDULED: "SCHEDULED",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
};

export const TREATMENT_STATUSES = [
    { id: "SCHEDULED", label: "scheduled", color: "#FAC256" },
    { id: "IN_PROGRESS", label: "inProcess", color: "#29B3BC" },
    { id: "COMPLETED", label: "completed", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
];

export const TREATMENT_JOB_COST_ASSESSMENT_OPTIONS = [
    {
        id: TreatmentJobCostAssessmentOptionType.AUTO_CALCULATE,
        label: Messages.autoCalculateJobCost,
    },
    {
        id: TreatmentJobCostAssessmentOptionType.MANUAL_INPUT,
        label: Messages.manualInputJobCost,
    },
    {
        id: TreatmentJobCostAssessmentOptionType.MANUAL_IMPORT,
        label: Messages.manualImportJobCost,
    },
];
