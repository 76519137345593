import {
    Button,
    InputText,
    Notifications,
    Progress,
    TimeUtils,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import {
    MaterialPurchaseOrderStatus,
    useApprovalMaterialPurchaseMutation,
    useRejectMaterialPurchaseMutation,
} from "../../../api/hooks";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer, { CustomDrawerProps } from "../../../common/Drawer";
import Gallery from "../../../common/gallery/Gallery";
import InputTextForm from "../../../common/input/InputTextForm";
import UploadButton from "../../../common/upload/UploadButton";
import { MATERIAL_PURCHASE_ORDER_STATUSES } from "../../../constant/material";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import Messages from "../../../languages/Messages";

export const OrderApproval = () => {
    const { order, loadOrderDetail } = useContext(
        MaterialPurchaseDetailContext
    );
    const { confirm } = order ?? {};
    const orderStatus = useMemo(() => {
        return find(MATERIAL_PURCHASE_ORDER_STATUSES, {
            id: order?.materialPurchaseOrderStatus,
        });
    }, [order]);
    const [showApproveDrawer, setShowApproveDrawer] = useState(false);
    const [showCancelDrawer, setShowCancelDrawer] = useState(false);

    const handleApprovePO = () => {
        setShowApproveDrawer(true);
    };

    const handleRejectPO = () => {
        setShowCancelDrawer(true);
    };

    return (
        <div className="border p-3 mb-3">
            <div className="flex flex-row justify-between items-center ">
                <h5 className="font-semibold mb-2">{Messages.poApproval}</h5>
            </div>
            <div className="mb-2">
                <span className="text-bold">{Messages.status}: </span>
                <span style={{ color: orderStatus?.color }}>
                    {Messages[orderStatus?.label || ""]}
                </span>
            </div>
            {order?.materialPurchaseOrderStatus ===
                MaterialPurchaseOrderStatus.Pending && (
                <>
                    {Messages.poPendingApproval}
                    <div className="flex mt-2">
                        <Button onClick={() => handleApprovePO()}>
                            {Messages.approve}
                        </Button>
                        <Button
                            onClick={() => handleRejectPO()}
                            variant="outline"
                            className="ml-2"
                        >
                            {Messages.reject}
                        </Button>
                    </div>
                </>
            )}
            {confirm && (
                <div className="flex flex-col mt-2">
                    <div className="mb-2">
                        <span className="text-bold">
                            {Messages.approvedBy}:{" "}
                        </span>
                        <UserAvatarName
                            user={confirm.createByAdmin}
                            size="xx-small"
                            placeholder={Messages.system}
                            showInfo={{ showEmployeeId: false, showOPD: false }}
                        />
                    </div>
                    <div className="mb-2">
                        <span className="text-bold">
                            {Messages.approvedAt}:{" "}
                        </span>
                        <span>{TimeUtils.convertMiliToDate(confirm.at)} </span>
                    </div>
                    {confirm?.attachment?.length > 0 && (
                        <div className="flex flex-col mb-2">
                            <span className="text-bold">
                                {Messages.attachments}:{" "}
                            </span>
                            <Gallery
                                gallery={confirm?.attachment}
                                itemClassName="w-32 h-32 object-cover mr-2"
                            />
                        </div>
                    )}
                    <div className="">
                        <span className="text-bold">
                            {Messages.confirmationRemark}:{" "}
                        </span>
                        <div className="mt-1 bg-[#e5ecec] py-3 px-3">
                            {confirm.remark}
                        </div>
                    </div>
                </div>
            )}
            {showApproveDrawer && (
                <ApproveCancelRequestDrawer
                    open={showApproveDrawer}
                    onClose={() => setShowApproveDrawer(false)}
                    type="approve"
                />
            )}
            {showCancelDrawer && (
                <ApproveCancelRequestDrawer
                    open={showCancelDrawer}
                    onClose={() => setShowCancelDrawer(false)}
                    type="cancel"
                />
            )}
        </div>
    );
};

const ApproveCancelRequestDrawer = ({
    open,
    onClose,
    type,
}: CustomDrawerProps) => {
    const [approvePO] = useApprovalMaterialPurchaseMutation();
    const [rejectPO] = useRejectMaterialPurchaseMutation();
    const { order, loadOrderDetail } = useContext(
        MaterialPurchaseDetailContext
    );

    const approveForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            // attachment: Yup.mixed().required(Messages.fieldRequired),
            remark: Yup.string().required(Messages.fieldRequired),
        }),
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method: type === "approve" ? approvePO : rejectPO,
                    params: [
                        {
                            variables: {
                                id: order?.id,
                                remark: values?.remark ?? "",
                                attachment:
                                    values?.attachment?.map(
                                        (item: any) => item.fileUrl
                                    ) ?? [],
                            },
                        },
                    ],
                },
                (res: any) => {
                    Notifications.showSuccess(
                        type === "approve"
                            ? Messages.approveSuccessfully
                            : Messages.cancelSuccess
                    );
                    onClose();
                    loadOrderDetail();
                }
            );
        },
    });
    const classNameInput = "mb-3";
    const { handleSubmit, setFieldValue, errors, values } = approveForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={type === "approve" ? Messages.approvePO : Messages.cancelPO}
            onSave={() => handleSubmit()}
        >
            <div className="p-4">
                <InputTextForm
                    form={approveForm}
                    keyData="remark"
                    className={classNameInput}
                    label={Messages.remark}
                    multiple
                />
                <div className="mb-3">
                    <label className="text-label">
                        <span>{Messages.attachment}</span>
                    </label>
                    <UploadButton
                        variant="square"
                        onChange={(file: any) =>
                            setFieldValue("attachment", file)
                        }
                        defaultFiles={values?.attachment ?? []}
                    />
                    <ViewTextError error={errors.attachment} />
                </div>
            </div>
        </Drawer>
    );
};

export default OrderApproval;
