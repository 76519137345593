import React, { Fragment, useContext } from "react";
import { find } from "lodash";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import { PURCHASE_TYPES } from "../../../constant/quotation";

const OrderPurchaseType = () => {
    const { order } = useContext(OrderDetailContext);
    const { purchaseType } = order || {};
    const purchaseTypeItem: any = find(PURCHASE_TYPES, { id: purchaseType });
    return (
        <div className="border p-3 mb-3">
            <h5 className="font-semibold mb-0">{Messages.purchaseType}</h5>
            <div className="mt-2">{Messages[purchaseTypeItem?.label]}</div>
        </div>
    );
};

export default OrderPurchaseType;
