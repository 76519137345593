import { Button, Header, Notifications, Progress } from "d-react-components";
import { Formik } from "formik";
import {
    filter,
    find,
    forEach,
    isEmpty,
    isUndefined,
    map,
    omit,
    pick,
} from "lodash";
import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
    ShippingType,
    useCreateMaterialPurchaseMutation,
    useCreateShippingForAdminMutation,
    useDetailMaterialPurchaseRequestLazyQuery,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import { PERMISSION } from "../../../constant/user";
import { AppStateContext } from "../../../context/app";
import { MaterialPurchaseContext as OrderCreateContext } from "../../../context/material";
import { useCheckCartValue } from "../../../hooks/order";
import { mapAddressToSer, mapBillingToSer } from "../../../interfaces/address";
import {
    IMaterial,
    IMaterialPurchaseRequest,
} from "../../../interfaces/material";
import {
    CartValueState,
    IOrderManualDiscount,
    OrderType,
} from "../../../interfaces/order";
import { IProduct } from "../../../interfaces/product";
import { IVendor } from "../../../interfaces/vendor";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import MaterialCreateHeader from "./MaterialPurchaseCreateHeader";
import MaterialPurchaseMaterialList from "./MaterialPurchaseMaterialList";
import MaterialPurchaseOrderCart from "./MaterialPurchaseOrderCart";

const OrderSchema = Yup.object().shape({
    vendor: Yup.mixed().required(Messages.fieldRequired),
    poRequest: Yup.mixed().required(Messages.fieldRequired),
});

const getManualDiscountPayload = (
    discounts: IOrderManualDiscount[],
    products: Array<any>
) => {
    if (isEmpty(discounts)) {
        return {};
    }
    const manualDiscount = map(
        filter(discounts, (item) => {
            const parseValue = parseFloat(item?.discountValue ?? "");
            return !Number.isNaN(parseValue) && parseValue > 0;
        }),
        (discountItem) => {
            const { discountValue, discountType, product } = discountItem || {};

            return {
                discountType,
                discountValue: parseFloat(discountValue || ""),
                material: product?.id ?? undefined,
            };
        }
    );
    return { discount: manualDiscount };
};

export const getOrderPayload = ({
    values,
    billing,
    productList,
    voucherCode,
    manualDiscount,
}: any) => {
    const { vendor, remark, poRequest, contact, isSameShippingAddress } =
        values;

    return {
        remark,
        materialPurchaseRequest: poRequest?.[0]?.id,
        isSameShippingAddress,
        contact,
        ...(voucherCode ? { voucherCode } : {}),
        ...(vendor?.[0]?.id ? { vendor: vendor?.[0]?.id } : {}),
        ...(billing
            ? {
                  billing: omit(mapBillingToSer(billing), ["isDefault"]),
              }
            : {}),
        ...(productList?.length
            ? {
                  materials: productList.map((product: any) => ({
                      material: product.id,
                      quantity: product.quantity,
                      price: Number(product.price),
                      remark: product.remark || "",
                  })),
              }
            : {}),
        ...getManualDiscountPayload(manualDiscount, productList),
    };
};

const MaterialPurchaseOrderCreate = () => {
    const navigate = useNavigate();
    const [getPurchaseRequest] = useDetailMaterialPurchaseRequestLazyQuery();
    const { me } = useContext(AppStateContext);
    const [createOrder] = useCreateMaterialPurchaseMutation();
    const [createShipping] = useCreateShippingForAdminMutation();
    const [vendor, setVendor] = useState<IVendor[]>();
    const [productList, setProductList] = useState<IMaterial[]>([]);
    const [productCustomList, setProductCustomList] = useState<IProduct[]>([]);
    const [cartValue, setCartValue] = useState<CartValueState>({
        total: 0,
        subtotal: 0,
    });
    const [manualDiscount, setManualDiscount] = useState<any>();
    const [materialRequest, setMaterialRequest] =
        useState<IMaterialPurchaseRequest>();

    const [shipping, setShipping] = useState<any[]>([]);
    const [billing, setBilling] = useState<any>();
    const [productFilters, setProductFilters] = useState({
        viewMode: "grid",
    });

    const { checkCartValue } = useCheckCartValue({
        setCartValue,
        billing,
        productList,
        productCustomList,
        manualDiscount,
        cartValue,
    });

    const onCreateOrder = async (values: any) => {
        if (!productList.length && !productCustomList.length) {
            return Notifications.showError(
                Messages.youHaveToChooseProductsFirst
            );
        }
        const orderPayload: any = getOrderPayload({
            values,
            billing,
            productList,
            // productCustomList,
            // voucherCode: cartValue?.voucher?.map((v) => v?.voucher?.code),
            manualDiscount,
        });
        return Progress.show(
            {
                method: createOrder,
                params: [
                    {
                        variables: {
                            payload: orderPayload,
                        },
                    },
                ],
            },
            (resp: any) => {
                Notifications.showSuccess(Messages.createOrderSuccessfully);
                const orderId = resp?.data?.data?.data?.id;
                if (shipping && shipping.length) {
                    forEach(shipping, async (shippingItem) => {
                        await createShipping({
                            variables: {
                                refId: orderId,
                                shippingType:
                                    ShippingType.MaterialPurchase as any,
                                payload: omit(
                                    mapAddressToSer(shippingItem),
                                    "isDefault"
                                ),
                            },
                        }).catch((err) => {
                            Notifications.showError(err);
                        });
                    });
                }
                navigate(generatePath(Path.MATERIAL_ORDER));
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    useEffect(() => {
        setProductList(
            (materialRequest?.materials?.map((item) => ({
                ...item,
                ...item.material,
            })) as any) ?? []
        );
    }, [materialRequest]);

    return (
        <div>
            <OrderCreateContext.Provider
                value={{
                    productFilters,
                    setProductFilters,
                    productList,
                    setProductList,
                    productCustomList,
                    setProductCustomList,
                    cartValue,
                    setCartValue,
                    checkCartValue,
                    vendor,
                    setVendor,
                    shipping,
                    setShipping,
                    billing,
                    setBilling,
                    manualDiscount,
                    setManualDiscount,
                    materialRequest,
                    setMaterialRequest,
                }}
            >
                <Formik
                    initialValues={{}}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={OrderSchema}
                    onSubmit={(values) => {
                        onCreateOrder(values);
                    }}
                >
                    {({ handleSubmit, values, errors }) => {
                        return (
                            <>
                                <Header
                                    className="sticky top-0"
                                    showCancel={false}
                                    title={Messages.newOrder}
                                    customRight={() => (
                                        <>
                                            <Button
                                                size="small"
                                                className="ml-2"
                                                variant="outline"
                                                onClick={() => navigate(-1)}
                                            >
                                                {Messages.back}
                                            </Button>
                                            <PermissibleRender
                                                permission={
                                                    PERMISSION.ORDER.CREATE
                                                }
                                            >
                                                <Button
                                                    size="small"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        handleSubmit()
                                                    }
                                                >
                                                    {Messages.save}
                                                </Button>
                                            </PermissibleRender>
                                        </>
                                    )}
                                />
                                <div className="grid grid-cols-3 gap-4 p-4">
                                    <div className="col-span-2">
                                        <MaterialCreateHeader />
                                        <MaterialPurchaseMaterialList />
                                    </div>
                                    <div className="col-span-1">
                                        <MaterialPurchaseOrderCart
                                            type={OrderType.ORDER}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    }}
                </Formik>
            </OrderCreateContext.Provider>
        </div>
    );
};

export default MaterialPurchaseOrderCreate;
