import { AgencyStatus, TypeOfAgency, TypeOfIdentity } from "../api/hooks";

export const AGENCY_STATUS = {
    ACTIVE: AgencyStatus.Active,
    INACTIVE: AgencyStatus.InActive,
};
export const AGENCY_STATUSES = [
    { id: AGENCY_STATUS.ACTIVE, label: "enabled", color: "#33B950" },
    { id: AGENCY_STATUS.INACTIVE, label: "disabled", color: "#C4C4C4" },
];

export const AGENCY_TYPES = [
    { id: TypeOfAgency.Arab, label: "arab" },
    { id: TypeOfAgency.Chinese, label: "chinese" },
    { id: TypeOfAgency.Concierge, label: "concierge" },
    { id: TypeOfAgency.Embassy, label: "embassy" },
    { id: TypeOfAgency.English, label: "english" },
    { id: TypeOfAgency.GuidedTour, label: "tourGuide" },
    { id: TypeOfAgency.Hotel, label: "hotel" },
    { id: TypeOfAgency.TaxiCooperative, label: "taxiCooperative" },
    { id: TypeOfAgency.Thai, label: "thai" },
    { id: TypeOfAgency.Other, label: "other" },

];

export const AGENCY_TYPE_OF_IDENTITY = {
    Business: TypeOfIdentity.Business,
    Individual: TypeOfIdentity.Individual,
};
export const AGENCY_TYPE_OF_IDENTITIES = [
    { id: TypeOfIdentity.Business, label: "business" },
    { id: TypeOfIdentity.Individual, label: "individual" },
];
