import { Progress } from "d-react-components";
import { filter, isEmpty, map, some, uniqBy } from "lodash";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import {
    useGetBranchListLazyQuery,
    useListCalendarForAdminLazyQuery,
} from "../../api/hooks";
import { CALENDAR_SUBJECT_TYPE } from "../../constant/calendar";
import {
    CalendarContext,
    ICalendarDayDrawerState,
    ICalendarFilterState,
} from "../../context/calendar";
import {
    CalendarAdditionalSubjectType,
    ICalendarEvent,
    mapListCalendarDataToEvents,
} from "../../interfaces/calendar";
import { JobType } from "../../interfaces/user";
import CalendarBody from "./content/CalendarBody";
import CalendarDayDrawer from "./content/CalendarDayDrawer";
import CalendarHeader from "./content/CalendarHeader";

export interface ICalendarProps {
    [key: string]: any;
}

const Calendar: React.FC<ICalendarProps> = ({ id }) => {
    const [calendarFilterState, setCalendarFilterState] =
        useState<ICalendarFilterState>({
            calendarSubject: CALENDAR_SUBJECT_TYPE[0],
            dateRange: moment(),
        });

    const [calendarDayDrawer, setCalendarDayDrawer] =
        useState<ICalendarDayDrawerState>();

    const [branchList, setBranchList] = useState<Array<any>>([]);
    const [calendarDataSource, setCalendarDataSource] = useState<
        ICalendarEvent[]
    >([]);
    const [calendarEvents, setCalendarEvents] = useState<ICalendarEvent[]>([]);

    const [getBranchList] = useGetBranchListLazyQuery();
    const [listCalendarForAdmin, { data, refetch, loading }] =
        useListCalendarForAdminLazyQuery();

    useEffect(() => {
        loadBranches();
    }, []);

    useDeepCompareEffect(() => {
        loadCalendarData();
    }, [calendarFilterState?.branch?.id, calendarFilterState?.dateRange]);

    useEffect(() => {
        if (
            !calendarFilterState?.calendarSubject?.id ||
            isEmpty(calendarDataSource)
        ) {
            return;
        }
        getCalendarEventsBySubjectType();
    }, [calendarFilterState?.calendarSubject?.id]);

    const loadBranches = () => {
        getBranchList({
            variables: {
                paginate: {
                    page: 1,
                    limit: 1000,
                },
            },
        }).then((res: any) => {
            const branchRes = res?.data?.listBranchForAdmin?.data ?? [];
            const filterBranch = map(branchRes, (item) => ({
                id: item.id,
                name: item.name,
            }));
            const uniqBranch = uniqBy(filterBranch, (item) => item.id);
            setBranchList(uniqBranch);
            updateFilterState({ branch: uniqBranch?.[0] });
        });
    };

    const loadCalendarData = () => {
        const { branch, dateRange } = calendarFilterState || {};
        if (isEmpty(branch) || !dateRange) {
            return;
        }
        Progress.show(
            {
                method: listCalendarForAdmin,
                params: [
                    {
                        variables: {
                            input: {
                                branch: [branch?.id],
                                dateRange: getDateRangeFilter(dateRange),
                            },
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {
                const listCalendars = res?.data?.data?.data ?? [];
                const mappedList = mapListCalendarDataToEvents(listCalendars);
                setCalendarDataSource(mappedList);
                getCalendarEventsBySubjectType(mappedList);
            }
        );
    };

    const getDateRangeFilter = (date: Moment) => {
        return [moment(date).startOf("M"), moment(date).endOf("M")];
    };

    const getCalendarEventsBySubjectType = (listData?: ICalendarEvent[]) => {
        const dataSource = listData || calendarDataSource;
        const filteredData = filter(dataSource, (item) => {
            const { resource } = item;
            const { adminUsers, equipments } = resource || {};
            switch (calendarFilterState?.calendarSubject?.id) {
                case JobType.DOCTOR:
                    return some(
                        adminUsers,
                        (user) => user.adminUser?.jobType === JobType.DOCTOR
                    );
                case JobType.THERAPIST:
                    return some(
                        adminUsers,
                        (user) => user.adminUser?.jobType === JobType.THERAPIST
                    );
                case JobType.NURSE:
                    return some(
                        adminUsers,
                        (user) => user.adminUser?.jobType === JobType.NURSE
                    );
                case CalendarAdditionalSubjectType.EQUIPMENT:
                    return !isEmpty(equipments);
                default:
                    return false;
            }
        });
        setCalendarEvents(filteredData);
    };

    const updateFilterState = (value: Partial<ICalendarFilterState>) => {
        setCalendarFilterState((prev) => ({ ...prev, ...value }));
    };

    return (
        <CalendarContext.Provider
            value={{
                branchList,
                calendarEvents,
                calendarDayDrawer,
                calendarFilterState,
                updateFilterState,
                fetchCalendarData: loadCalendarData,
                setCalendarDayDrawer,
            }}
        >
            <React.Fragment>
                <CalendarHeader />
                <CalendarBody />
                {calendarDayDrawer?.open && calendarDayDrawer?.day && (
                    <CalendarDayDrawer
                        open={calendarDayDrawer?.open}
                        onClose={() => setCalendarDayDrawer({ open: false })}
                        calendarProps={{
                            date: calendarDayDrawer?.day,
                            events: calendarEvents,
                            // events: calendarDayDrawer?.normalEvents,
                            // backgroundEvents:
                            //     calendarDayDrawer?.backgroundEvents,
                        }}
                    />
                )}
            </React.Fragment>
        </CalendarContext.Provider>
    );
};

export default Calendar;
