import { Switch } from "antd";
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Icon,
    InputText,
    ObjectUtils,
    Select,
    StringUtils,
    ViewLabelStatus,
} from "d-react-components";
import { map } from "lodash";
import { useState } from "react";
import {
    EQUIPMENT_CONDITIONS,
    EQUIPMENT_CONFIGURATION,
    EQUIPMENT_CONFIGURATIONS,
} from "../../../constant/equipment";
import Messages from "../../../languages/Messages";
import EquipmentQuickAddDrawer from "./EquipmentQuickAddDrawer";
import EquipmentSearchButton from "./EquipmentSearchButton";
import { useListEquipmentToAddAsChildForAdminLazyQuery } from "../../../api/hooks";

interface IEquipmentConfiguration {
    form: any;
    className?: any;
}

const EquipmentConfiguration = ({
    form,
    className,
}: IEquipmentConfiguration) => {
    const {
        configuration,
        equipmentsChildNew = [],
        equipmentsChild = [],
    } = form?.values ?? {};

    const [openQuickAddEquipment, setOpenQuickAddEquipment] = useState(false);
    const [getListEquipmentToAddChild] =
        useListEquipmentToAddAsChildForAdminLazyQuery();

    const columns: IColumnsProps = [
        {
            title: Messages.image,
            dataIndex: "gallery",
            render: (gallery, item) => {
                if (!item.isAddNew) {
                    return (
                        <img
                            className="image-square-xx-small"
                            src={gallery[0]}
                        />
                    );
                }
                return (
                    <img
                        className="image-square-xx-small"
                        src={gallery[0]?.fileUrl}
                    />
                );
            },
        },
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => {
                if (!item.isAddNew) {
                    return <div>{name}</div>;
                }
                return (
                    <InputText
                        value={name}
                        onChange={(event) =>
                            onUpdateConfigurable({
                                ...item,
                                name: event.target.value,
                            })
                        }
                    />
                );
            },
        },
        {
            title: Messages.serialNo,
            dataIndex: "serialNo",
            render: (serialNo, item) => {
                if (!item.isAddNew) {
                    return <div>{serialNo}</div>;
                }
                return (
                    <InputText
                        value={serialNo}
                        onChange={(event) =>
                            onUpdateConfigurable({
                                ...item,
                                serialNo: event.target.value,
                            })
                        }
                    />
                );
            },
        },
        {
            title: Messages.condition,
            dataIndex: "condition",
            render: (condition, item) => {
                if (!item.isAddNew) {
                    return (
                        <ViewLabelStatus
                            status={condition}
                            listStatus={EQUIPMENT_CONDITIONS}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    );
                }
                return (
                    <Select
                        dataSource={EQUIPMENT_CONDITIONS}
                        value={condition}
                        onChange={(condition) =>
                            onUpdateConfigurable({
                                ...item,
                                condition,
                            })
                        }
                        getLabel={(item) => Messages[item.label]}
                    />
                );
            },
        },
        {
            title: Messages.weight,
            dataIndex: "weight",
            render: (weight, item) => {
                if (!item.isAddNew) {
                    return <div>{weight}</div>;
                }
                return (
                    <InputText
                        value={weight}
                        onChange={(event) => {
                            const { value } = event.target;
                            onUpdateConfigurable({
                                ...item,
                                weight: value,
                            });
                        }}
                    />
                );
            },
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (item, data) => (
                <Icon
                    className="text-primary cursor-pointer"
                    name="remove_circle_outline"
                    onClick={() => handleRemoveConfigurable(data)}
                />
            ),
        },
    ];

    const handleRemoveConfigurable = (config: any) => {
        const result = ObjectUtils.removeArrayById(
            equipmentsChildNew,
            config?.id
        );
        form.setFieldValue("children", result);
    };

    const onAddConfigurable = (equipments: any[]) => {
        form.setFieldValue("equipmentsChild", equipments);
    };

    const onAddNewConfigurable = (newConfig: any) => {
        const newConfigurable = {
            id: StringUtils.getUniqueID(),
            weight: 0,
            isAddNew: true,
            ...newConfig,
        };
        form.setFieldValue("equipmentsChildNew", [
            newConfigurable,
            ...equipmentsChildNew,
        ]);
    };

    const onUpdateConfigurable = (newConfig: any) => {
        const result = ObjectUtils.updateArrayById(
            equipmentsChildNew,
            newConfig
        );
        form.setFieldValue("equipmentsChildNew", result);
    };

    const renderEquipmentConfigurable = () => {
        return (
            <div className="mt-3">
                <div className="flex-row-between-center">
                    <div className="label">{Messages.configuration}</div>
                    <div className="d-flex flex-row">
                        <EquipmentSearchButton
                            onSave={onAddConfigurable}
                            buttonProps={{
                                variant: "outline",
                                iconName: "",
                                className: "mr-2",
                            }}
                            defaultValue={equipmentsChild ?? []}
                            customSource={(search: any, pagingData: any) =>
                                getListEquipmentToAddChild({
                                    variables: {
                                        paginate: {
                                            page: pagingData?.pageIndex as any,
                                            limit: pagingData?.pageSize,
                                            search,
                                            sort: {
                                                createdAt: -1,
                                            },
                                        },
                                    },
                                    fetchPolicy: "no-cache",
                                })
                            }
                        >
                            {Messages.add}
                        </EquipmentSearchButton>
                        <Button
                            onClick={() => setOpenQuickAddEquipment(true)}
                            className=""
                        >
                            {Messages.create}
                        </Button>
                    </div>
                </div>
                <AwesomeTableComponent
                    columns={columns}
                    dataSource={[...equipmentsChildNew, ...equipmentsChild]}
                    className="mt-3"
                />
                {openQuickAddEquipment && (
                    <EquipmentQuickAddDrawer
                        open={openQuickAddEquipment}
                        onClose={() => setOpenQuickAddEquipment(false)}
                        onSave={onAddNewConfigurable}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={`d-flex flex-column ${className}`}>
            {map(EQUIPMENT_CONFIGURATIONS, (type) => (
                <div className="d-flex align-items-center mt-3">
                    <Switch
                        checked={configuration === type?.id}
                        onChange={() =>
                            form.setFieldValue("configuration", type?.id)
                        }
                    />
                    <text className="ml-3">
                        {(Messages as any)[type.label]}
                    </text>
                </div>
            ))}
            {configuration === EQUIPMENT_CONFIGURATION.CONFIGURABLE &&
                renderEquipmentConfigurable()}
        </div>
    );
};

export default EquipmentConfiguration;
