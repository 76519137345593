import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { IMaterialCategory } from "../../../interfaces/material";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";
import { useListCategoryMaterialLazyQuery } from "../../../api/hooks";

export interface IMaterialCategorySelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const MaterialCategorySelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: IMaterialCategorySelect<IMaterialCategory>) => {
    const [categoryList, setCategoryList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getMaterialCategories] = useListCategoryMaterialLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setCategoryList([]);
        } else {
            getMaterialCategories({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 20,
                        search: textSearch,
                        sort: {
                            createdAt: -1,
                        },
                    },
                },
                fetchPolicy: "no-cache",
            }).then((res: any) => {
                const categoryRes = res?.data?.data?.data ?? [];
                const selectedValue = isArray(value) ? value : [value];
                const filterCategory = map(
                    [...selectedValue, ...categoryRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqCategory = uniqBy(filterCategory, (item) => item.id);
                setCategoryList(uniqCategory);
            });
        }
    }, 500);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) =>
                    ObjectUtils.findItemFromId(categoryList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(categoryList, id));
    };

    const categoryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.materialCategory}
            dataSource={categoryList}
            getLabel={(item) => item?.name}
            value={categoryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            filterOption={false}
            {...selectProps}
        />
    );
};

export default MaterialCategorySelect;
