import React from "react";
import { IDoctorOrder } from "../interfaces/doctor-order";
import { ITreatment } from "../interfaces/treatment";

interface ITreatmentCreateState {
    treatmentForm: any;
}

const treatmentCreateState: ITreatmentCreateState = {
    treatmentForm: null,
};

export const TreatmentCreateContext = React.createContext(treatmentCreateState);

interface ITreatmentDetailState {
    treatment: ITreatment;
    setTreatment: any;
    onPrint: any;
    onPrintMaterial: any;
}

const treatmentDetailState: ITreatmentDetailState = {
    treatment: {} as any,
    setTreatment: () => {},
    onPrint: () => {},
    onPrintMaterial: () => {},
};

export const TreatmentDetailContext = React.createContext(treatmentDetailState);
