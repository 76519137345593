import {
    DateInput,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router";
import PromotionAPI from "../../../api/queries/promotion";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    IPromotionUpdateDrawerState,
    PromotionDetailContext,
    PromotionUpdateDrawerType,
    usePromotionDetailContext,
} from "../../../context/promotion";
import {
    IPromotion,
    PROMOTION_UPDATE_DRAWER_TYPES,
} from "../../../interfaces/promotion";
import Messages from "../../../languages/Messages";
import PromotionDetailActivity from "./PromotionDetailActivity";
import PromotionDetailHeader from "./PromotionDetailHeader";
import PromotionDetailInfo from "./PromotionDetailInfo";
import PromotionDetailItems from "./PromotionDetailItems";
import PromotionDetailSideView from "./PromotionDetailSideView";
import BranchSelect from "../../branch/share/BranchSelect";
import UploadButton from "../../../common/upload/UploadButton";

const PromotionDetail = () => {
    const [promotion, setPromotion] = useState<IPromotion>();
    const [openUpdateDrawer, setOpenUpdateDrawer] =
        useState<IPromotionUpdateDrawerState>({ open: false });
    const { promotionId } = useParams();

    useEffect(() => {
        loadPromotionDetail();
    }, []);

    const loadPromotionDetail = () => {
        Progress.show(
            { method: PromotionAPI.detail, params: [promotionId] },

            (res: any) => {
                const { data, error } = res?.data?.data ?? {};
                if (error) {
                    return;
                }
                setPromotion(data);
            }
        );
    };

    if (!promotion) return <div />;
    return (
        <PromotionDetailContext.Provider
            value={{
                promotion,
                setPromotion,
                openUpdateDrawer,
                setOpenUpdateDrawer,
            }}
        >
            <PromotionDetailHeader />
            <div className="grid grid-cols-12 p-4 gap-4">
                <div className="col-span-9 flex flex-col gap-4">
                    <PromotionDetailItems />
                    <PromotionDetailInfo />
                    <PromotionDetailActivity />
                </div>
                <div className="col-span-3">
                    <PromotionDetailSideView />
                </div>
            </div>
            {openUpdateDrawer.open && openUpdateDrawer.type && (
                <PromotionUpdateDrawer open={openUpdateDrawer.open} />
            )}
        </PromotionDetailContext.Provider>
    );
};

export default PromotionDetail;

interface IPromotionUpdateDrawer {
    open: boolean;
}

const PromotionUpdateDrawer = ({ open }: IPromotionUpdateDrawer) => {
    const { promotion, setPromotion, setOpenUpdateDrawer, openUpdateDrawer } =
        usePromotionDetailContext();
    const typeDataSet = PROMOTION_UPDATE_DRAWER_TYPES.find(
        (i) => i?.id === openUpdateDrawer?.type
    );
    const { memo, start, end, branch, documents } = promotion || {};
    const updateForm = useFormik<any>({
        initialValues: {
            memo,
            start: moment(start),
            end: moment(end),
            branch,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const payload =
                typeDataSet?.transformer && typeDataSet.transformer(values);
            if (!payload) return;
            onUpdatePromotion(payload);
        },
        enableReinitialize: true,
    });

    const { values, errors, handleSubmit, setFieldValue } = updateForm;

    const onUpdatePromotion = (payload: any) => {
        Progress.show(
            {
                method: PromotionAPI.update,
                params: [promotion?.id, payload],
            },
            (res: any) => {
                setPromotion(res?.data?.data?.data);
                Notifications.showSuccess(Messages.updateSuccess);
                setOpenUpdateDrawer({ open: false });
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={() => {
                setOpenUpdateDrawer({ open: false });
            }}
            title={Messages[typeDataSet?.title ?? ""]}
            onSave={() => updateForm.handleSubmit()}
            size="large"
        >
            <div
                className="p-3"
                hidden={
                    openUpdateDrawer?.type !== PromotionUpdateDrawerType.MEMO
                }
            >
                <InputTextForm form={updateForm} keyData="memo" multiple />
            </div>
            <div
                className="p-3"
                hidden={
                    openUpdateDrawer?.type !== PromotionUpdateDrawerType.PERIOD
                }
            >
                <div className="flex items-start gap-4">
                    <DateInput
                        className="w-100"
                        showTime
                        format="DD/MM/YYYY HH:mm"
                        allowClear={false}
                        label={Messages.startAt}
                        onChange={(value) => {
                            setFieldValue("start", value);
                            if (values?.end && value?.isAfter(values?.end)) {
                                setFieldValue("end", undefined);
                            }
                        }}
                        value={
                            values?.start ? moment(values.start) : (null as any)
                        }
                        error={errors.start as any}
                    />
                    <DateInput
                        disabled={!values?.start}
                        className="w-100"
                        showTime
                        format="DD/MM/YYYY HH:mm"
                        allowClear={false}
                        label={Messages.endAt}
                        onChange={(value) => setFieldValue("end", value)}
                        value={values?.end ? moment(values.end) : (null as any)}
                        error={errors.end as any}
                        disabledDate={(date) => {
                            return date.isBefore(values?.start);
                        }}
                    />
                </div>
            </div>
            <div
                className="p-3"
                hidden={
                    openUpdateDrawer?.type !== PromotionUpdateDrawerType.BRANCH
                }
            >
                <BranchSelect
                    className=""
                    multiple
                    value={values?.branch}
                    error={errors?.branch}
                    onChange={(v) => {
                        setFieldValue("branch", v);
                    }}
                />
            </div>
            <div
                className="p-3"
                hidden={
                    openUpdateDrawer?.type !==
                    PromotionUpdateDrawerType.DOCUMENT
                }
            >
                <UploadButton
                    variant="drag&drop"
                    disabled
                    onChange={(file: any) => {
                        setFieldValue("documents", file);
                    }}
                    defaultFiles={
                        (documents || [])?.map((item) => {
                            return {
                                id: StringUtils.getUniqueID(),
                                imageData: item,
                                fileUrl: item,
                            };
                        }) ?? []
                    }
                />
            </div>
        </Drawer>
    );
};
