import { useFormik } from "formik";
import { useState } from "react";
import Drawer from "../../../common/Drawer";
import GalleryUpload from "../../../common/gallery/GalleryUpload";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { EQUIPMENT_CONDITIONS } from "../../../constant/equipment";
import Messages from "../../../languages/Messages";

interface IEquipmentQuickAddDrawer {
    open: boolean;
    onClose: () => void;
    onSave: (value: any) => void;
}

const EquipmentQuickAddDrawer = ({
    open,
    onClose,
    onSave,
}: IEquipmentQuickAddDrawer) => {
    const eForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            onSave(values);
            onClose();
        },
    });
    const formValues = eForm?.values;
    const formErrors = eForm?.errors;

    const inputClassName = "mt-3 w-100";
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.create}
            onSave={() => eForm.handleSubmit()}
            size="auto"
            width="50vw"
        >
            <div className="p-3">
                <InputTextForm
                    form={eForm}
                    keyData="name"
                    className={inputClassName}
                />
                <div className={inputClassName}>
                    {/* <label>{Messages.image}</label> */}
                    <GalleryUpload form={eForm} />
                </div>
                <InputTextForm
                    form={eForm}
                    keyData="serialNo"
                    className={inputClassName}
                />
                <InputSelectForm
                    form={eForm}
                    keyData="condition"
                    dataSource={EQUIPMENT_CONDITIONS}
                    className={inputClassName}
                />
                <InputTextForm
                    form={eForm}
                    keyData="weight"
                    className={inputClassName}
                />
            </div>
        </Drawer>
    );
};

export default EquipmentQuickAddDrawer;
