import {
    AwesomeTableComponent,
    Badge,
    HeaderTable,
    IColumnsProps,
    TabBar,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, filter, reduce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import { ProductStockTransferStatus, DeliveryStatus } from "../../../api/hooks";
import DeliveryDriverAPI from "../../../api/queries/delivery-driver";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import TableAction from "../../../common/TableActions";
import {
    DELIVERY_DRIVER_PURPOSES,
    DELIVERY_DRIVER_STATUSES,
} from "../../../constant/delivery-driver";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import DeliveryDriverFilterDrawer from "./DeliveryDriverFilterDrawer";

const FILTER_DELIVERY_DRIVER_STATUS = [
    DeliveryStatus.DeliveryPending,
    DeliveryStatus.DeliveryBooked,
    DeliveryStatus.DeliveryCompleted,
    DeliveryStatus.DeliveryCancelled,
    DeliveryStatus.InDelivery,
];

const STATUS_TABS = [
    {
        id: "all",
        label: "all",
    },
    ...filter(DELIVERY_DRIVER_STATUSES, (item) =>
        FILTER_DELIVERY_DRIVER_STATUS.includes(item.id)
    ),
];

const DeliveryDriverTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [summary, setSummary] = useState<any>();
    const [tabSelected, setTabSelected] = useState<any>(STATUS_TABS[0]);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    useEffect(() => {
        tableRef.current.refresh();
    }, [tabSelected]);

    useEffect(() => {
        // getQuantityOrder();
    }, []);

    const columns: IColumnsProps = [
        {
            title: "Ref. No",
            dataIndex: "deliveryNo",
            render: (deliveryNo, item) => (
                <AppLink
                    to={generatePath(Path.DELIVERY_DRIVER_DETAIL, {
                        deliveryId: item.id,
                    })}
                >
                    {deliveryNo}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "transferFor",
            render: (transferFor) =>
                (Messages as any)[
                    DELIVERY_DRIVER_PURPOSES.find((i) => i?.id === transferFor)
                        ?.label ?? ""
                ],
        },
        {
            title: "DO No.",
            dataIndex: "deliveryNo",
        },
        {
            title: Messages.origin,
            dataIndex: "origin",
            render: (branch) => branch?.name ?? "N/A",
        },
        {
            title: Messages.destination,
            dataIndex: "destination",
            render: (branch) => branch?.name ?? "N/A",
        },
        {
            title: Messages.vehicleNo,
            dataIndex: "vehicle",
            render: (vehicle) => vehicle?.registrationNo ?? "N/A",
        },
        {
            title: Messages.driver,
            dataIndex: "driver",
            render: (user, item) => (
                <UserAvatarName size="x-small" user={user} />
            ),
        },
        {
            title: Messages.itemQty,
            dataIndex: "products",
            align: "center",
            render: (products, item) => {
                return reduce(
                    products,
                    (res, item) => res + (item?.stock ?? 0),
                    0
                );
            },
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (data) => (
                <ViewLabelStatus
                    status={data}
                    listStatus={DELIVERY_DRIVER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.estDateOfArrival,
            dataIndex: "estDateOfArrival",
            render: (data) => (data ? TimeUtils.toDateTime(data) : "N/A"),
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user, item) => (
                <UserAvatarName size="x-small" user={user} />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: (data) => (data ? TimeUtils.toDateTime(data) : "N/A"),
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        // const filterParam = mapBookingFilterToServer(filter);
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: 1 },
            ...(tabSelected?.id !== "all" ? { status: [tabSelected?.id] } : {}),
            // ...filterParam,
        };
        return DeliveryDriverAPI.list(input);
    };

    const onClickTableAction = (actionId: string, data: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.DELIVERY_DRIVER_DETAIL, {
                        deliveryId: data.id,
                    })
                );
        }
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current && tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                // onClickNew={onClickAddNew}
                label={Messages.deliveryDriver}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />

            <div className="flex">
                <TabBar
                    dataSource={STATUS_TABS}
                    onChange={(tab) => setTabSelected(tab)}
                    value={tabSelected}
                    // className="overflow-x-scroll my-4 pb-1 flex-column form-tabs"
                    getLabel={(item: any) => (
                        <>
                            <span>{Messages[item.label]}</span>
                            {item.id !== "all" &&
                                item.id !==
                                    ProductStockTransferStatus.Cancelled && (
                                    <Badge
                                        index={
                                            summary?.[item.id] > 100
                                                ? "100+"
                                                : summary?.[item.id]
                                        }
                                        variant="index"
                                        size="x-large"
                                        color={
                                            summary?.[item.id] === 0
                                                ? "green"
                                                : "red"
                                        }
                                    />
                                )}
                        </>
                    )}
                    isScroll
                    hideScrollBar={false}
                    minWidthItem="100%"
                    classNameItem="justify-content-start text-gray-500 bg-white"
                    variant="horizontal"
                />
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <DeliveryDriverFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default DeliveryDriverTable;
