import { gql } from "@apollo/client";

export const F_JOB_COST = gql`
    fragment F_JOB_COST on JobCostDto {
        id
        type
        role
        purchaseType
        primaryFee {
            feeType
            amount
            amountSecondary
            applySecondary
            unit
            unitQty
            isFlexible
            triggerThresholdQty
        }
        secondaryFee {
            feeType
            amount
            amountSecondary
            applySecondary
            unit
            unitQty
            isFlexible
            triggerThresholdQty
        }
    }
`;
