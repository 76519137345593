import {
    IRowsKey,
    Notifications,
    Progress,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { isNil, omitBy, pick } from "lodash";
import React, { useContext, useState } from "react";
import { useUpdateBranchAddressForAdminMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { BranchDetailContext } from "../../../context/branch";
import { BranchAddressSchema } from "../../../formschema/branch";
import { mapAddressToForm, mapBillingToSer } from "../../../interfaces/address";
import { IBranch } from "../../../interfaces/branch";
import Messages from "../../../languages/Messages";
import AddressFormInput from "../../address/share/AddressFormInput";
import AddressForm from "../../customer/share/AddressForm";

const SETTINGS_KEY: IRowsKey<IBranch["address"]>[] = [
    {
        id: "address",
        label: Messages.address,
    },
    {
        id: "subDistrict",
        label: Messages.subDistrict,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "district",
        label: Messages.district,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "province",
        label: Messages.province,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "postcode",
        label: Messages.postcode,
    },
];

const BranchAddress = () => {
    const { branch } = useContext(BranchDetailContext);
    const [showEditModal, setShowEditModal] = useState(false);
    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">{Messages.address}</h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>

            {branch?.address && (
                <ViewRowInterchange
                    keyList={SETTINGS_KEY}
                    dataSource={branch?.address}
                    variant="background"
                />
            )}
            {showEditModal && (
                <ModalEditAddress
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                />
            )}
        </div>
    );
};

const ModalEditAddress = ({ open, onClose }: any) => {
    const { branch, loadBranchDetail } = useContext(BranchDetailContext);
    const addressDefault = branch?.address
        ? pick(
              branch?.address,
              "address",
              "postcode",
              "country",
              "province",
              "subDistrict",
              "district"
          )
        : {};

    const [updateAddress] = useUpdateBranchAddressForAdminMutation();
    const branchForm = useFormik({
        initialValues: mapAddressToForm(addressDefault),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BranchAddressSchema,
        onSubmit: (values) => {
            const input = mapBillingToSer(values);
            Progress.show(
                {
                    method: updateAddress,
                    params: [
                        {
                            variables: {
                                id: branch?.id as string,
                                payload: omitBy(
                                    input,
                                    (value, key) =>
                                        isNil(value) ||
                                        ["isDefault", "country"].includes(key)
                                ) as any,
                            },
                        },
                    ],
                },
                (resp: any) => {
                    loadBranchDetail();
                    Notifications.showSuccess(
                        Messages.updateBranchSuccessfully
                    );
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    const { handleSubmit, values, errors } = branchForm;

    const onChangeAddress = (newValue: any) => {
        const { isDefault, ...newFormValue } = newValue;
        branchForm.setValues({
            ...values,
            ...newFormValue,
        });
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.address}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <div className="row">
                    <AddressFormInput
                        formDataValue={values}
                        formDataError={errors}
                        onChange={onChangeAddress}
                        show={{
                            typeOfPlace: false,
                        }}
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default BranchAddress;
