import { useFormik } from "formik";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";
import Messages from "../../../languages/Messages";
import MaterialInventoryFilterForm from "./MaterialInventoryFilterForm";

interface IAppointmentTableFilterDrawer {
    open: boolean;
    onClose: () => void;
}

const MaterialInventoryFilterDrawer = ({
    open,
    onClose,
}: IAppointmentTableFilterDrawer) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            setFilter(values);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.filter}
            size="large"
            onSave={() => filterForm?.handleSubmit()}
        >
            <MaterialInventoryFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default MaterialInventoryFilterDrawer;
