import {
    Avatar,
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    Icon,
    TimeUtils,
    useFirstTime,
} from "d-react-components";
import { debounce, map } from "lodash";
import { useRef, useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import ProductAPI from "../../../api/queries/product";

import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductFilterDrawer from "./ProductFilterDrawer";
import Drawer from "../../../common/Drawer";

const ProductTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const [openDescription, setOpenDescription] = useState<any>();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.PRODUCT_DETAIL, {
                        productId: item.id,
                    })}
                >
                    <div className="flex-center-y">
                        <Avatar
                            src={item?.gallery?.[0]}
                            size="x-small"
                            variant="rounded"
                            className="mr-2"
                            text={name}
                        />
                        {name}
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.sku,
            dataIndex: "sku",
        },
        {
            title: Messages.category,
            dataIndex: "category",
            render: (category) => category?.name,
        },
        {
            title: Messages.productType,
            dataIndex: "typeOfProduct",
            // render: (category) => category?.name,
        },
        {
            title: Messages.description,
            dataIndex: "description",
            // render: (category) => category?.name,
            render: (description, product) => (
                <div className="flex-center">
                    <Icon
                        name="visibility"
                        className="cursor-default"
                        onClick={() => setOpenDescription(product)}
                    />
                </div>
            ),
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: (regularPrice) => (
                <CurrencyFormat
                    value={regularPrice}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: (salePrice) => (
                <CurrencyFormat
                    value={salePrice}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },

        {
            title: Messages.updatedAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (product: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, product)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        return ProductAPI.list({
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            sort: {
                createdAt: -1,
            },
            typeOfProduct: filter?.productType,
            categories: map(filter?.category, (item) => item.id),
            service: map(filter?.serviceConfig, (item) => item.id),
            isMedicine: filter?.isMedicine,
            isVisibility: filter?.visibility,
            isSellOutOfStock: filter?.isSellOutOfStock,
        });
    };

    const onClickTableAction = (actionId: string, product: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.PRODUCT_DETAIL, {
                        productId: product.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.PRODUCT_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allProduct}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <ProductFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
            {openDescription && (
                <Drawer
                    size="large"
                    open={!!openDescription}
                    onClose={() => setOpenDescription(null)}
                    title={Messages.description}
                >
                    <div
                        className="p-3"
                        dangerouslySetInnerHTML={{
                            __html: openDescription.description,
                        }}
                    />
                </Drawer>
            )}
        </div>
    );
};

export default ProductTable;
