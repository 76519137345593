import classNames from "classnames";
import { filter, find, forEach, isUndefined, omit } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { Notifications } from "d-react-components";
import {
    useCreateShippingForAdminMutation,
    useDeleteShippingForAdminMutation,
    useListAllShippingForAdminLazyQuery,
    useUpdateShippingForAdminMutation,
} from "../../../api/hooks";
import { OrderDetailContext } from "../../../context/order";
import { IAddress, mapAddressToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressItem from "../share/AddressItem";
import { QuotationCreateContext } from "../../../context/quotation";
import ShippingDrawer from "../../quotation/share/ShippingDrawer";
import { CustomerDetailContext } from "../../../context/customer";
import { ShippingType } from "../../../interfaces/shipping";
import { CreateEditAddressDrawer } from "../../customer/detail/CustomerDetailAddress";

const OrderShipping = () => {
    const { order, setShippingList: setOrderShippingList } =
        useContext(OrderDetailContext);
    const [createShipping] = useCreateShippingForAdminMutation();
    const [updateShipping] = useUpdateShippingForAdminMutation();
    const [showEditShipping, setShowEditShipping] = useState<any>(undefined);
    const [showAddModal, setShowAddModal] = useState(false);
    const [shippingList, setShippingList] = useState<IAddress[]>([]);
    const [newShippingList, setNewShippingList] = useState<IAddress[]>([]); // use for editing
    const [getShippingList] = useListAllShippingForAdminLazyQuery();
    const [deleteShipping] = useDeleteShippingForAdminMutation();

    const loadShippingList = () => {
        getShippingList({
            variables: {
                refId: order?.id as string,
            },
            fetchPolicy: "no-cache",
        }).then((resp) => {
            const shippingAddList = resp?.data?.data?.data ?? ([] as any);
            setShippingList(shippingAddList);
            setOrderShippingList(shippingAddList);
            setNewShippingList(shippingAddList);
        });
    };

    useEffect(() => {
        if (order && !shippingList.length) {
            loadShippingList();
        }
    }, [order]);

    if (!order) {
        return <></>;
    }

    const onChangeOrderShipping = async () => {
        setShowAddModal(false);
        const newShipping = filter(
            newShippingList,
            (newShipping) =>
                !find(
                    shippingList,
                    (shipping) => newShipping.id === shipping.id
                )
        );
        if (newShipping.length) {
            let successfulCount = 0;
            forEach(newShipping, async (shippingItem, index) => {
                await createShipping({
                    variables: {
                        refId: order?.id,
                        shippingType: ShippingType.ORDER as any,
                        payload: omit(
                            mapAddressToSer(shippingItem),
                            "isDefault"
                        ),
                    },
                })
                    .then(() => {
                        successfulCount += 1;
                        if (successfulCount === newShipping.length) {
                            loadShippingList();
                        }
                    })
                    .catch((err) => {
                        Notifications.showError(err);
                    });
            });
        }
    };

    const removeAddress = (shipping: any) => {
        deleteShipping({
            variables: {
                id: shipping.id,
                refId: order?.id,
                shippingType: ShippingType.ORDER as any,
            },
        })
            .then(() => {
                loadShippingList();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    const onUpdatedShipping = (shippingItem: any) => {
        updateShipping({
            variables: {
                refId: order?.id,
                id: shippingItem?.id,
                shippingType: ShippingType.ORDER as any,
                payload: omit(mapAddressToSer(shippingItem), "isDefault"),
            },
        })
            .then(() => {
                setShowEditShipping(undefined);
                loadShippingList();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    return (
        <QuotationCreateContext.Provider
            value={
                {
                    shipping: newShippingList,
                    setShipping: setNewShippingList,
                } as any
            }
        >
            <CustomerDetailContext.Provider
                value={
                    { customer: order?.customer, setCustomer: () => {} } as any
                }
            >
                <Formik
                    initialValues={{
                        customer: [order?.customer],
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values) => {}}
                >
                    {({ handleSubmit }) => (
                        <>
                            <div className="border p-3 mb-3">
                                <div className="flex items-center">
                                    <h5 className="font-semibold mb-2 flex-1">
                                        {Messages.shippingInformation}
                                    </h5>
                                    <a onClick={() => setShowAddModal(true)}>
                                        {Messages.add}
                                    </a>
                                </div>
                                <div>
                                    {shippingList &&
                                        shippingList.map((shipping, index) => (
                                            <div
                                                className={classNames("pt-2", {
                                                    "pb-2 border-b":
                                                        index + 1 !==
                                                        shippingList.length,
                                                })}
                                                key={index}
                                            >
                                                <AddressItem
                                                    address={shipping}
                                                />
                                                <div className="mt-2">
                                                    <span
                                                        onClick={() =>
                                                            setShowEditShipping(
                                                                shipping
                                                            )
                                                        }
                                                        className="text-primary cursor-pointer mr-3"
                                                    >
                                                        {Messages.edit}
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            removeAddress(
                                                                shipping
                                                            )
                                                        }
                                                        className="text-red-500 cursor-pointer"
                                                    >
                                                        {Messages.remove}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            {showAddModal && (
                                <ShippingDrawer
                                    open={showAddModal}
                                    onClose={() => setShowAddModal(false)}
                                    onSave={onChangeOrderShipping}
                                />
                            )}
                            {!isUndefined(showEditShipping) && (
                                <CreateEditAddressDrawer
                                    open={!isUndefined(showEditShipping)}
                                    onSaveAddress={onUpdatedShipping}
                                    onClose={() =>
                                        setShowEditShipping(undefined)
                                    }
                                    addressDefault={showEditShipping}
                                />
                            )}
                        </>
                    )}
                </Formik>
            </CustomerDetailContext.Provider>
        </QuotationCreateContext.Provider>
    );
};

export default OrderShipping;
