import { Icon, ObjectUtils, StringUtils } from "d-react-components";
import ClassNames from "classnames";
import { isEmpty, map } from "lodash";
import React from "react";
import Image from "../../../common/Image";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductSelect, { IProductSelect } from "./ProductSelect";

interface IProductSolidItem {
    product: IProduct;
    onClickRemove: () => void;
    renderContent?: () => any;
}

export const ProductSolidItem = ({
    product,
    onClickRemove,
    renderContent,
}: IProductSolidItem) => {
    return (
        <div className="w-100 bg-primary d-flex align-items-center pr-3 mt-2">
            <Image
                src={product?.thumbnail ?? product?.gallery?.[0]}
                className={ClassNames({
                    "image-square-medium": !renderContent,
                    "image-square-large": !!renderContent,
                })}
            />
            <div className="flex-1 flex-column text-white pl-2">
                <span className="block text-white font-weight-bold">
                    {product?.name}
                </span>
                <span className="block text-white text-sm opacity-75">
                    {`${Messages.sku}: ${product?.sku}`}
                </span>
                {renderContent && renderContent()}
            </div>
            <Icon
                name="delete"
                className="text-white cursor-pointer"
                onClick={onClickRemove}
            />
        </div>
    );
};

const ProductSolidSelect = ({
    className,
    ...selectProps
}: IProductSelect<IProduct>) => {
    const onRemoveInMultiple = (product: IProduct) => {
        const result = ObjectUtils.removeArrayById(
            selectProps?.value as any[],
            product?.id
        );
        selectProps?.onChange(result);
    };
    const renderProductSelected = () => {
        if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
        if (selectProps?.multiple) {
            return map(selectProps?.value, (product: any) => (
                <ProductSolidItem
                    product={product}
                    onClickRemove={() => onRemoveInMultiple(product)}
                    renderContent={() => (
                        <span className="block text-white text-sm opacity-75">
                            {`${
                                Messages.salePrice
                            }: ${StringUtils.moneyThaiFormat(
                                product?.salePrice
                            )}`}
                        </span>
                    )}
                />
            ));
        }
        return (
            <ProductSolidItem
                product={selectProps?.value as IProduct}
                onClickRemove={() => selectProps?.onChange(null)}
                renderContent={() => (
                    <span className="block text-white text-sm opacity-75">
                        {`${Messages.salePrice}: ${StringUtils.moneyThaiFormat(
                            (selectProps?.value as any)?.salePrice
                        )}`}
                    </span>
                )}
            />
        );
    };

    return (
        <div className={className}>
            <ProductSelect {...selectProps} />
            {renderProductSelected()}
        </div>
    );
};

export default ProductSolidSelect;
