import {
    DateInput,
    InputText,
    Notifications,
    Select,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, isNil, pickBy } from "lodash";
import moment from "moment";
import * as Yup from "yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    PaymentMethod,
    PaymentStatus,
    useConfirmationPaymentForAdminMutation,
    useListAllBankForAdminLazyQuery,
} from "../../../../api/hooks";
import Drawer from "../../../../common/Drawer";
import UploadButton from "../../../../common/upload/UploadButton";
import { PAYMENT_CONFIRM_STATUS } from "../../../../constant/payment";
import { PaymentContext } from "../../../../context/payment";
import Messages from "../../../../languages/Messages";

const CONTENT_PAYMENT = {
    [PAYMENT_CONFIRM_STATUS.APPROVE]: {
        title: Messages.confirmFullPay,
        description: Messages.areCertainPaymentPaid,
        saveText: Messages.confirm,
    },
    [PAYMENT_CONFIRM_STATUS.REJECTED]: {
        title: Messages.confirmRejectPayment,
        description: Messages.areCertainPaymentReject,
        saveText: Messages.reject,
    },
};

const PaymentConfirmDrawer = ({
    open,
    onClose,
    type,
    payment,
    onSave,
}: any) => {
    const { ref: order } = useContext(PaymentContext);
    const [bankOptions, setBankOptions] = useState<any[]>([]);
    const [getListBank] = useListAllBankForAdminLazyQuery();
    const [confirmPayment] = useConfirmationPaymentForAdminMutation();
    const { paymentMethod } = payment;

    const PaymentConfirmSchema = Yup.lazy((value: any) => {
        let schema = Yup.object().shape({});

        if (
            paymentMethod !== PaymentMethod.PaidByCheck &&
            paymentMethod !== PaymentMethod.EmployeeSalaryDeduction
        ) {
            schema = schema.concat(
                Yup.object().shape({
                    attachments: Yup.array()
                        .required(Messages.fieldRequired)
                        .min(1, Messages.fieldRequired),
                }) as any
            );
        }
        if (type === PAYMENT_CONFIRM_STATUS.APPROVE) {
            schema = schema.concat(
                Yup.object().shape({
                    actualDatePayment: Yup.mixed().required(
                        Messages.requiredField
                    ),
                }) as any
            );
        }
        if (
            paymentMethod === PaymentMethod.BankTransfer &&
            type === PAYMENT_CONFIRM_STATUS.APPROVE
        ) {
            schema = schema.concat(
                Yup.object().shape({
                    actualBankPayment: Yup.mixed().required(
                        Messages.requiredField
                    ),
                }) as any
            );
        }
        return schema;
    });

    const form = useFormik<any>({
        initialValues: {},
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: PaymentConfirmSchema,
        onSubmit: (values: any) => {
            onConfirm();
        },
    });

    useEffect(() => {
        getListBank({
            variables: {
                paymentMethod,
                branch: order?.store?.id as string,
            },
        }).then((resp) => {
            setBankOptions(resp?.data?.data?.data ?? []);
        });
    }, [paymentMethod]);

    const onConfirm = () => {
        const { attachments, remark, actualBankPayment, actualDatePayment } =
            values;
        confirmPayment({
            variables: {
                refId: order?.id as string,
                id: payment?.id,
                status:
                    type === PAYMENT_CONFIRM_STATUS.APPROVE
                        ? PaymentStatus.Confirmed
                        : PaymentStatus.Rejected,
                paymentMethod,
                payload: pickBy(
                    {
                        remark,
                        attachments: attachments?.map(
                            (item: any) => item.fileUrl
                        ),
                        ...(actualBankPayment
                            ? { bank: actualBankPayment }
                            : {}),
                        ...(actualDatePayment
                            ? {
                                  dateOfReceiving:
                                      moment(actualDatePayment).toDate(),
                              }
                            : {}),
                    },
                    (item) => !isNil(item)
                ) as any,
            },
        })
            .then(() => {
                onSave && onSave();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    const { values, errors, setFieldValue, handleSubmit } = form;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            saveText={CONTENT_PAYMENT?.[type]?.saveText}
            title={CONTENT_PAYMENT?.[type]?.title}
            onSave={handleSubmit}
        >
            <div className="flex-column p-3">
                <div className="text-x-small">
                    {CONTENT_PAYMENT?.[type]?.description}
                </div>
                <div className="mt-2">
                    <UploadButton
                        onChange={(file: any) =>
                            setFieldValue("attachments", file)
                        }
                        defaultFiles={values?.attachments}
                        // uploadImagesOnly
                    />
                    <ViewTextError error={errors.attachments} />
                </div>
                <div hidden={type !== PAYMENT_CONFIRM_STATUS.APPROVE}>
                    <DateInput
                        className="mt-3"
                        showTime
                        format="DD/MM/YYYY HH:mm"
                        label={Messages.actualDatePayment}
                        onChange={(date) => {
                            setFieldValue(
                                "actualDatePayment",
                                moment(date).valueOf()
                            );
                        }}
                        value={
                            values?.actualDatePayment
                                ? (moment(values.actualDatePayment) as any)
                                : null
                        }
                        error={errors?.actualDatePayment as string}
                    />
                    {paymentMethod === PaymentMethod.BankTransfer && (
                        <Select
                            className="mt-3"
                            label={Messages.actualBankReceivePayment}
                            dataSource={bankOptions}
                            value={values?.actualBankPayment}
                            onChange={(value) => {
                                setFieldValue("actualBankPayment", value);
                            }}
                            getLabel={(pro) =>
                                `${pro?.accountNumber ?? ""} // ${
                                    pro.bankName ?? ""
                                } // ${pro?.accountName ?? ""}`
                            }
                            error={errors?.actualBankPayment}
                        />
                    )}
                </div>
                <InputText
                    className="mt-3"
                    label={Messages.remark}
                    onChange={(event) => {
                        // remarkContent.current = event.target.value;
                        setFieldValue("remark", event.target.value);
                    }}
                    value={values?.remark}
                    multiple
                    error={errors?.remark as string}
                />
            </div>
        </Drawer>
    );
};

export default PaymentConfirmDrawer;
