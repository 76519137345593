import { InputText } from "d-react-components";
import React from "react";
import { ICustomerEmergencyContact } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

interface IEmergencyContactForm {
    contact: ICustomerEmergencyContact;
    onChange: any;
    formError?: any;
}

const EmergencyContactForm = ({
    contact,
    onChange,
    formError,
}: IEmergencyContactForm) => {
    const inputClassName = "col-6 mt-3";
    const onChangeValue = (key: string, value: any) => {
        onChange({ ...contact, [key]: value });
    };

    return (
        <div className="row">
            <InputText
                label={Messages.name}
                onChange={(event) =>
                    onChangeValue("name", event?.target?.value)
                }
                className={inputClassName}
                value={contact?.name}
                error={formError?.name}
            />
            <InputText
                label={Messages.relationship}
                onChange={(event) =>
                    onChangeValue("relationship", event?.target?.value)
                }
                className={inputClassName}
                value={contact?.relationship}
                error={formError?.relationship}
            />

            <InputText
                label={Messages.currentAddress}
                onChange={(event) =>
                    onChangeValue("residence", event?.target?.value)
                }
                className={inputClassName}
                value={contact?.residence}
                error={formError?.residence}
            />
            <InputText
                label={Messages.phone}
                onChange={(event) =>
                    onChangeValue("phone", event?.target?.value)
                }
                className={inputClassName}
                value={contact?.phone}
                error={formError?.phone}
            />
        </div>
    );
};
export default EmergencyContactForm;
