/* eslint-disable no-param-reassign */
import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import { ViewTextError } from "d-react-components";
import { forEach } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import Messages from "../../languages/Messages";
import { SelectMediaManagerModal } from "../../screen/media/selectmedia/SelectMediaManagerButton";
import "./RichTextTiny.scss";

const RichTextTiny = ({
    value,
    onChange,
    containerClass,
    size = "medium", // large, small
    onBlur,
    label,
    error,
    ...props
}: any) => {
    const tinyMCERef = useRef();
    const renderRef = useRef<number>(0);
    const [inputKey, setInputKey] = useState<string>();
    const [openModal, setOpenModal] = useState(false);
    const height = useMemo(() => {
        switch (size) {
            case "small":
                return 200;
            case "large":
                return 600;
            default:
                return 300;
        }
    }, [size]);

    const onSelectMedia = (medias: any[]) => {
        forEach(medias, (media) => {
            (window as any).tinymce.execCommand(
                "mceInsertContent",
                false,
                `<img alt=${media.fileName} src="${media.fileUrl}"/>`
            );
        });
    };

    const containerClasses = classNames("d-flex flex-column", containerClass);

    useEffect(() => {
        renderRef.current = +1;
        // setInputValue(value?.[lang] ?? "");
        if (renderRef.current === 1) {
            renderRef.current = +1;
            setInputKey(`input${renderRef.current}`);
        }
    }, [value]);

    return (
        <div className={containerClasses}>
            {label && <div className="text-bold text-label mb-1">{label}</div>}
            <Editor
                key={inputKey}
                ref={(ref: any) => {
                    tinyMCERef.current = ref;
                }}
                apiKey="uk68x567b8vwukvy5bpvjoqndyqfmxr09ol0niidjs9rvz86"
                value={value}
                init={{
                    /**
                     * custom style when view + not effect to style in line of tags
                     */
                    content_style:
                        "img { max-width: 100%; } div { max-width: 100%; }",
                    /**
                     * add custom class table
                     */
                    table_class_list: [
                        { title: "None", value: "" },
                        { title: "Keep columns", value: "table_keep_columns" },
                    ],
                    height,
                    menubar: false,
                    plugins:
                        " code preview importcss tinydrive searchreplace autolink" +
                        " autosave directionality visualblocks visualchars fullscreen" +
                        " link media template codesample table charmap hr pagebreak" +
                        " nonbreaking anchor toc insertdatetime advlist lists wordcount" +
                        " textpattern noneditable help" +
                        " charmap emoticons paste",
                    //toolbar
                    toolbar_mode: "wrap",
                    toolbar:
                        "code | undo redo | bold italic underline strikethrough | fontsizeselect formatselect" +
                        " | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist" +
                        " | forecolor backcolor formatpainter removeformat | pagebreak" +
                        " | charmap | fullscreen  preview | mediaToolbarButton image media link | ltr rtl " +
                        " | table tabledelete | tableprops tablerowprops tablecellprops" +
                        " | tableinsertrowbefore tableinsertrowafter tabledeleterow" +
                        " | tableinsertcolbefore tableinsertcolafter tabledeletecol" +
                        " | ",

                    //remove class attr in inline tag
                    selector: "*",
                    valid_classes: "table_keep_columns",

                    setup: (editor) => {
                        editor.ui.registry.addButton("mediaToolbarButton", {
                            text: Messages.media,
                            onAction: () => setOpenModal(true),
                        });
                    },
                }}
                onEditorChange={onChange}
                onBlur={onBlur}
                {...props}
            />
            <ViewTextError error={error} />
            <SelectMediaManagerModal
                onClose={() => setOpenModal(false)}
                open={openModal}
                onSelect={onSelectMedia}
            />
        </div>
    );
};

export default RichTextTiny;
