import {
    InputText,
    IRowsKey,
    Notifications,
    Progress,
    Select,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { pick } from "lodash";
import React, { useContext, useState } from "react";
import { useUpdateBranchForAdminMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import {
    BRANCH_STATUS,
    BRANCH_STATUSES,
    STOCK_ADJUSTMENT_STATUS,
    STOCK_ADJUSTMENT_STATUSES,
} from "../../../constant/branch";
import { BranchDetailContext } from "../../../context/branch";
import { BranchSchema } from "../../../formschema/branch";
import { IBranch } from "../../../interfaces/branch";
import Messages from "../../../languages/Messages";

const GENERAL_KEYS: IRowsKey<IBranch>[] = [
    {
        id: "name",
        label: Messages.name,
    },
    {
        id: "code",
        label: Messages.code,
    },
    {
        id: "pickupCode",
        label: Messages.pickupCode,
    },
    {
        id: "brandTaxId",
        label: Messages.brandTaxId,
    },
    {
        id: "phone",
        label: Messages.phone,
    },
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => {
            return data === BRANCH_STATUS.ACTIVE
                ? Messages.enabled
                : Messages.disabled;
        },
    },
    {
        id: "manualStockAdjustment",
        label: Messages.manualStockAdjustment,
        renderContent: ({ data }) => {
            return data === STOCK_ADJUSTMENT_STATUS.ALLOW
                ? Messages.allowed
                : Messages.notAllowed;
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
];

const BranchGeneralInformation = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const { branch } = useContext(BranchDetailContext);
    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <ViewRowInterchange
                keyList={GENERAL_KEYS}
                dataSource={branch}
                variant="background"
            />
            {showEditModal && (
                <ModalEditGeneralInformation
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                />
            )}
        </div>
    );
};

const ModalEditGeneralInformation = ({ open, onClose }: any) => {
    const [updateBranch] = useUpdateBranchForAdminMutation();
    const { branch, loadBranchDetail } = useContext(BranchDetailContext);
    const defaultValues = pick(branch, [
        "brandTaxId",
        "code",
        "manualStockAdjustment",
        "name",
        "phone",
        "pickupCode",
        "status",
    ]);
    const branchForm = useFormik({
        initialValues: { ...defaultValues } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BranchSchema,
        onSubmit: (values) => {
            onUpdateBranch(values);
        },
    });
    const onUpdateBranch = (input: any) => {
        const body = {
            variables: {
                payload: { ...input },
                id: branch?.id,
            },
        };

        Progress.show(
            {
                method: updateBranch,
                params: [body],
            },
            (resp: any) => {
                Notifications.showSuccess(Messages.updateBranchSuccessfully);
                loadBranchDetail();
                onClose();
            }
        );
    };
    const { handleSubmit, values, errors } = branchForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.generalInformation}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1 mb-3">
                        <InputText
                            label={Messages.name}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "name",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.name}
                            error={branchForm?.errors?.name as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.code}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "code",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.code}
                            error={branchForm?.errors?.code as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.pickupCode}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "pickupCode",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.pickupCode}
                            error={branchForm?.errors?.pickupCode as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.brandTaxId}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "brandTaxId",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.brandTaxId}
                            error={branchForm?.errors?.brandTaxId as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <InputText
                            label={Messages.phone}
                            placeholder={Messages.pleaseInput}
                            onChange={(event) =>
                                branchForm.setFieldValue(
                                    "phone",
                                    event.target.value
                                )
                            }
                            value={branchForm?.values?.phone}
                            error={branchForm?.errors?.phone as any}
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.status}
                            value={branchForm.values?.status}
                            error={branchForm.errors?.status}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={BRANCH_STATUSES}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                branchForm.setFieldValue("status", value)
                            }
                        />
                    </div>
                    <div className="col-span-1">
                        <Select
                            label={Messages.manualStockAdjustment}
                            value={branchForm.values?.manualStockAdjustment}
                            error={branchForm.errors?.manualStockAdjustment}
                            placeholder={Messages.pleaseSelect}
                            className="mb-3"
                            dataSource={STOCK_ADJUSTMENT_STATUSES}
                            getLabel={(item) => (Messages as any)[item?.label]}
                            onChange={(value) =>
                                branchForm.setFieldValue(
                                    "manualStockAdjustment",
                                    value
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default BranchGeneralInformation;
