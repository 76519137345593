import { Button, Notifications } from "d-react-components";
import { map, pick, round } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    OrderStatus,
    PaymentMethod,
    SourceOfPayment,
    useAllPayment2C2PForAdminQuery,
    useUpdatePaymentForAdminMutation,
} from "../../../api/hooks";
import { OrderDetailContext } from "../../../context/order";
import { OrderRefType, PaymentContext } from "../../../context/payment";
import { IPayment } from "../../../interfaces/payment";
import Messages from "../../../languages/Messages";
import PaymentInfoItem from "../share/payment/PaymentInfoItem";
import SplitPaymentDrawer from "../share/payment/SplitPaymentDrawer";

const OrderPayment = () => {
    const {
        order,
        loadOrderDetail,
        paymentList,
        paymentMethodsList,
        reloadPaymentList,
    } = useContext(OrderDetailContext);
    const [openSplitPaymentModal, setOpenSplitPaymentModal] = useState(false);
    const [splitPayment] = useUpdatePaymentForAdminMutation();
    const { data: payment2C2POptionsList } = useAllPayment2C2PForAdminQuery();

    const isShowSplitPayment = useMemo(() => {
        return (
            order?.status === OrderStatus.PendingPayment ||
            order?.status === OrderStatus.PendingConfirmation ||
            order?.status === OrderStatus.PartiallyPaid
        );
    }, [order]);

    const onClickSplitPayment = () => {
        setOpenSplitPaymentModal(true);
    };

    const onChangePaymentInfo = () => {
        reloadPaymentList();
        loadOrderDetail();
    };

    const handleSplitPayment = (
        splitPaymentList: any,
        remainAmount: number
    ) => {
        const arrayTotal = splitPaymentList.map((item: any) => item?.total);
        if (arrayTotal.includes(0) || arrayTotal.includes("0")) {
            Notifications.showError(Messages.pleaseFillAllThePaymentAmount);
            return;
        }

        if (round(remainAmount * 100) / 100 !== 0) {
            Notifications.showError(Messages.paymentIsNotEnough);
            return;
        }

        if (splitPaymentList[0].paymentMethod === PaymentMethod.Default) {
            Notifications.showError(
                Messages.youNeedToChooseASpecificPaymentMethod
            );
            return;
        }

        splitPayment({
            variables: {
                refId: order?.id as string,
                sourceOfPayment: SourceOfPayment.Order,
                payload: splitPaymentList.map((payment: any) => ({
                    ...pick(payment, ["paymentMethod", "paymentOption"]),
                    total: parseFloat(payment.total),
                    ...(payment.paymentNo ? { id: payment.id } : {}),
                })),
            },
        })
            .then((resp) => {
                setOpenSplitPaymentModal(false);
                reloadPaymentList();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    return (
        <PaymentContext.Provider
            value={
                {
                    paymentMethodsList: paymentMethodsList ?? [],
                    payment2C2POptionsList:
                        payment2C2POptionsList?.data?.data ?? [],
                    ref: order,
                    reloadRef: loadOrderDetail,
                    refType: OrderRefType.ORDER,
                } as any
            }
        >
            <div className="border p-3 mb-3 mt-3">
                <div className="flex items-center">
                    <h5 className="font-semibold mb-2 flex-1">
                        {Messages.customerPayment}
                    </h5>
                    <div>
                        {isShowSplitPayment && (
                            <Button
                                iconName="call_split"
                                onClick={onClickSplitPayment}
                            >
                                {Messages.splitPayment}
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    <div className="mt-2 w-full flex flex-col whitespace-nowrap overflow-x-auto py-2">
                        {paymentMethodsList?.length &&
                            paymentList?.map((payment, index) => (
                                <PaymentInfoItem
                                    payment={payment as any}
                                    key={index}
                                    index={index}
                                    onChangePaymentInfo={onChangePaymentInfo}
                                />
                            ))}
                    </div>
                </div>
                {openSplitPaymentModal && (
                    <SplitPaymentDrawer
                        open={openSplitPaymentModal}
                        onClose={() => setOpenSplitPaymentModal(false)}
                        paymentList={map(paymentList, (item) => ({
                            ...item,
                            total: `${Number.parseFloat(
                                item.total as any
                            ).toFixed(2)}`,
                        }))}
                        onSave={handleSplitPayment}
                    />
                )}
            </div>
        </PaymentContext.Provider>
    );
};

export default OrderPayment;
