import React, { useRef, useState } from "react";
import { Modal, Progress, Select } from "d-react-components";
import { debounce, isEmpty, map, uniqBy } from "lodash";
import { ITag } from "../../../interfaces/tag";
import Messages from "../../../languages/Messages";
import {
    useCreateTagMutation,
    useGetTagListLazyQuery,
} from "../../../api/hooks";

interface ITagSelectModal {
    ref?: string;
    value: ITag[];
    open: boolean;
    onClose: any;
    onSave: any;
}

const TagSelectModal = ({
    ref = "CUSTOMER",
    open,
    value = [],
    onClose,
    onSave,
}: ITagSelectModal) => {
    const [createTag] = useCreateTagMutation();

    const [localValues, setLocalValues] = useState<ITag[]>(value);
    const searchRef = useRef();

    const [tagList, setTagList] = useState(value);
    const [getTagList] = useGetTagListLazyQuery();

    const onChangeTextSearch = debounce((textSearch) => {
        searchRef.current = textSearch;

        if (isEmpty(textSearch)) {
            setTagList([]);
        } else {
            getTagList({
                variables: {
                    paginate: {
                        page: 1,
                        limit: 15,
                        search: textSearch,
                        ref: [ref] as any,
                    },
                },
            }).then((res: any) => {
                const tagListRes = res?.data?.data?.data ?? [];

                const filterTag = map([...localValues, ...tagListRes], (item) => ({
                    id: item.id,
                    name: item.name,
                }));
                const uniqTags = uniqBy(filterTag, (item) => item.id);
                setTagList(uniqTags as any);
            });
        }
    }, 500);

    const onChange = (value: any, valuesData: any) => {
        const values = map(valuesData, (item) => ({
            id: item.value,
            name: item.children,
        }));
        setLocalValues(values as any);
    };

    const onSubmitSearch = (event: any) => {
        if (isEmpty(searchRef.current)) {
            return;
        }
        if (event.keyCode === 13 && event.key === "Enter") {
            Progress.show(
                {
                    method: createTag,
                    params: [
                        {
                            variables: {
                                payload: {
                                    name: searchRef.current,
                                    ref,
                                },
                            },
                        },
                    ],
                },
                (res: any) => {
                    const newTag = res?.data?.data?.data;
                    const newTagList = [...localValues, newTag];
                    setTagList(newTagList);
                    setLocalValues(newTagList);
                }
            );
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.tags}
            onSave={() => onSave(localValues)}
        >
            <Select
                dataSource={tagList}
                onSearch={onChangeTextSearch}
                className="w-100"
                getLabel={(item) => item?.name}
                placeholder={Messages.pleaseSearchAndSelect}
                multiple
                onChange={onChange}
                onInputKeyDown={(event) => onSubmitSearch(event)}
                value={map(localValues, (item) => item.id)}
            />
        </Modal>
    );
};

export default TagSelectModal;
