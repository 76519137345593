import { Button, Header, Notifications, Progress } from "d-react-components";
import { Formik } from "formik";
import { find, forEach, isUndefined, map, omit, pick } from "lodash";
import { useContext, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
    PriceSetType,
    SalesPersonType,
    ShippingType,
    useCreateOrderForAdminMutation,
    useCreateShippingForAdminMutation,
} from "../../../api/hooks";
import PermissibleRender from "../../../common/PermissibleComponent";
import { PurchaseType } from "../../../constant/quotation";
import { PERMISSION } from "../../../constant/user";
import { AppStateContext } from "../../../context/app";
import {
    IOrderMetrics,
    QuotationCreateContext as OrderCreateContext,
} from "../../../context/quotation";
import { useCheckCartValue } from "../../../hooks/order";
import { mapAddressToSer, mapBillingToSer } from "../../../interfaces/address";
import { ICustomer } from "../../../interfaces/customer";
import { CartValueState, OrderType } from "../../../interfaces/order";
import {
    IProduct,
    IProductOrder,
    ISalesPerson,
    getProductPrice,
} from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import { getManualDiscountPayload } from "../../../utils/order";
import Path from "../../Path";
import OrderCreateHeader from "../../quotation/crud/QuotationCreateHeader";
import OrderCreateProductList from "../../quotation/crud/QuotationCreateProductList";
import OrderProductsCart from "../../quotation/crud/QuotationProductsCart";

const OrderSchema = Yup.object().shape({
    store: Yup.mixed().required(Messages.fieldRequired),
    // typeOfTreatment: Yup.mixed().required(Messages.fieldRequired),
    purchaseTypes: Yup.mixed().required(Messages.fieldRequired),
    customer: Yup.mixed().required(Messages.fieldRequired),
    primarySalesPerson: Yup.mixed().required(Messages.fieldRequired),
    sourceOfCustomer: Yup.string().required(Messages.fieldRequired),
    agency: Yup.mixed().when(
        "purchaseTypes",
        (purchaseTypes: string, yup: any) => {
            if (purchaseTypes === PurchaseType.AGENCY) {
                return Yup.mixed().required(Messages.fieldRequired);
            }
            return Yup.mixed().nullable();
        }
    ),
});

const getSalesPerson = (
    primarySalesPerson: any,
    coSalePerson: ISalesPerson[] = []
): ISalesPerson[] => {
    return [
        ...(primarySalesPerson
            ? [
                  {
                      salesPerson: primarySalesPerson?.id,
                      type: SalesPersonType.Sales,
                      isPrimary: true,
                  },
              ]
            : []),
        ...coSalePerson.map((item) => ({
            ...item,
            salesPerson: item?.salesPerson?.id,
            isPrimary: false,
        })),
    ];
};

export const getOrderPayload = ({
    values,
    billing,
    productList,
    productCustomList,
    voucherCode,
    manualDiscount,
    orderMetrics,
    credits,
}: any) => {
    const {
        store,
        agency,
        purchaseTypes,
        // typeOfTreatment,
        customer,
        remark,
        isSameShippingAddress,
        primarySalesPerson,
        sourceOfCustomer,
    } = values;
    const { applyPriceSet } =
        (orderMetrics as IOrderMetrics) || ({} as IOrderMetrics);

    return {
        store: store?.id,
        purchaseType: purchaseTypes,
        agency: agency?.id,
        // typeOfTreatment: typeOfTreatment?.[0]?.id,
        salesPerson: primarySalesPerson?.id,
        priceSetType: applyPriceSet,
        isSameShippingAddress,
        remark,
        sourceOfCustomer,
        ...(voucherCode ? { voucherCode } : {}),
        ...(credits ? { credits } : {}),
        ...(customer?.[0]?.id
            ? { customer: customer?.[0]?.id }
            : {
                  contact: {
                      ...(customer?.[0] || {}),
                      nationality: customer?.[0]?.nationality?.id,
                  },
              }),
        ...(billing
            ? {
                  billing: omit(mapBillingToSer(billing), ["isDefault"]),
              }
            : {}),
        ...(productList?.length
            ? {
                  products: productList.map((product: any) => ({
                      product: product.id,
                      promotion: product?.promotion ?? null,
                      quantity: product.quantity,
                      hasOpeningPrice: !!product.openingPrice,
                      salesPerson: getSalesPerson(
                          primarySalesPerson,
                          product.salesPerson
                      ),
                      salePrice:
                          product.openingPrice ||
                          getProductPrice("salePrice", product, applyPriceSet),
                      ...(product.doctorOrder
                          ? {
                                doctorOrder: product.doctorOrder?.id,
                            }
                          : {}),
                      ...(product.treatment
                          ? {
                                treatment: product.treatment?.id,
                            }
                          : {}),
                      ...(isUndefined(product.isGift)
                          ? {}
                          : {
                                isGift: product.isGift,
                            }),
                  })),
              }
            : { products: [] }),
        ...(productCustomList?.length
            ? {
                  manualProduct: productCustomList.map((product: any) => ({
                      ...pick(product, [
                          "name",
                          "quantity",
                          "remark",
                          "salePrice",
                          "sku",
                          "isNonVAT",
                      ]),
                      //   salesPerson: getSalesPerson(
                      //       primarySalesPerson,
                      //       product.salesPerson
                      //   ),
                  })),
              }
            : {}),
        ...getManualDiscountPayload(manualDiscount, productList),
    };
};

const OrderCreate = () => {
    const navigate = useNavigate();
    const { me } = useContext(AppStateContext);
    const [createOrder] = useCreateOrderForAdminMutation();
    const [createShipping] = useCreateShippingForAdminMutation();
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [orderMetrics, setOrderMetrics] = useState<IOrderMetrics>({
        applyPriceSet: PriceSetType.Default,
    });
    const [productList, setProductList] = useState<IProductOrder[]>([]);
    const [productCustomList, setProductCustomList] = useState<IProduct[]>([]);
    const [cartValue, setCartValue] = useState<CartValueState>({
        total: 0,
        subtotal: 0,
    });
    const [manualDiscount, setManualDiscount] = useState<any>();

    const [shipping, setShipping] = useState<any[]>([]);
    const [billing, setBilling] = useState<any>();
    const [productFilters, setProductFilters] = useState({
        viewMode: "grid",
    });

    const { checkCartValue } = useCheckCartValue({
        setCartValue,
        billing,
        productList,
        productCustomList,
        manualDiscount,
        cartValue,
        orderMetrics,
    });

    const onCreateOrder = async (values: any) => {
        if (!productList.length && !productCustomList.length) {
            return Notifications.showError(
                Messages.youHaveToChooseProductsFirst
            );
        }
        if (
            values.purchaseTypes === PurchaseType.COMPLICATION &&
            !find(productList, (item) => !!item?.doctorOrder?.id)
        ) {
            return Notifications.showError(
                Messages.youHaveToChooseAtLeastOneDoctorOrderForComplicationCase
            );
        }
        const orderPayload: any = getOrderPayload({
            values,
            billing,
            productList,
            productCustomList,
            voucherCode: cartValue?.voucher?.map((v) => v?.voucher?.code),
            manualDiscount,
            orderMetrics,
            credits: map(cartValue?.credits, (v) => v?.credit?.id),
        });
        return Progress.show(
            {
                method: createOrder,
                params: [
                    {
                        variables: {
                            payload: orderPayload,
                        },
                    },
                ],
            },
            (resp: any) => {
                Notifications.showSuccess(Messages.createOrderSuccessfully);
                const orderId = resp?.data?.data?.data?.id;
                if (shipping && shipping.length) {
                    forEach(shipping, async (shippingItem) => {
                        await createShipping({
                            variables: {
                                refId: orderId,
                                shippingType: ShippingType.Order as any,
                                payload: omit(
                                    mapAddressToSer(shippingItem),
                                    "isDefault"
                                ),
                            },
                        }).catch((err) => {
                            Notifications.showError(err);
                        });
                    });
                }
                navigate(
                    generatePath(Path.ORDER_DETAIL, {
                        orderId,
                    })
                );
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <div>
            <OrderCreateContext.Provider
                value={{
                    productFilters,
                    setProductFilters,
                    productList,
                    setProductList,
                    productCustomList,
                    setProductCustomList,
                    cartValue,
                    setCartValue,
                    checkCartValue,
                    selectedCustomer,
                    setSelectedCustomer,
                    shipping,
                    setShipping,
                    billing,
                    setBilling,
                    manualDiscount,
                    setManualDiscount,
                    orderMetrics,
                    setOrderMetrics,
                }}
            >
                <Formik
                    initialValues={{}}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={OrderSchema}
                    onSubmit={(values) => {
                        onCreateOrder(values);
                    }}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <>
                                <Header
                                    className="sticky top-0"
                                    showCancel={false}
                                    title={Messages.newOrder}
                                    customRight={() => (
                                        <>
                                            <Button
                                                size="small"
                                                className="ml-2"
                                                variant="outline"
                                                onClick={() => navigate(-1)}
                                            >
                                                {Messages.back}
                                            </Button>
                                            <PermissibleRender
                                                permission={
                                                    PERMISSION.ORDER.CREATE
                                                }
                                            >
                                                <Button
                                                    size="small"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        handleSubmit()
                                                    }
                                                >
                                                    {Messages.save}
                                                </Button>
                                            </PermissibleRender>
                                        </>
                                    )}
                                />
                                <div className="grid grid-cols-3 gap-4 p-4">
                                    <div className="col-span-2">
                                        <OrderCreateHeader
                                            type={OrderType.ORDER}
                                        />
                                        <OrderCreateProductList />
                                    </div>
                                    <div className="col-span-1">
                                        <OrderProductsCart
                                            type={OrderType.ORDER}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    }}
                </Formik>
            </OrderCreateContext.Provider>
        </div>
    );
};

export default OrderCreate;
