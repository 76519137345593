import { DateInput } from "d-react-components";
import moment from "moment";
import React from "react";
import InputSelectForm from "../../../common/input/InputSelectForm";
import InputTextForm from "../../../common/input/InputTextForm";
import { MARITAL_STATUS, RELIGIONS } from "../../../constant/customer";
import Messages from "../../../languages/Messages";
import CountrySelect from "../../address/share/CountrySelect";

interface IPrivacyInfoForm {
    form: any;
}

const PrivacyInfoForm = ({ form: customerForm }: IPrivacyInfoForm) => {
    const inputClassName = "col-12 mt-3";
    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;

    const { nationality } = formValues || {};

    return (
        <div className="row p-3">
            <CountrySelect
                allowClear={false}
                value={formValues?.nationality}
                onChange={(value) =>
                    customerForm.setFieldValue("nationality", value)
                }
                className={inputClassName}
                error={formErrors?.nationality}
            />
            <InputTextForm
                keyData="citizenId"
                form={customerForm}
                className={inputClassName}
                required={nationality?.primary}
                disabled={!nationality}
            />
            <InputTextForm
                keyData="passportNo"
                form={customerForm}
                className={inputClassName}
            />
            <DateInput
                allowClear={false}
                label={Messages.passportExp}
                onChange={(value) =>
                    customerForm.setFieldValue("passportExp", value)
                }
                value={
                    formValues.passportExp
                        ? moment(formValues.passportExp)
                        : (null as any)
                }
                error={formErrors.passportExp as any}
                format="DD/MM/YYYY"
                className={inputClassName}
            />

            <InputSelectForm
                allowClear={false}
                keyData="religion"
                form={customerForm}
                dataSource={RELIGIONS}
                className={inputClassName}
            />
            <InputSelectForm
                allowClear={false}
                keyData="maritalStatus"
                form={customerForm}
                dataSource={MARITAL_STATUS}
                className={inputClassName}
            />
            <InputTextForm
                keyData="numberOfChild"
                form={customerForm}
                className={inputClassName}
            />
        </div>
    );
};

export default PrivacyInfoForm;
