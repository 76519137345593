import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import React, { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import { BRANCH_STATUSES } from "../../../constant/branch";
import { PAYMENT_METHOD_STATUSES } from "../../../constant/payment";
import { BranchDetailContext } from "../../../context/branch";
import { PaymentMethodDetailContext } from "../../../context/payment-method";
import { IBranch } from "../../../interfaces/branch";
import { IPaymentMethod } from "../../../interfaces/payment-method";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const PaymentMethodSummaryView = ({ loading }: { loading: boolean }) => {
    const { paymentMethod } = useContext(PaymentMethodDetailContext);
    const BRANCH_DETAILS_KEY: IRowsKey<IPaymentMethod>[] = [
        {
            id: "name",
            label: Messages.paymentMethod,
        },
        {
            id: "title",
            label: Messages.title,
        },
        {
            id: "status",
            label: Messages.status,
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.updatedAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {paymentMethod && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/payment-method.svg" />
                        <div className="text-large-bold my-3">
                            <Link
                                to={generatePath(Path.PAYMENT_METHOD_DETAIL, {
                                    paymentId: paymentMethod?.id ?? "N/A",
                                })}
                            >
                                {paymentMethod?.name}
                            </Link>
                        </div>
                        <ViewLabelStatus
                            status={paymentMethod?.status}
                            listStatus={PAYMENT_METHOD_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={paymentMethod}
                            keyList={BRANCH_DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
            {/* {loading && <Skeleton count={5} />} */}
        </div>
    );
};

export default PaymentMethodSummaryView;
