import { InputTextProps, SelectProps } from "d-react-components";
import { FormikProps } from "formik";
import React from "react";
import InputTextForm from "../../../common/input/InputTextForm";
import Messages from "../../../languages/Messages";
import PhoneCodeSelect from "./PhoneCodeSelect";

export interface IPhoneCodeInputProps {
    className?: string;
    label?: string;
    form: FormikProps<any>;
    phoneCodeKey?: string;
    phoneKey?: string;
    inputTextProps?: Partial<InputTextProps>;
    selectProps?: Partial<SelectProps>;
}

const PhoneCodeInput: React.FC<IPhoneCodeInputProps> = ({
    className,
    form,
    label = Messages.phone,
    phoneCodeKey = "phoneCodeCountry",
    phoneKey = "phone",
    inputTextProps = {},
    selectProps = {},
}) => {
    const { values: formValues, errors: formErrors, setFieldValue } = form;

    return (
        <div className={`${className} `}>
            <label className="mb-0">{label}</label>
            <div className={`flex items-start `}>
                <PhoneCodeSelect
                    className="w-fit"
                    allowClear={false}
                    showLabel={false}
                    error={formErrors?.[phoneCodeKey]}
                    value={formValues?.[phoneCodeKey]}
                    onChange={(v) => setFieldValue(phoneCodeKey, v)}
                    {...selectProps as any}
                />
                <InputTextForm
                    className="w-full col-span-6"
                    showLabel={false}
                    keyData={phoneKey}
                    form={form}
                    required
                    {...inputTextProps}
                />
            </div>
        </div>
    );
};

export default PhoneCodeInput;
