import { Button, Notifications, Progress } from "d-react-components";
import { map, pick } from "lodash";
import React, { Fragment, useContext, useState } from "react";
import StockTransferAPI from "../../../../api/queries/stock-transfer";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import { IDeliveryDriver } from "../../../../interfaces/delivery-driver";
import Messages from "../../../../languages/Messages";
import DeliveryConfirmDrawer, {
    IDeliveryConfirmationInput,
} from "../../../delivery-driver/shared/DeliveryConfirmDrawer";

export interface IStockTransferCompleteDeliveryButtonProps {
    delivery: IDeliveryDriver;
}

const StockTransferCompleteDeliveryButton: React.FC<
    IStockTransferCompleteDeliveryButtonProps
> = ({ delivery }) => {
    const { stockTransfer, loadTransferDetail } = useContext(
        StockTransferDetailContext
    );

    const [openConfirmDrawer, setOpenConfirmDrawer] = useState(false);

    const onConfirmDestination = (values: IDeliveryConfirmationInput) => {
        const { receiver, giver } = values;
        const input = {
            id: delivery?.id,
            payload: {
                driver: { ...pick(giver, ["signature", "fullName"]) },
                staff: {
                    ...pick(receiver, ["signature", "remark"]),
                    proofs:
                        receiver?.proofs && receiver?.proofs?.length > 0
                            ? map(receiver?.proofs, (i) => i?.fileUrl)
                            : null,
                },
            },
        };
        return Progress.show(
            {
                method: StockTransferAPI.confirmPickUpDestination,
                params: [input],
            },
            (res) => {
                Notifications.showSuccess(Messages.confirmSuccess);
                loadTransferDetail();
            }
        );
    };
    return (
        <Fragment>
            <Button
                variant="standard"
                className="ml-3"
                onClick={() => {
                    setOpenConfirmDrawer(true);
                }}
            >
                {Messages.complete}
            </Button>
            {openConfirmDrawer && (
                <DeliveryConfirmDrawer
                    open={openConfirmDrawer}
                    onClose={() => setOpenConfirmDrawer(false)}
                    onSave={(values) => {
                        onConfirmDestination(values);
                    }}
                    receiverLabel={Messages.pickupStaff}
                    giverLabel={Messages.driver}
                />
            )}
        </Fragment>
    );
};

export default StockTransferCompleteDeliveryButton;
