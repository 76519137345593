import { Button, Notifications, Progress, TimeUtils } from "d-react-components";
import { find, isEmpty, map } from "lodash";
import React, { useContext, useState } from "react";
import {
    useGenerateCustomerHealthAssessmentCodeMutation,
    useGetCustomerHealthAssessmentQuery,
} from "../../../api/hooks";
import { CustomerDetailContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import SecretCodeView from "../share/SecretCodeView";
import {
    CustomerDetailSurveyDrawer,
    SurveyQuestion,
} from "./CustomerDetailSurvey";

const CustomerDetailHealthAssessment = () => {
    const { customer, loadCustomerDetail } = useContext(CustomerDetailContext);
    const {
        updateInfoStatus,
        updateInfo,
        healthAssessmentCode,
        healthAssessment,
    } = customer || {};

    const [openSurvey, setOpenSurvey] = useState(false);
    const surveyListRes = useGetCustomerHealthAssessmentQuery();
    const [generateCustomerHealthAssessmentCode] =
        useGenerateCustomerHealthAssessmentCodeMutation();

    const surveyQuestionList = surveyListRes?.data?.data?.data ?? [];

    const onRegenerateQrCode = () => {
        return Progress.show(
            {
                method: generateCustomerHealthAssessmentCode,
                params: [
                    {
                        variables: {
                            customerId: customer?.id as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            () => {
                Notifications.showSuccess(Messages.updateSuccess);
                loadCustomerDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    const renderQrCodeView = () => {
        if (isEmpty(healthAssessmentCode)) {
            return (
                <React.Fragment>
                    <label>{Messages.healthAssessmentForm}</label>
                    <Button
                        onClick={() => {
                            onRegenerateQrCode && onRegenerateQrCode();
                        }}
                        className="my-3"
                    >
                        {Messages.generateQrCode}
                    </Button>
                </React.Fragment>
            );
        }
        return (
            <SecretCodeView
                variant="healthAssessment"
                secretCode={healthAssessmentCode as any}
                customer={customer}
                onRegenerateQrCode={onRegenerateQrCode}
                onChangeExpiryTime={() => {
                    // setOpenExpiryTimeInput({ open: true })
                }}
            />
        );
    };

    const renderSignature = (label: any, signature: any, time: any) => {
        return (
            <div className="mt-3 border p-3">
                <label className="text-primary">{label}</label>
                <img
                    src={signature}
                    className="image-reg-x-large border mt-3"
                />
                <div className="small mt-3">
                    {`${Messages.signedOn} ${TimeUtils.convertMiliToDateTime(
                        time
                    )}`}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="bg-primary-light p-3">{renderQrCodeView()}</div>
            {healthAssessment && (
                <div className="bg-primary-light p-3 flex-row-between-center mt-3">
                    <div className="text-primary">
                        {`${
                            Messages.assessmentCompletedAt
                        } ${TimeUtils.toDateTime(healthAssessment?.updatedAt)}`}
                    </div>
                    {/* <div
                        className="text-primary text-bold cursor-pointer"
                        onClick={() => setOpenSurvey(true)}
                    >
                        {Messages.update}
                    </div> */}
                </div>
            )}
            {healthAssessment &&
                map(surveyQuestionList, (survey) => {
                    const answer = find(
                        healthAssessment?.surveyAnswers,
                        (item) => item.questionId === survey.id
                    );
                    return (
                        <SurveyQuestion
                            survey={survey}
                            answer={answer?.answers ?? []}
                            disabled
                        />
                    );
                })}
            {healthAssessment?.confirmation?.customer &&
                renderSignature(
                    Messages.clientSignature,
                    healthAssessment?.confirmation?.customer?.signature,
                    healthAssessment?.confirmation?.customer?.updatedAt
                )}
            {healthAssessment?.confirmation?.other &&
                renderSignature(
                    Messages.witnessSignature,
                    healthAssessment?.confirmation?.other?.signature,
                    healthAssessment?.confirmation?.other?.updatedAt
                )}

            {openSurvey && (
                <CustomerDetailSurveyDrawer
                    open={openSurvey}
                    onClose={() => setOpenSurvey(false)}
                    surveyList={surveyQuestionList}
                />
            )}
        </div>
    );
};

export default CustomerDetailHealthAssessment;
