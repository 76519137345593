import { gql } from "@apollo/client";
import { mapBookingFromServer } from "../../interfaces/booking";
import API from "../API";
import { F_NOTE, F_NOTES } from "../fragments/app";
import { F_BOOKING, F_BOOKINGS } from "../fragments/booking";

export const getBookingList = gql`
    ${F_BOOKINGS}
    query getBookingList($input: AdminBookingPaginateRequest!) {
        data: listBookingForAdmin(paginate: $input) {
            ...F_BOOKINGS
        }
    }
`;

export const getBookingDetail = gql`
    ${F_BOOKING}
    query getBookingDetail($id: String!) {
        data: detailBookingForAdmin(id: $id) {
            data {
                ...F_BOOKING
            }
        }
    }
`;

export const createBooking = gql`
    ${F_BOOKING}
    mutation createBooking($payload: AdminCreateBookingRequest!) {
        data: createBookingForAdmin(payload: $payload) {
            data {
                ...F_BOOKING
            }
        }
    }
`;

export const getBookingNotes = gql`
    ${F_NOTES}
    query listBookingNotesForAdmin(
        $bookingId: String!
        $paginate: NotePaginateRequest!
    ) {
        data: listBookingNotesForAdmin(
            bookingId: $bookingId
            paginate: $paginate
        ) {
            ...F_NOTES
        }
    }
`;

export const createBookingNote = gql`
    ${F_NOTE}
    mutation createBookingNoteForAdmin(
        $bookingId: String!
        $payload: NoteCreateRequest!
    ) {
        data: createBookingNoteForAdmin(
            bookingId: $bookingId
            payload: $payload
        ) {
            data {
                ...F_NOTE
            }
        }
    }
`;

export const updateBookingNote = gql`
    ${F_NOTE}
    mutation updateBookingNoteForAdmin(
        $noteId: String!
        $payload: NoteUpdateRequest!
    ) {
        data: updateBookingNoteForAdmin(noteId: $noteId, payload: $payload) {
            data {
                ...F_NOTE
            }
        }
    }
`;

export const removeBookingNote = gql`
    ${F_NOTE}
    mutation removeBookingNote($noteId: String!) {
        data: removeBookingNoteForAdmin(noteId: $noteId) {
            data {
                ...F_NOTE
            }
        }
    }
`;

export const updateBookingServices = gql`
    ${F_BOOKING}
    mutation updateBookingServicesForAdmin(
        $bookingId: String!
        $serviceIds: [String!]!
        $action: Action!
    ) {
        data: updateBookingServicesForAdmin(
            id: $bookingId
            serviceIds: $serviceIds
            action: $action
        ) {
            data {
                ...F_BOOKING
            }
        }
    }
`;

export const confirm = gql`
    ${F_BOOKING}
    mutation confirmBookingForAdmin(
        $bookingId: String!
        $input: AdminBookingConfirmRequest!
    ) {
        data: confirmBookingForAdmin(id: $bookingId, input: $input) {
            data {
                ...F_BOOKING
            }
        }
    }
`;

export const updateDoctor = gql`
    ${F_BOOKING}
    mutation updateBookingDoctorForAdmin(
        $bookingId: String!
        $input: AdminBookingUpdateDoctorRequest!
    ) {
        data: updateBookingDoctorForAdmin(id: $bookingId, input: $input) {
            data {
                ...F_BOOKING
            }
        }
    }
`;

export const cancelBooking = gql`
    ${F_BOOKING}
    mutation cancelBookingForAdmin($id: String!) {
        data: cancelBookingForAdmin(id: $id) {
            data {
                ...F_BOOKING
            }
        }
    }
`;

const bookingsConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const bookingConverter = {
    fromServer: (res: any) => {
        const detail = mapBookingFromServer(res?.data?.data?.data ?? {});
        return detail;
    },
};

const BookingAPI = {
    list: async (input: any) =>
        API.withConverter(bookingsConverter).query({
            query: getBookingList,
            variables: { input },
        }),

    detail: async (id: any) =>
        API.withConverter(bookingConverter).query({
            query: getBookingDetail,
            variables: { id },
        }),

    create: async (payload: any) =>
        API.withConverter(bookingConverter).mutate({
            mutation: createBooking,
            variables: { payload },
        }),

    cancel: async (id: string) =>
        API.withConverter(bookingConverter).mutate({
            mutation: cancelBooking,
            variables: { id },
        }),

    // Note
    noteList: async (bookingId: string, paginate: any) =>
        API.query({
            query: getBookingNotes,
            variables: { bookingId, paginate },
            fetchPolicy: "no-cache",
        }),

    createNote: async (bookingId: string, payload: any) =>
        API.mutate({
            mutation: createBookingNote,
            variables: { bookingId, payload },
        }),

    updateNote: async (noteId: string, payload: any) =>
        API.mutate({
            mutation: updateBookingNote,
            variables: { noteId, payload },
        }),

    removeNote: async (noteId: string) =>
        API.mutate({
            mutation: removeBookingNote,
            variables: { noteId },
        }),

    //Services
    addService: async (bookingId: string, serviceIds: string[]) =>
        API.withConverter(bookingConverter).mutate({
            mutation: updateBookingServices,
            variables: { bookingId, serviceIds, action: "ADD" },
        }),

    removeService: async (bookingId: string, serviceIds: string[]) =>
        API.withConverter(bookingConverter).mutate({
            mutation: updateBookingServices,
            variables: { bookingId, serviceIds, action: "REMOVE" },
        }),

    confirm: async (bookingId: string, input: any) =>
        API.withConverter(bookingConverter).mutate({
            mutation: confirm,
            variables: { bookingId, input },
        }),

    updateDoctor: async (bookingId: string, input: any) =>
        API.withConverter(bookingConverter).mutate({
            mutation: updateDoctor,
            variables: { bookingId, input },
        }),
};

export default BookingAPI;
