/* eslint-disable no-nested-ternary */
import { ObjectUtils, Select } from "d-react-components";
import {
    filter,
    includes,
    isArray,
    isEmpty,
    map,
    toLower,
    uniqBy,
} from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useGetAllCountryLazyQuery } from "../../../api/hooks";
import { ICountry } from "../../../interfaces/address";
import { IFieldSelect } from "../../../interfaces/select";
import Messages from "../../../languages/Messages";
import { base64ImgSource } from "../../../utils/Utils";

export interface IPhoneCodeSelectProps<T> extends IFieldSelect<T> {
    [key: string]: any;
    showLabel?: boolean;
}

const PhoneCodeSelect: React.FC<IPhoneCodeSelectProps<ICountry>> = ({
    value = [],
    multiple,
    className,
    onChange,
    showLabel = true,
    label,
    ...selectProps
}) => {
    const [textSearch, setTextSearch] = useState("");
    const [countryList, setCountryList] = useState<any[]>(
        isArray(value) ? value : [value]
    );
    const [getCountryList] = useGetAllCountryLazyQuery();

    useEffect(() => {
        getCountryList({
            variables: {
                paginate: {
                    page: 1,
                    limit: 10000,
                },
            },
        }).then((res: any) => {
            const countryRes = res?.data?.data?.data ?? [];
            const selectedValue = isArray(value) ? value : value ? [value] : [];
            const filterCountry = map(
                [...selectedValue, ...countryRes],
                (item) => ({
                    ...item,
                    name: item?.name,
                })
            );
            const uniqCountry = uniqBy(filterCountry, (item) => item?.id);
            setCountryList(uniqCountry);
        });
    }, []);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(countryList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(countryList, id));
    };

    const countryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    const dataSourceList = useMemo(() => {
        if (isEmpty(textSearch)) return countryList;
        return filter(countryList, (item) =>
            includes(
                toLower(`${item.name}${item.phoneCode}`),
                toLower(textSearch)
            )
        );
    }, [countryList, textSearch]);

    return (
        <Select
            className={className}
            label={showLabel ? label || Messages.country : undefined}
            dataSource={dataSourceList}
            getLabel={(item) => (
                <div className="flex items-center">
                    <div className="mr-2">
                        {item?.flag && (
                            <img
                                style={{ width: "25px" }}
                                src={base64ImgSource(item?.flag)}
                            />
                        )}
                    </div>
                    <div>{item?.phoneCode}</div>
                </div>
            )}
            value={countryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.code}
            showSearch
            filterOption={false}
            onSearch={(text) => setTextSearch(text)}
            getKey={(item) => item?.id}
            {...selectProps}
        />
    );
};

export default PhoneCodeSelect;
