import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";

const BookingDetailActivity = () => {
    const { bookingId } = useParams<any>();
    return (
        <div>
            {bookingId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.Booking}
                    activityRefId={bookingId ?? ""}
                />
            )}
        </div>
    );
};

export default BookingDetailActivity;
