import * as Yup from "yup";

export const PromotionSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        branch: Yup.array().min(1).required("Required field!"),
        title: Yup.string().nullable().required("Required field!"),
        start: Yup.string().nullable().required("Required field!"),
        end: Yup.string().nullable().required("Required field!"),
    });
    // if (value.typeOfIssuance === VOUCHER_ISSUANCE_TYPE.MANUAL) {
    //     schema = schema.shape({
    //         code: Yup.string().min(6).max(20).required("Required field!"),
    //     });
    // }
    // if (value.typeOfIssuance === VOUCHER_ISSUANCE_TYPE.AUTO_GENERATED) {
    //     schema = schema.shape({
    //         quantity: Yup.number().required("Required field!"),
    //         cashValue: Yup.number().required("Required field!"),
    //         usageLimit: Yup.number().required("Required field!"),
    //     });
    // }
    return schema;
});
