import {
    Button,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import StockTransferAPI from "../../../../api/queries/stock-transfer";
import ActionMenu from "../../../../common/ActionButton";
import Drawer from "../../../../common/Drawer";
import InputTextForm from "../../../../common/input/InputTextForm";
import {
    STOCK_TRANSFER_STATUS,
    STOCK_TRANSFER_STATUSES,
} from "../../../../constant/stock-transfer";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import Messages from "../../../../languages/Messages";

interface IStockTransferConfirmDrawer {
    open: boolean;
    title?: string;
    description?: string;
    onClose: () => void;
    onConfirm: (props: any) => void;
}

const StockTransferConfirmDrawer = ({
    open,
    onClose,
    onConfirm,
    title,
    description,
}: IStockTransferConfirmDrawer) => {
    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            remark: Yup.string().required("Is Required!"),
        }),
        onSubmit: (values: any) => {
            onClose && onClose();
            onConfirm(values);
        },
    });

    const formValues = confirmForm?.values;
    const formErrors = confirmForm?.errors;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={title}
            onSave={() => confirmForm.handleSubmit()}
            size="auto"
            width="40%"
        >
            {description && <p className="text px-4 mt-4">{description}</p>}
            <div className="px-4">
                <InputTextForm form={confirmForm} keyData="remark" multiple />
            </div>
        </Drawer>
    );
};

const StockTransferHeader = () => {
    const { stockTransfer, setStockTransfer } = useContext(
        StockTransferDetailContext
    );
    const [openConfirm, setOpenConfirm] = useState<{
        open: boolean;
        type?: "approve" | "cancel";
    }>({ open: false });

    const isShowConfirm = useMemo(() => {
        return stockTransfer?.status === STOCK_TRANSFER_STATUS.PENDING;
    }, [stockTransfer?.status]);

    const onClickAction = (item: any) => {
        if (item.id === "cancelRequest") {
            setOpenConfirm({ open: true, type: "cancel" });
        }
    };

    const onCancelTransfer = (input: any) => {
        Progress.show(
            {
                method: StockTransferAPI.cancel,
                params: [
                    { id: stockTransfer?.id, remark: input?.remark ?? null },
                ],
            },
            (res: any) => {
                if (res) {
                    Notifications.showSuccess(Messages.cancelSuccess);
                    setStockTransfer(res);
                }
            }
        );
    };

    return (
        <div className="bg-white p-3 flex-row-between-center border-bottom">
            <div className="d-flex">
                <h5 className="text-primary">{`${stockTransfer?.transferNo}`}</h5>
                <ViewLabelStatus
                    status={stockTransfer?.status}
                    listStatus={STOCK_TRANSFER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                    className="ml-3"
                />
            </div>
            <div className="d-flex">
                <Button
                    variant="outline"
                    size="small"
                    onClick={() => {}}
                    className="ml-2"
                >
                    {Messages.print}
                </Button>
                <ActionMenu
                    className="ml-2"
                    dataSource={[
                        {
                            id: "cancelRequest",
                            label: Messages.cancelRequest,
                        },
                    ]}
                    onClick={onClickAction}
                />
            </div>
            {openConfirm?.open && (
                <StockTransferConfirmDrawer
                    title={
                        openConfirm?.type === "approve"
                            ? Messages.approvalConfirmation
                            : Messages.cancelConfirmation
                    }
                    description={
                        openConfirm?.type === "approve"
                            ? Messages.approvalRequestedConfirmationDes
                            : Messages.cancelRequestedConfirmationDes
                    }
                    open={openConfirm?.open}
                    onClose={() => setOpenConfirm({ open: false })}
                    onConfirm={(input) =>
                        openConfirm?.type === "approve"
                            ? {}
                            : onCancelTransfer(input)
                    }
                />
            )}
        </div>
    );
};

export default StockTransferHeader;
