import { Header, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find, forEach } from "lodash";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useCreateProductMutation } from "../../../api/hooks";
import ProductAPI from "../../../api/queries/product";
import { PRODUCT_TYPE } from "../../../constant/product";
import { ProductCreateContext } from "../../../context/product";
import { ProductSchema } from "../../../formschema/product";
import {
    getPriceDynamicItemInSpecialGroupProduct,
    mapProductToServer,
} from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductCreateConfiguration from "./ProductCreateConfiguration";
import ProductCreateGallery from "./ProductCreateGallery";
import ProductCreateGiftVoucherConfig from "./ProductCreateGiftVoucherConfig";
import ProductCreateInfo from "./ProductCreateInfo";
import ProductCreateMedicineConfig from "./ProductCreateMedicineConfig";

const ProductCreate = () => {
    const { duplicateId } = useParams<any>();
    const navigate = useNavigate();
    const [createProduct] = useCreateProductMutation();

    const productForm = useFormik<any>({
        initialValues: {
            gallery: [],
            typeOfProduct: PRODUCT_TYPE.SIMPLE_PRODUCT,
            brand: "dermaster",
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values) => {
            const errors: any = {};
            const { typeOfProduct, specialGroups } = values;
            if (typeOfProduct === PRODUCT_TYPE.SPECIAL_GROUP_SERVICE) {
                const dynamicPriceProduct = find(
                    specialGroups,
                    (item) => item.isDynamicPrice
                );
                const priceDynamicProduct =
                    getPriceDynamicItemInSpecialGroupProduct(
                        dynamicPriceProduct?.id,
                        values
                    );

                if (priceDynamicProduct < 0) {
                    errors.configuration = Messages.dynamicItemPriceNoLess0;
                }
            }

            return errors;
        },
        validationSchema: ProductSchema,
        onSubmit: (values: any) => {
            const input = mapProductToServer(values);
            onCreateProduct(input);
        },
    });

    useEffect(() => {
        if (duplicateId && duplicateId?.length === 24) {
            loadProductDetail();
        }
    }, [duplicateId]);

    const loadProductDetail = () =>
        Progress.show(
            {
                method: ProductAPI.detail,
                params: [duplicateId],
            },
            (data: any) => {
                productForm.setValues({
                    ...(data || {}),
                    visibility: data?.isVisibility,
                    brand: "dermaster",
                    gallery: [],
                });
            }
        );

    useEffect(() => {
        forEach(Object.keys(productForm.errors), (key) =>
            Notifications.showError(`${productForm.errors[key]}`)
        );
    }, [productForm.errors]);

    const onCreateProduct = (payload: any) => {
        const body = {
            variables: {
                payload,
            },
        };
        Progress.show({ method: createProduct, params: [body] }, (res: any) => {
            const productId = res?.data?.data?.data?.id;
            Notifications.showSuccess(Messages.createSuccess);
            navigate(generatePath(Path.PRODUCT_DETAIL, { productId }));
        });
    };

    return (
        <ProductCreateContext.Provider
            value={{
                productForm,
            }}
        >
            <Header
                className="sticky top-0"
                showCancel={false}
                title={Messages.productCreate}
                onSave={() => productForm.handleSubmit()}
            />
            <div className="h-100 overflow-auto p-3 bg-white mb-5">
                <ProductCreateInfo />
                <ProductCreateGallery />
                <ProductCreateMedicineConfig />
                <ProductCreateConfiguration />
                <ProductCreateGiftVoucherConfig />
            </div>
        </ProductCreateContext.Provider>
    );
};

export default ProductCreate;
