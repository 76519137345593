import { Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import StockRequestAPI from "../../../../api/queries/stock-request";
import { StockRequestDetailContext } from "../../../../context/stock-request";
import Messages from "../../../../languages/Messages";
import StockRequestActivity from "./StockRequestActivity";
import StockRequestHeader from "./StockRequestHeader";
import StockRequestSummary from "./StockRequestSummary";
import StockRequestGeneral from "./StockRequestGeneral";
import StockRequestItems from "./StockRequestItems";

const TABS = [
    {
        id: "general",
        label: Messages.generalInformation,
        component: <StockRequestGeneral />,
    },
    {
        id: "service",
        label: Messages.items,
        component: <StockRequestItems />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <StockRequestActivity />,
    },
];

const StockRequestDetail = () => {
    const { stockRequestId } = useParams<any>();
    const [stockRequest, setStockRequest] = useState<any>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadBookingDetail();
    }, []);

    const loadBookingDetail = () => {
        Progress.show(
            {
                method: StockRequestAPI.detail,
                params: [stockRequestId],
            },
            setStockRequest as any
        );
    };

    if (!stockRequest) {
        return <div />;
    }

    return (
        <StockRequestDetailContext.Provider
            value={{ stockRequest, setStockRequest }}
        >
            <div className="flex-column-container bg-white">
                <StockRequestHeader />
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <StockRequestSummary />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </StockRequestDetailContext.Provider>
    );
};

export default StockRequestDetail;
