import classNames from "classnames";
import { JobTypeAdmin, TypeOfProduct } from "../../../api/hooks";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import Messages from "../../../languages/Messages";
import BranchSelect from "../../branch/share/BranchSelect";
import CustomerSelect from "../../customer/share/CustomerSelect";
import ProductSelect from "../../product/share/ProductSelect";
import UserSelect from "../../user/share/UserSelect";

interface IDoctorOrderFilterForm {
    filterForm: any;
}

const DoctorOrderFilterForm = ({ filterForm }: IDoctorOrderFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="px-2 py-3">
            <BranchSelect
                className={classNameInput}
                value={formValues?.branch}
                onChange={(value) => filterForm.setFieldValue("branch", value)}
                multiple
            />
            <InputSelectForm
                keyData="status"
                dataSource={DOCTOR_ORDER_STATUSES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <UserSelect
                className={classNameInput}
                value={formValues?.doctor}
                onChange={(value) => filterForm.setFieldValue("doctor", value)}
                label={Messages.doctor}
                multiple
                params={{ jobType: JobTypeAdmin.Doctor }}
            />
            <CustomerSelect
                className={classNameInput}
                value={formValues?.customer}
                onChange={(value: any) =>
                    filterForm.setFieldValue("customer", value)
                }
                label={Messages.customer}
                multiple
            />
            <ProductSelect
                className={`${classNameInput}`}
                value={formValues?.services}
                onChange={(values: any) => {
                    filterForm.setFieldValue("services", values);
                }}
                label={Messages.services}
                multiple
                customQuery={{
                    typeOfProduct: TypeOfProduct.ServiceProduct,
                }}
            />
            <UserSelect
                className={classNameInput}
                value={formValues?.createdBy}
                onChange={(value) =>
                    filterForm.setFieldValue("createdBy", value)
                }
                label={Messages.createdBy}
                multiple
            />
        </div>
    );
};

export default DoctorOrderFilterForm;
