import {
    IRowsKey,
    Notifications,
    ViewRowInterchange,
    Progress,
} from "d-react-components";
import React, { useContext, useState } from "react";
import { useUpdateQuotationForAdminMutation } from "../../../api/hooks";
import { QuotationDetailContext } from "../../../context/quotation";
import { IQuotation } from "../../../interfaces/quotation";
import Messages from "../../../languages/Messages";
import NewCustomerDrawer from "../share/NewCustomerDrawer";

const CONTACT_KEYS: IRowsKey<IQuotation["contact"]>[] = [
    {
        id: "firstName",
        label: Messages.firstName,
    },
    {
        id: "lastName",
        label: Messages.lastName,
    },
    {
        id: "phone",
        label: Messages.phone,
    },
    {
        id: "email",
        label: Messages.email,
    },
];

const QuotationContactInformation = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const { quotation, loadQuotationDetail } = useContext(
        QuotationDetailContext
    );
    const [updateQuotation] = useUpdateQuotationForAdminMutation();

    const onUpdateContact = (contact: any) => {
        Progress.show(
            {
                method: updateQuotation,
                params: [
                    {
                        variables: {
                            id: quotation?.id as string,
                            payload: {
                                contact,
                            },
                        },
                    },
                ],
            },
            (resp) => {
                Notifications.showSuccess(Messages.updateQuotationSuccessfull);
                loadQuotationDetail();
                setShowEditModal(false);
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.contactInformation}
                </h5>
                {!quotation?.hasOrder && (
                    <span
                        className="cursor-pointer text-red-500"
                        onClick={() => setShowEditModal(true)}
                    >
                        {Messages.edit}
                    </span>
                )}
            </div>
            <ViewRowInterchange
                keyList={CONTACT_KEYS}
                dataSource={quotation?.contact ?? {}}
                variant="background"
            />
            {showEditModal && (
                <NewCustomerDrawer
                    open={showEditModal}
                    onSave={onUpdateContact}
                    onClose={() => setShowEditModal(false)}
                    contact={quotation?.contact ?? {}}
                />
            )}
        </div>
    );
};

export default QuotationContactInformation;
