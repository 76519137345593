import { Avatar, AvatarProps } from "d-react-components";
import { isUndefined } from "lodash";
import React, { useContext } from "react";
import { AppStateContext } from "../../context/app";
import { getCustomerFullNameByLanguage } from "../../utils/Utils";

interface IUserAvatarName {
    user: any;
    placeholder?: any;
    size?: AvatarProps["size"];
}

const CustomerName = ({
    user,
    placeholder = "N/A",
    size = "xx-small",
}: IUserAvatarName) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    if (!user) return placeholder;
    const isGuest = !isUndefined(user?.firstName);

    const customerName = isGuest
        ? `${user.firstName} ${user.lastName}`
        : getCustomerFullNameByLanguage(user);
    const content = (
        <React.Fragment>
            <Avatar
                src={user?.avatar}
                size={size}
                text={customerName}
                variant="square"
            />
            <div className="ml-2 font-semibold">{`${customerName}`}</div>
        </React.Fragment>
    );
    if (isGuest) {
        return <div className="flex-center-y">{content}</div>;
    }

    return (
        <div
            className="flex-center-y a hover:cursor-pointer"
            onClick={() =>
                setOpenAppDrawer({ open: true, customerId: user?.id })
            }
        >
            {content}
        </div>
    );
};

export default CustomerName;
