import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useUpdatePaymentMethodSettingMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { PAYMENT_METHOD_STATUS } from "../../../constant/payment";
import { PaymentMethodDetailContext } from "../../../context/payment-method";
import { PaymentMethodGeneralSchema } from "../../../formschema/payment-method";
import {
    IPaymentMethod,
    mapPaymentMethodGeneralInfoToServer,
    PaymentMethodType,
} from "../../../interfaces/payment-method";
import Messages from "../../../languages/Messages";
import PaymentMethodGeneralForm from "./form/PaymentMethodGeneralForm";

const GENERAL_KEYS_2C2P: IRowsKey<IPaymentMethod>[] = [
    {
        id: "name",
        label: Messages.paymentMethod,
    },
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => {
            return data === PAYMENT_METHOD_STATUS.ENABLE
                ? Messages.enabled
                : Messages.disabled;
        },
    },
    {
        id: "configuration2C2P",
        label: Messages.merchantId,
        renderContent: ({ data }) => {
            return data?.merchantId ?? "N/A";
        },
    },

    {
        id: "configuration2C2P",
        label: Messages.secretKey,
        renderContent: ({ data }) => {
            const showKey = data?.secretKey
                ? `${data?.secretKey?.slice(0, 15)}****`
                : null;
            return (
                <div className="flex-center-y">
                    {showKey ?? "N/A"}
                    {showKey && (
                        <Button
                            variant="trans"
                            iconName="content_copy"
                            onClick={() => {
                                navigator.clipboard.writeText(data?.secretKey);
                            }}
                        />
                    )}
                </div>
            );
        },
    },
    {
        id: "configuration2C2P",
        label: Messages.mode,
        renderContent: ({ data }) => {
            return data?.mode ?? "N/A";
        },
    },
    {
        id: "priority",
        label: Messages.priority,
    },
    {
        id: "updatedAt",
        label: Messages.updatedAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
];

const GENERAL_KEYS_BANK_TRANSFER_CASH: IRowsKey<IPaymentMethod>[] = [
    {
        id: "name",
        label: Messages.paymentMethod,
    },
    {
        id: "title",
        label: Messages.title,
    },
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => {
            return data === PAYMENT_METHOD_STATUS.ENABLE
                ? Messages.enabled
                : Messages.disabled;
        },
    },
    {
        id: "description",
        label: Messages.description,
    },
    {
        id: "priority",
        label: Messages.priority,
    },
    {
        id: "updatedAt",
        label: Messages.updatedAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
];

const GENERAL_KEYS_THAI_QR: IRowsKey<IPaymentMethod>[] = [
    {
        id: "name",
        label: Messages.paymentMethod,
    },
    {
        id: "title",
        label: Messages.title,
    },
    {
        id: "status",
        label: Messages.status,
        renderContent: ({ data }) => {
            return data === PAYMENT_METHOD_STATUS.ENABLE
                ? Messages.enabled
                : Messages.disabled;
        },
    },
    {
        id: "description",
        label: Messages.remark,
    },
    {
        id: "priority",
        label: Messages.priority,
    },
    {
        id: "updatedAt",
        label: Messages.updatedAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
        },
    },
];

const PaymentMethodGeneralInformation = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const { paymentMethod } = useContext(PaymentMethodDetailContext);
    const { paymentConfigurationType } = paymentMethod || {};
    const keyList = React.useMemo(() => {
        if (paymentConfigurationType === PaymentMethodType.PAYMENT_2C2P) {
            return GENERAL_KEYS_2C2P;
        }
        if (
            paymentConfigurationType === PaymentMethodType.BANK_TRANSFER ||
            paymentConfigurationType === PaymentMethodType.CASH ||
            paymentConfigurationType ===
                PaymentMethodType.EMPLOYEE_SALARY_DEDUCTION ||
            paymentConfigurationType === PaymentMethodType.PAID_BY_CHECK
        ) {
            return GENERAL_KEYS_BANK_TRANSFER_CASH;
        }
        if (paymentConfigurationType === PaymentMethodType.THAI_QR_PAYMENT) {
            return GENERAL_KEYS_THAI_QR;
        }
        return GENERAL_KEYS_BANK_TRANSFER_CASH;
    }, [paymentConfigurationType]);

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-2">
                <h5 className="text-[16px] flex-1">
                    {Messages.generalInformation}
                </h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <ViewRowInterchange
                keyList={keyList}
                dataSource={paymentMethod}
                variant="background"
            />
            {showEditModal && (
                <ModalEditGeneralInformation
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                />
            )}
        </div>
    );
};

const ModalEditGeneralInformation = ({ open, onClose }: any) => {
    const [updatePaymentMethodSetting] =
        useUpdatePaymentMethodSettingMutation();
    const { paymentMethod, loadPaymentMethodDetail } = useContext(
        PaymentMethodDetailContext
    );

    const generalForm = useFormik({
        initialValues: { ...(paymentMethod || {}) } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PaymentMethodGeneralSchema,
        onSubmit: (values) => {
            onUpdateData(values);
        },
    });
    const onUpdateData = (input: any) => {
        const payload = mapPaymentMethodGeneralInfoToServer(input);
        const body = {
            variables: {
                id: paymentMethod?.id,
                payload,
            },
        };
        Progress.show(
            {
                method: updatePaymentMethodSetting,
                params: [body],
            },
            (resp: any) => {
                Notifications.showSuccess(
                    Messages.updatePaymentMethodSuccessfully
                );
                loadPaymentMethodDetail();
                onClose();
            }
        );
    };
    const { handleSubmit, values, errors } = generalForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.generalInformation}
            onSave={() => {
                handleSubmit();
            }}
        >
            <PaymentMethodGeneralForm generalForm={generalForm} />
        </Drawer>
    );
};

export default PaymentMethodGeneralInformation;
