import {
    AwesomeTableComponent,
    Button,
    DateInput,
    IColumnsProps,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import React, { useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { generatePath } from "react-router";
import {
    OrderStatus,
    RefActivity,
    useChangeUpdateInfoCodeExpiryTimeMutation,
    useGenerateUpdateInfoCodeMutation,
    useGetCustomerOrderHistoryLazyQuery,
    useGetCustomerTreatmentHistoryLazyQuery,
} from "../../../api/hooks";
import ActivityView from "../../../common/activity/ActivityView";
import AppLink from "../../../common/AppLink";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import { BOOKING_STATUSES } from "../../../constant/booking";
import { ORDER_STATUSES } from "../../../constant/order";
import { TREATMENT_STATUSES } from "../../../constant/treatment";
import { CustomerDetailContext } from "../../../context/customer";
import { CustomerUpdateInfoStatus } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import SecretCodeView from "../share/SecretCodeView";
import { TABS } from "./CustomerDetail";
import CustomerUpdateInfoDrawer from "./CustomerUpdateInfoDrawer";

const CustomerDetailDashboard = () => {
    const { customer, setTab } = useContext(CustomerDetailContext);
    const [orderResponse, setOrderResponse] = useState<any>();
    const [treatmentResponse, setTreatmentResponse] = useState<any>();
    const [getCustomerOrderHistory] = useGetCustomerOrderHistoryLazyQuery();
    const [getCustomerTreatmentHistory] =
        useGetCustomerTreatmentHistoryLazyQuery();

    useEffect(() => {
        if (customer?.id) {
            loadTableData();
        }
    }, [customer?.id]);

    const loadTableData = async () => {
        const [orderResponse, treatmentResponse] = await Promise.all([
            getCustomerOrderHistory({
                variables: {
                    customerId: customer?.id as string,
                    paginate: {
                        page: 1,
                        limit: 10,
                    },
                },
                fetchPolicy: "no-cache",
            }),
            getCustomerTreatmentHistory({
                variables: {
                    customerId: customer?.id as string,
                    paginate: {
                        page: 1,
                        limit: 10,
                    },
                },
                fetchPolicy: "no-cache",
            }),
        ]);
        setOrderResponse(orderResponse);
        setTreatmentResponse(treatmentResponse);
    };

    const treatmentColumn: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "treatmentNo",
            render: (treatmentNo, item) => (
                <AppLink
                    to={generatePath(Path.TREATMENT_DETAIL, {
                        treatmentId: item.id,
                    })}
                >
                    {treatmentNo}
                </AppLink>
            ),
        },
        {
            title: Messages.doctorOrder,
            dataIndex: "doctorOrder",
            render: (doctorOrder, item) => (
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: doctorOrder.id,
                    })}
                >
                    {doctorOrder?.doctorOrderNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={TREATMENT_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },

        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor) => (
                <UserAvatarName
                    user={doctor?.adminUser}
                    size="xx-small"
                    variant="square"
                    showInfo={{ showEmployeeId: false, showOPD: false }}
                />
            ),
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const purchaseColumn: IColumnsProps = [
        {
            title: Messages.orderId,
            dataIndex: "orderNo",
            width: 100,
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={ORDER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.amount,
            dataIndex: "total",
            render: (amount) => (
                <CurrencyFormat
                    value={amount}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
            width: "100px",
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];
    const renderStaticInfo = (title: any, content: any) => {
        return (
            <div className="flex-column col-4 mt-3">
                <small>{title}</small>
                <div className="font-weight-bold">{content}</div>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <CustomerSelfRegistrationForm />
            <div className="border p-3">
                <h5>{Messages.customerStatic}</h5>
                <div className="row ">
                    {renderStaticInfo(Messages.lifetimeSpending, "19000THB")}
                    {renderStaticInfo(Messages.lifeTimePurchase, "5 orders")}
                    {renderStaticInfo(
                        Messages.averageTimeBetweenOrder,
                        "17 days"
                    )}
                    {renderStaticInfo(Messages.averageOrderValue, "1600 THB")}
                    {renderStaticInfo(Messages.lastPurchase, "158 days ago")}
                    {renderStaticInfo(Messages.lastActive, "12 days ago")}
                </div>
            </div>
            <div className="border p-3 mt-3">
                <div className="flex flex-row items-center w-full justify-between">
                    <h5>{Messages.recentTreatments}</h5>
                    {treatmentResponse?.data?.data?.pagination?.items > 3 && (
                        <Button
                            size="small"
                            variant="trans"
                            color="secondary"
                            onClick={() =>
                                setTab(
                                    TABS.find(
                                        (item) =>
                                            item?.id === "treatmentHistory"
                                    )
                                )
                            }
                        >
                            {Messages.viewAll}
                        </Button>
                    )}
                </div>
                <AwesomeTableComponent
                    pagination={false}
                    columns={treatmentColumn}
                    dataSource={(
                        treatmentResponse?.data?.data?.data || []
                    ).slice(0, 3)}
                    className="mt-3"
                />
            </div>
            <div className="border p-3 mt-3">
                <div className="flex flex-row items-center w-full justify-between">
                    <h5>{Messages.recentPurchaseHistory}</h5>
                    {orderResponse?.data?.data?.pagination?.items > 3 && (
                        <Button
                            size="small"
                            variant="trans"
                            color="secondary"
                            onClick={() =>
                                setTab(
                                    TABS.find(
                                        (item) => item?.id === "orderHistory"
                                    )
                                )
                            }
                        >
                            {Messages.viewAll}
                        </Button>
                    )}
                </div>
                <AwesomeTableComponent
                    pagination={false}
                    columns={purchaseColumn}
                    dataSource={(orderResponse?.data?.data?.data || []).slice(
                        0,
                        3
                    )}
                    className="mt-3"
                />
            </div>
            <ActivityView
                className="mt-3 border"
                activityRef={RefActivity?.Customer}
                activityRefId={customer?.id ?? ""}
            />
        </div>
    );
};

export default CustomerDetailDashboard;

const CustomerSelfRegistrationForm = () => {
    const { customer, loadCustomerDetail } = useContext(CustomerDetailContext);
    const { updateInfoStatus, updateInfo, updateInfoCode } = customer || {};
    const [openUpdateInfoDrawer, setOpenUpdateInfoDrawer] = useState(false);
    const [openExpiryTimeInput, setOpenExpiryTimeInput] = useState<{
        open: boolean;
        expiryTime?: any;
    }>({ open: false });
    const [generateUpdateInfoCode] = useGenerateUpdateInfoCodeMutation();
    const [changeUpdateInfoCodeExpiryTime] =
        useChangeUpdateInfoCodeExpiryTimeMutation();

    if (
        updateInfoStatus === CustomerUpdateInfoStatus.APPROVED ||
        !updateInfoStatus
    ) {
        return <div />;
    }

    const columns: IColumnsProps = [
        {
            title: Messages.submittedDate,
            dataIndex: "updatedAt",
            render: (data, item) =>
                data ? TimeUtils.convertMiliToDateTime(data) : "N/A",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            width: 200,
            render: (status) => (
                <ViewLabelStatus
                    status="PENDING"
                    listStatus={BOOKING_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "status",
            align: "center",
            width: 200,
            render: (data) => (
                <div className="w-full flex justify-center">
                    <Button
                        iconName="visibility"
                        variant="trans"
                        color="secondary"
                        onClick={() => {
                            setOpenUpdateInfoDrawer(true);
                        }}
                    />
                </div>
            ),
        },
    ];

    const onRegenerateQrCode = () => {
        return Progress.show(
            {
                method: generateUpdateInfoCode,
                params: [
                    {
                        variables: {
                            customerId: customer?.id as string,
                        },
                    },
                ],
            },
            () => {
                Notifications.showSuccess(Messages.updateSuccess);
                loadCustomerDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    const onChangeExpiryTime = () => {
        return Progress.show(
            {
                method: changeUpdateInfoCodeExpiryTime,
                params: [
                    {
                        variables: {
                            codeId: updateInfoCode?.id as string,
                            payload: {
                                expiryTime: openExpiryTimeInput.expiryTime,
                            },
                        },
                    },
                ],
            },
            () => {
                setOpenExpiryTimeInput({ open: false });
                Notifications.showSuccess(Messages.updateSuccess);
                loadCustomerDetail();
            },
            (err: any) => {
                Notifications.showError(err);
            }
        );
    };

    return (
        <div className="bg-primary-light p-4 mb-3 border">
            {updateInfoCode && (
                <SecretCodeView
                    variant="updateInfo"
                    secretCode={updateInfoCode}
                    customer={customer}
                    onRegenerateQrCode={onRegenerateQrCode}
                    onChangeExpiryTime={() =>
                        setOpenExpiryTimeInput({ open: true })
                    }
                />
            )}
            {updateInfo && <div className="divider my-3 bg-gray-600" />}
            {updateInfo && (
                <div className="">
                    <label className="">{Messages.profileUpdateRequest}</label>
                    <p className="text">
                        {Messages.thisCustomerJustMakeAnModification}
                    </p>
                    <AwesomeTableComponent
                        pagination={false}
                        columns={columns}
                        dataSource={[updateInfo]}
                        className="mt-3"
                    />
                </div>
            )}
            {openUpdateInfoDrawer && (
                <CustomerUpdateInfoDrawer
                    open={openUpdateInfoDrawer}
                    onClose={() => setOpenUpdateInfoDrawer(false)}
                />
            )}
            {openExpiryTimeInput?.open && (
                <Modal
                    title={Messages.expiryTime}
                    open={openExpiryTimeInput.open}
                    onClose={() => setOpenExpiryTimeInput({ open: false })}
                    disabledSaveButton={!openExpiryTimeInput?.expiryTime}
                    onSave={onChangeExpiryTime}
                    maskClosable={false}
                >
                    <DateInput
                        format="DD/MM/YYYY HH:mm"
                        showTime
                        value={openExpiryTimeInput?.expiryTime ?? null}
                        onChange={(v) =>
                            setOpenExpiryTimeInput({
                                ...openExpiryTimeInput,
                                expiryTime: v,
                            })
                        }
                    />
                </Modal>
            )}
        </div>
    );
};
