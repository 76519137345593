import { gql } from "@apollo/client";
import API from "../API";
import { F_VOUCHER, F_VOUCHERS } from "../fragments/voucher";

export const getVouchers = gql`
    ${F_VOUCHERS}
    query getVoucherList($input: AdminVoucherPaginateRequest!) {
        data: listVoucherForAdmin(paginate: $input) {
            ...F_VOUCHERS
        }
    }
`;

export const detailVoucher = gql`
    ${F_VOUCHER}
    query detailVoucher($id: String!) {
        data: detailVoucherForAdmin(id: $id) {
            data {
                ...F_VOUCHER
            }
        }
    }
`;

export const detailVoucherByCode = gql`
    ${F_VOUCHER}
    query detailVoucherByCodeForAdmin($code: String!) {
        data: detailVoucherByCodeForAdmin(code: $code) {
            data {
                ...F_VOUCHER
            }
        }
    }
`;

export const createVoucher = gql`
    ${F_VOUCHER}
    mutation createVoucher($input: AdminVoucherCreateRequest!) {
        data: createVoucherForAdmin(payload: $input) {
            data {
                ...F_VOUCHER
            }
        }
    }
`;

export const updateVoucher = gql`
    ${F_VOUCHER}
    mutation updateVoucher($id: String!, $input: AdminVoucherUpdateRequest!) {
        data: updateVoucherForAdmin(id: $id, payload: $input) {
            data {
                ...F_VOUCHER
            }
        }
    }
`;

export const cancelVoucher = gql`
    ${F_VOUCHER}
    mutation cancelVoucher($id: String!, $remark: String!) {
        data: cancelVoucherForAdmin(id: $id, remark: $remark) {
            data {
                ...F_VOUCHER
            }
        }
    }
`;

const vouchersConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const voucherConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? [];
        return detail;
    },
};

const VoucherAPI = {
    list: async (input: any) =>
        API.withConverter(vouchersConverter).query({
            query: getVouchers,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    listForSelect: async (input: any) =>
        API.withConverter(vouchersConverter).query({
            query: gql`
                ${F_VOUCHERS}
                query listVoucherForSelectAdmin(
                    $input: AdminVoucherPaginateRequest!
                ) {
                    data: listVoucherForSelectAdmin(paginate: $input) {
                        ...F_VOUCHERS
                    }
                }
            `,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    create: async (input: any) =>
        API.withConverter(voucherConverter).mutate({
            mutation: createVoucher,
            variables: { input },
        }),

    cancel: async (id: any, remark: string) =>
        API.withConverter(voucherConverter).mutate({
            mutation: cancelVoucher,
            variables: { id, remark },
        }),

    detail: async (id: any) =>
        API.withConverter(voucherConverter).query({
            query: detailVoucher,
            variables: { id },
        }),

    updateRemark: async (id: any, remark: string) =>
        API.withConverter(voucherConverter).mutate({
            mutation: updateVoucher,
            variables: { id, input: { remark } },
        }),
};

export default VoucherAPI;
