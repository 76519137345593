import classNames from "classnames";
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { forEach, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import {
    useUpdateCustomerPersonalInfoMutation,
    useUpdateCustomerPrivacyInfoMutation,
    useUpdateCustomerContactInfoMutation,
    useUpdateCustomerFamilyEmergencyMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { CUSTOMER_PHONE_NUMBER_STATUS } from "../../../constant/customer";
import { CustomerDetailContext } from "../../../context/customer";
import {
    CustomerContactSchema,
    CustomerEmergencyContactSchema,
    CustomerFamilyInfoSchema,
    CustomerPersonalInfoSchema,
    CustomerPrivacyInfoSchema,
} from "../../../formschema/customer";
import {
    ICustomer,
    mapCustomerContactToServer,
    mapCustomerEmergencyToServer,
    mapCustomerFamilyInfoToServer,
    mapCustomerPersonalInfoToServer,
    mapCustomerPrivacyInfoToServer,
    PhoneNumberStatus,
} from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import AgencySelectedView from "../../agency/share/AgencySelectedView";
import UserSelectedView from "../../user/share/UserSelectedView";
import ContactForm from "../share/ContactForm";
import EmergencyContactForm from "../share/EmergencyContactForm";
import FamilyInfoForm from "../share/FamilyInfoForm";
import PersonalInfoForm from "../share/PersonalInfoForm";
import PrivacyInfoForm from "../share/PrivacyInfoForm";
import CustomerDetailVerifyPhone from "./CustomerDetailVerifyButtons";

const UpdatePersonalInfoDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);

    const [updateCustomerPersonal] = useUpdateCustomerPersonalInfoMutation();

    const customerForm = useFormik<any>({
        initialValues: customer as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerPersonalInfoSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerPersonalInfoToServer(values);
            onUpdatePersonalInfo(input);
        },
    });

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateCustomerPersonal,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.personalInfo}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <div className="p-3">
                <PersonalInfoForm form={customerForm} />
            </div>
        </Drawer>
    );
};

const UpdatePrivacyInfoDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);

    const [updateCustomerPrivacy] = useUpdateCustomerPrivacyInfoMutation();

    const customerForm = useFormik<any>({
        initialValues: customer as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerPrivacyInfoSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerPrivacyInfoToServer(values);
            onUpdatePersonalInfo(input);
        },
    });

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateCustomerPrivacy,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.privacyInfo}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <PrivacyInfoForm form={customerForm} />
        </Drawer>
    );
};

const UpdateContactDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);

    const [updateCustomerContact] = useUpdateCustomerContactInfoMutation();

    const customerForm = useFormik<any>({
        initialValues: customer as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerContactSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerContactToServer(values);
            onUpdatePersonalInfo(input);
        },
    });

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateCustomerContact,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.contact}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <ContactForm form={customerForm} />
        </Drawer>
    );
};

const UpdateFamilyInfoDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);

    const [updateCustomerFamily] = useUpdateCustomerFamilyEmergencyMutation();

    const customerForm = useFormik<any>({
        initialValues: customer as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerFamilyInfoSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerFamilyInfoToServer(values);
            onUpdatePersonalInfo(input);
        },
    });

    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;

    useEffect(() => {
        forEach(Object.keys(formErrors), (key) =>
            Notifications.showError(JSON.stringify(formErrors[key]))
        );
    }, [formErrors]);

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateCustomerFamily,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    const onUpdateFamilyInfoList = (familyInfo: any, index: string) => {
        const list = map(formValues?.familyInformation, (item, itemIndex) => {
            if (index === itemIndex) {
                return familyInfo;
            }
            return item;
        });
        customerForm.setFieldValue("familyInformation", list);
    };

    const onAddFamilyInfoList = () => {
        const list = [...(formValues?.familyInformation ?? []), {}];
        customerForm.setFieldValue("familyInformation", list);
    };

    const onRemoveFamily = (indexItem: number) => {
        const list = formValues?.familyInformation;
        list.splice(indexItem, 1);
        customerForm.setFieldValue("familyInformation", list);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.familyInfo}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <div className="p-3">
                {map(formValues?.familyInformation, (family, index) => (
                    <div className="border-bottom py-3">
                        <FamilyInfoForm
                            familyInfo={family}
                            formError={formErrors?.[index]}
                            onChange={(value: any) =>
                                onUpdateFamilyInfoList(value, index)
                            }
                        />
                        <div
                            className="text-error mt-3 cursor-pointer"
                            // eslint-disable-next-line radix
                            onClick={() => onRemoveFamily(parseInt(index))}
                        >
                            {Messages.remove}
                        </div>
                    </div>
                ))}
                <Button onClick={onAddFamilyInfoList} className="mt-3">
                    {Messages.add}
                </Button>
            </div>
        </Drawer>
    );
};

const UpdateEmergencyContactDrawer = ({ open, onClose }: any) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);

    const [updateCustomerEmergency] =
        useUpdateCustomerFamilyEmergencyMutation();

    const customerForm = useFormik<any>({
        initialValues: customer as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CustomerEmergencyContactSchema,
        onSubmit: (values: any) => {
            const input = mapCustomerEmergencyToServer(values);
            onUpdatePersonalInfo(input);
        },
    });

    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;

    useEffect(() => {
        forEach(Object.keys(formErrors), (key) =>
            Notifications.showError(JSON.stringify(formErrors[key]))
        );
    }, [formErrors]);

    const onUpdatePersonalInfo = (input: any) => {
        Progress.show(
            {
                method: updateCustomerEmergency,
                params: [{ variables: { id: customer.id, input } }],
            },
            (res: any) => {
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
                onClose();
                Notifications.showSuccess(Messages.updateCustomerSuccess);
            }
        );
    };

    const onUpdateContactList = (familyInfo: any, index: string) => {
        const list = map(formValues?.emergencyContact, (item, itemIndex) => {
            if (index === itemIndex) {
                return familyInfo;
            }
            return item;
        });
        customerForm.setFieldValue("emergencyContact", list);
    };

    const onAddContactList = () => {
        const list = [...(formValues?.emergencyContact ?? []), {}];
        customerForm.setFieldValue("emergencyContact", list);
    };

    const onRemoveContact = (indexItem: number) => {
        const list = formValues?.emergencyContact;
        list.splice(indexItem, 1);
        customerForm.setFieldValue("emergencyContact", list);
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.emergencyContact}
            width="800px"
            onSave={() => {
                customerForm.handleSubmit();
            }}
        >
            <div className="p-3">
                {map(formValues?.emergencyContact, (contact, index) => (
                    <div className="border-bottom py-3">
                        <EmergencyContactForm
                            contact={contact}
                            formError={formErrors?.[index]}
                            onChange={(value: any) =>
                                onUpdateContactList(value, index)
                            }
                        />
                        <div
                            className="text-error mt-3 cursor-pointer"
                            // eslint-disable-next-line radix
                            onClick={() => onRemoveContact(parseInt(index))}
                        >
                            {Messages.remove}
                        </div>
                    </div>
                ))}
                <Button onClick={onAddContactList} className="mt-3">
                    {Messages.add}
                </Button>
            </div>
        </Drawer>
    );
};

const CUSTOMER_INFO_KEYS: IRowsKey<ICustomer>[] = [
    {
        id: "title",
        label: Messages.title,
    },
    {
        id: "firstNameTh",
        label: Messages.firstNameTh,
    },
    {
        id: "firstNameEn",
        label: Messages.firstNameEn,
    },
    {
        id: "lastNameTh",
        label: Messages.lastNameTh,
    },
    {
        id: "lastNameEn",
        label: Messages.lastNameEn,
    },
    {
        id: "nickname",
        label: Messages.nickname,
    },
    {
        id: "birthDay",
        label: Messages.dateOfBirth,
        renderContent: ({ data }) => (data ? TimeUtils.toDate(data) : "N/A"),
    },
    {
        id: "gender",
        label: Messages.gender,
    },
];

const CUSTOMER_PRIVACY_INFO_KEYS: IRowsKey<ICustomer>[] = [
    {
        id: "citizenId",
        label: Messages.citizenId,
    },
    {
        id: "passportNo",
        label: Messages.passportNo,
    },
    {
        id: "passportExp",
        label: Messages.passportExp,
        renderContent: ({ data }) => (data ? TimeUtils.toDate(data) : "N/A"),
    },
    {
        id: "nationality",
        label: Messages.nationality,
        renderContent: ({ data }) => data?.name,
    },
    {
        id: "religion",
        label: Messages.religion,
    },
    {
        id: "maritalStatus",
        label: Messages.maritalStatus,
    },
    {
        id: "numberOfChild",
        label: Messages.numberOfChild,
    },
];

const CustomerDetailPersonalInfo = ({
    editable = true,
    customerData,
}: {
    editable?: boolean;
    customerData?: ICustomer;
}) => {
    const { customer: customerContext } = useContext(CustomerDetailContext);
    const customer = customerData || customerContext;
    const [openEditPersonal, setOpenEditPersonal] = useState(false);
    const [openEditPrivacy, setOpenEditPrivacy] = useState(false);
    const [openEditContact, setOpenEditContact] = useState(false);
    const [openEditFamilyInfo, setOpenEditFamilyInfo] = useState(false);
    const [openEditEmergencyContact, setOpenEditEmergencyContact] =
        useState(false);

    const {
        familyInformation,
        emergencyContact,
        status,
        phoneNumberStatus,
        referrer,
    } = customer;

    const familyInfoColumns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
        },
        {
            title: Messages.relationship,
            dataIndex: "relationship",
        },
        {
            title: Messages.dateOfBirth,
            dataIndex: "dateOfBirth",
            render: (data) => (data ? TimeUtils.toDate(data) : "N/A"),
        },
        {
            title: Messages.phone,
            dataIndex: "phone",
        },
    ];

    const emergencyColumns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
        },
        {
            title: Messages.relationship,
            dataIndex: "relationship",
        },
        {
            title: Messages.residence,
            dataIndex: "residence",
        },
        {
            title: Messages.phone,
            dataIndex: "phone",
        },
    ];

    const CUSTOMER_CONTACT_INFO_KEYS: IRowsKey<ICustomer>[] = [
        {
            id: "phone",
            label: Messages.phone,
            renderContent: ({ data, item }) => {
                return (
                    <div className="flex-center-y">
                        <div className="">
                            {item?.phoneCodeCountry?.phoneCode + data}
                        </div>
                        <ViewLabelStatus
                            className="ml-3"
                            status={phoneNumberStatus}
                            listStatus={CUSTOMER_PHONE_NUMBER_STATUS}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                        {phoneNumberStatus ===
                            PhoneNumberStatus.UN_VERIFIED && (
                            <CustomerDetailVerifyPhone />
                        )}
                    </div>
                );
            },
        },
        {
            id: "alternativePhone",
            label: Messages.alternativePhone,
        },
        {
            id: "email",
            label: Messages.email,
        },
        {
            id: "lineId",
            label: Messages.lineId,
        },
        {
            id: "facebook",
            label: Messages.facebook,
        },
    ];

    const renderHeader = (title: any, onClickEdit: any, showEdit?: boolean) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                {showEdit && (
                    <Button
                        onClick={onClickEdit}
                        className=""
                        variant="trans"
                        size="small"
                        color="red"
                    >
                        {Messages.edit}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(
                    Messages.personalInfo,
                    () => setOpenEditPersonal(true),
                    editable
                )}

                <ViewRowInterchange
                    keyList={CUSTOMER_INFO_KEYS}
                    dataSource={customer}
                    variant="background"
                />
                {openEditPersonal && (
                    <UpdatePersonalInfoDrawer
                        open={openEditPersonal}
                        onClose={() => setOpenEditPersonal(false)}
                    />
                )}
            </div>

            <div className="border p-3 mt-3">
                {renderHeader(
                    Messages.privacyInfo,
                    () => setOpenEditPrivacy(true),
                    editable
                )}
                <ViewRowInterchange
                    keyList={CUSTOMER_PRIVACY_INFO_KEYS}
                    dataSource={customer}
                    variant="background"
                />
                {openEditPrivacy && (
                    <UpdatePrivacyInfoDrawer
                        open={openEditPrivacy}
                        onClose={() => setOpenEditPrivacy(false)}
                    />
                )}
            </div>

            <div className="border p-3 mt-3">
                {renderHeader(
                    Messages.contactInfo,
                    () => setOpenEditContact(true),
                    editable
                )}
                <ViewRowInterchange
                    keyList={CUSTOMER_CONTACT_INFO_KEYS}
                    dataSource={customer}
                    variant="background"
                />
                {openEditContact && (
                    <UpdateContactDrawer
                        open={openEditContact}
                        onClose={() => setOpenEditContact(false)}
                    />
                )}
            </div>

            <div className="border p-3 mt-3">
                {renderHeader(
                    Messages.familyInfo,
                    () => setOpenEditFamilyInfo(true),
                    editable
                )}
                <AwesomeTableComponent
                    columns={familyInfoColumns}
                    dataSource={familyInformation ?? []}
                    pagination={false}
                />
                {openEditFamilyInfo && (
                    <UpdateFamilyInfoDrawer
                        open={openEditFamilyInfo}
                        onClose={() => setOpenEditFamilyInfo(false)}
                    />
                )}
            </div>
            <div className="border p-3 mt-3">
                {renderHeader(
                    Messages.emergencyContact,
                    () => setOpenEditEmergencyContact(true),
                    editable
                )}
                <AwesomeTableComponent
                    columns={emergencyColumns}
                    dataSource={emergencyContact ?? []}
                    pagination={false}
                />
                {openEditEmergencyContact && (
                    <UpdateEmergencyContactDrawer
                        open={openEditEmergencyContact}
                        onClose={() => setOpenEditEmergencyContact(false)}
                    />
                )}
            </div>
            {referrer && (
                <div className="border p-3 mt-3">
                    {renderHeader(Messages.referrer, undefined, false)}
                    {referrer?.salePerson && (
                        <UserSelectedView
                            user={referrer.salePerson as any}
                            className="mt-4"
                        />
                    )}
                    {referrer?.agency && (
                        <AgencySelectedView
                            agency={referrer.agency as any}
                            className="mt-4"
                        />
                    )}
                </div>
            )}
            <div className="h-[200px]" />
        </div>
    );
};

export default CustomerDetailPersonalInfo;
