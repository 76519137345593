import { InputText, Notifications, Progress, Select } from "d-react-components";
import React, { useContext } from "react";

import { useFormik } from "formik";
import VendorAPI from "../../../api/queries/vendor";
import UploadAvatar from "../../../common/upload/UploadAvatar";
import { VendorDetailContext } from "../../../context/vendor";
import { VendorSchema } from "../../../formschema/vendor";
import Messages from "../../../languages/Messages";
import CountrySelect from "../../address/share/CountrySelect";
import { VENDOR_STATUSES } from "../../../constant/vendor";
import Drawer from "../../../common/Drawer";

export interface IVendorGeneralDrawer {
    open: any;
    onClose: any;
}

const VendorGeneralUpdateDrawer: React.FC<IVendorGeneralDrawer> = ({
    open,
    onClose,
}) => {
    const { vendor, setVendor } = useContext(VendorDetailContext);

    const vendorForm = useFormik({
        initialValues: vendor as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VendorSchema,
        onSubmit: (values) => {
            const input = {
                address: values?.address,
                codeNumber: values?.codeNumber,
                name: values?.name,
                phone: values?.phone,
                picture: values?.picture,
                remark: values?.remark,
                representativesEmail: values?.representativesEmail,
                representativesFirstName: values?.representativesFirstName,
                representativesLastName: values?.representativesLastName,
                representativesPrimaryPhone:
                    values?.representativesPrimaryPhone,
                creditTerm: parseFloat(values?.creditTerm),
                status: values?.status,
                countryOfOrigin: values?.countryOfOrigin?.id,
            };
            onUpdateVendor(input);
        },
    });

    const formValues = vendorForm?.values;
    const formErrors = vendorForm?.errors;

    const onUpdateVendor = (input: any) => {
        Progress.show(
            {
                method: VendorAPI.update,
                params: [vendor?.id, input],
            },
            (resp: any) => {
                const vendor = resp?.data?.data?.data;
                setVendor(vendor);
                onClose();
                Notifications.showSuccess(Messages.updateSuccessfully);
            }
        );
    };
    const classNameInput = "col-span-2";

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.generalInformation}
            onSave={() => vendorForm.handleSubmit()}
        >
            <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                        <label>{Messages.profilePicture}</label>
                        <UploadAvatar
                            value={formValues?.picture}
                            onChange={(file: any) => {
                                vendorForm.setFieldValue(
                                    "picture",
                                    file?.fileUrl
                                );
                            }}
                        />
                    </div>

                    <CountrySelect
                        className={classNameInput}
                        value={formValues?.countryOfOrigin}
                        onChange={(value) =>
                            vendorForm.setFieldValue("countryOfOrigin", value)
                        }
                        error={formErrors?.countryOfOrigin}
                    />
                    <InputText
                        label={Messages.companyName}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue("name", event.target.value)
                        }
                        value={vendorForm?.values?.name}
                        error={vendorForm?.errors?.name as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.companyRegistrationNo}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "codeNumber",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.codeNumber}
                        error={vendorForm?.errors?.codeNumber as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.companyAddress}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "address",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.address}
                        error={vendorForm?.errors?.address as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.companyPhoneNo}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "phone",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.phone}
                        error={vendorForm?.errors?.phone as any}
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativeFirstName}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesFirstName",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesFirstName}
                        error={
                            vendorForm?.errors?.representativesFirstName as any
                        }
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativeLastName}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesLastName",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesLastName}
                        error={
                            vendorForm?.errors?.representativesLastName as any
                        }
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativePhoneNo}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesPrimaryPhone",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesPrimaryPhone}
                        error={
                            vendorForm?.errors
                                ?.representativesPrimaryPhone as any
                        }
                        className={classNameInput}
                    />
                    <InputText
                        label={Messages.representativeEmail}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "representativesEmail",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.representativesEmail}
                        error={vendorForm?.errors?.representativesEmail as any}
                        className={classNameInput}
                    />

                    <InputText
                        label={Messages.creditTermDays}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "creditTerm",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.creditTerm}
                        error={vendorForm?.errors?.creditTerm as any}
                        className={classNameInput}
                    />

                    <Select
                        label={Messages.status}
                        onChange={(status) =>
                            vendorForm.setFieldValue("status", status)
                        }
                        value={vendorForm?.values?.status}
                        error={vendorForm?.errors?.status as any}
                        className={classNameInput}
                        dataSource={VENDOR_STATUSES}
                        getLabel={(item) => Messages[item.label]}
                    />

                    <InputText
                        label={Messages.remark}
                        placeholder={Messages.pleaseInput}
                        onChange={(event) =>
                            vendorForm.setFieldValue(
                                "remark",
                                event.target.value
                            )
                        }
                        value={vendorForm?.values?.remark}
                        error={vendorForm?.errors?.remark as any}
                        className="col-span-2 mt-3"
                        multiple
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default VendorGeneralUpdateDrawer;
