import {
    Button,
    DialogManager,
    Modal,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { isUndefined, map } from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
    useCreateCustomerBillingMutation,
    useRemoveCustomerBillingMutation,
    useUpdateCustomerBillingMutation,
} from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { CustomerDetailContext } from "../../../context/customer";
import { BillingSchema } from "../../../formschema/customer";
import {
    getFullAddress,
    IBillingAddress,
    mapAddressToForm,
    mapAddressToSer,
    mapBillingToSer,
} from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import AddressForm from "../share/AddressForm";
import AddressItemHeader from "../share/AddressItemHeader";

interface IAddressItem {
    address: IBillingAddress;
    index: number;
    showAction?: boolean;
}

export const CustomerUpdateAddressModal = ({
    open,
    onClose,
    addressDefault,
    isEdit,
    onSaveAddress, //save address without calling BE
}: any) => {
    const { customerId: customerIdFromUrl } = useParams<any>();
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const customerId = customerIdFromUrl || customer?.id;
    const customerForm = useFormik({
        initialValues: mapAddressToForm(addressDefault),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BillingSchema,
        onSubmit: (values) => {
            const input = mapBillingToSer(values);
            if (!isUndefined(onSaveAddress)) {
                onSaveAddress({
                    ...values,
                    id: addressDefault?.id,
                });
            }
            else if (isEdit) {
                onEditAddress(input);
            } else {
                onCreateAddress(input);
            }
        },
    });
    const [createBilling] = useCreateCustomerBillingMutation();
    const [updateBilling] = useUpdateCustomerBillingMutation();

    const isPersonal = customerForm.values?.typeOfCustomer === "INDIVIDUAL";

    const onEditAddress = (input: any) => {
        Progress.show(
            {
                method: updateBilling,
                params: [
                    {
                        variables: {
                            customerId,
                            billingId: addressDefault?.id,
                            input,
                        },
                    },
                ],
            },
            (res: any) => {
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
                setCustomer(res?.data?.data?.data);
                onClose();
            }
        );
    };

    const onCreateAddress = (input: any) => {
        Progress.show(
            {
                method: createBilling,
                params: [
                    {
                        variables: {
                            customerId,
                            input,
                        },
                    },
                ],
            },
            (res: any) => {
                Notifications.showSuccess(
                    Messages.createCustomerAddressSuccess
                );
                setCustomer(res?.data?.data?.data);
                onClose();
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.updateAddress}
            size="medium"
            onSave={() => customerForm.handleSubmit()}
        >
            <div className="p-3">
                <AddressForm
                    formData={customerForm}
                    show={{
                        citizenId: isPersonal,
                        company: !isPersonal,
                        email: true,
                        nickname: false,
                        gender: false,
                        typeOfCustomer: true,
                        typeOfPlace: false,
                        map: false,
                    }}
                />
            </div>
        </Drawer>
    );
};

export const AddressItem = ({ address, index, showAction }: IAddressItem) => {
    const { customerId } = useParams<any>();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [updateBilling] = useUpdateCustomerBillingMutation();
    const [removeBilling] = useRemoveCustomerBillingMutation();
    const { setCustomer } = useContext(CustomerDetailContext);

    const {
        id,
        phone,
        isDefault,
        typeOfPlace,
        firstName,
        lastName,
        typeOfCustomer,
        citizenId,
        taxId,
    } = address;

    const onSetDefaultAddress = () => {
        const input = { isDefault: true };
        Progress.show(
            {
                method: updateBilling,
                params: [
                    {
                        variables: {
                            customerId,
                            billingId: id,
                            input,
                        },
                    },
                ],
            },
            (res: any) => {
                setCustomer(res?.data?.data?.data);
                Notifications.showSuccess(
                    Messages.updateCustomerAddressSuccess
                );
            }
        );
    };

    const onClickDelete = () => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureDeleteAddress,
            onDeleteAddress
        );
    };

    const onDeleteAddress = () => {
        Progress.show(
            {
                method: removeBilling,
                params: [
                    {
                        variables: {
                            customerId,
                            billingId: id,
                        },
                    },
                ],
            },

            (res: any) => {
                setCustomer(res?.data?.data?.data);
                Notifications.showSuccess(Messages.deleteAddressSuccess);
            }
        );
    };

    return (
        <div className="mt-3">
            <AddressItemHeader
                isDefault={isDefault}
                label={`${Messages.billingAddress} ${index}`}
                onClickEdit={() => setOpenEdit(true)}
                onClickDelete={onClickDelete}
                onClickSetDefault={onSetDefaultAddress}
                showAction={showAction}
            />
            <div className="flex-column">
                <text>{`${firstName} ${lastName}`}</text>
                {typeOfCustomer === "INDIVIDUAL" && (
                    <text>{`${Messages.citizenId}: ${citizenId}`}</text>
                )}
                {typeOfCustomer === "BUSINESS" && (
                    <text>{`${Messages.taxId}: ${taxId}`}</text>
                )}
                <text>{phone}</text>
                <text>{getFullAddress(address)}</text>
                <text>{Messages[typeOfPlace]}</text>
            </div>
            <CustomerUpdateAddressModal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                addressDefault={address}
                isEdit
            />
        </div>
    );
};

const CustomerDetailBilling = () => {
    const { customer } = useContext(CustomerDetailContext);
    const [openAdd, setOpenAdd] = useState<boolean>(false);

    return (
        <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between">
                <h5>{Messages.billingAddress}</h5>
                <Button iconName="add" onClick={() => setOpenAdd(true)}>
                    {Messages.new}
                </Button>
            </div>
            <div>
                {map(customer?.billing, (address, index) => (
                    <AddressItem address={address} index={index + 1} />
                ))}
            </div>
            {openAdd && (
                <CustomerUpdateAddressModal
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            )}
        </div>
    );
};

export default CustomerDetailBilling;
