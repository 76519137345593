import {
    AwesomeTableComponent,
    Button,
    DialogManager,
    IColumnsProps,
    Icon,
    Notifications,
    Progress,
} from "d-react-components";
import { orderBy } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { useUpdateBranchWarehouseDeliveryForAdminMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { BranchDetailContext } from "../../../context/branch";
import { IBranch } from "../../../interfaces/branch";
import { Action } from "../../../interfaces/common";
import Messages from "../../../languages/Messages";
import BranchSelect from "../share/BranchSelect";

const BranchWarehouseDelivery = () => {
    const { branch, loadBranchDetail } = useContext(BranchDetailContext);
    const tableRef = useRef<any>(null);
    const [showModal, setShowModal] = useState(false);
    const [removeWarehouse] =
        useUpdateBranchWarehouseDeliveryForAdminMutation();

    const removeBranch = (id: string) => {
        DialogManager.showConfirm(
            Messages.confirm,
            Messages.areYouSureWantRemoveBranch,
            () =>
                Progress.show(
                    {
                        method: removeWarehouse,
                        params: [
                            {
                                variables: {
                                    id: branch?.id as string,
                                    warehouseIds: [id],
                                    action: Action.Remove,
                                },
                            },
                        ],
                    },
                    () => {
                        loadBranchDetail();
                    }
                )
        );
    };
    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "name",
        },
        {
            title: Messages.code,
            dataIndex: "code",
        },
        {
            title: Messages.action,
            dataIndex: "id",
            width: 30,
            render: (data) => {
                return (
                    <Icon
                        className="text-primary cursor-pointer"
                        name="remove_circle_outline"
                        onClick={() => removeBranch(data)}
                    />
                );
            },
        },
    ];
    return (
        <div className="my-3">
            <div className="flex items-center mb-3">
                <h5 className="text-[16px] flex-1">
                    {Messages.warehouseDelivery}
                </h5>
                <Button
                    size="small"
                    iconName="add"
                    classNameIcon="mx-0 mr-1"
                    onClick={() => setShowModal(true)}
                >
                    {Messages.add}
                </Button>
            </div>

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                dataSource={orderBy(branch?.warehouseDelivery, "name")}
                columns={columns}
                baseColumnProps={{ width: 100 }}
            />
            {showModal && (
                <ModalAddWarehouse
                    open={showModal}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

const ModalAddWarehouse = ({ open, onClose }: any) => {
    const { branch, loadBranchDetail } = useContext(BranchDetailContext);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>([]);
    const [updateWarehouse] =
        useUpdateBranchWarehouseDeliveryForAdminMutation();

    const handleSubmit = () => {
        if (selectedBranches.length) {
            Progress.show(
                {
                    method: updateWarehouse,
                    params: [
                        {
                            variables: {
                                id: branch?.id as string,
                                warehouseIds: selectedBranches.map((b) => b.id),
                                action: Action.Add,
                            },
                        },
                    ],
                },
                (resp: any) => {
                    loadBranchDetail();
                    Notifications.showSuccess(
                        Messages.updateBranchSuccessfully
                    );
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        } else {
            onClose();
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="large"
            title={Messages.addBranch}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <BranchSelect
                    value={selectedBranches}
                    multiple
                    onChange={(value) =>
                        setSelectedBranches(value as IBranch[])
                    }
                />
            </div>
        </Drawer>
    );
};

export default BranchWarehouseDelivery;
