import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
} from "d-react-components";
import React from "react";
import Messages from "../../../languages/Messages";

const CustomerDetailCaseStudy = () => {
    const columns: IColumnsProps = [
        {
            title: Messages.caseId,
            dataIndex: "caseId",
        },
        {
            title: Messages.service,
            dataIndex: "service",
        },
        {
            title: Messages.status,
            dataIndex: "status",
        },
        {
            title: Messages.starting,
            dataIndex: "from",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.ending,
            dataIndex: "to",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (paging: any) => {
        return Promise.resolve([]);
    };

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.recentCaseStudy}</h5>
                <Button iconName="add" onClick={() => {}} className="">
                    {Messages.new}
                </Button>
            </div>
            <InputTextSearch className="mt-3" />
            <AwesomeTableComponent
                className="mt-3"
                columns={columns}
                source={source}
                transformer={() => []}
            />
        </div>
    );
};

export default CustomerDetailCaseStudy;
