import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_COUNTRY } from "./address";

export const F_VENDOR_BASIC = gql`
    fragment F_VENDOR_BASIC on AdminVendorBasic {
        id
        name
        vendorNo
        picture
    }
`;

export const F_VENDOR_SEARCH = gql`
    fragment F_VENDOR_SEARCH on AdminSearchVendor {
        id
        name
        vendorNo
        picture
    }
`;

export const F_VENDOR = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_COUNTRY}
    fragment F_VENDOR on AdminVendor {
        id
        name
        vendorNo
        countryOfOrigin {
            ...F_COUNTRY
        }
        address
        codeNumber
        phone
        picture
        remark
        representativesFirstName
        representativesLastName
        representativesPrimaryPhone
        representativesAlternatePhone
        representativesEmail
        status
        createdAt
        updatedAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        creditTerm
        totalPurchaseOrder
    }
`;

export const F_VENDORS = gql`
    ${F_VENDOR}
    ${F_PAGINATE}
    fragment F_VENDORS on ListAdminVendorResponse {
        data {
            ...F_VENDOR
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_VENDOR_PURCHASES = gql`
    ${F_PAGINATE}
    fragment F_VENDOR_PURCHASES on SearchAdminMaterialPurchaseResponse {
        data {
            id
            updatedAt
            createdAt
            materialPurchaseNo
            status
            materialPurchaseOrderStatus
            subtotal
            total
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

