import {
    AwesomeTableComponent,
    Button,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
    Avatar,
    useFirstTime,
} from "d-react-components";
import { JsonParam, useQueryParam } from "use-query-params";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useUserListLazyQuery } from "../../../api/hooks";
// import UserAPI from "../../../api/user/UserAPI";
import AppLink from "../../../common/AppLink";
import PermissibleRender from "../../../common/PermissibleComponent";
import TableAction from "../../../common/TableActions";
import {
    JOB_TYPES,
    PERMISSION,
    USER_STATUS,
    USER_STATUSES,
} from "../../../constant/user";
import { IUser } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import UserTableFilterDrawer from "./filter/UserTableFilterDrawer";

const UserTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [getListUser] = useUserListLazyQuery();
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    const columns: IColumnsProps = [
        {
            title: Messages.avatar,
            dataIndex: "avatar",
            width: 200,
            render: (avatar, item) => (
                <AppLink
                    to={generatePath(Path.USER_DETAIL, {
                        userId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <Avatar
                            src={avatar}
                            text={item.firstName}
                            variant="square"
                            size="x-small"
                        />
                        <span className="ml-2">{`${item.firstName} ${item.lastName}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.companyId,
            dataIndex: "companyId",
        },
        {
            title: Messages.role,
            dataIndex: "role",
            render: (role) => role?.name,
        },
        {
            title: Messages.jobType,
            dataIndex: "jobType",
            render: (jobType) =>
                (Messages as any)?.[
                    JOB_TYPES.find((item) => item?.key === jobType)?.label ?? ""
                ] || "N/A",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE}
                    listStatus={USER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.authentication,
            dataIndex: "authenticationStatus",
            render: (authenticationStatus) => (
                <ViewLabelStatus
                    status={
                        authenticationStatus
                            ? USER_STATUS.ACTIVE
                            : USER_STATUS.INACTIVE
                    }
                    listStatus={USER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.lastLogin,
            dataIndex: "lastLogin",
            render: (data) => {
                return data ? TimeUtils.toDateTime(data) : "N/A";
            },
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.updatedDate,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            width: 30,
            render: (user: IUser) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, user)
                        }
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        console.log("filter", filter);
        return getListUser({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    ...filter,
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickTableAction = (actionId: string, user: IUser) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.USER_DETAIL, {
                        userId: user.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.USER_CREATE);
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    return (
        <div className="">
            <HeaderTable
                label={Messages.allUsers}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onSubmitSearch={onSubmitSearch}
                className="app-layout__page-header"
                customButtons={() => (
                    <PermissibleRender
                        permission={PERMISSION.ADMIN.USER.CREATE}
                    >
                        <Button size="small" onClick={onClickAddNew}>
                            {Messages.new}
                        </Button>
                    </PermissibleRender>
                )}
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.listAdminUserForAdmin?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return (
                        res?.data?.listAdminUserForAdmin?.pagination?.items ?? 0
                    );
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
            {openFilter && (
                <UserTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default UserTable;
