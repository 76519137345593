import { useContext } from "react";
import { ProductCreateContext } from "../../../context/product";
import ProductConfigurationForm from "../share/ProductConfigurationForm";

const ProductCreateConfiguration = () => {
    const { productForm } = useContext(ProductCreateContext);
    return <ProductConfigurationForm configForm={productForm} />;
};

export default ProductCreateConfiguration;
