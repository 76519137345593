import { useFormik } from "formik";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";
import { mapAppointmentFilterToQueryParams } from "../../../interfaces/appointment";
import Messages from "../../../languages/Messages";
import AppointmentFilterForm from "../share/AppointmentFilterForm";

interface IAppointmentTableFilterDrawer {
    open: boolean;
    onClose: () => void;
}

const AppointmentTableFilterDrawer = ({
    open,
    onClose,
}: IAppointmentTableFilterDrawer) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const params = mapAppointmentFilterToQueryParams(values);
            setFilter(params);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
        >
            <AppointmentFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default AppointmentTableFilterDrawer;
