import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import React, { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import { BRANCH_STATUSES } from "../../../constant/branch";
import { BranchDetailContext } from "../../../context/branch";
import { IBranch } from "../../../interfaces/branch";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const BranchSummaryView = ({ loading }: { loading: boolean }) => {
    const { branch } = useContext(BranchDetailContext);
    const BRANCH_DETAILS_KEY: IRowsKey<IBranch>[] = [
        {
            id: "branchId",
            label: Messages.systemId,
        },
        {
            id: "code",
            label: Messages.code,
        },
        {
            id: "pickupCode",
            label: Messages.pickupCode,
        },
        {
            id: "phone",
            label: Messages.phone,
        },
        {
            id: "brandTaxId",
            label: Messages.brandTaxId,
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {branch && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 border-b py-5 px-3">
                        <img src="/icons/branch.svg" />
                        <div className="text-large-bold my-3">
                            <Link
                                to={generatePath(Path.BRANCH_DETAIL, {
                                    branchId: branch?.id,
                                })}
                            >
                                {branch?.name}
                            </Link>
                        </div>
                        <ViewLabelStatus
                            status={branch?.status}
                            listStatus={BRANCH_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={branch}
                            keyList={BRANCH_DETAILS_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
            {/* {loading && <Skeleton count={5} />} */}
        </div>
    );
};

export default BranchSummaryView;
