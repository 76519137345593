import React from "react";
import { useParams } from "react-router";
import { RefActivity } from "../../../../api/hooks";
import ActivityView from "../../../../common/activity/ActivityView";

const StockRequestActivity = () => {
    const { stockRequestId } = useParams<any>();
    return (
        <div>
            {stockRequestId && (
                <ActivityView
                    className="mt-3 border"
                    activityRef={RefActivity?.ProductStockRequest}
                    activityRefId={stockRequestId ?? ""}
                />
            )}
        </div>
    );
};

export default StockRequestActivity;
