import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { generatePath } from "react-router-dom";

import { useFormik } from "formik";
import { find, isNaN, omit } from "lodash";
import { Fragment, useContext, useState } from "react";
import ProductAPI from "../../../api/queries/product";
import AppLink from "../../../common/AppLink";
import Drawer from "../../../common/Drawer";
import { PRODUCT_TYPE, PRODUCT_TYPES } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import { ProductGeneralSchema } from "../../../formschema/product";
import {
    IProduct,
    mapProductConfigToServer,
    mapProductGeneralInfoToServer,
} from "../../../interfaces/product";

import { TypeOfProduct } from "../../../api/hooks";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductGeneralInfoForm from "../share/ProductGeneralInfoForm";

const UpdateGeneralInfoDrawer = ({ open, onClose }: any) => {
    const { product, setProduct } = useContext(ProductDetailContext);

    const productForm = useFormik<any>({
        initialValues:
            {
                ...(product || {}),
                visibility: !!product?.isVisibility,
                brand: "dermaster",
            } ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductGeneralSchema,
        onSubmit: (values: any) => {
            const inputGeneralInfo: any = mapProductGeneralInfoToServer(values);

            // Need to update spending price follow salePrice
            const inputConfig = mapProductConfigToServer(values);

            const input = {
                ...omit(inputConfig, "isMedicine"),
                ...inputGeneralInfo,
            };

            onUpdateProduct(input);
        },
    });

    const onUpdateProduct = (input: any) => {
        Progress.show(
            {
                method: ProductAPI.update,
                params: [product?.id, input],
            },
            (product: any) => {
                setProduct(product);
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.generalInformation}
            width="800px"
            onSave={() => {
                productForm.handleSubmit();
            }}
        >
            <div className="p-3">
                <ProductGeneralInfoForm
                    productForm={productForm}
                    variant="updateGeneral"
                />
            </div>
        </Drawer>
    );
};

const ProductDetailGeneralInfo = () => {
    const { product } = useContext(ProductDetailContext);
    const [openEditInfo, setOpenEditGeneral] = useState(false);

    if (!product) {
        return null;
    }

    const { typeOfProduct } = product || {};

    const PRODUCT_INFO_KEYS: IRowsKey<IProduct>[] = [
        {
            id: "sku",
            label: Messages.sku,
        },
        {
            id: "name",
            label: Messages.name,
        },
        {
            id: "category",
            label: Messages.category,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "weight",
            label: Messages.weight,
        },
        {
            id: "isMedicine",
            label: Messages.medicineConfig,
            renderContent: ({ data }) =>
                data ? Messages.thisIsMedicine : Messages.thisIsNotMedicine,
        },
        {
            id: "typeOfProduct",
            label: Messages.productConfiguration,
            renderContent: ({ data }) => {
                const productType = find(
                    PRODUCT_TYPES,
                    (item) => item.id === product?.typeOfProduct
                );
                return Messages[productType?.title ?? ""];
            },
        },
        {
            id: "service",
            label: Messages.serviceConfiguration,
            renderContent: ({ data, item }) => {
                return (
                    <div>
                        <AppLink
                            to={generatePath(
                                Path.SERVICE_CONFIGURATION_DETAIL,
                                {
                                    configId: data?.id ?? "",
                                }
                            )}
                            target="_blank"
                        >
                            {data?.name}
                        </AppLink>
                        {product.typeOfProduct ===
                            TypeOfProduct.ServiceProduct && (
                            <div className="mt-2">
                                {item.isOperationService
                                    ? Messages.thisIsOperationProduct
                                    : Messages.thisIsNonOperationProduct}
                            </div>
                        )}

                        {item.isNonVAT && (
                            <div className="mt-2">
                                {Messages.thisIsNonVatProduct}
                            </div>
                        )}
                    </div>
                );
            },
            hidden: typeOfProduct !== PRODUCT_TYPE.SERVICE_PRODUCT,
        },
        {
            id: "isGenerateGiftVoucher",
            label: Messages.giftVoucherConfiguration,
            renderContent: ({ data, item }) => {
                return (
                    <Fragment>
                        <div hidden={product.isGenerateGiftVoucher}>
                            {Messages.disableGenerateGift}
                        </div>
                        <div hidden={!product.isGenerateGiftVoucher}>
                            {Messages.enableGenerateGift}
                        </div>
                    </Fragment>
                );
            },
            hidden: typeOfProduct !== PRODUCT_TYPE.SIMPLE_PRODUCT,
        },
        {
            id: "expiredAfterDay",
            label: Messages.giftVoucherExpiryDate,
            renderContent: ({ data, item }) => {
                const totalTimeExpiry =
                    product.expiredAfterYear +
                    product.expiredAfterMonth +
                    product.expiredAfterDay;
                return (
                    <Fragment>
                        <div
                            hidden={
                                isNaN(totalTimeExpiry) || totalTimeExpiry === 0
                            }
                        >
                            {Messages.expireInYearMonthDay
                                .replace("%year", `${product.expiredAfterYear}`)
                                .replace(
                                    "%month",
                                    `${product.expiredAfterMonth}`
                                )
                                .replace("%day", `${product.expiredAfterDay}`)}
                        </div>
                        <div
                            hidden={
                                !isNaN(totalTimeExpiry) && totalTimeExpiry !== 0
                            }
                        >
                            {Messages.neverExpire}
                        </div>
                    </Fragment>
                );
            },
            hidden: typeOfProduct !== PRODUCT_TYPE.SIMPLE_PRODUCT,
        },
        {
            id: "isSellOutOfStock",
            label: Messages.isSellOutOfStock,
            renderContent: ({ data }) => (data ? "Yes" : "No"),
        },
        {
            id: "isVisibility",
            label: Messages.visibility,
            renderContent: ({ data }) =>
                data ? Messages.enabled : Messages.disabled,
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
        {
            id: "updatedAt",
            label: Messages.updatedAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
    ];

    const renderHeader = (title: any, onClickEdit: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                <Button
                    onClick={onClickEdit}
                    className=""
                    variant="trans"
                    size="small"
                    color="red"
                >
                    {Messages.edit}
                </Button>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation, () =>
                    setOpenEditGeneral(true)
                )}
                <ViewRowInterchange
                    keyList={PRODUCT_INFO_KEYS}
                    dataSource={product}
                    variant="background"
                />

                {openEditInfo && (
                    <UpdateGeneralInfoDrawer
                        open={openEditInfo}
                        onClose={() => setOpenEditGeneral(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProductDetailGeneralInfo;
