import styled from "@emotion/styled";
import { find, isEmpty } from "lodash";
import classNames from "classnames";
import { Checkbox, Icon } from "d-react-components";
import { useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { DiscountType, PriceSetType, TypeOfProduct } from "../../../api/hooks";
import { OrderType } from "../../../interfaces/order";
import { getProductPrice, IProductOrder } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import AddCustomItemDrawer from "./AddCustomItemDrawer";
import ProductName from "./ProductName";
import QuantityInputField from "./QuantityInputField";
import ProductItemSalesperson from "./ProductItemSalesperson";
import IUser from "../../../interfaces/user";

const ProductCartItem = ({
    product,
    isSubItem,
    onChangeQuantity,
    onDeleteProduct,
    onChangeProductDetail,
    type = OrderType.QUOTATION,
    applyPriceSet,
    primarySalesPerson,
    manualDiscount,
}: {
    product: IProductOrder & { [key: string]: any };
    isSubItem: boolean;
    onChangeQuantity?: any;
    onDeleteProduct?: any;
    onChangeProductDetail?: any;
    type?: any;
    applyPriceSet?: PriceSetType;
    primarySalesPerson?: IUser;
    manualDiscount?: any;
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showEditProductCustom, setShowEditProductCustom] =
        useState<any>(undefined);
    const isCustomProduct = product?.isCustomProduct;
    const isSimpleProduct =
        product?.typeOfProduct === TypeOfProduct.SimpleProduct;
    const subProducts: any[] = [];
    if (
        product?.groups?.length &&
        (product?.typeOfProduct === TypeOfProduct.GroupProduct ||
            product.typeOfProduct === TypeOfProduct.SpecialGroupService ||
            product.typeOfProduct === TypeOfProduct.BundleProduct)
    ) {
        Object.assign(subProducts, product?.groups);
    }
    if (
        product?.premiumService?.product?.length &&
        product?.typeOfProduct === TypeOfProduct.PremiumService
    ) {
        Object.assign(subProducts, product?.premiumService?.product);
    }
    const showCollapseButton = !isEmpty(subProducts);
    const subTotal = product?.quantity
        ? product?.quantity *
          (product?.openingPrice ||
              getProductPrice("salePrice", product, applyPriceSet) ||
              0)
        : 0;
    const discountItem = find(
        manualDiscount,
        (item: any) => item?.product?.id === product?.id
    );
    const isDiscountProduct = !isEmpty(discountItem);
    const total = useMemo(() => {
        let discountValue = 0;
        if (isDiscountProduct) {
            discountValue =
                isDiscountProduct &&
                discountItem?.discountType === DiscountType.Percentage
                    ? (discountItem.discountValue / 100) * Number(subTotal || 0)
                    : discountItem.discountValue;
        }
        return subTotal - (discountValue || 0);
    }, [manualDiscount, subTotal]);
    return (
        <StyledProductItem
            expanded={expanded}
            className={classNames({
                "flex items-center": isSubItem,
            })}
        >
            {isSubItem && (
                <span className="border-t h-[1px] w-[45px] flex-nowrap grow-0 shrink-0" />
            )}
            <div
                className={classNames({
                    "flex-1 sub-item": isSubItem,
                    "border-t": !isSubItem,
                })}
            >
                <div
                    className={classNames("flex pt-2", {
                        "bg-gray-100 p-2 mt-2": isSubItem,
                    })}
                >
                    <div
                        className={classNames(
                            { "parent-product": !isSubItem },
                            "flex-1 mb-1"
                        )}
                    >
                        <ProductName
                            applyPriceSet={applyPriceSet}
                            item={product}
                            imgClass="w-10 h-10"
                            showPrice
                            isCreate
                            generateVoucher={() =>
                                isSimpleProduct &&
                                type === OrderType.ORDER &&
                                product.isGenerateGiftVoucher ? (
                                    <div className="w-full p-2 my-2 text-primary bg-[#DCE5E5] flex items-center">
                                        <Checkbox
                                            onChange={(value) =>
                                                onChangeProductDetail(
                                                    product,
                                                    "isGift",
                                                    !product.isGift
                                                )
                                            }
                                            checked={product.isGift}
                                        />{" "}
                                        <span className="ml-2 text-sm">
                                            {Messages.generateGiftVoucher}
                                        </span>
                                    </div>
                                ) : null
                            }
                            addOneAfterName={() => (
                                <>
                                    {showCollapseButton ? (
                                        <span
                                            className="bg-primary p-1 text-center cursor-pointer text-white text-xs h-[20px] w-[52px] mx-2 grow-0 shrink-0"
                                            onClick={() =>
                                                setExpanded(!expanded)
                                            }
                                        >
                                            {expanded
                                                ? Messages.collapse
                                                : Messages.expand}
                                        </span>
                                    ) : null}
                                    {isCustomProduct && (
                                        <div
                                            className="ml-2 w-[16px] h-[16px] text-white bg-black flex items-center justify-content-center cursor-pointer"
                                            onClick={() => {
                                                setShowEditProductCustom(
                                                    product
                                                );
                                            }}
                                        >
                                            <Icon name="edit" size="xx-small" />
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    {!isSubItem && (
                        <div>
                            <Icon
                                name="delete"
                                className="text-red-500 cursor-pointer"
                                onClick={() => onDeleteProduct(product)}
                            />
                        </div>
                    )}
                </div>
                {showCollapseButton && expanded && (
                    <div className="sub-product">
                        {subProducts?.map((item: any, index: number) => (
                            <ProductCartItem
                                product={{ ...item?.product, ...item }}
                                key={index}
                                isSubItem
                            />
                        ))}
                    </div>
                )}
                {/* {!isSubItem &&
                    product.typeOfProduct === PRODUCT_TYPE.SERVICE_PRODUCT && (
                        <div className="flex mb-2 pl-12">
                            <PriceInputField product={product} />
                        </div>
                    )} */}
                {product.remark && (
                    <div className="ml-12 mb-2 bg-[#82A4A4] py-3 px-3 text-white">
                        <h5 className="text-sm text-white mb-2">
                            {Messages.remark}
                        </h5>
                        <div className="text-xs whitespace-pre-line">
                            {product.remark}
                        </div>
                    </div>
                )}
                <div
                    className={classNames("flex mb-2 items-center", {
                        "bg-gray-100 px-2 pb-2 flex-1": isSubItem,
                        // "bg-amber-100 px-2 pb-2": isCustomProduct,
                    })}
                >
                    <div className="flex-1">
                        <div className="w-[150px] pl-[45px]">
                            <QuantityInputField
                                quantity={product.quantity}
                                onChangeQuantity={(quantity: number) =>
                                    onChangeQuantity(product, quantity)
                                }
                                disabled={isSubItem || product.doctorOrder}
                            />
                        </div>
                    </div>
                    {isDiscountProduct && (
                        <span className="line-through mr-1">
                            <CurrencyFormat
                                value={subTotal}
                                displayType="text"
                                thousandSeparator
                                prefix="฿"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </span>
                    )}
                    <div className="px-2 py-1 text-white bg-primary text-sm">
                        <CurrencyFormat
                            value={total}
                            displayType="text"
                            thousandSeparator
                            prefix="฿"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </div>
                </div>
                {/* {product?.doctorOrder && (
                    <div className="ml-[45px] mb-2 px-2 py-1 text-white bg-primary text-sm w-fit-content">
                        {Messages.doctorOrderNo}: #{product?.doctorOrder?.doctorOrderNo}
                    </div>
                )} */}
            </div>
            {type === OrderType.ORDER && (
                <div className="pl-[45px]">
                    <ProductItemSalesperson
                        primarySalesPerson={primarySalesPerson as IUser}
                        product={product}
                        onChange={onChangeProductDetail}
                    />
                </div>
            )}
            {showEditProductCustom && (
                <AddCustomItemDrawer
                    open={!!showEditProductCustom}
                    product={showEditProductCustom}
                    onClose={() => setShowEditProductCustom(undefined)}
                    // onSave={(item: any) => onSaveCustomItem(item)}
                />
            )}
        </StyledProductItem>
    );
};

const StyledProductItem: any = styled.div`
    .parent-product {
        ${(props: any) =>
            props.expanded &&
            `
    position: relative;
    &:before {
      display: block;
      content: "";
      height: 95%;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: #e5e7eb;
    }
    `}
    }
    .sub-product {
        & > div {
            position: relative;
            &:before {
                display: block;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                background-color: #e5e7eb;
            }
            &:last-child {
                &:before {
                    height: 50%;
                }
            }
            &:not(:first-child) {
                .sub-item {
                    border-top: 1px solid #e5e7eb;
                }
            }
        }
    }
`;

export default ProductCartItem;
