import styled from "@emotion/styled";
import React, { Fragment, useContext } from "react";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import Messages from "../../../languages/Messages";

const OrderVendor = () => {
    const { order } = useContext(MaterialPurchaseDetailContext);
    const { createByAdmin, vendor } = order || {};
    return (
        <OrderSalePersonStyled className="border p-3 mb-3">
            {createByAdmin && (
                <Fragment>
                    <h5 className="font-semibold mb-2">{Messages.vendor}</h5>
                    <div
                        className="user-selected-view w-100 mt-3 bg-primary d-flex"
                    >
                        <img
                            src={vendor?.picture}
                            className="image-square-medium"
                        />
                        <div className="p-2 flex-column">
                            <span className="block text-white font-weight-bold hover:cursor-pointer hover:text-blue-500">
                                {vendor.name}
                            </span>
                            <span className="block text-white text-sm opacity-75">{`${Messages.id} : ${vendor.vendorNo}`}</span>
                        </div>
                    </div>
                </Fragment>
            )}
        </OrderSalePersonStyled>
    );
};

export default OrderVendor;

const OrderSalePersonStyled = styled.div`
    .user-selected-view {
        background-color: white !important;
        margin-top: 0 !important;
        img {
            width: 50px;
            height: 50px;
        }
        .text-white {
            color: black !important;
        }
    }
`;
