import { CalendarAdditionalSubjectType } from "../interfaces/calendar";
import { JobType } from "../interfaces/user";
import { JOB_TYPES } from "./user";

export const CALENDAR_SUBJECT_TYPE = [
    ...JOB_TYPES.filter((i) => i?.key !== JobType.NORMAL_EMPLOYEE).map((i) => ({
        ...i,
        id: i.key,
    })),
    {
        id: CalendarAdditionalSubjectType.ROOM,
        key: CalendarAdditionalSubjectType.ROOM,
        label: "room",
    },
    {
        id: CalendarAdditionalSubjectType.EQUIPMENT,
        key: CalendarAdditionalSubjectType.EQUIPMENT,
        label: "equipment",
    },
];
