import React, { useEffect } from "react";
import _ from "lodash";
import { generatePath } from "react-router";
import LinesEllipsis from "react-lines-ellipsis";
import { Icon } from "d-react-components";
import Messages from "../../../languages/Messages";
import Path from "../../Path";

const ProductCard = React.memo(
    ({
        product,
        isHideSku = false,
        isHideImage = false,
        addNewProduct,
        applyPriceSet,
        quantity,
    }: any) => {
        const { imageurl, name, sku, gallery, thumbnail } = product;

        const getNameProduct = () => {
            return <span className="text-primary">{name}</span>;
        };
        const renderImageProduct = () => {
            let url = "/images/img-default.png";
            if (isHideImage) {
                return <div />;
            }
            if (thumbnail) {
                url = thumbnail;
            } else if (gallery && gallery?.[0]) {
                url = gallery?.[0];
            }
            return (
                <div className="aspect-w-4 aspect-h-3">
                    <img
                        className="w-full h-full object-center object-cover"
                        alt={name}
                        src={url}
                    />
                </div>
            );
        };

        return (
            <div className="group mb-2 bg-white flex flex-col cursor-pointer relative max-h-[280px] 3xl:max-h-[320px] 4xl:max-h-[360px]">
                {/* {product?.total_stock > 0 && ( */}
                {/* <div
                    onClick={() => addNewProduct(product)}
                    className="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 hidden group-hover:flex items-center justify-content-center z-50"
                >
                    <Icon name="add" size="xxx-large" color="white" />
                </div> */}
                {/* )} */}
                {quantity > 0 && (
                    <div className="absolute bg-green-500 text-white px-2 py-1 text-xs top-0 left-0 z-40">
                        {quantity}
                        {/* {Messages.left} */}
                    </div>
                )}
                {/* {product.total_stock <= 0 && (
                    <div className="absolute bg-gray-400 text-white px-2 py-1 text-xs top-0 left-0 z-40">
                        {Messages.outOfStock}
                    </div>
                )} */}
                {renderImageProduct()}

                <div className="p-2 flex-1 overflow-hidden">
                    {!isHideSku && (
                        <span className="text-xs inline-block text-gray-400 w-100">
                            <LinesEllipsis
                                text={sku || ""}
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                            />
                        </span>
                    )}
                    <span className="text-sm inline-block text-gray-500 overflow-hidden">
                        <LinesEllipsis
                            text={(getNameProduct() || "") as any}
                            maxLine="3"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                        {/* {renderSubName && renderSubName()} */}
                    </span>
                    <span className="block text-gray-600 font-italic text-xs">
                        {Messages.lastPurchase}:{" "}
                        {product.lastPurchasingUnitCost} THB/
                        {product.purchaseUnit?.name}
                    </span>
                </div>
            </div>
        );
    }
);

export default ProductCard;
