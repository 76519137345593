import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { find } from "lodash";
import { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import {
    STOCK_TRANSFER_STATUSES,
    STOCK_TRANSFER_TYPES,
} from "../../../../constant/stock-transfer";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import { IStockTransfer } from "../../../../interfaces/stock-transfer";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";

const StockTransferSummary = ({ loading }: { loading?: boolean }) => {
    const { stockTransfer } = useContext(StockTransferDetailContext);
    const STOCK_TRANSFER_KEY: IRowsKey<IStockTransfer>[] = [
        {
            id: "transferNo",
            label: "ID",
        },
        {
            id: "origin",
            label: Messages.origin,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "destination",
            label: Messages.destination,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "type",
            label: Messages.transferType,
            renderContent: ({ data }) =>
                (Messages as any)[
                    find(STOCK_TRANSFER_TYPES, (i) => i?.id === data)?.label ??
                        ""
                ],
        },
        {
            id: "expectedDateOfArrival",
            label: Messages.expectedDateOfArrival,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "expectedDateOfTransfer",
            label: Messages.expectedDateOfTransfer,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {stockTransfer && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            <Link
                                to={generatePath(
                                    Path.INVENTORY_TRANSFER_STOCK_DETAIL,
                                    {
                                        transferId: stockTransfer?.id,
                                    }
                                )}
                            >
                                {stockTransfer?.transferNo}
                            </Link>
                        </div>
                        <ViewLabelStatus
                            status={stockTransfer?.status}
                            listStatus={STOCK_TRANSFER_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={stockTransfer}
                            keyList={STOCK_TRANSFER_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default StockTransferSummary;
