import { DateInput, InputText, Select } from "d-react-components";
import React, { useContext } from "react";
// import UploadAvatar from "../../../common/upload/UploadAvatar";
import { SPECIAL_ACCESS_TYPES, USER_STATUSES } from "../../../constant/user";
import { UserCrudContext } from "../../../context/user";
import Messages from "../../../languages/Messages";

const UserCrudSpecialAccess = () => {
    const { userForm } = useContext(UserCrudContext);

    return (
        <div className="bg-white p-4">
            <div className="row">
                <div className="col-12">
                    <Select
                        label={Messages.status}
                        value={userForm.values?.specialAccess?.status}
                        error={userForm.errors?.specialAccess?.status}
                        className="mb-3"
                        dataSource={USER_STATUSES}
                        getLabel={(item) => (Messages as any)[item?.label]}
                        onChange={(value) =>
                            userForm.setFieldValue(
                                "specialAccess.status",
                                value
                            )
                        }
                    />
                </div>
                <div className="col-12">
                    <Select
                        label={Messages.usageType}
                        value={
                            userForm.values?.specialAccess?.specialAccessType
                        }
                        error={
                            userForm.errors?.specialAccess?.specialAccessType
                        }
                        className="mb-3"
                        dataSource={SPECIAL_ACCESS_TYPES}
                        getLabel={(item) => (Messages as any)[item?.label]}
                        getValue={(item) => item.key}
                        onChange={(value) => {
                            userForm.setFieldValue(
                                "specialAccess.specialAccessType",
                                value
                            );
                        }}
                    />
                </div>
                <div className="col-12">
                    <InputText
                        className="w-100 mb-3"
                        label={Messages.code}
                        placeholder={Messages.code}
                        value={userForm.values?.specialAccess?.code}
                        error={userForm.errors?.specialAccess?.code}
                        onChange={(event) =>
                            userForm.setFieldValue(
                                "specialAccess.code",
                                event.target.value
                            )
                        }
                    />
                </div>
                <div className="col-12">
                    <DateInput
                        className="w-100 mb-3"
                        label={Messages.expiredAt}
                        value={userForm.values?.specialAccess?.expired}
                        error={userForm.errors?.specialAccess?.expired}
                        format="DD/MM/YYYY HH:mm"
                        showTime
                        onChange={(value) =>
                            userForm.setFieldValue(
                                "specialAccess.expired",
                                value
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default UserCrudSpecialAccess;
