import { gql } from "@apollo/client";
import { F_PAGINATE } from "./app";

export const F_EQUIPMENT_CATEGORY = gql`
    fragment F_EQUIPMENT_CATEGORY on AdminCategoryEquipmentDto {
        id
        name
        status
        description
        categoryEquipmentId
        createdAt
        updatedAt
    }
`;

export const F_EQUIPMENT_CATEGORY_LIST = gql`
    ${F_PAGINATE}
    fragment F_EQUIPMENT_CATEGORY_LIST on ListAdminCategoryEquipmentResponse {
        data {
            ...F_EQUIPMENT_CATEGORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_EQUIPMENT_BASIC = gql`
    fragment F_EQUIPMENT_BASIC on EquipmentBasicDto {
        id
        equipmentNo
        name
        description
        serialNo
        model
        condition
    }
`;

export const F_EQUIPMENT = gql`
    fragment F_EQUIPMENT on EquipmentDto {
        id
        equipmentNo
        name
        serialNo
        model
        branch {
            name
        }
        category {
            categoryEquipmentId
            name
        }
        condition
        visibility
        updatedAt
        configuration
        gallery
        weight
    }
`;

export const F_EQUIPMENT_LIST = gql`
    ${F_PAGINATE}
    fragment F_EQUIPMENT_LIST on EquipmentPaginateResponse {
        data {
            ...F_EQUIPMENT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_EQUIPMENT_DETAIL = gql`
    fragment F_EQUIPMENT_DETAIL on EquipmentDto {
        ...F_EQUIPMENT
        weight
        description
        gallery
        createdAt
    }
    ${F_EQUIPMENT}
`;

export const F_EQUIPMENT_SCHEDULE = gql`
    fragment F_EQUIPMENT_SCHEDULE on UserEquipmentScheduleDto {
        day
        isWorking
        isTemporarySchedule
        workingSchedule {
            date
            branch {
                id
                name
                code
            }
            schedule {
                start
                end
            }
        }
        occupiedSlots {
            ref
            refId
            schedule {
                start
                end
            }
            treatment {
                id
                treatmentNo
            }
            booking {
                id
                bookingNo
            }
        }
    }
`;
