import { Moment } from "moment";
import React, { Dispatch } from "react";
import { CALENDAR_SUBJECT_TYPE } from "../constant/calendar";
import {
    CalendarAdditionalSubjectType,
    ICalendarEvent,
} from "../interfaces/calendar";

export interface ICalendarFilterState {
    branch?: any;
    calendarSubject?: (typeof CALENDAR_SUBJECT_TYPE)[0];
    dateRange?: Moment;
}

export interface ICalendarDayDrawerState {
    open?: boolean;
    day?: Date;
    normalEvents?: Array<ICalendarEvent>;
    backgroundEvents?: Array<ICalendarEvent>;
}

export interface ICalendarState {
    calendarFilterState: ICalendarFilterState;
    calendarDayDrawer?: ICalendarDayDrawerState;
    branchList?: Array<any>;
    calendarEvents?: Array<ICalendarEvent>;
    updateFilterState: (v: Partial<ICalendarFilterState>) => void;
    fetchCalendarData: () => void;
    setCalendarDayDrawer: Dispatch<ICalendarDayDrawerState>;
}

const calendarState: ICalendarState = {
    calendarFilterState: {},
    updateFilterState: () => {},
    fetchCalendarData: () => {},
    setCalendarDayDrawer: () => {},
};

export const CalendarContext = React.createContext(calendarState);
