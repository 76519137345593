import { Button } from "d-react-components";
import React from "react";
import { ToolbarProps } from "react-big-calendar";

export interface BigCalendarToolbarProps extends ToolbarProps {
    dateRange?: any;
}

const BigCalendarToolbar: React.ComponentType<BigCalendarToolbarProps> = ({
    date,
    label,
    localizer,
    onView,
    children,
    onNavigate,
    view,
    views,
    dateRange,
}) => {
    return (
        <div className="w-100  my-3 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center">
                <Button
                    onClick={() => {
                        onNavigate("PREV");
                    }}
                    iconName="chevron_left"
                    variant="trans"
                />
                <div className="w-100 text-small-bold text-center">{label}</div>
                <Button
                    onClick={() => {
                        onNavigate("NEXT");
                    }}
                    iconName="chevron_right"
                    variant="trans"
                />
            </div>
        </div>
    );
};

export default BigCalendarToolbar;
