import {
    JobCostTemplate,
    JobCostType,
    FeeType,
    FeeUnit,
    ServiceSurgeryType,
} from "../api/hooks";

export const SERVICE_CATEGORY_STATUSES = [
    { id: "DISABLED", label: "disabled", color: "#C4C4C4" },
    { id: "ENABLED", label: "enabled", color: "#33B950" },
];

export const SERVICE_CONFIG_STATUSES = [
    { id: "DISABLE", label: "disabled", color: "#C4C4C4" },
    { id: "ENABLE", label: "enabled", color: "#33B950" },
];

export const FEE_UNITS = [
    { id: FeeUnit.Minutes, label: "minutes" },
    { id: FeeUnit.Time, label: "time" },
    { id: FeeUnit.Night, label: "night" },
    { id: FeeUnit.Piece, label: "graft" },
];

export const FEE_TYPES = [
    { id: FeeType.Percentage, label: "Fixed rate (%)" },
    { id: FeeType.Amount, label: "Fixed rate (THB)" },
];

export const JOB_COST_TYPES = [
    {
        id: JobCostType.DoctorFee,
        label: "doctorFee",
        templates: [
            JobCostTemplate.Jc1Template,
            JobCostTemplate.Jc2Template,
            JobCostTemplate.Jc3Template,
        ],
    },

    {
        id: JobCostType.NurseFee,
        label: "nurseFeePrimary",
        templates: [JobCostTemplate.Jc1Template, JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.NurseFeeSub,
        label: "nurseFeeSub",
        templates: [JobCostTemplate.Jc1Template, JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.TherapistFee,
        label: "therapistFeePrimary",
        templates: [JobCostTemplate.Jc1Template, JobCostTemplate.Jc3Template],
    },
    {
        id: JobCostType.TherapistFeeSub,
        label: "therapistFeeSub",
        templates: [JobCostTemplate.Jc1Template, JobCostTemplate.Jc3Template],
    },
    {
        id: JobCostType.AnesthesiologistFee,
        label: "anesthesiologistFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.AssistantFee,
        label: "assistantFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.AssistantScrubFee,
        label: "assistantScrubFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.AssistantAnesthesiologistFee,
        label: "assistantAnesthesiologistFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.AidNurseFee,
        label: "aidNurseFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.CirculateNurseFee,
        label: "circulateNurseFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.PractitionerNurseFee,
        label: "practitionerNurseFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.RecoveryRoomNurseFee,
        label: "recoveryRoomNurseFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.RegisterNurseFee,
        label: "registerNurseFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.ScrubNurseFee,
        label: "scrubNurseFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.StaffFee,
        label: "staffFee",
        templates: [JobCostTemplate.Jc2Template],
    },
    {
        id: JobCostType.TherapistFee_2,
        label: "therapistFee2",
        templates: [JobCostTemplate.Jc3Template],
    },
    {
        id: JobCostType.TherapistFee_3,
        label: "therapistFee3",
        templates: [JobCostTemplate.Jc3Template],
    },
    {
        id: JobCostType.TherapistFee_4,
        label: "therapistFee4",
        templates: [JobCostTemplate.Jc3Template],
    },
    {
        id: JobCostType.TherapistFee_5,
        label: "therapistFee5",
        templates: [JobCostTemplate.Jc3Template],
    },
    {
        id: JobCostType.TherapistFee_6,
        label: "therapistFee6",
        templates: [JobCostTemplate.Jc3Template],
    },
];

export const JOB_COST_NOT_APPLY_FOR_LA_SURGERY = [
    JobCostType.AnesthesiologistFee,
    JobCostType.AssistantAnesthesiologistFee,
    JobCostType.RecoveryRoomNurseFee,
    JobCostType.RegisterNurseFee,
    JobCostType.PractitionerNurseFee,
    JobCostType.AidNurseFee,
    JobCostType.StaffFee,
];

export const JOB_COST_TEMPLATES = [
    { id: JobCostTemplate.Jc1Template, label: "JcTemplate_1" },
    { id: JobCostTemplate.Jc2Template, label: "JcTemplate_2" },
    { id: JobCostTemplate.Jc3Template, label: "JcTemplate_3" },
];

export const SERVICE_SURGERY_TYPES = [
    { id: ServiceSurgeryType.None, label: "none" },
    { id: ServiceSurgeryType.La, label: "la" },
    { id: ServiceSurgeryType.Ga, label: "ga" },
];

export const SERVICE_ADJUSTMENT_CONFIGS = [
    { id: "YES", label: "yes" },
    { id: "NO_UP_TO", label: "yesUpToInitQty" },
    { id: "NO_REGARDLESS", label: "yesRegardlessInitQty" },
];
