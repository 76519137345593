import { Header } from "d-react-components";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Messages from "../../languages/Messages";
import ImportCustomer from "./import-customer/ImportCustomer";
import VerticalTabView from "../../common/VerticalTabView";
import Path from "../Path";

const TABS = [
    {
        id: "customer",
        label: Messages.importCustomer,
        component: <ImportCustomer />,
        url: Path.IMPORT_CUSTOMER,
    },
];

const Import = () => {
    const [selectedTab, setSelectedTab] = useState(TABS[0]);
    const navigate = useNavigate();
    return (
        <div>
            <Header
                showCancel={false}
                className="sticky top-0 z-10"
                title={Messages.importTools}
            />
            <div className="h-100 overflow-auto p-3 bg-white">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <VerticalTabView
                            dataSource={TABS}
                            value={selectedTab}
                            onChange={(tabSelect: any) => {
                                setSelectedTab(tabSelect);
                                navigate(tabSelect.url);
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Import;
