import ClassNames from "classnames";
import { Loading, ObjectUtils, TabBar, TabBarProps } from "d-react-components";
import { isEmpty, map, uniqBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useGetBranchListLazyQuery } from "../../../api/hooks";
import { IBranch } from "../../../interfaces/branch";

interface IBranchSelectByTab
    extends Omit<Partial<TabBarProps<any>>, "value" | "onChange"> {
    className?: string;
    value: IBranch;
    multiple?: boolean;
    onChange: (value: IBranch | IBranch[]) => void;
    autoLoadData?: boolean;
    branchListProps?: Array<any>;
}

const BranchSelectByTab = ({
    value,
    multiple,
    className,
    onChange,
    autoLoadData = true,
    branchListProps,
    ...tabProps
}: IBranchSelectByTab) => {
    const [branchList, setBranchList] = useState<any[]>(branchListProps ?? []);
    const [getBranchList] = useGetBranchListLazyQuery();

    useEffect(() => {
        if (!autoLoadData) {
            return;
        }
        getBranchList({
            variables: {
                paginate: {
                    page: 1,
                    limit: 1000,
                },
            },
        }).then((res: any) => {
            const branchRes = res?.data?.listBranchForAdmin?.data ?? [];
            const filterBranch = map(branchRes, (item) => ({
                id: item.id,
                name: item.name,
            }));
            const uniqBranch = uniqBy(filterBranch, (item) => item.id);
            setBranchList(uniqBranch);
        });
    }, []);
    const branchValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return value as any;
    }, [value]);

    return (
        <React.Fragment>
            {isEmpty(branchList) ? (
                <Loading />
            ) : (
                <TabBar
                    activeIndicator="none"
                    className={className}
                    dataSource={branchList}
                    getLabel={(item) => item?.name}
                    value={branchValue}
                    onChange={onChange as any}
                    getItemProps={({ isActive }) => {
                        return {
                            className: `border-none  ${ClassNames({
                                "text-primary": isActive,
                            })}`,
                            color: "gray",
                        };
                    }}
                    {...tabProps}
                />
            )}
        </React.Fragment>
    );
};

export default BranchSelectByTab;
