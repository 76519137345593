import { ICustomer } from "./customer";
import IUser from "./user";
import { IVoucher } from "./voucher";

export interface ITransferVoucher {
    id: string;
    transferVoucherNo: string;
    transferVoucherType: string;
    status: string;
    remark: string;
    createdAt: number;
    from: ICustomer;
    voucher: IVoucher[];
    to: ICustomer;
    confirmation: {
        at: string;
        remark: string;
        createByAdmin: IUser;
    };
    createByAdmin: IUser;
    reason: string;
    voucherExpiryDate: { id: string; expiryDate: string }[];
}

export const mapTransferVoucherFilterToServer = (
    input: any,
    searchKey?: string
) => {
    return {
        status: input?.status,
        transferVoucherType: input?.transferVoucherType || undefined,
        searchKey: searchKey ? [searchKey] : undefined,
    };
};
