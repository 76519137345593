import React from "react";

interface IEquipmentStockRequestDetailState {
    stockRequest: any;
    setStockRequest: any;
}

const equipmentStockRequestDetailState: IEquipmentStockRequestDetailState = {
    stockRequest: {} as any,
    setStockRequest: () => {},
};

export const EquipmentStockRequestDetailContext = React.createContext(
    equipmentStockRequestDetailState
);
