import React, { useContext } from "react";
import TimeSlotSelect from "../../../common/TimeSlotSelect";
import { BookingCreateContext } from "../../../context/booking";
import Messages from "../../../languages/Messages";

const BookingCreateEstTimeVisit = () => {
    const { bookingForm } = useContext(BookingCreateContext);

    const formValues = bookingForm?.values;
    if (formValues?.doctor) {
        return <div />;
    }
    return (
        <div className="mt-3">
            <TimeSlotSelect
                label={Messages.timeSlot}
                workingSchedule={[{ start: 0, end: 24 }]}
                occupiedSchedule={[]}
                className="mt-3"
                value={formValues?.estTimeVisit}
                onChange={(value) => {
                    bookingForm.setFieldValue("estTimeVisit", value);
                }}
                multiple
            />
        </div>
    );
};

export default BookingCreateEstTimeVisit;
