import {
    MaterialPurchaseDeliveryStatus,
    MaterialPurchaseOrderStatus,
    MaterialPurchasePaymentMethod,
    MaterialPurchasePaymentStatus,
    MaterialPurchaseQuotationStatus,
    MaterialPurchaseRequestStatus,
    MaterialPurchaseStatus,
} from "../api/hooks";

export const MATERIAL_CATEGORY_STATUSES = [
    { id: "DISABLED", label: "disabled", color: "#C4C4C4" },
    { id: "ENABLED", label: "enabled", color: "#33B950" },
];

export const MATERIAL_STATUSES = [
    { id: "DISABLED", label: "disabled", color: "#C4C4C4" },
    { id: "ENABLED", label: "enabled", color: "#33B950" },
];

export const MATERIAL_ADJUST_REQUEST_STATUS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
};

export const MATERIAL_ADJUST_REQUEST_STATUSES = [
    { id: "PENDING", label: "pending", color: "#FAC256" },
    { id: "APPROVED", label: "approved", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
];

export const MATERIAL_REQUEST_STATUSES = [
    {
        id: MaterialPurchaseRequestStatus.Pending,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: MaterialPurchaseRequestStatus.Approved,
        label: "approved",
        color: "#33B950",
    },
    {
        id: MaterialPurchaseRequestStatus.Cancelled,
        label: "cancelled",
        color: "#C4C4C4",
    },
    {
        id: MaterialPurchaseRequestStatus.Completed,
        label: "completed",
        color: "#FAC256",
    },
];

export const MATERIAL_QUOTATION_STATUSES = [
    {
        id: MaterialPurchaseQuotationStatus.Pending,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: MaterialPurchaseQuotationStatus.Approved,
        label: "approved",
        color: "#33B950",
    },
    {
        id: MaterialPurchaseQuotationStatus.Rejected,
        label: "rejected",
        color: "#ED6969",
    },
    {
        id: MaterialPurchaseQuotationStatus.Cancelled,
        label: "cancelled",
        color: "#C4C4C4",
    },
];

export enum MATERIAL_INVENTORY_HISTORY_TYPE {
    MATERIAL_STOCK_ADJUSTMENT = "MATERIAL_STOCK_ADJUSTMENT",
    MATERIAL_PURCHASE_ORDER = "MATERIAL_PURCHASE_ORDER",
    TREATMENT = "TREATMENT",
}

export const MATERIAL_PURCHASE_STATUSES = [
    {
        id: MaterialPurchaseStatus.PendingPayment,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: MaterialPurchaseStatus.PendingConfirmation,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: MaterialPurchaseStatus.DeliveryProcessing,
        label: "inDelivery",
        color: "#29B3BC",
    },
    {
        id: MaterialPurchaseStatus.MaterialPurchaseProcessing,
        label: "processing",
        color: "#84C4FF",
    },
    {
        id: MaterialPurchaseStatus.Completed,
        label: "completed",
        color: "#33B950",
    },
    {
        id: MaterialPurchaseStatus.Delivered,
        label: "delivered",
        color: "#33B950",
    },
    {
        id: MaterialPurchaseStatus.Refunded,
        label: "refunded",
        color: "#33B950",
    },
    {
        id: MaterialPurchaseStatus.Cancelled,
        label: "cancelled",
        color: "#C4C4C4",
    },
];

export const MATERIAL_PURCHASE_ORDER_STATUSES = [
    {
        id: MaterialPurchaseOrderStatus.Pending,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: MaterialPurchaseOrderStatus.Approved,
        label: "approved",
        color: "#33B950",
    },
    {
        id: MaterialPurchaseOrderStatus.Rejected,
        label: "rejected",
        color: "#C4C4C4",
    },
];

export const MATERIAL_PAYMENTS_METHODS = [
    {
        value: MaterialPurchasePaymentMethod.BankTransfer,
        label: "bankTransfer",
    },
    {
        value: MaterialPurchasePaymentMethod.Cash,
        label: "cash",
    },
    {
        value: MaterialPurchasePaymentMethod.Cheque,
        label: "cheque",
    },
    // {
    //     value: MaterialPurchasePaymentMethod.Default,
    //     label: "default",
    // },
];

export const MATERIAL_PAYMENT_STATUSES = [
    {
        id: MaterialPurchasePaymentStatus.Confirmed,
        label: "confirmed",
        color: "#33B950",
    },
    {
        id: MaterialPurchasePaymentStatus.Pending,
        label: "pending",
        color: "#FAC256",
    },
    {
        id: MaterialPurchasePaymentStatus.PendingPaymentConfirmation,
        label: "pendingPaymentConfirmation",
        color: "#FAC256",
    },
    {
        id: MaterialPurchasePaymentStatus.Rejected,
        label: "rejected",
        color: "#ED6969",
    },
];

export const MATERIAL_INVENTORY_STATUSES = [
    { id: "in-stock", label: "inStock" },
    { id: "low-stock", label: "lowStock" },
    { id: "out-of-stock", label: "outOfStock" },
];

export const MATERIAL_ORDER_DELIVERY_STATUSES = [
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryPending,
        label: "deliveryPending",
    },
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryBooked,
        label: "deliveryBooked",
    },
    {
        key: MaterialPurchaseDeliveryStatus.InDelivery,
        label: "inDelivery",
    },
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryCompleted,
        label: "deliveryCompleted",
    },
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryCancelled,
        label: "deliveryCancelled",
    },
];

export const MATERIAL_PURCHASE_DELIVERY_STATUSES = [
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryPending,
        label: "deliveryPending",
    },
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryBooked,
        label: "deliveryBooked",
    },
    {
        key: MaterialPurchaseDeliveryStatus.InDelivery,
        label: "inDelivery",
    },
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryCompleted,
        label: "deliveryCompleted",
    },
    {
        key: MaterialPurchaseDeliveryStatus.DeliveryCancelled,
        label: "deliveryCancelled",
    },
];