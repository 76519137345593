import { TimePicker } from "antd";
import {
    Button,
    DateInput,
    Dropdown,
    Icon,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import moment from "moment";
import { useCallback, useContext, useMemo, useState } from "react";
import TreatmentAPI from "../../../api/queries/treatment";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import PermissibleRender from "../../../common/PermissibleComponent";
import {
    TreatmentJobCostAssessmentOptionType,
    TREATMENT_JOB_COST_ASSESSMENT_OPTIONS,
    TREATMENT_STATUS,
    TREATMENT_STATUSES,
} from "../../../constant/treatment";
import { PERMISSION } from "../../../constant/user";
import { TreatmentDetailContext } from "../../../context/treatment";
import Messages from "../../../languages/Messages";
import TreatmentJobAssessmentDrawer from "./treatment-jobcost/TreatmentJobAssessmentDrawer";
import TreatmentJobAssessmentImportDrawer from "./treatment-jobcost/TreatmentJobAssessmentImportDrawer";

interface ITreatmentStartDrawer {
    open: boolean;
    onClose: () => void;
}

const TreatmentStartDrawer = ({ open, onClose }: ITreatmentStartDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);

    const treatmentForm = useFormik<any>({
        initialValues: {
            actualTime: moment(),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            onStartTreatment(values);
        },
    });

    const onStartTreatment = (input: any) => {
        Progress.show(
            {
                method: TreatmentAPI.start,
                params: [treatment?.id, input],
            },
            (newTreatment: any) => {
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(newTreatment);
            }
        );
    };

    const formValues = treatmentForm?.values;
    const formErrors = treatmentForm?.errors;

    const inputClassName = "col-12 mt-3";
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.startTreatment}
            onSave={() => treatmentForm.handleSubmit()}
        >
            <div className="rows">
                <div className={`${inputClassName} d-date-input`}>
                    <label>{Messages.recordAt}</label>
                    <TimePicker
                        onChange={(value) => {
                            treatmentForm.setFieldValue("actualTime", value);
                        }}
                        value={
                            formValues?.actualTime
                                ? moment(formValues?.actualTime, "HH:mm")
                                : (null as any)
                        }
                        format="HH:mm"
                        className="d-date-input__input"
                    />
                </div>
                <InputTextForm
                    form={treatmentForm}
                    keyData="remark"
                    className={inputClassName}
                    multiple
                />
            </div>
        </Drawer>
    );
};

interface ITreatmentCompleteDrawer {
    open: boolean;
    onClose: () => void;
}

const TreatmentCompleteDrawer = ({
    open,
    onClose,
}: ITreatmentCompleteDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);

    const treatmentForm = useFormik<any>({
        initialValues: {
            actualTime: moment(),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            onCompleteTreatment(values);
        },
    });

    const onCompleteTreatment = (input: any) => {
        Progress.show(
            {
                method: TreatmentAPI.complete,
                params: [treatment?.id, input],
            },
            (newTreatment: any) => {
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(newTreatment);
            }
        );
    };

    const formValues = treatmentForm?.values;
    const formErrors = treatmentForm?.errors;

    const inputClassName = "col-12 mt-3";
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.completeTreatment}
            onSave={() => treatmentForm.handleSubmit()}
        >
            <div className="rows">
                <div className={`${inputClassName} d-date-input`}>
                    <label>{Messages.recordAt}</label>
                    <TimePicker
                        onChange={(value) => {
                            treatmentForm.setFieldValue("actualTime", value);
                        }}
                        value={
                            formValues?.actualTime
                                ? moment(formValues?.actualTime, "HH:mm")
                                : (null as any)
                        }
                        format="HH:mm"
                        className="d-date-input__input"
                    />
                </div>
                <InputTextForm
                    form={treatmentForm}
                    keyData="remark"
                    className={inputClassName}
                    multiple
                />
            </div>
        </Drawer>
    );
};

const TreatmentCancelDrawer = ({ open, onClose }: ITreatmentCompleteDrawer) => {
    const { treatment, setTreatment } = useContext(TreatmentDetailContext);

    const treatmentForm = useFormik<any>({
        initialValues: {
            actualTime: moment(),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            onCancelTreatment(values);
        },
    });

    const onCancelTreatment = (input: any) => {
        Progress.show(
            {
                method: TreatmentAPI.cancel,
                params: [treatment?.id, input?.remark],
            },
            (newTreatment: any) => {
                onClose();
                Notifications.showSuccess(Messages.updateSuccess);
                setTreatment(newTreatment);
            }
        );
    };

    const formValues = treatmentForm?.values;
    const formErrors = treatmentForm?.errors;

    const inputClassName = "col-12 mt-3";
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.cancel}
            onSave={() => treatmentForm.handleSubmit()}
        >
            <div className="rows">
                <InputTextForm
                    form={treatmentForm}
                    keyData="remark"
                    className={inputClassName}
                    multiple
                />
            </div>
        </Drawer>
    );
};

const TreatmentDetailHeader = () => {
    const { treatment, onPrint } = useContext(TreatmentDetailContext);
    const { jobCostMetric } = treatment || {};
    const [openStart, setOpenStart] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openAssessmentDrawer, setOpenAssessmentDrawer] = useState<{
        open: boolean;
        type?: TreatmentJobCostAssessmentOptionType;
    }>({ open: false });
    const [openAssessmentImportDrawer, setOpenAssessmentImportDrawer] =
        useState(false);
    const [warningExpand, setWarningExpand] = useState(true);

    const renderWarningJobCostMetric = useMemo(() => {
        if (jobCostMetric?.valid === false && jobCostMetric?.reasonOfInvalid) {
            return (
                <div
                    className="bg-yellowLight flex flex-row justify-center items-center px-3 gap-3 hover:cursor-pointer"
                    onClick={() => {
                        setWarningExpand(!warningExpand);
                    }}
                >
                    <Icon name="warning" className="text-orange-400" />
                    {warningExpand ? (
                        <div className="text-orange-400 font-semibold">
                            {jobCostMetric.reasonOfInvalid}
                        </div>
                    ) : null}
                </div>
            );
        }
        return null;
    }, [jobCostMetric, warningExpand]);

    const onClickJobAssessment = useCallback((item) => {
        switch (item?.id) {
            case TreatmentJobCostAssessmentOptionType.AUTO_CALCULATE:
                setOpenAssessmentDrawer({
                    open: true,
                    type: TreatmentJobCostAssessmentOptionType.AUTO_CALCULATE,
                });
                break;
            case TreatmentJobCostAssessmentOptionType.MANUAL_INPUT:
                setOpenAssessmentDrawer({
                    open: true,
                    type: TreatmentJobCostAssessmentOptionType.MANUAL_INPUT,
                });
                break;
            case TreatmentJobCostAssessmentOptionType.MANUAL_IMPORT:
                setOpenAssessmentImportDrawer(true);
                break;
            default:
                break;
        }
    }, []);

    return (
        <div className="bg-white px-4 py-3 flex-row-between-center border-bottom">
            <div className="flex flex-1 align-items-center">
                <h5 className="text-primary">{`${treatment?.treatmentNo}`}</h5>
                <ViewLabelStatus
                    status={treatment?.status}
                    listStatus={TREATMENT_STATUSES}
                    className="ml-3"
                    getLabel={(item) => Messages[item.label]}
                />
            </div>
            <div className="d-flex">
                <PermissibleRender
                    permissions={[
                        PERMISSION.TREATMENT.UPDATE_JOB_ASSESSMENT,
                        PERMISSION.TREATMENT.UPDATE_MANUAL_JOB_ASSESSMENT,
                    ]}
                >
                    {renderWarningJobCostMetric}
                    <Dropdown
                        className="treatment-job-cost__job-assessment-dropdown"
                        dataSource={TREATMENT_JOB_COST_ASSESSMENT_OPTIONS}
                        onClick={onClickJobAssessment}
                    >
                        <Button
                            hidden={!jobCostMetric?.valid}
                            variant="outline"
                            className="ml-2"
                            suffixIcon="arrow_drop_down"
                        >
                            {Messages.jobCostAssessment}
                        </Button>
                    </Dropdown>
                </PermissibleRender>

                <Button
                    hidden={treatment?.status !== TREATMENT_STATUS.SCHEDULED}
                    variant="outline"
                    onClick={() => setOpenStart(true)}
                    className="ml-2"
                >
                    {Messages.startTreatment}
                </Button>
                <Button
                    hidden={treatment?.status !== TREATMENT_STATUS.IN_PROGRESS}
                    variant="outline"
                    onClick={() => setOpenComplete(true)}
                    className="ml-2"
                >
                    {Messages.completeTreatment}
                </Button>
                <Button
                    hidden={treatment?.status === TREATMENT_STATUS.CANCELLED}
                    variant="outline"
                    onClick={() => setOpenCancel(true)}
                    className="ml-2"
                    color="error"
                >
                    {Messages.cancel}
                </Button>
                {treatment?.status === TREATMENT_STATUS.COMPLETED && (
                    <Button
                        variant="outline"
                        onClick={onPrint}
                        className="ml-2"
                    >
                        {Messages.print}
                    </Button>
                )}
            </div>
            {openStart && (
                <TreatmentStartDrawer
                    open={openStart}
                    onClose={() => setOpenStart(false)}
                />
            )}
            {openComplete && (
                <TreatmentCompleteDrawer
                    open={openComplete}
                    onClose={() => setOpenComplete(false)}
                />
            )}
            {openCancel && (
                <TreatmentCancelDrawer
                    open={openCancel}
                    onClose={() => setOpenCancel(false)}
                />
            )}

            {openAssessmentDrawer.open &&
                openAssessmentDrawer.type &&
                [
                    TreatmentJobCostAssessmentOptionType.AUTO_CALCULATE,
                    TreatmentJobCostAssessmentOptionType.MANUAL_INPUT,
                ].includes(openAssessmentDrawer.type) && (
                    <TreatmentJobAssessmentDrawer
                        onClose={() => setOpenAssessmentDrawer({ open: false })}
                        open={openAssessmentDrawer.open}
                        assessmentType={openAssessmentDrawer.type}
                    />
                )}
            {openAssessmentImportDrawer && (
                <TreatmentJobAssessmentImportDrawer
                    onClose={() => setOpenAssessmentImportDrawer(false)}
                    open={openAssessmentImportDrawer}
                />
            )}
        </div>
    );
};

export default TreatmentDetailHeader;
