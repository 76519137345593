import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Icon,
    InputTextSearch,
} from "d-react-components";
import { debounce, filter, includes, isEmpty, toLower } from "lodash";
import { useContext, useMemo, useState } from "react";

import { AppointmentDetailContext } from "../../../context/appointment";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/share/ProductNameView";

const AppointmentDetailServices = () => {
    const { setAppointment, appointment } = useContext(
        AppointmentDetailContext
    );
    const [openAdd, setOpenAdd] = useState(false);
    const [textSearch, setTextSearch] = useState("");

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },

        // {
        //     title: Messages.action,
        //     dataIndex: "",
        //     render: (service) => (
        //         <Icon
        //             name="remove_circle"
        //             className="cursor-pointer"
        //             onClick={() => onRemove(service)}
        //         />
        //     ),
        // },
    ];

    const onRemove = (product: IProduct) => {
        // DialogManager.showConfirm(
        //     Messages.confirm,
        //     Messages.areYouSureWantRemove,
        //     () =>
        //         Progress.show(
        //             {
        //                 method: AppointmentAPI.removeService,
        //                 params: [appointment?.id, product?.id],
        //             },
        //             (appointmentRes) => {
        //                 setAppointment(appointmentRes);
        //                 Notifications.showSuccess(Messages.removeSuccess);
        //             }
        //         )
        // );
    };

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 400);

    const serviceDataSource = useMemo(() => {
        if (isEmpty(textSearch)) {
            return appointment?.services ?? [];
        }

        return filter(appointment?.services ?? [], (product) =>
            includes(toLower(product?.name), toLower(textSearch))
        );
    }, [textSearch, appointment?.services]);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.service}</label>
                {/* <Button onClick={() => setOpenAdd(true)} className="">
                    {Messages.add}
                </Button> */}
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />

            <AwesomeTableComponent
                columns={columns}
                dataSource={serviceDataSource}
                className="mt-3"
                pagination={false}
            />
        </div>
    );
};

export default AppointmentDetailServices;
