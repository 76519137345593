import {
    Avatar,
    Button,
    Icon,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import { isEmpty, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { generatePath } from "react-router";
import {
    useGetCustomerDetailLazyQuery,
    useUpdateCustomerTagMutation,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import Image from "../../../common/Image";
import { QuotationDetailContext } from "../../../context/quotation";
import { ICustomer } from "../../../interfaces/customer";
import { IQuotation } from "../../../interfaces/quotation";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import TagSelectModal from "../../tags/share/TagSelectModal";

const QuotationSummaryView = () => {
    const { quotation } = useContext(QuotationDetailContext);
    const [getCustomerDetail] = useGetCustomerDetailLazyQuery();
    const { customer: quotationCustomer, contact } =
        quotation ?? ({} as IQuotation);

    const [customer, setCustomer] = useState<ICustomer>();
    const [updateCustomerTag] = useUpdateCustomerTagMutation();
    const [openAddTag, setOpenAddTag] = useState(false);
    const {
        avatar,
        lineId,
        email,
        phone,
        tags,
        customerNo,
        firstNameEn,
        lastNameEn,
        nickname,
        title,
        gender,
        birthDay,
        createdAt,
        updatedAt,
    } = customer ?? {};

    const onClickPhone = () => {};
    const onClickEmail = () => {};
    const onClickLine = () => {};

    useEffect(() => {
        if (quotationCustomer && quotationCustomer.id) {
            loadCustomerDetail();
        }
    }, [quotationCustomer]);

    const loadCustomerDetail = () => {
        Progress.show(
            {
                method: getCustomerDetail,
                params: [{ variables: { id: quotationCustomer.id } }],
            },
            (res: any) => {
                const customerDetail = res?.data?.data?.data ?? {};
                setCustomer(customerDetail);
            }
        );
    };

    const onUpdateCustomerTags = (newTags: any[]) => {
        Progress.show(
            {
                method: updateCustomerTag,
                params: [
                    {
                        variables: {
                            customerId: customer?.id,
                            tagIds: map(newTags, (item) => item.id),
                        },
                    },
                ],
            },
            (res: any) => {
                setOpenAddTag(false);
                Notifications.showSuccess(Messages.updateCustomerTagSuccess);
                const newCustomer = res?.data?.data?.data;
                setCustomer(newCustomer);
            }
        );
    };

    const renderContactIcon = (
        iconName: string,
        onClick: any,
        href: string = "#"
    ) => (
        <a href={href}>
            <Image
                onClick={onClick}
                src={`/images/contact/${iconName}.png`}
                className="mr-2 cursor-pointer"
                style={{ width: "20px", height: "20px" }}
            />
        </a>
    );

    const renderInfoRow = (title: any, content: any) => {
        return (
            <div className="flex-row-between-center border-bottom py-3 w-100">
                <div className="text-bold">{title}</div>
                <div className="text">{content}</div>
            </div>
        );
    };

    if (isEmpty(quotation)) {
        return <></>;
    }
    return (
        <div className="w-100 bg-white">
            {quotationCustomer && customer && (
                <div className="flex-column flex-center border py-5 px-4">
                    <Avatar
                        src={avatar}
                        size="large"
                        text={customer?.fullNameTh}
                    />
                    <h4 className="text-primary mt-2">
                        <AppLink
                            to={generatePath(Path.CUSTOMER_DETAIL, {
                                customerId: quotationCustomer.id,
                            })}
                            target="_blank"
                        >
                            {/* {getCustomerNameByLanguage(customer as ICustomer)} */}
                            {customer?.fullNameTh}
                        </AppLink>
                    </h4>
                    <div className="d-flex mt-2">
                        {phone &&
                            renderContactIcon(
                                "phone",
                                onClickPhone,
                                `tel:${customer?.phone}`
                            )}
                        {email &&
                            renderContactIcon(
                                "email",
                                onClickEmail,
                                `mailto:${customer?.email}`
                            )}
                        {lineId && renderContactIcon("line", onClickLine)}
                    </div>
                    <div className="d-flex flex-center w-100 flex-wrap px-2">
                        <Icon name="local_offer" className="text-primary" />
                        {map(tags, (tag) => (
                            <div className="px-2 py-1 ml-2 bg-primary text-white small text-no-wrap mt-2">
                                {tag.name}
                            </div>
                        ))}
                        <Button
                            iconName="add"
                            size="x-small"
                            variant="outline"
                            className="ml-2 mt-2"
                            onClick={() => setOpenAddTag(true)}
                        >
                            {Messages.add}
                        </Button>
                    </div>
                </div>
            )}
            {!quotationCustomer && contact && (
                <div className="flex-column flex-center border py-5 px-4">
                    <Avatar
                        src={avatar}
                        size="large"
                        text={contact.firstName}
                    />
                    <h4 className="text-primary mt-2">
                        {contact.firstName} {contact.lastName}
                    </h4>
                    <div className="d-flex mt-2">
                        {contact.phone &&
                            renderContactIcon(
                                "phone",
                                onClickPhone,
                                `tel:${contact?.phone}`
                            )}
                    </div>
                </div>
            )}
            {quotationCustomer && (
                <div className="flex-column flex-center border p-3">
                    {renderInfoRow(Messages.customerNo, customerNo)}
                    {renderInfoRow(Messages.firstName, firstNameEn)}
                    {renderInfoRow(Messages.lastName, lastNameEn)}
                    {renderInfoRow(Messages.nickname, nickname)}
                    {renderInfoRow(Messages.title, title)}
                    {renderInfoRow(Messages.gender, gender)}
                    {renderInfoRow(
                        Messages.dateOfBirth,
                        TimeUtils.toDate(birthDay)
                    )}
                    {renderInfoRow(
                        Messages.joinSince,
                        TimeUtils.toDate(createdAt)
                    )}
                    {renderInfoRow(
                        Messages.lastUpdated,
                        TimeUtils.toDate(updatedAt)
                    )}
                </div>
            )}
            {!quotationCustomer && contact && (
                <div className="flex-column flex-center border p-3">
                    {renderInfoRow(Messages.firstName, contact.firstName)}
                    {renderInfoRow(Messages.lastName, contact.lastName)}
                    {renderInfoRow(Messages.phone, contact.phone)}
                    {renderInfoRow(Messages.email, contact.email)}
                </div>
            )}
            {openAddTag && (
                <TagSelectModal
                    open={openAddTag}
                    onClose={() => setOpenAddTag(false)}
                    value={tags ?? []}
                    onSave={onUpdateCustomerTags}
                />
            )}
        </div>
    );
};

export default QuotationSummaryView;
