import React from 'react';
import { useParams } from 'react-router';
import ActivityView from '../../../common/activity/ActivityView';
import { RefActivity } from '../../../api/hooks';

const MaterialActivity = () => {
  const { materialId } = useParams<any>();
  return (
      <div>
          {materialId && (
              <ActivityView
                  className="mt-3 border"
                  activityRef={RefActivity?.Material}
                  activityRefId={materialId ?? ""}
              />
          )}
      </div>
  );
};

export default MaterialActivity;