import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useRef } from "react";
import { generatePath, useNavigate } from "react-router";

import VendorAPI from "../../../api/queries/vendor";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import { VENDOR_STATUSES } from "../../../constant/vendor";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import Image from "../../../common/Image";

const VendorTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();

    const columns: IColumnsProps = [
        {
            title: Messages.vendorId,
            dataIndex: "vendorNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.VENDOR_DETAIL, {
                        vendorId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.name,
            dataIndex: "name",
            render: (name, item) => (
                <div className="d-flex align-items-center">
                    <Image
                        src={item.picture}
                        className="image-square-tiny"
                    />
                    <div className="ml-2">{name}</div>
                </div>
            ),
        },
        {
            title: Messages.countryOfOrigin,
            dataIndex: "countryOfOrigin",
            render: (countryOfOrigin) => countryOfOrigin?.name,
        },
        {
            title: Messages.phone,
            dataIndex: "phone",
        },
        {
            title: Messages.qtyOfPO,
            dataIndex: "totalPurchaseOrder",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus status={status} listStatus={VENDOR_STATUSES} />
            ),
        },

        {
            title: Messages.lastUpdated,
            dataIndex: "updatedAt",
            width: 100,
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (vendor: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, vendor)
                        }
                        dataSource={[
                            // { id: "update", label: "update" },
                            { id: "detail", label: "detail" },
                        ]}
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = {};
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return VendorAPI.list(input);
    };

    const onClickTableAction = (actionId: string, vendor: any) => {
        switch (actionId) {
            case "update":
                break;
            default:
                navigate(
                    generatePath(Path.VENDOR_DETAIL, {
                        vendorId: vendor.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.VENDOR_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div>
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.vendorManagement}
                onClickFilter={() => {}}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
            />
        </div>
    );
};

export default VendorTable;
