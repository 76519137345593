import { gql } from "@apollo/client";
import API from "../API";

export const GQLLogin = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            accessToken
            isOtpAuth
        }
    }
`;

export const GQLOtpAuth = gql`
    mutation authenticate($code: String!, $accessToken: String!) {
        authenticate(code: $code, accessToken: $accessToken) {
            accessToken
        }
    }
`;

export default {
    signIn: (bodyLogin: any) => {
        return API.instance.mutate({
            mutation: GQLLogin,
            variables: bodyLogin,
        });
    },
    otpAuthenticate: (bodyOtp: any) =>
        API.instance.mutate({
            mutation: GQLOtpAuth,
            variables: bodyOtp,
        }),

    updateMessingToken: (token: any) =>
        API.mutate({
            mutation: gql`
                mutation updateMessagingToken($token: String) {
                    updateMessagingToken(token: $token) {
                        messagingToken
                    }
                }
            `,
            variables: { token },
        }),
};
