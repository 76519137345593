import React, { useContext, useMemo, useState } from "react";
import { Spin } from "antd";
import { Notifications, Button, TimeUtils, Badge } from "d-react-components";
import { find } from "lodash";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import {
    DeliveryStatus,
    MaterialPurchaseDeliveryStatus,
    OrderDeliveryStatus,
    OrderDeliveryType,
    OrderStatus,
} from "../../../api/hooks";
import OrderDeliveryCreate from "./OrderDeliveryCreate";
import { ORDER_DELIVERY_STATUSES } from "../../../constant/order";
import OrderDeliveryStatusUpdate from "./OrderDeliveryStatusUpdate";
import { MaterialPurchaseDetailContext } from "../../../context/material";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Gallery from "../../../common/gallery/Gallery";
import { MATERIAL_ORDER_DELIVERY_STATUSES } from "../../../constant/material";

const DeliveryTrackingItem = ({
    deliveryTracking,
    onReloadOrderData,
    order,
    ...props
}: any) => {
    const { loadDeliveryList } = useContext(OrderDetailContext);
    const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
    const [progressPrint, setProgressPrint] = useState(false);
    const [openEditDeliveryModal, setOpenEditDeliveryModal] = useState(false);
    const [isViewDelivery, setIsViewDelivery] = useState(false);

    const isDislayEdit =
        deliveryTracking?.status !== OrderDeliveryStatus.DeliveryCompleted;

    const onClickCopy = () => {
        navigator.clipboard.writeText(deliveryTracking.code);
        Notifications.showInfo(Messages.copySuccess);
    };

    const isDisplayTrackIcon = () => {
        return (
            deliveryTracking?.delivery?.courier === OrderDeliveryType.Shippop
        );
    };

    const isDisplayUpdateStatus = () => {
        return (
            !isDisplayTrackIcon() &&
            deliveryTracking.status !==
                MaterialPurchaseDeliveryStatus.DeliveryCompleted
        );
    };

    const onClickTrackPackage = () => {
        const trackingUrl = deliveryTracking?.delivery?.tracking;
        if (!trackingUrl || !deliveryTracking.code) {
            Notifications.showError(Messages.hasNoTrackingInfo);
            return;
        }
        const urlReplacedCode = trackingUrl.replace(
            "#code",
            deliveryTracking.code
        );

        window.open(urlReplacedCode, "_blank");
    };

    const onClickPrintShippopHandle = () => {
        // setProgressPrint(true);
        // printDelivery(order?.id).then((respone) => {
        //     const listDelivery = respone?.data?.data?.print ?? [];
        //     const printResult = listDelivery.find(
        //         (item: any) => item.code === deliveryTracking.code
        //     );
        //     if (isEmpty(printResult) || isEmpty(printResult?.download)) {
        //         setProgressPrint(false);
        //         Notifications.showError(
        //             t("notification:unableToPrintThisShippop")
        //         );
        //     } else {
        //         setProgressPrint(false);
        //         window.open(printResult?.download);
        //     }
        // });
        // logPrint(order?.id, "delivery");
    };

    const codeDisplay = () => {
        if (isDisplayTrackIcon()) {
            return deliveryTracking?.deliveryNo ?? null;
        }
        return deliveryTracking?.deliveryNo ?? null;
    };

    const getNumberOfItemDelivery = () => {
        let total = 0;
        const listItems = deliveryTracking?.materials ?? [];
        listItems.forEach((item: any) => {
            total += item.quantity;
        });
        return total;
    };

    const deliveryStatus = useMemo(() => {
        return find(MATERIAL_ORDER_DELIVERY_STATUSES, {
            key: deliveryTracking.status,
        });
    }, [deliveryTracking.status]);

    return (
        <div
            className="deliveryTracking border-b border-gray-200"
            key={props.key}
        >
            {deliveryTracking.deliveryNo && (
                <div className="flex items-center my-2">
                    <span className="font-weight-bold mr-2">
                        {Messages.ref}:
                    </span>
                    <div className="">{deliveryTracking.deliveryNo}</div>
                </div>
            )}
            {deliveryTracking.deliveryNo && (
                <div className="flex items-center my-2">
                    <span className="font-weight-bold mr-2">
                        {Messages.lotNo}:
                    </span>
                    <div className="">{deliveryTracking.lotNo}</div>
                </div>
            )}
            {deliveryTracking.destination && (
                <div className="flex items-center my-2">
                    <span className="font-weight-bold mr-2">
                        {Messages.branchWarehouse}:
                    </span>
                    <div className="">
                        {deliveryTracking?.destination?.name}
                    </div>
                </div>
            )}
            {deliveryTracking.receiptNo && (
                <div className="flex items-center my-2">
                    <span className="font-weight-bold mr-2">
                        {Messages.receiptNo}:
                    </span>
                    <div className="">{deliveryTracking?.receiptNo}</div>
                </div>
            )}
            <div className="mb-2 flex items-center">
                <span className="text-bold mr-1">{Messages.receivedBy}: </span>
                <UserAvatarName
                    user={deliveryTracking.received}
                    size="xx-small"
                    placeholder={Messages.system}
                    showInfo={{ showEmployeeId: false, showOPD: false }}
                />
            </div>
            {deliveryTracking.status && (
                <div className="flex items-center my-2">
                    <span className="font-weight-bold mr-2">
                        {Messages.status}:
                    </span>
                    <div
                        className=""
                        style={{
                            color:
                                deliveryTracking.status ===
                                DeliveryStatus.DeliveryCompleted
                                    ? "#27AE60"
                                    : "#E29A46",
                        }}
                    >
                        {Messages[deliveryStatus?.label ?? ""]}
                    </div>
                </div>
            )}
            <div className="mb-2">
                <span className="text-bold">{Messages.deliveryTime}: </span>
                <span>
                    {TimeUtils.convertMiliToDateTime(
                        deliveryTracking.deliveryTime
                    )}{" "}
                </span>
            </div>
            <div className="flex my-2">
                {isDisplayTrackIcon() && (
                    <Button
                        onClick={() => onClickTrackPackage()}
                        id="buttonTrack"
                        variant="outline"
                        color="primary"
                        className="mr-1"
                    >
                        <span className="subTitle2 text-primary whitespace-nowrap text-ellipsis text-sm overflow-hidden inline-block">
                            {Messages.trackPackage.toLocaleUpperCase()}
                        </span>
                    </Button>
                )}
                {isDisplayTrackIcon() &&
                    deliveryTracking.status !== "completed" &&
                    order.status !== OrderStatus.Completed && (
                        <Button
                            onClick={() => onClickPrintShippopHandle()}
                            id="buttonTrack"
                            variant="outline"
                            color="primary"
                            className="mr-1"
                        >
                            {progressPrint ? (
                                <Spin size="default" />
                            ) : (
                                <span className="text-primary whitespace-nowrap text-ellipsis text-sm overflow-hidden inline-block">
                                    {Messages.printShippopLabel.toLocaleUpperCase()}
                                </span>
                            )}
                        </Button>
                    )}
                <Button
                    onClick={() => {
                        setOpenEditDeliveryModal(true);
                        setIsViewDelivery(true);
                    }}
                    id="buttonTrack"
                    variant="outline"
                    color="primary"
                    className="inline-block mr-2"
                >
                    <span className="text-primary whitespace-nowrap text-ellipsis text-sm overflow-hidden inline-block">
                        {`${Messages.listItem.toUpperCase()} (${getNumberOfItemDelivery()})`}
                    </span>
                </Button>

                {/* {isDisplayUpdateStatus() && (
                    <Button
                        onClick={() => setOpenUpdateStatusModal(true)}
                        id="buttonTrack"
                        color="primary"
                        className="inline-block"
                    >
                        <span className="subTitle2 text-white text-sm">
                            {Messages.updateStatus.toUpperCase()}
                        </span>
                    </Button>
                )} */}

                {/* <NavLogButton deliveryTracking={deliveryTracking} /> */}
            </div>
            <div className="flex my-2">
                {isDislayEdit && (
                    <div
                        className="subTitle2 buttonEdit text-primary cursor-pointer"
                        onClick={() => {
                            setOpenEditDeliveryModal(true);
                            setIsViewDelivery(false);
                        }}
                    >
                        {Messages.edit}
                    </div>
                )}
            </div>
            {deliveryTracking?.attachment?.length > 0 && (
                <div className="flex flex-col mb-2">
                    <span className="text-bold">{Messages.attachment}: </span>
                    <Gallery
                        gallery={deliveryTracking.attachment}
                        itemClassName="w-32 h-32 object-cover mr-2"
                    />
                </div>
            )}
            {deliveryTracking.remark && (
                <div className="flex flex-col mb-2">
                    <span className="text-bold">{Messages.remark}: </span>
                    <div className="p-3 my-2 text-sm bg-[#DCE5E5] mb-2">
                        <span className="text-sm">
                            {deliveryTracking.remark}
                        </span>
                    </div>
                </div>
            )}
            <div className="text-gray-500 text-sm mb-2">
                {Messages.createdBy}{" "}
                {deliveryTracking?.createByAdmin?.companyId}_
                {deliveryTracking?.createByAdmin?.nickName} {Messages.at}{" "}
                {TimeUtils.convertMiliToDateTime(deliveryTracking.createdAt)}
            </div>
            {openUpdateStatusModal && (
                <OrderDeliveryStatusUpdate
                    open={openUpdateStatusModal}
                    onClose={() => setOpenUpdateStatusModal(false)}
                    delivery={deliveryTracking}
                    onSave={() => {
                        setOpenUpdateStatusModal(false);
                        loadDeliveryList();
                    }}
                />
            )}
            {openEditDeliveryModal && (
                <OrderDeliveryCreate
                    delivery={deliveryTracking}
                    open={openEditDeliveryModal}
                    isView={isViewDelivery}
                    onClose={() => setOpenEditDeliveryModal(false)}
                    onSave={() => {
                        setOpenEditDeliveryModal(false);
                        loadDeliveryList();
                    }}
                />
            )}
        </div>
    );
};

const OrderDelivery = () => {
    const [showAllDeliveries, setShowAllDeliveries] = useState(false);
    const {
        order,
        deliveryList: delivery,
        loadDeliveryList,
    } = useContext(MaterialPurchaseDetailContext);

    const deliveryItemToDisplay = useMemo(() => {
        if (showAllDeliveries) {
            return delivery;
        }
        return [delivery?.[0] ?? {}];
    }, [showAllDeliveries, delivery]);

    if (!delivery || delivery.length === 0) {
        return <div />;
    }

    // const getRemainingItems = () => {
    //     const totalQuantity = reduce(
    //         products,
    //         (sum, product) => {
    //             const { groups } = product;
    //             if (!groups || groups.length === 0) {
    //                 return (sum += product?.quantity);
    //             }
    //             const sumQuantity = reduce(
    //                 groups,
    //                 (sumGroup, proGroup) => (sumGroup += proGroup?.quantity),
    //                 0
    //             );
    //             return (sum += sumQuantity);
    //         },
    //         0
    //     );

    //     let totalDelivered = 0;
    //     delivery.forEach((deliveryItem: any) => {
    //         deliveryItem.items.forEach((skuItem: any) => {
    //             totalDelivered += skuItem.stock;
    //         });
    //     });
    //     let pickedUp = 0;
    //     pickup.forEach((pickupItem: any) => {
    //         pickupItem.pickup.forEach((skuItem: any) => {
    //             pickedUp += skuItem.stock;
    //         });
    //     });

    //     return totalQuantity - totalDelivered - pickedUp;
    // };
    return (
        <div className="bg-white deliveryContainer mb-3 p-3 border">
            <h5 className="font-semibold mb-2">{Messages.podeliveryReceipt}</h5>
            {deliveryItemToDisplay?.map((deliveryItem: any, index: number) => (
                <DeliveryTrackingItem
                    deliveryTracking={deliveryItem}
                    onReloadOrderData={loadDeliveryList}
                    order={order}
                    key={index}
                />
            ))}
            {delivery.length > 1 && (
                <div className="flex justify-end">
                    <Button
                        onClick={() => setShowAllDeliveries(!showAllDeliveries)}
                        id="buttonTrack"
                        color="primary"
                        className="mt-3"
                    >
                        <span className="text-white whitespace-nowrap text-ellipsis overflow-hidden inline-block flex items-center">
                            {showAllDeliveries
                                ? Messages.hide
                                : Messages.allDeliveries.toLocaleUpperCase()}
                            <Badge
                                index={delivery.length}
                                variant="index"
                                size="x-large"
                                color="red"
                                className="ml-2"
                            />
                        </span>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default OrderDelivery;
