import { isEmpty, map, maxBy, minBy, split } from "lodash";
import {
    TreatmentJobCostMetricDto,
    TreatmentUserJobCostDto,
} from "../api/hooks";
import { IBranch } from "./branch";
import { ICustomer } from "./customer";
import { IEquipment } from "./equipment";
import { IMaterial } from "./material";
import IUser from "./user";
import { IProduct } from "./product";
import { IServiceConfiguration } from "./service";
import { getMinutesFromHhMm } from "../utils/Utils";
import { IOrder } from "./order";

export interface ITreatmentMaterial {
    quantity: number;
    material: IMaterial;
    provideAfterTreatment: boolean;
}

export interface ITreatmentProduct {
    quantity: number;
    product: IProduct;
    productFull?: IProduct;
    provideAfterTreatment: boolean;
    serviceConfiguration?: IServiceConfiguration;
}

export interface ITreatmentUser {
    adminUser: IUser;
    schedule: {
        start: number;
        end: number;
    };
    jobCost?: TreatmentUserJobCostDto[];
}

export interface ITreatmentEquipment {
    equipment: IEquipment;
    schedule: {
        start: number;
        end: number;
    };
}

export interface ITreatmentBeforeAfter {
    id: string;
    refNo: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    recordedAt: string;
    before: string;
    after: string;
}

export interface ITreatmentConfirm {
    createByAdmin: IUser;
    createdAt: string;
    actualTime: string;
    remark: string;
}

export interface ITreatment {
    id: string;
    treatmentNo: string;
    products: ITreatmentProduct[];
    services: ITreatmentProduct[];
    materials: ITreatmentMaterial[];
    addOnServices: ITreatmentProduct[];
    updatedAt: string;
    createdAt: string;
    status: string;
    day: string;
    startDate: string;
    endDate: string;
    scheduleDate: string;
    schedule: {
        start: number;
        end: number;
    };
    beforeAfter: ITreatmentBeforeAfter[];
    doctorOrder: {
        id: string;
        updatedAt: string;
        createdAt: string;
        doctorOrderNo: string;
        doctorSubject: string;
        status: string;
        order: IOrder;
    };
    customer: ICustomer;
    createByAdmin: IUser;
    branch: IBranch;

    doctor: ITreatmentUser;
    PIC: ITreatmentUser[];
    equipments: ITreatmentEquipment[];
    starting: ITreatmentConfirm;
    completion: ITreatmentConfirm;
    cancellation: ITreatmentConfirm;
    jobCostAssessment?: any;
    manualJobCostAssessment?: any;
    jobCostMetric?: TreatmentJobCostMetricDto;
    bom?: number;
    hasActionCalculatorBom?: boolean;
    complicationTreatments: ITreatment[];
}

export const mapTreatmentFromServer = (treatment: any) => {
    return treatment;
};

export const mapTimeSlotsToSchedule = (timeSlots: any[]) => {
    const scheduleStart = minBy(timeSlots, (item: any) => item.start);
    const scheduleEnd = maxBy(timeSlots, (item: any) => item.end);
    return {
        start: scheduleStart?.start * 3600,
        end: scheduleEnd?.end * 3600,
    };
};

export const mapTreatmentDoctorToServer = (input: any) => {
    const schedule = mapTimeSlotsToSchedule(input?.timeSlot);
    return {
        doctorId: input?.user?.id,
        schedule,
    };
};

export const mapTreatmentPicToServer = (input: any) => {
    const { timeSlot, user, jobCostType } = input || {};
    const res = {
        id: user?.id,
    };
    if (timeSlot) {
        const schedule = mapTimeSlotsToSchedule(timeSlot);
        Object.assign(res, { schedule });
    }
    if (jobCostType && jobCostType?.length) {
        const jobCost = map(jobCostType, (type) => ({ type }));
        Object.assign(res, { jobCost });
    }
    return res;
};

export const mapTreatmentJobAssessmentToServer = (input: any) => {
    if (isEmpty(input)) {
        return null;
    }
    const res = new Map();
    Object.keys(input).forEach((key) => {
        const [userId, jobType] = split(key, "&");
        const value = input?.[key];
        const { quantity, start, end, manualAmount } = value || {};
        if (res.has(userId)) {
            const oldJobCost = res.get(userId)?.jobCost ?? [];
            oldJobCost.push({
                type: jobType,
                quantity,
                start: start
                    ? parseFloat(`${getMinutesFromHhMm(start)}`)
                    : null,
                end: end ? parseFloat(`${getMinutesFromHhMm(end)}`) : null,
                manualAmount: manualAmount ? parseFloat(manualAmount) : null,
            });
            res.set(userId, {
                id: userId,
                jobCost: oldJobCost,
            });
        } else {
            res.set(userId, {
                id: userId,
                jobCost: [
                    {
                        type: jobType,
                        quantity,
                        start: start
                            ? parseFloat(`${getMinutesFromHhMm(start)}`)
                            : null,
                        end: end
                            ? parseFloat(`${getMinutesFromHhMm(end)}`)
                            : null,
                        manualAmount: manualAmount
                            ? parseFloat(manualAmount)
                            : null,
                    },
                ],
            });
        }
    });
    return Array.from(res.values());
};

export const mapTreatmentEquipmentToServer = (input: any) => {
    const schedule = mapTimeSlotsToSchedule(input?.timeSlot);
    return {
        equipmentId: input?.equipment?.id,
        schedule,
    };
};

export const mapTreatmentBeforeAfterToServer = (input: any) => {
    return {
        recordedAt: input?.date,
        description: input?.description,
        before: input?.before?.fileUrl,
        after: input?.after?.fileUrl,
    };
};
export const mapTreatmentToServer = (input: any) => {
    return {
        schedule: mapTimeSlotsToSchedule(input.timeSlot),
        scheduleDate: input.date,
        branch: input?.branch?.id,
        doctorOrder: input?.doctorOrder?.id,
        doctor: input?.doctor?.id,
        remark: input?.remark,
        services: map(input?.products, (item) => ({
            id: item.id,
            quantity: item?.quantity ?? 1,
            complicationTreatment: item?.complicationTreatment?.id ?? null,
        })),
    };
};

export const mapTreatmentFilterToQueryParams = (input: any) => {
    return {
        branch: map(input?.branch, (item) => ({
            id: item.id,
            name: item.name,
        })),
        status: input?.status,
        createdBy: map(input?.createdBy, (item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        })),
        doctor: map(input?.doctor, (item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        })),
        PIC: map(input?.PIC, (item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        })),
        customer: map(input?.customer, (item) => ({
            id: item.id,
            nickname: item.nickname,
        })),
        doctorOrder: map(input?.doctorOrder, (item) => ({
            id: item.id,
            doctorOrderNo: item?.doctorOrderNo,
        })),
        services: map(input?.services, (item) => ({
            id: item.id,
            name: item.name,
        })),
    };
};

export const mapTreatmentFilterToServer = (input: any) => {
    return {
        branch: map(input?.branch, (item) => item.id),
        status: input?.status,
        createdBy: map(input?.createdBy, (item) => item.id),
        doctor: map(input?.doctor, (item) => item.id),
        PIC: map(input?.PIC, (item) => item.id),
        customer: map(input?.customer, (item) => item.id),
        doctorOrder: map(input?.doctorOrder, (item) => item.id),
        services: map(input?.services, (item) => item.id),
    };
};
