import classNames from "classnames";
import InputSelectForm from "../../../common/input/InputSelectForm";
import {
    TRANSFER_VOUCHER_STATUSES,
    TRANSFER_VOUCHER_TYPES,
} from "../../../constant/transfer-voucher";
import Messages from "../../../languages/Messages";
import CustomerSolidSelect from "../../customer/share/CustomerSolidSelect";
import UserSelect from "../../user/share/UserSelect";

interface IVoucherFilterForm {
    filterForm: any;
}

const TransferVoucherFilterForm = ({ filterForm }: IVoucherFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <InputSelectForm
                keyData="status"
                dataSource={TRANSFER_VOUCHER_STATUSES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <InputSelectForm
                keyData="transferVoucherType"
                dataSource={TRANSFER_VOUCHER_TYPES}
                form={filterForm}
                className={classNameInput}
                multiple
            />
            <UserSelect
                label={Messages.createdBy}
                onChange={(value) =>
                    filterForm.setFieldValue("createdBy", value)
                }
                value={formValues?.createdBy}
                className={classNameInput}
                multiple
            />
            <CustomerSolidSelect
                label={Messages.fromCustomer}
                onChange={(value) => filterForm.setFieldValue("from", value)}
                value={formValues?.from}
                className={classNameInput}
                multiple
            />
            <CustomerSolidSelect
                label={Messages.toCustomer}
                onChange={(value) => filterForm.setFieldValue("to", value)}
                value={formValues?.to}
                className={classNameInput}
                multiple
            />
        </div>
    );
};

export default TransferVoucherFilterForm;
