import { map } from "lodash";
import { IBranch } from "./branch";
import { ICustomer } from "./customer";
import { IOrder } from "./order";
import { IProduct } from "./product";
import { IQuotation } from "./quotation";
import IUser from "./user";

export interface IAppointment {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: IUser;
    day: string;
    startDate: string;
    endDate: string;
    scheduleDate: string;
    schedule: {
        start: number;
        end: number;
    };
    appointmentNo: string;
    status: string;
    remark: string;
    branch: IBranch;
    services: IProduct[];
    customer: ICustomer;
    contact: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    };
    order: IOrder;
    quotation: IQuotation;
}

export const mapAppointmentFilterToServer = (input: any) => {
    return {
        branch: map(input?.branch, (item) => item.id),
        status: input?.status,
        estDateOfVisit: input?.timeRange,
    };
};

export const mapAppointmentFilterToQueryParams = (input: any) => {
    return {
        branch: map(input?.branch, (item) => ({
            id: item.id,
            name: item.name,
        })),
        status: input?.status,
        timeRange: map(input?.timeRange, (item) => item.valueOf()),
    };
};
