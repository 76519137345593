import {
    Button,
    IRowsKey,
    TimeUtils,
    ViewRowInterchange,
} from "d-react-components";
import { useContext, useState } from "react";

import Messages from "../../../languages/Messages";
import { IVendor } from "../../../interfaces/vendor";
import { VendorDetailContext } from "../../../context/vendor";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import VendorGeneralUpdateDrawer from "./VendorGeneralUpdateDrawer";

const GENERAL_KEYS: IRowsKey<IVendor>[] = [
    {
        id: "vendorNo",
        label: Messages.id,
    },
    {
        id: "name",
        label: Messages.name,
    },
    {
        id: "countryOfOrigin",
        label: Messages.countryOfOrigin,
        renderContent: ({ data }) => {
            return data?.name;
        },
    },
    {
        id: "codeNumber",
        label: Messages.companyRegNo,
    },
    {
        id: "creditTerm",
        label: Messages.creditTerm,
    },
    {
        id: "address",
        label: Messages.companyAddress,
    },
    {
        id: "phone",
        label: Messages.phone,
    },
    {
        id: "representativesFirstName",
        label: Messages.representativesName,
        renderContent: ({ data, item }) => {
            return `${data} ${item?.representativesLastName}`;
        },
    },
    {
        id: "representativesPrimaryPhone",
        label: Messages.representativePhoneNo,
    },
    {
        id: "representativesEmail",
        label: Messages.representativeEmail,
    },
    {
        id: "remark",
        label: Messages.remark,
    },

    {
        id: "createByAdmin",
        label: Messages.createdBy,
        renderContent: ({ data }) => {
            return <UserAvatarName user={data} />;
        },
    },
    {
        id: "createdAt",
        label: Messages.createdAt,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.toDateTime(data);
        },
    },
    {
        id: "updatedAt",
        label: Messages.lastUpdated,
        renderContent: ({ data }) => {
            return !data ? "N/A" : TimeUtils.toDateTime(data);
        },
    },
];

const VendorDetailGeneral = () => {
    const { vendor } = useContext(VendorDetailContext);
    const [openEdit, setOpenEdit] = useState(false);

    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
                <Button
                    variant="trans"
                    onClick={() => setOpenEdit(true)}
                    className=""
                >
                    {Messages.edit}
                </Button>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={vendor}
                    variant="background"
                />
            </div>
            {openEdit && (
                <VendorGeneralUpdateDrawer
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

export default VendorDetailGeneral;
