import { gql } from "@apollo/client";
import API from "../API";
import { F_ADDRESS, F_BILLING } from "../fragments/address";
import { F_BOOKINGS } from "../fragments/booking";
import {
    F_CUSTOMER,
    F_CUSTOMERS,
    F_CUSTOMER_CREDIT,
    F_CUSTOMER_CREDITS,
    F_CUSTOMER_EXPORT,
    F_CUSTOMER_NOTE,
    F_CUSTOMER_NOTES,
    F_CUSTOMER_QUICK_VIEW,
    F_CUSTOMER_SERVICE_HISTORY,
    F_CUSTOMER_SURVEY,
    F_SECRET_CODE,
} from "../fragments/customer";
import { F_DOCTOR_ORDERS } from "../fragments/doctor-order";
import { F_ORDERS } from "../fragments/order";
import { F_QUOTATIONS } from "../fragments/quotation";
import { F_TREATMENTS } from "../fragments/treatment";
import { F_VOUCHERS } from "../fragments/voucher";
import { VoucherStatus } from "../hooks";

export const getCustomerList = gql`
    ${F_CUSTOMERS}
    query getCustomerList($paginate: CustomerPaginateRequest!) {
        data: listCustomerForAdmin(paginate: $paginate) {
            ...F_CUSTOMERS
        }
    }
`;

export const getCustomersExport = gql`
    ${F_CUSTOMER_EXPORT}
    query getCustomersExport($paginate: CustomerPaginateRequest!) {
        data: listCustomerForAdmin(paginate: $paginate) {
            ...F_CUSTOMER_EXPORT
        }
    }
`;

export const getCustomerDetail = gql`
    ${F_CUSTOMER}
    query getCustomerDetail($id: String!) {
        data: detailCustomerForAdmin(id: $id) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const getCustomerQuickView = gql`
    ${F_CUSTOMER_QUICK_VIEW}
    query getCustomerQuickView($id: String!) {
        data: quickViewCustomerForAdmin(id: $id) {
            data {
                ...F_CUSTOMER_QUICK_VIEW
            }
        }
    }
`;

export const getCustomerSurvey = gql`
    ${F_CUSTOMER_SURVEY}
    query getCustomerSurvey {
        data: getCustomerSurveyForAdmin {
            data {
                ...F_CUSTOMER_SURVEY
            }
        }
    }
`;

export const getCustomerHealthAssessment = gql`
    query getCustomerHealthAssessment {
        data: getCustomerHealthAssessmentForAdmin {
            data {
                ...F_CUSTOMER_SURVEY
            }
        }
    }
`;

export const getCustomerNotes = gql`
    ${F_CUSTOMER_NOTES}
    query getCustomerNotes(
        $customerId: String!
        $paginate: NotePaginateRequest!
    ) {
        data: listCustomerNotesForAdmin(
            paginate: $paginate
            customerId: $customerId
        ) {
            ...F_CUSTOMER_NOTES
        }
    }
`;

export const createCustomer = gql`
    mutation createCustomer($input: CustomerCreateRequest!) {
        data: createCustomerForAdmin(input: $input) {
            data {
                id
            }
        }
    }
`;

export const updateCustomerTag = gql`
    ${F_CUSTOMER}
    mutation updateCustomerTag($customerId: String!, $tagIds: [String!]!) {
        data: updateCustomerTagForAdmin(
            customerId: $customerId
            tagIds: $tagIds
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerFamilyEmergency = gql`
    ${F_CUSTOMER}
    mutation updateCustomerFamilyEmergency(
        $id: String!
        $input: CustomerUpdateFamilyEmergencyRequest!
    ) {
        data: updateCustomerFamilyEmergencyForAdmin(id: $id, input: $input) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerPersonalInfo = gql`
    ${F_CUSTOMER}
    mutation updateCustomerPersonalInfo(
        $id: String!
        $input: CustomerUpdatePersonalInformationRequest!
    ) {
        data: updateCustomerPersonalInformationForAdmin(
            id: $id
            input: $input
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerPrivacyInfo = gql`
    ${F_CUSTOMER}
    mutation updateCustomerPrivacyInfo(
        $id: String!
        $input: CustomerUpdatePrivacyInformationRequest!
    ) {
        data: updateCustomerPrivacyInformationForAdmin(id: $id, input: $input) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerContactInfo = gql`
    ${F_CUSTOMER}
    mutation updateCustomerContactInfo(
        $id: String!
        $input: CustomerUpdateContactInformationRequest!
    ) {
        data: updateCustomerContactInformationForAdmin(id: $id, input: $input) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerMedicalProfile = gql`
    ${F_CUSTOMER}
    mutation updateCustomerMedicalProfile(
        $id: String!
        $input: CustomerUpdateMedicalProfileRequest!
    ) {
        data: updateCustomerMedicalProfileForAdmin(id: $id, input: $input) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const createCustomerAddress = gql`
    ${F_CUSTOMER}
    mutation createCustomerAddress(
        $customerId: String!
        $input: AddressRequest!
    ) {
        data: createCustomerAddressForAdmin(
            customerId: $customerId
            input: $input
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerAddress = gql`
    ${F_CUSTOMER}
    mutation updateCustomerAddress(
        $customerId: String!
        $addressId: String!
        $input: AddressUpdateRequest!
    ) {
        data: updateCustomerAddressForAdmin(
            customerId: $customerId
            addressId: $addressId
            input: $input
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const removeCustomerAddress = gql`
    ${F_CUSTOMER}
    mutation removeCustomerAddress($customerId: String!, $addressId: String!) {
        data: removeCustomerAddressForAdmin(
            customerId: $customerId
            addressId: $addressId
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const createCustomerBilling = gql`
    ${F_CUSTOMER}
    mutation createCustomerBilling(
        $customerId: String!
        $input: BillingRequest!
    ) {
        data: createCustomerBillingForAdmin(
            customerId: $customerId
            input: $input
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerBilling = gql`
    ${F_CUSTOMER}
    mutation updateCustomerBilling(
        $customerId: String!
        $billingId: String!
        $input: BillingUpdateRequest!
    ) {
        data: updateCustomerBillingForAdmin(
            customerId: $customerId
            billingId: $billingId
            input: $input
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const removeCustomerBilling = gql`
    ${F_CUSTOMER}
    mutation removeCustomerBilling($customerId: String!, $billingId: String!) {
        data: removeCustomerBillingForAdmin(
            customerId: $customerId
            billingId: $billingId
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerSetting = gql`
    ${F_CUSTOMER}
    mutation updateCustomerSetting(
        $customerId: String!
        $settingInput: CustomerSettingRequest!
    ) {
        data: updateCustomerSettingForAdmin(
            customerId: $customerId
            settingInput: $settingInput
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const updateCustomerSurvey = gql`
    ${F_CUSTOMER}
    mutation updateCustomerSurvey(
        $customerId: String!
        $surveyAnswers: [SurveyAnswersRequest!]!
    ) {
        data: updateCustomerSurveyForAdmin(
            customerId: $customerId
            surveyAnswers: $surveyAnswers
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const createCustomerNote = gql`
    ${F_CUSTOMER_NOTE}
    mutation createCustomerNote(
        $customerId: String!
        $payload: NoteCreateRequest!
    ) {
        data: createCustomerNoteForAdmin(
            customerId: $customerId
            payload: $payload
        ) {
            data {
                ...F_CUSTOMER_NOTE
            }
        }
    }
`;

export const updateCustomerNote = gql`
    ${F_CUSTOMER_NOTE}
    mutation updateCustomerNote(
        $noteId: String!
        $payload: NoteUpdateRequest!
    ) {
        data: updateCustomerNoteForAdmin(noteId: $noteId, payload: $payload) {
            data {
                ...F_CUSTOMER_NOTE
            }
        }
    }
`;

export const removeCustomerNote = gql`
    ${F_CUSTOMER_NOTE}
    mutation removeCustomerNote($noteId: String!) {
        data: removeCustomerNoteForAdmin(noteId: $noteId) {
            data {
                ...F_CUSTOMER_NOTE
            }
        }
    }
`;

export const getCustomerShippingAddress = gql`
    ${F_ADDRESS}
    query allCustomerAddressForAdmin($customerId: String!) {
        data: allCustomerAddressForAdmin(customerId: $customerId) {
            data {
                ...F_ADDRESS
            }
        }
    }
`;
export const getCustomerBillingAddress = gql`
    ${F_BILLING}
    query allCustomerBillingForAdmin($customerId: String!) {
        data: allCustomerBillingForAdmin(customerId: $customerId) {
            data {
                ...F_BILLING
            }
        }
    }
`;

// History

export const getCustomerBookingHistory = gql`
    ${F_BOOKINGS}
    query getCustomerBookingHistory(
        $customerId: String!
        $paginate: AdminBookingPaginateRequest!
    ) {
        data: customerBookingHistoryForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_BOOKINGS
        }
    }
`;

export const getCustomerDoctorOrderHistory = gql`
    ${F_DOCTOR_ORDERS}
    query getCustomerDoctorOrderHistory(
        $customerId: String!
        $paginate: AdminDoctorOrderPaginateRequest!
    ) {
        data: customerDoctorOrderHistoryForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_DOCTOR_ORDERS
        }
    }
`;

export const getCustomerQuotationHistory = gql`
    ${F_QUOTATIONS}
    query getCustomerQuotationHistory(
        $customerId: String!
        $paginate: ListAdminQuotationRequest!
    ) {
        data: customerQuotationHistoryForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_QUOTATIONS
        }
    }
`;

export const getCustomerOrderHistory = gql`
    ${F_ORDERS}
    query getCustomerOrderHistory(
        $customerId: String!
        $paginate: ListAdminOrderRequest!
    ) {
        data: customerOrderHistoryForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_ORDERS
        }
    }
`;

export const getCustomerTreatmentHistory = gql`
    ${F_TREATMENTS}
    query getCustomerTreatmentHistory(
        $customerId: String!
        $paginate: AdminTreatmentPaginateRequest!
    ) {
        data: customerListTreatmentHistoryForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_TREATMENTS
        }
    }
`;

export const getCustomerServiceHistory = gql`
    ${F_CUSTOMER_SERVICE_HISTORY}
    query customerServiceHistoryForAdmin(
        $payload: CustomerServiceHistoryRequest!
    ) {
        data: customerServiceHistoryForAdmin(payload: $payload) {
            ...F_CUSTOMER_SERVICE_HISTORY
        }
    }
`;

export const getCustomerVouchers = gql`
    ${F_VOUCHERS}
    query getCustomerVouchers(
        $customerId: String!
        $paginate: AdminVoucherPaginateRequest!
    ) {
        data: customerListVouchersForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_VOUCHERS
        }
    }
`;

export const generateSelfRegistrationCode = gql`
    ${F_SECRET_CODE}
    mutation generateSelfRegistrationCode {
        data: generateSelfRegistrationCodeForAdmin {
            data {
                ...F_SECRET_CODE
            }
        }
    }
`;

export const generateUpdateInfoCode = gql`
    ${F_SECRET_CODE}
    mutation generateUpdateInfoCode($customerId: String!) {
        data: generateCustomerUpdateInfoCodeForAdmin(customerId: $customerId) {
            data {
                ...F_SECRET_CODE
            }
        }
    }
`;

export const generateCustomerHealthAssessmentCode = gql`
    ${F_SECRET_CODE}
    mutation generateCustomerHealthAssessmentCode($customerId: String!) {
        data: generateCustomerHealthAssessmentCodeForAdmin(
            customerId: $customerId
        ) {
            data {
                ...F_SECRET_CODE
            }
        }
    }
`;

export const changeUpdateInfoCodeExpiryTime = gql`
    ${F_SECRET_CODE}
    mutation changeUpdateInfoCodeExpiryTime(
        $codeId: String!
        $payload: SecretCodeUpdateRequest!
    ) {
        data: changeCustomerUpdateInfoCodeExpiryTimeForAdmin(
            codeId: $codeId
            payload: $payload
        ) {
            data {
                ...F_SECRET_CODE
            }
        }
    }
`;

export const approveUpdateInformationCustomer = gql`
    ${F_CUSTOMER}
    mutation approveUpdateInformationCustomer($customerId: String!) {
        data: approveUpdateInformationCustomerForAdmin(
            customerId: $customerId
        ) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const verifyCustomer = gql`
    ${F_CUSTOMER}
    mutation verifyCustomer($customerId: String!, $remark: String!) {
        data: verifyCustomerForAdmin(customerId: $customerId, remark: $remark) {
            data {
                ...F_CUSTOMER
            }
        }
    }
`;

export const verifyOtpCustomer = gql`
    mutation verifyOtpCustomer(
        $otpToken: String!
        $otpCode: String!
        $customerId: String!
    ) {
        data: verifyCustomerOtpForAdmin(
            otpToken: $otpToken
            otpCode: $otpCode
            customerId: $customerId
        ) {
            isVerified
        }
    }
`;

export const requestOtpCustomer = gql`
    mutation requestOtpCustomer($phone: String!) {
        data: requestOtpCustomerForAdmin(phone: $phone) {
            otpToken
        }
    }
`;

export const checkCustomerIsStaff = gql`
    query checkCustomerIsStaff($id: String!) {
        data: checkCustomerIsStaffForAdmin(id: $id)
    }
`;

export const validateImportCustomer = gql`
    mutation validateImportCustomerForAdmin(
        $typeOfImport: String!
        $input: ImportCustomerRequest!
    ) {
        data: validateImportCustomerForAdmin(
            typeOfImport: $typeOfImport
            input: $input
        ) {
            errors {
                branchId
                customerNo
                messages
            }
        }
    }
`;

export const importCustomer = gql`
    mutation importCustomerForAdmin(
        $typeOfImport: String!
        $input: ImportCustomerRequest!
    ) {
        data: importCustomerForAdmin(
            typeOfImport: $typeOfImport
            input: $input
        ) {
            errors {
                branchId
                customerNo
                messages
            }
            success {
                customerNo
            }
        }
    }
`;

export const getCustomerCreditList = gql`
    ${F_CUSTOMER_CREDITS}
    query customerListCreditsForAdmin(
        $customerId: String!
        $paginate: CreditPaginateInput!
    ) {
        data: customerListCreditsForAdmin(
            customerId: $customerId
            paginate: $paginate
        ) {
            ...F_CUSTOMER_CREDITS
        }
    }
`;
export const getCustomerCreditTotalAvailable = gql`
    query customerTotalAvailableCreditsForAdmin($customerId: String!) {
        data: customerTotalAvailableCreditsForAdmin(customerId: $customerId) {
            data
        }
    }
`;

const customersConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const CustomerAPI = {
    list: async (paginate: any) =>
        API.withConverter(customersConverter).query({
            query: getCustomerList,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    export: async (paginate: any) =>
        API.withConverter(customersConverter).query({
            query: getCustomersExport,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    requestOtp: async (phone: any) =>
        API.mutate({
            mutation: requestOtpCustomer,
            variables: { phone },
            fetchPolicy: "no-cache",
        }),
    verifyOtp: async (params: {
        otpToken: string;
        otpCode: string;
        customerId: string;
    }) =>
        API.mutate({
            mutation: verifyOtpCustomer,
            variables: { ...params },
            fetchPolicy: "no-cache",
        }),
    checkIsStaff: async (id: any) =>
        API.query({
            query: checkCustomerIsStaff,
            variables: { id },
            fetchPolicy: "no-cache",
        }),
    updateSetting: async (params: { customerId: string; settingInput: any }) =>
        API.mutate({
            mutation: updateCustomerSetting,
            variables: { ...params },
            fetchPolicy: "no-cache",
        }),
    validateImportCustomer: async (params: any) =>
        API.mutate({
            mutation: validateImportCustomer,
            variables: { ...params },
            fetchPolicy: "no-cache",
        }),
    importCustomer: async (params: any) =>
        API.mutate({
            mutation: importCustomer,
            variables: { ...params },
            fetchPolicy: "no-cache",
        }),

    creditList: async (customerId: string, paging: any, filter?: any) =>
        API.query({
            query: getCustomerCreditList,
            variables: {
                customerId,
                paginate: {
                    page: paging?.pageIndex,
                    limit: paging?.pageSize,
                    ...(filter ? filter : {}),
                },
            },
            fetchPolicy: "no-cache",
        }),

    creditTotalAvailable: async (customerId: string) =>
        API.query({
            query: getCustomerCreditTotalAvailable,
            variables: {
                customerId,
            },
            fetchPolicy: "no-cache",
        }),

    serviceHistory: async (payload: {
        customerId: string;
        status?: VoucherStatus;
    }) =>
        API.query({
            query: getCustomerServiceHistory,
            variables: {
                payload,
            },
            fetchPolicy: "no-cache",
        }),

    cancelCustomer: async (customerId: string) =>
        API.mutate({
            mutation: gql`
                mutation cancelCustomerForAdmin($customerId: String!) {
                    data: cancelCustomerForAdmin(customerId: $customerId) {
                        data {
                            status
                        }
                    }
                }
            `,
            variables: {
                customerId,
            },
            fetchPolicy: "no-cache",
        }),
};

export default CustomerAPI;
