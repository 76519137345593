import {
    AwesomeTableComponent,
    DateInput,
    IColumnsProps,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { join, map, reduce } from "lodash";
import moment from "moment";
import { useState } from "react";
import ReportAPI from "../../api/queries/report";
import { EXPORT_MODE } from "../../constant/report";
import Messages from "../../languages/Messages";
import { getFullName } from "../../utils/Utils";
import { exportToCSV } from "../../utils/file";
import UserSelect from "../user/share/UserSelect";
import ReportFooter from "./ReportFooter";

export const INIT_EXPORT_FORM = {
    mode: EXPORT_MODE.INPUT,
    user: [],
    timestamp: null,
};

const ReportSalespersonMonthly = () => {
    const [dataTable, setDataTable] = useState([]);

    const exportForm = useFormik<any>({
        initialValues: INIT_EXPORT_FORM,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onLoadData(values);
        },
    });
    const classNameInput = "col-span-2";
    const formValues = exportForm?.values ?? {};

    const onLoadData = (values: any) => {
        const payload = {
            user: map(values.user, (item) => item.id),
            timestamp: values?.timestamp?.valueOf(),
        };
        Progress.show(
            { method: ReportAPI.salespersonMonthly, params: [payload] },
            loadData as any
        );
    };

    const loadData = (dataList: any[]) => {
        const exportData: any = [];
        const firstDayMonth = moment(formValues.timestamp).startOf("M");
        const lastDayMonth = moment(formValues.timestamp).endOf("M");
        const dateRangeText = join(
            [
                firstDayMonth.format("DD/MM/YYYY"),
                lastDayMonth.format("DD/MM/YYYY"),
            ],
            " - "
        );
        for (let index = 0; index < dataList.length; index += 1) {
            const dataItem = dataList[index];

            exportData.push({
                "Report Date": dateRangeText,
                Salesperson: getFullName(dataItem.user),

                "Sales KPI this month": "0",
                "Total customer served": dataItem.totalCustomerServed,
                "Today sales amount": dataItem.todaySaleAmount,
                "1st day of the month to yesterday's sales amount":
                    dataItem.firstToYesterdayAmount,
                "Month-to-date Sales Amount": dataItem.monthToDateAmount,
            });
        }

        const totalCustomerServed = reduce(
            dataList,
            (sum, item) => sum + item.totalCustomerServed,
            0
        );
        const todaySaleAmount = reduce(
            dataList,
            (sum, item) => sum + item.todaySaleAmount,
            0
        );
        const firstToYesterdayAmount = reduce(
            dataList,
            (sum, item) => sum + item.firstToYesterdayAmount,
            0
        );
        const monthToDateAmount = reduce(
            dataList,
            (sum, item) => sum + item.monthToDateAmount,
            0
        );

        exportData.push({
            "Report Date": "Sum",
            Salesperson: "",

            "Sales KPI this month": "0",
            "Total customer served": totalCustomerServed,
            "Today sales amount": todaySaleAmount,
            "1st day of the month to yesterday's sales amount":
                firstToYesterdayAmount,
            "Month-to-date Sales Amount": monthToDateAmount,
        });

        if (formValues.mode === EXPORT_MODE.VIEW) {
            setDataTable(exportData);
        }
        if (formValues.mode === EXPORT_MODE.EXPORT) {
            exportToCSV(exportData, `Salesperson Monthly Report`);
        }
    };

    const classNameHeader =
        "d-flex justify-content-between align-items-center p-4 border-bottom";
    const renderInput = () => {
        return (
            <div className="grid grid-cols-4 gap-4 p-4">
                <DateInput
                    className={classNameInput}
                    label={Messages.month}
                    value={formValues.timestamp ?? null}
                    onChange={(value) =>
                        exportForm.setFieldValue("timestamp", value)
                    }
                    format="MM/YYYY"
                    type="month"
                />
                <UserSelect
                    className={classNameInput}
                    value={formValues?.user}
                    onChange={(value) =>
                        exportForm.setFieldValue("user", value)
                    }
                    multiple
                />
            </div>
        );
    };

    const columns: IColumnsProps = [
        {
            title: "Report Date",
            dataIndex: "Report Date",
        },
        {
            title: "Salesperson",
            dataIndex: "Salesperson",
        },
        {
            title: "Sales KPI this month",
            dataIndex: "Sales KPI this month",
        },
        {
            title: "Total customer served",
            dataIndex: "Total customer served",
        },
        {
            title: "Today sales amount",
            dataIndex: "Today sales amount",
            render: StringUtils.moneyFormat,
        },
        {
            title: "1st day of the month to yesterday's sales amount",
            dataIndex: "1st day of the month to yesterday's sales amount",
            render: StringUtils.moneyFormat,
        },
        {
            title: "Month-to-date Sales Amount",
            dataIndex: "Month-to-date Sales Amount",
            render: StringUtils.moneyFormat,
        },
    ];
    const renderPreview = () => {
        return (
            <AwesomeTableComponent
                columns={columns}
                dataSource={dataTable}
                className=""
                // expandable={{
                //     defaultExpandAllRows: true,
                // }}
                pagination={false}
            />
        );
    };

    const renderContent = () => {
        switch (formValues.mode) {
            case EXPORT_MODE.EXPORT:
            case EXPORT_MODE.INPUT:
                return renderInput();
            case EXPORT_MODE.VIEW:
                return renderPreview();
            default:
                return <div />;
        }
    };

    return (
        <div>
            <div className="card-container">
                <div className={classNameHeader}>
                    <div className="h5 text-primary">
                        {Messages.salespersonMonthlyReport}
                    </div>
                </div>
                {renderInput()}
            </div>
            <ReportFooter
                previousDisabled={formValues === EXPORT_MODE.INPUT}
                viewDisabled={formValues === EXPORT_MODE.VIEW}
                onClickPrevious={() =>
                    exportForm.setFieldValue("mode", EXPORT_MODE.INPUT)
                }
                onClickExport={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.EXPORT);
                    exportForm.handleSubmit();
                }}
                onClickView={() => {
                    exportForm.setFieldValue("mode", EXPORT_MODE.VIEW);
                    exportForm.handleSubmit();
                }}
                exportDisabled={!formValues?.timestamp}
            />
            {formValues.mode === EXPORT_MODE.VIEW && renderPreview()}
        </div>
    );
};

export default ReportSalespersonMonthly;
