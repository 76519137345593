import {
    AwesomeTableComponent,
    Button,
    Drawer,
    HeaderTable,
    IColumnsProps,
    Progress,
    TimeUtils,
    ViewLabelStatus,
    useFirstTime,
} from "d-react-components";
import { debounce, map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import {
    useGenerateSelfRegistrationCodeMutation,
    useGetCustomerListLazyQuery,
} from "../../../api/hooks";
import CustomerAPI from "../../../api/queries/customer";
import AppLink from "../../../common/AppLink";
import TableAction from "../../../common/TableActions";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import ExportProgress from "../../../common/views/ExportProgress";
import { CUSTOMER_STATUS } from "../../../constant/customer";
import {
    CustomerStatus,
    mapCustomerFilterParamsToServer,
} from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import { getAge } from "../../../utils/Utils";
import Path from "../../Path";
import SecretCodeView from "../share/SecretCodeView";
import CustomerTableFilterDrawer from "./filter/CustomerTableFilterDrawer";

const EXPORT_KEYS = [
    {
        id: "branch",
        label: "Branch ID",
        getContent: ({ data }: any) => data?.code,
    },
    {
        id: "customerNo",
        label: Messages.customerNo,
    },
    {
        id: "title",
        label: Messages.title,
    },
    {
        id: "firstNameTh",
        label: Messages.firstNameTh,
    },
    {
        id: "lastNameTh",
        label: Messages.lastNameTh,
    },
    {
        id: "firstNameEn",
        label: Messages.firstNameEn,
    },
    {
        id: "lastNameEn",
        label: Messages.lastNameEn,
    },
    {
        id: "nickname",
        label: Messages.nickname,
    },
    {
        id: "gender",
        label: Messages.gender,
    },
    {
        id: "birthDay",
        label: Messages.birthDay,
    },
    {
        id: "citizenId",
        label: Messages.citizenId,
    },
    {
        id: "nationality",
        label: Messages.nationality,
        getContent: ({ data }: any) => data?.name,
    },
    {
        id: "passportNo",
        label: Messages.passportNo,
    },
    {
        id: "passportExp",
        label: Messages.passportExp,
        getContent: ({ data }: any) => TimeUtils.toDate(data),
    },
    {
        id: "religion",
        label: Messages.religion,
    },
    {
        id: "numberOfChild",
        label: Messages.numberOfChild,
    },
    {
        id: "phoneCode",
        label: Messages.phoneCodeCountry,
    },
    {
        id: "phone",
        label: Messages.phone,
    },
    {
        id: "alternativePhone",
        label: Messages.alternativePhone,
    },
    {
        id: "email",
        label: Messages.email,
    },
    {
        id: "lineId",
        label: Messages.lineId,
    },
    {
        id: "instagram",
        label: Messages.instagram,
    },
    {
        id: "facebook",
        label: Messages.facebook,
    },
    {
        id: "familyInformation",
        label: Messages.familyInfo,
        getContent: ({ data }: any) =>
            data?.length
                ? JSON.stringify(
                      map(data, (item) => ({
                          relationship: item.relationship,
                          phone: item.phone,
                          name: item.name,
                          date_of_birth: item.dateOfBirth,
                      }))
                  )
                : "",
    },
    {
        id: "emergencyContact",
        label: Messages.emergencyContact,
        getContent: ({ data }: any) => JSON.stringify(data),
    },
    {
        id: "isVip",
        label: Messages.isVip,
        getContent: ({ data }: any) => (data ? "TRUE" : "FALSE"),
    },
    {
        id: "referrer",
        label: Messages.referrerSalePerson,
        getContent: ({ data }: any) => {
            const { salePerson } = data ?? {};
            return `${salePerson?.firstName ?? ""} ${
                salePerson?.lastName ?? ""
            }`;
        },
    },
    {
        id: "referrer",
        label: Messages.referrerAgency,
        getContent: ({ data }: any) => data?.agency?.agencyNo,
    },
    {
        id: "medicalProfile",
        label: Messages.height,
        getContent: ({ data }: any) => data?.height,
    },
    {
        id: "medicalProfile",
        label: Messages.weight,
        getContent: ({ data }: any) => data?.weight,
    },
    {
        id: "medicalProfile",
        label: Messages.bloodGroup,
        getContent: ({ data }: any) => data?.bloodGroup,
    },
    {
        id: "medicalProfile",
        label: Messages.bloodPressure,
        getContent: ({ data }: any) => data?.bloodPressure,
    },
    {
        id: "medicalProfile",
        label: Messages.allergyHistory,
        getContent: ({ data }: any) => data?.allergyHis,
    },
    {
        id: "medicalProfile",
        label: Messages.underlyingDisease,
        getContent: ({ data }: any) => data?.underDisease,
    },
    {
        id: "medicalProfile",
        label: Messages.surgeryHistory,
        getContent: ({ data }: any) => JSON.stringify(data?.surgeryHis),
    },
];

const CustomerTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const [getCustomerList] = useGetCustomerListLazyQuery();
    const [generateSelfRegistrationCode] =
        useGenerateSelfRegistrationCodeMutation();
    const navigate = useNavigate();
    const [openNewRegistration, setOpenNewRegistration] = useState<{
        open: boolean;
        code?: any;
    }>({ open: false });
    const [openFilter, setOpenFilter] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [startExport, setStartExport] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const isFirstTime = useFirstTime();

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",
            render: (value, customer) => (
                <CustomerAvatarName
                    customer={customer}
                    size="xx-small"
                    onClick={() =>
                        navigate(
                            generatePath(Path.CUSTOMER_DETAIL, {
                                customerId: customer.id,
                            })
                        )
                    }
                />
            ),
        },
        {
            title: Messages.customerNo,
            dataIndex: "customerNo",
            render: (customerNo, item) => (
                <AppLink
                    to={generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: item.id,
                    })}
                >
                    {customerNo}
                </AppLink>
            ),
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status || CustomerStatus.UN_VERIFIED}
                    listStatus={CUSTOMER_STATUS}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.age,
            dataIndex: "birthDay",
            render: getAge,
        },
        {
            title: Messages.totalPurchase,
            dataIndex: "summary",
            render: (summary) => summary?.totalPurchase ?? 0,
        },
        {
            title: Messages.lastPurchase,
            dataIndex: "summary",
            render: (summary) => summary?.totalPurchase ?? 0,
        },

        // {
        //     title: Messages.totalCourseUsage,
        //     dataIndex: "totalCourseUsage",
        // },
        {
            title: Messages.updatedDate,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },

        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (customer: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, customer)
                        }
                    />
                );
            },
        },
    ];

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const source = (pagingData: any) => {
        const filterParam = mapCustomerFilterParamsToServer(filter);
        const body = {
            page: pagingData?.pageIndex as any,
            limit: pagingData?.pageSize,
            search: searchRef?.current ?? "",
            sort: {
                createdAt: -1,
            },
            ...filterParam,
        };
        return CustomerAPI.list(body);
    };

    const onClickTableAction = (actionId: string, customer: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.CUSTOMER_DETAIL, {
                        customerId: customer.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.CUSTOMER_CREATE);
    };

    const onClickNewRegistration = () => {
        return Progress.show(
            {
                method: generateSelfRegistrationCode,
                params: {},
            },
            (res: any) => {
                const secretCode = res?.data?.data?.data ?? {};
                setOpenNewRegistration({ open: true, code: secretCode });
            }
        );
    };
    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    const mapServiceConfigDataToExport = (data: any[]) => {
        const header: any[] = EXPORT_KEYS.map((item) => item.label);
        const rows = map(data, (item) => {
            const row: any[] = [];
            EXPORT_KEYS.forEach((i) => {
                const { id, getContent, label } = i;
                const dataItem = item[id];
                const v = getContent
                    ? getContent({ data: dataItem })
                    : dataItem;
                row.push(v);
            });
            return row;
        });
        return [header, ...rows];
    };

    return (
        <div className="">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allCustomer}
                customButtons={() => {
                    return (
                        <Button
                            iconName="qr_code_scanner"
                            className="ml-3"
                            onClick={() => {
                                onClickNewRegistration();
                            }}
                        >
                            {Messages.newRegistration}
                        </Button>
                    );
                }}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onClickFilter={() => setOpenFilter(true)}
                onClickExport={() => setStartExport(true)}
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
                baseColumnProps={{ width: 100 }}
            />
            {openNewRegistration?.open && openNewRegistration?.code && (
                <Drawer
                    maskClosable={false}
                    open={openNewRegistration?.open}
                    onClose={() => setOpenNewRegistration({ open: false })}
                    title={Messages.newRegistration}
                >
                    <SecretCodeView
                        className="p-4"
                        variant="selfRegistration"
                        secretCode={openNewRegistration?.code}
                    />
                </Drawer>
            )}
            {openFilter && (
                <CustomerTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
            <ExportProgress
                source={(props = {}) => {
                    const filterParam = mapCustomerFilterParamsToServer(filter);
                    const body = {
                        search: searchRef?.current ?? "",
                        sort: {
                            createdAt: -1,
                        },
                        ...props,
                        ...filterParam,
                    };
                    return CustomerAPI.export(body);
                }}
                start={startExport}
                onStop={() => setStartExport(false)}
                mapDataToExport={(data: any) =>
                    mapServiceConfigDataToExport(data)
                }
                getData={(res) => res?.data}
                getPagination={(res) => res?.pagination}
                exportName="Der_Customer"
            />
        </div>
    );
};

export default CustomerTable;
