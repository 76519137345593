import { Progress, TabBar } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDetailBranchForAdminLazyQuery } from "../../../api/hooks";
import { BranchDetailContext } from "../../../context/branch";
import Messages from "../../../languages/Messages";
import BranchActivity from "./BranchActivity";
import BranchAddress from "./BranchAddress";
import BranchGeneralInformation from "./BranchGeneralInformation";
import BranchPic from "./BranchPic";
import BranchSummaryView from "./BranchSummaryView";
import BranchWarehouseDelivery from "./BranchWarehouseDelivery";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <BranchGeneralInformation />,
    },
    {
        id: "address",
        label: Messages.address,
        component: <BranchAddress />,
    },
    {
        id: "warehouseDelivery",
        label: Messages.warehouseDelivery,
        component: <BranchWarehouseDelivery />,
    },
    {
        id: "user",
        label: Messages.user,
        component: <BranchPic />,
    },
    {
        id: "activity",
        label: Messages.activities,
        component: <BranchActivity />,
    },
];

const BranchDetail = () => {
    const { branchId } = useParams<any>();
    const [tab, setTab] = useState(TABS[0]);
    const [getBranchDetail, { data, loading, refetch }] =
        useDetailBranchForAdminLazyQuery();

    useEffect(() => {
        loadBranchDetail();
    }, []);

    const loadBranchDetail = () => {
        Progress.show(
            {
                method: getBranchDetail,
                params: [
                    {
                        variables: {
                            id: branchId as string,
                        },
                        fetchPolicy: "no-cache",
                    },
                ],
            },
            (res: any) => {}
        );
    };

    const branchDetail = data?.detailBranchForAdmin?.data;

    return (
        <BranchDetailContext.Provider
            value={{
                branch: data?.detailBranchForAdmin?.data as any,
                loadBranchDetail: refetch,
            }}
        >
            <div className="bg-white flex-column-container">
                <div className="border-b p-3 d-flex justify-content-between align-items-center">
                    <h5 className="text-primary">{`${branchDetail?.name}`}</h5>
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <BranchSummaryView loading={loading} />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />

                            {tab.component}
                        </div>
                    </div>
                </div>
            </div>
        </BranchDetailContext.Provider>
    );
};

export default BranchDetail;
