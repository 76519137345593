import styled from "@emotion/styled";
import classNames from "classnames";
import { Icon, InputText } from "d-react-components";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import Drawer, { CustomDrawerProps } from "../../../common/Drawer";
import { IMaterialOrder } from "../../../interfaces/material";
import Messages from "../../../languages/Messages";
import QuantityInputField from "../../quotation/share/QuantityInputField";
import MaterialName from "./ProductName";
// import AddCustomItemDrawer from "./AddCustomItemDrawer";

const ProductCartItem = ({
    product,
    isSubItem,
    onChangeQuantity,
    onChangeRemark,
    onChangeProductDetail,
}: {
    product: IMaterialOrder & { [key: string]: any };
    isSubItem: boolean;
    onChangeQuantity?: any;
    onChangeProductDetail?: any;
    onChangeRemark?: any;
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showEditRemark, setShowEditRemark] = useState(false);

    return (
        <StyledProductItem
            expanded={expanded}
            className={classNames({
                "flex items-center": isSubItem,
            })}
        >
            {isSubItem && (
                <span className="border-t h-[1px] w-[45px] flex-nowrap grow-0 shrink-0" />
            )}
            <div
                className={classNames({
                    "flex-1 sub-item": isSubItem,
                    "border-t": !isSubItem,
                })}
            >
                <div
                    className={classNames("flex pt-2", {
                        "bg-gray-100 p-2 mt-2": isSubItem,
                    })}
                >
                    <div
                        className={classNames(
                            { "parent-product": !isSubItem },
                            "flex-1 mb-1"
                        )}
                    >
                        <MaterialName
                            item={product}
                            // imgClass="w-10 h-10"
                            // showPrice
                            // isCreate
                            // generateVoucher={() =>
                            //     isSimpleProduct &&
                            //     type === OrderType.ORDER &&
                            //     product.isGenerateGiftVoucher ? (
                            //         <div className="w-full p-2 my-2 text-primary bg-[#DCE5E5] flex items-center">
                            //             <Checkbox
                            //                 onChange={(value) =>
                            //                     onChangeProductDetail(
                            //                         product,
                            //                         "isGift",
                            //                         !product.isGift
                            //                     )
                            //                 }
                            //                 checked={product.isGift}
                            //             />{" "}
                            //             <span className="ml-2 text-sm">
                            //                 {Messages.generateGiftVoucher}
                            //             </span>
                            //         </div>
                            //     ) : null
                            // }
                            // addOneAfterName={() => (
                            //     <>
                            //         {showCollapseButton ? (
                            //             <span
                            //                 className="bg-primary p-1 text-center cursor-pointer text-white text-xs h-[20px] w-[52px] mx-2 grow-0 shrink-0"
                            //                 onClick={() =>
                            //                     setExpanded(!expanded)
                            //                 }
                            //             >
                            //                 {expanded
                            //                     ? Messages.collapse
                            //                     : Messages.expand}
                            //             </span>
                            //         ) : null}
                            //         {isCustomProduct && (
                            //             <div
                            //                 className="ml-2 w-[16px] h-[16px] text-white bg-black flex items-center justify-content-center cursor-pointer"
                            //                 onClick={() => {
                            //                     setShowEditProductCustom(
                            //                         product
                            //                     );
                            //                 }}
                            //             >
                            //                 <Icon name="edit" size="xx-small" />
                            //             </div>
                            //         )}
                            //     </>
                            // )}
                        />
                    </div>
                    <div>
                        <Icon
                            name="note"
                            className="text-primary cursor-pointer"
                            onClick={() => setShowEditRemark(true)}
                        />
                    </div>
                </div>
                <div className="py-2 pl-[45px] text-sm">
                    <div className="flex items-center">
                        <InputText
                            prefix="THB"
                            value={product.price}
                            className="max-w-[150px]"
                            onChange={(e) =>
                                onChangeProductDetail(
                                    product,
                                    "price",
                                    e?.target?.value
                                )
                            }
                        />
                        <span className="text-gray-500 ml-2">
                            per {product.purchaseUnit?.name}
                        </span>
                    </div>
                </div>
                {product.remark && (
                    <div className="ml-12 mb-2 bg-[#82A4A4] py-3 px-3 text-white">
                        <h5 className="text-sm text-white mb-2">
                            {Messages.remark}
                        </h5>
                        <div className="text-xs whitespace-pre-line">
                            {product.remark}
                        </div>
                    </div>
                )}
                <div
                    className={classNames("flex mb-2 items-center", {
                        "bg-gray-100 px-2 pb-2 flex-1": isSubItem,
                        // "bg-amber-100 px-2 pb-2": isCustomProduct,
                    })}
                >
                    <div className="flex-1">
                        <div className="w-[150px] pl-[45px]">
                            <QuantityInputField
                                quantity={product.quantity}
                                onChangeQuantity={(quantity: number) =>
                                    onChangeQuantity(product, quantity)
                                }
                                disabled
                                // disabled={isSubItem || product.doctorOrder}
                            />
                        </div>
                    </div>
                    <div className="px-2 py-1 text-white bg-primary text-sm">
                        <CurrencyFormat
                            value={
                                product?.quantity && product?.price
                                    ? product?.quantity * Number(product?.price)
                                    : 0
                            }
                            displayType="text"
                            thousandSeparator
                            prefix="฿"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </div>
                </div>
            </div>
            {showEditRemark && (
                <ProductNote
                    open={showEditRemark}
                    item={product}
                    onClose={() => setShowEditRemark(false)}
                    onSave={(value: any) => {
                        onChangeRemark(product, value);
                        setShowEditRemark(false);
                    }}
                />
            )}
        </StyledProductItem>
    );
};

const ProductNote = ({ open, onClose, onSave, item }: CustomDrawerProps) => {
    const [remark, setRemark] = useState(item.remark);
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.remark}
            onSave={() => onSave(remark)}
        >
            <InputText
                multiple
                value={remark}
                onChange={(e) => setRemark(e?.target?.value)}
                className="mt-3 col-sm-12"
            />
        </Drawer>
    );
};

const StyledProductItem: any = styled.div`
    .parent-product {
        ${(props: any) =>
            props.expanded &&
            `
    position: relative;
    &:before {
      display: block;
      content: "";
      height: 95%;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: #e5e7eb;
    }
    `}
    }
    .sub-product {
        & > div {
            position: relative;
            &:before {
                display: block;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                background-color: #e5e7eb;
            }
            &:last-child {
                &:before {
                    height: 50%;
                }
            }
            &:not(:first-child) {
                .sub-item {
                    border-top: 1px solid #e5e7eb;
                }
            }
        }
    }
`;

export default ProductCartItem;
