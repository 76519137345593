import { IRowsKey, TimeUtils, ViewRowInterchange } from "d-react-components";
import { find } from "lodash";
import { useContext } from "react";
import { STOCK_TRANSFER_TYPES } from "../../../../constant/stock-transfer";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import { IStockTransfer } from "../../../../interfaces/stock-transfer";
import Messages from "../../../../languages/Messages";

const StockTransferGeneral = () => {
    const { stockTransfer } = useContext(StockTransferDetailContext);

    const GENERAL_KEYS: IRowsKey<IStockTransfer>[] = [
        {
            id: "transferNo",
            label: "ID",
        },
        {
            id: "origin",
            label: Messages.origin,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "destination",
            label: Messages.destination,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "type",
            label: Messages.transferType,
            renderContent: ({ data }) =>
                (Messages as any)[
                    find(STOCK_TRANSFER_TYPES, (i) => i?.id === data)?.label ??
                        ""
                ],
        },
        {
            id: "expectedDateOfArrival",
            label: Messages.expectedDateOfArrival,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "expectedDateOfTransfer",
            label: Messages.expectedDateOfTransfer,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "remark",
            label: Messages.remark,
            renderContent: ({ data }) => data || "N/A",
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => TimeUtils.toDateTime(data),
        },
    ];
    const renderHeader = (title: any) => {
        return (
            <div className="flex-row-between-center mb-3">
                <h5>{title}</h5>
            </div>
        );
    };

    return (
        <div className="flex-column">
            <div className="border p-3 mt-3">
                {renderHeader(Messages.generalInformation)}
                <ViewRowInterchange
                    keyList={GENERAL_KEYS}
                    dataSource={stockTransfer}
                    variant="background"
                />
            </div>
        </div>
    );
};

export default StockTransferGeneral;
