import { useFormikContext } from "formik";
import { filter, find, forEach, isEmpty, isUndefined, reduce } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useMeasure } from "react-use";
import { DiscountType, PriceSetType } from "../../../api/hooks";
import { MaterialPurchaseContext } from "../../../context/material";
import { OrderType } from "../../../interfaces/order";
import VendorSelect from "../../vendor/share/VendorSelect";
import PriceTable from "../share/PriceTable";
import ProductCartItem from "../share/ProductCartItem";
import VendorInfo from "../share/VendorInfo";
import MaterialProductActions from "./MaterialProductActions";

const getSubTotal = (productList: any, applyPriceSet?: PriceSetType) => {
    let result = 0;
    forEach(productList, (product) => {
        result += Number(product.price || 0) * product.quantity;
    });

    return result;
};

const getTotal = (subTotal: number, manualDiscount: any) => {
    if (manualDiscount?.length) {
        const totalDiscount = reduce(
            manualDiscount,
            (result, value, key) => {
                const { discountType, discountValue, total } = value;
                return result + total;
            },
            0
        );
        return subTotal - totalDiscount;
    }
    return subTotal;
};

const MaterialPurchaseOrderCart = ({ type }: { type?: OrderType }) => {
    const {
        productList,
        setProductList,
        productCustomList = [],
        setProductCustomList,
        shipping,
        setShipping,
        billing,
        setBilling,
        cartValue,
        setCartValue,
        checkCartValue,
        manualDiscount,
        setManualDiscount,
    } = useContext(MaterialPurchaseContext);
    const [actionBlockRef, { height: actionBlockHeight }] = useMeasure<any>();
    const [customerBlockRef, { height: customerBlockHeight }] =
        useMeasure<any>();
    const { values, errors, handleSubmit, setFieldValue, setValues } =
        useFormikContext<any>();

    const onChangeQuantity = (product: any, quantity: number) => {
        if (quantity < 1) {
            return;
        }
        if (product.isCustomProduct) {
            const newProductList = productCustomList.map((item: any) => {
                if (item.id === product?.id) {
                    return {
                        ...item,
                        quantity,
                    };
                }
                return item;
            });
            setProductCustomList([...newProductList]);
        } else {
            const newProductList = productList.map((item: any) => {
                if (item.productOrderId === product?.productOrderId) {
                    return {
                        ...item,
                        quantity,
                    };
                }
                return item;
            });
            setProductList([...newProductList]);
        }
    };

    const onChangeRemark = (product: any, remark: string) => {
        const newProductList = productList.map((item: any) => {
            if (item.material.id === product?.material.id) {
                return {
                    ...item,
                    remark,
                };
            }
            return item;
        });
        setProductList([...newProductList]);
    };

    const onDeleteProductHandle = (productInfo: any) => {
        const { id, productOrderId } = productInfo;

        if (productInfo.isCustomProduct) {
            const newProCustomList = productCustomList.filter(
                (item: any) => item.id !== id
            );
            setProductCustomList(newProCustomList);
        } else {
            const newProductList = productList.filter(
                (item: any) => item.productOrderId !== productOrderId
            );

            if (manualDiscount && manualDiscount?.length > 0) {
                const newDiscountList = filter(
                    manualDiscount ?? [],
                    (i) => i?.product?.productOrderId !== productOrderId
                );
                setManualDiscount && setManualDiscount(newDiscountList);
                if (isEmpty(newDiscountList)) {
                    setCartValue({ ...cartValue, manualDiscount: undefined });
                }
            }
            setProductList([...newProductList]);
        }
    };

    const onChangeProductDetail = (
        productInfo: any,
        key: string,
        newValue: any
    ) => {
        const { id } = productInfo;
        if (productInfo.isCustomProduct) {
            const newProCustomList = productCustomList.map((item: any) =>
                item.id !== id
                    ? item
                    : {
                          ...item,
                          [key]: newValue,
                      }
            );
            setProductCustomList(newProCustomList);
        } else {
            const newProductList = productList.map((item: any) =>
                item.id !== id
                    ? item
                    : {
                          ...item,
                          [key]: newValue,
                      }
            );
            setProductList([...newProductList]);
        }
    };

    // const productSource = useMemo(
    //     () => [...productList, ...productCustomList],
    //     [productList, productCustomList]
    // );

    const submitOrder = () => {
        handleSubmit();
    };

    useEffect(() => {
        const subTotalValue = getSubTotal([
            ...productList,
            ...productCustomList,
        ]);
        setCartValue({
            ...cartValue,
            subtotal: subTotalValue,
            total: getTotal(subTotalValue, manualDiscount),
            manualDiscount,
        });
    }, [productList, productCustomList, manualDiscount]);

    ///load customer detail after selected
    // useEffect(() => {
    //     if (customer?.[0]?.id) {
    //         getCustomerDetail({
    //             variables: {
    //                 id: customer?.[0]?.id,
    //             },
    //         }).then((resp) => {
    //             updateCustomerContextData(resp);
    //             setProductList([]);
    //         });
    //     } else if (!isUndefined(setSelectedCustomer)) {
    //         setSelectedCustomer(undefined);
    //         setShipping([]);
    //         setBilling(undefined);
    //         if (isEmpty(customer)) {
    //             setProductList([]);
    //         }
    //     }
    // }, [customer]);

    // const updateCustomerContextData = (resp: any) => {
    //     if (!isUndefined(setSelectedCustomer)) {
    //         let customerDetail = resp?.data?.data?.data;
    //         if (customer?.[0]?.setting?.isStaff) {
    //             customerDetail = {
    //                 ...customerDetail,
    //                 setting: {
    //                     ...(customerDetail?.setting || {}),
    //                     isStaff: true,
    //                 },
    //             };
    //         }

    //         setSelectedCustomer(customerDetail);
    //         if (
    //             customerDetail?.address &&
    //             customerDetail?.address.length &&
    //             !isUndefined(setShipping)
    //         ) {
    //             const defaultAddress = find(
    //                 customerDetail?.address,
    //                 (add) => add.isDefault
    //             );
    //             setShipping(
    //                 defaultAddress
    //                     ? [defaultAddress]
    //                     : [customerDetail?.address?.[0]]
    //             );
    //         }
    //     }
    // };

    return (
        <div className="border relative ">
            <div
                className="bg-white p-3"
                style={{ height: `calc(100vh - 142px)` }}
            >
                <div ref={customerBlockRef}>
                    <VendorSelect
                        value={values.vendor}
                        onChange={(value: any) =>
                            setFieldValue("vendor", value)
                        }
                        error={errors.vendor}
                    />
                    {!isEmpty(values.vendor) && (
                        <VendorInfo vendor={values.vendor?.[0]} />
                    )}
                </div>
                <div
                    className="overflow-auto"
                    style={{
                        height: `calc(100% - ${
                            customerBlockHeight + actionBlockHeight
                        }px)`,
                    }}
                >
                    <div className="my-3">
                        {productList.map((product, index) => (
                            <ProductCartItem
                                product={product as any}
                                isSubItem={false}
                                key={index}
                                onChangeQuantity={onChangeQuantity}
                                onChangeRemark={onChangeRemark}
                                // onDeleteProduct={onDeleteProductHandle}
                                onChangeProductDetail={onChangeProductDetail}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div
                ref={actionBlockRef}
                className="bg-white absolute bottom-0 left-0 w-100 z-30"
            >
                <div className="bg-white">
                    <PriceTable />
                </div>
                {/* <CustomerDetailContext.Provider
                    value={
                        {
                            customer: vendor as ICustomer,
                            setCustomer: setVendor,
                        } as any
                    }
                > */}
                <MaterialProductActions
                    // applyCoupon={(coupon: any) => onLoadFreeGift(coupon)}
                    submitOrder={submitOrder}
                />
                {/* </CustomerDetailContext.Provider> */}
            </div>
        </div>
    );
};

export default MaterialPurchaseOrderCart;
