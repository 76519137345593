import { InputNumber } from "antd";
import React from "react";
import styled from "@emotion/styled";

const QuantityInputField = ({
    quantity = 0,
    onChangeQuantity,
    disabled,
    label = "",
}: any) => {
    return (
        <StyledInputNumber disabled={disabled}>
            <InputNumber
                controls={false}
                disabled={disabled}
                addonBefore={
                    <span
                        onClick={() =>
                            !disabled &&
                            onChangeQuantity &&
                            quantity > 0 &&
                            onChangeQuantity(quantity - 1)
                        }
                        className="cursor-pointer px-[10px]"
                    >
                        -
                    </span>
                }
                addonAfter={
                    <span
                        onClick={() =>
                            !disabled &&
                            onChangeQuantity &&
                            onChangeQuantity(quantity + 1)
                        }
                        className="cursor-pointer px-[10px]"
                    >
                        +
                    </span>
                }
                value={quantity}
                onChange={(value) => {
                    onChangeQuantity(value);
                }}
            />
        </StyledInputNumber>
    );
};

const StyledInputNumber: any = styled.div`
    .ant-input-number-input {
        text-align: center;
        border: none;
    }
    .ant-input-number {
        background-color: #f9f9f9;
        border: none !important;
        box-shadow: none !important;
    }
    .ant-input-number-group-addon {
        cursor: pointer;
        border: none;
        background-color: #efefef;
        padding: 0;
    }
    ${(props: any) =>
        props.disabled &&
        `
    opacity: 0.75;
  `}
`;

export default QuantityInputField;
