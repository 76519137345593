import { map } from "lodash";
import { ICustomer } from "./customer";
import { IOrder } from "./order";
import { IProduct } from "./product";
import IUser from "./user";
import { UsageType } from "../api/hooks";

export interface IVoucher {
    id: string;
    updatedAt: string;
    createdAt: string;
    createByAdmin: IUser;
    voucherNo: string;
    status: string;
    type: string;
    code: string;
    value: string;
    limit: number;
    totalUsedTimes: number;
    totalUsedValues: number;
    balance?: number;
    effectiveDate: string;
    expiryDate: string;
    remark: string;
    services: IProduct[];
    orderProduct?: IProduct;
    usage: {
        usedAt: string;
        usedValue: string;
        balance: any;
        ref: string;
        refId: string;
        usedBy: IUser;
        order: IOrder;
    }[];
    ownership: {
        status: string;
        remark: string;
        ownedBy: IUser;
    };
    customer: ICustomer;
    order?: IOrder;
}

export const mapVoucherToServer = (input: any) => {
    return {
        type: input?.type,
        typeOfIssuance: input.typeOfIssuance,
        remark: input.remark,
        code: input.code,
        effectiveDate: input?.effectiveDate,
        expiryDate: input?.expiryDate,
        services: map(input?.products, (item) => item.id),
        customer: input?.customer?.id,
        prefix: input?.prefix,
        suffix: input?.suffix,
        quantity: input?.quantity,
        value: input?.cashValue,
        usageLimitPerCoupon: input?.usageLimit,
        limit: input?.usageType === UsageType.SingleUse ? 1 : 0,
    };
};

export const mapVoucherFilterToServer = (input: any) => {
    return {
        status: input?.status,
        type: input?.type,
        customer: map(input?.customer, (item) => item.id),
        services: map(input?.services, (item) => item.id),
        orderProducts: map(input?.orderProducts, (item) => item.id),
    };
};

export const mapVoucherFilterToQueryParams = (input: any) => {
    return {
        status: input?.status,
        type: input?.type,
        customer: map(input?.customer, (item) => ({
            id: item.id,
            name: item.name,
        })),
        services: map(input?.services, (item) => ({
            id: item.id,
            name: item.name,
        })),
        orderProducts: map(input?.orderProducts, (item) => ({
            id: item.id,
            name: item.name,
        })),
    };
};
