import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";
import { F_BRANCH_BASIC } from "./branch";

export const F_PRODUCT_WITH_STOCK = gql`
    fragment F_PRODUCT_WITH_STOCK on AdminProductWithStock {
        id
        name
        productNo
        status
        sku
        unit
        gallery
        thumbnail
        quantity
        hold
        currentQuantity
        currentHold
    }
`;

export const F_INVENTORIES = gql`
    ${F_PRODUCT_WITH_STOCK}
    ${F_PAGINATE}
    fragment F_INVENTORIES on ListAdminProductStockResponse {
        data {
            ...F_PRODUCT_WITH_STOCK
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_PRODUCT_STOCK_HISTORY = gql`
    ${F_BRANCH_BASIC}
    ${F_ADMIN_USER_BASIC}
    fragment F_PRODUCT_STOCK_HISTORY on AdminProductStockHistory {
        id
        code
        hold
        quantity
        refId
        refType
        createdAt
        updatedAt
        branch {
            ...F_BRANCH_BASIC
        }
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        adjustmentRequest {
            id
            adjustmentNo
        }
        delivery {
            id
            deliveryNo
        }
    }
`;

export const F_PRODUCT_STOCK_HISTORIES = gql`
    ${F_PRODUCT_STOCK_HISTORY}
    ${F_PAGINATE}
    fragment F_PRODUCT_STOCK_HISTORIES on ListAdminProductStockHistoryResponse {
        data {
            ...F_PRODUCT_STOCK_HISTORY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
