import {
    AwesomeTableComponent,
    IColumnsProps,
    InputTextSearch,
} from "d-react-components";
import { debounce, filter, map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { MaterialStockRequestDetailContext } from "../../../context/material";
import Messages from "../../../languages/Messages";

const MaterialAdjustRequestItems = () => {
    const { requestDetail } = useContext(MaterialStockRequestDetailContext);
    const [textSearch, setTextSearch] = useState<string>("");
    const proSource = useMemo(() => {
        const mappedPros = map(requestDetail?.materials ?? [], (item) => ({
            ...(item?.material ?? {}),
            adjustment: item?.adjustment ?? 0,
            stock: item?.stock ?? 0,
            change: item?.adjustment - item.stock,
        }));
        if (textSearch) {
            return filter(mappedPros, (item) => {
                const { name, sku } = item;
                const text = textSearch?.toLocaleLowerCase?.();
                return (
                    name?.toLocaleLowerCase?.()?.indexOf(text) !== -1 ||
                    sku?.toLocaleLowerCase?.()?.indexOf(text) !== -1
                );
            });
        }
        return mappedPros;
    }, [textSearch, requestDetail?.materials]);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (item) => item.name,
        },
        {
            title: Messages.currentQty,
            dataIndex: "stock",
        },
        {
            title: Messages.adjustmentQty,
            dataIndex: "adjustment",
        },
        {
            title: Messages.differentRate,
            dataIndex: "change",
            render: (change, item) =>
                `${change > 0 ? "+" : "-"} ${(change * 100) / item.stock}`,
        },
    ];

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 0);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
            </div>
            <InputTextSearch onChange={onChangeSearch} className="mt-3" />
            <AwesomeTableComponent
                columns={columns}
                dataSource={proSource}
                className="mt-3"
                pagination={false}
            />
        </div>
    );
};

export default MaterialAdjustRequestItems;
