import { isEmpty } from "lodash";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useFormik } from "formik";
import React, { useContext, useMemo, useRef, useState } from "react";
import {
    Button,
    Modal,
    Notifications,
    Progress,
    TimeUtils,
} from "d-react-components";
import QRCode from "react-qr-code";
import { UserCrudContext, UserDetailContext } from "../../../context/user";
import Messages from "../../../languages/Messages";
import { getAuthString } from "../../../utils/qrcode";
import UserCrudOtpAuth from "../crud/UserCrudOtpAuth";
import { mapUserGeneralInfoToSer } from "../../../interfaces/user";
import { useUpdateAdminUserMutation } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";

const renderInfoRow = (title: string, content: string) => (
    <div className="d-flex border-bottom mt-2 py-2">
        <text className="text-bold w-50">{title}</text>
        <text className="w-50">{content}</text>
    </div>
);

const UserAuthenticationSetting = () => {
    const { user } = useContext(UserDetailContext);
    const [isPrinting, seTisPrinting] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const pdfUserAuthRef = useRef();
    const [openExportModal, setOpenExportModal] = useState(false);

    const beforePDFPrinting = () => {
        const cc = document.getElementsByClassName("user-detail__auth-code");
        for (let i = 0; i < cc.length; i += 1) {
            const svg = cc[i];
            const rect = svg.getBoundingClientRect();

            const img = document.createElement("img");
            img.src = `data:image/svg+xml;base64,${btoa(
                unescape(encodeURIComponent(svg.outerHTML))
            )}`;
            //@ts-ignore
            img.style = `height:100px;width:100px;margin-top:16px`;
            img.className = "remove-after-print";
            //@ts-ignore
            svg.parentNode.insertBefore(img, svg);
        }
    };
    const afterPDFPrinting = () => {
        setTimeout(() => {
            const imagePrintE = document.querySelector(".remove-after-print");
            //@ts-ignore
            imagePrintE.parentElement.removeChild(imagePrintE);
        }, 300);
    };
    const otpAuthUrl = useMemo(() => {
        if (!user) {
            return "";
        }
        return getAuthString(
            user?.nickName,
            user?.authenticationCode as string
        );
    }, [user]);

    const onClickExport = async () => {
        seTisPrinting(true);
        beforePDFPrinting();
        //@ts-ignore
        pdfUserAuthRef.current.save();
        seTisPrinting(false);
        afterPDFPrinting();
        return Promise.resolve();
    };

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-3">
                <h5 className="text-[16px] flex-1">
                    {Messages.authentication}
                </h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEditModal(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            <div>
                <p className="mt-3">{Messages.adminAuthenticatorDescription}</p>
                <div className="mb-3">
                    <label className="text-label">{Messages.status}</label>
                    <div>
                        {user?.authenticationStatus
                            ? Messages.enabled
                            : Messages.disabled}
                    </div>
                </div>
                {user?.authenticationStatus && (
                    <>
                        <div className="mb-3">
                            <label className="text-label">
                                {Messages.secretCode}
                            </label>
                            <div>{user?.authenticationCode}</div>
                        </div>
                        <div className="mb-3">
                            <label className="text-label">
                                {Messages.qrCode}
                            </label>
                            <div>
                                {!isEmpty(otpAuthUrl) && (
                                    <QRCode
                                        value={otpAuthUrl}
                                        size={150}
                                        className="mt-3"
                                    />
                                )}
                            </div>
                        </div>
                        <Button
                            iconName="download"
                            onClick={() => setOpenExportModal(true)}
                        >
                            {Messages.downloadPdf}
                        </Button>
                    </>
                )}
            </div>

            {openExportModal && (
                <Modal
                    title={Messages.export}
                    saveText={Messages.export}
                    open={openExportModal}
                    onClose={() => setOpenExportModal(false)}
                    onSave={onClickExport}
                >
                    <PDFExport
                        paperSize="auto"
                        ref={pdfUserAuthRef as any}
                        margin={20}
                        fileName={`${user?.companyId}_${
                            user?.fullName
                        }_${TimeUtils.convertMiliToDate(new Date().getTime())}`}
                        author="Lotus Team"
                    >
                        <div className="d-flex flex-column">
                            <h4 style={{ fontWeight: "bold" }}>
                                {Messages.workInfinityXAdvancedAuthentication}
                            </h4>
                            <div className="mt-3">
                                {Messages.exportAuthenDescription}
                            </div>
                            {renderInfoRow(
                                Messages.username,
                                user?.username || ""
                            )}
                            {renderInfoRow(
                                Messages.fullName,
                                `${user?.firstName} ${user?.lastName}`
                            )}
                            {renderInfoRow(
                                Messages.nickName,
                                user?.nickName || ""
                            )}
                            {renderInfoRow(
                                Messages.employeeId,
                                user?.companyId as any
                            )}
                            <div className="d-flex">
                                <QRCode
                                    value={otpAuthUrl}
                                    size={150}
                                    className="mt-3"
                                />
                            </div>
                            <text className="mt-3">
                                {Messages.exportAuthenFooter}
                            </text>
                        </div>
                    </PDFExport>
                </Modal>
            )}

            {showEditModal && (
                <ModalEditAuthentication
                    open={showEditModal}
                    onClose={() => {
                        setShowEditModal(false);
                    }}
                />
            )}
        </div>
    );
};

const ModalEditAuthentication = ({ open, onClose }: any) => {
    const { user, loadAdminDetail } = useContext(UserDetailContext);
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const userForm = useFormik({
        initialValues: {
            isOtpAuth: user?.authenticationStatus,
            otpAuthSecretCode: user?.authenticationCode,
            nickName: user?.nickName,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        onSubmit: (values) => {
            const bodyInfo = mapUserGeneralInfoToSer(values);

            const body = {
                variables: {
                    id: user?.id as string,
                    payload: bodyInfo,
                },
            };

            Progress.show(
                {
                    method: updateAdminUser,
                    params: [body],
                },
                (resp: any) => {
                    loadAdminDetail();
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    return (
        <UserCrudContext.Provider value={{ userForm }}>
            <ModalEditAuthenticationContent open={open} onClose={onClose} />
        </UserCrudContext.Provider>
    );
};

const ModalEditAuthenticationContent = ({ open, onClose }: any) => {
    const { userForm } = useContext(UserCrudContext);
    const { handleSubmit } = userForm;
    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="medium"
            title={Messages.authentication}
            onSave={() => {
                handleSubmit();
            }}
        >
            <div className="p-4">
                <UserCrudOtpAuth />
            </div>
        </Drawer>
    );
};

export default UserAuthenticationSetting;
