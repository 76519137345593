import {
    AwesomeTableComponent,
    IColumnsProps,
    Icon,
    useDeepEffect,
    ViewTextError,
} from "d-react-components";
import { filter, find, map } from "lodash";
import { useMemo } from "react";
import InventoryAPI from "../../../../api/queries/inventory";
import InputTextQuantity from "../../../../common/input/InputTextQuantity";
import { IProduct } from "../../../../interfaces/product";
import Messages from "../../../../languages/Messages";
import EquipmentSearchButton from "../../../equipment/share/EquipmentSearchButton";
import ProductNameView from "../../../product/share/ProductNameView";

const EquipmentStockRequestCreateItems = ({ stockRequestForm }: any) => {
    const { values, errors, setFieldValue } = stockRequestForm || {};
    const { equipments, branch } = values || {};
    const equipmentIds = useMemo(() => {
        return map(equipments || [], (i) => i?.id);
    }, [equipments]);

    useDeepEffect(() => {
        if (equipmentIds && equipmentIds?.length > 0 && branch?.id) {
            // mapStockToProduct();
        }
    }, [equipmentIds]);

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (service) => <ProductNameView product={service} />,
        },
        {
            title: Messages.currentQty,
            dataIndex: "currentQty",
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            render: (quantity, product) => (
                <InputTextQuantity
                    value={quantity}
                    onChange={(quantityValue) =>
                        onUpdateQty({
                            ...product,
                            quantity: quantityValue,
                        })
                    }
                    min={1}
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (service) => (
                <Icon
                    className="cursor-pointer"
                    name="remove_circle_outline"
                    onClick={() => onRemoveProduct([service?.id])}
                />
            ),
        },
    ];

    const mapStockToProduct = async () => {
        try {
            const res = await InventoryAPI.checkProductStock({
                branch: [branch?.id],
                product: equipmentIds,
            });
            const proStocks = res?.data?.data?.data ?? [];
            const mappedStocks = map(equipments, (item) => {
                const found = find(proStocks, (pro) => pro?.id === item?.id);
                return {
                    ...item,
                    currentQty: found?.quantity ?? 0,
                    quantity: item?.quantity ?? 0,
                };
            });
            setFieldValue("equipments", mappedStocks);
        } catch (error) {
            console.error({ error });
        }
    };

    const onAddProduct = (addPros: IProduct[]) => {
        setFieldValue("equipments", addPros);
    };

    const onRemoveProduct = (removeIds: string[]) => {
        setFieldValue(
            "equipments",
            filter(equipments, (pro) => !removeIds?.includes(pro?.id))
        );
    };

    const onUpdateQty = (updatePro: any) => {
        setFieldValue(
            "equipments",
            map(equipments, (pro) =>
                updatePro?.id === pro?.id ? updatePro : pro
            )
        );
    };

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
                {values?.branch && (
                    <EquipmentSearchButton
                        onSave={onAddProduct}
                        defaultValue={equipments ?? []}
                        // filterSource={{
                        //     productType: [PRODUCT_TYPE.SIMPLE_PRODUCT],
                        // }}
                    >
                        {Messages.add}
                    </EquipmentSearchButton>
                )}
            </div>
            <AwesomeTableComponent
                columns={columns}
                dataSource={equipments ?? []}
                className="mt-3"
                pagination={false}
            />
            {errors?.equipments && <ViewTextError error={errors.equipments} />}
        </div>
    );
};

export default EquipmentStockRequestCreateItems;
