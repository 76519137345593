import { Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { findIndex, forEach } from "lodash";
import React, { useState } from "react";
import PromotionAPI from "../../../api/queries/promotion";
import Drawer from "../../../common/Drawer";
import { usePromotionDetailContext } from "../../../context/promotion";
import {
    mapPromotionProductToServer,
    mapPromotionProductToValuesProduct,
} from "../../../interfaces/promotion";
import Messages from "../../../languages/Messages";
import PromotionItemsForm from "../create/PromotionItemsForm";

export interface IPromotionDetailItemsProps {
    [key: string]: any;
}

const PromotionDetailItems: React.FC<IPromotionDetailItemsProps> = ({ id }) => {
    const { promotion } = usePromotionDetailContext();
    const [openEdit, setOpenEdit] = useState(false);

    return (
        <div className="p-4 border">
            <PromotionItemsForm
                variant="detail"
                form={
                    {
                        values: {
                            products: mapPromotionProductToValuesProduct(
                                promotion?.products ?? []
                            ),
                        },
                    } as any
                }
                onEdit={() => setOpenEdit(true)}
            />
            {openEdit && (
                <PromotionProductUpdateDrawer
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

export default PromotionDetailItems;

interface IMemoUpdateDrawer {
    open: boolean;
    onClose: () => void;
}

const PromotionProductUpdateDrawer = ({ open, onClose }: IMemoUpdateDrawer) => {
    const { promotion, setPromotion } = usePromotionDetailContext();
    const productForm = useFormik<any>({
        initialValues: {
            products: mapPromotionProductToValuesProduct(
                promotion?.products ?? []
            ),
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        onSubmit: (values: any) => {
            const input = mapPromotionProductToServer(values?.products ?? []);
            onUpdateProduct(input);
        },
    });

    const onUpdateProduct = (input: any) => {
        Progress.show(
            {
                method: PromotionAPI.updateProducts,
                params: [promotion?.id, input],
            },
            (res: any) => {
                const { data, error } = res?.data?.data ?? {};
                if (error) {
                    if (error?.length > 0) {
                        const clone = productForm?.values?.products ?? [];
                        forEach(error, (e: any) => {
                            const foundIdx = findIndex(
                                clone,
                                (i: any) => i?.id === e?.product?.id
                            );
                            if (foundIdx !== -1) {
                                clone[foundIdx].error = e;
                            }
                        });
                        productForm.setFieldValue("products", clone);
                    }
                } else {
                    setPromotion(data);
                    Notifications.showSuccess(Messages.updateSuccess);
                    onClose();
                }
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.products}
            onSave={() => productForm.handleSubmit()}
            size="auto"
            width="1200px"
        >
            <div className="p-3">
                <PromotionItemsForm form={productForm} promotion={promotion} />
            </div>
        </Drawer>
    );
};
