import React from "react";
import { IBranch } from "../interfaces/branch";
import { IUserRole } from "../interfaces/user";

interface IBranchCreateState {
    branchForm: any;
}

const branchCreateState: IBranchCreateState = {
    branchForm: null,
};

export const BranchCreateContext = React.createContext(branchCreateState);

////////// DETAIL CONTEXT

interface IBranchDetailState {
    branch?: IBranch;
    loadBranchDetail: () => void;
}

const branchDetailState: IBranchDetailState = {
    branch: undefined,
    loadBranchDetail: () => {},
};

export const BranchDetailContext = React.createContext(branchDetailState);

////////// DETAIL USER CONTEXT

interface IBranchUserState {
    roleList?: IUserRole[];
    selectedRole?: IUserRole;
    setSelectedRole?: any;
    reloadList?: any;
    setReloadList?: any;
}

const branchUserState: IBranchUserState = {
};

export const BranchUserContext = React.createContext(branchUserState);