import { gql } from "@apollo/client";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_NOTE, F_PAGINATE } from "./app";
import { F_CUSTOMER, F_CUSTOMER_BASIC } from "./customer";
import { F_PRODUCT_BASIC } from "./product";

export const F_BOOKING_CONFIRMATION = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_CUSTOMER_BASIC}
    fragment F_BOOKING_CONFIRMATION on AdminBookingConfirmationDto {
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        createdAt
        createByCustomer {
            ...F_CUSTOMER_BASIC
        }
    }
`;

export const F_BOOKING = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_CUSTOMER_BASIC}
    ${F_CUSTOMER}
    ${F_PRODUCT_BASIC}
    ${F_BOOKING_CONFIRMATION}
    fragment F_BOOKING on AdminBookingDto {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        bookingNo
        status
        contact {
            firstName
            lastName
            email
            phone
        }
        sourceOfCustomer
        doctor {
            ...F_ADMIN_USER_BASIC
        }
        remark
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        branch {
            id
            name
            code
        }
        services {
            ...F_PRODUCT_BASIC
        }
        customer {
            ...F_CUSTOMER
        }
        confirmation {
            ...F_BOOKING_CONFIRMATION
        }
        cancellation {
            ...F_BOOKING_CONFIRMATION
        }
        completion {
            ...F_BOOKING_CONFIRMATION
        }
    }
`;

export const F_BOOKINGS = gql`
    ${F_BOOKING}
    ${F_PAGINATE}
    fragment F_BOOKINGS on AdminBookingPaginateResponse {
        data {
            ...F_BOOKING
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_BOOKING_CALENDAR = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_CUSTOMER_BASIC}
    ${F_PRODUCT_BASIC}
    fragment F_BOOKING_CALENDAR on AdminBookingDto {
        id
        bookingNo
        status
        sourceOfCustomer
        doctor {
            ...F_ADMIN_USER_BASIC
        }
        remark
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        branch {
            id
            name
            code
        }
        services {
            ...F_PRODUCT_BASIC
        }
    }
`;
