import {
    IRowsKey,
    TimeUtils,
    ViewLabelStatus,
    ViewRowInterchange,
} from "d-react-components";
import { useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import { STOCK_ADJUSTMENT_STATUSES } from "../../../../constant/stock-adjustment";
import { StockAdjustmentDetailContext } from "../../../../context/stock-adjustment";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";

const StockAdjustmentSummary = ({ loading }: { loading?: boolean }) => {
    const { stockAdjustment } = useContext(StockAdjustmentDetailContext);
    const STOCK_ADJUSTMENT_KEY: IRowsKey<any>[] = [
        {
            id: "adjustmentNo",
            label: "ID",
        },
        {
            id: "branch",
            label: Messages.branchWarehouse,
            renderContent: ({ data }) => data?.name,
        },
        {
            id: "branch",
            label: Messages.branchWarehouseCode,
            renderContent: ({ data }) => data?.code,
        },
        {
            id: "createdAt",
            label: Messages.createdAt,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
        {
            id: "updatedAt",
            label: Messages.lastUpdated,
            renderContent: ({ data }) => {
                return !data ? "N/A" : TimeUtils.convertMiliToDateTime(data);
            },
        },
    ];

    return (
        <div>
            {stockAdjustment && (
                <div className="card-container border p-0">
                    <div className="d-flex align-items-center flex-column w-100 py-3 border-b py-5 px-3">
                        <img src="/icons/contract.svg" />
                        <div className="text-large-bold my-3">
                            <Link
                                to={generatePath(
                                    Path.INVENTORY_STOCK_ADJUSTMENT_DETAIL,
                                    {
                                        stockAdjustmentId: stockAdjustment?.id,
                                    }
                                )}
                            >
                                {stockAdjustment?.adjustmentNo}
                            </Link>
                        </div>
                        <ViewLabelStatus
                            status={stockAdjustment?.status}
                            listStatus={STOCK_ADJUSTMENT_STATUSES}
                            getLabel={(item) => (Messages as any)[item.label]}
                        />
                    </div>
                    <div className="w-100 px-4 py-2">
                        <ViewRowInterchange
                            dataSource={stockAdjustment}
                            keyList={STOCK_ADJUSTMENT_KEY as any}
                            variant="border"
                            classNameLabel="font-weight-bold"
                            classNameContent="text-end"
                        />
                    </div>
                </div>
            )}
            {/* {loading && <Skeleton count={5} />} */}
        </div>
    );
};

export default StockAdjustmentSummary;
