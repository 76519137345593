import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import {
    ListAdminUserRequest,
    useSearchVendorLazyQuery,
} from "../../../api/hooks";
import { SelectInfinityFilter } from "../../../common/SelectFilter";
import { IFieldSelect } from "../../../interfaces/select";
// import UserAPI from "../../../api/user/UserAPI";
import { IUser } from "../../../interfaces/user";
import { IVendor } from "../../../interfaces/vendor";
import Messages from "../../../languages/Messages";

interface IUserSearchSelect<T> extends IFieldSelect<T> {
    params?: ListAdminUserRequest;
}

const VendorSelect = ({
    value = [],
    error,
    className,
    multiple,
    label = Messages.user,
    onChange,
    params = {},
    disabled = false,
}: IUserSearchSelect<IVendor>) => {
    const [userList, setUserList] = useState<IVendor[]>(
        isArray(value) ? value : [value]
    );
    const [getVendorList] = useSearchVendorLazyQuery();

    // const onChangeTextSearch = debounce((textSearch) => {
    //     if (isEmpty(textSearch)) {
    //         setUserList([]);
    //     } else {
    //         getVendorList({
    //             variables: {
    //                 paginate: {
    //                     page: 1,
    //                     limit: 50,
    //                     search: textSearch,
    //                     ...params,
    //                 },
    //             },
    //             fetchPolicy: "no-cache",
    //         }).then((res) => {
    //             const userList = res?.data?.data?.data ?? [];
    //             const selectedValue = isArray(value) ? value : [value];
    //             const uniqUser = uniqBy(
    //                 [...selectedValue, ...userList],
    //                 (item: any) => item.id
    //             );

    //             setUserList(uniqUser as any);
    //         });
    //     }
    // }, 500);

    const onSelectUser = (ids: any) => {
        if (multiple) {
            onChange(
                map(ids, (id) =>
                    ObjectUtils.findItemFromId(userList, id)
                ) as any
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(userList, ids));
    };

    const userValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <SelectInfinityFilter
            label={Messages.vendor}
            value={value}
            error={error}
            multiple={false}
            onChange={onChange}
            fetchFn={(params: any, paging: any) =>
                getVendorList({
                    variables: {
                        paginate: {
                            search: params?.search ?? "",
                            limit: paging.pageSize,
                            page: paging.pageIndex,
                        },
                    },
                })
            }
            dataKey="data"
            placeholder={Messages.pleaseSearchAndSelect}
            getLabel={(item) => item?.name}
        />
    );
};

export default VendorSelect;
