import React from "react";
import { Tooltip as AntdTooltip, TooltipProps } from "antd";
import { Icon, IconProps } from "d-react-components";

export type ITooltipProps = TooltipProps & {
    iconProps?: Partial<IconProps>;
};

const Tooltip: React.FC<ITooltipProps> = ({
    children,
    className,
    iconProps,
    ...rest
}) => {
    return (
        <AntdTooltip {...rest} className={` ${className}`}>
            {children}
            <Icon name="info" className="" {...(iconProps || {})} />
        </AntdTooltip>
    );
};

export default Tooltip;
