import React, { Dispatch } from "react";
import { PriceSetType } from "../api/hooks";
import { ICustomer } from "../interfaces/customer";
import { CartValueState, IOrderManualDiscount } from "../interfaces/order";
import { IProduct, IProductOrder } from "../interfaces/product";
import { IQuotation } from "../interfaces/quotation";
import IUser from "../interfaces/user";

export interface IOrderMetrics {
    applyPriceSet?: PriceSetType;
}

interface QuotationCreateState {
    productFilters: any;
    setProductFilters: (productFilters: any) => void;
    setProductList: (values: any) => void;
    productList: IProductOrder[];
    productCustomList?: any;
    setProductCustomList?: any;
    discountList?: any;
    selectedCustomer?: ICustomer;
    setSelectedCustomer?: (values: any) => void;
    shipping?: any[];
    setShipping?: any;
    billing?: any[];
    setBilling?: any;
    cartValue?: CartValueState;
    setCartValue?: any;
    checkCartValue?: any;
    coSalePerson?: IUser;
    orderMetrics?: IOrderMetrics;
    setCoSalePerson?: (values: any) => void;
    manualDiscount?: IOrderManualDiscount[];
    setManualDiscount?: Dispatch<IOrderManualDiscount[]>;
    setOrderMetrics?: Dispatch<IOrderMetrics>;
}

const quotationCreateState: QuotationCreateState = {
    productFilters: null,
    setProductFilters: () => {},
    productList: [],
    setProductList: () => {},
    selectedCustomer: undefined,
    setSelectedCustomer: () => {},
    coSalePerson: undefined,
    setCoSalePerson: () => {},
    manualDiscount: undefined,
    setManualDiscount: () => {},
    orderMetrics: undefined,
    setOrderMetrics: () => {},
};

export const QuotationCreateContext = React.createContext(quotationCreateState);

export interface IQuotationDetailState {
    quotation?: IQuotation;
    loadQuotationDetail: () => void;
}

const quotationDetailState: IQuotationDetailState = {
    quotation: undefined,
    loadQuotationDetail: () => {},
};

export const QuotationDetailContext = React.createContext(quotationDetailState);
