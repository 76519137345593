import classNames from "classnames";
import { Avatar, TimeUtils, ViewLabelStatus } from "d-react-components";
import { find } from "lodash";
import { useContext } from "react";
import { TypeOfIdentity } from "../../../api/hooks";
import {
    AGENCY_STATUSES,
    AGENCY_TYPES,
    AGENCY_TYPE_OF_IDENTITIES,
} from "../../../constant/agency";
import { AgencyDetailContext } from "../../../context/agency";
import Messages from "../../../languages/Messages";

const AgencyDetailInfo = () => {
    const { agencyDetail } = useContext(AgencyDetailContext);

    const {
        name,
        agencyNo,
        phone,
        createdAt,
        updatedAt,
        status,
        picture,
        countryOfOrigin,
        typeOfAgency,
        typeOfIdentity,
    } = agencyDetail ?? {};

    const renderInfoRow = (
        title: any,
        content: any,
        showBorder: boolean = true
    ) => {
        return (
            <div
                className={classNames("flex-row-between-center py-3 w-100", {
                    " border-bottom": showBorder,
                })}
            >
                <div className="text-bold">{title}</div>
                <div className="text">{content}</div>
            </div>
        );
    };

    return (
        <div className="w-100 bg-white">
            <div className="flex-column flex-center border py-5 px-4">
                <Avatar src={picture} text={name} />
                <h4 className="text-primary mt-2">{name}</h4>
                <ViewLabelStatus
                    status={status}
                    listStatus={AGENCY_STATUSES}
                    className="mt-3"
                    getLabel={(item) => Messages[item.label]}
                />
            </div>
            <div className="flex-column flex-center border p-3">
                {renderInfoRow(Messages.id, agencyNo)}
                {renderInfoRow(
                    Messages.typeOfAgency,
                    (Messages as any)[
                        find(AGENCY_TYPES, (item) => item?.id === typeOfAgency)
                            ?.label || ""
                    ]
                )}
                {renderInfoRow(
                    Messages.typeOfIdentity,
                    (Messages as any)[
                        find(
                            AGENCY_TYPE_OF_IDENTITIES,
                            (item) => item?.id === typeOfIdentity
                        )?.label || ""
                    ]
                )}
                {renderInfoRow(
                    typeOfIdentity === TypeOfIdentity.Business
                        ? Messages.companyName
                        : Messages.individualName,
                    name
                )}
                {renderInfoRow(Messages.countryOfOrigin, countryOfOrigin?.name)}
                {renderInfoRow(Messages.phoneNo, phone)}
                {renderInfoRow(Messages.createdAt, TimeUtils.toDate(createdAt))}
                {renderInfoRow(
                    Messages.lastUpdated,
                    TimeUtils.toDate(updatedAt),
                    false
                )}
            </div>
        </div>
    );
};

export default AgencyDetailInfo;
