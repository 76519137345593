import {
    AwesomeTableComponent,
    IColumnsProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { map } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useRoleListQuery } from "../../../api/hooks";
import Drawer from "../../../common/Drawer";
import { ServiceCommissionType } from "../../../constant/user";

import UserAPI from "../../../api/queries/user";
import { UserCrudContext, UserDetailContext } from "../../../context/user";
import IUser from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import UserCrudCommission from "../crud/UserCrudCommission";

const UserDetailCommission = () => {
    const { user } = useContext(UserDetailContext);
    const { commissionServiceType, commissionService } = user as IUser;
    const [showEdit, setShowEdit] = useState(false);

    const columns: IColumnsProps = [
        {
            title: Messages.service,
            dataIndex: "service",
            render: (service) => service?.name,
        },
        {
            title: Messages.rate,
            dataIndex: "value",
            render: (value) => `${value}%`,
        },
    ];

    const renderCommissionCustomize = () => {
        return (
            <AwesomeTableComponent
                dataSource={commissionService}
                columns={columns}
                className="mt-3"
            />
        );
    };

    return (
        <div className="px-4 py-3 border my-3">
            <div className="flex mb-3">
                <h5 className="text-[16px] flex-1">{Messages.commission}</h5>
                <span
                    className="cursor-pointer text-red-500"
                    onClick={() => setShowEdit(true)}
                >
                    {Messages.edit}
                </span>
            </div>
            {commissionServiceType === ServiceCommissionType.DEFAULT && (
                <p className="text-base">{Messages.default}</p>
            )}
            {commissionServiceType === ServiceCommissionType.CUSTOMIZE &&
                renderCommissionCustomize()}

            {showEdit && (
                <ModalEditInfo
                    open={showEdit}
                    onClose={() => {
                        setShowEdit(false);
                    }}
                />
            )}
        </div>
    );
};

const ModalEditInfo = ({ open, onClose }: any) => {
    const { user, loadAdminDetail } = useContext(UserDetailContext);

    const { data: dataRole } = useRoleListQuery({
        variables: {
            paginate: {
                limit: 1000,
                page: 1,
            },
        },
    });
    const roleList: any[] = useMemo(() => {
        return dataRole?.listAdminRoleForAdmin?.data ?? [];
    }, [dataRole]);

    const userForm = useFormik({
        initialValues: {
            commissionServiceType: user?.commissionServiceType,
            commissionService: user?.commissionService ?? [],
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: isEdit ? UserUpdateInfoSchema : UserCreateSchema,
        onSubmit: (values) => {
            const body = {
                commissionServiceType: values?.commissionServiceType,
                commissionService: map(values?.commissionService, (item) => ({
                    value: item.value,
                    serviceId: item?.service.id,
                })),
            };

            Progress.show(
                {
                    method: UserAPI.updateCommissionServiceAdmin,
                    params: [user?.id, body],
                },
                (resp: any) => {
                    loadAdminDetail();
                    Notifications.showSuccess(Messages.updateUserSuccessfully);
                    onClose();
                },
                (err: any) => {
                    Notifications.showError(err);
                }
            );
        },
    });
    return (
        <UserCrudContext.Provider value={{ userForm, roleList }}>
            <Drawer
                open={open}
                onClose={onClose}
                size="auto"
                width="50vw"
                title={Messages.commission}
                onSave={() => {
                    userForm.handleSubmit();
                }}
            >
                <UserCrudCommission />
            </Drawer>
        </UserCrudContext.Provider>
    );
};

export default UserDetailCommission;
