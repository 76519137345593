import classNames from "classnames";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { YES_NO_OPTIONS } from "../../../constant/app";
import { PRODUCT_TYPES } from "../../../constant/product";
import Messages from "../../../languages/Messages";
import ProductCategorySelect from "../../product-category/share/ProductCategorySelect";
import ServiceConfigurationSelect from "../../service-config/share/ServiceConfigurationSelect";

interface IProductFilterForm {
    filterForm: any;
}

const ProductFilterForm = ({ filterForm }: IProductFilterForm) => {
    const classNameInput = classNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <ProductCategorySelect
                className={classNameInput}
                value={formValues?.category}
                onChange={(value) =>
                    filterForm.setFieldValue("category", value)
                }
                multiple
            />
            <InputSelectForm
                dataSource={YES_NO_OPTIONS}
                keyData="isMedicine"
                className={classNameInput}
                form={filterForm}
                label={Messages.thisIsMedicine}
            />
            <InputSelectForm
                dataSource={PRODUCT_TYPES}
                keyData="productType"
                className={classNameInput}
                form={filterForm}
                multiple
            />

            <InputSelectForm
                dataSource={YES_NO_OPTIONS}
                keyData="visibility"
                className={classNameInput}
                form={filterForm}
            />
            <ServiceConfigurationSelect
                className={classNameInput}
                value={formValues?.serviceConfig ?? []}
                onChange={(value) =>
                    filterForm.setFieldValue("serviceConfig", value)
                }
                multiple
            />
            <InputSelectForm
                dataSource={YES_NO_OPTIONS}
                keyData="isSellOutOfStock"
                className={classNameInput}
                form={filterForm}
            />
        </div>
    );
};

export default ProductFilterForm;
