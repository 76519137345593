import {
    AwesomeTableComponent,
    IColumnsProps,
    Icon,
    ObjectUtils,
} from "d-react-components";
import { uniqBy } from "lodash";
import { useContext } from "react";
import { PRODUCT_TYPE } from "../../../constant/product";
import { VoucherCreateContext } from "../../../context/voucher";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/share/ProductNameView";
import ProductSearchButton from "../../product/share/ProductSearchButton";

const VoucherCreateService = () => {
    const { voucherForm } = useContext(VoucherCreateContext);
    const formValues = voucherForm?.values ?? {};

    const columns: IColumnsProps = [
        {
            title: Messages.item,
            dataIndex: "",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (product: IProduct) => (
                <Icon
                    name="delete"
                    className="text-primary cursor-pointer"
                    onClick={() => onRemoveProduct(product?.id)}
                />
            ),
        },
    ];

    const onAddProduct = (productsAdd: any[]) => {
        const result = uniqBy(
            [...(formValues?.products ?? []), ...productsAdd],
            (item) => item.id
        );
        voucherForm.setFieldValue("products", result);
    };

    const onRemoveProduct = (productId: string) => {
        const result = ObjectUtils.removeArrayById(
            formValues?.products,
            productId
        );
        voucherForm.setFieldValue("products", result);
    };

    return (
        <div className="mt-3 col-12">
            <div className="flex-row-between-center">
                <label> {Messages.product}</label>

                <ProductSearchButton
                    onSave={onAddProduct}
                    defaultValue={formValues?.products ?? []}
                    filterSource={{
                        productType: [PRODUCT_TYPE.SERVICE_PRODUCT],
                    }}
                >
                    {Messages.add}
                </ProductSearchButton>
            </div>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={formValues?.products ?? []}
                pagination={false}
            />
        </div>
    );
};

export default VoucherCreateService;
