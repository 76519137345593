import styled from "@emotion/styled";
import { Spin } from "antd";
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Icon,
    InputText,
    Notifications,
    TimeUtils,
} from "d-react-components";
import { FormikProps, useFormik } from "formik";
import { filter, forEach, map, some, uniqBy } from "lodash";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import CurrencyFormat from "react-currency-format";
import { useDropzone } from "react-dropzone";
import { PriceSetType } from "../../../api/hooks";
import PromotionAPI from "../../../api/queries/promotion";
import Drawer from "../../../common/Drawer";
import InputSelectKey from "../../../common/input/InputSelectKey";
import { PRICE_SET_TYPES } from "../../../constant/product";
import { IMPORT_PROMOTION_SAMPLE_DATA } from "../../../constant/promotion";
import { IProduct } from "../../../interfaces/product";
import { IPromotion } from "../../../interfaces/promotion";
import Messages from "../../../languages/Messages";
import { exportToCSVFile } from "../../../utils/file";
import { csvJSON } from "../../import/SelectCSVFile";
import ProductNameView from "../../product/share/ProductNameView";
import ProductSearchButton from "../../product/share/ProductSearchButton";

const isExpandableProductRow = (row: any) => {
    return row?.children?.length > 0;
};

const addProductChildren = (row: IProduct) => {
    const proPriceSet = row?.priceSet ?? [];
    return {
        children: PRICE_SET_TYPES.map((item) => {
            const foundSet = proPriceSet?.find(
                (i) => i?.priceSetType === item?.id
            );
            return {
                ...item,
                priceSetType: item?.id,
                regularPrice: foundSet?.regularPrice ?? row?.regularPrice,
                salePrice: foundSet?.salePrice ?? row?.salePrice,
                newPrice: foundSet?.salePrice ?? row?.salePrice,
                isChildren: true,
                productId: row?.id,
                id: `${row?.id}-${item?.id}`,
            };
        }),
    };
};

const validateDataFormat = (data: any) => {
    if (!data || !data?.length) return false;
    if (
        some(
            data,
            (i) =>
                !i?.sku ||
                some(
                    PRICE_SET_TYPES.map((j) => j?.id),
                    (key) => Number.isNaN(i[key])
                )
        )
    ) {
        return false;
    }
    return true;
};

interface IPromotionItemsFormProps {
    form: FormikProps<any>;
    variant?: "detail" | "create" | "import";
    onEdit?: any;
    promotion?: IPromotion;
    hideButtons?: boolean;
}

type IImportPromotionData = {
    [key in PriceSetType]: any;
} & {
    sku?: string;
};

const SEARCH_PRODUCT_KEYS = [
    {
        id: "sku&name",
        label: "searchBySKUName",
    },
];

const PromotionItemsForm: React.FC<IPromotionItemsFormProps> = ({
    form,
    variant = "create",
    onEdit,
    promotion,
    hideButtons = false,
}) => {
    const { values, errors, setFieldValue } = form;
    const [textSearch, setTextSearch] = useState("");
    const [searchKeys, setSearchKeys] = useState(SEARCH_PRODUCT_KEYS[0].id);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

    const productSource = useMemo(() => {
        const productSource = values?.products;
        const allWithNos = map(productSource, (item, index) => {
            if (item?.children?.length > 0) {
                const mappedChild = map(item.children || [], (i, subIndex) => ({
                    ...i,
                    no: `${index + 1}.${subIndex + 1}`,
                }));
                return {
                    ...item,
                    children: mappedChild,
                    no: `${index + 1}`,
                };
            }
            return { ...item, no: `${index + 1}` };
        });
        let filtered = [...allWithNos];
        if (textSearch) {
            filtered = allWithNos.filter((pro: IProduct) => {
                const { sku, name } = pro;
                return (
                    sku.toLowerCase?.().indexOf(textSearch.toLowerCase()) >
                        -1 ||
                    name.toLowerCase?.().indexOf(textSearch.toLowerCase()) > -1
                );
            });
        }
        return filtered;
    }, [values?.products, map(values?.products, (i) => i?.error), textSearch]);

    const onAddProduct = (productsAdd: any[]) => {
        const productsAddWithChildren = map(productsAdd, (item) => ({
            ...item,
            ...addProductChildren(item),
        }));
        const newExpandingKeys = productsAdd.map((i) => i?.id);
        const result = uniqBy(
            [...(values?.products ?? []), ...productsAddWithChildren],
            (item) => item.id
        );
        setFieldValue("products", result);
        setExpandedRowKeys(newExpandingKeys);
    };

    const onRemoveProduct = (removeId: string) => {
        setFieldValue(
            "products",
            filter(values?.products ?? [], (item) => item?.id !== removeId)
        );
    };

    const onChangePrice = (
        price: string,
        productId: string,
        priceType: string
    ) => {
        const foundPro = values?.products?.find(
            (i: any) => i?.id === productId
        );
        if (foundPro) {
            const newChild = map(foundPro?.children ?? [], (i) => {
                return i?.priceSetType === priceType
                    ? { ...i, newPrice: price }
                    : i;
            });
            setFieldValue(
                "products",
                map(values?.products ?? [], (i) => {
                    return i?.id === productId
                        ? { ...i, children: newChild }
                        : i;
                })
            );
        }
    };

    const columns: IColumnsProps = [
        {
            title: Messages.name,
            dataIndex: "",
            render: (item: any) => {
                if ((item as any)?.isChildren) {
                    const foundSet = PRICE_SET_TYPES.find(
                        (i) => i?.id === (item as any)?.priceSetType
                    );
                    return <div>{Messages[foundSet?.label ?? ""]}</div>;
                }
                if (item?.error) {
                    return (
                        <div>
                            <ProductNameView product={item} />
                            <div className="text-danger text-xs bg-redLight  px-3 py-2 mt-2">
                                {Messages.attentionErrorPromotion}
                                <span>
                                    {`"${
                                        item?.error?.promotion?.title ?? "N/A"
                                    }" `}
                                </span>
                                <span>
                                    {`(ID:${item?.error?.promotion?.promotionNo}) | `}
                                </span>
                                <span>
                                    {`Start:${TimeUtils.toDateTime(
                                        item?.error?.promotion?.start
                                    )} - `}
                                </span>
                                <span>
                                    {`End:${TimeUtils.toDateTime(
                                        item?.error?.promotion?.end
                                    )}`}
                                </span>
                            </div>
                        </div>
                    );
                }
                return <ProductNameView product={item} />;
            },
        },
        {
            title: Messages.regularPrice,
            dataIndex: "",
            width: 150,
            render: (item: any) => {
                if ((item as any)?.isChildren) {
                    return (
                        <CurrencyFormat
                            value={(item as any)?.regularPrice}
                            thousandSeparator
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    );
                }
                return null;
            },
        },
        {
            title: Messages.defaultSalePrice,
            dataIndex: "",
            width: 150,
            render: (item: any) => {
                if ((item as any)?.isChildren) {
                    return (
                        <CurrencyFormat
                            value={(item as any)?.salePrice}
                            thousandSeparator
                            fixedDecimalScale
                            displayType="text"
                            decimalScale={2}
                        />
                    );
                }

                return null;
            },
        },
        {
            title: Messages.newSalePrice,
            dataIndex: "",
            width: 150,
            render: (item: any) => {
                if ((item as any)?.isChildren) {
                    if (variant === "detail") {
                        return (
                            <CurrencyFormat
                                value={(item as any)?.newPrice}
                                thousandSeparator
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        );
                    }
                    return (
                        <div>
                            <InputText
                                value={(item as any)?.newPrice}
                                onChange={(e) => {
                                    onChangePrice(
                                        e?.target?.value,
                                        item?.productId,
                                        item?.priceSetType
                                    );
                                }}
                            />
                        </div>
                    );
                }
                return null;
            },
        },
    ];

    if (variant === "create") {
        columns.push({
            title: Messages.action,
            dataIndex: "",
            width: 50,
            render: (item: any) => {
                if (item?.isChildren) return null;
                return (
                    <Icon
                        name="delete"
                        className="text-primary cursor-pointer"
                        onClick={() => onRemoveProduct(item?.id)}
                    />
                );
            },
        });
    }

    const renderExpandableProductTable = (product: any) => {
        return <></>;
    };

    const renderTableHeader = useMemo(() => {
        const renderButtons = (
            <div className="flex-center-y gap-2">
                {variant !== "detail" && (
                    <Button
                        variant="outline"
                        size="small"
                        onClick={() => setFieldValue("products", [])}
                    >
                        {Messages.reset}
                    </Button>
                )}
                {values?.products?.length ? (
                    <ExportProductButton data={values?.products} />
                ) : null}
                {variant !== "detail" && (
                    <BulkImportProductButton
                        onSave={(v: any) => {
                            const filtered = filter(
                                values?.products,
                                (i) => !map(v, (j) => j?.id).includes(i?.id)
                            );
                            const res = [...filtered, ...v];
                            setFieldValue("products", res);
                            setExpandedRowKeys(map(v, (i) => i?.id));
                        }}
                        promotion={promotion}
                    />
                )}
                {variant === "detail" && (
                    <Button variant="standard" size="small" onClick={onEdit}>
                        {Messages.edit}
                    </Button>
                )}
                {variant !== "detail" && (
                    <ProductSearchButton
                        onSave={onAddProduct}
                        defaultValue={values?.products ?? []}
                        buttonProps={{ size: "small" }}
                        // filterSource={{
                        //     productType: [PRODUCT_TYPE.SERVICE_PRODUCT],
                        // }}
                    >
                        {Messages.addItem}
                    </ProductSearchButton>
                )}
            </div>
        );
        return (
            <div className="flex-center-y justify-between">
                <h5>{Messages.items}</h5>
                {hideButtons ? null : renderButtons}
            </div>
        );
    }, [values, onAddProduct, promotion]);

    const expandableTable = {
        expandedRowRender: renderExpandableProductTable,
        rowExpandable: (record: any) => isExpandableProductRow(record),
        defaultExpandAllRows: true,
        expandedRowKeys,
        onExpandedRowsChange: (items: any) => {
            setExpandedRowKeys(items);
        },
    };

    return (
        <StyledTable className="p-4 border flex flex-col gap-4">
            {renderTableHeader}
            <InputSelectKey
                dataSource={SEARCH_PRODUCT_KEYS}
                onChangeText={(e) => {
                    setTextSearch(e?.target?.value);
                }}
                styleSelect={{ minWidth: "250px" }}
                onChangeSelectKey={(v) => {
                    setSearchKeys(v);
                }}
                selectKey={searchKeys}
            />
            <AwesomeTableComponent
                tableLayout="auto"
                columns={columns}
                expandable={expandableTable}
                dataSource={productSource ?? []}
                rowKey={(item) => item.id}
                bordered={false}
            />
        </StyledTable>
    );
};

export default PromotionItemsForm;

interface BulkImportProductButtonProps {
    onSave: (v: any) => void;
    promotion?: IPromotion;
}

const BulkImportProductButton: React.FC<BulkImportProductButtonProps> = ({
    onSave,
    promotion,
}) => {
    const [openImportModal, setOpenImportModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [importState, setImportState] = useState<{
        mode?: "import" | "error" | "success";
        fileName?: string;
        errors?: any;
        data?: any[];
    }>({ mode: "import" });

    const successForm = useFormik({
        initialValues: {},
        onSubmit: () => {},
    });

    const mapValidatedDataToForm = (
        validatedData: any[],
        importData: Array<IImportPromotionData>
    ) => {
        const mapped = validatedData.map((item) => {
            const foundImport = importData.find(
                (i) => i?.sku === item?.product?.sku
            );
            const product: IProduct = item?.product;
            const {
                priceSet: proPriceSet,
                regularPrice,
                salePrice,
            } = product ?? [];
            const children = PRICE_SET_TYPES.map((p) => {
                const foundSet = proPriceSet?.find(
                    (i) => i?.priceSetType === p?.id
                );
                return {
                    ...p,
                    priceSetType: p?.id,
                    regularPrice: foundSet?.regularPrice || regularPrice || 0,
                    salePrice: foundSet?.salePrice || salePrice || 0,
                    newPrice: foundImport?.[p?.id] || foundSet?.salePrice || 0,
                    isChildren: true,
                    productId: product?.id,
                    id: `${product?.id}-${p?.id}`,
                };
            });
            return {
                ...product,
                children,
            };
        });
        return mapped;
    };

    const onDrop = ([file]: any) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            const data: IImportPromotionData[] = csvJSON(reader.result) as any;
            const validation = validateDataFormat(data);
            if (validation) {
                setLoading(true);
                PromotionAPI.validateProducts(
                    {
                        sku: map(data, (i) => i?.sku),
                        ...(promotion
                            ? {
                                  start: moment(promotion.start).toDate(),
                                  end: moment(promotion.end).toDate(),
                                  branch: promotion.branch.map((i) => i?.id),
                              }
                            : {}),
                    },
                    promotion?.id ?? undefined
                )
                    .then((res) => {
                        const validatedData = res?.data?.data?.data;
                        if (some(validatedData, (item) => !!item?.error)) {
                            Notifications.showError(Messages.importFailed);
                            setImportState({
                                fileName: file?.name,
                                errors: validatedData,
                                mode: "error",
                            });
                        } else {
                            Notifications.showSuccess(Messages.importSuccess);
                            const mappedData = mapValidatedDataToForm(
                                validatedData,
                                data
                            );
                            onSave && onSave(mappedData);
                            setOpenImportModal(false);
                            // successForm.setFieldValue("products", mappedData);
                            // setImportState({
                            //     fileName: file?.name,
                            //     data: validatedData,
                            //     mode: "success",
                            // });
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        Notifications.showError(error);
                        setLoading(false);
                    });
            } else {
                Notifications.showError("File format is invalid !");
            }
        };
    };

    const onClickDownloadTemplate = () => {
        const importColumnHeader: any[] = [
            "sku",
            ...PRICE_SET_TYPES.map((item) => item.id),
        ];
        const importColumnRows = IMPORT_PROMOTION_SAMPLE_DATA?.map((item) => {
            const row: any = [];
            forEach(importColumnHeader, (key) => {
                row.push((item as any)[key]);
            });
            return row;
        });
        exportToCSVFile(
            [importColumnHeader, ...importColumnRows],
            `Import_Promotion_Products_Template`
        );
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const getErrorItemsData = () => {
        const header = ["SKU", "Messages"];
        const dataResult = [header];
        importState?.errors.forEach((validation: any) => {
            dataResult.push([validation.sku, validation.error]);
        });
        return dataResult;
    };

    const renderImportView = useMemo(() => {
        return (
            <div className="p-5">
                <div
                    className="flex-center border-dashed p-5 flex-column"
                    {...getRootProps()}
                >
                    <img
                        src="/images/CSVIcon.png"
                        id="csvIconImage"
                        className="mt-5"
                    />
                    <input {...getInputProps()} />
                    <text className="font-weight-bold my-3">
                        {Messages.dragAndDropYourCSVHere}
                    </text>
                    <small>{Messages.maximumFileSizeIs5MB}</small>
                    <small className="mb-5 text-primary text-underline">
                        {Messages.orSelectFromYourComputer}
                    </small>
                    {/* <text className="mb-5 text-primary">
                {fileImport?.name}
            </text> */}
                </div>
                <div className="flex-column d-flex">
                    <text className="mt-3">{Messages.notSureHowToStart}</text>
                    <text>- {Messages.downloadACSVFile}</text>
                    <div className="d-flex text">
                        {`- ${Messages.download}`}
                        <div
                            onClick={onClickDownloadTemplate}
                            className="mx-3 text-primary cursor-pointer"
                        >
                            {Messages.blankCSVTemplate}
                        </div>
                    </div>
                </div>
            </div>
        );
    }, []);

    const renderSuccessView = useMemo(() => {
        if (importState?.mode !== "success") return null;
        return (
            <div className="">
                <PromotionItemsForm form={successForm} hideButtons />
            </div>
        );
    }, [importState, successForm]);

    const renderErrorView = useMemo(() => {
        if (importState?.mode !== "error") return null;
        return (
            <div className="flex flex-col justify-center items-center p-[100px]">
                <div className="flex flex-col gap-[50px] justify-center items-center w-100">
                    <img src="/icons/error.svg" className="w-[70px] h-[70px]" />
                    <div className="flex justify-center items-center  text-lg">
                        <div className="">
                            {
                                importState?.errors?.filter(
                                    (i: any) => !!i?.error
                                )?.length
                            }{" "}
                            {Messages.productsWereFailedToImport}
                        </div>
                        <CSVLink
                            data={getErrorItemsData()}
                            filename={`Import_promotion_product_errors_${new Date().valueOf()}`}
                        >
                            <div className="ml-2 text-error">
                                {Messages.downloadLog}
                            </div>
                        </CSVLink>
                    </div>
                </div>
                <div className="w-100 flex justify-center px-[100px]">
                    <Button
                        iconName="chevron_left"
                        variant="trans"
                        onClick={() => {
                            setImportState({ mode: "import" });
                        }}
                    >
                        {Messages.back}
                    </Button>
                </div>
            </div>
        );
    }, [importState?.mode]);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="flex flex-col justify-center items-center  w-full h-[1000px]">
                    <Spin />
                </div>
            );
        }

        return (
            <Fragment>
                {importState.mode === "import" ? renderImportView : null}
                {importState.mode === "success" ? renderSuccessView : null}
                {importState.mode === "error" ? renderErrorView : null}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Button
                variant="outline"
                size="small"
                onClick={() => {
                    setOpenImportModal(true);
                }}
            >
                {Messages.bulkImport}
            </Button>
            <Drawer
                title={Messages.bulkImport}
                open={openImportModal}
                onClose={() => {
                    setOpenImportModal(false);
                    setImportState({ mode: "import" });
                }}
                onSave={successForm.handleSubmit}
                size="auto"
                width="1000px"
                saveText={Messages.import}
                disableSave={importState.mode !== "success"}
            >
                {renderContent()}
            </Drawer>
        </Fragment>
    );
};

const StyledTable: any = styled.div`
    overflow: hidden;
    .ant-table-expanded-row-level-1 {
        td {
            padding: 0 !important;
        }
    }
`;

const ExportProductButton: React.FC<{ data: any[] }> = ({ data }) => {
    const onClickExport = () => {
        const importColumnHeader: any[] = [
            "sku",
            ...PRICE_SET_TYPES.map((item) => item.id),
        ];
        const importColumnRows = data?.map((item) => {
            const row: any = [];
            forEach(importColumnHeader, (key) => {
                if (key === "sku") {
                    row.push(item?.sku);
                } else {
                    const foundSet = item?.children?.find(
                        (i: any) => i?.priceSetType === key
                    );
                    row.push(foundSet?.newPrice);
                }
            });
            return row;
        });
        exportToCSVFile(
            [importColumnHeader, ...importColumnRows],
            `Export_Promotion_Products_Template`
        );
    };

    return (
        <Fragment>
            <Button variant="outline" size="small" onClick={onClickExport}>
                {Messages.export}
            </Button>
        </Fragment>
    );
};
