import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    Select,
    StringUtils,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce, find, reduce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import TransferVoucherAPI from "../../../api/queries/transfer-voucher";
import AppLink from "../../../common/AppLink";
import CustomerAvatarName from "../../../common/avatar/CustomerAvatarName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import {
    TRANSFER_VOUCHER_STATUSES,
    TRANSFER_VOUCHER_TYPES,
} from "../../../constant/transfer-voucher";

import Messages from "../../../languages/Messages";
import Path from "../../Path";
import TransferVoucherTableFilterDrawer from "./TransferVoucherTableFilterDrawer";
import { mapTransferVoucherFilterToServer } from "../../../interfaces/transfer-voucher";

const SEARCH_TRANSFER_VOUCHER_KEYS = [
    {
        id: "transferVoucherNo",
        label: "transferId",
    },
    {
        id: "from_customers",
        label: "fromCustomer",
    },
    {
        id: "to_customers",
        label: "toCustomer",
    },
];

const TransferVoucherTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();
    const [searchKeys, setSearchKeys] = useState(
        SEARCH_TRANSFER_VOUCHER_KEYS[0].id
    );

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "transferVoucherNo",
            render: (transferVoucherNo, item) => (
                <AppLink
                    to={generatePath(Path.TRANSFER_VOUCHER_DETAIL, {
                        transferVoucherId: item.id,
                    })}
                >
                    {transferVoucherNo}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "transferVoucherType",
            render: (transferVoucherType: string) => {
                const type = find(
                    TRANSFER_VOUCHER_TYPES,
                    (item) => item.id === transferVoucherType
                );
                return Messages[type?.label as any];
            },
        },
        {
            title: Messages.fromCustomer,
            dataIndex: "from",
            render: (customer) => <CustomerAvatarName customer={customer} />,
        },
        {
            title: Messages.toCustomer,
            dataIndex: "to",
            render: (customer) => <CustomerAvatarName customer={customer} />,
        },
        {
            title: Messages.totalCredit,
            dataIndex: "voucher",
            render: (voucher) => {
                const total = reduce(
                    voucher,
                    (sum, item) => sum + item.value,
                    0
                );
                return StringUtils.moneyThaiFormat(total);
            },
        },

        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={TRANSFER_VOUCHER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },

        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user) => (
                <UserAvatarName
                    user={user}
                    showInfo={{ showOPD: false, showEmployeeId: false }}
                />
            ),
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = mapTransferVoucherFilterToServer(
            filter,
            searchKeys
        );
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            search: searchRef.current ?? "",
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return TransferVoucherAPI.list(input);
    };

    const onClickAddNew = () => {
        navigate(Path.TRANSFER_VOUCHER_CREATE);
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allTransferVoucher}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
                customView={() => (
                    <Select
                        dataSource={SEARCH_TRANSFER_VOUCHER_KEYS}
                        onChange={(v) => {
                            setSearchKeys(v);
                        }}
                        getLabel={(i) => (Messages as any)[i.label]}
                        value={searchKeys}
                    />
                )}
            />

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <TransferVoucherTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default TransferVoucherTable;
