import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import { useListDoctorOrderForAdminLazyQuery } from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import CustomerName from "../../../common/avatar/CustomerName";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import TableAction from "../../../common/TableActions";
import { DOCTOR_ORDER_STATUSES } from "../../../constant/order";
import { mapDoctorOrderFilterToServer } from "../../../interfaces/doctor-order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import DoctorOrderTableFilterDrawer from "./DoctorOrderTableFilterDrawer";

const DoctorOrderTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const [getListDoctorOrder, { data }] =
        useListDoctorOrderForAdminLazyQuery();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "doctorOrderNo",
            width: "80px",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: item.id,
                    })}
                >
                    <div className="flex items-center">
                        <span>{`${name}`}</span>
                    </div>
                </AppLink>
            ),
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer, item) => <CustomerName user={customer} />,
        },
        {
            title: Messages.doctor,
            dataIndex: "doctor",
            render: (doctor, item) => (
                <UserAvatarName
                    user={doctor}
                    size="xx-small"
                    showInfo={{ showOPD: false, showEmployeeId: false }}
                />
            ),
        },
        {
            title: Messages.booking,
            dataIndex: "booking",
            render: (booking, item) => {
                return (
                    <AppLink
                        to={generatePath(Path.BOOKING_DETAIL, {
                            bookingId: item.id,
                        })}
                    >
                        <div className="">{booking?.bookingNo}</div>
                    </AppLink>
                );
            },
            width: "100px",
        },
        {
            title: Messages.branch,
            dataIndex: "booking",
            render: (booking, item) => {
                return <div className="">{booking?.branch?.name}</div>;
            },
            width: "80px",
        },
        {
            title: Messages.recommendedTreatmentQty,
            dataIndex: "services",
            align: "center",
            render: (item) => item?.length,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={DOCTOR_ORDER_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        {
            title: Messages.updatedAt,
            dataIndex: "updatedAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, data)
                        }
                    />
                );
            },
        },
    ];

    const onClickTableAction = (actionId: string, item: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.DOCTOR_ORDER_DETAIL, {
                        orderId: item.id,
                    })
                );
        }
    };

    const source = (pagingData: any) => {
        const filterParam = mapDoctorOrderFilterToServer(filter);
        return getListDoctorOrder({
            variables: {
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search: searchRef?.current ?? "",
                    sort: { createdAt: -1 },
                    ...filterParam,
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const onClickAddNew = () => {
        navigate(Path.QUOTATION_CREATE);
    };

    const onChangeSearch = (text: string) => {
        searchRef.current = text;
        tableRef.current.refresh();
    };

    const onSubmitSearch = () => {
        tableRef.current.refresh();
    };

    return (
        <div>
            <HeaderTable
                label={Messages.doctorOrder}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                // onSubmitSearch={onSubmitSearch}
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => {
                    return res?.data?.data?.data ?? [];
                }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
            />
            {openFilter && (
                <DoctorOrderTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default DoctorOrderTable;
