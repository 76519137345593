import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputTextSearch,
    TimeUtils,
    useFirstTime,
    Badge,
} from "d-react-components";
import { useFormik } from "formik";
import { debounce, find, forEach, isEmpty, join, map, unionBy } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { generatePath } from "react-router";
import {
    OrderStatus,
    useListVoucherInOrderLazyQuery,
} from "../../../api/hooks";
import AppLink from "../../../common/AppLink";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { PRODUCT_TYPE } from "../../../constant/product";
import { VOUCHER_TYPES } from "../../../constant/voucher";
import { OrderDetailContext } from "../../../context/order";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductSelect from "../../product/share/ProductSelect";
import ProductName from "../../quotation/share/ProductName";

interface IOrderVoucherFilterDrawer {
    open: boolean;
    onClose: () => void;
    value: any;
    onChange: (props: any) => void;
    productSource: any[];
}

const getFilterVoucherParams = (filter: any) => {
    const { type } = filter;
    return {
        type: !isEmpty(type) ? type : undefined,
        // orderProducts: !isEmpty(product)
        //     ? map(product, (i) => i?.id)
        //     : undefined,
    };
};

const OrderGeneratedVoucher = () => {
    const { order } = useContext(OrderDetailContext);
    const [listVoucherInOrder] = useListVoucherInOrderLazyQuery();
    const [isEmptyVouchers, setIsEmptyVouchers] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
    const [searchText, setSearchText] = useState();
    const { id: orderId } = order || {};
    const searchRef = useRef<string>("");
    const [openFilter, setOpenFilter] = useState<{
        open: boolean;
        value?: any;
    }>({ open: false });

    // useEffect(() => {
    //     if (isFirstTime) return;
    //     refreshTable();
    // }, [openFilter?.value]);

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        // refreshTable();
        setSearchText(text);
    }, 500);

    const renderFilter = () => {
        const button = (
            <Button
                className="ml-3"
                iconName="filter_list"
                onClick={(e) => setOpenFilter((v) => ({ ...v, open: true }))}
            />
        );
        if (!isEmpty(openFilter?.value)) {
            return <Badge>{button}</Badge>;
        }
        return button;
    };

    const renderExpandableProductTable = (
        product: any,
        index: any,
        indent: any,
        expanded: boolean
    ) => {
        return expanded ? (
            <>
                <VoucherTable
                    search={searchText}
                    product={product}
                    openFilter={openFilter}
                    orderId={orderId}
                />
            </>
        ) : null;
    };

    const expandableTable = {
        expandedRowRender: renderExpandableProductTable,
        rowExpandable: (record: any) => true,
        defaultExpandAllRows: false,
        expandedRowKeys,
        onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
    };

    const productSource = useMemo(() => {
        const alls: any[] = [];
        forEach(order?.products, (product: any) => {
            if (product?.groups?.length > 0) {
                alls.push(
                    ...product?.groups?.map((p: any) => ({
                        ...p,
                        id: p?.product?.id,
                        isService: true,
                    }))
                );
            } else {
                alls.push({ ...product, id: product?.product?.id });
            }
        });
        return alls;
    }, [order]);

    const productColumns = useMemo(() => {
        return [
            {
                title: Messages.no,
                dataIndex: "no",
                render: (data: any, item: any, index: any) => index + 1,
            },
            {
                title: Messages.name,
                dataIndex: "product",
                render: (data: any, item: any) => (
                    <ProductName
                        item={{
                            ...data,
                            doctorOrder: item?.doctorOrder,
                            isGift: item?.isGift,
                        }}
                        // addOneAfterName={() => renderSubName(item, false)}
                    />
                ),
            },
        ];
    }, []);

    const filteredProductSource = useMemo(() => {
        const { product } = openFilter?.value ?? {};
        if (product?.length) {
            const productIds = map(product, (i) => i?.id);
            return (
                productSource?.filter((p: any) => {
                    return productIds.includes(p.id);
                }) ?? []
            );
        }
        return productSource ?? [];
    }, [productSource, openFilter.value]);

    useEffect(() => {
        if (!order?.id) {
            return;
        }
        setIsEmptyVouchers(false);
        listVoucherInOrder({
            variables: {
                orderId: orderId as string,
                paginate: {
                    page: 1,
                    limit: 1,
                },
            },
            fetchPolicy: "no-cache",
        }).then(
            (resp) => {
                if (resp.data?.data?.data?.length === 0) {
                    setIsEmptyVouchers(true);
                }
            },
            (err) => err
        );
    }, [order?.status]);

    if (isEmptyVouchers) {
        return null;
    }

    return (
        <div className="border mt-3 p-4">
            <div className="flex-row-between-center">
                <h5>{Messages.generatedVouchers}</h5>
            </div>
            <div className="flex-center-y mt-3 mb-2">
                <InputTextSearch
                    onChange={(e: any) => onChangeSearch(e?.target?.value)}
                />
                {renderFilter()}
            </div>

            <AwesomeTableComponent
                tableLayout="auto"
                dataSource={filteredProductSource}
                columns={productColumns}
                expandable={expandableTable}
                pagination={false}
                rowKey={(item) => item.id}
                bordered={false}
            />
            {openFilter?.open && (
                <OrderVoucherFilterDrawer
                    open={openFilter?.open}
                    onClose={() =>
                        setOpenFilter((v) => ({ ...v, open: false }))
                    }
                    onChange={(v) => setOpenFilter({ open: false, value: v })}
                    value={openFilter?.value}
                    productSource={productSource}
                />
            )}
        </div>
    );
};

const VoucherTable = ({ product, openFilter, orderId, search }: any) => {
    const tableRef = useRef<any>(null);
    const [listVoucherInOrder] = useListVoucherInOrderLazyQuery();

    const source = (pagingData: any) => {
        if (!orderId) {
            return Promise.resolve();
        }
        let params = {};
        if (openFilter?.value) {
            params = getFilterVoucherParams(openFilter.value);
        }
        return listVoucherInOrder({
            variables: {
                orderId,
                paginate: {
                    page: pagingData?.pageIndex,
                    limit: pagingData?.pageSize,
                    search,
                    ...(product.isService
                        ? { products: [product.id] }
                        : { orderProducts: [product.id] }),
                    ...params,
                },
            },
            fetchPolicy: "no-cache",
        });
    };

    const refreshTable = () => {
        return tableRef.current && tableRef.current.refresh();
    };

    const columns: IColumnsProps = [
        {
            title: Messages.productFromSO,
            dataIndex: "orderProduct",
            render: (orderProduct, voucher) => {
                if (isEmpty(orderProduct)) {
                    return "N/A";
                }
                const { services } = voucher;
                let childProps: any = <div />;

                if (services?.length > 0) {
                    childProps = (
                        <div className="mt-1 flex-center-y">
                            {map(services, (iServices) => {
                                return (
                                    <AppLink
                                        to={generatePath(Path.PRODUCT_DETAIL, {
                                            productId: iServices.id,
                                        })}
                                    >
                                        {iServices?.name}
                                    </AppLink>
                                );
                            })}
                        </div>
                    );
                }
                return (
                    <div>
                        <ProductName item={orderProduct} />
                        {orderProduct?.typeOfProduct !==
                            PRODUCT_TYPE.PREMIUM_SERVICE && childProps}
                    </div>
                );
            },
        },
        {
            title: Messages.voucherCode,
            dataIndex: "code",
            render: (voucherNo, voucher) => (
                <AppLink
                    to={generatePath(Path.VOUCHER_DETAIL, {
                        voucherId: voucher.id,
                    })}
                >
                    {voucherNo}
                </AppLink>
            ),
        },
        {
            title: Messages.type,
            dataIndex: "type",
            render: (data) => {
                const voucherType = find(VOUCHER_TYPES, { id: data });
                return Messages[voucherType?.label as string];
            },
        },
        {
            title: Messages.effectiveDate,
            dataIndex: "effectiveDate",
            render: (data) => (data ? TimeUtils.toDateTime(data) : "N/A"),
        },
        {
            title: Messages.expiryDate,
            dataIndex: "expiryDate",
            render: (data) =>
                data ? TimeUtils.toDateTime(data) : Messages.never,
        },
    ];

    useEffect(() => {
        refreshTable();
    }, [search, openFilter.value]);

    return (
        <div>
            <AwesomeTableComponent
                className="mt-3"
                key={product.id}
                ref={tableRef}
                columns={columns}
                source={source}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
        </div>
    );
};

export default OrderGeneratedVoucher;

const OrderVoucherFilterDrawer = ({
    open,
    onClose,
    value,
    onChange,
    productSource,
}: IOrderVoucherFilterDrawer) => {
    const classNameInput = "mt-3 col-sm-12";
    const filterForm = useFormik<any>({
        initialValues: value ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onChange(values);
        },
    });
    const { values, errors, setFieldValue, setValues } = filterForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
            cancelText={Messages.clearAll}
            cancelButtonProps={{ onClick: () => setValues({}) }}
        >
            <InputSelectForm
                multiple
                dataSource={VOUCHER_TYPES}
                keyData="type"
                form={filterForm}
                className={classNameInput}
            />
            <ProductSelect
                className={`${classNameInput}`}
                classNameSelect={` pb-1`}
                dataSource={unionBy(
                    map(productSource, (item) => ({
                        ...item,
                        //@ts-ignore
                        ...item?.product,
                    })),
                    (item) => item?.id
                )}
                value={values?.product}
                onChange={(values: any) => {
                    setFieldValue("product", values);
                }}
                title={Messages.product}
                error={errors?.product}
                multiple
            />
        </Drawer>
    );
};
