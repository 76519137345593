import React, { useContext, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import {
    Button,
    DialogManager,
    Notifications,
    Progress,
    TabBar,
} from "d-react-components";
import { useFormik } from "formik";
import Messages from "../../../languages/Messages";
import MaterialRequestDetailGeneralInformation from "./MaterialRequestDetailGeneralInformation";
import MaterialRequestDetailItems from "./MaterialRequestDetailItems";
import MaterialRequestDetailQuotation from "./MaterialRequestDetailQuotation";
import MaterialRequestDetailPO from "./MaterialRequestDetailPO";
import MaterialRequestDetailActivity from "./MaterialRequestDetailActivity";
import {
    MaterialPurchaseRequestStatus,
    useApprovalMaterialPurchaseRequestMutation,
    useCancelMaterialPurchaseRequestMutation,
    useDetailMaterialPurchaseRequestQuery,
} from "../../../api/hooks";
import { MaterialRequestContext } from "../../../context/material";
import ActionMenu, { ActionButton } from "../../../common/ActionButton";
import MaterialRequestDetailSummaryView from "./MaterialRequestDetailSummaryView";
import Drawer, { CustomDrawerProps } from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import Path from "../../Path";

const TABS = [
    {
        id: "generalInformation",
        label: Messages.generalInformation,
        component: <MaterialRequestDetailGeneralInformation />,
    },
    {
        id: "items",
        label: Messages.materialItemsList,
        component: <MaterialRequestDetailItems />,
    },
    {
        id: "quotation",
        label: Messages.quotation,
        component: <MaterialRequestDetailQuotation />,
    },
    {
        id: "po",
        label: Messages.po,
        component: <MaterialRequestDetailPO />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <MaterialRequestDetailActivity />,
    },
];

const MaterialRequestDetail = () => {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState(TABS[0]);
    const [showApproveDrawer, setShowApproveDrawer] = useState(false);
    const [showCancelDrawer, setShowCancelDrawer] = useState(false);

    const { data, refetch } = useDetailMaterialPurchaseRequestQuery({
        variables: {
            id: requestId as string,
        },
        skip: !requestId,
        fetchPolicy: "no-cache",
    });
    const onClickAction = () => {};

    const request = data?.data?.data ?? {};

    return (
        <MaterialRequestContext.Provider
            value={{
                //@ts-ignore
                request,
                reloadRequest: refetch,
            }}
        >
            <div className="px-4 py-3 bg-white flex border-b items-center">
                <div className="flex flex-1 align-items-center">
                    <h5 className="text-primary mr-3">{`${request?.materialPurchaseRequestNo}`}</h5>
                </div>
                <div className="flex items-center">
                    {request.status ===
                        MaterialPurchaseRequestStatus.Approved && (
                        <ActionButton
                            onClick={() =>
                                navigate(generatePath(Path.MATERIAL_ORDER_CREATE, {
                                    requestId: request.id,
                                }))
                            }
                        >
                            {Messages.createMaterialOrder}
                        </ActionButton>
                    )}
                    {/* <ActionMenu dataSource={[]} onClick={onClickAction} /> */}
                </div>
            </div>
            <div className="px-4 py-3">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <MaterialRequestDetailSummaryView />
                    </div>
                    <div className="col-span-3">
                        {request.status ===
                            MaterialPurchaseRequestStatus.Pending && (
                            <div className="p-4 bg-primary mb-2">
                                <h5 className="text-white mb-2">
                                    {Messages.purchaseRequestApproval}
                                </h5>
                                <p className="text-white text-sm mb-3">
                                    {Messages.attentionApprovalRequest}
                                </p>
                                <div className="flex">
                                    <Button
                                        size="small"
                                        className="bg-white mr-2 text-primary"
                                        onClick={() =>
                                            setShowApproveDrawer(true)
                                        }
                                    >
                                        {Messages.approve}
                                    </Button>
                                    <Button
                                        size="small"
                                        className="bg-transparent mr-2 text-white border-white border"
                                        onClick={() =>
                                            setShowCancelDrawer(true)
                                        }
                                    >
                                        {Messages.cancel}
                                    </Button>
                                </div>
                            </div>
                        )}

                        <TabBar
                            dataSource={TABS}
                            onChange={setTab as any}
                            value={tab}
                        />

                        {tab.component}
                    </div>
                </div>
            </div>
            {showApproveDrawer && (
                <ApproveCancelRequestDrawer
                    open={showApproveDrawer}
                    onClose={() => setShowApproveDrawer(false)}
                    type="approve"
                />
            )}
            {showCancelDrawer && (
                <ApproveCancelRequestDrawer
                    open={showCancelDrawer}
                    onClose={() => setShowCancelDrawer(false)}
                    type="cancel"
                />
            )}
        </MaterialRequestContext.Provider>
    );
};

const ApproveCancelRequestDrawer = ({
    open,
    onClose,
    type,
}: CustomDrawerProps) => {
    const [approvePurchaseRequest] =
        useApprovalMaterialPurchaseRequestMutation();
    const [canPurchaseRequest] = useCancelMaterialPurchaseRequestMutation();
    const { request, reloadRequest } = useContext(MaterialRequestContext);

    const approveForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: QuotationCreateSchema,
        onSubmit: (values: any) => {
            Progress.show(
                {
                    method:
                        type === "approve"
                            ? approvePurchaseRequest
                            : canPurchaseRequest,
                    params: [
                        {
                            variables: {
                                id: request?.id,
                                remark: values?.remark ?? "",
                            },
                        },
                    ],
                },
                (res: any) => {
                    Notifications.showSuccess(
                        type === "approve"
                            ? Messages.approveSuccessfully
                            : Messages.cancelSuccess
                    );
                    onClose();
                    reloadRequest();
                }
            );
        },
    });
    const classNameInput = "mb-3";
    const { handleSubmit } = approveForm;

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={
                type === "approve"
                    ? Messages.approvePurchaseRequest
                    : Messages.cancelPurchaseRequest
            }
            onSave={() => handleSubmit()}
        >
            <div className="p-4">
                <InputTextForm
                    form={approveForm}
                    keyData="remark"
                    className={classNameInput}
                    label={Messages.remark}
                    multiple
                />
            </div>
        </Drawer>
    );
};

export default MaterialRequestDetail;
