import { gql } from "@apollo/client";
import { mapTreatmentFromServer } from "../../interfaces/treatment";
import API from "../API";
import { F_NOTE, F_NOTES } from "../fragments/app";
import {
    F_TREATMENT,
    F_TREATMENTS,
    F_TREATMENT_JOB_COST_METRIC,
} from "../fragments/treatment";
import { F_MATERIAL_BASIC } from "../fragments/material";

export const getTreatmentDetail = gql`
    ${F_TREATMENT}
    ${F_TREATMENT_JOB_COST_METRIC}
    query detailTreatmentForAdmin($id: String!) {
        data: detailTreatmentForAdmin(id: $id) {
            data {
                ...F_TREATMENT
                jobCostMetric {
                    ...F_TREATMENT_JOB_COST_METRIC
                }
                jobCostAssessment
            }
        }
    }
`;

export const getTreatmentList = gql`
    ${F_TREATMENTS}
    query listTreatmentForAdmin($input: AdminTreatmentPaginateRequest!) {
        data: listTreatmentForAdmin(paginate: $input) {
            ...F_TREATMENTS
        }
    }
`;

export const createTreatment = gql`
    ${F_TREATMENT}
    mutation createTreatment($payload: TreatmentCreateRequest!) {
        data: createTreatmentForAdmin(payload: $payload) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const updateTreatment = gql`
    ${F_TREATMENT}
    mutation updateTreatmentForAdmin(
        $treatmentId: String!
        $input: TreatmentUpdateRequest!
    ) {
        data: updateTreatmentForAdmin(id: $treatmentId, payload: $input) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const updateTreatmentDoctor = gql`
    ${F_TREATMENT}
    mutation updateTreatmentDoctorForAdmin(
        $treatmentId: String!
        $doctorId: String!
        $schedule: ScheduleTimeRequest!
    ) {
        data: updateTreatmentDoctorForAdmin(
            id: $treatmentId
            doctorId: $doctorId
            schedule: $schedule
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const addTreatmentPic = gql`
    ${F_TREATMENT}
    ${F_TREATMENT_JOB_COST_METRIC}
    mutation addTreatmentPic(
        $treatmentId: String!
        $picId: String!
        $payload: TreatmentUserUpdateRequest!
    ) {
        data: addTreatmentPICForAdmin(
            id: $treatmentId
            picId: $picId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
                jobCostMetric {
                    ...F_TREATMENT_JOB_COST_METRIC
                }
                jobCostAssessment
            }
        }
    }
`;

export const updateTreatmentPic = gql`
    ${F_TREATMENT}
    ${F_TREATMENT_JOB_COST_METRIC}
    mutation updateTreatmentPic(
        $treatmentId: String!
        $payload: [TreatmentUserUpdateRequest!]!
    ) {
        data: updateTreatmentPICForAdmin(id: $treatmentId, payload: $payload) {
            data {
                ...F_TREATMENT
                jobCostMetric {
                    ...F_TREATMENT_JOB_COST_METRIC
                }
                jobCostAssessment
            }
        }
    }
`;

export const removeTreatmentPic = gql`
    ${F_TREATMENT}
    ${F_TREATMENT_JOB_COST_METRIC}
    mutation removeTreatmentPICForAdmin(
        $treatmentId: String!
        $picId: String!
    ) {
        data: removeTreatmentPICForAdmin(id: $treatmentId, picId: $picId) {
            data {
                ...F_TREATMENT
                jobCostMetric {
                    ...F_TREATMENT_JOB_COST_METRIC
                }
                jobCostAssessment
            }
        }
    }
`;

export const addTreatmentMaterial = gql`
    ${F_TREATMENT}
    mutation addTreatmentMaterial(
        $treatmentId: String!
        $payload: [TreatmentUpdateMaterialRequest!]!
    ) {
        data: addTreatmentMaterialForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const updateTreatmentMaterial = gql`
    ${F_TREATMENT}
    mutation updateTreatmentMaterial(
        $treatmentId: String!
        $payload: TreatmentUpdateMaterialRequest!
    ) {
        data: updateTreatmentMaterialForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const removeTreatmentMaterial = gql`
    ${F_TREATMENT}
    mutation removeTreatmentMaterial($id: String!, $materialId: String!) {
        data: removeTreatmentMaterialForAdmin(
            id: $id
            materialId: $materialId
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const addTreatmentMedicine = gql`
    ${F_TREATMENT}
    mutation addTreatmentMedicine(
        $treatmentId: String!
        $payload: [TreatmentUpdateProductRequest!]!
    ) {
        data: addTreatmentMedicineForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const updateTreatmentMedicine = gql`
    ${F_TREATMENT}
    mutation updateTreatmentMedicine(
        $treatmentId: String!
        $payload: TreatmentUpdateProductRequest!
    ) {
        data: updateTreatmentMedicineForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const removeTreatmentMedicine = gql`
    ${F_TREATMENT}
    mutation removeTreatmentMedicine(
        $treatmentId: String!
        $productId: String!
    ) {
        data: removeTreatmentMedicineForAdmin(
            id: $treatmentId
            productId: $productId
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const addTreatmentAddonService = gql`
    ${F_TREATMENT}
    mutation addTreatmentAddonService(
        $treatmentId: String!
        $payload: [TreatmentUpdateServiceRequest!]!
    ) {
        data: addTreatmentAddOnServiceForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const updateTreatmentAddonService = gql`
    ${F_TREATMENT}
    mutation updateTreatmentAddonService(
        $treatmentId: String!
        $payload: TreatmentUpdateServiceRequest!
    ) {
        data: updateTreatmentAddOnServiceForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const removeTreatmentAddonService = gql`
    ${F_TREATMENT}
    mutation removeTreatmentAddOnService(
        $treatmentId: String!
        $productId: String!
    ) {
        data: removeTreatmentAddOnServiceForAdmin(
            id: $treatmentId
            productId: $productId
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const addTreatmentEquipment = gql`
    ${F_TREATMENT}
    mutation addTreatmentEquipment(
        $treatmentId: String!
        $equipmentId: String!
        $schedule: ScheduleTimeRequest!
    ) {
        data: addTreatmentEquipmentForAdmin(
            id: $treatmentId
            equipmentId: $equipmentId
            schedule: $schedule
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const removeTreatmentEquipment = gql`
    ${F_TREATMENT}
    mutation removeTreatmentEquipment(
        $treatmentId: String!
        $equipmentId: String!
    ) {
        data: removeTreatmentEquipmentForAdmin(
            id: $treatmentId
            equipmentId: $equipmentId
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const addTreatmentBeforeAfter = gql`
    ${F_TREATMENT}
    mutation addTreatmentBeforeAfter(
        $treatmentId: String!
        $payload: TreatmentBeforeAfterCreateRequest!
    ) {
        data: addTreatmentBeforeAfterForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;
export const updateTreatmentBeforeAfter = gql`
    ${F_TREATMENT}
    mutation updateTreatmentBeforeAfter(
        $treatmentId: String!
        $beforeAfterId: String!
        $payload: TreatmentBeforeAfterUpdateRequest!
    ) {
        data: updateTreatmentBeforeAfterForAdmin(
            id: $treatmentId
            beforeAfterId: $beforeAfterId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const getTreatmentNotes = gql`
    ${F_NOTES}
    query listTreatmentNotesForAdmin(
        $treatmentId: String!
        $paginate: NotePaginateRequest!
    ) {
        data: listTreatmentNotesForAdmin(
            treatmentId: $treatmentId
            paginate: $paginate
        ) {
            ...F_NOTES
        }
    }
`;

export const getListDetailTreatmentMaterial = gql`
    ${F_MATERIAL_BASIC}
    query listDetailTreatmentMaterialForAdmin($id: String!) {
        data: listDetailTreatmentMaterialForAdmin(id: $id) {
            data {
                id
                updatedAt
                createdAt
                quantity
                price
                lotNo
                material {
                    ...F_MATERIAL_BASIC
                }
                materialPurchaseDelivery {
                    id
                    deliveryNo
                    status
                    receiptNo
                    lotNo
                    materialPurchase {
                        id
                        updatedAt
                        createdAt
                        materialPurchaseNo
                        status
                        materialPurchaseOrderStatus
                        subtotal
                        total
                    }
                }
            }
        }
    }
`;

export const createTreatmentNote = gql`
    ${F_NOTE}
    mutation createTreatmentNoteForAdmin(
        $treatmentId: String!
        $payload: NoteCreateRequest!
    ) {
        data: createTreatmentNoteForAdmin(
            treatmentId: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_NOTE
            }
        }
    }
`;

export const updateTreatmentNote = gql`
    ${F_NOTE}
    mutation updateTreatmentNoteForAdmin(
        $noteId: String!
        $payload: NoteUpdateRequest!
    ) {
        data: updateTreatmentNoteForAdmin(noteId: $noteId, payload: $payload) {
            data {
                ...F_NOTE
            }
        }
    }
`;

export const removeTreatmentNote = gql`
    ${F_NOTE}
    mutation removeTreatmentNote($noteId: String!) {
        data: removeTreatmentNoteForAdmin(noteId: $noteId) {
            data {
                ...F_NOTE
            }
        }
    }
`;

export const startTreatment = gql`
    ${F_TREATMENT}
    mutation startTreatment(
        $treatmentId: String!
        $payload: TreatmentConfirmationRequest!
    ) {
        data: startTreatmentForAdmin(id: $treatmentId, payload: $payload) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const completeTreatment = gql`
    ${F_TREATMENT}
    mutation completeTreatment(
        $treatmentId: String!
        $payload: TreatmentConfirmationRequest!
    ) {
        data: completeTreatmentForAdmin(id: $treatmentId, payload: $payload) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

export const updateAutoJobCostAssessment = gql`
    ${F_TREATMENT}
    ${F_TREATMENT_JOB_COST_METRIC}
    mutation updateTreatmentAssessment(
        $treatmentId: String!
        $payload: [TreatmentUserJobCostUpdateRequest!]!
    ) {
        data: updateTreatmentAssessmentForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
                jobCostMetric {
                    ...F_TREATMENT_JOB_COST_METRIC
                }
                jobCostAssessment
                manualJobCostAssessment
            }
        }
    }
`;

export const updateManualJobCostAssessment = gql`
    ${F_TREATMENT}
    ${F_TREATMENT_JOB_COST_METRIC}
    mutation updateManualJobCostAssessment(
        $treatmentId: String!
        $payload: [TreatmentUserJobCostUpdateRequest!]!
    ) {
        data: manualUpdateTreatmentAssessmentForAdmin(
            id: $treatmentId
            payload: $payload
        ) {
            data {
                ...F_TREATMENT
                jobCostMetric {
                    ...F_TREATMENT_JOB_COST_METRIC
                }
                jobCostAssessment
                manualJobCostAssessment
            }
        }
    }
`;

export const cancelTreatment = gql`
    ${F_TREATMENT}
    mutation cancelTreatmentForAdmin($id: String!, $remark: String) {
        data: cancelTreatmentForAdmin(id: $id, remark: $remark) {
            data {
                ...F_TREATMENT
            }
        }
    }
`;

const treatmentConverter = {
    fromServer: (res: any) => {
        const detail = mapTreatmentFromServer(res?.data?.data?.data ?? {});
        return detail;
    },
};

const materialConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? {};
        return detail;
    },
};

const treatmentsConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const TreatmentAPI = {
    list: async (input: any) =>
        API.withConverter(treatmentsConverter).query({
            query: getTreatmentList,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(treatmentConverter).query({
            query: getTreatmentDetail,
            variables: { id },
        }),

    getListMaterialDetail: async (id: any) =>
        API.query({
            query: getListDetailTreatmentMaterial,
            variables: { id },
        }),

    create: async (payload: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: createTreatment,
            variables: { payload },
        }),

    update: async (treatmentId: string, input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatment,
            variables: { treatmentId, input },
        }),

    updateDoctor: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatmentDoctor,
            variables: input,
        }),

    addPic: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: addTreatmentPic,
            variables: input,
        }),

    updatePic: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatmentPic,
            variables: input,
        }),

    removePic: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: removeTreatmentPic,
            variables: input,
        }),

    addMedicine: async (treatmentId: string, payload: any[]) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: addTreatmentMedicine,
            variables: { treatmentId, payload },
        }),

    addMaterial: async (treatmentId: string, payload: any[]) =>
        API.withConverter(materialConverter).mutate({
            mutation: addTreatmentMaterial,
            variables: { treatmentId, payload },
        }),

    updateMedicine: async (treatmentId: string, payload: any[]) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatmentMedicine,
            variables: { treatmentId, payload },
        }),

    removeMedicine: async (treatmentId: string, productId: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: removeTreatmentMedicine,
            variables: { treatmentId, productId },
        }),

    updateMaterial: async (treatmentId: string, payload: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatmentMaterial,
            variables: { treatmentId, payload },
        }),

    removeMaterial: async (id: string, materialId: string) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: removeTreatmentMaterial,
            variables: { id, materialId },
        }),

    addEquipment: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: addTreatmentEquipment,
            variables: input,
        }),

    removeEquipment: async (treatmentId: string, equipmentId: string) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: removeTreatmentEquipment,
            variables: { treatmentId, equipmentId },
        }),

    addBeforeAfter: async (treatmentId: string, payload: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: addTreatmentBeforeAfter,
            variables: { treatmentId, payload },
        }),

    updateBeforeAfter: async (
        treatmentId: string,
        beforeAfterId: string,
        payload: any
    ) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatmentBeforeAfter,
            variables: { treatmentId, beforeAfterId, payload },
        }),

    // Note
    noteList: async (treatmentId: string, paginate: any) =>
        API.query({
            query: getTreatmentNotes,
            variables: { treatmentId, paginate },
            fetchPolicy: "no-cache",
        }),

    createNote: async (treatmentId: string, payload: any) =>
        API.mutate({
            mutation: createTreatmentNote,
            variables: { treatmentId, payload },
        }),

    updateNote: async (noteId: string, payload: any) =>
        API.mutate({
            mutation: updateTreatmentNote,
            variables: { noteId, payload },
        }),

    removeNote: async (noteId: string) =>
        API.mutate({
            mutation: removeTreatmentNote,
            variables: { noteId },
        }),

    start: async (treatmentId: string, payload: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: startTreatment,
            variables: { treatmentId, payload },
        }),

    complete: async (treatmentId: string, payload: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: completeTreatment,
            variables: { treatmentId, payload },
        }),

    cancel: async (id: string, remark?: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: cancelTreatment,
            variables: { id, remark },
        }),

    updateAutoJobCostAssessment: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateAutoJobCostAssessment,
            variables: input,
        }),

    updateManualJobCostAssessment: async (input: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateManualJobCostAssessment,
            variables: input,
        }),

    addAddonService: async (treatmentId: string, payload: any[]) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: addTreatmentAddonService,
            variables: { treatmentId, payload },
        }),

    updateAddonService: async (treatmentId: string, payload: any[]) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: updateTreatmentAddonService,
            variables: { treatmentId, payload },
        }),

    removeAddonService: async (treatmentId: string, productId: any) =>
        API.withConverter(treatmentConverter).mutate({
            mutation: removeTreatmentAddonService,
            variables: { treatmentId, productId },
        }),
};

export default TreatmentAPI;
