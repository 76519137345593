import { Checkbox, Icon, ViewTextError } from "d-react-components";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import { BookingCreateContext } from "../../../context/booking";
import { BookingGuestSchema } from "../../../formschema/booking";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import CustomerSelect from "../../customer/share/CustomerSelect";
import CustomerSolidSelect from "../../customer/share/CustomerSolidSelect";

interface IGuestInfo {
    firstName: string;
    lastName: string;
    // nickname: string;
    phone: string;
    email: string;
}

interface IGuestVisitInfoDrawer<T> {
    open: boolean;
    guestInfo?: T;
    onSave: (value: T) => void;
    onClose: () => void;
}

const GuestVisitInfoDrawer = ({
    guestInfo,
    open,
    onClose,
    onSave,
}: IGuestVisitInfoDrawer<IGuestInfo>) => {
    const guestForm = useFormik<any>({
        initialValues: guestInfo ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: BookingGuestSchema,
        onSubmit: (values: any) => {
            onSave(values);
        },
    });

    const classNameInput = "col-12 mt-3";
    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.guestInfo}
            onSave={() => guestForm.handleSubmit()}
        >
            <div className="row p-3">
                <InputTextForm
                    form={guestForm}
                    keyData="firstName"
                    className={classNameInput}
                />
                <InputTextForm
                    form={guestForm}
                    keyData="lastName"
                    className={classNameInput}
                />
                {/* <InputTextForm
                    form={guestForm}
                    keyData="nickname"
                    className={classNameInput}
                /> */}
                <InputTextForm
                    form={guestForm}
                    keyData="phone"
                    className={classNameInput}
                />
                <InputTextForm
                    form={guestForm}
                    keyData="email"
                    className={classNameInput}
                />
            </div>
        </Drawer>
    );
};

const BookingCreateCustomer = () => {
    const { bookingForm } = useContext(BookingCreateContext);
    const [openGuestInfo, setOpenGuestInfo] = useState(false);
    const [openUpdateGuest, setOpenUpdateGuest] = useState(false);
    const formValues = bookingForm?.values;

    const onCheckGuestVisit = () => {
        if (!formValues?.isGuestVisit) {
            setOpenGuestInfo(true);
        }
        bookingForm.setFieldValue("isGuestVisit", !formValues?.isGuestVisit);
    };

    const onUpdateGuestInfo = (guestInfo: IGuestInfo) => {
        bookingForm.setFieldValue("guestInfo", guestInfo);
        setOpenGuestInfo(false);
        setOpenUpdateGuest(false);
    };

    const renderGuestInfo = () => {
        if (!formValues.isGuestVisit || !formValues.guestInfo) return <div />;
        const { firstName, lastName, email, phone } =
            formValues.guestInfo as IGuestInfo;
        return (
            <div className="w-100 mt-3 bg-primary flex-row-between-center px-3 py-2">
                <div className=" flex-column">
                    <span className="block text-white font-weight-bold">
                        {`${firstName} ${lastName}`}
                    </span>
                    <span className="block text-white opacity-75">{phone}</span>
                    <span className="block text-white opacity-75">{email}</span>
                </div>
                <Icon
                    name="edit"
                    className="text-white cursor-pointer"
                    onClick={() => setOpenUpdateGuest(true)}
                />
            </div>
        );
    };

    return (
        <div className="mt-3">
            <CustomerSolidSelect
                value={formValues?.customer}
                onChange={(value) =>
                    bookingForm?.setFieldValue("customer", value)
                }
                error={bookingForm?.errors?.customer}
            />
            {/* <CustomerSelect
                value={formValues?.customer}
                onChange={(value) =>
                    bookingForm?.setFieldValue("customer", value)
                }
                error={bookingForm?.errors?.customer}
            />
            {renderCustomerSelected()} */}
            <Checkbox
                label={Messages.thisIsGuestVisit}
                onChange={onCheckGuestVisit}
                className="mt-3"
                checked={formValues.isGuestVisit}
            />
            <ViewTextError error={bookingForm?.errors?.doctor} />

            {renderGuestInfo()}
            {openGuestInfo && (
                <GuestVisitInfoDrawer
                    open={openGuestInfo}
                    onClose={() => {
                        bookingForm.setFieldValue("isGuestVisit", false);
                        setOpenGuestInfo(false);
                    }}
                    onSave={onUpdateGuestInfo}
                />
            )}
            {openUpdateGuest && (
                <GuestVisitInfoDrawer
                    open={openUpdateGuest}
                    onClose={() => setOpenUpdateGuest(false)}
                    guestInfo={formValues?.guestInfo}
                    onSave={onUpdateGuestInfo}
                />
            )}
        </div>
    );
};

export default BookingCreateCustomer;
