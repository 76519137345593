import { gql } from "@apollo/client";
import {
    F_CATEGORY_MATERIAL,
    F_CATEGORY_MATERIALS,
    F_CATEGORY_MATERIAL_SEARCH,
    F_MATERIAL,
    F_MATERIALS,
    F_MATERIAL_ORDER,
    F_MATERIAL_ORDER_DETAIL,
    F_MATERIAL_ORDER_SEARCH,
    F_MATERIAL_PURCHASE_DELIVERY,
    F_MATERIAL_PURCHASE_DELIVERY_MATERIAL,
    F_MATERIAL_PURCHASE_PAYMENT,
    F_MATERIAL_PURCHASE_QUOTATION,
    F_MATERIAL_PURCHASE_REQUEST,
    F_MATERIAL_PURCHASE_SEARCH,
    F_MATERIAL_REQUEST_SEARCH,
    F_MATERIAL_SEARCH,
} from "../fragments/material";
import API from "../API";
import { ListAdminMaterialPurchaseRequestRequest } from "../hooks";
import { F_PAGINATE } from "../fragments/app";
import { F_MATERIAL_ADJUST_REQUEST } from "../fragments/material-inventory";

export const listCategoryMaterial = gql`
    ${F_CATEGORY_MATERIALS}
    query listCategoryMaterial($paginate: ListAdminCategoryMaterialRequest!) {
        data: listCategoryMaterialForAdmin(paginate: $paginate) {
            ...F_CATEGORY_MATERIALS
        }
    }
`;

export const createCategoryMaterial = gql`
    mutation createCategoryMaterial(
        $payload: CreateAdminCategoryMaterialRequest!
    ) {
        data: createCategoryMaterialForAdmin(payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const updateCategoryMaterial = gql`
    ${F_CATEGORY_MATERIAL}
    mutation updateCategoryMaterial(
        $id: String!
        $payload: UpdateAdminCategoryMaterialRequest!
    ) {
        data: updateCategoryMaterialForAdmin(id: $id, payload: $payload) {
            data {
                ...F_CATEGORY_MATERIAL
            }
        }
    }
`;

export const getCategoryMaterialDetail = gql`
    ${F_CATEGORY_MATERIAL}
    query detailCategoryMaterial($id: String!) {
        data: detailCategoryMaterialForAdmin(id: $id) {
            data {
                ...F_CATEGORY_MATERIAL
            }
        }
    }
`;

export const listMaterial = gql`
    ${F_MATERIALS}
    query listMaterial($paginate: ListAdminMaterialRequest!) {
        data: listMaterialForAdmin(paginate: $paginate) {
            ...F_MATERIALS
        }
    }
`;

export const createMaterial = gql`
    mutation createMaterial($payload: CreateAdminMaterialRequest!) {
        data: createMaterialForAdmin(payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const updateMaterial = gql`
    ${F_MATERIAL}
    mutation updateMaterial(
        $id: String!
        $payload: UpdateAdminMaterialRequest!
    ) {
        data: updateMaterialForAdmin(id: $id, payload: $payload) {
            data {
                ...F_MATERIAL
            }
        }
    }
`;

export const getMaterialDetail = gql`
    ${F_MATERIAL}
    query detailMaterial($id: String!) {
        data: detailMaterialForAdmin(id: $id) {
            data {
                ...F_MATERIAL
            }
        }
    }
`;

export const searchMaterial = gql`
    ${F_MATERIAL_SEARCH}
    ${F_PAGINATE}
    query searchMaterial($paginate: SearchAdminMaterialRequest!) {
        data: searchMaterialForAdmin(paginate: $paginate) {
            data {
                ...F_MATERIAL_SEARCH
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const searchCategoryMaterial = gql`
    ${F_CATEGORY_MATERIAL_SEARCH}
    query searchCategoryMaterial(
        $paginate: SearchAdminCategoryMaterialRequest!
    ) {
        data: searchCategoryMaterialForAdmin(paginate: $paginate) {
            data {
                ...F_CATEGORY_MATERIAL_SEARCH
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const searchMaterialPurchaseRequest = gql`
    ${F_MATERIAL_REQUEST_SEARCH}
    query searchMaterialPurchaseRequest(
        $paginate: SearchAdminMaterialPurchaseRequestRequest!
    ) {
        data: searchMaterialPurchaseRequestForAdmin(paginate: $paginate) {
            data {
                ...F_MATERIAL_REQUEST_SEARCH
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const listMaterialPurchaseRequest = gql`
    ${F_MATERIAL_PURCHASE_REQUEST}
    query listMaterialPurchaseRequest(
        $paginate: ListAdminMaterialPurchaseRequestRequest!
    ) {
        data: listMaterialPurchaseRequestForAdmin(paginate: $paginate) {
            data {
                ...F_MATERIAL_PURCHASE_REQUEST
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const createMaterialPurchaseRequest = gql`
    mutation createMaterialPurchaseRequest(
        $payload: CreateAdminMaterialPurchaseRequestRequest!
    ) {
        data: createMaterialPurchaseRequestForAdmin(payload: $payload) {
            data {
                id
            }
        }
    }
`;

export const updateMaterialPurchaseRequest = gql`
    ${F_MATERIAL_PURCHASE_REQUEST}
    mutation updateMaterialPurchaseRequest(
        $id: String!
        $payload: UpdateAdminMaterialPurchaseRequestRequest!
    ) {
        data: updateMaterialPurchaseRequestForAdmin(
            id: $id
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_REQUEST
            }
        }
    }
`;

export const getMaterialPurchaseRequestDetail = gql`
    ${F_MATERIAL_PURCHASE_REQUEST}
    query detailMaterialPurchaseRequest($id: String!) {
        data: detailMaterialPurchaseRequestForAdmin(id: $id) {
            data {
                ...F_MATERIAL_PURCHASE_REQUEST
            }
        }
    }
`;

export const purchaseMaterialPurchaseRequestForAdmin = gql`
    ${F_MATERIAL_PURCHASE_SEARCH}
    query purchaseMaterialPurchaseRequest(
        $id: String!
        $paginate: SearchAdminMaterialPurchaseRequest!
    ) {
        data: purchaseMaterialPurchaseRequestForAdmin(
            id: $id
            paginate: $paginate
        ) {
            data {
                ...F_MATERIAL_PURCHASE_SEARCH
            }
        }
    }
`;

export const approvalMaterialPurchaseRequest = gql`
    ${F_MATERIAL_PURCHASE_REQUEST}
    mutation approvalMaterialPurchaseRequest($id: String!, $remark: String!) {
        data: approvalMaterialPurchaseRequestForAdmin(
            id: $id
            remark: $remark
        ) {
            data {
                ...F_MATERIAL_PURCHASE_REQUEST
            }
        }
    }
`;

export const cancelMaterialPurchaseRequest = gql`
    ${F_MATERIAL_PURCHASE_REQUEST}
    mutation cancelMaterialPurchaseRequest($id: String!, $remark: String!) {
        data: cancelMaterialPurchaseRequestForAdmin(id: $id, remark: $remark) {
            data {
                ...F_MATERIAL_PURCHASE_REQUEST
            }
        }
    }
`;

export const listMaterialPurchaseQuotation = gql`
    ${F_MATERIAL_PURCHASE_QUOTATION}
    query listMaterialPurchaseQuotation(
        $materialPurchaseRequestId: String!
        $paginate: ListAdminMaterialPurchaseQuotationRequest!
    ) {
        data: listMaterialPurchaseQuotationForAdmin(
            materialPurchaseRequestId: $materialPurchaseRequestId
            paginate: $paginate
        ) {
            data {
                ...F_MATERIAL_PURCHASE_QUOTATION
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const createMaterialPurchaseQuotation = gql`
    mutation createMaterialPurchaseQuotation(
        $materialPurchaseRequestId: String!
        $payload: CreateAdminMaterialPurchaseQuotationRequest!
    ) {
        data: createMaterialPurchaseQuotationForAdmin(
            materialPurchaseRequestId: $materialPurchaseRequestId
            payload: $payload
        ) {
            data {
                id
            }
        }
    }
`;

export const updateMaterialPurchaseQuotation = gql`
    ${F_MATERIAL_PURCHASE_QUOTATION}
    mutation updateMaterialPurchaseQuotation(
        $materialPurchaseRequestId: String!
        $id: String!
        $payload: UpdateAdminMaterialPurchaseQuotationRequest!
    ) {
        data: updateMaterialPurchaseQuotationForAdmin(
            materialPurchaseRequestId: $materialPurchaseRequestId
            id: $id
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_QUOTATION
            }
        }
    }
`;

export const detailMaterialPurchaseQuotation = gql`
    ${F_MATERIAL_PURCHASE_QUOTATION}
    query detailMaterialPurchaseQuotation(
        $materialPurchaseRequestId: String!
        $id: String!
    ) {
        data: detailMaterialPurchaseQuotationForAdmin(
            materialPurchaseRequestId: $materialPurchaseRequestId
            id: $id
        ) {
            data {
                ...F_MATERIAL_PURCHASE_QUOTATION
            }
        }
    }
`;

export const approvalMaterialPurchaseQuotation = gql`
    ${F_MATERIAL_PURCHASE_QUOTATION}
    mutation approvalMaterialPurchaseQuotation(
        $materialPurchaseRequestId: String!
        $id: String!
        $remark: String!
    ) {
        data: approvalMaterialPurchaseQuotationForAdmin(
            materialPurchaseRequestId: $materialPurchaseRequestId
            id: $id
            remark: $remark
        ) {
            data {
                ...F_MATERIAL_PURCHASE_QUOTATION
            }
        }
    }
`;

export const cancelMaterialPurchaseQuotation = gql`
    ${F_MATERIAL_PURCHASE_QUOTATION}
    mutation cancelMaterialPurchaseQuotation(
        $materialPurchaseRequestId: String!
        $id: String!
        $remark: String!
    ) {
        data: cancelMaterialPurchaseQuotationForAdmin(
            materialPurchaseRequestId: $materialPurchaseRequestId
            id: $id
            remark: $remark
        ) {
            data {
                ...F_MATERIAL_PURCHASE_QUOTATION
            }
        }
    }
`;

export const searchMaterialPurchaseOrder = gql`
    ${F_MATERIAL_ORDER_SEARCH}
    query searchMaterialPurchase(
        $paginate: SearchAdminMaterialPurchaseRequest!
    ) {
        data: searchMaterialPurchaseForAdmin(paginate: $paginate) {
            data {
                ...F_MATERIAL_ORDER_SEARCH
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const listMaterialPurchaseOrder = gql`
    ${F_MATERIAL_ORDER}
    query listMaterialPurchase($paginate: ListAdminMaterialPurchaseRequest!) {
        data: listMaterialPurchaseForAdmin(paginate: $paginate) {
            data {
                ...F_MATERIAL_ORDER
            }
            pagination {
                ...F_PAGINATE
            }
        }
    }
`;

export const createMaterialPurchaseOrder = gql`
    mutation createMaterialPurchase(
        $payload: CreateAdminMaterialPurchaseRequest!
    ) {
        data: createMaterialPurchaseForAdmin(payload: $payload) {
            data {
                materialPurchaseNo
                id
            }
        }
    }
`;

export const getMaterialPurchaseOrderDetail = gql`
    ${F_MATERIAL_ORDER_DETAIL}
    query detailMaterialPurchase($id: String!) {
        data: detailMaterialPurchaseForAdmin(id: $id) {
            data {
                ...F_MATERIAL_ORDER_DETAIL
            }
        }
    }
`;

export const loadConfigurationMaterial = gql`
    query loadConfigurationMaterial {
        data: loadConfigurationMaterialForAdmin {
            data: materialTrackingUnit {
                name
                id: value
            }
        }
    }
`;
export const approvalMaterialPurchase = gql`
    mutation approvalMaterialPurchase(
        $id: String!
        $remark: String!
        $attachment: [String!]!
    ) {
        data: approvalMaterialPurchaseForAdmin(
            id: $id
            remark: $remark
            attachment: $attachment
        ) {
            data {
                materialPurchaseNo
                id
            }
        }
    }
`;

export const rejectMaterialPurchase = gql`
    mutation rejectMaterialPurchase(
        $id: String!
        $remark: String!
        $attachment: [String!]!
    ) {
        data: rejectMaterialPurchaseForAdmin(
            id: $id
            remark: $remark
            attachment: $attachment
        ) {
            data {
                materialPurchaseNo
                id
            }
        }
    }
`;

export const listAllMaterialPurchasePayment = gql`
    ${F_MATERIAL_PURCHASE_PAYMENT}
    query listAllMaterialPurchasePayment($refId: String!) {
        data: listAllMaterialPurchasePaymentForAdmin(refId: $refId) {
            data {
                ...F_MATERIAL_PURCHASE_PAYMENT
            }
        }
    }
`;

export const updateMaterialPurchasePayment = gql`
    ${F_MATERIAL_PURCHASE_PAYMENT}
    mutation updateMaterialPurchasePayment(
        $refId: String!
        $payload: [UpdateAdminMaterialPurchasePaymentRequest!]!
    ) {
        data: updateMaterialPurchasePaymentForAdmin(
            refId: $refId
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_PAYMENT
            }
        }
    }
`;

export const updateMaterialPaymentMethod = gql`
    ${F_MATERIAL_PURCHASE_PAYMENT}
    mutation updateMaterialPaymentMethod(
        $refId: String!
        $paymentId: String!
        $payload: UpdatePaymentMethodAdminMaterialPurchasePaymentRequest!
    ) {
        data: updateMaterialPaymentMethodForAdmin(
            refId: $refId
            paymentId: $paymentId
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_PAYMENT
            }
        }
    }
`;

export const uploadMaterialProof = gql`
    ${F_MATERIAL_PURCHASE_PAYMENT}
    mutation uploadMaterialProof(
        $refId: String!
        $id: String!
        $paymentMethod: MaterialPurchasePaymentMethod!
        $payload: UploadProofAdminMaterialPurchasePaymentRequest!
    ) {
        data: uploadMaterialProofForAdmin(
            refId: $refId
            id: $id
            paymentMethod: $paymentMethod
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_PAYMENT
            }
        }
    }
`;

export const listAllMaterialPurchaseDelivery = gql`
    ${F_MATERIAL_PURCHASE_DELIVERY}
    query listAllMaterialPurchaseDelivery($materialPurchase: String!) {
        data: listAllMaterialPurchaseDeliveryForAdmin(
            materialPurchase: $materialPurchase
        ) {
            data {
                ...F_MATERIAL_PURCHASE_DELIVERY
            }
        }
    }
`;

export const listMaterialMaterialPurchaseDelivery = gql`
    ${F_MATERIAL_PURCHASE_DELIVERY_MATERIAL}
    query listMaterialMaterialPurchaseDelivery($materialPurchase: String!) {
        data: listMaterialMaterialPurchaseDeliveryForAdmin(
            materialPurchase: $materialPurchase
        ) {
            data {
                ...F_MATERIAL_PURCHASE_DELIVERY_MATERIAL
            }
        }
    }
`;

export const createMaterialPurchaseDelivery = gql`
    ${F_MATERIAL_PURCHASE_DELIVERY}
    mutation createMaterialPurchaseDelivery(
        $materialPurchase: String!
        $payload: CreateAdminMaterialPurchaseDeliveryRequest!
    ) {
        data: createMaterialPurchaseDeliveryForAdmin(
            materialPurchase: $materialPurchase
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_DELIVERY
            }
        }
    }
`;

export const updateMaterialPurchaseDelivery = gql`
    ${F_MATERIAL_PURCHASE_DELIVERY}
    mutation updateMaterialPurchaseDelivery(
        $materialPurchase: String!
        $id: String!
        $payload: UpdateAdminMaterialPurchaseDeliveryRequest!
    ) {
        data: updateMaterialPurchaseDeliveryForAdmin(
            materialPurchase: $materialPurchase
            id: $id
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_PURCHASE_DELIVERY
            }
        }
    }
`;

export const detailMaterialPurchaseDelivery = gql`
    ${F_MATERIAL_PURCHASE_DELIVERY}
    query detailMaterialPurchaseDelivery(
        $materialPurchase: String!
        $id: String!
    ) {
        data: detailMaterialPurchaseDeliveryForAdmin(
            materialPurchase: $materialPurchase
            id: $id
        ) {
            data {
                ...F_MATERIAL_PURCHASE_DELIVERY
            }
        }
    }
`;

export const updateStatusMaterialPurchaseDelivery = gql`
    ${F_MATERIAL_PURCHASE_DELIVERY}
    mutation updateStatusMaterialPurchaseDelivery(
        $materialPurchase: String!
        $id: String!
        $status: String!
    ) {
        data: updateStatusMaterialPurchaseDeliveryForAdmin(
            materialPurchase: $materialPurchase
            id: $id
            status: $status
        ) {
            data {
                ...F_MATERIAL_PURCHASE_DELIVERY
            }
        }
    }
`;

export const updateMaterialPurchase = gql`
    ${F_MATERIAL_ORDER}
    mutation updateMaterialPurchase(
        $id: String!
        $payload: UpdateAdminMaterialPurchaseRequest!
    ) {
        data: updateMaterialPurchaseForAdmin(id: $id, payload: $payload) {
            data {
                ...F_MATERIAL_ORDER
            }
        }
    }
`;

export const updateMaterialPurchaseBilling = gql`
    ${F_MATERIAL_ORDER}
    mutation updateMaterialPurchaseBilling(
        $id: String!
        $isSameShippingAddress: Boolean!
        $payload: UpdateAdminMaterialPurchaseBillingRequest!
    ) {
        data: updateMaterialPurchaseBillingForAdmin(
            id: $id
            isSameShippingAddress: $isSameShippingAddress
            payload: $payload
        ) {
            data {
                ...F_MATERIAL_ORDER
            }
        }
    }
`;

export const checkMaterialPurchaseRequest = gql`
    ${F_MATERIAL_PURCHASE_REQUEST}
    query checkMaterialPurchaseRequest($payload: CheckAdminMaterialPurchaseRequestRequest!) {
        data: checkMaterialPurchaseRequestForAdmin(payload: $payload) {
            data {
                ...F_MATERIAL_PURCHASE_REQUEST
            }
        }
    }
`;

export const checkMaterialStockAdjustment = gql`
    ${F_MATERIAL_ADJUST_REQUEST}
    query checkMaterialStockAdjustment($payload: CheckAdminMaterialStockAdjustmentRequest!) {
        data: checkMaterialStockAdjustmentForAdmin(payload: $payload) {
            data {
                ...F_MATERIAL_ADJUST_REQUEST
            }
        }
    }
`;

const MaterialAPI = {
    search: async (paginate: any) =>
        API.query({
            query: searchMaterial,
            variables: { paginate },
            fetchPolicy: "no-cache",
        }),

    loadConfig: async () =>
        API.query({
            query: loadConfigurationMaterial,
            fetchPolicy: "no-cache",
        }),
};

export default MaterialAPI;
