import {
    Button,
    DateInput,
    Icon,
    InputText,
    ObjectUtils,
    StringUtils,
} from "d-react-components";
import { map } from "lodash";
import { useContext } from "react";
import { CustomerCreateContext } from "../../../context/customer";
import Messages from "../../../languages/Messages";
import FamilyInfoForm from "../share/FamilyInfoForm";

const CustomerCreateFamilyInfo = () => {
    const { customerForm } = useContext(CustomerCreateContext);
    const familyInfoList = customerForm?.values?.familyInformation ?? [];
    const onUpdateFamilyInformationList = (familyInfo: any) => {
        const result = ObjectUtils.updateArrayById(familyInfoList, familyInfo);
        customerForm.setFieldValue("familyInformation", result);
    };

    const onAddFamilyInfo = () => {
        customerForm.setFieldValue("familyInformation", [
            ...familyInfoList,
            { id: StringUtils.getUniqueID() },
        ]);
    };

    const onRemoveFamilyInfo = (index: string) => {
        customerForm.setFieldValue(
            "familyInformation",
            familyInfoList.filter((_: any, i: string) => i !== index)
        );
    };

    return (
        <div className="card-container p-3">
            {map(
                customerForm?.values?.familyInformation,
                (familyInfo, index) => (
                    <div className="mt-3 mb-5 px-3">
                        <div className="flex">
                            <label className="flex-1">{`${
                                Messages.familyInfo
                            } ${index + 1}`}</label>
                            <Icon
                                className="cursor-pointer"
                                name="remove_circle_outline"
                                onClick={() => onRemoveFamilyInfo(index)}
                            />
                        </div>
                        <FamilyInfoForm
                            familyInfo={familyInfo}
                            onChange={(value: any) =>
                                onUpdateFamilyInformationList(value)
                            }
                        />
                    </div>
                )
            )}
            <Button
                className="mx-3 mt-3"
                variant="trans"
                iconName="add"
                onClick={onAddFamilyInfo}
            >
                {Messages.addNew}
            </Button>
        </div>
    );
};

export default CustomerCreateFamilyInfo;
