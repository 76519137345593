import {
    Button,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import { MaterialStockRequestDetailContext } from "../../../context/material";
import Drawer from "../../../common/Drawer";
import InputTextForm from "../../../common/input/InputTextForm";
import {
    MATERIAL_ADJUST_REQUEST_STATUS,
    MATERIAL_ADJUST_REQUEST_STATUSES,
} from "../../../constant/material";
import Messages from "../../../languages/Messages";
import ActionMenu from "../../../common/ActionButton";
import MaterialInventoryAPI from "../../../api/queries/material-inventory";

interface IStockRequestConfirmDrawer {
    open: boolean;
    title?: string;
    description?: string;
    onClose: () => void;
    onConfirm: (props: any) => void;
}

const AdjustRequestConfirmDrawer = ({
    open,
    onClose,
    onConfirm,
    title,
    description,
}: IStockRequestConfirmDrawer) => {
    const confirmForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object().shape({
            remark: Yup.string().required("Is Required!"),
        }),
        onSubmit: (values: any) => {
            onClose && onClose();
            onConfirm(values);
        },
    });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={title}
            onSave={() => confirmForm.handleSubmit()}
            size="auto"
            width="40%"
        >
            {description && <p className="text px-4 mt-4">{description}</p>}
            <div className="px-4">
                <InputTextForm form={confirmForm} keyData="remark" multiple />
            </div>
        </Drawer>
    );
};

const MaterialAdjustRequestHeader = () => {
    const { requestDetail, setRequestDetail } = useContext(
        MaterialStockRequestDetailContext
    );
    const [openConfirm, setOpenConfirm] = useState<{
        open: boolean;
        type?: "approve" | "cancel";
    }>({ open: false });

    const isShowConfirm = useMemo(() => {
        return requestDetail?.status === MATERIAL_ADJUST_REQUEST_STATUS.PENDING;
    }, [requestDetail?.status]);

    const onClickAction = (item: any) => {
        if (item.id === "cancelRequest") {
            setOpenConfirm({ open: true, type: "cancel" });
        }
    };

    const onApproveRequest = (input: any) => {
        Progress.show(
            {
                method: MaterialInventoryAPI.approveAdjustmentRequest,
                params: [
                    { id: requestDetail?.id, remark: input?.remark ?? null },
                ],
            },
            (res: any) => {
                if (res) {
                    Notifications.showSuccess(Messages.approveSuccess);
                    setRequestDetail(res);
                }
            }
        );
    };

    const onCancelRequest = (input: any) => {
        Progress.show(
            {
                method: MaterialInventoryAPI.cancelAdjustmentRequest,
                params: [
                    { id: requestDetail?.id, remark: input?.remark ?? null },
                ],
            },
            (res: any) => {
                if (res) {
                    Notifications.showSuccess(Messages.cancelSuccess);
                    setRequestDetail(res);
                }
            }
        );
    };

    return (
        <div className="px-4 py-3 bg-white flex border-b items-center justify-content-between">
            <div className="d-flex">
                <h5 className="text-primary mr-3">{`${requestDetail?.adjustmentNo}`}</h5>

                <ViewLabelStatus
                    status={requestDetail?.status}
                    listStatus={MATERIAL_ADJUST_REQUEST_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                    className="ml-3"
                />
            </div>
            <div className="d-flex">
                <Button
                    variant="outline"
                    size="small"
                    onClick={() =>
                        setOpenConfirm({ open: true, type: "approve" })
                    }
                    className="ml-2"
                    hidden={!isShowConfirm}
                >
                    {Messages.approve}
                </Button>
                <Button
                    variant="outline"
                    size="small"
                    onClick={() => {}}
                    className="ml-2"
                >
                    {Messages.print}
                </Button>
                <ActionMenu
                    className="ml-2"
                    dataSource={[
                        {
                            id: "cancelRequest",
                            label: Messages.cancelRequest,
                        },
                    ]}
                    onClick={onClickAction}
                />
            </div>
            {openConfirm?.open && (
                <AdjustRequestConfirmDrawer
                    title={
                        openConfirm?.type === "approve"
                            ? Messages.approvalConfirmation
                            : Messages.cancelConfirmation
                    }
                    description={
                        openConfirm?.type === "approve"
                            ? Messages.approvalRequestedConfirmationDes
                            : Messages.cancelRequestedConfirmationDes
                    }
                    open={openConfirm?.open}
                    onClose={() => setOpenConfirm({ open: false })}
                    onConfirm={(input) =>
                        openConfirm?.type === "approve"
                            ? onApproveRequest(input)
                            : onCancelRequest(input)
                    }
                />
            )}
        </div>
    );
};

export default MaterialAdjustRequestHeader;
