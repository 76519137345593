import { DateInput, InputText } from "d-react-components";
import moment from "moment";
import React from "react";
import { ICustomerFamilyInfo } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";

interface IFamilyInfoForm {
    familyInfo: ICustomerFamilyInfo;
    onChange: any;
    formError?: any;
}

const FamilyInfoForm = ({
    familyInfo,
    onChange,
    formError,
}: IFamilyInfoForm) => {
    const inputClassName = "col-6 mt-3";
    const onChangeValue = (key: string, value: any) => {
        onChange({ ...familyInfo, [key]: value });
    };

    return (
        <div className="row">
            <InputText
                label={Messages.name}
                onChange={(event) =>
                    onChangeValue("name", event?.target?.value)
                }
                className={inputClassName}
                value={familyInfo?.name}
                error={formError?.name}
            />
            <InputText
                label={Messages.relationship}
                onChange={(event) =>
                    onChangeValue("relationship", event?.target?.value)
                }
                className={inputClassName}
                value={familyInfo?.relationship}
                error={formError?.relationship}
            />

            <DateInput
                label={Messages.dateOfBirth}
                onChange={(value) => onChangeValue("dateOfBirth", value)}
                value={
                    familyInfo?.dateOfBirth
                        ? moment(familyInfo?.dateOfBirth)
                        : (null as any)
                }
                format="DD/MM/YYYY"
                className={inputClassName}
                error={formError?.dateOfBirth}
            />
            <InputText
                label={Messages.phone}
                onChange={(event) =>
                    onChangeValue("phone", event?.target?.value)
                }
                className={inputClassName}
                value={familyInfo?.phone}
                error={formError?.phone}
            />
        </div>
    );
};

export default FamilyInfoForm;
