import {
    AwesomeTableComponent,
    Badge,
    Button,
    HeaderTable,
    IColumnsProps,
    TabBar,
    TimeUtils,
    ViewLabelStatus,
    useFirstTime,
} from "d-react-components";
import { useUpdateEffect } from "react-use";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { JsonParam, useQueryParam } from "use-query-params";
import AppLink from "../../../common/AppLink";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import { MATERIAL_REQUEST_STATUSES } from "../../../constant/material";
import { useListMaterialPurchaseRequestLazyQuery } from "../../../api/hooks";
import TableAction from "../../../common/TableActions";
import UserAvatarName from "../../../common/avatar/UserAvatarName";

export interface IInventoryTableProps {
    [key: string]: any;
}

const STATUS_TABS = [
    {
        id: "all",
        label: "all",
    },
    ...MATERIAL_REQUEST_STATUSES,
];

const MaterialRequestTable: React.FC<IInventoryTableProps> = ({ id }) => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");

    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const [tabSelected, setTabSelected] = useState<any>(STATUS_TABS[0]);
    const [listMaterialPurchaseRequest, { refetch }] =
        useListMaterialPurchaseRequestLazyQuery();
    const navigate = useNavigate();
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.id,
            dataIndex: "materialPurchaseRequestNo",
            render: (name, item) => (
                <AppLink
                    to={generatePath(Path.MATERIAL_REQUEST_DETAIL, {
                        requestId: item.id,
                    })}
                >
                    {name}
                </AppLink>
            ),
        },
        {
            title: Messages.expectedDateOfArrival,
            dataIndex: "expectedDateOfArrival",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={MATERIAL_REQUEST_STATUSES}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (data: any) => {
                return <UserAvatarName user={data} size="xx-small" />;
            },
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (product: any) => {
                return (
                    <TableAction
                        onClick={(actionId: string) =>
                            onClickTableAction(actionId, product)
                        }
                        dataSource={[{ id: "detail", label: "detail" }]}
                    />
                );
            },
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = {};
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            sort: { createdAt: -1 },
            ...(tabSelected?.id !== "all" ? { status: [tabSelected?.id] } : {}),
            ...filterParam,
            search: searchRef?.current || "",
        } as any;
        return listMaterialPurchaseRequest({
            variables: {
                paginate: input,
            },
        });
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    useUpdateEffect(() => {
        tableRef.current.refresh();
    }, [tabSelected]);

    const onClickTableAction = (actionId: string, request: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.MATERIAL_REQUEST_DETAIL, {
                        requestId: request.id,
                    })
                );
        }
    };

    return (
        <div className="">
            <HeaderTable
                label={Messages.materialRequest}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                onClickNew={() => {
                    navigate(Path.MATERIAL_REQUEST_CREATE);
                }}
                onSubmitSearch={() => {
                    tableRef.current.refresh();
                }}
                className="app-layout__page-header"
            />
            <div className="flex">
                <TabBar
                    dataSource={STATUS_TABS}
                    onChange={(tab) => setTabSelected(tab)}
                    value={tabSelected}
                    // className="overflow-x-scroll my-4 pb-1 flex-column form-tabs"
                    // getLabel={(item: any) => (
                    //     <>
                    //         <span>{Messages[item.label]}</span>
                    //         {item.id !== "all" && (
                    //             <Badge
                    //                 index={
                    //                     summary?.[item.id] > 100
                    //                         ? "100+"
                    //                         : summary?.[item.id]
                    //                 }
                    //                 variant="index"
                    //                 size="x-large"
                    //                 color={
                    //                     summary?.[item.id] === 0
                    //                         ? "green"
                    //                         : "red"
                    //                 }
                    //             />
                    //         )}
                    //     </>
                    // )}
                    getLabel={(item: any) => (
                        <>
                            <span>{Messages[item.label]}</span>
                        </>
                    )}
                    isScroll
                    hideScrollBar={false}
                    minWidthItem="100%"
                    classNameItem="justify-content-start text-gray-500 bg-white"
                    variant="horizontal"
                />
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.data?.data?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data?.data?.data ?? []}
            />
        </div>
    );
};

export default MaterialRequestTable;
