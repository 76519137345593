import { gql } from "@apollo/client";
import { sortBy } from "lodash";
import API from "../API";
import {
    F_CUSTOMER_SOURCE,
    F_DOCTOR_SUMMARY,
    F_REPORT_CASHIER,
    F_REPORT_SALESPERSON_MONTHLY,
    F_REPORT_SALESPERSON_USER,
    F_TREATMENT_PIC_SUMMARY,
} from "../fragments/report";
import { F_TREATMENT_USER_REPORT } from "../fragments/treatment";
import { F_CUSTOMER_VOUCHER_REPORT } from "../fragments/voucher";

const reportConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        const sortList = sortBy(list, (item) => item?.customer?.customerNo);
        return sortList;
    },
};

export const getCashierReport = gql`
    ${F_REPORT_CASHIER}
    query getCashierReport($payload: ReportCashierRequest!) {
        data: reportCashier(payload: $payload) {
            data {
                ...F_REPORT_CASHIER
            }
        }
    }
`;

export const getCashierReportByPayment = gql`
    ${F_REPORT_CASHIER}
    query getCashierReportByPayment($payload: ReportCashierRequest!) {
        data: reportCashierByPayment(payload: $payload) {
            data {
                ...F_REPORT_CASHIER
            }
        }
    }
`;

export const getReportSalespersonUser = gql`
    ${F_REPORT_SALESPERSON_USER}
    query getReportSalespersonUser($payload: ReportSalespersonUserRequest!) {
        data: reportSalespersonUser(payload: $payload) {
            data {
                ...F_REPORT_SALESPERSON_USER
            }
        }
    }
`;

export const getReportSalespersonMonthly = gql`
    ${F_REPORT_SALESPERSON_MONTHLY}
    query getReportSalespersonMonthly(
        $payload: ReportSalespersonMonthlyRequest!
    ) {
        data: reportSalespersonMonthly(payload: $payload) {
            data {
                ...F_REPORT_SALESPERSON_MONTHLY
            }
        }
    }
`;

export const getReportCustomerSource = gql`
    ${F_CUSTOMER_SOURCE}
    query getReportCustomerSource($payload: ReportCustomerSourceRequest!) {
        data: reportCustomerSource(payload: $payload) {
            data {
                ...F_CUSTOMER_SOURCE
            }
        }
    }
`;

export const getReportDoctorSummary = gql`
    ${F_DOCTOR_SUMMARY}
    query getReportDoctorSummary($payload: ReportTreatmentPicSummaryRequest!) {
        data: reportDoctorSummary(payload: $payload) {
            data {
                ...F_DOCTOR_SUMMARY
            }
        }
    }
`;

export const getReportTreatmentPicSummary = gql`
    ${F_TREATMENT_PIC_SUMMARY}
    query getReportTreatmentPicSummary(
        $payload: ReportTreatmentPicSummaryRequest!
    ) {
        data: reportTreatmentPicSummary(payload: $payload) {
            data {
                ...F_TREATMENT_PIC_SUMMARY
            }
        }
    }
`;

export const getReportTreatmentUserDetail = gql`
    ${F_TREATMENT_USER_REPORT}
    query getReportDoctorDetail($payload: ReportDoctorDetailRequest!) {
        data: reportDoctorDetail(payload: $payload) {
            data {
                ...F_TREATMENT_USER_REPORT
            }
        }
    }
`;

export const getReportTreatmentDetailUsage = gql`
    ${F_TREATMENT_USER_REPORT}
    query getReportTreatmentDetailUsage(
        $payload: ReportTreatmentDailyUsageRequest!
    ) {
        data: reportTreatmentDetailUsage(payload: $payload) {
            data {
                ...F_TREATMENT_USER_REPORT
            }
        }
    }
`;

export const getCustomerVoucher = gql`
    ${F_CUSTOMER_VOUCHER_REPORT}
    query getCustomerVoucher($payload: ReportCustomerVoucherRequest!) {
        data: reportCustomerVoucherForAdmin(payload: $payload) {
            data {
                ...F_CUSTOMER_VOUCHER_REPORT
            }
        }
    }
`;

const ReportAPI = {
    cashier: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getCashierReport,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    cashierByPayment: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getCashierReportByPayment,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    salespersonByUser: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportSalespersonUser,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    salespersonMonthly: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportSalespersonMonthly,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    customerSource: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportCustomerSource,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    doctorSummary: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportDoctorSummary,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    treatmentPicSummary: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportTreatmentPicSummary,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    treatmentUserDetail: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportTreatmentUserDetail,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    customerVouchers: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getCustomerVoucher,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),

    treatmentDetailUsage: async (payload: any) =>
        API.withConverter(reportConverter).query({
            query: getReportTreatmentDetailUsage,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),
};

export default ReportAPI;
