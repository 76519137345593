/* eslint-disable no-case-declarations */
import {
    AwesomeTableComponent,
    IColumnsProps,
    Notifications,
    Progress,
    ViewLabelStatus,
} from "d-react-components";
import { useRef } from "react";
import {
    useCreateBankAccountSettingMutation,
    useUpdateBankAccountSettingMutation,
} from "../../../api/hooks";
import {
    PAYMENT_CARD_DEVICES,
    PAYMENT_METHOD_STATUS,
    PAYMENT_METHOD_STATUSES,
} from "../../../constant/payment";
import Messages from "../../../languages/Messages";

const PaymentMethodPOSCardDevice = () => {
    const tableRef = useRef<any>(null);
    const [updateBankAccountSetting] = useUpdateBankAccountSettingMutation();
    const [createBankAccountSetting] = useCreateBankAccountSettingMutation();

    const onUpdateHandler = (id: string, payload: any) => {
        return Progress.show(
            {
                method: updateBankAccountSetting,
                params: {
                    variables: {
                        id,
                        payload,
                    },
                },
            },
            (res) => {
                refreshTable();
                Notifications.showSuccess(Messages.updateSuccess);
            }
        );
    };
    const onCreateHandler = (payload: any) => {
        return Progress.show(
            {
                method: createBankAccountSetting,
                params: {
                    variables: {
                        payload,
                    },
                },
            },
            (res) => {
                refreshTable();
                Notifications.showSuccess(Messages.createSuccess);
            }
        );
    };

    const columns: IColumnsProps = [
        // {
        //     title: Messages.accountName,
        //     dataIndex: "accountName",
        // },
        {
            title: Messages.name,
            dataIndex: "label",
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={PAYMENT_METHOD_STATUS.ENABLE}
                    listStatus={PAYMENT_METHOD_STATUSES}
                    getLabel={(item) => (Messages as any)[item.label]}
                />
            ),
        },
        // {
        //     title: Messages.action,
        //     dataIndex: "id",
        //     align: "center",
        //     width: 30,
        //     render: (data: any, item) => {
        //         return (
        //             <TableAction
        //                 onClick={(actionId: string) =>
        //                     onClickTableAction(actionId, item)
        //                 }
        //                 dataSource={[{ id: "update", label: "update" }]}
        //             />
        //         );
        //     },
        // },
    ];

    const source = (paging: any) => {
        return Promise.resolve([]);
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current?.refresh?.();
    };

    return (
        <div className="my-3">
            <div className="flex items-center mb-3">
                <h5 className="text-[16px] flex-1">{Messages.posCardDevice}</h5>
                {/* <Button
                    size="small"
                    iconName="add"
                    classNameIcon="mx- 0 mr-1"
                    onClick={() => setShowModal({ open: true })}
                >
                    {Messages.add}
                </Button> */}
            </div>

            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                transformer={(res) => PAYMENT_CARD_DEVICES}
                columns={columns}
                baseColumnProps={{ width: 100 }}
                pagination={false}
            />
        </div>
    );
};

export default PaymentMethodPOSCardDevice;
