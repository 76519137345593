import { Avatar, AvatarProps } from "d-react-components";
import React, { useContext } from "react";
import { AppStateContext } from "../../context/app";
import { ICustomer } from "../../interfaces/customer";

interface ICustomerAvatarName {
    customer: ICustomer;
    placeholder?: any;
    size?: AvatarProps["size"];
    onClick?: () => void;
}

const CustomerAvatarName = ({
    customer,
    placeholder = "N/A",
    size = "xx-small",
    onClick,
}: ICustomerAvatarName) => {
    const { setOpenAppDrawer } = useContext(AppStateContext);
    if (!customer || !customer?.id) return placeholder;
    const { fullNameTh, firstNameTh, lastNameTh, nickname } = customer;
    return (
        <div
            className="flex-center-y a hover:cursor-pointer"
            onClick={() => {
                onClick
                    ? onClick()
                    : setOpenAppDrawer({
                          open: true,
                          customerId: customer?.id,
                      });
            }}
        >
            <Avatar
                src={customer?.avatar}
                size={size}
                variant="square"
                text={customer?.fullNameTh}
            />
            <div className="ml-2">{`${
                fullNameTh || `${firstNameTh} ${lastNameTh}`
            } ${nickname ? `(${nickname})` : ""}`}</div>
        </div>
    );
};

export default CustomerAvatarName;
