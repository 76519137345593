export const EQUIPMENT_CATEGORY_STATUS = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
};
export const EQUIPMENT_CATEGORY_STATUSES = [
    {
        id: EQUIPMENT_CATEGORY_STATUS.ENABLED,
        label: "enabled",
        color: "#33B950",
    },
    {
        id: EQUIPMENT_CATEGORY_STATUS.DISABLED,
        label: "disabled",
        color: "#ED6969",
    },
];

export const EQUIPMENT_VISIBILITY = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
};
export const EQUIPMENT_VISIBILITIES = [
    {
        id: EQUIPMENT_VISIBILITY.ENABLED,
        label: "enabled",
        color: "#33B950",
    },
    {
        id: EQUIPMENT_VISIBILITY.DISABLED,
        label: "disabled",
        color: "#ED6969",
    },
];

export const EQUIPMENT_CONDITION = {
    EXCELLENT: "EXCELLENT",
    UNDER_MAINTENANCE: "UNDER_MAINTENANCE",
};
export const EQUIPMENT_CONDITIONS = [
    {
        id: EQUIPMENT_CONDITION.EXCELLENT,
        label: "excellent",
        color: "#33B950",
    },
    {
        id: EQUIPMENT_CONDITION.UNDER_MAINTENANCE,
        label: "underMaintenance",
        color: "#ED6969",
    },
];

export const EQUIPMENT_CONFIGURATION = {
    SIMPLE: "SIMPLE",
    CONFIGURABLE: "CONFIGURABLE",
};

export const EQUIPMENT_CONFIGURATIONS = [
    {
        id: EQUIPMENT_CONFIGURATION.SIMPLE,
        label: "thisIsSimpleEquipment",
        color: "#33B950",
    },
    {
        id: EQUIPMENT_CONFIGURATION.CONFIGURABLE,
        label: "thisIsConfigurableEquipment",
        color: "#ED6969",
    },
];
