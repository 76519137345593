/* eslint-disable consistent-return */
import React, { Fragment, useContext, useRef, useState } from "react";
import {
    Button,
    Drawer,
    InputText,
    Notifications,
    StringUtils,
} from "d-react-components";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import Messages from "../../../languages/Messages";

interface CustomerForm {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
}

const ContactDrawer = ({ open, onClose, onSave, contact }: any) => {
    const isEdit = !isEmpty(contact);
    const CustomerSchema = Yup.object().shape({
        firstName: Yup.string().required(Messages.fieldRequired),
        lastName: Yup.string().required(Messages.fieldRequired),
        phone: Yup.string()
            .matches(StringUtils.phoneRegExp, {
                message: "Invalid phone number!",
            })
            .required(Messages.fieldRequired),
        email: Yup.string()
            .email("Invalid email!")
            .required(Messages.fieldRequired),
    });

    const customerForm = useFormik<CustomerForm>({
        initialValues: !isEdit ? {} : { ...contact },
        onSubmit: () => {
            submitForm();
        },
        validationSchema: CustomerSchema,
        validateOnChange: false,
    });
    const { values, setFieldValue, errors, handleSubmit } = customerForm;

    const submitForm = async () => {
        const { firstName, lastName, phone, email } = values;
        const body = {
            firstName,
            lastName,
            phone,
            email,
        };
        try {
            onSave && onSave(body);
        } catch (err) {
            Notifications.showError(err);
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width="400px"
            size="auto"
            title={isEdit ? Messages.editContact : Messages.addContact}
            maskClosable={false}
            destroyOnClose
        >
            <div className="grid grid-cols-1 gap-4 p-4">
                <div className="col-span-1">
                    <InputText
                        label={Messages.firstName}
                        value={values.firstName}
                        onChange={(e) =>
                            setFieldValue("firstName", e?.currentTarget?.value)
                        }
                        error={errors?.firstName}
                    />
                </div>
                <div className="col-span-1">
                    <InputText
                        label={Messages.lastName}
                        value={values.lastName}
                        onChange={(e) =>
                            setFieldValue("lastName", e?.currentTarget?.value)
                        }
                        error={errors?.lastName}
                    />
                </div>
                <div className="col-span-1">
                    <InputText
                        label={Messages.phone}
                        value={values.phone}
                        onChange={(e) => {
                            setFieldValue("phone", e?.currentTarget?.value);
                        }}
                        error={errors?.phone}
                    />
                </div>
                <div className="col-span-1">
                    <InputText
                        label={Messages.email}
                        value={values.email}
                        onChange={(e) =>
                            setFieldValue("email", e?.currentTarget?.value)
                        }
                        error={errors?.email}
                    />
                </div>
            </div>
            <div className="position-absolute bottom-0 w-100 end-0 start-0 flex-center-y justify-content-between py-3 px-3 bg-white">
                <Button
                    onClick={() => {
                        onClose && onClose({});
                    }}
                    variant="outline"
                >
                    {Messages.cancel}
                </Button>
                <Button
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {Messages.save}
                </Button>
            </div>
        </Drawer>
    );
};

export default ContactDrawer;
