import {
    AwesomeTableComponent,
    HeaderTable,
    IColumnsProps,
    TimeUtils,
    useFirstTime,
    ViewLabelStatus,
} from "d-react-components";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { JsonParam, useQueryParam } from "use-query-params";
import BookingAPI from "../../../api/queries/booking";
import AppLink from "../../../common/AppLink";
import CustomerName from "../../../common/avatar/CustomerName";
import { BOOKING_STATUSES } from "../../../constant/booking";
import { mapBookingFilterToServer } from "../../../interfaces/booking";
import Messages from "../../../languages/Messages";
import { fmtMSS } from "../../../utils/Utils";
import Path from "../../Path";
import BookingTableFilterDrawer from "./BookingTableFilterDrawer";

const BookingTable = () => {
    const tableRef = useRef<any>(null);
    const searchRef = useRef<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        tableRef.current.refresh();
    }, [filter]);

    const columns: IColumnsProps = [
        {
            title: Messages.bookingNo,
            dataIndex: "bookingNo",
            render: (bookingNo, item) => (
                <AppLink
                    to={generatePath(Path.BOOKING_DETAIL, {
                        bookingId: item.id,
                    })}
                >
                    {bookingNo}
                </AppLink>
            ),
        },
        {
            title: Messages.branchWarehouse,
            dataIndex: "branch",
            render: (branch) => branch?.name,
        },
        {
            title: Messages.estDateOfVisit,
            dataIndex: "scheduleDate",
            render: TimeUtils.toDate,
        },
        {
            title: Messages.timeOfVisit,
            dataIndex: "schedule",
            render: (schedule) =>
                `${fmtMSS(schedule?.start)} - ${fmtMSS(schedule?.end)}`,
        },
        {
            title: Messages.customer,
            dataIndex: "customer",
            render: (customer, item) => (
                <CustomerName user={customer || item.contact} />
            ),
        },
        {
            title: Messages.status,
            dataIndex: "status",
            render: (status) => (
                <ViewLabelStatus
                    status={status}
                    listStatus={BOOKING_STATUSES}
                    getLabel={(item) => Messages[item.label]}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const source = (pagingData: any) => {
        const filterParam = mapBookingFilterToServer(filter);
        const input = {
            page: pagingData.pageIndex,
            limit: pagingData.pageSize,
            search: searchRef?.current ?? "",
            sort: { createdAt: -1 },
            ...filterParam,
        };
        return BookingAPI.list(input);
    };

    const onClickTableAction = (actionId: string, booking: any) => {
        switch (actionId) {
            default:
                navigate(
                    generatePath(Path.BOOKING_DETAIL, {
                        bookingId: booking.id,
                    })
                );
        }
    };

    const onClickAddNew = () => {
        navigate(Path.BOOKING_CREATE.replace("/:appointmentId?", ""));
    };

    const onChangeSearch = debounce((text) => {
        searchRef.current = text;
        tableRef.current.refresh();
    }, 500);

    return (
        <div className="">
            <HeaderTable
                onClickNew={onClickAddNew}
                label={Messages.allBooking}
                onClickFilter={() => setOpenFilter(true)}
                onChangeText={(event: any) =>
                    onChangeSearch(event.target.value)
                }
                className="app-layout__page-header"
            />
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                source={source}
                columns={columns}
                showSelectColumn
                baseColumnProps={{ width: 100 }}
                getTotalItems={(res) => {
                    return res?.pagination?.items ?? 0;
                }}
                transformer={(res) => res?.data ?? []}
            />
            {openFilter && (
                <BookingTableFilterDrawer
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                />
            )}
        </div>
    );
};

export default BookingTable;
