/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { convertThaiDateToWesternDate } from "../utils/Utils";

const socketUrl = "ws://localhost:14820/TDKWAgent";

const READ_ID_CARD_COMMAND = {
    Command: "ReadIDCard",
    IDNumberRead: true,
    IDTextRead: true,
    IDATextRead: true,
    IDPhotoRead: true,
};

const SET_AUTO_READ_COMMAND = {
    Command: "SetAutoReadOptions",
    AutoRead: true,
    IDNumberRead: true,
    IDTextRead: true,
    IDATextRead: true,
    IDPhotoRead: true,
};

const GET_READER_LIST_COMMAND = {
    Command: "GetReaderList",
};

const SELECT_READER_COMMAND = {
    Command: "SelectReader",
};

function add_slash(Date: any) {
    const date = Date.substring(6, 8);
    const month = Date.substring(4, 6);
    const year = Date.substring(0, 4);
    return `${month}/${date}/${year}`;
}

const cardDataGetter = (jsObj: any) => {
    const IDNum = jsObj.ID_Number;
    const IDText = jsObj.ID_Text;
    const IDAText = jsObj.ID_AText;
    const IDPhoto = jsObj.ID_Photo;
    const status = jsObj.Status;

    // let reader: any = null;
    let nidNum = null;
    let name_T = null;
    let middleName_T = null;
    let lastName_T = null;
    let title = null;
    let name_E = null;
    let middleName_E = null;
    let lastName_E = null;
    let birthDate = null;
    let sex = null;
    let issuePlace = null;
    let issueDate = null;
    let expireDate = null;
    let address = null;
    let photoRefNo = null;

    let bp1RequestNum = null;
    let issuCode = null;
    let structVersion = null;
    let card_Type = null;
    let title_Flag = null;

    const photo = IDPhoto;

    if (status == 0) {
        if (IDNum != "") {
            nidNum = IDNum;
        }

        if (IDText != "") {
            const NIDData = IDText.split("#");

            photoRefNo = NIDData[22];
            nidNum = NIDData[0];
            name_T = `${NIDData[1]} ${NIDData[2]}`;
            middleName_T = NIDData[3];
            lastName_T = NIDData[4];
            title = NIDData[5];
            name_E = NIDData[6];
            middleName_E = NIDData[7];
            lastName_E = NIDData[8];
            if (NIDData[17] == "1") sex = "MALE";
            else if (NIDData[17] == "2") sex = "FEMALE";
            else sex = "NOT_SAY";
            birthDate = add_slash(NIDData[18]);
            address = `${NIDData[9]} ${NIDData[10]} ${NIDData[11]} ${NIDData[12]} ${NIDData[13]}\r\n${NIDData[14]} ${NIDData[15]} ${NIDData[16]}`;
            issueDate = add_slash(NIDData[20]);
            expireDate = add_slash(NIDData[21]);
            issuePlace = NIDData[19];
        }

        if (IDAText != "") {
            const NIDData = IDAText.split("#");

            photoRefNo = NIDData[22];
            nidNum = NIDData[0];
            name_T = `${NIDData[1]} ${NIDData[2]}`;
            middleName_T = NIDData[3];
            lastName_T = NIDData[4];
            title = NIDData[5];
            name_E = NIDData[6];
            middleName_E = NIDData[7];
            lastName_E = NIDData[8];
            if (NIDData[17] == "1") sex = "MALE";
            else if (NIDData[17] == "2") sex = "FEMALE";
            else sex = "NOT_SAY";
            birthDate = add_slash(NIDData[18]);
            address = `${NIDData[9]} ${NIDData[10]} ${NIDData[11]} ${NIDData[12]} ${NIDData[13]}\r\n${NIDData[14]} ${NIDData[15]} ${NIDData[16]}`;
            issueDate = add_slash(NIDData[20]);
            expireDate = add_slash(NIDData[21]);
            issuePlace = NIDData[19];

            if (NIDData.length > 23) {
                bp1RequestNum = NIDData[23];
                issuCode = NIDData[24];
                structVersion = NIDData[25];
                card_Type = NIDData[26];

                let txt_titleFlag = "";
                switch (NIDData[27]) {
                    case " ":
                        txt_titleFlag = "ไม่มีข้อมูล";
                        break;
                    case "0":
                        txt_titleFlag = "0: ปกติ";
                        break;
                    case "1":
                        txt_titleFlag = "1: ยศทหารเรือ (ร.น.)";
                        break;
                    case "2":
                        txt_titleFlag = "2: สมณศักดิ์";
                        break;
                    default:
                        txt_titleFlag = NIDData[27];
                }

                title_Flag = txt_titleFlag;
            }
        }
        const base64str = IDPhoto;
        return {
            nidNum,
            name_T,
            middleName_T,
            lastName_T,
            title,
            name_E,
            middleName_E,
            lastName_E,
            birthDate,
            sex,
            issuePlace,
            issueDate,
            expireDate,
            address,
            photoRefNo,
            bp1RequestNum,
            issuCode,
            structVersion,
            card_Type,
            title_Flag,
            photo,
        };
    }
    alert(`ERROR Code :${status}`);
    return null;
};

const mapCardDataToCustomer = (readData: any): any => {
    const {
        nidNum,
        name_T,
        middleName_T,
        lastName_T,
        title,
        name_E,
        middleName_E,
        lastName_E,
        birthDate,
        sex,
        issuePlace,
        issueDate,
        expireDate,
        address,
        photoRefNo,
        bp1RequestNum,
        issuCode,
        structVersion,
        card_Type,
        title_Flag,
        photo,
    } = readData || {};
    return {
        avatar: photo,
        title: title ? title?.replace(".", "") : undefined,
        firstNameTh: name_T,
        lastNameTh: lastName_T,
        firstNameEn: name_E,
        nickname: name_E,
        lastNameEn: lastName_E,
        birthDay: convertThaiDateToWesternDate(birthDate),
        gender: sex,
        citizenId: nidNum,
    };
};

export const useCardReader = (url = socketUrl) => {
    const { sendMessage, lastMessage, readyState } = useWebSocket(url);
    const [selectedReader, setSelectedReader] = useState();
    const [mappedData, setMappedData] = useState<any>();

    useEffect(() => {
        const msgData = lastMessage?.data;
        if (msgData) {
            const msgObj = JSON.parse(msgData);
            if (
                msgObj.Message == "AutoReadIDCardE" ||
                msgObj?.Message == "ReadIDCardR"
            ) {
                console.log("GET INSIDE PUT TO SCREEN");
                putToScreen(msgObj);
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        const msgData = lastMessage?.data;
        if (msgData) {
            const msgObj = JSON.parse(msgData);
            if (msgObj.Message == "SelectReaderR") {
                const reader = msgObj?.ReaderName;
                if (reader) {
                    setSelectedReader(reader);
                }
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        const msgData = lastMessage?.data;
        if (msgData) {
            const msgObj = JSON.parse(msgData);
            if (msgObj.Message == "GetReaderListR") {
                const readers = msgObj?.ReaderList ?? [];
                if (readers?.[0]) {
                    const selectReaderCommandObj = {
                        ...SELECT_READER_COMMAND,
                        ReaderName: readers[0],
                    };
                    const selectReaderCommand = JSON.stringify(
                        selectReaderCommandObj
                    );
                    sendMessage(selectReaderCommand);
                }
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            const autoReadCommand = JSON.stringify(SET_AUTO_READ_COMMAND);
            const getReadersCommand = JSON.stringify(GET_READER_LIST_COMMAND);
            sendMessage(autoReadCommand);
            sendMessage(getReadersCommand);
        }
    }, [readyState]);

    const onReadCard = () => {
        if (!selectedReader) {
            return;
        }
        const readIdCardCommand = JSON.stringify(READ_ID_CARD_COMMAND);
        console.log(
            "🚀 >>>>>> file: CustomerCreateCardReader.tsx:46 >>>>>> payloadSocket:",
            readIdCardCommand
        );
        sendMessage(readIdCardCommand);
    };

    const putToScreen = (cardData: any) => {
        const readData = cardDataGetter(cardData);
        if (readData) {
            const mappedData = mapCardDataToCustomer(readData);
            setMappedData(mappedData);
        }
    };

    return { selectedReader, readyState, sendMessage, mappedData, onReadCard };
};
