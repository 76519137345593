import { Notifications } from "d-react-components";
import { Formik } from "formik";
import { isEmpty, omit } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
    useUpdateOrderBillingForAdminMutation,
    useUpdateOrderForAdminMutation,
} from "../../../api/hooks";
import { CustomerDetailContext } from "../../../context/customer";
import { OrderDetailContext } from "../../../context/order";
import { QuotationCreateContext } from "../../../context/quotation";
import { mapBillingToSer } from "../../../interfaces/address";
import Messages from "../../../languages/Messages";
import BillingDrawer from "../../quotation/share/BillingDrawer";
import AddressItem from "../share/AddressItem";

const OrderBilling = () => {
    const { order, loadOrderDetail } = useContext(OrderDetailContext);
    const [showAddModal, setShowAddModal] = useState(false);
    const [updateOrderBilling] = useUpdateOrderBillingForAdminMutation();
    const { billing } = order ?? {};
    const [newBilling, setNewBilling] = useState<any>(order?.billing);

    const onChangeOrderBilling = (formValues: any) => {
        updateOrderBilling({
            variables: {
                id: order?.id as string,
                isSameShippingAddress: formValues.isSameShippingAddress,
                payload: omit(mapBillingToSer(newBilling), "isDefault"),
            },
        })
            .then(() => {
                setShowAddModal(false);
                loadOrderDetail();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    useEffect(() => {
        setNewBilling(order?.billing);
    }, [order]);

    if (!order) {
        return <></>;
    }

    return (
        <QuotationCreateContext.Provider
            value={
                {
                    billing: newBilling,
                    setBilling: setNewBilling,
                } as any
            }
        >
            <CustomerDetailContext.Provider
                value={
                    { customer: order?.customer, setCustomer: () => {} } as any
                }
            >
                <Formik
                    initialValues={{
                        customer: [order?.customer],
                        isSameShippingAddress: order?.isSameShippingAddress,
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={() => {}}
                >
                    {({ values, errors }) => {
                        return (
                            <>
                                <div className="border p-3 mb-3">
                                    <div className="flex items-center">
                                        <h5 className="font-semibold mb-2 flex-1">
                                            {Messages.billingInformation}
                                        </h5>
                                        <a
                                            onClick={() =>
                                                setShowAddModal(true)
                                            }
                                        >
                                            {Messages.edit}
                                        </a>
                                    </div>
                                    {billing &&
                                        !order?.isSameShippingAddress && (
                                            <div>
                                                <AddressItem
                                                    address={billing}
                                                />
                                            </div>
                                        )}
                                    {order?.isSameShippingAddress &&
                                        Messages.sameAsShipping}
                                </div>

                                {showAddModal && (
                                    <BillingDrawer
                                        open={showAddModal}
                                        onClose={() => setShowAddModal(false)}
                                        onSave={() =>
                                            onChangeOrderBilling(values)
                                        }
                                    />
                                )}
                            </>
                        );
                    }}
                </Formik>
            </CustomerDetailContext.Provider>
        </QuotationCreateContext.Provider>
    );
};

export default OrderBilling;
