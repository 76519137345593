import ClassNames from "classnames";
import { useFormik } from "formik";
import { JsonParam, useQueryParam } from "use-query-params";
import Drawer from "../../../common/Drawer";
import InputSelectForm from "../../../common/input/InputSelectForm";
import { SERVICE_CONFIG_STATUSES } from "../../../constant/service";
import Messages from "../../../languages/Messages";
import { mapServiceConfigurationFilterToQueryParams } from "../../../interfaces/service-configuration";
import ServiceCategorySelect from "../../service-category/share/ServiceCategorySelect";

interface IServiceConfigurationTableFilterDrawer {
    open: boolean;
    onClose: () => void;
}

const ServiceConfigurationTableFilterDrawer = ({
    open,
    onClose,
}: IServiceConfigurationTableFilterDrawer) => {
    const [filter, setFilter] = useQueryParam<any>("filter", JsonParam);

    const filterForm = useFormik<any>({
        initialValues: filter ?? ({} as any),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            const params = mapServiceConfigurationFilterToQueryParams(values);
            setFilter(params);
            onClose();
        },
    });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.filter}
            onSave={() => filterForm?.handleSubmit()}
            size="auto"
            width="30%"
        >
            <ServiceConfigurationFilterForm filterForm={filterForm} />
        </Drawer>
    );
};

export default ServiceConfigurationTableFilterDrawer;

interface IServiceConfigurationFilterForm {
    filterForm: any;
}

const ServiceConfigurationFilterForm = ({
    filterForm,
}: IServiceConfigurationFilterForm) => {
    const classNameInput = ClassNames("mt-3 col-12");
    const formValues = filterForm?.values ?? {};

    return (
        <div className="p-3 row">
            <InputSelectForm
                keyData="status"
                dataSource={SERVICE_CONFIG_STATUSES}
                form={filterForm}
                className={classNameInput}
                allowClear
            />
            <ServiceCategorySelect
                multiple
                value={formValues?.categories}
                onChange={(value) => {
                    filterForm.setFieldValue("categories", value);
                }}
                className={classNameInput}
            />
        </div>
    );
};
