/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputText,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { find, forEach, map, omit, pick, reduce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import ProductAPI from "../../../api/queries/product";
import Drawer from "../../../common/Drawer";
import { PriceSetTypeFe, PRODUCT_TYPE } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import {
    getPriceDynamicItemInSpecialGroupProduct,
    getProductPrice,
    IProduct,
    IProductGroupItem,
    mapUpdateProductPriceToServer,
} from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ServiceConfigurationSelect from "../../service-config/share/ServiceConfigurationSelect";
import {
    BundleProduct,
    ProductTypeGroup,
    ProductTypePremiumService,
    SpecialGroupProduct,
} from "../share/ProductConfigurationForm";

interface IProductPriceUpdateModal {
    open: boolean;
    onClose: () => void;
    data: any;
}

const normalizePriceSet = (
    product: IProductGroupItem,
    priceSetType?: string,
    options?: { getOtherData?: (v: any) => any }
) => {
    const { getOtherData } = options || {};
    const { salePrice, regularPrice, priceSet } = product;
    const foundSet = find(priceSet, (i) => i?.priceSetType === priceSetType);
    const otherData = getOtherData ? getOtherData(foundSet) : {};
    const res = {
        ...product,
        salePrice: foundSet?.salePrice ?? salePrice,
        regularPrice: foundSet?.regularPrice ?? regularPrice,
        originalPrice: {
            salePrice,
            regularPrice,
        },
        ...(otherData || {}),
    };
    return res;
};

const getPriceAccordingPriceGroup = (groupType: string, product: IProduct) => {
    const {
        typeOfProduct,
        groups,
        specialGroups,
        bundleProducts,
        salePrice,
        regularPrice,
        premiumService,
        service,
        priceSet,
    } = product;
    if (groupType === PriceSetTypeFe.Thai) {
        return {
            salePrice,
            regularPrice,
            groups,
            specialGroups,
            bundleProducts,
            groupType,
            typeOfProduct,
            priceSet,
            premiumService,
            service,
        };
    }
    let mappedGroups = [...(groups || [])];
    let mappedSpecialGroups = [...(specialGroups || [])];
    let mappedBundleProducts = [...(bundleProducts || [])];

    const mappedPrice = normalizePriceSet(product as any, groupType);

    if (groups?.length) {
        mappedGroups = map(groups, (item) => {
            return normalizePriceSet(item, groupType);
        });
    }
    if (specialGroups?.length) {
        mappedSpecialGroups = map(specialGroups, (item) => {
            return normalizePriceSet(item, groupType, {
                getOtherData: (foundSet) => ({
                    newSalePrice: foundSet?.salePrice ?? item?.salePrice,
                }),
            });
        });
    }
    if (bundleProducts?.length) {
        mappedBundleProducts = map(bundleProducts, (item) => {
            return normalizePriceSet(item, groupType);
        });
    }

    return {
        ...(pick(mappedPrice, ["salePrice", "regularPrice", "originalPrice"]) ||
            {}),
        groups: mappedGroups,
        specialGroups: mappedSpecialGroups,
        bundleProducts: mappedBundleProducts,
        groupType,
        typeOfProduct,
        premiumService,
        service,
        priceSet,
    };
};

const getDisabledOptions = (typeOfProduct: string, groupType: any): any => {
    if (groupType === PriceSetTypeFe?.Thai) {
        return {};
    }
    switch (typeOfProduct) {
        // case PRODUCT_TYPE.SERVICE_PRODUCT:
        //     return renderProductServiceConfig();
        case PRODUCT_TYPE.PREMIUM_SERVICE:
            return {
                premiumServiceType: true,
                addProduct: true,
                remove: true,
            };
        case PRODUCT_TYPE.GROUP_PRODUCT:
            return {
                quantity: true,
                voucherType: true,
                usageType: true,
                remove: true,
            };
        case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
            return {
                isDynamicPrice: true,
                quantity: true,
                voucherType: true,
                usageType: true,
                remove: true,
                addProduct: true,
            };
        case PRODUCT_TYPE.BUNDLE_PRODUCT:
            return {
                quantity: true,
            };
        default:
            return {};
    }
};

const getNormalGroupProductPrice = (groups: Array<any>) => {
    const salePrice = reduce(
        groups,
        (res, item) => res + (item?.salePrice || 0),
        0
    );

    return { salePrice };
};

const ProductPriceUpdateModal = ({
    open,
    onClose,
    data,
}: IProductPriceUpdateModal) => {
    const { groupType, label } = data || {};
    const { product, setProduct } = useContext(ProductDetailContext);
    const priceForm = useFormik<any>({
        initialValues: {} as any,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: schema,
        validate: (values) => {
            const errors: any = {};
            const { specialGroups } = values;
            if (product?.typeOfProduct === PRODUCT_TYPE.SPECIAL_GROUP_SERVICE) {
                const dynamicPriceProduct = find(
                    specialGroups,
                    (item) => item.isDynamicPrice
                );
                const priceDynamicProduct =
                    getPriceDynamicItemInSpecialGroupProduct(
                        dynamicPriceProduct?.id,
                        values
                    );

                if (priceDynamicProduct < 0) {
                    errors.configuration = Messages.dynamicItemPriceNoLess0;
                }
            }

            return errors;
        },
        onSubmit: (values: any) => {
            const input = mapUpdateProductPriceToServer(values);
            onUpdateProduct(omit(input, "isMedicine"));
        },
    });
    const { typeOfProduct } = product || {};
    const { values, setValues, setFieldValue } = priceForm || {};
    const { salePrice, regularPrice, bundles = [] } = values || {};
    const disableInputs = useMemo(() => {
        switch (typeOfProduct) {
            case PRODUCT_TYPE.SERVICE_PRODUCT:
                return {};
            case PRODUCT_TYPE.PREMIUM_SERVICE:
                return {};
            case PRODUCT_TYPE.GROUP_PRODUCT:
                return {
                    salePrice: true,
                    regularPrice: true,
                };
            case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
                return {};
            case PRODUCT_TYPE.BUNDLE_PRODUCT:
                return {};
            default:
                return {};
        }
    }, [typeOfProduct, values?.groups, groupType]);

    useEffect(() => {
        if (open && product) {
            const priceData = getPriceAccordingPriceGroup(groupType, product);
            setValues(priceData);
        }
    }, [open, product]);

    useEffect(() => {
        if (
            typeOfProduct === PRODUCT_TYPE.GROUP_PRODUCT &&
            values?.groups?.length
        ) {
            const price = getNormalGroupProductPrice(values?.groups);
            setValues((values: any) => ({ ...values, ...price }));
        }
    }, [values?.groups, typeOfProduct]);

    useEffect(() => {
        forEach(Object.keys(priceForm.errors), (key) =>
            Notifications.showError(`${priceForm.errors[key]}`)
        );
    }, [priceForm.errors]);

    const onUpdateProduct = (input: any) => {
        Progress.show(
            { method: ProductAPI.update, params: [product?.id, input] },
            (product: any) => {
                setProduct(product);
                Notifications.showSuccess(Messages.updateSuccess);
                onClose();
            }
        );
    };

    const renderProductTypeConfig = () => {
        switch (typeOfProduct) {
            case PRODUCT_TYPE.SERVICE_PRODUCT:
                return (
                    <ServiceConfigurationSelect
                        key={`${values?.service?.id}`}
                        value={values?.service ?? []}
                        onChange={(value) => setFieldValue("service", value)}
                        className="mt-3"
                        // disabled={groupType !== PriceSetTypeFe.Thai}
                        disabled
                    />
                );
            case PRODUCT_TYPE.PREMIUM_SERVICE:
                return (
                    <ProductTypePremiumService
                        configForm={priceForm}
                        disabledSpendingCap
                        disableOption={getDisabledOptions(
                            typeOfProduct,
                            groupType
                        )}
                    />
                );
            case PRODUCT_TYPE.GROUP_PRODUCT:
                return (
                    <ProductTypeGroup
                        configForm={priceForm}
                        disableOption={getDisabledOptions(
                            typeOfProduct,
                            groupType
                        )}
                    />
                );
            case PRODUCT_TYPE.SPECIAL_GROUP_SERVICE:
                return (
                    <SpecialGroupProduct
                        configForm={priceForm}
                        disableOption={getDisabledOptions(
                            typeOfProduct,
                            groupType
                        )}
                    />
                );
            case PRODUCT_TYPE.BUNDLE_PRODUCT:
                return (
                    <BundleProduct
                        configForm={priceForm}
                        disableOption={getDisabledOptions(
                            typeOfProduct,
                            groupType
                        )}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={Messages.priceConfig}
            onSave={() => priceForm.handleSubmit()}
            size="auto"
            width="80%"
        >
            <div className="p-3">
                <InputText
                    label={Messages.group}
                    value={Messages[label]}
                    disabled
                />
                <div className="grid grid-cols-2 gap-4 mt-3">
                    <InputText
                        disabled={disableInputs?.regularPrice}
                        className="col-span-1"
                        value={regularPrice}
                        label={Messages.regularPrice}
                        onChange={(e) => {
                            let value = e.target.value.replace(/\D+/g, "");
                            value = parseInt(value, 10);
                            if (!value) {
                                value = 0;
                            }
                            setFieldValue("regularPrice", e?.target?.value);
                        }}
                    />
                    <InputText
                        disabled={disableInputs?.salePrice}
                        className="col-span-1"
                        label={Messages.salePrice}
                        value={salePrice}
                        onChange={(e) => {
                            let value = e.target.value.replace(/\D+/g, "");
                            value = parseInt(value, 10);
                            if (!value) {
                                value = 0;
                            }
                            setFieldValue("salePrice", e?.target?.value);
                        }}
                    />
                </div>
                <div className="mt-3">{renderProductTypeConfig()}</div>
            </div>
        </Drawer>
    );
};

const ProductDetailPriceConfig = () => {
    const { product } = useContext(ProductDetailContext);
    const { salePrice, regularPrice, priceSet, ...rest } = product || {};
    const [openUpdate, setOpenUpdate] = useState<{
        open: boolean;
        data?: any;
    }>({
        open: false,
    });
    const priceData = product
        ? [
              {
                  groupType: PriceSetTypeFe.Thai,
                  label: "thaiNationality",
                  regularPrice,
                  salePrice,
              },
              {
                  groupType: PriceSetTypeFe.Foreigner,
                  label: "foreigner",
                  regularPrice: getProductPrice(
                      "regularPrice",
                      product,
                      PriceSetTypeFe.Foreigner
                  ),
                  salePrice: getProductPrice(
                      "salePrice",
                      product,
                      PriceSetTypeFe.Foreigner
                  ),
              },
              {
                  groupType: PriceSetTypeFe.Employee,
                  label: "employee",
                  regularPrice: getProductPrice(
                      "regularPrice",
                      product,
                      PriceSetTypeFe.Employee
                  ),
                  salePrice: getProductPrice(
                      "salePrice",
                      product,
                      PriceSetTypeFe.Employee
                  ),
              },
              {
                  groupType: PriceSetTypeFe.Vip,
                  label: "vip",
                  regularPrice: getProductPrice(
                      "regularPrice",
                      product,
                      PriceSetTypeFe.Vip
                  ),
                  salePrice: getProductPrice(
                      "salePrice",
                      product,
                      PriceSetTypeFe.Vip
                  ),
              },
          ]
        : [];

    const columns: IColumnsProps = [
        {
            title: Messages.group,
            dataIndex: "label",
            render: (label: any) => Messages[label],
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: (regularPrice, item) => {
                return (
                    <CurrencyFormat
                        value={regularPrice}
                        displayType="text"
                        prefix="฿ "
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                );
            },
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePrice",
            render: (regularPrice) => (
                <CurrencyFormat
                    value={regularPrice}
                    displayType="text"
                    prefix="฿ "
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.action,
            dataIndex: "",
            align: "center",
            render: (data: any) => (
                <div className="w-full justify-center flex">
                    <Button
                        variant="trans"
                        iconName="edit"
                        onClick={() =>
                            setOpenUpdate({
                                open: true,
                                data,
                            })
                        }
                    />
                </div>
            ),
        },
    ];

    return (
        <div className="mt-3">
            <label>{Messages.priceConfig}</label>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={priceData ?? []}
                pagination={false}
            />
            {openUpdate?.open && openUpdate?.data && (
                <ProductPriceUpdateModal
                    data={openUpdate.data}
                    open={openUpdate.open}
                    onClose={() => setOpenUpdate({ open: false })}
                />
            )}
        </div>
    );
};

export default ProductDetailPriceConfig;
