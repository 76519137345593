import { Button, Notifications } from "d-react-components";
import { map, pick, round } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    PaymentMethod,
    SourceOfPayment,
    useAllPayment2C2PForAdminQuery,
    useGetPaymentMethodSettingListQuery,
    useListAllPaymentForAdminLazyQuery,
    useUpdatePaymentForAdminMutation,
} from "../../../api/hooks";
import { OrderRefType, PaymentContext } from "../../../context/payment";
import { QuotationDetailContext } from "../../../context/quotation";
import { IPayment } from "../../../interfaces/payment";
import Messages from "../../../languages/Messages";
import PaymentInfoItem from "../../order/share/payment/PaymentInfoItem";
import SplitPaymentDrawer from "../../order/share/payment/SplitPaymentDrawer";

const QuotationPayment = () => {
    const { quotation, loadQuotationDetail } = useContext(
        QuotationDetailContext
    );
    const [openSplitPaymentModal, setOpenSplitPaymentModal] = useState(false);
    const [getPaymentList, { data, refetch }] =
        useListAllPaymentForAdminLazyQuery();
    const { data: dataPaymentMethodsList } =
        useGetPaymentMethodSettingListQuery({
            variables: {
                paginate: {
                    page: 1,
                    limit: 100,
                },
            },
        });
    const { data: payment2C2POptionsList } = useAllPayment2C2PForAdminQuery();
    const [splitPayment] = useUpdatePaymentForAdminMutation();

    const paymentList = data?.data?.data ?? [];
    const paymentMethodsList = [
        ...(dataPaymentMethodsList?.data?.data ?? []),
        {
            paymentConfigurationType: PaymentMethod.Default,
            title: "Default",
            name: "Default",
        },
    ];

    const isShowSplitPayment = useMemo(() => {
        return !quotation?.hasOrder;
    }, [quotation]);

    const onClickSplitPayment = () => {
        setOpenSplitPaymentModal(true);
    };

    useEffect(() => {
        if (quotation?.id) {
            getPaymentList({
                variables: {
                    refId: quotation?.id,
                },
            });
        }
    }, [quotation]);

    const onChangePaymentInfo = () => {
        refetch();
    };

    const handleSplitPayment = (
        splitPaymentList: any,
        remainAmount: number
    ) => {
        const arrayTotal = splitPaymentList.map((item: any) => item?.total);
        if (arrayTotal.includes(0) || arrayTotal.includes("0")) {
            Notifications.showError(Messages.pleaseFillAllThePaymentAmount);
            return;
        }
        if (round(remainAmount * 100) / 100 !== 0) {
            Notifications.showError(Messages.paymentIsNotEnough);
            return;
        }

        if (splitPaymentList[0].paymentMethod === PaymentMethod.Default) {
            Notifications.showError(
                Messages.youNeedToChooseASpecificPaymentMethod
            );
            return;
        }

        splitPayment({
            variables: {
                refId: quotation?.id as string,
                sourceOfPayment: SourceOfPayment.Quotation,
                payload: splitPaymentList.map((payment: any) => ({
                    ...pick(payment, [
                        "paymentMethod",
                        "paymentOption",
                        "total",
                    ]),
                    ...(payment.paymentNo ? { id: payment.id } : {}),
                })),
            },
        })
            .then((resp) => {
                setOpenSplitPaymentModal(false);
                refetch();
            })
            .catch((err) => {
                Notifications.showError(err);
            });
    };

    return (
        <PaymentContext.Provider
            value={
                {
                    paymentMethodsList: paymentMethodsList ?? [],
                    payment2C2POptionsList:
                        payment2C2POptionsList?.data?.data ?? [],
                    ref: quotation,
                    reloadRef: loadQuotationDetail,
                    refType: OrderRefType.QUOTATION,
                } as any
            }
        >
            <div className="px-4 py-3 border my-3">
                <div className="flex items-center">
                    <h5 className="font-semibold mb-2 flex-1">
                        {Messages.payments}
                    </h5>
                    <div>
                        {isShowSplitPayment && (
                            <Button
                                iconName="call_split"
                                onClick={onClickSplitPayment}
                            >
                                {Messages.splitPayment}
                            </Button>
                        )}
                    </div>
                </div>
                {paymentList?.map((payment, index) => (
                    <PaymentInfoItem
                        payment={payment as IPayment}
                        key={index}
                        index={index}
                        onChangePaymentInfo={onChangePaymentInfo}
                    />
                ))}
                {openSplitPaymentModal && (
                    <SplitPaymentDrawer
                        open={openSplitPaymentModal}
                        onClose={() => setOpenSplitPaymentModal(false)}
                        paymentList={map(paymentList, (item) => ({
                            ...item,
                            total: `${Number.parseFloat(
                                item.total as any
                            ).toFixed(2)}`,
                        }))}
                        onSave={handleSplitPayment}
                    />
                )}
            </div>
        </PaymentContext.Provider>
    );
};

export default QuotationPayment;
