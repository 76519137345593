import React from "react";
import { IAddress } from "../interfaces/address";
import { IDeliveryOrder } from "../interfaces/delivery-order";
import { IOrder } from "../interfaces/order";
import { IProduct } from "../interfaces/product";
import { IVoucher } from "../interfaces/voucher";
import { AdminPayment, AdminPaymentConfiguration } from "../api/hooks";
import { ICredit } from "../interfaces/credit";

interface OrderCreateState {
    productFilters: any;
    setProductFilters: (productFilters: any) => void;
    setProductList: (values: any) => void;
    productList: IProduct[];
    productCustomList?: any;
    setProductCustomList?: any;
    discountList?: any;
}

const orderCreateState: OrderCreateState = {
    productFilters: null,
    setProductFilters: () => {},
    productList: [],
    setProductList: () => {},
};

export const OrderCreateContext = React.createContext(orderCreateState);

export interface IOrderDetailState {
    order?: IOrder | null;
    loadOrderDetail: () => void;
    shippingList?: IAddress[];
    setShippingList: any;
    deliveryList?: IDeliveryOrder[];
    loadDeliveryList: any;
    paymentList?: AdminPayment[];
    paymentMethodsList?: AdminPaymentConfiguration[];
    reloadPaymentList: any;
}

const orderDetailState: IOrderDetailState = {
    order: undefined,
    loadOrderDetail: () => {},
    shippingList: [],
    setShippingList: () => {},
    deliveryList: [],
    loadDeliveryList: () => {},
    paymentList: [],
    paymentMethodsList: [],
    reloadPaymentList: () => {},
};

export const OrderDetailContext = React.createContext(orderDetailState);

export interface IOrderVoucherState {
    voucherList: IVoucher[];
    selectedVoucherList: IVoucher[];
    setSelectedVoucherList: any;
    searchText: string;
}

const orderVoucherState: IOrderVoucherState = {
    voucherList: [],
    selectedVoucherList: [],
    setSelectedVoucherList: undefined,
    searchText: "",
};

export const OrderVoucherContext = React.createContext(orderVoucherState);

export interface IOrderCreditState {
    creditList: ICredit[];
    selectedCreditList: ICredit[];
    setSelectedCreditList: any;
    searchText: string;
}

const orderCreditState: IOrderCreditState = {
    creditList: [],
    selectedCreditList: [],
    setSelectedCreditList: undefined,
    searchText: "",
};

export const OrderCreditContext = React.createContext(orderCreditState);
