import {
    find,
    forEach,
    includes,
    isNil,
    isUndefined,
    omitBy,
    split,
} from "lodash";
import { JobCostTemplate } from "../api/hooks";
import {
    PERMISSION_MODULES,
    ROLE_ACCESS_TYPES,
    PERMISSION_TYPE,
    USER_STATUS,
} from "../constant/user";
import { IBranch } from "./branch";
import { IJobCost, IServiceConfiguration } from "./service";

export enum DeliveryStatusManagement {
    DELIVERY_PENDING = "DELIVERY_PENDING",
    DELIVERY_BOOKED = "DELIVERY_BOOKED",
    IN_DELIVERY = "IN_DELIVERY",
    DELIVERY_COMPLETED = "DELIVERY_COMPLETED",
    DELIVERY_CANCELLED = "DELIVERY_CANCELLED",
    DELIVERY_RETURNED = "DELIVERY_RETURNED",
}

export enum OrderStatusManagement {
    PENDING_PAYMENT = "PENDING_PAYMENT",
    PENDING_CONFIRMATION = "PENDING_CONFIRMATION",
    PARTIALLY_PAID = "PARTIALLY_PAID",
    ORDER_PROCESSING = "ORDER_PROCESSING",
    DELIVERY_PROCESSING = "DELIVERY_PROCESSING",
    DELIVERED = "DELIVERED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    REFUNDED = "REFUNDED",
}
export enum JobType {
    NORMAL_EMPLOYEE = "NORMAL_EMPLOYEE",
    DOCTOR = "DOCTOR",
    THERAPIST = "THERAPIST",
    NURSE = "NURSE",
    DRIVER = "DRIVER",
}

export enum DateSchedule {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export enum SpecialAccessType {
    ONE_TIME_USE = "ONE_TIME_USE",
    MULTIPLE_TIME_USE = "MULTIPLE_TIME_USE",
}

export enum IUserStatus {
    ACTIVE,
    INACTIVE,
}

export interface IUserRole {
    id: string;
    name: string;
    permissions?: any[];
    permissionType?: string;
}

export interface SpecialAccess {
    specialAccessType: SpecialAccessType;
    status: boolean;
    code: string;
    expired: string;
}

export interface WorkingSchedule {
    date: DateSchedule;
    order?: number;
    schedule: {
        start: number;
        end: number;
    };
    branch: IBranch;
}

export interface IUser {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    username: string;
    nickName: string;
    companyId: string;
    avatar: string;
    createdAt: string;
    lastLogin: string;
    updatedAt: string;
    email: string;
    isOtpAuth?: boolean;
    otpAuthSecretCode?: string;
    otpAuthUrl?: string;
    status: IUserStatus;
    role?: IUserRole;
    jobType?: JobType;
    orderStatusManagement?: OrderStatusManagement;
    deliveryStatusManagement?: DeliveryStatusManagement;
    authenticationStatus?: boolean;
    authenticationCode?: string;
    specialAccess: SpecialAccess;
    branch: IBranch[];
    defaultWorkingSchedule?: WorkingSchedule[];
    jobCostFee?: Array<{
        template?: JobCostTemplate;
        presets?: Array<IJobCost>;
    }>;
    commissionServiceType?: string;
    commissionService: { value: string; service: IServiceConfiguration }[];
    jobCostFeature?: boolean;
}

export interface UserWorkingScheduleDate {
    day: string;
    isWorking: boolean;
    occupiedSlots: any[];
    workingSchedule: WorkingSchedule[];
}

export const getDefaultRolePermissions = () => {
    const values: string[] = [];
    forEach(PERMISSION_MODULES, (access, index) => {
        values.push(`0-${index}`);
        const accessTypeList = access.accessTypes ?? ROLE_ACCESS_TYPES;
        forEach(accessTypeList, (spec, indexSpec) => {
            values.push(`0-${index}-${indexSpec}`);
        });
    });
    return values;
};

export const mapUserRolePermissionToSever = (permissions: any[]) => {
    const values: any[] = [];
    forEach(PERMISSION_MODULES, (access, index) => {
        const accessValue = {};
        const accessTypeList = access.accessTypes ?? ROLE_ACCESS_TYPES;
        forEach(accessTypeList, (spec, indexSpec) => {
            const value = `0-${index}-${indexSpec}`;
            const isGrantPermission = includes(permissions, value);
            (accessValue as any)[spec?.id] = isGrantPermission;
        });
        values.push({ key: access.key, access: accessValue });
    });
    return values;
};

export const mapUserRolePermissionFromSever = (permissions: any[]) => {
    const values: string[] = [];

    forEach(PERMISSION_MODULES, (access, index) => {
        const accessTypeList = access.accessTypes ?? ROLE_ACCESS_TYPES;
        forEach(accessTypeList, (spec, indexSpec) => {
            const permission = find(
                permissions,
                (item) => item.key === access?.key
            );
            if (permission && permission?.access?.[spec?.id] === true) {
                values.push(`0-${index}-${indexSpec}`);
            }
        });
    });

    return values;
};

export const mapUserRoleToSever = (data: any) => {
    const { name, permissions, permissionType } = data;
    const result: any = {
        name,
        permissionType,
    };
    if (permissionType === PERMISSION_TYPE.CUSTOM) {
        result.permissions = mapUserRolePermissionToSever(permissions);
    }
    return result;
};

export const mapUserFromSer = (dataServer: any) => {
    return {
        ...dataServer,
        roleId: dataServer?.role?.id,
        fullName: `${dataServer?.firstName} ${dataServer?.lastName}`,
    };
};

export const mapUserGeneralInfoToSer = (input: any) => {
    const {
        firstName,
        lastName,
        username,
        nickName,
        companyId,
        avatar,
        email,
        status,
        roleId,
        isOtpAuth,
        otpAuthSecretCode,
        jobType,
        orderStatusManagement,
        deliveryStatusManagement,
        password,
        specialAccess,
        jobCostFeature,
    } = input;
    return omitBy(
        {
            avatar,
            firstName,
            lastName,
            username,
            nickName,
            companyId,
            email,
            ...(isUndefined(status)
                ? {}
                : { status: status === USER_STATUS.ACTIVE }),
            role: roleId,
            authenticationStatus: isOtpAuth,
            authenticationCode: otpAuthSecretCode,
            jobType,
            orderStatusManagement,
            deliveryStatusManagement,
            jobCostFeature,
            password,
            ...(specialAccess
                ? {
                      specialAccess: {
                          ...specialAccess,
                          status: specialAccess?.status === USER_STATUS.ACTIVE,
                          expired: specialAccess?.expired?.toDate(),
                      },
                  }
                : {}),
        },
        isNil
    );
};

export const mapUserPasswordToSer = (input: any) => {
    const { password, confirmPassword } = input;
    return {
        password,
        confirmPassword,
    };
};

export const mapUserResetPasswordToSer = (input: any) => {
    const { password, confirmPassword } = input;
    return {
        newPassword: password,
        confirmPassword,
    };
};

export default IUser;
