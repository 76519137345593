import * as Yup from "yup";

export const ScheduleSchema = Yup.object().shape({
    date: Yup.date().required("Required field!"),
    branch: Yup.object().required("Required field!"),
    from: Yup.string().required("Required field!"),
    to: Yup.string().required("Required field!"),
});

export const TemporaryScheduleSchema = Yup.object().shape({
    user: Yup.object().required("User required!"),
    schedules: Yup.array()
        .of(ScheduleSchema as any)
        .min(1)
        .required("Schedules required!"),
});
