import { gql } from "@apollo/client";
import { F_COUNTRY } from "./address";
import { F_ADMIN_USER_BASIC } from "./admin";
import { F_PAGINATE } from "./app";

export const F_AGENCY = gql`
    ${F_ADMIN_USER_BASIC}
    ${F_COUNTRY}
    fragment F_AGENCY on AdminAgency {
        id
        updatedAt
        createdAt
        createByAdmin {
            ...F_ADMIN_USER_BASIC
        }
        agencyNo
        typeOfAgency
        typeOfIdentity
        countryOfOrigin {
            ...F_COUNTRY
        }
        name
        address
        codeNumber
        phone
        picture
        remark
        representativesName
        representativesPrimaryPhone
        representativesAlternatePhone
        status
    }
`;
export const F_AGENCY_BASIC = gql`
    fragment F_AGENCY_BASIC on AdminAgencyBasic {
        id
        name
        agencyNo
        picture
        codeNumber
    }
`;

export const F_AGENCIES = gql`
    ${F_AGENCY}
    ${F_PAGINATE}
    fragment F_AGENCIES on ListAdminAgencyResponse {
        data {
            ...F_AGENCY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
