import { AwesomeTableComponent } from "d-react-components";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useListMaterialLazyQuery } from "../../../api/hooks";
import { MaterialPurchaseContext } from "../../../context/material";
import Messages from "../../../languages/Messages";
import MaterialNameView from "../../material/share/MaterialNameView";
import { SIZE_OPTIONS } from "../crud/MaterialPurchaseMaterialList";

const ProductList = ({ pagination, setPagination, addNewProduct }: any) => {
    const productItemTable = useRef<any>(null);
    const { productFilters, materialRequest } = useContext(
        MaterialPurchaseContext
    );
    const [getProductList] = useListMaterialLazyQuery();

    const getProduct = async (pagination: any) => {
        return getProductList({
            variables: {
                paginate: {
                    page: pagination?.pageIndex as any,
                    limit: pagination?.pageSize,
                    search: productFilters?.textSearch ?? "",
                    sort: {
                        createdAt: -1,
                    },
                    // ...(productFilters?.categories?.length
                    //     ? {
                    //           categories: productFilters?.categories?.map(
                    //               (item: any) => item.id
                    //           ),
                    //       }
                    //     : {}),
                },
            },
        });
    };

    const materialList = useMemo(() => {
        return materialRequest?.materials?.map((item) => ({
            ...item,
            ...item.material,
        }));
    }, [materialRequest?.materials]);

    const columns = [
        {
            title: Messages.item,
            dataIndex: "product",
            width: 300,
            ellipsis: true,
            render: (product: any, item: any) => {
                return <MaterialNameView material={item} showLink={false} />;
            },
        },
        {
            title: Messages.avgPurchase,
            dataIndex: "avgPurchasingUnitCost",
            width: 120,
            render: (price: any, item: any) => (
                <>
                    {item.avgPurchasingUnitCost} THB/{item.purchaseUnit?.name}
                </>
            ),
        },
        {
            title: Messages.lastPurchase,
            dataIndex: "lastPurchasingUnitCost",
            width: 120,
            render: (price: any, item: any) => (
                <>
                    {item.lastPurchasingUnitCost} THB/{item.purchaseUnit?.name}
                </>
            ),
        },
        // {
        //     title: Messages.action,
        //     dataIndex: "",
        //     width: 30,
        //     render: (productItem: any, item: any) => {
        //         return (
        //             <span
        //                 className="cursor-pointer text-primary"
        //                 onClick={() => addNewProduct(item)}
        //             >
        //                 <Icon name="add_shopping_cart_icon" />
        //             </span>
        //         );
        //     },
        // },
    ];

    useEffect(() => {
        productItemTable?.current?.refresh();
    }, [productFilters?.textSearch, productFilters?.categories]);

    return (
        <div>
            <AwesomeTableComponent
                ref={productItemTable}
                // source={(currentPage) => {
                //     setPagination(currentPage);
                //     return getProduct(currentPage);
                // }}
                // transformer={(res) => res?.data?.data?.data}
                columns={columns}
                isScroll={false}
                className="height-max-content mt-3 overflow-auto"
                dataSource={materialList}
                defaultPagination={{
                    pageSize: SIZE_OPTIONS[0],
                    pageIndex: 1,
                    showQuickJumper: false,
                    pageSizeOptions: SIZE_OPTIONS,
                }}
            />
        </div>
    );
};

export default ProductList;
