import {
    AwesomeTableComponent,
    Button,
    TimeUtils,
    ViewLabelStatus,
} from "d-react-components";
import ClassNames from "classnames";
import React, { Fragment, useState } from "react";
import UserAvatarName from "../../../common/avatar/UserAvatarName";
import Drawer from "../../../common/Drawer";
import { DELIVERY_DRIVER_CONFIRMATION_STATUSES } from "../../../constant/delivery-driver";
import {
    IDeliveryConfirmationUser,
    IDeliveryDriver,
} from "../../../interfaces/delivery-driver";
import Messages from "../../../languages/Messages";
import ProductName from "../../quotation/share/ProductName";

export interface IDeliveryConfirmationViewProps {
    status?: string;
    receiver?: IDeliveryConfirmationUser;
    receiverLabel?: string;
    giver?: IDeliveryConfirmationUser;
    giverLabel?: string;
    delivery?: IDeliveryDriver;
    confirmationLabel?: string;
    confirmationTime?: any;
}

const DeliveryConfirmationView: React.FC<IDeliveryConfirmationViewProps> = ({
    status,
    receiver,
    giver,
    receiverLabel = Messages.driver,
    giverLabel = Messages.supervisor,
    delivery,
    confirmationLabel = Messages.originPickupConfirmation,
    confirmationTime,
}) => {
    const { stocks = [], stockOffList = [] } = delivery || {};

    const [openListItemDrawer, setOpenListItemDrawer] = useState({
        open: false,
    });

    const renderViewButton = () => {
        const renderConfirmationView = () => {
            return (
                <div className="p-4 bg-primary-light">
                    <div className="text font-weight-bold mb-2">
                        {confirmationLabel}
                    </div>
                    <div className="flex-center-y text py-2 border-b-1 border-b-primary">
                        <div>{Messages.deliveryNo}</div>
                        <div className="text-primary ml-3">
                            {delivery?.deliveryNo}
                        </div>
                    </div>
                    <div className="flex-center-y text py-2 border-b-1 border-b-primary">
                        <div>{Messages.signedBy}</div>
                        <div className="ml-2 font-semibold">{giver?.fullName}</div>
                        {!giver?.fullName && giver?.createByAdmin && (
                            <UserAvatarName
                                user={giver?.createByAdmin}
                                size="x-small"
                                className="ml-2"
                            />
                        )}
                        <div className="ml-2">
                            {TimeUtils.convertMiliToDateTime(confirmationTime)}
                        </div>
                    </div>
                    <div
                        className={`flex-center-y text py-2  ${ClassNames({
                            "border-b-1 border-b-primary": !!receiver?.remark,
                        })}`}
                    >
                        <div>{Messages.signedBy}</div>
                        <div className="ml-2 font-semibold">
                            {receiver?.fullName}
                        </div>
                        {!receiver?.fullName && receiver?.createByAdmin && (
                            <UserAvatarName
                                user={receiver?.createByAdmin}
                                size="xx-small"
                                className="ml-2"
                            />
                        )}
                        <div className="ml-3">
                            {TimeUtils.convertMiliToDateTime(confirmationTime)}
                        </div>
                    </div>
                    {receiver?.remark && (
                        <div className="flex-center-y text py-2">
                            <div>{Messages.remark} </div>
                            <div className="ml-2">
                                {` : "${receiver?.remark}"`}
                            </div>
                        </div>
                    )}
                </div>
            );
        };

        const renderStockList = () => {
            const columns = [
                {
                    title: Messages.item,
                    dataIndex: "product",
                    render: (product: any, item: any) => {
                        return <ProductName item={product} />;
                    },
                    width: 500,
                },
                {
                    title: Messages.quantity,
                    dataIndex: "quantity",
                    width: 200,
                    render: (receiveQty: any, item: any) => {
                        return receiveQty;
                    },
                },
            ];

            return (
                <div className="mt-3">
                    <div className="text font-semibold">{Messages.items}</div>
                    <AwesomeTableComponent
                        className="height-auto"
                        baseColumnProps={{ width: 150, align: "center" }}
                        dataSource={stocks ?? []}
                        columns={columns}
                        isScroll={false}
                        isPagination={false}
                        classNameTable="tableItemDelivery"
                        rowClassName={(product) => {
                            let rowClassName = "rowAwesomeTable ";
                            if (product.isGift) {
                                rowClassName += "productGiftTableItemRow";
                            }
                            return rowClassName;
                        }}
                    />
                </div>
            );
        };

        const renderStockOffList = () => {
            const columnsOffList = [
                {
                    title: Messages.item,
                    dataIndex: "product",
                    render: (product: any, item: any) => {
                        return <ProductName item={product} />;
                    },
                    width: 500,
                },
                {
                    title: Messages.quantity,
                    dataIndex: "quantity",
                    width: 200,
                    render: (receiveQty: any, item: any) => {
                        return receiveQty;
                    },
                },
            ];

            return (
                <div className="mt-3">
                    <div className="text font-semibold">
                        {Messages.itemsOffTheList}
                    </div>
                    <AwesomeTableComponent
                        className="height-auto mt-3"
                        baseColumnProps={{ width: 150, align: "center" }}
                        dataSource={stockOffList ?? []}
                        columns={columnsOffList}
                        isScroll={false}
                        isPagination={false}
                        classNameTable="tableItemDelivery"
                        rowClassName={(product) => {
                            let rowClassName = "rowAwesomeTable ";
                            if (product.isGift) {
                                rowClassName += "productGiftTableItemRow";
                            }
                            return rowClassName;
                        }}
                    />
                </div>
            );
        };

        return (
            <Fragment>
                <Button
                    variant="outline"
                    className="mt-3"
                    onClick={() => {
                        setOpenListItemDrawer({ open: true });
                    }}
                >
                    {Messages.view}
                </Button>
                <Drawer
                    open={openListItemDrawer.open}
                    onClose={() => setOpenListItemDrawer({ open: false })}
                    size="auto"
                    width="650px"
                    title={Messages.listItems}
                    hideFooter
                >
                    <div className="p-4">
                        {renderConfirmationView()}
                        {renderStockList()}
                        {stockOffList?.length > 0 && renderStockOffList()}
                    </div>
                </Drawer>
            </Fragment>
        );
    };

    const renderReceiverView = () => {
        const {
            signature,
            fullName,
            remark,
            proofs,
            createByAdmin,
            createdAt,
        } = receiver || {};
        return (
            <div className="border p-4 mt-3">
                <div className="flex-center-y">
                    <div className="text font-weight-bold block">
                        {receiverLabel} :
                    </div>
                    {createByAdmin && (
                        <UserAvatarName
                            user={createByAdmin}
                            size="xx-small"
                            className="ml-2"
                        />
                    )}
                    {fullName && !createByAdmin && (
                        <div className="text ml-2">{fullName}</div>
                    )}
                </div>
                <img src={signature} className="image-reg-medium mt-3" />
                <div className="mt-3">{Messages.receiverConfirmDes}</div>
                {renderViewButton()}
                {remark && (
                    <div className="mt-3 p-3 bg-primary-light">{remark}</div>
                )}
            </div>
        );
    };
    const renderGiverView = () => {
        const {
            signature,
            fullName,
            remark,
            proofs,
            createByAdmin,
            createdAt,
        } = giver || {};
        return (
            <div className="border p-4 mt-3">
                <div className="flex-center-y">
                    <div className="text font-weight-bold block">
                        {giverLabel} :
                    </div>
                    {createByAdmin && (
                        <UserAvatarName
                            user={createByAdmin}
                            size="x-small"
                            className="ml-2"
                        />
                    )}
                    {fullName && !createByAdmin && (
                        <div className="text ml-2">{fullName}</div>
                    )}
                </div>
                <img src={signature} className="image-reg-medium mt-3" />
                <div className="mt-3">{Messages.giverConfirmDes}</div>
                {renderViewButton()}
                {remark && (
                    <div className="mt-3 p-3 bg-primary-light">{remark}</div>
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            <ViewLabelStatus
                className="mt-3"
                status={status}
                listStatus={DELIVERY_DRIVER_CONFIRMATION_STATUSES}
            />
            {renderReceiverView()}
            {renderGiverView()}
        </React.Fragment>
    );
};

export default DeliveryConfirmationView;
