import {
    AwesomeTableComponent,
    IColumnsProps,
    TimeUtils,
} from "d-react-components";
import { debounce } from "lodash";
import { useContext, useState } from "react";
import { generatePath } from "react-router";
import AppLink from "../../../../common/AppLink";
import UserAvatarName from "../../../../common/avatar/UserAvatarName";
import { StockTransferDetailContext } from "../../../../context/stock-transfer";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";

const StockTransferRequest = () => {
    const { stockTransfer } = useContext(StockTransferDetailContext);
    const [textSearch, setTextSearch] = useState<string>("");

    const columns: IColumnsProps = [
        {
            title: "ID",
            dataIndex: "requestNo",
            render: (requestNo, item) => (
                <AppLink
                    to={generatePath(Path.INVENTORY_REQUEST_STOCK_DETAIL, {
                        stockRequestId: item.id,
                    })}
                >
                    {requestNo}
                </AppLink>
            ),
        },
        {
            title: Messages.createdBy,
            dataIndex: "createByAdmin",
            render: (user) => (
                <UserAvatarName
                    user={user}
                    size="xx-small"
                    variant="square"
                    showInfo={{ showOPD: false }}
                />
            ),
        },
        {
            title: Messages.createdAt,
            dataIndex: "createdAt",
            render: TimeUtils.toDateTime,
        },
    ];

    const onChangeSearch = debounce((event) => {
        const search = event.target.value;
        setTextSearch(search);
    }, 0);

    return (
        <div className="mt-3">
            <div className="flex-row-between-center">
                <label>{Messages.items}</label>
            </div>
            {/* <InputTextSearch onChange={onChangeSearch} className="mt-3" /> */}
            <AwesomeTableComponent
                columns={columns}
                dataSource={stockTransfer?.requests ?? []}
                className="mt-3"
                pagination={false}
            />
        </div>
    );
};

export default StockTransferRequest;
