import { CheckboxGroup, InputText, Select } from "d-react-components";
import React, { Fragment, useContext } from "react";
import { JobCostTemplate } from "../../../api/hooks";
// import UploadAvatar from "../../../common/upload/UploadAvatar";
import {
    DELIVERY_STATUS_MANAGEMENTS,
    JOB_TYPES,
    ORDER_STATUS_MANAGEMENTS,
    USER_STATUSES,
} from "../../../constant/user";
import { UserCrudContext } from "../../../context/user";
import { JobType } from "../../../interfaces/user";
import Messages from "../../../languages/Messages";
import { SIMPLE_CHOICES } from "../../../constant/customer";

const UserCrudSetting = ({ isCreate }: any) => {
    const { userForm, roleList } = useContext(UserCrudContext);

    const content = (
        <div className="bg-white">
            <div className="row ">
                <Select
                    label={Messages.status}
                    value={userForm.values?.status}
                    error={userForm.errors?.status}
                    className="col-12 mb-3"
                    dataSource={USER_STATUSES}
                    getLabel={(item) => (Messages as any)[item?.label]}
                    onChange={(value) =>
                        userForm.setFieldValue("status", value)
                    }
                />
            </div>
            <div className="row ">
                <Select
                    label={Messages.role}
                    value={userForm.values?.roleId}
                    error={userForm.errors?.roleId}
                    className="col-12 mb-3"
                    dataSource={roleList}
                    getLabel={(item) => item.name}
                    onChange={(value) =>
                        userForm.setFieldValue("roleId", value)
                    }
                />
            </div>
            <div className="row ">
                <Select
                    label={Messages.jobType}
                    value={userForm.values?.jobType}
                    error={userForm.errors?.jobType}
                    className="col-12 mb-3"
                    dataSource={JOB_TYPES}
                    getLabel={(item) => Messages[item.label] || item.label}
                    getValue={(item) => item.key}
                    onChange={(value) =>
                        userForm.setFieldValue("jobType", value)
                    }
                />
            </div>
            <div className="row ">
                <Select
                    label={Messages.jobCostFeature}
                    value={userForm.values?.jobCostFeature ?? false}
                    error={userForm.errors?.jobCostFeature}
                    className="col-12 mb-3"
                    dataSource={SIMPLE_CHOICES}
                    getLabel={(item) => Messages[item.label]}
                    onChange={(value) =>
                        userForm.setFieldValue("jobCostFeature", value)
                    }
                />
            </div>
            <div className="row ">
                <div className="col-12 mb-3">
                    <CheckboxGroup
                        showSelectAll
                        label={Messages.orderStatusManagement}
                        dataSource={ORDER_STATUS_MANAGEMENTS}
                        value={userForm.values?.orderStatusManagement}
                        numberOfColumns="3"
                        getLabel={(item) => Messages[item.label] || item.label}
                        getValue={(item) => item.key}
                        onChange={(value) => {
                            userForm.setFieldValue(
                                "orderStatusManagement",
                                value
                            );
                        }}
                    />
                </div>
            </div>
            <div className="row ">
                <div className="col-12 mb-3">
                    <CheckboxGroup
                        showSelectAll
                        label={Messages.deliveryStatusManagement}
                        dataSource={DELIVERY_STATUS_MANAGEMENTS}
                        value={userForm.values?.deliveryStatusManagement}
                        numberOfColumns="3"
                        getLabel={(item) => Messages[item.label] || item.label}
                        getValue={(item) => item.key}
                        onChange={(value) => {
                            userForm.setFieldValue(
                                "deliveryStatusManagement",
                                value
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
    if (isCreate && userForm.values?.jobType === JobType.THERAPIST) {
        return (
            <Fragment>
                {content}
                <UserCrudTherapistFee />
            </Fragment>
        );
    }
    return content;
};

export default UserCrudSetting;

export const UserCrudTherapistFee = () => {
    const { userForm } = useContext(UserCrudContext);

    return (
        <div className="bg-white">
            <div className="row ">
                <InputText
                    label={Messages.JcTemplate_1}
                    value={userForm.values?.feeTemplate1}
                    error={userForm.errors?.feeTemplate1}
                    className="col-12 mb-3"
                    onChange={(event) =>
                        userForm.setFieldValue(
                            "feeTemplate1",
                            event?.target?.value
                        )
                    }
                />
            </div>
            <div className="row ">
                <InputText
                    label={Messages.JcTemplate_3}
                    value={userForm.values?.feeTemplate3}
                    error={userForm.errors?.feeTemplate3}
                    className="col-12 mb-3"
                    onChange={(event) =>
                        userForm.setFieldValue(
                            "feeTemplate3",
                            event?.target?.value
                        )
                    }
                />
            </div>
        </div>
    );
};

export const UserCrudDoctorFee = () => {
    const { userForm } = useContext(UserCrudContext);
    const { values, errors, setFieldValue, setValues } = userForm || {};

    return (
        <div className="bg-white">
            <div className="row ">
                <InputText
                    label={Messages.gaSurgery}
                    value={values?.amountGA}
                    error={errors?.amountGA}
                    className="col-12 mb-3"
                    onChange={(event) =>
                        userForm.setFieldValue("amountGA", event?.target?.value)
                    }
                />
            </div>
            <div className="row ">
                <InputText
                    label={Messages.laSurgery}
                    value={values?.amountLA}
                    error={errors?.amountLA}
                    className="col-12 mb-3"
                    onChange={(event) =>
                        userForm.setFieldValue("amountLA", event?.target?.value)
                    }
                />
            </div>
        </div>
    );
};

export const UserCrudAnesthesiologistFee = () => {
    const { userForm } = useContext(UserCrudContext);
    const { values, errors, setFieldValue, setValues } = userForm || {};

    return (
        <div className="bg-white">
            <div className="row ">
                <InputText
                    label={Messages.primaryFee}
                    value={values?.primaryFee}
                    error={errors?.primaryFee}
                    className="col-12 mb-3"
                    onChange={(event) =>
                        userForm.setFieldValue(
                            "primaryFee",
                            event?.target?.value
                        )
                    }
                />
            </div>
            <div className="row ">
                <InputText
                    label={Messages.additionalFeePer}
                    value={values?.additionalFee}
                    error={errors?.additionalFee}
                    className="col-12 mb-3"
                    onChange={(event) =>
                        userForm.setFieldValue(
                            "additionalFee",
                            event?.target?.value
                        )
                    }
                />
            </div>
        </div>
    );
};
