import { gql } from "@apollo/client";
import API from "../API";
import { F_APPOINTMENT, F_APPOINTMENTS } from "../fragments/appointment";

export const getAppointments = gql`
    ${F_APPOINTMENTS}
    query getAppointments($input: AdminAppointmentPaginateRequest!) {
        data: listAppointmentForAdmin(paginate: $input) {
            ...F_APPOINTMENTS
        }
    }
`;

export const getAppointment = gql`
    ${F_APPOINTMENT}
    query getAppointment($id: String!) {
        data: detailAppointmentForAdmin(id: $id) {
            data {
                ...F_APPOINTMENT
            }
        }
    }
`;

const appointmentDetailConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? [];
        return detail;
    },
};

const appointmentsConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return { data: list, pagination: res?.data?.data?.pagination };
    },
};

const AppointmentAPI = {
    list: async (input: any) =>
        API.withConverter(appointmentsConverter).query({
            query: getAppointments,
            variables: { input },
            fetchPolicy: "no-cache",
        }),

    detail: async (id: any) =>
        API.withConverter(appointmentDetailConverter).query({
            query: getAppointment,
            variables: { id },
            fetchPolicy: "no-cache",
        }),
};

export default AppointmentAPI;
