import React from "react";
import { IAppointment } from "../interfaces/appointment";

interface IAppointmentCreateState {
    appointmentForm: any;
}

const appointmentCreateState: IAppointmentCreateState = {
    appointmentForm: null,
};

export const AppointmentCreateContext = React.createContext(
    appointmentCreateState
);

interface IAppointmentDetailState {
    appointment: IAppointment;
    setAppointment: any;
}

const appointmentDetailState: IAppointmentDetailState = {
    appointment: {} as any,
    setAppointment: () => {},
};

export const AppointmentDetailContext = React.createContext(
    appointmentDetailState
);
