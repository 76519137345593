import {
    Button,
    IRowsKey,
    Notifications,
    Progress,
    ViewRowInterchange,
} from "d-react-components";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import CustomerAPI from "../../../api/queries/customer";
import Drawer from "../../../common/Drawer";
import { CustomerDetailContext } from "../../../context/customer";
import { ICustomer } from "../../../interfaces/customer";
import Messages from "../../../languages/Messages";
import CustomerSettingForm from "../share/CustomerSettingForm";

const CustomerDetailSetting = () => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const { setting } = customer || {};
    const CUSTOMER_SETTING_KEYS: IRowsKey<ICustomer>[] = [
        {
            id: "setting",
            label: Messages.vipGroup,
            renderContent: ({ data }) =>
                data?.isVip ? Messages.yes : Messages.no,
        },
    ];

    const [openEditDrawer, setOpenEditDrawer] = useState<{
        open: boolean;
        data?: any;
    }>({ open: false });
    return (
        <div className="p-4 border mt-3">
            <div className="flex-row-between-center">
                <h5>{Messages.setting}</h5>
                <Button
                    iconName="edit"
                    variant="trans"
                    color="secondary"
                    size="small"
                    onClick={() =>
                        setOpenEditDrawer({ open: true, data: setting })
                    }
                    className=""
                >
                    {Messages.edit}
                </Button>
            </div>

            <ViewRowInterchange
                keyList={CUSTOMER_SETTING_KEYS}
                dataSource={customer}
                variant="background"
            />

            {openEditDrawer?.open && (
                <CustomerUpdateSettingDrawer
                    onClose={() => setOpenEditDrawer({ open: false })}
                    open={openEditDrawer.open}
                    setting={openEditDrawer.data}
                />
            )}
        </div>
    );
};

export default CustomerDetailSetting;

interface ICustomerCrudNoteDrawer {
    open: boolean;
    setting?: any;
    onClose: () => void;
}

const CustomerUpdateSettingDrawer = ({
    setting,
    open,
    onClose,
}: ICustomerCrudNoteDrawer) => {
    const { customer, setCustomer } = useContext(CustomerDetailContext);
    const settingForm = useFormik<any>({
        initialValues: setting ?? {},
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: CustomerNoteSchema,
        onSubmit: (values: any) => {
            const input = { isVip: values?.isVip };
            onUpdateSetting(input);
        },
    });

    const onUpdateSetting = (input: any) => {
        Progress.show(
            {
                method: CustomerAPI.updateSetting,
                params: [
                    {
                        customerId: customer?.id as string,
                        settingInput: input,
                    },
                ],
            },
            (res: any) => {
                const updated = res?.data?.data?.data;
                if (updated) {
                    setCustomer(updated);
                    Notifications.showSuccess(Messages.updateSuccess);
                    onClose();
                }
            }
        );
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            size="auto"
            title={Messages.updateSetting}
            width="800px"
            onSave={() => {
                settingForm.handleSubmit();
            }}
        >
            <CustomerSettingForm form={settingForm} />
        </Drawer>
    );
};
