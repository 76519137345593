import * as Yup from "yup";
import { VOUCHER_ISSUANCE_TYPE } from "../constant/voucher";

export const VoucherCancelSchema = Yup.object().shape({
    remark: Yup.string().required("Required field!"),
});

export const VoucherSchema = Yup.lazy((value: any) => {
    let schema = Yup.object().shape({
        type: Yup.string().required("Required field!"),
        typeOfIssuance: Yup.string().required("Required field!"),
        effectiveDate: Yup.object().required("Required field!"),
        products: Yup.array().min(1).required("Required field!"),
        // customer: Yup.object().required("Required field!"),
    });
    if (value.typeOfIssuance === VOUCHER_ISSUANCE_TYPE.MANUAL) {
        schema = schema.shape({
            code: Yup.string().min(6).max(20).required("Required field!"),
        });
    }
    if (value.typeOfIssuance === VOUCHER_ISSUANCE_TYPE.AUTO_GENERATED) {
        schema = schema.shape({
            quantity: Yup.number().required("Required field!"),
            cashValue: Yup.number().required("Required field!"),
            usageLimit: Yup.number().required("Required field!"),
        });
    }
    return schema;
});
