import { Button, Progress, TabBar } from "d-react-components";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ProductAPI from "../../../api/queries/product";
import { ProductDetailContext } from "../../../context/product";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import Path from "../../Path";
import ProductDetailActivity from "./ProductDetailActivity";
import ProductDetailConfiguration from "./ProductDetailConfiguration";
import ProductDetailDescription from "./ProductDetailDescription";
import ProductDetailGallery from "./ProductDetailGallery";
import ProductDetailGeneralInfo from "./ProductDetailGeneralInfo";
import ProductDetailInfo from "./ProductDetailInfo";
import ProductDetailPriceConfig from "./ProductDetailPriceConfig";

const TABS = [
    {
        id: "personal",
        label: Messages.generalInformation,
        component: <ProductDetailGeneralInfo />,
    },
    {
        id: "description",
        label: Messages.description,
        component: <ProductDetailDescription />,
    },
    {
        id: "gallery",
        label: Messages.gallery,
        component: <ProductDetailGallery />,
    },
    {
        id: "priceConfig",
        label: Messages.priceConfig,
        component: <ProductDetailPriceConfig />,
    },
    {
        id: "activity",
        label: Messages.activity,
        component: <ProductDetailActivity />,
    },
];

const ProductDetail = () => {
    const navigate = useNavigate();
    const { productId } = useParams<any>();
    const [product, setProduct] = useState<IProduct>();
    const [tab, setTab] = useState(TABS[0]);

    useEffect(() => {
        loadProductDetail();
    }, []);

    const loadProductDetail = () =>
        Progress.show(
            {
                method: ProductAPI.detail,
                params: [productId],
            },
            setProduct as any
        );

    if (!product) {
        return <div />;
    }

    return (
        <ProductDetailContext.Provider value={{ product, setProduct }}>
            <div className="flex-column-container bg-white">
                <div className="bg-white px-4 py-3 flex-row-between-center border-bottom">
                    <div className="flex flex-1 align-items-center">
                        <h5 className="text-primary">{`${product?.name}`}</h5>
                    </div>
                    <Button
                        onClick={() =>
                            navigate(
                                generatePath(Path.PRODUCT_CREATE, {
                                    duplicateId: productId,
                                })
                            )
                        }
                    >
                        {Messages.duplicate}
                    </Button>
                </div>
                <div className="px-4 py-3">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <ProductDetailInfo />
                        </div>
                        <div className="col-span-3">
                            <TabBar
                                dataSource={TABS}
                                onChange={setTab as any}
                                value={tab}
                            />
                            <div className="bg-white mt-3">{tab.component}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ProductDetailContext.Provider>
    );
};

export default ProductDetail;
